import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    AddSessionsTypeErrorAction,
    AddSessionsTypeStartAction,
    AddSessionsTypeSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import SessionTypeService from "services/sessionTypeService";

export function* addSessionsTypeRecord({ data}: ReturnType<typeof AddSessionsTypeStartAction>) {
    try {
        yield SessionTypeService.addSessionsTypeRecord(data)
        yield put(AddSessionsTypeSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.sessionsType))

        yield put(NotificationOpen('success', 'Новый тип сессии добавлен'))
    } catch (error: any) {
        yield put(AddSessionsTypeErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить тип сессии'))
    }
}
