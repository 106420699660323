const reportsSchedulersData = [
    {
        id: '2',
        selector: '.tour-report-select',
        content: 'Выбранные значения позволяют отфильтровать список по типу отчета, для которых созданы расписания авторассылки отчетов',
        role: []
    },
    {
        id: '3',
        selector: '.tour-user-select',
        content: 'Выбранные значения позволяют отфильтровать список по пользователям, для которых созданы расписания авторассылки отчетов',
        role: []
    },
    {
        id: '4',
        selector: '.table',
        content:'Таблица расписаний авторассылки отчетов отображает созданные правила рассылки для конкретных пользователей по конкретным типам отчетов',
        role: [],
    },
]

export default reportsSchedulersData
