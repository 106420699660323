import {delay, put, select, call} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {FetchCF} from "../store/main/actions";
import {TRole} from "models/user";
import {selectRole} from "store/auth/selectors";
import {ExtendedSearchModel, ExtendedSearchTypes } from 'features/extended-search';
import {ProjectsFunction} from "./projects";
import {ContentFunction} from "./content";
import {CalendarFunction} from "./calendar";


export function* getDataWorker({ typeContent }: ReturnType<typeof FetchCF>) {
	try {
		const role: TRole = yield select(selectRole);

		if (!role) {
			yield delay(500)
			yield put(FetchCF(typeContent))
		}

		const filterES: ExtendedSearchTypes.Stores.TAllFilterValues = yield select(ExtendedSearchModel.selectors.filter(typeContent));
		if (typeContent === 'projects') {
			yield call(ProjectsFunction, filterES)
		}
		if (typeContent === 'content') {
			yield call(ContentFunction, filterES)
		}
		if (typeContent === 'calendar') {
			yield call(CalendarFunction, filterES)
		}

	} catch (error: any) {
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
	}
}


