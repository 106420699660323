import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetAllNationalProjectTag1ErrorAction,
    GetAllNationalProjectTag1StartAction,
    GetAllNationalProjectTag1SuccessAction
} from "../../store/national-project-tag1/actions";
import NationalProjectTag1Service from "../../services/nationalProjectTag1Service";

export function* nationalProjectTag1(_: ReturnType<typeof GetAllNationalProjectTag1StartAction> ) {
    try {
        const {data} =  yield NationalProjectTag1Service.getNationalProjectTag1()
        yield put(GetAllNationalProjectTag1SuccessAction(data))
    } catch (error) {
        yield put(GetAllNationalProjectTag1ErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить список тегов национальных проектов 1-го уровня'))
    }
}