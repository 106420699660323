import React from 'react';
import styles from './styles.module.less';
import { SelectComponent } from 'components/formic-control';
import { useQuery } from 'react-query';
import { useFormikContext } from 'formik';
import { TForm } from './modal';
import { TaskDuplicationSchedulerController } from 'features/сonfiguring-task-autocreation/api';

const Schedule = () => {
	const {values} = useFormikContext<TForm>();
	const enabled = values.enabled;

	const {data: tasks, isLoading} = useQuery({
		queryKey: [TaskDuplicationSchedulerController.get.key],
		queryFn: TaskDuplicationSchedulerController.get.fetchOptions,
	});

	return (
		<div className={styles.item}>
			<span className={styles.label}>Созданные расписания <sup>*</sup></span>
			<div className={styles.field}>
				<SelectComponent
					options={tasks || []}
					multi={true}
					maxTagCount={'responsive'}
					loading={isLoading}
					disabled={!enabled}
					name={'schedule'}
					placeholder={'Выберите задачи для согласования'}
				/>
			</div>
		</div>
	)
};

export default Schedule;