import {call, put, select} from "redux-saga/effects";
import {
    GetTasksContentErrorAction,
    GetTasksContentStartAction,
    GetTasksContentSuccessAction,
    SetTaskContentFilter,
    VoteTaskContentChangeMultipleStatuses
} from "store/tasks/actions/content";
import TasksContentService from "services/tasksContentService";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {TTaskRecordFilter} from "models/task/task-content";
import {AxiosResponse} from "axios";
import {selectContentTasksFilter} from "store/tasks/selectors/selectors-task-content";
import {Query} from "processes/query";
import {TaskApi, TaskTypes} from "entities/task";
import ZodParser from 'shared/services/zod-parser';
import {selectUser} from "store/auth/selectors";
import {UserTypes} from 'entities/user'

export function* getTasks({taskId}: ReturnType<typeof GetTasksContentStartAction>) {
    try {
        const filter: TTaskRecordFilter = yield select(selectContentTasksFilter)

        const {data}: AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.ContentRecords[]>> = yield TasksContentService.getTaskRecords({
            ...filter,
            filterData: {...filter.filterData, taskId: taskId}
        })
        ZodParser.parse(
          TaskTypes.ContentRecordsSchema.array(),
          data.content,
          `/tasks/content/records/search`
        );
        yield call(saveVotes, data)
        if (checkValidPage(data)) {
            yield put(SetTaskContentFilter({...filter, page: 1}))
        } else {
            Query.invalidate([TaskApi.getTaskRecordsCount.key(taskId)]);
            yield put(GetTasksContentSuccessAction(data))
        }
    } catch (error: any) {
        yield put(GetTasksContentErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить задачи'))
    }
}

function checkValidPage(data: TaskTypes.TaskRecordsResponse<any>): boolean {
    return !!(data?.totalCount && data.page * data.pageSize >= data?.totalCount && data.totalCount !== 0)
}
/**
 * После получения данных по записям, сохраняем в redux голоса(votes), используем action (VoteTaskContentChangeMultipleStatuses) который сохраняет пачкой
 */
function* saveVotes (data: TaskTypes.TaskRecordsResponse<TaskTypes.ContentRecords[]>) {
    const user: UserTypes.CurrentUser = yield select(selectUser)
    let votes: Record<string, TaskTypes.NewVote> = {};

    for (const record of data.content) {
        for (const vote of record.votes || []) {
            const voteData: TaskTypes.NewVote = {
                vote: {
                    comment: vote.comment || null,
                    isApproved: vote.isInterimApproved,
                    isInterimApproved: vote.isInterimApproved,
                    recordId: record.id,
                },
                files: vote.files || [],
            };
            if (vote.isInterimApproved !== null && vote.user.id === user.id) {
                votes[record.id] = voteData
            }
        }
    }
    yield put(VoteTaskContentChangeMultipleStatuses(votes))
}