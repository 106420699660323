import {AxiosResponse} from 'axios'
import {put} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {
	GetTagsSecondLevelWithFirstErrorAction,
	GetTagsSecondLevelWithFirstStartAction,
	GetTagsSecondLevelWithFirstSuccessAction
} from "store/tags/actions";
import {TTagSecondLevel} from "models/tags";
import {TagsService} from "services/tagsService";

export default function* getSecondLevelWithFirst({ firstLevelTagIds }: ReturnType<typeof GetTagsSecondLevelWithFirstStartAction>) {
	try {
		const {data}: AxiosResponse<TTagSecondLevel[]>  = yield TagsService.getTagsSecondLevel({
			page:0,
			pageSize: 0,
			filterData: {firstLvlTagIds: firstLevelTagIds}
		});
		yield put(GetTagsSecondLevelWithFirstSuccessAction(data))
	} catch (error: any) {
		yield put(GetTagsSecondLevelWithFirstErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
