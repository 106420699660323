import React, {FC, useState} from 'react';
import {Modal} from "antd";
import styles from './modal-support.module.less'
import useHandlerModal from "hooks/handlModal";
import {MessageOutlined, SendOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpenText} from "@fortawesome/free-solid-svg-icons";


type ModalSupportProps = {
    status: boolean
}
const ModalSupport: FC<ModalSupportProps> = ({status}) => {
    const [selectedOption, setSelectedOption] = useState<'email'|'telegram'>('email');

    const handleOptionChange = (e: any) => {
        setSelectedOption(e.target.value);
    };

    const {isVisible, closeModal} = useHandlerModal(status);

    return (
        <Modal
            className={styles.modal_support}
            visible={isVisible}
            onCancel={closeModal}
            footer={false}
            maskClosable={true}
        >
            <div className={styles.header}>
            <h1 className={styles.title}>У вас есть вопросы?</h1>
            <p className={styles.text}>Напишите нам</p>
            </div>
            <div className={styles.radio}>
                <div className={styles.email}>
                    <input
                        type="radio"
                        id="emailOption"
                        value="email"
                        checked={selectedOption === 'email'}
                        onChange={handleOptionChange}
                    />
                    <label htmlFor="emailOption">Email</label>
                </div>
                <div className={styles.telegram}>
                    <input
                        type="radio"
                        id="telegramOption"
                        value="telegram"
                        checked={selectedOption === 'telegram'}
                        onChange={handleOptionChange}
                    />
                    <label htmlFor="telegramOption">Telegram</label>
                </div>

            </div>
            {selectedOption === 'email' ? <EmailBody/> : <TelegramBody/>}

        </Modal>
    )
};

function EmailBody() {
    return (
        <div className={styles.email_body}>
            <div>
                <FontAwesomeIcon className={styles.icon} icon={faEnvelopeOpenText} size="2x"/>
            </div>
            <div className={styles.email_body_text}>
                <a href="mailto:info@iri.center">
                    <MessageOutlined />
                    <span>
                        Отправить e-mail
                    </span>
                </a>

            </div>
        </div>
    )
}

function TelegramBody() {
    return (
        <div className={styles.telegram_body}>
            <img src={window.location.origin + '/images/qr.png'} alt=""/>
            <div className={styles.email_body_text}>
                <a href="https://t.me/IRI_support_kontent_bot" rel="noreferrer" target="_blank">
                    <SendOutlined />
                    <span>Телеграм-чат поддержки</span>
                </a>
            </div>
        </div>
    )
}

export default ModalSupport;
