import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteSessionsTypeErrorAction,
    DeleteSessionsTypeStartAction,
    DeleteSessionsTypeSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import SessionTypeService from "services/sessionTypeService";


export function* deleteSessionsTypeRecord({id}: ReturnType<typeof DeleteSessionsTypeStartAction>) {
    try {
        yield SessionTypeService.deleteSessionsTypeRecord(id)
        yield put(DeleteSessionsTypeSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.sessionsType))
        yield put(NotificationOpen('success', 'Тип сессии удален'))

    } catch (error: any) {
        yield put(DeleteSessionsTypeErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить тип сессии'))
    }
}
