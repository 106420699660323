import {z} from 'zod';
import {RoleSchema} from 'entities/user/types';
import {FilterRequest, SearchResponse} from 'shared/types/common';

export const ReportTypeEnum = z.enum([
    'R1.2', 'R7', 'R4', 'R6', 'R11', 'R14', 'R15', 'R16', 'R17', 'R5', 'R2', 'R3.2', 'R1.3', 'R1.1', 'R9.2', 'R12', 'R8', 'R9.1', 'R3.1', 'R10'
])
export type ReportType = z.infer<typeof ReportTypeEnum>;

const ReportFileSchema = z.object({
    id: z.string(),
    compressedId: z.string(),
    scaledId: z.string(),
    name: z.string(),
    type: z.enum([ 'PDF', 'DOCX', 'XLSX' ]),
    storagePath: z.string(),
    previewFil: z.string(),
    size: z.number()
})
const ArchiveDataSchema = z.object({
    id: z.string(),
    entityType: z.enum(['ESTIMATE_TASK_RECORD', 'CONTENT_TASK_RECORD', 'REPORTS']),
    createDateTime: z.string(),
    deletionDateTime: z.string(),
    status: z.enum([ 'NEW', 'CREATED', 'ERROR', 'DELETED' ]),
    message: z.string(),
    file: ReportFileSchema
})
export const ReportsContentSchema = z.object({
    id: z.string(),
    user: z.object({
        id: z.string(),
        lastName: z.string(),
        firstName: z.string(),
        middleName: z.string(),
        role: RoleSchema,
        username: z.string(),
    }),
    type: z.string(),
    format: z.enum([ 'PDF', 'DOCX', 'XLSX' ]),
    status: z.enum([ 'NEW', 'CREATED', 'ERROR', 'DELETED' ]),
    message: z.string(),
    filename: z.string(),
    createDate: z.string(),
    file: ReportFileSchema,
    deletionDate: z.string(),
    archiveData: ArchiveDataSchema
})

export type ReportsContent = z.infer<typeof ReportsContentSchema>
export type ReportsResponse = SearchResponse<ReportsContent>

export const FilterDataSchema = z.object({
    format: z.enum([ 'PDF', 'DOCX', 'XLSX' ]).optional(),
    fromCreateDate: z.string().optional(),
    status: z.enum([ 'NEW', 'CREATED', 'ERROR', 'DELETED' ]).optional(),
    type: ReportTypeEnum,
    projectIds: z.array(z.string()).optional()
})
export type FilterData = z.infer<typeof FilterDataSchema>
export type Filter = FilterRequest<FilterData>

export const ReportTemplateSchema = z.object({
    id: z.string(),
    code: z.string(),
    name: z.string(),
    fileExtensions: z.array(z.string())
})

export type ReportTemplate = z.infer<typeof ReportTemplateSchema>