export function downloadXLXS(content: BlobPart, fileName: string) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([content], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"}));
    link.download = fileName;
    link.click();
}

export function downloadFile(content:any, fileName: string) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([content],{type: 'application/octet-stream' }));
    link.download = fileName
    link.click();
}
