import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {downloadXLXS} from "../../utils/downloadXLXS";
import {NotificationOpen} from "../../store/notification/actions";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {
    DownloadProjectsErrorAction,
    DownloadProjectsStartAction,
    DownloadProjectsSuccessAction
} from "../../store/projects/actions";
import Api from "../../entities/project/api/project";

export default function* downloadProjects({projectsIds, name}: ReturnType<typeof DownloadProjectsStartAction>) {
    try {
        const { data: response }: AxiosResponse<any> = yield Api.downloadProjects(projectsIds)
        yield put(DownloadProjectsSuccessAction())
        downloadXLXS(response, name)
        yield put(NotificationOpen('success', 'Данные выгружены успешно'))

    } catch (error: any) {
        yield put(DownloadProjectsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось выгрузить'));
    }
}