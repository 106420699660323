import {put} from 'redux-saga/effects'
import {
	BulkCategoryProjectActionsErrorAction,
	BulkCategoryProjectActionsStartAction,
	BulkCategoryProjectActionsSuccessAction,
	GetHandbookStartAction,
} from 'store/options/actions/actions-handbook'
import {TStatusesHandbookFeels} from 'models/handbook'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import CategoryProjectService from 'services/categoryProjectService'

export function* bulkCategoryProjectActions({
	userArr,
	action,
}: ReturnType<typeof BulkCategoryProjectActionsStartAction>) {
	try {
		yield CategoryProjectService.bulkCategoryProjectActions(userArr, action)

		yield put(BulkCategoryProjectActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.categoryProject))
		yield put(NotificationOpen('success', 'Обновлено успешно'))
	} catch (error: any) {
		yield put(BulkCategoryProjectActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}
}
