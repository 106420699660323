import {AxiosResponse} from "axios";
import $api from '../http'
import {TInternet} from "../models/handbook";

export default class InternetResourcesService {

    /**
     *  Добавление интернет ресурсов
     */
    static async addInternet(data: Omit<TInternet, 'id'>): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/internet-resources`, data)
    }
    /**
     *   Обновление интернет ресурсов
     */
    static async updateInternet(data: Omit<TInternet, 'id'>, id: string): Promise<AxiosResponse> {
        return $api.put<any>(`/internet-resources/${id}`, data)
    }
    /**
     *   Удаление интернет ресурсов
     */
    static async deleteInternet(id: string): Promise<AxiosResponse> {
        return $api.delete<any>(`/internet-resources/${id}`)
    }
    /**
     *   Массовые действия интернет ресурсов
     */
    static async bulkActions(usersArr: string[], action: string): Promise<AxiosResponse> {
        return $api.post<any>(`/internet-resources/bulk-actions/${action}`, usersArr)
    }
    /**
     *   Выгрузка данных справочниках для интернет ресурсов
     */
    static async downloadInternet(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/internet-resources/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }
    /**
     *   Выгрузка шаблона для интернет ресурсов
     */
    static async downloadInternetTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/internet-resources/import/template',
            responseType:'arraybuffer',
        })
    }
    /**
     * Получение списка данных для поля (Вид площадки)
     */
    static getInternetResourceTypeListKey = '/internet-resources/types'
    static async getInternetResourceTypeList (): Promise<AxiosResponse> {
        return $api.get('/internet-resources/types')
    }

    /**
     * Получение списка данных для поля (Интернет ресурсов)
     */
    static getInternetResourceListKey = '/internet-resources'
    static async getInternetResourceList (): Promise<AxiosResponse> {
        return $api.get('/internet-resources')
    }

    /**
     * Загрузить файл интернет ресурсов
     */
    static async uploadInternet (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/internet-resources/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
}