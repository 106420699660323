import {put, select} from 'redux-saga/effects'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import {NotificationOpen} from '../../store/notification/actions'
import {
	GetProjectDocumentsWithFilterErrorAction,
	GetProjectDocumentsWithFilterStartAction,
	GetProjectDocumentsWithFilterSuccessAction,
	SetProjectDocumentsFilter,
} from '../../store/project-documents/actions'
import ProjectDocumentsService from '../../services/projectDocument'
import {selectProjectDocumentsFilter} from '../../store/project-documents/selectors'
import {TProjectDocumentsFilter} from '../../models/projects/filter'
import {AxiosResponse} from 'axios'
import {TResponseProjectDocuments} from '../../models/projects/project-document'

export function* getDocumentsWithFilter(_action: ReturnType<typeof GetProjectDocumentsWithFilterStartAction>) {
	try {
		const filter: TProjectDocumentsFilter = yield select(selectProjectDocumentsFilter)
		const { data }: AxiosResponse<TResponseProjectDocuments> = yield ProjectDocumentsService.getDocumentsWithFilter(
			filter
		)
		if (data.totalCount && data.page * data.pageSize >= data.totalCount && data.totalCount !== 0) {
			yield put(SetProjectDocumentsFilter({...filter, page: 1}))
		} else {
			yield put(GetProjectDocumentsWithFilterSuccessAction(data))
		}
	} catch (error: any) {
		yield put(GetProjectDocumentsWithFilterErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось загрузить документы'))
	}
}
