import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    AddKPEErrorAction,
    AddKPEStartAction,
    AddKPESuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import HandbookService from "services/handbookService";

export function* addKPE({ data}: ReturnType<typeof AddKPEStartAction>) {
    try {
        yield HandbookService.addKPERecord(data)
        yield put(AddKPESuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.KPE))

        yield put(NotificationOpen('success', 'Новые данные КПЭ добавлены'))
    } catch (error: any) {
        yield put(AddKPEErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить данные КПЭ'))
    }
}
