import {ReportType, TypesTask} from "entities/task/types";
import {Content, Report, TActions} from "entities/task/types/records";

export function validateForContractor({
    archival,
    typeTask,
    recordStatus,
    reportType,
    isUploadInProgress,
    file
}: Content | Report): TActions {
    const view: TActions = {
        edit: 'hidden',
        delete: 'hidden',
        changeStatus: 'hidden',
        send: 'hidden',
        cancel: 'hidden',
        save: 'hidden'
    };
    if (archival) return view;
    if (typeTask === TypesTask.CONTENT) {
        if (recordStatus === 'READY_FOR_VERIFICATION') {
            if (isUploadInProgress && !file) {
                view.edit = 'view';
                view.delete = 'view';
                view.send = 'disabled';
            }
            if (!isUploadInProgress && file !== null) {
                view.edit = 'view';
                view.delete = 'view';
                view.send = 'view';
            }
        }
        if (recordStatus === 'DRAFT') {
            view.edit = 'view';
            view.delete = 'view';
            view.send = 'disabled';
        }
    }
    if (typeTask === TypesTask.REPORT) {
        if (recordStatus === 'REJECTED') {
            view.edit = 'view';
            view.delete = 'disabled';
            view.send = 'disabled';
        }
        if (recordStatus === 'DRAFT') {
            if (reportType === ReportType.CONTENT_REACH_REPORT) {
                view.edit = 'view';
                view.delete = 'disabled';
                view.send = 'disabled';
            } else if (
                reportType === ReportType.CONTENT_POSTING_REPORT ||
                reportType === ReportType.COPYRIGHT_LIST ||
                reportType === ReportType.PROJECT_ESTIMATE
            ) {
                view.edit = 'view';
                view.delete = 'view';
                view.send = 'disabled';
            }
        }
        if (recordStatus === 'READY_FOR_VERIFICATION') {
            if (reportType === ReportType.CONTENT_REACH_REPORT) {
                view.edit = 'view';
                view.delete = 'disabled'
                view.send = 'view';
            } else if (
                reportType === ReportType.CONTENT_POSTING_REPORT ||
                reportType === ReportType.COPYRIGHT_LIST ||
                reportType === ReportType.PROJECT_ESTIMATE
            ) {
                view.edit = 'view';
                view.delete = 'view';
                view.send = 'view';
            }
        }
    }
    return view;
}