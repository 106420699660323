import {put} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	GetAvailableReportsErrorAction,
	GetAvailableReportsStartAction,
	GetAvailableReportsSuccessAction,
} from 'store/project-reporting/actions'

import {AxiosResponse} from 'axios'
import ReportingService from "services/reportingService";
import {TAvailableReports} from "models/projects/projects-reporting";

export function* getAvailableReports({typeReports, id}: ReturnType<typeof GetAvailableReportsStartAction>) {
	try {
		const { data }: AxiosResponse<TAvailableReports[]> = yield ReportingService.getAvailableReports(typeReports, id)

		yield put(GetAvailableReportsSuccessAction({data, projectId: id as string}))
	} catch (error: any) {
		yield put(GetAvailableReportsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ))
	}
}
