import React, { FC } from 'react'
import { Tooltip } from 'antd'
import { PROJECTS } from 'routing/names'
import {
	ContactsOutlined,
	DownloadOutlined,
	FileTextOutlined,
	LinkOutlined,
	ScheduleOutlined,
	UnorderedListOutlined,
} from '@ant-design/icons'
import PathConverter from 'utils/path-converter'
import { NotificationOpen } from 'store/notification/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectContentProject } from 'store/content/content/selectors'
import { TypeContent } from 'models/content'
import WhetherTheUser from 'components/whetherTheUser'
import { ModalOpenAction } from 'store/modals/actions'
import {selectRoleWithOptionalParams} from "store/auth/selectors";

const ProjectInformation: FC<{ activeContent: TypeContent | null }> = ({ activeContent }) => {
	const put = useDispatch()
	const history = useHistory()
	const {downloadAccess, storageLinkAccess} = useSelector(selectRoleWithOptionalParams)
	const { project } = useSelector(selectContentProject)
	const handlerNavigate = (path: string) => () => {
		history.push(PathConverter(path, project?.id), {tooltip: project?.name})
	}
	const handlerCopyUrl = (url: string) => () => {
		navigator.clipboard.writeText(url).then((_r) => {
			put(NotificationOpen('success', 'Ссылка скопирована'))
		})
	}
	return (
		<div className="item">
			<span className="title">Информация о проекте</span>
			<div className="links">
				<WhetherTheUser role={['ROLE_CLIENT', 'ROLE_AGENT']} isCan={false}>
					<>
						<Tooltip title={'Карточка проекта'}>
							<div className="links__item" onClick={handlerNavigate(PROJECTS.PROJECT_PARAMS)}>
								<ContactsOutlined />
							</div>
						</Tooltip>
						<WhetherTheUser role={['ROLE_CONTRACTOR', 'ROLE_VIEWER']} isCan={false}>
							<Tooltip title={'Задачи проекта'}>
								<div className="links__item" onClick={handlerNavigate(PROJECTS.PROJECT_TASKS)}>
									<UnorderedListOutlined />
								</div>
							</Tooltip>
						</WhetherTheUser>
						<WhetherTheUser role={['ROLE_CONTRACTOR', 'ROLE_VIEWER']} isCan={false}>
							<Tooltip title={'Документы проекта'}>
								<div className="links__item" onClick={handlerNavigate(PROJECTS.PROJECTS_DOCUMENTS)}>
									<FileTextOutlined />
								</div>
							</Tooltip>
						</WhetherTheUser>

						<WhetherTheUser role={['ROLE_CONTRACTOR', 'ROLE_VIEWER']} isCan={false}>
							<>
								{activeContent?.file?.storagePath && storageLinkAccess && (
									<Tooltip title={'Ссылка на СХД'}>
										<div className="links__item" onClick={handlerCopyUrl(activeContent?.file.storagePath)}>
											<LinkOutlined />
										</div>
									</Tooltip>
								)}
							</>
						</WhetherTheUser>
					</>
				</WhetherTheUser>
				<WhetherTheUser role={['ROLE_VIEWER']} isCan={false}>
					<>
						<Tooltip title={'Публикации контента в сети'}>
							<div
								className="links__item"
								onClick={() => {
									put(
										ModalOpenAction('posting-content', {
											projectId: project?.id,
										})
									)
								}}
							>
								<ScheduleOutlined />
							</div>
						</Tooltip>
						{downloadAccess ? (
							<Tooltip title={'Скачать контент проекта'}>
								<div
									className="links__item"
									onClick={() => {
										put(
											ModalOpenAction('content-records', {
												projectId: project?.id,
											})
										)
									}}
								>
									<DownloadOutlined />
								</div>
							</Tooltip>
						) : null}
					</>
				</WhetherTheUser>
			</div>
		</div>
	)
}

export default ProjectInformation
