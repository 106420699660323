import {
	GetActivityUsersListErrorAction,
	GetActivityUsersListStartAction,
	GetActivityUsersListSuccessAction,
	SetActivityUsersFilter,
} from 'store/options/actions/actions-actions-users'
import {AxiosResponse} from 'axios'
import {put, select} from 'redux-saga/effects'
import {TUserOptionsFilter, TUsersActionResponse} from 'models/user'
import {selectActivityUsersFilter} from 'store/options/selectors/selectors-actions-users'
import UsersActionsService from "services/usersActionsService";

export default function* getActivityUsersList(_action: ReturnType<typeof GetActivityUsersListStartAction>) {
	try {
		const filter: TUserOptionsFilter = yield select(selectActivityUsersFilter)
		const { data }: AxiosResponse<TUsersActionResponse> = yield UsersActionsService.getActivityUsers(filter)
		if (data.totalCount && data.page * data.pageSize >= data.totalCount && data.totalCount !== 0) {
			yield put(SetActivityUsersFilter({...filter, page: 1}))
		} else {
			yield put(GetActivityUsersListSuccessAction(data))
		}
	} catch (error: any) {
		yield put(GetActivityUsersListErrorAction(error))
	}
}
