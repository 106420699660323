import {AxiosResponse} from 'axios'
import $api from '../http'
import {TOrganizationsUser, TPerson} from '../models/user'
import {TUserForm} from '../models/users-list/user-form'
import {UserTypes} from "entities/user";

/**
 * @category Service
 * @class UsersService
 */
export default class UsersService {
	/**
	 * Получение всех подрядчиков системы
	 */
	static contractorsKey = '/users/contractors'
	static async getContractors(id?: string): Promise<AxiosResponse<TPerson[]>> {
		return $api.get<TPerson[]>('/users/contractors', {
			params: {
				organizationId: id
			}
		})
	}
	/**
	 * Получение всех подрядчиков по ID проекта
	 */
	static async getContractorsId(id: string): Promise<AxiosResponse<TPerson[]>> {
		return $api({
			method: 'GET',
			url: '/users/contractors',
			params: {
				organizationId: id
			}
		})
	}
	/**
	 * Получение всех координаторов системы
	 */
	static coordinatorsKey = '/users/coordinators'
	static async getCoordinators(): Promise<AxiosResponse<TPerson[]>> {
		return $api.get<TPerson[]>('/users/coordinators')
	}
	/**
	 * Получение всех продюсеров системы
	 */
	static producersKey = '/users/producers'
	static async getProducers(): Promise<AxiosResponse<TPerson[]>> {
		return $api.get<TPerson[]>('/users/producers')
	}
	/**
	 * Получение всех аналитиков системы
	 */
	static analystsKey = '/users/analysts'
	static async getAnalysts(): Promise<AxiosResponse<TPerson[]>> {
		return $api.get<TPerson[]>('/users/analysts')
	}
	/**
	 * Получение всех финансистов системы
	 */
	static financiersKey = '/users/financiers'
	static async getFinanciers(): Promise<AxiosResponse<TPerson[]>> {
		return $api.get<TPerson[]>('/users/financiers')
	}
	/**
	 * Получение всех юристов системы
	 */
	static lawyersKey = '/users/lawyers'
	static async getLawyers(): Promise<AxiosResponse<TPerson[]>> {
		return $api.get<TPerson[]>('/users/lawyers')
	}
	/**
	 * Получение всех менеджеров проекта системы
	 */
	static projectManagersKey = '/users/project-managers'
	static async getProjectManagers(): Promise<AxiosResponse<TPerson[]>> {
		return $api.get<TPerson[]>('/users/project-managers')
	}
	/**
	 * Получение всех пользователей системы
	 */
	static async getUsers(data: UserTypes.OptionsUserFilter): Promise<AxiosResponse<UserTypes.OptionsUserResponse>> {
		const filter = {...data, page: data.page - 1}
		return $api.post<UserTypes.OptionsUserResponse>('/users/search', filter)
	}
	/**
	 * Получение всех редакторов контента
	 */
	static contentEditorsKey = '/users/content-editors'
	static async getContentEditors(): Promise<AxiosResponse<TPerson[]>> {
		return $api.get<TPerson[]>('/users/content-editors')
	}
	/**
	 * Создание нового пользователя
	 */
	static async createUser(data: any): Promise<AxiosResponse<string | number>> {
		return $api({
			method: 'POST',
			url: '/users',
			withCredentials: true,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: data,
		})
	}
	/**
	 * Удаление пользователя
	 */
	static async deleteUser(userId: string): Promise<AxiosResponse> {
		return $api.delete(`/users/${userId}`)
	}
	/**
	 * Обновление пользователя
	 */
	static async updateUser(userId: string, data: any): Promise<AxiosResponse> {
		return $api({
			method: 'PUT',
			url: `/users/${userId}`,
			headers : {
				'Content-Type': 'multipart/form-data'
			},
			data: data
		})
	}
	/**
	 * Обновление базовых персональных настроек пользователя
	 */
	static async updatePersonalData (data: any): Promise<AxiosResponse> {
		return $api({
			method: 'PUT',
			url: `/users/personal-data`,
			headers : {
				'Content-Type': 'multipart/form-data'
			},
			data: data
		})
	}
	/**
	 * Получение пользователя по ID
	 */
	static async getUser(userId: string): Promise<AxiosResponse<TUserForm>> {
		return $api.get<TUserForm>(`/users/${userId}`)
	}
	/**
	 * Повторная отправка логина/пароля пользователю Администратором системы
	 */
	static async sendUserDetails(id: string): Promise<any> {
		return $api.get(`/users/login-details/${id}`)
	}
	/**
	 * Получить список пользователей c признаком исключения видимости контента для конкретной записи контента
	 * с фильтрацией и пагинацией в кратком формате
	 */
	static async getExcludedUsers(filter: UserTypes.ExcludedTypes.Filter): Promise<UserTypes.ExcludedTypes.ExcludedResponse> {
		const newFilter = {...filter, page: filter.page - 1}
		return $api.post('/users/excluded-users/search', newFilter)
	}
	/**
	 * Изменение пароля
	 */
	static async changePassword(userId: string, password: any): Promise<AxiosResponse> {
		return $api({
			method: 'PUT',
			url: `/users/${userId}/update-password`,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: password,
		})
	}
	/**
	 * Изменение пароля в персональных настройках
	 */
	static async changePasswordPersonalSetting(password: any): Promise<AxiosResponse> {
		return $api({
			method: 'PUT',
			url: `/users/update-password`,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: password,
		})
	}
	/**
	 * Получение пользователей системы задействованых в задачах текущего пользователя
	 */
	static getRelatedUsersKey = '/users/current/tasks/related-users'
	static async getRelatedUsers(): Promise<AxiosResponse<TPerson[]>> {
		return $api.get<TPerson[]>('/users/current/tasks/related-users')
	}
	/**
	 * Подтверждение на согласие обработку данных
	 */
	static async confirmAgreement(): Promise<AxiosResponse> {
		return $api.put(`/users/confirm-agreement`)
	}
	/**
	 * Подтверждение согласия на  получение информационных уведомлений
	 */
	static async confirmReceiving(): Promise<AxiosResponse> {
		return $api.put(`/users/confirm-receiving-notifications`)
	}
	/**
	 * Выполнение выгрузки списка в формате Excel
	 */
	static async usersExport(usersIds: string[]): Promise<AxiosResponse> {
		return $api({
			method: 'POST',
			url: '/users/export',
			responseType:'arraybuffer',
			headers: {
				'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
			data: usersIds,
		})
	}
	/**
	 * Получение файла шаблона для выполнения импорта данных о пользователях
	 */
	static async usersImportTemplate(): Promise<AxiosResponse> {
		return $api({
			method: 'GET',
			url: '/users/import/template',
			responseType:'arraybuffer',
			headers: {
				'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
		})
	}
	/**
	 * Выполнение импорта данных о пользователях из заполненного шаблона в формате Excel
	 */
	static async usersImport(data: FormData): Promise<AxiosResponse> {
		return $api({
			method: 'POST',
			url: '/users/import',
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data,
		})
	}
	static async updateDownloadRights(): Promise<AxiosResponse<any>> {
		return $api.put('/users/confirm-download-rights')
	}
	/**
	 * Получение организаций пользователя
	 */
	static async getOrganizationsUser(userId: string): Promise<AxiosResponse<TOrganizationsUser[]>> {
		return $api.get<TOrganizationsUser[]>(`/users/${userId}/organizations`, {
			headers: {
				'Actioncode': 'GET_USERS_USER_ID_ORGANIZATIONS'
			}
		})
	}
	/**
	 * Получение пользователей системы задействованных в задачах проектов текущего пользователя как проверяющие
	 */
	static async getInspectorsUsers(): Promise<AxiosResponse<UserTypes.TInspector[]>> {
		return $api.get<UserTypes.TInspector[]>('/users/current/tasks/inspectors')
	}
	/**
	 * Получение списка активных синхронизированных пользователей
	 */
	static async getListActiveSyncedUsers(): Promise<AxiosResponse<UserTypes.User[]>> {
		return $api.get<UserTypes.User[]>(`users/active-synced-users`)
	}

}
