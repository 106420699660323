import {InferValueTypes} from 'models/common'
import * as actions from '../actions/actions-actions-users'
import {TUserOptionsFilter, TUsersActionResponse,} from 'models/user'
import {UsersDoingTypes} from '../action-types'
import {ErrorActionState, StartActionState, SuccessActionState} from 'utils/reducerHalper'
import {AuthActionTypes} from "../../auth/action-types";
import {Logout} from "../../auth/reducer";
import {UserTypes} from 'entities/user'

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TUsersActionsState = {
	filterActivity: TUserOptionsFilter
	filterActions: TUserOptionsFilter
	activityUsersData: TUsersActionResponse | null
	actionsUsersData: UserTypes.UserActionsResponse | null
	isLoading: boolean
	error: any
}

export const initialState: TUsersActionsState = {
	filterActivity: {
		page: 1,
		pageSize: 50,
	},
	filterActions: {
		page: 1,
		pageSize: 50,
	},
	activityUsersData: null,
	actionsUsersData: null,
	isLoading: false,
	error: null,
}

export default function usersActionsReducer(
	state: TUsersActionsState = initialState,
	action: ActionTypes | Logout
): TUsersActionsState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return {...initialState}
		case UsersDoingTypes.SET_ACTIVITY_USERS_FILTER:
			return {...state, filterActivity: action.filter }
		case UsersDoingTypes.SET_ACTIONS_USERS_FILTER:
			return {...state, filterActions: action.filter }

		case UsersDoingTypes.GET_ACTIVITY_USERS_LIST_START:
			return {...StartActionState(state)}
		case UsersDoingTypes.GET_ACTIVITY_USERS_LIST_SUCCESS:
			return {...SuccessActionState(state), activityUsersData: action.payload }
		case UsersDoingTypes.GET_ACTIVITY_USERS_LIST_ERROR:
			return ErrorActionState(state, action)

		case UsersDoingTypes.GET_ACTIONS_USERS_START:
			return {...StartActionState(state)}
		case UsersDoingTypes.GET_ACTIONS_USERS_SUCCESS:
			return {...SuccessActionState(state), actionsUsersData: action.payload }
		case UsersDoingTypes.GET_ACTIONS_USERS_ERROR:
			return ErrorActionState(state, action)

		default:
			return state
	}
}
