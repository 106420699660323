import { axiosRequest } from 'shared/config/axios'
import { RegionSet } from '../types'
import { IOptions } from 'models/feels'

export class RegionController {
	static key = `/region/types`
	static keyOptions = 'Options: /region/types'

	static async fetch() {
		const { data: genres } = await axiosRequest<RegionSet[]>({
			method: 'GET',
			url: '/region/types',
		})
		return genres
	}
	static async fetchOptions() {
		const data = await RegionController.fetch()
		return data.map((el) => ({
					value: el.id,
					label: el.name,
					hidden: el.hidden,
				} as IOptions)
		)
	}
}
