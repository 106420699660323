import {ProjectTypes} from "entities/project";
import {ProjectStatusEnum} from "entities/project/types";

export default function statusValidateRules(status: ProjectTypes.ProjectStatus): ProjectTypes.ProjectStatus[] {
    let res: ProjectTypes.ProjectStatus[] = []
    if (status === 'ARCHIVED') res = [ProjectStatusEnum.READY_TO_BE_ACTUAL, ProjectStatusEnum.COMPLETED, ProjectStatusEnum.ACTUAL]
    if (status === 'READY_TO_BE_ACTUAL') res = [ProjectStatusEnum.ACTUAL, ProjectStatusEnum.ARCHIVED]
    if (status === 'ACTUAL') res = [ProjectStatusEnum.SUSPENDED, ProjectStatusEnum.COMPLETED, ProjectStatusEnum.ARCHIVED, ProjectStatusEnum.READY_TO_BE_ACTUAL]
    if (status === 'SUSPENDED' || status === 'COMPLETED') res = [ProjectStatusEnum.ACTUAL, ProjectStatusEnum.ARCHIVED]
    return res
}