import React, {MouseEventHandler} from 'react';
import './index.less';
import {Button, Tooltip} from "antd";

const LinkPropsCell = (handler: MouseEventHandler<HTMLElement>, text: string | React.ReactNode, tooltip?: string) => {
    return (
        <Tooltip title={tooltip ? tooltip : ''}>
            <Button className="LinkPropsCell" onClick={handler} type={'link'}>{text}</Button>
        </Tooltip>

    )
};
export default LinkPropsCell;
