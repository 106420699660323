import {THandbookOrganizationAction} from "models/handbook";
import {Nullable} from "models/common";

class SendOrganizationFormDto {
    "bik": Nullable<string>
    "correspondentAccount": Nullable<string>
    "fullName": string
    "hidden"?: boolean
    "inn": string
    "kpp": Nullable<string>
    "legalAddress": string
    "mailingAddress": Nullable<string>
    "name": string
    "ogrnOgrnip": string
    "okato": Nullable<string>
    "okpo": Nullable<string>
    "okved": Nullable<string>
    "paymentAccount": Nullable<string>
    "phone": Nullable<string>
    "site": Nullable<string>
    "isContentCustomer": boolean
    "regionId": Nullable<string>
    "legalFormId": string

    constructor(data: THandbookOrganizationAction) {
        this.bik = data.bik || null
        this.correspondentAccount = data.correspondentAccount || null
        this.fullName = data.fullName
        this.hidden = data.hidden
        this.inn = data.inn
        this.kpp = data.kpp || null
        this.legalAddress = data.legalAddress
        this.mailingAddress = data.mailingAddress || null
        this.name = data.name
        this.ogrnOgrnip = data.ogrnOgrnip
        this.okato = data.okato || null
        this.okpo = data.okpo || null
        this.okved = data.okved || null
        this.paymentAccount = data.paymentAccount || null
        this.phone = data.phone || null
        this.site = data.site || null
        this.isContentCustomer = data.isContentCustomer
        this.regionId = data.region ?  data.region?.value : null
        this.legalFormId = data.legalForm ? data.legalForm?.value : ''
    }
}

export default SendOrganizationFormDto;