import {delay, put, select} from "redux-saga/effects";
import {GetProjectsStartAction, SetProjectFilter} from "store/projects/actions";
import {TRole} from "models/user";
import {selectRole} from "store/auth/selectors";


export function* setProjectsFilter({filter}: ReturnType<typeof SetProjectFilter>) {
    try {
        const role: TRole = yield select(selectRole);
        if (!role) {
            yield delay(500)
            yield put(SetProjectFilter(filter));
        }
        yield put(GetProjectsStartAction())
    } catch (error: any) {
        console.log(error)
    }
}