export const ContentActionTypes = {
	GET_PROJECT_START: '[CONTENT] GET_PROJECT_START',
	GET_PROJECT_SUCCESS: '[CONTENT] GET_PROJECT_SUCCESS',
	GET_PROJECT_ERROR: '[CONTENT] GET_PROJECT_ERROR',

	GET_CONTENT_START: '[CONTENT] GET_CONTENT_START',
	GET_CONTENT_SUCCESS: '[CONTENT] GET_CONTENT_SUCCESS',
	GET_CONTENT_ERROR: '[CONTENT] GET_CONTENT_ERROR',

	GET_CONTENT_MODULE_SECTIONS_START: '[CONTENT] GET_CONTENT_MODULE_SECTIONS_START',
	GET_CONTENT_MODULE_SECTIONS_SUCCESS: '[CONTENT] GET_CONTENT_MODULE_SECTIONS_SUCCESS',
	GET_CONTENT_MODULE_SECTIONS_ERROR: '[CONTENT] GET_CONTENT_MODULE_SECTIONS_ERROR',

	SET_CONTENT_FILTER: '[CONTENT] SET_CONTENT_FILTER',
	SET_SORTING_FILTER: '[CONTENT] SET_SORTING_FILTER',
} as const
