import {TNationalTag} from "../../models/handbook";
import {NationalProjectTag1Types} from "./action-types";

/**
 * Получение всех NationalProjectTag1
 */
export const GetAllNationalProjectTag1StartAction = () => ({
	type: NationalProjectTag1Types.GET_ALL_NATIONAL_PROJECT_TAG1_START,
})
export const GetAllNationalProjectTag1SuccessAction = (data: TNationalTag[]) => ({
	type: NationalProjectTag1Types.GET_ALL_NATIONAL_PROJECT_TAG1_SUCCESS,
	data
})
export const GetAllNationalProjectTag1ErrorAction = (error: any) => ({
	type: NationalProjectTag1Types.GET_ALL_NATIONAL_PROJECT_TAG1_ERROR,
	error,
})
