import React, { FC } from 'react';
import { Button } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { Form, Formik } from 'formik';
import {
	SwitchComponent,
} from 'components/formic-control';
import styles from './styles.module.less';
import ScheduleDates from './schedule-dates';
import Dates from './dates';
import { ModalEvent } from 'shared/ui/modal';
import { validation } from './validation';
import { TaskDuplicationSchedulerController } from 'features/сonfiguring-task-autocreation/api';
import { ScheduleCreate, ScheduleUpdate } from '../../types';
import { IOptions } from 'models/feels';
import Tasks from './tasks';
import Duplicate from './duplicate';
import Schedule from './schedule';


export type TForm = {
	mode: 'create' | 'update',
	duplicationTerms: '1' | '2',
	scheduleDates: string[] | Date[]
	dayOfWeek: IOptions | null
	daysToDeadline: string | null
	tasks: IOptions[]
	schedule: IOptions[]
} & Omit<ScheduleCreate | ScheduleUpdate, 'scheduleDates' | 'dayOfWeek' | 'daysToDeadline' | 'taskIds' | 'scheduleIds'>;

const ModalScheduler: FC<{mode: 'create' | 'update'}> = ({mode}) => {
	const queryClient = useQueryClient();
	const {mutate: create, isLoading: isLoadingCreate} = useMutation({
		mutationFn: (data: ScheduleCreate) => TaskDuplicationSchedulerController.bulkCreation(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries([TaskDuplicationSchedulerController.search.key])
			ModalEvent.close();
		},
	})
	const {mutate: update, isLoading: isLoadingUpdate} = useMutation({
		mutationFn: (data: ScheduleUpdate) => TaskDuplicationSchedulerController.bulkUpdate(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries([TaskDuplicationSchedulerController.search.key])
			ModalEvent.close();
		},
	})

	const initValues: TForm = {
		firstTaskDate: null,
		lastTaskDate: null,
		dayOfWeek: null,
		scheduleDates: [],
		daysToDeadline: null,
		enabled: true,
		tasks: [],
		schedule: [],
		duplicationTerms: '1',
		mode,
	}

	const close = () => {
		ModalEvent.close();
	}
	const onSubmit = (data: TForm) => {
		if (mode === 'create') {
			const values: ScheduleCreate = {
				scheduleDates: data.scheduleDates as string[],
				enabled: data.enabled,
				dayOfWeek: data.dayOfWeek?.value ? data.dayOfWeek?.value as ScheduleCreate['dayOfWeek'] : null,
				firstTaskDate: data.firstTaskDate,
				lastTaskDate: data.lastTaskDate,
				taskIds: data.tasks.map((el) => el.value),
				daysToDeadline: !!data.daysToDeadline ? Number(data.daysToDeadline) : 0
			}
			create(values);
		} else {
			const values: ScheduleUpdate = {
				scheduleDates: data.scheduleDates as string[],
				enabled: data.enabled,
				dayOfWeek: data.dayOfWeek?.value ? data.dayOfWeek?.value as ScheduleUpdate['dayOfWeek'] : null,
				firstTaskDate: data.firstTaskDate,
				lastTaskDate: data.lastTaskDate,
				scheduleIds: data.schedule.map((el) => el.value),
				daysToDeadline: !!data.daysToDeadline ? Number(data.daysToDeadline) : 0
			}
			update(values);
		}

	}

	return (
		<Formik
			enableReinitialize
			initialValues={initValues}
			validationSchema={validation}
			onSubmit={onSubmit}
			validateOnMount={true}
			validateOnChange={true}
		>
			{(formik) => {
				const values = formik.values;
				const enabled = values.enabled;
				return (
					<Form className={styles.container}>
						<div className={styles.header}>
							<span className={styles.title}>Настройка расписания автосоздания задач</span>
							<SwitchComponent
								name={'enabled'}
								label={enabled ? 'Включена' : 'Выключена'}
								handler={(value: boolean) => {
									if (!value) formik.setValues({ ...initValues, enabled: value })
								}}
							/>
						</div>
						<hr/>
						{mode === 'create' && <Tasks/>}
						{mode === 'update' && <Schedule/>}
						<Duplicate/>
						<hr/>
						<ScheduleDates/>
						<hr/>
						<Dates/>
						<div className={styles.buttons}>
							<Button type={'default'} onClick={close}>Отменить</Button>
							<Button
								htmlType={'submit'}
								disabled={!formik.isValid}
								type={'primary'}
								loading={isLoadingCreate || isLoadingUpdate}
							>
								Сохранить
							</Button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
};

export default ModalScheduler;