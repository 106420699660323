import {StepFirstDto} from "./task-form_StepFirst.dto";
import {StepSecondDto} from "./task-form_StepSecond.dto";
import {StepThirdDto} from "./task-form_StepThird.dto";
import {TPostingTask, TTaskProject, TTaskResponse} from "models/task";
import {IOptions} from "models/feels";
import {T_ID_NAME_CODE} from "models/common";
import {Sprint} from "models/sprints";

export type TDefault<T> = { value: T, isDisabled?: boolean }

export class TaskFormDto {
    stepFirst: StepFirstDto
    stepSecond: StepSecondDto
    stepThird: StepThirdDto

    isEdit: boolean

    constructor(task?: TTaskResponse | null) {
        this.isEdit= !!task?.id

        this.stepFirst = new StepFirstDto(task)
        this.stepSecond = new StepSecondDto(task)
        this.stepThird = new StepThirdDto(task)
    }
}

export class OptionsTasksDto implements IOptions {
    value
    label

    constructor(model: TPostingTask | T_ID_NAME_CODE | Sprint | TTaskProject) {
        this.value = model.id
        this.label = model.name
    }
}