import React, {FC, useState} from 'react';
import {Select, Spin} from "antd";
import Checkbox, {CheckboxChangeEvent} from "antd/es/checkbox";
import {useQuery} from "react-query";
import {SessionApi} from 'entities/session'
import styles from './styles.module.less'

type TSession = {
    setState: (val: string[])=>void,
    state: string[]
    disabled: boolean
}

const SessionSelect:FC<TSession> = ({setState,state, disabled}) => {
    const [sessionIds, setSessionIds] = useState<string[]>([])

    const { data: sessions = [], isLoading } = useQuery({
        queryKey: [SessionApi.SessionController.sessionsOptionsKey],
        queryFn: () => SessionApi.SessionController.getSessionsOptions(),
        onSuccess: (data) => {
            const ids = data?.map(it=> it.value) || []
            setSessionIds(ids)
        }
    })
    const handlerSelect = (values: string[])=> {
        setState(values)
    }
    const onClear = () => {
        setState([])
    }
    const handlerCheckbox = (e: CheckboxChangeEvent) => {
        const checked: boolean = e.target.checked
        if (checked) setState(sessionIds)
        else setState([])
    }

    return (
        <Spin spinning={isLoading}>
            <div className={styles.wrapper}>
                <p className={styles.text}>Сессия</p>

                <Select
                    disabled={disabled}
                    allowClear
                    getPopupContainer={trigger => trigger.parentNode}
                    mode={"multiple"}
                    value={state}
                    onClear={onClear}
                    onChange={handlerSelect}
                    options={sessions ? sessions : []}
                    placeholder={'Выберите сессию'}
                    maxTagCount={"responsive"}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }
                    dropdownRender ={(menu)=>(
                        <>
                            {menu}
                            <div className={styles.select_checkbox}>
                                <Checkbox
                                    onChange={handlerCheckbox}
                                    checked={sessionIds?.length === state?.length}
                                >
                                    Выбрать все
                                </Checkbox>
                            </div>

                        </>
                    )}
                />
            </div>
        </Spin>
    );
};

export default SessionSelect;