import React, {useCallback, useMemo, useState} from "react";

export function useBulkActions () {
    const [rowsIds,setRowsIds] = useState([])
    const [bulkAction,setBulkAction] = useState('')

    const rowSelection = useMemo(()=>{
        return {
            selectedRowKeys: rowsIds,
            onChange: (selectedRowKeys: React.Key[], _selectedRows: any[]) => {
                setRowsIds(selectedRowKeys as string[] as [])
            },
        }
    },[rowsIds])

    const handlerBulkAction = useCallback( (val: any) => {
        setBulkAction(val)
    } ,[])

    const handlerRowIds = useCallback( (val: any) => {
        setRowsIds(val)
    } ,[])

    return {
        bulkAction,
        rowsIds,
        handlerBulkAction,
        rowSelection,
        handlerRowIds
    }
}