export const TasksGantAndKanbanActionTypes = {
    GET_TASKS_GANT_START: '[TASKS_GANT_KANBAN] GET_TASKS_GANT_START',
    GET_TASKS_GANT_SUCCESS: '[TASKS_GANT_KANBAN] GET_TASKS_GANT_SUCCESS',
    GET_TASKS_GANT_ERROR: '[TASKS_GANT_KANBAN] GET_TASKS_GANT_ERROR',

    GET_TASKS_KANBAN_START: '[TASKS_GANT_KANBAN] GET_TASKS_KANBAN_START',
    GET_TASKS_KANBAN_SUCCESS: '[TASKS_GANT_KANBAN] GET_TASKS_KANBAN_SUCCESS',
    GET_TASKS_KANBAN_ERROR: '[TASKS_GANT_KANBAN] GET_TASKS_KANBAN_ERROR',

    SET_TASKS_KANBAN_FILTER: '[TASKS_GANT_KANBAN] SET_TASKS_KANBAN_FILTER',
    CLEAR_TASKS_KANBAN_FILTER: '[TASKS_GANT_KANBAN] CLEAR_TASKS_KANBAN_FILTER',

    SET_CARD_IDS: '[TASKS_GANT_KANBAN] SET_CARD_IDS',
    CLEAR_CARD_IDS: '[TASKS_GANT_KANBAN] CLEAR_CARD_IDS',
    CLEAR_BULK_ACTIONS: '[TASKS_GANT_KANBAN] CLEAR_BULK_ACTIONS',

} as const;