import {put, select} from "redux-saga/effects";

import TasksService from "../../../services/tasksService";
import CreateErrorMessage from "../../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../../store/notification/actions";
import {AxiosResponse} from "axios";
import {
    GetTasksKanbanErrorAction,
    GetTasksKanbanStartAction,
    GetTasksKanbanSuccessAction
} from "../../../store/tasks/actions/gant-kanban";
import {TTasksKanban, TTasksKanbanFilter} from "../../../models/task/task.kanban";
import {selectTasksFilter} from "../../../store/tasks/selectors";


export function* getTasksKanban({projectId}: ReturnType<typeof GetTasksKanbanStartAction>) {
    try {
        const filter: TTasksKanbanFilter = yield select(selectTasksFilter);
        const {data}: AxiosResponse<TTasksKanban> = yield TasksService.getTasksKanban(projectId, filter);
        yield put(GetTasksKanbanSuccessAction(data))
    } catch (error: any) {
        yield put(GetTasksKanbanErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить задачи'))
    }
}