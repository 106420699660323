import { FunctionalityInfoType } from '../types'

export const reportingReportsSchedulers: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка расписаний авторассылки отчетов с поиском, фильтрацией, сортировкой, пагинацией',
		LevelGroupData: [
			{
				name: 'Поиск расписаний',
				text: 'Введите название, код отчета либо ФИО пользователя для поиска расписаний',
				screenshots: ['/images/screenshots/reporting-reports-schedulers/search.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Фильтрация расписаний',
				text: 'Выберите значение из выпадающего списка для фильтрации расписаний',
				screenshots: ['/images/screenshots/reporting-reports-schedulers/filters.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Сортировка отображения таблицы',
				text: 'Нажимайте на стрелки для сортировки отображения таблицы расписаний авторассылки отчетов',
				screenshots: ['/images/screenshots/reporting-reports-schedulers/table-sorting.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Настройка количества расписаний, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице расписаний:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр получателей сформированных отчетов',
		LevelGroupData: [
			{
				name: 'Просмотр полного списка получателей сформированных отчетов',
				text: 'Нажмите для просмотра списка получателей сформированных отчетов',
				screenshots: ['/images/screenshots/reporting-reports-schedulers/button.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Работа с расписаниями формирования отчетов',
		LevelGroupData: [
			{
				name: 'Создание расписания формирования отчетов',
				points: [
					'Нажмите "Создать расписание формирования отчетов" для перехода в окно настройки автоформирования и отправки отчетов',
					'Заполните поля формы',
					'Сохраните форму',
				],
				screenshots: ['/images/screenshots/reporting-reports-schedulers/create.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Редактирование расписания формирования отчетов',
				points: [
					'Нажмите на кнопку для перехода в окно настройки автоформирования и отправки отчетов',
					'Редактируйте поля формы',
					'Сохраните форму',
				],
				screenshots: ['/images/screenshots/reporting-reports-schedulers/edit.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Удаление расписания формирования отчетов',
				text: 'Нажмите для удаления расписания формирования отчета',
				icons: ['delete-red'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
]
