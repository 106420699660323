import React from 'react';
import {Teg} from "./teg";

const TagsContent = () => (
    <>
        <Teg name='contentFilterZeroLvlTagIds' level={0}/>
        <Teg name='contentFilterFirstLvlTagIds' level={1}/>
        <Teg name='contentFilterSecondLvlTagIds' level={2}/>
        <Teg name='contentFilterThirdLvlTagIds' level={3}/>
    </>
);

export default TagsContent;