import React, {FC, SyntheticEvent} from 'react';
import {EditOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import { TaskTypes } from 'entities/task';
import classNames from "classnames";

type Props = {
    actionInfo: Omit<TaskTypes.TStatus, 'hidden'>
    handler: (event: SyntheticEvent) => void
}
const EditComponent: FC<Props> = ({actionInfo, handler}) => {

    return (
        <Tooltip title={"Редактировать"}>
            <div
                data-btn="edit"
                className={classNames('item edit', {disabled: actionInfo === 'disabled'})}
                onClick={(event) => actionInfo !== 'disabled' && handler(event)}
            >
                <EditOutlined />
            </div>
        </Tooltip>
    );
}

export default EditComponent;