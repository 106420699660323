import {FamousPeople} from "../types";
import {useQuery, UseQueryResult} from "react-query";
import {useMemo} from "react";
import {getFamousPeople} from "../api";
import {IOptions} from "models/feels";

type onSuccessType = (data: FamousPeople[]) => void
export function useGetFamousPeople(rankId?: string, onSuccess?: onSuccessType): UseQueryResult<FamousPeople[]> {
    return useQuery({
        queryKey: [getFamousPeople.key, rankId],
        queryFn: () => getFamousPeople.fetch(rankId),
        onSuccess,
    });
}
export function useGetFamousPeopleOptions(rankId?: string, onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetFamousPeople(rankId, onSuccess);
    const options = useMemo(() => data.map((el) => ({value: el?.id, label: el?.fullName} as IOptions)), [data]);
    return { data: options, ...rest };
}