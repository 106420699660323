import {eventBus} from "shared/services";

const eventName = 'put-action';

export const subscribePut = (callback: Function) => {
    return eventBus.subscribe(eventName, callback);
};

export const put = (action: () => Object) => {
    eventBus.broadcast(eventName, action);
};