import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetTasksContentStartAction, ToExaminationTaskContentErrorAction,
    ToExaminationTaskContentStartAction,
    ToExaminationTaskContentSuccessAction
} from "store/tasks/actions/content";
import TasksContentService from "services/tasksContentService";


export function* toExaminationContentTask({recordId, taskId}: ReturnType<typeof ToExaminationTaskContentStartAction>) {
    try {
        yield TasksContentService.ToExaminationTaskReport(recordId);

        yield put(ToExaminationTaskContentSuccessAction());
        yield put(NotificationOpen('success', 'Запись отправлена на проверку'));

        if (taskId) yield put(GetTasksContentStartAction(taskId));

    } catch (error: any) {
        yield put(ToExaminationTaskContentErrorAction(error));
        const message = CreateErrorMessage.response(error);
        yield put(NotificationOpen(
            'error',
            'Ошибка',
            message ? message : 'Не удалось отправить на проверку'
        ));
    }
}