import {InferValueTypes} from '../../models/common'
import * as actions from './actions'
import {ErrorActionState, StartActionState, SuccessActionState} from '../../utils/reducerHalper'
import {Logout} from '../auth/reducer'
import {AuthActionTypes} from '../auth/action-types'
import {LegalFormActionTypes} from './action-types'
import {TLegalFormOrganization} from "../../models/handbook";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TLegalFormState = {
	legalFormOrganizations: TLegalFormOrganization[] | null
	isLoading: boolean
	error: any
}

export const initialState: TLegalFormState = {
	legalFormOrganizations: null,
	isLoading: false,
	error: null,
}

export default function reducer(state: TLegalFormState = initialState, action: ActionTypes | Logout): TLegalFormState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return { ...initialState }

		/** START ACTIONS  */
		case LegalFormActionTypes.GET_ALL_LEGAL_FORM_ORGANIZATION_START:
			return StartActionState(state)

		/** SUCCESS ACTIONS  */
		case LegalFormActionTypes.GET_ALL_LEGAL_FORM_ORGANIZATION_SUCCESS:
			return {...SuccessActionState(state), legalFormOrganizations: action.data}

		/** ERROR ACTIONS  */
		case LegalFormActionTypes.GET_ALL_LEGAL_FORM_ORGANIZATION_ERROR:
			return ErrorActionState(state, action.error)
		default:
			return state
	}
}
