import {TTaskRecord, TTaskRecordFilter} from "models/task/task-content";
import {TasksContentActionTypes} from "../action-types/tasks-content";
import {T_ID_NAME} from "models/common";
import {TaskTypes} from "entities/task";

/**
 * Добавление записей для задачи контента
 */
export const AddTaskContentStartAction = (taskId: string) => ({
    type: TasksContentActionTypes.ADD_TASK_STAT,
    taskId,
})
export const AddTaskContentSuccessAction = (recordId: string) => ({
    type: TasksContentActionTypes.ADD_TASK_SUCCESS,
    recordId
})
export const AddTaskContentErrorAction = (error: any) => ({
    type: TasksContentActionTypes.ADD_TASK_ERROR,
    error
})
/**
 * Получение списка записей задачи без пагинации
 */
export const GetTasksContentStartAction = (taskId: string) => ({
    type: TasksContentActionTypes.GET_TASKS_STAT,
    taskId
})
export const GetTasksContentSuccessAction = (tasks: TaskTypes.TaskRecordsResponse<TaskTypes.ContentRecords[]>) => ({
    type: TasksContentActionTypes.GET_TASKS_SUCCESS, tasks
})
export const GetTasksContentErrorAction = (error: any) => ({
    type: TasksContentActionTypes.GET_TASKS_ERROR,
    error
})
/**
 * Обновление записи для задачи контента
 */
export const UpdateTaskContentStartAction = (recordId: string, data: TTaskRecord, taskId: string) => ({
    type: TasksContentActionTypes.UPDATE_TASK_STAT,
    recordId, data, taskId
})
export const UpdateTaskContentSuccessAction = () => ({
    type: TasksContentActionTypes.UPDATE_TASK_SUCCESS
})
export const UpdateTaskContentErrorAction = (error: any) => ({
    type: TasksContentActionTypes.UPDATE_TASK_ERROR,
    error
})

/**
 * Получение списка одобренных записей задач на контент текущего пользователя по проекту
 */
export const GetContentTaskRecordStartAction = (projectId: string) => ({
    type: TasksContentActionTypes.GET_CONTENT_TASK_RECORD_START,
    projectId
})
export const GetContentTaskRecordSuccessAction = (data: T_ID_NAME[]) => ({
    type: TasksContentActionTypes.GET_CONTENT_TASK_RECORD_SUCCESS,
    data
})
export const GetContentTaskRecordErrorAction = (error: any) => ({
    type: TasksContentActionTypes.GET_CONTENT_TASK_RECORD_ERROR,
    error
})

/**
 * Удаление записи задачи контента
 */
export const DeleteTaskContentStartAction = (recordId: string, taskId: string) => ({
    type: TasksContentActionTypes.DELETE_TASK_STAT, recordId, taskId
})
export const DeleteTaskContentSuccessAction = () => ({
    type: TasksContentActionTypes.DELETE_TASK_SUCCESS
})
export const DeleteTaskContentErrorAction = (error: any) => ({
    type: TasksContentActionTypes.DELETE_TASK_ERROR,
    error
})
/**
 * Голосование за запись (голос и коментраий)
 */
export const VoteTaskContentChangeStatus = (data: TaskTypes.NewVote) => ({
    type: TasksContentActionTypes.VOTE_TASK_STATUS,
    data
})
export const VoteTaskContentChangeMultipleStatuses = (data: Record<string, TaskTypes.NewVote>) => ({
    type: TasksContentActionTypes.VOTE_TASK_MULTIPLE_STATUSES,
    data
})
export const VoteTaskContentChange = (id: string) => ({
    type: TasksContentActionTypes.VOTE_TASK_CHANGE,
    id
})
/**
 * Голосование за запись (отправка данных)
 */
export const VoteTaskContentStartAction = (data: TaskTypes.NewVote) => ({
    type: TasksContentActionTypes.VOTE_TASK_START,
    data
})
export const VoteTaskContentSuccessAction = () => ({
    type: TasksContentActionTypes.VOTE_TASK_SUCCESS
})
export const VoteTaskContentErrorAction = (error: any) => ({
    type: TasksContentActionTypes.VOTE_TASK_ERROR,
    error
})
/**
 * Промежуточное Голосование за запись (отправка данных)
 */
export const InterimVoteTaskContentStartAction = (data: TaskTypes.NewVote) => ({
    type: TasksContentActionTypes.INTERIM_VOTE_TASK_START,
    data
})
export const InterimVoteTaskContentSuccessAction = () => ({
    type: TasksContentActionTypes.INTERIM_VOTE_TASK_SUCCESS
})
export const InterimVoteTaskContentErrorAction = (error: any) => ({
    type: TasksContentActionTypes.INTERIM_VOTE_TASK_ERROR,
    error
})
export const SetTaskContentFilter = (filter: TTaskRecordFilter) => ({
    type: TasksContentActionTypes.SET_TASK_FILTER,
    filter
})
export const ToExaminationTaskContentStartAction = (recordId: string, taskId?: string) => ({
    type: TasksContentActionTypes.TO_EXAMINATION_TASK_START,
    recordId, taskId
})
export const ToExaminationTaskContentSuccessAction = () => ({
    type: TasksContentActionTypes.TO_EXAMINATION_TASK_SUCCESS
})
export const ToExaminationTaskContentErrorAction = (error: any) => ({
    type: TasksContentActionTypes.TO_EXAMINATION_TASK_ERROR,
    error
})
export const ResetTasksContentStatusStartAction = (recordId: string) => ({
    type: TasksContentActionTypes.RESET_STATUS_TASK_START, recordId
})
export const ResetTasksContentStatusSuccessAction = (recordId: string) => ({
    type: TasksContentActionTypes.RESET_STATUS_TASK_SUCCESS,
    recordId
})
export const ResetTasksContentStatusErrorAction = (recordId: string, error: any) => ({
    type: TasksContentActionTypes.RESET_STATUS_TASK_ERROR,
    recordId, error
})
export const SetContentBulkAction = (bulkAction: string | null) => ({
    type: TasksContentActionTypes.SET_BULK_ACTION,
    bulkAction
})
export const SetContentRowsIds = (rows: string[]) => ({
    type: TasksContentActionTypes.SET_ROWS_IDS,
    rows
})

export const SendContentBulkVotesStartAction = (votes: TaskTypes.NewVote['vote'][]) => ({
    type: TasksContentActionTypes.SEND_BULK_VOTES_START, votes
})
export const SendContentBulkVotesSuccessAction = () => ({
    type: TasksContentActionTypes.SEND_BULK_VOTES_SUCCESS,
})
export const SendContentBulkVotesErrorAction = (error: any) => ({
    type: TasksContentActionTypes.SEND_BULK_VOTES_ERROR,
    error
})

export const SendContentBulkReviewStartAction = (recordsIds: string[]) => ({
    type: TasksContentActionTypes.SEND_BULK_REVIEW_START, recordsIds
})
export const SendContentBulkReviewSuccessAction = () => ({
    type: TasksContentActionTypes.SEND_BULK_REVIEW_SUCCESS,
})
export const SendContentBulkReviewErrorAction = (error: any) => ({
    type: TasksContentActionTypes.SEND_BULK_REVIEW_ERROR,
    error
})

export const CreateFileArchiveStartAction = (recordsIds: string[]) => ({
    type: TasksContentActionTypes.CREATE_FILE_ARCHIVE_START, recordsIds
})
export const CreateFileArchiveSuccessAction = () => ({
    type: TasksContentActionTypes.CREATE_FILE_ARCHIVE_SUCCESS,
})
export const CreateFileArchiveErrorAction = (error: any) => ({
    type: TasksContentActionTypes.CREATE_FILE_ARCHIVE_ERROR,
    error
})
export const ClearTaskContentFilter = () => ({
    type: TasksContentActionTypes.CLEAR_FILTER
})

/**
 * Получение одной записи задачи
 */
export const GetRecordStartAction = (originalRecordId: string, updatedRecordId?: string) => ({
    type: TasksContentActionTypes.GET_RECORD_START, originalRecordId, updatedRecordId
})
export const GetRecordSuccessAction = (data: TaskTypes.ContentRecords[]) => ({
    type: TasksContentActionTypes.GET_RECORD_SUCCESS, data
})
export const GetRecordErrorAction = (error: any) => ({
    type: TasksContentActionTypes.GET_RECORD_ERROR, error
})