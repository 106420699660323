import {put, select} from "redux-saga/effects";
import {
    DeleteTaskErrorAction,
    DeleteTaskStartAction,
    DeleteTaskSuccessAction,
    GetAllTasksStartAction,
} from "store/tasks/actions/tasks";
import TasksService from "services/tasksService";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {GetTasksGantStartAction, GetTasksKanbanStartAction} from "store/tasks/actions/gant-kanban";
import {selectParams} from "store/selectors";


export function* deleteTasks({taskId}: ReturnType<typeof DeleteTaskStartAction>) {
    try {
        yield TasksService.deleteTask(taskId);
        yield put(DeleteTaskSuccessAction());

        const location = window.location.href.split('/');
        const page = location[3];
        if (page === 'tasks') yield put(GetAllTasksStartAction())
        if (page === 'project') {
            const type = location[4].split('?')[0];
            const { id } = yield select(selectParams)
            if (type === 'tasks' ) {
                yield put(GetTasksKanbanStartAction(id))
            }
            if (type === 'calendar' ) {
                yield put(GetTasksGantStartAction(id))
            }
        }
    } catch (error: any) {
        yield put(DeleteTaskErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить проект'))
    }
}
