import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetProjectsTypesErrorAction,
    GetProjectsTypesStartAction,
    GetProjectsTypesSuccessAction,
} from "../../store/projects/actions";

import Api from "../../entities/project/api/project";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../store/notification/actions";
import {T_ID_NAME_CODE} from "../../models/common";


export function* getProjectTypes(_action: ReturnType<typeof GetProjectsTypesStartAction>) {
    try {
        const {data}: AxiosResponse<T_ID_NAME_CODE> = yield Api.getProjectsTypes();

        yield put(GetProjectsTypesSuccessAction(data));

    } catch (error: any) {
        yield put(GetProjectsTypesErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить проект'))
    }
}
