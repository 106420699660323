import {put, select} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {selectParams} from "store/selectors";
import {
    GetTasksContentStartAction,
    ResetTasksContentStatusErrorAction,
    ResetTasksContentStatusStartAction,
    ResetTasksContentStatusSuccessAction
} from "store/tasks/actions/content";
import TasksContentService from "../../../services/tasksContentService";


export function* resetStatusTaskContent({recordId}: ReturnType<typeof ResetTasksContentStatusStartAction>) {
    try {
        const {id} = yield select(selectParams)
        yield TasksContentService.resetTaskRecordsStatus(recordId);

        yield put(ResetTasksContentStatusSuccessAction(recordId))
        yield put(NotificationOpen('success', 'Статус сброшен'))
        if (id) yield put(GetTasksContentStartAction(id))
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(ResetTasksContentStatusErrorAction(recordId, message))
    }
}