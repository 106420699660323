function getMode(): TMode {
    const url = new URL(window.location.href).hostname;

    if (url === 'localhost') return 'local';
    if (url === 'kontent-dev.iri.center') return 'dev';
    if (url === 'kontent-test.iri.center') return 'test';
    if (url === 'kontent-demo.iri.center') return 'demo';
    return 'prod'
}
function getApiEndpoint(mode: TMode): TEnv["API_ENDPOINT"] {
    if (mode === 'local') return 'https://kontent-dev.iri.center/api';
    return process.env.REACT_APP_API_ENDPOINT || '/api'
}

function getTelegramBot(mode: TMode): TEnv["TELEGRAM_BOT"] {
    if (mode === 'local') return 'content_iri_dev_oxygyn_bot';
    if (mode === 'dev') return 'content_iri_dev_oxygyn_bot';
    if (mode === 'test') return 'content_iri_test_oxygyn_bot';
    if (mode === 'demo') return 'content_iri_demo_oxygyn_bot';
    return process.env.REACT_APP_TELEGRAM_BOT || 'content_iri_prod_bot'
}
function getKeycloackConfig(mode: TMode): TEnv["keycloack"] {
    const KEYCLOAK_CLIENT = {
        'local': 'content-dev',
        'dev': 'content-dev',
        'test': 'content-test',
        'demo': 'content-demo',
    }
    if (mode !== 'prod') {
        return {
            KEYCLOAK_URL: 'https://sso-dev-keycloak-01.iri.center',
            KEYCLOAK_REALM: 'iri',
            KEYCLOAK_CLIENT: KEYCLOAK_CLIENT[mode],
        }
    }
    return {
        KEYCLOAK_URL: 'https://xn--b1ae3a1a.xn--h1aax.xn--p1ai/',
        KEYCLOAK_REALM: 'iri',
        KEYCLOAK_CLIENT: 'content',
    }
}
function getKeycloackRedirectUri(mode: TMode): TEnv["keycloackRedirectUrl"] {
    if (mode === 'prod') return 'https://xn--b1ae3a1a.xn--h1aax.xn--p1ai/'
    else if (mode !== 'local') return 'https://sso-dev-front-01.iri.center/'
    return null
}

function getKeycloackUserList(mode: TMode): TEnv["keycloackUserList"] {
    const baseUrl = 'https://sso-dev-keycloak-01.iri.center/admin/master/console/#/iri/users';

    switch (mode) {
        case 'local':
        case 'dev':
        case 'test':
        case 'demo':
            return baseUrl;
        default:
            return 'https://sso.iri.center/admin/master/console/#/iri/users';
    }
}

export type TMode = 'prod' | 'demo' | 'test' | 'dev' | 'local';
type TEnv = {
    mode: TMode,
    API_ENDPOINT: string,
    REGX: string,
    TELEGRAM_BOT: string
    keycloack: {
        KEYCLOAK_URL: string
        KEYCLOAK_REALM: string
        KEYCLOAK_CLIENT: string
    }
    keycloackRedirectUrl: string | null
    keycloackUserList: string
}
class Env implements TEnv {
    readonly mode;
    readonly API_ENDPOINT;
    readonly REGX;
    readonly TELEGRAM_BOT;
    readonly keycloack;
    readonly keycloackRedirectUrl;
    readonly keycloackUserList;
    constructor() {
        const mode = getMode();

        this.mode = mode;
        this.API_ENDPOINT = getApiEndpoint(mode);
        this.REGX = process.env.REGX || '^(?=.*\\d)(?=.*[!@#\\\\$%^_()&*])(?=.*[a-z])(?=.*[A-Z]).{6,}';
        this.TELEGRAM_BOT = getTelegramBot(mode);
        this.keycloack = getKeycloackConfig(mode);
        this.keycloackRedirectUrl = getKeycloackRedirectUri(mode);
        this.keycloackUserList = getKeycloackUserList(mode);
    }
}
const env = new Env();
export default env;