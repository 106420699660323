import { put } from 'redux-saga/effects'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import { NotificationOpen } from '../../store/notification/actions'
import {
	GetProjectDocumentsWithFilterStartAction,
	SetProjectDocumentsFilter,
} from '../../store/project-documents/actions'

export function* setDocumentsFilter(_action: ReturnType<typeof SetProjectDocumentsFilter>) {
	try {
		yield put(GetProjectDocumentsWithFilterStartAction())
	} catch (error: any) {
		console.log(error)
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
