import {put} from "redux-saga/effects";
import {GetTaskErrorAction, GetTaskStartAction, GetTaskSuccessAction,} from "../../store/tasks/actions/tasks";
import TasksService from "../../services/tasksService";
import {TTaskResponse} from "../../models/task";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../store/notification/actions";
import {AxiosResponse} from "axios";
import {GetFileStartAction} from "../../store/files/actions";


export function* getTask({taskId}: ReturnType<typeof GetTaskStartAction>) {
    try {
        const {data: task}: AxiosResponse<TTaskResponse> = yield TasksService.getTask(taskId);

        yield put(GetTaskSuccessAction(task))

        for (const person of task.coordinators) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.producers) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.analysts) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.financiers) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.contentEditors) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.projectManagers) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.contractors || []) { //TODO пока бэк не готов подставляю пустой массив, убрать после обновления бэка
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        if (task.project.coordinators) {
            for (const person of task.project?.coordinators) {
                if (person?.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
            }
        }
        if (task.project.producers) {
            for (const person of task.project.producers) {
                if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
            }
        }
        if (task.project.contractors) {
            for (const person of task.project.contractors) {
                if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
            }
        }
        if (task.project.analysts) {
            for (const person of task.project?.analysts) {
                if (person?.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
            }
        }
        if (task.project.financiers) {
            for (const person of task.project?.financiers) {
                if (person?.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
            }
        }
        if (task.project.contentEditors) {
            for (const person of task.project?.contentEditors) {
                if (person?.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
            }
        }
        if (task.project.projectManagers) {
            for (const person of task.project?.projectManagers) {
                if (person?.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
            }
        }
        if (task.project.owner) {
            if (task.project.owner.image?.id) yield put(GetFileStartAction(task.project.owner.image.id, task.project.owner.image.type))
        }


    } catch (error: any) {
        yield put(GetTaskErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить задачу'))
    }
}
