import {takeLatest,} from 'redux-saga/effects';

import {ProjectsActionTypes} from "store/projects/action-types";

import {getProjects} from "./getProjects";
import {setProjectsFilter} from "./setProjectFilter";
import {getProject} from "./getProject";
import {createProject} from "./createProject";
import {deleteProjects} from "./deleteProjects";
import {updateProject} from "./updateProject";
import {getActualOrArchivedProjects} from "./getActualOrArchivedProjects";
import {updateProjectStatus} from "./updateProjectStatus";
import {getProjectsReachData} from "./getProjectsReachData";
import {bulkActionsProjects} from "./bulkProjects";
import {getProjectTypes} from "./getProjectTypes";
import {downloadProjectsTemplate} from "./download-template";
import {uploadProjectsExcel} from "./upload-excel";
import downloadProjects from "./download-projects";
import {getProjectsReporting} from "./getProjectsReporting";

export default function* projectsSaga() {
    yield takeLatest(ProjectsActionTypes.GET_PROJECTS_START, getProjects)
    yield takeLatest(ProjectsActionTypes.GET_PROJECT_START, getProject)
    yield takeLatest(ProjectsActionTypes.SET_PROJECTS_FILTER, setProjectsFilter)
    yield takeLatest(ProjectsActionTypes.CREATE_PROJECTS_START, createProject)
    yield takeLatest(ProjectsActionTypes.UPDATE_PROJECT_START, updateProject)
    yield takeLatest(ProjectsActionTypes.DELETE_PROJECT_START, deleteProjects)
    yield takeLatest(ProjectsActionTypes.GET_ACTUAL_OR_ARCHIVED_PROJECTS_START, getActualOrArchivedProjects)
    yield takeLatest(ProjectsActionTypes.UPDATE_PROJECT_STATUS_START, updateProjectStatus)
    yield takeLatest(ProjectsActionTypes.GET_PROJECTS_REACH_DATA_START, getProjectsReachData)
    yield takeLatest(ProjectsActionTypes.BULK_PROJECT_START, bulkActionsProjects)
    yield takeLatest(ProjectsActionTypes.GET_PROJECTS_TYPES_START, getProjectTypes)
    yield takeLatest(ProjectsActionTypes.DOWNLOAD_TEMPLATE_START, downloadProjectsTemplate)
    yield takeLatest(ProjectsActionTypes.UPLOAD_START, uploadProjectsExcel)
    yield takeLatest(ProjectsActionTypes.DOWNLOAD_PROJECT_LIST_START, downloadProjects)
    yield takeLatest(ProjectsActionTypes.GET_PROJECTS_REPORTING_START, getProjectsReporting)
}
