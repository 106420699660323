import React, {useMemo} from 'react';
import classNames from "classnames";
import styles from "./styles.module.less";
//components
import {CheckboxComponent, SelectComponent} from "components/formic-control";
import ReloadList from "components/forms/components/reload-list/reload-list";
//services
//antd
import {Spin} from "antd";
import Checkbox from "antd/es/checkbox";
//any
import {useFormikContext} from "formik";
import {ProjectFormDto} from "../../../lib";
import {TagApi, TagLib} from "entities/tags";
import {Query} from 'processes/query'

function filingName(key: string): string {
    return `stepFifth.${key}.value`
}

function useGetsData() {
    const firstLvlNationalProjectTag = TagLib.useGetProjectTagsOptions({level: 1, isForNationalProject: true, isAssignment: false})
    const secondLvlNationalProjectTag = TagLib.useGetProjectTagsOptions({level: 2, isForNationalProject: true, isAssignment: false})
    const firstLvlNationalAssignmentProjectTag = TagLib.useGetProjectTagsOptions({level: 1, isForNationalProject: true, isAssignment: true})
    const secondLvlNationalAssignmentProjectTag = TagLib.useGetProjectTagsOptions({level: 2, isForNationalProject: true, isAssignment: true})

    return {
        isLoading: {
            first: firstLvlNationalProjectTag.isLoading,
            second: secondLvlNationalProjectTag.isLoading,
            firstAssignment: firstLvlNationalAssignmentProjectTag.isLoading,
            secondAssignment: secondLvlNationalAssignmentProjectTag.isLoading
        } ,
        options: {
            FIRST_LVL_NATIONAL_PROJECT_TAG: firstLvlNationalProjectTag.data || [],
            SECOND_LVL_NATIONAL_PROJECT_TAG: secondLvlNationalProjectTag.data || [],
            FIRST_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG: firstLvlNationalAssignmentProjectTag.data || [],
            SECOND_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG: secondLvlNationalAssignmentProjectTag.data || [],
        }
    }
}
const ThemeNationalProject = () => {
    const {values, setFieldValue} = useFormikContext<ProjectFormDto>();
    const {isLoading: isLoadingNationalTags, options} = useGetsData()
    const form = values.stepFifth;
    const status = values.stepFirst.status.value;

    const firstLevelOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.FIRST_LVL_NATIONAL_PROJECT_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);

    const secondLevelOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.SECOND_LVL_NATIONAL_PROJECT_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);
    const firstLevelAssignmentOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.FIRST_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);

    const secondLevelAssignmentOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.SECOND_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);

    const firstFilteredOptions = options?.FIRST_LVL_NATIONAL_PROJECT_TAG.filter(option => {
        return !firstLevelOptionsDisabled.some(value => value === option.value);
    })
    const secondFilteredOptions = options?.SECOND_LVL_NATIONAL_PROJECT_TAG.filter(option => {
        return !secondLevelOptionsDisabled.some(value => value === option.value);
    })
    const firstAssignmentFilteredOptions = options?.FIRST_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG.filter(option => {
        return !firstLevelOptionsDisabled.some(value => value === option.value);
    })
    const secondAssignmentFilteredOptions = options?.SECOND_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG.filter(option => {
        return !secondLevelOptionsDisabled.some(value => value === option.value);
    })
    const handlerIsNationalProject = (e: any) => {
        if (e.target.checked) {
            setFieldValue('stepFifth.firstNationalLevelTags', {value: form.firstNationalLevelTags.value, isDisabled: false})
            setFieldValue('stepFifth.secondNationalLevelTags', {value: form.secondNationalLevelTags.value  , isDisabled: false})
            setFieldValue('stepFifth.firstLevelNationalAssignmentTags', {value: form.firstLevelNationalAssignmentTags.value  , isDisabled: false})
            setFieldValue('stepFifth.secondLevelNationalAssignmentTags', {value: form.secondLevelNationalAssignmentTags.value  , isDisabled: false})
        } else {
            setFieldValue('stepFifth.firstNationalLevelTags', {value: [], isDisabled: true})
            setFieldValue('stepFifth.secondNationalLevelTags', {value: [], isDisabled: true})
            setFieldValue('stepFifth.firstLevelNationalAssignmentTags', {value: [], isDisabled: true})
            setFieldValue('stepFifth.secondLevelNationalAssignmentTags', {value: [], isDisabled: true})

        }
    }

    const handlerFirstNationalLevelTags = (arr: any) => {
        if (!arr.length) setFieldValue('stepFifth.secondNationalLevelTags', {value: [], isDisabled: form.secondNationalLevelTags.isDisabled})
    }

    const handlerUpdateFirstNationalTags = () => {
        Query.invalidate([TagApi.getProjectTags.key, {level: 1, isForNationalProject: true, isAssignment: false}]);
    }
    const handlerUpdateFirstNationalAssignmentTags = () => {
        Query.invalidate([TagApi.getProjectTags.key, {level: 1, isForNationalProject: true, isAssignment: true}]);
    }
    const handlerUpdateSecondNationalAssignmentTags = () => {
        Query.invalidate([TagApi.getProjectTags.key, {level: 2, isForNationalProject: true, isAssignment: true}]);
    }

    const selectCheckboxAllFirstTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('firstNationalLevelTags'), firstFilteredOptions)
        else {
            setFieldValue('stepFifth.secondNationalLevelTags', {value: [], isDisabled: form.secondNationalLevelTags.isDisabled})
            setFieldValue(filingName('firstNationalLevelTags'),[])
        }
    }

    const selectCheckboxAllSecondTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('secondNationalLevelTags'), secondFilteredOptions)
        else setFieldValue(filingName('secondNationalLevelTags'),[])
    }
    const selectCheckboxAllFirstAssignmentTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('firstLevelNationalAssignmentTags'), firstAssignmentFilteredOptions)
        else setFieldValue(filingName('firstLevelNationalAssignmentTags'),[])
    }
    const selectCheckboxAllSecondAssignmentTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('secondLevelNationalAssignmentTags'), secondAssignmentFilteredOptions)
        else setFieldValue(filingName('secondLevelNationalAssignmentTags'),[])
    }

    return (
        <>
            <div className={classNames('label', styles.isNationalProject_label)}>Национальные проекты</div>
            <div className={styles.isNationalProject}>
                <CheckboxComponent
                    disabled={form?.isNationalProject.isDisabled}
                    name={filingName('isNationalProject')}
                    handler={handlerIsNationalProject}
                    placeholder={`${form?.isNationalProject.value ?
                        'является национальным проектом' :
                        'не является национальным проектом'
                    }`}
                />
            </div>

            {/*----------------*/}
            <div className={classNames('label', styles.firstNationalLevelTags_label)}>
                Тематика национального проекта
            </div>
            <div className={styles.firstNationalLevelTags}>
                <Spin spinning={isLoadingNationalTags.first || !options.FIRST_LVL_NATIONAL_PROJECT_TAG}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateFirstNationalTags}/>
                    <SelectComponent
                        multi={true}
                        options={options.FIRST_LVL_NATIONAL_PROJECT_TAG ? options.FIRST_LVL_NATIONAL_PROJECT_TAG : []}
                        name={filingName('firstNationalLevelTags')}
                        placeholder='Выберите теги нац. проекта 1-го уровня'
                        disabled={form.firstNationalLevelTags.isDisabled}
                        handler={handlerFirstNationalLevelTags}
                        disabledOptionsIds={firstLevelOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxAllFirstTag}
                                disabled={form.firstNationalLevelTags.isDisabled}
                                checked={options.FIRST_LVL_NATIONAL_PROJECT_TAG?.length ? form.firstNationalLevelTags?.value?.length === firstFilteredOptions?.length : false}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
            <div className={styles.secondNationalLevelTags}>
                <Spin spinning={isLoadingNationalTags.second || !options.SECOND_LVL_NATIONAL_PROJECT_TAG}>
                    <SelectComponent
                        multi={true}
                        options={options.SECOND_LVL_NATIONAL_PROJECT_TAG ? options.SECOND_LVL_NATIONAL_PROJECT_TAG : []}
                        disabledOptionsIds={secondLevelOptionsDisabled}
                        name={filingName('secondNationalLevelTags')}
                        placeholder='Выберите теги нац. проекта 2-го уровня'
                        disabled={form.secondNationalLevelTags.isDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxAllSecondTag}
                                disabled={form.secondNationalLevelTags.isDisabled}
                                checked={options.SECOND_LVL_NATIONAL_PROJECT_TAG?.length ? form.secondNationalLevelTags?.value?.length === secondFilteredOptions?.length : false}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />

                </Spin>
            </div>
            <div className={styles.firstNationalLevelAssignmentTags}>
                <Spin spinning={isLoadingNationalTags.firstAssignment || !options.FIRST_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateFirstNationalAssignmentTags}/>
                    <SelectComponent
                        multi={true}
                        options={options.FIRST_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG ? options.FIRST_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG : []}
                        name={filingName('firstLevelNationalAssignmentTags')}
                        placeholder='Выберите теги поручений нац. проекта 1-го уровня'
                        disabled={form.firstLevelNationalAssignmentTags.isDisabled}
                        disabledOptionsIds={firstLevelAssignmentOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxAllFirstAssignmentTag}
                                disabled={form.firstLevelNationalAssignmentTags.isDisabled}
                                checked={options.FIRST_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG?.length ? form.firstLevelNationalAssignmentTags?.value?.length === firstAssignmentFilteredOptions?.length : false}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
            <div className={styles.secondNationalLevelAssignmentTags}>
                <Spin spinning={isLoadingNationalTags.secondAssignment || !options.SECOND_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateSecondNationalAssignmentTags}/>
                    <SelectComponent
                        multi={true}
                        options={options.SECOND_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG ? options.SECOND_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG : []}
                        disabledOptionsIds={secondLevelAssignmentOptionsDisabled}
                        name={filingName('secondLevelNationalAssignmentTags')}
                        placeholder='Выберите теги поручений нац. проекта 2-го уровня'
                        disabled={form.secondLevelNationalAssignmentTags.isDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxAllSecondAssignmentTag}
                                disabled={form.secondLevelNationalAssignmentTags.isDisabled}
                                checked={options.SECOND_LVL_NATIONAL_ASSIGNMENT_PROJECT_TAG?.length ? form.secondLevelNationalAssignmentTags?.value?.length === secondAssignmentFilteredOptions?.length : false}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />

                </Spin>
            </div>
        </>
    );
};

export default ThemeNationalProject;