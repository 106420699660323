import { put, select } from 'redux-saga/effects'
import {
	GetRecordStartAction,
	GetTasksContentStartAction,
	UpdateTaskContentErrorAction,
	UpdateTaskContentStartAction,
	UpdateTaskContentSuccessAction,
} from 'store/tasks/actions/content'
import { NotificationOpen } from 'store/notification/actions'
import TasksContentService from 'services/tasksContentService'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import { TTaskRecordFilter } from 'models/task/task-content'
import { selectContentTasksFilter } from 'store/tasks/selectors/selectors-task-content'

export function* updateTask({ recordId, data, taskId }: ReturnType<typeof UpdateTaskContentStartAction>) {
	try {
		let updatedRecordId; // сохраняем в эту переменную id записи если recordId поменялся
		const filter: TTaskRecordFilter = yield select(selectContentTasksFilter)
		const { searchString, ...rest } = filter.filterData

		const {data: id} = yield TasksContentService.updateTaskRecord(recordId, data)
		updatedRecordId = id
		yield put(UpdateTaskContentSuccessAction())
		yield put(NotificationOpen('success', 'Запись обновлена'))

		if (searchString || Object.keys(rest).length) {
			if (taskId) put(GetTasksContentStartAction(taskId))
		} else {
			yield put(GetRecordStartAction(recordId , updatedRecordId))
		}
	} catch (error: any) {
		yield put(UpdateTaskContentErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить запись'))
	}
}
