export const SprintsActionTypes = {
	GET_SPRINTS_START: '[SPRINTS] GET_SPRINTS_START',
	GET_SPRINTS_SUCCESS: '[SPRINTS] GET_SPRINTS_SUCCESS',
	GET_SPRINTS_ERROR: '[SPRINTS] GET_SPRINTS_ERROR',

	GET_SPRINT_ID_START: '[SPRINTS] GET_SPRINT_ID_START',
	GET_SPRINT_ID_SUCCESS: '[SPRINTS] GET_SPRINT_ID_SUCCESS',
	GET_SPRINT_ID_ERROR: '[SPRINTS] GET_SPRINT_ID_ERROR',

	CREATE_SPRINT_START: '[SPRINTS] CREATE_SPRINT_START',
	CREATE_SPRINT_SUCCESS: '[SPRINTS] CREATE_SPRINT_SUCCESS',
	CREATE_SPRINT_ERROR: '[SPRINTS] CREATE_SPRINT_ERROR',

	UPDATE_SPRINT_ID_START: '[SPRINTS] UPDATE_SPRINT_ID_START',
	UPDATE_SPRINT_ID_SUCCESS: '[SPRINTS] UPDATE_SPRINT_ID_SUCCESS',
	UPDATE_SPRINT_ID_ERROR: '[SPRINTS] UPDATE_SPRINT_ID_ERROR',

	DELETE_SPRINT_ID_START: '[SPRINTS] DELETE_SPRINT_ID_START',
	DELETE_SPRINT_ID_SUCCESS: '[SPRINTS] DELETE_SPRINT_ID_SUCCESS',
	DELETE_SPRINT_ID_ERROR: '[SPRINTS] DELETE_SPRINT_ID_ERROR',

	SET_EDITING_KEY: '[SPRINTS] SET_EDITING_KEY',
	CLEAR_EDITING_KEY: '[SPRINTS] CLEAR_EDITING_KEY',
} as const
