const notifications = [
	{
		id: '59',
		selector: '.ant-table',
		content:
			'В разделе уведомлений можно ознакомиться со списком уведомлений системы, перейти в настройки условий отправки конкретного уведомления посредством редактирования',
		role: [],
	},
]

export default notifications
