import {put} from 'redux-saga/effects'
import {push} from 'connected-react-router';

import {
	AddOrganizationErrorAction,
	AddOrganizationStartAction,
	AddOrganizationSuccessAction,
	GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import HandbookService from "services/handbookService";
import {HANDBOOK} from "routing/names";
import SendOrganizationFormDto
	from "components/forms/handbook-form/organization/send-organization-form.dto";

export function* addOrganization({ data }: ReturnType<typeof AddOrganizationStartAction>) {
	try {
		const sendData = new SendOrganizationFormDto(data) //todo конструктор нужно вызывать до отправки в экшен (Сергей)
		yield HandbookService.addOrganizationRecord(sendData)

		yield put(AddOrganizationSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.organization ))
		yield put(NotificationOpen('success', 'Организация успешно добавлена'))
		yield put(push(HANDBOOK.ORGANIZATION));
	} catch (error: any) {
		yield put(AddOrganizationErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Неверные данные'))
	}
}
