import React, {FC} from "react";
import {ErrorMessage, Field, useFormikContext} from 'formik';
import {FieldAttributes} from "formik/dist/Field";
import {InputNumber} from "antd";
import TextError from "./text-error";
import {IInputNumber} from "models/feels";


const InputNumberComponent: FC<IInputNumber> = ({
    label,
    name,
    placeholder,
    min,
    disabled,
    max,
    errorMessage,
    flor = false
}) => {
    const {setFieldValue, touched, setTouched} = useFormikContext()
    const handleChange = (value: number) => {
        setFieldValue(name, flor ? Math.floor(value) : value);
    }
    return (
        <div className="form-group">
            {label && (
                <label htmlFor={name}>{label}</label>
            )}
            <Field className="form-control" name={name}>
                {(el: FieldAttributes<any>) => {
                    const isError = errorMessage ? errorMessage : el.meta.error
                    const isTouched = el.form.touched[name] ? el.form.touched[name] : el.meta.touched;
                    return (
                        <>
                            <InputNumber
                                disabled={disabled}
                                min={min ? min : 0}
                                max={max && (max <= Number.MAX_SAFE_INTEGER) ? max : Number.MAX_SAFE_INTEGER}
                                id={name}
                                formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                parser={(value: any) => value?.replace(/\$\s?|( *)/g, '')}
                                className={`${isTouched && isError ? 'error' : ''}`}
                                placeholder={placeholder}
                                {...el.field}
                                defaultValue={el.meta.value}
                                onChange={handleChange}
                                onBlur={(e: any) => {
                                    const value = e.target.value;
                                    const parse = parseFloat(value.replace(/,/g, '.').replace(/\s+/g, ''))
                                    if (parse !== el.field.value) handleChange(parse);
                                    setTouched({...touched, [name]: true})
                                }}
                            />
                            {max}
                            {el.meta.touched && el.meta.error && (
                                <ErrorMessage name={name} component={TextError}/>
                            )}
                        </>
                    )
                }}
            </Field>
            {/*// @ts-ignore*/}
            {errorMessage && touched[name] && <div className="invalid-feel">{errorMessage}</div>}
        </div>
    )
}
export default InputNumberComponent
