import React, {FC, SyntheticEvent} from 'react';
import {RetweetOutlined} from "@ant-design/icons";
import {Popconfirm, Spin, Tooltip} from "antd";
import {TaskTypes} from 'entities/task';
import classNames from "classnames";
import {ModalOpenAction} from "store/modals/actions";
import {useDispatch, useSelector} from "react-redux";
import {selectResetStatus} from "store/tasks/selectors/selectors-task";
import {ResetTasksContentStatusSuccessAction} from "store/tasks/actions/content";
import {ResetTasksStatusSuccessAction} from "store/tasks/actions/report";

type Props = {
    recordId: string
    typeTask: TaskTypes.TypesTask
    recordStatus: TaskTypes.TasksRecordStatus
    actionInfo: Omit<TaskTypes.TStatus, 'hidden'>
    onChangeStatus: (event: SyntheticEvent) => void
}
const ChangeStatusComponent: FC<Props> = ({
    actionInfo,
    recordId,
    typeTask,
    recordStatus,
    onChangeStatus
}) => {
    const put = useDispatch();
    const status = useSelector(selectResetStatus(recordId, typeTask))
    const validate = validation(recordStatus, status)
    const handlerOpenModalResetStatus = (error: string) => {
        put(ModalOpenAction('reset-status-task-error', error))
        if(typeTask === 'CONTENT') put(ResetTasksContentStatusSuccessAction(recordId))
        else put(ResetTasksStatusSuccessAction(recordId))
    }

    return (
        <Tooltip title={"Отмена результатов"}>
            <Popconfirm
                className='changeStatus-popover'
                placement="left"
                title={<TitlePopover/>}
                onConfirm={(event: any)=>{
                    onChangeStatus(event)
                }}
                okText="Подтвердить"
                cancelText="Отмена"
                disabled={!(validate === 'ok' && actionInfo !== 'disabled')}
            >
                <Spin spinning={status?.isLoading === undefined ? false : status?.isLoading}>
                    <div
                        data-btn="changeStatus"
                        className={classNames(
                            'item changeStatus',
                            {disabled: actionInfo === 'disabled' || validate === 'disabled'},
                            {error: validate === 'error'}
                        )}
                        onClick={() => {
                            if (validate === 'error') handlerOpenModalResetStatus(status?.error)
                        }}
                    >
                        <RetweetOutlined />
                    </div>
                </Spin>
            </Popconfirm>

        </Tooltip>
    );
}
function validation(
    recordStatus: TaskTypes.TasksRecordStatus,
    send?: {ok?: boolean, error?: string}
): 'error' | 'disabled' | 'ok' {
    let status: 'error' | 'disabled' | 'ok' = 'disabled';
    if (
        recordStatus === 'APPROVED' ||
        recordStatus === 'REJECTED'
    ) {
        if (typeof send === 'undefined' || send.ok) status = 'ok';
        if (send && send.error) status = 'error'
    }
    return status;
}
function TitlePopover() {
    return (
        <span>
            Вы уверены, что хотите удалить все голоса <br/>
            проверяющих пользователей по данной записи <br/>
            для изменения результатов согласования?
        </span>
    )
}

export default ChangeStatusComponent;