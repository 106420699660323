import { AxiosResponse } from 'axios'
import { put } from 'redux-saga/effects'
import { GetSprintIdErrorAction, GetSprintIdStartAction, GetSprintIdSuccessAction } from 'store/sprints/actions'
import SprintService from 'services/sprintService'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import { NotificationOpen } from 'store/notification/actions'
import { SprintTypes } from 'entities/sprints'

export default function* getSprintId({ stageId }: ReturnType<typeof GetSprintIdStartAction>) {
	try {
		const { data }: AxiosResponse<SprintTypes.SprintProject> = yield SprintService.getSprints(stageId)
		yield put(GetSprintIdSuccessAction(data))
	} catch (error: any) {
		yield put(GetSprintIdErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
