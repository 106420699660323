import {AxiosResponse} from "axios";
import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import {T_ID_NAME_CODE_HIDDEN} from "models/common";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetSessionsTypeErrorAction,
    GetSessionsTypeStartAction,
    GetSessionsTypeSuccessAction
} from "../../store/session-type/actions";
import SessionTypeService from "../../services/sessionTypeService";

export default function* getSessionsType(_: ReturnType<typeof GetSessionsTypeStartAction>) {
    try {
        const { data }: AxiosResponse<T_ID_NAME_CODE_HIDDEN[]> = yield SessionTypeService.getSessionsType();
        yield put(GetSessionsTypeSuccessAction(data))
    } catch (error: any) {
        yield put(GetSessionsTypeErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить список типов сессий'))
    }
}