import React from "react";
import './index.less'
import {useSelector} from "react-redux";
import {selectRole} from "store/auth/selectors";
import {selectProject} from "store/projects/selectors";
import Person from "./person";


const Team = () => {
    const project = useSelector(selectProject);
    const role = useSelector(selectRole)
    const showTitle =
        !!project?.projectManagers?.length ||
        !!project?.producers?.length ||
        !!project?.contentEditors?.length ||
        !!project?.coordinators?.length ||
        !!project?.financiers?.length ||
        !!project?.analysts?.length ||
        !!project?.contractors?.length
    return (
        <div className='team'>
            {showTitle && <span className='title'>Команда</span>}
            <div className='team__list'>
                {project?.projectManagers && (project.projectManagers.length > 0) &&
                    <Person
                        person={project.projectManagers[0]}
                        role={'ROLE_PROJECT_MANAGER'}
                        persons={project.projectManagers}
                        contractorOrganization={project.contractorOrganization}
                    />
                }
                {project?.producers && (project.producers.length > 0) &&
                    <Person
                        person={project.producers[0]}
                        role={'ROLE_PRODUCER'}
                        persons={project.producers}
                        contractorOrganization={project.contractorOrganization}
                    />
                }
                {project?.contentEditors && (project.contentEditors.length > 0) &&
                    <Person
                        person={project.contentEditors[0]}
                        role={'ROLE_CONTENT_EDITOR'}
                        persons={project.contentEditors}
                    />
                }
                {project?.coordinators && (project.coordinators.length > 0) &&
                    <Person
                        person={project.coordinators[0]}
                        role={'ROLE_COORDINATOR'}
                        persons={project.coordinators}
                        contractorOrganization={project.contractorOrganization}
                    />
                }
                {project?.lawyers && (project.lawyers.length > 0) &&
                    <Person
                        person={project.lawyers[0]}
                        role={'ROLE_LAWYER'}
                        persons={project.lawyers}
                        contractorOrganization={project.contractorOrganization}
                    />
                }
                {project?.financiers && role !== 'ROLE_CONTRACTOR' && (project.financiers.length > 0) &&
                    <Person
                        person={project.financiers[0]}
                        role={'ROLE_FINANCIER'}
                        persons={project.financiers}
                        contractorOrganization={project.contractorOrganization}
                    />
                }
                {project?.analysts && role !== 'ROLE_CONTRACTOR' && (project.analysts.length > 0) &&
                    <Person
                        person={project.analysts[0]}
                        role={'ROLE_ANALYST'}
                        persons={project.analysts}
                        contractorOrganization={project.contractorOrganization}
                    />
                }
                {project?.contractors && role !== 'ROLE_CONTRACTOR' && (project.contractors.length > 0) &&
                    <Person
                        person={project.contractors[0]}
                        role={'ROLE_CONTRACTOR'}
                        persons={project?.contractors}
                        contractorOrganization={project?.contractorOrganization}
                    />
                }
            </div>
        </div>
    )
}

export default Team;
