import {AxiosResponse} from "axios";
import $api from "../http";

export default class OrganizationsService {
    /**
     * Получение организаций по роле
     */
    static getOrganizationsWithRole(roleId: string): Promise<AxiosResponse<any>> {
        return  $api.get<any>(`/organizations/by-role/${roleId}`)
    }
}