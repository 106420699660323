import {createSelector} from 'reselect';
import {TApplicationState} from "store/aplication-state";
import {IOptions} from "models/feels";
import {selectUser} from "store/auth/selectors";
import {TypesTask} from "models/task/types";


const getTasksState = (state: TApplicationState) => state.tasks;

export const selectTaskListFilter = createSelector(
    getTasksState,
    (state) => state.tasks.filter
)
export const selectTaskList = createSelector(
    getTasksState,
    (state) => state.tasks.taskList
)

export const selectTaskListsOptions = createSelector(
    getTasksState,
    (state) => state.tasks.taskList ? state.tasks.taskList.map((el) => {
        return {value: el.id, label: el.name} as IOptions
    }) : null
)

export const selectTask = createSelector(
    getTasksState,
    (state) => state.tasks.task
)

export const selectTaskData = createSelector(
    selectTask,
    selectUser,
    (task, user) => {
        let isMain = false;
        if(task) {
           const users = [
                ...task?.coordinators,
                ...task?.producers,
                ...task?.analysts,
                ...task?.financiers,
                ...task?.contentEditors,
                ...task?.projectManagers,
            ]
            let i = 0


            do {
                if(users[i].id === user?.id) {
                    isMain = true;
                }
                i++
            }
            while (!isMain && i < users.length )
        }
        return {
            task: task,
            user: user,
            isMain
        }
    }
)

export const selectTaskIsMainPersons = createSelector(
    getTasksState,
    (state) => {
        const producers = state.tasks.task?.producers
        const analysts = state.tasks.task?.analysts
        const financiers = state.tasks.task?.financiers
        const coordinators = state.tasks.task?.coordinators
        const contractors = state.tasks.task?.contractors
        const contentEditors = state.tasks.task?.contentEditors
        const projectManagers = state.tasks.task?.projectManagers
        return {
            producers: producers?.length ? producers: [],
            analysts: analysts?.length ? analysts: [],
            financiers: financiers?.length ? financiers: [],
            coordinators: coordinators?.length ? coordinators: [],
            contractors: contractors?.length ? contractors : [],
            contentEditors: contentEditors?.length ? contentEditors : [],
            projectManagers: projectManagers?.length ? projectManagers : []
        }
    }
)

export const selectTaskNPOptions = createSelector(
    getTasksState,
    (state) => state.tasks.tasksNP ?
        state.tasks.tasksNP.map((el) => ({
            value: el.id,
            label: el.name,
            code: el.type.code === 'REPORT' ? el.reportType?.code : 'CONTENT',
            check: el.archival
        } as IOptions)) :
        null
)
export const selectTaskStatus = createSelector(
    getTasksState,
    (state) => ({
        isLoading: state.tasks.isLoading,
        error: state.tasks.error,
        isLoaded: state.tasks.isLoaded
    })
)
export const selectResetStatus = (id: string, type: TypesTask) => createSelector(
    getTasksState,
    (state) => {
        if (type === 'REPORT') return state.tasksReport.resetStatusRecords[id]
        if (type === 'CONTENT') return state.tasksContent.resetStatusRecords[id]
    }
)

export const selectRowIds =  createSelector(
    getTasksState,
    (state) => state.tasks.rowIds
)
