import React, {FC, SyntheticEvent, useEffect, useRef} from 'react';
import './modal-confirm-delete.less'
import {Button, Modal} from "antd";
import useHandlerModal from "hooks/handlModal";

type ModalDeleteProps = {
    status: boolean
    data: {
        term : string,
        onConfirm: (e: SyntheticEvent) => void
    }
}
const ModalConfirmDelete: FC<ModalDeleteProps> = ({status, data}) => {
    const {isVisible, closeModal } = useHandlerModal(status);
    const refButton = useRef<HTMLButtonElement>(null)
    const {term, onConfirm} = data

    const handlerConfirm = (e: SyntheticEvent) => {
        onConfirm(e)
        closeModal()
    }

    useEffect(()=>{
        if(refButton.current) refButton?.current?.focus()
        // eslint-disable-next-line
    },[refButton.current])

    return (
        <Modal
            className='modal-confirm-delete'
            title="Подтвердите действие"
            visible={isVisible}
            onCancel={closeModal}
            footer={(
                <>
                    <Button type={"default"} onClick={closeModal}>Отменить</Button>
                    <Button type={"primary"} onClick={handlerConfirm} ref={refButton}>Подтвердить</Button>
                </>
            )}
            maskClosable={true}
        >
            <div className='modal-confirm-delete__content'>
                <span>{`Вы уверены, что хотите удалить ${term}?`}</span>
            </div>
        </Modal>
    )
};
export default ModalConfirmDelete;
