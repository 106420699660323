import { FunctionalityInfoType } from '../types'

export const optionsHandbook: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка справочников с поиском, фильтрацией, сортировкой, пагинацией',
		LevelGroupData: [
			{
				name: 'Переход на другую вкладку',
				text: 'Нажмите для перехода на другую вкладку',
				important: ['! Вы можете свернуть / развернуть список справочников по стрелке в верхнем правом углу'],
				screenshots: ['/images/screenshots/options-handbook/tabs.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Поиск данных справочников',
				text: 'Для поиска:',
				points: ['Нажмите "Поиск"', 'Введите текст', 'Нажмите на кнопку поиска'],
				screenshots: ['/images/screenshots/options-handbook/search.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Фильтрация данных справочников',
				text: 'Выберите значения для фильтрации списка',
				screenshots: ['/images/screenshots/options-handbook/filter.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Сортировка отображения таблицы данных справочников',
				text: 'Нажимайте на стрелки для сортировки отображения таблицы данных справочников',
				screenshots: ['/images/screenshots/options-handbook/sorting.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Настройка количества данных, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице данных справочников:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Добавление данных справочников',
		LevelGroupData: [
			{
				name: 'Добавление данных справочников через загрузку шаблона данных',
				points: [
					'Нажмите "Выгрузить шаблон" для скачивания шаблона',
					'Заполните шаблон',
					'Нажмите "Загрузить данные" для загрузки данных справочников',
				],
				screenshots: ['/images/screenshots/import-template.png', '/images/screenshots/export-template.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Добавление данных справочников',
				text: 'Выберите значения для фильтрации списка',
				points: ['Нажмите "Добавить новые данные"', 'Заполните поля формы', 'Нажмите на галочку для сохранения формы'],
				screenshots: ['/images/screenshots/options-handbook/add.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных справочников',
		LevelGroupData: [
			{
				name: 'Массовые действия над справочниками',
				text: 'Для массового выполнения действий:',
				points: [
					'Нажмите "Выберите массовое действие"',
					'Выберите массовое действие из выпадающего списка',
					'Выберите данные',
					'Нажмите "Применить"',
				],
				screenshots: ['/images/screenshots/bulk-actions.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Редактирование данных справочников',
				points: [
					'Нажмите на кнопку для перехода в режим редактирования',
					'Заполните поля формы',
					'Нажмите на галочку для сохранения формы',
				],
				icons: ['edit-blue'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Удаление данных справочников',
				text: 'Нажмите для удаления данных справочников',
				icons: ['delete-red'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
