import React, {FC} from "react";
import {ErrorMessage, Field} from "formik";
import TextError from "./text-error";
import {FieldAttributes} from "formik/dist/Field";
import {Input} from "antd";
import {ITextarea} from "../../models/feels";
const { TextArea } = Input;

const TextareaComponent: FC<ITextarea> = (props) => {
    const {label, name, rows, ...rest} = props;
    return (
        <div className='form-group'>
            {label && (
                <label htmlFor={name}>{label}</label>
            )}
            <Field className="form-control" name={name}>
                {(el: FieldAttributes<any>) => (
                    <>
                        <TextArea
                            rows={rows ? rows : 4}
                            id={name}
                            {...rest}
                            {...el.field}/>
                        {el.meta.touched && el.meta.error && (
                            <ErrorMessage name={name} component={TextError}/>
                        )}
                    </>
                )}
            </Field>
        </div>
    )
}
export default TextareaComponent