import {z} from 'zod';
import {FilterRequest, Id_Name_Code_Hidden_Schema, SearchResponse} from 'shared/types/common';

export const FamousPeopleSchema =  z.object({
    id: z.string(),
    fullName: z.string(),
    code: z.string(),
    hidden: z.boolean(),
    ranks: z.array(Id_Name_Code_Hidden_Schema),
})

export type FamousPeople = z.infer<typeof FamousPeopleSchema>;

export const FamousPeopleShortSchema =  z.object({
    rank: Id_Name_Code_Hidden_Schema,
    famousPerson: z.object({
        id: z.string(),
        fullName: z.string(),
        code: z.string(),
        hidden: z.boolean(),
    }),
})

export type FamousPeopleShort = z.infer<typeof FamousPeopleShortSchema>;

export type FamousPeopleRequest = {
    fullName: string,
    rankIds: string[]
    hidden: boolean
}

export type FilterData = {
    searchString?: string;
    hidden?: boolean;
};

export type FamousPeopleResponse = SearchResponse<FamousPeople>;
export type Filter = FilterRequest<FilterData>
