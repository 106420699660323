import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    AddContentDirectionErrorAction,
    AddContentDirectionStartAction,
    AddContentDirectionSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import HandbookService from "services/handbookService";
import {TStatusesHandbookFeels} from "models/handbook";

export function* addContentDirection({ data}: ReturnType<typeof AddContentDirectionStartAction>) {
    try {
        yield HandbookService.addContentDirectionRecord(data)
        yield put(AddContentDirectionSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.contentDirection))

        yield put(NotificationOpen('success', 'Новый тип направлений контента добавлен'))
    } catch (error: any) {
        yield put(AddContentDirectionErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить тип направлений контента'))
    }
}
