import * as Yup from "yup";
import {getValue, statusIsNullOrDraftOrReadyToBeActual, ValidateDates, validateIsAfter} from "./utils";
import {StepSecondDto} from "../dto/project-form_StepSecond.dto";

export const validationStepSecond = Yup.object({
    contentThematics: Yup.mixed()
        .test(
            'contentThematics',
            'Поле «Тематические линии» обязательно для заполнения',
            function ({value}: StepSecondDto["contentThematics"], context) {
                if (!value.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
                let isValid = false;
                if (value.length) {
                    isValid = true;
                    let length = value.length;
                    do {
                        if (!value[length - 1].value) isValid = false;
                        length--;
                    } while (isValid && length !== 0);
                }
                return isValid
        }),
    contentDirection: Yup.mixed()
        .test(
            'contentDirection',
            'Поле «Направление контента» обязательно для заполнения',
            function ({value}: StepSecondDto["contentDirection"], context) {
                if (!value && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
                else return !!value
            }),
    contentFormats: Yup.mixed()
        .test(
            'contentFormats',
            'Поле «Формат контента» заполнено некорректно. Поле «Формат контента» обязательно к заполнению',
            function ({value}: StepSecondDto["contentFormats"], context) {
                if (!value.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
                let isValid = false;
                if (value.length) {

                    isValid = true;
                    let length = value.length;
                    do {
                        if (!value[length - 1].type) isValid = false;
                        if (!value[length - 1].submissionForms?.length) isValid = false;
                        if (!value[length - 1].contentCount) isValid = false;
                        if (!value[length - 1].isContent) isValid = true
                        length--;
                    } while (isValid && length !== 0);
                }
                return isValid
            }),
    firstContentCreationDate: Yup.mixed()
        .test(
            'firstContentCreationDate',
            'Поле «Создание первой единицы контента» заполнено некорректно',
            function ({value}, context) {
                if (!value && statusIsNullOrDraftOrReadyToBeActual(context)) return true;

                const form = getValue(context);
                const firstContentCreationDate = value;

                const validateDefault = ValidateDates({
                    value: firstContentCreationDate,
                    form: form,
                    context,
                    label: 'Создание первой единицы контента',
                    path: 'stepSecond.firstContentCreationDate'
                })

                if (typeof validateDefault !== "boolean") return validateDefault;

                const allContentCreationDate = form.stepSecond.allContentCreationDate.value;
                if (!!allContentCreationDate && validateIsAfter(firstContentCreationDate, allContentCreationDate)) {
                    return context.createError({
                        path: 'stepSecond.firstContentCreationDate',
                        message: 'Поле «Создание первой единицы контента» не может быть позже чем дата «Создание всех единиц контента»'
                    })
                }

                const firstContentPostingDate = form.stepSecond.firstContentPostingDate.value;
                if (!!firstContentPostingDate && validateIsAfter(firstContentCreationDate, firstContentPostingDate)) {
                    return context.createError({
                        path: 'stepSecond.firstContentCreationDate',
                        message: 'Поле «Создание первой единицы контента» не может быть позже чем дата «Начало размещения первой единицы»'
                    })
                }

                return !!value;
            }),
    allContentCreationDate: Yup.mixed()
        .test(
            'allContentCreationDate',
            'Поле «Создание всех единиц контента» заполнено некорректно',
            function ({value}, context) {
                if (!value && statusIsNullOrDraftOrReadyToBeActual(context)) return true;

                const values = getValue(context);
                const allContentCreationDate = value;

                const validateDefault = ValidateDates({
                    value: allContentCreationDate,
                    form: values,
                    context,
                    label: 'Создание всех единиц контента',
                    path: 'stepSecond.allContentCreationDate'
                })
                if (typeof validateDefault !== "boolean") return validateDefault;

                const allContentReachFixDate = values.stepSecond.allContentReachFixDate.value;
                if (!!allContentReachFixDate && validateIsAfter(allContentCreationDate, allContentReachFixDate)) {
                    return context.createError({
                        path: 'stepSecond.allContentCreationDate',
                        message: 'Поле «Создание всех единиц контента» не может быть позже чем дата «Дата фиксации охвата аудитории»'
                    })
                }

                return !!value;
            }),
    firstContentPostingDate: Yup.mixed()
        .test(
            'firstContentPostingDate',
            'Поле «Начало размещения первой единицы» заполнено некорректно',
            function ({value}, context) {
                if (!value && statusIsNullOrDraftOrReadyToBeActual(context)) return true;

                const form = getValue(context);
                const firstContentPostingDate = value;

                const validateDefault = ValidateDates({
                    value: firstContentPostingDate,
                    form: form,
                    context,
                    label: 'Начало размещения первой единицы',
                    path: 'stepSecond.firstContentPostingDate'
                })
                if (typeof validateDefault !== "boolean") return validateDefault;

                const allContentPostingDate = form.stepSecond.allContentPostingDate.value;
                if (!!allContentPostingDate && validateIsAfter(firstContentPostingDate, allContentPostingDate)) {
                    return context.createError({
                        path: 'stepSecond.firstContentPostingDate',
                        message: 'Поле «Начало размещения первой единицы» не может быть позже чем дата «Размещение последней единицы контента»'
                    })
                }
                if (form.stepFirst.projectType.value?.code === 'CONTENT_CREATION_AND_PLACEMENT') {
                    return !!value;
                } else return true
            }),
    allContentPostingDate: Yup.mixed()
        .test(
            'allContentPostingDate',
            'Поле «Размещение последней единицы контента» заполнено некорректно',
            function ({value}, context) {
                if (!value && statusIsNullOrDraftOrReadyToBeActual(context)) return true;

                const form = getValue(context);
                const allContentPostingDate = value;

                const validateDefault = ValidateDates({
                    value: allContentPostingDate,
                    form: form,
                    context,
                    label: 'Размещение последней единицы контента',
                    path: 'stepSecond.allContentPostingDate'
                })
                if (typeof validateDefault !== "boolean") return validateDefault;

                const allContentReachFixDate = form.stepSecond.allContentReachFixDate.value;
                if (!!allContentReachFixDate && validateIsAfter(allContentPostingDate, allContentReachFixDate)) {
                    return context.createError({
                        path: 'stepSecond.allContentPostingDate',
                        message: 'Поле «Размещение последней единицы контента» не может быть позже чем дата «Дата фиксации охвата аудитории»'
                    })
                }
                if (form.stepFirst.projectType.value?.code === 'CONTENT_CREATION_AND_PLACEMENT') {
                    return !!value;
                } else return true
            }
        ),
    allContentReachFixDate: Yup.mixed()
        .test(
            'allContentReachFixDate',
            'Поле «Дата фиксации охвата аудитории» заполнено некорректно',
            function ({value}, context) {
                if (!value && statusIsNullOrDraftOrReadyToBeActual(context)) return true;

                const form = getValue(context);
                const allContentReachFixDate = value;

                const validateDefault = ValidateDates({
                    value: allContentReachFixDate,
                    form: form,
                    context,
                    label: 'Дата фиксации охвата аудитории',
                    path: 'stepSecond.allContentReachFixDate'
                })
                if (typeof validateDefault !== "boolean") return validateDefault;
                else if (form.stepFirst.projectType.value?.code === 'CONTENT_CREATION_AND_PLACEMENT') {
                    return !!allContentReachFixDate;
                } else return true
            }
        ),
})

