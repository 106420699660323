import { ProjectLabel, ProjectLabelSchema } from '../types';
import { axiosRequest } from 'shared/config/axios';
import { IOptions } from 'models/feels';

export const get = {
    key: '/project-labels',
    keyOptions: 'Options: /project-labels',
    fetch: async (params?: {
        labelTypeCode?: ProjectLabel['labelTypeCode'],
        usedByCurrentUserProjects?: boolean
    }) => {
        const {data} = await axiosRequest<ProjectLabel[]>({
            method: 'GET',
            url: '/project-labels',
            params: {...params},
            parser: ProjectLabelSchema.array(),
        });
        return data;
    },
    fetchOptions: async (params?: {
        labelTypeCode?: ProjectLabel['labelTypeCode'],
        usedByCurrentUserProjects?: boolean
    }): Promise<IOptions<ProjectLabel['labelTypeCode']>[]> => {
        const {data} = await axiosRequest<ProjectLabel[]>({
            method: 'GET',
            url: '/project-labels',
            params: {...params},
            parser: ProjectLabelSchema.array(),
        });
        return data.map((el) => ({
            value: el.id,
            label: el.name,
            code: el.labelTypeCode
        } as IOptions<ProjectLabel['labelTypeCode']>));
    },
};
export const create = async (data: { name: string, labelTypeCode?: ProjectLabel['labelTypeCode'] }) => {
    await axiosRequest({
        method: 'POST',
        url: '/project-labels',
        data: data
    })
};

export const update = async ({id, name, labelTypeCode}: {id: string; name: string, labelTypeCode?: ProjectLabel['labelTypeCode']}) => {
    await axiosRequest({
        method: 'PUT',
        url: `/project-labels/${id}`,
        data: {name, labelTypeCode},
    });
}
export const deleteLabels = async (id: string) => {
    await axiosRequest({
        method: 'DELETE',
        url: `/project-labels/${id}`,
    });
}