import { put, select } from 'redux-saga/effects'
import {
	GetProjectContentErrorAction,
	GetProjectContentStartAction,
	GetProjectContentSuccessAction,
} from 'store/content/content/actions'
import { TProjectResponse } from 'models/content'
import { AxiosResponse } from 'axios'
import { ContentService } from 'services/contentService'
import { selectParams } from 'store/selectors'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import { NotificationOpen } from 'store/notification/actions'

export function* getProject(_action: ReturnType<typeof GetProjectContentStartAction>) {
	try {
		const { id } = yield select(selectParams)

		if (id) {
			const { data }: AxiosResponse<TProjectResponse> = yield ContentService.getProject(id)
			yield put(GetProjectContentSuccessAction(data))
		}
	} catch (error: any) {
		yield put(GetProjectContentErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить проекты контента'))
	}
}
