import {T_ID_NAME} from "models/common";
import {IOptions} from "models/feels";
import {TPerson} from "models/user";
import {TaskTypes} from "entities/task";
import ConvertRole from "./convertRole";

export default function transformObjectFeels(arr: T_ID_NAME[] ): IOptions[] {
    return arr.map((el) => {
        return {label: ConvertRole(el.name), value: el.id, code: el.id} as IOptions
    })
}

export function transformObject(obj: T_ID_NAME ): IOptions {
        return {value: obj?.name, label: obj?.id} as IOptions
    }

export function transformObjectForValue(value: string | number ): IOptions {
    return {value: value, label: value} as IOptions
}
export function transformArrayForValue(values: string[] | number[] ): IOptions[] {
    return values.map((el) => ({value: String(el), label: String(el)} as IOptions))
}

export function transformArrayIdNameForValue(values: T_ID_NAME[] ): IOptions[] {
    return values.map((el) => ({value: el?.id, label: el?.name} as IOptions))
}
export function transformPersonForValue(users: TPerson[], ruName?: boolean): IOptions[] {
    return users.map((user) => {
        const firstName = user.firstName ? user.firstName + ' ' : '';
        const lastName = user.lastName ? user.lastName + ' ' : '';
        const middleName = user.middleName ? user.middleName + ' ' : '';
        return {value: user.id, label: `${lastName}${firstName}${middleName}${ruName ? ` - ${user.role?.ruName}` : ''}`} as IOptions
    })
}


export function transformRecordsStatusNameOptions(value: TaskTypes.TasksRecordStatus) {
    switch (value) {
        case "APPROVED":
            return 'Согласована'
        case "CREATED_BY_CUSTOMER":
            return 'Создана заказчиком'
        case "VERIFICATION_REQUIRED":
            return 'На согласовании'
        case "REJECTED":
            return 'Отклонена'
        case "READY_FOR_CUSTOMER_VERIFICATION":
            return 'Готова к проверке заказчиком'
        case "READY_FOR_VERIFICATION":
            return 'Готова к согласованию'
        case "DRAFT":
            return 'Черновик'
        default: return ''
    }
}

