const documents = [
    {
        id: '64d',
        selector: '.ant-table',
        content:
                'Для наполнения проекта  документами нажмите “Добавить файл”. Для скачивания документа нажмите на иконку документа таблицы',
        role: [],
    },
];
export default documents;
