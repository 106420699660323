import {takeEvery, takeLatest, takeLeading} from 'redux-saga/effects';

import {getTasksFields} from "./getTasksFields";
import {createTasks} from "./createTask";
import {deleteTasks} from "./deleteTask";
import {getTasksGant} from "./task-gant-kanban/getTasksGant";
import {getTasksKanban} from "./task-gant-kanban/getTasksKanban";
import {setTaskKanbanFilter} from "./task-gant-kanban/setTaskKanbanFilter";
import {updateStatusTask} from "./updateStatusTask";
import {getAllTasks} from "./getAllTasks";
import {getTask} from "./getTask";

import {getTasks} from "./task-content/get-tasks";
import {updateTask} from "./task-content/update-tasks";
import {deleteTask} from "./task-content/delete-tasks";
import {setTasksContentFilter} from "./task-content/set-filter-tasks";
import {voteTask} from "./task-content/vote-tasks";
import {updateTasks} from "./updateTask";
import {TasksActionTypes} from "../../store/tasks/action-types/tasks";
import {TasksGantAndKanbanActionTypes} from "../../store/tasks/action-types/gant-and-kanban";
import {TasksContentActionTypes} from "../../store/tasks/action-types/tasks-content";
import {TasksReportActionTypes,} from "../../store/tasks/action-types/tasks-report";
import {TasksRecordsActionTypes} from "../../store/tasks/action-types/task-records";
import {addTask} from "./task-content/add-task";
import {getReportTasks} from "./task-report/get-task";
import {addReportTask} from "./task-report/add-task";
import {deleteReportTask} from "./task-report/delete-tasks";
import {setTasksPostingFilter} from "./task-report/set-filter-tasks";
import {updateReportTask} from "./task-report/update-tasks";
import {voteReportTask} from "./task-report/vote-tasks";
import {duplicateTasks} from "./duplicateTasks";
import {getAllTasksNP} from "./getAllTasksNP";
import {toExaminationReportTask} from "./task-report/toExamination-tasks";
import {toExaminationContentTask} from "./task-content/toExamination-tasks";
import {duplicateScheduleTasks} from "./duplicateScheduleTasks";
import {updateDuplicateScheduleTasks} from "./updateDuplicateScheduleTasks";
import {getTasksRecords} from "./task-records/get-task";
import {getContentTaskRecord} from "./task-content/get-content-task-record";
import {resetStatusTask} from "./task-report/reset-status-task";
import {resetStatusTaskContent} from "./task-content/reset-status-task";
import {sendReportBulkVote} from "./task-report/send-bulk-vote";
import {sendContentBulkVote} from "./task-content/send-bulk-vote";
import {sendReportBulkReview} from "./task-report/send-bulk-review";
import {sendContentBulkReview} from "./task-content/send-bulk-review";
import {setTasksRecordsSearchFilter} from "./task-records/set-task-search-filter";
import {getReachStatistic} from "./task-report/reach-statistic";
import {createFileArchive} from "./task-content/create-file-archive";
import {downloadTemplate} from "./task-report/download-template";
import {uploadTemplate} from "./task-report/upload-template";
import exportReachRecords from "./task-report/exportReachRecords";
import {getReportRecord} from "./task-report/get-record";
import {getContentRecord} from "./task-content/get-record";
import {interimVoteReportTask} from "./task-report/interim-vote-tasks";
import {interimVoteTask} from "./task-content/interim-vote-tasks";


export default function* tasksSaga() {
    yield takeEvery(TasksActionTypes.GET_TASKS_FIELDS_STAT, getTasksFields)
    yield takeLatest(TasksActionTypes.CREATE_TASK_START, createTasks)
    yield takeLatest(TasksActionTypes.UPDATE_TASK_START, updateTasks)
    yield takeLeading(TasksActionTypes.GET_TASK_START, getTask)
    yield takeLatest(TasksActionTypes.DELETE_TASK_START, deleteTasks)
    yield takeLatest(TasksActionTypes.UPDATE_STATUS_TASK_START, updateStatusTask)
    yield takeLatest(TasksActionTypes.GET_TASKS_START, getAllTasks)
    yield takeLatest(TasksActionTypes.DUPLICATE_TASKS_START, duplicateTasks)
    yield takeLatest(TasksActionTypes.DUPLICATE_SCHEDULE_TASKS_START, duplicateScheduleTasks)
    yield takeLatest(TasksActionTypes.UPDATE_DUPLICATE_SCHEDULE_TASKS_START, updateDuplicateScheduleTasks)
    yield takeLatest(TasksActionTypes.GET_ALL_TASKS_START, getAllTasksNP)

    yield takeLatest(TasksGantAndKanbanActionTypes.GET_TASKS_GANT_START, getTasksGant)
    yield takeLatest(TasksGantAndKanbanActionTypes.GET_TASKS_KANBAN_START, getTasksKanban)
    yield takeLatest(TasksGantAndKanbanActionTypes.SET_TASKS_KANBAN_FILTER, setTaskKanbanFilter)

    yield takeLatest(TasksContentActionTypes.ADD_TASK_STAT, addTask)
    yield takeLatest(TasksContentActionTypes.GET_TASKS_STAT, getTasks)
    yield takeLatest(TasksContentActionTypes.UPDATE_TASK_STAT, updateTask)
    yield takeLatest(TasksContentActionTypes.GET_RECORD_START, getContentRecord)
    yield takeLatest(TasksContentActionTypes.DELETE_TASK_STAT, deleteTask)
    yield takeLatest(TasksContentActionTypes.VOTE_TASK_START, voteTask)
    yield takeLatest(TasksContentActionTypes.INTERIM_VOTE_TASK_START, interimVoteTask)
    yield takeLatest(TasksContentActionTypes.SET_TASK_FILTER, setTasksContentFilter)
    yield takeLatest(TasksContentActionTypes.TO_EXAMINATION_TASK_START, toExaminationContentTask)
    yield takeLatest(TasksContentActionTypes.GET_CONTENT_TASK_RECORD_START, getContentTaskRecord)
    yield takeLatest(TasksContentActionTypes.RESET_STATUS_TASK_START, resetStatusTaskContent)
    yield takeLatest(TasksContentActionTypes.SEND_BULK_VOTES_START, sendContentBulkVote)
    yield takeLatest(TasksContentActionTypes.SEND_BULK_REVIEW_START, sendContentBulkReview)
    yield takeLatest(TasksContentActionTypes.CREATE_FILE_ARCHIVE_START, createFileArchive)

    yield takeLatest(TasksReportActionTypes.GET_TASKS_START, getReportTasks)
    yield takeLatest(TasksReportActionTypes.ADD_TASK_START, addReportTask)
    yield takeLatest(TasksReportActionTypes.DELETE_TASK_START, deleteReportTask)
    yield takeLatest(TasksReportActionTypes.SET_TASK_FILTER, setTasksPostingFilter)
    yield takeLatest(TasksReportActionTypes.UPDATE_TASK_START, updateReportTask)
    yield takeLatest(TasksReportActionTypes.GET_RECORD_START, getReportRecord)
    yield takeLatest(TasksReportActionTypes.VOTE_TASK_START, voteReportTask)
    yield takeLatest(TasksReportActionTypes.INTERIM_VOTE_TASK_START, interimVoteReportTask)
    yield takeLatest(TasksReportActionTypes.TO_EXAMINATION_TASK_START, toExaminationReportTask)
    yield takeLatest(TasksReportActionTypes.RESET_STATUS_TASK_START, resetStatusTask)
    yield takeLatest(TasksReportActionTypes.SEND_BULK_VOTES_START, sendReportBulkVote)
    yield takeLatest(TasksReportActionTypes.SEND_BULK_REVIEW_START, sendReportBulkReview)
    yield takeLatest(TasksReportActionTypes.GET_REACH_STATISTIC_START, getReachStatistic)
    yield takeLatest(TasksReportActionTypes.DOWNLOAD_TEMPLATE_START, downloadTemplate)
    yield takeLatest(TasksReportActionTypes.UPLOAD_TEMPLATE_START, uploadTemplate)
    yield takeLatest(TasksReportActionTypes.DOWNLOAD_EXCEL_START, exportReachRecords)

    yield takeLatest(TasksRecordsActionTypes.GET_TASKS_STAT, getTasksRecords)
    yield takeLatest(TasksRecordsActionTypes.SET_TASK_SEARCH_FILTER, setTasksRecordsSearchFilter)
}
