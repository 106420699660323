import React from 'react'
import styles from '../styles.module.less'
import { InputComponent } from 'components/formic-control'
import { useFormikContext } from 'formik'
import { TInitValues } from '../modal-content-records'

const NameDownLoadFile = () => {
	const { errors } = useFormikContext<TInitValues>()

	return (
		<div className={styles.item}>
			<span className={styles.label}>Название загруженного файла</span>
			<div className={styles.field}>
				<InputComponent type="text" name={'nameFile'} disabled={true} errorMessage={errors?.name as string} />
			</div>
		</div>
	)
}

export default NameDownLoadFile
