import {takeLatest} from "redux-saga/effects";
import {ActionTypes} from "../store/types";
import {getTypesWorker} from "./get-types";
import {activateFilterWorker} from "./activate-filter/activate-filter";
import {createFilterWorker} from "./create-filter/create-filter";

export function* Saga() {
    yield takeLatest(ActionTypes.GET_TYPES, getTypesWorker)
    yield takeLatest(ActionTypes.CREATE_FILTER, createFilterWorker)
    yield takeLatest(ActionTypes.ACTIVATE_FILTER, activateFilterWorker)
}