import {eventBus} from "shared/services";


const eventName = 'ModalClose';
export const subscribeClose = (callback: Function) => {
	return eventBus.subscribe(eventName, callback);
};

export const close = () => {
	eventBus.broadcast(eventName);
};
