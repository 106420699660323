import React, {FC} from 'react'
import validationSchema from './validationSchema'
import {Form, Formik} from 'formik'
import {Button, Tooltip} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import CreateErrorMessage, {CreateTaskErrorMessage} from 'utils/validation/createErrorMessage'
import StepFirst from "./steps/step-first";
import StepSecond from "./steps/step-second";
import StepThird from "./steps/step-third";
import {TaskFormDto} from "./domain/dto/task-form.dto";
import {selectReportTypeContentReachReport, selectReportTypeProjectEstimateReport} from "store/app/selectors";
import {SendTaskFormDto} from "./send-task-form.dto";
import {CreateTaskStartAction, UpdateTaskStartAction} from "store/tasks/actions/tasks";
import {NotificationOpen} from "store/notification/actions";
import useFeelsApp from "hooks/feelsApp";
import {TStatusesFeels} from "models/projects/projectFeels";
import {ModalOpenAction} from "store/modals/actions";
import { TASKS } from 'routing/names';
import { ModalFormNocSaveProps } from 'components/modals/modal-form-no-save/modal-form-no-save';
import { useHistory } from 'react-router-dom';
import ErrorComponent from 'components/error-boundary';
import {useGetReturnLink} from "shared/lib/hooks/get-return-link";

type TaskFormComponentProps = {
	value: TaskFormDto
	statusCount: number
	handlerStatus: any
	id?: string | null
	closeFunction: Function
	definitionSidebar?: boolean
}

const TaskFormComponent: FC<TaskFormComponentProps> = ({
	value,
	statusCount,
	handlerStatus,
	id,
	closeFunction,
	definitionSidebar,
}) => {
	const put = useDispatch();
	const history = useHistory();
	const CONTENT_REACH_REPORT =  useSelector(selectReportTypeContentReachReport)
	const PROJECT_ESTIMATE_REPORT = useSelector(selectReportTypeProjectEstimateReport)
	useFeelsApp([TStatusesFeels.reportType]);
	const ReturnLink = useGetReturnLink();

	function onSubmit(value: TaskFormDto, formikProps: any) {
		if (formikProps.isValid && CONTENT_REACH_REPORT && PROJECT_ESTIMATE_REPORT) {
			const task = new SendTaskFormDto(value, {CONTENT_REACH_REPORT,PROJECT_ESTIMATE_REPORT}).get()
			id ? put(UpdateTaskStartAction(id, task)) : put(CreateTaskStartAction(task))
			id
				? put(NotificationOpen('info', 'Задача обновляется'))
				: put(NotificationOpen('info', 'Задача создаётся'))
		} else {
			const errors: string = CreateErrorMessage.form(formikProps.errors)
			put(NotificationOpen('error', 'Ошибка заполнения формы', errors))
		}
	}

	return (
		<Formik
			enableReinitialize
			initialValues={value}
			validationSchema={validationSchema}
			onSubmit={()=>{}}
			validateOnMount
		>
			{(formik) => {
				return (
					<Form className="taskForm-form">
						{statusCount === 1 && <StepFirst />}
						{statusCount === 2 && <StepSecond/>}
						{statusCount === 3 && (
							<ErrorComponent>
								<StepThird definitionSidebar={definitionSidebar} />
							</ErrorComponent>
						)}
						<div className="taskForm-form__buttons">
							<Button
								type="default"
								className="arrowBtn arrowBtn__mr"
								onClick={(_e) => {
									const project = new URL(window.location.href).pathname === '/project/tasks';
									put(ModalOpenAction('form-no-save', {
										reset: formik.handleReset,
										handlerClose: closeFunction,
										handlerCancel: (project || ReturnLink) ? undefined : () => {
											if (window.innerWidth > 800) put(ModalOpenAction('task', id))
											else history.push(TASKS.CREATE_TASKS);
										}
									} as ModalFormNocSaveProps['data']))
								}}
							>
								Отменить
							</Button>
							{statusCount !== 1 && (
								<Button
									type="default"
									className="arrowBtn arrowBtn__mr"
									onClick={handlerStatus()}
								>
									Назад
								</Button>
							)}
							{statusCount === 3 && (
								<Tooltip title={CreateTaskErrorMessage.form(formik.errors)}>
									<Button
										type="primary"
										htmlType="submit"
										className="arrowBtn"
										onClick={() => onSubmit(formik.values, formik)}
										disabled={!formik.isValid}
									>
										Сохранить
									</Button>
								</Tooltip>
							)}
							{statusCount !== 3 && (
								<Button type="primary" className="arrowBtn" onClick={handlerStatus('inc')}>
									Далее
								</Button>
							)}
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}

export default TaskFormComponent
