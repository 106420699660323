import React, {FC} from 'react';
import {InputNumber} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {ExtendedSearchModel} from 'features/extended-search';


type TProps = {
    item: any
    index: number
}
const InputNumberComponent:FC<TProps> = ({item, index}) => {
    const put = useDispatch();
    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const counts = useSelector(ExtendedSearchModel.selectors.counts)

    const onInputChange = (index: number, field: 'minValue' | 'maxValue') => (value: string) => {
        const newArray = [...counts[typeContent].fields];
        newArray[index][field] = value
        put(ExtendedSearchModel.actions.setCounts(
            typeContent,
            newArray
        ))
    };
    const onBlurInput = (index: number, field: 'minValue' | 'maxValue') => ({target}: any) => {
        const newArray = [...counts[typeContent].fields];
        let minValue = newArray[index].minValue;
        let maxValue = newArray[index].maxValue;
        let value = target.value;

        const deleteSpaces = /\s/g;

        minValue = minValue?.replace(deleteSpaces,'');
        maxValue = maxValue?.replace(deleteSpaces,'');
        value = value?.replace(deleteSpaces,'');

        if (field === 'maxValue') {
            newArray[index][field] = +value < +minValue ?
                counts[typeContent].fields[index].minValue : value;
        } else {
            if (maxValue) {
                newArray[index][field] =  +value > +maxValue ? maxValue : value
            } else {
                newArray[index][field] = value
            }
        }
        put(ExtendedSearchModel.actions.setCounts(
            typeContent,
            newArray
        ))
    }

    return (
        <div className="form-group double">
            <InputNumber<string>
                disabled={item.field === 'Параметр неважен'}
                value={item.minValue}
                min="0"
                max={String(Number.MAX_SAFE_INTEGER)}
                formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                parser={(value: any) => value?.replace(/\$\s?|( *)/g, '')}
                onChange={onInputChange(index, "minValue")}
                onBlur={onBlurInput(index, "minValue")}
                placeholder={'Минимум'}
                stringMode
            />
            <InputNumber<string>
                disabled={item.field === 'Параметр неважен'}
                value={item.maxValue}
                min="0"
                onBlur={onBlurInput(index, "maxValue")}
                max={String(Number.MAX_SAFE_INTEGER)}
                formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                parser={(value: any) => value?.replace(/\$\s?|( *)/g, '')}
                onChange={onInputChange(index, "maxValue")}
                placeholder={'Максимум'}
                stringMode
            />
        </div>
    );
};

export default InputNumberComponent;