import React, {FC} from 'react';
import styles from './styles.module.less'
import {useSelector} from "react-redux";
import {selectRole} from "store/auth/selectors";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

type TProps = {
    isApproved: boolean
}
const StatusCellComponent:FC<TProps> = ({isApproved}) => {
    const role = useSelector(selectRole);
    const returnIcon = (status: boolean) => {
        if (role !== "ROLE_CONTRACTOR") {
            if (status) {
                return <CheckCircleOutlined className={styles.green}/>
            } else if (!status) {
                return <CloseCircleOutlined className={styles.red}/>
            }
        }
    }

    return (
        <div className={styles.main}>
            {isApproved !== null ? returnIcon(isApproved) : '__'}
        </div>
    );
};

export const StatusCell = (isApproved: boolean) => {
    return <StatusCellComponent isApproved={isApproved} />
}

export default StatusCell;