const reportsData = [
    {
        id: '1_reporting',
        selector: '.reporting-actions',
        content: 'Используйте фильтр для получения списка доступных отчетов проекта',
        role: [],
    },
    {
        id: '2_reporting',
        selector: 'h2',
        content:
            'Выгрузки данных отчетов позволяют получить информацию по всем проектам системы',
        role: [],
    },
]

export default reportsData
