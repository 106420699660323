import * as Yup from "yup";
import {StepFirstDto} from "../dto/project-form_StepFirst.dto";
import moment from "moment/moment";
import FilterHTML from "utils/filterHTML";
import {getValue, statusIsNullOrDraftOrReadyToBeActual, ValidateDateRequired} from "./utils";

export const validationStepFirst = Yup.object({
    name: Yup.object({
        value: Yup.string()
            .required('Поле «Название проекта» обязательно для заполнения'),
    }),
    requestNumber: Yup.object({
        value: Yup.string()
            .required('Поле «Номер заявки» обязательно для заполнения'),
    }),
    budget: Yup.object({
        value: Yup.mixed()
            .test(
                'budget',
                'Бюджет не может равняться 0',
                function (value: number, context) {
                    if (statusIsNullOrDraftOrReadyToBeActual(context)) return true;
                    if (!value) {
                        return this.createError({path: 'stepFirst.budget', message: 'Поле «Бюджет» обязательно для заполнения'})
                    }
                    return true
                }
            )
    }),

    contractNumber: Yup.object({
        value: Yup.mixed()
            .test(
                'contractNumber',
                'Поле «Договор» обязательно для заполнения',
                function (value: string, context) {
                    if (statusIsNullOrDraftOrReadyToBeActual(context)) return true;
                    else return !!value
                }
            )
    }),
    contractDate: Yup.mixed()
        .test(
            'contractDate',
            'Поле «Дата договора» обязательно для заполнения',
            function ({value}: StepFirstDto["contractDate"], context) {
                const contractDate = moment(value);
                const agreementDate = (context.parent as StepFirstDto).agreementDate.value;

                if (!value && statusIsNullOrDraftOrReadyToBeActual(context)) return true;

                if (value && agreementDate) {
                    const completionDateValue = (context.parent as StepFirstDto).completionDate.value;
                    const completionDate = moment(completionDateValue);
                    if (contractDate.isAfter(completionDate)) {
                        return context.createError({
                            path: 'stepFirst.contractDate',
                            message: 'Поле «Дата договора» не может быть позже чем дата «Дата завершения ' +
                                'проекта»'
                        })
                    }
                    return true;
                } else return !!value || !!agreementDate;
            }),
    agreementDate: Yup.mixed()
        .test(
            'agreementDate',
            'Поле «Дата соглашения» обязательно для заполнения',
            function ({value}: StepFirstDto["agreementDate"], context) {
                if (!value && statusIsNullOrDraftOrReadyToBeActual(context)) return true;

                const contractDateValue = getValue(context).stepFirst.contractDate.value;
                if (value) {
                    const completionDateValue = getValue(context).stepFirst.completionDate.value;

                    const agreementDate = moment(value);
                    const completionDate = moment(completionDateValue);
                    const contractDate = moment(contractDateValue);
                    if (agreementDate.isAfter(completionDate)) {
                        return context.createError({
                            path: 'stepFirst.agreementDate',
                            message: 'Поле «Дата соглашения» не может быть позже чем дата «Дата завершения ' +
                                'проекта»'
                        })
                    }
                    if (
                        contractDateValue &&
                        !(agreementDate.isSame(contractDate, 'day') || agreementDate.isBefore(contractDate))) {
                        return context.createError({
                            path: 'stepFirst.agreementDate',
                            message: 'Поле «Дата соглашения» не может быть позже чем дата «Дата договора»'
                        })
                    }
                    return true;
                } else return !!contractDateValue;
            }),
    completionDate: Yup.mixed()
        .test(
            'completionDate',
            'Поле «Дата завершения проекта» обязательно для заполнения',
            function (value: StepFirstDto["completionDate"], context) {
                if (!value.value && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
                else return ValidateDateRequired(value)
            }
        ),
    igk: Yup.object({
        value: Yup.mixed().test(
            'igk',
            'Поле «Номер ИГК» должно содержать от 14 до 25 символов',
            function (value: StepFirstDto["igk"]['value'], context) {
                if (value) {
                    if (value.length < 14) {
                        return this.createError({
                            path: 'stepFirst.igk',
                            message: 'Поле «Номер ИГК» должно содержать от 14 до 25 символов'
                        })
                    }
                    if (value.length > 25) {
                        return this.createError({path: 'stepFirst.igk', message: 'Поле «Номер ИГК» должен содержать до 25 символов'})
                    }

                    return true;
                } else {
                    return statusIsNullOrDraftOrReadyToBeActual(context);
                }
        }),
    }),
    session: Yup.object({
        value: Yup.mixed().test(
            'session',
            'Поле «Сессия» обязательно для заполнения',
            function (value: StepFirstDto["session"]['value'], context) {
                const contest = value.contest?.value,
                    year = value.year,
                    session = value.session?.value;
                const projectFilesExist = getValue(context).projectFilesExist;
                const optionalTestError = projectFilesExist ? '' : ''
                if (contest) {
                    if (!year) {
                        return this.createError({
                            path: 'stepFirst.session.value.year',
                            message: `Поле «Год» обязательно для заполнения ${optionalTestError}`
                        })
                    }
                    if (!session) {
                        return this.createError({
                            path: 'stepFirst.session.value.session',
                            message: `Поле «Сессия» обязательно для заполнения ${optionalTestError}`
                        })
                    }
                    return true;
                } else {

                    if (!contest && statusIsNullOrDraftOrReadyToBeActual(context) && !projectFilesExist) return true;
                    return this.createError({
                        path: 'stepFirst.session.value.contest',
                        message: `Поле «Конкурс» обязательно для заполнения ${optionalTestError}`
                    })
                }
        })
    }),
    goal: Yup.object({
        value: Yup.mixed()
            .test(
                'goal',
                'Описание цели превышает допустимые 1200 символов',
                function (value: StepFirstDto["goal"]['value'], context) {
                    if (!value && statusIsNullOrDraftOrReadyToBeActual(context)) return true
                    const filteredValue = value ? FilterHTML(value) : ''
                    if (filteredValue?.length >= 1200) {
                        return this.createError({
                            path: 'stepFirst.goal',
                            message: 'Поле «Цель проекта» не должно превышать более 1200 символов'
                        })
                    }
                    return true;
            })
    }),
    description: Yup.object({
        value: Yup.mixed()
            .test(
                'description',
                'Описание проекта превышает допустимые 5000 символов',
                function (value: StepFirstDto["description"]['value'], context) {
                    if (!value && statusIsNullOrDraftOrReadyToBeActual(context)) return true
                    const filteredValue = value ? FilterHTML(value) : ''
                    if (filteredValue?.length >= 5000) {
                        return this.createError({
                            path: 'stepFirst.description',
                            message: 'Поле «Описание проекта» не должно превышать более 5000 символов'
                        })
                    }
                    return true
            })
    }),
})

