const usersList = [
	{
		id: '50',
		selector: '.users-menu',
		content: 'Данные разделы позволяют совершать переход внутри модуля настроек',
		role: [],
	},
	{
		id: '51',
		selector: '.ant-table-container',
		content: 'Таблица пользователей выводит всю ключевую информацию по всем пользователям системы',
		role: [],
	},
	{
		id: '52',
		selector: '.usersPage__add-icon',
		content: 'Для создания нового пользователя воспользуетесь данной кнопкой',
		role: [],
	},
	{
		id: '52_1',
		selector: '.usersPage__by-user',
		content: 'Переход на страницу списка пользователей в keycloak',
		role: [],
	},
	{
		id: '53',
		selector: '.usersPage__search',
		content: 'Поиск позволяет быстро найти необходимые данные',
		role: [],
	},
	{
		id: '54',
		selector: '.usersPage__actions__filters',
		content: 'Фильтрация позволяет легко найти нужного пользователя',
		role: [],
	},
	{
		id: '55',
		selector: '.usersPage__bulkActions',
		content:
			'Массовые действия позволяют совершить действия над несколькими элементами таблицы одновременно. В выпадающем списке поля можно ознакомиться с перечнем допустимых массовых действий ',
		role: [],
	},

]

export default usersList
