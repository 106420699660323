import { z } from 'zod';

export const NotVotedRecordCountsSchema = z.object({
	inspector: z.object({
		id: z.string(),
		lastName: z.string(),
		firstName: z.string(),
		middleName: z.string(),
		role: z.object({
			id: z.string(),
			name: z.string(),
			ruName: z.string(),
		}),
		leader: z.boolean(),
		status: z.string(),
	}),
	contentTaskNotVotedRecordCount: z.number(), //"Контент" требующие голосования
	postingTaskNotVotedRecordCount: z.number(), //"Публикация контента" требующие голосования
	reachTaskNotVotedRecordCount: z.number(), //"Отчёт об охвате размещённого контента" требующие голосования
	copyrightTaskNotVotedRecordCount: z.number(), //"Список объектов авторского права" требующие голосования
	estimateTaskNotVotedRecordCount: z.number(), //"Смета проекта" требующие голосования
})
export type NotVotedRecordCounts = z.infer<typeof NotVotedRecordCountsSchema>;