import {axiosRequest} from "shared/config/axios";
import {ReportTypes} from 'entities/reports'

export class ReportTemplateController {
    static key = '/reports/templates'

    static async fetch() {
        return  await axiosRequest<ReportTypes.ReportTemplate[]>({
            method: 'GET',
            url: '/reports/templates'
        })
    }
}