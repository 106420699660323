import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetProjectsReportingErrorAction,
    GetProjectsReportingStartAction,
    GetProjectsReportingSuccessAction,
} from "../../store/projects/actions";

import Api from "../../entities/project/api/project";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../store/notification/actions";
import {T_ID_NAME} from "../../models/common";


export function* getProjectsReporting({sessionId}: ReturnType<typeof GetProjectsReportingStartAction>) {
    try {
        const { data: projects }: AxiosResponse<T_ID_NAME[]> = yield Api.getProjectsReporting(sessionId);
        yield put(GetProjectsReportingSuccessAction(projects))
    } catch (error: any) {
        yield put(GetProjectsReportingErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message))
    }
}
