const tasksContent = [
    {
        id: '41_2',
        selector: '.ant-select',
        content: 'Массовые действия позволяют изменить статус нескольких задач одновременно или удалить их',
        role: []
    },
    {
        id: '41_21',
        selector: '.options div:nth-child(1)',
        content: 'Поиск позволяет найти необходимые данные',
        role: []
    },
    {
        id: '41_22',
        selector: '.filters',
        content: 'С помощью фильтрации можно быстро отсортировать нужную информацию',
        role: []
    },
    {
        id: '41_3',
        selector: '.duplicate',
        content: 'Функция дублирования позволяет создать копию любой созданной задачи с новым сроком исполнения',
        role: ['ROLE_ADMIN', 'ROLE_PRODUCER','ROLE_ANALYST', 'ROLE_FINANCIER','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS']
    },
    {
        id: '41_4',
        selector: '.create-task',
        content: 'Для добавления задач используйте кнопку создания',
        role: ['ROLE_ADMIN', 'ROLE_PRODUCER','ROLE_ANALYST', 'ROLE_FINANCIER','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS']
    },
    {
        id: '42',
        selector: '.taskList__tour',
        content: 'Это список задач по всем проектам, в которых вы участвуете. Для перехода в карточку задачи, ' +
            'нажмите на строку списка с интересующей вас задачей',
        role: []
    },
];
export default tasksContent;
