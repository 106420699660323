import {put, select} from "redux-saga/effects";
import {Logger} from "shared/services";
import {selectContentTasksFilter} from "store/tasks/selectors/selectors-task-content";
import {SetTaskContentFilter} from "store/tasks/actions/content";
import {TTaskRecordsFilter} from "models/task/task-list";
import {FilterDataReport} from "models/task/task-report";

type TFilterDataTasks = {
    filterData: FilterDataReport,
}
export function* taskContent(filter: string, filterId: string | undefined) {
        try {
            const filterTasks: TTaskRecordsFilter = yield select(selectContentTasksFilter)
            if (filterId) {
                const filterDataTasks: TFilterDataTasks = JSON.parse(filter);
                yield  put(SetTaskContentFilter({...filterTasks, filterData: {searchString: '', ...filterDataTasks}}))
            } else {
               yield put(SetTaskContentFilter({page: 1, pageSize: 50, filterData: { searchString: '' } }))
            }
        } catch (error) {
            Logger.error(error)
        }
}
