import {createSelector} from 'reselect';
import {TApplicationState} from '../aplication-state';
import {IOptions} from "../../models/feels";
import {selectTask} from "./selectors/selectors-task";
import {
    selectContentTasks,
    selectContentTasksBulkActions,
    selectContentTasksStatus
} from "./selectors/selectors-task-content";
import {TaskTypes} from "entities/task";
import {transformRecordsStatusNameOptions} from "utils/transformObjectFeels";

const getTasksState = (state: TApplicationState) => state.tasks;


/**
 * fields
 */
export const selectTasksFieldsStatuses = createSelector(
    getTasksState,
    (state) => ({
        isLoading: state.fields.isLoading,
        error: state.fields.error
    })
)
export const selectTasksStatuses = createSelector(
    getTasksState,
    (state) => state.fields.statuses
)
export const selectTasksStatusReadyId = createSelector(
    getTasksState,
    (state) => state.fields.statuses?.filter((status)=> status.code === 'READY').map( task => task.id)
)
export const selectTasksStatusesOptions = createSelector(
    getTasksState,
    (state) => state.fields.statuses ? state.fields.statuses.map((el) => {
        return {value: el.id, label: el.name, code: el.code} as IOptions
    }) : null
)
export const selectTasksTypeReportType = createSelector(
    getTasksState,
    (state) => state.fields.types?.find((el:any) => el.code === "REPORT")
)
export const selectTasksTypesOptions = createSelector(
    getTasksState,
    (state) => state.fields.types ? state.fields.types.map((el) => {
        return {value: el.id, label: el.name, code: el.code} as IOptions
    }) : null
)

export const selectTasksStages = createSelector(
    getTasksState,
    (state) => state.fields.stages
)
export const selectTasksStagesOptions = createSelector(
    getTasksState,
    (state) => state.fields.stages ? state.fields.stages.map((el) => {
        return {value: el.id, label: el.name, code: el.code} as IOptions
    }) : null
)
export const selectTasksCostTypeOptions = createSelector(
    getTasksState,
    (state) => state.fields.costType ? state.fields.costType.map((el) => {
        return {value: el.id, label: el.name} as IOptions
    }) : null
)
export const selectTasksRecordsStatusesOptions = createSelector(
    getTasksState,
    (state) => state.fields.recordsStatuses ? state.fields.recordsStatuses.map((el) => {
        return {value: el.id, label: transformRecordsStatusNameOptions(el.code as TaskTypes.TasksRecordStatus), code: el.code} as IOptions<TaskTypes.TasksRecordStatus>
    }) : null
)
export const selectTasksRecordsStatusApprovedId = createSelector(
    getTasksState,
    (state) => state.fields.recordsStatuses ? state.fields.recordsStatuses.find((el) => el.code === 'APPROVED')?.id : null
)
export const selectTasksPostingOptions = createSelector(
    getTasksState,
    (state) => state.fields.posting ? state.fields.posting.map((el) => {
        return {value: el.id, label: el.name} as IOptions
    }) : null
)
//Gant and Kanban
export const selectTasksGant = createSelector(
    getTasksState,
    (state) => ({
        tasks: state.tasksGantKanban.tasksGant,
        isLoading: state.tasksGantKanban.isLoading,
        error: state.tasksGantKanban.error,
    })
)
export const selectTasksKanban = createSelector(
    getTasksState,
    (state) => ({
        tasks: state.tasksGantKanban.tasksKanban,
        isLoading: state.tasksGantKanban.isLoading,
        error: state.tasksGantKanban.error,
    })
)
export const selectTasksFilter = createSelector(
    getTasksState,
    (state) => state.tasksGantKanban.kanbanFilter
)

export const selectTaskContentData = createSelector(
    selectTask,
    selectContentTasks,
    selectContentTasksStatus,
    selectContentTasksBulkActions,
    (task, tasks, {isLoading}, tasksContentBulkActions) => ({task, tasks, isLoading,tasksContentBulkActions}),
)

export const selectKanbanCardIds =  createSelector(
    getTasksState,
    (state) => state.tasksGantKanban.cardIds
)