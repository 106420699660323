import {put, select} from "redux-saga/effects";
import {Logger} from "shared/services";
import {TTaskFilter, TTaskListFilterData} from "models/task/task-list";
import {GetAllTasksStartAction, SetTaskFilter} from "store/tasks/actions/tasks";
import {selectTaskListFilter} from "store/tasks/selectors/selectors-task";

export function* tasksProjects(filterData: string, filterId: string | undefined) {
    try {
        const filter: TTaskFilter = yield select(selectTaskListFilter)
        if (filterId) {
            const filterDataTasks: TTaskListFilterData = JSON.parse(filterData);
            yield put(SetTaskFilter({...filter,filterData: { ...filterDataTasks}}))
        } else {
            yield put(SetTaskFilter({...filter,filterData: undefined}))
        }

        yield put(GetAllTasksStartAction())
    } catch (error) {
        Logger.error(error)
    }
}