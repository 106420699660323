import React, {FC, useState} from 'react';
import {TaskModel, TaskTypes} from "entities/task";
import {Button} from "antd";
import styles from '../modal.module.less'
import {useDispatch} from "react-redux";
import classNames from "classnames";
import ReactQuill from "react-quill";
import FilterHTML from "utils/filterHTML";
import {NotificationOpen} from "store/notification/actions";

type TProps = {
    taskId: string
    commentRef: any
    handlerType: (type: TaskTypes.TypeAction) => void
}


const UpdateComment: FC<TProps> = ({taskId, handlerType, commentRef}) => {
    const put = useDispatch()
    const [value,setValue] = useState(commentRef?.current?.text ||'')

    const handlerChangeArea = (value: any) => {
        if (value === "<p><br></p>") {
            setValue('');
            return
        }
        setValue(value)
    }

    const handlerClickSave = () => {
        if(value) {
            put(TaskModel.actions.Update(commentRef?.current?.id,{
                text: value,
                taskId: taskId
            }))
            setValue('')
            handlerType('CREATE')
        } else {
            put(NotificationOpen('error', 'Заполните или удалите комментарий по кнопке "Удалить'))
        }
    }

    const handlerClickCancel = () => {
        handlerType('CREATE')
    }

    return (
        <div className={styles.update_container}>
            <div className={styles.circle}/>
            <div className={styles.update}>
                <ReactQuill
                    onChange={handlerChangeArea}
                    theme="snow"
                    value={value}
                />
                <p className={styles.limit}>
                    {value ? FilterHTML(value)?.length : 0} / 5000 {value?.length > 5000 && `Фактический размер: теги + текст ${value.length} превысил лимит в 5000 символов`}
                </p>
                <div className={classNames(styles.button_containerArea)}>
                    <Button htmlType="submit" className={styles.button_save} onClick={handlerClickSave}>
                        Изменить
                    </Button>
                    <Button htmlType="submit" className={styles.button_cancel} onClick={handlerClickCancel}>
                        Отменить
                    </Button>
                </div>
            </div>
        </div>
    )

    ;
};

export default UpdateComment;