import React, { useState } from 'react'
import SockJsClient from 'react-stomp'
import ErrorComponent from './error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationOpen } from '../store/notification/actions'
import { selectUser } from 'store/auth/selectors'
import env from 'shared/config/env'

const BrowserNotification = () => {
	const put = useDispatch()
	const user = useSelector(selectUser)
	const [error, setError] = useState(false)

	function onMessageReceived(msg: any) {
		if (msg.id) {
			put(NotificationOpen('info', msg.subject, msg.message))
		}
	}

	if (error) return null
	return (
		<ErrorComponent>
			<SockJsClient
				url={`${env.API_ENDPOINT}/secured/ws/connect`}
				topics={['/user/secured/reports/notification']}
				headers={{
					'user-name': user?.id,
				}}
				onMessage={onMessageReceived}
				onDisconnect={(_ms: any) => {
					setError(true)
				}}
				debug={false}
			/>
		</ErrorComponent>
	)
}
export default BrowserNotification
