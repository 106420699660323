import React, {Key, SyntheticEvent} from 'react'
import styles from './styles.module.less'
import {Button, Table} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import classNames from 'classnames'
import {TablePaginationConfig} from 'antd/lib/table/interface'
import {ColumnsType} from 'antd/es/table'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import NameCenterCell from 'components/table-cells/name-center-cell'
import ActionCell from 'components/table-cells/action-cell'
import handlerTable from 'utils/handleTable'
import {ModalEvent} from 'shared/ui/modal'
import {action, select} from '../../model'
import {ReportSchedulerController} from 'features/report-auto-sending-schedule/api'
import {ReportsSchedulers} from '../../types'
import NameCell from 'components/table-cells/name-cell'
import ModalScheduler from '../modal-scheduler'
import NameCellWithCount from 'components/table-cells/name-cell-with-count'
import ReportSelect from './items/report-select'
import UsersSelect from './items/users-select'
import Search from "./items/search";

type FORMAT_TYPE = {
	sessionIds: string
	projectIds: string
}

const FORMAT_FILTER: FORMAT_TYPE = {
	sessionIds: 'по сессиям',
	projectIds: 'по проектам',
}

const TableScheduler = () => {
	const put = useDispatch()
	const queryClient = useQueryClient()
	const filter = useSelector(select.filter)


	let indexTasks = 0

	const { data, isLoading } = useQuery({
		queryKey: [ReportSchedulerController.search.key, filter.page, filter.pageSize, filter.sort, filter.filterData],
		queryFn: async () => {
			const { content, page, ...rest } = await ReportSchedulerController.search.fetch(filter)
			put(action.setValue({ ...rest, page: page + 1 }))
			return content
		},
		refetchOnWindowFocus: false,
	})

	const { mutate: deleteScheduler } = useMutation({
		mutationFn: (id: string) => ReportSchedulerController.deleteScheduler(id),
		onSuccess: async () => {
			await queryClient.invalidateQueries([ReportSchedulerController.search.key])
			ModalEvent.close()
		},
	})

	const onOpenModal = () => {
		ModalEvent.open({
			component: <ModalScheduler mode={'create'} />,
			destroyOnClose: true,
			width: 780,
		})
	}

	const handlerPagination = async (
		pagination: TablePaginationConfig,
		filter: Record<string, (Key | boolean)[] | null>,
		sorter: any
	) => {
		const { sort, ...rest }: any = handlerTable(pagination, filter, sorter)
		put(action.setValue({ sort, ...rest }))
	}

	const onDelete = (id: string) => (_e: SyntheticEvent) => {
		deleteScheduler(id)
	}
	const onEdit = (_id: string, rowData: ReportsSchedulers) => (_e: SyntheticEvent) => {
		ModalEvent.open({
			component: <ModalScheduler data={rowData} mode={'update'} />,
			destroyOnClose: true,
			width: 780,
		})
	}

	const columns: ColumnsType<ReportsSchedulers> = [
		{
			title: '№ Расписания',
			dataIndex: '',
			key: '',
			render: () => {
				if (data && indexTasks < data.length) indexTasks++ // eslint-disable-next-line
				else indexTasks = 1
				return NameCenterCell(String(indexTasks))
			},
			width: 100,
		},
		{
			title: 'Отчеты формирования',
			dataIndex: 'reportTemplate',
			key: 'reportTemplate',
			sorter: true,
			width: 240,
			render: (report, _rowData) => {
				return NameCell(`${report?.code} - ${report?.name}`)
			},
		},
		{
			title: 'Дата формирования первого отчета',
			dataIndex: 'firstFormationDate',
			key: 'firstFormationDate',
			sorter: true,
			width: 150,
			render: (date: string) => {
				if (date) return NameCenterCell(moment(date).format('DD.MM.yy'))
				else return NameCenterCell('__')
			},
		},
		{
			title: 'Дата формирования последнего отчета',
			dataIndex: 'lastFormationDate',
			key: 'lastFormationDate',
			sorter: true,
			width: 150,
			render: (date: string) => {
				if (date) return NameCenterCell(moment(date).format('DD.MM.yy'))
				else return NameCenterCell('__')
			},
		},
		{
			title: 'Время формирования отчета',
			dataIndex: 'formationTime',
			key: 'formationTime',
			sorter: true,
			width: 150,
			render: NameCenterCell,
		},
		{
			title: 'Формат формирования',
			dataIndex: 'formationFormat',
			key: 'formationFormat',
			sorter: true,
			width: 150,
			render: NameCenterCell,
		},
		{
			title: 'Фильтрация данных',
			dataIndex: 'inputData',
			key: 'inputData',
			sorter: true,
			width: 150,
			render: (data) => {
				let keys: string[] = [];
				if (data && typeof data === 'object') {
					keys = Object.keys(data)
				}
				const str = keys.map((key) => FORMAT_FILTER[key as keyof FORMAT_TYPE]).join(', ')
				return NameCenterCell(str || '__')
			},
		},
		{
			title: 'Получатели сформированных отчетов',
			dataIndex: 'users',
			key: 'users',
			sorter: true,
			width: 250,
			render: (_users, rowData) => {
				const data = rowData.users.map((user) => {
					const firstName = user.firstName ? user.firstName + ' ' : ''
					const lastName = user.lastName ? user.lastName + ' ' : ''
					const middleName = user.middleName ? user.middleName + ' ' : ''
					return { name: `${lastName}${firstName}${middleName}` }
				})
				return NameCellWithCount(data)
			},
		},
		{
			title: '',
			dataIndex: 'id',
			key: 'id',
			width: 50,
			render: ActionCell({ onEdit, onDelete }, 'запись'),
			fixed: 'right',
		},
	]

	return (
		<div className={classNames(styles.container)}>
			<div className={styles.filter}>
				<div className={styles.wrapper}>
					<Search/>
					<ReportSelect />
					<UsersSelect />
				</div>

				<div className={styles.buttons}>
					<Button type={'primary'} onClick={onOpenModal}>
						Создать расписание формирования отчетов
					</Button>
				</div>
			</div>
			<div className={styles.table}>
				<Table
					loading={isLoading}
					dataSource={data || []}
					columns={columns}
					className="table"
					sticky
					scroll={{ x: 1000 }}
					pagination={{
						current: filter.page,
						pageSize: filter.pageSize,
						total: filter.totalCount,
						position: ['bottomCenter'],
						showSizeChanger: true,
						pageSizeOptions: ['50', '100', '500', '1000', '3000'],
					}}
					onChange={handlerPagination}
					rowKey="id"
				/>
			</div>
		</div>
	)
}

export default TableScheduler
