import {InferValueTypes, TGenerateKey} from "../../models/common";
import * as actions from './actions';
import {AuthActionTypes} from "./action-types";
import {ErrorActionState, StartActionState, SuccessActionState} from "../../utils/reducerHalper";
import { UserTypes } from "entities/user";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;
export type Logout = {
    type: '[AUTH] LOGOUT'
}
export type Login = {
    type: '[AUTH] LOGIN_SUCCESS'
}
export type TAuthState = {
    isLogin: boolean
    user: UserTypes.CurrentUser | null
    restore: boolean
    generatedKey: TGenerateKey | null
    receivedKey: TGenerateKey | null
    downloadRights: boolean
    isLoading: boolean
    error: any
}

export const initialState: TAuthState = {
    isLogin: false,
    user: null,
    restore: false,
    generatedKey: null,
    receivedKey: null,
    downloadRights: false,
    isLoading: false,
    error: null
}

export default function reducer(state: TAuthState = initialState, action: ActionTypes): TAuthState {
    switch (action.type) {
        case AuthActionTypes.LOGIN_START:
            return StartActionState(state);
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...SuccessActionState(state),
                user: action.user,
                isLogin: true,
            }
        case AuthActionTypes.LOGIN_ERROR:
            return ErrorActionState(state, action);

        case AuthActionTypes.RESTORE_START:
            return StartActionState(state);
        case AuthActionTypes.RESTORE_SUCCESS:
            return {...SuccessActionState(state), restore: true}
        case AuthActionTypes.RESTORE_ERROR:
            return ErrorActionState(state, action)

        case AuthActionTypes.GENERATE_KEY_START:
            return StartActionState(state);
        case AuthActionTypes.GENERATE_KEY_SUCCESS:
            return {...SuccessActionState(state), generatedKey: action.data}
        case AuthActionTypes.GENERATE_KEY_ERROR:
            return ErrorActionState(state, action);

        case AuthActionTypes.GET_KEY_START:
            return StartActionState(state);
        case AuthActionTypes.GET_KEY_SUCCESS:
            return {...SuccessActionState(state), receivedKey: action.data, generatedKey:null}
        case AuthActionTypes.GET_KEY_ERROR:
            return ErrorActionState(state, action);

        case AuthActionTypes.BLOCK_KEY_START:
            return StartActionState(state);
        case AuthActionTypes.BLOCK_KEY_SUCCESS:
            return {...SuccessActionState(state), receivedKey: null, generatedKey:null}
        case AuthActionTypes.LOGOUT:
            return {...initialState}
        case AuthActionTypes.BLOCK_KEY_ERROR:
            return ErrorActionState(state, action);

        case AuthActionTypes.CHANGE_DOWNLOAD_RIGHTS_START:
            return StartActionState(state);
        case AuthActionTypes.CHANGE_DOWNLOAD_RIGHTS_SUCCESS:
            return {...state, isLoading: false, downloadRights: action.value}
        case AuthActionTypes.CHANGE_DOWNLOAD_RIGHTS_ERROR:
            return {...state, isLoading: false, downloadRights: false}
        default:
            return state
    }
}
