import { TPaginationDefault, TSort } from 'models/common';
import { z } from 'zod';

export type Filter = {
	sort?: TSort
} & TPaginationDefault;

export const DuplicateScheduleSchema = z.object({
	id: z.string(),
	createDateTime: z.string(),
	firstTaskDate: z.string().nullable(),
	lastTaskDate: z.string().nullable(),
	dayOfWeek: z.enum(['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']).nullable(),
	scheduleDates: z.array(z.string()),
	daysToDeadline: z.number(),
	enabled: z.boolean(),
	task: z.object({
		id: z.string(),
		name: z.string(),
		code: z.string(),
		number: z.number(),
	}),
});
export type DuplicateSchedule = z.infer<typeof DuplicateScheduleSchema>;
export type ResponseList = {
	content: DuplicateSchedule[]
} & Filter;


const ScheduleDataSchema = z.object({
	firstTaskDate: z.string().nullable(),
	lastTaskDate: z.string().nullable(),
	dayOfWeek: z.enum([
		'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'
	]).nullable(),
	scheduleDates: z.array(z.string()),
	daysToDeadline: z.number(),
	enabled: z.boolean(),
})

export const ScheduleCreateSchema = ScheduleDataSchema.extend({
	taskIds: z.array(z.string()),
})
export const ScheduleUpdateSchema = ScheduleDataSchema.extend({
	scheduleIds: z.array(z.string()),
})
export const ScheduleDatesSetSchema = z.object({
	id: z.string(),
	name: z.string(),
	dateSet: z.array(z.string().or(z.date())),
})
export const ScheduleDatesSetCreateSchema = ScheduleDatesSetSchema.omit({ id: true });


export type ScheduleDatesSetCreate = z.infer<typeof ScheduleDatesSetCreateSchema>
export type ScheduleDatesSet = z.infer<typeof ScheduleDatesSetSchema>

export type ScheduleCreate = z.infer<typeof ScheduleCreateSchema>
export type ScheduleUpdate = z.infer<typeof ScheduleUpdateSchema>

export const SchedulersSchema = z.object({
	id: z.string(),
	createDateTime: z.string(),
})
export type Schedules = z.infer<typeof SchedulersSchema>