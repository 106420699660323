import React, {FC} from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {Checkbox, Tag} from 'antd'
import {useFormikContext} from "formik";
import {UserDto} from "entities/user";

type TDragDropTags = {
	index: number
	tag: any
	tags: any
	name: string
	handleClose: any
	setEditInputValue: any
	setEditInputIndex: any
}

const getColor = (value:any)=> {
	if(value === 'isMain') return '#F2FBCF'
	else return '#F5F5F5'
}

const DragDropTag: FC<TDragDropTags> = ({
	index,
	tags,
	name,
	tag,
	handleClose,
	setEditInputIndex,
	setEditInputValue,
}) => {
	const { setFieldValue, values } = useFormikContext<UserDto.User>()

	const handleCheckbox = (index: number) => (value: any) => {
		const isChecked = value.target.checked;
		setFieldValue(`${name}[${index}].isConfirmed`, isChecked);

		const typeField = name.split('.')[1];
		const field = typeField === 'emails' ? 'email' : 'phone';

		const updateOrgField = (orgIndex: number, arr: any[]) => {
			const itemIndex = arr.findIndex((item) => item?.[field] === tag);
			if (itemIndex >= 0) {
				setFieldValue(`organizations[${orgIndex}].${typeField}[${itemIndex}].isConfirmed`, isChecked);
			}
		};

		values.organizations.forEach((org, orgIndex) => {
			if (typeField === 'emails') {
				updateOrgField(orgIndex, org.emails);
			} else {
				updateOrgField(orgIndex, org.phones);
			}
		});
	};

	return (
		<Draggable draggableId={`${tag + index}`} index={index}>
			{(provided, _snapshot) => (
				<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
					<div>
						<Tag
							className="edit-tag"
							closable={true}
							onClose={() => handleClose(tag)}
							color={index === 0 ? getColor('isMain') : getColor(tags[index].isConfirmed)}
						>
							<span
								onDoubleClick={(e) => {
									setEditInputIndex(index)
									setEditInputValue(tag)
									e.preventDefault()
								}}
							>
								{`${tag.slice(0, 20)}`}
							</span>
							<Checkbox onChange={handleCheckbox(index)} checked={tags[index].isConfirmed} disabled={values.disabled}/>
						</Tag>
					</div>
				</div>
			)}
		</Draggable>
	)
}

export default DragDropTag
