import React, {FC, useEffect, useRef} from 'react';
import './modal-form-no-save.less'
import {Button, Modal} from "antd";
import useHandlerModal from "hooks/handlModal";

export type ModalFormNocSaveProps = {
    status: boolean
    data: {
        reset: Function,
        handlerClose: Function
        handlerCancel?: Function
    }
}

const ModalFormNoSave: FC<ModalFormNocSaveProps> = ({status, data}) => {
    const {isVisible, closeModal } = useHandlerModal(status);
    const refButton = useRef<HTMLButtonElement>(null);
    const handlerClickYes = ()=>{
        if(data.handlerClose) data.handlerClose()
        data.reset()
        closeModal()
    }

    const onCancel = () => {
        data.handlerCancel ? data.handlerCancel() : closeModal();
    }

    useEffect(()=>{
       if(refButton.current) refButton?.current?.focus()
        // eslint-disable-next-line
    },[refButton.current])

    return (
        <Modal
            className='modal-form-no-save'
            title="Подтвердите действие"
            visible={isVisible}
            onCancel={onCancel}
            footer={(
                <>
                    <Button type={"default"} onClick={onCancel}>Нет</Button>
                    <Button ref={refButton} type={"primary"} onClick={handlerClickYes}>Да</Button>
                </>
            )}
            maskClosable={true}
        >
            <div className='modal-form-no-save__content'>
                <span>Вы уверены что хотите покинуть форму без сохранения? </span>
            </div>
        </Modal>
    )
};
export default ModalFormNoSave;
