import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteOrganizationErrorAction,
    DeleteOrganizationStartAction,
    DeleteOrganizationSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import HandbookService from "services/handbookService";

export function* deleteOrganization({id}: ReturnType<typeof DeleteOrganizationStartAction>) {
    try {
        yield HandbookService.deleteOrganizationRecord(id)
        yield put(DeleteOrganizationSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.organization ))
        yield put(NotificationOpen('success', 'Организация удалена'))

    } catch (error: any) {
        yield put(DeleteOrganizationErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить организацию'))
    }
}
