import moment from "moment";
import {Moment} from "moment/moment";

export function hoursToSeconds(hours: string | number) {
    if(!hours) return 0
    return Number(hours) * 3600;
}

export function minutesToSeconds(minutes: string | number) {
    if(!minutes) return 0
    return Number(minutes) * 60;
}

export function secondsToTimeString(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function calculateTotalSeconds(hours: string | number, minutes: string | number, seconds: string | number): number | null {
    const secondsTime = hoursToSeconds(hours || 0) + minutesToSeconds(minutes || 0) + Number(seconds);
    return Number.isNaN(secondsTime) ? null : secondsTime;
}

export const formatSecondToMomentObj = (seconds: number)=> {
    const duration = moment.duration(seconds, 'seconds');
    const timeString = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
    const momentObject: Moment = moment(timeString, 'HH:mm:ss');

    return momentObject

}