import { Breadcrumb } from './type';

export const Names = {
	'/projects': 'Проекты',
	'/project-create': 'Создание проекта',
	'/project-edit': 'Редактирование проекта',
	'/project/params': 'Параметры проекта',
	'/project/calendar': 'Календарь проекта',
	'/project/tasks': 'Задачи проекта',
	'/project/reporting': 'Шаблоны приложений к договорам',
	'/project/documents': 'Документы проекта',

	'/calendar': 'Календарь проектов',

	'/tasks/projects': 'Задачи проектов',
	'/tasks/records': 'Записи задач',
	'/task': 'Задача',
	'/create-task': 'Создание задачи',
	'/edit-task': 'Редактирование задачи',

	'/options/list': 'Список пользователей',
	'/options/action-user': 'Действия пользователей',
	'/options/notifications': 'Уведомления',
	'/options/handbook': 'Справочники',

	'/options/session-contest/contest': 'Данные о конкурсах',
	'/options/session-contest/session': 'Данные о сессиях',

	'/options/personal-settings/customization': 'Настройки подтверждений и подписок',
	'/options/personal-settings/control': 'Управление уведомлениями',


	'/options/handbook/organization': 'Справочник организаций',
	'/options/handbook/projecs-tags': 'Справочник тегирования проектов',
	'/options/handbook/thematic': 'Справочник тематических линий',
	'/options/handbook/content-direction': 'Справочник направлений контента',
	'/options/handbook/content-type': 'Справочник единиц контента',
	'/options/handbook/internet': 'Справочник интернет - ресурсов',
	'/options/handbook/KPE': 'Справочник данных КПЭ',
	'/options/handbook/documents': 'Справочник типов документов',
	'/options/handbook/actions-users': 'Справочник действий пользователя',
	'/options/handbook/regions': 'Справочник регионов',
	'/options/handbook/ranks': 'Справочник званий',
	'/options/handbook/legal-form-organization': 'Справочник правовых форм организаций',
	'/options/handbook/sessions-type': 'Справочник типов сессий',
	'/options/handbook/category-project': 'Справочник категорий проектов',
	'/options/handbook/genre-project': 'Справочник жанров проектов',

	'/content': 'Список контента',
	'/content/info': 'Контент проекта',

	'/reporting': 'Отчетность и шаблоны',
}

export class SessionStorage {
	static clear(userId: string) {
		const user = sessionStorage.getItem('userId')
		if (user && user === userId) {
			return;
		}
		sessionStorage.clear();
		sessionStorage.setItem('userId', userId)
	}
	static get(): Breadcrumb[] {
		const breadcrumbs = sessionStorage.getItem('breadcrumbs');
		return breadcrumbs ? JSON.parse(breadcrumbs) : [];
	}
	static getList(): Breadcrumb[] {
		const mobile = window.innerWidth <= 940;
		const count = mobile ? 3 : 5;
		const breadcrumbs = this.get();
		if (breadcrumbs.length > 1) {
			breadcrumbs.splice(-1, 1)
			if (breadcrumbs.length > count) return breadcrumbs.slice(-count);
			return breadcrumbs;
		}
		return []
	}
	static set(props: { pathname: string, search: string, tooltip?: string }) {
		const breadcrumbs = this.get();
		const length = breadcrumbs.length
		if (length === 100) {
			breadcrumbs.splice(0, 1)
		}
		if (breadcrumbs[length - 1]?.link === (props.pathname + props.search)) return;
		const name = Names[(props.pathname as keyof typeof Names)];
		if (!name) return;


		const breadcrumb: Breadcrumb = {
			// @ts-ignore
			id: window.crypto.randomUUID(),
			link: props.pathname + props.search,
			name: name,
			tooltip: props.tooltip
		}
		breadcrumbs.push(breadcrumb)
		sessionStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs))
	}
}