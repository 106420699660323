import React from 'react'
import { useFormikContext } from 'formik'
import styles from './styles.module.less'
import { RadioButtonsComponent } from 'components/formic-control'
import { InitialValueDto } from '../../lib/dto'

const RadioButtons = () => {
	const { setValues } = useFormikContext()

	const handler = (value: InitialValueDto['radioButtons']) => {
		if (value === 'ALL') {
			setValues((prevState: InitialValueDto) => ({
				...prevState,
				sessions: [],
				projects: [],
				disabledProjects: true,
				disabledSessions: true,
			}))
		}
		if (value === 'PROJECTS') {
			setValues((prevState: InitialValueDto) => ({
				...prevState,
				sessions: [],
				disabledProjects: false,
				disabledSessions: false,
			}))
		}
		if (value === 'SESSIONS') {
			setValues((prevState: InitialValueDto) => ({
				...prevState,
				projects: [],
				disabledProjects: true,
				disabledSessions: false,
			}))
		}
	}

	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Фильтр выгрузки <sup>*</sup>
			</span>
			<div className={styles.field}>
				<RadioButtonsComponent
					name={'radioButtons'}
					options={[
						{ value: 'ALL', label: 'Все имеющиеся данные' },
						{ value: 'SESSIONS', label: 'Данные выбранных сессий' },
						{ value: 'PROJECTS', label: 'Данные выбранных проектов' },
					]}
					handler={handler}
				/>
			</div>
		</div>
	)
}

export default RadioButtons
