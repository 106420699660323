const control = [
	{
		id: '88',
		selector: '.controlPage',
		content:
			'Для отказа или возобновления рассылки сообщений снимите/установите отметки напротив уведомлений или каналов их распространения, далее сохраните внесенные вами изменения',
		role: [],
	},
]

export default control
