import { AxiosResponse } from 'axios'
import $api from '../http'
import { THandbookCodeNameHidden, THandbookContentResponse, THandbookFilter, TTagsResponse } from 'models/handbook'
import { T_ID_NAME_CODE_HIDDEN } from 'models/common'

export default class CategoryProjectService {
	/**
	 * Получение всех тегох категорий проектов
	 */
	static async getCategoryProject(): Promise<AxiosResponse<T_ID_NAME_CODE_HIDDEN[]>> {
		return $api.get('/category-types')
	}
	/**
	 * Получение всех тегох категорий проектов c filters
	 */
	static async getCategoryProjectWithFilter(data: THandbookFilter): Promise<AxiosResponse<THandbookContentResponse>> {
		const filter = { ...data, page: data.page - 1 }
		return $api.post<TTagsResponse>('/category-types/search', filter)
	}
	/**
	 *  Добавление тега категорий проектов
	 */
	static async addICategoryProjectRecord(data: THandbookCodeNameHidden): Promise<AxiosResponse<string>> {
		return $api.post<string>(`/category-types`, data)
	}
	/**
	 *   Обновление тега категорий проектов
	 */
	static async updateCategoryProjectRecord(data: THandbookCodeNameHidden, id: string): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/category-types/${id}`, data)
	}
	/**
	 *   Удаление тега категорий проектов
	 */
	static async deleteCategoryProjectRecord(id: string): Promise<AxiosResponse<any>> {
		return $api.delete<any>(`/category-types/${id}`)
	}
	/**
	 *   Массовые действия тега категорий проектов
	 */
	static async bulkCategoryProjectActions(recordsArr: string[], action: string): Promise<AxiosResponse<any>> {
		return $api.post<any>(`/category-types/bulk-actions/${action}`, recordsArr)
	}
	/**
	 *   Выгрузка данных тега категорий проектов
	 */
	static async downloadCategoryProject(data: string[]): Promise<AxiosResponse<any>> {
		return $api({
			method: 'POST',
			url: `/category-types/export`,
			responseType: 'arraybuffer',
			headers: {
				'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
			data: data,
		})
	}
	/**
	 * Выполнение импорта данных тега категорий проектов из заполненного шаблона в формате Excel
	 */
	static async importCategoryProject(data: any): Promise<AxiosResponse> {
		return $api({
			method: 'POST',
			url: `/category-types/import`,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data,
		})
	}
	/**
	 *   Выгрузка шаблона
	 */
	static async downloadCategoryProjectTemplate(): Promise<AxiosResponse<any>> {
		return $api({
			method: 'GET',
			url: '/category-types/import/template',
			responseType: 'arraybuffer',
		})
	}
}
