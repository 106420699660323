import {put} from "redux-saga/effects";
import {
    BulkProjectsErrorAction,
    BulkProjectsStartAction,
    BulkProjectsSuccessAction,
    GetProjectsStartAction,
} from "store/projects/actions";

import Api from "entities/project/api/project";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";


export function* bulkActionsProjects({projectsId, actionType}: ReturnType<typeof BulkProjectsStartAction>) {
    try {
        yield Api.projectsBulkActions(projectsId, actionType);

        yield put(BulkProjectsSuccessAction())
        yield put(GetProjectsStartAction())
    } catch (error: any) {
        yield put(BulkProjectsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : ''))
    }
}