import { axiosRequest } from 'shared/config/axios'
import { ProjectStatusResponse } from '../types'
import { IOptions } from 'models/feels'

type Params = {
	projectId: string
}
export default class ProjectStatusesController {
	static getStatusesKey = '/projects/all'
	static optionsStatusesKey = 'Options: /projects/all'
	/**
	 * @name getStatuses
	 * @description Получение всех статусов (проектов и проекта), доступных для текущей роли
	 */
	static async getStatuses(params?: Params) {
		return await axiosRequest<ProjectStatusResponse[]>({
			method: 'GET',
			url: `/projects/statuses`,
			params,
		})
	}
	static async getOptionsStatuses(params?: Params): Promise<IOptions[] | null> {
		const { data } = await ProjectStatusesController.getStatuses(params)
		return data?.length ? (data.map((el) => ({ value: el.id, label: el.name, code: el.code })) as IOptions[]) : null
	}
}
