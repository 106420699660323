import {AxiosResponse} from 'axios'
import $api from '../http'
import {TCreateReporting} from '../models/projects/reportings'
import {TAvailableReportsResponse} from "../models/projects/projects-reporting";
import {T_ID_NAME_CODE} from "../models/common";

/**
 * @category Service
 * @class ReportingService
 */
export default class ReportingService {

	/**
	 * Получение всех шаблонов отчетов
	 */
	static reportsTemplatesKey= '/reports/templates'
	static async getReportsTemplates(): Promise<AxiosResponse<T_ID_NAME_CODE[]>> {
		return $api.get<T_ID_NAME_CODE[]>('/reports/templates')
	}

	/**
	 * Создание задачи на формирование отчета, с указанием типа(ов) файл(ов) и входными параметрами
	 */
	static async createReporting(data: TCreateReporting): Promise<AxiosResponse<string>> {
		return $api.post<string>('/reports', data)
	}

	/**
	 * Скачать уведомление
	 */
	static async downloadReporting(id: string): Promise<AxiosResponse<any>> {
		return  $api({
			method: 'GET',
			url:  `/reports/${id}`,
			responseType:'arraybuffer',
		})
	}
	/**
	 * Получение списка отчетов проекта доступных для формирования
	 */
	static async getAvailableReports(typesReports: string[],id?: string): Promise<AxiosResponse<TAvailableReportsResponse[]>> {
		return $api({
			method: 'POST',
			url: `/projects/allowable-reports`,
			data: {
				projectId: id,
				types: typesReports
			}
		})
	}
}
