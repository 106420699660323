import React, {FC} from 'react';
import {FieldArray, useFormikContext} from "formik";
import {Button, Col, Row, Spin, Tooltip} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import useFeelsApp from "hooks/feelsApp";
import {TStatusesFeels} from "models/projects/projectFeels";
import {GetFeelsStartAction} from "store/app/actions";
import {useDispatch} from "react-redux";
import {ProjectFormDto} from "../../../lib/dto/project-form.dto";
import { InputNumberComponent, SelectComponent } from 'components/formic-control';
import ReloadList from 'components/forms/components/reload-list/reload-list';
import styles from './styles.module.less';
type ContentFormatFieldsProps = {
    name: string
    disabled?: boolean
}
const PlannedKpi: FC<ContentFormatFieldsProps> = ({name, disabled}) => {
    const put = useDispatch()
    const {errors, touched} = useFormikContext<ProjectFormDto>()
    const isError = errors.stepThird?.kpis;
    const {isLoading, formatKpi} = useFeelsApp([TStatusesFeels.formatKpi])

    function isTouched(id: number) {
        // @ts-ignore
        const count = touched[`stepThird.kpis.value[${id - 1}].count`]
        // @ts-ignore
        const type = touched[`stepThird.kpis.value[${id - 1}].type`]
        return count || type
    }
    const handlerUpdateFormatKpi = ()=> {
        put(GetFeelsStartAction(TStatusesFeels.formatKpi))
    }
    return (
        <FieldArray
            name={name}
            render={({form, remove, push}) => {
                const idx = form.values.stepThird.kpis.value.length;
                const disableBtn = isError && idx !== 0;
                return (
                    <>
                        {form.values.stepThird.kpis.value.map((item: any, index: number) => (
                            <Row className='form-group__row' key={index} gutter={[5, 5]}>
                                <Col md={11} xs={22}>
                                    <InputNumberComponent
                                        name={`${name}[${index}].count`}
                                        placeholder='Введите числовой показатель КПЭ'
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col md={11} xs={22}>
                                    <Spin spinning={isLoading || !formatKpi}>
                                        <ReloadList handlerUpdateList={handlerUpdateFormatKpi}/>
                                        <SelectComponent
                                            options={formatKpi}
                                            name={`${name}[${index}].type`}
                                            placeholder='Выберите меру измерения КПЭ'
                                            disabled={disabled}
                                        />
                                    </Spin>
                                </Col>
                                <Col md={2} xs={2}>
                                    {!disabled && (
                                        <div className='form-group__btn'>
                                            <Tooltip title="Удалить аудиторию?">
                                                <Button className={styles.delete} type="link"
                                                        onClick={() => remove(index)}>
                                                    <DeleteOutlined/>
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        ))}
                        {!disabled && (
                            <>
                                {disableBtn && isTouched(idx) ? (
                                    <div className="invalid-feel">{errors.stepThird?.kpis as string}</div>
                                ) : (
                                    <Button
                                        type="link"
                                        onClick={() => push({
                                            type: '',
                                            count: '',
                                        })}>
                                        <PlusCircleOutlined/> Добавить плановый КПЭ
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                )
            }}
        />
    )
}
export default PlannedKpi;
