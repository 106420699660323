import {
	DuplicateScheduleSchema,
	Filter,
	ResponseList,
	ScheduleCreate, SchedulersSchema, Schedules,
	ScheduleUpdate,
} from '../types';
import { axiosRequest } from 'shared/config/axios';
import { IOptions } from 'models/feels';
import moment from 'moment';

/**
 *
 * @description Получение списка расписаний автосоздания задач
 */
export const search = {
	key: '/tasks/duplication/schedulers/search',
	fetch: async (params: Filter): Promise<ResponseList> => {
		const filter = {...params, page: params.page - 1}
		const {data} = await axiosRequest<ResponseList>({
			method: 'POST',
			url: '/tasks/duplication/schedulers/search',
			data: filter,
			parser: {list: true, schema: DuplicateScheduleSchema.array()},
		})
		return data
	}
}
/**
 *
 * @description Массовое создание расписания автосоздания задачи
 */
export const bulkCreation = async (data: ScheduleCreate) => {
	await axiosRequest({
		method: 'POST',
		url: '/tasks/duplication/schedulers/bulk-creation',
		data,
	})
}
/**
 *
 * @description Массовое обновление расписания автосоздания задачи
 */
export const bulkUpdate = async (data: ScheduleUpdate) => {
	await axiosRequest({
		method: 'PUT',
		url: '/tasks/duplication/schedulers/bulk-update',
		data,
	})
}
/**
 *
 * @description Изменение статуса задачи
 */
export const enabledSchedulers = async ({enabled, taskDuplicationScheduleId}: {taskDuplicationScheduleId: string, enabled: boolean }) => {
	await axiosRequest<unknown>({
		method: 'PUT',
		url: `/tasks/duplication/schedulers/${taskDuplicationScheduleId}/enabled`,
		params: {enabled},
	})
}
/**
 *
 * @description Получение всех расписаний автосоздания задач
 */
export const get = {
	key: '/tasks/duplication/schedulers',
	fetch: async () => {
		const {data} = await axiosRequest<Schedules[]>({
			method: 'GET',
			url: '/tasks/duplication/schedulers',
			parser: SchedulersSchema.array(),
			showError: true
		})
		return data;
	},
	fetchOptions: async (): Promise<IOptions[]> => {
		const {data} = await axiosRequest<Schedules[]>({
			method: 'GET',
			url: '/tasks/duplication/schedulers',
			parser: SchedulersSchema.array(),
		})
		return data.map((el) => ({
			value: el.id,
			label: moment(el.createDateTime).format('DD.MM.YYYY')
		} as IOptions));
	}
}
