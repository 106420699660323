import {
    DownloadFileArchiveErrorAction,
    DownloadFileArchiveStartAction,
    DownloadFileArchiveSuccessAction
} from "../../store/project-reporting/actions";
import {TasksReportEstimateService} from "../../services/tasksReportService";
import {put} from "redux-saga/effects";
import {NotificationOpen} from "../../store/notification/actions";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";


export function* downloadFileArchive ({projectId}: ReturnType<typeof DownloadFileArchiveStartAction>) {
    try {
        yield TasksReportEstimateService.getFileArchive(projectId)
        yield put(DownloadFileArchiveSuccessAction())
        yield put(NotificationOpen('success', ' Архив отправлен на формирование'))
    } catch (error: any) {
        yield put(DownloadFileArchiveErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Нет одобренных записей для формирования архива'))
    }
}