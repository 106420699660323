import {TasksRecordsActionTypes} from "../action-types/task-records";
import {TTaskRecordsFilter} from "models/task/task-list";
import {ReportType} from "models/task/types";
import {Nullable} from "models/common";
import {TaskTypes} from "entities/task";

export const GetTasksRecordsStartAction = () => ({
    type: TasksRecordsActionTypes.GET_TASKS_STAT,
})
export const GetTasksRecordsSuccessAction = (
    data: TaskTypes.TaskRecordsResponse<
      TaskTypes.ContentRecords[] |
      TaskTypes.PostingRecords[] |
      TaskTypes.ReachRecords[] |
      TaskTypes.EstimateRecords[] |
      TaskTypes.CopyrightRecords[] |
      null
    >
) => ({
    type: TasksRecordsActionTypes.GET_TASKS_SUCCESS,
    data
})
export const GetTasksRecordsErrorAction = (error: any) => ({
    type: TasksRecordsActionTypes.GET_TASKS_ERROR,
    error
})
export const SetTasksRecordsFilterAction = (
    {filter, typeTask = null, typeReport = null}: {
        filter: TTaskRecordsFilter,
        typeTask?: Nullable<{ id: string, code: 'REPORT' | 'CONTENT' }>,
        typeReport?: Nullable<{ id: string, code: ReportType }>,
    }
) => ({
    type: TasksRecordsActionTypes.SET_TASK_FILTER,
    filter, typeTask, typeReport
})
export const SetTasksRecordsSearchFilterAction = (filter: TTaskRecordsFilter) => ({
    type: TasksRecordsActionTypes.SET_TASK_SEARCH_FILTER,
    filter
})

export const ClearTasksRecordsFilterAction = () => ({
    type: TasksRecordsActionTypes.CLEAR_TASK_FILTER,
})

export const ResetTasksFilterAction = (
    filter: TTaskRecordsFilter,
    typeTask: Nullable<{ id: string, code: 'REPORT' | 'CONTENT' }>,
    typeReport: Nullable<{ id: string, code: ReportType }>
) => ({
    type: TasksRecordsActionTypes.RESET_TASK_FILTER,
    filter, typeReport, typeTask
})
export const SetBulkAction = (bulkAction?: string) => ({
    type: TasksRecordsActionTypes.SET_BULK_ACTION,
    bulkAction
})
export const SetRowsIds = (rows: string[]) => ({
    type: TasksRecordsActionTypes.SET_ROWS_IDS,
    rows
})