import { FunctionalityInfoType } from '../types'

export const analyticsCoverageData: FunctionalityInfoType = [
	{
		LevelGroupName: 'Фильтрация данных по охватам',
		LevelGroupData: [
			{
				name: 'Фильтрация данных по охватам для получения графика',
				points: [
					'Нажмите на кнопку',
					'Выберите значение из выпадающего списка',
					'Настройте период вывода данных',
					'Нажмите "Построить график"',
				],
				screenshots: ['/images/screenshots/analytics-coverage-data/filters.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
