import React, {FC, useMemo} from 'react';
import './index.less'
import {Empty, Modal} from "antd";
import useHandlerModal from "hooks/handlModal";
import {FamousPeople} from "models/projects";
import {UserOutlined} from "@ant-design/icons";


type ModalFamousPeopleProps = {
    status: boolean,
    data?: FamousPeople[]
}
type PeopleList = {
    [key: string]: string[]
}
const ModalFamousPeople: FC<ModalFamousPeopleProps> = ({status, data }) => {
    const {isVisible, closeModal} = useHandlerModal(status);
    const list: PeopleList = useMemo(() => {
        let people: PeopleList = {};
        data?.forEach((el) => {
            const key = el?.rank?.name;
            if (people[key] === undefined) people[key] = [el.famousPerson.fullName]
            else people[key].push(el.famousPerson.fullName)
        })
        return people;
    }, [data]);
    const group = Object.keys(list);

    return (
        <Modal
            className='modal-famousPeople'
            title="Известные люди проекта"
            visible={isVisible}
            footer={null}
            maskClosable={true}
            onCancel={closeModal}
        >
            <div className='content'>
                {!data?.length && <Empty/>}
                {!!data?.length && group.map((el) => (
                    <div className="item" key={el}>
                        <div className="title">{el}</div>
                        <ul className="users">
                            {list[el].map((user, index) => (<User name={user} key={index}/>))}
                        </ul>
                    </div>
                ))}
            </div>
        </Modal>
    )
};
function User({name}: {name: string}) {
    return <li><UserOutlined /> {name}</li>
}
export default ModalFamousPeople;
