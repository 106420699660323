import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteThematicErrorAction,
    DeleteThematicStartAction,
    DeleteThematicSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import HandbookService from "services/handbookService";

export function* deleteThematic({id}: ReturnType<typeof DeleteThematicStartAction>) {
    try {
        yield HandbookService.deleteThematicRecord(id)
        yield put(DeleteThematicSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.thematic))
        yield put(NotificationOpen('success', 'Тематика удалена'))

    } catch (error: any) {
        yield put(DeleteThematicErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить тематику'))
    }
}
