import {ArchiveActionTypes} from "./action-types";
import {TArchiveFilter, TArchiveResponse} from "../../models/archive";


export const GetArchiveListStartAction = () => ({
    type: ArchiveActionTypes.GET_ARCHIVE_LIST_START
})
export const GetArchiveListSuccessAction = (data:TArchiveResponse) => ({
    type: ArchiveActionTypes.GET_ARCHIVE_LIST_SUCCESS,
    data
});
export const GetArchiveListErrorAction = (error: any) => ({
    type: ArchiveActionTypes.GET_ARCHIVE_LIST_ERROR,
    error,
});

export const DeleteRecordStartAction = (id: string) => ({
    type: ArchiveActionTypes.DELETE_ARCHIVE_RECORD_START,
    id
})
export const DeleteRecordSuccessAction = () => ({
    type: ArchiveActionTypes.DELETE_ARCHIVE_RECORD_SUCCESS
});
export const DeleteRecordErrorAction = (error: any) => ({
    type: ArchiveActionTypes.DELETE_ARCHIVE_RECORD_ERROR,
    error,
});

export const DeleteRecordsStartAction = (ids: string[]) => ({
    type: ArchiveActionTypes.DELETE_ARCHIVE_RECORDS_START,
    ids
})
export const DeleteRecordsSuccessAction = () => ({
    type: ArchiveActionTypes.DELETE_ARCHIVE_RECORDS_SUCCESS
});
export const DeleteRecordsErrorAction = (error: any) => ({
    type: ArchiveActionTypes.DELETE_ARCHIVE_RECORDS_ERROR,
    error,
});

/**
 * Изменение фильтра
 */
export const SetArchiveFilter = (filter: TArchiveFilter) => ({
    type: ArchiveActionTypes.SET_ARCHIVE_FILTER,
    filter,
})