import {takeLatest} from 'redux-saga/effects'
import {TagsActionTypes} from "../../store/tags/action-types";
import getFirstLevelThematics from "./getFirstLevelThematics";
import getSecondLevelWithFirst from "./getSecondLevelWithFirst";
import getFirstLevelTaskRecordContent from "./getFirstLevelTaskRecordContent";
import getSecondLevelTaskRecordContent from "./getSecondLevelTaskRecordContent";
import getSecondLevelContent from "./getSecondLevelContent";
import getFirstLevelContent from "./getFirstLevelContent";
import getThirdLevelTaskRecordContent from "./getThirdLevelTaskRecordContent";

export default function* tagsSaga() {
	yield takeLatest(TagsActionTypes.GET_TAGS_FIRST_LEVEL_THEMATICS_START, getFirstLevelThematics)
	yield takeLatest(TagsActionTypes.GET_TAGS_SECOND_LEVEL_WITH_FIRST_START, getSecondLevelWithFirst)
	yield takeLatest(TagsActionTypes.GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_START, getFirstLevelTaskRecordContent)
	yield takeLatest(TagsActionTypes.GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_START, getSecondLevelTaskRecordContent)
	yield takeLatest(TagsActionTypes.GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_START, getThirdLevelTaskRecordContent)
	yield takeLatest(TagsActionTypes.GET_TAGS_SECOND_LEVEL_CONTENT_START, getSecondLevelContent)
	yield takeLatest(TagsActionTypes.GET_TAGS_FIRST_LEVEL_CONTENT_START, getFirstLevelContent)

}
