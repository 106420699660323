import {put, select} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {TaskTypes, TaskModel} from "entities/task/index";
import TasksService from "services/tasksService";
import {GetAllTasksStartAction, GetTaskStartAction} from "store/tasks/actions/tasks";
import {selectParams} from "store/selectors";
import {GetTasksKanbanStartAction} from "store/tasks/actions/gant-kanban";
import ZodParser from "shared/services/zod-parser";

export function* getCommentsWorker(_action: ReturnType<typeof TaskModel.actions.Get>) {
    try {
        const filter: TaskTypes.Filter = yield select(TaskModel.selectors.filter);

        const {data}: AxiosResponse<TaskTypes.CommentsResponse> = yield TasksService.getCommentsTask(filter)
        ZodParser.parse(TaskTypes.CommentSchema.array(), data.content,'getCommentsWorker getComments')

        yield put(TaskModel.actions.SetInfo({
            isLoading: false,
            error: null,
            comments: data.content,
            filter: {
                ...filter,
                totalCount: data.totalCount
            }
        }))
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
    }
}

export function* createCommentsWorker({data}: ReturnType<typeof TaskModel.actions.Create>) {
    try {
        const {id: projectId} =  yield select(selectParams)
        const location = window.location.href.split('/');
        const page = location[3]

        yield TasksService.createCommentsTask(data)
        yield put(TaskModel.actions.Get())
        if (page === 'project' && projectId) {
            yield put(GetTasksKanbanStartAction(projectId))
        } else if (page === 'tasks') {
            yield put(GetAllTasksStartAction())
        } else {
            yield put(GetTaskStartAction(data.taskId))
        }
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
    }
}

export function* updateCommentsWorker({id, data}: ReturnType<typeof TaskModel.actions.Update>) {
    try {
        const {id: projectId} =  yield select(selectParams)
        const location = window.location.href.split('/');
        const page = location[3]

        yield TasksService.updateCommentsTask(id,data)
        yield put(TaskModel.actions.Get())
        if (page === 'project' && projectId) {
            yield put(GetTasksKanbanStartAction(projectId))
        } else if (page === 'tasks') {
            yield put(GetAllTasksStartAction())
        } else {
            yield put(GetTaskStartAction(data.taskId))
        }
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
    }
}

export function* deleteCommentsWorker({id, taskId}: ReturnType<typeof TaskModel.actions.Delete>) {
    try {
        const {id: projectId} =  yield select(selectParams)
        const location = window.location.href.split('/');
        const page = location[3]

        yield TasksService.deleteCommentsTask(id)
        yield put(TaskModel.actions.Get())
        if (page === 'project' && projectId) {
            yield put(GetTasksKanbanStartAction(projectId))
        } else if (page === 'tasks') {
            yield put(GetAllTasksStartAction())
        } else {
            yield put(GetTaskStartAction(taskId))
        }
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
    }
}