import React, {FC, SetStateAction} from 'react';
import styles from '../modal.module.less'
//antd
import {Button} from "antd";
//store
import {selectUser} from "store/auth/selectors";
//other
import moment from "moment";
import {TaskTypes} from 'entities/task'
import classNames from "classnames";
import {useSelector} from "react-redux";
import InnerHTMLFunction from "utils/innerHTMLFunction";

type TProps = {
    comment: TaskTypes.Comment
    handlerType: (value: SetStateAction<TaskTypes.TypeAction>) => void
    handlerDelete: () => void
    handlerRef: (value: TaskTypes.Comment) => void
    type: TaskTypes.TypeAction
}

const Comment:FC<TProps> = ({comment, handlerType, handlerDelete, type,handlerRef}) => {
    const user = useSelector(selectUser)
    const onClickChangeButton = () => {
        handlerType('UPDATE')
        handlerRef(comment)
    }

    return (
        <div className={styles.comment}>
            <div className={styles.circle}/>
            <div className={styles.info_container}>
                <div className={styles.info}>
                    <span className={styles.name}>{comment?.user?.lastName} {comment?.user?.firstName}</span>
                    <span className={styles.date}>{moment(comment.updateDateTime || comment.createDateTime).format('LL в H:mm')}</span>
                </div>
                <span className={styles.text} dangerouslySetInnerHTML={InnerHTMLFunction(comment.text)}/>
                {user?.id === comment.user.id && (
                    <div>
                        <Button  className={classNames(styles.button_change,{disabled: type === 'UPDATE'})} disabled={type === 'UPDATE'} onClick={onClickChangeButton}>
                            Изменить
                        </Button>
                        <Button  className={classNames(styles.button_delete,{disabled: type === 'UPDATE'})} disabled={type === 'UPDATE'} onClick={handlerDelete}>
                            Удалить
                        </Button>
                    </div>
                )}
            </div>

        </div>
    );
};

export default Comment;