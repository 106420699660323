import React, {FC, useState} from 'react'
import 'moment/locale/ru';
import moment from 'moment'
import locale from "antd/es/date-picker/locale/ru_RU";
import { ErrorMessage, Field, useFormikContext } from 'formik'
import TextError from './text-error'
import { FieldAttributes } from 'formik/dist/Field'
import { DatePicker } from 'antd'
import { IDate } from 'models/feels'
import { dateFormatList } from 'utils/varibales'

const { RangePicker } = DatePicker

const DatePickerComponent: FC<IDate> = ({
	label,
	name,
	range,
	isAfter,
	isBefore,
	disabled,
	errorMessage,
	isAfterDate,
	isBeforeDate,
	handler,
	showError = true
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const { setFieldValue, setTouched, touched, getFieldMeta } = useFormikContext()
	const handleChange = (date: any, dateString: any) => {
		const arr = dateString.split('.')
		const dateFormat = arr.reverse().join('-')
		setFieldValue(name, dateString ? new Date(dateFormat) : null)
		handler && handler(dateString ? new Date(dateFormat) : null)
	}

	const handleChangeRange = (date: any, dateString: any) => {
		const date1 = date[0]
		const date2 = date[1]
		setFieldValue(name, dateString ? [date1._d, date2._d] : null)
		handler && handler(dateString ? [date1._d, date2._d] : null)
	}

	const onDatePickerPanelChange = (event: any) => {
		let value = event?.target?.value
		const reg = /^\d{2}\.\d{2}\.\d{4}$/ // Проверяет, что строка - это дата в формате "DD.MM.YYYY"

		if (value && !reg.test(value)) return

		if (event?.target?.value && isOpen) {
			const dateString = event.target.value;
			const arr = dateString.split('.')

			// Проверяем год, если он меньше 2000
			if (parseInt(arr[2], 10) < 2000) return

			const dateFormat = arr.reverse().join('-')
			setFieldValue(name, dateString ? new Date(dateFormat) : null)

			const dateFromState = getFieldMeta(name).value;
			try {
				if (new Date(dateFormat).toISOString() !== (dateFromState as Date).toISOString()) {
					setIsOpen(false)
				}
			} catch (error) {
				console.error(error)
			}
		}
	}

	const onDatePickerOpenChange = (isOpen: boolean) => {
		setIsOpen(isOpen)
	}

	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			<Field className="form-control" name={name}>
				{(el: FieldAttributes<any>) => {
					const isError = errorMessage ? errorMessage : el.meta.error
					const isTouched = el.form.touched[name] ? el.form.touched[name] : el.meta.touched
					return (
						<>
							{range ? (
								<RangePicker
									onBlur={(_event) => {
										setTouched({ ...el.form.touched, [name]: true })
									}}
									className={`${isTouched && isError ? 'error' : ''}`}
									disabled={disabled}
									disabledDate={(date: any) => {
										if (date && date.year() < 2000) {
											return true
										}
										// @ts-ignore
										const current = date ? new Date(date['_d']) : null
										if (isAfter && isBefore) {
											const dateAfter = isAfterDate ? new Date(isAfterDate) : null
											const dateBefore = isBeforeDate ? new Date(isBeforeDate) : null
											let startCheck = true
											let endCheck = true
											if (dateAfter) {
												startCheck = current
													? moment(current).format('YYYY-MM-DD') < moment(dateAfter).format('YYYY-MM-DD')
													: false
											}
											if (dateBefore) {
												endCheck = current
													? moment(current).format('YYYY-MM-DD') > moment(dateBefore).format('YYYY-MM-DD')
													: false
											}

											if (dateAfter && dateBefore) return startCheck || endCheck
											if (dateAfter) return startCheck
											if (dateBefore) return endCheck
											return false
										}
										if (isAfter) {
											const dateToday = isAfterDate ? new Date(isAfterDate) : new Date()
											if (date) return moment(dateToday).format('YYYY-MM-DD') > moment(current).format('YYYY-MM-DD')
											return false
										} else return false
									}}
									onChange={handleChangeRange}
									locale={locale}
								/>
							) : (
								<DatePicker
									onMouseLeave={onDatePickerPanelChange}
									onBlur={(_event) => {
										setTouched({ ...el.form.touched, [name]: true })
									}}
									className={`${isTouched && isError && showError ? 'error' : ''}`}
									disabled={disabled}
									disabledDate={(date: any) => {
										if (date && date.year() < 2000) {
											return true
										}
										// @ts-ignore
										const current = date ? new Date(date['_d']) : null
										if (isAfter && isBefore) {
											const dateAfter = isAfterDate ? new Date(isAfterDate) : null
											const dateBefore = isBeforeDate ? new Date(isBeforeDate) : null
											let startCheck = true
											let endCheck = true
											if (dateAfter) {
												startCheck = current
													? moment(current).format('YYYY-MM-DD') < moment(dateAfter).format('YYYY-MM-DD')
													: false
											}
											if (dateBefore) {
												endCheck = current
													? moment(current).format('YYYY-MM-DD') > moment(dateBefore).format('YYYY-MM-DD')
													: false
											}

											if (dateAfter && dateBefore) return startCheck || endCheck
											if (dateAfter) return startCheck
											if (dateBefore) return endCheck
											return false
										}
										if (isAfter) {
											const dateToday = isAfterDate ? new Date(isAfterDate) : new Date()
											if (date) return moment(dateToday).format('YYYY-MM-DD') > moment(current).format('YYYY-MM-DD')
											return false
										} else return false
									}}
									value={el.meta.value && moment(el.meta.value)}
									onChange={handleChange}
									format={dateFormatList}
									open={isOpen}
									onOpenChange={onDatePickerOpenChange}
									locale={locale}
								/>
							)}
							{isTouched && el.meta.error && <ErrorMessage name={name} component={TextError} />}
						</>
					)
				}}
			</Field>
			{/*// @ts-ignore*/}
			{errorMessage && touched[name] && <div className="invalid-feel">{errorMessage}</div>}
		</div>
	)
}

export default DatePickerComponent
