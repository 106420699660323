import React from 'react';
import {Radio, RadioChangeEvent, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import './index.less';
import {ExtendedSearchModel, ExtendedSearchTypes} from 'features/extended-search/index';
import {IOptions} from "models/feels";

const {Option} = Select;

type TKey = keyof Omit<
    ExtendedSearchTypes.Stores.IndicatorsContent, 'isToggled'>

const options: ExtendedSearchTypes.Stores.IndicatorsContentOptions = {
    isContent: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Контент', value: 'true'},
        {label: 'Рабочий материал', value: 'false'},
    ],
    isAccountedContent: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Учитываемый', value: 'TRUE'},
        {label: 'Неучитываемый', value: 'FALSE'},
        {label: 'Неопределённый', value: 'NULL'},
    ],
    isMasterContent: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Мастер файл', value: 'true'},
        {label: 'Предмастер файл', value: 'false'},
    ]
}
export const IndicatorContentItem = () => {
    return (
        <div className="item-wrapper IndicatorsContent">
            <Item
                options={options.isContent}
                label={'Тип материала'}
                name={'isContent'}
            />
            <Item
                options={options.isAccountedContent}
                label={'Тип учета'}
                name={'isAccountedContent'}
            />
            <Item
                options={options.isMasterContent}
                label={'Тип файла'}
                name={'isMasterContent'}
            />
            <SecondItem/>
        </div>
    )
}
type ItemProps = {
    options: IOptions[],
    name: TKey,
    label: string
}
function Item({options, name, label}: ItemProps) {
    const put = useDispatch();
    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const indicatorsContent = useSelector(ExtendedSearchModel.selectors.indicatorsContent)
    const onGroupChange = (e: RadioChangeEvent) => {
        put(ExtendedSearchModel.actions.setIndicatorsContent(typeContent, {[name]: e.target.value}))
    }
    return (
        <div className="item">
            <div className="label">{label}</div>
            <div className="form-group form-group--radio">
                <Radio.Group
                    value={indicatorsContent[typeContent][name]}
                    onChange={onGroupChange}
                >
                    {options.map((option: IOptions) => {
                        return (
                            <Radio
                                checked={indicatorsContent[typeContent][name] === (option.value)}
                                key={option.label}
                                value={option.value}
                            >
                                {option.label}
                            </Radio>
                        )
                    })}
                </Radio.Group>
            </div>
        </div>
    )
}

function SecondItem() {
    const put = useDispatch();

    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const values = useSelector(ExtendedSearchModel.selectors.indicatorsContent)
    function onSelectChange(value: string[]) {
        put(ExtendedSearchModel.actions.setValuesIndicatorsContent(typeContent, {
            contentStatusCodes: value
        }))
    }
    const data: IOptions[] = [
        {label: 'Согласовано', value: 'APPROVED'},
        {label: 'Отклонено', value: 'REJECTED'}
    ]

    return (
        <div className="item">
            <div className="label">Статус согласования</div>
            <div className="form-group">
                <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={true}
                    onChange={onSelectChange}
                    maxTagCount={"responsive"}
                    mode={"multiple"}
                    value={values[typeContent]['contentStatusCodes']}
                    placeholder={'Искать среди всех'}
                    filterOption={(input, option) => {
                        if (option === undefined || option === null) return false;
                        return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                >
                    {data && data.map((el) => (
                        <Option
                            key={el.value}
                            value={el.value}
                            data-name={el.label}
                        >
                            {el.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}