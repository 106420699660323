import {z} from "zod";

export const ContentDatesSchema = z.object({
    firstContentCreationDate: z.string().nullable(),
    allContentCreationDate: z.string().nullable(),
    firstContentPostingDate: z.string().nullable(),
    allContentPostingDate: z.string().nullable(),
    allContentReachFixDate: z.string().nullable(),
    licenseDate: z.string().nullable(),
});
export type ContentDates = z.infer<typeof ContentDatesSchema>;