import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetUserErrorAction,
    GetUserStartAction,
    GetUserSuccessAction
} from "store/options/actions/actions-users";
import UsersService from "services/usersService";
import {TUserForm} from "models/users-list/user-form";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {GetFileStartAction} from "store/files/actions";


export function* getUser({userId}: ReturnType<typeof GetUserStartAction>) {
    try {
        const { data: user}: AxiosResponse<TUserForm> = yield UsersService.getUser(userId);

        yield put(GetUserSuccessAction(user))
        if (user.image) {
            yield put(GetFileStartAction(user.image.id, user.image.type))
        }
    } catch (error: any) {
        yield put(GetUserErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить данные'))

    }
}
