export enum PUBLIC_PATH {
	APP = '/',
	LOGIN = '/login',
	ERROR = '/error',
	CONFIRM = '/confirm',
}

//content
export enum PROJECTS {
	PROJECTS = '/projects',
	PROJECTS_CREATE = '/project-create',
	PROJECTS_EDIT = '/project-edit',
	PROJECT = '/project',
	PROJECT_PARAMS = '/project/params',
	PROJECT_CALENDAR = '/project/calendar',
	PROJECT_TASKS = '/project/tasks',
	PROJECTS_REPORTING = '/project/reporting',
	PROJECTS_DOCUMENTS = '/project/documents',
}

//content
export enum CALENDAR {
	CALENDAR = '/calendar',
}
//tasks-page
export enum TASKS {
	TASKS = '/tasks',
	TASKS_PROJECTS = '/tasks/projects',
	TASKS_RECORDS = '/tasks/records',
	TASKS_SETTINGS = '/tasks/settings',
	TASK = '/task',
	CREATE_TASKS = '/create-task',
	TASK_EDIT = '/edit-task',
}

//Users-page
export enum OPTIONS {
	OPTIONS = '/options',
	OPTIONS_LIST = '/options/list',
	OPTIONS_ACTION_USER = '/options/action-user',
	OPTIONS_ACTION_USERS = '/options/actions-users',
	OPTIONS_NOTIFICATION = '/options/notifications',
	OPTIONS_HANDBOOK = '/options/handbook',
	OPTIONS_NOTIFICATION_EDIT = '/options/notifications/edit',
	OPTIONS_SETTINGS = '/options/personal-settings',
	OPTIONS_CREATE = '/options/create',
	OPTIONS_EDIT = '/options/edit',
}

export enum ACTIONS_USERS {
	ACTIONS = '/options/actions-users/actions',
	ACTIVITY = '/options/actions-users/activity',
}

export enum PERSONAL_SETTING {
	GENERAL = '/options/personal-settings/',
	CONTROL = '/options/personal-settings/control',
	CUSTOMIZATION = '/options/personal-settings/customization',
	PASSWORD = '/options/personal-settings/password',
	ACCESS_KEY = '/options/personal-settings/key'
}

//Handbook
export enum HANDBOOK {
	THEMATIC = '/options/handbook/thematic',
	INTERNET = '/options/handbook/internet',
	CONTENT_TYPE = '/options/handbook/content-type',
	CONTENT_CATEGORY = '/options/handbook/content-category',
	CONTENT_DELIVERY = '/options/handbook/content-delivery',
	ORGANIZATION = '/options/handbook/organization',
	ACTIONS_USERS = '/options/handbook/actions-users',
	KPE = '/options/handbook/KPE',
	DOCUMENTS = '/options/handbook/documents',
	CONTENT_DIRECTION = '/options/handbook/content-direction',
	ORGANIZATION_FORM = '/options/handbook/organization/form',
	PROJECT_TAGS = '/options/handbook/project-tags',
	REGIONS= '/options/handbook/regions',
	RANKS= '/options/handbook/ranks',
	FILM_CLUSTERS= '/options/handbook/film-clusters',
	LEGAL_FORM= '/options/handbook/legal-form-organization',
	SESSIONS_TYPE= '/options/handbook/sessions-type',
	CATEGORY_PROJECT= '/options/handbook/category-project',
	GENRE_PROJECT= '/options/handbook/genre-project',
	SESSIONS= '/options/handbook/sessions',
	CONTESTS= '/options/handbook/contests',
	ERV_RANGES= '/options/handbook/erv-evaluation-ranges',
	FAMOUS_PEOPLE= '/options/handbook/famous-people',
}
export enum ANALYTICS {
	ANALYTICS = '/analytics',
	ANALYTICS_INDICATORS = '/analytics/indicators',
	ANALYTICS_SUMMARY_DATA = '/analytics/summary-data',
	ANALYTICS_COVERAGE_DATA = '/analytics/coverage-data',
}

export enum REPORTING {
	REPORTING= '/reporting',
	REPORTING_REPORTS= '/reporting/reports',
	REPORTING_REPORTS_SCHEDULERS= '/reporting/reports-schedulers',
}

export enum CONTENT {
    CONTENT = '/content',
    CONTENT_INFO = '/content/info',
}