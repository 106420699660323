import { AxiosResponse } from 'axios'
import $api from '../http'
import { THandbookCodeNameHidden, THandbookContentResponse, THandbookFilter, TTagsResponse } from 'models/handbook'
import { T_ID_NAME_CODE_HIDDEN } from 'models/common'

export default class GenreProjectService {
	/**
	 * Получение всех жанров категорий проектов
	 */
	static async getGenreProject(): Promise<AxiosResponse<T_ID_NAME_CODE_HIDDEN[]>> {
		return $api.get('/genre-types')
	}
	/**
	 * Получение всех тегох жанров проектов c filters
	 */
	static async getGenreProjectWithFilter(data: THandbookFilter): Promise<AxiosResponse<THandbookContentResponse>> {
		const filter = { ...data, page: data.page - 1 }
		return $api.post<TTagsResponse>('/genre-types/search', filter)
	}
	/**
	 *  Добавление тега жанров проектов
	 */
	static async addIGenreProjectRecord(data: THandbookCodeNameHidden): Promise<AxiosResponse<string>> {
		return $api.post<string>(`/genre-types`, data)
	}
	/**
	 *   Обновление тега жанров проектов
	 */
	static async updateGenreProjectRecord(data: THandbookCodeNameHidden, id: string): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/genre-types/${id}`, data)
	}
	/**
	 *   Удаление тега жанров проектов
	 */
	static async deleteGenreProjectRecord(id: string): Promise<AxiosResponse<any>> {
		return $api.delete<any>(`/genre-types/${id}`)
	}
	/**
	 *   Массовые действия тега жанров проектов
	 */
	static async bulkGenreProjectActions(recordsArr: string[], action: string): Promise<AxiosResponse<any>> {
		return $api.post<any>(`/genre-types/bulk-actions/${action}`, recordsArr)
	}
	/**
	 *   Выгрузка данных тега жанров проектов
	 */
	static async downloadGenreProject(data: string[]): Promise<AxiosResponse<any>> {
		return $api({
			method: 'POST',
			url: `/genre-types/export`,
			responseType: 'arraybuffer',
			headers: {
				'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
			data: data,
		})
	}
	/**
	 * Выполнение импорта данных тега жанров проектов из заполненного шаблона в формате Excel
	 */
	static async importGenreProject(data: any): Promise<AxiosResponse> {
		return $api({
			method: 'POST',
			url: `/genre-types/import`,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data,
		})
	}
	/**
	 *   Выгрузка шаблона
	 */
	static async downloadGenreProjectTemplate(): Promise<AxiosResponse<any>> {
		return $api({
			method: 'GET',
			url: '/genre-types/import/template',
			responseType: 'arraybuffer',
		})
	}
}
