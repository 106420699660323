import React from 'react'
import styles from './styles.module.less'
import {SelectComponent} from 'components/formic-control'
import {useReportsTemplates} from "../../lib/hooks/use-reports";

const Reports = () => {
	const { data: reports, isLoading } = useReportsTemplates()

	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Выберите отчет <sup>*</sup>
			</span>
			<div className={styles.field}>
				<SelectComponent
					options={reports || []}
					multi={true}
					maxTagCount={'responsive'}
					loading={isLoading}
					name={'reports'}
					placeholder={'Выберите отчеты для автоформирования'}
				/>
			</div>
		</div>
	)
}

export default Reports
