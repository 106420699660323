import React, { useState } from 'react'
import { Empty, Image, Spin } from 'antd'
import styles from './styles.module.less'
import env from 'shared/config/env'

type Props = {
	name: string
	id: string
}

function ImageComponent({ name, id }: Props) {
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	const onLoadHandler = () => {
		setLoading(false)
	}
	const onErrorHandler = () => {
		setError(true)
	}

	if (error) {
		return <Empty className={styles.error} description={'Не удалось загрузить изображение'} />
	}

	return (
		<Spin spinning={loading}>
			<Image
				src={`${env.API_ENDPOINT}/files/${id}`}
				alt={name}
				onLoad={onLoadHandler}
				onError={onErrorHandler}
				preview={{
					src: `${env.API_ENDPOINT}/files/${id}`
				}}
			/>
		</Spin>
	)
}
export default ImageComponent
