import {put} from "redux-saga/effects";
import {
    GetAllTasksStartAction,
    UpdateTaskErrorAction,
    UpdateTaskStartAction,
    UpdateTaskSuccessAction,
} from "store/tasks/actions/tasks";
import TasksService from "services/tasksService";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {GetTasksGantStartAction, GetTasksKanbanStartAction} from "store/tasks/actions/gant-kanban";


export function* updateTasks({taskId, task}: ReturnType<typeof UpdateTaskStartAction>) {
    try {
        yield TasksService.updateTask(taskId, task);
        yield put(UpdateTaskSuccessAction())
        yield put(NotificationOpen('success', 'Задача обновлена'))

        const location = window.location.href.split('/');
        const page = location[3];

        if (page === 'project') {
            const type = location[4].split('?')[0];
            if (type === 'tasks') {
                yield put(GetTasksKanbanStartAction(task.projectId))
            }
            if (type === 'calendar') yield put(GetTasksGantStartAction(task.projectId))
        }
        else if (page === 'tasks') yield put(GetAllTasksStartAction())
        else if (page === 'create-task') yield put(GetAllTasksStartAction())

    } catch (error: any) {
        yield put(UpdateTaskErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось изменить задачу'))
    }
}
