import * as Yup from "yup";
import {validationStepFirst} from "./validationStepFirst";
import { validationStepSecond } from "./validationStepSecond";
import {validationStepThird} from "./validationStepThird";
import {validationStepFourth} from "./validationStepFourth";
import {validationStepFifth} from "./validationStepFifth";

export const validationSchema = Yup.object({
    stepFirst: validationStepFirst,
    stepSecond: validationStepSecond,
    stepThird: validationStepThird,
    stepFourth: validationStepFourth,
    stepFifth: validationStepFifth,
});