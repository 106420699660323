import { FunctionalityInfoType } from '../types'

export const projectParams: FunctionalityInfoType = [
	{
		LevelGroupName: 'Редактирование данных проекта',
		LevelGroupData: [
			{
				name: 'Редактирование данных проекта',
				text: 'Нажмите для перехода в форму редактирования проекта. Для\u00A0редактирования проекта: ',
				points: [
					'Заполните обязательные и доступные поля формы на 1-5 вкладке',
					'Сохраните форму',
					'Нажмите "Список ошибок" в случае возникновения ошибок',
					'Исправьте перечисленные ошибки на вкладках формы',
					'Сохраните форму повторно',
				],
				topIcons: ['edit-blue'],
				screenshots: ['/images/screenshots/project-form.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр параметров проекта',
		LevelGroupData: [
			{
				name: 'Просмотр интернет-ресурсов проекта',
				text: 'Нажмите для перехода в интернет-ресурсы проекта ',
				screenshots: ['/images/screenshots/project-params/internet.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр участников проекта',
				text: 'Нажмите на кнопку "Показать всех" для просмотра участников проекта',
				screenshots: ['/images/screenshots/project-params/button-person.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр единиц контента проекта',
				text: 'Нажмите "Посмотреть требования" для просмотра данных в модальном окне',
				screenshots: ['/images/screenshots/project-params/button-content.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр известных людей проекта',
				text: 'Нажмите "Посмотреть известных людей проекта" для просмотра данных в модальном окне',
				screenshots: ['/images/screenshots/project-params/button-people.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр наград проекта',
				text: 'Нажмите "Посмотреть награды" для просмотра данных в модальном окне',
				screenshots: ['/images/screenshots/project-params/button-awards.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр графика реализации проекта',
				text: 'Нажмите "Посмотреть сроки создания и размещения контента" для просмотра данных в модальном окне',
				screenshots: ['/images/screenshots/project-params/button-realization.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Работа с контентом проекта',
		LevelGroupData: [
			{
				name: 'Просмотр контента проекта',
				text: 'Нажмите "Посмотреть согласованный контент проекта" для просмотра данных в модальном окне',
				screenshots: [
					'/images/screenshots/project-params/button-content-project.png',
					'/images/screenshots/view-content-modal.png',
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход в карточку контента по кнопке "Посмотреть контент" из модального окна просмотра контента проекта',
				text: 'Нажмите "Посмотреть контент" для перехода в карточку контента проекта в окне "Список единиц контента проекта"',
				screenshots: ['/images/screenshots/content-button.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Скачивание единиц контента из модального окна просмотра контента проекта',
				text: 'Для скачивания единиц контента проекта:',
				points: [
					'Нажмите "Посмотреть согласованный контент проекта" для просмотра согласованного контента проекта в модальном окне "Список единиц контента проекта"',
					'Нажмите чекбокс в баннере "Согласен с положениями Руководства по использованию контента"',
					'Выберите единицу контента из списка и нажмите иконку "Скачать" либо используйте массовое действие "Сформировать архив контента для скачивания"',
				],
				screenshots: ['/images/screenshots/view-content-modal.png'],
				roles: [
					'ROLE_MODERATOR',
					'ROLE_ANALYST',
					'ROLE_ADMIN',
					'ROLE_LAWYER',
					'ROLE_AGENT',
					'ROLE_PROJECT_MANAGER',
					'ROLE_CLIENT',
					'ROLE_ADMIN_IS',
					'ROLE_PRODUCER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
				],
			},
			{
				name: 'Массовое скачивание единиц контента из модального окна просмотра контента проекта',
				text: 'Для массового скачивания единиц контента проекта:',
				points: [
					'Нажмите "Массовые действия" в модальном окне "Список единиц контента проекта"',
					'Нажмите "Сформировать архив контента для скачивания"',
					'Нажмите чекбоксы (галочки) для выбора единиц контента для скачивания',
					'Нажмите "Применить"',
				],
				screenshots: ['/images/screenshots/project-params/bulk-actions.png'],
				roles: [
					'ROLE_MODERATOR',
					'ROLE_ANALYST',
					'ROLE_ADMIN',
					'ROLE_LAWYER',
					'ROLE_AGENT',
					'ROLE_PROJECT_MANAGER',
					'ROLE_CLIENT',
					'ROLE_ADMIN_IS',
					'ROLE_PRODUCER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
				],
			},
		],
	},
]
