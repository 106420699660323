import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetActiveSyncedUsersErrorAction,
    GetActiveSyncedUsersStartAction,
    GetActiveSyncedUsersSuccessAction
} from "store/options/actions/actions-users";
import UsersService from "services/usersService";
import {UserTypes} from "entities/user";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";


export function* getActiveSyncedUsers(_: ReturnType<typeof GetActiveSyncedUsersStartAction>) {
    try {
        const { data: user}: AxiosResponse<UserTypes.User[]> = yield UsersService.getListActiveSyncedUsers();

        yield put(GetActiveSyncedUsersSuccessAction(user))
    } catch (error: any) {
        yield put(GetActiveSyncedUsersErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить данные'))

    }
}
