export const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export const emailRegExp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)


export const matchDuplicate = (arr: string[], value: string, editInputIndex: number) => {
    let isValid = false
    let length = arr.length
    if(length) {
        do {
            if(arr.includes(value) && editInputIndex !== arr.indexOf(value)) isValid = true
            length --;
        } while (isValid && length !== 0)

    }
    return isValid

}