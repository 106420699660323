import {put} from 'redux-saga/effects'
import {
	BulkRanksActionsErrorAction,
	BulkRanksActionsStartAction,
	BulkRanksActionsSuccessAction,
	GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import RanksService from "services/ranksService";

export function* bulkRanksActions({ userArr, action }: ReturnType<typeof BulkRanksActionsStartAction>) {
	try {
		yield RanksService.bulkRanksActions(userArr, action)

		yield put(BulkRanksActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.ranks))
		yield put(NotificationOpen('success', 'Обновлено успешно'))

	} catch (error: any) {
		yield put(BulkRanksActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}
}
