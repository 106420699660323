import { SearchContentProjectTypes } from "features/search-project-content";
import {ActionTypes} from "./types";


export const setContent = <T extends Partial<SearchContentProjectTypes.Stores.TContentState["content"]>>(
    typeContent: SearchContentProjectTypes.Stores.TTypeStore, value: T
) => ({
    type: ActionTypes.SET_VALUE, typeContent, value
})
export const setValuesContent = <T extends Partial<Omit<SearchContentProjectTypes.Stores.Content, 'isToggled'>>>(
    typeContent: SearchContentProjectTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUES, typeContent, value, isToggled
})
export const clearContent = (typeContent: SearchContentProjectTypes.Stores.TTypeStore) => ({
    type: ActionTypes.CLEAR_VALUE, typeContent
})