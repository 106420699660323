import { AxiosResponse } from 'axios'
import { put, select } from 'redux-saga/effects'
import { GetSprintsErrorAction, GetSprintsStartAction, GetSprintsSuccessAction } from 'store/sprints/actions'
import SprintService from 'services/sprintService'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import { NotificationOpen } from 'store/notification/actions'
import { selectParams } from 'store/selectors'
import { SprintTypes } from 'entities/sprints'

export default function* getSprints({ projectId }: ReturnType<typeof GetSprintsStartAction>) {
	try {
		const { id } = yield select(selectParams)

		const { data }: AxiosResponse<SprintTypes.SprintProject[]> = projectId ? yield SprintService.getSprints(projectId) : id ? yield SprintService.getSprints(id): null
		yield put(GetSprintsSuccessAction(data))
	} catch (error: any) {
		yield put(GetSprintsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
