import {useState} from "react";
import {DownloadElement} from "utils/createObjUrlFunction";
import {useDispatch} from "react-redux";
import {NotificationOpen} from "../store/notification/actions";

export function useDownload() {
    const put = useDispatch()
    const [loading, setLoading] = useState(false)

    function download(fileId: string, fileName: string) {
        if (!fileId) {
            put(NotificationOpen('error', 'Ошибка', 'Отсутствует файл для загрузки'))
            return
        }
        setLoading(true)
        DownloadElement(fileId, fileName)
        setLoading(false)
    }

    return {loading, download}
}