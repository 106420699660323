import { UserTypes } from 'entities/user';
import {createSelector} from 'reselect';
import {TApplicationState} from '../aplication-state';

const getAuthState = (state: TApplicationState) => state.auth;

export const selectIsLogin = createSelector(
    getAuthState,
    (state) => state.isLogin
)

export const selectGeneratedKey = createSelector(
    getAuthState,
    (state) => state.generatedKey
)

export const selectReceivedKey = createSelector(
    getAuthState,
    (state) => state.receivedKey
)

export const selectUser = createSelector(
    getAuthState,
    (state) => state.user
)
export const selectDownloadRights = createSelector(
    getAuthState,
    (state) => state.downloadRights
)
export const selectUserInfo = createSelector(
    getAuthState,
    (state) => ({
       user: state.user,
       confirmAgreement: state.user?.isUserAgreementConfirmed,
       confirmReceiving: state.user?.isReceivingNotificationsConfirmed
    })
)

export const selectUserConfirmAgreement = createSelector(
    getAuthState,
    (state) => state.user?.isUserAgreementConfirmed
)

export const selectUserEmail = createSelector(
    getAuthState,
    (state) => state.user?.personalData?.email
)

export const selectUserConfirmEmail = createSelector(
    getAuthState,
    (state) => state.user?.emailConfirmed || false
)

export const selectUserConfirmAllEmail = createSelector(
    getAuthState,
    (state) => state.user?.allMainEmailsConfirmed || false
)

export const selectUserConfirmTelegram = createSelector(
    getAuthState,
    (state) => state.user?.telegramConfirmed || false
)

export const selectUserConfirm = createSelector(
    selectUserConfirmEmail,
    selectUserConfirmTelegram,
    (emailConfirmed, telegramConfirmed) => ({
        emailConfirmed,
        telegramConfirmed
    })
)

export const selectAuthStatus = createSelector(
    getAuthState,
    (state) => ({
        isLoading: state.isLoading,
        error: state.error,
        restore: state.restore
    })
)
export const selectRole = createSelector(
    getAuthState,
    (state) => state.user?.role
)
export const selectRoleWithOptionalParams = createSelector(
    getAuthState,
    (state) => ({
        role: state.user?.role as UserTypes.RoleName | undefined,
        contentViewAccess: state.user?.contentViewAccess,
        downloadAccess: state.user?.contentDownloadAccess,
        storageLinkAccess: state.user?.contentStorageLinkAccess,
        userId: state.user?.id
    })
)
export const selectUserId = createSelector(
    getAuthState,
    (state) => state.user?.id
)
