import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetAllContentDeliveryErrorAction,
    GetAllContentDeliveryStartAction,
    GetAllContentDeliverySuccessAction
} from "store/options/actions/actions-handbook";
import HandbookService from "services/handbookService";
import {TTHandbookContentOverAll} from "models/handbook";
import {AxiosResponse} from "axios";


export function* getAllContentDelivery(_: ReturnType<typeof GetAllContentDeliveryStartAction>) {
    try {
        const {data }: AxiosResponse<TTHandbookContentOverAll[]> =  yield HandbookService.getAllContentDeliveryRecord()
        yield put(GetAllContentDeliverySuccessAction(data))

    } catch (error: any) {
        yield put(GetAllContentDeliveryErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить все типы форм сдачи'))
    }
}
