import React, { FC, useEffect } from 'react'
import { FieldArray, useFormikContext } from 'formik'

import { CheckboxComponent } from '../../../formic-control'
import { useSelector } from 'react-redux'
import { Col } from 'antd'
import { selectTask } from 'store/tasks/selectors/selectors-task'
import InnerHTMLFunction from 'utils/innerHTMLFunction'
import { ProjectTypes } from 'entities/project'
import { UserTypes } from 'entities/user'

function filingName(key: string): string {
	return `stepThird.${key}.value`
}

const getNameRole = (type: 'producers' | 'coordinators' | 'analysts' | 'financiers' | 'contentEditors' | 'projectManagers' | 'lawyers' | 'contractors') => {
	switch (type) {
		case 'analysts':
			return 'Аналитик<sup>*</sup>'
		case 'coordinators':
			return 'Координатор'
		case 'financiers':
			return 'Финансист<sup>*</sup>'
		case 'producers':
			return 'Продюсер<sup>*</sup>'
		case 'contentEditors':
			return 'Редактор контента<sup>*</sup>'
		case 'projectManagers':
			return 'Менеджер проекта<sup>*</sup>'
		default:
			return ''
	}
}

type CheckingCoordinatorProps = {
	fieldRole:
		| 'producers'
		| 'coordinators'
		| 'analysts'
		| 'financiers'
		| 'contentEditors'
		| 'projectManagers'
		| 'lawyers'
		| 'contractors'
	disabled?: boolean
	definitionSidebar?: boolean
	isCreated: boolean
	length: number | null
	project: ProjectTypes.MapProjectUsers
	role: UserTypes.RoleName
	hide?: boolean
	id?: string | null | number
}

const ChooseRole: FC<CheckingCoordinatorProps> = ({
	fieldRole,
	role,
	disabled,
	definitionSidebar,
	isCreated,
	length,
	project,
	hide,
	id,
}) => {
	const { setFieldValue } = useFormikContext()
	const task = useSelector(selectTask)

	const getRoleObject = (el: any) => ({
		isMain: false,
		user: el.id,
	})

	const getIsRoleArray = () =>
		project?.get(role)?.reduce((acc: any, el: any) => {
			acc = {
				...acc,
				[el.id]: getRoleObject(el),
			}
			return acc
		}, {})

	/**
	 * Создает аккумулированный массив ролей из задачи и проекта.
	 *
	 * task - Объект задачи, содержащий массив ролей.
	 * project - Объект проекта, содержащий карту пользователей.
	 * @returns - Объект, где ключи - это идентификаторы ролей, а значения - объекты с флагом
	 * isMain и идентификатором пользователя.
	 */
	const getAccumulatedArray = (task: any, project: ProjectTypes.MapProjectUsers) => {
		const personsTask = task[fieldRole] || [] // список пользователей из задачи по ключу например (fieldRole = producers)
		const personsProject = project?.get(role) || [] // список пользователей из проекта по ключу например (role = ROLE_PRODUCER)

		const filteredPersonsProject = personsProject //personsProject фильтруем чтобы не было совпадений с personsTask
			.filter((projectItem: any) => !personsTask.some((taskItem: any) => taskItem.id === projectItem.id))

		return [...personsTask, ...filteredPersonsProject].reduce((acc: any, el: any) => {
			//создаем обьект где ключ будее id: {user: id, isMain: boolean} isMain - true если присутствует в списке personsTask}
			acc[el.id] = {
				isMain: personsTask.some((taskItem: any) => taskItem.id === el.id),
				user: el.id,
			}
			return acc
		}, {})
	}

	//todo add types
	const handler = (project: any, task: any, length: number | null) => {
		const isRoleArray = getIsRoleArray()

		if (isCreated && task && project) {
			const accumulatedArray = getAccumulatedArray(task, project)
			setFieldValue(filingName(fieldRole), accumulatedArray)
		} else if (!isCreated && isRoleArray && length !== project?.get(role)?.length) {
			setFieldValue(filingName(fieldRole), isRoleArray)
		}
	}

	useEffect(() => {
		handler(project, task, length)
		// eslint-disable-next-line
	}, [project, fieldRole, length, task, role])

	if (!id || !project) return null
	if (hide) return null

	return (
		<FieldArray
			key={fieldRole}
			name={fieldRole}
			render={(_props) => {
				return (
					<React.Fragment key={fieldRole}>
						{project && project?.get(role)?.length && (
							<>
								<Col
									md={definitionSidebar ? 5 : 4}
									xs={24}
									className="label"
									dangerouslySetInnerHTML={InnerHTMLFunction(getNameRole(fieldRole))}
								/>
								<Col md={19} xs={24}>
									<div className={`taskForm-form__checkbox ${definitionSidebar && 'taskForm-form__mobile--checkbox'}`}>
										{project &&
											project?.get(role)?.map((it: any) => {
												return (
													<div key={it.id} className="taskForm-form__checkbox--item">
														<CheckboxComponent
															name={`[${filingName(fieldRole)}][${it.id}].isMain`}
															disabled={disabled}
														/>
														<span className="span">
															{it.firstName} {it.lastName} {it.middleName || ''}
														</span>
													</div>
												)
											})}
									</div>
								</Col>
							</>
						)}
					</React.Fragment>
				)
			}}
		/>
	)
}
export default React.memo(ChooseRole)
