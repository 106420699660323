import React, {useRef} from 'react'
import styles from './styles.module.less'
import {SelectComponent} from 'components/formic-control'
import {useQuery} from 'react-query'
import TasksService from 'services/tasksService'
import {IOptions} from 'models/feels'
import {TaskTypes} from 'entities/task'
import {useFormikContext} from 'formik'
import {TForm} from './modal'
import {DeleteOutlined, FilterOutlined} from '@ant-design/icons'
import {Popover, Tooltip} from 'antd'
import PopoverContent from './popover-content'

export type PopoverRefType = {
	sessionIds?: TaskTypes.TaskSearchDto['sessionIds']
	contestIds?: TaskTypes.TaskSearchDto['contestIds']
	projectIds?: TaskTypes.TaskSearchDto['projectIds']
}
const Tasks = () => {
	const { values, setFieldValue } = useFormikContext<TForm>()
	const enabled = values.enabled
	const popoverRef = useRef<PopoverRefType>({})

	const { data: tasks, isLoading } = useQuery({
		queryKey: [TasksService.getAllTasksKey, TaskTypes.TypesTask.REPORT, TaskTypes.ReportType.CONTENT_REACH_REPORT],
		queryFn: async () => {
			const { data } = await TasksService.getAllTasks({
				typeCodes: [TaskTypes.TypesTask.REPORT],
				reportTypeCodes: [TaskTypes.ReportType.CONTENT_REACH_REPORT],
				isSchedulerExist: false,
				...(popoverRef.current.contestIds?.length && { contestIds: popoverRef.current.contestIds }),
				...(popoverRef.current.sessionIds?.length && { sessionIds: popoverRef.current.sessionIds }),
				...(popoverRef.current.projectIds?.length && { projectIds: popoverRef.current.projectIds }),
			})
			return data.map((el) => ({ value: el.id, label: el.name } as IOptions))
		},
	})
	const handlerChangeRef = (value: PopoverRefType) => {
		popoverRef.current = { ...popoverRef.current, ...value }
	}

	const handlerDelete = (task: IOptions) => () => {
		const filteredTasks = values?.tasks.filter((it) => it.value !== task.value)
		setFieldValue('tasks', filteredTasks)
	}
	return (
		<>
			<div className={styles.item}>
				<span className={styles.label}>
					Задачи <sup>*</sup>
				</span>
				<div className={styles.field}>
					<Popover content={<PopoverContent handlerChangeRef={handlerChangeRef} />} placement={'right'}>
						<FilterOutlined className={styles.select_suffix} />
					</Popover>
					<SelectComponent
						options={tasks || []}
						multi={true}
						maxTagCount={'responsive'}
						loading={isLoading}
						disabled={!enabled}
						name={'tasks'}
						placeholder={'Выберите задачи для согласования'}
					/>
				</div>
			</div>
			<div className={styles.item}>
				<span className={styles.label} />
				<div className={styles.field}>
					<div className={styles.tasks_filters}>
						{values.tasks?.map((task) => {
							return (
								<div key={task.value} className={styles.name}>
									{task.label?.length > 20 ? (
										<Tooltip title={task.label}>{task.label.slice(0, 20) + '...'}</Tooltip>
									) : (
										<>{task.label}</>
									)}
									<DeleteOutlined className={styles.icon} onClick={handlerDelete(task)} />
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</>
	)
}

export default Tasks
