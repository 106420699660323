import {axiosRequest} from 'shared/config/axios'
import {OptionsUserContentSchema, OptionsUserFilter, OptionsUserResponse, UsersRequest} from '../types'
import {UserTypes} from 'entities/user'
import {MapPerson, MapPersonProjectForm, MapPersonRoleName} from 'entities/user/lib'

export class UserController {
	static key = `/users/all`
	static keyOptions = 'Options: /users/all'
	static keyOptionsRoleName = 'OptionsRoleName: /users/all'

	static async fetch(data?: UsersRequest) {
		const { data: users } = await axiosRequest<UserTypes.Person[]>({
			method: 'POST',
			url: `/users/all`,
			data,
		})
		return users
	}
	static async options(data?: UsersRequest) {
		const users = await UserController.fetch(data)
		return users ? MapPerson(users) : []
	}
	static async optionsStatus(data?: UsersRequest) {
		const users = await UserController.fetch(data)
		return users ? MapPersonProjectForm(users) : []
	}
	static async optionsRoleName(data?: UsersRequest) {
		const users = await UserController.fetch(data)
		return users ? MapPersonRoleName(users) : []
	}
	/**
	 * Массовое действие
	 */
	static async bulkActions({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) {
		await axiosRequest({
			method: 'POST',
			url: `/users/bulk-actions/${bulkAction}`,
			data: rowIds,
		})
	}
	/**
	 * Получение всех пользователей системы c с фильтрома
	 */
	static async fetchWithFilter(data: OptionsUserFilter) {
		const filter = {...data, page: data.page - 1}
		const { data: users } = await axiosRequest<OptionsUserResponse[]>({
			method: 'POST',
			url: '/users/search',
			data: filter,
			parser: {list: true, schema: OptionsUserContentSchema.array()}
		})
		return {data: users}
	}
}
