import React, {FC} from 'react';
import formatSize from "utils/formatSize";
import {Progress, Spin, Tooltip, Upload} from "antd";
import {
    PauseCircleOutlined,
    PlayCircleOutlined,
    RedoOutlined,
    StopOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {selectUploaderData, selectUploaderInfo,} from "store/uploader/selectors";
import useUploaderContext from "../../context/uploader/useUploaderContext";

type UploaderModalItemProps = {
    handleSetUpload: any
    handleStart: any
    handleAbort: any
    handleRemove: any
}
const UploaderModalItem: FC<UploaderModalItemProps> = ({
    handleSetUpload, handleStart, handleAbort, handleRemove
}) => {
    const {uploaderState} = useUploaderContext()
    const {file, fileIsUpload} = useSelector(selectUploaderData);
    const {loading} = useSelector(selectUploaderInfo)
    const validateUpload = (_file: File) => {
        return false;
    };
    const title = file?.originalFilename;
    const size = file?.length;

    return (
        <Spin spinning={uploaderState.status === 'finishLoad'} tip={'Не обновляйте страницу, идёт сохранение файла'}>
            <div className='modal-upload__content'>
                <div className='modal-upload__content-item name'>
                    <span className='title'>Название файла</span>
                    <Tooltip title={title}>
                        <span className='value'>{title ? title : '-'}</span>
                    </Tooltip>

                </div>
                <div className='modal-upload__content-item size'>
                    <span className='title'>Размер</span>
                    <span className='value'>{size ? formatSize(size) : '-'}</span>
                </div>
                <div className='modal-upload__content-item load'>
                    <span className='title'>Состояние загрузки</span>
                    <Progress percent={uploaderState.progress} status={uploaderState.progress === 100 ? 'success' : 'normal'} />
                </div>
                <div className='modal-upload__content-item buttons'>
                    <span className='title'>Действия с файлом</span>
                    <div className='btn'>
                        {!loading && !fileIsUpload && (
                            <div className='upload'>
                                <Upload
                                    accept="*"
                                    listType="text"
                                    multiple={false}
                                    beforeUpload={validateUpload}
                                    onChange={handleSetUpload}

                                >
                                    {file && !file.isUploadInProgress && (
                                        <Tooltip title={'Заменить файл'}>
                                            <RedoOutlined  className='icon-accent' onClick={handleAbort}/>
                                        </Tooltip>
                                    )}
                                    {file && file.isUploadInProgress && (
                                        <Tooltip title={'Выбрать файл'}>
                                            <UploadOutlined className='icon-accent' />
                                        </Tooltip>
                                    )}
                                    {!file && (
                                        <Tooltip title={'Загрузить файл'}>
                                            <UploadOutlined className='icon-accent' />
                                        </Tooltip>
                                    )}
                                </Upload>
                            </div>
                        )}

                        {fileIsUpload && (
                            <>
                                {!loading ?
                                    <PlayCircleOutlined className='icon-accent' onClick={handleStart}/> :
                                    <PauseCircleOutlined className='icon' onClick={handleAbort}/>
                                }
                            </>
                        )}

                        {title && file.isUploadInProgress && (
                            <Tooltip title={'Остановить загрузку и использовать старый файл при его наличии'}>
                                <StopOutlined className='icon-red' onClick={handleRemove}/>
                            </Tooltip>

                        )}
                    </div>
                </div>
            </div>
        </Spin>
    )
};
export default UploaderModalItem;