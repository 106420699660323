import {put} from 'redux-saga/effects'
import {GetArchiveListStartAction, SetArchiveFilter} from "../../store/archive/actions";

export function* setArchiveFilter(_action: ReturnType<typeof SetArchiveFilter>) {
	try {
		yield put(GetArchiveListStartAction())
	} catch (error: any) {
		console.log(error)
	}
}
