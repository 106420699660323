import {ContentFormatTypes, Filter,} from "../types";
import {useQuery, UseQueryResult} from "react-query";
import {useMemo} from "react";
import {getWithFilter} from "../api";

type onSuccessType = (data: ContentFormatTypes) => void
export function useSearchFormatType(filter: Filter, onSuccess?: onSuccessType): UseQueryResult<ContentFormatTypes> {
    return useQuery({
        queryKey: [getWithFilter.key, filter],
        queryFn: () => getWithFilter.fetch(filter),
        onSuccess,
    });
}
export function useSearchFormatTypeOptions(filter: Filter = { page: 1, pageSize: 0 }, onSuccess?: onSuccessType) {
    const { data , ...rest } = useSearchFormatType(filter, onSuccess);
    const options = useMemo(() => data?.content.map((el) => ({
        value: el.id,
        label: el.name,
        code: !!el.contentCategory,
    })), [data]);
    return { data: options, ...rest };
}