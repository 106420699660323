import {put} from 'redux-saga/effects'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import {NotificationOpen} from '../../store/notification/actions'
import {
    CreateProjectReportingErrorAction,
    CreateProjectReportingStartAction,
    CreateProjectReportingSuccessAction,
} from '../../store/project-reporting/actions'
import ReportingService from '../../services/reportingService'

export function* createReporting({ data }: ReturnType<typeof CreateProjectReportingStartAction>) {
	try {
		yield ReportingService.createReporting(data)

		yield put(CreateProjectReportingSuccessAction())
		yield put(NotificationOpen('success', 'Отчет создан'))

	} catch (error: any) {
		yield put(CreateProjectReportingErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось создать отчет'))
	}
}
