import React from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import useToggleSidebar from 'hooks/toggleSidebar'
import {OPTIONS, PERSONAL_SETTING} from 'routing/names'
//store
import {selectUser} from 'store/auth/selectors'
import {ModalOpenAction} from "store/modals/actions";
import {selectFile} from "store/files/selectors";
//antd
import {
	BellOutlined,
	CloseOutlined,
	CommentOutlined,
	FileZipOutlined,
	LoginOutlined,
	MenuOutlined,
	SettingOutlined
} from '@ant-design/icons'
import {Badge, Tooltip} from 'antd'
//other
import convertName from 'utils/converName'
import './info-menu.less'
import TourBtn from "components/tour";
import InfoMenuUploader from "./info-menu-uploader";
import WhetherTheUser from "components/whetherTheUser";
import {GetArchiveListStartAction} from "store/archive/actions";
import {NotificationApi} from 'entities/notification'
import {useQuery} from "react-query";

const InfoMenu = () => {

	const put = useDispatch()
	const user = useSelector(selectUser)
	const { handlerSidebar, menuStatus } = useToggleSidebar()
	const history = useHistory();

	const img = useSelector(selectFile(user?.personalData?.image?.id))

	const deniedRoles = user && ['ROLE_CLIENT', 'ROLE_AGENT', 'ROLE_VIEWER'].includes(user?.role)
	const {data} = useQuery({
		queryKey: [NotificationApi.BrowserNotificationController.getNotificationKey, 'false'],
		queryFn: () => NotificationApi.BrowserNotificationController.getNotification(false),
		enabled: !deniedRoles
	})

	const handleClickSettings = () => {
		if (user && (user.role === 'ROLE_ADMIN' || user.role === 'ROLE_ADMIN_IS')) history.push(OPTIONS.OPTIONS_LIST)
		else history.push(PERSONAL_SETTING.CUSTOMIZATION)
	}

	const onLogout = () => {
		put(ModalOpenAction('logout'))
	}

	return (
		<nav className="infoMenu">
			<InfoMenuUploader/>
			<WhetherTheUser role={["ROLE_CONTRACTOR", "ROLE_VIEWER"]} isCan={false}>
				<Tooltip title={'Активные запросы на получение архивов'}>
					<div className="infoMenu__archive" onClick={(_e) => {
						put(ModalOpenAction('archive'))
						put(GetArchiveListStartAction())//Получение списка запросов на формирование архивов текущего пользователя
					}}>
						<FileZipOutlined />
					</div>
				</Tooltip>
			</WhetherTheUser>

			<TourBtn/>
			<Tooltip title={'Связь с поддержкой'}>
				<div  className='infoMenu__support' onClick={()=>put(ModalOpenAction('support'))}>
					<CommentOutlined />
				</div>
			</Tooltip>
			<WhetherTheUser role={["ROLE_CLIENT", "ROLE_VIEWER", "ROLE_AGENT"]} isCan={false}>
				<Tooltip title={'Уведомления'}>
					<div  className='infoMenu__notification' onClick={handlerSidebar("notification")}>
						<Badge count={data?.unreadCount}>
							<BellOutlined />
						</Badge>
					</div>
				</Tooltip>
			</WhetherTheUser>

			<Tooltip title={'Профиль'}>
				<div className="infoMenu__user" onClick={()=>put(ModalOpenAction('modal-user-profile'))}>
					{user && img?.data ? (
						<img src={img.data} alt={user.username}/>
					) : (
						<span>{convertName(user)}</span>
					)}
				</div>
			</Tooltip>

			<Tooltip title={'Настройки'}>
				<div className="infoMenu__setting infoMenu__setting-wrapper" onClick={handleClickSettings}>
					<SettingOutlined />
				</div>
			</Tooltip>

			<Tooltip title={'Завершить работу'}>
				<div
					className="infoMenu__logout"
					onClick={onLogout}
				>
					<LoginOutlined />
				</div>
			</Tooltip>

			<div className="infoMenu__menu" onClick={handlerSidebar('menu')}>
				{menuStatus ? <CloseOutlined /> : <MenuOutlined />}
			</div>
		</nav>
	)
}
export default InfoMenu;