import {AxiosResponse} from "axios";
import {put, select} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetArchiveListErrorAction,
    GetArchiveListStartAction,
    GetArchiveListSuccessAction
} from "../../store/archive/actions";
import ArchiveService from "../../services/archiveService";
import {TArchiveFilter, TArchiveResponse} from "../../models/archive";
import {selectArchiveFilter} from "../../store/archive/selectors";

export default function* getArchiveList(_: ReturnType<typeof GetArchiveListStartAction>) {
    try {
        let filter: TArchiveFilter = yield select(selectArchiveFilter)

        const { data }: AxiosResponse<TArchiveResponse> = yield ArchiveService.getArchiveList(filter);
        yield put(GetArchiveListSuccessAction(data))
    } catch (error: any) {
        yield put(GetArchiveListErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить список'))
    }
}