import { put, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import { NotificationOpen } from '../../store/notification/actions'
import {
	GetAnalyticsProjectErrorAction,
	GetAnalyticsProjectStartAction,
	GetAnalyticsProjectSuccessAction,
} from '../../store/analytics/actions'
import AnalyticsService from '../../services/analyticsService'
import { TAnalyticsFilter, TAnalyticsProjectResponse } from '../../models/analytics/analytics-project'
import { selectAnalyticsFilter } from '../../store/analytics/selectors'

export function* getAnalyticsProject(_action: ReturnType<typeof GetAnalyticsProjectStartAction>) {
	try {
		const filter: TAnalyticsFilter = yield select(selectAnalyticsFilter)
		const { data: analytics }: AxiosResponse<TAnalyticsProjectResponse> = yield AnalyticsService.getAnalyticsProject(
			filter
		)
		yield put(GetAnalyticsProjectSuccessAction(analytics))
	} catch (error: any) {
		yield put(GetAnalyticsProjectErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить данные'))
	}
}
