import React, {FC} from 'react';
import {Radio, RadioChangeEvent} from "antd";

type Props = {
    handler: Function
}

const ReactionRadio: FC<Props> = ({handler}) => {
    const handlerRadio = (e: RadioChangeEvent) => {
        const value = e.target.value
        handler(value)
    }

    return (
        <Radio.Group defaultValue={false} name="radiogroup" onChange={handlerRadio}>
            <Radio value={false}>Нет</Radio>
            <Radio value={true}>Да</Radio>
        </Radio.Group>
    );
};

export default ReactionRadio;