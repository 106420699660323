import React, {FC, useState} from 'react'
import {MailOutlined, PhoneOutlined, StarFilled} from '@ant-design/icons'
import { UserTypes } from 'entities/user'

type TItemComponent = {
	organizations: UserTypes.Organizations[]
}
export const ItemEmail: FC<TItemComponent> = ({ organizations }) => {
	const [visible, setVisible] = useState(false)

	return (
		<>
			<li>
				<div>
					<MailOutlined /> {organizations[0].emails[0].email}
				</div>
				{visible && organizations[0].emails[0].isMain && (
					<div className="item__content__star">
						<StarFilled onClick={() => setVisible(false)} />
					</div>
				)}
				{!visible && (
					<>
						{organizations[0].emails.length > 1 && (
							<span className="item__content__amount" onClick={() => setVisible(true)}>
								+ {organizations[0].emails.length - 1}
							</span>
						)}
					</>
				)}
			</li>
			{visible && (
				<>
					{organizations[0].emails.slice(1).map((it) => (
						<li>
							<div>
								<MailOutlined /> {it.email}
							</div>
							{it.isMain && (
								<div className="item__content__star">
									<StarFilled onClick={() => setVisible(false)}/>
								</div>
							)}
						</li>
					))}
				</>
			)}
		</>
	)
}

export const ItemPhone: FC<TItemComponent> = ({ organizations }) => {
	const [visible, setVisible] = useState(false)

	return (
		<>
			<li>
				<div>
					<PhoneOutlined /> {organizations[0].phones[0].phone}
				</div>
				{visible &&  organizations[0].phones[0].isMain && (
					<div className="item__content__star">
						<StarFilled onClick={() => setVisible(false)}/>
					</div>
				)}
				{!visible && (
					<>
						{organizations[0].phones.length > 1 && (
							<span className="item__content__amount" onClick={() => setVisible(true)}>
								+ {organizations[0].phones.length - 1}
							</span>
						)}
					</>
				)}
			</li>
			{visible && (
				<>
					{organizations[0].phones.slice(1).map((it) => (
						<li>
							<div>
								<PhoneOutlined /> {it.phone}
							</div>
							{it.isMain && (
								<div className="item__content__star">
									<StarFilled onClick={() => setVisible(false)}/>
								</div>
							)}
						</li>
					))}
				</>
			)}
		</>
	)
}
