import {put, select, delay, putResolve} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {GetContentSettingsList, SetContentInfo} from "store/content/settings/actions";
import TasksContentService from "services/tasksContentService";
import {selectContentSettingsFilter} from "store/content/settings/selectors";
import {TContentSettingsFilter} from "store/content/settings/reducer";
import {selectTasksRecordsStatusApprovedId} from "store/tasks/selectors";
import {GetTasksFieldsStartAction} from "store/tasks/actions/tasks";
import {TTasksTypesFields} from "models/task";

export function* getContentSettingsListWorker({count}: ReturnType<typeof GetContentSettingsList>) {
    try {
        const filter: TContentSettingsFilter = yield select(selectContentSettingsFilter);
        const statusRecordApproved: string | undefined = yield select(selectTasksRecordsStatusApprovedId);

        yield put(SetContentInfo({isLoading: true, error: false}))

        if (!statusRecordApproved && (!count || count <= 3)) {
            //todo Переделать statusRecordApproved с id на code т.к нужно получать
            yield putResolve(GetTasksFieldsStartAction(TTasksTypesFields.recordsStatuses))
            yield delay(1000)
            yield put(GetContentSettingsList(count ? count++ : 1))
        } else {
            const filterForRequest: TContentSettingsFilter = {
                ...filter,
                filterData: {
                    ...filter.filterData,
                    isMasterFile: true,
                    taskRecordStatusId: statusRecordApproved
                }
            }
            const {data} = yield TasksContentService.getTaskRecords(filterForRequest)
            yield put(SetContentInfo({
                isLoading: false,
                list: data.content,
                filter: {...filter, totalCount: data.totalCount}
            }))
        }

    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        const messageTest = message ? message : 'Не удалось получить список контента'
        yield put(SetContentInfo({list: null, error: messageTest, isLoading: false}))
        yield put(NotificationOpen('error', 'Ошибка', messageTest))
    }
}
