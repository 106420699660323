import { put } from 'redux-saga/effects'
import { NotificationOpen } from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	GetHandbookStartAction,
	UpdateGenreProjectErrorAction,
	UpdateGenreProjectStartAction,
	UpdateGenreProjectSuccessAction,
} from 'store/options/actions/actions-handbook'
import { TStatusesHandbookFeels } from 'models/handbook'
import GenreProjectService from 'services/genreProjectService'

export function* updateGenreProject({ id, data }: ReturnType<typeof UpdateGenreProjectStartAction>) {
	try {
		yield GenreProjectService.updateGenreProjectRecord(data, id)
		yield put(UpdateGenreProjectSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.genreProject))
		yield put(NotificationOpen('success', 'Жанр обновлен'))
	} catch (error: any) {
		yield put(UpdateGenreProjectErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить жанр'))
	}
}
