import { ContentActionTypes } from './action-types'
import {
	TProjectResponse,
	TypeContentResponse,
	TypeContentFilter
} from 'models/content'
import { T_ID_NAME_CODE } from 'models/common'

//----------------
export const GetProjectContentStartAction = () => ({
	type: ContentActionTypes.GET_PROJECT_START,
})
export const GetProjectContentSuccessAction = (project: TProjectResponse) => ({
	type: ContentActionTypes.GET_PROJECT_SUCCESS,
	project,
})
export const GetProjectContentErrorAction = (error: any) => ({
	type: ContentActionTypes.GET_PROJECT_ERROR,
	error,
})
export const GetContentStartAction = () => ({
	type: ContentActionTypes.GET_CONTENT_START,
})
export const GetContestSuccessAction = (content: TypeContentResponse | null) => ({
	type: ContentActionTypes.GET_CONTENT_SUCCESS,
	content,
})
export const GetContestErrorAction = (error: any) => ({
	type: ContentActionTypes.GET_CONTENT_ERROR,
	error,
})

export const GetContentModuleSectionsStartAction = (projectIds?: string[]) => ({
	type: ContentActionTypes.GET_CONTENT_MODULE_SECTIONS_START,
	projectIds,
})
export const GetContestModuleSectionsSuccessAction = (data: T_ID_NAME_CODE[]) => ({
	type: ContentActionTypes.GET_CONTENT_MODULE_SECTIONS_SUCCESS,
	data,
})
export const GetContestModuleSectionsErrorAction = (error: any) => ({
	type: ContentActionTypes.GET_CONTENT_MODULE_SECTIONS_ERROR,
	error,
})

/**
 * Изменение фильтра сортировки сортировки
 */
export const SetSortingFilter = (filter: TypeContentFilter) => ({
	type: ContentActionTypes.SET_SORTING_FILTER,
	filter,
})
