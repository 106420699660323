import { put } from 'redux-saga/effects'
import { GetActivityUsersListStartAction, SetActivityUsersFilter } from 'store/options/actions/actions-actions-users'

export function* setUsersActionsFilter(_action: ReturnType<typeof SetActivityUsersFilter>) {
	try {
		yield put(GetActivityUsersListStartAction())
	} catch (error: any) {
		console.log(error)
	}
}
