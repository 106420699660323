import React, {FC, MouseEvent, useEffect, useState} from 'react'
import './index.less'
import {Audio, DefaultUi, Player, Video} from '@vime/react'
//antd
import {Image, Modal} from 'antd'
//hooks
import useHandlerModal from 'hooks/handlModal'
//models
import {ContentTimeCode} from 'models/task/task-content'
import {FileType} from 'shared/types/file'
//others
import {DocumentViewer} from 'shared/ui/file-viewer'
import env from 'shared/config/env'
import $api from '../../../http'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {Notification} from 'processes/notification'
import {FORMATS_FOR_CONVERSION} from "utils/varibales";

const path = `${env.API_ENDPOINT}/files/`

type Props = {
	status: boolean
	data: {
		title: string
		file: FileType
		timeCodes?: Array<ContentTimeCode>
	}
}
const ModalContent: FC<Props> = ({ status, data }) => {
	const { isVisible, closeModal } = useHandlerModal(status)
	const [currentTime, setCurrentTime] = useState({ time: 0 })

	useEffect(() => {
		if (FORMATS_FOR_CONVERSION.includes(data.file.type) && !data.file.previewFile) {
			generatePreview(data.file.id)
		}
		// eslint-disable-next-line
	}, [])

	const onTimeClick = (timeString: string) => (_event: MouseEvent<HTMLSpanElement>) => {
		const time = timeString.split(':')
		const convertTime = +time[2] + +time[1] * 60 + +time[0] * 120
		setCurrentTime({ time: convertTime })
	}

	return (
		<Modal
			className="modal-contentTask"
			title={`Просмотр "${data.title}"`}
			visible={isVisible}
			footer={''}
			maskClosable={true}
			onCancel={closeModal}
		>
			<div className="modal-contentTask__content">
				{FORMATS_FOR_CONVERSION.includes(data.file.type) && (
					<DocumentViewer
						extension={'pdf'}
						fileId={getFileDetails(data.file).fileId}
						name={getFileDetails(data.file).fileName}
						closeModal={closeModal}
					/>
				)}
				{data.file.type === 'IMAGE' && (
					<div className="modal-contentTask__content-image">
						<Image
							crossOrigin={'use-credentials'}
							preview={{
								src: data.file.compressedId ? `${path}${data.file.compressedId}` : `${path}${data.file.id}`,
							}}
							src={data.file.scaledId ? `${path}${data.file.scaledId}` : `${path}${data.file.id}`}
						/>
					</div>
				)}
				{(data.file.type === 'VIDEO' || data.file.type === 'AUDIO') && (
					<Player
						theme="dark"
						currentTime={currentTime.time}
						currentPoster={data.file.previewFile ? path + data.file.previewFile?.id : undefined}
					>
						{data.file.type === 'VIDEO' && (
							<>
								<Video crossOrigin="use-credentials">
									<source data-src={`${path}${data.file.id}`} />
								</Video>
							</>
						)}
						{data.file.type === 'AUDIO' && (
							<>
								<Audio crossOrigin="use-credentials">
									<source data-src={`${path}${data.file.id}`} />
								</Audio>
							</>
						)}
						<DefaultUi />
					</Player>
				)}
				{data.file.type === 'VIDEO' && !!data.timeCodes?.length && (
					<ul className="timeCodesList">
						{data.timeCodes.map((timeCode, index) => {
							return (
								<li className="timeCode" key={index}>
									<span className="time" onClick={onTimeClick(timeCode.timeline)}>
										{timeCode.timeline}
									</span>{' '}
									- <span>{timeCode.name}</span>
								</li>
							)
						})}
					</ul>
				)}
			</div>
		</Modal>
	)
}

async function generatePreview(id: string) {
	try {
		await $api({
			method: 'POST',
			url: `/files/generate-preview-files`,
			data: [id],
		})
	} catch (error) {
		const message = CreateErrorMessage.response(error)
		Notification.open({ type: 'success', info: {message: message ? message : 'Расписание успешно отключено'}});
	}
}
function getFileDetails(file: FileType) {
	const fileId = file.type === 'PDF' ? file.id : file.previewFile?.id
	const fileName = file.type === 'PDF' ? file.name : file.previewFile?.name

	return { fileId, fileName }
}
export default ModalContent
