import {AxiosResponse} from 'axios'
import {put} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {
	GetTagsFirstLevelContentErrorAction,
	GetTagsFirstLevelContentStartAction,
	GetTagsFirstLevelContentSuccessAction
} from "../../store/tags/actions";
import {TTag} from "../../models/tags";
import {TagsService} from "../../services/tagsService";

export default function* getFirstLevelContent(_: ReturnType<typeof GetTagsFirstLevelContentStartAction>) {
	try {
		const {data}: AxiosResponse<TTag[]> = yield TagsService.getTagsFirstLevelContent();
		yield put(GetTagsFirstLevelContentSuccessAction(data))
	} catch (error: any) {
		yield put(GetTagsFirstLevelContentErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
