import { FieldArray } from 'formik'
import { FormikProps } from 'formik/dist/types'
import styles from './styles.module.less'
import classNames from 'classnames'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { DatePickerComponent } from 'components/formic-control'
import React from 'react'
import { TForm } from './modal'
import TasksScheduleDatesSet from '../tasks-schedule-dates-set'

export default function ScheduleDates() {
	const date = new Date()
	date.setDate(date.getDate() + 1)
	return (
		<FieldArray name={'scheduleDates'} render={({ form, push, remove }) => {
				const {
					values: { scheduleDates, enabled, duplicationTerms },
					errors,
					touched,
				} = form as FormikProps<TForm>

				const touchedFields = Object.keys(touched).some((value: string) => {
					const key = value.slice(0, 13)
					return key === 'scheduleDates'
				})

				const duplicationTermsFirst = duplicationTerms === '1'

				return (
					<div className={styles.item}>
						<span className={styles.label}>Даты создания задач {duplicationTerms === '2' && <sup>*</sup>}</span>
						<div className={styles.field}>
							<div
								className={classNames(styles.add, { 'disabled': !enabled || duplicationTermsFirst })}
								onClick={() => {
									if (enabled && !duplicationTermsFirst) push('')
								}}
							>
								<PlusCircleOutlined />
								Добавить даты создания задач
							</div>
							<TasksScheduleDatesSet />
							<div>
								{scheduleDates.map((_el, index) => (
									<div key={index} className={styles.field__item}>
										<span className={styles.counter}>Дата {index + 1}</span>
										<DatePickerComponent
											isAfter={true}
											isAfterDate={date}
											showError={false}
											disabled={!enabled || duplicationTermsFirst}
											name={`scheduleDates[${index}]`}
										/>
										<DeleteOutlined className={styles.remove} onClick={() => remove(index)} />
									</div>
								))}
							</div>
							{touchedFields && <div className={styles.error}>{errors.scheduleDates as string}</div>}
						</div>
					</div>
				)
			}}
		/>
	)
}
