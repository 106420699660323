import {put} from "redux-saga/effects";
import {push} from 'connected-react-router';

import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetHandbookStartAction,
    UpdateOrganizationErrorAction,
    UpdateOrganizationStartAction,
    UpdateOrganizationSuccessAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import HandbookService from "services/handbookService";
import {HANDBOOK} from "routing/names";
import SendOrganizationFormDto
    from "components/forms/handbook-form/organization/send-organization-form.dto";


export function* updateOrganization({id, data,}: ReturnType<typeof UpdateOrganizationStartAction>) {
    try {
        const sendData = new SendOrganizationFormDto(data)

        yield HandbookService.updateOrganizationRecord(sendData,id)
        yield put(UpdateOrganizationSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.organization))
        yield put(NotificationOpen('success', 'Данные об организации обновлены'))
        yield put(push(HANDBOOK.ORGANIZATION));
    } catch (error: any) {
        yield put(UpdateOrganizationErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить организации'))
    }
}
