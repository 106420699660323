import {InferValueTypes} from '../../models/common'
import * as actions from './actions'
import {ErrorActionState, StartActionState, SuccessActionState} from '../../utils/reducerHalper'
import {Logout} from '../auth/reducer'
import {AuthActionTypes} from '../auth/action-types'
import {TagsActionTypes} from './action-types'
import {TTag, TTagSecondLevel} from "../../models/tags";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TTagsState = {
	tagsFirstThematics: TTag[] | null
	tagsSecondWithFirst: TTagSecondLevel[] | null
	tagsFirstTaskRecordContent: TTag[] | null
	tagsSecondTaskRecordContent: TTagSecondLevel[] | null
	tagsThirdTaskRecordContent: TTagSecondLevel[] | null
	tagsFirstContent: TTag[] | null
	tagsSecondContent: TTagSecondLevel[] | null
	isLoading: boolean
	error: any
}

export const initialState: TTagsState = {
	tagsFirstThematics: null,
	tagsSecondWithFirst: null,
	tagsFirstTaskRecordContent: null,
	tagsSecondTaskRecordContent: null,
	tagsThirdTaskRecordContent: null,
	tagsFirstContent: null,
	tagsSecondContent: null,
	isLoading: false,
	error: null,
}

export default function reducer(state: TTagsState = initialState, action: ActionTypes | Logout): TTagsState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return { ...initialState }
		/** START ACTIONS  */
		case TagsActionTypes.GET_TAGS_FIRST_LEVEL_THEMATICS_START:
			return StartActionState(state)
		case TagsActionTypes.GET_TAGS_SECOND_LEVEL_WITH_FIRST_START:
			return StartActionState(state)
		case TagsActionTypes.GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_START:
			return StartActionState(state)
		case TagsActionTypes.GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_START:
			return StartActionState(state)
		case TagsActionTypes.GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_START:
			return StartActionState(state)
		case TagsActionTypes.GET_TAGS_FIRST_LEVEL_CONTENT_START:
			return StartActionState(state)
		case TagsActionTypes.GET_TAGS_SECOND_LEVEL_CONTENT_START:
			return StartActionState(state)


		/** SUCCESS ACTIONS  */
		case TagsActionTypes.GET_TAGS_FIRST_LEVEL_THEMATICS_SUCCESS:
			return {
				...SuccessActionState(state),
				tagsFirstThematics: action.data,
			}

		case TagsActionTypes.GET_TAGS_SECOND_LEVEL_WITH_FIRST_SUCCESS:
			return {
				...SuccessActionState(state),
				tagsSecondWithFirst: action.data,
			}
		case TagsActionTypes.GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_SUCCESS:
			return {
				...SuccessActionState(state),
				tagsFirstTaskRecordContent: action.data,
			}
		case TagsActionTypes.GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_SUCCESS:
			return {
				...SuccessActionState(state),
				tagsSecondTaskRecordContent: action.data,
			}
		case TagsActionTypes.GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_SUCCESS:
			return {
				...SuccessActionState(state),
				tagsThirdTaskRecordContent: action.data,
			}
		case TagsActionTypes.GET_TAGS_FIRST_LEVEL_CONTENT_SUCCESS:
			return {
				...SuccessActionState(state),
				tagsFirstContent: action.data,
			}
		case TagsActionTypes.GET_TAGS_SECOND_LEVEL_CONTENT_SUCCESS:
			return {
				...SuccessActionState(state),
				tagsSecondContent: action.data,
			}

		/** ERROR ACTIONS  */
		case TagsActionTypes.GET_TAGS_FIRST_LEVEL_THEMATICS_ERROR:
			return ErrorActionState(state, action.error)
		case TagsActionTypes.GET_TAGS_SECOND_LEVEL_WITH_FIRST_ERROR:
			return ErrorActionState(state, action.error)
		case TagsActionTypes.GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_ERROR:
			return ErrorActionState(state, action.error)
		case TagsActionTypes.GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_ERROR:
			return ErrorActionState(state, action.error)
		case TagsActionTypes.GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_ERROR:
			return ErrorActionState(state, action.error)
		case TagsActionTypes.GET_TAGS_FIRST_LEVEL_CONTENT_ERROR:
			return ErrorActionState(state, action.error)
		case TagsActionTypes.GET_TAGS_SECOND_LEVEL_CONTENT_ERROR:
			return ErrorActionState(state, action.error)
		default:
			return state
	}
}
