import {IOptions} from "models/feels";
import {TRole} from "models/user";
import {TActualOrArchivedProjects} from "models/projects/actual-projects";
import {TaskTypes} from 'entities/task'

export function isRepost(types: IOptions[] | null, id: string) {
    if (!types) return false
    const type = types.find((el) => (el.value === id || el.key === id) && el.label === 'Отчёт')
    return !!type
}

export function transformTypeProject(role: TRole | undefined, types: IOptions<TaskTypes.TypesTask>[]) {
    if (role === 'ROLE_ANALYST' || role === 'ROLE_FINANCIER') {
        return  types?.filter(it=> it.code === TaskTypes.TypesTask.REPORT)
    }
    if (role === 'ROLE_CONTENT_EDITOR') {
        return  types?.filter(it=> it.code === TaskTypes.TypesTask.CONTENT)
    }
    return types
}


export function transformReportType(role: TRole | undefined, reportType: IOptions<TaskTypes.ReportType>[]) {
    const analystListDisabled: Array<TaskTypes.ReportType> = [
        TaskTypes.ReportType.CONTENT_POSTING_REPORT,
        TaskTypes.ReportType.COPYRIGHT_LIST,
        TaskTypes.ReportType.PROJECT_ESTIMATE,
    ]
    const financierListDisabled: Array<TaskTypes.ReportType> = [
        TaskTypes.ReportType.CONTENT_POSTING_REPORT,
        TaskTypes.ReportType.CONTENT_REACH_REPORT,
        TaskTypes.ReportType.COPYRIGHT_LIST,
    ]
    switch (role) {
        case 'ROLE_ANALYST':
            return reportType?.map(it => ({
                ...it,
                disabled: analystListDisabled.includes(it.code as TaskTypes.ReportType),
            }))
        case 'ROLE_FINANCIER':
            return reportType?.map(it => ({
                ...it,
                disabled: financierListDisabled.includes(it.code as TaskTypes.ReportType),
            }))
        default:
            return reportType
    }
}

export function getContractorOrganization(projects: TActualOrArchivedProjects[] | null, id?: string | number | null) {
    if (!id || !projects) return null
    const organization = projects.find((el) => el.id === id)

    return `${organization?.contractorOrganization.name}`
}

export function transformAccountedContentToString(value: boolean | null) {
    switch (value) {
        case true:
            return 'true'
        case false:
            return 'false'
        case null:
            return 'null'
        default: return 'null'
    }
}

export function transformAccountedContent(value: string) {
    switch (value) {
        case 'true':
            return true
        case 'false':
            return false
        case 'null':
            return null
        default: return null
    }
}