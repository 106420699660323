import {LogsActionTypes} from "./action-types";
import {TLogsAction} from "models/logs";
import {InferValueTypes} from "models/common";
import * as actions from './actions';
import {AuthActionTypes} from "../auth/action-types";
import {Logout} from "../auth/reducer";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;


export type TLogsState = {
    actions: TLogsAction[] | null
    isLoading: boolean;
    error: any;
}
const initialState: TLogsState = {
    actions: null,
    isLoading: false,
    error: null
};
export default function reducer(state: TLogsState = initialState, action: ActionTypes | Logout): TLogsState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState}
        case LogsActionTypes.LOGS_ADD:
            return state
        case LogsActionTypes.LOGS_PUSH:
            const logs = state.actions ? [...state.actions, action.logs] : [action.logs]
            return {...state, actions: logs}
        case LogsActionTypes.LOGS_SEND_START:
            return {...state, isLoading: true, error: null, actions: null}
        case LogsActionTypes.LOGS_SEND_SUCCESS:
            return {...state, isLoading: false}
        case LogsActionTypes.LOGS_SEND_ERROR:
            return {...state, error: action.error}
        default:
            return state
    }
}