import React from 'react';
import styles from './styles.module.less'
import {FileFilled} from "@ant-design/icons";
import {Tooltip} from "antd";
import env from "shared/config/env";
import {FileType} from "models/common";

const FilesCell = (files: FileType[]) => {
    if(!files?.length) {
        return (
            <div className={styles.main}>
                <div>
                    <FileFilled  className={styles.icon_disabled}/>
                    <span className={styles.text}>Скачать</span>
                </div>
            </div>
        )
    } else {
        return (
            <>
                {
                    files?.map((el) => (
                        <div className={styles.main} key={el.id}>
                            <Tooltip title={el.name}>
                                <a
                                    href={`${env.API_ENDPOINT}/files/${el.id}`}
                                    download
                                    referrerPolicy={"no-referrer"}
                                >
                                    <FileFilled className={styles.icon}/>
                                    Скачать
                                </a>
                            </Tooltip>
                        </div>
                    ))
                }
            </>
        )
    }
};

export default FilesCell;