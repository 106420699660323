import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import useDebounce from "hooks/useDebounce";
import {Input, Tooltip} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {selectFilter} from "store/content/content/selectors";
import {SetSortingFilter} from "store/content/content/actions";
import {selectRole} from "store/auth/selectors";
import useTasksFields from "hooks/tasksField";
import {TTasksTypesFields} from "models/task";
import {TFilterData} from "models/content";

const SearchItem = () => {
    const put = useDispatch();
    const filter = useSelector(selectFilter)
    const {recordsStatuses} = useTasksFields([TTasksTypesFields.recordsStatuses])
    const role = useSelector(selectRole)
    const [searchString, setSearchString] = useState('');


    useEffect(()=>{
       if (filter.filterData?.searchString) setSearchString(filter.filterData.searchString)
        else setSearchString('')
    },[filter.filterData?.searchString])

    useEffect(()=>{
        if(role === 'ROLE_AGENT' || role === 'ROLE_VIEWER' || role === 'ROLE_CLIENT'){
            if(recordsStatuses) {
                const ids: string[]  = recordsStatuses?.filter(it=>it.code === 'APPROVED').map(it=>it.value)
                let newFilter: TFilterData = {};
                if (filter.filterData) newFilter = { ...filter.filterData }
                newFilter = { ...newFilter, taskRecordStatusIds: ids}

                put(SetSortingFilter({ ...filter, filterData: newFilter }))
            }
        }
        // eslint-disable-next-line
        },[recordsStatuses,role])

    const handlerSearchDebounce = useDebounce((value: string) => {
        put(SetSortingFilter({...filter, filterData: {...filter.filterData, searchString: value}}))
    }, 500)
    const handlerSearch = (e: any) => {
        setSearchString(e.target.value)
        handlerSearchDebounce(e.target.value)
    }
    return (
        <div className="sorting__item search">
            <Tooltip title="Поиск" placement="top">
                <Input
                    autoComplete="off"
                    value={searchString}
                    allowClear={true}
                    prefix={<SearchOutlined className="sorting__item--icon"/>}
                    placeholder="Поиск"
                    onChange={handlerSearch}
                />
            </Tooltip>
        </div>
    )
}

export default SearchItem;