const tasksRecords = [
    {
        id: '1',
        selector: '.sorting__item.search',
        content: 'Поиск позволяет найти необходимые данные',
        role: []
    },
    {
        id: '2',
        selector: '.filters',
        content: 'С помощью фильтрации можно быстро отсортировать нужную информацию',
        role: []
    },
    {
        id: '3',
        selector: '.ant-table',
        content: 'Данные полей талицы формируются в зависимости от типа задач “Контент” / “Отчет” и типов отчетов',
        role: []
    },
];
export default tasksRecords;
