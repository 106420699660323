import {put, select} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {
	GetSprintsStartAction,
	UpdateSprintErrorAction,
	UpdateSprintStartAction,
	UpdateSprintSuccessAction,
	ClearEditingKey
} from "store/sprints/actions";
import SprintService from "services/sprintService";
import {GetTasksGantStartAction} from "store/tasks/actions/gant-kanban";
import {selectParams} from "store/selectors";

export function* updateSprint({ stageId, data }: ReturnType<typeof UpdateSprintStartAction>) {
	try {
		const {id} = yield select(selectParams);
		yield SprintService.updateSprint(stageId,data)
		yield put(UpdateSprintSuccessAction())
		yield put(NotificationOpen('success', 'Этапы обновлены'))
		yield put(GetSprintsStartAction())
		yield put(ClearEditingKey())
		if (id) yield put(GetTasksGantStartAction(id))
	} catch (error: any) {
		yield put(UpdateSprintErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ))
	}
}
