import {put, select} from 'redux-saga/effects'
import {AxiosResponse} from 'axios'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import {NotificationOpen} from '../../store/notification/actions'
import {
	GetAnalyticsSessionContentKpiErrorAction,
	GetAnalyticsSessionContentKpiStartAction,
	GetAnalyticsSessionContentKpiSuccessAction,
} from '../../store/analytics/actions'
import AnalyticsService from '../../services/analyticsService'
import {
	TAnalyticsContentResponse,
	TAnalyticsFilter,
	TAnalyticsSessionResponse
} from '../../models/analytics/analytics-project'
import {selectAnalyticsFilter} from '../../store/analytics/selectors'

const sendRequest = {
	'session' : AnalyticsService.getAnalyticsSessionKpi,
	'content' : AnalyticsService.getAnalyticsContentKpi
}


export function* getAnalyticsSessionContentKpi({dataType}: ReturnType<typeof GetAnalyticsSessionContentKpiStartAction>) {
	try {
		const filter: TAnalyticsFilter = yield select(selectAnalyticsFilter)
		const { data: analytics }: AxiosResponse<TAnalyticsContentResponse[] | TAnalyticsSessionResponse[]> = yield sendRequest[dataType](filter)
		yield put(GetAnalyticsSessionContentKpiSuccessAction(analytics))
	} catch (error: any) {
		yield put(GetAnalyticsSessionContentKpiErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить данные'))
	}
}
