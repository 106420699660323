import {createSelector} from "reselect";
import {IOptions} from "../../../models/feels";
import {TApplicationState} from "../../aplication-state";

const usersState = (state: TApplicationState) => state.options.users

export const selectUsersList = createSelector(usersState, (state) => state.usersData)
export const selectUsersListFilter = createSelector(usersState, (state) => state.filter)
export const selectGetUser = createSelector(usersState, (state) => state.user)
export const selectGetOrganizationsUser = createSelector(usersState, (state) => state.organizationsUser)
export const selectRelatedUsersIsEmpty = createSelector(usersState, (state) => !!state.relatedUsers)
export const selectRelatedUsersOptions = createSelector(usersState, (state) =>
    state.relatedUsers
        ? state.relatedUsers.map((el) => {
            return { value: el.id, label: `${el.lastName} ${el.firstName} ${el.middleName || ''}` } as IOptions
        })
        : null
)
export const selectInspectorsOptions = createSelector(usersState, (state) =>
    state.inspectors
        ? state.inspectors.map((el) => {
            return { value: el.id, label: `${el.lastName} ${el.firstName} ${el.middleName || ''}` } as IOptions
        })
        : null
)

export const selectUsersStatus = createSelector(usersState, (state) => ({
    isSuccess: state.isSuccess,
    isLoadingUser: state.isLoadingUser,
    isLoading: state.isLoading,
    error: state.error,
}))

