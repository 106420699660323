import React, {lazy, useEffect} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import {HANDBOOK} from './names'
import {UserTypes} from "entities/user";
import SuspenseComponent from '../components/suspense'
import {Routes} from '../models/routes'
import {useSelector} from 'react-redux'
import {selectRole} from '../store/auth/selectors'
import CreateRouting from "./create-routing";

const Thematic = lazy(() => import('pages/options/handbook/thematic/thematic'))
const Internet = lazy(() => import('pages/options/handbook/internet/internet'))
const ContentFormatType = lazy(() => import('pages/options/handbook/content-format-type/content-format-type'))
const ContentCategories = lazy(() => import('pages/options/handbook/content-categories/content-categories'))
const ContentDelivery = lazy(() => import('pages/options/handbook/content-delivery/content-delivery'))
const Organization = lazy(() => import('pages/options/handbook/organization/organization'))
const OrganizationForm = lazy(() => import('components/forms/handbook-form/organization/organization-form'))
const ActionsUsers = lazy(() => import('pages/options/handbook/actions-users/actions-users'))
const Kpe = lazy(() => import('pages/options/handbook/kpe/kpe'))
const ContentDirection = lazy(() => import('pages/options/handbook/content-direction/content-direction'))
const Tag = lazy(() => import('pages/options/handbook/documents/documents'))
const ProjectTags = lazy(()=> import('pages/options/handbook/project-tags/project-tags'))
const Regions = lazy(()=> import(  'pages/options/handbook/regions/regions'))
const Ranks = lazy(()=> import(  'pages/options/handbook/ranks/ranks'))
const LegalFormOrganization = lazy(()=> import('pages/options/handbook/legal-form-organization/legal-form'))
const SessionsType = lazy(()=> import(  'pages/options/handbook/sessions-type/sessions-type'))
const CategoryProject = lazy(()=> import('pages/options/handbook/category-project/category'))
const GenreProject = lazy(()=> import('pages/options/handbook/genre-project/genre'))
const Sessions = lazy(()=> import('pages/options/handbook/sessions/session'))
const Contest = lazy(()=> import('pages/options/handbook/contests/contests'))
const ErvRanges = lazy(()=> import('pages/options/handbook/erv-ranges/erv-ranges'))
const FamousPeople = lazy(()=> import('pages/options/handbook/famous-people/famous-people'))
const FilmClusters = lazy(()=> import('pages/options/handbook/film-clusters/film-clusters'))

const roleHasAccess:UserTypes.RoleName[] = [
	'ROLE_ADMIN',
	'ROLE_ADMIN_IS',
	'ROLE_MODERATOR',
	'ROLE_COORDINATOR',
	'ROLE_PRODUCER',
	'ROLE_CONTENT_EDITOR',
	'ROLE_PROJECT_MANAGER',
]
const roleHasNotAccessActionsUser:UserTypes.RoleName[] = ['ROLE_CONTRACTOR', 'ROLE_ANALYST', 'ROLE_FINANCIER', 'ROLE_LAWYER', 'ROLE_CLIENT', 'ROLE_COORDINATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER']


export const handbooksRoutes: Routes[] = [
	{ path: HANDBOOK.THEMATIC, Component: Thematic, roleHasAccess},
	{ path: HANDBOOK.INTERNET, Component: Internet, roleHasAccess: [...roleHasAccess, 'ROLE_ANALYST'] },
	{ path: HANDBOOK.CONTENT_TYPE, Component: ContentFormatType, roleHasAccess },
	{ path: HANDBOOK.CONTENT_CATEGORY, Component: ContentCategories, roleHasAccess},
	{ path: HANDBOOK.CONTENT_DELIVERY, Component: ContentDelivery, roleHasAccess },
	{ path: HANDBOOK.ORGANIZATION, Component: Organization, roleHasAccess },
	{ path: HANDBOOK.ORGANIZATION_FORM, Component: OrganizationForm, roleHasAccess },
	{ path: HANDBOOK.ACTIONS_USERS, Component: ActionsUsers, roleHasNotAccess: roleHasNotAccessActionsUser},
	{ path: HANDBOOK.KPE, Component: Kpe, roleHasAccess },
	{ path: HANDBOOK.CONTENT_DIRECTION, Component: ContentDirection, roleHasAccess },
	{ path: HANDBOOK.DOCUMENTS, Component: Tag, roleHasAccess },
	{ path: HANDBOOK.PROJECT_TAGS, Component: ProjectTags, roleHasAccess: [...roleHasAccess, 'ROLE_ANALYST']},
	{ path: HANDBOOK.REGIONS, Component: Regions, roleHasAccess},
	{ path: HANDBOOK.RANKS, Component: Ranks, roleHasAccess},
	{ path: HANDBOOK.FILM_CLUSTERS, Component: FilmClusters, roleHasAccess},
	{ path: HANDBOOK.FAMOUS_PEOPLE, Component: FamousPeople, roleHasAccess},
	{ path: HANDBOOK.LEGAL_FORM, Component: LegalFormOrganization, roleHasAccess},
	{ path: HANDBOOK.SESSIONS_TYPE, Component: SessionsType, roleHasAccess},
	{ path: HANDBOOK.CATEGORY_PROJECT, Component: CategoryProject, roleHasAccess},
	{ path: HANDBOOK.GENRE_PROJECT, Component: GenreProject, roleHasAccess},
	{ path: HANDBOOK.SESSIONS, Component: Sessions, roleHasAccess},
	{ path: HANDBOOK.CONTESTS, Component: Contest, roleHasAccess},
	{ path: HANDBOOK.ERV_RANGES, Component: ErvRanges, roleHasAccess},
]

const HandbooksRouting = () => {
	const userRole = useSelector(selectRole)

	const redirect = () => {
		switch (userRole) {
			case 'ROLE_ANALYST':
				return HANDBOOK.PROJECT_TAGS
			default:
				return HANDBOOK.SESSIONS
		}

	}

	useEffect(()=>{
		redirect()
		// eslint-disable-next-line
	},[userRole])

	return (
		<SuspenseComponent>
			<Switch>
				{CreateRouting(handbooksRoutes, userRole)}
				<Redirect to={redirect()} />
			</Switch>
		</SuspenseComponent>
	)
}
export default HandbooksRouting
