import {takeLatest} from 'redux-saga/effects'
import {ProjectDocumentsActionTypes} from "../../store/project-documents/action-types";
import {getDocumentsWithFilter} from "./getDocumentsWithFilter";
import {updateDocuments} from "./update";
import {addDocuments} from "./add";
import {deleteDocuments} from "./delete";
import {setDocumentsFilter} from "./setFilter";

export default function* projectDocumentsSaga() {
	yield takeLatest(ProjectDocumentsActionTypes.GET_DOCUMENTS_WITH_FILTER_START, getDocumentsWithFilter)
	yield takeLatest(ProjectDocumentsActionTypes.UPDATE_DOCUMENTS_START, updateDocuments)
	yield takeLatest(ProjectDocumentsActionTypes.ADD_DOCUMENTS_START, addDocuments)
	yield takeLatest(ProjectDocumentsActionTypes.DELETE_DOCUMENTS_START, deleteDocuments)
	yield takeLatest(ProjectDocumentsActionTypes.SET_PROJECT_DOCUMENTS_FILTER, setDocumentsFilter)
}
