import React, {useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import styles from './styles.module.less'
import {useFormikContext} from 'formik'
import {GetFeelsStartAction} from 'store/app/actions'
import classNames from 'classnames'
//antd
import {Spin} from 'antd'
//models
import {IOptions} from 'models/feels'
import {TStatusesFeels} from 'models/projects/projectFeels'
//components
import {CheckboxComponent, DatePickerComponent, SelectComponent} from 'components/formic-control'
import ReloadList from 'components/forms/components/reload-list/reload-list'
import WhetherTheUser from 'components/whetherTheUser'
import ThemeNationalProject from './themeNationalProject'
import FamousPeople from './famousPeople'
//others
import useFeelsApp from 'hooks/feelsApp'

import {ProjectFormDto} from '../../../lib'
import AwardComponent from '../../award-component'
import LocationRegions from "./locationRegions";
import FilmClusters from "./filmClusters";

function filingName(key: string): string {
	return `stepFifth.${key}.value`
}
const optionsAgeFrom = new Array(65)
	.fill('')
	.map((it, i) => ({ label: String(i), value: String(i) } as IOptions))
	.concat([{ label: '65+', value: '65' } as IOptions])

const StepFifth = () => {
	const put = useDispatch()
	const { values, setFieldValue, errors } = useFormikContext<ProjectFormDto>()
	const { isLoading , genres, categories } = useFeelsApp([
		TStatusesFeels.genres,
		TStatusesFeels.categories,
	])

	const [optionsAgeTo, setOptionsAgeTo] = useState(optionsAgeFrom)

	const status = values.stepFirst.status.value
	const form = values.stepFifth
	const formFirst = values.stepFirst
	const formErrors = errors.stepFifth

	const categoriesOptionsDisabled = useMemo(() => {
		if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
			return categories?.filter((el) => el.hidden).map((el) => el.value)
		}
		return []
	}, [status, categories])

    const genresOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return genres?.filter((el) => el.hidden).map((el) => el.value)
        }
        return []
    }, [status, genres])

	const handlerTargetAudienceAgeFrom = (val: IOptions) => {
		if (val) {
			setOptionsAgeTo(optionsAgeFrom.slice(Number(val.value), optionsAgeFrom.length))
			setFieldValue('stepFifth.targetAudienceAgeTo', {
				value:
					form.targetAudienceAgeTo.value?.value && +val.value > +form.targetAudienceAgeTo.value?.value
						? null
						: form.targetAudienceAgeTo.value,
				isDisabled: false,
			})
		} else {
			setOptionsAgeTo(optionsAgeFrom)
			setFieldValue('stepFifth.targetAudienceAgeTo', { value: null, isDisabled: true })
		}
	}

	const handlerUpdateCategories = () => put(GetFeelsStartAction(TStatusesFeels.categories))
	const handlerUpdateGenres = () => put(GetFeelsStartAction(TStatusesFeels.genres))

	return (
		<div className={styles.container}>
			<WhetherTheUser role={['ROLE_CONTRACTOR']} isCan={false}>
				<ThemeNationalProject />
			</WhetherTheUser>

			{/*--------------*/}
			<div className={classNames('label', styles.category_label)}>Категории проекта</div>
			<div className={styles.category}>
				<Spin spinning={isLoading || !categories}>
					<ReloadList className="ident" handlerUpdateList={handlerUpdateCategories} />
					<SelectComponent
						name={filingName('categoryTypes')}
						placeholder="Выберите одну или несколько категорий проекта"
						options={categories}
						disabledOptionsIds={categoriesOptionsDisabled}
						multi={true}
						showError={false}
						disabled={form.categoryTypes?.isDisabled}
						errorMessage={formErrors?.categoryTypes as string}
						maxTagCount={'responsive'}
					/>
				</Spin>
			</div>
			<div className={classNames('label', styles.genre_label)}>Жанры проекта</div>
			<div className={styles.genre}>
				<Spin spinning={isLoading || !genres}>
					<ReloadList className="ident" handlerUpdateList={handlerUpdateGenres} />
					<SelectComponent
						name={filingName('genreTypes')}
						placeholder="Выберите один или несколько жанров проекта"
						options={genres}
                        disabledOptionsIds={genresOptionsDisabled}
						multi={true}
						showError={false}
						disabled={form.genreTypes?.isDisabled}
						errorMessage={formErrors?.genreTypes as string}
						maxTagCount={'responsive'}
					/>
				</Spin>
			</div>

			{/*--------------*/}
			<WhetherTheUser role={['ROLE_CONTRACTOR']} isCan={false}>
				<>
					<div className={classNames('label', styles.licenseDate_label)}>Дата приема-передачи лицензии ИРИ</div>
					<div className={styles.licenseDate}>
						<DatePickerComponent
							name={filingName('licenseDate')}
							disabled={form.licenseDate.isDisabled}
							errorMessage={formErrors?.licenseDate as string}
							isAfterDate={formFirst.agreementDate.value || formFirst.contractDate.value}
							isBeforeDate={formFirst.completionDate.value}
							isAfter={true}
							isBefore={true}
						/>
					</div>
				</>
			</WhetherTheUser>

			{/*-----------------*/}
			<div className={classNames('label', styles.targetAudienceAgeFrom_label)}>Целевая аудитория проекта</div>
			<div className={styles.targetAudienceAgeFrom}>
				<span>От</span>
				<SelectComponent
					options={optionsAgeFrom}
					name={`${filingName('targetAudienceAgeFrom')}`}
					placeholder={'Укажите возраст'}
					disabled={form.targetAudienceAgeFrom.isDisabled}
					handler={handlerTargetAudienceAgeFrom}
				/>
			</div>
			<div className={styles.targetAudienceAgeTo}>
				<span>До</span>
				<SelectComponent
					options={optionsAgeTo}
					name={`${filingName('targetAudienceAgeTo')}`}
					placeholder={'Укажите возраст'}
					disabled={form.targetAudienceAgeTo.isDisabled}
				/>
			</div>
			{/*-------------*/}

			<div className={classNames('label', styles.famousPeople_label)}>Известные люди проекта</div>
			<div className={styles.famousPeople}>
				<FamousPeople name={filingName('famousPeople')} disabled={form.famousPeople.isDisabled} />
			</div>

			{/*--------------------*/}

			<div className={classNames('label', styles.contentLocationRegions_label)}>Места съемок контента</div>
			<div className={styles.contentLocationRegions}>
				<LocationRegions/>
			</div>

			{/*--------------------*/}
			<WhetherTheUser role={['ROLE_CONTRACTOR']} isCan={false}>
				<>
					<div className={classNames('label', styles.filmClusters_label)}>Кинокластеры</div>
					<div className={styles.filmClusters}>
						<FilmClusters/>
					</div>
				</>
			</WhetherTheUser>
			{/*----------------*/}
			<div className={classNames('label', styles.awards_label)}>Награды проекта</div>
			<div className={styles.awards}>
				<AwardComponent />
			</div>

			{/*----------------*/}
			<WhetherTheUser role={['ROLE_CONTRACTOR']} isCan={false}>
				<>
					<div className={classNames('label', styles.projectDataCheckedDatetime_label)}>Данные проекта</div>
					<div className={styles.projectDataCheckedDatetime}>
						<CheckboxComponent
							disabled={form?.projectDataCheckedDatetime.isDisabled}
							name={`stepFifth.projectDataCheckedDatetime.code`}
							placeholder={`${
								form?.projectDataCheckedDatetime.code
									? 'данные проекта проверены редактором каталога '
									: 'данные проекта не проверены редактором каталога '
							}`}
						/>
					</div>
				</>
			</WhetherTheUser>
		</div>
	)
}

export default React.memo(StepFifth)
