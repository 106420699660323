import React, {FC} from 'react';
import styles from './styles.module.less';
import {LeftOutlined} from "@ant-design/icons";
import {useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {SetPathAction} from "store/app/actions";
import { SessionStorage } from '../../lib';
import { Breadcrumb } from '../../type';
import { Tooltip } from 'antd';

type ComeBackBtnProps = {
    breadcrumbs?: Array<Omit<Breadcrumb, 'id'>>
}
const ComeBackBtn: FC<ComeBackBtnProps> = ({breadcrumbs}) => {
    const put = useDispatch();
    const history = useHistory();
    const list = SessionStorage.getList();

    const pathComponent = () => {
        if (!!list.length) {
            return list.map((el) => (
              <div key={el.id} className={styles.link} onClick={() => {
                history.push(el.link, {tooltip: el.tooltip})
                put(SetPathAction({path: el.link, title: el.name}))
              }}>
                <Tooltip title={el.tooltip}>
                  <LeftOutlined />
                  <span>{el.name}</span>
                </Tooltip>

              </div>
            ))
        } if (!!breadcrumbs?.length) {
            return breadcrumbs.map((el) => (
                    <div key={el.link} className={styles.link} onClick={() => {
                        history.push(el.link, {tooltip: el.tooltip})
                        put(SetPathAction({path: el.link, title: el.name}))
                    }}>
                        <LeftOutlined />
                        <span>{el.name}</span>
                    </div>
                ))
        }
        else {
            return (
                <div className={styles.link} onClick={() => window.history.back()}>
                    <LeftOutlined />
                    <span>Назад</span>
                </div>
            )
        }
    }

    return (
        <div className={styles.comeBackBtn}>
            {pathComponent()}
        </div>
    )


};
export default ComeBackBtn;