import {z} from "zod";
import {PersonSchema} from "./person";
import {SearchResponse, SortSchema} from "shared/types/common";
import {RoleNameSchema} from "./user";
import {TSearchStringType} from "models/user";

export const OptionsUserContentSchema = PersonSchema.omit({
    roleName: true,
    isTutor: true,
    tutorVisible: true,
    isMain: true,
    image: true,
    emailConfirmed: true,
    userPhoneConfirmed: true,
    telegramConfirmed: true,
    organizations: true,
    keycloakId: true,
    keycloakPhone: true,
    keycloakEmail: true,
}).extend({
    isTelegramConfirmed: z.boolean(),
});

export type OptionsUserContent = z.infer<typeof OptionsUserContentSchema>

export type OptionsUserResponse = SearchResponse<OptionsUserContent>

export const FilterDataSchema = z.object({
    searchString: z.string().optional(),
    searchStringType: z.enum([TSearchStringType.CS, TSearchStringType.EQ]).optional(),
    roleNames: z.array(RoleNameSchema).optional(),
    status: z.enum(['ACTIVE', 'BLOCKED', 'ARCHIVE']).optional(),
    bulkAction: z.string().nullable().optional(),
    contentViewAccess: z.boolean().optional(),
    contentDownloadAccess: z.boolean().optional(),
    contentStorageLinkAccess: z.boolean().optional(),
    privateProjectViewAccess: z.boolean().optional(),
    isDeleted: z.boolean().optional(),
    fieldFilters: z.array(z.object({
        field: z.enum(['DELETED_DATETIME', 'LAST_ACTION_DATETIME' , 'KEYCLOAK_LAST_SYNC_DATETIME']).optional(),
        startDate: z.string().optional(),
        endDate: z.string().optional()
    })).optional(),
    isKeycloakSynced: z.boolean().optional(),
    limitedRightsToViewProjectsList: z.boolean().optional(),
    limitedRightsToViewContentTaskRecords: z.boolean().optional()
})

export const FilterSchema = z.object({
    filterData: FilterDataSchema.optional(),
    page: z.number(),
    pageSize: z.number().optional(),
    sort: SortSchema.optional(),
    totalCount: z.number().optional()
})
export type OptionsUserFilter = z.infer<typeof FilterSchema>
