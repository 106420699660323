import {TPaginationDefault} from "models/common";
import {z} from "zod";

const ActionEnums = z.enum(['CREATE' , 'UPDATE'])
const SortingEnums = z.enum(['ALL' , 'CURRENT'])

export type TypeAction = z.infer<typeof ActionEnums>;
export type TypeSorting = z.infer<typeof SortingEnums>;

export const CommentSchema = z.object({
        id: z.string(),
        taskId: z.string(),
        createDateTime: z.string(),
        updateDateTime: z.string(),
        text: z.string(),
        user: z.object({
            id: z.string(),
            lastName: z.string(),
            firstName: z.string(),
            middleName: z.string(),
            role: z.object({
                id: z.string(),
                name: z.string(),
                ruName: z.string(),
            })
        })
    }
)

export const CommentsRequestSchema = z.object( {
    taskId: z.string(),
    text: z.string()
})

export type CommentsResponse = {
    content: Comment[]
} & TPaginationDefault

export type Filter = {
    filterData?: {
        searchString?: string
        taskId?: string
        userId?: string
    }
} & TPaginationDefault

export type CommentsRequest = z.infer<typeof CommentsRequestSchema>;
export type Comment = z.infer<typeof CommentSchema>;
