import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Select, Spin} from "antd";
import AppService from "services/appService";
import {ExtendedSearchModel, ExtendedSearchTypes} from 'features/extended-search/index';
import {transformArrayForValue} from "utils/transformObjectFeels";
import {IOptions} from "models/feels";
import {useQuery} from "react-query";

type TKey = keyof Omit<
    ExtendedSearchTypes.Stores.Project, 'isToggled'>
function useGetsData() {
    const requestNumbers = useQuery({
        queryKey: [AppService.requestNumbersKey],
        queryFn: async () => {
            const {data} = await AppService.getRequestNumbers()
            return transformArrayForValue(data)
        },
        refetchOnWindowFocus: false
    })
    const contractNumbers = useQuery({
        queryKey: [AppService.contractNumbersKey],
        queryFn: async () => {
            const {data} = await AppService.getContractNumbers()
            return transformArrayForValue(data)
        },
        refetchOnWindowFocus: false
    })
    const codes = useQuery({
        queryKey: [AppService.codesKey],
        queryFn: async () => {
            const {data} = await AppService.getCodes()
            return transformArrayForValue(data)
        },
        refetchOnWindowFocus: false
    })
    const igks = useQuery({
        queryKey: [AppService.igksKey],
        queryFn: async () => {
            const {data} = await AppService.getIgks()
            return transformArrayForValue(data)
        },
        refetchOnWindowFocus: false
    })

    return {
        loading: requestNumbers.isLoading || contractNumbers.isLoading || codes.isLoading || igks.isLoading,
        options: {
            requestNumbers: requestNumbers.data || [],
            contractNumbers: contractNumbers.data || [],
            codes: codes.data || [],
            igks: igks.data || [],
        }
    }
}
export const ProjectItem = () => {
    const {loading, options} = useGetsData();

    return (
        <Spin spinning={loading}>
            <div className="item-wrapper">
                <Item options={options.requestNumbers} name={'requestNumbers'} label={'Номер заявки'}/>
                <Item options={options.contractNumbers} name={'contractNumbers'} label={'Номер договора'}/>
                <Item options={options.codes} name={'codes'} label={'Код'}/>
                <Item options={options.igks} name={'igks'} label={'ИГК'}/>
            </div>
        </Spin>

    )
}

type ItemProps = {
    options: IOptions[],
    name: TKey,
    label: string
}
function Item({options, name, label}: ItemProps) {
    const put = useDispatch();
    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const project = useSelector(ExtendedSearchModel.selectors.project)

    function onSelectChange(value: string[]) {
        put(ExtendedSearchModel.actions.setProject(typeContent, {[name]: value}))
    }

    return (
        <div className="item">
            <div className="label">{label}</div>
            <div className='form-group'>
                <Select
                    allowClear={true}
                    onChange={onSelectChange}
                    maxTagCount={"responsive"}
                    mode={"multiple"}
                    value={project[typeContent][name]}
                    placeholder={'Искать среди всех'}
                    options={options}
                    getPopupContainer={trigger => trigger.parentNode}
                />
            </div>
        </div>
    )
}