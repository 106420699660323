import {AxiosResponse} from "axios";
import $api from "../http";
import {
    TContentCountResponse,
    TContentExtendedSearch,
    TContentFilter,
    TContentFilterData,
    TContentResponse,
    TProjectResponse,
    TypeContentFilter,
    TypeContentResponse
} from "models/content";
import {TTag, TTagSecondLevel} from "../models/tags";
import {T_ID_NAME_CODE} from "models/common";

export class ContentService {
    static getContents(data: TContentFilter): Promise<AxiosResponse<TContentResponse>> {
        const filter = {...data, page: data.page - 1};
        return $api.post<TContentResponse>('/contents/projects/search', filter)
    }
    static getContentsExtendedSearch(data: TContentFilter<TContentExtendedSearch>): Promise<AxiosResponse<TContentResponse>> {
        const filter = {...data, page: data.page - 1};
        return $api.post<TContentResponse>('/contents/projects/extended-search', filter)
    }

    static getContentsCount(data?: TContentFilterData): Promise<AxiosResponse<TContentCountResponse[]>> {
        let filter = undefined;
        if (data) {
            const {contentModuleSectionId, ...rest} = data;
            filter = rest
        }
        return $api.post<TContentCountResponse[]>('/contents/count', filter)
    }
    static getContentsExtendedSearchCount(data: TContentFilter<TContentExtendedSearch>): Promise<AxiosResponse<TContentCountResponse[]>> {
        const filter = {...data, page: data.page - 1};
        return $api.post<TContentCountResponse[]>('/contents/projects/extended-search/count', filter)
    }

    static getProject(projectId: string): Promise<AxiosResponse<TProjectResponse>> {
        return $api.get<TProjectResponse>(`/contents/projects/${projectId}`)
    }

    static async getContent(data: TypeContentFilter): Promise<AxiosResponse<TypeContentResponse>> {
        const filter = {...data, page: data.page - 1}
        return $api.post<TypeContentResponse>('/contents/search', filter)
    }
    static getContentModuleSections(projectIds?: string[]): Promise<AxiosResponse<T_ID_NAME_CODE[]>> {
        return $api.get<T_ID_NAME_CODE[]>(`/contents/content-module-sections`, {
            params: {projectIds}
        })
    }
    static regionsAboutKey = '/contents/regions_about'
    static getRegionsAbout(): Promise<AxiosResponse<TTagSecondLevel[]>> {
        return $api.get('/contents/regions_about')
    }
    static regionsForKey = '/contents/regions_for'
    static getRegionsFor(): Promise<AxiosResponse<TTag[]>> {
        return $api.get('/contents/regions_for', )
    }
}