import { UserTypes } from "entities/user";

export default function convertName(user: UserTypes.CurrentUser | null) {
    if (user && user.personalData) {
        if (user.personalData.firstName && user.personalData.lastName) {
            return (user.personalData.firstName.slice(0, 1) + user.personalData.lastName.slice(0, 1)).toUpperCase();
        } else if (user.personalData.firstName) {
            return (user.personalData.firstName.slice(0, 2)).toUpperCase();
        } else if (user.personalData.lastName) {
            return (user.personalData.lastName.slice(0, 2)).toUpperCase();
        }
    } else if (user?.username) {
        return user.username.slice(0, 2).toUpperCase();
    } else {
        return "ИИ"
    }
}