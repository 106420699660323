import {ActionTypes} from "./types";
import {TMainState} from "./reducer";

export const setCFMain = <T extends Partial<TMainState>>(value: T) => ({
    type: ActionTypes.SET_VALUE, value
})
export const clearCFMain = () => ({
    type: ActionTypes.CLEAR_VALUE
})
export const FetchCF = (typeContent: TMainState["typeContent"]) => ({
    type: ActionTypes.FETCH_DATA, typeContent
})
