import React from 'react';
import './index.less';
import FilterSearch from "./filter-search";
import FilterInfo from "./filter-info";
import UserFilters from "features/user-filters/ui";


const Filters = () => {
    return (
        <div className="ContentPage__filters">
            <div className="ContentPage__filters-top">
                <div className="ContentPage__filters-left">
                    <FilterSearch/>
                    <FilterInfo/>
                </div>
            </div>
            <UserFilters filterType={'PROJECTS_CONTENT'} needToMakeRequest={true}/>
        </div>
    )
};
export default Filters;

