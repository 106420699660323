import {put, select} from "redux-saga/effects";
import {Logger} from "shared/services";
import {selectTasksFilter} from "store/tasks/selectors";
import {TTasksKanbanFilter} from "models/task/task.kanban";
import {selectProjectId} from "store/projects/selectors";
import {SetTasksKanbanFilter} from "store/tasks/actions/gant-kanban";

export function* tasks(filterData: string, filterId: string | undefined) {
    try {
        const filter: TTasksKanbanFilter = yield select(selectTasksFilter)
        const projectId: string = yield select(selectProjectId)
        if (projectId) {
            if(filterId) {
                const filterDataTasks: TTasksKanbanFilter = JSON.parse(filterData);
                yield  put(SetTasksKanbanFilter(projectId, {...filter, filterData: {...filterDataTasks.filterData}}))
            } else {
                yield put(SetTasksKanbanFilter(projectId,{ filterData: {searchString: null, stageId: null} }))
            }
        }
    } catch (error) {
        Logger.error(error)
    }
}