import {getTypes, setUserFiltersValue} from "../store/actions";
import {put} from "redux-saga/effects";
import Api from "../../api";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {AxiosResponse} from "axios";
import {Types, TypesSchema} from "../../types";
import ZodParser from "shared/services/zod-parser";

export function* getTypesWorker(_action: ReturnType<typeof getTypes>) {
    try {
        yield put(setUserFiltersValue({isLoading: true}))
        const {data}: AxiosResponse<Types[]> = yield Api.getTypes();
        ZodParser.parse(TypesSchema.array(), data, 'getTypesWorker getTypes')
        yield put(setUserFiltersValue({types: data}))
    } catch (error) {
        yield put(setUserFiltersValue({error: error}))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
    } finally {
        yield put(setUserFiltersValue({isLoading: false}))
    }
} 