import { z } from 'zod'
import { UserTypes } from 'entities/user'
import { variantOther } from 'shared/ui/icon'

const levelGroupName2Schema = z.object({
	name: z.string(),
	screenshots: z.array(z.string()).optional(),
	points: z.union([
		z.array(z.string()),
		z.array(z.object({
			label: z.string(),
			icon: z.enum(variantOther)
		}))
	]).optional(),
	text: z.string().optional(),
	important: z.array(z.string()).optional(),
	roles: z.array(UserTypes.RoleNameSchema),
	icons: z.array(z.enum(variantOther)).optional(),
	topIcons: z.array(z.enum(variantOther)).optional(),
})

export const FunctionalityInfoSchema = z.array(
	z.object({
		LevelGroupName: z.string(),
		LevelGroupData: z.array(levelGroupName2Schema),
	})
)

export type FunctionalityInfoType = z.infer<typeof FunctionalityInfoSchema>
