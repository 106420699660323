import $api from "../../http";
import {Filter, Types} from "./types";
import {AxiosResponse} from "axios";

export const ApiKeys = {
    filters: '/filters',
    filtersTypes: '/filters/types',
    filtersTypeCode: '/filters/types/filterTypeCode',
}

type FilterCreateAndUpdate = {name?: string} & Pick<Filter, 'filterData' | 'filterTypeId'>
export default class Api {
    static async update(id: string, filter: FilterCreateAndUpdate): Promise<any> {
        return $api.put(`/filters/${id}`, filter)
    }
    static async delete(id: string): Promise<any> {
        return $api.delete(`/filters/${id}`)
    }
    static async create(filter: FilterCreateAndUpdate): Promise<any> {
        return $api.post('/filters', filter)
    }
    //Получение списка пользовательских фильтров для текущего пользователя
    static async getTypes(): Promise<AxiosResponse<Types[]>> {
        return $api.post('/filters/types')
    }
    //Получение списка пользовательских фильтров для текущего пользователя
    static async getFilters(filterTypeCode: Types['code']): Promise<AxiosResponse<Filter[]>> {
        return $api.get(`/filters/types/${filterTypeCode}`)
    }
}