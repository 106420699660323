import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteContentDirectionErrorAction,
    DeleteContentDirectionStartAction,
    DeleteContentDirectionSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import HandbookService from "services/handbookService";
import {TStatusesHandbookFeels} from "models/handbook";


export function* deleteContentDirection({id}: ReturnType<typeof DeleteContentDirectionStartAction>) {
    try {
        yield HandbookService.deleteContentDirectionRecord(id)
        yield put(DeleteContentDirectionSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.contentDirection))

        yield put(NotificationOpen('success', 'Тип направлений контента удален'))

    } catch (error: any) {
        yield put(DeleteContentDirectionErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить тип направлений контента'))
    }
}
