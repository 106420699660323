import {takeLatest} from 'redux-saga/effects'
import {getAnalyticsProject} from './getAnalyticsProject'
import {AnalyticsActionTypes} from '../../store/analytics/action-types'
import {getAnalyticsPostingTopResources} from './getAnalyticsPostingTopResources'
import {getAnalyticsKpiTopProject} from './getAnalyticsKpiTopProject'
import {getAnalyticsKpiTopResources} from './getAnalyticsKpiTopResources'
import {getAnalyticsSessionContentKpi} from './getAnalyticsSessionContentKpi'
import {setAnalyticsFilter} from './setAnalyticsFilter'
import {getAnalyticsSessionContentPosting} from "./getAnalyticsSessionContentPosting";
import {getAnalyticsReachData} from "./getAnalyticsReachData";
import {setAnalyticsFilterReach} from "./setAnalyticsFilterReach";
import {getAnalyticsSummaryData} from "./getAnalyticsSummaryData";

export default function* analyticsSaga() {
	yield takeLatest(AnalyticsActionTypes.GET_ANALYTICS_PROJECT_START, getAnalyticsProject)
	yield takeLatest(AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_KPI_START, getAnalyticsSessionContentKpi)
	yield takeLatest(AnalyticsActionTypes.GET_ANALYTICS_POSTING_TOP_RESOURCES_START, getAnalyticsPostingTopResources)
	yield takeLatest(AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_PROJECT_START, getAnalyticsKpiTopProject)
	yield takeLatest(AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_RESOURCES_START, getAnalyticsKpiTopResources)
	yield takeLatest(AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_POSTING_START, getAnalyticsSessionContentPosting)
	yield takeLatest(AnalyticsActionTypes.GET_ANALYTICS_REACH_DATA_START, getAnalyticsReachData)
	yield takeLatest(AnalyticsActionTypes.GET_ANALYTICS_SUMMARY_DATA_START, getAnalyticsSummaryData)
	yield takeLatest(AnalyticsActionTypes.SET_ANALYTICS_FILTER, setAnalyticsFilter)
	yield takeLatest(AnalyticsActionTypes.SET_ANALYTICS_FILTER_REACH, setAnalyticsFilterReach)
}
