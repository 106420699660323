import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    AddRegionsErrorAction,
    AddRegionsStartAction,
    AddRegionsSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import RegionService from "services/regionService";

export function* addRegionsRecord({ data}: ReturnType<typeof AddRegionsStartAction>) {
    try {
        yield RegionService.addIRegionsRecord(data)
        yield put(AddRegionsSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.regions))
        yield put(NotificationOpen('success', 'Новый регион добавлен'))
    } catch (error: any) {
        yield put(AddRegionsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить регион'))
    }
}
