import {InferValueTypes} from '../../models/common'

import * as actions from './actions'
import {AnalyticsActionTypes} from './action-types'
import {ErrorActionState, StartActionState, SuccessActionState} from '../../utils/reducerHalper'
import {AuthActionTypes} from '../auth/action-types'
import {Logout} from '../auth/reducer'
import {
	TAnalyticsContentResponse,
	TAnalyticsFilter,
	TAnalyticsFilterReach,
	TAnalyticsKpiTopProjectResponse,
	TAnalyticsKpiTopResourcesResponse,
	TAnalyticsPostingTopResourcesResponse,
	TAnalyticsProjectResponse,
	TAnalyticsReachDataResponse,
	TAnalyticsSessionResponse,
} from '../../models/analytics/analytics-project'

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TAnalyticsState = {
	summaryData: any | null
	reachData: TAnalyticsReachDataResponse[] | null
	projectCount: TAnalyticsProjectResponse[] | null
	sessionContentKpi: TAnalyticsSessionResponse[] | TAnalyticsContentResponse[] | null
	sessionContentPosting: TAnalyticsSessionResponse[] | TAnalyticsContentResponse[] | null
	postingTop: TAnalyticsPostingTopResourcesResponse[] | null
	kpiTop: TAnalyticsKpiTopResourcesResponse[] | null
	kpiTopProject: TAnalyticsKpiTopProjectResponse[] | null
	filter: TAnalyticsFilter | {}
	filterReach: TAnalyticsFilterReach | {}
	isLoading: boolean
	error: any
}

export const initialState: TAnalyticsState = {
	summaryData: null,
	reachData: null,
	projectCount: null,
	sessionContentKpi: null,
	sessionContentPosting: null,
	postingTop: null,
	kpiTop: null,
	kpiTopProject: null,
	filter: {},
	filterReach: {},
	isLoading: false,
	error: null,
}

export default function reducer(state: TAnalyticsState = initialState, action: ActionTypes | Logout): TAnalyticsState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return { ...initialState }
		case AnalyticsActionTypes.SET_ANALYTICS_FILTER:
			return { ...state, filter: action.filter }
		case AnalyticsActionTypes.SET_ANALYTICS_FILTER_REACH:
			return { ...state, filterReach: action.filter }
		case AnalyticsActionTypes.CLEAR_ANALYTICS_FILTER_REACH:
			return { ...state, filterReach: {}, reachData: null }
		case AnalyticsActionTypes.CLEAR_ANALYTICS_DATA:
			return {
				...state,
				sessionContentKpi: null,
				sessionContentPosting: null,
				projectCount : null,
				postingTop: null,
				kpiTop: null,
				kpiTopProject: null,
			}
		/** START ACTIONS  */
		case AnalyticsActionTypes.GET_ANALYTICS_PROJECT_START:
			return StartActionState(state)
		case AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_KPI_START:
			return StartActionState(state)
		case AnalyticsActionTypes.GET_ANALYTICS_POSTING_TOP_RESOURCES_START:
			return StartActionState(state)
		case AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_RESOURCES_START:
			return StartActionState(state)
		case AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_PROJECT_START:
			return StartActionState(state)
		case AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_POSTING_START:
			return StartActionState(state)
		case AnalyticsActionTypes.GET_ANALYTICS_REACH_DATA_START:
			return StartActionState(state)
		case AnalyticsActionTypes.GET_ANALYTICS_SUMMARY_DATA_START:
			return StartActionState(state)

		/** SUCCESS ACTIONS  */
		case AnalyticsActionTypes.GET_ANALYTICS_PROJECT_SUCCESS:
			return {
				...SuccessActionState(state),
				projectCount: action.data,
			}
		case AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_KPI_SUCCESS:
			return {
				...SuccessActionState(state),
				sessionContentKpi: action.data,
			}
		case AnalyticsActionTypes.GET_ANALYTICS_POSTING_TOP_RESOURCES_SUCCESS:
			return {
				...SuccessActionState(state),
				postingTop: action.data,
			}
		case AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_RESOURCES_SUCCESS:
			return {
				...SuccessActionState(state),
				kpiTop: action.data,
			}
		case AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_PROJECT_SUCCESS:
			return {
				...SuccessActionState(state),
				kpiTopProject: action.data,
			}
		case AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_POSTING_SUCCESS:
			return {
				...SuccessActionState(state),
				sessionContentPosting: action.data,
			}
		case AnalyticsActionTypes.GET_ANALYTICS_REACH_DATA_SUCCESS:
			return {
				...SuccessActionState(state),
				reachData: action.data,
			}
		case AnalyticsActionTypes.GET_ANALYTICS_SUMMARY_DATA_SUCCESS:
			return {
				...SuccessActionState(state),
				summaryData: action.data,
			}

		/** ERROR ACTIONS  */

		case AnalyticsActionTypes.GET_ANALYTICS_PROJECT_ERROR:
			return ErrorActionState(state, action)
		case AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_KPI_ERROR:
			return ErrorActionState(state, action)
		case AnalyticsActionTypes.GET_ANALYTICS_POSTING_TOP_RESOURCES_ERROR:
			return ErrorActionState(state, action)
		case AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_RESOURCES_ERROR:
			return ErrorActionState(state, action)
		case AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_PROJECT_ERROR:
			return ErrorActionState(state, action)
		case AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_POSTING_ERROR:
			return ErrorActionState(state, action)
		case AnalyticsActionTypes.GET_ANALYTICS_REACH_DATA_ERROR:
			return ErrorActionState(state, action)
		case AnalyticsActionTypes.GET_ANALYTICS_SUMMARY_DATA_ERROR:
			return ErrorActionState(state, action)
		default:
			return state
	}
}
