import {
	GetUsersListErrorAction,
	GetUsersListStartAction,
	GetUsersListSuccessAction,
	SetUsersFilter,
} from 'store/options/actions/actions-users'
import {AxiosResponse} from 'axios'
import {put, select} from 'redux-saga/effects'
import {selectUsersListFilter} from 'store/options/selectors/selectors-users'
import {UserApi, UserTypes} from 'entities/user'

export default function* getUserList(_action: ReturnType<typeof GetUsersListStartAction>) {
	try {
		let filter: UserTypes.OptionsUserFilter = yield select(selectUsersListFilter)

		if ( filter.filterData?.bulkAction === 'DOWNLOAD_SELECTED_DATA' || filter.filterData?.bulkAction === 'DOWNLOAD_ALL_DATA') {
			filter = {...filter, filterData : {...filter.filterData , bulkAction: null}}
		}
		const { data }: AxiosResponse<UserTypes.OptionsUserResponse> = yield UserApi.UserController.fetchWithFilter(filter)

		if (data.totalCount && data.page * data.pageSize >= data.totalCount && data.totalCount !== 0) {
			yield put(SetUsersFilter({...filter, page: 1}))
		} else {
			yield put(GetUsersListSuccessAction(data))
		}
	} catch (error: any) {
		yield put(GetUsersListErrorAction(error))
	}
}
