import {put, select} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import {AxiosResponse} from 'axios'
import HandbookService from "services/handbookService";

import {
	TDocumentThematicsListResponse,
	THandbookContentResponse,
	THandbookFilter,
	THandbookLegaFormOrganizationResponse,
	THandbookOrganizationResponse,
	TInternetResourceResponse,
	TStatusesHandbookFeels,
} from 'models/handbook'
import {
	GetHandbookErrorAction,
	GetHandbookStartAction,
	GetHandbookSuccessAction,
	SetHandbookFilter,
} from 'store/options/actions/actions-handbook'
import {
	selectHandbookActionsUsersFilter,
	selectHandbookCategoryProjectFilter,
	selectHandbookContentDeliveryFilter,
	selectHandbookContentDirectionFilter,
	selectHandbookDocumentsFilter,
	selectHandbookGenreProjectFilter,
	selectHandbookInternetResourceFilter,
	selectHandbookKPEFilter,
	selectHandbookLegalFormFilter,
	selectHandbookOrganizationFilter,
	selectHandbookRanksFilter,
	selectHandbookRegionsFilter,
	selectHandbookSessionsTypeFilter,
	selectHandbookThematicsFilter,
} from 'store/options/selectors/selectors-handbook'
import {onSearchValue} from "pages/options/handbook/page-actions";
import RegionService from "services/regionService";
import RanksService from "services/ranksService";
import LegalFormOrganizationService from "services/legalFormOrganizationService";
import SessionTypeService from "services/sessionTypeService";
import CategoryProjectService from "services/categoryProjectService";
import GenreProjectService from "services/genreProjectService";

const FeelActions = {
	'thematic': HandbookService.getHandBookThematics,
	'internet': HandbookService.getHandBookInternetResources,
	'contentDelivery': HandbookService.getHandBookContentDelivery,
	'actionsUsers': HandbookService.getHandBookActionsUsers,
	'KPE' : HandbookService.getHandBookKPE,
	'contentDirection' : HandbookService.getHandBookContentDirection,
	'organization' : HandbookService.getHandBookOrganization,
	'documents' : HandbookService.getHandBookDocuments,
	'regions' : RegionService.getRegionsWithFilter,
	'ranks' : RanksService.getRanksWithFilter,
	'legalFormOrganization' : LegalFormOrganizationService.getLegalFormOrganizationWithFilter,
	'sessionsType' : SessionTypeService.getSessionsTypeWithFilter,
	'categoryProject' : CategoryProjectService.getCategoryProjectWithFilter,
	'genreProject' : GenreProjectService.getGenreProjectWithFilter,
}

type TFilter = {
	[key in TStatusesHandbookFeels]: any
}

const Filter: TFilter = {
	'thematic': selectHandbookThematicsFilter,
	'internet': selectHandbookInternetResourceFilter,
	'contentDelivery': selectHandbookContentDeliveryFilter,
	'actionsUsers': selectHandbookActionsUsersFilter,
	'KPE': selectHandbookKPEFilter,
	'contentDirection': selectHandbookContentDirectionFilter,
	'organization': selectHandbookOrganizationFilter,
	'documents': selectHandbookDocumentsFilter,
	'regions': selectHandbookRegionsFilter,
	'ranks': selectHandbookRanksFilter,
	'legalFormOrganization': selectHandbookLegalFormFilter,
	'sessionsType': selectHandbookSessionsTypeFilter,
	'categoryProject': selectHandbookCategoryProjectFilter,
	'genreProject': selectHandbookGenreProjectFilter,
}

export function* getHandbook({ feel }: ReturnType<typeof GetHandbookStartAction>) {
	try {
		const filter: THandbookFilter & {totalCount: number} = yield select(Filter[feel])
		const {
			data,
		}: AxiosResponse<
			| TInternetResourceResponse
			| THandbookContentResponse
			| TDocumentThematicsListResponse
			| THandbookOrganizationResponse
			| THandbookLegaFormOrganizationResponse
		> = yield FeelActions[feel](filter)
		if (data?.totalCount && data?.page * data.pageSize >= data?.totalCount && data?.totalCount !== 0) {
			const val =	 onSearchValue(feel)
			yield put(SetHandbookFilter({ [val]: {...filter, page: 1} }))
		} else {
			yield put(GetHandbookSuccessAction({ [feel]: data }))
		}

	} catch (error) {
		yield put(GetHandbookErrorAction(error))
		if (feel) {
			yield put(NotificationOpen('error', 'Ошибка', `Не удалось получить ${feel}`))
		}
	}
}
