import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetHandbookStartAction,
    UpdateInternetErrorAction,
    UpdateInternetStartAction,
    UpdateInternetSuccessAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import InternetResourcesService from "services/internetResourcesService";


export function* updateInternet({id, data}: ReturnType<typeof UpdateInternetStartAction>) {
    try {
        yield InternetResourcesService.updateInternet(data, id)
        yield put(UpdateInternetSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.internet))
        yield put(NotificationOpen('success', 'Интернет ресурс обновлен'))
    } catch (error: any) {
        yield put(UpdateInternetErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить интернет ресурс'))
    }
}
