import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    TasksReportCopyrightService,
    TasksReportEstimateService,
    TasksReportPostingService, TasksReportReachService
} from "services/tasksReportService";
import {ReportType} from "models/task/types";
import {
    DeleteTaskReportErrorAction,
    DeleteTaskReportStartAction,
    DeleteTaskReportSuccessAction,
    GetTaskReportStartAction
} from "store/tasks/actions/report";

export function* deleteReportTask({recordId, typeTask, taskId}: ReturnType<typeof DeleteTaskReportStartAction>) {
    try {
        switch (typeTask) {
            case ReportType.CONTENT_POSTING_REPORT:
                yield TasksReportPostingService.deleteTaskReport(recordId);
                break;
            case ReportType.COPYRIGHT_LIST:
                yield TasksReportCopyrightService.deleteTaskReport(recordId);
                break;
            case ReportType.PROJECT_ESTIMATE:
                yield TasksReportEstimateService.deleteTaskReport(recordId);
                break;
            case ReportType.CONTENT_REACH_REPORT:
                yield TasksReportReachService.deleteTaskReport(recordId);
                break;
            default:
                console.log('Тип задачи не поддерживается');
        }
        yield put(DeleteTaskReportSuccessAction())
        yield put(NotificationOpen('success', 'Запись удалена'))

        if (taskId) yield put(GetTaskReportStartAction(taskId, typeTask))
    } catch (error: any) {
        yield put(DeleteTaskReportErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen(
            'error',
            'Ошибка',
            message ? message : 'Не удалось удалить запись'))
    }
}