import {ProjectFormDto} from "../dto/project-form.dto";
import {step1} from "./step1";
import {step2} from "./step2";
import {step3} from "./step3";
import {step4} from "./step4";
import {step5} from "./step5";
import {CreateProject} from "../../type";

export const create = (projectForm: ProjectFormDto): CreateProject => {
    return {
        ...step1(projectForm.stepFirst),
        ...step2(projectForm.stepSecond),
        ...step3(projectForm.stepThird),
        ...step4(projectForm.stepFourth),
        ...step5(projectForm.stepFifth),
    }
}