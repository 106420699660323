import React from 'react'
import {useQuery} from 'react-query'
import styles from './styles.module.less'
import {SelectComponent} from 'components/formic-control'
import {IOptions} from 'models/feels'
import {ProjectApi} from 'entities/project'
import {useFormikContext} from 'formik'
import {InitialValueDto} from '../../lib/dto'
import {SessionApi} from 'entities/session'

const ProjectsSessions = () => {
	const { values } = useFormikContext<InitialValueDto>()
	const sessionIds = values?.sessions?.map((el: any) => el.value)

	const { data: sessions = [], isLoading: loadingSessions } = useQuery({
		queryKey: [SessionApi.SessionController.sessionsOptionsKey],
		queryFn: () => SessionApi.SessionController.getSessionsOptions(),
	})

	const { data: projects, isLoading: loadingProjects } = useQuery({
		queryKey: [ProjectApi.Project.projectsAllKey, { statusCodes: ['ACTUAL', 'ARCHIVED'], sessionIds: sessionIds || [] }],
		queryFn: async () => {
			const { data } = await ProjectApi.Project.getProjectsAll({ statusCodes: ['ACTUAL', 'ARCHIVED'], sessionIds: sessionIds || [] })
			return data.map((el) => ({ value: el.id, label: el.name, code: el.status.code } as IOptions))
		},
		enabled:  !!sessionIds,
	})
	return (
		<>
			<div className={styles.item}>
				<span className={styles.label}>Сессия</span>
				<div className={styles.field}>
					<SelectComponent
						options={sessions}
						multi={true}
						maxTagCount={'responsive'}
						loading={loadingSessions}
						disabled={values.disabledSessions}
						name={'sessions'}
						placeholder={'Выберите сессии для фильтрации списка проектов'}
					/>
				</div>
			</div>
			<div className={styles.item}>
				<span className={styles.label}>Проект</span>
				<div className={styles.field}>
					<SelectComponent
						options={projects || []}
						multi={true}
						maxTagCount={'responsive'}
						loading={loadingProjects}
						name={'projects'}
						disabled={values.disabledProjects}
						placeholder={'Выберите проекты, по которым будет выгружена информация'}
					/>
				</div>
			</div>
		</>
	)
}

export default ProjectsSessions
