import {InferValueTypes} from "../../models/common";
import * as actions from './actions';
import {FilesActionTypes} from "./action-types";
import {Logout} from "../auth/reducer";
import {AuthActionTypes} from "../auth/action-types";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

type TDataStatus = {
    isLoading: boolean;
    error: any;
}

export type TFilesState = Record<string, {
    data: any;
} & TDataStatus>;

export const initialState: TFilesState = {}

export default function reducer(state: TFilesState = initialState, action: ActionTypes| Logout): TFilesState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState};
        case FilesActionTypes.GET_FILES_START:
            return {
                ...state,
                [action.id]: {
                    data: state[action.id] ? state[action.id].data : null,
                    isLoading: !state[action.id],
                    error: null,
                }
            };
        case FilesActionTypes.GET_FILES_SUCCESS:
            return {
                ...state,
                [action.id]: {
                    data: action.file,
                    isLoading: false,
                    error: null,
                }
            }

        case FilesActionTypes.GET_FILES_ERROR:
            return {
                ...state,
                [action.id]: {
                    data: null,
                    isLoading: false,
                    error: action.error,
                }
            }
        default:
            return state
    }
}