import {UsersDoingTypes} from '../action-types'
import {TUserOptionsFilter, TUsersActionResponse,} from '../../../models/user'
import {UserTypes} from 'entities/user'

/**
 * Получение списка (активность)
 */

export const GetActivityUsersListStartAction = () => ({
	type: UsersDoingTypes.GET_ACTIVITY_USERS_LIST_START,
})

export const GetActivityUsersListSuccessAction = (data: TUsersActionResponse) => ({
	type: UsersDoingTypes.GET_ACTIVITY_USERS_LIST_SUCCESS,
	payload: data,
})

export const GetActivityUsersListErrorAction = (error: any) => ({
	type: UsersDoingTypes.GET_ACTIVITY_USERS_LIST_ERROR,
	error,
})

/**
 * Получение списка действий пользователя
 */

export const GetActionsUsersStartAction = () => ({
	type: UsersDoingTypes.GET_ACTIONS_USERS_START,
})

export const GetActionsUsersSuccessAction = (data: UserTypes.UserActionsResponse | null) => ({
	type: UsersDoingTypes.GET_ACTIONS_USERS_SUCCESS,
	payload: data,
})

export const GetActionsUsersErrorAction = (error: any) => ({
	type: UsersDoingTypes.GET_ACTIONS_USERS_ERROR,
	error,
})

/**
 * Изменение фильтра активности пользователей
 */
export const SetActivityUsersFilter = (filter: TUserOptionsFilter) => ({
	type: UsersDoingTypes.SET_ACTIVITY_USERS_FILTER,
	filter,
})

/**
 * Изменение фильтра действий пользователей
 */
export const SetActionsUsersFilter = (filter: TUserOptionsFilter) => ({
	type: UsersDoingTypes.SET_ACTIONS_USERS_FILTER,
	filter,
})
