import {all, put} from 'redux-saga/effects'
import {
	GetAnalyticsKpiTopProjectStartAction,
	GetAnalyticsKpiTopResourcesStartAction,
	GetAnalyticsPostingTopResourcesStartAction,
	GetAnalyticsProjectStartAction,
	GetAnalyticsSessionContentKpiStartAction,
	GetAnalyticsSessionContentPostingStartAction,
	SetAnalyticsFilter,
} from '../../store/analytics/actions'
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../store/notification/actions";

export function* setAnalyticsFilter({ dataType }: ReturnType<typeof SetAnalyticsFilter>) {
	try {
		yield all([
			put(GetAnalyticsSessionContentKpiStartAction(dataType)),
			put(GetAnalyticsKpiTopProjectStartAction()),
			put(GetAnalyticsPostingTopResourcesStartAction()),
			put(GetAnalyticsKpiTopResourcesStartAction()),
			put(GetAnalyticsProjectStartAction()),
			put(GetAnalyticsSessionContentPostingStartAction(dataType)),
		])
	} catch (error: any) {
		console.log(error)
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
