import React, { FC } from 'react';
import { Empty, Spin } from 'antd';
import { useQuery } from 'react-query';
import { getProjectAwards } from '../../api';
import styles from './styles.module.less';
import {Award} from "../../types";

const ProjectAwards: FC<{projectId?: string}> = ({projectId}) => {
	const awards = useQuery({
		queryKey: [getProjectAwards.key(projectId as string)],
		queryFn: () => getProjectAwards.fetch(projectId as string),
		enabled: !!projectId
	})
	return (
		<Spin spinning={awards.isLoading}>
			<div className={styles.container}>
				{awards.data?.length === 0 && <Empty className={styles.empty} description={(<span>Награды не найдены</span>)}/>}
				{awards.data?.map((el) => (
					<div className={styles.item} key={el.id}>{formatAward(el)}</div>
				))}
			</div>
		</Spin>

	)
};

function formatAward(award: Award) {
	const parts = [];
	if (award.year) parts.push(award.year)
	if (award.name) parts.push(award.name)
	if (award.nomination) parts.push(`"${award.nomination}"`)
	if (award.status) parts.push(award.status)

	return parts.join(" - ");
}

export default ProjectAwards;