import {takeEvery} from "redux-saga/effects";
import {UploaderActionTypes} from "store/uploader/action-types";
import {getUnfinishedFiles} from "./get-unfinished-files";
import {abortedLoad} from "./aborted-load";
import {startLoad} from "./start-load";
import {deleteFile} from "./delete-file";
import {setFileId} from "./set-fileId";
import {finishLoad} from "./finish-load";


export default function* uploaderSaga() {
    yield takeEvery(UploaderActionTypes.GET_UNFINISHED_START, getUnfinishedFiles)
    yield takeEvery(UploaderActionTypes.ABORTED_LOAD, abortedLoad)
    yield takeEvery(UploaderActionTypes.START_LOAD, startLoad)
    yield takeEvery(UploaderActionTypes.DELETE_FILES_START, deleteFile)
    yield takeEvery(UploaderActionTypes.SET_FILE_ID, setFileId)
    yield takeEvery(UploaderActionTypes.FINISH_LOAD, finishLoad)
}
