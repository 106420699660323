import { z } from 'zod';
import {UserTypes} from 'entities/user'
import { FileSchema, UploadFileSchema } from 'shared/types/file';

const UserSchema = z.object({
	id: z.string(),
	lastName: z.string(),
	firstName: z.string(),
	middleName: z.string().nullable(),
	role: z.object({
		id: z.string(),
		name: UserTypes.RoleNameSchema,
		ruName: z.string(),
	}),
	leader: z.boolean(),
	status: z.enum(['ACTIVE', 'BLOCKED', 'ARCHIVE']),
	keycloakLastSyncDatetime: z.string().nullable(), //check types
	contentViewAccess: z.boolean().nullable(),
	contentDownloadAccess: z.boolean().nullable(),
	contentStorageLinkAccess: z.boolean().nullable(),
})

export const VoteSchema = z.object({
	comment: z.string().nullable(),
	dateTime: z.string().nullable(),
	isApproved: z.boolean().nullable(),
	isInterimApproved: z.boolean().nullable(), //Признак промежуточного голоса
	files: z.array(FileSchema).nullable(),
	obsolete: z.boolean().nullable(),//Признак устаревшего голоса
	//todo add normal userSchema
	user: UserSchema,
})

export type Vote = z.infer<typeof VoteSchema>;

export const NewVoteSchema = z.object({
	vote: z.object({
		isApproved: z.boolean().nullable(),
		isInterimApproved: z.boolean().nullable(),
		recordId: z.string(),
		comment: z.string().nullable(),
	}),
	files: z.array(FileSchema.or(UploadFileSchema))
})
export type NewVote = z.infer<typeof NewVoteSchema>;