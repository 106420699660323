import {z} from "zod";
import {OrganizationsSchema} from "./organizations";
import {FileSchema} from "shared/types/file";
import {RoleNameSchema, RoleSchema} from "./user";

export const PersonSchema = z.object({
    id: z.string(),
    roleName: RoleNameSchema,
    role: RoleSchema,
    isTutor: z.boolean(),//Признак куратора
    tutorVisible: z.boolean(),//Признак видимости куратора для карточки проекта
    status: z.enum(['ACTIVE', 'BLOCKED', 'ARCHIVE']),
    isMain: z.boolean().nullable(),
    leader: z.boolean(),
    contactPhone: z.string(),
    contactPhoneCount: z.number(),
    email: z.string(),
    emailCount: z.number(),
    firstName: z.string(),
    image: FileSchema.nullable(),
    lastName: z.string(),
    middleName: z.string().nullable(),
    organizationINN: z.string(),
    organizationId: z.string(),
    organizationShortName: z.string(),
    organizationCount: z.number(),
    isReceivingNotificationsConfirmed: z.boolean(),
    isUserAgreementConfirmed: z.boolean(),
    emailConfirmed: z.boolean(),
    userPhoneConfirmed: z.boolean(),
    telegramConfirmed: z.boolean(),
    organizations: z.array(OrganizationsSchema),//Список организаций
    contentDownloadAccess: z.boolean(),
    contentStorageLinkAccess: z.boolean(),
    contentViewAccess: z.boolean(),
    deletedDatetime: z.string().nullable(),
    keycloakId: z.string().nullable(),
    keycloakPhone: z.string().nullable(),
    keycloakEmail: z.string().nullable(),
    keycloakLastSyncDatetime: z.string().nullable(),
    lastActionDateTime: z.string(),
    limitedRightsToViewContentTaskRecords: z.boolean(),
    limitedRightsToViewProjectsList: z.boolean(),
    projectsListLimitCount: z.number(),
    contentTaskRecordsLimitCount: z.number(),
})

export type Person = z.infer<typeof PersonSchema>;
