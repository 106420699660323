import { axiosRequest } from 'shared/config/axios'
import { NotificationTypes } from 'entities/notification'
export default class BrowserNotificationController {
	static getNotificationKey = '/notifications/browser-notifications/current_user'

	static async getNotification(read: boolean) {
		const { data } = await axiosRequest<NotificationTypes.BrowserNotifications>({
			method: 'GET',
			url: '/notifications/browser-notifications/current_user',
			params: { read },
		})
		return data
	}
	static async bulkUpdateNotification({ action, data }: { action: 'SET_READ' | 'SET_UNREAD'; data: string[] }) {
		await axiosRequest({
			method: 'POST',
			url: `/notifications/browser-notifications/bulk-actions/${action}/current_user`,
			data,
		})
	}
}
