import React, {MouseEvent, useState} from 'react';
import './index.less'
import {Button, Checkbox, Collapse, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {actions, selectors } from 'features/extended-search/model';
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {CaretDownOutlined, CaretRightOutlined, DeleteOutlined} from "@ant-design/icons";
import {
    AgreementOfProjectTaskRecordsItem,
    OrganizationItem,
    ProjectItem,
    StatusItem,
    IndicatorItem,
    ThematicsItem,
    RegionalsItem,
    ContentProjectItem,
    ChannelsItem,
    UsersItem,
    FamousPeopleItem,
    ContestsItem,
    DatesItem,
    CountsItem,
    CategoriesGenresItem,
    AwardsItem,
    IndicatorContentItem
} from './items';

import { ExtendedSearchModel } from 'features/extended-search/index';
import UserFilters, {SaveUserFilterButton, UserFilterName} from "features/user-filters/ui";
import {UserFiltersLib, UserFiltersModel, UserFiltersTypes } from 'features/user-filters';

const { Panel } = Collapse;

const ollItems: Items[] = [
    "project",
    "agreementOfProjectTaskRecordsItem",
    "organization",
    "contests",
    "status",
    "indicatorsContent",
    "indicators",
    "awards",
    "thematics",
    "regionals",
    "content",
    "channels",
    "users",
    "famousUsers",
    "dates",
    "counts",
    "categoriesGenres"
]
const initState: Items[] = ['project']

const ModalComponent = () => {

    const put = useDispatch();
    const {isOpen, typeContent} = useSelector(selectors.main);
    const isToggle = useSelector(selectors.isToggle)
    const isToggleFilter = useSelector(selectors.isToggleFilter(typeContent))
    const type = (typeContent === 'projects' || typeContent === 'calendar') ? 'проектов' : 'контента'
    const [checked, setChecked] = useState(false);
    const [checkedContent, setCheckedContent] = useState(false);
    const [checkedCalendar, setCheckedCalendar] = useState(false);

    const [open, setOpen] = useState<typeof initState>(initState);
    const [openContent, setOpenContent] = useState<typeof initState>(initState);
    const [openCalendar, setOpenCalendar] = useState<typeof initState>(initState);

    const onModalCancel = (_e: React.MouseEvent) => {
        put(actions.setCFMain({isOpen: false, typeContent}))
    }

    function onCheckboxChange(e: CheckboxChangeEvent) {
        if (e.target.checked) {
            if (typeContent === 'projects') setOpen(ollItems)
            if (typeContent === 'content') setOpenContent(ollItems)
            if (typeContent === 'calendar') setOpenCalendar(ollItems)
        }
        else {
            if (typeContent === 'projects') setOpen([])
            if (typeContent === 'content') setOpenContent([])
            if (typeContent === 'calendar') setOpenCalendar([])
        }
        setChecked(e.target.checked)
        setCheckedContent(e.target.checked)
        setCheckedCalendar(e.target.checked)
    }

    function onCollapseChange(key: string | string[]) {
        if (typeof key === 'string') {
            if (typeContent === 'projects') setOpen([key as Items])
            if (typeContent === 'content') setOpenContent([key as Items])
            if (typeContent === 'calendar') setOpenCalendar([key as Items])
        }
        else {
            if (typeContent === 'projects') setOpen(key as Items[])
            if (typeContent === 'content') setOpenContent(key as Items[])
            if (typeContent === 'calendar') setOpenCalendar(key as Items[])
        }
    }

    const onClearClick = (key: keyof ExtendedSearchModel.TCFState) => (event: MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        ExtendedSearchModel.actions.ClearFilters(put, typeContent, key)
    }
    function onButtonClick(_event: MouseEvent<HTMLElement>) {
        put(actions.FetchCF(typeContent))
        put(actions.setCFMain({isOpen: false, typeContent}))
    }

    const getFilterType = (): UserFiltersTypes.Types['code'] => {
        switch (typeContent) {
            case 'calendar':
                return 'PROJECTS_CALENDAR'
            case 'content':
                return 'PROJECTS_CONTENT'
            default:
                return 'PROJECTS'
        }
    }
    const resetFilterHandler = UserFiltersLib.useResetFilter(getFilterType());
    function onClearFilterClick(_event: MouseEvent<HTMLDivElement>) {
        ExtendedSearchModel.actions.ClearAllFilters(put, typeContent)
        put(UserFiltersModel.actions.setUserFilter(getFilterType(), {activeFilterId: undefined, filterName: undefined}))
        resetFilterHandler();
    }

    const isChecked = () => {
        if (typeContent === 'projects') return checked
        if (typeContent === 'content') return checkedContent
        if (typeContent === 'calendar') return checkedCalendar
    }
    const isCheckedLabel = () => {
        if (typeContent === 'projects') return checked ? 'Свернуть' : 'Развернуть'
        if (typeContent === 'content') return checkedContent ? 'Свернуть' : 'Развернуть'
        if (typeContent === 'calendar') return checkedCalendar ? 'Свернуть' : 'Развернуть'
    }
    const getActiveKey = () => {
        if (typeContent === 'projects') return open
        if (typeContent === 'content') return openContent
        if (typeContent === 'calendar') return openCalendar
    }


    return (
        <Modal
            className='ModalExtendedSearch'
            visible={isOpen}
            footer={(
                <div className="ModalExtendedSearch__footer">
                    <SaveUserFilterButton filterType={getFilterType()} disabled={!isToggleFilter}/>
                    <div>
                        {open.length >= 5 && (
                            <div className="bottom">
                                <div className="checkbox">
                                    <Checkbox
                                        checked={isChecked()}
                                        onChange={onCheckboxChange}
                                    >
                                        {isCheckedLabel()} все разделы
                                    </Checkbox>
                                </div>
                            </div>
                        )}
                        <Button type={"primary"} onClick={onButtonClick}>Найти</Button>
                        <Button type={"default"} onClick={onModalCancel}>Закрыть</Button>
                    </div>
                </div>
            )}
            maskClosable={true}
            onCancel={onModalCancel}
        >
            <div className="content">
                <div className="top">
                    <div className="title">Поиск {type}</div>
                    <div className="checkbox">
                        <Checkbox checked={isChecked()} onChange={onCheckboxChange}>
                            {isCheckedLabel()} все разделы
                        </Checkbox>
                    </div>
                </div>

                <div className={"content__UserFilterName"}>
                    <UserFilters filterType={getFilterType()} needToMakeRequest={false}/>
                </div>

                {isToggleFilter && (
                    <div className="clear-filter" onClick={onClearFilterClick}>
                        <Button type={"default"}>Сбросить фильтр</Button>
                    </div>
                )}
                {isToggleFilter && <UserFilterName filterType={getFilterType()}/>}


                <div className="items">
                    <Collapse
                        onChange={onCollapseChange}
                        activeKey={getActiveKey()}
                        expandIcon={({isActive}) => {
                            return isActive ? <CaretDownOutlined /> : <CaretRightOutlined />
                        }}
                    >
                        <Panel
                            header="Идентификаторы проекта"
                            key="project"
                            extra={isToggle[typeContent].project && <DeleteOutlined onClick={onClearClick("project")}/>}
                        >
                            <ProjectItem/>
                        </Panel>
                        <Panel
                            header="Согласование записей задач проекта"
                            key="agreementOfProjectTaskRecordsItem"
                            extra={isToggle[typeContent].agreementOfProjectTaskRecords && <DeleteOutlined onClick={onClearClick("agreementOfProjectTaskRecords")}/>}
                        >
                            <AgreementOfProjectTaskRecordsItem/>
                        </Panel>
                        <Panel
                            header="Организация - подрядчик"
                            key="organization"
                            extra={isToggle[typeContent].organizations && <DeleteOutlined onClick={onClearClick('organizations')}/>}
                        >
                            <OrganizationItem/>
                        </Panel>
                        <Panel
                            header="Конкурсы / года / сессии"
                            key="contests"
                            extra={isToggle[typeContent].contests && <DeleteOutlined onClick={onClearClick('contests')}/>}
                        >
                            <ContestsItem/>
                        </Panel>
                        <Panel
                            header="Статусы проектов / этапов и направление проекта"
                            key="status"
                            extra={isToggle[typeContent].status && <DeleteOutlined onClick={onClearClick('status')}/>}
                        >
                            <StatusItem/>
                        </Panel>
                        <Panel
                            header="Индикаторы контента"
                            key="indicatorsContent"
                            extra={isToggle[typeContent].indicatorsContent && <DeleteOutlined onClick={onClearClick('indicatorsContent')}/>}
                        >
                            <IndicatorContentItem/>
                        </Panel>
                        <Panel
                            header="Индикаторы проекта"
                            key="indicators"
                            extra={isToggle[typeContent].indicators && <DeleteOutlined onClick={onClearClick('indicators')}/>}
                        >
                            <IndicatorItem/>
                        </Panel>
                        <Panel
                          header="Награды проекта"
                          key="awards"
                          extra={isToggle[typeContent].awards && <DeleteOutlined onClick={onClearClick('awards')}/>}
                        >
                            <AwardsItem/>
                        </Panel>
                        <Panel
                            header="Тематика проекта и контента"
                            key="thematics"
                            extra={isToggle[typeContent].thematics && <DeleteOutlined onClick={onClearClick('thematics')}/>}
                        >
                            <ThematicsItem/>
                        </Panel>
                        <Panel
                            header="Категория и жанр проекта"
                            key="categoriesGenres"
                            extra={isToggle[typeContent].categoriesGenres && <DeleteOutlined onClick={onClearClick('categoriesGenres')}/>}
                        >
                            <CategoriesGenresItem/>
                        </Panel>
                        <Panel
                            header="Региональные особенности проекта и контента"
                            key="regionals"
                            extra={isToggle[typeContent].regionals && <DeleteOutlined onClick={onClearClick('regionals')}/>}
                        >
                            <RegionalsItem/>
                        </Panel>
                        <Panel
                            header="Единицы контента и формы сдачи контента проекта"
                            key="content"
                            extra={isToggle[typeContent].content && <DeleteOutlined onClick={onClearClick('content')}/>}
                        >
                            <ContentProjectItem/>
                        </Panel>
                        <Panel
                            header="Интернет-ресурсы проекта и единиц контента"
                            key="channels"
                            extra={isToggle[typeContent].channels && <DeleteOutlined onClick={onClearClick('channels')}/>}
                        >
                            <ChannelsItem/>
                        </Panel>
                        <Panel
                            header="Участники проекта"
                            key="users"
                            extra={isToggle[typeContent].users && <DeleteOutlined onClick={onClearClick('users')}/>}
                        >
                            <UsersItem/>
                        </Panel>
                        <Panel
                            header="Известные люди проекта"
                            key="famousUsers"
                            extra={isToggle[typeContent].famousPeople && <DeleteOutlined onClick={onClearClick('famousPeople')}/>}
                        >
                            <FamousPeopleItem/>
                        </Panel>
                        <Panel
                            header="Даты реализации проекта"
                            key="dates"
                            extra={isToggle[typeContent].dates && <DeleteOutlined onClick={onClearClick('dates')}/>}
                        >
                            <DatesItem/>
                        </Panel>
                        <Panel
                            header="Количественные параметры проекта и единиц контента"
                            key="counts"
                            extra={isToggle[typeContent].counts && <DeleteOutlined onClick={onClearClick('counts')}/>}
                        >
                            <CountsItem/>
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </Modal>
    )
};

type Items = 'project' |
    'agreementOfProjectTaskRecordsItem' |
    'organization' |
    'contests' |
    'status' |
    'indicatorsContent' |
    'indicators' |
    'awards' |
    'thematics' |
    'regionals' |
    'content' |
    'channels' |
    'users' |
    'famousUsers' |
    'dates' |
    'counts'|
    'categoriesGenres'

export default ModalComponent;