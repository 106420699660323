export const AnalyticsActionTypes = {
	GET_ANALYTICS_PROJECT_START: '[ANALYTICS] GET_ANALYTICS_PROJECT_START',
	GET_ANALYTICS_PROJECT_SUCCESS: '[ANALYTICS] GET_ANALYTICS_PROJECT_SUCCESS',
	GET_ANALYTICS_PROJECT_ERROR: '[ANALYTICS] GET_ANALYTICS_PROJECT_ERROR',

	GET_ANALYTICS_SESSION_CONTENT_KPI_START: '[ANALYTICS] GET_ANALYTICS_SESSION_CONTENT_KPI_START',
	GET_ANALYTICS_SESSION_CONTENT_KPI_SUCCESS: '[ANALYTICS] GET_ANALYTICS_SESSION_CONTENT_KPI_SUCCESS',
	GET_ANALYTICS_SESSION_CONTENT_KPI_ERROR: '[ANALYTICS] GET_ANALYTICS_SESSION_CONTENT_KPI_ERROR',

	GET_ANALYTICS_POSTING_TOP_RESOURCES_START: '[ANALYTICS] GET_ANALYTICS_POSTING_TOP_RESOURCES_START',
	GET_ANALYTICS_POSTING_TOP_RESOURCES_SUCCESS: '[ANALYTICS] GET_ANALYTICS_POSTING_TOP_RESOURCES_SUCCESS',
	GET_ANALYTICS_POSTING_TOP_RESOURCES_ERROR: '[ANALYTICS] GET_ANALYTICS_POSTING_TOP_RESOURCES_ERROR',

	GET_ANALYTICS_KPI_TOP_RESOURCES_START: '[ANALYTICS] GET_ANALYTICS_KPI_TOP_RESOURCES_START',
	GET_ANALYTICS_KPI_TOP_RESOURCES_SUCCESS: '[ANALYTICS] GET_ANALYTICS_KPI_TOP_RESOURCES_SUCCESS',
	GET_ANALYTICS_KPI_TOP_RESOURCES_ERROR: '[ANALYTICS] GET_ANALYTICS_KPI_TOP_RESOURCES_ERROR',

	GET_ANALYTICS_KPI_TOP_PROJECT_START: '[ANALYTICS] GET_ANALYTICS_KPI_TOP_PROJECT_START',
	GET_ANALYTICS_KPI_TOP_PROJECT_SUCCESS: '[ANALYTICS] GET_ANALYTICS_KPI_TOP_PROJECT_SUCCESS',
	GET_ANALYTICS_KPI_TOP_PROJECT_ERROR: '[ANALYTICS] GET_ANALYTICS_KPI_TOP_PROJECT_ERROR',

	GET_ANALYTICS_SESSION_CONTENT_POSTING_START: '[ANALYTICS] GET_ANALYTICS_SESSION_CONTENT_POSTING_START',
	GET_ANALYTICS_SESSION_CONTENT_POSTING_SUCCESS: '[ANALYTICS] GET_ANALYTICS_SESSION_CONTENT_POSTING_SUCCESS',
	GET_ANALYTICS_SESSION_CONTENT_POSTING_ERROR: '[ANALYTICS] GET_ANALYTICS_SESSION_CONTENT_POSTING_ERROR',

	GET_ANALYTICS_REACH_DATA_START: '[ANALYTICS] GET_ANALYTICS_REACH_DATA_START',
	GET_ANALYTICS_REACH_DATA_SUCCESS: '[ANALYTICS] GET_ANALYTICS_REACH_DATA_SUCCESS',
	GET_ANALYTICS_REACH_DATA_ERROR: '[ANALYTICS] GET_ANALYTICS_REACH_DATA_ERROR',

	GET_ANALYTICS_SUMMARY_DATA_START: '[ANALYTICS] GET_ANALYTICS_SUMMARY_DATA_START',
	GET_ANALYTICS_SUMMARY_DATA_SUCCESS: '[ANALYTICS] GET_ANALYTICS_SUMMARY_DATA_SUCCESS',
	GET_ANALYTICS_SUMMARY_DATA_ERROR: '[ANALYTICS] GET_ANALYTICS_SUMMARY_DATA_ERROR',

	SET_ANALYTICS_FILTER: '[ANALYTICS] SET_ANALYTICS_FILTER',
	SET_ANALYTICS_FILTER_REACH: '[ANALYTICS] SET_ANALYTICS_FILTER_REACH',
	CLEAR_ANALYTICS_FILTER_REACH: '[ANALYTICS] CLEAR_ANALYTICS_FILTER_REACH',

	CLEAR_ANALYTICS_DATA: '[ANALYTICS] CLEAR_ANALYTICS_DATA',
} as const
