import {ErrorActionState, StartActionState, SuccessActionState} from '../../utils/reducerHalper'
import {ArchiveActionTypes} from './action-types'
import {InferValueTypes} from '../../models/common'
import * as actions from './actions'
import {AuthActionTypes} from "../auth/action-types";
import {Logout} from "../auth/reducer";
import {TArchiveFilter, TArchiveResponse} from "../../models/archive";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TArchiveState = {
    archiveList: TArchiveResponse | null
    filter: TArchiveFilter
    isLoading: boolean
    error: any
}
const initialState: TArchiveState = {
    filter: {
        page: 1,
        pageSize: 10,
        sort: {field: "createDateTime", direction: "DESC"}
    },
    archiveList: null,
    isLoading: false,
    error: null,
}
export default function reducer(state: TArchiveState = initialState, action: ActionTypes | Logout): TArchiveState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState}
        case ArchiveActionTypes.SET_ARCHIVE_FILTER:
            return { ...state, filter: action.filter}

        /** START ACTIONS  */
        case ArchiveActionTypes.DELETE_ARCHIVE_RECORDS_START:
            return StartActionState(state)
        case ArchiveActionTypes.DELETE_ARCHIVE_RECORD_START:
            return StartActionState(state)
        case ArchiveActionTypes.GET_ARCHIVE_LIST_START:
            return StartActionState(state)

        /** SUCCESS  */
        case ArchiveActionTypes.DELETE_ARCHIVE_RECORDS_SUCCESS:
            return SuccessActionState(state)
        case ArchiveActionTypes.DELETE_ARCHIVE_RECORD_SUCCESS:
            return SuccessActionState(state)
        case ArchiveActionTypes.GET_ARCHIVE_LIST_SUCCESS:
            return {...SuccessActionState(state), archiveList: action.data}

        /** ERROR */
        case ArchiveActionTypes.DELETE_ARCHIVE_RECORDS_ERROR:
            return ErrorActionState(state, action)
        case ArchiveActionTypes.DELETE_ARCHIVE_RECORD_ERROR:
            return ErrorActionState(state, action)
        case ArchiveActionTypes.GET_ARCHIVE_LIST_ERROR:
            return ErrorActionState(state, action)


        default:
            return state
    }
}
