import React from 'react'
import styles from '../styles.module.less'
import { Field, useFormikContext } from 'formik'
import TextArea from 'antd/es/input/TextArea'
import { FieldAttributes } from 'formik/dist/Field'
import { TInitValues } from '../modal-content-records'

const Description = () => {
	const { touched, errors, values } = useFormikContext<TInitValues>()
	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Описание единицы контента
			</span>
			<div className={styles.field}>
				<Field name={'description'}>
					{(el: FieldAttributes<any>) => {
						return (
							<TextArea
								{...el.field}
								rows={8}
								disabled={values.disabled}
								allowClear={true}
								name="description"
								placeholder={'Описание контента'}
								onBlur={(_event) => {
									// eslint-disable-next-line
									el.form.setTouched({ ...el.form.touched, ['description']: true })
								}}
							/>
						)
					}}
				</Field>
			</div>
			{/*// @ts-ignore*/}
			{errors?.description && touched['description'] && <div className="invalid-feel">{errors?.description}</div>}
		</div>
	)
}

export default Description
