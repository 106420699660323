import React from 'react';
import {Button} from "antd";
import {CaretLeftOutlined, CaretRightOutlined, CloseCircleOutlined} from "@ant-design/icons/lib";

const Close = (props: any) => {
    return (
        <Button
            className='close__popover'
            type={"link"}
            onClick={props.onClick}
        >
            <CloseCircleOutlined />
        </Button>
    )
}
const Arrow = (props: any) => {
    if (!props.inverted) return <CaretLeftOutlined />
    else return <CaretRightOutlined />
}
export {Close, Arrow};