import React, {FC} from 'react';
import styles from './styles.module.less';
import {DeleteOutlined} from "@ant-design/icons";
import {UserFiltersModel, UserFiltersTypes} from 'features/user-filters';
import {useDispatch, useSelector} from "react-redux";
import {Popconfirm, Spin, Tooltip} from "antd";
import classNames from "classnames";
import {useMutation, useQueryClient} from "react-query";
import Api from "../../api";
import { useErrorNotification } from 'shared/lib/error-notification';

type Props = {
    filter: UserFiltersTypes.Filter,
    filterType: UserFiltersTypes.Types['code']
    needToMakeRequest: boolean
}
const Item: FC<Props> = ({filter, filterType, needToMakeRequest}) => {
    const queryClient = useQueryClient();
    const errorNotification = useErrorNotification();
    const {isLoading, mutate} = useMutation((id: string) => Api.delete(id), {
        onSuccess: () => {
            queryClient.invalidateQueries(['/filters/types/filterTypeCode', filterType])
        },
        onError: errorNotification
    })
    const put = useDispatch();
    const activeFilterId = useSelector(UserFiltersModel.select.activeFilterId(filterType));


    const onDelete = (event: any) => {
        event.stopPropagation();
        put(UserFiltersModel.actions.setUserFilter(filterType, {
            filterName: undefined,
            activeFilterId: undefined,
        }))
        put(UserFiltersModel.actions.activateFilter(filterType, '', needToMakeRequest))
        mutate(filter.id)
    }

    const onClickItem = () => {
        put(UserFiltersModel.actions.setUserFilter(filterType, {
            filterName: activeFilterId === filter.id ? undefined : filter.name,
            activeFilterId:  activeFilterId === filter.id ? undefined : filter.id,
        }))
        put(UserFiltersModel.actions.activateFilter(filterType, filter.filterData, needToMakeRequest))
    }

    return (
        <Spin spinning={isLoading}>
            <div
                className={classNames(styles.item, {active: activeFilterId === filter.id})}
                onClick={onClickItem}
            >
                <Tooltip title={filter.name}><span>{filter.name}</span></Tooltip>
                <Popconfirm
                    title={'Удалить фильтр'}
                    onConfirm={onDelete}
                    onCancel={(event) => event?.stopPropagation()}
                >
                    <DeleteOutlined className={styles.icon} onClick={(event) => {
                        event.stopPropagation();
                    }}/>
                </Popconfirm>
            </div>
        </Spin>

    );
};

export default Item;