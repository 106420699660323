import { TApplicationState } from 'store/aplication-state'
import { createSelector } from 'reselect'

const getReportSchedulersState = (state: TApplicationState) => state.feature.reportSchedulers
export const filter = createSelector(getReportSchedulersState, (state) => ({
	page: state.page,
	sort: state.sort,
	pageSize: state.pageSize,
	totalCount: state.totalCount,
	filterData: state.filterData,
}))
