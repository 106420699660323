import * as Yup from 'yup'

export const validation = Yup.object({
	reports: Yup.mixed().test('reports', 'Поле «Выберите отчет» обязательно для заполнения', function (value) {
		return value?.length > 0
	}),
	users: Yup.mixed().test('users', 'Поле «Получатели выгрузки отчета» обязательно для заполнения', function (value) {
		return value?.length > 0
	}),
	firstFormationDate: Yup.mixed().test(
		'firstFormationDate',
		'Поле «Первая дата формирования отчета» обязательно для заполнения',
		function (value) {
			return !!value
		}
	),
	lastFormationDate: Yup.mixed().test(
		'lastFormationDate',
		'Поле «Последняя дата формирования отчета» обязательно для заполнения',
		function (value) {
			return !!value
		}
	),
	time: Yup.mixed().test(
		'lastFormationDate',
		'Поле «Время формирования отчета» обязательно для заполнения',
		function (value) {
			return !!value
		}
	),
	format: Yup.mixed().test('format', 'Поле «Формат отчета» обязательно для заполнения', function (value) {
		return !!value
	}),
})
