import {useState} from "react";

type useHandlerStatusProps = {
    init: number
    limit: number
}
const useHandlerStatus = ({init, limit}: useHandlerStatusProps) => {
    const [state, setState] = useState(init);

    const handlerStatus = (status?: number | string) => () => {
        if (typeof status === "number") {
            setState(status)
        } else if (typeof status === "string" && status === 'inc') {
            const count = state === limit ? limit : state + 1;
            setState(count)
        } else {
            const count = state === init ? limit : state - 1;
            setState(count)
        }
    }
    return {statusCount: state, handlerStatus}
}
export default useHandlerStatus;
