import React from 'react';
import {useSelector} from "react-redux";
import {selectModal} from "store/modals/selectors";
import ModalTeams from "./modal-teams";
import ModalDeleteProject from "./modal-delete-project";
import ModalVote from "./modal-vote";
import ModalVoteUser from "./modal-vote-user";
import ModalLogout from "./modal-logout";
import ModalDelete from "./modal-delete";
import ModalDuplicate from "./modal-duplicate";
import ModalReporting from "./modal-reporting";
import ModalComment from "./modal-comment";
import ModalUpload from "./modal-upload";
import ModalContent from "./modal-content";
import ModalDeadlines from "./modal-deadlines";
import ModalContentFormat from "./modal-content-format";
import ModalError from "./modal-error";
import ModalErrorResetStatus from "./modal-error-resetStatus";
import ModalPostingContent from "./modal-posting-content";
import ModalContentRecords from "./modal-content-records";
import ModalFormNoSave from "./modal-form-no-save";
import ModalTimeCodeContent from "./modal-timeCode-content";
import ModalReachStatistic from "./modal-reach-statistic";
import ModalFamousPeople from "./modal-famousPeople";
import ModalProjectInfo from "./modal-projectInfo";
import ModalChannels from "./modal-channels";
import ModalProjectUsers from "./modal-projectUsers";
import ModalArchive from "./modal-archive/modal-archive";
import ModalSHD from "./modal-SHD/modal-SHD";
import {ModalUser} from "./modal-user";
import {ModalUserProfile} from "./modal-user-profile";
import {ModalUserContactDetails} from "./modal-user-contact-details";
import ModalConfirmDelete from "./modal-confirm-delete";
import ModalTask from "./modal-task";
import ModalSupport from "./modal-support";

const Modals = () => {
    const {type, isOpen, data} = useSelector(selectModal);
    if (!isOpen) return null;

    switch (type) {
        case "team":
            return <ModalTeams status={isOpen} data={data}/>
        case "delete-project":
            return <ModalDeleteProject status={isOpen} data={data}/>
        case "vote":
            return <ModalVote status={isOpen} data={data}/>
        case "vote-user":
            return <ModalVoteUser status={isOpen} data={data}/>
        case "delete-user":
        case "delete-users":
        case "delete-organization":
        case "delete-organizations":
        case "delete-contest":
        case "delete-session":
        case "delete-content-category":
        case "delete-format-type":
        case "delete-erv-range":
        case "delete-famous-people":
        case "delete-tag":
        case "delete-film-cluster":
            return <ModalDelete status={isOpen} data={data} type={type}/>
        case "logout":
            return <ModalLogout status={isOpen}/>
        case "duplicate":
            return <ModalDuplicate status={isOpen} data={data}/>
        case "reporting":
            return <ModalReporting status={isOpen}  data={data}/>
        case "comment":
            return <ModalComment status={isOpen} data={data}/>
        case "upload":
            return <ModalUpload status={isOpen}/>
        case "content-task":
            return <ModalContent status={isOpen} data={data}/>
        case "deadlines":
            return <ModalDeadlines status={isOpen} data={data}/>
        case "content-format":
            return <ModalContentFormat status={isOpen} projectId={data}/>
        case "modal-error":
            return <ModalError status={isOpen} data={data}/>
        case "reset-status-task-error":
            return <ModalErrorResetStatus status={isOpen} data={data}/>
        case "posting-content":
            return <ModalPostingContent status={isOpen} data={data}/>
        case "content-records":
            return <ModalContentRecords status={isOpen} data={data}/>
        case "form-no-save":
            return <ModalFormNoSave status={isOpen} data={data}/>
        case "reach-statistic":
            return <ModalReachStatistic status={isOpen}/>
        case "time-code-content":
            return <ModalTimeCodeContent status={isOpen} data={data} />
        case "famousPeople":
            return <ModalFamousPeople status={isOpen} data={data}/>
        case "projectInfo":
            return <ModalProjectInfo status={isOpen} data={data}/>
        case "channels":
            return <ModalChannels status={isOpen} data={data}/>
        case "projectUsers":
            return <ModalProjectUsers status={isOpen} data={data}/>
        case "archive":
            return <ModalArchive status={isOpen}/>
        case "SHD":
            return <ModalSHD status={isOpen}/>
        case "modal-user":
            return <ModalUser status={isOpen} data={data}/>
        case "modal-user-profile":
            return <ModalUserProfile status={isOpen}/>
        case 'user-contact-details':
            return <ModalUserContactDetails status={isOpen} data={data}/>
        case 'confirm-delete':
            return <ModalConfirmDelete status={isOpen} data={data}/>
        case "task":
            return <ModalTask status={isOpen} taskId={data}/>
        case "support":
            return <ModalSupport status={isOpen}/>
        default:
            return null
    }
};
export default Modals;
