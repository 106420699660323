import React from 'react';
import styles from './calendar.module.less'
import Filter from "./filter";
import GanttComponent from "../gantt-component";

const Calendar = () => {
    return (
        <div className={styles.root}>
            <Filter/>
            <GanttComponent/>
        </div>
    )
};
export default Calendar;