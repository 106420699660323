import React, {FC} from 'react';
import {Tooltip} from "antd";
import {EyeFilled} from "@ant-design/icons";
import {DownloadFileArchiveStartAction} from "store/project-reporting/actions";
import {useDispatch} from "react-redux";
import {ModalCloseAction, ModalOpenAction} from "store/modals/actions";
import {GetArchiveListStartAction} from "store/archive/actions";

type FileTaskProps = {
    projectId: string
}

const FileTask: FC<FileTaskProps> = ({projectId}) => {
    const put = useDispatch()

    const handleClickCreate = () => {
        put(DownloadFileArchiveStartAction(projectId))
    }

    function handlerClickGoToDownload () {
        put(GetArchiveListStartAction())//Получение списка запросов на формирование архивов текущего пользователя
        put(ModalCloseAction())
         put(ModalOpenAction('archive'))
    }

    return (
        <div className="modal__file">
            <h2 className="modal__file--text">Файл задач</h2>
            <p onClick={handleClickCreate} className="modal__file--create">Сформировать архив с файлами согласованных записей задач на текущий день</p>
            <p>
                <Tooltip placement="top" title="Перейти к скачиванию архива">
                    <EyeFilled onClick={handlerClickGoToDownload}/>
                </Tooltip>
            </p>
        </div>
    );
};

export default FileTask;