import { SearchContentProjectTypes } from "features/search-project-content";
import {ActionTypes} from "./types";


export const setDates = <T extends Partial<SearchContentProjectTypes.Stores.Dates["fields"]>>(
    typeContent: SearchContentProjectTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUE, typeContent, value, isToggled
})
export const clearDates = (typeContent: SearchContentProjectTypes.Stores.TTypeStore) => ({
    type: ActionTypes.CLEAR_VALUE, typeContent
})