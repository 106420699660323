export const TasksRecordsActionTypes = {
    GET_TASKS_STAT: '[TASKS_RECORDS] GET_TASKS_STAT',
    GET_TASKS_SUCCESS: '[TASKS_RECORDS] GET_TASKS_SUCCESS',
    GET_TASKS_ERROR: '[TASKS_RECORDS] GET_TASKS_ERROR',

    SET_TASK_FILTER: '[TASKS_RECORDS] SET_TASK_FILTER',
    CLEAR_TASK_FILTER: '[TASKS_RECORDS] CLEAR_TASK_FILTER',
    RESET_TASK_FILTER: '[TASKS_RECORDS] RESET_TASK_FILTER',

    SET_TASK_SEARCH_FILTER: '[TASKS_RECORDS] SET_TASK_SEARCH_FILTER',
    SET_BULK_ACTION: '[TASKS_RECORDS] SET_BULK_ACTION',
    SET_ROWS_IDS: '[TASKS_RECORDS] SET_ROWS_IDS',



    CLEAR_ALL_TASKS: '[TASKS_RECORDS] CLEAR_ALL_TASKS',

} as const;