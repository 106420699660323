import { createSelector } from 'reselect';
import {TApplicationState} from '../aplication-state';

const getModalsState = (state: TApplicationState) => state.modals;

export const selectModal = createSelector(
    getModalsState,
    (state) => {
        return {
            isOpen: state.isOpen,
            type: state.type,
            data: state.data
        }
    }
)
