import {InferValueTypes} from "../../models/common";
import * as actions from './actions';
import {NotificationActionTypes} from "./action-types";
import {AuthActionTypes} from "../auth/action-types";
import {Login, Logout} from "../auth/reducer";
type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TNotification = 'success' | 'info' | 'warning' | 'error' | null

export type TNotificationState = {
    title: string | null
    description: string | null
    type: TNotification
    checkAuth: boolean
    isLogin: boolean
}

export const initialState: TNotificationState = {
    title: null,
    description: null,
    type: null,
    checkAuth: true,
    isLogin: true
}

export default function reducer(state: TNotificationState = initialState, action: ActionTypes | Logout | Login): TNotificationState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState, isLogin: false}
        case AuthActionTypes.LOGIN_SUCCESS:
            return {...state, isLogin: true}
        case NotificationActionTypes.NOTIFICATION_OPEN:
            return {
                ...state,
                title: action.title,
                description: action.description,
                checkAuth: action.checkAuth,
                type: action.types
            }
        case NotificationActionTypes.NOTIFICATION_CLOSE:
            return {
                ...initialState
            }
        default:
            return state
    }
}