import React, {FC, useEffect, useState} from 'react';
import './index.less'
import {FileType} from "models/common";
import {FileType as FileTypeNew} from "shared/types/file";
import {Empty, Spin} from "antd";
import env from "shared/config/env";

const pathImage = `${env.API_ENDPOINT}/files/`
//todo fix FileTypeNew
const ImageComponent: FC<{ file?: FileType | FileTypeNew |null, altImg?: string, alt?: string }> = ({
   file, altImg, alt = '',
}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const imageId = file?.compressedId ? file.compressedId : file?.id;

    useEffect(() => {
        if (!imageId) setLoading(false)
    }, [imageId]);

    return (
        <Spin spinning={loading} className="ImageComponent">
            {imageId && !error ? (
                <img src={pathImage + imageId} alt={alt ? alt : file?.name} onLoad={_event => {
                    setLoading(false)
                }} onError={() => setError(true)}/>
            ) : altImg ? (
                <img src={altImg} alt={alt} onLoad={_event => {
                    setLoading(false)
                }} onError={() => setError(true)}/>
            ) : <Empty/>}
        </Spin>
    )
};
export default ImageComponent;