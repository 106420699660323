import React from 'react';
import './index.less';

const LinkCell = (link: string) => {
    const linkRE = /^(http|https):\/\/[^ "]+$/;
    const url = linkRE.test(link) ? link : `https://${link}`
    return (
        <a href={url} rel="noreferrer"  target='_blank' className='link-cell'>{link}</a>
    )
};
export default LinkCell;
