import React, {FC} from 'react';
import './indesx.less'
import {Button, Modal} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectUploaderData, selectUploaderStatus} from "store/uploader/selectors";
import {CloseModalUploadAction} from "store/uploader/actions";
import UploaderModalItem from "./uploader-modal-item";
import PathConverter from "utils/path-converter";
import {TASKS} from "routing/names";
import { Link } from 'react-router-dom';

type TUploaderModal = {
    handleSetUpload: any
    handleStart: any
    handleAbort: any
    handleRemove: any
}
const UploaderModal: FC<TUploaderModal> = ({
    handleSetUpload,
    handleStart,
    handleAbort,
    handleRemove,
}) => {
    const put = useDispatch();
    const {data} = useSelector(selectUploaderData);
    const isOpen = useSelector(selectUploaderStatus);
    const closeModal = () => {
        put(CloseModalUploadAction())
    }
    return (
        <Modal
            className='modal-upload'
            title={
                <Link to={PathConverter(TASKS.TASK, data?.taskId)}>Загрузка контента {data?.taskName}</Link>
            }
            visible={isOpen}
            footer={(
                <Button type={"primary"} onClick={closeModal}>Закрыть</Button>
            )}
            maskClosable={true}
            onCancel={closeModal}
        >
            <div className='modal-upload__head'>
                <div className='modal-upload__head-item status'>Статус файла</div>
            </div>
            <hr/>
            <UploaderModalItem
                handleStart={handleStart}
                handleAbort={handleAbort}
                handleRemove={handleRemove}
                handleSetUpload={handleSetUpload}
            />
        </Modal>
    )
};
export default UploaderModal;