import {put} from 'redux-saga/effects'
import {
    ChangePasswordErrorAction,
    ChangePasswordStartAction,
    ChangePasswordSuccessAction,
} from 'store/options/actions/actions-users'
import UsersService from 'services/usersService'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from "utils/validation/createErrorMessage";

export function* changePassword({ userId, password }: ReturnType<typeof ChangePasswordStartAction>) {
	try {
		const formData = new FormData()
		formData.append('password', password)
		yield UsersService.changePassword(userId, formData)
		yield put(ChangePasswordSuccessAction())
		yield put(NotificationOpen('success', 'Пароль успешно обновлён'))
	} catch (error: any) {
		yield put(ChangePasswordErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить пароль'))

	}
}
