import { FunctionalityInfoType } from '../types'

export const analyticsSummaryData: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр сводных данных',
		LevelGroupData: [
			{
				name: 'Раскрытие всех сводных данных по конкурсам',
				text: 'Нажмите для раскрытия всех данных',
				screenshots: ['/images/screenshots/analytics-summary-data/view-data.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Раскрытие сводных данных по конкурсам',
				text: 'Нажмите для раскрытия всех данных',
				screenshots: ['/images/screenshots/analytics-summary-data/open-data.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
