import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetHandbookStartAction,
    UpdateKPEErrorAction,
    UpdateKPEStartAction,
    UpdateKPESuccessAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import HandbookService from "services/handbookService";

export function* updateKPE({id, data,}: ReturnType<typeof UpdateKPEStartAction>) {
    try {
        yield HandbookService.updateKPERecord(data,id)
        yield put(UpdateKPESuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.KPE))

        yield put(NotificationOpen('success', 'Тип КПЭ обновлен'))

    } catch (error: any) {
        yield put(UpdateKPEErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить тип КПЭ'))
    }
}
