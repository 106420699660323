import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Query } from '..';

const QueryComponent = () => {
	const queryClient = useQueryClient();

	const invalidate = (data: string[]) => {
		(async () => {
			await queryClient.invalidateQueries(data);
		})();
	};
	useEffect(() => {
		const unsubscribe = Query.subscribe(invalidate);
		return () => unsubscribe();
	}, []);//eslint-disable-line

	return null;
};
export default QueryComponent;
