import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom'
import {useSelector} from "react-redux";
import {selectIsLogin, selectUserInfo} from "store/auth/selectors";

import PrivateModule from "./private";
import PublicModule from "./public";
import KeycloakChecker from "../processes/keycloak-checker";


const RootRoutes = () => {
    const isLogin = useSelector(selectIsLogin);
    const {user, confirmAgreement, confirmReceiving} = useSelector(selectUserInfo)

    const {pathname} = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (isLogin) {
            if (pathname === '/' || pathname === '/error') {
                const savePath = localStorage.getItem('path');
                if (confirmAgreement && confirmReceiving) {
                    const clientOrAgent = user?.role === 'ROLE_CLIENT' || user?.role === 'ROLE_AGENT'
                    if (clientOrAgent && savePath !== '/projects') {
                        history.push(savePath ? savePath : '/content');
                    } else {
                       if (savePath === '/confirm') history.push('/projects') // после подтверждения почты остается в savePath '/confirm' и не редиректит на '/content'
                        else history.push(savePath && savePath !== '/error' ? savePath : '/projects')
                    }
                    localStorage.removeItem('path');
                } else if (
                    (!confirmAgreement && typeof confirmAgreement === 'boolean') ||
                    (!confirmReceiving && typeof confirmReceiving === 'boolean')
                ) {
                    history.push('/confirm');
                }
            }
        }
    }, [pathname, isLogin, history, user])// eslint-disable-line

    let Component = isLogin ? <PrivateModule/> : <PublicModule/>


    if( typeof confirmAgreement === 'boolean' && typeof confirmReceiving === 'boolean') {
        Component = isLogin && confirmAgreement && confirmReceiving ? <PrivateModule/> : <PublicModule/>
    }
    return (
        <KeycloakChecker>
            {Component}
        </KeycloakChecker>
    )
};
export default RootRoutes;
