import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetActualOrArchivedProjectsErrorAction,
    GetActualOrArchivedProjectsStartAction,
    GetActualOrArchivedProjectsSuccessAction,
} from "store/projects/actions";

import Api from "entities/project/api/project";
import {TActualOrArchivedProjects} from "models/projects/actual-projects";

export function* getActualOrArchivedProjects({sprintExist}: ReturnType<typeof GetActualOrArchivedProjectsStartAction>) {
    try {
        const { data: projects }: AxiosResponse<TActualOrArchivedProjects[]> = yield Api.getProjectsAll({
            statusCodes: ['ACTUAL', 'ARCHIVED'],
            sprintExist
        });

        yield put(GetActualOrArchivedProjectsSuccessAction(projects))
    } catch (error: any) {
        yield put(GetActualOrArchivedProjectsErrorAction(error))
    }
}