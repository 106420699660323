import {put, select} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	GetAllReportingErrorAction,
	GetAllReportingStartAction,
	GetAllReportingSuccessAction,
} from 'store/project-reporting/actions'
import {selectReportingFilter} from "store/project-reporting/selectors";
import {ReportApi, ReportTypes} from 'entities/reports'

export function* getReporting(_ : ReturnType<typeof GetAllReportingStartAction>) {
	try {
		const filter: ReportTypes.Filter = yield select(selectReportingFilter)
		const { data } = yield ReportApi.ReportsController.fetch(filter)

		yield put(GetAllReportingSuccessAction(data))
		yield put(NotificationOpen('success', 'Отчеты получены'))
	} catch (error: any) {
		yield put(GetAllReportingErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить отчеты'))
	}
}
