import React, { ChangeEvent } from 'react'
import styles from '../styles.module.less'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import { TInitValues } from '../modal-content-records'
import { formatTime } from 'shared/lib/format-time'
import { FieldAttributes } from 'formik/dist/Field'
import { Input } from 'antd'
import TextError from 'components/formic-control/text-error'

const ContentTiming = () => {
	const { setFieldValue, setTouched, touched, values } = useFormikContext<TInitValues>()
	const name = 'contentTiming'
	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = formatTime(e.target.value)
		setFieldValue('contentTiming', value)
	}
	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Хронометраж
			</span>
			<div className={styles.field}>
				<Field name={name}>
					{(el: FieldAttributes<any>) => {
						const isError = el.meta.error
						const isTouched = el.form.touched[name] ? el.form.touched[name] : el.meta.touched
						let classError = ''
						if (typeof el.meta.error !== 'function' && isTouched && isError) classError = 'error'
						return (
							<>
								<Input
									{...el.field}
									disabled={values.disabled}
									onChange={onChange}
									className={classError}
									placeholder="00:00:00"
									autoComplete="off"
									id={name}
									allowClear={true}
									type={'text'}
									onBlur={(_e: any) => {
										setTouched({ ...touched, [name]: true })
									}}
								/>
								{isTouched && isError && <ErrorMessage name={name} component={TextError} />}
							</>
						)
					}}
				</Field>
			</div>
		</div>
	)
}

export default ContentTiming
