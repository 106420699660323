import React, {FC} from 'react';
import {Button} from "antd";
import styles from './style.module.less';
import {useDispatch, useSelector} from "react-redux";
import {UserFiltersModel, UserFiltersTypes} from 'features/user-filters';
import {useQueryClient} from "react-query";
import {ApiKeys} from "../../api";

type Props = {
    filterType: UserFiltersTypes.Types['code']
    disabled: boolean
}
const SaveFilterButton: FC<Props> = ({filterType, disabled}) => {
    const put = useDispatch();
    const queryClient = useQueryClient();
    const activeFilterId = useSelector(UserFiltersModel.select.activeFilterId(filterType))

    const onSaveFilter = () => {
        put(UserFiltersModel.actions.createFilter(filterType, async () => {
            await queryClient.invalidateQueries([ApiKeys.filtersTypeCode, filterType])
        }))
    }
    return (
        <Button
            className={styles.btn}
            disabled={disabled}
            type={'primary'}
            onClick={onSaveFilter}
        >
            {activeFilterId ? 'Обновить фильтр' : 'Сохранить фильтр'}
        </Button>
    )
};

export default SaveFilterButton;