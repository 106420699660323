const coverageData = [
	{
		id: '90',
		selector: '.analytics-actions--sections-one',
		content: 'Для построения графика выберите проект согласно условиям фильтра и нажмите “Построить график”',
		role: [],
	},
	{
		id: '91',
		selector: '.line-chart',
		content:
			'График охватов по выбранному вами проекту отражает только проверенные данные по задачам на фиксацию охвата',
		role: [],
	},
	{
		id: '92',
		selector: '.analytics-actions--sections-two',
		content: 'Фильтр позволяет вывести на графике данные за определенный, указанный вами период времени',
		role: [],
	},
]

export default coverageData
