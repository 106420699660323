import {put} from 'redux-saga/effects'
import {
    BulkOrganizationActionsErrorAction,
    BulkOrganizationActionsStartAction,
    BulkOrganizationActionsSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import HandbookService from "services/handbookService";

export function* bulkOrganizationsActions({ userArr, action }: ReturnType<typeof BulkOrganizationActionsStartAction>) {
	try {
		yield HandbookService.bulkOrganizationActions(userArr, action)
		yield put(BulkOrganizationActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.organization))
		yield put(NotificationOpen('success', 'Обновлено успешно'))
	} catch (error: any) {
		yield put(BulkOrganizationActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}
}
