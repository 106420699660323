import {z} from "zod";
import {Id_Name_Code_Schema} from "shared/types/common";

export const ContestSchema = Id_Name_Code_Schema.extend({
    id: z.string(),
    name: z.string().optional(),
    code: z.string(),
    shortName: z.string().optional(),
    startDate: z.string().optional(),
    endDate: z.string().nullable().optional()
})
export type Contest = z.infer<typeof ContestSchema>;