import {ContextActions, ContextState} from "./uploaderContext";

export default function UploaderReducer(state: ContextState, action: ContextActions) {
    switch (action.type) {
        case "SET_PROGRESS":
            return {...state, progress: action.payload}
        case "RESET_PROGRESS":
            return {...state, progress: 0, status: null}
        case "SET_STATUS":
            return {...state, status: action.payload}
        default:
            return state
    }
}