import React from 'react'
import styles from '../styles.module.less'
import { InputComponent } from 'components/formic-control'
import {useFormikContext} from "formik";
import {TInitValues} from "../modal-content-records";

const NameContent = () => {
	const {values} = useFormikContext<TInitValues>()

	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Название единицы контента <sup>*</sup>
			</span>
			<div className={styles.field}>
				<InputComponent type="text" name={'name'} placeholder="Введите название" disabled={values.disabled}/>
			</div>
		</div>
	)
}

export default NameContent
