import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteKPEErrorAction,
    DeleteKPEStartAction,
    DeleteKPESuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import HandbookService from "services/handbookService";


export function* deleteKPE({id}: ReturnType<typeof DeleteKPEStartAction>) {
    try {
        yield HandbookService.deleteKPERecord(id)
        yield put(DeleteKPESuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.KPE ))
        yield put(NotificationOpen('success', 'Тип KПЭ удален'))

    } catch (error: any) {
        yield put(DeleteKPEErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить тип KПЭ'))
    }
}
