import React, {FC, Key} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styles from './styles.module.less'
//antd
import {Table, TablePaginationConfig, Tooltip} from 'antd'
import {ColumnsType} from 'antd/es/table'
//store
import {selectReporting, selectReportingFilter} from 'store/project-reporting/selectors'
import {SetReportingFilter} from 'store/project-reporting/actions'
//utils
import {dateParseFormat} from 'utils/dateParse'
import handlerTable from 'utils/handleTable'
import formatSize from 'utils/formatSize'
//others
import NameCenterCell from 'components/table-cells/name-center-cell'
import {ReportTypes} from 'entities/reports'
import {DownloadOutlined} from '@ant-design/icons'
import {NotificationOpen} from 'store/notification/actions'
import classNames from 'classnames'
import {useDownload} from "hooks";


type Props = {
	type: ReportTypes.ReportType
}
const TableReporting: FC<Props> = ({ type }) => {
	const put = useDispatch()
	const reporting = useSelector(selectReporting)
	const filter = useSelector(selectReportingFilter)
	const {download} = useDownload()
	const handleClickDownload = (status: string, id: string, name: string) => () => { // для скачивание отчета если есть record.archiveData.file.id то используем GET /files/{archiveData.file.id}
		status === 'CREATED'
			? download(id, name)
			: put(NotificationOpen('error', 'Ошибка', 'Дождитесь статуса CREATED'))

	}

	const columns: ColumnsType<ReportTypes.ReportsContent> = [
		{
			title: 'Дата формирования',
			dataIndex: 'createDate',
			key: 'createDate',
			width: 100,
			render: (_value, record) => {
				const _date = record.archiveData ? record.archiveData?.createDateTime : record?.createDate
				const date = `${dateParseFormat('d:m:y', _date)} ${dateParseFormat('h:m:s', _date)}`
				return NameCenterCell(date)
			},
		},
		{
			title: 'Название файла',
			dataIndex: 'filename',
			key: 'filename',
			width: 100,
			render: (_value, record) => {
				const filename = record.archiveData ? record.archiveData?.file?.name : record?.file?.name
				return NameCenterCell(filename || '-')
			},
		},
		{
			title: 'Пользователь',
			dataIndex: ['user', 'username'],
			key: 'id',
			width: 100,
			render: (_value, record) => {
				const username = record?.user?.username
				return NameCenterCell(username || '-')
			},
		},
		{
			title: 'Размер файла',
			dataIndex: ['file', 'size'],
			key: 'id',
			width: 60,
			render: (_value, record) => {
				const text = record.archiveData ? formatSize(record?.archiveData?.file?.size) : formatSize(record?.file?.size)
				return NameCenterCell(text || '-')
			},
		},
		{
			title: 'Статус формирования',
			dataIndex: 'status',
			key: 'status',
			width: 100,
			render: (_status, record) => {
				const status = record.archiveData ? record.archiveData?.status : record.status
				const id = record.archiveData ? record.archiveData?.file?.id : record?.file?.id
				const filename = record?.filename
				return (
					<div className={styles.download}>
						<span>{status}</span>
						<Tooltip placement="top" title={!id ? 'Отсутствует файл для загрузки' : "Скачать"}>
							<DownloadOutlined
								onClick={handleClickDownload(status, id, filename)}
								className={classNames('download-reporting', {
									success: status === 'CREATED',
									block: (status !== 'CREATED' || !id),
								})}
							/>
						</Tooltip>
					</div>
				)
			},
		},
	]
	const handleTable = (
		pagination: TablePaginationConfig,
		filterTable: Record<string, (Key | boolean)[] | null>,
		sorter: any
	) => {
		const newFilter = handlerTable(pagination, filterTable, sorter) as ReportTypes.Filter
		put(SetReportingFilter({...newFilter, filterData: {...filter.filterData, type}}))
	}

	return (
		<Table
			className={classNames('table')}
			sticky
			columns={columns}
			dataSource={reporting?.content ? reporting.content : []}
			pagination={{
				current: filter ? filter.page : 1,
				pageSize: filter ? filter.pageSize : 10,
				total: reporting ? reporting.totalCount : 1,
				position: ['bottomCenter'],
				showSizeChanger: false,
			}}
			rowKey="id"
			onChange={handleTable}
		/>
	)
}

export default TableReporting
