import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    AddDocumentsErrorAction,
    AddDocumentsStartAction,
    AddDocumentsSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import HandbookService from "services/handbookService";
import {TStatusesHandbookFeels} from "models/handbook";

export function* addDocuments({ data}: ReturnType<typeof AddDocumentsStartAction>) {
    try {
        yield HandbookService.addDocumentsRecord(data)
        yield put(AddDocumentsSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.documents))

        yield put(NotificationOpen('success', 'Новый тип документа добавлен'))
    } catch (error: any) {
        yield put(AddDocumentsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить тег'))
    }
}
