import React, {FC} from 'react'
import './action-cell.less'
import {DeleteOutlined, DownloadOutlined, EditOutlined, UserSwitchOutlined} from '@ant-design/icons'
import {Tooltip} from 'antd'
import {useDispatch, useSelector} from "react-redux";
import {ModalOpenAction} from "store/modals/actions";
import {selectRoleWithOptionalParams} from "store/auth/selectors";
import classNames from "classnames";

type Actions = {
	onEdit?: Function
	onDelete?: Function
	onSend?: Function
	onDownload?: Function | null
}

type ActionsDisabled = {
	edit?: boolean
	delete?: boolean
	send?: boolean
	download?: boolean
}

type TActionCellComponent = {
	actions: Actions
	term?: string | null
	id: string
	rowData: any
	onConfirm: (e: any) => void
	disabled?: ActionsDisabled
}

const ActionCellComponent: FC<TActionCellComponent> = ({ actions, term, id, rowData, onConfirm, disabled }) => {
	const put = useDispatch()
	const {downloadAccess} = useSelector(selectRoleWithOptionalParams)

	const handlerDeleteWithTerm = () => put(ModalOpenAction('confirm-delete',{term, onConfirm}))

	const handlerDelete = (e: any) => {
		if (!disabled?.delete && actions.onDelete) actions.onDelete(id, rowData)(e)
	}
	const handlerEdit = (e: any) => {
		if (!disabled?.edit && actions.onEdit) actions.onEdit(id, rowData)(e)
	}

	return (
		<>
			<div className="action-cell">
				{actions.onEdit && (
					<Tooltip title="Редактировать">
						<div data-btn="edit"
							 className={classNames('action-cell__edit', {disabled: disabled?.edit})}
							 onClick={handlerEdit}
						>
							<EditOutlined />
						</div>
					</Tooltip>
				)}
				{actions.onDelete && (
					<Tooltip title="Удалить">
						{term ? (
							<div
								data-btn="delete"
								className="action-cell__delete"
								onClick={handlerDeleteWithTerm}
							>
								<DeleteOutlined />
							</div>
						) : (
							<div data-btn="delete"
								 className={classNames('action-cell__delete', {disabled: disabled?.delete})}
								 onClick={handlerDelete}>
								<DeleteOutlined />
							</div>
						)}
					</Tooltip>
				)}
				{actions.onSend && (
					<div data-btn="send" className="action-cell__send" onClick={actions.onSend(id, rowData)}>
						<UserSwitchOutlined />
					</div>
				)}
				{actions.onDownload && downloadAccess && (
					<Tooltip placement="top" title="Скачать">
						<div data-btn="download" className="action-cell__download" onClick={actions.onDownload(id, rowData)}>
							<DownloadOutlined />
						</div>
					</Tooltip>
				)}
			</div>
		</>
	)
}

const ActionCell = (actions: Actions, term?: string | null, disabled?: ActionsDisabled) => (id: string, rowData: any) => {
	const onConfirm = (e: any) => {
		actions.onDelete && actions.onDelete(id, rowData)(e)
	}

	return (
		<ActionCellComponent
			actions={actions}
			term={term}
			id={id}
			rowData={rowData}
			onConfirm={onConfirm}
			disabled={disabled}
		/>
	)
}
export default ActionCell
