import {createSelector} from 'reselect';
import {TApplicationState} from '../aplication-state';

const getFilesState = (state: TApplicationState) => state.files;

export const selectFile = (id?: string | null) => (
    createSelector(
        getFilesState,
        (state) => {
            if (!id) return null
            if (!state[id]) return null
            return state[id]
        }
    )
)
export const selectIsNotEmptyFile = (id?: string | null) => (
    createSelector(
        getFilesState,
        (state) => {
            if (!id) return null
            if (!state[id]) return null
            return !!state[id].data
        }
    )
)

export const selectFiles = (ids?: string[]) => (
    createSelector(
        getFilesState,
        state => {
            if (!ids || ids.length === 0) return null;
            const files: any = {};
             ids.forEach((id) => {
                if (!state[id]) files[id] = null;
                files[id] = state[id];
            });
            return files
        }
    ));
export const selectFilesArray = (ids?: string[]) => (
    createSelector(
        getFilesState,
        state => {
            if (!ids || ids.length === 0) return null;
            return ids.map((id) => {
                if (!state[id]) return {id, data: null};
                else return {id, data: state[id]}
            });
        }
    ));
