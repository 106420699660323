import {z} from "zod";
import {ContestSchema} from "entities/session/types";
import {Id_Name_Code_Hidden_Schema, Id_Name_Code_Schema} from "shared/types/common";

export const ContentThematicsSchema = Id_Name_Code_Schema.extend({
    contests: z.array(ContestSchema),
    projectTags: z.array(Id_Name_Code_Hidden_Schema),
    hidden: z.boolean()
})
export type ContentThematics = z.infer<typeof ContentThematicsSchema>
