import main from './main'
import {board, calendar, documents, project, reporting} from './project'
import {task, tasksContent, tasksRecords, tasksSetting} from './tasks'
import {coverageData, indicators, summaryData} from './analytics'
import {reportsData, reportsSchedulersData} from './reporting'
import {content, contentList} from './content'
import {calendarProject} from './calendar'
import {
	actionsUsers,
	contentDelivery,
	contentDirection,
	contentType,
	contest,
	control,
	customization,
	internet,
	kpe,
	notifications,
	organization,
	session,
	settings,
	tagDocuments,
	thematic,
	userActions,
	usersActions,
	usersList,
	projectTags,
	regions,
	ranks,
	legalFormOrganization,
	sessionsType,
	category,
	genre,
	contentCategory,
	ervRanges,
	famousPeople,
	filmClusters
} from './options'

export type Step = {
	id: string
	selector: string
	content: string
	role: string[] | []
	roleCant?: string[]
	mediaHidden?: number
	mediaShow?: number
	page?: string[] | null
}
type Steps = {
	[key: string]: Step[]
}
const stepsTour: Steps = {
	'/projects': main,
	'/project/params': project,
	'/project/calendar': calendar,
	'/project/tasks': board,
	'/project/reporting': reporting,
	'/project/documents': documents,

	'/calendar': calendarProject,

	'/tasks/projects': tasksContent,
	'/tasks/records': tasksRecords,
	'/task': task,
	'/tasks/settings': tasksSetting,

	'/analytics/indicators': indicators,
	'/analytics/summary-data': summaryData,
	'/analytics/coverage-data': coverageData,

	'/reporting/reports': reportsData,
	'/reporting/reports-schedulers': reportsSchedulersData,

	'/options/list': usersList,
	'/options/actions-users': usersActions,
	'/options/action-user': userActions,
	'/options/notifications': notifications,
	'/options/notifications/edit/setting': settings,
	'/options/handbook/thematic': thematic,
	'/options/handbook/internet': internet,
	'/options/handbook/content-type': contentType,
	'/options/handbook/content-delivery': contentDelivery,
	'/options/handbook/organization': organization,
	'/options/handbook/actions-users': actionsUsers,
	'/options/handbook/KPE': kpe,
	'/options/handbook/content-direction': contentDirection,
	'/options/handbook/documents': tagDocuments,
	'/options/handbook/project-tags': projectTags,
	'/options/handbook/regions': regions,
	'/options/handbook/ranks': ranks,
	'/options/handbook/legal-form-organization': legalFormOrganization,
	'/options/handbook/sessions-type': sessionsType,
	'/options/handbook/category-project': category,
	'/options/handbook/genre-project': genre,
	'/options/handbook/sessions': session,
	'/options/handbook/contests': contest,
	'/options/handbook/content-category': contentCategory,
	'/options/handbook/erv-evaluation-ranges': ervRanges,
	'/options/handbook/famous-people': famousPeople,
	'/options/handbook/film-clusters': filmClusters,

	'/options/personal-settings/customization': customization,
	'/options/personal-settings/control': control,

	'/content': contentList,
	'/content/info': content
}
export default stepsTour
