import { ProjectTypes } from 'entities/project';
import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import styles from './styles.module.less';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { ModalEvent } from 'shared/ui/modal';
import AgreementOfProjectTaskRecords from '../../agreement-of-project-task-records';

const UnverifiedRecordCount = (
	unverifiedRecordCount: ProjectTypes.ProjectsList['unverifiedRecordCount'],
	project: ProjectTypes.ProjectsList
) => {

	const onOpenModal = (event: any) => {
		event.stopPropagation();
		ModalEvent.open({
			width: 800,
			destroyOnClose: true,
			component: (
				<AgreementOfProjectTaskRecords
					projectId={project.id}
					projectName={project.name}
				/>
			)
		})
	}

	return (
		<div className={styles.container}>
			<Tooltip title={unverifiedRecordCount > 0 ? 'Требуется согласование материала' : 'Материал на проверку отсутствует'}>
				<WarningOutlined onClick={onOpenModal} className={classNames(styles.item, {active: unverifiedRecordCount > 0})} />
			</Tooltip>
		</div>
	)
};

export default UnverifiedRecordCount;