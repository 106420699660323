import {createSelector} from 'reselect';
import {TApplicationState} from "store/aplication-state";
import {Task} from "gantt-task-react";
import { CSSProperties } from 'react';

const calendar = (state: TApplicationState) => state.calendar;

export const filter = createSelector(
    calendar,
    (state) => state.filter
)
export const view = createSelector(
    calendar,
    (state) => state.view
)
export const list = createSelector(
    calendar,
    (state) => state.list
)
export const listGant = createSelector(
    calendar,
    (state) => {
        const result: Task[] = [];

        state.list?.forEach((el) => {
            const tasks: Task[] = [];

            result.push({
                id: el.id,
                name: el.name,
                type: 'project',
                start: new Date(el.contractDate),
                end: el.completionDate ? new Date(el.completionDate) : new Date(),
                styles: {
                  backgroundColor: '#D9D9DE',
                  backgroundSelectedColor: '#D9D9DE',
                  progressSelectedColor: '#A7A7AB',
                  progressColor: '#A7A7AB'
                },
                isDisabled: true,
                dependencies: [],
                progress: 0
            })
            el.sprints?.forEach((sprint) => {


                const color = (progress: boolean): CSSProperties['color'] => {
                    switch (sprint.status.code) {
                        case 'INACTIVE':
                            return progress ? '#76767A' : '#76767a36'
                        case 'ACTIVE':
                            return progress ? '#FFC700' : '#ffc70036'
                        case 'CLOSED':
                            return progress ? '#84BD00' : '#20b22736'
                        case 'ENDED_OPEN':
                            return progress ? '#FF2E56' : '#ff2e5636'
                        default:
                            return ''
                    }
                }
                tasks.push({
                    id: sprint.id,
                    name: sprint.name,
                    type: 'task',
                    start: new Date(sprint.dateStart),
                    end: new Date(sprint.dateEnd),
                    isDisabled: true,
                    dependencies: [el.id],
                    progress: !!sprint.totalTaskCount && !!sprint.readyTaskCount ? (100 * sprint.readyTaskCount) /  sprint.totalTaskCount : 0,
                    styles: {
                        backgroundColor: color(false),
                        backgroundSelectedColor: color(false),
                        progressColor: color(true),
                        progressSelectedColor: color(true),
                    },

                })
            })

            result.push(...tasks)
        })
        return result;
    }
)
export const status = createSelector(
    calendar,
    (state) => ({isLoading: state.isLoading, error: state.error})
)