import {AxiosResponse} from 'axios'
import {put} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {
	GetTagsFirstLevelWithThematicErrorAction,
	GetTagsFirstLevelWithThematicStartAction,
	GetTagsFirstLevelWithThematicSuccessAction
} from "store/tags/actions";
import {TTag} from "models/tags";
import {TagsService} from "services/tagsService";

export default function* getFirstLevelThematics({ thematicIds }: ReturnType<typeof GetTagsFirstLevelWithThematicStartAction>) {
	try {
		const {data}: AxiosResponse<TTag[]> = yield TagsService.getTagsFirstLevel({
			page: 0,
			pageSize: 0,
			filterData: { thematicIds }
		});
		yield put(GetTagsFirstLevelWithThematicSuccessAction(data))
	} catch (error: any) {
		yield put(GetTagsFirstLevelWithThematicErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
