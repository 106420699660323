const summaryData = [
	{
		id: '89',
		selector: '.table-analytics',
		content:
			'Сводная таблица позволяет ознакомиться с метриками проектов системы в разрезе конкурса, года, сессии, статуса проекта и его направления',
		role: [],
	},
]

export default summaryData
