import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectTasksCostTypeOptions,
    selectTasksFieldsStatuses,
    selectTasksRecordsStatusesOptions,
    selectTasksStagesOptions,
    selectTasksStatusesOptions,
    selectTasksTypesOptions
} from "../store/tasks/selectors";
import {TTasksTypesFields} from "../models/task";
import {GetTasksFieldsStartAction} from "../store/tasks/actions/tasks";


const useTasksFields = (terms?: TTasksTypesFields[]) => {
    const put = useDispatch();

    const data = {
        "statuses": useSelector(selectTasksStatusesOptions),
        "types": useSelector(selectTasksTypesOptions),
        "stages": useSelector(selectTasksStagesOptions),
        "costType": useSelector(selectTasksCostTypeOptions),
        "recordsStatuses": useSelector(selectTasksRecordsStatusesOptions),
    }
    const {isLoading} = useSelector(selectTasksFieldsStatuses)

    useEffect(() => {
        if (terms) {
            terms.forEach((el) => {
                if (!data[el]) put(GetTasksFieldsStartAction(el))
            })
        }
        // eslint-disable-next-line
    }, [])

    return {isLoading, ...data}
}
export default useTasksFields;