import React from 'react'
import styles from './styles.module.less'
import {Select, Spin} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {action, select} from '../../../model'
import classNames from "classnames";
import {useReportsTemplates} from "../../../lib/hooks/use-reports";

const 	ReportSelect = () => {
	const put = useDispatch()
	const filter = useSelector(select.filter)
	const { data: options, isLoading } = useReportsTemplates()

	const handlerChange = (values: any) => {
		put(action.setValue({
				...filter,
				filterData: {
					...filter.filterData,
					reportTemplateIds: values.length ? values : undefined,
				},
			}))
	}
	return (
		<div className={styles.select_wrapper}>
			<Spin spinning={isLoading}>
				<Select
					mode="multiple"
					getPopupContainer={(trigger) => trigger.parentNode}
					allowClear={true}
					style={{ width: '250px' }}
					maxTagCount={1}
					options={options}
					placeholder={'Отчеты'}
					className={classNames(styles.select,'tour-report-select')}
					onChange={handlerChange}
					filterOption={(input, option) =>
						(option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
					}
				/>
			</Spin>
		</div>
	)
}

export default ReportSelect
