import {ContentCategory,} from "../types";
import {useQuery, UseQueryResult} from "react-query";
import {transformArrayIdNameForValue} from "utils/transformObjectFeels";
import {useMemo} from "react";
import {getContentCategories} from "../api";

type onSuccessType = (data: ContentCategory[]) => void
export function useGetContentCategories(onSuccess?: onSuccessType): UseQueryResult<ContentCategory[]> {
    return useQuery({
        queryKey: [getContentCategories.key],
        queryFn: () => getContentCategories.fetch(),
        onSuccess,
    });
}
export function useGetContentCategoriesOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetContentCategories(onSuccess);
    const options = useMemo(() => transformArrayIdNameForValue(data), [data]);
    return { data: options, ...rest };
}