import {ActionTypes} from "./types";
import {FilterValues, TStore} from "./reducer";
import {Types, Filter} from "../../types";

export const setUserFiltersValue = <V extends Partial<TStore>>(value: V) => ({
    type: ActionTypes.SET_VALUE,
    value
})
export const setUserFilter = <V extends Partial<FilterValues>>(typeContent: Types['name'], value: V) => ({
    type: ActionTypes.SET_FILTER,
    typeContent, value
})
export const getTypes = () => ({
    type: ActionTypes.GET_TYPES
})
export const createFilter = (filterType: Types["code"], callback: () => void) => ({
    type: ActionTypes.CREATE_FILTER, filterType, callback
})
export const activateFilter = (filterType: Types["code"], filterData: Filter['filterData'], needToMakeRequest: boolean = true) => ({
    type: ActionTypes.ACTIVATE_FILTER, filterType, filterData, needToMakeRequest
})