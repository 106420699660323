import {AxiosResponse} from 'axios'
import {put} from 'redux-saga/effects'
import {
    GetPersonalSettingErrorAction,
    GetPersonalSettingStartAction,
    GetPersonalSettingSuccessAction,
} from '../../../store/options/actions/actions-personal-setting'
import CreateErrorMessage from '../../../utils/validation/createErrorMessage'
import {NotificationOpen} from '../../../store/notification/actions'
import PersonalSettingService from '../../../services/personalSettingService'
import {TPersonalSettingResponse} from '../../../models/personal-setting'

export default function* getPersonalSetting({ id }: ReturnType<typeof GetPersonalSettingStartAction>) {
	try {
		const { data }: AxiosResponse<TPersonalSettingResponse[]> = yield PersonalSettingService.getPersonalSetting(id)
		yield put(GetPersonalSettingSuccessAction(data))
	} catch (error: any) {
		yield put(GetPersonalSettingErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить настройки'))
	}
}
