import React, {FC} from 'react'
import {ImageComponent} from '../../../image-component'
import {ItemEmail, ItemPhone} from './item'
import {UserTypes} from 'entities/user'
import {ProjectTypes} from 'entities/project'
import {Tooltip} from "antd";
import classNames from "classnames";

type TItemComponent = {
	person: UserTypes.User
	role: UserTypes.RoleName | undefined
	status: 'ARCHIVE' | 'BLOCKED' | 'ACTIVE'
	contractorOrganization?: ProjectTypes.Project['contractorOrganization']
}

export const ItemsComponent: FC<TItemComponent> = ({ person,role,contractorOrganization, status }) => {

	const organizations: UserTypes.Organizations[] = role === 'ROLE_CONTRACTOR' ?
		person.organizations.filter(it=> it.id === contractorOrganization?.id) : person.organizations

	return (
		<div className="item" key={person.id}>
			<div className="item__header">
				<ImageComponent
					file={person.image}
					altImg={window.location.origin + '/images/avatar.png'}
					alt={person.firstName}
				/>
			</div>
			<div className="item__content">
				<div className="item__content__name-wrapper">
					<Tooltip title={statusName(status)}>
						<span className={classNames('item__content__rhombus', status)}/>
					</Tooltip>
					<span className="item__content__name">
						{person.lastName} {person.firstName} {person.middleName || ''}
					</span>
                    {!!person?.leader && (
                        <div className="item__leader">
                            <span>Руководитель команды</span>
                        </div>
                    )}
				</div>
				<ul>
					{person.organizations.length ? (
						<>
							<ItemEmail organizations={organizations} />
							<ItemPhone organizations={organizations} />
						</>
					) : null}
				</ul>
			</div>

		</div>
	)
}

function statusName(status: string | undefined) {
	switch (status) {
		case 'ACTIVE':
			return 'Активен'
		case 'ARCHIVE':
			return 'Архивирован'
		case 'BLOCKED':
			return 'Блокирован'
		default:
			return ''
	}
}