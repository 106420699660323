import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "store/auth/selectors";
import React, {FC, useState} from "react";
import {
    ConfirmEmailStartAction,
    SendConfirmEmailStartAction
} from "store/options/actions/actions-personal-setting";
import {TYPE_NOTIFICATION_CHANNELS} from "models/personal-setting";
import {Button, Input, Tooltip} from "antd";
import {CheckCircleOutlined, StarFilled} from "@ant-design/icons";
import Timer from "../options/personal-setting/customization/timer";
import {TEmail} from "models/common";


type TEmailComponent = {
    data: TEmail
    disabled: boolean
}
type TConfirmMailComponent= {
    disabled: boolean
}

const EmailComponent: FC<TEmailComponent> = ({data,disabled}) => {
    const put = useDispatch()
    const user = useSelector(selectUser)
    const [confirmOrReadyMail, setConfirmOrReadyMail] = useState<boolean>(false)
    const [code, setCode] = useState<any>('')


    const handleConfirmMail = () => {
        setConfirmOrReadyMail(true)
        user && put(SendConfirmEmailStartAction(user.id, TYPE_NOTIFICATION_CHANNELS.EMAIL,data.email))
    }

    const handleClickRightMail = () => {
        user && put(ConfirmEmailStartAction(user.id, TYPE_NOTIFICATION_CHANNELS.EMAIL, code, data.email))
        setCode('')
    }

    const handleClickRepeatCode = (type: TYPE_NOTIFICATION_CHANNELS) => () => {
        user && put(SendConfirmEmailStartAction(user.id, type,data.email))
    }

    const onChangeCode = (e:any) =>{
        setCode(e.target.value)}
    return (
        <div className={`confirmPage__wrapper ${data.isConfirmed || !confirmOrReadyMail ? `confirmPage__nowrap` : ``}`}>
            {data.isMain && (<div>
                <Tooltip title="Основной">
                    <StarFilled className="confirmPage__star"/>
                </Tooltip>
            </div>)}
            <Input autoComplete="off" type="text" value={data.email} placeholder="Ваша почта" disabled />
            {data.isConfirmed && <div className="checkCircle" ><CheckCircleOutlined /> <span className="checkCircle__text">Почта подтверждена</span></div>}
            {!data.isConfirmed && (
                <>
                    {confirmOrReadyMail ? (
                        <div className="wrapper__confirmPage">
                            <Timer onGetCodeClick={handleClickRepeatCode(TYPE_NOTIFICATION_CHANNELS.EMAIL)} />
                            <Input
                                autoComplete="off"
                                value={code}
                                onChange={onChangeCode}
                                type="text"
                                placeholder="Введите код"
                                className="input__confirm"
                            />
                            <Button type="primary" className="btn-confirm" onClick={handleClickRightMail}>
                                Готов
                            </Button>
                        </div>
                    ) : (
                        <Button
                            type="primary"
                            onClick={handleConfirmMail}
                            disabled={!disabled}
                            className={`${!disabled && 'btn-disabled'}`}
                        >
                            Подтвердить
                        </Button>
                    )}
                </>
            )}
        </div>
    )

}




const ConfirmMailComponent:FC<TConfirmMailComponent> = ({disabled}) => {
    const user = useSelector(selectUser)

    const mailList =user?.personalData?.organizations && user?.personalData?.organizations.map(it=>[...it.emails]).flat().filter(it=>it.isMain)

    const mailListSet = mailList?.filter((it: TEmail,index: number)=>{
        const arr = mailList.map(it=>it.email)
        const i = arr.findIndex(el=>el===it.email)
        return   i === index
    })

    return (
        <div className="confirmPage__content--mail">
            {mailListSet?.length && (
                mailListSet.map( (it, index) => (
                   <EmailComponent data={it} disabled={disabled} key={index} />
                ))
            )}
        </div>
    )
}

export default ConfirmMailComponent