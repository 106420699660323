import { axiosRequest } from 'shared/config/axios'
import { ScheduleDatesSet, ScheduleDatesSetCreate, ScheduleDatesSetSchema } from '../types'

/**
 *
 * @description Получение всех наборов дат для автосоздания задач
 */
export const getTasksScheduleDatesKey = 'task_duplication_schedule_date_presets/all'
export const getTasksScheduleDates = async () => {
	const { data } = await axiosRequest<ScheduleDatesSet[]>({
		method: 'GET',
		url: '/task-duplication-schedule-date-presets/all',
		parser: ScheduleDatesSetSchema.array(),
	})
	return data?.length ? data : []
}
/**
 *
 * @description Создание набора дата для автосоздания задач
 */
export const createTasksScheduleDates = async (data: ScheduleDatesSetCreate) => {
	await axiosRequest({
		method: 'POST',
		url: 'task-duplication-schedule-date-presets',
		data,
	})
}
/**
 *
 * @description Обновление набора дат для автосоздания задач
 */
export const updateTasksScheduleDates = async ({ data, id }: { data: ScheduleDatesSetCreate; id: string }) => {
	await axiosRequest({
		method: 'PUT',
		url: `task-duplication-schedule-date-presets/${id}`,
		data,
	})
}
/**
 *
 * @description Удаление набора дат для автосоздания задач
 */
export const deleteTasksScheduleDates = async (id: string) => {
	await axiosRequest({
		method: 'DELETE',
		url: `task-duplication-schedule-date-presets/${id}`,
	})
}
