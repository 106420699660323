import {AxiosResponse} from 'axios'
import {put} from 'redux-saga/effects'
import {
	GetActiveNotificationChannelsErrorAction,
	GetActiveNotificationChannelsStartAction,
	GetActiveNotificationChannelsSuccessAction
} from "../../../store/options/actions/actions-notifications";
import NotificationService from "../../../services/notificationService";
import {TNotificationChannels} from "../../../models/notifications";
import CreateErrorMessage from "../../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../../store/notification/actions";

export default function* getActiveNotificationChannels(_: ReturnType<typeof GetActiveNotificationChannelsStartAction>) {
	try {
		const { data }: AxiosResponse<TNotificationChannels[]> = yield NotificationService.getActiveNotificationsAChannels()
		yield put(GetActiveNotificationChannelsSuccessAction(data))
	} catch (error: any) {
		yield put(GetActiveNotificationChannelsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить каналы'))
	}
}
