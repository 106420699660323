import React, {FC} from "react";
import {Field, ErrorMessage} from 'formik';
import {FieldAttributes} from "formik/dist/Field";
import {Input} from "antd";
import TextError from "./text-error";
import {ISearch} from "../../models/feels";

const { Search } = Input;

const InputSearchComponent: FC<ISearch> = ({label, name, handler, placeholder}) => {
    const handleChange = (form: any) => (e: any) => {
        form.setFieldValue(name, e.target.value);
        handler && handler(e.target.value)
    }
    const onSearch = (form: any) => (value: string) => {
        form.setFieldValue(name, value);
        handler && handler(value)
    }
    return (
        <div className="form-group">
            {label && (
                <label htmlFor={name}>{label}</label>
            )}
            <Field className="form-control" name={name}>
                {(el: FieldAttributes<any>) => {
                    return (
                        <>
                            <Search
                                value={el.form.values[name]}
                                placeholder={placeholder}
                                onChange={handleChange(el.form)}
                                onSearch={onSearch(el.form)}
                            />
                            {el.meta.touched && el.meta.error && (
                                <ErrorMessage name={name} component={TextError}/>
                            )}
                        </>
                    )
                }}
            </Field>
        </div>
    )
}
export default InputSearchComponent