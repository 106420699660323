import { AxiosResponse } from 'axios';
import { Id_Name_Code } from 'shared/types/common';
import $api from '../../http';

export class SprintService {
	//Получение всех статусов этапов
	static getAllKey = '/sprints/statuses';
	static getAll(): Promise<AxiosResponse<Id_Name_Code[]>> {
		return $api.get('/sprints/statuses')
	}
}