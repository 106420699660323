import { createSelector } from 'reselect'
import {TApplicationState} from "store/aplication-state";

const getState = (state: TApplicationState) => state.content.list;
export const selectContentListFilter = createSelector(getState, (state) => state.filter)
export const selectContent = createSelector(getState, (state) => ({
    isLoading: state.isLoading,
    contentList: state.contentList,
    filter: state.filter,
    typeContent: state.typeContent,
}))