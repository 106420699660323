import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetHandbookStartAction,
    UpdateContentDirectionErrorAction,
    UpdateContentDirectionStartAction,
    UpdateContentDirectionSuccessAction
} from "store/options/actions/actions-handbook";
import HandbookService from "services/handbookService";
import {TStatusesHandbookFeels} from "models/handbook";


export function* updateContentDirection({id, data,}: ReturnType<typeof UpdateContentDirectionStartAction>) {
    try {
        yield HandbookService.updateContentDirectionRecord(data,id)
        yield put(UpdateContentDirectionSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.contentDirection))

        yield put(NotificationOpen('success', 'Тип направлений контента обновлен'))

    } catch (error: any) {
        yield put(UpdateContentDirectionErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить тип направлений контента'))
    }
}
