import {put} from "redux-saga/effects";
import {GetContentListStartAction, SetContentFilter} from "store/content/list/actions";

export function* setFilter(_action: ReturnType<typeof SetContentFilter>) {
    try {
        yield put(GetContentListStartAction())

    } catch (error: any) {
        console.log(error)
    }
}