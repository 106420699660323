const contentList = [
	{
		id: '1',
		selector: '.ContentPage__filters',
		content: 'Разделы содержат контент проектов определенного типа и показывают количество проектов на разных стадиях реализации',
		role: []
	},
	{
		id: '2',
		selector: '.ContentPage__list',
		content: 'Из списка проектов можно перейти в карточку контента выбранного вами проекта',
		role: []
	},
]
export default contentList