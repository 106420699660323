import {put} from 'redux-saga/effects'
import {
    BulkKPEActionsErrorAction,
    BulkKPEActionsStartAction,
    BulkKPEActionsSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import HandbookService from "services/handbookService";

export function* bulkKPEActions({ userArr, action }: ReturnType<typeof BulkKPEActionsStartAction>) {
	try {
		yield HandbookService.bulkKPEActions(userArr, action)
		yield put(BulkKPEActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.KPE))
		yield put(NotificationOpen('success', 'КПЭ обновлено'))

	} catch (error: any) {
		yield put(BulkKPEActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	} finally {
	}
}
