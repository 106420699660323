import {TasksActionTypes} from "../action-types/tasks";
import {TTaskRequestDto, TTaskResponse, TTasksTypesFields} from "models/task";
import {T_ID_NAME, T_ID_NAME_CODE} from "models/common";
import {TAllTasksListResponse, TTaskFilter, TTaskListFilterData, TTaskListResponse} from "models/task/task-list";
import {TGetTaskArray} from "../reducer/tasks";
import {TDuplicationSchedule} from "models/task/task.kanban";

export const ClearStatusTasks = () => ({type: TasksActionTypes.CLEAR_STATUS,})
/**
 * Получение списка задач без пагинации
 */
export const GetAllTasksNPStartAction = (filter: TTaskListFilterData) => ({
    type: TasksActionTypes.GET_ALL_TASKS_START,
    filter
})
export const GetAllTasksNPSuccessAction = (tasks: TAllTasksListResponse[]) => ({
    type: TasksActionTypes.GET_ALL_TASKS_SUCCESS,
    tasks
})
export const GetAllTasksNPErrorAction = (error: any) => ({
    type: TasksActionTypes.GET_ALL_TASKS_ERROR,
    error
})
export const ClearTasksNPAction = () => ({
    type: TasksActionTypes.CLEAR_ALL_TASKS,
})
/**
 * Получение полей для задач
 */
export const GetTasksFieldsStartAction = (typeField: TTasksTypesFields) => ({
    type: TasksActionTypes.GET_TASKS_FIELDS_STAT,
    typeField
})
export const GetTasksFieldsSuccessAction = ( typeField: TTasksTypesFields, filed: T_ID_NAME[] | T_ID_NAME_CODE[]) => ({
    type: TasksActionTypes.GET_TASKS_FIELDS_SUCCESS,
    typeField,
    filed
})
export const GetTasksFieldsErrorAction = (error: any) => ({
    type: TasksActionTypes.GET_TASKS_FIELDS_ERROR,
    error
})
/**
 * Получение задачи по ID
 */
export const GetTaskStartAction = (taskId: string) => ({
    type: TasksActionTypes.GET_TASK_START,
    taskId
})
export const GetTaskSuccessAction = (task: TTaskResponse) => ({
    type: TasksActionTypes.GET_TASK_SUCCESS, task
})
export const GetTaskErrorAction = (error: any) => ({
    type: TasksActionTypes.GET_TASK_ERROR,
    error
})
/**
 * Создание новой задачи
 */
export const CreateTaskStartAction = (task: TTaskRequestDto, ) => ({
    type: TasksActionTypes.CREATE_TASK_START,
    task
})
export const CreateTaskSuccessAction = () => ({
    type: TasksActionTypes.CREATE_TASK_SUCCESS,
})
export const CreateTaskErrorAction = (error: any) => ({
    type: TasksActionTypes.CREATE_TASK_ERROR,
    error
})
/**
 * Создание новой задачи
 */
export const UpdateTaskStartAction = (taskId: string, task: TTaskRequestDto, ) => ({
    type: TasksActionTypes.UPDATE_TASK_START,
    taskId, task,
})
export const UpdateTaskSuccessAction = () => ({
    type: TasksActionTypes.UPDATE_TASK_SUCCESS,
})
export const UpdateTaskErrorAction = (error: any) => ({
    type: TasksActionTypes.UPDATE_TASK_ERROR,
    error
})
/**
 * Удаление задачи
 */
export const DeleteTaskStartAction = (taskId: string) => ({
    type: TasksActionTypes.DELETE_TASK_START,
    taskId
})
export const DeleteTaskSuccessAction = () => ({
    type: TasksActionTypes.DELETE_TASK_SUCCESS
})
export const DeleteTaskErrorAction = (error: any) => ({
    type: TasksActionTypes.DELETE_TASK_ERROR,
    error
})
/**
 * Изменение статуса задачи
 */
export const UpdateStatusTaskStartAction = ({taskId, statusId, projectId, callback}: {taskId: string, statusId: string, projectId?: string, callback?: Function}) => ({
    type: TasksActionTypes.UPDATE_STATUS_TASK_START,
    taskId, statusId, projectId, callback
})
export const UpdateStatusTaskSuccessAction = () => ({
    type: TasksActionTypes.UPDATE_STATUS_TASK_SUCCESS
})
export const UpdateStatusTaskErrorAction = (error: any) => ({
    type: TasksActionTypes.UPDATE_STATUS_TASK_ERROR,
    error
})
/**
 * Изменение фильтра задач
 */
export const SetTaskFilter = (filter: TTaskFilter) => ({
    type: TasksActionTypes.SET_TASK_FILTER,
    filter
})

/**
 * Сброс фильтра задач
 */
export const ResetTaskFilter = (filter: TTaskFilter) => ({
    type: TasksActionTypes.RESET_TASK_FILTER,
    filter
})

/**
 * Получение списка задач
 */
export const GetAllTasksStartAction = () => ({
    type: TasksActionTypes.GET_TASKS_START,
})
export const GetAllTasksSuccessAction = (tasks: TTaskListResponse) => ({
    type: TasksActionTypes.GET_TASKS_SUCCESS,
    tasks
})
export const GetAllTasksErrorAction = (error: any) => ({
    type: TasksActionTypes.GET_TASKS_ERROR,
    error
})
/**
 * Дублирование задачи
 */
export const DuplicateTasksStartAction = (taskId: string, dateList: string[], tasksGet?: TGetTaskArray) => ({
    type: TasksActionTypes.DUPLICATE_TASKS_START, taskId, dateList, tasksGet
})
export const DuplicateTasksSuccessAction = () => ({
    type: TasksActionTypes.DUPLICATE_TASKS_SUCCESS,
})
export const DuplicateTasksErrorAction = (error: any) => ({
    type: TasksActionTypes.DUPLICATE_TASKS_ERROR,
    error
})


export const DuplicateScheduleTasksStartAction = (task: TDuplicationSchedule & { taskId : string}, closeModal?: Function) => ({
    type: TasksActionTypes.DUPLICATE_SCHEDULE_TASKS_START,
    task,
    closeModal
})
export const DuplicateScheduleTasksSuccessAction = () => ({
    type: TasksActionTypes.DUPLICATE_SCHEDULE_TASKS_SUCCESS,
})
export const DuplicateScheduleTasksErrorAction = (error: any) => ({
    type: TasksActionTypes.DUPLICATE_SCHEDULE_TASKS_ERROR,
    error
})

/**
 * Обновление Создания расписания автосоздания задачи
 */
export const UpdateDuplicateScheduleTasksStartAction = (scheduleId: string, data: TDuplicationSchedule & { taskId : string}, callback?: Function) => ({
    type: TasksActionTypes.UPDATE_DUPLICATE_SCHEDULE_TASKS_START,
    scheduleId,
    data,
    callback
})
export const UpdateDuplicateScheduleTasksSuccessAction = () => ({
    type: TasksActionTypes.UPDATE_DUPLICATE_SCHEDULE_TASKS_SUCCESS,
})
export const UpdateDuplicateScheduleTasksErrorAction = (error: any) => ({
    type: TasksActionTypes.UPDATE_DUPLICATE_SCHEDULE_TASKS_ERROR,
    error
})

export const SetRowIds = (rowIds: string[]) => ({
    type: TasksActionTypes.SET_ROW_IDS, rowIds
})
export const ClearRowIds = () => ({
    type: TasksActionTypes.CLEAR_ROW_IDS
})