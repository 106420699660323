import React, { useEffect, useState } from 'react';
import "gantt-task-react/dist/index.css";
import styles from './gantt.module.less';
import {Gantt, ViewMode} from "gantt-task-react";
import ErrorBoundary from "components/error-boundary";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import {CalendarModel, CalendarTypes} from 'pages/calendar';
import {Empty, Pagination} from "antd";
import GanttLeft from "./gantt-left";
import classNames from "classnames";

const heightPagination = 58;
const ganttHeader = 70;

const GanttComponent = () => {
    const [height, setHeight] = useState(600);
    const put = useDispatch();
    const list = useSelector(CalendarModel.select.listGant);
    const filter = useSelector(CalendarModel.select.filter);
    const view = useSelector(CalendarModel.select.view);

    const handlerPagination = (
        page: number, pageSize?: number
    ) => {
        put(CalendarModel.actions.setCalendarValue({filter: {
                filterData: filter.filterData,
                pageSize: pageSize || filter.pageSize,
                page: page,
                totalCount: filter.totalCount
            } as CalendarTypes.TCalendarState['filter']} ))
        put(CalendarModel.actions.GetCalendarValue())
    }

    useEffect(() => {
        const windowHeight = document.body.clientHeight;
        const allHeight = windowHeight - (heightPagination + ganttHeader)
        const heightCount = list.length * 50;
        setHeight(heightCount <= allHeight ? heightCount : allHeight)
    }, [list.length]);

    return (
        <div className={classNames(styles.gantt,'gant-table')}>
            {list.length ? (
                <Gantt
                    ganttHeight={height}
                    tasks={list}
                    viewMode={ViewType(view).view}
                    columnWidth={ViewType(view, list.length).width}


                    headerHeight={70}
                    arrowColor="#0765d929"

                    projectBackgroundColor={'#D9D9DE'}
                    projectProgressColor={'#A7A7AB'}

                    projectBackgroundSelectedColor={'rgba(193, 216, 237, 1)'}
                    projectProgressSelectedColor={'#D0F053'}

                    barProgressColor={'#D0F053'}
                    barProgressSelectedColor={'#D0F053'}

                    barBackgroundColor={'#D9D9DE'}
                    barBackgroundSelectedColor={'#D0F053'}

                    todayColor='#F5F5F5'
                    TaskListHeader={(props, _context) => {
                        return <div className={styles.header}><span>Проекты</span></div>
                    }}
                    TaskListTable={(_props, _context) => {
                        return <GanttLeft/>
                    }}
                    TooltipContent={(props) => {
                        return (
                            <div className={styles.details}>
                                <strong>{props.task.name}</strong>
                                <ErrorBoundary>
                                    <ul>
                                        {props.task.type === 'task' && (
                                            <li>Статус {Math.floor(props.task.progress)} %</li>
                                        )}
                                        <li>Дата начала {moment(props.task.start).format('DD.MM.yy')}</li>
                                        <li>Дата окончания {moment(props.task.end).format('DD.MM.yy')}</li>
                                    </ul>
                                </ErrorBoundary>
                            </div>
                        )
                    }}

                    locale={'ru'}
                    timeStep={10000}
                />
            ) : <Empty/>}
            <div className={classNames(styles.pagination, 'pagination')}>
                <Pagination
                    onChange={handlerPagination}
                    current={filter.page}
                    pageSize={filter.pageSize}
                    total={filter.totalCount}
                    showSizeChanger={true}
                    pageSizeOptions={['10', '20', '30', '40' ,'50']}
                />
            </div>
        </div>
    )
};
function ViewType(type: CalendarTypes.ViewGantt, count?: number) {
    const widthScreen = window.innerWidth;
    let view;
    let width;
    switch (type) {
        case 'month':
            view = ViewMode.Month;
            if (count && count < 10 && widthScreen >= 900) {
                width = 200;
            } else {
                width = 150;
            }
            break;
        case "day":
            view = ViewMode.Day;
            if (count && count < 10 && widthScreen >= 900) {
                width = 60;
            } else {
                width = 45;
            }
            break;
        case "week":
            view = ViewMode.Week;
            if (count && count < 10 && widthScreen >= 900) {
                width = 140;
            } else {
                width = 55;
            }

            break
    }
    return {view, width};
}
export default GanttComponent;