import {takeLatest} from 'redux-saga/effects'
import {AuthActionTypes} from '../../store/auth/action-types'
import {loginWorker} from './login'
import {getCurrentUserWorker} from './getCurentUser'
import {logoutWorker} from './logout'
import {restorePassword} from './restore'
import {generateAuthKey} from './generateKey'
import {getAuthKey} from './getKey'
import {blockAuthKey} from './blockKey'
import {updateDownloadRightsWorker} from "./updateDownloadRights";

export default function* authSaga() {
	yield takeLatest(AuthActionTypes.LOGIN_START, loginWorker)
	yield takeLatest(AuthActionTypes.LOGIN_BY_USER_START, loginWorker)
	yield takeLatest(AuthActionTypes.GET_CURRENT_USER, getCurrentUserWorker)
	yield takeLatest(AuthActionTypes.LOGOUT, logoutWorker)
	yield takeLatest(AuthActionTypes.RESTORE_START, restorePassword)
	yield takeLatest(AuthActionTypes.GENERATE_KEY_START, generateAuthKey)
	yield takeLatest(AuthActionTypes.GET_KEY_START, getAuthKey)
	yield takeLatest(AuthActionTypes.BLOCK_KEY_START, blockAuthKey)
	yield takeLatest(AuthActionTypes.CHANGE_DOWNLOAD_RIGHTS_START, updateDownloadRightsWorker)
}
