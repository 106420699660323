import {put} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	GetProjectsStartAction,
	UploadProjectsErrorAction,
	UploadProjectsStartAction,
	UploadProjectsSuccessAction
} from "store/projects/actions";
import Api from "entities/project/api/project";


export function* uploadProjectsExcel({ data }: ReturnType<typeof UploadProjectsStartAction>) {
	try {
		const formData = new FormData()
		formData.append('excel', data)
		yield Api.uploadProjects(formData)
		yield put(UploadProjectsSuccessAction())
		yield put(GetProjectsStartAction())
		yield put(NotificationOpen('success', 'Успешно'))
	} catch (error: any) {
		const message = CreateErrorMessage.response(error)
		yield put(UploadProjectsErrorAction(message ? message : 'Не удалось загрузить'))
		yield put(NotificationOpen('error', 'Ошибка', 'Загрузка данных не удалась, посмотрите список ошибок заполнения файла'))
	}
}
