import { put } from 'redux-saga/effects'
import { NotificationOpen } from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	AddGenreProjectErrorAction,
	AddGenreProjectStartAction,
	AddGenreProjectSuccessAction,
	GetHandbookStartAction,
} from 'store/options/actions/actions-handbook'
import { TStatusesHandbookFeels } from 'models/handbook'
import GenreProjectService from 'services/genreProjectService'

export function* addGenreProject({ data }: ReturnType<typeof AddGenreProjectStartAction>) {
	try {
		yield GenreProjectService.addIGenreProjectRecord(data)
		yield put(AddGenreProjectSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.genreProject))

		yield put(NotificationOpen('success', 'Новый жанр добавлен'))
	} catch (error: any) {
		yield put(AddGenreProjectErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить жанр'))
	}
}
