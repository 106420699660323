import {z} from "zod";
import ZodParser from "shared/services/zod-parser";
import {StepSecondDto} from "../dto/project-form_StepSecond.dto";


const schema = z.object({
    contentThematicIds: z.array(z.string()).nullable(),
    zeroLevelTagIds: z.array(z.string()).nullable(),
    firstLevelTagIds: z.array(z.string()).nullable(),
    secondLevelTagIds: z.array(z.string()).nullable(),
    thirdLevelTagIds: z.array(z.string()).nullable(),
    firstLevelAssignmentTagIds: z.array(z.string()).nullable(),
    secondLevelAssignmentTagIds: z.array(z.string()).nullable(),
    regionAboutIds: z.array(z.string()).nullable(),
    regionForIds: z.array(z.string()).nullable(),
    contentDirectionId: z.string().nullable(),
    contentFormats: z.array(z.object({
        id: z.string(),
        contentCount: z.number(),
        typeId: z.string(),
        submissionFormIds: z.array(z.string())
    })).nullable(),
    firstContentCreationDate: z.date().nullable(),
    allContentCreationDate: z.date().nullable(),
    firstContentPostingDate: z.date().nullable(),
    allContentPostingDate: z.date().nullable(),
    allContentReachFixDate: z.date().nullable(),

})
export type CreateSchema2 = z.infer<typeof schema>
export function step2(step: StepSecondDto): CreateSchema2 {
    const mapped: CreateSchema2 = {
        contentThematicIds: step.contentThematics.value.length > 0
            ? step.contentThematics.value.map((el) => el.value) : null,
        zeroLevelTagIds: step.zeroLevelTags.value.length > 0
            ? step.zeroLevelTags.value.map((el) => el.value) : null,
        firstLevelTagIds: step.firstLevelTags.value.length > 0
            ? step.firstLevelTags.value.map((el) => el.value) : null,
        secondLevelTagIds: step.secondLevelTags.value.length > 0
            ? step.secondLevelTags.value.map((el) => el.value) : null,
        thirdLevelTagIds: step.thirdLevelTags.value.length > 0
            ? step.thirdLevelTags.value.map((el) => el.value) : null,
        firstLevelAssignmentTagIds: step.firstLevelAssignmentTags.value.length > 0
            ? step.firstLevelAssignmentTags.value.map((el) => el.value) : null,
        secondLevelAssignmentTagIds: step.secondLevelAssignmentTags.value.length > 0
            ? step.secondLevelAssignmentTags.value.map((el) => el.value) : null,
        contentDirectionId: step.contentDirection.value as string || null,
        contentFormats: step.contentFormats.value.length > 0
            ? step.contentFormats.value.filter(it => it?.type && it?.submissionForms?.length && it?.contentCount).map((el) => ({
                id: el.id,
                contentCount: el?.contentCount || 0,
                submissionFormIds: el?.submissionForms?.map((el) => el.value) || [],
                typeId: el?.type?.value,
                isContent: el.isContent,
                isAccountedContent: el.isAccountedContent,
            })) : null,
        regionAboutIds: step.regionAboutSet.value.length > 0
            ? step.regionAboutSet.value.map(({value}) => value) : null,
        regionForIds: step.regionForSet.value.length > 0
            ? step.regionForSet.value.map(({value}) => value) : null,
        firstContentCreationDate: step.firstContentCreationDate.value ? new Date(String(step.firstContentCreationDate.value)) : null,
        allContentCreationDate: step.allContentCreationDate.value ? new Date(String(step.allContentCreationDate.value)) : null,
        firstContentPostingDate: step.firstContentPostingDate.value ? new Date(String(step.firstContentPostingDate.value)) : null,
        allContentPostingDate: step.allContentPostingDate.value ? new Date(String(step.allContentPostingDate.value)) : null,
        allContentReachFixDate: step.allContentReachFixDate.value ? new Date(String(step.allContentReachFixDate.value)) : null,
    }
    ZodParser.parse(schema, mapped, 'Create step2');
    return mapped
}