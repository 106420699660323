import {put} from 'redux-saga/effects'
import {
    BulkInternetActionsErrorAction,
    BulkInternetActionsStartAction,
    BulkInternetActionsSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import InternetResourcesService from "services/internetResourcesService";

export function* bulkInternetActions({ userArr, action }: ReturnType<typeof BulkInternetActionsStartAction>) {
	try {
		yield InternetResourcesService.bulkActions(userArr, action)

		yield put(BulkInternetActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.internet))
		yield put(NotificationOpen('success', 'Обновлено успешно'))

	} catch (error: any) {
		yield put(BulkInternetActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}
}
