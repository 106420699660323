import React from 'react';
import './index.less';

type TResource = {domainLink: string, name: string, image: string}

const ResourceCell = (resource?: TResource) => {
    return (
        <div className='resource-cell'>{resource && resource.name}</div>
    )
};
export default ResourceCell;
