import {axiosRequest} from 'shared/config/axios'
import {NotificationTypes} from 'entities/notification'
import {transformData} from '../lib/transform-data'
import {SendConditionsDto} from 'widgets/modal-edit-notification/lib/dto'

export default class NotificationController {
	static getPrioritiesKey = '/notifications/setting/priorities'
	static getPrioritiesOptionsKey = 'Options: ' + NotificationController.getPrioritiesKey
	static getTemplateKey = (id: string) => `/notifications/${id}/templates`
	static getNotificationIdKey = (id: string) => `/notifications/${id}`
	static getDelayKey = `/notifications/setting/send-notifications-after`

	static async getNotificationId(id: string) {
		const { data } = await axiosRequest<NotificationTypes.Notification>({
			method: 'GET',
			url: `/notifications/${id}`,
		})
		return data
	}

	static async updateNotificationId({ id, data }: { id: string; data: SendConditionsDto }) {
		await axiosRequest({
			method: 'PUT',
			url: `/notifications/${id}`,
			data,
		})
	}

	static async getPriorities() {
		const { data } = await axiosRequest<NotificationTypes.Priorities>({
			method: 'GET',
			url: '/notifications/setting/priorities',
		})
		return data
	}
	static async getPrioritiesOptions() {
		const data = await NotificationController.getPriorities()

		return {
			EMAIL: transformData(data.EMAIL),
			SMS: transformData(data.SMS),
			SYSTEM: transformData(data.SYSTEM),
			TELEGRAM: transformData(data.TELEGRAM),
		}
	}
	static async getTemplate({ id, channelName }: { id: string; channelName?: 'SMS' | 'EMAIL' | 'SYSTEM' | 'TELEGRAM' }) {
		const { data } = await axiosRequest<NotificationTypes.NotificationTemplate[]>({
			method: 'GET',
			url: `/notifications/${id}/templates`,
			params: { channelName },
		})
		return data
	}
	static async updateTemplate({ id, templateId, data }: NotificationTypes.NotificationTemplateRequest) {
		await axiosRequest({
			method: 'PUT',
			url: `/notifications/${id}/templates/${templateId}`,
			data,
		})
	}
	static async getDelay() {
		const { data } = await axiosRequest<string[]>({
			method: 'GET',
			url: `/notifications/setting/send-notifications-after`,
		})
		return data
	}
}
