import { takeLatest } from 'redux-saga/effects'
import { ContentActionTypes } from 'store/content/content/action-types'
import { Types } from 'store/content/list/action-types'
import { Types as SettingsTypes } from 'store/content/settings/action-types'
import { getContentList } from './get-content-list'
import { setFilter } from './set-filter'
import { getProject } from './get-project'
import { getContent } from './get-content'
import { setSortingFilter } from './set-sorting-filter'
import { getContentModuleSections } from './get-content-module-sections'
import {getContentSettingsListWorker} from "./get-settings-list";

export default function* contentSaga() {
	yield takeLatest(Types.SET_CONTENT_FILTER, setFilter)
	yield takeLatest(Types.GET_CONTENT_LIST_STAT, getContentList)

	yield takeLatest(SettingsTypes.GET_LIST, getContentSettingsListWorker)

	yield takeLatest(ContentActionTypes.SET_SORTING_FILTER, setSortingFilter)
	yield takeLatest(ContentActionTypes.GET_PROJECT_START, getProject)
	yield takeLatest(ContentActionTypes.GET_CONTENT_START, getContent)
	yield takeLatest(ContentActionTypes.GET_CONTENT_MODULE_SECTIONS_START, getContentModuleSections)
}
