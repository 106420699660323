import {AppActionTypes} from "./action-types";
import {TFeels, TStatusesFeels} from "../../models/projects/projectFeels";


/**
 * GET_PROJECTS_STATUSES
 */
export const GetFeelsStartAction = (feel: TStatusesFeels, id?: string) => ({
    type: AppActionTypes.GET_FEELS_START,
    feel, id
})
export const GetFeelsSuccessAction = (data: TFeels) => ({
    type: AppActionTypes.GET_FEELS_SUCCESS,
    data
})
export const GetFeelsErrorAction = (error: any) => ({
    type: AppActionTypes.GET_FEELS_ERROR,
    error
})

export const SetPathAction = (path: {title: string, path: string} | null) => ({
    type: AppActionTypes.SET_PATH,
    path
})

export const WritePageNameAction = (page: string | null) => ({
    type: AppActionTypes.WRITE_PAGE_NAME,
    page
})

export const ClearPageNameAction = () => ({
    type: AppActionTypes.CLEAR_PAGE_NAME
})