import {ContentFormatType,} from "../types";
import {useQuery, UseQueryResult} from "react-query";
import {useMemo} from "react";
import {getContentFormatTypes} from "../api";

type onSuccessType = (data: ContentFormatType[]) => void
export function useGetContentFormatType(params?: {isLinkedWithCategory: boolean}, onSuccess?: onSuccessType): UseQueryResult<ContentFormatType[]> {
    return useQuery({
        queryKey: [getContentFormatTypes.key, params],
        queryFn: () => getContentFormatTypes.fetch(params),
        onSuccess,
    });
}
export function useGetContentFormatTypeOptions(params?: {isLinkedWithCategory: boolean}, onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetContentFormatType(params, onSuccess);
    const options = useMemo(() => data.map((el) => ({
        value: el.id,
        label: el.name,
        code: !!el.contentCategory,
    })), [data]);
    return { data: options, ...rest };
}