import React from 'react';
import {Select, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import AppService from "services/appService";
import {transformArrayIdNameForValue} from "utils/transformObjectFeels";
import {IOptions} from "models/feels";
import { SearchContentProjectModel, SearchContentProjectTypes } from 'features/search-project-content';
import {useQuery} from "react-query";
const { Option} = Select;

type TKey = keyof Omit<
    SearchContentProjectTypes.Stores.Status, 'isToggled'>

export const StatusItem = () => {
    const statuses = useQuery({
        queryKey: ['projects/statuses'],
        queryFn: async () => {
            const {data} = await AppService.getStatuses()
            return transformArrayIdNameForValue(data)
        },
        refetchOnWindowFocus: false
    })

    const contentDirection = useQuery({
        queryKey: ['projects/directions'],
        queryFn: async () => {
            const {data} = await AppService.getDirections()
            return transformArrayIdNameForValue(data)
        },
        refetchOnWindowFocus: false
    })

    const isLoading = statuses.isLoading || contentDirection.isLoading;

    return (
        <Spin spinning={isLoading}>
            <div className="item-wrapper">
                <Item options={statuses.data || []} name={'statusIds'} label={'Статус'}/>
                <Item options={contentDirection.data || []} name={'contentDirectionIds'} label={'Направление'}/>
            </div>
        </Spin>
    )
}


type ItemProps = {
    options: IOptions[],
    name: TKey,
    label: string
}
function Item({options, name, label}: ItemProps) {
    const put = useDispatch();
    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main)
    const status = useSelector(SearchContentProjectModel.selectors.status)

    function onSelectChange(value: string[]) {
        put(SearchContentProjectModel.actions.setStatus(typeContent, {[name]: value}))
    }

    return (
        <div className="item">
            <div className="label">{label}</div>
            <div className="form-group">
                <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={true}
                    onChange={onSelectChange}
                    maxTagCount={"responsive"}
                    mode={"multiple"}
                    value={status[typeContent][name]}
                    placeholder={'Искать среди всех'}
                    filterOption={(input, option) => {
                        if (option === undefined || option === null) return false;
                        return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                >
                    {options.map((el) => (
                        <Option
                            key={el.value}
                            value={el.value}
                            data-name={el.label}
                        >
                            {el.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}