import {TUser, TUserCredentials} from "../models/user";
import {AxiosResponse} from "axios";
import $api from "../http";
import {TGenerateKey} from "../models/common";

/**
 * @category Service
 * @class AuthService
 */
export default class AuthService {
    static async login(credentials: TUserCredentials): Promise<AxiosResponse<TUser>> {
        return $api.post<TUser>('/login', credentials)
    }

    static async loginByUser(credentials: TUserCredentials): Promise<AxiosResponse<TUser>> {
        return $api.post<TUser>('/login-by-user', credentials)
    }

    static async generateKey(password: string | null): Promise<AxiosResponse<TGenerateKey>> {
        return $api.post<TGenerateKey>('/users/current/auth-key', {password})
    }

    static async getKey(): Promise<AxiosResponse<TGenerateKey>> {
        return $api.get<TGenerateKey>('/users/current/auth-key')
    }

    static async blockKey(): Promise<AxiosResponse<any>> {
        return $api.put('/users/current/auth-key/block')
    }

    static async passwordRestore(email: string): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: '/password-restore',
            data: email,
            headers: {
                'Content-Type': 'text/plain'
            }
        })
    }

    static setToStorageUser(id: string) {
        localStorage.setItem('user', id);
    }
    static setToStorageTime() {
        const expDate = new Date(new Date().getTime() + (60 * 60 * 1000));
        localStorage.setItem('time', expDate.toString());
    }
    static async logout(): Promise<any>{
        localStorage.removeItem('user');
        localStorage.removeItem('time');
    }
    static async getCurrentUser(): Promise<AxiosResponse<TUser>> {
        return $api.get<TUser>('/current_user')
    }
    static setCookie() {
        setCookieF('cookieUse', 'yes', {secure: true, 'max-age': 3600 * 24 * 14,});
    }
    static getCookie(name: string) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
}
function setCookieF(name: string, value: string, options: any = {}) {
    options = {
        path: '/',
        ...options
    };
    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
        console.log(options)
    }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }
    document.cookie = updatedCookie;
}
