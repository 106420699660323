import {InferValueTypes, T_ID_NAME_CODE_HIDDEN} from '../../models/common'
import * as actions from './actions'
import {ErrorActionState, StartActionState, SuccessActionState} from '../../utils/reducerHalper'
import {Logout} from '../auth/reducer'
import {AuthActionTypes} from '../auth/action-types'
import {SessionsTypeActionTypes} from './action-types'

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TSessionsTypeState = {
	sessionsType: T_ID_NAME_CODE_HIDDEN[] | null
	isLoading: boolean
	error: any
}

export const initialState: TSessionsTypeState = {
	sessionsType: null,
	isLoading: false,
	error: null,
}

export default function reducer(state: TSessionsTypeState = initialState, action: ActionTypes | Logout): TSessionsTypeState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return { ...initialState }

		/** START ACTIONS  */
		case SessionsTypeActionTypes.GET_SESSIONS_TYPE_START:
			return StartActionState(state)

		/** SUCCESS ACTIONS  */
		case SessionsTypeActionTypes.GET_SESSIONS_TYPE_SUCCESS:
			return {...SuccessActionState(state), sessionsType: action.data}

		/** ERROR ACTIONS  */
		case SessionsTypeActionTypes.GET_SESSIONS_TYPE_ERROR:
			return ErrorActionState(state, action.error)
		default:
			return state
	}
}
