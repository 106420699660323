import { FunctionalityInfoType } from '../types'

export const tasksRecords: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка записей задач с поиском, фильтрацией, сортировкой, пагинацией',
		LevelGroupData: [
			{
				name: 'Поиск задач',
				text: 'Для поиска задач по наименованию контента / публикаций, описанию и статьям расходов:',
				points: ['Нажмите "Поиск записей задач"', 'Введите текст'],
				screenshots: ['/images/screenshots/tasks-records/search-task-records.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Фильтрация задач',
				text: 'Используйте фильтры для поиска:',
				points: [
					'Нажмите для перехода в окно фильтрации',
					'Примените фильтры',
					'Нажмите “Найти” для поиска задач',
					'Нажмите "Сбросить фильтры" для сброса неактуальных фильтров',
				],
				important: ['! Нажмите "Сохранить фильтр" для сохранения фильтра'],
				screenshots: ['/images/screenshots/tasks-records/filters-tasks-records.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Сортировка отображения таблицы записей задач',
				text: 'Нажимайте на стрелки для сортировки отображения таблицы записей задач',
				screenshots: ['/images/screenshots//tasks-records/task-sorting.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Настройка количества записей, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице записей задач:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Управление выводом полей',
		LevelGroupData: [
			{
				name: 'Настройка скрытия видимости полей таблицы',
				text: 'Для настройки скрытия видимости полей таблицы:',
				points: ['Нажмите на кнопку', 'Выберите столбцы для скрытия', 'Нажмите "Сохранить"'],
				icons: ['setting-gray'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Добавление новой записи задачи',
		LevelGroupData: [
			{
				name: 'Добавление новой записи задачи',
				points: ['Нажмите "Скачать шаблон"', 'Заполните шаблон', 'Нажмите "Загрузить шаблон"'],
				screenshots: ['/images/screenshots//tasks-records/select-template.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER'],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных записей задач',
		LevelGroupData: [
			{
				name: 'Массовые действия над записями задач',
				text: 'Для массового выполнения действий:',
				points: [
					'Нажмите "Массовые действия"',
					'Выберите массовое действие из выпадающего списка',
					'Выберите задачи',
					'Нажмите "Применить"',
				],
				screenshots: ['/images/screenshots/tasks-records/bulk-actions.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Массовое обновление связи пользователей с доступным списком единиц контента',
				points: [
					'Нажмите на количество  пользователей с доступом к просмотру данных единиц контента проектов в списках для открытия окна "Массовое обновление связи пользователей с доступным списком единиц контента"',
					'Выберите пользователей из списка',
					'Нажмите "Применить"',
				],
				screenshots: ['/images/screenshots/tasks-records/mass-update.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Загрузка контента',
		LevelGroupData: [
			{
				name: 'Добавление файла контента',
				text: 'Нажмите для добавления файла контента',
				screenshots: ['/images/screenshots/tasks-records/add-file.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_MODERATOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Скачивание контента',
				text: 'Нажмите для скачивания контента',
				icons: ['download'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр данных записей задач',
		LevelGroupData: [
			{
				name: 'Просмотр метаданных записей задач',
				text: 'Нажмите для просмотра данных в модальном окне "Метаданные файла"',
				icons: ['file-done'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр контента',
				text: 'Нажмите для просмотра данных в модальном окне "Просмотр контента"',
				icons: ['file-image', 'youtube'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Копирование ссылки на СХД',
				text: 'Нажмите для копирования ссылки на СХД',
				icons: ['link'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр описания контента',
				text: 'Нажмите для просмотра данных в модальном окне "Описание контента"',
				icons: ['message'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Результат проверки',
				text: 'Ознакомьтесь со статусом согласования задач:',
				points: [
					{
						label: 'Данные согласованы',
						icon: 'check-circle'
					},
					{
						label: 'На согласовании',
						icon: 'warning-blue'
					},
					{
						label: 'Редактируется',
						icon: 'minus-circle'
					},
					{
						label: 'Данные отклонены',
						icon: 'close-circle'
					},
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр комментариев по записи задачи',
				text: 'Нажмите для просмотра данных в модальном окне "Комментарии"',
				screenshots: ['/images/screenshots/tasks-records/view-comments.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход в окно просмотра скриншотов в задачах на охват ',
				text: 'Нажимите на скриншот для перехода в модальное окно просмотра скриншотов',
				screenshots: ['/images/screenshots/tasks-records/view-screenshots.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
