import {put} from 'redux-saga/effects'
import {
	GetNotificationsListStartAction,
	SetNotificationFilter
} from "../../../store/options/actions/actions-notifications";

export function* setFilter(_action: ReturnType<typeof SetNotificationFilter>) {
	try {
		yield put(GetNotificationsListStartAction())
	} catch (error: any) {
		console.log(error)
	}
}
