import {ProjectReportingActionTypes} from './action-types'
import {TCreateReporting} from 'models/projects/reportings'
import {TAvailableReportsResponse} from "models/projects/projects-reporting";
import {T_ID_NAME_CODE} from "models/common";
import {ReportTypes} from 'entities/reports'

/**
 * Create PROJECT-REPORTING
 */
export const CreateProjectReportingStartAction = (data: TCreateReporting) => ({
	type: ProjectReportingActionTypes.CREATE_REPORTING_START,
	data,
})
export const CreateProjectReportingSuccessAction = () => ({
	type: ProjectReportingActionTypes.CREATE_REPORTING_SUCCESS,
})
export const CreateProjectReportingErrorAction = (error: any) => ({
	type: ProjectReportingActionTypes.CREATE_REPORTING_ERROR,
	error,
})

/**
 * get all REPORTING
 */
export const GetAllReportingStartAction = () => ({
	type: ProjectReportingActionTypes.GET_ALL_REPORTING_START,
})
export const GetAllReportingSuccessAction = (data: ReportTypes.ReportsResponse | null) => ({
	type: ProjectReportingActionTypes.GET_ALL_REPORTING_SUCCESS,
	data
})
export const GetAllReportingErrorAction = (error: any) => ({
	type: ProjectReportingActionTypes.GET_ALL_REPORTING_ERROR,
	error,
})

/**
 * get all REPORTS Templates
 */
export const GetReportsTemplatesStartAction = () => ({
	type: ProjectReportingActionTypes.GET_REPORTS_TEMPLATES_START,
})
export const GetReportsTemplatesSuccessAction = (data: T_ID_NAME_CODE[] | null) => ({
	type: ProjectReportingActionTypes.GET_REPORTS_TEMPLATES_SUCCESS,
	data
})
export const GetReportsTemplatesErrorAction = (error: any) => ({
	type: ProjectReportingActionTypes.GET_REPORTS_TEMPLATES_ERROR,
	error,
})

/**
 * download REPORTING
 */
export const DownloadReportingStartAction = (id:string, name: string) => ({
	type: ProjectReportingActionTypes.DOWNLOAD_REPORTING_START,
	id, name
})
export const DownloadReportingSuccessAction = () => ({
	type: ProjectReportingActionTypes.DOWNLOAD_REPORTING_SUCCESS,
})
export const DownloadReportingErrorAction = (error: any) => ({
	type: ProjectReportingActionTypes.DOWNLOAD_REPORTING_ERROR,
	error,
})

/**
 * download FileArchive
 */
export const DownloadFileArchiveStartAction = (projectId: string) => ({
	type: ProjectReportingActionTypes.DOWNLOAD_FILE_ARCHIVE_START,
	projectId
})
export const DownloadFileArchiveSuccessAction = () => ({
	type: ProjectReportingActionTypes.DOWNLOAD_FILE_ARCHIVE_SUCCESS,
})
export const DownloadFileArchiveErrorAction = (error: any) => ({
	type: ProjectReportingActionTypes.DOWNLOAD_FILE_ARCHIVE_ERROR,
	error,
})

/**
 * Получение списка отчетов проекта доступных для формирования
 */
export const GetAvailableReportsStartAction = (typeReports: string[], id?:string) => ({
	type: ProjectReportingActionTypes.GET_AVAILABLE_REPORTS_START,
	id,
	typeReports
})
export const GetAvailableReportsSuccessAction = (data: TAvailableReportsResponse | null) => ({
	type: ProjectReportingActionTypes.GET_AVAILABLE_REPORTS_SUCCESS,
	data
})
export const GetAvailableReportsErrorAction = (error: any) => ({
	type: ProjectReportingActionTypes.GET_AVAILABLE_REPORTS_ERROR,
	error,
})

/**
 * Изменение фильтра
 */
export const SetReportingFilter = (filter: ReportTypes.Filter) => ({
	type: ProjectReportingActionTypes.SET_REPORTING_FILTER,
	filter,
})

/**
 * Сброс фильтра для 'reports/project'
 */
export const SetReportingClearFilter = () => ({
	type: ProjectReportingActionTypes.SET_REPORTING_CLEAR_FILTER
})
