import {put, select, delay, call} from "redux-saga/effects";
import {activateFilter} from "../../store/actions";
import {activeFilterId} from "../../store/selectors";
import {TRole} from "models/user";
import {selectRole} from "store/auth/selectors";
import {projectWorker} from "./project/project-worker";
import {Logger} from "shared/services";
import {tasksProjects} from "./tasks-records/tasks-projects";
import {tasksRecords} from "./tasks-records/tasks-records";
import {tasks} from "./tasks";
import {taskContent} from "./task/task-content";
import {taskReach} from "./task/task-reach";
import {taskCopyright} from "./task/task-copyright";
import {taskPosting} from "./task/task-posting";
import {taskEstimate} from "./task/task-estimate";
import {users} from "./users/users";

export function* activateFilterWorker({filterType, filterData, needToMakeRequest}: ReturnType<typeof activateFilter>) {
    try {
        const role: TRole = yield select(selectRole);
        if (!role) {
            yield delay(500)
            yield put(activateFilter(filterType, filterData))
        }
        const filterId: string | undefined = yield select(activeFilterId(filterType));
        if (['PROJECTS', 'PROJECTS_CALENDAR', 'PROJECTS_CONTENT'].includes(filterType)) {
            yield call(
                projectWorker,
                {role, filterData, needToMakeRequest, filterId, filterType}
            )
        }
        if (filterType === 'TASKS') {
            yield call(tasksProjects, filterData, filterId)
        }
        if (filterType === 'TASKS_RECORDS') {
            yield  call(tasksRecords, filterData, filterId)
        }
        if (filterType === 'PROJECTS_KANBAN') {
            yield  call(tasks, filterData, filterId)
        }
        if (filterType === 'CONTENT_TASK') {
            yield  call(taskContent, filterData, filterId)
        }
        if (filterType === 'REACH_TASK') {
            yield  call(taskReach, filterData, filterId)
        }
        if (filterType === 'COPYRIGHT_TASK') {
            yield  call(taskCopyright, filterData, filterId)
        }
        if (filterType === 'POSTING_TASK') {
            yield  call(taskPosting, filterData, filterId)
        }
        if (filterType === 'ESTIMATE_TASK') {
            yield  call(taskEstimate, filterData, filterId)
        }
        if (filterType === 'USERS_SEARCH') {
            yield  call(users, filterData, filterId)
        }
    } catch (error) {
        Logger.error(error)
    }
}