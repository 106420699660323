import React from 'react';
import './contacts-cell.less';

const ContactsCell = (contacts: any, _data: any) => {
    const isEmail = 'email' in contacts[0]
    return (
        <div className='contacts-cell'>
            {contacts?.map((it: any, i: number)=>{
                const value = isEmail ? it.email : it.phone
                return (
                    <div key={i.toString() + value} className={`contacts-cell__item ${it.isMain && 'contacts-cell__general'}`}>
                        {value}
                    </div>
                )
            })}
        </div>
    )
};
export default ContactsCell;
