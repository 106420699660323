import React from 'react';
import styles from './user-guide.module.less';
import {AuditOutlined, FileTextOutlined, ReadOutlined, VideoCameraOutlined} from "@ant-design/icons";
import WhetherTheUser from "components/whetherTheUser";
import {useSelector} from "react-redux";
import {selectRole} from "store/auth/selectors";
import {UserTypes} from 'entities/user'

const UserGuide = () => {
    const role = useSelector(selectRole)

    const pathFullInstruction = getPathFullInstruction(role)
    const pathPresentation = getPathPresentation(role)
    const pathCheckList = getPathCheckList(role)

    return (
        <>
            <WhetherTheUser role={['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_VIEWER', 'ROLE_PROJECT_MANAGER', 'ROLE_CONTENT_EDITOR']} isCan={false}>
                <>
                    <hr />
                    <p className={styles.text}>
                        Руководство пользователя
                    </p>
                </>
            </WhetherTheUser>
            <div className={styles.user_guide}>
                <WhetherTheUser role={['ROLE_CONTRACTOR']} isCan={true}>
                    <div className={styles.item}>
                        <a href="/docs/short-instruction/подрядчик.pdf" rel="noreferrer" target="_blank">
                            <FileTextOutlined />
                            <span>Краткая инструкция</span>
                        </a>
                    </div>
                </WhetherTheUser>
                {pathFullInstruction && (
                    <div className={styles.item}>
                        <a href={pathFullInstruction} rel="noreferrer" target="_blank">
                            <ReadOutlined/>
                            <span>Полная инструкция</span>
                        </a>
                    </div>
                )}
                {pathCheckList && (
                    <div className={styles.item}>
                        <a href={pathCheckList} rel="noreferrer" target="_blank">
                            <AuditOutlined/>
                            <span>Чек-лист действий</span>
                        </a>
                    </div>
                )}
                {pathPresentation && (
                    <div className={styles.item}>
                        <a href={pathPresentation} rel="noreferrer" target="_blank">
                            <VideoCameraOutlined/>
                            <span>Презентация</span>
                        </a>
                    </div>
                )}
            </div>
        </>

    );
};

function getPathPresentation(role?: UserTypes.RoleName) {
    switch (role) {
        case 'ROLE_PROJECT_MANAGER':
            return "/docs/presentation/менеджер_проекта.pdf"
        case 'ROLE_CONTRACTOR':
            return "/docs/presentation/подрядчик.pdf"
        case 'ROLE_PRODUCER':
            return "/docs/presentation/продюсер.pdf"
        case 'ROLE_COORDINATOR':
            return "/docs/presentation/координатор.pdf"
        case 'ROLE_MODERATOR':
            return "/docs/presentation/модератор.pdf"
        case 'ROLE_CONTENT_EDITOR':
            return "/docs/presentation/редактор_каталога.pdf"
        case 'ROLE_ANALYST':
            return "/docs/presentation/аналитик.pdf"
        case 'ROLE_FINANCIER':
            return "/docs/presentation/финансист.pdf"
        case 'ROLE_LAWYER':
            return "/docs/presentation/юрист.pdf"
        case 'ROLE_AGENT':
            return "/docs/presentation/агент.pdf"
        case 'ROLE_CLIENT':
            return "/docs/presentation/клиент.pdf"
        case 'ROLE_VIEWER':
            return "/docs/presentation/наблюдатель.pdf"
        case 'ROLE_ADMIN_IS':
            return "/docs/presentation/админ_иб.pdf"
        case 'ROLE_ADMIN':
            return "/docs/presentation/админ_иб.pdf"
        default: return null
    }
}

function getPathFullInstruction(role?: UserTypes.RoleName) {
    switch (role) {
        case "ROLE_CLIENT":
            return '/docs/full-instruction/клиент.pdf'
        case "ROLE_AGENT":
            return '/docs/full-instruction/агент.pdf'
        case "ROLE_MODERATOR":
            return '/docs/full-instruction/редактор_каталога.pdf'
        case "ROLE_CONTRACTOR":
            return '/docs/full-instruction/подрядчик.pdf'
        case "ROLE_COORDINATOR":
            return '/docs/full-instruction/координатор.pdf'
        default: return null
    }
}

function getPathCheckList(role?: UserTypes.RoleName) {
    switch (role) {
        case "ROLE_ANALYST":
            return '/docs/check-list/аналитик.pdf'
        case "ROLE_FINANCIER":
            return '/docs/check-list/финансист.pdf'
        case "ROLE_PRODUCER":
            return '/docs/check-list/продюсер.pdf'
        case "ROLE_LAWYER":
            return '/docs/check-list/юрист.pdf'
        case "ROLE_COORDINATOR":
            return '/docs/check-list/координатор.pdf'
        default: return null
    }
}

export default UserGuide;