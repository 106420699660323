import React, {FC} from 'react';
import './index.less'
import {Button, Modal} from "antd";
import useHandlerModal from "hooks/handlModal";
import {LogsLogoutAction} from "store/logs/actions";
import env from 'shared/config/env';

type Props = {
    status: boolean
}
const ModalLogout: FC<Props> = ({status}) => {
    const {isVisible, closeModal, put} = useHandlerModal(status);
    const onLogout = () => {
        put(LogsLogoutAction())
        closeModal()
    }

    return (
        <Modal
            className='ModalLogout'
            title='Подтвердите действие'
            visible={isVisible}
            footer={(
                <>
                    <Button type={"default"} onClick={closeModal}>Отменить</Button>
                    {env.keycloackRedirectUrl && (
                        <Button type={'primary'}>
                            <a href={env.keycloackRedirectUrl}>Выбрать другую систему</a>
                        </Button>
                    )}
                    <Button type={"primary"} onClick={onLogout}>Подтвердить</Button>
                </>
            )}
            maskClosable={true}
            onCancel={closeModal}
        >
            <div className='ModalLogout__content'>
                <p>Завершить работу в системе?</p>
            </div>
        </Modal>
    )
};
export default ModalLogout;