import React, {FC, useEffect} from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {useDispatch} from "react-redux";
import {GetCurrentUser} from "store/auth/actions";
import Connect from "components/connect";

const KeycloakChecker: FC<{children: React.ReactNode}> = ({children}) => {
    const {keycloak, initialized} = useKeycloak();
    const put = useDispatch()
    const url = new URL(window.location.href).pathname;
    useEffect(() => {
        if (!keycloak.authenticated && initialized && url !== '/error') {
            keycloak.login();
        }
    }, [initialized, keycloak])// eslint-disable-line

    useEffect(() => {
        if (keycloak.idTokenParsed) {

            put(GetCurrentUser())
        }
    }, [keycloak.idTokenParsed]);//eslint-disable-line


    if (!keycloak.authenticated && url !== '/error') return <Connect/>
    return (<>{children}</>)
};

export default KeycloakChecker;