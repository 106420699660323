import {ActionTypes} from "./types";
import {TCommentsSettingsState} from "./reducer";
import {TaskTypes} from 'entities/task'

export const Get = () => ({
    type: ActionTypes.GET_COMMENTS
})
export const Create = (data: TaskTypes.CommentsRequest) => ({
    type: ActionTypes.CREATE_COMMENTS, data
})
export const Update = (id: string, data: TaskTypes.CommentsRequest) => ({
    type: ActionTypes.UPDATE_COMMENTS, id, data
})
export const Delete = (id: string, taskId: string) => ({
    type: ActionTypes.DELETE_COMMENTS, id, taskId
})
export const SetInfo = <T extends Partial<TCommentsSettingsState>>(value: T) => ({
    type: ActionTypes.SET_INFO, value
})
