import {ActionTypes} from './types'
import {InferValueTypes} from "models/common";
import * as actions from "./actions";
import { ExtendedSearchTypes } from 'features/extended-search';
type ActionsTypes = ReturnType<InferValueTypes<typeof actions>>;

const initialState: ExtendedSearchTypes.Stores.TChannelsState = {
    content: {
        isToggled: false,
        fields: [{field: 'Параметр неважен', isAndOrBesides: false, values: []}]
    },
    projects: {
        isToggled: false,
        fields: [{field: 'Параметр неважен', isAndOrBesides: false, values: []}]
    },
    calendar: {
        isToggled: false,
        fields: [{field: 'Параметр неважен', isAndOrBesides: false, values: []}]
    },
}

export default function reducer(
    state: ExtendedSearchTypes.Stores.TChannelsState = initialState,
    action: ActionsTypes
): ExtendedSearchTypes.Stores.TChannelsState {
    switch (action.type) {
        case ActionTypes.SET_VALUE:
            return {
                ...state,
                [action.typeContent]: {
                    isToggled: action.isToggled,
                    fields: action.value
                }
            }
        case ActionTypes.CLEAR_VALUE:
            return {
                ...state,
                [action.typeContent]: {
                    isToggled: false,
                    fields: [{field: 'Параметр неважен', isAndOrBesides: false, values: []}]
                }
            }
        default:
            return state
    }
}