import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import styles from './styles.module.less'
import {
	analyticsCoverageData,
	analyticsIndicators,
	analyticsSummaryData,
	calendar,
	content,
	contentInfo,
	optionsActionsUsers,
	optionsHandbook,
	optionsList,
	optionsNotifications,
	optionsPersonalSettings,
	projectCalendar,
	projectDocuments,
	projectParams,
	projectReporting,
	projects,
	projectTasks,
	reportingReports,
	reportingReportsSchedulers,
	task,
	tasksProjects,
	tasksRecords,
	tasksSettings,
} from '../lib'
import {Collapse, Empty} from 'antd'
import {FunctionalityInfoType} from '../types'
import Icon from 'shared/ui/icon'
import {useSelector} from 'react-redux'
import {selectRole} from 'store/auth/selectors'

const { Panel } = Collapse
const FunctionalityInfo = () => {
	const userRole = useSelector(selectRole)
	const [data, setData] = useState<FunctionalityInfoType>([])
	const location = useLocation()
	const page = location.pathname.split('/')[1]
	const typePage = location.pathname.split('/')[2]

	useEffect(() => {
		setData([])
		if (page === 'calendar' && !typePage) setData(calendar)
		if (page === 'projects' && !typePage) setData(projects)
		if (page === 'content' && !typePage) setData(content)
		if (page === 'content' && typePage === 'info') setData(contentInfo)
		if (page === 'tasks' && typePage === 'projects') setData(tasksProjects)
		if (page === 'tasks' && typePage === 'records') setData(tasksRecords)
		if (page === 'tasks' && typePage === 'settings') setData(tasksSettings)
		if (page === 'analytics' && typePage === 'indicators') setData(analyticsIndicators)
		if (page === 'analytics' && typePage === 'summary-data') setData(analyticsSummaryData)
		if (page === 'analytics' && typePage === 'coverage-data') setData(analyticsCoverageData)
		if (page === 'reporting' && typePage === 'reports') setData(reportingReports)
		if (page === 'reporting' && typePage === 'reports-schedulers') setData(reportingReportsSchedulers)
		if (page === 'reporting' && typePage === 'reports-schedulers') setData(reportingReportsSchedulers)
		if (page === 'options' && typePage === 'list') setData(optionsList)
		if (page === 'options' && typePage === 'actions-users') setData(optionsActionsUsers)
		if (page === 'options' && typePage === 'notifications') setData(optionsNotifications)
		if (page === 'options' && typePage === 'handbook') setData(optionsHandbook)
		if (page === 'options' && typePage === 'personal-settings') setData(optionsPersonalSettings)
		if (page === 'project' && typePage === 'params') setData(projectParams)
		if (page === 'project' && typePage === 'calendar') setData(projectCalendar)
		if (page === 'project' && typePage === 'tasks') setData(projectTasks)
		if (page === 'project' && typePage === 'reporting') setData(projectReporting)
		if (page === 'project' && typePage === 'documents') setData(projectDocuments)
		if (page === 'task' && !typePage) setData(task)
	}, [page, typePage])

	if (!data.length) return <Empty />

	return (
		<div className={styles.functionality_info}>
			<Collapse>
				{data.map((group, index) => (
					<Panel header={group.LevelGroupName} key={index}>
						<Collapse className={styles.second_collapse}>
							{group.LevelGroupData.map((subGroup, subIndex) => (
								<React.Fragment key={`Fragment-${index}-${subIndex}`}>
									{userRole && subGroup.roles.includes(userRole) && (
										<Panel header={subGroup.name} key={`${index}-${subIndex}`}>
											<>
												{subGroup?.text && <div className={styles.text}>{subGroup.text}</div>}
												<ol>
													{subGroup?.points?.length &&
														subGroup?.points.map((point) => {
															if (typeof point === 'string') {
																return <li key={point}>{point}</li>
															} else {
																return (
																	<div key={point.label} className={styles.point}>
																		<li>{point.label}</li>
																		<Icon.Other name={point.icon} />
																	</div>
																)
															}
														})}
												</ol>
												{subGroup?.important?.length &&
													subGroup?.important?.map((text) => (
														<div key={text} className={styles.important}>
															{text}
														</div>
													))}
												{subGroup.topIcons?.map((name) => (
													<div key={name} className={styles.icons}>
														<Icon.Other name={name} />
													</div>
												))}
												{subGroup.screenshots?.map((screenshot, screenshotIndex) => (
													<div key={screenshotIndex + screenshot} className={styles.img_wrapper}>
														<img
															className={styles.img}
															src={window.location.origin + screenshot}
															alt={`Screenshot ${screenshotIndex + 1}`}
														/>
													</div>
												))}
												{subGroup.icons?.map((name) => (
													<div key={name} className={styles.icons}>
														<Icon.Other name={name} />
													</div>
												))}
											</>
										</Panel>
									)}
								</React.Fragment>
							))}
						</Collapse>
					</Panel>
				))}
			</Collapse>
		</div>
	)
}

export default FunctionalityInfo
