import React from 'react';
import { Radio, RadioChangeEvent, Select } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import './index.less';
import {ExtendedSearchModel, ExtendedSearchTypes} from 'features/extended-search/index';
import {IOptions} from "models/feels";
import { useQuery } from 'react-query';
import UsersService from 'services/usersService';
const {Option} = Select;

type TKey = keyof Omit<
    ExtendedSearchTypes.Stores.AgreementOfProjectTaskRecords, 'isToggled'>

const options: ExtendedSearchTypes.Stores.AgreementOfProjectTaskRecordsOptions = {
    isNotVotedRecordsExists: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Требуется проверка', value: 'true'},
        {label: 'Не требуется проверка', value: 'false'},
    ],
}
export const AgreementOfProjectTaskRecordsItem = () => {

    return (
        <div className="item-wrapper Indicators">
            <Item
                options={options.isNotVotedRecordsExists}
                label={'Проверка записей задач'}
                name={'isNotVotedRecordsExists'}
            />
            <SecondItem/>
        </div>
    )
}
type ItemProps = {
    options: IOptions[],
    name: TKey,
    label: string
}
function Item({options, name, label}: ItemProps) {
    const put = useDispatch();
    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const value = useSelector(ExtendedSearchModel.selectors.agreementOfProjectTaskRecords)
    const onGroupChange = (e: RadioChangeEvent) => {
        put(ExtendedSearchModel.actions.setAgreementOfProjectTaskRecords(typeContent, {[name]: e.target.value}))
    }
    return (
        <div className="item">
            <div className="label">{label}</div>
            <div className="form-group form-group--radio">
                <Radio.Group
                    value={value[typeContent][name]}
                    onChange={onGroupChange}
                >
                    {options.map((option: IOptions) => {
                        return (
                            <Radio
                                checked={value[typeContent][name] === (option.value)}
                                key={option.label}
                                value={option.value}
                            >
                                {option.label}
                            </Radio>
                        )
                    })}
                </Radio.Group>
            </div>
        </div>
    )
}
function SecondItem() {
    const put = useDispatch();
    const {data, isLoading} = useQuery({
        queryKey: [UsersService.getRelatedUsersKey],
        queryFn: async () => {
            const {data} = await UsersService.getRelatedUsers();
            return data.map((el) => ({
                value: el.id,
                label: `${el.lastName || ''} ${el.firstName || ''} ${el.middleName || ''}`
            } as IOptions))
        }
    })
    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const values = useSelector(ExtendedSearchModel.selectors.agreementOfProjectTaskRecords)
    function onSelectChange(value: string[]) {
        put(ExtendedSearchModel.actions.setValueAgreementOfProjectTaskRecords(typeContent, {
            notVotedRecordsInspectorUserIds: value
        }))
    }

    return (
      <div className="item">
          <div className="label">Проверяющий пользователь</div>
          <div className="form-group">
              <Select
                loading={isLoading}
                getPopupContainer={trigger => trigger.parentNode}
                allowClear={true}
                onChange={onSelectChange}
                maxTagCount={"responsive"}
                mode={"multiple"}
                disabled={values[typeContent]['isNotVotedRecordsExists'] === 'Все проекты'}
                value={values[typeContent]['notVotedRecordsInspectorUserIds']}
                placeholder={'Искать среди всех'}
                filterOption={(input, option) => {
                    if (option === undefined || option === null) return false;
                    return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
              >
                  {data && data.map((el) => (
                    <Option
                      key={el.value}
                      value={el.value}
                      data-name={el.label}
                    >
                        {el.label}
                    </Option>
                  ))}
              </Select>
          </div>
      </div>
    )
}