import { ZodError, ZodSchema } from 'zod';
import { Logger } from 'shared/services';
import env, {TMode} from "shared/config/env";

class ZodParserInstance {
	private mode: TMode = env.mode;
	private showError(data: any, error: ZodError, info?: string) {
		Logger.group({ collapsed: true, label: `Parse error ${info ? info : ''}` })
			.log('Не валидные данные')
			.group({ collapsed: true, label: 'zod error' })
			.log(data)
			.log(error.errors)
			.groupEnd()
			.groupEnd();
	}
	parse(schema: ZodSchema, data: any, info?: string) {
		if (this.mode === 'prod') {
			return;
		}
		try {
			schema.parse(data, {});
		} catch (error: ZodError | any) {
			this.showError(data, error, info);
		}
	}
	async parseAsync(schema: ZodSchema, data: any[], info?: string) {
		if (this.mode === 'prod') {
			return;
		}
		try {
			await schema.parseAsync(data);
		} catch (error: ZodError | any) {
			this.showError(data, error, info);
		}
	}
}
const ZodParser = new ZodParserInstance();
export default ZodParser