import { combineReducers } from 'redux'

import usersReducer from './users-reducer'
import usersActionsReducer from './users-actions-reducer'
import handbookReducer from './users-handbook-reducer'
import optionsNotificationsReducer from "./notifications-reducer";
import personalSettingReducer from "./personal-setting-reducer";

const reducer = combineReducers({
	users: usersReducer,
	usersActions: usersActionsReducer,
	handbook: handbookReducer,
	notifications: optionsNotificationsReducer,
	setting: personalSettingReducer,

})

export type TOptionsState = ReturnType<typeof reducer>

export default reducer
