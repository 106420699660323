import {AxiosResponse} from "axios";
import $api from "../http";
import {THandbookContentResponse, THandbookFilter, TNationalTag, TTagsResponse} from "../models/handbook";
import {T_ID_NAME_CODE_HIDDEN} from "../models/common";

export default class NationalProjectTag1Service {
    /**
     * Получение всех тегох 1-го уровня национальных проектов
     */
    static async getNationalProjectTag1(): Promise<AxiosResponse<T_ID_NAME_CODE_HIDDEN[]>> {
        return $api.get('/national-project-tagging/first-lvl-tags')
    }
    /**
     * Получение всех тегох 1-го уровня национальных проектов c filters
     */
    static nationalProjectTag1Key = '/national-project-tagging/first-lvl-tags'
    static async getNationalProjectTag1WithFilter(data: THandbookFilter): Promise<AxiosResponse<THandbookContentResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<TTagsResponse>('/national-project-tagging/first-lvl-tags/search', filter)
    }
    /**
     *  Добавление тега 1-го уровня национальных проектов
     */
    static async addINationalProjectTag1Record( data: TNationalTag): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/national-project-tagging/first-lvl-tags`, data)
    }
    /**
     *   Обновление тега 1-го уровня национальных проектов
     */
    static async updateNationalProjectTag1Record( data: TNationalTag, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/national-project-tagging/first-lvl-tags/${id}`, data)
    }
    /**
     *   Удаление тега 1-го уровня национальных проектов
     */
    static async deleteNationalProjectTag1Record(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/national-project-tagging/first-lvl-tags/${id}`)
    }
    /**
     *   Массовые действия тега 1-го уровня национальных проектов
     */
    static async bulkNationalProjectTag1Actions(recordsArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/national-project-tagging/first-lvl-tags/bulk-actions/${action}`, recordsArr)
    }
    /**
     *   Выгрузка данных тега 1-го уровня национальных проектов
     */
    static async downloadNationalProjectTag1(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/national-project-tagging/first-lvl-tags/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }
    /**
     * Выполнение импорта данных тега 1-го уровня национальных проектов из заполненного шаблона в формате Excel
     */
    static async importNationalProjectTag1 (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/national-project-tagging/first-lvl-tags/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    /**
     *   Выгрузка шаблона
     */
    static async downloadNationalProjectTag1Template(): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/national-project-tagging/first-lvl-tags/import/template',
            responseType:'arraybuffer',
        })
    }
}



