import {put, select} from "redux-saga/effects";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {
    UpdateDuplicateScheduleTasksErrorAction,
    UpdateDuplicateScheduleTasksStartAction,
    UpdateDuplicateScheduleTasksSuccessAction,
} from "store/tasks/actions/tasks";
import TasksService from "services/tasksService";
import {GetTasksKanbanStartAction} from "store/tasks/actions/gant-kanban";
import {selectParams} from "store/selectors";


export function* updateDuplicateScheduleTasks({scheduleId, data, callback}: ReturnType<typeof UpdateDuplicateScheduleTasksStartAction>) {
    try {
        const { id } = yield select(selectParams)

        yield TasksService.updateDuplicateSchedulersTasks(scheduleId, data)
        yield put(UpdateDuplicateScheduleTasksSuccessAction())
        yield put(NotificationOpen('success', 'Успешно', ''))
        const url = new URL(window.location.href).pathname;
        if (url === '/project/tasks') {
            yield put(GetTasksKanbanStartAction(id))
        }

        if(callback) yield callback()

    } catch (error: any) {
        yield put(UpdateDuplicateScheduleTasksErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))

    }
}
