import { ExtendedSearchModel, ExtendedSearchTypes } from "features/extended-search";
import {all, put} from "redux-saga/effects";
import {Logger} from "shared/services";
import {getTypeContent} from "features/user-filters/lib";
import {Types} from "features/user-filters/types";
type Props = {
    filterType: Types["code"]
    filterData: string,
    needToMakeRequest: boolean,
    filterId?: string
}
export function* extendedSearchWorker({needToMakeRequest, filterType, filterData, filterId}: Props) {
    try {
        const typeContent = getTypeContent(filterType);

        if (filterId) {
            const filter: ExtendedSearchTypes.Stores.TAllFilterValues = JSON.parse(filterData);
            yield all([
                put(ExtendedSearchModel.actions.setValuesProject(
                    typeContent,
                    filter.project,
                    [
                        !!filter.project.contractNumbers.length,
                        !!filter.project.codes.length,
                        !!filter.project.requestNumbers.length,
                        !!filter.project.igks.length,
                    ].some(value => value)
                )),
                put(ExtendedSearchModel.actions.setValueAgreementOfProjectTaskRecords(
                  typeContent,
                  {
                      isNotVotedRecordsExists: filter.agreementOfProjectTaskRecords?.isNotVotedRecordsExists !== undefined ?
                        `${filter.agreementOfProjectTaskRecords.isNotVotedRecordsExists}` : 'Все проекты',
                      notVotedRecordsInspectorUserIds: filter.agreementOfProjectTaskRecords?.notVotedRecordsInspectorUserIds || []
                  },
                  [
                    filter.agreementOfProjectTaskRecords?.isNotVotedRecordsExists !== undefined,
                    !!filter.agreementOfProjectTaskRecords?.notVotedRecordsInspectorUserIds?.length,
                  ].some(Boolean)
                )),
                put(ExtendedSearchModel.actions.setValuesOrganizations(typeContent, filter.organizations, [
                    !!filter.organizations.organizationIds?.length,
                    !!filter.organizations.legalFormIds?.length,
                ].some(Boolean))),
                put(ExtendedSearchModel.actions.setValuesContests(typeContent, {
                    years: filter.contests.years.map((el) => `${el}`),
                    sessionIds: filter.contests.sessionIds,
                    contestIds: filter.contests.contestIds
                }, [
                    !!filter.contests.years.length,
                    !!filter.contests.sessionIds.length,
                    !!filter.contests.contestIds.length
                ].some(value => value))),
                put(ExtendedSearchModel.actions.setValuesStatus(typeContent, filter.status,
                    [
                        !!filter.status.statusIds?.length,
                        !!filter.status.contentDirectionIds?.length,
                        !!filter.status.contentEditorLabelIds?.length,
                        !!filter.status.moderatorLabelIds?.length,
                    ].some(value => value)
                )),
                put(ExtendedSearchModel.actions.setValuesIndicators(typeContent, {
                    advancePayment: filter.indicators.advancePayment !== undefined ? `${filter.indicators.advancePayment}` : 'Все проекты',
                    isContentCreationProjectType: filter.indicators.isContentCreationProjectType !== undefined ? `${filter.indicators.isContentCreationProjectType}` : 'Все проекты',
                    isNationalProject: filter.indicators.isNationalProject !== undefined ? `${filter.indicators.isNationalProject}` : 'Все проекты',
                    isDataChecked: filter.indicators.isDataChecked !== undefined ? `${filter.indicators.isDataChecked}` : 'Все проекты',
                    isFamousPeopleRemarked: filter.indicators.isFamousPeopleRemarked !== undefined ? `${filter.indicators.isFamousPeopleRemarked}` : 'Все проекты',
                    isProjectPosted: filter.indicators.isProjectPosted !== undefined ? `${filter.indicators.isProjectPosted}` : 'Все проекты',
                    isPrivateProject: filter.indicators.isPrivateProject !== undefined ? `${filter.indicators.isPrivateProject}` : 'Все проекты',
                    isTaskOverdueExist: filter.indicators.isTaskOverdueExist !== undefined ? `${filter.indicators.isTaskOverdueExist}` : 'Все проекты',
                    isFavoriteProject: filter.indicators.isFavoriteProject !== undefined ? `${filter.indicators.isFavoriteProject}` : 'Все проекты',
                }, [
                    filter.indicators.advancePayment !== undefined,
                    filter.indicators.isContentCreationProjectType !== undefined,
                    filter.indicators.isNationalProject !== undefined,
                    filter.indicators.isDataChecked !== undefined,
                    filter.indicators.isFamousPeopleRemarked !== undefined,
                    filter.indicators.isProjectPosted !== undefined,
                    filter.indicators.isPrivateProject !== undefined,
                    filter.indicators.isTaskOverdueExist !== undefined,
                    filter.indicators.isFavoriteProject !== undefined,
                ].some(value => value))),
                put(ExtendedSearchModel.actions.setValuesIndicatorsContent(typeContent, {
                    isContent: filter.indicatorsContent?.isContent !== undefined ? `${filter.indicatorsContent?.isContent}` : 'Все проекты',
                    isAccountedContent: filter.indicatorsContent?.isAccountedContent !== undefined ? `${filter.indicatorsContent?.isAccountedContent}` : 'Все проекты',
                    isMasterContent: filter.indicatorsContent?.isMasterContent !== undefined ? `${filter.indicatorsContent?.isMasterContent}` : 'Все проекты',
                    contentStatusCodes: filter.indicatorsContent?.contentStatusCodes || []
                }, [
                    filter.indicatorsContent?.isContent !== undefined,
                    filter.indicatorsContent?.isAccountedContent !== undefined,
                    filter.indicatorsContent?.isMasterContent !== undefined,
                    !!filter.indicatorsContent?.contentStatusCodes?.length,
                ].some(value => value))),
                put(ExtendedSearchModel.actions.setThematics(typeContent, filter.thematics, !!filter.thematics.length)),
                put(ExtendedSearchModel.actions.setAwards(typeContent, filter.awards || [], !!filter.awards?.length)),
                put(ExtendedSearchModel.actions.setCategoriesGenres(typeContent, filter.categoriesGenres, !!filter.categoriesGenres.length)),
                put(ExtendedSearchModel.actions.setRegionals(typeContent, filter.regionals, !!filter.regionals.length)),
                put(ExtendedSearchModel.actions.setContent(typeContent, filter.content, !!filter.content.length)),
                put(ExtendedSearchModel.actions.setChannels(typeContent, filter.channels, !!filter.channels.length)),
                put(ExtendedSearchModel.actions.setUsers(typeContent, filter.users, !!filter.users.length)),
                put(ExtendedSearchModel.actions.setFamousPeople(typeContent, filter.famousPeople, !!filter.famousPeople.length)),
                put(ExtendedSearchModel.actions.setDates(typeContent, filter.dates, !!filter.dates.length)),
                put(ExtendedSearchModel.actions.setCounts(typeContent, filter.counts, !!filter.counts.length)),
            ])
        } else {
            yield all([
                put(ExtendedSearchModel.actions.clearProject(typeContent)),
                put(ExtendedSearchModel.actions.clearOrganizations(typeContent)),
                put(ExtendedSearchModel.actions.clearContests(typeContent)),
                put(ExtendedSearchModel.actions.clearStatus(typeContent)),
                put(ExtendedSearchModel.actions.clearIndicators(typeContent)),
                put(ExtendedSearchModel.actions.clearIndicatorsContent(typeContent)),
                put(ExtendedSearchModel.actions.clearAwards(typeContent)),
                put(ExtendedSearchModel.actions.clearThematics(typeContent)),
                put(ExtendedSearchModel.actions.clearCategoriesGenres(typeContent)),
                put(ExtendedSearchModel.actions.clearRegionals(typeContent)),
                put(ExtendedSearchModel.actions.clearContent(typeContent)),
                put(ExtendedSearchModel.actions.clearChannels(typeContent)),
                put(ExtendedSearchModel.actions.clearUsers(typeContent)),
                put(ExtendedSearchModel.actions.clearFamousPeople(typeContent)),
                put(ExtendedSearchModel.actions.clearDates(typeContent)),
                put(ExtendedSearchModel.actions.clearCounts(typeContent)),
            ])
        }
        if (needToMakeRequest) {
            yield put(ExtendedSearchModel.actions.FetchCF(typeContent))
        }

    } catch (error) {
        Logger.error(error)
    }
}