import {
	GetActionsUsersErrorAction,
	GetActionsUsersStartAction,
	GetActionsUsersSuccessAction,
	SetActionsUsersFilter,
} from 'store/options/actions/actions-actions-users'
import {put, select} from 'redux-saga/effects'
import {TUserOptionsFilter} from 'models/user'
import {selectActionsUsersFilter} from 'store/options/selectors/selectors-actions-users'
import UsersActionsService from "services/usersActionsService";

export default function* getActionsUsers(_: ReturnType<typeof GetActionsUsersStartAction>) {
	try {
		const filter: TUserOptionsFilter = yield select(selectActionsUsersFilter)
		const { data } = yield UsersActionsService.getActionsUsers( filter)
		if (data.totalCount && data.page * data.pageSize >= data.totalCount && data.totalCount !== 0) {
			yield put(SetActionsUsersFilter({...filter, page: 1}))
		} else {
			yield put(GetActionsUsersSuccessAction(data))
		}
	} catch (error: any) {
		yield put(GetActionsUsersErrorAction(error))
	}
}
