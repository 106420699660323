import {axiosRequest} from "shared/config/axios";
import {MassTasksContentRecords, RelatedUser} from "../types";
export class ContentTaskRecordController {
    static getUsersContentRecordsKey = (recordId: string) => `/tasks/content/records/${recordId}/content-users`
    static async updateTasksContentRecords (data: MassTasksContentRecords) {
        await axiosRequest({
            method: 'PUT',
            url: `/tasks/content/records/content-users`,
            data,
        });
    }
    static async getUsersContentRecords(recordId: string){
       const {data} = await axiosRequest<RelatedUser[]>({
           method: 'GET',
           url: `/tasks/content/records/${recordId}/content-users`,
           headers: {
               'Actioncode': 'GET_TASKS_CONTENT_RECORDS_RECORD_ID_CONTENT_USERS'
           }
       });
       return data
    }
}