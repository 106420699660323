import {put, select} from 'redux-saga/effects';
import $api from "../../http";
import {AxiosResponse} from 'axios';
import {GetFileErrorAction, GetFileStartAction, GetFileSuccessAction} from "store/files/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {selectIsNotEmptyFile} from "store/files/selectors";


export function* fileWorker({ id, fileType }: ReturnType<typeof GetFileStartAction>) {
    try {
        const isNotEmpty: boolean = yield select(selectIsNotEmptyFile(id));
        if (!isNotEmpty) {
            const {data}: AxiosResponse<any> = yield $api({
                method: 'GET',
                url: `/files/${id}`,
                responseType: 'blob',
            });
            let result = data

            if (fileType === 'image' || 'PDF' || 'IMAGE') {
                result = window.URL.createObjectURL(data);
            }
            yield put(GetFileSuccessAction(id, result));
        }

    } catch (error: any) {
        yield put(GetFileErrorAction(id, error));
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : `Не удалось получить файл: ${id}`))
    }
}