import {
    ImportUsersErrorAction,
    ImportUsersStartAction,
    ImportUsersSuccessAction,
} from "store/options/actions/actions-users";
import {put} from "redux-saga/effects";
import UsersService from "services/usersService";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";

export default function* importUsers({data}: ReturnType<typeof ImportUsersStartAction>) {
    try {
        const formData = new FormData()
        formData.append('excel', data)
        yield UsersService.usersImport(formData)
        yield put(ImportUsersSuccessAction())

        yield put(NotificationOpen('success', 'Успешно'))

    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(ImportUsersErrorAction(message ? message : 'Не удалось загрузить'))
        yield put(NotificationOpen('error', 'Ошибка', 'Загрузка данных не удалась, посмотрите список ошибок заполнения файла'));
    }
}