import React, {FC} from 'react';
import './modal-deadlines.less'
import {Modal} from "antd";
import useHandlerModal from "hooks/handlModal";
import moment from "moment";
import { ProjectTypes } from 'entities/project';

export type TDataModalDeadlinesProps = {
    type: ProjectTypes.Type
} & ProjectTypes.ContentDates
type ModalDeadlinesProps = {
    status: boolean
    data: TDataModalDeadlinesProps
}

const ModalDeadlines: FC<ModalDeadlinesProps> = ({status, data }) => {
    const {isVisible, closeModal} = useHandlerModal(status);

    const renderTime = (time?: string | null) => {
        if (time) {
            return moment(time).format('DD/MM/YYYY')
        }
        return '—'
    }
    return (
        <Modal
            className='modal-deadlines'
            title="Требования к сроку создания и размещения контента"
            visible={isVisible}
            footer={null}
            maskClosable={true}
            onCancel={closeModal}
        >
            <div className='modal-deadlines__body'>
                <div className='modal-deadlines__item'>
                    <h3>Создание единиц контента</h3>
                    <div className='modal-deadlines__item__block'>
                        <span>Создание 1-ой единицы контента</span>
                        <span>{renderTime(data.firstContentCreationDate)}</span>
                    </div>
                    <div className='modal-deadlines__item__block'>
                        <span>Создание всех единиц контента</span>
                        <span>{renderTime(data.allContentCreationDate)}</span>
                    </div>
                </div>
                {data.type?.code !== 'CONTENT_CREATION' && (
                    <>
                        <div className='modal-deadlines__item'>
                            <h3>Размещение единиц контента</h3>
                            <div className='modal-deadlines__item__block'>
                                <span>Размещение контента 1-ой единицы контента</span>
                                <span>{renderTime(data.firstContentPostingDate)}</span>

                            </div>
                            <div className='modal-deadlines__item__block'>
                                <span>Размещение последней единицы контента</span>
                                <span>{renderTime(data.allContentPostingDate)}</span>

                            </div>
                        </div>

                        <div className='modal-deadlines__item'>
                            <h3>Фиксация охватов (достижение Целевого показателя) Контента</h3>
                            <div className='modal-deadlines__item__block'>
                                <span>Фиксации охвата аудитории  контента</span>
                                <span>{renderTime(data.allContentReachFixDate)}</span>
                            </div>
                        </div>

                    </>
                )}
                <div className='modal-deadlines__item'>
                    <h3>Прием-передача лицензии ИРИ </h3>
                    <div className='modal-deadlines__item__block'>
                        <span>Дата получения лицензии</span>
                        <span>{renderTime(data.licenseDate)}</span>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default ModalDeadlines;
