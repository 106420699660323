import { Nullable } from 'models/common';
import { Vote } from 'entities/task/types';
import { UserTypes } from "entities/user";

export default function CheckReviewer(user: UserTypes.CurrentUser, votes?: Nullable<Vote[]>): {
    isCanReview: boolean, isReviewed: boolean
} {
    const res = {isCanReview: false, isReviewed: false}
    if (!!votes) {
        let condition = true;
        let i = 0;
        do {
            if (votes && votes[i]?.user.id === user?.id) {
                res.isCanReview = true;
                if (votes[i].isApproved !== null) {
                    res.isReviewed = true
                    condition = false;
                }
            }
            i++;
        } while (condition && i < votes.length)
    }

    return res;
}