import Icon from "@ant-design/icons";
import {CustomIconComponentProps} from "@ant-design/icons/es/components/Icon";

const Svg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id={'circle'} d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#84BD00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path id={'coin'} fillRule="evenodd" clipRule="evenodd" d="M10.1176 6C9.56536 6 9.11765 6.44772 9.11765 7V11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H9.11765V14H8C7.44772 14 7 14.4477 7 15C7 15.5523 7.44772 16 8 16H9.11765V18C9.11765 18.5523 9.56536 19 10.1176 19C10.6699 19 11.1176 18.5523 11.1176 18V16H13C13.5523 16 14 15.5523 14 15C14 14.4477 13.5523 14 13 14H11.1176V13H13H14.8824C15.578 13 16.4213 12.8624 17.0761 12.227C17.7333 11.5894 18 10.6466 18 9.5C18 8.35337 17.7333 7.41064 17.0761 6.77297C16.4213 6.13756 15.578 6 14.8824 6H10.1176ZM13 11H11.1176V8H14.8824C15.3563 8 15.5717 8.09994 15.6833 8.20827C15.7927 8.31435 16 8.62162 16 9.5C16 10.3784 15.7927 10.6856 15.6833 10.7917C15.5717 10.9001 15.3563 11 14.8824 11H13Z" fill="#84BD00"/>
    </svg>
)
const CoinIcon = (props: Partial<CustomIconComponentProps>) => {
    return <Icon component={Svg} {...props} />;
};
export default CoinIcon;
