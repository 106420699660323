import { z } from 'zod'
import { ProjectStatusNativeEnum } from 'entities/project/types'
import { TasksStatusEnum } from 'entities/task/types'

export const BrowserNotificationSchema = z.object({
	id: z.string(),
	subject: z.string(),
	message: z.string(),
	userId: z.string(),
	read: z.boolean(),
	date: z.string(),
	project: z
		.object({
			id: z.string(),
			name: z.string(),
			code: z.string(),
			status: z.object({
				id: z.string(),
				code: ProjectStatusNativeEnum,
				name: z.string(),
			}),
		})
		.nullable(),
	task: z
		.object({
			id: z.string(),
			name: z.string(),
			code: z.string(),
			number: z.number(),
			status: z.object({
				id: z.string(),
				code: TasksStatusEnum,
				name: z.string(),
			}),
		})
		.nullable(),
})
export type BrowserNotification = z.infer<typeof BrowserNotificationSchema>
export const BrowserNotificationsSchema = z.object({
	totalCount: z.number(),
	unreadCount: z.number(),
	content: z.array(BrowserNotificationSchema),
})

export type BrowserNotifications = z.infer<typeof BrowserNotificationsSchema>
