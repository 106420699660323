import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetInspectorsErrorAction,
    GetInspectorsStartAction,
    GetInspectorsSuccessAction,
} from "store/options/actions/actions-users";
import UsersService from "services/usersService";
import {InspectorSchema} from "entities/user/types/inspector";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import zodParser from "shared/services/zod-parser";
import {UserTypes} from 'entities/user'

export function* getInspectors(_action: ReturnType<typeof GetInspectorsStartAction>) {
    try {
        const { data }: AxiosResponse<UserTypes.TInspector[]> = yield UsersService.getInspectorsUsers();
        yield put(GetInspectorsSuccessAction(data))
        zodParser.parse(InspectorSchema.array(), data, '/users/current/tasks/inspectors')
    } catch (error: any) {
        yield put(GetInspectorsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить список проверяющих'))
    }
}
