import {put} from 'redux-saga/effects'
import {
	ChangeDownloadRightsErrorAction,
	ChangeDownloadRightsStartAction,
	ChangeDownloadRightsSuccessAction,
} from '../../store/auth/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import UsersService from "services/usersService";

export function* updateDownloadRightsWorker({value}: ReturnType<typeof ChangeDownloadRightsStartAction>) {
	try {
		if (value) {
			yield UsersService.updateDownloadRights()
		}

		yield put(ChangeDownloadRightsSuccessAction(value))
	} catch (error: any) {
		yield put(ChangeDownloadRightsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось авторизоваться'))
	}
}
