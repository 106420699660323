import React, {FC} from "react";
import {Col, Row} from "antd";
import ChooseRole from "../feels/сhoose-role";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {selectReportTypeContentReachReport, selectReportTypeProjectEstimateReport} from "store/app/selectors";
import {selectTasksTypeReportType} from "store/tasks/selectors";
import {selectActualProjects} from "store/projects/selectors";
import {getContractorOrganization} from "../utils/utils";
import {TaskFormDto} from "../domain/dto/task-form.dto";
import {useQuery} from "react-query";
import {ProjectApi, ProjectTypes} from "entities/project";


type Props = {
    definitionSidebar?: boolean
}

const StepThird:FC<Props> = ({definitionSidebar}) => {
    const { values } = useFormikContext<TaskFormDto>()

    const CONTENT_REACH_REPORT = useSelector(selectReportTypeContentReachReport)
    const PROJECT_ESTIMATE_REPORT = useSelector(selectReportTypeProjectEstimateReport)
    const TASK_TYPE_REPORT = useSelector(selectTasksTypeReportType)
    const actualProjects = useSelector(selectActualProjects)

    const formStepFirst = values.stepFirst
    const formStepThird = values.stepThird

    const projectId = (formStepFirst.project?.value?.value || formStepFirst.project?.value?.key) as string
    const {data: project} = useQuery({
        queryKey: [ProjectApi.ProjectViewDetailsController.GetProjectUsers.key(projectId)],
        queryFn: () => ProjectApi.ProjectViewDetailsController.GetProjectUsers.mapUsers(projectId),
        enabled: !!projectId
    })
    return (
        <>
            <Row gutter={16} className="taskForm-form__item">
                <Col md={definitionSidebar ? 5 : 4} xs={24} className="label">
                    Подрядчик<sup>*</sup>
                </Col>
                <Col md={15} xs={24} className="contractor-organization">
                    {formStepFirst.project?.value?.value ? (
                        getContractorOrganization(actualProjects, formStepFirst.project?.value?.value || formStepFirst.project?.value?.key)
                    ) : (
                        'Сначала выберите проект'
                    )}
                </Col>
            </Row>
            <Row gutter={16} align={definitionSidebar ? 'top' : 'middle'} className="taskForm-form__item">
                <ChooseRole
                    role={'ROLE_PROJECT_MANAGER'}
                    fieldRole={'projectManagers'}
                    isCreated={values.isEdit}
                    length={formStepThird.projectManagers.value ? Object.values(formStepThird.projectManagers.value).length : null}
                    id={formStepFirst.project?.value?.value || formStepFirst.project?.value?.key}
                    disabled={formStepThird.projectManagers.isDisabled}
                    definitionSidebar={definitionSidebar}
                    project={project as ProjectTypes.MapProjectUsers}
                />
            </Row>
            <Row gutter={16} align={definitionSidebar ? 'top' : 'middle'} className="taskForm-form__item">
                <ChooseRole
                    role={'ROLE_PRODUCER'}
                    fieldRole={'producers'}
                    isCreated={values.isEdit}
                    length={formStepThird.producers.value ? Object.values(formStepThird.producers.value).length : null}
                    id={formStepFirst.project?.value?.value || formStepFirst.project?.value?.key}
                    disabled={formStepThird.producers.isDisabled}
                    definitionSidebar={definitionSidebar}
                    project={project as ProjectTypes.MapProjectUsers}
                />
            </Row>
            {TASK_TYPE_REPORT?.id !== formStepFirst.typeProject.value && (
                <Row gutter={16} align={definitionSidebar ? 'top' : 'middle'} className="taskForm-form__item">
                    <ChooseRole
                        role={'ROLE_CONTENT_EDITOR'}
                        fieldRole={'contentEditors'}
                        isCreated={values.isEdit}
                        length={formStepThird.contentEditors.value ? Object.values(formStepThird.contentEditors.value).length : null}
                        id={formStepFirst.project?.value?.value || formStepFirst.project?.value?.key}
                        disabled={formStepThird.contentEditors.isDisabled}
                        definitionSidebar={definitionSidebar}
                        project={project as ProjectTypes.MapProjectUsers}
                    />
                </Row>
            )}
            <Row gutter={16} align={definitionSidebar ? 'top' : 'middle'} className="taskForm-form__item">
                <ChooseRole
                    role={'ROLE_COORDINATOR'}
                    fieldRole={'coordinators'}
                    isCreated={values.isEdit}
                    length={formStepThird.coordinators.value ? Object.values(formStepThird.coordinators.value).length : null}
                    id={formStepFirst.project?.value?.value || formStepFirst.project?.value?.key}
                    disabled={formStepThird.coordinators.isDisabled}
                    definitionSidebar={definitionSidebar}
                    hide={TASK_TYPE_REPORT?.id !== formStepFirst.typeProject.value}
                    project={project as ProjectTypes.MapProjectUsers}
                />
            </Row>
            {CONTENT_REACH_REPORT?.id === formStepFirst.report.value &&
                TASK_TYPE_REPORT?.id === formStepFirst.typeProject.value && (
                    <Row gutter={16} align={definitionSidebar ? 'top' : 'middle'} className="taskForm-form__item">
                        <ChooseRole
                            role={'ROLE_ANALYST'}
                            fieldRole={'analysts'}
                            isCreated={values.isEdit}
                            length={formStepThird.analysts.value ? Object.values(formStepThird.analysts.value).length : null}
                            id={formStepFirst.project?.value?.value || formStepFirst.project?.value?.key}
                            disabled={formStepThird.analysts.isDisabled}
                            definitionSidebar={definitionSidebar}
                            project={project as ProjectTypes.MapProjectUsers}
                        />
                    </Row>
                )}
            {PROJECT_ESTIMATE_REPORT?.id === formStepFirst.report.value &&
                TASK_TYPE_REPORT?.id === formStepFirst.typeProject.value && (
                    <Row gutter={16} align={definitionSidebar ? 'top' : 'middle'} className="taskForm-form__item">
                        <ChooseRole
                            role={'ROLE_FINANCIER'}
                            fieldRole={'financiers'}
                            isCreated={values.isEdit}
                            length={formStepThird.financiers.value ? Object.values(formStepThird.financiers.value).length : null}
                            id={formStepFirst.project?.value?.value || formStepFirst.project?.value?.key}
                            disabled={formStepThird.financiers.isDisabled}
                            definitionSidebar={definitionSidebar}
                            project={project as ProjectTypes.MapProjectUsers}
                        />
                    </Row>
                )}
        </>
    )
}
export default React.memo(StepThird)