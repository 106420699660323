import {put} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {AxiosResponse} from 'axios'
import {downloadXLXS} from 'utils/downloadXLXS'
import {
    DownloadProjectsTemplateErrorAction,
    DownloadProjectsTemplateStartAction,
    DownloadProjectsTemplateSuccessAction
} from "store/projects/actions";
import Api from "entities/project/api/project";


export function* downloadProjectsTemplate(_action: ReturnType<typeof DownloadProjectsTemplateStartAction>) {
	try {
		const { data: response }: AxiosResponse<BlobPart> = yield Api.downloadProjectsTemplate()
		yield put(DownloadProjectsTemplateSuccessAction())
		downloadXLXS(response, 'шаблон проектов')
		yield put(NotificationOpen('success', 'Данные выгружены успешно'))
	} catch (error: any) {
		yield put(DownloadProjectsTemplateErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось выгрузить'))
	}
}
