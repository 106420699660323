import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteLegalFormOrgErrorAction,
    DeleteLegalFormOrgStartAction,
    DeleteLegalFormOrgSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import LegalFormOrganizationService from "services/legalFormOrganizationService";


export function* deleteLegalFormOrganizationRecord({id}: ReturnType<typeof DeleteLegalFormOrgStartAction>) {
    try {
        yield LegalFormOrganizationService.deleteLegalFormOrganizationRecord(id)
        yield put(DeleteLegalFormOrgSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.legalFormOrganization))
        yield put(NotificationOpen('success', 'Удаление правовой формы организации выполнено успешно'))

    } catch (error: any) {
        yield put(DeleteLegalFormOrgErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить правовую форму организации'))
    }
}
