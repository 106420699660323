import React, {FC} from 'react';
import './index.less'
import {Modal} from "antd";

import useHandlerModal from "hooks/handlModal";
import {UserEditForm} from "entities/user";

type TModalProps = {
    status: boolean,
    data?: any
}

const ModalUser: FC<TModalProps> = ({status, data}) => {
    const {isVisible, closeModal } = useHandlerModal(status);

    return (
        <Modal
            className='modal-user'
            title={`${data?.id ? 'Редактирование пользователя': 'Добавление пользователя'}`}
            visible={isVisible}
            maskClosable={true}
            footer={false}
            onCancel={closeModal}
        >
            <div className='modal-user'>
                <UserEditForm id={data?.id}/>
            </div>
        </Modal>
    );
};

export default ModalUser;