import React, { FC } from 'react'
import moment from 'moment'
import { Checkbox, Empty, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import { PROJECTS, TASKS } from 'routing/names'
import { ProjectStatus } from 'models/projects/projectStatus'
import PathConverter from 'utils/path-converter'
import { TaskTypes } from 'entities/task'
import { ProjectTypes } from 'entities/project'
import { NotificationApi, NotificationTypes } from 'entities/notification'
import { useMutation } from 'react-query'
import { Query } from 'processes/query'

const getColor = (code: ProjectTypes.ProjectStatus | TaskTypes.TasksStatus) => {
	const style = { color: '' }
	if ((code as ProjectStatus) === 'COMPLETED' || (code as TaskTypes.TasksStatus) === 'READY') style.color = '#3CD298'
	if ((code as ProjectStatus) === 'ACTUAL') style.color = '#FF9E00FF'
	if ((code as TaskTypes.TasksStatus) === 'IN_PROGRESS') style.color = '#00A8B8'
	if ((code as ProjectStatus) === 'DRAFT' || (code as TaskTypes.TasksStatus) === 'TO_IMPLEMENTATION')
		style.color = '#525252'
	if ((code as ProjectStatus) === 'SUSPENDED') style.color = '#FF4F00FF'
	return style
}
const ListItem: FC<{ notification: NotificationTypes.BrowserNotification; readTask: Function }> = ({
	notification,
	readTask,
}) => {
	const history = useHistory()

	return (
		<div className={`notificationSidebar__list-item ${!notification.read ? 'noRead' : ''}`} key={notification.id}>
			<div className="notificationHeader">
				<strong className="notificationHeader__title">{notification.subject}</strong>
				<span className="notificationHeader__time">
					{moment(notification.date).utcOffset(0, true).format('ddd, DD.MM.YYYY, H:mm')} <br />
				</span>
			</div>
			<div className="notificationBody" dangerouslySetInnerHTML={{ __html: notification.message }} />
			<div className="notificationFooter">
				<div className="notificationFooter__status">
					{notification.project && (
						<div
							className="notificationFooter__status-item"
							onClick={(_e) => {
								history.push(PathConverter(PROJECTS.PROJECT_PARAMS, notification.project?.id), {
									tooltip: notification.project?.name,
								})
							}}
						>
							<Tooltip title={notification.project.name} placement={'topLeft'}>
								<div>
									Проект <span>{notification.project.code}</span>
									{notification.project.status && (
										<small style={getColor(notification.project.status.code)}>
											{' '}
											• {notification.project.status?.name}
										</small>
									)}
								</div>
							</Tooltip>
						</div>
					)}
					{notification.task && (
						<div
							className="notificationFooter__status-item"
							onClick={(_e) => {
								history.push(PathConverter(TASKS.TASK, notification.task?.id), { tooltip: notification.task?.name })
							}}
						>
							<Tooltip title={notification.task.name} placement={'bottomLeft'}>
								<div>
									Задача{' '}
									<span>
										{notification.task.code}-{notification.task.number}
									</span>
									{notification.task.status && (
										<small style={getColor(notification.task.status.code)}> • {notification.task.status?.name}</small>
									)}
								</div>
							</Tooltip>
						</div>
					)}
				</div>
				<Tooltip title={!notification.read ? 'Отметить как прочитанное' : 'Отметить как не прочитанное'}>
					{!notification.read ? 'Новое ' : 'Прочитано '}
					<Checkbox defaultChecked={notification.read} onChange={readTask(notification.id)} />
				</Tooltip>
			</div>
		</div>
	)
}
const List: FC<{
	userId: string
	checked: boolean
	data?: NotificationTypes.BrowserNotifications
}> = ({ checked, data }) => {
	const { mutate } = useMutation({
		mutationFn: NotificationApi.BrowserNotificationController.bulkUpdateNotification,
		onSuccess: () => {
			Query.invalidate([NotificationApi.BrowserNotificationController.getNotificationKey, String(checked)])
		},
	})
	const readTask = (id: string) => (event: { target: { checked: boolean } }) => {
		if (event.target.checked) mutate({ action: 'SET_READ', data: [id] })
		else mutate({ action: 'SET_UNREAD', data: [id] })
	}

	return (
		<>
			<div className="notificationSidebar__list">
				{!!data?.content?.length && data.content.length > 0 ? (
					data?.content.map((notification) => (
						<ListItem key={notification.id} notification={notification} readTask={readTask} />
					))
				) : (
					<Empty />
				)}
			</div>
		</>
	)
}
export default List
