import {useFormikContext} from "formik";
import {ProjectFormDto} from "./dto/project-form.dto";

export function useGetStatus() {
    const {values} = useFormikContext<ProjectFormDto>();
    return values.stepFirst.status.value?.code;
}
export function useIsStatusDraftOrReadyToBeActual(): boolean {
    const {values} = useFormikContext<ProjectFormDto>();
    const status = values.stepFirst.status.value?.code
    return status === null || status === undefined || status === 'DRAFT' || status === 'READY_TO_BE_ACTUAL'
}