import {z} from "zod";
import {OrganizationsSchema} from "./organizations";

import {FileSchema} from "shared/types/file";
export const RoleNameSchema = z.enum([
    'ROLE_ADMIN',
    'ROLE_COORDINATOR',
    'ROLE_PRODUCER',
    'ROLE_CONTRACTOR',
    'ROLE_ANALYST',
    'ROLE_FINANCIER',
    'ROLE_LAWYER',
    'ROLE_CLIENT',
    'ROLE_MODERATOR',
    'ROLE_VIEWER',
    'ROLE_AGENT',
    'ROLE_CONTENT_EDITOR',
    'ROLE_PROJECT_MANAGER',
    'ROLE_ADMIN_IS',
    'ROLE_INTEGRATION_USER'
])
export type RoleName = z.infer<typeof RoleNameSchema>
export const RoleSchema = z.object({
    id: z.string(),
    name: RoleNameSchema,
    ruName: z.string(), //Наименование роли на кириллице
})
export type Role = z.infer<typeof RoleSchema>;

export const UserSchema = z.object({
    id: z.string(),
    username: z.string().nullable(),
    lastName: z.string(),
    firstName: z.string(),
    middleName: z.string().nullable(),
    role: RoleSchema,
    leader: z.boolean(), //Флаг руководителя
    status: z.enum(['ACTIVE', 'BLOCKED', 'ARCHIVE']),
    telegram: z.string().nullable(),
    keycloakLastSyncDatetime: z.string().nullable(),
    contentViewAccess: z.boolean().nullable(), //Флаг доступ к просмотру контента
    contentDownloadAccess: z.boolean().nullable(), //Флаг доступ к скачиванию контента
    contentStorageLinkAccess: z.boolean().nullable(), //Флаг доступ к ссылка места хранения контента
    privateProjectViewAccess: z.boolean().nullable(), //Флаг доступ к конфиденциальным проектам
    organizations: z.array(OrganizationsSchema),//Список организаций
    image: FileSchema.nullable(),
    isUserAgreementConfirmed: z.boolean().nullable(),//Признак - Пользователь подтвердил согласие на обработку персональных данных
    isReceivingNotificationsConfirmed: z.boolean().nullable(),//Признак - Пользователь подтвердил согласие на получение уведомлений от АНО ИРИ по смс, e-mail и telegram
    isTelegramConfirmed: z.boolean().nullable(),//Признак подтверждения Telegram пользователя
    isTutor: z.boolean(),//Признак куратора
    tutorVisible: z.boolean(),//Признак видимости куратора для карточки проекта
})
export type User = z.infer<typeof UserSchema>;

export const CurrentUserSchema = z.object({
    id: z.string(),
    username: z.string(),
    personalData: z.object({
        email: z.string().optional(),
        lastName: z.string(),
        firstName: z.string(),
        middleName: z.string().nullable(),
        leader: z.boolean(), //Флаг руководителя
        organizations: z.array(OrganizationsSchema),//Список организаций
        image: FileSchema.nullable(),
    }),
    role: RoleNameSchema,
    emailConfirmed: z.boolean(),
    telegramConfirmed: z.boolean(),
    userPhoneConfirmed: z.boolean(),
    allMainEmailsConfirmed: z.boolean(),
    allMainPhonesConfirmed: z.boolean(),
    isUserAgreementConfirmed: z.boolean(),
    isReceivingNotificationsConfirmed: z.boolean(),
    isDownloadRightsConfirmed: z.boolean(),
    contentViewAccess: z.boolean(),
    contentDownloadAccess: z.boolean(),
    contentStorageLinkAccess: z.boolean(),
    privateProjectViewAccess: z.boolean(),
    keycloakId: z.string(),
    keycloakPhone: z.string().nullable(),
    keycloakEmail: z.string(),
    keycloakLastSyncDatetime: z.string(),
    limitedRightsToViewContentTaskRecords: z.boolean(),
    limitedRightsToViewProjectsList: z.boolean(),
})
export type CurrentUser = z.infer<typeof CurrentUserSchema>;

export const UsersRequestSchema = z.object({
    roleNames: z.array(RoleNameSchema).optional(),
    statuses: z.array(z.enum(['ACTIVE', 'BLOCKED', 'ARCHIVE'])).optional(),
    isKeycloakSynced: z.boolean().optional(),
    isDeleted: z.boolean().optional(),
    organizationIds: z.array(z.string()).optional(),
    projectIds: z.array(z.string()).optional(),
    limitedRightsToViewContentsExist: z.boolean().optional(),
})
export type UsersRequest = z.infer<typeof UsersRequestSchema>;