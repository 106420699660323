import React, {FC} from 'react';
import useHandlerModal from "../../../hooks/handlModal";
import {Modal} from "antd";

export type TDataModalError = {
    error?: string,
    clearFunction: Function
}
type Props = {
    status: boolean
    data: TDataModalError
}
const ModalError: FC<Props> = ({status, data: {clearFunction, error}}) => {
    const {isVisible, closeModal} = useHandlerModal(status);

    const handleOk = () => {
        closeModal()
        clearFunction()
    };

    const handleCancel = () => {
        closeModal()
    };
    return (
        <Modal
            className={'Template-modal'}
            title="Список ошибок заполнения файла"
            visible={isVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelText={'Закрыть'}
            okText={'Очистить'}
        >
            {typeof error === 'string' && error?.split(';').map((el: string, i) => (
                <p key={i}>{el}</p>
            ))}
        </Modal>
    )
};
export default ModalError;