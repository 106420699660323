import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectReportBulkAction,
    selectReportRowsIds,
    selectReportVotes
} from "store/tasks/selectors/selectors-task-report";
import {
    ExportReachRecordsStartAction,
    SendReportBulkReviewStartAction,
    SendReportBulkVotesStartAction,
    SetReportBulkAction,
    SetReportRowsIds
} from "store/tasks/actions/report";
import {TypesTask} from "models/task/types";
import {
    selectContentBulkAction,
    selectContentRowsIds,
    selectContentTasksFilter,
    selectContentVotes
} from "store/tasks/selectors/selectors-task-content";
import {
    CreateFileArchiveStartAction,
    SendContentBulkReviewStartAction,
    SendContentBulkVotesStartAction,
    SetContentBulkAction,
    SetContentRowsIds,
    SetTaskContentFilter
} from "store/tasks/actions/content";
import {NotificationOpen} from "store/notification/actions";
import {TaskApi, TaskTypes} from 'entities/task'
import {useMutation} from "react-query";


const selectBulkAction  = {
    'REPORT' : selectReportBulkAction,
    "CONTENT" : selectContentBulkAction
}

const selectRowsIds  = {
    'REPORT' : selectReportRowsIds,
    "CONTENT" : selectContentRowsIds
}

const selectVotes  = {
    'REPORT' : selectReportVotes,
    "CONTENT" : selectContentVotes
}

type TVotes = {
    [key: string]: TaskTypes.NewVote
}

const sendBulkVotes = (rowsIds: string[], votes: TVotes, type: TaskTypes.TypesTask, reportType: TaskTypes.ReportType, put: any, archival?: boolean) => {
    const data: TaskTypes.NewVote['vote'][] = rowsIds.map(it=> {
        const currentVote = votes[it]['vote']
        return {...currentVote, isApproved: currentVote.isInterimApproved, isInterimApproved: null}
    });
    let isValid = true;
    for (let i = 0; isValid && i < data.length; i++) {
        if (!data[i]?.isApproved && !data[i]?.comment && !archival) {
            isValid = false
            put(NotificationOpen(
                'warning',
                'Ошибка заполения',
                'При отклонении записи укажите причину в комментарии'
            ))
            break;
        }
    }
    if (isValid) {
        if (type === TypesTask.REPORT && reportType) {
            put(SendReportBulkVotesStartAction(reportType, data))
            put(SetReportRowsIds([]))
        } else {
            put(SendContentBulkVotesStartAction(data))
            put(SetContentRowsIds([]))
        }
    }
}

const sendBulkVotesApprove = (rowsIds: string[],type: TaskTypes.TypesTask, reportType: TaskTypes.ReportType, put: any) => {
    const data: TaskTypes.NewVote['vote'][] = rowsIds.map(it=> ({
        comment: null,
        isApproved: true,
        recordId: it,
        isInterimApproved: null
    }));

    if (type === TypesTask.REPORT && reportType) {
            put(SendReportBulkVotesStartAction(reportType,data))
            put(SetReportRowsIds([]))
        } else {
            put(SendContentBulkVotesStartAction(data))
            put(SetContentRowsIds([]))
        }

}

const sendBulkVotesReject = (rowsIds: string[],type: TaskTypes.TypesTask, reportType: TaskTypes.ReportType, put: any) => {
    const data: TaskTypes.NewVote['vote'][] = rowsIds.map(it=> ({
        comment: 'Причину отклонения материала уточнить у проверяющего',
        isApproved: false,
        recordId: it,
        isInterimApproved: null
    }));

    if (type === TypesTask.REPORT && reportType) {
        put(SendReportBulkVotesStartAction(reportType, data))
        put(SetReportRowsIds([]))
    } else {
        put(SendContentBulkVotesStartAction(data))
        put(SetContentRowsIds([]))
    }

}

const sendBulkReview = (rowsIds: string[], type: TaskTypes.TypesTask, reportType: TaskTypes.ReportType, put: any) => {
    if (type === TypesTask.REPORT && reportType) {
        put(SendReportBulkReviewStartAction(reportType,rowsIds))
        put(SetReportRowsIds([]))
    } else {
        put(SendContentBulkReviewStartAction(rowsIds))
        put(SetContentRowsIds([]))
    }
}

const useBulkAction = (type: TaskTypes.TypesTask, reportType?: TaskTypes.ReportType) => {
    const put = useDispatch()
    const filterContent = useSelector(selectContentTasksFilter)
    const bulkAction = useSelector(selectBulkAction[type])
    const rowsIds = useSelector(selectRowsIds[type])
    const votes = useSelector(selectVotes[type])

    const {mutate} = useMutation({
        mutationFn: TaskApi.recordsBulkActionsContent,
        onSuccess: () => {
            put(SetTaskContentFilter({
                    ...filterContent,
                    filterData: { ...filterContent.filterData, bulkAction: undefined },
                })
            )
        }
    })

    const rowSelection = {
        selectedRowKeys: rowsIds,
        onChange: (selectedRowKeys: React.Key[], _selectedRows: any[]) => {
            if(type === TypesTask.REPORT ) {
                put(SetReportRowsIds(selectedRowKeys as string[]))
            } else {
                put(SetContentRowsIds(selectedRowKeys as string[]))
            }
        },
    }

    const onChangeBulkActions = (val: string | null) => {
        if(type === TypesTask.REPORT ) {
            if (val) put(SetReportBulkAction(val))
            else {
                put(SetReportRowsIds([]))
                put(SetReportBulkAction(null))
            }
        } else {
            put(SetTaskContentFilter({
                    ...filterContent,
                    filterData: { ...filterContent.filterData, bulkAction: getNameBulkAction(val) },
                })
            )
            if (val) put(SetContentBulkAction(val))
            else {
                put(SetContentRowsIds([]))
                put(SetContentBulkAction(null))
            }
        }
    }
    const handleClickActions = (archival?: boolean) => {
        if (bulkAction === 'SET_RECORDS_VERIFICATION_REQUIRED') {
            sendBulkReview(rowsIds, type, reportType as TaskTypes.ReportType, put)
        }
        if (bulkAction === 'SEND_APPROVAL_RESULTS') {
            sendBulkVotes(rowsIds, votes, type, reportType as TaskTypes.ReportType, put, archival)
        }
        if (bulkAction === 'SET_RECORDS_APPROVED') {
            sendBulkVotesApprove(rowsIds, type, reportType as TaskTypes.ReportType, put)
        }
        if (bulkAction === 'SET_RECORDS_REJECTED') {
            sendBulkVotesReject(rowsIds, type, reportType as TaskTypes.ReportType, put)
        }
        if (bulkAction === 'GENERATE_CONTENT_ARCHIVE') {
            put(CreateFileArchiveStartAction(rowsIds))
        }
        if (bulkAction === 'DOWNLOAD_SELECTED_DATA') {
            put(ExportReachRecordsStartAction(rowsIds, 'reach-records'))
        }
        if (bulkAction === 'DOWNLOAD_ALL_DATA') {
            put(ExportReachRecordsStartAction(rowsIds, 'reach-records'))
        }
        if (bulkAction && bulkAction.includes('SET_IS_')) {
            mutate({rowsIds, bulkAction})
        }
    }

    return {
        rowSelection,
        onChangeBulkActions,
        handleClickActions,
        bulkAction,
        rowsIds,
    }
}

export default useBulkAction

function getNameBulkAction(action?: string | null) {
    if (action?.includes('SET_IS_')) {
        return action
    }
    if (action === 'GENERATE_CONTENT_ARCHIVE') {
        return action
    }
    return undefined
}