import {createSelector, createSelectorCreator, defaultMemoize} from "reselect";
import {TApplicationState} from "store/aplication-state";
import {selectRole, selectUser} from "store/auth/selectors";

function isEqual<T>(a: T, b: T, _index: number) {
    // @ts-ignore
    if (a.timeStamp === null) {
        return false
        // @ts-ignore
    } else if (a.timeStamp === b.timeStamp) {
        return true
    }
    return false
}

const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual,
);


const reportTask = (state: TApplicationState) => state.tasks.tasksReport;

export const selectReportTask =  createDeepEqualSelector(
    reportTask,
    (state) => state.tasks,
)

export const selectReportBulkAction =  createSelector(
    reportTask,
    (state) => state.bulkAction
)

export const selectReportRowsIds =  createSelector(
    reportTask,
    (state) => state.rowsIds
)

//status
export const selectReportTasksStatus = createSelector(
    reportTask,
    (state) => ({
        isLoading: state.isLoading,
        error: state.error,
    })
)
export const selectReportTasksInfo = createSelector(
    reportTask,
    (state) => ({
        votesStore: state.votes ? state.votes : null,
        recordId: state.recordId,
        isLoaded: state.isLoaded
    })
)

export const selectReportVotes = createSelector(
    reportTask,
    (state) => state.votes
)


export const selectReportTaskWithVotes = createSelector(
    selectUser,
    reportTask,
    selectReportTask,
    selectReportBulkAction,
    (user,state,tasks, bulkAction) => {
        if ( tasks?.length && ( bulkAction === 'DOWNLOAD_SELECTED_DATA' || bulkAction === 'DOWNLOAD_ALL_DATA' )) {
            return tasks
        } else if (tasks?.length && (bulkAction === 'SET_RECORDS_APPROVED' || bulkAction === 'SET_RECORDS_REJECTED')) {
            const votesIds = state.votes ? Object.keys(state.votes) : [];
            // @ts-ignore
            const ids = tasks.filter((task:any) => task.votes?.some((vote:any) => vote.user?.id === user?.id && typeof vote.isApproved === 'boolean'))
                .map((task:any) => task.id);
            // @ts-ignore
            return tasks.filter((task:any) => !ids?.includes(task.id) && !votesIds.includes(task.id) && ["VERIFICATION_REQUIRED", "READY_FOR_CUSTOMER_VERIFICATION"].includes(task.status));

        } else if (state.votes && tasks?.length) {
            const votesIds = Object.keys(state.votes)
            // @ts-ignore
            return tasks.filter((it: any)=> votesIds.includes(it.id) && (it.status === "VERIFICATION_REQUIRED" || it.status === "READY_FOR_CUSTOMER_VERIFICATION"))
        }
        return []
    }
)

export const selectReportTasksReview = createSelector(
    selectReportTask,
    (tasks) => {
        if (tasks?.length) {
            // @ts-ignore
            return tasks.filter((it: any)=> it.status === "READY_FOR_VERIFICATION")
        }
        return []
    }
)

export const selectReportTasksBulkActions = createSelector(
    selectReportTaskWithVotes,
    selectReportTasksReview,
    selectRole,
    (tasksVotes, tasksReview, role ) => {
       if (role === "ROLE_CONTRACTOR") return tasksReview
        else return tasksVotes
    }
)

export const selectReportTasksCost = createSelector(
    reportTask,
    (state) => state.tasksCost ? state.tasksCost : null
)
export const selectReachStatistic = createSelector(
    reportTask,
    (state) => state.reachStatistic
)
/**
 * Filter
 */
export const selectReportTasksFilter = createSelector(
    reportTask,
    (state) => state.filter
)
export const selectStatusRecordAction = (action: string) => createSelector(
  reportTask,
  (state) => state.isLoadingAction[action]
)