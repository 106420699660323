import React, {FC, useEffect} from 'react';
import './index.less'
import {Button, Modal, Spin, Table} from "antd";
import useHandlerModal from "hooks/handlModal";
import {ColumnsType} from "antd/es/table";
import moment from "moment";
import NameCenterCell from "components/table-cells/name-center-cell";
import LinkCell from "components/table-cells/link-cell";
import ResourceCell from "components/table-cells/resource-cell";
import {useRecords} from "./hooks";
import useTasksFields from "hooks/tasksField";
import {TTasksTypesFields} from "models/task";
import { TaskTypes } from 'entities/task';


type ModalPostingContentProps = {
    status: boolean
    data: {
        projectId?: string
    }
}
const ModalPostingContent: FC<ModalPostingContentProps> = ({status, data}) => {
    const {recordsStatuses, isLoading} = useTasksFields([TTasksTypesFields.recordsStatuses])
    const {getRecords, filter, loading, records, handleTable} = useRecords();
    const {isVisible, closeModal} = useHandlerModal(status);

    useEffect(() => {
        if (recordsStatuses && isVisible) {
            getRecords({
                page: 1,
                pageSize: 10,
                filterData: {
                    isMasterFile: true,
                    taskRecordStatusId: recordsStatuses?.find((el) => el.code === 'APPROVED')?.value,
                    projectIds: data?.projectId ? [data?.projectId] : undefined
                }
            })
        }
    }, [recordsStatuses, isVisible, data?.projectId]);//eslint-disable-line



    const columns: ColumnsType<TaskTypes.PostingRecords> = [
        {
            title: 'Фактическая дата публикации',
            dataIndex: 'actualPublicationDate',
            key: 'actualPublicationDate',
            width: 115,
            render: (data: Date | string) => {
                return NameCenterCell(moment(data).format('DD.MM.yy'))
            },
        },
        {
            title: 'Публикуемая единица контента',
            dataIndex: 'contentTaskRecord',
            key: 'contentTaskRecord',
            width: 120,
            render: (_el, {contentTaskRecord}) => {
                return NameCenterCell(contentTaskRecord?.name || '')
            },
        },
        {
            title: 'Название контента при публикации',
            dataIndex: 'publishedName',
            key: 'publishedName',
            width: 180,
            render: NameCenterCell,
        },
        {
            title: 'Ссылка на единицу контента, размещенную в сети интернет',
            dataIndex: 'link',
            key: 'link',
            width: 210,
            render: LinkCell,
        },
        {
            title: 'Площадка размещения',
            dataIndex: 'resource',
            key: 'resource',
            width: 110,
            render: ResourceCell,
        },
        {
            title: 'Возрастная категория',
            dataIndex: 'ageCategory',
            key: 'ageCategory',
            width: 110,
            render: NameCenterCell,
        },
        {
            title: 'Хронометраж',
            dataIndex: 'contentUnitDimension',
            key: 'contentUnitDimension',
            width: 120,
            render: NameCenterCell
        },
    ]

    return (
        <Modal
            className='modal-posting-content'
            title="Ссылки на публикации контента проекта в сети Интернет"
            visible={isVisible}
            onCancel={closeModal}
            footer={(
                <>
                    <Button type={"default"} onClick={closeModal}>Закрыть</Button>
                </>
            )}
            maskClosable={true}
        >
            <Spin spinning={isLoading}>
                <Table
                    loading={loading}
                    className='table-project table'
                    sticky
                    scroll={{ x: 900 }}
                    columns={columns}
                    dataSource={records ? records : []}
                    pagination={{
                        current: filter ? filter.page : 1,
                        pageSize: filter ? filter.pageSize : 10,
                        total: filter ? filter.totalCount: 1,
                        position: ['bottomCenter'],
                        showSizeChanger: true
                    }}
                    rowKey='id'
                    onChange={handleTable}
                />
            </Spin>
        </Modal>
    )
};

export default ModalPostingContent;