import React from 'react';
import './menu-sidebar.less'
import {Drawer} from "antd";
import MainMenu from "../../menu/main-menu";
import useToggleSidebar from "../../../hooks/toggleSidebar";

const MenuSidebar = () => {
    const { menuStatus, handlerSidebar } = useToggleSidebar();

    return (
        <Drawer
            className='menuSidebar'
            placement="right"
            closable={false}
            onClose={handlerSidebar('menu')}
            visible={menuStatus}
        >
            <MainMenu mode={"inline"}/>
        </Drawer>
    )
};
export default MenuSidebar;