import React, {FC} from 'react';
import './status-task-form.less'
import {AppstoreAddOutlined, CaretLeftOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import {Tooltip} from 'antd';

type StatusTaskProps = {
    status: number | string,
    handler?: any
}
function activeClass(status: number | string, num: number) {
    if (+status === num) return 'active'
}
const StatusTaskForm: FC<StatusTaskProps> = ({status, handler}) => {
    return (
        <div className='statusTaskForm'>
            <Tooltip title={'Общие параметры'}>
                <div
                    onClick={handler(1)}
                    className={`statusTaskForm__item statusTaskForm__1 ${activeClass(status, 1)}`}>
                    <AppstoreAddOutlined />
                </div>
            </Tooltip>
            <Tooltip title={'Условия задачи'}>
                <div
                    onClick={handler(2)}
                    className={`statusTaskForm__item statusTaskForm__2 ${activeClass(status, 2)}`}>
                    <SettingOutlined />
                </div>
            </Tooltip>
            <Tooltip title={'Параметры ролей'}>
                <div
                    onClick={handler(3)}
                    className={`statusTaskForm__item statusTaskForm__3 ${activeClass(status, 3)}`}>
                    <UserOutlined />
                </div>
            </Tooltip>
            <div
                className='statusTaskForm__item statusTaskForm__back active' onClick={handler()}>
                <CaretLeftOutlined />
            </div>
        </div>
    )
};
export default StatusTaskForm;