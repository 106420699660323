import {put, select} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetAllTasksErrorAction,
    GetAllTasksStartAction,
    GetAllTasksSuccessAction,
    SetTaskFilter,
} from "store/tasks/actions/tasks";
import TasksService from "services/tasksService";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {selectTaskListFilter} from "store/tasks/selectors/selectors-task";
import {TTaskFilter, TTaskListResponse} from "models/task/task-list";

export function* getAllTasks(_action: ReturnType<typeof GetAllTasksStartAction>) {
    try {
        const filter: TTaskFilter = yield select(selectTaskListFilter);
        const {data}: AxiosResponse<TTaskListResponse> = yield TasksService.getAllTask(filter);

        if (data.page * data.pageSize >= data.totalCount && data.totalCount !== 0) {
            yield put(SetTaskFilter({...filter, page: 1}))
            yield put(GetAllTasksStartAction())
        } else {
            yield put(GetAllTasksSuccessAction(data))
        }
    } catch (error: any) {
        yield put(GetAllTasksErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить задачи'))
    }
}
