import React, {FC} from "react";
import {StepFirstDto} from "../domain/dto/task-form_StepFirst.dto";
import {useSelector} from "react-redux";
import {selectSprintsList} from "store/sprints/selectors";
import {Tooltip} from "antd";
import moment from "moment";
import {DatePickerComponent} from "../../../formic-control";
import {filingName} from "../steps/step-first";


type Props = {
    values: StepFirstDto
    isDisabled: boolean | undefined
}

const DeadlineComponent: FC<Props> = ( { values, isDisabled }) => {
    const sprintsList = useSelector(selectSprintsList)
    const isAfterDate = sprintsList?.find((el) => el.id === values.sprint?.value?.value)?.dateStart
    const isBeforeDate = sprintsList?.find((el) => el.id === values.sprint?.value?.value)?.dateEnd

    return (
        <Tooltip
            placement="right"
            title={
                isAfterDate && isBeforeDate
                    ? `Выберите дату в диапозоне ${moment(isAfterDate).format('LL')} - ${moment(isBeforeDate).format('LL')}`
                    : ''
            }
        >
            <div>
                <DatePickerComponent
                    name={filingName('deadline')}
                    placeholder={'Введите'}
                    isAfter={true}
                    isBefore={true}
                    disabled={isDisabled || !values.sprint?.value?.value}
                    isAfterDate={isAfterDate}
                    isBeforeDate={isBeforeDate}
                />
            </div>
        </Tooltip>
    )
}

export default DeadlineComponent