import {put} from 'redux-saga/effects'
import HandbookService from "services/handbookService";
import {
	BulkContentDirectionActionsErrorAction,
	BulkContentDirectionActionsStartAction,
	BulkContentDirectionActionsSuccessAction,
	GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";

export function* bulkContentDirectionActions({ userArr, action }: ReturnType<typeof BulkContentDirectionActionsStartAction>) {
	try {
		yield HandbookService.bulkContentDirectionActions(userArr, action)

		yield put(BulkContentDirectionActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.contentDirection))
		yield put(NotificationOpen('success', 'Обновлено успешно'))

	} catch (error: any) {
		yield put(BulkContentDirectionActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}
}
