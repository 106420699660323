import React, {useMemo} from 'react';
import {Col, Row, Spin} from "antd";
import {DatePickerComponent, RadioButtonsComponent, SelectComponent} from "components/formic-control";
import ContentFormatFields from "./contentFormatFields";
import useFeelsApp from "hooks/feelsApp";
import {TStatusesFeels} from "models/projects/projectFeels";
import {useFormikContext} from "formik";
import TagsField from "./tags";
import RegionalTagsField from "./regionalTags";
import {GetFeelsStartAction} from "store/app/actions";
import {useDispatch} from "react-redux";
import {ProjectFormDto} from "features/create-or-edit-project-form/lib/dto/project-form.dto";
import ReloadList from 'components/forms/components/reload-list/reload-list';
import styles from './styles.module.less';
import classNames from "classnames";
import {useIsStatusDraftOrReadyToBeActual} from "features/create-or-edit-project-form/lib";

function filingName(key: string): string {
    return `stepSecond.${key}.value`
}

const StepSecond = () => {
    const put = useDispatch()
    const {values, errors} = useFormikContext<ProjectFormDto>()
    const statusIsStatusDraftOrReadyToBeActual = useIsStatusDraftOrReadyToBeActual();
    const status = values.stepFirst.status.value;

    const {
        isLoading: isLoadingFeels,
        thematics,
        directions,
    } = useFeelsApp([
        TStatusesFeels.thematics,
        TStatusesFeels.directions,
        TStatusesFeels.statuses
    ])
    const form = values.stepSecond;
    const formFirst = values.stepFirst;
    const formErrors = errors.stepSecond;
    const typeContentContentCreation = formFirst.projectType.value?.code === 'CONTENT_CREATION';
    const isRequiredDates = typeContentContentCreation && !statusIsStatusDraftOrReadyToBeActual;

    const thematicsDisabledOptions = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return thematics?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, thematics]);
    const directionsDisabledOptions = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return directions?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, directions]);

    const handlerUpdateThematics = ()=> {
        put(GetFeelsStartAction(TStatusesFeels.thematics))
    }
    const handlerUpdateDirection = ()=> {
        put(GetFeelsStartAction(TStatusesFeels.directions))
    }

    return (
        <div className={styles.container}>
            <div className={classNames(styles.contentThematics_label, 'label')}>
                Тематические линии<sup>*</sup>
            </div>
            <div className={styles.contentThematics}>
                {thematics && (
                    <Spin spinning={isLoadingFeels}>
                        <ReloadList className="ident" handlerUpdateList={handlerUpdateThematics}/>
                        <SelectComponent
                            name={filingName('contentThematics')}
                            placeholder="Выберите одну или несколько тематических линий проекта"
                            options={thematics}
                            maxTagCount={'responsive'}
                            disabledOptionsIds={thematicsDisabledOptions}
                            multi={true}
                            showError={false}
                            disabled={form.contentThematics.isDisabled}
                            errorMessage={formErrors?.contentThematics as string}
                        />
                    </Spin>
                )}
            </div>

            {/*-----------------*/}

            <TagsField/>
            <RegionalTagsField/>

            {/*--------*/}

            <div className={classNames(styles.contentDirection_label, 'label')}>
                Направление исполнения проекта<sup>*</sup>
            </div>
            <div className={styles.contentDirection}>
                {directions && (
                    <Spin spinning={isLoadingFeels}>
                        <RadioButtonsComponent
                            name={filingName('contentDirection')}
                            options={directions}
                            disabled={form.contentDirection.isDisabled}
                            disabledOptionsIds={directionsDisabledOptions}
                        />
                        <ReloadList className="ident" handlerUpdateList={handlerUpdateDirection}/>
                    </Spin>
                )}
            </div>

            {/*-------*/}

            <div className={classNames(styles.contentFormats_label, 'label')}>
                Единицы контента<sup>*</sup>
            </div>
            <div className={styles.contentFormats}>
                <ContentFormatFields
                    isContent
                    name={filingName('contentFormats')}
                    disabled={form.contentFormats.isDisabled}/>
            </div>

            {/*------------------*/}

            <div className={classNames(styles.workingMaterials_label, 'label')}>
                Рабочие материалы
            </div>
            <div className={styles.workingMaterials}>
                <ContentFormatFields
                    name={filingName('contentFormats')}
                    disabled={form.contentFormats.isDisabled}/>
            </div>

            {/*------------------*/}

            <div className={classNames(styles.dates_label, 'label')}>
                Сроки создания и размещения контента<sup>*</sup>
            </div>
            <div className={styles.dates}>
                <ContentDate
                    title="Создание первой единицы контента"
                    name={filingName('firstContentCreationDate')}
                    disabled={form.firstContentCreationDate.isDisabled || !formFirst.completionDate.value}
                    errorMessage={formErrors?.firstContentCreationDate as string}
                    isBeforeDate={formFirst.completionDate.value as Date | string}
                    isAfterDate={formFirst.agreementDate.value as Date | string || formFirst.contractDate.value as Date | string}
                    isRequired={!statusIsStatusDraftOrReadyToBeActual}
                />
                <ContentDate
                    title="Создание всех единиц контента"
                    name={filingName('allContentCreationDate')}
                    disabled={form.allContentCreationDate.isDisabled || !formFirst.completionDate.value}
                    errorMessage={formErrors?.allContentCreationDate as string}
                    isBeforeDate={formFirst.completionDate.value as Date | string}
                    isAfterDate={formFirst.agreementDate.value as Date | string || formFirst.contractDate.value as Date | string}
                    isRequired={!statusIsStatusDraftOrReadyToBeActual}
                />
                <ContentDate
                    title="Начало размещения первой единицы контента"
                    name={filingName('firstContentPostingDate')}
                    disabled={form.firstContentPostingDate.isDisabled || !form.firstContentCreationDate.value}
                    errorMessage={formErrors?.firstContentPostingDate as string}
                    isBeforeDate={formFirst.completionDate.value as Date | string}
                    isAfterDate={formFirst.agreementDate.value as Date | string || formFirst.contractDate.value as Date | string}
                    isRequired={isRequiredDates}
                />

                <ContentDate
                    title="Размещение последней единицы контента"
                    name={filingName('allContentPostingDate')}
                    disabled={form.allContentPostingDate.isDisabled ||!form.allContentReachFixDate.value}
                    errorMessage={formErrors?.allContentPostingDate as string}
                    isBeforeDate={form.allContentReachFixDate.value as Date | string}
                    isAfterDate={formFirst.agreementDate.value as Date | string || formFirst.contractDate.value as Date | string}
                    isRequired={isRequiredDates}
                />
                <ContentDate
                    title="Дата фиксации охвата аудитории"
                    name={filingName('allContentReachFixDate')}
                    disabled={form.allContentReachFixDate.isDisabled || !formFirst.completionDate.value}
                    errorMessage={formErrors?.allContentReachFixDate as string}
                    isBeforeDate={formFirst.completionDate.value as Date | string}
                    isAfterDate={formFirst.agreementDate.value as Date | string || formFirst.contractDate.value as Date | string}
                    isRequired={isRequiredDates}
                />
            </div>

        </div>
    )
};

function ContentDate(props: {
    title: string,
    name: string,
    isAfterDate?: string | Date,
    isBeforeDate?: string | Date,
    disabled?: boolean,
    errorMessage?: string
    isRequired?: boolean
}) {
    return (
        <Row className="gutter-row" gutter={[5, 5]}>
            <Col md={8} xs={22} className={styles.labelSmall}>
                {props.title}{props.isRequired && <sup>*</sup>}
            </Col>
            <Col lg={6} md={10} xs={22}>
                <DatePickerComponent
                    name={props.name}
                    disabled={props.disabled}
                    errorMessage={props.errorMessage}
                    isAfterDate={props.isAfterDate}
                    isBeforeDate={props.isBeforeDate}
                    isAfter={true}
                    isBefore={true}
                />
            </Col>
        </Row>
    )
}

export default React.memo(StepSecond);
