import React, {FC} from 'react';
import './index.less'
import {Modal, Table} from "antd";
import useHandlerModal from "hooks/handlModal";
import {TChannels} from "models/projects/channels";
import {ColumnsType} from "antd/es/table";
import NameCell from "components/table-cells/name-cell";
import {TInternetResourceServer} from "models/projects/internetResource";
import NameCenterCell from "../../table-cells/name-center-cell";
import LinkCell from "../../table-cells/link-cell";

type ModalChannelsProps = {
    status: boolean
    data: TChannels<NonNullable<TInternetResourceServer>>[]
}

const ModalChannels: FC<ModalChannelsProps> = ({status, data }) => {
    const {isVisible, closeModal} = useHandlerModal(status);

    const columns: ColumnsType<TChannels> = [
        {
            title: 'Площадка размещения',
            dataIndex: 'internetResource',
            key: 'internetResource',
            width: 100,
            render: (internetResource: NonNullable<TInternetResourceServer>) => {
                return NameCell(String(internetResource.name))
            }
        },
        {
            title: 'Название канала',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            render: NameCenterCell
        },
        {
            title: 'Ссылка на канал',
            dataIndex: 'link',
            key: 'link',
            width: 160,
            render: LinkCell
        },
        {
            title: 'Плановый объем размещения',
            dataIndex: 'planPublicationCount',
            key: 'planPublicationCount',
            width: 180,
            render: NameCenterCell
        },
    ]

    return (
        <Modal
            className='modal-channels'
            title="Интернет-ресурсы размещения контента проекта"
            visible={isVisible}
            footer={null}
            maskClosable={true}
            onCancel={closeModal}
        >
            <div className="content">
                <Table
                    className="table"
                    sticky
                    columns={columns}
                    scroll={{ x: 540 }}
                    dataSource={data ? data : []}
                    rowKey="id"
                    pagination={false}
                />
            </div>
        </Modal>
    )
};

export default ModalChannels;
