import React from 'react';
import ChannelsFeel from "./chanels";
import PlannedKpi from "./plannedKpi";
import {UploadComponent} from "components/formic-control";
import {useFormikContext} from "formik";
import {ProjectFormDto} from "features/create-or-edit-project-form/lib/dto/project-form.dto";
import styles from './styles.module.less';
import classNames from "classnames";

function filingName(key: string): string {
    return `stepThird.${key}.value`
}

const StepThird = () => {
    const {values, errors} = useFormikContext<ProjectFormDto>();

    const form = values.stepThird;
    const isRequired = values.stepFirst.projectType.value?.code === 'CONTENT_CREATION_AND_PLACEMENT';

    return (
        <div className={styles.container}>
            <div className={classNames(styles.channels_label, 'label')}>
                Добавить интернет-ресурс{isRequired && <sup>*</sup>}
            </div>
            <div className={styles.channels}>
                <ChannelsFeel
                    name={filingName('channels')}
                    disabled={form.channels.isDisabled}
                />
                {form.channels.isDisabled &&
                    'Данные не требуются для проектов на создание контента'
                }
            </div>

            {/*---------------*/}

            <div className={classNames(styles.kpis_label, 'label')}>
                Плановый КПЭ{isRequired && <sup>*</sup>}
            </div>
            <div className={styles.kpis}>
                <PlannedKpi name={filingName('kpis')} disabled={form.kpis.isDisabled}/>
                {form.channels.isDisabled &&
                    'Данные не требуются для проектов на создание контента'
                }
            </div>

            {/*-------------------*/}

            <div className={classNames(styles.image_label, 'label')}>
                Изображение проекта
            </div>
            <div className={styles.image}>
                <UploadComponent
                    disabled={form.image.isDisabled}
                    name={filingName('image')}
                    typeUploadFile={'image/*'}
                    multi={false}
                    placeholder="Добавить изображение"
                    existingLabel={filingName('existingImage')}
                    existingLabelDisabled={form.existingImage.isDisabled}
                />
                {errors.stepThird?.image && (
                    <div className={styles.error}>{errors.stepThird.image}</div>
                )}
            </div>
        </div>
    )
};
export default React.memo(StepThird);
