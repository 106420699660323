import React, {MouseEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FilterOutlined} from "@ant-design/icons";
import {Button, Spin, Tooltip} from "antd";
import Search from "antd/es/input/Search";
import styles from './filter.module.less';
import {selectProjectsStatus} from "store/projects/selectors";
import {RoleName} from "entities/user/types";
import { CalendarModel, CalendarTypes } from 'pages/calendar';
import { ExtendedSearchModel } from 'features/extended-search';
import { SearchContentProjectModel } from 'features/search-project-content';
import { UserFiltersLib } from 'features/user-filters';
import UserFilters from "features/user-filters/ui";
import WhetherTheUser from "components/whetherTheUser";
import { useIsFirstRender } from 'shared/lib/hooks/if-first-render';
import {useDebounceValue} from "hooks/useDebounce";

const roles: RoleName[] = ['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_CLIENT']

const Filter = () => {
    const resetFilterHandler = UserFiltersLib.useResetFilter('PROJECTS_CALENDAR');
    const isFirst = useIsFirstRender()
    const put = useDispatch();
    const {isLoading} = useSelector(selectProjectsStatus);
    const filter = useSelector(CalendarModel.select.filter);
    const view = useSelector(CalendarModel.select.view);
    const showClearFilterButton = useSelector(ExtendedSearchModel.selectors.isToggleFilter('calendar'))
    const showClearFilterButtonSecond = useSelector(SearchContentProjectModel.selectors.isToggleFilter('calendar'))
    const [searchString, setSearchString] = useState(filter.filterData?.searchString);
    const debouncedValue = useDebounceValue(searchString, 2000)

    const updateFilter = (searchValue?: string) => {
        const filterData = { ...filter.filterData, searchString: searchValue };
        const newFilter = { ...filter, page: 1, filterData };
        put(CalendarModel.actions.setCalendarValue({ filter: newFilter }));
    }
    const handlerSearch = (e: any) => {
        updateFilter(e.target.value)
        setSearchString(e.target.value)
    }
    const onSearch = (value: any) => {
        updateFilter(value)
        put(CalendarModel.actions.GetCalendarValue())
    }

    useEffect(() => {
        if (!isFirst) put(CalendarModel.actions.GetCalendarValue())
    }, [debouncedValue])//eslint-disable-line

    function onModalOpenExtendedSearch(_event: MouseEvent<HTMLDivElement>) {
        put(ExtendedSearchModel.actions.setCFMain({isOpen: true, typeContent: 'calendar'}))
    }
    function onModalOpenSearch(_event: MouseEvent<HTMLDivElement>) {
        put(SearchContentProjectModel.actions.setCFMain({isOpen: true, typeContent: 'calendar'}))
    }

    function onClearFilterClick(_event: MouseEvent<HTMLDivElement>) {
        ExtendedSearchModel.actions.ClearAllFilters(put, 'calendar')
        SearchContentProjectModel.actions.ClearAllFilters(put, 'calendar')
        put(CalendarModel.actions.GetCalendarValue())
        resetFilterHandler()
    }

    const changeView = (view: CalendarTypes.ViewGantt) => () => {
        put(CalendarModel.actions.setCalendarValue({view}))
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className='form-group'>
                        <Spin spinning={isLoading}>
                            <Tooltip title={'Поиск по названию проекта, № договора, № заявки'}>
                                <Search
                                    value={searchString}
                                    onSearch={onSearch}
                                    name='searchString'
                                    placeholder='Введите название'
                                    onChange={handlerSearch}
                                    allowClear={true}
                                />
                            </Tooltip>
                        </Spin>
                    </div>

                    <WhetherTheUser role={roles} isCan={false}>
                        <div className={styles.filters} onClick={onModalOpenExtendedSearch}>
                            <FilterOutlined />
                        </div>
                    </WhetherTheUser>
                    <WhetherTheUser role={roles} isCan={true}>
                        <div className={styles.filters} onClick={onModalOpenSearch}>
                            <FilterOutlined />
                        </div>
                    </WhetherTheUser>

                    {!!filter.totalCount && (<div className={styles.count}>Найдено: {filter.totalCount}</div>)}

                    {(showClearFilterButton || showClearFilterButtonSecond) && (
                        <div className={styles.clear} onClick={onClearFilterClick}>
                            <Button type={"default"}>Сбросить фильтр</Button>
                        </div>
                    )}
                </div>
                <div className={styles.view}>
                    <Button onClick={changeView('month')} type={view === 'month' ? 'primary' : 'default'}>Месяц</Button>
                    <Button onClick={changeView('week')} type={view === 'week' ? 'primary' : 'default'}>Неделя</Button>
                    <Button onClick={changeView('day')} type={view === 'day' ? 'primary' : 'default'}>День</Button>
                </div>
            </div>
            <div className={styles.container} style={{marginTop: 0}}>
                <UserFilters filterType={'PROJECTS_CALENDAR'} needToMakeRequest={true}/>
            </div>
        </>
    )
};

export default Filter;