import { FunctionalityInfoType } from '../types'

export const projects: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка проектов с фильтрацией, сортировкой, пагинацией',

		LevelGroupData: [
			{
				name: 'Поиск проектов',
				text: 'Для поиска проектов по названию, номеру договора, номеру заявки:',
				points: ['Нажмите "Введите название"', 'Введите текст', 'Нажмите на кнопку поиска'],
				screenshots: ['/images/screenshots/search-screenshot.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Фильтрация проектов',
				text: 'Используйте фильтры для поиска:',
				points: [
					'Нажмите для перехода в окно фильтрации',
					'Примените фильтры',
					'Нажмите “Найти” для поиска проектов',
					'Нажмите "Сбросить фильтры" для сброса неактуальных фильтров',
				],
				important: [
					'! Нажмите "Развернуть все разделы" для раскрытия списка доступных фильтров либо "Свернуть все разделы" для скрытия развернутого списка фильтров',
					'! Нажмите "Сохранить фильтр" для сохранения фильтра',
				],
				screenshots: ['/images/screenshots/filters-projects.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Состояние "Избранный" проекта',
				points: ['Нажмите, чтобы отметить проект как избранный', 'Нажмите еще раз, чтобы снять пометку избранности'],
				icons: ['star'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Копирование данных столбца',
				text: 'Нажмите на название поля для копирования данных',
				screenshots: ['/images/screenshots/copy-data.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Сортировка отображения таблицы проектов',
				text: 'Нажимайте на стрелки для сортировки отображения таблицы проектов',
				screenshots: ['/images/screenshots/sorting-column.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Настройка количества проектов, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице проектов:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Управление выводом полей',
		LevelGroupData: [
			{
				name: 'Настройка скрытия видимости полей таблицы',
				text: 'Для настройки скрытия видимости полей таблицы:',
				points: ['Нажмите на кнопку', 'Выберите столбцы для скрытия', 'Нажмите "Сохранить"'],
				screenshots: ['/images/screenshots/setting.png', '/images/screenshots/setting-table.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Настройка порядка отображения полей таблицы',
				text: 'Для настройки порядка отображения полей таблицы:',
				points: ['Нажмите на кнопку', 'Перетяните столбцы в желаемом порядке', 'Нажмите "Сохранить"'],
				screenshots: ['/images/screenshots/setting.png', '/images/screenshots/setting-table2.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Создание проектов',
		LevelGroupData: [
			{
				name: 'Создание проектов через загрузку шаблона данных',
				text: 'Для настройки скрытия видимости полей таблицы:',
				points: [
					'Нажмите "Выгрузить шаблон" для скачивания шаблона проекта',
					'Заполните шаблон',
					'Нажмите "Загрузить данные" для загрузки данных по проекту',
				],
				screenshots: ['/images/screenshots/import-template.png', '/images/screenshots/export-template.png'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_COORDINATOR', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Создание проектов через форму создания проектов',
				points: [
					'Нажмите “Создать проект” для перехода в форму создания проекта',
					'Заполните обязательные и доступные поля формы на 1-5 вкладках',
					'Сохраните форму',
					'Нажмите "Список ошибок" в случае возникновения ошибок',
					'Исправьте перечисленные ошибки на вкладках формы',
					'Сохраните форму повторно',
				],
				important: ['! Для сохранения черновика проекта достаточно заполнить следующие поля: “Название проекта”, “Номер заявки”, “Дата договора” или “Дата соглашения”, “Подрядчик”. Заполнение всех остальных обязательных полей, выделенных звездочкой, необходимо для успешного перевода проекта в статус “Текущий”'],
				screenshots: ['/images/screenshots/create-project.png', '/images/screenshots/project-form.png'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_COORDINATOR', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER'],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных проекта',
		LevelGroupData: [
			{
				name: 'Редактирование данных проекта',
				text: 'Нажмите для перехода в форму редактирования проекта. Для\u00A0редактирования\u00A0проекта:',
				points: [
					'Заполните обязательные и доступные поля формы на 1-5 вкладках',
					'Сохраните форму',
					'Нажмите "Список ошибок" в случае возникновения ошибок',
					'Исправьте перечисленные ошибки на вкладках формы',
					'Сохраните форму повторно',
				],
				screenshots: ['/images/screenshots/edit-project.png', '/images/screenshots/project-form.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Массовые действия над проектами',
				text: 'Для массового выполнения действий:',
				points: [
					'Нажмите "Выберите массовое действие"',
					'Выберите массовое действие из выпадающего списка',
					'Выберите проекты',
					'Нажмите "Применить"',
				],
				screenshots: [
					'/images/screenshots/bulk-actions.png',
					'/images/screenshots/checked-checkbox.png',
					'/images/screenshots/button-apply.png',
				],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_COORDINATOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_PRODUCER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_ANALYST',
					'ROLE_LAWYER',
					'ROLE_FINANCIER',
				],
			},
			{
				name: 'Смена статуса проекта',
				text: 'Для смены статуса проекта:',
				points: [
					'Нажмите на статус проекта',
					'Выберите доступный статус из выпадающего списка',
					'Нажмите на нужный статус',
				],
				screenshots: ['/images/screenshots/select-status-project.png'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_COORDINATOR', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Удаление проекта',
				text: 'Нажмите для удаления проекта',
				icons: ['delete-red'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_COORDINATOR', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER'],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр данных по проекту',
		LevelGroupData: [
			{
				name: 'Просмотр меток редактора контента',
				text: 'Наведите курсор для просмотра меток редактора контента',
				screenshots: ['/images/screenshots/label-select.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр меток редактора каталога',
				text: 'Наведите курсор на название проекта на диаграмме для просмотра дополнительной информации',
				screenshots: ['/images/screenshots/label-select.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Индикатор согласования материалов проекта',
				text: 'Наведите курсор на индикатор для просмотра статуса:',
				points: [
					{
						label: 'Красный - требуется согласование материала',
						icon: 'warning-red'
					},
					{
						label: 'Серый - материал на проверку отсутствует',
						icon: 'warning-gray'
					}
				],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Индикатор формы оплаты',
				text: 'Наведите курсор на индикатор для просмотра статуса:',
				points: [
					{
						label: 'Зеленый - авансирование',
						icon: 'circle-coin-green'
					},
					{
						label: 'Серый - постоплата',
						icon: 'circle-coin-gray'
					}
				],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_CONTRACTOR'
				],
			},
			{
				name: 'Индикатор статуса размещения',
				text: 'Наведите курсор на индикатор для просмотра статуса:',
				points: [
					{
						label: 'Зеленый - размещается',
						icon: 'search-green'
					},
					{
						label: 'Серый - не размещается',
						icon: 'search-gray'
					}
				],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Просмотр графика реализации проекта',
				text: 'Нажмите для просмотра данных в модальном окне "Требования к сроку создания и размещения контента"',
				icons: ['calendar'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Просмотр категорий и жанров проекта',
				text: 'Нажмите для просмотра данных в модальном окне "Категории и жанры проекта"',
				icons: ['appstore-add'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Просмотр регионов проекта',
				text: 'Нажмите для просмотра данных в модальном окне "Регионы проекта"',
				icons: ['home'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Просмотр наград проекта',
				text: 'Нажмите для просмотра данных в модальном окне "Награды проекта"',
				icons: ['trophy'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Просмотр публикаций контента проекта',
				text: 'Нажмите для просмотра данных в модальном окне "Ссылки на публикации контента проекта в сети Интернет"',
				icons: ['file-search'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Просмотр тематики проекта',
				text: 'Нажмите для просмотра данных в модальном окне "Тематика проекта"',
				icons: ['tags'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Просмотр единиц контента проекта',
				text: 'Нажмите для просмотра данных в модальном окне "Единицы контента проекта"',
				icons: ['container'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Просмотр интернет-ресурсов проекта',
				text: 'Нажмите для просмотра данных в модальном окне "Интернет-ресурсы размещения контента проекта"',
				icons: ['global'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
			{
				name: 'Просмотр команды проекта',
				text: 'Нажмите для просмотра данных в модальном окне "Команда проекта"',
				icons: ['usergroup-add'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Метки редактора контента',
		LevelGroupData: [
			{
				name: 'Просмотр меток редактора контента',
				text: 'Наведите курсор для просмотра меток редактора контента',
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_CONTENT_EDITOR'],
				screenshots: ['/images/screenshots/label-select.png'],
			},
			{
				name: 'Добавление меток редактора контента',
				text: 'Для добавления метки:',
				points: [
					'Нажмите "Выберите метку"',
					'Нажмите "Добавить новое значение"',
					'Введите новое значение',
					'Сохраните данные',
				],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_CONTENT_EDITOR'],
				screenshots: ['/images/screenshots/label-select-add.png', '/images/screenshots/select-add.png'],
			},
			{
				name: 'Редактирование меток редактора контента',
				text: 'Для редактирование метки:',
				points: [
					'Нажмите "Выбрать метку"',
					'Нажмите на кнопку редактирования',
					'Введите новое значение',
					'Сохраните данные',
				],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_CONTENT_EDITOR'],
				screenshots: ['/images/screenshots/label-select-edit.png'],
			},
			{
				name: 'Удаление меток редактора контента',
				text: 'Для удаления метки:',
				points: ['Нажмите "Выбрать метку"', 'Нажмите на кнопку удаления'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_CONTENT_EDITOR'],
				screenshots: ['/images/screenshots/label-select-delete.png'],
			},
		],
	},
	{
		LevelGroupName: 'Метки редактора каталога',
		LevelGroupData: [
			{
				name: 'Просмотр меток редактора каталога',
				text: 'Наведите курсор для просмотра меток редактора каталога',
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR'],
				screenshots: ['/images/screenshots/label-select.png'],
			},

			{
				name: 'Добавление меток редактора каталога',
				text: 'Для добавления метки:',
				points: [
					'Нажмите "Выбрать метку"',
					'Нажмите "Добавить новое значение"',
					'Введите новое значение',
					'Сохраните данные',
				],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR'],
				screenshots: ['/images/screenshots/label-select-add.png', '/images/screenshots/select-add.png'],
			},
			{
				name: 'Редактирование меток редактора каталога',
				text: 'Для редактирование метки:',
				points: [
					'Нажмите "Выбрать метку"',
					'Нажмите на кнопку редактирования',
					'Введите новое значение',
					'Сохраните данные',
				],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR'],
				screenshots: ['/images/screenshots/label-select-edit.png'],
			},
			{
				name: 'Удаление меток редактора каталога',
				text: 'Для удаления метки:',
				points: ['Нажмите "Выбрать метку"', 'Нажмите на кнопку удаления'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR'],
				screenshots: ['/images/screenshots/label-select-delete.png'],
			},
		],
	},
	{
		LevelGroupName: 'Работа с контентом проекта',
		LevelGroupData: [
			{
				name: 'Просмотр контента проекта',
				text: 'Нажмите "Посмотреть" для просмотра данных в модальном окне',
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
				screenshots: ['/images/screenshots/view-content.png', '/images/screenshots/view-content-modal.png'],
			},
			{
				name: 'Переход в карточку контента по кнопке "Посмотреть контент" из модального окна просмотра контента проекта',
				text: 'Нажмите "Посмотреть контент" для перехода в карточку контента проекта в окне "Список единиц контента проекта"',
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
				screenshots: ['/images/screenshots/content-button.png'],
			},
			{
				name: 'Скачивание единиц контента из модального окна просмотра контента проекта',
				text: 'Для скачивания единиц контента проекта в окне "Список единиц контента проекта":',
				points: [
					'Нажмите чекбокс в баннере "Согласен с положениями Руководства по использованию контента"',
					'Выберите единицу контента из списка и нажмите иконку "Скачать" либо используйте массовое действие "Сформировать архив контента для скачивания"',
				],
				roles: [
					'ROLE_MODERATOR',
					'ROLE_ANALYST',
					'ROLE_ADMIN',
					'ROLE_LAWYER',
					'ROLE_AGENT',
					'ROLE_PROJECT_MANAGER',
					'ROLE_CLIENT',
					'ROLE_ADMIN_IS',
					'ROLE_PRODUCER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
				],
				screenshots: ['/images/screenshots/view-content-modal.png'],
			},
			{
				name: 'Массовое скачивание единиц контента из модального окна просмотра контента проекта',
				text: 'Для массового скачивания единиц контента проекта в модальном окне "Список единиц контента проекта":',
				points: [
					'Нажмите "Выберите массовое действие"',
					'Нажмите "Сформировать архив контента для скачивания"',
					'Нажмите чекбоксы (галочки) для выбора единиц контента для скачивания',
					'Нажмите "Применить"',
				],
				roles: [
					'ROLE_MODERATOR',
					'ROLE_ANALYST',
					'ROLE_ADMIN',
					'ROLE_LAWYER',
					'ROLE_AGENT',
					'ROLE_PROJECT_MANAGER',
					'ROLE_CLIENT',
					'ROLE_ADMIN_IS',
					'ROLE_PRODUCER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
				],
				screenshots: ['/images/screenshots/bulk-actions.png'],
			},
		],
	},
]
