export const ProjectDocumentsActionTypes = {
	GET_DOCUMENTS_WITH_FILTER_START: '[PROJECT DOCUMENTS] GET_DOCUMENTS_WITH_FILTER_START',
	GET_DOCUMENTS_WITH_FILTER_SUCCESS: '[PROJECT DOCUMENTS] GET_DOCUMENTS_WITH_FILTER_SUCCESS',
	GET_DOCUMENTS_WITH_FILTER_ERROR: '[PROJECT DOCUMENTS] GET_DOCUMENTS_WITH_FILTER_ERROR',

	GET_DOCUMENTS_ALL_START: '[PROJECT DOCUMENTS] GET_DOCUMENTS_ALL_START',
	GET_DOCUMENTS_ALL_SUCCESS: '[PROJECT DOCUMENTS] GET_DOCUMENTS_ALL_SUCCESS',
	GET_DOCUMENTS_ALL_ERROR: '[PROJECT DOCUMENTS] GET_DOCUMENTS_ALL_ERROR',

	GET_DOCUMENTS_PROJECT_ID_START: '[PROJECT DOCUMENTS] GET_DOCUMENTS_PROJECT_ID_START',
	GET_DOCUMENTS_PROJECT_ID_SUCCESS: '[PROJECT DOCUMENTS] GET_DOCUMENTS_PROJECT_ID_SUCCESS',
	GET_DOCUMENTS_PROJECT_ID_ERROR: '[PROJECT DOCUMENTS] GET_DOCUMENTS_PROJECT_ID_ERROR',

	UPDATE_DOCUMENTS_START: '[PROJECT DOCUMENTS] UPDATE_DOCUMENTS_START',
	UPDATE_DOCUMENTS_SUCCESS: '[PROJECT DOCUMENTS] UPDATE_DOCUMENTS_SUCCESS',
	UPDATE_DOCUMENTS_ERROR: '[PROJECT DOCUMENTS] UPDATE_DOCUMENTS_ERROR',

	DELETE_DOCUMENTS_START: '[PROJECT DOCUMENTS] DELETE_DOCUMENTS_START',
	DELETE_DOCUMENTS_SUCCESS: '[PROJECT DOCUMENTS] DELETE_DOCUMENTS_SUCCESS',
	DELETE_DOCUMENTS_ERROR: '[PROJECT DOCUMENTS] DELETE_DOCUMENTS_ERROR',

	ADD_DOCUMENTS_START: '[PROJECT DOCUMENTS] ADD_DOCUMENTS_START',
	ADD_DOCUMENTS_SUCCESS: '[PROJECT DOCUMENTS] ADD_DOCUMENTS_SUCCESS',
	ADD_DOCUMENTS_ERROR: '[PROJECT DOCUMENTS] ADD_DOCUMENTS_ERROR',

	SET_PROJECT_DOCUMENTS_FILTER: '[PROJECT DOCUMENTS] SET_PROJECT_DOCUMENTS_FILTER',

	SET_EDITING_KEY: '[PROJECT DOCUMENTS] SET_EDITING_KEY',
	CLEAR_EDITING_KEY: '[PROJECT DOCUMENTS] CLEAR_EDITING_KEY',
} as const
