import {put} from 'redux-saga/effects';

import {
    ClearUploadAction,
    DeleteFileErrorAction,
    DeleteFileStartAction,
    DeleteFileSuccessAction,
    GetUnfinishedStartAction,
} from "../../store/uploader/actions";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import $api from "../../http";
import {GetTasksContentStartAction} from "../../store/tasks/actions/content";


export function* deleteFile({ fileId, taskId }: ReturnType<typeof DeleteFileStartAction>) {
    try {
        if (fileId) {
            yield $api.delete(`/files/uploading/${fileId}`);
        }
        yield put(DeleteFileSuccessAction())
        const location = window.location.href.split('/');
        const page = location[3].split('?')[0];
        if (page === 'task' && taskId) yield put(GetTasksContentStartAction(taskId))
        yield put(GetUnfinishedStartAction())
        yield put(ClearUploadAction())

    } catch (error: any) {
        yield put(DeleteFileErrorAction(error));
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : `Не удалить файл: ${fileId}`))
    }
}