const usersActions = [
	{
		id: '56',
		selector: '.users-actions__datePicker',
		content: 'С помощью фильтра можно искать данные за определенный период времени',
		role: [],
	},
	{
		id: '57',
		selector: '.ant-table-container',
		content:
			'Таблица действий пользователей выводит по умолчанию последние действия всех пользователей системы и ключевую информацию по ним. При нажатии на строку таблицы можно посмотреть список действий  выбранного вами пользователя',
		role: [],
	},
]

export default usersActions
