import { AxiosResponse } from 'axios'
import { SprintTypes } from 'entities/sprints'
import $api from '../http'
import { TSprintResponse } from '../models/sprints'
/**
 * @category Service
 * @class SprintService
 */
export default class SprintService {
	/**
	 * получение списка этапов
	 */
	static sprintsKey = '/sprints/all/'
	static async getSprints(projectId: string): Promise<AxiosResponse<TSprintResponse[]>> {
		return $api.get<TSprintResponse[]>(`/sprints/all/${projectId}`)
	}

	/**
	 * получение эатапа по id
	 */
	static async getSprint(stageId: string): Promise<AxiosResponse<TSprintResponse>> {
		return $api.get<TSprintResponse>(`/sprints/${stageId}`)
	}

	/**
	 * создание нового эатапа
	 */
	static async createSprint(data: SprintTypes.CreateSprint): Promise<AxiosResponse<any>> {
		return $api.post<any>(`/sprints`, data)
	}

	/**
	 * обновление эатапа по id
	 */
	static async updateSprint(stageId: string, data: SprintTypes.CreateSprint): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/sprints/${stageId}`, data)
	}

	/**
	 * удаление эатапа по id
	 */
	static async deleteSprint(sprintId: string): Promise<AxiosResponse<any>> {
		return $api.delete<any>(`/sprints/${sprintId}`)
	}
}
