import React, {FC, SyntheticEvent} from 'react';
import {SendOutlined} from "@ant-design/icons";
import { Spin, Tooltip } from 'antd';
import { TaskTypes } from 'entities/task';
import classNames from "classnames";
import { useSelector } from 'react-redux';
import { selectStatusRecordAction } from 'store/tasks/selectors/selectors-task-report';

type Props = {
    actionInfo: Omit<TaskTypes.TStatus, 'hidden'>
    handler: (event: SyntheticEvent) => void
}
const SendComponent: FC<Props>  = ({actionInfo, handler}) => {
  const isLoading = useSelector(selectStatusRecordAction('send'));
  return (
        <Spin spinning={isLoading}>
          <Tooltip title={"Отправить на согласование"}>
            <div
              data-btn="send"
              className={classNames('item send', {disabled: actionInfo === 'disabled' || isLoading})}
              onClick={(event) => actionInfo !== 'disabled' && handler(event)}>
              <SendOutlined />
            </div>
          </Tooltip>
        </Spin>

    );
}

export default SendComponent;