import {put} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	TasksReportCopyrightService,
	TasksReportEstimateService,
	TasksReportPostingService,
	TasksReportReachService,
} from 'services/tasksReportService'
import {
	InterimVoteTaskReportErrorAction,
	InterimVoteTaskReportStartAction,
	InterimVoteTaskReportSuccessAction,
} from 'store/tasks/actions/report'
import {ReportType} from 'models/task/types'

export function* interimVoteReportTask({ data, typeTask }: ReturnType<typeof InterimVoteTaskReportStartAction>) {
	try {
		//при отправке isInterimApproved (промежуточного голоса) isApproved всегда null
		const requestData = {
			...data,
			vote: { ...data.vote, isApproved: null, isInterimApproved: data.vote.isInterimApproved },
		}

		switch (typeTask) {
			case ReportType.CONTENT_POSTING_REPORT:
				yield TasksReportPostingService.voteTaskReport(requestData)
				break
			case ReportType.COPYRIGHT_LIST:
				yield TasksReportCopyrightService.voteTaskReport(requestData)
				break
			case ReportType.CONTENT_REACH_REPORT:
				yield TasksReportReachService.voteTaskReport(requestData)
				break
			case ReportType.PROJECT_ESTIMATE:
				yield TasksReportEstimateService.voteTaskReport(requestData)
				break
		}

		yield put(InterimVoteTaskReportSuccessAction())
	} catch (error: any) {
		yield put(InterimVoteTaskReportErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось проголосовать'))
	}
}
