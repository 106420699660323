import {axiosRequest} from "../config/axios";
import {Name_Code, PageCode} from "../types/common";
import {IOptions} from "models/feels";

class RoleController {
    static key = (pageCode: string) => `/roles/bulk-actions/${pageCode}`
    static keyOptions = (pageCode: string) => `Options: /roles/bulk-actions/${pageCode}`

    static async bulk(pageCode: PageCode) {
        return  await axiosRequest<Array<Name_Code & {pageCode: PageCode}>>({
            method: 'GET',
            url: '/roles/bulk-actions',
            params: {
                pageCode
            }
        })

    }
    static async bulkOptions(pageCode: PageCode) {
        const {data} = await RoleController.bulk(pageCode)
        return data.map((el) => ({
            value: el.code,
            label: el.name,
        } as IOptions))
    }
}
export default RoleController