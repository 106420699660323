import { axiosRequest } from 'shared/config/axios'
import { FilterSession, SessionsRequest, SessionsResponse, SessionsResponseSchema } from '../types'
import { AxiosResponse } from 'axios'
import { downloadXLXS } from 'utils/downloadXLXS'

export class SessionHandbookController {
	static getWithFilterKey = `/sessions/search`

	/**
	 * Получение списка сессий c фильтрами
	 */
	static async getWithFilter(filter: FilterSession): Promise<AxiosResponse<SessionsResponse>> {
		const newFilter = { ...filter, page: filter.page - 1 }
		const { data } = await axiosRequest<AxiosResponse<SessionsResponse>>({
			method: 'POST',
			url: `/sessions/search`,
			data: newFilter,
			showError: true,
			parser: SessionsResponseSchema,
		})
		return data
	}

	/**
	 * Изменение данных существующей сессии
	 */
	static async updateSession({ sessionId, data }: { sessionId: string; data: SessionsRequest }) {
		await axiosRequest<AxiosResponse<any>>({
			method: 'PUT',
			url: `/sessions/${sessionId}`,
			data,
			showError: true,
		})
	}

	/**
	 * Создание новой сессии
	 */
	static async createSession(data: SessionsRequest) {
		await axiosRequest<AxiosResponse<any>>({
			method: 'POST',
			url: `sessions`,
			data,
			showError: true,
		})
	}

	/**
	 * Удаление сессии
	 */
	static async deleteSession(id: string) {
		await axiosRequest({
			method: 'DELETE',
			url: `/sessions/${id}`,
		})
	}
	/**
	 * Массовое действие
	 */
	static async bulkActions({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) {
		await axiosRequest<AxiosResponse<any>>({
			method: 'POST',
			url: `/sessions/bulk-actions/${bulkAction}`,
			data: rowIds,
			showError: true,
		})
	}
	/**
	 * Выполнение выгрузки списка в формате Excel
	 */
	static async download(value: string[]) {
		const { data }: any = await axiosRequest<AxiosResponse<any>>({
			method: 'POST',
			url: `/sessions/export`,
			responseType: 'arraybuffer',
			headers: {
				'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
			data: value,
			showError: true,
		})
		downloadXLXS(data, 'sessions')
	}
	/**
	 * Получение файла шаблона для выполнения импорта данных о сессиях
	 */
	static async downloadTemplate() {
		const { data }: any = await axiosRequest<AxiosResponse<any>>({
			method: 'GET',
			url: '/sessions/import/template',
			responseType: 'arraybuffer',
			headers: {
				'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
			showError: true,
		})
		downloadXLXS(data, 'sessions-template')
	}
	/**
	 * Выполнение импорта данных о сесииях из заполненного шаблона в формате Excel
	 */
	static async upload(data: FormData) {
		await axiosRequest({
			method: 'POST',
			url: '/sessions/import',
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data,
			showError: true,
		})
	}
}
