import {z} from "zod";

export enum ProjectStatusEnum {
    DRAFT = 'DRAFT', //Черновик
    READY_TO_BE_ACTUAL = 'READY_TO_BE_ACTUAL', //Подготовлен для перевода в актуальное состояние
    ACTUAL = 'ACTUAL', //Текущий
    SUSPENDED = 'SUSPENDED', //Приостановлен
    COMPLETED = 'COMPLETED', //Завершён
    ARCHIVED = 'ARCHIVED', //Архивный
}
export const ProjectStatusNativeEnum = z.enum([
    'DRAFT',
    'READY_TO_BE_ACTUAL',
    'ACTUAL',
    'SUSPENDED',
    'COMPLETED',
    'ARCHIVED'
])
export const ProjectStatusResponseSchema = z.object({
    id: z.string(),
    name: z.string(),
    code: ProjectStatusNativeEnum
})
export type ProjectStatusResponse = z.infer<typeof ProjectStatusResponseSchema>
export type ProjectStatus = z.infer<typeof ProjectStatusNativeEnum>