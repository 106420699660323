import {TSendOrganizations} from "../../../models/users-list/user-form";
import {TUserProfileForm} from "../../../models/user";

export class SendUpdateUserFormDto {
    "firstName": string
    "lastName": string
    "middleName": string
    "imageId": any
    "organizations": TSendOrganizations[]

    constructor(data: TUserProfileForm) {
        this.firstName = data.firstName
        this.imageId = data.imageId && data.image.length === 0 ? data.imageId : null
        this.lastName = data.lastName
        this.middleName = data.middleName
        this.organizations = data.organizations.map((it)=>{
            return {
                organizationId: it.id.value,
                emails: it.emails.map((it,index)=>({
                    email: it.email,
                    isMain: index === 0,
                    isConfirmed: it.isConfirmed
                })),
                phones: it.phones.map((it,index)=>({
                    phone: it.phone,
                    isMain: index === 0,
                    isConfirmed: it.isConfirmed
                }))
            }
        })
    }
}