import {createSelector} from 'reselect';
import {TApplicationState} from "../aplication-state";
import {IOptions} from "models/feels";
import { ProjectTypes } from 'entities/project';

const projectsState = (state: TApplicationState) => state.projects;

export const selectProjectsStatus = createSelector(
    projectsState,
    (state) => ({
        isLoading: state.isLoading,
        error: state.error,
        isLoaded: state.isLoaded,
        project: state.project
    })
)
export const selectProjects = createSelector(
    projectsState,
    (state) => state.projects
)
export const selectProject = createSelector(
    projectsState,
    (state) => state.project
)
export const selectProjectId = createSelector(
    projectsState,
    (state) => state.project?.id
)
export const selectProjectCode = createSelector(
    projectsState,
    (state) => state.project?.code
)

export const selectActualProjectStatus = createSelector(
    projectsState,
    (state) => state.project?.status.code as ProjectTypes.ProjectStatus
)
export const selectProjectIdAndCodeAndStatus = createSelector(
    selectProjectId,
    selectProjectCode,
    selectActualProjectStatus,
    (projectId, projectCode, projectStatus) => ({
        projectId,
        projectCode,
        projectStatus
    })
)
export const selectProjectsTypesOptions = createSelector(
    projectsState,
    (state) => state.projectTypes ? state.projectTypes.map((el) => {
        return {value: el.id, label: el.name, code: el.code} as IOptions
    }) : []
)

export const selectActualProjectIsEmpty = createSelector(
    projectsState,
    (state) => !!state.actualProjects
)
export const selectProjectsFilter = createSelector(
    projectsState,
    (state) => state.filter
)

export const selectActualProjects = createSelector(
    projectsState,
    (state) => state.actualProjects
)
export const selectActualProjectsOptions = createSelector(
    projectsState,
    (state) => state.actualProjects ? state.actualProjects.map(el => {
        return {value: el.id, label: el.name, code: el.status.code} as IOptions
    }) : null
)

export const selectProjectsReachData = createSelector(
    projectsState,
    (state) => state.projectReachData ? state.projectReachData.map(el => {
        return {value: el.id, label: el.name} as IOptions
    }) : null
)
export const selectProjectsReporting= createSelector(
    projectsState,
    (state) => state.projectsReporting ? state.projectsReporting.map(el => {
        return {value: el.id, label: el.name} as IOptions
    }) : null
)

export const selectProjectInfoForGant = createSelector(
    projectsState,
    (state) => ({
        projectStatus: state.project?.status.code,
        projectCode: state.project?.code,
        projectId: state.project?.id,
        projectBudget: state.project?.budget
    })
)