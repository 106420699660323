import {Routes} from "models/routes";
import {REPORTING} from "routing/names";
import {lazy} from "react";
import {UserTypes} from 'entities/user'

const ReportingPage = lazy(() => import('pages/reporting/reporting'))

const roleHasNotAccess: UserTypes.RoleName[] = ['ROLE_CONTRACTOR', 'ROLE_CLIENT', 'ROLE_VIEWER', 'ROLE_AGENT']

const reportRoutes: Routes[] = [
    {path: REPORTING.REPORTING, Component: ReportingPage, name: 'Настройки222', exact: false, roleHasNotAccess},
]

export default reportRoutes;