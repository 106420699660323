import {put} from 'redux-saga/effects'
import {
	BulkRegionsActionsErrorAction,
	BulkRegionsActionsStartAction,
	BulkRegionsActionsSuccessAction,
	GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import RegionService from "services/regionService";

export function* bulkRegionsActions({ userArr, action }: ReturnType<typeof BulkRegionsActionsStartAction>) {
	try {
		yield RegionService.bulkRegionsActions(userArr, action)

		yield put(BulkRegionsActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.regions))
		yield put(NotificationOpen('success', 'Обновлено успешно'))

	} catch (error: any) {
		yield put(BulkRegionsActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}
}
