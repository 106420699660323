import {put, select} from "redux-saga/effects";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {FilterValues} from "features/user-filters/model/store/reducer";
import {setUserFiltersValue} from "features/user-filters/model/store/actions";
import Api from "features/user-filters/api";
import {Logger} from "shared/services";
import {TTaskRecordsFilter} from "models/task/task-list";
import {selectTasksRecordsFilter, selectTasksRecordsInfo} from "store/tasks/selectors/tasks-records";

export function* tasksRecords(typeId: string, filter?: FilterValues) {
    try {

        const filterTasks: TTaskRecordsFilter = yield select(selectTasksRecordsFilter)
        const {typeTask, typeReport} = yield select(selectTasksRecordsInfo)

        if (filter?.activeFilterId) {
            yield Api.update(filter.activeFilterId, {
                filterTypeId: typeId,
                name: filter.filterName,
                filterData: JSON.stringify({filterData:filterTasks.filterData,typeTask,typeReport})
            })
        } else {
            yield Api.create({
                filterTypeId: typeId,
                name: filter?.filterName,
                filterData: JSON.stringify({filterData:filterTasks.filterData,typeTask,typeReport})
            })
        }
    } catch (error) {
        Logger.error('createFilterWorker/search', error)
        yield put(setUserFiltersValue({error: error}))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
    }
}