import {put} from "redux-saga/effects";

import {
    DeleteProjectErrorAction,
    DeleteProjectStartAction,
    DeleteProjectSuccessAction,
    GetProjectsStartAction,
} from "store/projects/actions";

import Api from "entities/project/api/project";
import {NotificationOpen} from "store/notification/actions";

export function* deleteProjects({projectId}: ReturnType<typeof DeleteProjectStartAction>) {
    try {
        yield Api.deleteProject(projectId);
        yield put(DeleteProjectSuccessAction());
        yield put(NotificationOpen("success", 'Проект удален'));
        yield put(GetProjectsStartAction())
    } catch (error: any) {
        yield put(NotificationOpen("error", 'Ошибка', 'Не удалось удалить проект'))
        yield put(DeleteProjectErrorAction(error))
    }
}