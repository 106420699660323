import {ModalsActionTypes} from "./action-types";
import {TModalTypes} from "./reducer";
import {ModalTeamsData} from "../../components/modals/modal-teams/modal-teams";

type ModalData = ModalTeamsData | any
export const ModalOpenAction = (typeModal: TModalTypes, data?: ModalData) => ({
    type: ModalsActionTypes.MODAL_OPEN,
    typeModal,
    data
})
export const ModalCloseAction = () => ({
    type: ModalsActionTypes.MODAL_CLOSE
})
