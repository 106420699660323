const content = [
	{
		id: '1',
		selector: '.ContentItemPage__header',
		content: 'Название проекта и его ключевые параметры ',
		role: []
	},
	{
		id: '2',
		selector: '.status',
		content: 'Показываем тип и статус реализации проекта на текущий момент',
		role: []
	},
	{
		id: '3',
		selector: '.links',
		content: 'Позволяет перейти в карточку проекта, увидеть задачи проекта и  документы, а также копировать ссылку к папке хранения контента проекта',
		role: ['ROLE_CONTRACTOR','ROLE_ADMIN','ROLE_PRODUCER','ROLE_ADMIN_IS']
	},
	{
		id: '3_1',
		selector: '.links',
		content: 'Позволяет посмотреть данные о публикациях контента, ознакомиться со списком единиц контента, допустимых к скачиванию',
		role: ['ROLE_CLIENT', "ROLE_AGENT"]
	},
	{
		id: '3_2',
		selector: '.links',
		content: 'Позволяет ознакомиться с другими данными проекта, перейти в задачи и документы проекта, скопировать ссылку к папке хранения контента, просмотреть данные о публикациях контента, воспользоваться функционалом скачивания единиц контента',
		role: ['ROLE_COORDINATOR','ROLE_ANALYST','ROLE_FINANCIER','ROLE_LAWYER','ROLE_MODERATOR','ROLE_PROJECT_MANAGER']
	},
	{
		id: '3_3',
		selector: '.links',
		content: 'Позволяет перейти в карточку проекта',
		role: ['ROLE_VIEWER'],
	},
	{
		id: '4_1',
		selector: '.ContentItemPage__footer__cards',
		content: 'Представлены разделы в виде названий единиц контента, содержащие файлы мастеров / предмастеров контента в статусах “Одобрено” или “Отклонено”',
		role: ['ROLE_CONTRACTOR','ROLE_ADMIN','ROLE_PRODUCER','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS'],
	},
	{
		id: '4_2',
		selector: '.ContentItemPage__footer__cards',
		content: 'Представлены разделы в виде названий единиц контента, содержащие файлы мастеров / предмастеров контента',
		role: ['ROLE_VIEWER'],
	}
]
export default content