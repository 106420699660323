import { FunctionalityInfoType } from '../types'

export const optionsNotifications: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка уведомлений с поиском, фильтрацией, сортировкой, пагинацией',
		LevelGroupData: [
			{
				name: 'Поиск уведомлений',
				text: 'Для поиска:',
				points: ['Нажмите "Поиск уведомления"', 'Введите название уведомления'],
				screenshots: ['/images/screenshots/options-notifications/search.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Фильтрация уведомлений',
				text: 'Выберите значения для фильтрации списка',
				screenshots: ['/images/screenshots/options-notifications/filters.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Сортировка отображения таблицы уведомлений',
				text: 'Нажимайте на стрелки для сортировки отображения таблицы',
				screenshots: ['/images/screenshots/options-notifications/sorting.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Индикатор доступа пользователей к настройкам получения уведомлений',
				text: 'Индикатор доступа пользователей к настройкам получения уведомлений:',
				points: [
					{
						label: 'Зеленый - настройки получения доступны пользователям',
						icon: 'setting-green'
					},
					{
						label: 'Красный - настройки получения недоступны пользователям',
						icon: 'setting-red'
					}
				],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование уведомлений',
		LevelGroupData: [
			{
				name: 'Массовое включение / выключение уведомлений',
				text: 'Для массового выполнения действий:',
				points: [
					'Нажмите "Массовые действия"',
					'Выберите массовое действие из выпадающего списка',
					'Выберите уведомления',
					'Нажмите "Применить"',
				],
				screenshots: ['/images/screenshots/options-notifications/bulk-actions.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Редактирование уведомлений ',
				text: 'Для массового выполнения действий:',
				points: [
					'Нажмите для перехода в форму и редактирования данных уведомления',
					'Заполните обязательные и доступные поля формы',
					'Включите / выключите уведомления по кнопке "Вкл / Выкл"',
					'Нажмите “Сохранить” ',
				],
				topIcons: ['edit-blue'],
				screenshots: ['/images/screenshots/options-notifications/form.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
]
