import React, { FC } from 'react';
import styles from './styles.module.less';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NotVotedRecordCounts } from '../../types';
import NameCenterCell from 'components/table-cells/name-center-cell';
import { useQuery } from 'react-query';
import { ProjectViewDetailsController } from '../../api';
import classNames from 'classnames';

const AgreementOfProjectTaskRecords: FC<{projectId: string, projectName: string}> = ({projectId, projectName}) => {
	const {data, isLoading} = useQuery({
		queryKey: [ProjectViewDetailsController.notVotedRecordCounts.key(projectId)],
		queryFn: () => ProjectViewDetailsController.notVotedRecordCounts.fetch(projectId),
		cacheTime: 180000,
	})

	const columns: ColumnsType<NotVotedRecordCounts> = [
		{
			title: 'ФИО проверяющего',
			dataIndex: 'inspector',
			key: 'inspector',
			width: 150,
			render: (inspector: NotVotedRecordCounts['inspector']) => NameCenterCell(
				`${inspector.lastName} ${inspector.firstName} ${inspector.middleName}`
			)
		},
		{
			title: 'Роль',
			dataIndex: 'id',
			key: 'id',
			width: 120,
			render: (_: any, record) => NameCenterCell(record.inspector.role.ruName)
		},
		{
			title: 'Непроверенные записи задач',
			children: [
				{
					title: 'Контент',
					dataIndex: 'contentTaskNotVotedRecordCount',
					key: 'contentTaskNotVotedRecordCount',
					width: 80,
					render: NameCenterCell
				},
				{
					title: 'Размещение',
					dataIndex: 'postingTaskNotVotedRecordCount',
					key: 'postingTaskNotVotedRecordCount',
					width: 110,
					render: NameCenterCell
				},
				{
					title: 'Охват',
					dataIndex: 'reachTaskNotVotedRecordCount',
					key: 'reachTaskNotVotedRecordCount',
					width: 70,
					render: NameCenterCell
				},
				{
					title: 'Авторские права',
					dataIndex: 'copyrightTaskNotVotedRecordCount',
					key: 'copyrightTaskNotVotedRecordCount',
					width: 100,
					render: NameCenterCell
				},
				{
					title: 'Смета проекта',
					dataIndex: 'estimateTaskNotVotedRecordCount',
					key: 'estimateTaskNotVotedRecordCount',
					width: 80,
					render: NameCenterCell
				},
			]
		},
	]
	return (
		<div className={styles.container}>
			<div className={styles.title}>
				<h2>Согласование записей задач проекта “{projectName}”</h2>
			</div>
			<Table
				className={classNames('table', styles.table)}
				dataSource={data || []}
				columns={columns}
				sticky
				scroll={{x: 600}}
				loading={isLoading}
				pagination={false}
			/>
		</div>
	)
};

export default AgreementOfProjectTaskRecords;