import {useQuery, UseQueryResult} from "react-query";
import {transformArrayIdNameForValue} from "utils/transformObjectFeels";
import {useMemo} from "react";
import {Id_Name_Code_Hidden} from "shared/types/common";
import {getFilmClusters} from "../api";

type onSuccessType = (data: Id_Name_Code_Hidden[]) => void
export function useGetFilmClusters(onSuccess?: onSuccessType): UseQueryResult<Id_Name_Code_Hidden[]> {
    return useQuery({
        queryKey: [getFilmClusters.key],
        queryFn: () => getFilmClusters.fetch(),
        onSuccess,
    });
}
export function useGetFilmClustersOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetFilmClusters(onSuccess);
    const options = useMemo(() => transformArrayIdNameForValue(data), [data]);
    return { data: options, ...rest };
}