import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    AddContentDeliveryErrorAction,
    AddContentDeliveryStartAction,
    AddContentDeliverySuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import HandbookService from "services/handbookService";

export function* addContentDelivery({ data}: ReturnType<typeof AddContentDeliveryStartAction>) {
    try {
        yield HandbookService.addContentDeliveryRecord(data)
        yield put(AddContentDeliverySuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.contentDelivery))

        yield put(NotificationOpen('success', 'Новый тип формы сдачи добавлен'))
    } catch (error: any) {
        yield put(AddContentDeliveryErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить тип формы сдачи'))
    }
}
