import {TOption} from "models/projects/project-form";
import {TDefault} from "./project-form.dto";
import {TTag, TTagSecondLevel} from "models/tags";
import {ProjectTypes} from "entities/project";
import {TInfoProject} from "../../ui/form-container";
import {TRole} from "models/user";


export class StepSecondDto {
    contentThematics: TDefault<TOption[] | []>

    zeroLevelTags: TDefault<TOption[] | []>
    firstLevelTags: TDefault<TOption[] | []>
    secondLevelTags: TDefault<TOption[] | []>
    thirdLevelTags: TDefault<TOption[] | []>
    firstLevelAssignmentTags: TDefault<TOption[] | []>
    secondLevelAssignmentTags: TDefault<TOption[] | []>

    regionAboutSet: TDefault<TOption[] | []>
    regionForSet: TDefault<TOption[] | []>

    contentDirection: TDefault<TOption | string | null>
    contentFormats: TDefault<ContentFormatsDto[] | []>

    firstContentCreationDate: TDefault<string | Date | null>
    allContentCreationDate: TDefault<string | Date | null>
    firstContentPostingDate: TDefault<string | Date | null>
    allContentPostingDate: TDefault<string | Date | null>
    allContentReachFixDate: TDefault<string | Date | null>

    info?: TInfoProject;
    constructor(project?: ProjectTypes.Project | null) {
        this.contentThematics = project ?
            {value: project.contentThematics.map((el) => new ContentThematicsDto(el))} :
            {value: []}
        this.contentDirection = project?.contentDirection ?
            {
                value: {
                    value: project.contentDirection.id,
                    label: project.contentDirection.name
                } as TOption
            } : {value: null}
        this.contentFormats = project ?
            {value: project.contentFormats.map((el) => new ContentFormatsDto(el))} :
            {value: []}
        this.firstContentCreationDate = project ? {value: project.firstContentCreationDate} : {value: ''};
        this.allContentCreationDate = project ? {value: project.allContentCreationDate} : {value: ''};
        this.firstContentPostingDate = project ? {value: project.firstContentPostingDate} : {value: ''};
        this.allContentPostingDate = project ? {value: project.allContentPostingDate} : {value: ''};
        this.allContentReachFixDate = project ? {value: project.allContentReachFixDate} : {value: ''};
        this.zeroLevelTags = project?.zeroLevelTags?.length ? {value: project.zeroLevelTags.map((el) => new TagsDto(el)),} : {value: []}
        this.firstLevelTags = project?.firstLevelTags?.length ? {value: project.firstLevelTags.map((el) => new TagsDto(el)),} : {value: []}
        this.secondLevelTags = project?.secondLevelTags?.length ? {value: project.secondLevelTags.map((el) => new TagsDto(el))} : {value: []}
        this.thirdLevelTags = project?.thirdLevelTags?.length ? {value: project.thirdLevelTags.map((el) => new TagsDto(el))} : {value: []}
        this.firstLevelAssignmentTags = project?.firstLevelAssignmentTags?.length ? {value: project.firstLevelAssignmentTags.map((el) => new TagsDto(el))} : {value: []}
        this.secondLevelAssignmentTags = project?.secondLevelAssignmentTags?.length ? {value: project.secondLevelAssignmentTags.map((el) => new TagsDto(el))} : {value: []}
        this.regionAboutSet = project?.regionAboutSet?.length ? {value: project.regionAboutSet.map((el) => ({value: el.id, label: el.name} as TOption))} : {value: []}
        this.regionForSet = project?.regionForSet?.length ? {value: project.regionForSet.map((el) => ({value: el.id, label: el.name} as TOption))} : {value: []}
    }

    validate(info: TInfoProject) {
        this.info = info;
        for (const key in this) {
            // @ts-ignore
            if (this[key]) this[key].isDisabled = validate(key, info)
        }
        return this;
    }
    validateProjectType(type?: string) {
        if (type === 'CONTENT_CREATION') {
            this.firstContentPostingDate = {value: null, isDisabled: true}
            this.allContentPostingDate = {value: null, isDisabled: true}
            this.allContentReachFixDate = {value: null, isDisabled: true}
        } else {
            if (this.info) {
                this.validate(this.info)
            } else {
                this.firstContentPostingDate.isDisabled = false;
                this.allContentPostingDate.isDisabled = false;
                this.allContentReachFixDate.isDisabled = false;
            }
        }
        return this;
    }
    validateZeroLevelTags(projectStatus?: ProjectTypes.Project["status"]['code'], role?: TRole) {
        if (role === 'ROLE_ANALYST' && (projectStatus === 'ACTUAL' || projectStatus === 'ARCHIVED')) {
            this.zeroLevelTags = {value: this.zeroLevelTags.value, isDisabled: false}
            this.firstLevelTags = {value: this.firstLevelTags.value, isDisabled: false}
            this.secondLevelTags = {value: this.secondLevelTags.value, isDisabled: false}
            this.thirdLevelTags = {value: this.thirdLevelTags.value, isDisabled: false}
        }
        return this;
    }
    validateLevelTags(projectStatus?: ProjectTypes.Project["status"]['code']) {
        if (projectStatus === 'COMPLETED' || projectStatus === 'SUSPENDED') {
            this.zeroLevelTags = {value: this.zeroLevelTags.value, isDisabled: true}
            this.firstLevelTags = {value: this.firstLevelTags.value, isDisabled: true}
            this.secondLevelTags = {value: this.secondLevelTags.value, isDisabled: true}
            this.thirdLevelTags = {value: this.thirdLevelTags.value, isDisabled: true}
            this.firstLevelAssignmentTags = {value: this.firstLevelAssignmentTags.value, isDisabled: true}
            this.secondLevelAssignmentTags = {value: this.secondLevelAssignmentTags.value, isDisabled: true}
        }
        return this;
    }
}

class ContentThematicsDto implements TOption {
    label;
    value;

    constructor(el: ProjectTypes.Project['contentThematics'][0]) {
        this.value = el.id;
        this.label = el.name;
    }
}
class TagsDto implements TOption {
    label;
    value;

    constructor(el: TTag | TTagSecondLevel) {
        this.value = el.id;
        this.label = el.name;
    }
}
class ContentFormatsDto {
    type: TOption
    contentCount?: number
    submissionForms?: TOption[]
    isAccountedContent?: boolean | null
    isContent?: boolean | null
    id: string

    constructor(model: ProjectTypes.ContentFormats) {
        this.contentCount = model.contentCount
        this.isAccountedContent = model.isAccountedContent
        this.isContent = model.isContent
        this.contentCount = model.contentCount
        this.id = model.id
        this.submissionForms = model.submissionForms.map((el) => ({label: el.name, value: el.id}))
        this.type = {value: model.type.id, label: model.type.name}
    }
}

function validate(field: keyof StepSecondDto, info: TInfoProject): boolean {
    let res = false;

    if (!info) return res
    const {role, status} = info;
    if (role === 'ROLE_CONTRACTOR') res = true
    if ((role === 'ROLE_ADMIN') || (role ==='ROLE_ADMIN_IS')) {
        if (status === 'DRAFT') res = false;
        else if (status === 'SUSPENDED' || status === 'COMPLETED') res = true
        else if (status === 'ACTUAL' || status === 'ARCHIVED') res = false
    }
    if ((role === 'ROLE_PRODUCER')) {
        if (status === 'DRAFT') res = true
        else if (status === 'SUSPENDED' || status === 'COMPLETED')   res = true
        else if (status === 'ACTUAL' || status === 'ARCHIVED') res = false
    }
    if ((role === 'ROLE_COORDINATOR') || (role ==='ROLE_MODERATOR') || (role === 'ROLE_PROJECT_MANAGER')) {
        if (status === 'DRAFT') res = false;
        else if (status === 'SUSPENDED' || status === 'COMPLETED') res = true
        else if (status === 'ACTUAL' || status === 'ARCHIVED') res = false
    }
    if ((role === 'ROLE_ANALYST') || (role === 'ROLE_FINANCIER') || (role === 'ROLE_LAWYER')) res = true;
    if (role === 'ROLE_CONTENT_EDITOR') {
        res = true
        if (status === 'ACTUAL' || status === 'ARCHIVED') {
            if (field === 'contentFormats') res = false;
        }
    }
    return res
}