import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteRecordsErrorAction,
    DeleteRecordsStartAction,
    DeleteRecordsSuccessAction, GetArchiveListStartAction
} from "../../store/archive/actions";
import ArchiveService from "../../services/archiveService";

export default function* deleteRecords ( { ids}: ReturnType<typeof DeleteRecordsStartAction>) {
    try {
        yield ArchiveService.deleteRecords(ids);
        yield put(DeleteRecordsSuccessAction())
        yield put(GetArchiveListStartAction())

    } catch (error: any) {
        yield put(DeleteRecordsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить'))
    }
}