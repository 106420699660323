import {ProjectsActionTypes} from "./action-types";
import {ProjectType} from "models/project";
import {TProjectsFilter} from "models/projects/filter";
import {TActualOrArchivedProjects} from "models/projects/actual-projects";
import {T_ID_NAME, T_ID_NAME_CODE} from "models/common";
import {TProjectsState} from "./reducer";
import {CreateProject, EditProject} from "features/create-or-edit-project-form";
import {ProjectTypes} from "entities/project";


export const GetProjectsStartAction = () => ({
    type: ProjectsActionTypes.GET_PROJECTS_START
})
export const GetProjectsSuccessAction = (project: ProjectTypes.ProjectsListResponse) => ({
    type: ProjectsActionTypes.GET_PROJECTS_SUCCESS,
    project
})
export const GetProjectsErrorAction = (error: any) => ({
    type: ProjectsActionTypes.GET_PROJECTS_ERROR,
    error
})
/**
 * GET PROJECT
 */
export const GetProjectStartAction = (projectId: string) => ({
    type: ProjectsActionTypes.GET_PROJECT_START,
    projectId
})
export const GetProjectSuccessAction = (project: ProjectTypes.Project | null) => ({
    type: ProjectsActionTypes.GET_PROJECT_SUCCESS,
    project
})
export const GetProjectErrorAction = (error: any) => ({
    type: ProjectsActionTypes.GET_PROJECT_ERROR,
    error
})
/**
 * Create PROJECT
 */
export const CreateProjectStartAction = (project: CreateProject) => ({
    type: ProjectsActionTypes.CREATE_PROJECTS_START,
    project
})
export const CreateProjectSuccessAction = () => ({
    type: ProjectsActionTypes.CREATE_PROJECTS_SUCCESS
})
export const CreateProjectErrorAction = (error: any) => ({
    type: ProjectsActionTypes.CREATE_PROJECTS_ERROR,
    error
})
/**
 * Update PROJECT
 */
export const UpdateProjectStartAction = (projectId: string, project: EditProject) => ({
    type: ProjectsActionTypes.UPDATE_PROJECT_START,
    projectId, project
})
export const UpdateProjectSuccessAction = (project?: ProjectType) => ({
    type: ProjectsActionTypes.UPDATE_PROJECT_SUCCESS,
    project
})
export const UpdateProjectErrorAction = (error: any) => ({
    type: ProjectsActionTypes.UPDATE_PROJECT_ERROR,
    error
})
/**
 * Delete PROJECT
 */
export const DeleteProjectStartAction = (projectId: string) => ({
    type: ProjectsActionTypes.DELETE_PROJECT_START,
    projectId
})
export const DeleteProjectSuccessAction = () => ({
    type: ProjectsActionTypes.DELETE_PROJECT_SUCCESS
})
export const DeleteProjectErrorAction = (error: any) => ({
    type: ProjectsActionTypes.DELETE_PROJECT_ERROR,
    error
})
/**
 * Изменение фильтра проектов
 */
export const SetProjectFilter = (filter: TProjectsFilter) => ({
    type: ProjectsActionTypes.SET_PROJECTS_FILTER,
    filter
})
export const SetProjectInfo = <T extends Partial<TProjectsState>>(value: T) => ({
    type: ProjectsActionTypes.SET_PROJECTS_INFO,
    value
})
/**
 * Получение списка текущих проектов без пагинации
 */
export const GetActualOrArchivedProjectsStartAction = (sprintExist?: boolean) => ({
    type: ProjectsActionTypes.GET_ACTUAL_OR_ARCHIVED_PROJECTS_START, sprintExist
})
export const GetActualOrArchivedProjectsSuccessAction = (projects: TActualOrArchivedProjects[]) => ({
    type: ProjectsActionTypes.GET_ACTUAL_OR_ARCHIVED_PROJECTS_SUCCESS,
    projects
})
export const GetActualOrArchivedProjectsErrorAction = (error: any) => ({
    type: ProjectsActionTypes.GET_ACTUAL_OR_ARCHIVED_PROJECTS_ERROR,
    error
})

/**
 * Изменение статуса проекта
 */
export const UpdateProjectStatusStartAction = (projectId: string, statusId: string) => ({
    type: ProjectsActionTypes.UPDATE_PROJECT_STATUS_START,
    projectId, statusId
})
export const UpdateProjectStatusSuccessAction = () => ({
    type: ProjectsActionTypes.UPDATE_PROJECT_STATUS_SUCCESS,
})
export const UpdateProjectStatusErrorAction = (error: any) => ({
    type: ProjectsActionTypes.UPDATE_PROJECT_STATUS_ERROR,
    error
})

/**
 * Получение списка проектов содержащих данные по охватам
 */
export const GetProjectsReachDataStartAction = (sessionId: string) => ({
    type: ProjectsActionTypes.GET_PROJECTS_REACH_DATA_START,
    sessionId
})
export const GetProjectsReachDataSuccessAction = (projects: T_ID_NAME[]) => ({
    type: ProjectsActionTypes.GET_PROJECTS_REACH_DATA_SUCCESS,
    projects
})
export const GetProjectsReachDataErrorAction = (error: any) => ({
    type: ProjectsActionTypes.GET_PROJECTS_REACH_DATA_ERROR,
    error
})
/**
 * Получение списка проектов для аналитики (reporting)
 */
export const GetProjectsReportingStartAction = (sessionId: string) => ({
    type: ProjectsActionTypes.GET_PROJECTS_REPORTING_START,
    sessionId
})
export const GetProjectsReportingSuccessAction = (projects: T_ID_NAME[]) => ({
    type: ProjectsActionTypes.GET_PROJECTS_REPORTING_SUCCESS,
    projects
})
export const GetProjectsReportingErrorAction = (error: any) => ({
    type: ProjectsActionTypes.GET_PROJECTS_REPORTING_ERROR,
    error
})

export const BulkProjectsStartAction = (projectsId: string[], actionType: string) => ({
    type: ProjectsActionTypes.BULK_PROJECT_START,
    projectsId, actionType
})
export const BulkProjectsSuccessAction = () => ({
    type: ProjectsActionTypes.BULK_PROJECT_SUCCESS,
})
export const BulkProjectsErrorAction = (error: any) => ({
    type: ProjectsActionTypes.BULK_PROJECT_ERROR,
    error
})

export const DownloadProjectsStartAction = (projectsIds: string[], name: string) => ({
    type: ProjectsActionTypes.DOWNLOAD_PROJECT_LIST_START, projectsIds, name
})
export const DownloadProjectsSuccessAction = () => ({
    type: ProjectsActionTypes.DOWNLOAD_PROJECT_LIST_SUCCESS
})
export const DownloadProjectsErrorAction = (error: any) => ({
    type: ProjectsActionTypes.DOWNLOAD_PROJECT_LIST_ERROR, error
})

export const GetProjectsTypesStartAction = () => ({
    type: ProjectsActionTypes.GET_PROJECTS_TYPES_START
})
export const GetProjectsTypesSuccessAction = (types: T_ID_NAME_CODE) => ({
    type: ProjectsActionTypes.GET_PROJECTS_TYPES_SUCCESS,
    types
})
export const GetProjectsTypesErrorAction = (error: any) => ({
    type: ProjectsActionTypes.GET_PROJECTS_TYPES_ERROR,
    error
})
export const GetProjectsTypesClearAction = (error: any) => ({
    type: ProjectsActionTypes.GET_PROJECTS_TYPES_CLEAR,
    error
})

/**
 * Скачать шаблон проектов
 */

export const DownloadProjectsTemplateStartAction = () => ({
    type: ProjectsActionTypes.DOWNLOAD_TEMPLATE_START,
})
export const DownloadProjectsTemplateSuccessAction = () => ({
    type: ProjectsActionTypes.DOWNLOAD_TEMPLATE_SUCCESS,
})
export const DownloadProjectsTemplateErrorAction = (error: any) => ({
    type: ProjectsActionTypes.DOWNLOAD_TEMPLATE_ERROR,
    error
})

/**
 * Загрузка данных в excel
 */

export const UploadProjectsStartAction = (data: any) => ({
    type: ProjectsActionTypes.UPLOAD_START,
    data,
})
export const UploadProjectsSuccessAction = () => ({
    type: ProjectsActionTypes.UPLOAD_SUCCESS,
})
export const UploadProjectsErrorAction = (error: any) => ({
    type: ProjectsActionTypes.UPLOAD_ERROR,
    error,
})

export const ClearProjectsErrorAction = () => ({
    type: ProjectsActionTypes.CLEAR_ERROR,
})

/**
 * Очищение массовых действий без запроса на сервер
 */
export const ClearBulkActions = () => ({
    type: ProjectsActionTypes.CLEAR_BULK_ACTIONS,

})