import React, {FC, SyntheticEvent} from 'react';
import {DeleteOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import {TaskTypes} from 'entities/task';
import classNames from "classnames";
import {useDispatch} from "react-redux";
import {ModalOpenAction} from "store/modals/actions";

type Props = {
    actionInfo: Omit<TaskTypes.TStatus, 'hidden'>
    handler: (event: SyntheticEvent) => void
}
const DeleteComponent: FC<Props> = ({actionInfo, handler}) => {
    const put = useDispatch()

    const onConfirm = () => put(ModalOpenAction('confirm-delete',{term: 'запись', onConfirm: handler}))

    return (
        <Tooltip title={"Удалить"}>
            {actionInfo === 'disabled' ? (
                <div
                    data-btn="delete"
                    className={classNames('item delete', {disabled: actionInfo === 'disabled'})}
                >
                    <DeleteOutlined />
                </div>
            ) : (
                <div
                    data-btn="delete"
                    className={'item delete'}
                    onClick={onConfirm}
                >
                    <DeleteOutlined />
                </div>
            )}
        </Tooltip>
    );
}

export default DeleteComponent;