import moment from "moment/moment";
import {ProjectFormDto} from "../dto/project-form.dto";
import {TestContext, ValidationError} from "yup";

export function getStatus(context: TestContext): string | null {
    // @ts-ignore
    const values: Array<{schema: unknown, value: ProjectFormDto}> = context.from
    const length = values.length;
    // @ts-ignore
    return values[length - 1].value.stepFirst.status.value
}
export function statusIsNullOrDraftOrReadyToBeActual(context: TestContext): boolean {
    // @ts-ignore
    const values: Array<{schema: unknown, value: ProjectFormDto}> = context.from;
    const length = values.length;
    // @ts-ignore
    const status = values[length - 1].value.stepFirst.status.value?.code;
    return status === null || status === undefined || status === 'DRAFT' || status === 'READY_TO_BE_ACTUAL';
}
export function getValue(context: TestContext): ProjectFormDto {
    // @ts-ignore
    const values: Array<{schema: unknown, value: ProjectFormDto}> = context.from
    const length = values.length;
    // @ts-ignore
    return values[length - 1].value
}
export function validateIsAfter(val1: string | Date, val2: string | Date | null) {
    return moment(val1, "YYYY-MM-DD", 'ru_RU', true).isAfter(moment(val2, "YYYY-MM-DD", 'ru_RU', true), 'day')
}
export function validateIsBefore(val1: string | Date, val2: string | Date | null) {
    return moment(val1, "YYYY-MM-DD", 'ru_RU', true).isBefore(moment(val2, "YYYY-MM-DD", 'ru_RU', true), 'day')
}
export function ValidateDateRequired(val: { value: string | Date | null }): boolean {
    if (val.value === 'null' || (typeof val.value === 'string' && val.value.length > 0)) return true
    return typeof val.value === 'object'
}

export function ValidateDates(
    {value, context, form, label, path}: {
        value: string | Date,
        form: ProjectFormDto,
        context: TestContext,
        label: string,
        path: string
    }
): boolean | ValidationError {
    const agreementDate = form.stepFirst.agreementDate.value;
    const contractDate = form.stepFirst.contractDate.value;

    if (!!agreementDate && validateIsBefore(value, agreementDate)) {
        return context.createError({
            path,
            message: `Поле «${label}» не может быть раньше чем дата «Дата соглашения»`
        })
    } else if (!!contractDate && !agreementDate && validateIsBefore(value, contractDate)) {
        return context.createError({
            path,
            message: `Поле «${label}» не может быть раньше чем дата «Дата договора»`
        })
    }


    const completionDate = form.stepFirst.completionDate.value;
    if (!!completionDate && validateIsAfter(value, completionDate)) {
        return context.createError({
            path,
            message: `Поле «${label}» не может быть позже чем дата «Дата завершения проекта»`
        })
    }
    return true;
}