import {FilesActionTypes} from "./action-types";

/**
 * @description Получение файлов
 */
export const GetFileStartAction = (id: string, fileType?: string | 'image' | 'PDF' | 'IMAGE') => ({
    type: FilesActionTypes.GET_FILES_START,
    id, fileType
})
export const GetFileSuccessAction = (id: string, file: any) => ({
    type: FilesActionTypes.GET_FILES_SUCCESS,
    id, file,
});
export const GetFileErrorAction = (id: string, error: any) => ({
    type: FilesActionTypes.GET_FILES_ERROR,
    id, error,
});

