import {AxiosResponse} from "axios";
import $api from "../http";
import {T_ID_NAME_CODE_HIDDEN} from "models/common";
import {THandbookFilter, TRegions, TTagsResponse} from "../models/handbook";

export default class RegionService {
    /**
     * Получение всех regions
     */
    static getRegions(): Promise<AxiosResponse<T_ID_NAME_CODE_HIDDEN[]>> {
        return $api.get('/region/types')
    }
    /**
     * Получение всех regions c filters
     */
    static async getRegionsWithFilter(data: THandbookFilter): Promise<AxiosResponse<TTagsResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<TTagsResponse>('/region/types/search', filter)
    }
    /**
     *  Добавление регионов
     */
    static async addIRegionsRecord( data: TRegions): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/region/types`, data)
    }
    /**
     *   Обновление регионов
     */
    static async updateRegionsRecord( data: TRegions, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/region/types/${id}`, data)
    }
    /**
     *   Удаление регионов
     */
    static async deleteRegionsRecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/region/types/${id}`)
    }
    /**
     *   Массовые действия регионов
     */
    static async bulkRegionsActions(recordsArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/region/types/bulk-actions/${action}`, recordsArr)
    }
}