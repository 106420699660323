import {ActionTypes} from './types'
import {InferValueTypes} from "models/common";
import * as actions from "./actions";
import { SearchContentProjectTypes } from 'features/search-project-content';
type ActionsTypes = ReturnType<InferValueTypes<typeof actions>>;

const initialState: SearchContentProjectTypes.Stores.TThematicsState = {
    content: {
        isToggled: false,
        zeroLevelTagIds: [],
        firstLevelTagIds: [],
        secondLevelTagIds: [],
        thirdLevelTagIds: [],
        contentFilterZeroLvlTagIds: [],
        contentFilterFirstLvlTagIds: [],
        contentFilterSecondLvlTagIds: [],
        contentFilterThirdLvlTagIds: [],
    },
    projects: {
        isToggled: false,
        zeroLevelTagIds: [],
        firstLevelTagIds: [],
        secondLevelTagIds: [],
        thirdLevelTagIds: [],
        contentFilterZeroLvlTagIds: [],
        contentFilterFirstLvlTagIds: [],
        contentFilterSecondLvlTagIds: [],
        contentFilterThirdLvlTagIds: [],
    },
    calendar: {
        isToggled: false,
        zeroLevelTagIds: [],
        firstLevelTagIds: [],
        secondLevelTagIds: [],
        thirdLevelTagIds: [],
        contentFilterZeroLvlTagIds: [],
        contentFilterFirstLvlTagIds: [],
        contentFilterSecondLvlTagIds: [],
        contentFilterThirdLvlTagIds: [],
    }
}

export default function reducer(
    state: SearchContentProjectTypes.Stores.TThematicsState = initialState,
    action: ActionsTypes
): SearchContentProjectTypes.Stores.TThematicsState {
    switch (action.type) {
        case ActionTypes.SET_VALUE:
            return {
                ...state,
                [action.typeContent]: {
                    ...state[action.typeContent],
                    isToggled: action.isToggled,
                    ...action.value}
            }
        case ActionTypes.CLEAR_VALUE:
            return {
                ...state,
                [action.typeContent]: initialState[action.typeContent]
            }
        default:
            return state
    }
}