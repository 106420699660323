import {useDispatch} from "react-redux";
import {Key, useState} from "react";
import {TTaskRecordsFilter} from "models/task/task-list";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {TablePaginationConfig} from "antd";
import handlerTable from "utils/handleTable";
import TasksContentService from "services/tasksContentService";
import {TaskTypes} from "entities/task";

export function useRecords() {
    const put = useDispatch();

    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<TaskTypes.ContentRecords[]>([]);
    const [filter, setFilter] = useState<TTaskRecordsFilter>({
        page: 1,
        pageSize: 5,
        filterData: {isMasterFile: true}
    });
    const getRecords = async (filterProps?: TTaskRecordsFilter) => {
        setLoading(true)
        try {
            const {data} = await TasksContentService.getTaskRecords(filterProps ? filterProps : filter)
            const {content, ...rest} = data;
            setRecords(content)
            const newFilter = filterProps ? {...filterProps,...rest, page: rest.page + 1 } : {...filter, ...rest, page: rest.page + 1}
            setFilter(newFilter)
        } catch (error: any) {
            const message = CreateErrorMessage.response(error)
            put(NotificationOpen("error", '', message))
        } finally {
            setLoading(false)
        }
    }

    const handleTable = (
        pagination: TablePaginationConfig,
        filterTable: Record<string, (Key | boolean)[] | null>,
        sorter: any,
    ) => {
        const newFilter = handlerTable(pagination, filterTable, sorter) as TTaskRecordsFilter
        const f = {...newFilter, filterData: filter.filterData}
        setFilter(f)
        getRecords(f)
    }

    return {loading, records, filter, getRecords, handleTable}
}