import {z} from "zod";
import ZodParser from "shared/services/zod-parser";
import {StepFirstDto} from "../dto/project-form_StepFirst.dto";
import replaceQuotesInString from "shared/lib/replaceQuotesInString";


const schema = z.object({
    isPrivateProject: z.boolean(),
    name: z.string(),
    projectTypeId: z.string(),
    requestNumber: z.string(),
    budget: z.number(),
    promotionalBudget: z.number().nullable(),
    contractNumber: z.string(),
    contractDate: z.date(),
    agreementDate: z.date().nullable(),
    advancePayment: z.boolean(),
    igk: z.string().nullable(),
    sessionId: z.string(),
    goal: z.string().nullable(),
    description: z.string().nullable(),
    status: z.null(),
    completionDate: z.date(),
})
export type EditSchema1 = z.infer<typeof schema>
export function step1(step: StepFirstDto): EditSchema1 {
    const mapped: EditSchema1 = {
        isPrivateProject: step.isPrivateProject.value,
        name: step.name.value,
        projectTypeId: step.projectType.value?.value as string,
        advancePayment: step.advancePayment.value as boolean,
        agreementDate: !!step.agreementDate.value ? new Date(String(step.agreementDate.value)) : null,
        budget: step.budget.value,
        promotionalBudget: step.promotionalBudget.value > 0 ? step.promotionalBudget.value : null,
        completionDate: new Date(String(step.completionDate.value)),
        contractDate: new Date(String(step.contractDate.value)),
        contractNumber: step.contractNumber.value,
        sessionId: step.session.value.session?.value as string,
        description: replaceQuotesInString(step.description.value),
        goal: replaceQuotesInString(step.goal.value),
        igk: step.igk.value || null,
        requestNumber: step.requestNumber.value,
        status: null,
    }
    ZodParser.parse(schema, mapped, 'Edit step1');
    return mapped;
}