const actionsUsers = [
	{
		id: '76',
		selector: '.ant-table',
		content:
			'Таблица справочной информации по действиям пользователей, позволяет отсортировать данные, отредактировать описание системных действий и сделать поведение пользователей по определенным действиям видимыми и невидимыми',
		role: [],
	},
]

export default actionsUsers
