import {z} from "zod";
import {EmailSchema} from "./email";
import {PhoneSchema} from "./phone";

export const OrganizationsSchema = z.object({
    id: z.string(),
    name: z.string(),
    inn: z.string(),
    isContentCustomer: z.boolean(),
    region: z.object({
        id: z.string(),
        name: z.string(),
    }).nullable(),
    legalForm: z.object({
        id: z.string(),
        shortName: z.string(),
        code: z.string(),
        fullName: z.string(),
        hidden: z.boolean(), //Признак. Запись не отображается для выбора пользователя
    }),
    emails: z.array(EmailSchema),
    phones: z.array(PhoneSchema),
})
export type Organizations = z.infer<typeof OrganizationsSchema>