import React, {FC} from 'react';
import {ModalOpenAction} from "../../../store/modals/actions";
import {Badge, Progress, Tooltip} from "antd";
import {CloudDownloadOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {selectUploaderInfoForInfoMenu} from "../../../store/uploader/selectors";
import {OpenModalUploadAction} from "store/uploader/actions";
import description from "assets/svg/description.svg";
import useUploaderContext from "../../../context/uploader/useUploaderContext";


const InfoMenuUploader: FC<{ type?: 'mobile' }> = ({type}) => {
    const put = useDispatch()
    const {uploaderState} = useUploaderContext()
    const {loading, fileIsUpload, unfinishedFiles} = useSelector(selectUploaderInfoForInfoMenu)


    return (
        <>
            {(loading || fileIsUpload) && (
                <Tooltip title={'Загрузка файла'}>
                    <div className={`infoMenu__loadBtn ${type}`} onClick={_e => {
                        put(OpenModalUploadAction())
                    }}>
                        <img src={description} alt="" className='infoMenu__loadBtn-img'/>
                        <div className='infoMenu__loadBtn-progress'>
                            <Progress size="small" status="normal" percent={uploaderState.progress}/>
                        </div>
                    </div>
                </Tooltip>
            )}
            {unfinishedFiles && !!unfinishedFiles.length && (
                <div className={`infoMenu__unfinished ${type}`} onClick={_e => {
                    put(ModalOpenAction('upload'))
                }}>
                    <Badge count={unfinishedFiles ? unfinishedFiles.length : undefined}>
                        <CloudDownloadOutlined/>
                    </Badge>
                </div>
            )}
        </>
    )
};
export default InfoMenuUploader;