import {IOptions} from "models/feels";
import {UserTypes} from 'entities/user/index'

export function MapPerson(arr: UserTypes.Person[]) {
    return arr.map((el) => {
        const user = {
            lastName: el.lastName ?? '',
            firstName: el.firstName ?? '',
            middleName: el.middleName ?? '',
        }
        return { value: el.id, label: `${user.lastName} ${user.firstName} ${user.middleName}` } as IOptions
    })
}

export function MapPersonRoleName(arr: UserTypes.Person[]) {
    return arr.map((user) => {
        const firstName = user.firstName ?? '';
        const lastName = user.lastName ?? '';
        const middleName = user.middleName ?? '';
        return {value: user.id, label: `${lastName} ${firstName} ${middleName} - ${user.role?.ruName}`} as IOptions
    })
}
export function MapPersonProjectForm(arr: UserTypes.Person[]) {
    return arr.map((el) => {
        const user = {
            lastName: el.lastName ?? '',
            firstName: el.firstName ?? '',
            middleName: el.middleName ?? '',
        }
        return { value: el.id, label: `${user.lastName} ${user.firstName} ${user.middleName}`, code: el.status } as IOptions
    })
}
