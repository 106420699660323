export const AuthActionTypes = {
    LOGIN_START: '[AUTH] LOGIN_START',
    LOGIN_SUCCESS: '[AUTH] LOGIN_SUCCESS',
    LOGIN_ERROR: '[AUTH] LOGIN_ERROR',

    LOGIN_BY_USER_START: '[AUTH] LOGIN_BY_USER_START',
    GET_CURRENT_USER: '[AUTH] GET_CURRENT_USER',
    LOGOUT: '[AUTH] LOGOUT',

    RESTORE_START: '[AUTH] RESTORE_START',
    RESTORE_SUCCESS: '[AUTH] RESTORE_SUCCESS',
    RESTORE_ERROR: '[AUTH] RESTORE_ERROR',

    GENERATE_KEY_START: '[AUTH] GENERATE_KEY_START',
    GENERATE_KEY_SUCCESS: '[AUTH] GENERATE_KEY_SUCCESS',
    GENERATE_KEY_ERROR: '[AUTH] GENERATE_KEY_ERROR',

    GET_KEY_START: '[AUTH] GET_KEY_START',
    GET_KEY_SUCCESS: '[AUTH] GET_KEY_SUCCESS',
    GET_KEY_ERROR: '[AUTH] GET_KEY_ERROR',

    BLOCK_KEY_START: '[AUTH] BLOCK_KEY_START',
    BLOCK_KEY_SUCCESS: '[AUTH] BLOCK_KEY_SUCCESS',
    BLOCK_KEY_ERROR: '[AUTH] BLOCK_KEY_ERROR',

    CHANGE_DOWNLOAD_RIGHTS_START: '[AUTH] CHANGE_DOWNLOAD_RIGHTS_START',
    CHANGE_DOWNLOAD_RIGHTS_SUCCESS: '[AUTH] CHANGE_DOWNLOAD_RIGHTS_SUCCESS',
    CHANGE_DOWNLOAD_RIGHTS_ERROR: '[AUTH] CHANGE_DOWNLOAD_RIGHTS_ERROR',
} as const;
