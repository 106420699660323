import React from 'react';
import './index.less';
import {Empty, Pagination, Spin} from "antd";
import Filters from "../components/filters";
import ErrorComponent from "components/error-boundary";
import {useDispatch, useSelector} from "react-redux";
import {selectContent} from "store/content/list/selectors";
import {SetContentFilter} from "store/content/list/actions";
import CardsContent from "../components/cards";
import useTasksFields from "hooks/tasksField";
import {TTasksTypesFields} from "models/task";

const ContentPage = () => {
    const put = useDispatch()
    useTasksFields([TTasksTypesFields.recordsStatuses])
    const {isLoading, contentList, filter, typeContent} = useSelector(selectContent)

    const onShowSizeChange = (page: number, pageSize?: number) => {
        put(SetContentFilter(
            {...filter, page: page, pageSize: pageSize ? pageSize : 10},
            typeContent ? typeContent : undefined))
    };

    return (
        <ErrorComponent>
            <div className="ContentPage">
                <Filters/>
                <Spin spinning={isLoading}>
                    <div className="ContentPage__list">
                        {!contentList?.length && <Empty/>}
                        {contentList?.map((el) => (
                            <CardsContent
                                key={el.id + typeContent}
                                content={el}
                            />
                        ))}
                    </div>
                </Spin>

                <div className="ContentPage__pagination">
                    <Pagination
                        pageSize={filter.pageSize}
                        current={filter.page}
                        total={filter?.totalCount ? filter.totalCount : 0}
                        showSizeChanger={true}
                        onChange={onShowSizeChange}
                    />
                </div>

            </div>
        </ErrorComponent>
    )
};
export default ContentPage;