import {takeLatest} from 'redux-saga/effects'
import {UsersActionTypes} from '../../../store/options/action-types'
import getUserList from './getUserList'
import {setUsersFilter} from './setUsersFilter'
import {createUser} from './createUser'
import {getUser} from './getUser'
import {updateUser} from './updateUser'
import {deleteUser} from './deleteUser'
import {changePassword} from './changePassword'
import {getRelatedUsers} from './getRelatedUsers'
import exportUsers from "./exportUsers";
import importTemplateUsers from "./importTemplateUsers";
import importUsers from "./importUsers";
import {sendUserDetails} from "./send-user-details";
import {updatePersonalDataUser} from "./updatePersonalDataUser";
import {getOrganizationsUser} from "./getOrganizationsUser";
import {getInspectors} from "./getInspectors";
import {getActiveSyncedUsers} from "./getActiveSyncedUsers";


export default function* usersSaga() {
	yield takeLatest(UsersActionTypes.GET_USERS_LIST_START, getUserList)
	yield takeLatest(UsersActionTypes.SET_USERS_FILTER, setUsersFilter)
	yield takeLatest(UsersActionTypes.CREATE_USER_START, createUser)
	yield takeLatest(UsersActionTypes.GET_USER_START, getUser)
	yield takeLatest(UsersActionTypes.UPDATE_USER_START, updateUser)
	yield takeLatest(UsersActionTypes.DELETE_USER_START, deleteUser)
	yield takeLatest(UsersActionTypes.GET_RELATED_USERS_START, getRelatedUsers)
	yield takeLatest(UsersActionTypes.CHANGE_PASSWORD_START, changePassword)
	yield takeLatest(UsersActionTypes.UNLOADING_USERS_START, exportUsers)
	yield takeLatest(UsersActionTypes.IMPORT_TEMPLATE_USERS_START, importTemplateUsers)
	yield takeLatest(UsersActionTypes.IMPORT_USERS_START, importUsers)
	yield takeLatest(UsersActionTypes.SEND_USER_DETAILS_START, sendUserDetails)
	yield takeLatest(UsersActionTypes.UPDATE_PERSONAL_DATA_START, updatePersonalDataUser)
	yield takeLatest(UsersActionTypes.GET_ORGANIZATIONS_USERS_START, getOrganizationsUser)
	yield takeLatest(UsersActionTypes.GET_INSPECTORS_START, getInspectors)
	yield takeLatest(UsersActionTypes.GET_LIST_ACTIVE_SYNCED_USERS_START, getActiveSyncedUsers)
}
