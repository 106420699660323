import React, {FC, useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {Button, Col, Row, Spin, Tooltip} from 'antd'
import {validationSchemaForNewUsers, validationSchemaUsers} from './validationSchema'
//formic
import {Form, Formik, FormikProps} from 'formik'
import {
	CheckboxComponent,
	InputComponent,
	RadioButtonsComponent,
	SelectComponent,
	UploadComponent,
} from 'components/formic-control'
//store
import {CreateUserStartAction, UpdateUserStartAction,} from 'store/options/actions/actions-users'
import {selectFile} from 'store/files/selectors'
import {selectUsersStatus} from 'store/options/selectors/selectors-users'
//hooks
import useFeelsApp from 'hooks/feelsApp'
//models
import {TStatusesFeels} from 'models/projects/projectFeels'
import {TResponseUser, TUserForm} from 'models/users-list/user-form'
//utils
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import useHandlerModal from 'hooks/handlModal'
import OrganizationField from './feels/organizations-field/organizations-field'
import moment from "moment";
import {UserDto} from "entities/user";
import {TRole} from "models/user";
import Access from "./feels/access/access";
import {selectRole} from "store/auth/selectors";
import {IOptions} from "models/feels";
import {RoleName} from "../../types";
import { sleep } from 'shared/lib/sleep';

const typeStatus = [
	{ label: 'Активен', value: 'ACTIVE' },
	{ label: 'Блокирован', value: 'BLOCKED' },
	{ label: 'Архивирован', value: 'ARCHIVE' },
]

type FormComponentProps = {
	user?: TResponseUser | null
	initialValue: UserDto.User
	id?: string | null
}
const disabledRoles: RoleName[] = ["ROLE_ADMIN", "ROLE_ADMIN_IS", "ROLE_MODERATOR", "ROLE_VIEWER", "ROLE_CLIENT", "ROLE_AGENT"];
const FormComponent: FC<FormComponentProps> = ({ user, initialValue, id }) => {
	const { closeModal, put } = useHandlerModal()
	const currentUser = useSelector(selectRole)
	const avatarUser = useSelector(selectFile(user?.image?.id))
	const { isSuccess, isLoading: isLoadingUser } = useSelector(selectUsersStatus)
	const { isLoading, roles } = useFeelsApp([TStatusesFeels.roles])
	const [visibleImg, setVisibleImg] = useState<boolean>(true)

	const handlerVisibleOff = () => {
		setVisibleImg(false)
	}

	useEffect(() => {
		if (isSuccess) closeModal()
		// eslint-disable-next-line
	}, [isSuccess])

	useEffect(() => {
		avatarUser?.data && setVisibleImg(true)
		avatarUser?.data || setVisibleImg(false)
	}, [avatarUser?.data])

	const options = useMemo(()=>{
		if (currentUser === 'ROLE_ADMIN') return roles?.filter((it: IOptions)=> (it.code !== 'ROLE_ADMIN_IS' && it.code !== 'ROLE_INTEGRATION_USER'))
		else return roles?.filter((it: IOptions)=> (it.code !== 'ROLE_INTEGRATION_USER'))
		// eslint-disable-next-line
	},[currentUser, roles])

	const handlerClickCancel = () => {
		setVisibleImg(true)
		closeModal()
	}

	function onSubmit(data: TUserForm) {
		if (!id) put(CreateUserStartAction(data))
		if (user && id) {
			put(UpdateUserStartAction(id, data))
		}
	}

	return (
		<Spin tip="Идёт обновление, пожалуйста ждите..." spinning={isLoadingUser}>
		<Row className="usersListForm">
			<Col xs={24} sm={20} md={24} className="usersListForm__form userForm">
				<Formik
					enableReinitialize
					initialValues={initialValue}
					validationSchema={initialValue.isEdit ? validationSchemaUsers : validationSchemaForNewUsers}
					onSubmit={() => {}}
					validateOnMount
					>
						{(formik: FormikProps<UserDto.User>) => {
							const isEdit= formik.values.isEdit;
							const formRole = formik.values.role?.code;
							const handlerRole = async (value?: UserDto.User["role"]) => {
								await sleep();
								if (!value) {
									formik.setFieldValue('disabledOrganizations', true)
								} else {
									formik.setFieldValue(
										'disabledOrganizations',
										formik.values.validateDisabledOrganizations(value.code as TRole)
									)
								}
							}
							return (
								<Form className="usersListForm-form userForm__form">
									{isEdit ? (
										<>
											<Row gutter={16} align="middle" className="usersListForm-form__item userForm__item">
												<Col span={24}>
													<div className="userForm__title">
														Данные для входа в контент.ири.рф
													</div>
												</Col>
											</Row>
											<Row
												gutter={[16, 16]}
												className="usersListForm-form__item userForm__item"
												wrap={true}
											>
												<Col>
													<div>
														<div className="userForm__label">
															Email
														</div>
														<div className="userForm__text">
															{user?.keycloakEmail}
														</div>
													</div>
												</Col>
												<Col>
													<div>
														<div className="userForm__label">
															Телефон
														</div>
														<div className="userForm__text">
															{user?.keycloakPhone ?? ' - '}
														</div>
													</div>
												</Col>
												<Col>
													<div>
														<div className="userForm__label">
															ФИО
														</div>
														<div className="userForm__text">
															{user?.lastName} {user?.firstName} {user?.middleName || ''}
														</div>
													</div>
												</Col>
											</Row>
										</>
									) : (
										<Row gutter={[16, 16]} align="middle">
											<Col md={6} xs={24}>
												<span className="label">Фамилия<sup>*</sup></span>
												<InputComponent type="text" name="lastName" placeholder="Фамилия*" allowClear={false} disabled={formik.values.disabled}/>
											</Col>
											<Col md={6} xs={24}>
												<span className="label">Имя<sup>*</sup></span>
												<InputComponent type="text" name="firstName" placeholder="Имя*" allowClear={false} disabled={formik.values.disabled}/>
											</Col>
											<Col md={6} xs={24}>
												<span className="label">Отчество</span>
												<InputComponent type="text" name="middleName" placeholder="Отчество" allowClear={false} disabled={formik.values.disabled}/>
											</Col>
										</Row>
									)}

									{isEdit && (
										<Row gutter={16} align="middle" className="usersListForm-form__item userForm__item">
											<Col span={24}>
												<div className="userForm__textSmall">
													Дата и время синхронизации {user?.keycloakLastSyncDatetime && moment(user?.keycloakLastSyncDatetime).format("DD.MM.YYYY HH:mm")}
												</div>
											</Col>
										</Row>
									)}

									<hr/>

									<Row gutter={16} align="middle" className="usersListForm-form__item">

										<Col md={11} xs={24} className="usersListForm-form__status">
											<span className="label">Статус<sup>*</sup></span>
											<RadioButtonsComponent name="status" options={typeStatus} disabled={formik.values.disabled}/>
										</Col>
									</Row>
									<Row gutter={16} align="middle" className="usersListForm-form__item">
										<Col md={6} xs={24}>
											<span className="label">Логин<sup>*</sup></span>
											<InputComponent type="text" name="username" placeholder="Логин" allowClear={false} disabled={formik.values.disabled}/>
										</Col>
										<Col md={6} xs={24}>
											<span className="label">Роль<sup>*</sup></span>
											<Spin spinning={isLoading || !roles}>
												<SelectComponent
													name="role"
													options={options || []}
													placeholder="Роль"
													handler={handlerRole}
													disabled={formik.values.disabled}
												/>
											</Spin>
										</Col>

										{formRole && !disabledRoles.includes(formRole) && (
											<Col md={6} xs={24}>
												<span className="label">Должность</span>
												<CheckboxComponent
													name="leader"
													placeholder="Является руководителем"
													disabled={!formik.values.role?.label || formik.values.disabled}
												/>
											</Col>
										)}
									</Row>

									<Row gutter={16} align="middle" justify="center" className="usersListForm-form__item">
										<OrganizationField name="organizations" role={formik.values.role}/>
									</Row>
									{((isEdit && currentUser === 'ROLE_ADMIN') || (currentUser === 'ROLE_ADMIN_IS')) ? (
										<Access />
									) : null}
									{isEdit && (
										<>
											<Row gutter={20} align="middle" className="usersListForm-form__item">
												<Col md={12} xs={24} className="label">
													Подтверждение telegram
												</Col>
												<Col md={12} xs={24} className="usersListForm-form__item--checkbox">
													<CheckboxComponent
														disabled={true}
														name="isTelegramConfirmed"
														placeholder={`${formik.values?.isTelegramConfirmed ? 'присутствует' : 'отсутствует'}`}
													/>
												</Col>
											</Row>
											<Row gutter={20} align="middle" className="usersListForm-form__item">
												<Col md={12} xs={24} className="label">
													Согласие на обработку ПД
												</Col>
												<Col md={12} xs={24} className="usersListForm-form__item--checkbox">
													<CheckboxComponent
														disabled={true}
														name="agreementConfirmed"
														placeholder={`${formik.values?.agreementConfirmed ? 'присутствует' : 'отсутствует'}`}
													/>
												</Col>
											</Row>
											<Row gutter={20} align="middle" className="usersListForm-form__item">
												<Col md={12} xs={24} className="label">
													Согласие на получение уведомлений
												</Col>
												<Col md={12} xs={24} className="usersListForm-form__item--checkbox">
													<CheckboxComponent
														disabled={true}
														name="notificationsConfirmed"
														placeholder={`${formik.values?.notificationsConfirmed ? 'присутствует' : 'отсутствует'}`}
													/>
												</Col>
											</Row>
										</>
									)}

									<Row
										gutter={16}
										align="middle"
										justify="center"
										className="usersListForm-form__item usersListForm-form--picture"
									>
										<Col md={6} xs={24} className="label label-picture">
											Изображение
										</Col>
										{avatarUser?.data && visibleImg ? (
											<Col md={3} xs={24}>
												<img src={avatarUser?.data} className="usersListForm-form__item--img" alt={avatarUser?.data} />
											</Col>
										) : (
											<Col md={3} xs={24} />
										)}
										<Col md={15} xs={24}>
											<UploadComponent
												disabled={formik.values.disabled}
												name="image"
												typeUploadFile={'image/*'}
												multi={false}
												placeholder={`${avatarUser?.data ? 'Изменить изображение' : 'Добавить изображение'}`}
												existingLabel="existingImage"
												extraOptions="user"
												callback={handlerVisibleOff}
											/>
										</Col>
									</Row>
									<div className="usersListForm-form__buttons">
										<Button
											type="default"
											className="arrowBtn usersListForm-form__buttons--cancel"
											onClick={handlerClickCancel}
										>
											Отменить
										</Button>
										<Tooltip title={CreateErrorMessage.form(formik.errors)}>
											<Button
												disabled={!formik.isValid || formik.values.disabled}
												type="primary"
												htmlType="submit"
												className="arrowBtn"
												onClick={(_event) => {
													onSubmit(formik.values)
													window.innerWidth < 1000 && window.history.back()
												}}
											>
												{user && id ? 'Обновить' : 'Сохранить'}
											</Button>
										</Tooltip>
									</div>
								</Form>
							)
						}}
					</Formik>
				</Col>
		</Row>
	</Spin>
	)
}

export default FormComponent
