import {InferValueTypes} from '../../../models/common'
import * as actions from '../actions/actions-personal-setting'
import {PersonalSettingTypes} from '../action-types'
import {ErrorActionState, StartActionState, SuccessActionState} from '../../../utils/reducerHalper'
import {TPersonalSettingResponse} from '../../../models/personal-setting'
import {AuthActionTypes} from "../../auth/action-types";
import {Logout} from "../../auth/reducer";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TPersonalSettingState = {
	setting: TPersonalSettingResponse[] | null
	containsSymbols: boolean
	passwordLength: number
	lettersNumbers: boolean
	clearInput: boolean
	isLoading: boolean
	error: any
}

export const initialState: TPersonalSettingState = {
	setting: null,
	containsSymbols: false,
	passwordLength: 0,
	lettersNumbers: false,
	clearInput: false,
	isLoading: false,
	error: null,
}

export default function personalSettingReducer(
	state: TPersonalSettingState = initialState,
	action: ActionTypes | Logout
): TPersonalSettingState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return {...initialState}
		case PersonalSettingTypes.UPDATE_PASSWORD_LENGTH_START:
			return {...state, passwordLength: action.length}
		case PersonalSettingTypes.UPDATE_CONTAIN_SYMBOLS_START:
			return {...state, containsSymbols: action.bol}
		case PersonalSettingTypes.UPDATE_LETTERS_NUMBERS_START:
			return {...state, lettersNumbers: action.bol}
		case PersonalSettingTypes.GET_PERSONAL_SETTING_START:
			return StartActionState(state)
		case PersonalSettingTypes.CONFIRM_EMAIL_START:
			return StartActionState(state)
		case PersonalSettingTypes.CONFIRM_TELEGRAM_START:
			return StartActionState(state)
		case PersonalSettingTypes.SEND_CONFIRM_EMAIL_START:
			return StartActionState(state)
		case PersonalSettingTypes.UPDATE_PERSONAL_SETTING_START:
			return StartActionState(state)
		case PersonalSettingTypes.CHANGE_PASSWORD_PERSONAL_SETTING_START:
			return {...StartActionState(state), clearInput: false}

		case PersonalSettingTypes.GET_PERSONAL_SETTING_SUCCESS:
			return {
				...SuccessActionState(state),
				setting: action.data,
			}
		case PersonalSettingTypes.UPDATE_PERSONAL_SETTING_SUCCESS:
			return SuccessActionState(state)
		case PersonalSettingTypes.CONFIRM_EMAIL_SUCCESS:
			return SuccessActionState(state)
		case PersonalSettingTypes.SEND_CONFIRM_EMAIL_SUCCESS:
			return SuccessActionState(state)
		case PersonalSettingTypes.CONFIRM_TELEGRAM_SUCCESS:
			return SuccessActionState(state)
		case PersonalSettingTypes.CHANGE_PASSWORD_PERSONAL_SETTING_SUCCESS:
			return {...SuccessActionState(state), clearInput: true}

		case PersonalSettingTypes.UPDATE_PERSONAL_SETTING_ERROR:
			return ErrorActionState(state, action)
		case PersonalSettingTypes.CONFIRM_EMAIL_ERROR:
			return ErrorActionState(state, action)
		case PersonalSettingTypes.SEND_CONFIRM_EMAIL_ERROR:
			return ErrorActionState(state, action)
		case PersonalSettingTypes.GET_PERSONAL_SETTING_ERROR:
			return ErrorActionState(state, action)
		case PersonalSettingTypes.CONFIRM_TELEGRAM_ERROR:
			return ErrorActionState(state, action)
		case PersonalSettingTypes.CHANGE_PASSWORD_PERSONAL_SETTING_ERROR:
			return ErrorActionState(state,action)
		default:
			return state
	}
}
