import React, { FC } from 'react'
import './modal-teams.less'
import { Button, Modal } from 'antd'
import useHandlerModal from 'hooks/handlModal'
import { ItemsComponent } from './components/items'
import { UserTypes } from 'entities/user'
import {ProjectTypes} from 'entities/project'
import {useSelector} from "react-redux";
import {selectRole} from "../../../store/auth/selectors";

export type ModalTeamsData = {
	personList: UserTypes.User[]
	type?: 'all'
	role?: UserTypes.RoleName
	contractorOrganization?: ProjectTypes.Project['contractorOrganization']
}
type ModalTeamsProps = {
	status: boolean
	data: ModalTeamsData
}

const ModalTeams: FC<ModalTeamsProps> = ({ status, data }) => {
	const role = useSelector(selectRole)
	const { isVisible, closeModal } = useHandlerModal(status)

	return (
		<Modal
			className="modal-team"
			title={titleModal(data)}
			visible={isVisible}
			footer={
				<Button type={'primary'} onClick={closeModal}>
					Закрыть
				</Button>
			}
			maskClosable={true}
			onCancel={closeModal}
		>
			<div className="modal-team__list">
				{data.personList.map((person, index) => {
					if (person.isTutor && !person.tutorVisible && role !== 'ROLE_CONTRACTOR') return null
					return <ItemsComponent
						person={person}
						role={data.role}
						contractorOrganization={data.contractorOrganization}
						key={person.firstName + index}
						status={person.status}
					/>
				})}
			</div>
		</Modal>
	)
}

function titleModal(data: ModalTeamsData) {
	if (!data.personList.length) return 'Команда'
	switch (data.role) {
		case 'ROLE_PRODUCER':
			return 'Команда продюсеров'
		case 'ROLE_FINANCIER':
			return 'Команда финансистов'
		case 'ROLE_COORDINATOR':
			return 'Команда координаторов'
		case 'ROLE_ANALYST':
			return 'Команда аналитиков'
		case 'ROLE_CONTRACTOR':
			const name = data.contractorOrganization ? data.contractorOrganization.name : ''
			return `Команда подрядчика ${name}`
		case 'ROLE_LAWYER':
			return 'Команда юристов'
		case 'ROLE_CONTENT_EDITOR':
			return 'Команда редакторов контента'
		case 'ROLE_PROJECT_MANAGER':
			return 'Команда менеджеров проекта'
		default:
			return ''
	}
}

export default ModalTeams
