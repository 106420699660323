import { createSelector } from 'reselect'
import { TApplicationState } from '../aplication-state'
import {IOptions} from "../../models/feels";

const regionsState = (state: TApplicationState) => state.regions

export const selectRegionsStatus = createSelector(regionsState, (state) => ({
	isLoading: state.isLoading,
	error: state.error,
}))

export const selectRegionsOptions = createSelector(
	regionsState,
	(state) => state.regions ? state.regions.map((el) => {
		return {value: el.id, label: el.name, code: el.code} as IOptions
	}) : []
)

