import React from 'react';
import './index.less';
import {useSelector} from "react-redux";
import {selectProject} from "store/projects/selectors";
import CoinIcon from "components/icons";
import {LineChartOutlined} from "@ant-design/icons";
import {transformationValues} from "utils/transformationValues";
import WhetherTheUser from "components/whetherTheUser";

const ProjectBudget = () => {
    const project = useSelector(selectProject);
    return (
        <div className="projectBudget">
            <WhetherTheUser role={['ROLE_VIEWER']} isCan={false}>
                <div className='projectBudget__item'>
                    <div className='title'><CoinIcon/> Бюджет</div>
                    <strong className="value">{project?.budget ? new Intl.NumberFormat('ru-RU',
                        {style: 'currency', currency: 'RUB', minimumFractionDigits: 0})
                        .format(project?.budget) : '—'}
                    </strong><br/>
                    <small className="description">{project?.advancePayment ? 'авансирование' : 'постоплата'}</small>
                </div>
            </WhetherTheUser>
            <WhetherTheUser role={['ROLE_CLIENT', 'ROLE_VIEWER', 'ROLE_AGENT']} isCan={false}>
                <div className='projectBudget__item'>
                    <div className='title'><CoinIcon/> Бюджет на продвижение</div>
                    <strong className="value">{project?.promotionalBudget ? new Intl.NumberFormat('ru-RU',
                        {style: 'currency', currency: 'RUB', minimumFractionDigits: 0})
                        .format(project?.promotionalBudget) : '—'}
                    </strong><br/>
                </div>
            </WhetherTheUser>
            {project?.type?.code !== 'CONTENT_CREATION' && !!project?.kpis.length && (
                <div className='projectBudget__item'>
                    <div className='title'><LineChartOutlined /> Плановый КПЭ</div>
                    {project?.kpis.map((el) => (
                        <div className='thoItems' key={el.id}>
                            <strong className="value">{transformationValues(el.planCount)}</strong>
                            <small className="description">{el.type.name}</small>
                        </div>
                    )) || '—'}
                </div>
            )}

            {project?.type?.code !== 'CONTENT_CREATION' && (
                <div className='projectBudget__item'>
                    <div className='title'><LineChartOutlined /> Фактический КПЭ</div>
                    <div className='thoItems'>
                        <strong className="value">
                            {project?.actualKpi ? transformationValues(project?.actualKpi) : '—'}
                        </strong>
                        <small className="description">Единиц</small>
                    </div>
                </div>
            )}

        </div>
    )
}

export default ProjectBudget;