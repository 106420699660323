import {put} from 'redux-saga/effects'
import {
    ConfirmEmailErrorAction,
    ConfirmEmailStartAction,
    ConfirmEmailSuccessAction,
} from 'store/options/actions/actions-personal-setting'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import PersonalSettingService from 'services/personalSettingService'
import {GetCurrentUser} from "store/auth/actions";

export default function* confirmEmail({
	id,
	notificationChannel,
	code,
    account

}: ReturnType<typeof ConfirmEmailStartAction>) {
	try {
		yield PersonalSettingService.confirmEmail(id, notificationChannel, code, account)
		yield put(ConfirmEmailSuccessAction())
		yield put(GetCurrentUser())
		yield put(NotificationOpen('success', 'OK', 'Подтверждено успешно'))
	} catch (error: any) {
		yield put(ConfirmEmailErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Неправильный код'))
	}
}
