import React from 'react'
import {SelectComponent} from 'components/formic-control'
import {useSelector} from 'react-redux'
import {selectParams} from 'store/selectors'
import styles from '../styles.module.less'
import {useFormikContext} from "formik";
import {TInitValues} from "../modal-content-records";
import DropdownComponent from "./dropdown";
import {TagLib} from 'entities/tags'

const SecondTags = () => {
	const { values } = useFormikContext<TInitValues>()
	const { id } = useSelector(selectParams)

	const {data: secondTags} = TagLib.useGetNotNationalProjectTagsOptions({projectId: id, level: 2, enabled: !!id, isAssignment: false})

	return (
		<div className={styles.item}>
			<span className={styles.label}>Теги единиц контента 2 уровня</span>
			<div className={styles.field}>
				<SelectComponent
					disabled={values.disabled}
					multi={true}
					options={secondTags}
					name={'secondTags'}
					placeholder={'Выберите тег'}
					maxTagCount={3}
					dropdownRender={<DropdownComponent name={'secondTags'} options={secondTags}/>}
				/>
			</div>
		</div>
	)
}

export default SecondTags
