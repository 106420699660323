import * as Yup from 'yup'
import { Nullable } from '../../../../models/common';
import { IOptions } from '../../../../models/feels';

function ValidateObj(val: any) {
	return !!(val && val.label && val.value)
}

export const validationSchemaForNewUsers = Yup.object({
	lastName: Yup.string().required('Обязательное поле фамилия'),
	firstName: Yup.string().required('Обязательное поле имя'),
	middleName: Yup.string().nullable(),
	leader: Yup.boolean(),
	status: Yup.string().required('Обязательное поле статус'),
	role: Yup.mixed().test('Роль','Выберите роль', function(value: Nullable<IOptions>) {
		return !!value?.value;
	}),
	organizations: Yup.array(Yup.object({
		id:  Yup.mixed().test('Организация','Выберите организацию', ValidateObj).required('Обязательное поле организация'),
		phones: Yup.mixed().test('Телефон', 'Добавьте хотя бы один номер', (val: any) => !!val.length),
		emails: Yup.mixed().test('Почта', 'Добавьте хотя бы одну почту', (val: any) => !!val.length),
	})),
})

export const validationSchemaUsers = Yup.object({
	leader: Yup.boolean(),
	status: Yup.string().required('Обязательное поле статус').nullable(),
	role: Yup.mixed().test('Роль','Выберите роль', ValidateObj).required('Обязательное поле роль').nullable(),
	organizations: Yup.array(Yup.object({
		id:  Yup.mixed().test('Организация','Выберите организацию', ValidateObj).required('Обязательное поле организация'),
		phones: Yup.mixed().test('Телефон', 'Добавьте хотя бы один номер', (val: any) => !!val.length),
		emails: Yup.mixed().test('Почта', 'Добавьте хотя бы одну почту', (val: any) => !!val.length),
	})),
})
