import React, {lazy, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import './cart.less'
import {PROJECTS} from "routing/names";
import {selectParams} from "store/selectors";
//store
import {DeleteProjectStartAction} from "store/projects/actions";
import {selectProject, selectProjectsStatus} from "store/projects/selectors";
//antd
import {Col, Popconfirm, Row, Spin, Tooltip} from 'antd';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
//components
import ProjectAccess from "components/validate-rights/project-access";
//utils
import InnerHTMLFunction from "utils/innerHTMLFunction";
//other
import {ModalsInfo, ProjectChannels, ProjectImage, ProjectInfo, Team} from "./components";
import ProjectBudget from "./components/budget/budget";
import {WritePageNameAction} from "store/app/actions";
import WhetherTheUser from "components/whetherTheUser";
import {ModalEvent} from 'shared/ui/modal';
import {ProjectFormContractor} from "features/create-or-edit-project-form";
import {ProjectTypes} from 'entities/project';

const ProjectForm = lazy(() => import('features/create-or-edit-project-form/ui'));
const ProjectCard = () => {
    const put = useDispatch();
    const history = useHistory();
    const [state, setState] = useState({
        isEdit: false
    });

    const {id} = useSelector(selectParams);
    const project = useSelector(selectProject);
    const {isLoading} = useSelector(selectProjectsStatus);


    useEffect(() => {
        if (!id && !isLoading ) history.push(PROJECTS.PROJECTS ,{replace: true})
        // eslint-disable-next-line
    }, [id])

    useEffect(()=>{
        put(WritePageNameAction(null))
    }, [])//eslint-disable-line

    const handleEdit = () => {
        setState({...state, isEdit: !state.isEdit})
        if(!state.isEdit) put(WritePageNameAction('project-no-data'))
        else put(WritePageNameAction(null))
    }
    const handleDelete = () => {
        if (id) {
            put(DeleteProjectStartAction(id));
            history.push(PROJECTS.PROJECTS);
        }
    }

    return (
        <>
            <ProjectPageOptions
                handlerEdit={handleEdit}
                handlerDelete={handleDelete}
                projectStatus={project?.status.code}
            />
            <Spin spinning={isLoading}>
                {state.isEdit && (
                    <Row className='projectPage__card'>
                        <Col lg={24} md={24} sm={24}><ProjectForm id={project?.id} handler={handleEdit} /></Col>
                    </Row>
                )}
                {!state.isEdit && (
                    <Row className='projectPage__card'>
                        <Col lg={4} md={7} sm={24} className='projectPage__card-left'>
                            <ProjectImage/>
                            <ProjectChannels/>
                        </Col>
                        <Col lg={19} md={17} sm={24}>
                            <Row className='projectPage__goal-description'>
                                <Col span={24}>
                                    <ProjectInfo/>
                                </Col>
                                <Col span={24}>
                                    <ProjectPageCardText title={'Цель проекта'}>
                                        {project?.goal ? project.goal : '—'}
                                    </ProjectPageCardText>
                                </Col>
                                <Col span={24} className='mt15'>
                                    <ProjectPageCardText title={'Описание проекта '}>
                                        {project?.description ? project.description : '—'}
                                    </ProjectPageCardText>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="mt15">
                                    <ModalsInfo/>
                                </Col>
                                <Col  className="mt15">
                                    <ProjectBudget/>
                                </Col>
                            </Row>

                            <Row className='width100'>
                                <Col span={24} className='mt15'>
                                    <Team/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>)
                }
            </Spin>
        </>
    )
};

function ProjectPageOptions(props: { handlerEdit: any, handlerDelete: any, projectStatus?: ProjectTypes.ProjectStatus }) {
    function onConfirm(_e: any) {
        props.handlerDelete && props.handlerDelete();
    }

    const openContractorModalForm = () => {
        ModalEvent.open({
            width: 896,
            component: <ProjectFormContractor/>,
        })
    }

    return (
        <Row justify='end' className='projectPage__options'>
            <Col className='projectPage__options-items'>
                <WhetherTheUser role={['ROLE_CONTRACTOR']} isCan={true}>
                    <>
                        {props.projectStatus === 'ACTUAL' && (
                            <Tooltip title={'Редактировать проект'}>
                                <div className="edit" onClick={openContractorModalForm}><EditOutlined/></div>
                            </Tooltip>
                        )}
                    </>
                </WhetherTheUser>
                <ProjectAccess typeElement={'edit'}>
                    <Tooltip title={'Редактировать проект'}>
                        <div className="edit" onClick={props.handlerEdit}><EditOutlined/></div>
                    </Tooltip>
                </ProjectAccess>
                <ProjectAccess typeElement={'delete'}>
                    <div className="delete">
                        <Tooltip title={'Удалить проект'}>
                            <Popconfirm
                                title="Вы действительно хотите удалить проект?"
                                onConfirm={onConfirm}
                                okText="Да"
                                cancelText="Нет"
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Tooltip>
                    </div>
                </ProjectAccess>

            </Col>
        </Row>
    )
}
function ProjectPageCardText(props: {title: string, children: any}) {
    return (
        <div className="projectPage__card-text">
            <span className='projectPage__card-title'>{props.title}</span>
            <div id={"quill-container"} dangerouslySetInnerHTML={InnerHTMLFunction(props.children)}/>
        </div>
    )
}




export default ProjectCard;
