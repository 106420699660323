import React, { FC, useEffect, useRef } from 'react'
import styles from './styles.module.less'
import { TaskTypes } from 'entities/task'
import moment from 'moment/moment'
import { Carousel, Empty } from 'antd'
import { CarouselRef } from 'antd/lib/carousel'
import ImageComponent from './image'
import {FileType} from "models/common";

type Props = {
	record: TaskTypes.ReachRecords,
	files: FileType[] | []
}
const Preview: FC<Props> = ({ record, files }) => {
	const carouselRef = useRef<CarouselRef>(null)
	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'ArrowRight') {
			carouselRef.current && carouselRef.current.next()
		} else if (event.key === 'ArrowLeft') {
			carouselRef.current && carouselRef.current.prev()
		} else {
			return
		}
	}

	useEffect(() => {
		if (files?.length > 1) {
			window.addEventListener('keydown', handleKeyDown)
			return () => {
				window.removeEventListener('keydown', handleKeyDown)
			}
		}
	}, [files?.length]) // eslint-disable-next-line

	return (
		<div className={styles.preview}>
			<div className={styles.carusel}>
				{files.length ? (
					<>
						<Carousel ref={carouselRef}>
							{files.map((screenshot) => {
								return <ImageComponent name={screenshot.name} id={screenshot.id} key={screenshot.id} />
							})}
						</Carousel>
					</>
				) : (
					<Empty />
				)}
			</div>
			<div className={styles.info}>
				<div className={styles.info_item}>
					<h3 className={styles.h3}>Базовая информация: </h3>
					<Text title={'Фактическая дата публикации:'} date={record?.actualPublicationDate || '__'} />
					<Text title={'Название контента при публикации:'} text={record?.publishedName || '__'} />
					<Text title={'Ссылка на единицу контента, размещенную в сети Интернет:'} link={record?.link || '__'} />
					<Text title={'Дата и время добавления охватов и скриншотов:'} date={record?.reachDateTime || '__'} />
					<Text title={'Охват:'} text={record?.reachAmount || '__'} />
				</div>
				<div className={styles.info_item}>
					<h3 className={styles.h3}>Дополнительная информация: </h3>
					<Text title={'Публикуемая единица контента:'} text={record?.workingName || '__'} />
					<Text
						title={'% изменения текущего значения к предыдущему:'}
						text={record?.reachAmountSpread ? `${Math.round(record?.reachAmountSpread * 100) / 100} %` : '__'}
					/>
					<Text title={'Разница изменения текущего охвата к предыдущему:'} text={record?.reachAmountMargin || '__'} />
					<Text title={'Тип счетчика: '} text={record?.isCounterTypeClosed ? 'Закрытый' : 'Открытый'} />
				</div>
			</div>
		</div>
	)
}

type TextProps = {
	title: string
	text?: string | number | null
	date?: string | Date | null
	link?: string | null
}

function Text({ title, text, date, link }: TextProps) {
	return (
		<>
			<p className={styles.title}>{title}</p>
			{link && (
				<a className={styles.text} href={link}>
					{link}
				</a>
			)}
			{text && <p className={styles.text}>{text}</p>}
			{date && <p className={styles.text}>{moment(date).format('DD.MM.yy')}</p>}
		</>
	)
}

export default Preview
