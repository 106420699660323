import {axiosRequest} from 'shared/config/axios'
import {Filter, ProjectTagRequest, ProjectTags, Tag, TagSchema} from '../types'
import {downloadXLXS} from 'utils/downloadXLXS'
import {IOptions} from "models/feels";
import {Tags, TagsSchema} from "../../project/types";

/**
 * Получение всех тегов проектов
 */
export const getProjectTags = {
	key: '/project-tags',
	fetch: async (params?: { level?: number; isForNationalProject?: boolean, isAssignment?: boolean }): Promise<Tag[]> => {
		const { data } = await axiosRequest<Tag[]>({
			method: 'GET',
			url: '/project-tags',
			parser: TagSchema.array(),
			params,
		})
		return data
	},
	fetchOptions: async (params?: { level?: number; isForNationalProject?: boolean, isAssignment?: boolean }) => {
			const data = await getProjectTags.fetch(params)
			return data.map(el => ({
				value: el.id,
				label: `${el.name} (${el.level}-уровень)`,
				hidden: el.hidden
			} as IOptions))
		},
}

export const getContentProjectTags = {
	key: `/contents/project-tags`,
	fetch: async (params?: { level?: number, isAssignment?: boolean}) => {
		const {data} = await axiosRequest<Tag[]>({
			method: 'GET',
			url: `/contents/project-tags`,
			params,
			parser: TagSchema.array()
		})
		return data
	},
}
export const getNotNationalProjectTags = {
	key: (projectId: string) => `/projects/${projectId}/not-national-project-tags`,
	fetch: async (data: {projectId: string, level: number, isAssignment?: boolean}) => {
		const {data: tags} = await axiosRequest<Tags[]>({
			url: `/projects/${data.projectId}/not-national-project-tags`,
			method: 'GET',
			showError: true,
			params: {
				level: data.level,
				isAssignment: data.isAssignment
			},
			parser: TagsSchema.array(),
			headers: {
				'Actioncode': 'GET_PROJECTS_PROJECT_ID_NOT_NATIONAL_PROJECT_TAGS'
			}
		})
		return tags;
	},
}
/**
 * Получение списка тегов проектов с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
	key: '/project-tags/search',
	fetch: async (filter: Filter): Promise<ProjectTags> => {
		const newFilter = { ...filter, page: filter.page - 1 };
		const { data } = await axiosRequest<ProjectTags>({
			method: 'POST',
			url: '/project-tags/search',
			data: newFilter,
			parser: { list: true, schema: TagSchema.array() },
		})
		return data
	},
}

/**
 * Создание тега
 */
export const create = async (data: ProjectTagRequest) => {
	await axiosRequest({
		method: 'POST',
		url: '/project-tags',
		data,
	})
}

/**
 * Обновление тега
 */
export const update = async ({ id, data }: { id: string; data: ProjectTagRequest }) => {
	await axiosRequest({
		method: 'PUT',
		url: `/project-tags/${id}`,
		data,
	})
}

/**
 * Удаление тега
 */
export const deleteProjectTag = async (id: string) => {
	await axiosRequest({
		method: 'DELETE',
		url: `/project-tags/${id}`,
	})
}

/**
 * Массовые действия над тегами проектов
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
	await axiosRequest({
		method: 'POST',
		url: `/project-tags/bulk-actions/${bulkAction}`,
		data: rowIds,
	})
	return bulkAction
}
/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
	const { data }: any = await axiosRequest({
		method: 'POST',
		url: `/project-tags/export`,
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
		data: value,
		showError: true,
	})
	downloadXLXS(data, 'project-tags')
}
/**
 * Получение файла шаблона для выполнения импорта данных о тегах проектов
 */
export const downloadTemplate = async () => {
	const { data }: any = await axiosRequest({
		method: 'GET',
		url: '/project-tags/import/template',
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
		showError: true,
	})
	downloadXLXS(data, 'project-tags')
}
/**
 * Выполнение импорта данных о тегах проектов из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData) => {
	await axiosRequest({
		method: 'POST',
		url: '/project-tags/import',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data,
		showError: true,
	})
}
