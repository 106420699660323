import * as Yup from "yup";
import {StepThirdDto} from "../dto/project-form_StepThird.dto";
import {getValue, statusIsNullOrDraftOrReadyToBeActual} from "./utils";

export const validationStepThird = Yup.object({
    channels: Yup.mixed()
        .test(
            'channels',
            'Поле «Интернет-ресурсы для размещения» заполнено некорректно',
            function ({value}: StepThirdDto["channels"], context) {
                const projectType = getValue(context).stepFirst.projectType.value?.code;
                if (projectType === 'CONTENT_CREATION_AND_PLACEMENT') {
                    if (!value.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
                    let isValid = false;
                    if (value.length) {
                        isValid = true;
                        let length = value.length;
                        do {
                            if (!value[length - 1].link) isValid = false;
                            if (!value[length - 1].internetResource?.value) isValid = false;
                            if (!value[length - 1].planPublicationCount) isValid = false;
                            length--;
                        } while (isValid && length !== 0);
                    }
                    return isValid
                } else if (projectType === 'CONTENT_CREATION' && value.length){
                    return context.createError({
                        path: 'stepThird.channels',
                        message: 'Поле «Интернет-ресурсы для размещения» неможет присутствовать если ' +
                            'тип проекта «Создание контента»'
                    })
                } else return true;
            }

        ),
    kpis: Yup.mixed()
        .test(
            'kpis',
            'Поле «Плановый КПЭ» заполнено некорректно',
            function ({value}: StepThirdDto["kpis"], context) {
                const projectType = getValue(context).stepFirst.projectType.value?.code;
                if (projectType === 'CONTENT_CREATION_AND_PLACEMENT') {
                    if (!value.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
                    let isValid = false;
                    if (value.length) {
                        isValid = true;
                        let length = value.length;
                        do {
                            if (!value[length - 1].type?.value) isValid = false;
                            if (!value[length - 1].count) isValid = false;
                            length--;
                        } while (isValid && length !== 0);
                    }
                    return isValid
                } else if (projectType === 'CONTENT_CREATION' && value.length){
                    return context.createError({
                        path: 'stepThird.kpis',
                        message: 'Поле «Плановый КПЭ» неможет присутствовать если ' +
                            'тип проекта «Создание контента»'
                    })
                } else return true;

            }
        ),
    image: Yup.mixed()
        .test(
            'image',
            'Поле «Изображение проекта» заполнено некорректно',
            function ({value}: StepThirdDto["image"], context) {
                const valueForm = getValue(context);
                const {contest, session, year} = valueForm.stepFirst.session.value;
                const existingImage = valueForm.stepThird.existingImage.value;
                if ((value.length > 0 || existingImage.length > 0) && !(contest?.value && session?.value && year?.value)) {
                    return context.createError({
                        path: 'stepThird.image',
                        message: 'Поле «Изображение проекта» не может присутствовать если не указан конкурс'
                    })
                }
                return true;
            }
        )
})
