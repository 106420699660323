import {TSendOrganizations, TUserForm} from "models/users-list/user-form";
import {UserTypes} from 'entities/user'

export class SendUpdateUserForm {
    "username": string
    "telegram": string | null
    "firstName": string
    "lastName": string
    "middleName": string
    "organizations": TSendOrganizations[]
    "roleId": string
    "leader": boolean
    "status": string
    "imageId": string
    "contentViewAccess": boolean
    "privateProjectViewAccess": boolean
    "contentDownloadAccess": boolean
    "contentStorageLinkAccess": boolean
    "limitedRightsToViewProjectsList": boolean
    "limitedRightsToViewContentTaskRecords": boolean
    constructor(data: TUserForm) {
        this.username = data.username
        this.imageId = data.image.length === 0 && data.existingImage ? data.existingImage[0]?.id : null
        this.telegram = null
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.middleName = data.middleName
        this.organizations = data.organizations.map((it)=>{
            return {
                organizationId: it.id.value,
                emails: it.emails.map((it,index)=>({
                    email: it.email,
                    isMain: index === 0,
                    isConfirmed: it.isConfirmed
                })),
                phones: it.phones.map((it,index)=>({
                    phone: it.phone,
                    isMain: index === 0,
                    isConfirmed: it.isConfirmed
                }))
            }
        })
        this.roleId = data.role.value
        this.leader = checkRole(data.role?.code) ? true : data.leader
        this.status = data.status
        this.contentViewAccess = data.contentViewAccess
        this.privateProjectViewAccess = data.privateProjectViewAccess
        this.contentDownloadAccess = data.contentDownloadAccess
        this.contentStorageLinkAccess = data.contentStorageLinkAccess
        this.limitedRightsToViewProjectsList = data.limitedRightsToViewProjectsList
        this.limitedRightsToViewContentTaskRecords = data.limitedRightsToViewContentTaskRecords

    }
}

function checkRole  (role: UserTypes.RoleName)  {
    switch (role) {
        case 'ROLE_MODERATOR':
            return true
        case 'ROLE_ADMIN':
            return true
        case 'ROLE_ADMIN_IS':
            return true
        default: return false
    }
}