import {CalendarActionTypes} from "./action-types";
import {TCalendarState} from "../../types";

export const setCalendarValue = <T extends Partial<TCalendarState>>(value: T) => ({
    type: CalendarActionTypes.SET_VALUE, value
})
export const GetCalendarValue = () => ({
    type: CalendarActionTypes.GET_VALUE
})



