import {TLegalFormOrganization} from "../../models/handbook";
import {LegalFormActionTypes} from "./action-types";

/**
 * Получение всех правовых формам организаций
 */
export const GetAllLegalFormOrganizationStartAction = () => ({
	type: LegalFormActionTypes.GET_ALL_LEGAL_FORM_ORGANIZATION_START,
})
export const GetAllLegalFormOrganizationSuccessAction = (data: TLegalFormOrganization[]) => ({
	type: LegalFormActionTypes.GET_ALL_LEGAL_FORM_ORGANIZATION_SUCCESS,
	data
})
export const GetAllLegalFormOrganizationErrorAction = (error: any) => ({
	type: LegalFormActionTypes.GET_ALL_LEGAL_FORM_ORGANIZATION_ERROR,
	error,
})
