import {RegionsActionTypes} from './action-types'
import {T_ID_NAME_CODE_HIDDEN} from "../../models/common";

/**
 * получение списка regions
 */
	export const GetRegionsStartAction = () => ({
	type: RegionsActionTypes.GET_REGIONS_START
})
export const GetRegionsSuccessAction = (data: T_ID_NAME_CODE_HIDDEN[]) => ({
	type: RegionsActionTypes.GET_REGIONS_SUCCESS,
	data,
})
export const GetRegionsErrorAction = (error: any) => ({
	type: RegionsActionTypes.GET_REGIONS_ERROR,
	error,
})
