import React, {FC} from 'react';
import './index.less'
import CardMovie from "./card-movie";
import {TProjectContent} from "models/projects";


type TProps = {
    content: TProjectContent,
    contentModuleSectionId?: string
}
const CardsContent: FC<TProps> = ({
    content,
    contentModuleSectionId
}) => {
    return <CardMovie content={content} contentModuleSectionId={contentModuleSectionId}/>
};
export default CardsContent;