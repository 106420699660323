import { FunctionalityInfoType } from '../types'

export const reportingReports: FunctionalityInfoType = [
	{
		LevelGroupName: 'Все шаблоны отчетов с автозаполнением данных из проектов и задач',
		LevelGroupData: [
			{
				name: 'Переход в окно формирования отчетности',
				text: 'Нажмите на название отчета для перехода в окно формирования отчетности',
				screenshots: ['/images/screenshots/reporting-reports/card.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Получение списка необходимых отчетов выбранного проекта',
		LevelGroupData: [
			{
				name: 'Получение списка необходимых отчетов и переход в окно формирования отчетности выбранного проекта',
				text: 'Для получения списка необходимых отчетов выбранного проекта:',
				points: [
					'Настройте фильтры для поиска отчетов по выбранному проекту',
					'Нажмите на название отчета для перехода в окно формирования отчетности',
				],
				screenshots: ['/images/screenshots/reporting-reports/filters.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Работа в окне формирования отчетности',
		LevelGroupData: [
			{
				name: 'Формирование отчета',
				text: 'Для формирования отчета:',
				points: [
					'Заполните поля формы в окне формирования отчетности',
					'Нажмите "Сформировать"',
					'Дождитесь окончания формирования отчета: статус формирования отчета "Created"',
				],
				screenshots: ['/images/screenshots/reporting-reports/modal.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Скачивание готового отчета',
				text: 'Нажмите на кнопку скачивания по окончании формирования отчета',
				screenshots: ['/images/screenshots/reporting-reports/download.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
