import React, {ChangeEvent, FC} from 'react';
import styles from './styles.module.less';
import {Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {UserFiltersModel, UserFiltersTypes} from 'features/user-filters';

type Props = {
    filterType: UserFiltersTypes.Types['code']
}
const FilterName: FC<Props> = ({filterType}) => {
    const put = useDispatch();
    const filterName = useSelector(UserFiltersModel.select.filterName(filterType));
    const activeFilterId = useSelector(UserFiltersModel.select.activeFilterId(filterType));

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        put(UserFiltersModel.actions.setUserFilter(filterType, {
            filterName: event.target.value || undefined,
            activeFilterId
        }))
    }
    return (
        <div className={styles.root}>
            <label aria-label={'UserFiltersFilterName'}>
                Наименование фильтра
            </label>
            <Input
                className={styles.input}
                id={'UserFiltersFilterName'}
                value={filterName}
                onChange={onChange}
                allowClear
                placeholder={'Введите наименование фильтра'}
            />
        </div>
    )
};

export default FilterName;