import React from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Switch} from 'react-router-dom'
import {CONTENT} from 'routing/names'
import CreateRouting from 'routing/create-routing'
import {selectRoleWithOptionalParams} from 'store/auth/selectors'
import SuspenseComponent from 'components/suspense'
import {Routes} from 'models/routes'
import ContentPage from './content-page'
import ContentItemPage from './content-item-page'

const contentChildRoutes: Routes[] = [
	{ path: CONTENT.CONTENT, Component: ContentPage, name: 'Контент', checkContentViewAccess: true },
	{ path: CONTENT.CONTENT_INFO, Component: ContentItemPage, name: 'Карточка контента', checkContentViewAccess: true },
]
const ContentRouting = () => {
	const {role, contentViewAccess} = useSelector(selectRoleWithOptionalParams)

    return (
		<SuspenseComponent>
			<Switch>
				{CreateRouting(contentChildRoutes, role, {contentViewAccess})}
				<Redirect to={CONTENT.CONTENT} />
			</Switch>
		</SuspenseComponent>
	)
}
const contentRoutes: Routes[] = [
	{ path: CONTENT.CONTENT, Component: ContentRouting, name: 'Контент', showInMenu: true, exact: false, checkContentViewAccess: true },
]

export default contentRoutes
