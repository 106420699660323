import {InferValueTypes} from '../../models/common'
import * as actions from './actions'
import {ErrorActionState, StartActionState, SuccessActionState} from '../../utils/reducerHalper'
import {Logout} from '../auth/reducer'
import {AuthActionTypes} from '../auth/action-types'
import {NationalProjectTag1Types} from './action-types'
import {TNationalTag} from "../../models/handbook";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TNationalProjectTag1State = {
	nationalProjectTag1: TNationalTag[] | null
	isLoading: boolean
	error: any
}

export const initialState: TNationalProjectTag1State = {
	nationalProjectTag1: null,
	isLoading: false,
	error: null,
}

export default function reducer(state: TNationalProjectTag1State = initialState, action: ActionTypes | Logout): TNationalProjectTag1State {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return { ...initialState }

		/** START ACTIONS  */
		case NationalProjectTag1Types.GET_ALL_NATIONAL_PROJECT_TAG1_START:
			return StartActionState(state)

		/** SUCCESS ACTIONS  */
		case NationalProjectTag1Types.GET_ALL_NATIONAL_PROJECT_TAG1_SUCCESS:
			return {...SuccessActionState(state), nationalProjectTag1: action.data}

		/** ERROR ACTIONS  */
		case NationalProjectTag1Types.GET_ALL_NATIONAL_PROJECT_TAG1_ERROR:
			return ErrorActionState(state, action.error)
		default:
			return state
	}
}
