import React, { FC } from 'react'
import styles from './styles.module.less'
import DocViewer from '@cyntler/react-doc-viewer'
import env from 'shared/config/env'
import keycloak from 'Keycloak'
import { Button } from 'antd'

type Props = {
	extension: string
	fileId?: string
	name?: string
	closeModal?: () => void
}

const path = `${env.API_ENDPOINT}/files/`

const DocumentViewer: FC<Props> = ({ fileId, extension, name, closeModal }) => {
	if (!fileId) {
		return (
			<div className={styles.loader_container}>
				<h3 className={styles.title}>Файл для просмотра формируется, подождите завершения процесса</h3>
				<MyDynamicEllipsis />
				<Button type="primary" onClick={closeModal}>
					Вернуться к списку
				</Button>
			</div>
		)
	}

	return (
		<DocViewer
			className={styles.doc_viewer}
			documents={[
				{
					uri: `${path}${fileId}`,
					fileType: extension,
					fileName: name,
				},
			]}
			requestHeaders={{
				'Authorization': `Bearer ${keycloak.token}`,
			}}
		/>
	)
}

function MyDynamicEllipsis() {
	return (
		<div className={styles.dynamic_ellipsis}>
			<span className={styles.dot1} />
			<span className={styles.dot2} />
			<span className={styles.dot3} />
		</div>
	)
}
export default DocumentViewer
