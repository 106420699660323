import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetHandbookStartAction,
    UpdateLegalFormOrgErrorAction,
    UpdateLegalFormOrgStartAction,
    UpdateLegalFormOrgSuccessAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import LegalFormOrganizationService from "services/legalFormOrganizationService";


export function* updateLegalFormOrganization({id, data,}: ReturnType<typeof UpdateLegalFormOrgStartAction>) {
    try {
        yield LegalFormOrganizationService.updateLegalFormOrganizationRecord(data, id)
        yield put(UpdateLegalFormOrgSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.legalFormOrganization))
        yield put(NotificationOpen('success', 'Запись обновлена'))
    } catch (error: any) {
        yield put(UpdateLegalFormOrgErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить запись'))
    }
}

