import Keycloak from "keycloak-js";
import env from "./shared/config/env";


const keycloak = new Keycloak({
    url: env.keycloack.KEYCLOAK_URL,
    realm: env.keycloack.KEYCLOAK_REALM,
    clientId: env.keycloack.KEYCLOAK_CLIENT,
});

export default keycloak;