import {SprintsActionTypes} from './action-types'
import { SprintTypes } from 'entities/sprints'

/**
 * получение списка этапов
 */
	export const GetSprintsStartAction = (projectId?:string) => ({
	type: SprintsActionTypes.GET_SPRINTS_START,
	projectId
})
export const GetSprintsSuccessAction = (data: SprintTypes.SprintProject[]) => ({
	type: SprintsActionTypes.GET_SPRINTS_SUCCESS,
	data,
})
export const GetSprintsErrorAction = (error: any) => ({
	type: SprintsActionTypes.GET_SPRINTS_ERROR,
	error,
})


/**
 * получение эатапа по id
 */
export const GetSprintIdStartAction = (stageId: string) => ({
	type: SprintsActionTypes.GET_SPRINT_ID_START,
	stageId,
})
export const GetSprintIdSuccessAction = (data: SprintTypes.SprintProject) => ({
	type: SprintsActionTypes.GET_SPRINT_ID_SUCCESS,
	data,
})
export const GetSprintIdErrorAction = (error: any) => ({
	type: SprintsActionTypes.GET_SPRINT_ID_ERROR,
	error,
})

/**
 * create эатапа
 */
export const CreateSprintStartAction = (data: SprintTypes.CreateSprint ) => ({
	type: SprintsActionTypes.CREATE_SPRINT_START,
	data,
})
export const CreateSprintSuccessAction = () => ({
	type: SprintsActionTypes.CREATE_SPRINT_SUCCESS,
})
export const CreateSprintErrorAction = (error: any) => ({
	type: SprintsActionTypes.CREATE_SPRINT_ERROR,
	error,
})

/**
 * обновление эатапа
 */
export const UpdateSprintStartAction = (stageId: string, data: SprintTypes.CreateSprint ) => ({
	type: SprintsActionTypes.UPDATE_SPRINT_ID_START,
	stageId,
	data,
})
export const UpdateSprintSuccessAction = () => ({
	type: SprintsActionTypes.UPDATE_SPRINT_ID_SUCCESS,
})
export const UpdateSprintErrorAction = (error: any) => ({
	type: SprintsActionTypes.UPDATE_SPRINT_ID_ERROR,
	error,
})

/**
 * delete эатапа
 */
export const DeleteSprintStartAction = (stageId: string ) => ({
	type: SprintsActionTypes.DELETE_SPRINT_ID_START,
	stageId,
})
export const DeleteSprintSuccessAction = () => ({
	type: SprintsActionTypes.DELETE_SPRINT_ID_SUCCESS,
})
export const DeleteSprintErrorAction = (error: any) => ({
	type: SprintsActionTypes.DELETE_SPRINT_ID_ERROR,
	error,
})

/**
 * Установитьи очистить editing key
 */
export const SetEditingKey = (key: string) => ({
	type: SprintsActionTypes.SET_EDITING_KEY,
	key
})
export const ClearEditingKey = () => ({
	type: SprintsActionTypes.CLEAR_EDITING_KEY
})
