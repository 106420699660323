import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import styles from './modal.module.less'
import {useDispatch, useSelector} from "react-redux";
import {TaskModel, TaskTypes} from 'entities/task'
import CreateComment from "./feels/create";
import UpdateComment from "./feels/update";
import Comment from "./feels/comment";
import {Button, Empty, Spin} from "antd";
import Sorting from "./feels/sorting/sorting";
import {selectUser} from "store/auth/selectors";

type TProps = {
    id: string
}

const Modal: FC<TProps> = ({id}) => {
    const put = useDispatch()
    const user = useSelector(selectUser)
    const {filter, isLoading, comments} = useSelector(TaskModel.selectors.store)
    const [type, setType] = useState<TaskTypes.TypeAction>('CREATE')
    const [typeSorting, setTypeSorting] = useState<TaskTypes.TypeSorting>('ALL')
    const commentRef = useRef<TaskTypes.Comment | null>(null)

    useEffect(() => {
            put(TaskModel.actions.SetInfo({filter: {
                    ...filter,
                    filterData: {
                        ...filter.filterData,
                        taskId: id
                    }
                }}))
            put(TaskModel.actions.Get())
    // eslint-disable-next-line
    }, [id]);

    const data = useMemo(()=>{
        let data: TaskTypes.Comment[] | null;
        switch (typeSorting) {
            case 'ALL' :
                data = comments
                break
            case 'CURRENT':
                data = comments?.length ? comments?.filter((it)=>it?.user?.id === user?.id) : null
                break
            default: data = comments
        }
        return data // eslint-disable-next-line
    },[typeSorting, comments])

    const handlerChangeRef = (value: TaskTypes.Comment)=> {
        commentRef.current = value
    }

    const handlerDelete = (comment: TaskTypes.Comment) => () =>{
        if (comment?.id) {
            put(TaskModel.actions.SetInfo({filter: {
                    ...filter,
                    page: 1,
                    pageSize: filter.totalCount && filter?.totalCount < filter.pageSize ? 10 : filter.pageSize,
                    filterData: {
                        ...filter.filterData,
                        taskId: id
                    }
                }}))
            put(TaskModel.actions.Delete(comment?.id,id))
            setType('CREATE')
        }
    }

    const handlerShowMore = () => {
        put(TaskModel.actions.SetInfo({filter: {
                ...filter,
                page: 1,
                pageSize: filter.pageSize + 10,
                filterData: {
                    ...filter.filterData,
                    taskId: id
                }
            }}))
        put(TaskModel.actions.Get())
    }



    return (
        <Spin spinning={isLoading}>
            <div className={styles.modal}>
                <Sorting handler={setTypeSorting}/>
                {type === 'CREATE' && <CreateComment id={id}/>}
                {type === 'UPDATE' && <UpdateComment taskId={id} handlerType={setType} commentRef={commentRef}/>}
                {data?.length ? (
                    data?.map(el=> (
                        <Comment
                            key={el?.id}
                            type={type}
                            comment={el}
                            handlerType={setType}
                            handlerDelete={handlerDelete(el)}
                            handlerRef={handlerChangeRef}
                        />))
                ) : (
                    <Empty className={styles.empty}/>
                )}
                {!!filter?.totalCount && filter?.totalCount > filter.pageSize && (<Button className={styles.show_more} onClick={handlerShowMore}>Посмотреть еще 10 старых комментариев</Button>)}
            </div>
        </Spin>

    )
};

export default Modal;