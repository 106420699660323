import React from 'react'
import {SelectComponent} from 'components/formic-control'
import {useSelector} from 'react-redux'
import {selectParams} from 'store/selectors'
import styles from '../styles.module.less'
import {useFormikContext} from "formik";
import {TInitValues} from "../modal-content-records";
import DropdownComponent from "./dropdown";
import {TagLib} from 'entities/tags'

const ThirdTags = () => {
	const { values } = useFormikContext<TInitValues>()
	const { id } = useSelector(selectParams)

	const {data: thirdTags} = TagLib.useGetNotNationalProjectTagsOptions({projectId: id, level: 3, enabled: !!id})

	return (
		<div className={styles.item}>
			<span className={styles.label}>Теги единиц контента 3 уровня</span>
			<div className={styles.field}>
				<SelectComponent
					disabled={values.disabled}
					multi={true}
					options={thirdTags}
					name={'thirdTags'}
					placeholder={'Выберите тег'}
					maxTagCount={3}
					dropdownRender={<DropdownComponent name={'thirdTags'} options={thirdTags}/>}
				/>
			</div>
		</div>
	)
}

export default ThirdTags
