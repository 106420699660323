import {delay, put, select} from "redux-saga/effects";
import {
    GetProjectsErrorAction,
    GetProjectsStartAction,
} from "store/projects/actions";
import {TRole} from "models/user";
import {selectRole} from "store/auth/selectors";
import { ExtendedSearchModel } from "features/extended-search";
import { SearchContentProjectModel } from "features/search-project-content";

const rolesForSearchCase: TRole[] = ['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_CLIENT'];

export function* getProjects(_action: ReturnType<typeof GetProjectsStartAction>) {
    try {
        const role: TRole = yield select(selectRole);

        if (!role) {
            yield delay(500)
            yield put(GetProjectsStartAction())
        }
        if (rolesForSearchCase.includes(role)) {
            yield put(SearchContentProjectModel.actions.FetchCF('projects'))
        } else {
            yield put(ExtendedSearchModel.actions.FetchCF('projects'))
        }

    } catch (error: any) {
        yield put(GetProjectsErrorAction(error))
    }
}