import React, {lazy, useEffect} from 'react'
import {Redirect, Switch} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
//sidebars
import MenuSidebar from 'components/sidebars/menu-sidebar'
import NotificationSidebar from 'components/sidebars/notification-sidebar'
import FunctionalityInfoSidebar from "components/sidebars/functionality-info-sidebar";
//pages-routes
import tasksRoutes from "pages/tasks";
import contentRoutes from "pages/content";
import optionsRoutes from "pages/options";
import analyticsRoutes from "pages/analytics";
import reportRoutes from "pages/reporting";
//antd
import {CaretUpOutlined} from '@ant-design/icons'
import {BackTop} from 'antd'
//models
import {Routes} from 'models/routes'
//components
import SuspenseComponent from 'components/suspense'
import {styleBtn} from 'constants/style-btn'
import Cookie from 'components/cookie'
import Header from 'components/header'
import MainComponent from 'components/main'
import BrowserNotification from 'components/browser-notification'
//others
import {LogsLogoutAction} from 'store/logs/actions'
import {CALENDAR, PROJECTS,} from './names'
import CreateAndEditProjectPage from 'pages/projects/createAndEdit-project-page'
import {selectRoleWithOptionalParams, selectUserId} from 'store/auth/selectors'
import CreateRouting from "./create-routing";

//pages
const ProjectsPage = lazy(() => import('pages/projects/projects-page'))
const ProjectPage = lazy(() => import('pages/projects/project-page'))
const Calendar = lazy(() => import('pages/calendar/ui'))

const privateRoutes: Routes[] = [
	//Модуль проектов
	{
		path: PROJECTS.PROJECTS,
		Component: ProjectsPage,
		name: 'Проекты',
		showInMenu: true,
		roleHasNotAccess: ['ROLE_AGENT', 'ROLE_CLIENT']
	},
	{
		path: PROJECTS.PROJECT,
		Component: ProjectPage,
		name: 'Параметры проекта',
		exact: false,
		roleHasNotAccess: ['ROLE_AGENT', 'ROLE_CLIENT']
	},
	{
		path: PROJECTS.PROJECTS_CREATE,
		Component: CreateAndEditProjectPage,
		name: 'Создание проекта',
		roleHasAccess: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_PROJECT_MANAGER', 'ROLE_COORDINATOR', 'ROLE_INTEGRATION_USER', 'ROLE_MODERATOR']
	},
	{
		path: PROJECTS.PROJECTS_EDIT,
		Component: CreateAndEditProjectPage,
		name: 'Редактирование проекта',
		roleHasNotAccess: ['ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_VIEWER','ROLE_CONTRACTOR']
	},

	{
		path: CALENDAR.CALENDAR,
		Component: Calendar,
		name: 'Календаря проектов',
		roleHasNotAccess: ['ROLE_AGENT', 'ROLE_CLIENT', 'ROLE_VIEWER']
	},

	//модуль задач
	...tasksRoutes,

	//модуль настроек
	...optionsRoutes,
	//модуль аналитики
	...analyticsRoutes,

    //модуль отчётов
	...reportRoutes,
    //модуль контента
    ...contentRoutes
]

const PrivateModule = () => {
	const put = useDispatch()
	const userId = useSelector(selectUserId)
	const {role, contentViewAccess} = useSelector(selectRoleWithOptionalParams)

	useEffect(() => {
		window.addEventListener('unload', function () {
			put(LogsLogoutAction())
		})

		return () => {
			put(LogsLogoutAction())
		} // eslint-disable-next-line
	}, [])

	return (
		<>
			<Header />
			<MenuSidebar />
			<NotificationSidebar />
			<FunctionalityInfoSidebar/>
			<MainComponent>
				<SuspenseComponent>
					<Switch>
						{CreateRouting(privateRoutes, role, {contentViewAccess})}
						<Redirect to="/" />
					</Switch>
				</SuspenseComponent>
			</MainComponent>
			<BackTop>
				{/*// @ts-ignore*/}
				<div style={styleBtn}>
					<CaretUpOutlined />
				</div>
			</BackTop>
			{userId && <BrowserNotification />}
			<Cookie />
		</>
	)
}
export default PrivateModule
