import React, {FC, SyntheticEvent} from 'react'
import { Field, ErrorMessage } from 'formik'
import { FieldAttributes } from 'formik/dist/Field'
import MaskedInput from 'antd-mask-input'
import TextError from './text-error'
import { IInputMasked } from '../../models/feels'

const InputMasked: FC<IInputMasked> = (props) => {
	const { label, name } = props
	const handleChange = (form: any) => (e: SyntheticEvent) => {
		form.setFieldValue(name, (e.target as HTMLInputElement).value)
	}
	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			<Field className="form-control" name={name}>
				{(el: FieldAttributes<any>) => (
					<>
						<MaskedInput
							mask={props.mask ? props.mask : '1(111) 111 11 11'}
							name={name}
							type="tel"
							placeholder={props?.placeholder}
							{...el.field}
							defaultValue={el.meta.value}
							onChange={handleChange(el.form)}
						/>
						{el.meta.touched && el.meta.error && <ErrorMessage name={name} component={TextError} />}
					</>
				)}
			</Field>
		</div>
	)
}

export default InputMasked
