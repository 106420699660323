import {GetRecordErrorAction, GetRecordStartAction, GetRecordSuccessAction} from "store/tasks/actions/content";
import {put, select} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import TasksContentService from "services/tasksContentService";
import {TaskTypes} from "entities/task";
import {selectContentTasks} from "store/tasks/selectors/selectors-task-content";

export function* getContentRecord({originalRecordId, updatedRecordId}: ReturnType<typeof GetRecordStartAction>) {
    try {
        const tasks: TaskTypes.ContentRecords[] = yield select(selectContentTasks)
        const { data }: AxiosResponse<TaskTypes.ContentRecords> = yield TasksContentService.getRecord(updatedRecordId || originalRecordId)
        const newData = replaceRecordById(tasks, data, originalRecordId)
        yield put(GetRecordSuccessAction(newData))

    } catch (error: any) {
        yield put(GetRecordErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить запись задачи'))
    }
}

function replaceRecordById(records: TaskTypes.ContentRecords[], newRecord: TaskTypes.ContentRecords, id: string): TaskTypes.ContentRecords[]  {
    const newTaskRecords = [...records]
    // Находим индекс записи, которую нужно заменить
    const targetIndex = newTaskRecords.findIndex(record => record.id === id)

    if (targetIndex !== -1) {
        newTaskRecords[targetIndex] = newRecord;
    }
    return newTaskRecords
}