import {put} from 'redux-saga/effects';
import {RestoreErrorAction, RestoreStartAction, RestoreSuccessAction} from "../../store/auth/actions";
import AuthService from "../../services/authService";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../store/notification/actions";


export function* restorePassword({email}: ReturnType<typeof RestoreStartAction>) {
    try {
        yield AuthService.passwordRestore(email);
        yield put(RestoreSuccessAction())
    } catch (error: any) {
        yield put(RestoreErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Ошибка'))
    }
}