import React, {useEffect} from 'react';
import {Select, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectFilter} from "store/content/content/selectors";
import {SetSortingFilter} from "store/content/content/actions";
import {TFilterData} from "models/content";
import {selectRole} from "store/auth/selectors";
import {selectTasksFieldsStatuses, selectTasksRecordsStatusesOptions} from "store/tasks/selectors";

const StatusSelect = () => {
    const put = useDispatch()
    const role = useSelector(selectRole)
    const recordsStatuses = useSelector(selectTasksRecordsStatusesOptions)
    const {isLoading} = useSelector(selectTasksFieldsStatuses)
    const filter = useSelector(selectFilter)

    useEffect(()=>{
        if(role !== 'ROLE_AGENT' && role !== 'ROLE_VIEWER' && role !== 'ROLE_CLIENT'){
            if(recordsStatuses) {
                const ids: string[]  = recordsStatuses?.filter(it=>it.code === 'APPROVED' || it.code === 'REJECTED').map(it=>it.value)
                let newFilter: TFilterData = {};
                if (filter.filterData) newFilter = { ...filter.filterData }
                newFilter = { ...newFilter, taskRecordStatusIds: ids}

                put(SetSortingFilter({ ...filter, filterData: newFilter }))
            }
        }
    // eslint-disable-next-line
    },[recordsStatuses,role])

    const onSelectStatus = (value: string[]) => {
        let newFilter: TFilterData = {};
        if (filter.filterData) newFilter = { ...filter.filterData }
        newFilter = { ...newFilter, taskRecordStatusIds: value }

        put(SetSortingFilter({ ...filter, filterData: newFilter }))
    }


    return (
        <div className="sorting__item">
            <Spin spinning={isLoading}>
                <Select
                    value={filter.filterData?.taskRecordStatusIds}
                    style={{ 'width': '200px' }}
                    placeholder="Все статусы контента"
                    mode={'multiple'}
                    allowClear={true}
                    onChange={onSelectStatus}
                    maxTagCount={"responsive"}
                    showSearch
                    options={recordsStatuses ? recordsStatuses.filter(it=>it.code === 'APPROVED' || it.code === 'REJECTED') : []}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }
                />
            </Spin>
        </div>
    )
}

export default StatusSelect;