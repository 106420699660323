import {InferValueTypes, TPaginationDefault} from 'models/common'
import * as actions from './actions'
import {ActionTypes} from './types'
import {TaskTypes} from "entities/task";

type ActionsTypes = ReturnType<InferValueTypes<typeof actions>>

export type TCommentsSettingsState = {
    comments: TaskTypes.Comment[] | null
    filter: TaskTypes.Filter
    isLoading: boolean
    error: any
}
export const InitialCommentsFilter = {
    page: 1,
    pageSize: 10,
    sort: {
        field: "createDateTime",
        direction: 'DESC'
    }
}
const initialState: TCommentsSettingsState = {
    comments: null,
    filter: InitialCommentsFilter as TPaginationDefault,
    isLoading: false,
    error: null,
}
export default function reducer(state: TCommentsSettingsState = initialState, action: ActionsTypes): TCommentsSettingsState {
    switch (action.type) {
        case ActionTypes.SET_INFO:
            return {
                ...state,
                ...action.value
            }
        case ActionTypes.GET_COMMENTS:
            return {
                ...state,
                isLoading: true
            }
        case ActionTypes.DELETE_COMMENTS:
            return {
                ...state,
                isLoading: true
            }
        case ActionTypes.CREATE_COMMENTS:
            return {
                ...state,
                isLoading: true
            }
        case ActionTypes.UPDATE_COMMENTS:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state
    }
}
