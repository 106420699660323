import { ActionTypes } from './types'
import { State } from './reducer'

export const setValue = <T extends Partial<State>>(value: T) => ({
	type: ActionTypes.SET_VALUE,
	value,
})
export const clearValue = () => ({
	type: ActionTypes.CLEAR_VALUE,
})
