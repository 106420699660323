import {put} from 'redux-saga/effects'
import {AxiosResponse} from 'axios'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import {NotificationOpen} from '../../store/notification/actions'
import {
	GetAnalyticsSummaryDataErrorAction,
	GetAnalyticsSummaryDataStartAction,
	GetAnalyticsSummaryDataSuccessAction,
} from '../../store/analytics/actions'
import AnalyticsService from '../../services/analyticsService'

export function* getAnalyticsSummaryData(_action: ReturnType<typeof GetAnalyticsSummaryDataStartAction>) {
	try {
		const { data: summary }: AxiosResponse<any> = yield AnalyticsService.getAnalyticsSummaryData()
		yield put(GetAnalyticsSummaryDataSuccessAction(summary))
	} catch (error: any) {
		yield put(GetAnalyticsSummaryDataErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить данные'))
	}
}
