import {ActionTypes} from './types'
import {InferValueTypes} from "models/common";
import * as actions from "./actions";
import { SearchContentProjectTypes } from 'features/search-project-content';
type ActionsTypes = ReturnType<InferValueTypes<typeof actions>>;

const initialState: SearchContentProjectTypes.Stores.TStatusState = {
    content: {
        isToggled: false,
        statusIds: [],
        contentDirectionIds: [],
    },
    projects: {
        isToggled: false,
        statusIds: [],
        contentDirectionIds: [],
    },
    calendar: {
        isToggled: false,
        statusIds: [],
        contentDirectionIds: [],
    }
}

export default function reducer(
    state: SearchContentProjectTypes.Stores.TStatusState = initialState,
    action: ActionsTypes
): SearchContentProjectTypes.Stores.TStatusState {
    switch (action.type) {
        case ActionTypes.SET_VALUE:
            return {
                ...state,
                [action.typeContent]: {
                    ...state[action.typeContent],
                    isToggled: true,
                    ...action.value}
            }
        case ActionTypes.CLEAR_VALUE:
            return {
                ...state,
                [action.typeContent]: initialState[action.typeContent]
            }
        default:
            return state
    }
}