import React, {FC, SyntheticEvent} from 'react';
import {CheckOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import { TaskTypes } from 'entities/task';
import classNames from "classnames";

type Props = {
    actionInfo: Omit<TaskTypes.TStatus, 'hidden'>
    handler: (event: SyntheticEvent) => void
}
export const SaveComponent: FC<Props> = ({actionInfo, handler}) => {

    return (
        <Tooltip title={"Сохранить"}>
            <div
                data-btn="save"
                className={classNames('item save', {disabled: actionInfo === 'disabled'})}
                onClick={(event) => actionInfo !== 'disabled' && handler(event)}
            >
                <CheckOutlined />
            </div>
        </Tooltip>
    );
}
export const CancelComponent: FC<Props> = ({actionInfo, handler}) => {
    return (
        <Tooltip title={"Отмена редактирования"}>
            <div
                data-btn="cancel"
                className={classNames('item cancel', {disabled: actionInfo === 'disabled'})}
                onClick={(event) => actionInfo !== 'disabled' && handler(event)}
            >
                <CloseCircleOutlined />
            </div>
        </Tooltip>
    );
}
