import {put} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	DownloadReportingErrorAction,
	DownloadReportingStartAction,
	DownloadReportingSuccessAction,
} from 'store/project-reporting/actions'

import {AxiosResponse} from 'axios'
import {TReportingDownloadResponse} from 'models/projects/reportings'
import ReportingService from "services/reportingService";
import {downloadFile} from "utils/downloadXLXS";

export function* downloadReporting({id, name}: ReturnType<typeof DownloadReportingStartAction>) {
	try {
		const { data }: AxiosResponse<TReportingDownloadResponse> = yield ReportingService.downloadReporting(id)
		downloadFile(data, name)
		yield put(DownloadReportingSuccessAction())
		yield put(NotificationOpen('success', 'Отчет скачан'))
	} catch (error: any) {
		yield put(DownloadReportingErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось скачать'))
	}
}
