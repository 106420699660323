import {InferValueTypes, Nullable, UnfinishedFile} from "models/common";
import {UploaderActionTypes} from "./action-types";
import * as actions from './actions'
import {Logout} from "../auth/reducer";
import {AuthActionTypes} from "../auth/action-types";

export type UploadData = {
    taskId: string
    taskName: string
}
export type UploadFile = {
    length: number
    offset: number
    originalFilename: string
    originalFileModificationDate: string
    isUploadInProgress: boolean
    id?: string
}
type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type UploaderState = {
    entityId: Nullable<string> //id записи куда нужно грузить файл
    uploadFile: Nullable<UploadFile> //информация по файлу
    uploadData: Nullable<UploadData> //информация по задаче
    isOpen: boolean
    fileIsUpload: boolean
    unfinishedFiles: Nullable<UnfinishedFile[]>
    loading: boolean
    isLoading: boolean
    error: any
}

export const initialState: UploaderState = {
    entityId: null,
    uploadFile: null,
    uploadData: null,
    isOpen: false,
    fileIsUpload: false,
    unfinishedFiles: null,
    loading: false,
    isLoading: false,
    error: null
}

export default function reducer(state: UploaderState = initialState, action: ActionTypes | Logout): UploaderState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState}
        case UploaderActionTypes.GET_UNFINISHED_START:
            return {...state, isLoading: true, unfinishedFiles: state.unfinishedFiles ? state.unfinishedFiles : null}
        case UploaderActionTypes.GET_UNFINISHED_SUCCESS:
            return {...state, isLoading: false, unfinishedFiles: action.files}
        case UploaderActionTypes.GET_UNFINISHED_ERROR:
            return {...state, isLoading: false, error: action.error}

        case UploaderActionTypes.OPEN_MODAL:
            const isSameEntityId = action.entityId ? action.entityId === state.entityId : true;
            if (isSameEntityId) {
                return {
                    ...state,
                    isOpen: true,
                    entityId: action.entityId ? action.entityId : state.entityId,
                    uploadFile: action.uploadFile ? action.uploadFile : state.uploadFile,
                    uploadData: action.uploadData ? action.uploadData : state.uploadData,
                }
            } else {
                return {
                    ...state,
                    isOpen: true,
                    entityId: action.entityId ? action.entityId : null,
                    uploadFile: action.uploadFile ? action.uploadFile : null,
                    uploadData: action.uploadData ? action.uploadData : null,
                    fileIsUpload: false,
                }
            }
        case UploaderActionTypes.CLOSE_MODAL:
            return {...state, isOpen: false}

        case UploaderActionTypes.SET_FILE:
            return {...state, uploadFile: action.uploadFile, fileIsUpload: true, loading: true}
        case UploaderActionTypes.SET_FILE_ID:
            return {...state, uploadFile: state.uploadFile ? {...state.uploadFile, id: action.fileId} : null}
        case UploaderActionTypes.CLEAR:
            return {
                ...state,
                uploadFile: null,
                fileIsUpload: false,
                loading: false
            }

        case UploaderActionTypes.START_LOAD:
            return {...state, loading: true}
        case UploaderActionTypes.ABORTED_LOAD:
            return {...state, loading: false}
        case UploaderActionTypes.FINISH_LOAD:
            return {...state, loading: false, fileIsUpload: false, uploadFile: null, isOpen: false}
        case UploaderActionTypes.DELETE_FILES_START:
            return {...state, isLoading: true}
        case UploaderActionTypes.DELETE_FILES_SUCCESS:
            return {...state, isLoading: false, uploadFile: null}
        case UploaderActionTypes.DELETE_FILES_ERROR:
            return {...state, isLoading: false, error: action.error}
        default:
            return state
    }
}
