import {ProjectTypes} from 'entities/project'
import {UserTypes} from 'entities/user'
export function MapPerson(arr: ProjectTypes.ProjectUser[]): ProjectTypes.MapProjectUsers {
    return arr.reduce((acc, obj) => {
        const roleName = obj.role.name;
        if (!acc.has(roleName)) {
            acc.set(roleName, []);
        }
        const roleArray = acc.get(roleName);
        if (roleArray !== undefined) {
            roleArray.push(obj); // Добавляем объект в массив по ключу roleName
        }
        return acc;
    }, new Map<UserTypes.RoleName, ProjectTypes.ProjectUser[]>());
}