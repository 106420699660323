import React, {FC, SyntheticEvent} from 'react';
import './index.less';
import {TaskLib, TaskTypes} from 'entities/task';
import {useSelector} from "react-redux";
import {selectRole} from "store/auth/selectors";
import {TRole} from "models/user";

import DeleteComponent from "./delete";
import EditComponent from "./edit";
import ChangeStatusComponent from "./changeStatus";
import SendComponent from "./send";
import {SaveComponent, CancelComponent} from "./save-cancel";


type Props = {
    isEdit: boolean,
    recordId: string,
    actions: {
        onEdit?: (event: SyntheticEvent) => void,
        onCancel?: (event: SyntheticEvent) => void,
        onSave?: (event: SyntheticEvent) => void,
        onDelete?: (event: SyntheticEvent) => void,
        onSend?: (event: SyntheticEvent) => void,
        onChangeStatus?: (event: SyntheticEvent) => void,
    }
    taskInfo: TaskTypes.Content | TaskTypes.Report
};
const RecordsActions: FC<Props> = ({isEdit, recordId, taskInfo, actions}) => {
    const role = useSelector(selectRole);
    const validate = validateForRoles({role, isEdit, taskInfo})
    return (
        <div className="RecordsActions">
            {validate.save !== 'hidden' && actions.onSave && (
                <SaveComponent handler={actions.onSave} actionInfo={validate.save}/>
            )}
            {validate.cancel !== 'hidden' && actions.onCancel && (
                <CancelComponent handler={actions.onCancel} actionInfo={validate.cancel}/>
            )}
            {validate.edit !== 'hidden' && actions.onEdit && (
                <EditComponent handler={actions.onEdit} actionInfo={validate.edit}/>
            )}
            {validate.delete !== 'hidden' && actions.onDelete && (
                <DeleteComponent handler={actions.onDelete} actionInfo={validate.delete}/>
            )}
            {validate.send !== 'hidden' && actions.onSend && (
                <SendComponent handler={actions.onSend} actionInfo={validate.send}/>
            )}
            {validate.changeStatus !== 'hidden' && actions.onChangeStatus && (
                <ChangeStatusComponent
                    recordStatus={taskInfo.recordStatus}
                    recordId={recordId}
                    typeTask={taskInfo.typeTask}
                    onChangeStatus={actions.onChangeStatus}
                    actionInfo={validate.changeStatus}
                />
            )}
        </div>
    );
};

function validateForRoles({role, isEdit, taskInfo}: {
    role?: TRole,
    isEdit: boolean,
    taskInfo: TaskTypes.Content | TaskTypes.Report
}): TaskTypes.TActions {
    const actions: TaskTypes.TActions = {
        edit: 'hidden',
        delete: 'hidden',
        changeStatus: 'hidden',
        save: 'hidden',
        cancel: 'hidden',
        send: 'hidden'
    };
    if (isEdit) {
        return {...actions, save: 'view', cancel: 'view'}
    }
    if ((role === 'ROLE_ADMIN') || (role === 'ROLE_ADMIN_IS') || (role === 'ROLE_MODERATOR')) {
        return TaskLib.validateForAdminAndModerator(taskInfo);
    }
    if ((role === 'ROLE_PRODUCER') || (role === 'ROLE_PROJECT_MANAGER')) {
        return TaskLib.validateForProducerAndProjectManager(taskInfo)
    }
    if (role === 'ROLE_CONTRACTOR') {
        return TaskLib.validateForContractor(taskInfo)
    }
    return actions;
}



export default RecordsActions;