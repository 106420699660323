import env, {TMode} from "shared/config/env";

interface ILoggerService {
	log(...args: any[]): ILoggerService | void;
	warn(...args: any[]): ILoggerService;
	error(...args: any[]): ILoggerService;
	dir(args: Object): ILoggerService;
	table(args: any[]): ILoggerService;
	group(args: { label?: string; collapsed?: boolean }): ILoggerService;
	groupEnd(): void;
}
class LoggerService implements ILoggerService {
	private mode: TMode = env.mode;
	log(...args: any[]) {
		if (this.mode !== 'prod') {
			// eslint-disable-next-line no-console
			console.log(...args);
		}
		return this;
	}
	warn(...args: any[]) {
		if (this.mode !== 'prod') {
			// eslint-disable-next-line no-console
			console.warn(...args);
		}
		return this;
	}
	error(...args: any[]) {
		if (this.mode !== 'prod') {
			// eslint-disable-next-line no-console
			console.error(...args);
		}
		return this;
	}
	dir(args: Object) {
		if (this.mode !== 'prod') {
			// eslint-disable-next-line no-console
			console.dir(args);
		}
		return this;
	}
	table(args: any[]) {
		if (this.mode !== 'prod') {
			// eslint-disable-next-line no-console
			console.table(args);
		}
		return this;
	}

	group({ label, collapsed }: { label?: string; collapsed?: boolean }) {
		if (this.mode !== 'prod') {
			if (collapsed) {
				// eslint-disable-next-line no-console
				console.groupCollapsed(label);
			} else {
				// eslint-disable-next-line no-console
				console.group(label);
			}
		}
		return this;
	}
	groupEnd() {
		if (this.mode !== 'prod') {
			// eslint-disable-next-line no-console
			console.groupEnd();
		}
		return this;
	}
}
const Logger = new LoggerService();
export default Logger;
