import {put, select} from 'redux-saga/effects'
import {
	GetUsersListStartAction,
	UpdateUserErrorAction,
	UpdateUserStartAction,
	UpdateUserSuccessAction
} from 'store/options/actions/actions-users'
import UsersService from 'services/usersService'
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {GetCurrentUser} from "store/auth/actions";
import {selectUserId} from "store/auth/selectors";
import {UserDto} from "entities/user";

export function* updateUser({ userId, user }: ReturnType<typeof UpdateUserStartAction>) {
	try {
		const formData = new FormData();
		const authUserId: string = yield select(selectUserId)

		let userData = new UserDto.SendUpdateUserForm(user)

		formData.append('user', JSON.stringify(userData));

		if (user.image.length !== 0) {
			formData.append('image', user.image[0].originFileObj as Blob);
		}

		yield UsersService.updateUser(userId, formData)

		yield put(UpdateUserSuccessAction(user))
		yield put(NotificationOpen('success', 'Пользователь обновлен'))
		yield put(GetUsersListStartAction())
		if (authUserId === userId) yield put(GetCurrentUser())

	} catch (error: any) {
		yield put(UpdateUserErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))

	}
}
