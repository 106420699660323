import React, {useMemo} from 'react';
import {useQuery} from "react-query";
import styles from '../user.module.less'
import {SelectComponent} from "components/formic-control";
import {Select, Spin, Switch, Tooltip} from "antd";
import useFeelsApp from "hooks/feelsApp";
import {TStatusesFeels} from "models/projects/projectFeels";
import {useDispatch, useSelector} from "react-redux";
import {GetFeelsStartAction} from "store/app/actions";
import {selectUser} from "store/auth/selectors";
import {Field, useFormikContext} from "formik";
import ReloadList from "components/forms/components/reload-list/reload-list";
import {FieldAttributes} from "formik/dist/Field";
import {IOptions} from "models/feels";
import classNames from "classnames";
import {CreateOrEditProjectLib} from "features/create-or-edit-project-form/";
import {UserApi, UserTypes} from 'entities/user'

const {Option} = Select;

function filingName(key: string): string {
    return `stepFourth.${key}.value`
}
function statusName(status: string| undefined) {
    switch (status) {
        case 'ACTIVE':
            return 'Активен'
        case 'ARCHIVE':
            return 'Архивирован'
        case 'BLOCKED':
            return 'Блокирован'
        default: return ''
    }
}

const roleHasNotAccess: UserTypes.RoleName[] = [
    'ROLE_PROJECT_MANAGER',
    'ROLE_COORDINATOR',
]

const ContractorFieldForm = () => {
    const put = useDispatch()
    const user = useSelector(selectUser)
    const {values, setFieldValue, touched, errors} = useFormikContext<CreateOrEditProjectLib.ProjectFormDto>();
    const form = values.stepFourth;
    const {isLoading: isLoadingFeels, organizationsWithContractors} = useFeelsApp([TStatusesFeels.organizationsWithContractors])
    const idOrganization = values.stepFourth.contractorOrganization.value?.value
    const isActualOrArchival =  values.stepFifth.info?.status === 'ARCHIVED' || values.stepFifth.info?.status === 'ACTUAL'

    const isLeader = user?.personalData.leader
    const hasNotAccessDeleteOption = !isLeader && isActualOrArchival && user?.role && roleHasNotAccess.includes(user?.role)

    const name = filingName('contractors')
    // eslint-disable-next-line
    const initialValueIds = useMemo(() => values.stepFourth['contractors']?.value?.map(it => it.value),[])// eslint-disable-next-line

    const {data: contractors = null, isLoading} = useQuery({
        queryKey: [UserApi.UserController.keyOptions, {roleNames: ['ROLE_CONTRACTOR'], organizationIds: [idOrganization]}],
        queryFn: ()=> UserApi.UserController.optionsStatus({
            roleNames: ['ROLE_CONTRACTOR'],
            isDeleted: false,
            ...(idOrganization && {organizationIds: [idOrganization]})
        }),
        enabled: !!idOrganization
    })

    function handlerOrganization() {
        setFieldValue(filingName('contractors'), null)
    }

    const handlerUpdateUsers = () => {
        put(GetFeelsStartAction(TStatusesFeels.organizationsWithContractors))
    }
    const handlerSelectTutor = (value: string[], option: IOptions[] | any) => {
        setFieldValue(filingName('contractors'), option)
    }

    return (
        <div className={classNames(styles.container)}>
            <div className={classNames('label', styles.first_label)}>Подрядчик<sup>*</sup></div>
            <div className={styles.first}>
                <Spin spinning={isLoadingFeels}>
                    <ReloadList handlerUpdateList={handlerUpdateUsers}/>
                    <SelectComponent
                        options={organizationsWithContractors}
                        name={filingName('contractorOrganization')}
                        placeholder="Выберите огранизацию"
                        showError={false}
                        disabled={form.contractorOrganization.isDisabled}
                        handler={handlerOrganization}
                        errorMessage={errors.stepFourth?.contractorOrganization as string}
                        maxTagCount={'responsive'}
                    />
                </Spin>
            </div>
            <div className={styles.switch}>
                <Switch
                    onChange={checked => {
                        setFieldValue(filingName('contractorAgentVisible'), checked)
                    }}
                    checked={form.contractorAgentVisible.value}
                    disabled={
                        form.contractorAgentVisible.isDisabled ||
                        !form.contractorOrganization.value?.value ||
                        !(form.contractors?.value?.length > 0)
                    }
                    unCheckedChildren={'Показать'}
                    checkedChildren={'Скрыть'}
                />
            </div>
            <div className={classNames('label', styles.second_label)}>
                Представитель подрядчика<sup>*</sup>
            </div>
            <div className={styles.second}>
                <Spin spinning={isLoading}>
                    <div className='form-group'>
                        <Field id={'contractors'} name={name}>
                            {(el: FieldAttributes<any>) => {
                                const val = el.field.value;
                                return (
                                    <Select
                                        showSearch
                                        maxTagCount={'responsive'}
                                        value={val?.length > 0 ? val.map((el: IOptions) => el.value) : []}
                                        placeholder={'Выберите пользователя'}
                                        allowClear={!hasNotAccessDeleteOption}
                                        disabled={form.contractors.isDisabled || !form.contractorOrganization.value?.value}
                                        mode={"multiple"}
                                        onChange={handlerSelectTutor}
                                        optionFilterProp="children"
                                        onBlur={_event => {
                                            el.form.setTouched({...el.form.touched, [name]: true})
                                        }}
                                        filterOption={(input, option) => {
                                            if (!option) return false

                                            const optionLabel = option['data-name']
                                            if (typeof optionLabel !== 'string') {
                                                return false
                                            }

                                            return optionLabel.toLowerCase().includes(input.toLowerCase())
                                        }}
                                    >
                                        {contractors?.map((el) => (

                                            <Option
                                                key={el.value}
                                                value={el.value}
                                                data-name={el.label}
                                                disabled={hasNotAccessDeleteOption ? [...initialValueIds].includes(el.value) : false}
                                            >
                                                <div className={classNames(styles.option)}>
                                                    <span>
                                                        <Tooltip title={el.label}>{el.label}</Tooltip>
                                                    </span>

                                                    <span className={styles.code}>
														<Tooltip title={statusName(el.code)}>
															<span className={classNames(styles.rhombus, el.code)}/>
														</Tooltip>
													</span>
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                )
                            }}
                        </Field>
                        {/*// @ts-ignore*/}
                        {errors.stepFourth?.contractors as string && touched[name] && <div className="invalid-feel">{errors.stepFourth?.contractors}</div>}
                    </div>
                </Spin>
            </div>
        </div>

    )
};
export default ContractorFieldForm;