import {axiosRequest} from 'shared/config/axios'
import {
    FamousPeople,
    FamousPeopleRequest,
    FamousPeopleResponse, FamousPeopleSchema, Filter
} from '../types'
import {downloadXLXS} from 'utils/downloadXLXS'

/**
 * Получение списка известных персон
 */
export const getFamousPeople = {
    key: '/famous-people',
    fetch: async (rankId?: string) => {
        const {data} =  await axiosRequest<FamousPeople[]>({
            method: 'GET',
            url: '/famous-people',
            params: {
                rankId
            },
            showError: true,
        })
        return data
    }
}
/**
 * Получение списка известных персон с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
    key: '/famous-people/search',
    fetch: async (filter: Filter): Promise<FamousPeopleResponse> => {
        const newFilter = { ...filter, page: filter.page - 1 };
        const { data } = await axiosRequest<FamousPeopleResponse>({
            method: 'POST',
            url: `/famous-people/search`,
            data: newFilter,
            parser: { list: true, schema: FamousPeopleSchema.array() },
        });
        return data;
    },
};
/**
 * Обновление известных персон
 */
export const update = async ({ id, data }: { id: string; data: FamousPeopleRequest }): Promise<void> => {
    await axiosRequest({
        method: 'PUT',
        url: `/famous-people/${id}`,
        data,
    });
};

/**
 * Создание известных персон
 */
export const create = async (data: FamousPeopleRequest): Promise<void> => {
    await axiosRequest({
        method: 'POST',
        url: `/famous-people`,
        data,
    });
};

/**
 * Удаление известных персон
 */
export const deleteFamousPeople = async (id: string): Promise<void> => {
    await axiosRequest({
        method: 'DELETE',
        url: `/famous-people/${id}`,
    });
};

/**
 * Массовое действие
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
    await axiosRequest({
        method: 'POST',
        url: `/famous-people/bulk-actions/${bulkAction}`,
        data: rowIds,
    });
    return bulkAction
};

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]): Promise<void> => {
    const { data }: any = await axiosRequest({
        method: 'POST',
        url: `/famous-people/export`,
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        data: value,
    });
    downloadXLXS(data, 'famous-people');
};

/**
 * Получение файла шаблона для выполнения импорта данных
 */
export const downloadTemplate = async (): Promise<void> => {
    const { data }: any = await axiosRequest({
        method: 'GET',
        url: '/famous-people/import/template',
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
    });
    downloadXLXS(data, 'famous-people-template');
};

/**
 * Выполнение импорта данных известных персон из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData): Promise<void> => {
    await axiosRequest({
        method: 'POST',
        url: '/famous-people/import',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data,
    });
};