import {takeLatest} from 'redux-saga/effects'
import {ProjectReportingActionTypes} from '../../store/project-reporting/action-types'
import {createReporting} from './createReporting'
import {setFilter} from "./setFilter";
import {getReporting} from "./getReporting";
import {downloadReporting} from "./downloadReporting";
import {getAvailableReports} from "./getAvailableReports";
import {getReportsTemplates} from "./getReportsTemplates";
import {downloadFileArchive} from "./downloadFileArchive";

export default function* projectReportingSaga() {
	yield takeLatest(ProjectReportingActionTypes.GET_ALL_REPORTING_START, getReporting)
	yield takeLatest(ProjectReportingActionTypes.CREATE_REPORTING_START, createReporting)
	yield takeLatest(ProjectReportingActionTypes.DOWNLOAD_REPORTING_START, downloadReporting)
	yield takeLatest(ProjectReportingActionTypes.SET_REPORTING_FILTER, setFilter)
	yield takeLatest(ProjectReportingActionTypes.GET_AVAILABLE_REPORTS_START, getAvailableReports)
	yield takeLatest(ProjectReportingActionTypes.GET_REPORTS_TEMPLATES_START, getReportsTemplates)
	yield takeLatest(ProjectReportingActionTypes.DOWNLOAD_FILE_ARCHIVE_START, downloadFileArchive)
}
