import React, {FC, Key, SyntheticEvent, useEffect, useMemo, useState} from 'react'
import './index.less'
import {Button, Col, Modal, Popconfirm, Select, Table, TablePaginationConfig, Tooltip} from 'antd'
import useHandlerModal from 'hooks/handlModal'
import DatePickerComponent from './components/actions/datePicker'
import StatusesSelect from './components/actions/statusesSelect'
import TypesSelect from './components/actions/typesSelect'
import {useSelector} from 'react-redux'
import {selectArchiveFilter, selectArchiveList} from 'store/archive/selectors'
import {TArchive, TArchiveFilter} from 'models/archive'
import {ColumnsType} from 'antd/es/table'
import {TColumns} from 'models/common'
import ActionCell from 'components/table-cells/action-cell'
import handlerTable from 'utils/handleTable'
import {
	DeleteRecordsStartAction,
	DeleteRecordStartAction,
	GetArchiveListStartAction,
	SetArchiveFilter
} from 'store/archive/actions'
import NameCenterCell from 'components/table-cells/name-center-cell'
import moment from 'moment'
import SockJsClient from "react-stomp";
import {useBulkActions} from "../modal-content-records/useBulkActions";
import {NotificationOpen} from "store/notification/actions";
import WhetherTheUser from "components/whetherTheUser";
import {selectRoleWithOptionalParams} from "store/auth/selectors";
import env from "shared/config/env";
import {DownloadElement} from "utils/createObjUrlFunction";
import {DeleteOutlined} from "@ant-design/icons";

type Props = {
	status: boolean
}

const ModalArchive: FC<Props> = ({ status }) => {
	const { isVisible, closeModal, put } = useHandlerModal(status)
	const {archiveList, isLoading} = useSelector(selectArchiveList)
	const {role, downloadAccess, userId} = useSelector(selectRoleWithOptionalParams)
	const {rowSelection, bulkAction, handlerBulkAction, handlerRowIds, rowsIds} = useBulkActions()
	const [valueSelect,setValueSelect] = useState()

	const [data, setData] = useState<TArchive[] | any>(archiveList?.content)

	const filterArchive = useSelector(selectArchiveFilter)

	useEffect(() => {
		setData(archiveList?.content)
	}, [archiveList])

	const onDownload = (_id: string, rowData: any) => (_e: SyntheticEvent) => {
		if (rowData.status === 'CREATED') {
			DownloadElement(rowData?.file.id, rowData?.file.name)
		} else put(NotificationOpen('error', 'Архив доступен для скачивания только в статусе "Созданный"'))
	}

	const onDelete = (id: string) => () => {
		put(DeleteRecordStartAction(id))
	}

	const columns: ColumnsType<TColumns> = useMemo(()=>{
		const arr: ColumnsType<TColumns>  = [
			{
				title: 'Дата и время создания запроса',
				dataIndex: 'createDateTime',
				key: 'createDateTime',
				render: (date: string) => (date ? NameCenterCell(moment(date).format('DD MMMM YYYY / HH:mm')) : ''),
				width: 100,
				sorter: true,
			},
			{
				title: 'Дата и время автоудаления',
				dataIndex: 'deletionDateTime',
				key: 'deletionDateTime',
				render: (date: string) => (date ? NameCenterCell(moment(date).format('DD MMMM YYYY / HH:mm')) : ''),
				width: 100,
				sorter: true,
			},
			{
				title: 'Наименование архива',
				dataIndex: ['file', 'name'],
				key: 'file',
				width: 200,
				render: NameCenterCell,
			},
			{
				title: 'Объем архива',
				dataIndex: ['file', 'size'],
				key: 'file',
				width: 100,
				render: (size: number) => NameCenterCell(conv_size(size)),
			},
			{
				title: 'Статус  архива',
				dataIndex: 'status',
				key: 'status',
				width: 100,
				render: (status:string) => NameCenterCell(statusName(status)),
			},
			{
				title: '',
				dataIndex: 'id',
				key: 'id',
				width: 25,
				render: (id) => (
					<Popconfirm
						placement="top"
						title={'Вы уверены, что хотите удалить запись с файлом ?'}
						onConfirm={onDelete(id)}
						okText="Подтвердить"
						cancelText="Отменить"
					>
						<div className="table-ModalArchive__delete">
							<DeleteOutlined className="delete-btn"/>
						</div>
					</Popconfirm>

				),
				fixed: 'right',
			},
			{
				title: '',
				dataIndex: 'id',
				key: 'id',
				width: 25,
				render: ActionCell({onDownload: downloadAccess ? onDownload : null}, 'запись с файлом'),
				fixed: 'right',
			},
		]

		if (role !== 'ROLE_CLIENT' && role !== 'ROLE_AGENT' ) arr.splice(2,0,{
			title: 'Тип архива',
			dataIndex: 'entityType',
			key: 'entityType',
			width: 100,
			render: (type: string) => NameCenterCell(typeName(type)),
		})

		return arr
		// eslint-disable-next-line 
	},[])

	const handleTable = (
		pagination: TablePaginationConfig,
		filter: Record<string, (Key | boolean)[] | null>,
		sorter: any
	) => {
		const newFilter = handlerTable(pagination, filter, sorter) as TArchiveFilter
		put(SetArchiveFilter({
			...newFilter,
			filterData: {...filterArchive.filterData},
			sort: sorter.order ? newFilter.sort : {field: "createDateTime", direction: "DESC"}
		}))
	}

	const handlerBulkActions = ( value: any) => {
		setValueSelect(value)
		handlerBulkAction(value)
		handlerRowIds([])
	}

	const handlerApply = () => {
		handlerBulkActions(null)
		put(DeleteRecordsStartAction(rowsIds))
	}

	const onMessageReceived = (msg: any) => {
		console.log(msg)
		msg && put(GetArchiveListStartAction())
	}

	return (
		<Modal
			className="ModalArchive"
			title="Активные запросы на получение архивов"
			visible={isVisible}
			maskClosable={true}
			onCancel={closeModal}
			footer={false}
		>
			<SockJsClient
				url={`${env.API_ENDPOINT}/secured/ws/connect`}
				topics={[`/user/secured/file-archive-requests/status-changed`]}
				headers={{
					'user-name': userId,
				}}
				onMessage={onMessageReceived}
				debug={true}
			/>
			<div className="ModalArchive__content">
				<div className="ModalArchive__content__nav">
					<WhetherTheUser role={["ROLE_CLIENT", "ROLE_AGENT"]} isCan={false}>
						<TypesSelect />
					</WhetherTheUser>
					<StatusesSelect />
					<DatePickerComponent />
					<Col md={5} sm={24} xs={24}>
						<div className="form-group">
							<Select
								allowClear={true}
								options={[{
									label: 'Удалить',
									value:  'delete',
								}]}
								className='ModalArchive__content__select'
								placeholder={'Массовые действия'}
								onChange={handlerBulkActions}
								value={valueSelect}
							/>
						</div>
					</Col>
					{
						rowsIds.length > 0 && (
							<Col md={4} sm={24} xs={24}>
								<div className="form-group">
									<Tooltip title={'Отправить голоса'}>
										<Button type={'primary'} onClick={handlerApply} disabled={false}>
											Применить
										</Button>
									</Tooltip>
								</div>
							</Col>
						)
					}

				</div>
				<Col span={24}>
					<Table
						rowSelection={bulkAction ? {type: 'checkbox', ...rowSelection} : undefined}
						className="table-ModalArchive table"
						sticky
						scroll={{ x: 1000 }}
						columns={columns}
						dataSource={archiveList?.content ? data : []}
						loading={isLoading}
						pagination={{
							current: data ? filterArchive.page : 1,
							pageSize: data ? filterArchive.pageSize : 10,
							total: data ? archiveList?.totalCount : 1,
							position: ['bottomCenter'],
							showSizeChanger: true,
							pageSizeOptions: ['10', '50', '100', '500'],
						}}
						onChange={handleTable}
						rowKey="id"
					/>
				</Col>
			</div>
		</Modal>
	)
}
export default ModalArchive

function conv_size(b: number) {
	const fsizekb: number = b / 1024
	const fsizemb: number = fsizekb / 1024
	const fsizegb: number = fsizemb / 1024
	const fsizetb: number = fsizegb / 1024

	let fsize: string

	if (fsizekb <= 1024) {
		fsize = fsizekb.toFixed(3) + ' кб'
	} else if (fsizekb >= 1024 && fsizemb <= 1024) {
		fsize = fsizemb.toFixed(3) + ' мб'
	} else if (fsizemb >= 1024 && fsizegb <= 1024) {
		fsize = fsizegb.toFixed(3) + ' гб'
	} else {
		fsize = fsizetb.toFixed(3) + ' тб'
	}
	return fsize
}

function statusName(status: string) {
	switch (status) {
		case 'NEW':
			return 'Новый'
		case 'CREATED':
			return 'Созданный'
		case 'ERROR':
			return 'Некорректный'
		default:
			return ''
	}
}

function typeName(type: string) {
	switch (type) {
		case 'ESTIMATE_TASK_RECORD':
			return 'Файл задач на смету'
		case 'CONTENT_TASK_RECORD':
			return 'Файл задач на контент'
		case 'REPORTS':
			return 'Файл с отчетами'
		default:
			return ''
	}
}
