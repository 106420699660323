import { createSelector } from 'reselect'
import { TApplicationState } from '../../aplication-state'
import { IOptions } from 'models/feels'

const getState = (state: TApplicationState) => state.content.content


export const selectContentInfo = createSelector(getState, (state) => ({
	isLoading: state.isLoading,
	typeContent: state.typeContent,
}))
export const selectContentProject = createSelector(getState, (state) => ({
	isLoading: state.isLoading,
	project: state.project,
	content: state.content?.content,
	contentTotalCount: state.content?.totalCount
}))


export const selectFilter = createSelector(
	getState, (state) => state.contentFilter
)

export const selectContentModuleSections = createSelector(getState, (state) =>
	state.contentModuleSections
		? state.contentModuleSections.map((el) => {
				return { value: el.id, label: el.name } as IOptions
		  })
		: null
)
