import React, {FC, useMemo, useState} from 'react'
import {useSelector} from "react-redux";
import {Checkbox, Select, Spin, Switch, Tooltip} from 'antd'
import {Field, useFormikContext} from 'formik'
import {IOptions} from 'models/feels'
import {FieldAttributes} from 'formik/dist/Field'
import classNames from 'classnames'
import styles from './user.module.less'
import {CreateOrEditProjectLib} from "features/create-or-edit-project-form";
import ReloadList from "components/forms/components/reload-list";
import {selectUser} from "store/auth/selectors";
import {UserTypes} from 'entities/user'

const { Option } = Select

type Props = {
	field: keyof Pick<
		CreateOrEditProjectLib.ProjectFormDto['stepFourth'],
		'coordinators' | 'producers' | 'analysts' | 'lawyers' | 'financiers' | 'projectManagers' | 'contentEditors'
	>
	fieldTutor: keyof Pick<
		CreateOrEditProjectLib.ProjectFormDto['stepFourth'],
		| 'producersTutor'
		| 'coordinatorsTutor'
		| 'lawyersTutor'
		| 'financiersTutor'
		| 'analystsTutor'
		| 'projectManagersTutor'
		| 'contentEditorsTutor'
	>
	fieldTutorVisible: keyof Pick<
		CreateOrEditProjectLib.ProjectFormDto['stepFourth'],
		| 'producersTutorVisible'
		| 'coordinatorsTutorVisible'
		| 'lawyersTutorVisible'
		| 'financiersTutorVisible'
		| 'analystsTutorVisible'
		| 'projectManagersTutorVisible'
		| 'contentEditorsTutorVisible'
	>
	options: IOptions[] | null
	disable?: boolean
	disableTutor?: boolean
	disableTutorVisible?: boolean
	label: {
		first: string | React.ReactElement
		second: string
	}
	disabledOptions: string[]
	disabledOptionsTutor: string[]
	errorMessage?: string
	handlerUpdateList: () => void
	isLoadingFeels?: boolean
}

function statusName(status: string | undefined) {
	switch (status) {
		case 'ACTIVE':
			return 'Активен'
		case 'ARCHIVE':
			return 'Архивирован'
		case 'BLOCKED':
			return 'Блокирован'
		default:
			return ''
	}
}

function filingName(key: string): string {
	return `stepFourth.${key}.value`
}

const roleHasNotAccess: UserTypes.RoleName[] = [
	'ROLE_PROJECT_MANAGER',
	'ROLE_PRODUCER',
	'ROLE_CONTENT_EDITOR',
	'ROLE_COORDINATOR',
	'ROLE_LAWYER',
	'ROLE_FINANCIER',
	'ROLE_ANALYST'
]

const UsersFieldForm: FC<Props> = ({
	field,
	fieldTutor,
	fieldTutorVisible,
	options,
	disable,
	disableTutor,
	disableTutorVisible,
	label,
	disabledOptionsTutor,
	disabledOptions,
	errorMessage,
	handlerUpdateList,
   	isLoadingFeels

}) => {
	const [state, setState] = useState(false)
	const {values ,setFieldValue, touched } = useFormikContext<CreateOrEditProjectLib.ProjectFormDto>()
	const user = useSelector(selectUser)

	const formFifth = values.stepFifth
	// eslint-disable-next-line
	const initialValue = useMemo(() => values.stepFourth[field].value,[])// eslint-disable-next-line
	const initialValueIds = useMemo(() => values.stepFourth[field].value.map(it => it.value),[])// eslint-disable-next-line
	const initialValueIdsTutor = useMemo(() => values.stepFourth[fieldTutor].value.map(it => it.value),[])
	const isActualOrArchival =  formFifth.info?.status === 'ARCHIVED' || formFifth.info?.status === 'ACTUAL'
	const isLeader = user?.personalData.leader

	const hasNotAccessDeleteOption = !isLeader && isActualOrArchival && user?.role && roleHasNotAccess.includes(user?.role)

	const handlerSelect = (_value: string[], option: IOptions[] | any) => {
		setFieldValue(filingName(field), option)
	}

	const handlerSelectTutor = (value: string[], option: IOptions[] | any) => {
		setFieldValue(filingName(fieldTutor), option)
	}
	const handlerSwitch = (value: boolean) => {
		setFieldValue(filingName(fieldTutorVisible), value)
	}
	const handlerCheckbox = (val: any) => {
		const checked: boolean = val.target.checked
		setState(checked)
		if (checked) {
			const filteredUsers = options?.filter((el) => !disabledOptions.includes(el.value))
			setFieldValue(filingName(field), filteredUsers)
		} else setFieldValue(filingName(field), hasNotAccessDeleteOption ? initialValue : [])
	}

	return (
		<div className={styles.container}>
			<div className={classNames('label', styles.first_label)}>
				{label.first}
			</div>
			<div className={styles.first}>
				<Spin spinning={isLoadingFeels}>
					<ReloadList className="ident" handlerUpdateList={handlerUpdateList} />
					<div className="form-group">
						<Field id={fieldTutor} name={filingName(field)}>
							{(el: FieldAttributes<any>) => {
								const val = el.field.value
								return (
									<Select
										showSearch
										value={val?.map((el: IOptions) => el.value) || []}
										placeholder={'Выберите пользователей'}
										allowClear={!hasNotAccessDeleteOption}
										disabled={disable}
										onBlur={(_event) => {
											el.form.setTouched({ ...el.form.touched, [field]: true })
										}}
										mode={'multiple'}
										onClear={() => setState(false)}
										onChange={handlerSelect}
										optionFilterProp="children"
										maxTagCount={'responsive'}
										filterOption={(input, option) => {
											if (!option) return false

											const optionLabel = option['data-name']
											if (typeof optionLabel !== 'string') {
												return false
											}

											return optionLabel.toLowerCase().includes(input.toLowerCase())
										}}
										dropdownRender={(menu) => {
											return (
												<>
													<Checkbox
														onChange={handlerCheckbox}
														className={styles.checkboxInSelect}
														checked={state}
														disabled={disable}
													>
														Выбрать всех
													</Checkbox>
													{menu}
												</>
											)
										}}
									>
										{options?.map((el) => (
											<Option
												key={el.value}
												value={el.value}
												disabled={hasNotAccessDeleteOption ?
													[disabledOptions, ...initialValueIds].includes(el.value) :
													disabledOptions.includes(el.value)}
												data-name={el.label}
											>
												<div className={classNames(styles.option)}>
													<span>
														<Tooltip title={el.label}>{el.label}</Tooltip>
													</span>
													<span className={styles.code}>
														<Tooltip title={statusName(el.code)}>
															<span className={classNames(styles.rhombus, el.code)}/>
														</Tooltip>
													</span>
												</div>
											</Option>
										))}
									</Select>
								)
							}}
						</Field>
						{/*// @ts-ignore*/}
						{touched[field] && errorMessage && <div className="invalid-feel">{errorMessage}</div>}
					</div>
				</Spin>
			</div>
			<div className={styles.switch}>
				<Field id={fieldTutorVisible} name={filingName(fieldTutorVisible)}>
					{(el: FieldAttributes<any>) => {
						const val = el.field.value
						return (
							<Switch
								onChange={handlerSwitch}
								checked={val}
								disabled={disable || disableTutorVisible}
								unCheckedChildren={'Показать'}
								checkedChildren={'Скрыть'}
							/>
						)
					}}
				</Field>
			</div>
			<div className={classNames(styles.second_label, 'label')}>{label.second}</div>
			<div className={styles.second}>
				<Spin spinning={isLoadingFeels}>
					<div className="form-group">
						<Field id={fieldTutor} name={filingName(fieldTutor)}>
							{(el: FieldAttributes<any>) => {
								const val = el.field.value
								return (
									<Select
										showSearch
										maxTagCount={'responsive'}
										value={val.length > 0 ? val.map((el: IOptions) => el.value) : []}
										placeholder={'Выберите пользователя'}
										allowClear={!hasNotAccessDeleteOption}
										disabled={disable || disableTutor}
										mode={'multiple'}
										onChange={handlerSelectTutor}
										optionFilterProp="children"
										filterOption={(input, option) => {
											if (!option) return false

											const optionLabel = option['data-name']
											if (typeof optionLabel !== 'string') {
												return false
											}

											return optionLabel.toLowerCase().includes(input.toLowerCase())
										}}
									>
										{options?.map((el) => (
											<Option
												key={el.value}
												value={el.value}
												disabled={hasNotAccessDeleteOption ?
													[disabledOptionsTutor, ...initialValueIdsTutor].includes(el.value) :
													disabledOptionsTutor.includes(el.value)}
												data-name={el.label}
											>
												<div className={classNames(styles.option)}>
													<span>
														<Tooltip title={el.label}>{el.label}</Tooltip>
													</span>
													<span className={styles.code}>
														<Tooltip title={statusName(el.code)}>
															<span className={classNames(styles.rhombus, el.code)}/>
														</Tooltip>
													</span>
												</div>
											</Option>
										))}
									</Select>
								)
							}}
						</Field>
					</div>
				</Spin>
			</div>
		</div>
	)
}

export default React.memo(UsersFieldForm)
