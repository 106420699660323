import React, {FC, useEffect} from 'react';
import styles from './styles.module.less';
import { UserFiltersModel, UserFiltersTypes } from 'features/user-filters';
import {useDispatch, useSelector} from "react-redux";
import {Spin} from "antd";
import Item from "../item";
import {useQuery} from "react-query";
import Api, {ApiKeys} from "../../api";

type Props = {
    filterType: UserFiltersTypes.Types['code']
    needToMakeRequest: boolean
}
const Items: FC<Props> = ({filterType, needToMakeRequest = true}) => {
    const put = useDispatch();
    const {data, isLoading} = useQuery({
        queryKey: [ApiKeys.filtersTypeCode, filterType],
        queryFn: () => Api.getFilters(filterType).then((res) => res.data),
        refetchOnWindowFocus: false,
    })
    const types = useSelector(UserFiltersModel.select.types)
    const {isLoading: isLoadingTypes} = useSelector(UserFiltersModel.select.status)

    useEffect(() => {
        if (!types.length) put(UserFiltersModel.actions.getTypes())
    }, [])//eslint-disable-line


    return (
        <Spin spinning={isLoading || isLoadingTypes}>
            <div className={styles.root}>
                {data && data.map((el) => (
                    <Item
                      key={el.id}
                      filter={el}
                      filterType={filterType}
                      needToMakeRequest={needToMakeRequest}
                    />
                ))}
            </div>
        </Spin>
    );
};

export default Items;