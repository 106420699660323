import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteInternetErrorAction,
    DeleteInternetStartAction,
    DeleteInternetSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import InternetResourcesService from "services/internetResourcesService";


export function* deleteInternet({id}: ReturnType<typeof DeleteInternetStartAction>) {
    try {
        yield InternetResourcesService.deleteInternet(id)
        yield put(DeleteInternetSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.internet))
        yield put(NotificationOpen('success', 'Интернет ресурс удален'))

    } catch (error: any) {
        yield put(DeleteInternetErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить интернет ресурс'))
    }
}
