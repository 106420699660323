import React from 'react';
import { Provider } from 'react-redux';
import configureStore, {history} from "configure-store";
import {fetchersInterceptor} from "http/fetchersInterceptor";

const { store } = configureStore({}, history);
fetchersInterceptor(store);

const WithStore = (component: () => React.ReactNode) => () => {
	return <Provider store={store}>{component()}</Provider>;
};

export default WithStore;
