import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {Routes} from "models/routes";
import {PUBLIC_PATH} from "./names";
import ConfirmPage from "pages/confirm/confirm-page";
import ErrorAuth from "pages/error-auth";


export const publicRoutes: Routes[] = [
    {path: PUBLIC_PATH.APP, Component: (_props, _context) => null},
    {path: PUBLIC_PATH.CONFIRM, Component: ConfirmPage, name: 'confirm'},
    {path: PUBLIC_PATH.ERROR, Component: ErrorAuth, name: 'error-auth'}
]

const PublicModule = () => {
    const path = window.location.pathname;
    if ((path !== '/') && (path !== '/login')) {
        const url = new URL(window.location.href)
        localStorage.setItem('path', url.pathname + url.search)
    }

    return (
        <Switch>
            {publicRoutes.map(({path, Component}) => (
                <Route key={path} path={path} component={Component} exact/>
            ))}
            <Redirect to='/'/>
        </Switch>
    )
};
export default PublicModule;

