import React, { FC } from 'react'
import Reporting from './components/reporting'
import {ReportTypes} from 'entities/reports'

type TData = {
	title: string
	name: string | null
	id: string | null
	type: ReportTypes.ReportType
	fileExtensions: string[]
}

type ModalReportingProps = {
	status: boolean
	data: TData
}

const ModalReporting: FC<ModalReportingProps> = ({ status, data }) => {
	return <Reporting status={status} data={data} />
}
export default ModalReporting
