import {takeLatest} from 'redux-saga/effects'
import {UsersDoingTypes} from '../../../store/options/action-types'
import getActivityUsersList from './getActivityUsersList'
import {setUsersActionsFilter} from './setUsersActionsFilter'
import getActionsUsers from './getActionsUsers'
import {setActionsUsersFilter} from './setActionsUsersFilter'

export default function* usersActionsSaga() {
	yield takeLatest(UsersDoingTypes.GET_ACTIVITY_USERS_LIST_START, getActivityUsersList)
	yield takeLatest(UsersDoingTypes.GET_ACTIONS_USERS_START, getActionsUsers)
	yield takeLatest(UsersDoingTypes.SET_ACTIVITY_USERS_FILTER, setUsersActionsFilter)
	yield takeLatest(UsersDoingTypes.SET_ACTIONS_USERS_FILTER, setActionsUsersFilter)

}
