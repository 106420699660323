import {put} from "redux-saga/effects";
import {
    CreateProjectErrorAction,
    CreateProjectStartAction,
    CreateProjectSuccessAction,
    GetProjectsStartAction,
} from "store/projects/actions";


import Api from "entities/project/api/project";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";


export function* createProject({project}: ReturnType<typeof CreateProjectStartAction>) {
    try {
        const formData = new FormData();

        const {image, ...rest} = project;

        formData.append('project', JSON.stringify(rest));

        if (image) {
            formData.append('image', image.originFileObj as Blob);
        }

        yield Api.createProject(formData);

        yield put(CreateProjectSuccessAction());
        yield put(GetProjectsStartAction());
        yield put(NotificationOpen('success', 'Проект создан'))
    } catch (error: any) {
        yield put(CreateProjectErrorAction(error))
        const message = CreateErrorMessage.response(error);
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось создать проект'))
    }
}