import {useLocation} from "react-router-dom";

type TPath = {
    page: string
    typePage2?: string
    typePage3?: string
}
export default function usePath() {
    const {pathname} = useLocation();
    const path: TPath = {
        page: pathname.split('/')[1],
        typePage2: pathname.split('/')[2],
        typePage3: pathname.split('/')[3]
    }
    return path
}