import {put} from 'redux-saga/effects';
import {AxiosResponse} from 'axios';
import {GetCurrentUser, LoginErrorAction, LoginSuccessAction} from "store/auth/actions";
import AuthService from "services/authService";
import {GetFileStartAction} from "store/files/actions";
import {GetUnfinishedStartAction} from "store/uploader/actions";
import {push} from "connected-react-router";
import {BreadcrumbsLib} from 'processes/breadcrumbs';
import ZodParser from 'shared/services/zod-parser';
import {UserTypes} from 'entities/user';


export function* getCurrentUserWorker(_action: ReturnType<typeof GetCurrentUser>) {
    try {
        const { data: user }: AxiosResponse<UserTypes.CurrentUser> = yield AuthService.getCurrentUser();
        ZodParser.parse(UserTypes.CurrentUserSchema, user, '/current_user');
        AuthService.setToStorageUser(user.id);
        AuthService.setToStorageTime();
        BreadcrumbsLib.SessionStorage.clear(user.id);

        yield put(LoginSuccessAction(user))
        if (user.personalData?.image) yield put(GetFileStartAction(user.personalData?.image.id, user.personalData?.image.type))
        if (user.role === 'ROLE_CONTRACTOR') yield put(GetUnfinishedStartAction())

    } catch (error: any) {
        yield put(push('/error'));
        yield put(LoginErrorAction(error))
    }
}