import { createSelector } from 'reselect'
import { TApplicationState } from '../../aplication-state'
import {TNotificationChannels} from "../../../models/notifications";
import {IOptions} from "../../../models/feels";

const handBookState = (state: TApplicationState) => state.options.notifications

export const selectOptionsNotifications = createSelector(handBookState, (state) => state.notifications)

export const selectNotificationChannelsOptions = createSelector(handBookState, (state) => state.notificationChannels
	?.filter(it=> ['SMS','EMAIL', 'TELEGRAM', 'SYSTEM'].includes(it.notificationChannelsEndpoints))
	?.map((notification:TNotificationChannels)=>{
		return {label:notification.notificationChannelsEndpoints, value: notification.id} as IOptions
	}))

export const selectActiveNotificationChannels = createSelector(handBookState, (state) => state.activeNotificationChannels)
export const selectActiveNotificationChannelsOptions = createSelector(handBookState, (state) => state.activeNotificationChannels
	?.map((notification:TNotificationChannels)=>{
		return {label:notification.notificationChannelsEndpoints, value: notification.notificationChannelsEndpoints} as IOptions
	}))


export const selectNotificationInfo= createSelector(handBookState, selectNotificationChannelsOptions,selectActiveNotificationChannelsOptions,
	(state,channelsOptions,activeChannelsOptions) => ({
	channelsOptions:channelsOptions,
	activeChannelsOptions:activeChannelsOptions,
	isLoading: state.isLoading,
	error: state.error,
}))

export const selectOptionsNotificationsIsLoading = createSelector(handBookState, (state) => ({
	isLoading: state.isLoading,
	error: state.error,
}))

export const selectNotificationFilter = createSelector(handBookState, (state) => state.filter)

