import React from 'react';
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectArchiveFilter} from "../../../../../store/archive/selectors";
import {TArchiveFilter} from "../../../../../models/archive";
import {SetArchiveFilter} from "../../../../../store/archive/actions";

const options = [
    {label: 'Файл задач на смету', value: 'ESTIMATE_TASK_RECORD'},
    {label: 'Файл задач на контент', value: 'CONTENT_TASK_RECORD'},
    {label: 'Файл с отчетами', value: 'REPORTS'}
]

const TypesSelect = () => {
    const put = useDispatch()
    const filter = useSelector(selectArchiveFilter)

    const handlerChange = (value: any) => {
        const  newFilter: TArchiveFilter = { ...filter, filterData: {...filter.filterData , entityType: value}}
        put(SetArchiveFilter(newFilter))
    }
    return (
        <div className="form-group form-group--types">
            <Select
                allowClear={true}
                value={filter.filterData?.entityType}
                onChange={handlerChange}
                placeholder="Тип архива"
                options={options}
                showSearch={false}
            />
        </div>
    )
}

export default TypesSelect;