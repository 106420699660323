import React, {useState} from 'react';
import {Button} from "antd";
import './index.less'
import {useLocation} from "react-router-dom";
import AuthService from "../../services/authService";
const Cookie = () => {
    const [state, setState] = useState<undefined | string>(AuthService.getCookie('cookieUse'));
    const {pathname} = useLocation();
    const handler = () => {
        AuthService.setCookie()
        setState('yes')
    }
    return (
        <div className={`cookie ${pathname.split('/')[1]} ${state}`}>
            <p>
                Данный сайт использует файлы «cookie» с целью персонализации сервисов и повышения удобства пользования веб-сайтом.
                Если вы не хотите, чтобы ваши пользовательские данные обрабатывались, пожалуйста, ограничьте их использование в
                своём браузере
            </p>
           <Button className='btn' type={"primary"} onClick={handler}>Ок</Button>
        </div>
    )
};
export default Cookie;