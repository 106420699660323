import {ErrorActionState, StartActionState, SuccessActionState} from "../../../utils/reducerHalper";
import {InferValueTypes, T_ID_NAME, T_ID_NAME_CODE} from "../../../models/common";

import * as actions from '../actions/tasks'
import {TPostingTask} from "../../../models/task";
import {TasksActionTypes} from "../action-types/tasks";
import {TTaskStages} from "../../../models/task/task.stages";
import {TypesTask} from "../../../models/task/types";
import {AuthActionTypes} from "../../auth/action-types";
import {Logout} from "../../auth/reducer";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TTasksFieldsState = {
    types: Array<{code: TypesTask} & T_ID_NAME> | null
    statuses: T_ID_NAME_CODE[] | null
    stages: Array<{
        code: TTaskStages | string
    } & T_ID_NAME> | null
    posting: TPostingTask[] | null
    costType: T_ID_NAME_CODE[] | null
    recordsStatuses: T_ID_NAME_CODE[] | null
    isLoading: boolean
    error: any
}

export const initialState: TTasksFieldsState = {
    types: null,
    statuses: null,
    stages: null,
    posting: null,
    costType: null,
    recordsStatuses: null,
    isLoading: false,
    error: null
}

export default function tasksFieldsReducer(state: TTasksFieldsState = initialState, action: ActionTypes | Logout): TTasksFieldsState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState}
        case TasksActionTypes.GET_TASKS_FIELDS_STAT:
            return StartActionState(state);
        case TasksActionTypes.GET_TASKS_FIELDS_SUCCESS:
            return {
                ...SuccessActionState(state),
                [action.typeField]: action.filed
            }
        case TasksActionTypes.GET_TASKS_FIELDS_ERROR:
            return ErrorActionState(state, action)
        default:
            return state
    }
}
