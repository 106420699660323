import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetAllTasksNPErrorAction,
    GetAllTasksNPStartAction,
    GetAllTasksNPSuccessAction,
} from "../../store/tasks/actions/tasks";
import TasksService from "../../services/tasksService";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../store/notification/actions";
import {TAllTasksListResponse} from "../../models/task/task-list";


export function* getAllTasksNP({filter}: ReturnType<typeof GetAllTasksNPStartAction>) {
    try {
        const {data}: AxiosResponse<TAllTasksListResponse[]> = yield TasksService.getAllTasks(filter);

        yield put(GetAllTasksNPSuccessAction(data))
    } catch (error: any) {
        yield put(GetAllTasksNPErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить задачи'))
    }
}