const main = [
    {
        id: '1',
        selector: '.mainMenu',
        content: 'Это меню портала с основными разделами системы',
        role: [],
        mediaHidden: 970
    },
    {
        id: '1.1',
        selector: '.infoMenu__menu',
        content: 'Это меню портала с основными разделами системы. Пункт настройки позволяет управлять пользователями, уведомлениями, справочниками, конкурсами и сессиями, менять персональные настройки',
        role: [],
        mediaShow: 970
    },
    {
        id: '2',
        selector: '.infoMenu__tour',
        content: 'Эта кнопка позволит вам ознакомиться с туром по Системе. Вы можете использовать её на любой странице для получения подсказок. Так вы сможете лучше ориентироваться в основных функциях разделов',
        role: [],
        mediaHidden: 400
    },
    {
        id: '2.2',
        selector: '.infoMenu__support',
        content: 'Эта кнопка позволяет связаться со службой поддержки',
        role: [],
        mediaHidden: 400
    },
    {
        id: '3',
        selector: '.infoMenu__notification',
        content: 'Сюда приходят все системные push-уведомления о проекте, задачах и ' +
            'других новостях. Нажмите, чтобы открыть уведомления для просмотра',
        role: [
            'ROLE_ADMIN',
            'ROLE_COORDINATOR',
            'ROLE_PRODUCER',
            'ROLE_ANALYST',
            'ROLE_FINANCIER',
            'ROLE_LAWYER',
            'ROLE_MODERATOR',
            'ROLE_CONTRACTOR',
            'ROLE_PROJECT_MANAGER',
            'ROLE_ADMIN_IS'
        ]
    },
    {
        id: '4.4',
        selector: '.filters',
        content: 'Это расширенный фильтр для списка проектов, чтобы вам легко было найти нужные данные',
        role: [
            'ROLE_ADMIN',
            'ROLE_COORDINATOR',
            'ROLE_PRODUCER',
            'ROLE_ANALYST',
            'ROLE_FINANCIER',
            'ROLE_LAWYER',
            'ROLE_CLIENT',
            'ROLE_MODERATOR',
            'ROLE_AGENT',
            'ROLE_PROJECT_MANAGER',
            'ROLE_CONTRACTOR',
            'ROLE_VIEWER',
            'ROLE_CONTENT_EDITOR',
            'ROLE_ADMIN_IS'
        ]
    },
    {
        id: '5',
        selector: '.infoMenu__user',
        content: 'Здесь можно посмотреть свой профиль и данные о нем, а также ознакомиться с инструкцией пользователя',
        role: []
    },
    {
        id: '6',
        selector: '.infoMenu__setting',
        content: 'Модуль настройки позволяет управлять пользователями, уведомлениями, справочниками, конкурсами и сессиями',
        role: ['ROLE_ADMIN','ROLE_ADMIN_IS'],
        mediaHidden: 970
    },
    {
        id: '6',
        selector: '.infoMenu__setting',
        content: 'Модуль настройки позволяет управлять подписками, подтверждениями, уведомлениями',
        role: ['ROLE_CONTRACTOR'],
        mediaHidden: 970
    },
    {
        id: '6',
        selector: '.infoMenu__setting',
        content: 'Модуль настройки позволяет менять персональные настройки',
        role: ['ROLE_ANALYST','ROLE_FINANCIER','ROLE_LAWYER','ROLE_VIEWER'],
        mediaHidden: 970
    },
    {
        id: '6',
        selector: '.infoMenu__setting',
        content: 'Модуль настройки позволяет управлять  справочниками, конкурсами и сессиями',
        role: ['ROLE_COORDINATOR', 'ROLE_MODERATOR','ROLE_PRODUCER','ROLE_PROJECT_MANAGER'],
        mediaHidden: 970
    },
    {
        id: '7',
        selector: '.infoMenu__logout',
        content: 'С помощью этой кнопки можно завершить работу в системе',
        role: []
    },
    {
        id: '8',
        selector: '.projectsPage__btn',
        content: 'Жмите на кнопку “Создать проект” и добавляйте новый проект',
        role: ['ROLE_ADMIN','ROLE_COORDINATOR','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS']
    },
    {
        id: '9',
        selector: '.table-project',
        content: 'Таблица проектов отражает ключевые данные по вашим проектам. Позволяет отсортировать данные, отредактировать, удалить и изменить статус проекта. Для детального просмотра проекта нажмите на строку таблицы с нужным проектом. Вы попадёте в карточку проекта',
        role: ['ROLE_ADMIN','ROLE_COORDINATOR','ROLE_CLIENT','ROLE_MODERATOR', 'ROLE_AGENT','ROLE_PROJECT_MANAGER', 'ROLE_ADMIN_IS']
    },
    {
        id: '9',
        selector: '.table-project',
        content: 'Таблица проектов позволяет просматривать и отсортировывать ключевые данные по вашим проектам. Для детального просмотра карточки проекта нажмите на строку таблицы с нужным проектом',
        role: ['ROLE_CONTRACTOR','ROLE_PRODUCER','ROLE_VIEWER']
    },
    {
        id: '9',
        selector: '.table-project',
        content: 'Таблица проектов отражает ключевые данные по вашим проектам. Позволяет отсортировать данные, редактировать данные проекта. Для детального просмотра проекта нажмите на строку таблицы с нужным проектом. Вы попадёте в карточку проекта',
        role: ['ROLE_ANALYST','ROLE_FINANCIER','ROLE_LAWYER']
    },
    {
        id: '10',
        selector: '.logo-header',
        content: 'При нажатии на логотип АНО ИРИ с любой страницы системы вы попадаете на главную страницу со списком проектов',
        role: []
    },
    {
        id: '11',
        selector: '.projectsPage__actions',
        content: 'Массовые действия позволяют изменить статус нескольких проектов одновременно или удалить их',
        role: [],
        roleCant: ['ROLE_ADMIN','ROLE_MODERATOR','ROLE_COORDINATOR','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS']
    },
]
export default main;
