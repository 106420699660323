import {TApplicationState} from "store/aplication-state";
import {createSelector} from "reselect";

const getState = (state: TApplicationState) => state.tasks.taskComments.comments
export const store = createSelector(getState, (state) => ({
    comments: state.comments,
    filter: state.filter,
    isLoading: state.isLoading,
}))
export const comments = createSelector(getState, (state) => state.comments)
export const filter = createSelector(getState, (state) => state.filter)
export const isLoading = createSelector(getState, (state) => state.isLoading)