import React from 'react';
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectFilter} from "store/content/content/selectors";
import {SetSortingFilter} from "store/content/content/actions";
import {TFilterData} from "models/content";

const options = [
  {label: 'Мастер файлы' , value: 'true'},
  {label: 'Предмастер файлы' , value: 'false'}
]

const TypeContentSelect = () => {
  const put = useDispatch()
  const filter = useSelector(selectFilter)

  const onSelectStatus = (value: string) => {
    let newFilter: TFilterData = {};
    if (filter.filterData) newFilter = { ...filter.filterData }
    newFilter = { ...newFilter, isMasterFile: value === 'true'}

    put(SetSortingFilter({ ...filter, filterData: newFilter }))
  }
  return (
    <div className="sorting__item">
      <Select
        value={filter.filterData?.isMasterFile ? 'true' : 'false'}
        style={{ 'width': '200px' }}
        placeholder="Все виды контента"
        onChange={onSelectStatus}
        maxTagCount={"responsive"}
        showSearch
        options={options}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
        }
      />
    </div>
  )
}

export default TypeContentSelect;