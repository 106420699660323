import {TFilterData, FieldFilter} from "./types/fileds";
import { SearchContentProjectTypes } from 'features/search-project-content';

import moment from "moment/moment";

export function mapData(filter: SearchContentProjectTypes.Stores.TAllFilterValues): Partial<TFilterData> {
    const {fields} = getFieldFilters(filter);
    return {
        ...(!!filter.contests.contestId && { contestId: filter.contests.contestId}),
        ...(!!filter.contests.year && { year: filter.contests.year}),
        ...(!!filter.contests.sessionId && { sessionId: filter.contests.sessionId}),

        ...(!!filter.status.statusIds.length && { statusIds: filter.status.statusIds}),
        ...(!!filter.status.contentDirectionIds.length && { contentDirectionIds: filter.status.contentDirectionIds}),

        ...(filter.indicators.isProjectPosted !== undefined && { isProjectPosted: filter.indicators.isProjectPosted}),
        ...(filter.indicators.isFavoriteProject !== undefined && { isFavoriteProject: filter.indicators.isFavoriteProject}),

        ...(filter.indicatorsContent.isContent !== undefined && { isContent: filter.indicatorsContent.isContent}),
        ...(filter.indicatorsContent.isAccountedContent !== undefined && { isAccountedContent: filter.indicatorsContent.isAccountedContent}),
        ...(filter.indicatorsContent.isMasterContent !== undefined && { isMasterContent: filter.indicatorsContent.isMasterContent}),
        ...(!!filter.indicatorsContent.contentStatusCodes?.length && { contentStatusCodes: filter.indicatorsContent.contentStatusCodes}),

        ...(!!filter.thematics.firstLevelTagIds.length && { firstLevelTagIds: filter.thematics.firstLevelTagIds}),
        ...(!!filter.thematics.secondLevelTagIds.length && { secondLevelTagIds: filter.thematics.secondLevelTagIds}),
        ...(!!filter.thematics.thirdLevelTagIds.length && { thirdLevelTagIds: filter.thematics.thirdLevelTagIds}),
        ...(!!filter.thematics.contentFilterZeroLvlTagIds.length && { contentFilterZeroLvlTagIds: filter.thematics.contentFilterZeroLvlTagIds}),
        ...(!!filter.thematics.contentFilterFirstLvlTagIds.length && { contentFilterFirstLvlTagIds: filter.thematics.contentFilterFirstLvlTagIds}),
        ...(!!filter.thematics.contentFilterSecondLvlTagIds.length && { contentFilterSecondLvlTagIds: filter.thematics.contentFilterSecondLvlTagIds}),
        ...(!!filter.thematics.contentFilterThirdLvlTagIds.length && { contentFilterThirdLvlTagIds: filter.thematics.contentFilterThirdLvlTagIds}),

        ...(!!filter.regionals.length && getRegionals(filter.regionals)),
        ...(!!filter.categoriesGenres.length && getCategoriesGenres(filter.categoriesGenres)),

        ...(!!filter.content.contentFormatTypeIds.length && { contentFormatTypeIds: filter.content.contentFormatTypeIds}),
        ...(!!filter.content.contentFilterContentSubmissionFormIds.length && { contentFilterContentSubmissionFormIds: filter.content.contentFilterContentSubmissionFormIds}),
        ...(!!filter.content.contentSubmissionFormIds.length && { contentSubmissionFormIds: filter.content.contentSubmissionFormIds}),
        ...(!!filter.content.contentFilterContentFormatTypeIds.length && { contentFilterContentFormatTypeIds: filter.content.contentFilterContentFormatTypeIds}),

        ...(!!fields.length && { fieldFilters: fields}),
    }
}
type Regions = {
    regionAboutIds: TFilterData["regionAboutIds"]
    regionForIds: TFilterData["regionForIds"]
    contentFilterRegionAbout: TFilterData["contentFilterRegionAbout"]
    contentFilterRegionFor: TFilterData["contentFilterRegionFor"]
}
function getRegionals(value: SearchContentProjectTypes.Stores.TAllFilterValues["regionals"]): Partial<Regions> {
    const res: Partial<Regions> = {};
    value.forEach((el) => {
        if (el.values.length) {
            if (el.field === 'REGION_ABOUT') {
                res.regionAboutIds = el.values
            }
            if (el.field === 'REGION_FOR') {
                res.regionForIds = el.values
            }
            if (el.field === 'CONTENT_FILTER_REGION_ABOUT') {
                res.contentFilterRegionAbout = el.values
            }
            if (el.field === 'CONTENT_FILTER_REGION_FOR') {
                res.contentFilterRegionFor = el.values
            }
        }

    })
    return res;
}
type CategoriesGenres = {
    genreIds: TFilterData["genreIds"]
    categoryIds: TFilterData["categoryIds"]
}
function getCategoriesGenres(value: SearchContentProjectTypes.Stores.TAllFilterValues["categoriesGenres"]): Partial<CategoriesGenres> {
    const res: Partial<CategoriesGenres> = {};
    value.forEach((el) => {
        if (el.values.length) {
            if (el.field === 'CATEGORY') {
                res.categoryIds = el.values
            }
            if (el.field === 'GENRE') {
                res.genreIds = el.values
            }
        }

    })
    return res;
}

function getFieldFilters(filter: SearchContentProjectTypes.Stores.TAllFilterValues): {
    fields: TFilterData["fieldFilters"],
} {
    const fields: TFilterData["fieldFilters"]= [];

    if (!!filter.dates.length) {
        filter.dates.forEach((el) => {
            if (el.startDate && el.endDate) {
                fields.push({
                    field: el.field,
                    startDate: moment(el.startDate, 'DD.MM.YYYY').toISOString(true),
                    endDate: moment(el.endDate, 'DD.MM.YYYY').toISOString(true)
                } as FieldFilter)
            }
        })
    }
    return {fields}
}