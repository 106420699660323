import {z} from "zod";
import {ResponsibleUserSchema} from "./responsible-user";
import {SprintTypes} from "entities/sprints";
import { ProjectTypes } from 'entities/project';

export const CalendarSchema = z.object({
    id: z.string(),
    name: z.string(),
    contractNumber: z.string(),
    requestNumber: z.string(),
    contractDate: z.string(),
    completionDate: z.string(),
    responsibleUsers: z.array(ResponsibleUserSchema),
    sprints: z.array(SprintTypes.SprintSchema),
    status: z.object({
        id: z.string(),
        name: z.string(),
        code: ProjectTypes.ProjectStatusNativeEnum,
    })
})
export type Calendar = z.infer<typeof CalendarSchema>;