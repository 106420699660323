import React from "react";
import {Route} from "react-router-dom";
import {Routes} from "models/routes";
import { UserTypes } from "entities/user";

export default function CreateRouting(
  routes: Routes[],
  role?: UserTypes.RoleName,
  optionalParams?: {contentViewAccess?: boolean}
) {
    return routes
        .map(({
            path,
            Component,
            exact = true,
            roleHasAccess,
            roleHasNotAccess,
            checkContentViewAccess,
            notAvailableContractor,
            notAvailableFinanciers,
            notAvailableAdmin,
            notAvailableAnalyst,
            notAvailableCoordinator,
            notAvailableProducer,
            notAvailableLawyer,
            notAvailableClient,
            notAvailableViewer,
            notAvailableModerator,
            notAvailableAgent,
            notAvailableContentEditor,
            notAvailableProjectManager
       }) => {
            if (optionalParams?.contentViewAccess === false && checkContentViewAccess) return null

            if (!!roleHasNotAccess?.length && role) {
              if (roleHasNotAccess.includes(role)) {
                return null;
              } else {
                return <Route key={path} path={path} component={Component} strict exact={exact} />
              }
            }
            if (!!roleHasAccess?.length && role) {
              if (roleHasAccess.includes(role)) {
                return <Route key={path} path={path} component={Component} strict exact={exact} />
              } else {
                return null
              }
            }
            //todo удалить по переменной
            if (role === 'ROLE_CONTRACTOR' && notAvailableContractor) return null
            if (role === 'ROLE_FINANCIER' && notAvailableFinanciers) return null
            if (role === 'ROLE_ADMIN' && notAvailableAdmin) return null
            if (role === 'ROLE_ANALYST' && notAvailableAnalyst) return null
            if (role === 'ROLE_COORDINATOR' && notAvailableCoordinator) return null
            if (role === 'ROLE_PRODUCER' && notAvailableProducer) return null
            if (role === 'ROLE_LAWYER' && notAvailableLawyer) return null
            if (role === 'ROLE_CLIENT' && notAvailableClient) return null
            if (role === 'ROLE_VIEWER' && notAvailableViewer) return null
            if (role === 'ROLE_MODERATOR' && notAvailableModerator) return null
            if (role === 'ROLE_AGENT' && notAvailableAgent) return null
            if (role === 'ROLE_CONTENT_EDITOR' && notAvailableContentEditor) return null
            if (role === 'ROLE_PROJECT_MANAGER' && notAvailableProjectManager) return null
            return <Route key={path} path={path} component={Component} strict exact={exact} />
        })
}