export const UploaderActionTypes = {
    OPEN_MODAL: '[UPLOADER] OPEN_MODAL',
    CLOSE_MODAL: '[UPLOADER] CLOSE_MODAL',

    SET_FILE: '[UPLOADER] SET_FILE',
    SET_FILE_ID: '[UPLOADER] SET_FILE_ID',
    CLEAR: '[UPLOADER] CLEAR',

    START_LOAD: '[UPLOADER] START_LOAD',
    ABORTED_LOAD: '[UPLOADER] ABORTED_LOAD',
    FINISH_LOAD: '[UPLOADER] FINISH_LOAD',

    GET_UNFINISHED_START: '[UPLOADER] GET_UNFINISHED_START',
    GET_UNFINISHED_SUCCESS: '[UPLOADER] GET_UNFINISHED_SUCCESS',
    GET_UNFINISHED_ERROR: '[UPLOADER] GET_UNFINISHED_ERROR',

    DELETE_FILES_START: '[UPLOADER] DELETE_FILES_START',
    DELETE_FILES_SUCCESS: '[UPLOADER] DELETE_FILES_SUCCESS',
    DELETE_FILES_ERROR: '[UPLOADER] DELETE_FILES_ERROR',
} as const