import { Logger } from 'shared/services'
import { selectUsersListFilter } from 'store/options/selectors/selectors-users'
import Api from 'features/user-filters/api'
import { FilterValues } from 'features/user-filters/model/store/reducer'
import { put, select } from 'redux-saga/effects'
import { setUserFiltersValue } from 'features/user-filters/model/store/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import { NotificationOpen } from 'store/notification/actions'
import { TUserOptionsFilter } from 'models/user'

export function* users(typeId: string, filter?: FilterValues) {
	try {
		const usersFilter: TUserOptionsFilter = yield select(selectUsersListFilter)
		if (filter?.activeFilterId) {
			yield Api.update(filter.activeFilterId, {
				filterTypeId: typeId,
				name: filter.filterName,
				filterData: JSON.stringify(usersFilter.filterData),
			})
		} else {
			yield Api.create({
				filterTypeId: typeId,
				name: filter?.filterName,
				filterData: JSON.stringify(usersFilter.filterData),
			})
		}
	} catch (error) {
		Logger.error('createFilterWorker/search', error)
		yield put(setUserFiltersValue({ error: error }))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
	}
}
