import React, {FC, useEffect} from 'react'
import './index.less'
import useHandlerModal from 'hooks/handlModal'
import {Button, Modal, Spin, Tooltip} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {selectUser} from 'store/auth/selectors'
import {selectFile} from 'store/files/selectors'
import {Form, Formik} from 'formik'
import convertName from 'utils/converName'
import {InputComponent, UploadComponent} from 'components/formic-control'
import {ExclamationCircleOutlined, InfoCircleOutlined, SyncOutlined,} from '@ant-design/icons'
import * as Yup from 'yup'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import OrganizationField from './feels/organization-field/organization-field'
import {TOrganizations} from 'models/users-list/user-form'
import {TUserProfileForm} from 'models/user'
import {ClearIsSuccessAction, UpdatePersonalDataStartAction} from 'store/options/actions/actions-users'
import {selectUsersStatus} from 'store/options/selectors/selectors-users'
import WhetherTheUser from "components/whetherTheUser";
import ConvertRole from "utils/convertRole";
import UserGuide from "./feels/user-guide/user-guide";

type TModalProps = {
	status: boolean
}

const validationSchema = Yup.object({
	firstName: Yup.string().required('Обязательное поле имя'),
	lastName: Yup.string().required('Обязательное поле фамилия'),
	middleName: Yup.string(),
	organizations: Yup.array(
		Yup.object({
			phones: Yup.mixed().test('Телефон', 'Добавьте хотябы один номер', (val: any) => !!val.length),
			emails: Yup.mixed().test('Почта', 'Добавьте хотябы одну почту', (val: any) => !!val.length),
		})
	),
})

const convertOrganizations = (data: TOrganizations[] | any) => {
	return data.map((it: TOrganizations) => ({
		id: { value: it.id, label: it.name, code: it.isContentCustomer },
		phones: it.phones,
		emails: it.emails,
		inn: it.inn
	}))
}

const ModalUserProfile: FC<TModalProps> = ({ status }) => {
	const { isVisible, closeModal } = useHandlerModal(status)
	const put = useDispatch()

	const user = useSelector(selectUser)
	const { isSuccess,isLoading } = useSelector(selectUsersStatus)
	const img = useSelector(selectFile(user?.personalData?.image?.id))

	const initialValue: TUserProfileForm = {
		firstName: user?.personalData ? user.personalData.firstName : '',
		lastName: user?.personalData ? user.personalData.lastName : '',
		role: user?.role ? ConvertRole(user?.role) : '',
		middleName: user?.personalData?.middleName ?? '',
		login: user?.username ? user.username : '',
		image: [],
		imageId: user?.personalData?.image?.id ? user?.personalData?.image?.id : null,
		organizations: user ? convertOrganizations(user?.personalData?.organizations) : [],
	}

	useEffect(() => {
		if (isSuccess) {
			closeModal()
			put(ClearIsSuccessAction())
		}
		// eslint-disable-next-line
	}, [isSuccess])

	const onSubmit = (value: any) => {
		put(UpdatePersonalDataStartAction(value))
	}
	return (
		<Modal
			className="modal-user-profile"
			title={'Информация профиля'}
			visible={isVisible}
			maskClosable={true}
			footer={false}
			onCancel={closeModal}
		>
			<Spin tip="Идёт обновление, пожалуйста ждите..." spinning={isLoading}>
				<div className="modal-user-profile__body">
					<Formik
						enableReinitialize
						initialValues={initialValue}
						onSubmit={onSubmit}
						validationSchema={validationSchema}
						validateOnMount
					>
						{(formik) => {
							return (
								<Form className="modal-user-profile__body__form form">
									<div className="form__info">
										<div className="form__avatar-wrapper">
											<div className="form__avatar">
												{user && img?.data ? (
													<img className="img" src={img?.data} alt={user.username} />
												) : (
													<div className="noImg">{convertName(user)}</div>
												)}
											</div>
											<div className="form__upload">
												<UploadComponent
													disabled={false}
													name="image"
													typeUploadFile={'image/*'}
													multi={false}
													icon={<SyncOutlined />}
												/>
											</div>
										</div>
										<div className="form__info__user-info user-info">
											<WhetherTheUser role={['ROLE_VIEWER']} isCan={false}>
												<>{user && user?.role && RenderPersonalData('Роль', 'role', true, 'user-info__role')}</>
											</WhetherTheUser>
											{/*TODO (refactoring) убрать инпуты, т.к редактировать у всех полей значение нельзя*/}
											{user?.personalData &&
												user?.personalData.lastName &&
												RenderPersonalData('Фамилия', 'lastName', true, 'user-info__lastName')}
											{user?.personalData &&
												user?.personalData.firstName &&
												RenderPersonalData('Имя', 'firstName', true, 'user-info__firstName')}
											{user?.personalData &&
												user?.personalData.middleName &&
												RenderPersonalData('Отчество', 'middleName', true, 'user-info__middleName')}
											{user?.username && user?.username && RenderPersonalData('Логин', 'login', true, 'user-info__login')}
										</div>
									</div>
									<p className="form__text">
										Контактные данные
										<span>
										<Tooltip
											title={TooltipTitle()}
											placement="right"
											overlayInnerStyle={{ width: '400px', borderRadius: '5px' }}
										>
											<ExclamationCircleOutlined />
										</Tooltip>
									</span>
									</p>
									<hr />
									<OrganizationField name={'organizations'} />
									<div className="form__footer">
										<UserGuide/>
										<div className="form__footer__confirm">
											<p>
											<span>
												<InfoCircleOutlined />
												Изменение персональных данных
											</span>
												<br />
												Обращаем ваше внимание, что при смене email потребуется выполнить повторный вход в личный кабинет.
												Подтвердить изменения персональных данных?
											</p>
											<div className="form__footer__buttons">
												<Button type="default" className="arrowBtn form__footer__buttons--cancel" onClick={closeModal}>
													Отменить
												</Button>
												<Tooltip title={CreateErrorMessage.form(formik.errors)}>
													<Button
														disabled={!formik.isValid}
														type="primary"
														htmlType="submit"
														className="arrowBtn"
														onClick={() => {}}
													>
														Сохранить
													</Button>
												</Tooltip>
											</div>
										</div>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</Spin>
		</Modal>
	)
}

function RenderPersonalData(label: string, name: any, disabled: boolean, className?: string) {
	return (
		<div className={className}>
			<p>{label}</p>
			<InputComponent type="text" name={name} allowClear={false} disabled={disabled} />
		</div>
	)
}

function TooltipTitle() {
	return (
		<div className="modal-user-profile__tooltip">
			<p>1.Первое значение в поле email /телефон определяется как “Основное”
				<WhetherTheUser role={["ROLE_CLIENT", "ROLE_VIEWER", "ROLE_AGENT"]} isCan={false}>
					<span>, на которое будут приходить уведомления</span>
				</WhetherTheUser>
			</p>
			<p>2. Основной email /телефон может быть только один.</p>
		</div>
	)
}

export default ModalUserProfile
