import React, {FC, useRef} from "react";
import TextError from "./text-error";
import {ErrorMessage, Field, useFormikContext} from "formik";
import {FieldAttributes} from "formik/dist/Field";
import {Select} from 'antd';
import {IOptions, ISelect} from "models/feels";

const { Option } = Select;

const SelectComponent: FC<ISelect> = ({
    name,
    multi,
    label,
    placeholder,
    options,
    handler,
    showError = true,
    allowClear = true,
    errorMessage,
    disabled,
    disabledOptions,
    disabledOptionsIds,
    maxTagCount,
    dropdownRender,
    loading,
    renderOptionsFunction
}) => {
    const {setFieldValue, touched} = useFormikContext()
    const ref = useRef(null);
    const handleChange = (value: string, option: any) => {
        if (option && option.value) {
            setFieldValue(name, {value: option.value, label: option.children, code: option['data-code']} as IOptions);
            handler && handler({value: option.value, label: option.children, code: option['data-code']} as IOptions)
        } else if (Array.isArray(option)) {
            const arr = option.map((el) => {
                return {value: el.value, label: el.children, code: el['data-code']} as IOptions
            })
            setFieldValue(name, arr);
            handler && handler(arr)
        } else {
            setFieldValue(name, '');
        }
    }

    return (
        <div className='form-group'>
            {label && (
                <label htmlFor={name}>{label}</label>
            )}
            <Field id={name} name={name} >
                {(el: FieldAttributes<any>) => {
                    const val = el.field.value;
                    const isError = errorMessage ? errorMessage : el.meta.error
                    const isTouched = el.form.touched[name] ? el.form.touched[name] : el.meta.touched;
                    return (
                        <div className={`select-wrapper ${isTouched && isError ? 'error' : ''}`}>
                            <Select
                                loading={loading}
                                getPopupContainer={trigger => trigger.parentNode}
                                ref={ref}
                                disabled={disabled}
                                mode={multi ? 'multiple' : undefined}
                                showSearch
                                allowClear={allowClear}
                                onClear={() => {
                                    handler && handler('')
                                }}
                                onBlur={_event => {
                                    el.form.setTouched({...el.form.touched, [name]: true})
                                }}
                                onChange={handleChange}
                                maxTagCount={maxTagCount}
                                placeholder={placeholder}
                                value={multi ? returnValue(val) : val?.label}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    if (option === undefined || option === null) return false

                                    const optionText = typeof option?.children === 'string' ? option?.children : option?.['data-code'];
                                    return optionText?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                                dropdownRender={(menu) => {
                                    return (
                                        <>
                                            {dropdownRender && dropdownRender}
                                            {menu}
                                        </>
                                    )
                                }}
                            >
                                {/*// @ts-ignore*/}
                                {options && options.map((options: string | IOptions) => renderOptionsFunction ?
                                  renderOptionsFunction(
                                    options as IOptions,
                                    disabledOptions,
                                    disabledOptionsIds
                                  ) :
                                  renderOptions(
                                    options,
                                    disabledOptions,
                                    disabledOptionsIds
                                ))}
                            </Select>
                        </div>
                    )
                }}
            </Field>
            {showError && <ErrorMessage name={name} component={TextError}/>}
            {/*// @ts-ignore*/}
            {errorMessage && touched[name] && <div className="invalid-feel">{errorMessage}</div>}
        </div>
    )
}

function returnValue(val?: IOptions[]) {
    if (val?.length === 0 ) return undefined
    return val?.map((el) => el.value)
}

function renderOptions(option: string | IOptions, disabledOptions?: string[], disabledOptionsIds?: string[]) {
    if (typeof option === 'string') {
        return (
            <Option key={option} value={option} disabled={disabledOptions ? !disabledOptions.includes(option) : false}>
                {option}
            </Option>
        )
    } else if (disabledOptionsIds?.length) {
        return (
            <Option
                key={option.value}
                value={option.value}
                data-code={option.code}
                disabled={disabledOptionsIds && option.value ? disabledOptionsIds.includes(option.value) : false}>
                {option.label}
            </Option>
        )
    } else {
        return (
            <Option
                key={option.value}
                value={option.value}
                data-code={option.code}
                //todo check disabledOptions
                disabled={disabledOptions && option.code ? !disabledOptions.includes(option.code) : false}>
                {option.label}
            </Option>
        )
    }
}


export default SelectComponent
