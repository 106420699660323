import React, {useEffect} from 'react';
import {Select, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
    selectContentInfo,
    selectContentModuleSections,
    selectFilter
} from "store/content/content/selectors";
import {GetContentModuleSectionsStartAction, SetSortingFilter} from "store/content/content/actions";
import {TFilterData} from "models/content";

const ContentModuleSectionsSelect = () => {
    const put = useDispatch()
    const filter = useSelector(selectFilter)
    const contentSectionsOptions = useSelector(selectContentModuleSections)
    const {isLoading} = useSelector(selectContentInfo)

    useEffect(()=>{
        if(!contentSectionsOptions) put(GetContentModuleSectionsStartAction())
        // eslint-disable-next-line
    },[contentSectionsOptions])

     const onSelectStatus = (value: string[]) => {
        let newFilter: TFilterData = {};
        if (filter.filterData) newFilter = { ...filter.filterData }
        newFilter = { ...newFilter, contentModuleSectionIds: value }

        put(SetSortingFilter({ ...filter, filterData: newFilter }))
    }
    return (
        <div className="sorting__item">
            <Spin spinning={isLoading}>
                <Select
                    value={filter.filterData?.contentModuleSectionIds}
                    style={{ 'width': '200px' }}
                    placeholder="Все виды контента"
                    mode={'multiple'}
                    allowClear={true}
                    onChange={onSelectStatus}
                    maxTagCount={"responsive"}
                    showSearch
                    options={contentSectionsOptions ? contentSectionsOptions : []}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }
                />
            </Spin>
        </div>
    )
}

export default ContentModuleSectionsSelect;