import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetHandbookStartAction,
    UpdateContentDeliveryErrorAction,
    UpdateContentDeliveryStartAction,
    UpdateContentDeliverySuccessAction
} from "store/options/actions/actions-handbook";
import HandbookService from "services/handbookService";
import {TStatusesHandbookFeels} from "models/handbook";


export function* updateContentDelivery({id, data,}: ReturnType<typeof UpdateContentDeliveryStartAction>) {
    try {
        yield HandbookService.updateContentDeliveryRecord(data, id)
        yield put(UpdateContentDeliverySuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.contentDelivery))

        yield put(NotificationOpen('success', 'Тип формы сдачи обновлен'))

    } catch (error: any) {
        yield put(UpdateContentDeliveryErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить тип формы сдачи'))
    }
}
