import {AxiosResponse} from 'axios'
import {put, select} from 'redux-saga/effects'
import {
	GetNotificationsListErrorAction,
	GetNotificationsListStartAction,
	GetNotificationsListSuccessAction
} from "../../../store/options/actions/actions-notifications";
import NotificationService from "../../../services/notificationService";
import {TNotificationFilter, TNotificationResponse} from "../../../models/notifications";
import CreateErrorMessage from "../../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../../store/notification/actions";
import {selectNotificationFilter} from "../../../store/options/selectors/selectors-notifications";

export default function* getNotifications(_action: ReturnType<typeof GetNotificationsListStartAction>) {
	try {
		let filter: TNotificationFilter = yield select(selectNotificationFilter)
		const { data }: AxiosResponse<TNotificationResponse[]> = yield NotificationService.getNotificationsList(filter || {})
		yield put(GetNotificationsListSuccessAction(data))
	} catch (error: any) {
		yield put(GetNotificationsListErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить список уведомлений'))
	}
}
