import React, { FC, useEffect, useState } from 'react'
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik'

import { Button, Col, Row, Tooltip } from 'antd'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { InputNumberComponent, SelectComponent } from 'components/formic-control'
import { IOptions } from 'models/feels'
import AppService from 'services/appService'
import { AxiosResponse } from 'axios'
import { T_ID_NAME_CODE } from 'models/common'
import ReloadList from 'components/forms/components/reload-list/reload-list'
import CheckboxAccountedContent from './checkbox-accounted-content/checkbox-accounted-content'
import { TaskFormDto } from '../domain/dto/task-form.dto'

const getData = async (id: string) => {
	const { data }: AxiosResponse<T_ID_NAME_CODE[]> = await AppService.getFormatTypesById(id)
	return data.map((el) => {
		return { value: el.id, label: el.name } as IOptions
	})
}
type InternetResourcesForPublicItemProps = {
	name: string
	shortName: string
	project: IOptions | null
	disabled?: boolean
	isContent?: boolean
}
type IState = {
	value: IOptions[] | null
	id: null | string
}

const TaskFormContentItem: FC<InternetResourcesForPublicItemProps> = ({
	shortName,
	name,
	project,
	disabled,
	isContent = false,
}) => {
	const { setFieldValue } = useFormikContext<TaskFormDto>()
	const [state, setState] = useState<IState>({ id: null, value: null })
	useEffect(() => {
		const setDataToState = (id: string) => {
			getData(id).then((res) => setState({ id: id, value: res }))
		}
		if (project && !state) setDataToState(String(project.value || project.key))
		else if (project && (state.id !== project.value || project.key))
			setDataToState(String(project.value || project.key))
		// eslint-disable-next-line
	}, [project])

	const handlerUpdateContentFormatType = () => {
		const setDataToState = (id: string) => {
			getData(id).then((res) => setState({ id: id, value: res }))
		}

		if (project) setDataToState(String(project.value || project.key))
	}

	const handlerCheckbox = (name: string) => (value: boolean | null) => {
		setFieldValue(name, value)
	}
	return (
		<FieldArray
			name={name}
			render={(props: FieldArrayRenderProps) => {
				return (
					<>
						{props.form.values.stepSecond[shortName].value &&
							props.form.values.stepSecond[shortName].value.map((item: any, index: number) => {
								const checkboxValue = props.form.values.stepSecond[shortName].value[index].isAccountedContent
                                if (item.isContent !== isContent) return null

								return (
									<Row key={index} className="form-group__row" justify={'space-between'}>
										<Col lg={5} xs={22} className="form-group__row-item">
											<CheckboxAccountedContent
												value={checkboxValue}
												onChange={handlerCheckbox(`${name}[${index}].isAccountedContent`)}
											/>
										</Col>
										<Tooltip title={!state.value ? 'Сначала выберите проект' : undefined}>
											<Col lg={9} xs={22} className="form-group__row-item">
												<ReloadList handlerUpdateList={handlerUpdateContentFormatType} />
												<SelectComponent
													options={state.value}
													name={`${name}[${index}].contentFormatType`}
													placeholder={'Выберите единицу контента'}
													disabled={disabled}
												/>
											</Col>
										</Tooltip>

										<Col lg={8} xs={22}>
											<InputNumberComponent
												name={`${name}[${index}].contentCount`}
												placeholder={'Введите количество контента'}
												disabled={disabled}
											/>
										</Col>
										<Col lg={1} xs={2}>
											{!disabled && (
												<div className="form-group__btn">
													<Tooltip title="Удалить элемент?">
														<Button type="link" onClick={() => props.remove(index)}>
															<DeleteOutlined />
														</Button>
													</Tooltip>
												</div>
											)}
										</Col>
									</Row>
								)
							})}
						{!disabled && (
							<Button type="link" onClick={() => props.push({
                                contentFormatType: '',
                                contentCount: null,
                                isAccountedContent: true,
                                isContent
                            })}>
								<PlusCircleOutlined /> Добавить контент
							</Button>
						)}
					</>
				)
			}}
		/>
	)
}
export default TaskFormContentItem
