import { SearchContentProjectTypes } from "features/search-project-content";
import {actions, TCFState} from "./index";


export function ClearFilters(put: any, type: SearchContentProjectTypes.Stores.TTypeStore, key: keyof TCFState) {
    if (key === 'contests') put(actions.clearContests(type))
    if (key === 'status') put(actions.clearStatus(type))
    if (key === 'indicators') put(actions.clearIndicators(type))
    if (key === 'indicatorsContent') put(actions.clearIndicatorsContent(type))
    if (key === 'thematics') put(actions.clearThematics(type))
    if (key === 'regionals') put(actions.clearRegionals(type))
    if (key === 'content') put(actions.clearContent(type))
    if (key === 'dates') put(actions.clearDates(type))
    if (key === 'categoriesGenres') put(actions.clearCategoriesGenres(type))
}
export function ClearAllFilters(put: any, type: SearchContentProjectTypes.Stores.TTypeStore) {
    put(actions.clearContests(type))
    put(actions.clearStatus(type))
    put(actions.clearIndicators(type))
    put(actions.clearIndicatorsContent(type))
    put(actions.clearThematics(type))
    put(actions.clearRegionals(type))
    put(actions.clearContent(type))
    put(actions.clearDates(type))
    put(actions.clearCategoriesGenres(type))
}