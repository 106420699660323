import { FunctionalityInfoType } from '../types'

export const analyticsIndicators: FunctionalityInfoType = [
	{
		LevelGroupName: 'Фильтрация основных показателей',
		LevelGroupData: [
			{
				name: 'Фильтрация основных показателей аналитики по конкурсам, годам, сессиям, типам проектов',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/analytics-indicators/filters.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_VIEWER',
				],
			},
		],
	},
]
