import React, {MouseEvent} from 'react';
import {FilterOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectContentListFilter} from "store/content/list/selectors";
import { ExtendedSearchModel } from 'features/extended-search';
import WhetherTheUser from "components/whetherTheUser";
import { SearchContentProjectModel } from 'features/search-project-content';
import {GetContentListStartAction} from "store/content/list/actions";
import {TRole} from "models/user";
import { UserFiltersLib } from 'features/user-filters';

const roles: TRole[] = ['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_CLIENT', 'ROLE_AGENT']
const FilterInfo = () => {
    const resetFilterHandler = UserFiltersLib.useResetFilter('PROJECTS_CONTENT');
    const put = useDispatch();
    const filters = useSelector(selectContentListFilter);
    const filtersIsToggled = useSelector(ExtendedSearchModel.selectors.isToggleFilter('content'));
    const filtersIsToggledSecond = useSelector(SearchContentProjectModel.selectors.isToggleFilter('content'));

    function onModalOpenExtendedSearch(_event: MouseEvent<HTMLDivElement>) {
        put(ExtendedSearchModel.actions.setCFMain({isOpen: true, typeContent: 'content'}))
    }
    function onModalOpenSearch(_event: MouseEvent<HTMLDivElement>) {
        put(SearchContentProjectModel.actions.setCFMain({isOpen: true, typeContent: 'content'}))
    }
    function onClearFilterClick(_event: MouseEvent<HTMLDivElement>) {
        ExtendedSearchModel.actions.ClearAllFilters(put, 'content')
        SearchContentProjectModel.actions.ClearAllFilters(put, 'content')
        put(GetContentListStartAction())
        resetFilterHandler()
    }


    return (
        <>
            <WhetherTheUser role={roles} isCan={false}>
                <div className="filters" onClick={onModalOpenExtendedSearch}>
                    <FilterOutlined />
                </div>
            </WhetherTheUser>
            <WhetherTheUser role={roles} isCan={true}>
                <div className="filters" onClick={onModalOpenSearch}>
                    <FilterOutlined />
                </div>
            </WhetherTheUser>

            {!!filters.totalCount && (<div className="count-content">Найдено: {filters.totalCount}</div>)}
            {(filtersIsToggled || filtersIsToggledSecond) && (
                <div className="clear-filter" onClick={onClearFilterClick}>
                    <Button type={"default"}>Сбросить фильтр</Button>
                </div>
            )}
        </>
    )
}

export default FilterInfo;