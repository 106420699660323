export const TagsActionTypes = {
	GET_TAGS_FIRST_LEVEL_THEMATICS_START: '[TAGS] GET_TAGS_FIRST_LEVEL_THEMATICS_START',
	GET_TAGS_FIRST_LEVEL_THEMATICS_SUCCESS: '[TAGS] GET_TAGS_FIRST_LEVEL_THEMATICS_SUCCESS',
	GET_TAGS_FIRST_LEVEL_THEMATICS_ERROR: '[TAGS] GET_TAGS_FIRST_LEVEL_THEMATICS_ERROR',

	GET_TAGS_SECOND_LEVEL_WITH_FIRST_START: '[TAGS] GET_TAGS_SECOND_LEVEL_WITH_FIRST_START',
	GET_TAGS_SECOND_LEVEL_WITH_FIRST_SUCCESS: '[TAGS] GET_TAGS_SECOND_LEVEL_WITH_FIRST_SUCCESS',
	GET_TAGS_SECOND_LEVEL_WITH_FIRST_ERROR: '[TAGS] GET_TAGS_SECOND_LEVEL_WITH_FIRST_ERROR',

	GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_START: '[TAGS] GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_START',
	GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_SUCCESS: '[TAGS] GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_SUCCESS',
	GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_ERROR: '[TAGS] GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_ERROR',

	GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_START: '[TAGS] GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_START',
	GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_SUCCESS: '[TAGS] GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_SUCCESS',
	GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_ERROR: '[TAGS] GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_ERROR',

	GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_START: '[TAGS] GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_START',
	GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_SUCCESS: '[TAGS] GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_SUCCESS',
	GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_ERROR: '[TAGS] GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_ERROR',

	GET_TAGS_FIRST_LEVEL_CONTENT_START: '[TAGS] GET_TAGS_FIRST_LEVEL_CONTENT_START',
	GET_TAGS_FIRST_LEVEL_CONTENT_SUCCESS: '[TAGS] GET_TAGS_FIRST_LEVEL_CONTENT_SUCCESS',
	GET_TAGS_FIRST_LEVEL_CONTENT_ERROR: '[TAGS] GET_TAGS_FIRST_LEVEL_CONTENT_ERROR',

	GET_TAGS_SECOND_LEVEL_CONTENT_START: '[TAGS] GET_TAGS_SECOND_LEVEL_CONTENT_START',
	GET_TAGS_SECOND_LEVEL_CONTENT_SUCCESS: '[TAGS] GET_TAGS_SECOND_LEVEL_CONTENT_SUCCESS',
	GET_TAGS_SECOND_LEVEL_CONTENT_ERROR: '[TAGS] GET_TAGS_SECOND_LEVEL_CONTENT_ERROR',
} as const
