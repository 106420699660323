import React, {FC, useMemo} from 'react';
import {FieldArray, useFormikContext} from "formik";
import {Button, Col, Row, Spin, Tooltip} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";

import {TStatusesFeels} from "models/projects/projectFeels";
import useFeelsApp from "hooks/feelsApp";
import {useDispatch, useSelector} from "react-redux";
import {selectFormatSubmissionOptions} from "store/app/selectors";
import {IOptions} from "models/feels";
import ReloadList from "components/forms/components/reload-list/reload-list";
import {GetFeelsStartAction} from "store/app/actions";
import Checkbox from "antd/es/checkbox";
import {ProjectFormDto} from "features/create-or-edit-project-form//lib/dto/project-form.dto";
import {InputNumberComponent, SelectComponent} from 'components/formic-control';
import styles from './styles.module.less'
import CheckboxComponent from "./checkbox";

type ContentFormatFieldsProps = {
    name: string,
    disabled?: boolean
    isContent?: boolean
}
const ContentFormatFields: FC<ContentFormatFieldsProps> = ({name, disabled, isContent = false}) => {
    const put = useDispatch()
    const {errors, touched, values, setFieldValue} = useFormikContext<ProjectFormDto>()
    const status = values.stepFirst.status.value;
    const {formatTypes, isLoading} = useFeelsApp([TStatusesFeels.formatTypes])
    const formatSubmission = useSelector(selectFormatSubmissionOptions);
    const isError = errors.stepSecond?.contentFormats;

    const formatSubmissionDisabledOptions = (arr?: IOptions<string>[]) => {
        if ((!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') && arr?.length) {
            return arr.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }
    const formatTypesDisabledOptions = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return formatTypes?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, formatTypes]);

    function isTouched(id: number) {
        // @ts-ignore
        const type = touched[`stepSecond.contentFormats.value[${id - 1}].type`]
        // @ts-ignore
        const contentCount = touched[`stepSecond.contentFormats.value[${id - 1}].contentCount`]
        // @ts-ignore
        const submissionForms = touched[`stepSecond.contentFormats.value[${id - 1}].submissionForms`]
        return type || contentCount || submissionForms
    }
    const handlerUpdateFormatTypes = ()=> {
        put(GetFeelsStartAction(TStatusesFeels.formatTypes))
    }

    const selectCheckboxAll = (name:string, arr: any) => (e: any)=> {
        const checked: boolean = e.target.checked;
        const disabledOptions = formatSubmissionDisabledOptions(arr)
        const filteredOptions = arr.filter((option: any) => {
            return !disabledOptions?.some(value => value === option.value);
        })
        if(checked) setFieldValue(name,filteredOptions)
        else setFieldValue(name,[])
    }

    const handlerCheckbox = (name: string) => (value: boolean | null) => {
        setFieldValue(name,value)
    }

    return (
        <FieldArray
            name={name}
            render={({form, push, remove}) => {
                const count = form.values.stepSecond.contentFormats.value.length;
                const disableBtn = isError && count !== 0;
                return (
                    <div className={styles.contentFormats}>
                        {form.values.stepSecond.contentFormats.value.map((item: any, index: number) => {
                            const disabledOptions = formatSubmissionDisabledOptions(formatSubmission ? formatSubmission[item.type.value] : [])
                            const filteredOptions = formatSubmission ?
                                formatSubmission[item.type.value]?.filter((option: any) => {
                                return !disabledOptions?.some(value => value === option.value)
                            }) : []
                            const checkboxValue = form.values.stepSecond.contentFormats.value[index].isAccountedContent

                            if (item.isContent !== isContent) return null

                            return (
                                <Row key={index} gutter={[5, 5]} style={{width: '100%', 'marginBottom': '15px'}} wrap={true}>
                                    <Col className={'item'} lg={3} span={24}>
                                        <CheckboxComponent value={checkboxValue} onChange={handlerCheckbox(`${name}[${index}].isAccountedContent`)}/>
                                    </Col>
                                    <Col className={'item'} lg={6} span={24}>
                                        <Spin spinning={isLoading}>
                                            <ReloadList handlerUpdateList={handlerUpdateFormatTypes}/>
                                            <SelectComponent
                                                name={`${name}[${index}].type`}
                                                placeholder='Выберите единицы контента'
                                                options={formatTypes}
                                                disabledOptionsIds={formatTypesDisabledOptions}
                                                showError={false}
                                                disabled={disabled}
                                                maxTagCount={'responsive'}
                                            />
                                        </Spin>
                                    </Col>
                                    <Col className={'item'} lg={5} span={24}>
                                        <InputNumberComponent
                                            name={`${name}[${index}].contentCount`}
                                            placeholder='Введите количество контента'
                                            showError={false}
                                            disabled={disabled}
                                        />
                                    </Col>
                                    <Col className={'item'} lg={7} span={21}>
                                        <Spin spinning={isLoading}>
                                            <ReloadList className="ident" handlerUpdateList={handlerUpdateFormatTypes}/>
                                            <SelectComponent
                                                multi={true}
                                                name={`${name}[${index}].submissionForms`}
                                                placeholder='Выберите формат сдачи контента'
                                                options={formatSubmission ? formatSubmission[item.type.value] : null}
                                                disabledOptionsIds={
                                                    formatSubmission ?
                                                        formatSubmissionDisabledOptions(formatSubmission[item.type.value]) :
                                                        []
                                                }
                                                showError={false}
                                                disabled={disabled}
                                                maxTagCount={'responsive'}
                                                dropdownRender={
                                                    <Checkbox
                                                        className={styles.checkboxInSelect}
                                                        onChange={selectCheckboxAll(`${name}[${index}].submissionForms`,formatSubmission? formatSubmission[item.type.value] : [] )}
                                                        checked={formatSubmission && item.submissionForms?.length ? item.submissionForms?.length === filteredOptions?.length : false}
                                                        disabled={disabled || !(item.type?.value)}
                                                    >
                                                        Выбрать все
                                                    </Checkbox>
                                                }
                                            />
                                        </Spin>
                                    </Col>
                                    <Col span={3}>
                                        {!disabled && (
                                            <div>
                                                <Tooltip title="Удалить формат контента?">
                                                    <Button
                                                        className={styles.delete}
                                                        type="link"
                                                        onClick={() => remove(index)}
                                                    >
                                                        <DeleteOutlined/>
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            )
                        })}
                        {!disabled && (
                            <>
                                {disableBtn && isTouched(count) && isContent ? (
                                    <div className="invalid-feel">{errors.stepSecond?.contentFormats as string}</div>
                                ) : (
                                    <div className={styles.btnAdd}>
                                        <Button
                                            type="link"
                                            onClick={() => push({
                                                type: '',
                                                contentCount: '',
                                                submissionForms: '',
                                                isAccountedContent: true,
                                                isContent
                                            })}>
                                            <PlusCircleOutlined/> Добавить ед.контента
                                        </Button>
                                    </div>

                                )}
                            </>
                        )}

                    </div>
                )
            }}
        />
    )
}
export default ContentFormatFields;
