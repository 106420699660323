import { TPaginationDefault, TSort } from 'models/common'
import { z } from 'zod'

type FilterData = {
	reportTemplateIds?: string[]
	userIds?: string[]
	searchString?: string
}

export type Filter = {
	sort?: TSort
	filterData?: FilterData
} & TPaginationDefault

export const ReportsSchedulerUserSchema = z.object({
	id: z.string(),
	lastName: z.string(),
	firstName: z.string(),
	middleName: z.string(),
	role: z.object({
		id: z.string(),
		name: z.string(),
		ruName: z.string(),
	}),
})
export type ReportsSchedulerUser = z.infer<typeof ReportsSchedulerUserSchema>

export const ReportsSchedulersInputDataSchema = z.object({
	projectIds: z.array(z.string()).optional(),
	sessionIds: z.array(z.string()).optional()
}).nullable().optional()

export type ReportsSchedulersInputData = z.infer<typeof ReportsSchedulersInputDataSchema>

export const ReportsSchedulersSchema = z.object({
	id: z.string(),
	firstFormationDate: z.string(),
	lastFormationDate: z.string(),
	formationTime: z.string(),
	reportTemplate: z.object({
		id: z.string(),
		code: z.string(),
		name: z.string(),
	}),
	formationFormat: z.string(),
	inputData: ReportsSchedulersInputDataSchema,
	users: z.array(ReportsSchedulerUserSchema),
})

export type ReportsSchedulers = z.infer<typeof ReportsSchedulersSchema>

export type ResponseList = {
	content: ReportsSchedulers[]
} & Filter

const ScheduleCreateSchema = z.object({
	reportTemplateIds: z.array(z.string()).nullable().optional(),
	format: z.enum(['PDF', 'DOCX', 'XLSX']).nullable(),
	inputData: z.object({
		projectIds: z.array(z.string()).optional(),
		sessionIds: z.array(z.string()).optional()
	}),
	firstFormationDate: z.string().nullable(),
	lastFormationDate: z.string().nullable(),
	formationTime: z.string().nullable(),
	userIds: z.array(z.string()).nullable(),
})

export type ScheduleCreate = z.infer<typeof ScheduleCreateSchema>
