import {AxiosResponse} from "axios";
import {put} from "redux-saga/effects";
import RegionService from "services/regionService";
import {GetRegionsErrorAction, GetRegionsStartAction, GetRegionsSuccessAction} from "store/region/actions";
import {NotificationOpen} from "store/notification/actions";
import {T_ID_NAME_CODE_HIDDEN} from "models/common";
import CreateErrorMessage from "utils/validation/createErrorMessage";

export default function* getRegions( _: ReturnType<typeof GetRegionsStartAction>) {
    try {
        const { data }: AxiosResponse<T_ID_NAME_CODE_HIDDEN[]> = yield RegionService.getRegions();
        yield put(GetRegionsSuccessAction(data))
    } catch (error: any) {
        yield put(GetRegionsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить список регионов'))
    }
}