import {TSubmissionFormsOptions} from "models/common";
import {createSelector} from 'reselect';
import {TApplicationState} from "../aplication-state";
import transformObjectFeels from "utils/transformObjectFeels";
import {TPerson} from "models/user";
import {IOptions} from "models/feels";

const appState = (state: TApplicationState) => state.app;

export const selectAppStatus = createSelector(
    appState,
    (state) => ({
        isLoading: state.isLoading,
        error: state.error
    })
)

export const selectRankOptions = createSelector(
    appState,
    (state) => state.ranks ?
        state.ranks.map((el) => ({
            label: el.name,
            value: el.id,
            hidden: el.hidden,
        } as IOptions)) :
        null
)
export const selectRegionsOptions = createSelector(
    appState,
    (state) => state.regions ?
        state.regions.map((el) => ({
            label: el.name,
            value: el.id,
            hidden: el.hidden,
        } as IOptions)) :
        null
)

export const selectGenresOptions = createSelector(
    appState,
    (state) => state.genres ?
        state.genres.map((el) => ({
            label: el.name,
            value: el.id,
            hidden: el.hidden,
        } as IOptions)) :
        null
)
export const selectCategoriesOptions = createSelector(
    appState,
    (state) => state.categories ?
        state.categories.map((el) => ({
            label: el.name,
            value: el.id,
            hidden: el.hidden,
        } as IOptions)) :
        null
)

export const selectInternetResourcesOptions = createSelector(
    appState,
    (state) => state.internetResources ?
        state.internetResources.map((el) => ({
            label: el.name,
            value: el.id,
            hidden: el.hidden,
            code: el.domainLink
        } as IOptions)) :
        null
)
export const selectInternetResourcesPostingTaskOptions = createSelector(
    appState,
    (state) => state.internetResourcesPostingTask ?
        transformObjectFeels(state.internetResourcesPostingTask.filter((el) => !el.hidden)) :
        null
)

export const selectDirectionsOptions = createSelector(
    appState,
    (state) => {
        if (state.directions) {
            return state.directions.map((el) => {
                return {label: el.name, value: el.id, hidden: el.hidden, code: el.code} as IOptions
            })
        }
        else return null;
    }
)
export const selectFormatTypesOptions = createSelector(
    appState,
    (state) => {
        if (state.formatTypes) {
            return state.formatTypes.map((el) => ({
                label: el.name,
                value: el.id,
                code: el.code,
                hidden: el.hidden
            } as IOptions<string>))
        }
        else return null;
    }
)
export const selectFormatKpiOptions = createSelector(
    appState,
    (state) => {
        if (state.formatKpi) {
            return transformObjectFeels(state.formatKpi.filter((el) => !el.hidden))
        }
        else return null;
    }
)
export const selectThematicsOptions = createSelector(
    appState,
    (state) => {
        if (state.thematics) {
            return state.thematics
                .map((el) => ({
                    value: el.id,
                    label: el.name,
                    code: el.contest?.code,
                    hidden: el.hidden
                } as IOptions))
        }
        else return null;
    }
)
export const selectStatusesOptions = createSelector(
    appState,
    (state) => {
        if (state.statuses) {
            return state.statuses.map((el) => ({value: el.id, label: el.name, code: el.code }))
        }
        else return null;
    }
)

export const selectFormatSubmissionOptions = createSelector(
    appState,
    (state) => {
        if (!state.formatTypes) return null
        const submissionForms: TSubmissionFormsOptions = {};
        state.formatTypes.forEach((el) => {
            submissionForms[el.id] = el.submissionForms.map((item) => ({
                label: item.name,
                value: item.id,
                hidden: item.hidden
            } as IOptions))
        })
        return submissionForms
    }
)
export const selectReportTypeOptions = createSelector(
    appState,
    (state) => {
        if (state.reportType) {
            return state.reportType.map((el) => ({label: el.name, value: el.id, code: el.code} as IOptions))
        }
        else return null;
    }
)
export const selectReportTypeContentReachReport = createSelector(
    appState,
    (state) => state.reportType?.find((el) => el.code === 'CONTENT_REACH_REPORT')
)

export const selectReportTypes = createSelector(
    appState,
    (state) => {
        return {
            CONTENT_REACH_REPORT: state.reportType?.find((el) => el.code === 'CONTENT_REACH_REPORT'),
            PROJECT_ESTIMATE:  state.reportType?.find((el) => el.code === 'PROJECT_ESTIMATE'),
            CONTENT_POSTING_REPORT:  state.reportType?.find((el) => el.code === 'CONTENT_POSTING_REPORT')
        }
    })

export const selectReportTypeProjectEstimateReport = createSelector(
    appState,
    (state) => state.reportType?.find((el) => el.code === 'PROJECT_ESTIMATE')
)

export const selectContractorsOptions = createSelector(
    appState,
    (state) => state.contractors ? MapPerson(state.contractors) : null
)
export const selectContractorsId = createSelector(
    appState,
    (state) => state.contractorsId ? state.contractorsId : null
)

export const selectCoordinatorsOptions = createSelector(
    appState,
    (state) => state.coordinators ? MapPerson(state.coordinators) : null
)
export const selectAnalystsOptions = createSelector(
    appState,
    (state) => state.analysts ? MapPerson(state.analysts) : null
)
export const selectProducersOptions = createSelector(
    appState,
    (state) => state.producers ? MapPerson(state.producers) : null
)
export const selectFinanciersOptions = createSelector(
    appState,
    (state) => state.financiers ? MapPerson(state.financiers) : null
)
export const selectLawyersOptions = createSelector(
    appState,
    (state) => state.lawyers ? MapPerson(state.lawyers) : null
)
export const selectContentEditorsOptions = createSelector(
    appState,
    (state) => state.contentEditors ? MapPerson(state.contentEditors) : null
)
export const selectProjectManagersOptions = createSelector(
    appState,
    (state) => state.projectManagers ? MapPerson(state.projectManagers) : null
)
export const selectFormatSubmissionByIdOptions = createSelector(
    appState,
    (state) => state.formatSubmission ? state.formatSubmission.map((el) => ({
        label: el.name,
        value: el.id
    } as IOptions)) : null
)

export const selectProducersLeaderOptions = createSelector(
    appState,
    (state) => {
        return state.producers ? MapPerson(state.producers.filter((person) => person.leader)) : []
    }
)

export const selectCoordinatorsLeaderOptions = createSelector(
    appState,
    (state) => {
        return state.coordinators ? MapPerson(state.coordinators.filter((person) => person.leader)) : []
    }
)

export const selectAnalystsLeaderOptions = createSelector(
    appState,
    (state) => {
        return state.analysts ? MapPerson(state.analysts.filter((person) => person.leader)) : []
    }
)
export const selectFinanciersLeaderOptions = createSelector(
    appState,
    (state) => {
        return state.financiers ? MapPerson(state.financiers.filter((person) => person.leader)) : []
    }
)

export const selectLeadersOptions = createSelector(
    selectAnalystsLeaderOptions,
    selectCoordinatorsLeaderOptions,
    selectProducersLeaderOptions,
    selectFinanciersLeaderOptions,
    (analystsLeader,coordinatorsLeader,producersLeader,financiersLeader)=>({analystsLeader,coordinatorsLeader,producersLeader,financiersLeader})
)

export const selectRolesOptions = createSelector(
    appState,
    (state) => {
        if (state.roles) {
            return state.roles.map((el) => ({label: el.ruName, value: el.id, code: el.name} as IOptions))
        }
        else return null;
    }
)

export const selectOrganizationsOptions = createSelector(
    appState,
    (state) => {
        if (state.organizations) {
            return state.organizations.map((el) => (
                {label: `${el.name}  - ${el.isContentCustomer ? "Заказчик контента" : "Поставщик контента"}`,
                    value: el.id, code: String(el.isContentCustomer)} as IOptions
            ))
        }
         else return null;
    }
)
export const selectOrganizationsWithContractorsOptions = createSelector(
    appState,
    (state) => {
        if (state.organizationsWithContractors) {
            return transformObjectFeels(state.organizationsWithContractors)
        } else return null;
    }
)

export const selectPath = createSelector(
    appState,
    (state) => state.path
)

export const selectTourPage = createSelector(
    appState,
    (state) => state.tourPage
)

export const selectProjectCoordinatorsOptions = createSelector(
    appState,
    (state) => state.coordinators ? MapPersonProjectForm(state.coordinators) : null
)
export const selectProjectAnalystsOptions = createSelector(
    appState,
    (state) => state.analysts ? MapPersonProjectForm(state.analysts) : null
)
export const selectProjectProducersOptions = createSelector(
    appState,
    (state) => state.producers ? MapPersonProjectForm(state.producers) : null
)
export const selectProjectFinanciersOptions = createSelector(
    appState,
    (state) => state.financiers ? MapPersonProjectForm(state.financiers) : null
)
export const selectProjectLawyersOptions = createSelector(
    appState,
    (state) => state.lawyers ? MapPersonProjectForm(state.lawyers) : null
)
export const selectProjectContentEditorsOptions = createSelector(
    appState,
    (state) => state.contentEditors ? MapPersonProjectForm(state.contentEditors) : null
)
export const selectProjectProjectManagersOptions = createSelector(
    appState,
    (state) => state.projectManagers ? MapPersonProjectForm(state.projectManagers) : null
)
export const selectProjectContractorsOptions = createSelector(
    appState,
    (state) => state.contractors ? MapPersonProjectForm(state.contractors) : null
)

//utils
function MapPerson(arr: TPerson[]) {
    return arr.map((el) => {
        const user = {
            lastName: el.lastName ?? '',
            firstName: el.firstName ?? '',
            middleName: el.middleName ?? '',
        }
        return { value: el.id, label: `${user.lastName} ${user.firstName} ${user.middleName}` } as IOptions
    })
}

function MapPersonProjectForm(arr: TPerson[]) {
    return arr.map((el) => {
        const user = {
            lastName: el.lastName ?? '',
            firstName: el.firstName ?? '',
            middleName: el.middleName ?? '',
        }
        return { value: el.id, label: `${user.lastName} ${user.firstName} ${user.middleName} `, code: el.status } as IOptions
    })
}
