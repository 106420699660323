import React from 'react';
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectArchiveFilter} from "../../../../../store/archive/selectors";
import {TArchiveFilter} from "../../../../../models/archive";
import {SetArchiveFilter} from "../../../../../store/archive/actions";

const options = [{label: 'Новый', value: 'NEW'},{label: 'Созданный', value: 'CREATED'},{label: 'Некорректный', value: 'ERROR'}]

const StatusesSelect = () => {
    const put = useDispatch()
    const filter = useSelector(selectArchiveFilter)

    const handlerChange = (value: any) => {
      const  newFilter: TArchiveFilter = { ...filter, filterData: {...filter.filterData , statuses: value}}
        put(SetArchiveFilter(newFilter))
    }
    return (
        <div className="form-group form-group--statuses">
                <Select
                    allowClear={true}
                    value={filter.filterData?.statuses}
                    mode={'multiple'}
                    onChange={handlerChange}
                    placeholder="Статус формирования архива"
                    options={options}
                    showSearch={false}
                />
        </div>
    )
}

export default StatusesSelect;