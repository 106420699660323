import {StepFirstDto} from "./project-form_StepFirst.dto";
import {StepSecondDto} from "./project-form_StepSecond.dto";
import {StepThirdDto} from "./project-form_StepThird.dto";
import {StepFourthDto} from "./project-form_StepFourth.dto";
import {StepFifthDto} from "./project-form_StepFifth.dto";
import { ProjectTypes } from "entities/project";

export type TDefault<T> = { value: T, isDisabled?: boolean }

export class ProjectFormDto {
    stepFirst: StepFirstDto
    stepSecond: StepSecondDto
    stepThird: StepThirdDto
    stepFourth: StepFourthDto
    stepFifth: StepFifthDto

    projectFilesExist: boolean

    constructor(project?: ProjectTypes.Project | null) {
        this.stepFirst = new StepFirstDto(project);
        this.stepSecond = new StepSecondDto(project);
        this.stepThird = new StepThirdDto(project);
        this.stepFourth = new StepFourthDto(project);
        this.stepFifth = new StepFifthDto(project);
        this.projectFilesExist = project?.projectFilesExist || false;
    }
}
