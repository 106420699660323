import React, {FC} from 'react';
import './status-project-form.less'
import {
    AppstoreAddOutlined,
    CalendarOutlined,
    CaretLeftOutlined, ExclamationCircleOutlined,
    GlobalOutlined,
    UserOutlined
} from "@ant-design/icons";
import {Tooltip} from "antd";

type StatusProjectProps = {
    status: number | string,
    handler?: any
}
function activeClass(status: number | string, num: number) {
    if (+status === num) return 'active'
}
const StatusProjectForm: FC<StatusProjectProps> = ({status, handler}) => {
    return (
        <div className='statusProjectForm'>
            <Tooltip title={'Общие параметры'}>
                <div
                    onClick={handler(1)}
                    className={`statusProjectForm__item statusProjectForm__1 ${activeClass(status, 1)}`}>
                    <AppstoreAddOutlined />
                </div>
            </Tooltip>
            <Tooltip title={'Контент параметры'}>
                <div
                    onClick={handler(2)}
                    className={`statusProjectForm__item statusProjectForm__2 ${activeClass(status, 2)}`}>
                    <CalendarOutlined />
                </div>
            </Tooltip>
            <Tooltip title={'Параметры размещения'}>
                <div
                    onClick={handler(3)}
                    className={`statusProjectForm__item statusProjectForm__3 ${activeClass(status, 3)}`}>
                    <GlobalOutlined />
                </div>
            </Tooltip>
            <Tooltip title={'Параметры ролей'}>
                <div
                    onClick={handler(4)}
                    className={`statusProjectForm__item statusProjectForm__4 ${activeClass(status, 4)}`}>
                    <UserOutlined />
                </div>
            </Tooltip>
            <Tooltip title={'Другие параметры'}>
                <div
                    onClick={handler(5)}
                    className={`statusProjectForm__item statusProjectForm__5 ${activeClass(status, 5)}`}>
                    <ExclamationCircleOutlined />
                </div>
            </Tooltip>
            <div
                className='statusProjectForm__item statusProjectForm__back active' onClick={handler()}>
                <CaretLeftOutlined />
            </div>
        </div>
    )
};
export default StatusProjectForm;