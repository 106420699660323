import {InferValueTypes} from 'models/common'
import * as actions from '../actions/actions-users'
import {TOrganizationsUser, TPerson, TUsersResponse} from 'models/user'
import {UsersActionTypes} from '../action-types'
import {ErrorActionState, StartActionState, SuccessActionState} from 'utils/reducerHalper'
import {TResponseUser} from 'models/users-list/user-form'
import {AuthActionTypes} from "../../auth/action-types";
import {Logout} from "../../auth/reducer";
import {UserTypes} from 'entities/user'

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TUsersState = {
	filter: UserTypes.OptionsUserFilter
	usersData: TUsersResponse | null
	user: TResponseUser | null
	relatedUsers: TPerson[] | null
	inspectors: UserTypes.TInspector[] | null
	organizationsUser: TOrganizationsUser[] | null
	usersActiveSynced: UserTypes.User[] | null
	isLoading: boolean
	isLoadingUser: boolean
	isSuccess: boolean
	access: boolean
	error: any
}

const initialFilter: UserTypes.OptionsUserFilter = {
	page: 1,
	pageSize: 50,
}

export const initialState: TUsersState = {
	filter: initialFilter,
	usersData: null,
	relatedUsers: null,
	inspectors: null,
	organizationsUser: null,
	usersActiveSynced: null,
	isLoading: false,
	isLoadingUser: false,
	isSuccess: false,
	access: false,
	user: null,
	error: null,
}

export default function usersReducer(state: TUsersState = initialState, action: ActionTypes | Logout): TUsersState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return {...initialState}
		case UsersActionTypes.CLEAR_BULK_ACTIONS:
			return {...state, filter: {...state.filter, filterData: {...state.filter.filterData, bulkAction: undefined}}}

		case UsersActionTypes.CLEAR_USERS_ERROR:
			return {...state, error: null}
		case UsersActionTypes.CLEAR_USERS_IS_SUCCESS:
			return {...state, isSuccess: false}
		case UsersActionTypes.SET_USERS_FILTER:
			return { ...state, filter: action.filter}
		/** START ACTIONS  */
		case UsersActionTypes.UPDATE_PERSONAL_DATA_START:
			return {...StartActionState(state), isSuccess: false}
		case UsersActionTypes.SEND_USER_DETAILS_START:
			return StartActionState(state)
		case UsersActionTypes.GET_USERS_LIST_START:
			return {...StartActionState(state),user: null, isSuccess: false}
		case UsersActionTypes.DELETE_USER_START:
			return {...StartActionState(state),user: null,}
		case UsersActionTypes.CHANGE_PASSWORD_START:
			return StartActionState(state)
		case UsersActionTypes.GET_USER_START:
			return {...StartActionState(state),user: null,}

		case UsersActionTypes.CREATE_USER_START:
			return {
				...StartActionState(state),
				error: null,
				isLoadingUser: true,
				isSuccess: false,
			}
		case UsersActionTypes.UPDATE_USER_START:
			return {
				...StartActionState(state),
				error: null,
				isLoadingUser: true,
				isSuccess: false,
			}
		case UsersActionTypes.UNLOADING_USERS_START:
			return StartActionState(state)
		case UsersActionTypes.IMPORT_TEMPLATE_USERS_START:
			return StartActionState(state)
		case UsersActionTypes.IMPORT_USERS_START:
			return StartActionState(state)
		case UsersActionTypes.GET_ORGANIZATIONS_USERS_START:
			return StartActionState(state)
		case UsersActionTypes.GET_RELATED_USERS_START:
			return StartActionState(state)
		case UsersActionTypes.GET_INSPECTORS_START:
			return StartActionState(state)
		case UsersActionTypes.GET_LIST_ACTIVE_SYNCED_USERS_START:
			return StartActionState(state)
		/** SUCCESS ACTIONS  */
		case UsersActionTypes.UPDATE_PERSONAL_DATA_SUCCESS:
			return {...SuccessActionState(state), isLoadingUser: false, isSuccess: true}
		case UsersActionTypes.SEND_USER_DETAILS_SUCCESS:
			return SuccessActionState(state)
		case UsersActionTypes.DELETE_USER_SUCCESS:
			return SuccessActionState(state)
		case UsersActionTypes.CHANGE_PASSWORD_SUCCESS:
			return SuccessActionState(state)
		case UsersActionTypes.GET_USER_SUCCESS:
			return {...SuccessActionState(state),user: action.user,}

		case UsersActionTypes.CREATE_USER_SUCCESS:
			return {...SuccessActionState(state), isLoadingUser: false, isSuccess: true, user: null}
		case UsersActionTypes.UPDATE_USER_SUCCESS:
			return {...SuccessActionState(state), isLoadingUser: false, isSuccess: true, user: null}
		case UsersActionTypes.GET_USERS_LIST_SUCCESS:
			return {...SuccessActionState(state), usersData: action.payload}
		case UsersActionTypes.GET_RELATED_USERS_SUCCESS:
			return { ...SuccessActionState(state), relatedUsers: action.users}
		case UsersActionTypes.GET_INSPECTORS_SUCCESS:
			return { ...SuccessActionState(state), inspectors: action.inspectors}
		case UsersActionTypes.UNLOADING_USERS_SUCCESS:
			return {...SuccessActionState(state), filter: initialFilter}
		case UsersActionTypes.IMPORT_TEMPLATE_USERS_SUCCESS:
			return SuccessActionState(state)
		case UsersActionTypes.IMPORT_USERS_SUCCESS:
			return SuccessActionState(state)
		case UsersActionTypes.GET_ORGANIZATIONS_USERS_SUCCESS:
			return {...SuccessActionState(state), organizationsUser: action.data}
		case UsersActionTypes.GET_LIST_ACTIVE_SYNCED_USERS_SUCCESS:
			return {...SuccessActionState(state), usersActiveSynced: action.users}
		/** ERROR ACTIONS  */
		case UsersActionTypes.UPDATE_PERSONAL_DATA_ERROR:
			return ErrorActionState(state, action)
		case UsersActionTypes.SEND_USER_DETAILS_ERROR:
			return ErrorActionState(state, action)
		case UsersActionTypes.GET_USERS_LIST_ERROR:
			return ErrorActionState(state, action)
		case UsersActionTypes.CREATE_USER_ERROR:
			return {
				...ErrorActionState(state,action),
				isLoadingUser: false,
				isSuccess: false,
				error: action.error
			}
		case UsersActionTypes.UPDATE_USER_ERROR:
			return {
				...ErrorActionState(state,action),
				isLoadingUser: false,
				isSuccess: false,
				error: action.error
			}
		case UsersActionTypes.DELETE_USER_ERROR:
			return ErrorActionState(state, action)
		case UsersActionTypes.CHANGE_PASSWORD_ERROR:
			return ErrorActionState(state, action)
		case UsersActionTypes.GET_RELATED_USERS_ERROR:
			return ErrorActionState(state, action)
		case UsersActionTypes.UNLOADING_USERS_ERROR:
			return ErrorActionState(state, action)
		case UsersActionTypes.IMPORT_TEMPLATE_USERS_ERROR:
			return ErrorActionState(state, action)
		case UsersActionTypes.IMPORT_USERS_ERROR:
			return ErrorActionState(state, action)
		case UsersActionTypes.GET_ORGANIZATIONS_USERS_ERROR:
			return ErrorActionState(state, action)
		case UsersActionTypes.GET_INSPECTORS_ERROR:
			return ErrorActionState(state, action)
		case UsersActionTypes.GET_LIST_ACTIVE_SYNCED_USERS_ERROR:
			return ErrorActionState(state, action)
		default:
			return state
	}
}
