const task = [
    {
        id: '29',
        selector: '.task-info',
        content: 'Карточка задачи содержит базовую информацию о задаче: ее название, ' +
            'статус задачи, срок исполнения, индикатор оплаты, информацию о команде, название проекта, тип и форму задачи',
        role: []
    },
    {
        id: '30',
        selector: '.task-description',
        content: ' Описание задачи содержит требования к ее выполнению, а также может содержать формат и количество контента, которые нужно предоставить в задаче',
        role: []
    },
    {
        id: '32',
        selector: '.tasksPage__table',
        content: 'В зависимости от формы задачи, таблица записей позволяет добавлять данные, загружать файлы, просматривать и скачивать их, редактировать, удалять и отправлять на проверку, а также голосовать за добавленные записи подрядчика.  Кнопка внизу таблицы позволяет подрядчику добавить данные: о контенте, о публикации, о фиксации охватов, об авторских правах и смете проекта',
        role: ['ROLE_ADMIN','ROLE_PRODUCER','ROLE_COORDINATOR','ROLE_ANALYST','ROLE_FINANCIER','ROLE_LAWYER','ROLE_MODERATOR','ROLE_CLIENT', 'ROLE_AGENT', 'ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS']

    },
    {
        id: '32',
        selector: '.tasksPage__table',
        content: 'В зависимости от формы задачи, таблица записей позволяет добавлять данные, загружать файлы, просматривать и скачивать их, редактировать, удалять и отправлять на проверку. Кнопка внизу таблицы позволяет добавить данные: о контенте, о публикации, о фиксации охватов, об авторских правах и смете проекта',
        role: ['ROLE_CONTRACTOR']

    },
    {
        id: '35',
        selector: '.submissionForm-status',
        content: 'Эти показатели позволяют понять, какое количество материала (записей таблицы) ' +
            'требует проверки, отклонено или принято проверяющими',
        role: []
    },
    {
        id: '36',
        selector: '.info span',
        content: 'В зависимости от вашей роли, переключатель статуса задачи позволяет перевести задачу в работу, обратно к выполнению или завершить ее',
        role: ['ROLE_ADMIN','ROLE_PRODUCER','ROLE_COORDINATOR','ROLE_ANALYST','ROLE_FINANCIER','ROLE_LAWYER','ROLE_MODERATOR','ROLE_CLIENT', 'ROLE_AGENT','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS']
    },
    {
        id: '36',
        selector: '.info span',
        content: 'Чтобы приступить к задаче переведите ее статус из "К выполнению" в "В процессе"',
        role: ['ROLE_CONTRACTOR']
    },
];
export default task;
