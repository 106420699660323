import { put, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import { NotificationOpen } from '../../store/notification/actions'
import {
	GetAnalyticsKpiTopProjectErrorAction,
	GetAnalyticsKpiTopProjectStartAction,
	GetAnalyticsKpiTopProjectSuccessAction,
} from '../../store/analytics/actions'
import AnalyticsService from '../../services/analyticsService'
import { TAnalyticsFilter, TAnalyticsKpiTopProjectResponse } from '../../models/analytics/analytics-project'
import { selectAnalyticsFilter } from '../../store/analytics/selectors'

export function* getAnalyticsKpiTopProject(_action: ReturnType<typeof GetAnalyticsKpiTopProjectStartAction>) {
	try {
		const filter: TAnalyticsFilter = yield select(selectAnalyticsFilter)
		const { data: kpiProject }: AxiosResponse<TAnalyticsKpiTopProjectResponse> = yield AnalyticsService.getAnalyticsKpiTopProject(filter)
		yield put(GetAnalyticsKpiTopProjectSuccessAction(kpiProject))
	} catch (error: any) {
		yield put(GetAnalyticsKpiTopProjectErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить данные'))
	}
}
