const board = [
    {
        id: '23',
        selector: '.taskBoard-formRow',
        content: 'В канбан доске представлены текущие задачи проекта. Для смены статуса ' +
            'задачи перетяните ее в необходимый столбец канбан доски. Вы можете менять статусы ' +
            'задач по мере работы и их выполнения. В столбце статуса “к выполнению” - новые задачи, ' +
            'в столбце статуса “в процессе” - задачи в работе, в столбце статуса “готово”- завершенные задачи',
        role: [],
        page: ['project/tasks/form']
    },
    {
        id: '24',
        selector: '.taskBoard-btn',
        content: 'Для добавления задач используйте кнопку создания',
        role: ['ROLE_ADMIN', 'ROLE_PRODUCER','ROLE_ANALYST', 'ROLE_FINANCIER','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS'],
        page: ['project/tasks/form']
    },
    {
        id: '25',
        selector: '.taskBoard-nav__copy',
        content: 'Функция дублирования позволяет создать копию любой созданной задачи с новым сроком исполнения',
        role: ['ROLE_ADMIN', 'ROLE_PRODUCER','ROLE_ANALYST', 'ROLE_FINANCIER','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS'],
        page: ['project/tasks/form']
    },
    {
        id: '26',
        selector: '.taskBoard-nav__sorting__icon',
        content: 'Сортировка позволяет изменить порядок вывода задач на странице',
        role: [],
        page: ['project/tasks/form']
    },
    {
        id: '27',
        selector: '.taskBoard-nav-search',
        content: 'Поиск позволяет найти необходимые данные',
        role: [],
        page: ['project/tasks/form']
    },

];
export default board;