import {put} from 'redux-saga/effects'
import {
    SendConfirmEmailErrorAction,
    SendConfirmEmailStartAction,
    SendConfirmEmailSuccessAction,
} from '../../../store/options/actions/actions-personal-setting'
import CreateErrorMessage from '../../../utils/validation/createErrorMessage'
import {NotificationOpen} from '../../../store/notification/actions'
import PersonalSettingService from '../../../services/personalSettingService'

export default function* sendConfirmEmail({ id, notificationChannel, account }: ReturnType<typeof SendConfirmEmailStartAction>) {
	try {
		yield PersonalSettingService.sendConfirmEmail(id, notificationChannel,account)
		yield put(SendConfirmEmailSuccessAction())
		yield put(NotificationOpen('success', 'OK', 'Письмо с кодом подтверждения отправлено на почту'))
	} catch (error: any) {
		yield put(SendConfirmEmailErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить код'))
	}
}
