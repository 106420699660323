import {AxiosResponse} from "axios";
import $api from "../http";
import {THandbookContentResponse, THandbookFilter, TRanks, TTagsResponse} from "../models/handbook";
import {T_ID_NAME_CODE_HIDDEN} from "../models/common";

export default class RanksService {
    /**
     * Получение всех званий
     */
    static ranksKey = '/rank'
    static async getRanks(): Promise<AxiosResponse<T_ID_NAME_CODE_HIDDEN[]>> {
        return $api.get('/rank')
    }
    /**
     * Получение всех званий c filters
     */
    static async getRanksWithFilter(data: THandbookFilter): Promise<AxiosResponse<THandbookContentResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<TTagsResponse>('/rank/search', filter)
    }
    /**
     *  Добавление званий
     */
    static async addIRanksRecord( data: TRanks): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/rank`, data)
    }
    /**
     *   Обновление званий
     */
    static async updateRanksRecord( data: TRanks, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/rank/${id}`, data)
    }
    /**
     *   Удаление званий
     */
    static async deleteRanksRecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/rank/${id}`)
    }
    /**
     *   Массовые действия званий
     */
    static async bulkRanksActions(recordsArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/rank/bulk-actions/${action}`, recordsArr)
    }
}



