import { SearchContentProjectTypes } from "features/search-project-content";
import {ActionTypes} from "./types";


export const setContests = <T extends Partial<SearchContentProjectTypes.Stores.TContestsState["content"]>>(
    typeContent: SearchContentProjectTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUE, typeContent, value, isToggled
})

export const setValuesContests = <T extends Partial<Omit<SearchContentProjectTypes.Stores.Contests, 'isToggled'>>>(
    typeContent: SearchContentProjectTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUES, typeContent, value, isToggled
})
export const clearContests = (typeContent: SearchContentProjectTypes.Stores.TTypeStore) => ({
    type: ActionTypes.CLEAR_VALUE, typeContent
})