import React, {FC} from 'react'
import './organization-field.less'
import {FieldArray} from 'formik'
import {TOrganizations} from 'models/users-list/user-form'
import {Tooltip} from "antd";
import TagsComponent from "../tags/tags";

type TOrganizationField = {
	name: string
}

const OrganizationField: FC<TOrganizationField> = ({ name }) => {

	return (
		<FieldArray
			name={name}
			render={({ form }) => {
				return (
					<>
						{form.values.organizations.map((organization: TOrganizations, index: number) => {
							return (
								<div className="modal-user-profile__organizations" key={'modal-duplication-user-profile' + index}>
									<div className="field-organization__organization">
										<div className="field-organization__organization--wrapper">
											<p>Организация</p>
											<span className="field-organization__organization__text">{organization.id.label}
												<Tooltip placement="top" title="ИНН организации">
													<span className="field-organization__organization__text__inn">{organization.inn}</span>
												</Tooltip>
											</span>
										</div>
									</div>
									<div className="field-organization__email">
										<div className="field-organization__email--wrapper">
											<p>Email</p>
											<TagsComponent
												tags={form.values.organizations[`${index}`]?.emails}
												name={`${name}[${index}].emails`}
												type={'email'}
											/>
										</div>
									</div>
									<div className="field-organization__phone">
										<div className="field-organization__phone--wrapper">
											<p>Телефон</p>
											<TagsComponent
												tags={form.values.organizations[`${index}`]?.phones}
												name={`${name}[${index}].phones`}
												type="phone"
											/>
										</div>
									</div>
								</div>
							)
						})}
					</>
				)
			}}
		/>
	)
}

export default OrganizationField
