import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    AddRanksErrorAction,
    AddRanksStartAction,
    AddRanksSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import RanksService from "services/ranksService";

export function* addRanksRecord({ data}: ReturnType<typeof AddRanksStartAction>) {
    try {
        yield RanksService.addIRanksRecord(data)
        yield put(AddRanksSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.ranks))

        yield put(NotificationOpen('success', 'Новое звание добавлено'))
    } catch (error: any) {
        yield put(AddRanksErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить звание'))
    }
}
