import {useMutation} from 'react-query';
import {
    TasksReportCopyrightService,
    TasksReportEstimateService,
    TasksReportPostingService,
    TasksReportReachService
} from "services/tasksReportService";
import TasksContentService from "services/tasksContentService";
import {TaskTypes} from 'entities/task';
import {Content, Report} from 'entities/task/ui/records-vote/vote';
import {GetRecordStartAction, GetTasksContentStartAction} from "store/tasks/actions/content";
import {useDispatch, useSelector} from "react-redux";
import {GetRecordStartAction as GetRecordReportStartAction, GetTaskReportStartAction} from "store/tasks/actions/report";
import {selectParams} from "store/selectors";
import {TTaskRecordsFilter} from "models/task/task-list";
import {selectReportTasksFilter} from "store/tasks/selectors/selectors-task-report";
import {TTaskRecordFilter} from "models/task/task-content";
import {selectContentTasksFilter} from "store/tasks/selectors/selectors-task-content";

export function useDeleteInterimVote (typeTask: Report | Content) {
     const put = useDispatch();
     const filterReport: TTaskRecordsFilter = useSelector(selectReportTasksFilter)
     const filterContent: TTaskRecordFilter = useSelector(selectContentTasksFilter)
     const {id} = useSelector(selectParams) // id задачи

     const recordId = typeTask?.task?.id
     let mutationFn;

     switch (typeTask?.type) {
        case TaskTypes.TypesTask.REPORT:
            if (typeTask.reportType === TaskTypes.ReportType.CONTENT_POSTING_REPORT) mutationFn = () => TasksReportPostingService.deleteInterimVoteRecord(recordId);
            if (typeTask.reportType === TaskTypes.ReportType.CONTENT_REACH_REPORT) mutationFn = () => TasksReportReachService.deleteInterimVoteRecord(recordId);
            if (typeTask.reportType === TaskTypes.ReportType.PROJECT_ESTIMATE) mutationFn = () => TasksReportEstimateService.deleteInterimVoteRecord(recordId);
            if (typeTask.reportType === TaskTypes.ReportType.COPYRIGHT_LIST) mutationFn = () => TasksReportCopyrightService.deleteInterimVoteRecord(recordId);
            break;
        case TaskTypes.TypesTask.CONTENT:
            mutationFn = () => TasksContentService.deleteInterimVoteRecord(recordId);
            break;
    }

    return useMutation({
        mutationFn,
        onSuccess: () => {
            if (typeTask.type === TaskTypes.TypesTask.REPORT) {
                handleReportSuccess()
            } else {
                handleContentSuccess()
            }
        },
    });
    function handleReportSuccess  ()  {
        const { searchString, taskId, ...rest } = filterReport.filterData
        if (searchString || Object.keys(rest).length) {
            if (id) put(GetTaskReportStartAction(id, typeTask.reportType))
        } else {
            put(GetRecordReportStartAction(typeTask.reportType, recordId))
        }
    }
    function handleContentSuccess  ()  {
        const { searchString, ...rest } = filterContent.filterData
        if (searchString || Object.keys(rest).length) {
            if (id) put(GetTasksContentStartAction(id))
        } else {
            put(GetRecordStartAction(recordId))
        }
    }
}

export function useDeleteInterimVoteWithoutSuccess (typeTask: Report | Content) {
    const recordId = typeTask?.task?.id
    let mutationFn;

    switch (typeTask?.type) {
        case TaskTypes.TypesTask.REPORT:
            if (typeTask.reportType === TaskTypes.ReportType.CONTENT_POSTING_REPORT) mutationFn = () => TasksReportPostingService.deleteInterimVoteRecord(recordId);
            if (typeTask.reportType === TaskTypes.ReportType.CONTENT_REACH_REPORT) mutationFn = () => TasksReportReachService.deleteInterimVoteRecord(recordId);
            if (typeTask.reportType === TaskTypes.ReportType.PROJECT_ESTIMATE) mutationFn = () => TasksReportEstimateService.deleteInterimVoteRecord(recordId);
            if (typeTask.reportType === TaskTypes.ReportType.COPYRIGHT_LIST) mutationFn = () => TasksReportCopyrightService.deleteInterimVoteRecord(recordId);
            break;
        case TaskTypes.TypesTask.CONTENT:
            mutationFn = () => TasksContentService.deleteInterimVoteRecord(recordId);
            break;
    }

    return useMutation({
        mutationFn,
    });
}

