import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetRelatedUsersErrorAction,
    GetRelatedUsersStartAction,
    GetRelatedUsersSuccessAction,
} from "../../../store/options/actions/actions-users";
import UsersService from "../../../services/usersService";
import {TPerson} from "../../../models/user";


export function* getRelatedUsers(_action: ReturnType<typeof GetRelatedUsersStartAction>) {
    try {
        const { data: users }: AxiosResponse<TPerson[]> = yield UsersService.getRelatedUsers();

        yield put(GetRelatedUsersSuccessAction(users))
    } catch (error: any) {
        yield put(GetRelatedUsersErrorAction(error))
    }
}
