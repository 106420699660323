import {TOrganizations, TResponseUser, TUserForm} from "models/users-list/user-form";
import {IOptions} from "models/feels";

const defaultOrganizations: TOrganizations[] = [
    {
        id: {value: '', label: ''},
        emails: [],
        phones:  []
    }
]


const convertOrganizations = (organizations: TResponseUser["organizations"]): TOrganizations[] => {
    if (organizations.length === 0) return defaultOrganizations;
    return organizations.map((el) => ({
        id: {
            value: el.id,
            label: el.name,
            code: el.isContentCustomer
        },
        phones: el.phones,
        emails: el.emails
    }))
}
export class User implements TUserForm {
    isEdit;
    username;
    firstName;
    lastName;
    middleName;
    role: IOptions<TResponseUser["role"]["name"]> | null;
    leader;
    status;
    image: any[];
    existingImage: any[];
    organizations;
    disabledOrganizations: boolean;
    isTelegramConfirmed;
    agreementConfirmed;
    notificationsConfirmed;
    contentViewAccess: boolean;
    contentDownloadAccess: boolean;
    contentStorageLinkAccess: boolean;
    privateProjectViewAccess: boolean;
    limitedRightsToViewProjectsList: boolean;
    limitedRightsToViewContentTaskRecords: boolean;
    disabled: boolean;
    constructor(user?: TResponseUser | null, currentUser?: string | undefined) {
        this.isEdit = !!user;
        this.username = user ? user.username : '';
        this.firstName = user ? user.firstName : '';
        this.lastName = user ? user.lastName : '';
        this.middleName = user ? user.middleName : '';
        this.role = user ? {value: user.role.id, label: user.role.ruName, code: user.role.name} : null;
        this.leader = user ?  user.leader : false;
        this.status =  user ? user.status : '';
        this.image =  [];
        this.existingImage = user && user?.image ? [user.image] : [];
        this.agreementConfirmed = user ?  user.isUserAgreementConfirmed : false;
        this.notificationsConfirmed = user ?  user.isReceivingNotificationsConfirmed : false;
        this.isTelegramConfirmed = user ?  user.isTelegramConfirmed : false;
        this.organizations = user ? convertOrganizations(user.organizations) : defaultOrganizations;
        this.disabledOrganizations =  ValidateDisabledOrganizations(this.role?.code as TResponseUser["role"]["name"]);
        this.disabled =  ValidateDisabled(this.role?.code as TResponseUser["role"]["name"], currentUser, user);
        this.contentViewAccess = user ? user.contentViewAccess : false;
        this.contentDownloadAccess = user ? user.contentDownloadAccess : false;
        this.contentStorageLinkAccess = user ? user.contentStorageLinkAccess : false;
        this.privateProjectViewAccess = user ? user.privateProjectViewAccess : false;
        this.limitedRightsToViewProjectsList = user ? user.limitedRightsToViewProjectsList : false;
        this.limitedRightsToViewContentTaskRecords = user ? user.limitedRightsToViewContentTaskRecords : false;
    }
    validateDisabledOrganizations(role: TResponseUser["role"]["name"] | null) {
        return ValidateDisabledOrganizations(role);
    }
    validate(role: TResponseUser["role"]["name"] | null , currentUser: string) {
        return ValidateDisabled(role, currentUser);
    }

}
function ValidateDisabledOrganizations(role: TResponseUser["role"]["name"] | null) {
    return role !== 'ROLE_CONTRACTOR';
}

function ValidateDisabled(role: TResponseUser["role"]["name"] | null ,currentUser: string |  undefined, user?: TResponseUser | null) {
    if (user?.deletedDatetime) return  true
    return currentUser === 'ROLE_ADMIN' && role === 'ROLE_ADMIN_IS'
}