import {AnalyticsActionTypes} from './action-types'
import {
	TAnalyticsContentResponse,
	TAnalyticsFilter,
	TAnalyticsFilterReach,
	TAnalyticsKpiTopProjectResponse,
	TAnalyticsKpiTopResourcesResponse,
	TAnalyticsPostingTopResourcesResponse,
	TAnalyticsProjectResponse,
	TAnalyticsReachDataResponse,
	TAnalyticsSessionResponse,
	TPostingKpi,
} from '../../models/analytics/analytics-project'

/**
 * GET Analytics PROJECTS
 */
export const GetAnalyticsProjectStartAction = () => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_PROJECT_START,
})
export const GetAnalyticsProjectSuccessAction = (data: TAnalyticsProjectResponse) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_PROJECT_SUCCESS,
	data,
})
export const GetAnalyticsProjectErrorAction = (error: any) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_PROJECT_ERROR,
	error,
})

/**
 * GET Analytics posting top resources
 */
export const GetAnalyticsPostingTopResourcesStartAction = () => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_POSTING_TOP_RESOURCES_START,
})
export const GetAnalyticsPostingTopResourcesSuccessAction = (data: TAnalyticsPostingTopResourcesResponse) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_POSTING_TOP_RESOURCES_SUCCESS,
	data,
})
export const GetAnalyticsPostingTopResourcesErrorAction = (error: any) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_POSTING_TOP_RESOURCES_ERROR,
	error,
})

/**
 * GET Analytics posting top kpi площадок
 */
export const GetAnalyticsKpiTopResourcesStartAction = () => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_RESOURCES_START,
})
export const GetAnalyticsKpiTopResourcesSuccessAction = (data: TAnalyticsKpiTopResourcesResponse) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_RESOURCES_SUCCESS,
	data,
})
export const GetAnalyticsKpiTopResourcesErrorAction = (error: any) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_RESOURCES_ERROR,
	error,
})

/**
 * GET Analytics posting top kpi проектов
 */
export const GetAnalyticsKpiTopProjectStartAction = () => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_PROJECT_START,
})
export const GetAnalyticsKpiTopProjectSuccessAction = (data: TAnalyticsKpiTopProjectResponse) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_PROJECT_SUCCESS,
	data,
})
export const GetAnalyticsKpiTopProjectErrorAction = (error: any) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_KPI_TOP_PROJECT_ERROR,
	error,
})

/**
 * GET Analytics session-content kpi
 */
export const GetAnalyticsSessionContentKpiStartAction = (dataType: TPostingKpi) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_KPI_START,
	dataType,
})
export const GetAnalyticsSessionContentKpiSuccessAction = (
	data: TAnalyticsContentResponse[] | TAnalyticsSessionResponse[]
) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_KPI_SUCCESS,
	data,
})
export const GetAnalyticsSessionContentKpiErrorAction = (error: any) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_KPI_ERROR,
	error,
})

/**
 * GET Analytics session-content posting
 */
export const GetAnalyticsSessionContentPostingStartAction = (dataType: TPostingKpi) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_POSTING_START,
	dataType,
})
export const GetAnalyticsSessionContentPostingSuccessAction = (
	data: TAnalyticsContentResponse[] | TAnalyticsSessionResponse[]
) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_POSTING_SUCCESS,
	data,
})
export const GetAnalyticsSessionContentPostingErrorAction = (error: any) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_SESSION_CONTENT_POSTING_ERROR,
	error,
})

/**
 * GET Analytics reach-data
 */
export const GetAnalyticsReachDataStartAction = () => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_REACH_DATA_START,
})
export const GetAnalyticsReachDataSuccessAction = (data: TAnalyticsReachDataResponse[]) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_REACH_DATA_SUCCESS,
	data,
})
export const GetAnalyticsReachDataErrorAction = (error: any) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_REACH_DATA_ERROR,
	error,
})

/**
 * GET Analytics summary-data
 */
export const GetAnalyticsSummaryDataStartAction = () => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_SUMMARY_DATA_START,
})
export const GetAnalyticsSummaryDataSuccessAction = (data: any) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_SUMMARY_DATA_SUCCESS,
	data,
})
export const GetAnalyticsSummaryDataErrorAction = (error: any) => ({
	type: AnalyticsActionTypes.GET_ANALYTICS_SUMMARY_DATA_ERROR,
	error,
})

/**
 * Изменение фильтра
 */
export const SetAnalyticsFilter = (filter: TAnalyticsFilter, dataType: TPostingKpi) => ({
	type: AnalyticsActionTypes.SET_ANALYTICS_FILTER,
	filter,
	dataType,
})

/**
 * Изменение фильтра данных по охватам
 */
export const SetAnalyticsFilterReach = (filter: TAnalyticsFilterReach) => ({
	type: AnalyticsActionTypes.SET_ANALYTICS_FILTER_REACH,
	filter,
})
export const ClearAnalyticsFilterReach = () => ({
	type: AnalyticsActionTypes.CLEAR_ANALYTICS_FILTER_REACH,
})
export const ClearAnalyticsData = () => ({
	type: AnalyticsActionTypes.CLEAR_ANALYTICS_DATA,
})
