import React, {FC} from 'react';
import ErrorBoundary from "antd/es/alert/ErrorBoundary";

const ErrorComponent: FC<{message?: string, description?: string, children: any}> = ({message, description, children}) => {
    return (
        <ErrorBoundary
            message={message ? message : 'Что-то пошло не так'}
            description={description ? description : 'Попробуйте обновить страницу или связаться с технической поддержкой'}>
            {children}
        </ErrorBoundary>
    );
};

export default ErrorComponent;
