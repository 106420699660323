import {put, select} from 'redux-saga/effects'
import { NotificationOpen } from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	CreateSprintErrorAction,
	CreateSprintStartAction,
	CreateSprintSuccessAction,
	GetSprintsStartAction,
	ClearEditingKey
} from 'store/sprints/actions'
import SprintService from 'services/sprintService'
import {GetTasksGantStartAction} from "store/tasks/actions/gant-kanban";
import {selectParams} from "store/selectors";

export function* createSprint({ data }: ReturnType<typeof CreateSprintStartAction>) {
	try {
		const {id} = yield select(selectParams);
		yield SprintService.createSprint(data)
		yield put(CreateSprintSuccessAction())
		yield put(GetSprintsStartAction())
		yield put(NotificationOpen('success', 'Этап создан'))
		yield put(ClearEditingKey())
		if (id) yield put(GetTasksGantStartAction(id))
	} catch (error: any) {
		yield put(CreateSprintErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
