import {axiosRequest} from "shared/config/axios";
import {ReportTypes} from 'entities/reports'
import {ReportsContentSchema} from "../types";

export class ReportsController {
    static key = `/reports/search`

    static async fetch(data: ReportTypes.Filter) {
        const filter = {...data, page: data.page - 1}
        const { data: reports } = await axiosRequest<ReportTypes.ReportsResponse>({
            method: 'POST',
            url: '/reports/search',
            data: filter,
            parser: {list: true, schema: ReportsContentSchema.array()}
        })
        return {data: reports}
    }
}