import {put, select} from 'redux-saga/effects'
import {
	GetContentStartAction,
	GetContestErrorAction,
	GetContestSuccessAction,
	SetSortingFilter
} from 'store/content/content/actions'
import {TypeContentFilter, TypeContentResponse} from 'models/content'
import {AxiosResponse} from 'axios'
import {ContentService} from 'services/contentService'
import {selectFilter} from 'store/content/content/selectors'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {selectParams} from "store/selectors";

export function* getContent(_action: ReturnType<typeof GetContentStartAction>) {
	try {
		const filter: TypeContentFilter = yield select(selectFilter)
		const { id } = yield select(selectParams)
		const { data }: AxiosResponse<TypeContentResponse> = yield ContentService.getContent({
			...filter,
			filterData:	{
				...filter.filterData,
				projectId: id
			}
		})

		if (data.page * data.pageSize >= data.totalCount && data.totalCount !== 0) {
			yield put(SetSortingFilter({...filter, page: 1}))
		} else {
			yield put(GetContestSuccessAction(data))
		}

	} catch (error: any) {
		yield put(GetContestErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить контент'))
	}
}
