import {createSelector} from 'reselect'
import {TApplicationState} from '../aplication-state'

const projectDocumentsState = (state: TApplicationState) => state.projectDocuments

export const selectDocumentsAllProject = createSelector(projectDocumentsState, (state) => state.documentsAll)
export const selectProjectDocumentsFilter = createSelector(projectDocumentsState, (state) => state.filter)
export const selectProjectDocumentsWithFilter = createSelector(
	projectDocumentsState,
	(state) =>(
		{
			documents: state.documentsWithFilter,
			isLoading: state.isLoadingFile
		}
	)
)
export const selectProjectDocumentsId = createSelector(projectDocumentsState, (state) => state.documentsId)
export const selectProjectDocumentsEditingKey = createSelector(projectDocumentsState, (state) => state.editingKey)
