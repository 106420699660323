import $api from '../http'
import { AxiosResponse } from 'axios'
import {
	TAnalyticsContentResponse,
	TAnalyticsReachDataResponse,
	TAnalyticsFilter,
	TAnalyticsFilterReach,
	TAnalyticsKpiTopProjectResponse,
	TAnalyticsKpiTopResourcesResponse,
	TAnalyticsPostingTopResourcesResponse,
	TAnalyticsProjectResponse,
	TAnalyticsSessionResponse,
} from '../models/analytics/analytics-project'

/**
 * @category Service
 * @subcategory All
 * @class AnalyticsService
 */
export default class AnalyticsService {
	/**
	 * получение данных для графика кол-во проектов
	 */
	static getAnalyticsProject(filter: TAnalyticsFilter): Promise<AxiosResponse<TAnalyticsProjectResponse[]>> {
		return $api.post<TAnalyticsProjectResponse[]>(`/analytics/project-count`, filter)
	}

	/**
	 * получение данных для графика топ площадок по числу размещения
	 */
	static getAnalyticsPostingTopResources(filter: TAnalyticsFilter): Promise<AxiosResponse<TAnalyticsPostingTopResourcesResponse[]>> {
		return $api({
				method: 'POST',
				url: '/analytics/posting-top-internet-resources',
				data: filter,
				params: {
					top: 5
				}
			})
	}

	/**
	 * получение данных для графика топ площадок по числу КПЭ охватов
	 */
	static getAnalyticsKpiTopResources(
		filter: TAnalyticsFilter
	): Promise<AxiosResponse<TAnalyticsKpiTopResourcesResponse[]>> {
		return $api({
				method: 'POST',
				url: '/analytics/kpi-top-internet-resources',
				data: filter,
				params: {
					top: 5
				}
	 		})
	}

	/**
	 * получение данных для графика топ проектов по числу КПЭ охватов
	 */
	static getAnalyticsKpiTopProject(
		filter: TAnalyticsFilter
	): Promise<AxiosResponse<TAnalyticsKpiTopProjectResponse[]>> {
		return $api({
			method: 'POST',
			url: '/analytics/kpi-top-projects',
			data: filter,
			params: {
				top: 5
			}
		})
	}

	/**
	 * получение данных для графика выполнение КПЭ по сессиям
	 */
	static getAnalyticsSessionKpi(filter: TAnalyticsFilter): Promise<AxiosResponse<TAnalyticsSessionResponse[]>> {
		return $api.post<TAnalyticsSessionResponse[]>(`/analytics/session-kpi-plan-execution`, filter)
	}

	/**
	 * получение данных для графика выполнения КПЭ контента
	 */
	static getAnalyticsContentKpi(filter: TAnalyticsFilter): Promise<AxiosResponse<TAnalyticsContentResponse[]>> {
		return $api.post<TAnalyticsContentResponse[]>(`/analytics/direction-kpi-plan-execution`, filter)
	}
	/**
	 * получение данных для графика выполнение размещения КПЭ по сессиям
	 */

	static getAnalyticsSessionPosting(filter: TAnalyticsFilter): Promise<AxiosResponse<TAnalyticsSessionResponse[]>> {
		return $api.post<TAnalyticsSessionResponse[]>(`/analytics/session-posting-plan-execution`, filter)
	}
	/**
	 * получение данных для графика выполнения размещения КПЭ контента
	 */

	static getAnalyticsContentPosting(filter: TAnalyticsFilter): Promise<AxiosResponse<TAnalyticsContentResponse[]>> {
		return $api.post<TAnalyticsContentResponse[]>(`/analytics/direction-posting-plan-execution`, filter)
	}

	/**
	 * получение данных по охватам
	 */

	static getAnalyticsReachData(filter: TAnalyticsFilterReach): Promise<AxiosResponse<TAnalyticsReachDataResponse[]>> {
		return $api.post<TAnalyticsReachDataResponse[]>(`/analytics/actual-kpi-data`, filter)
	}

	/**
	 * получение сводных данных
	 */
	static getAnalyticsSummaryData(): Promise<AxiosResponse> {
		return $api.get<any>(`/analytics/summary-data`)
	}
}
