import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation, useQueryClient } from 'react-query'
import styles from './styles.module.less'
import { ScheduleDatesSet } from '../../../types'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { TForm } from '../../modal-duplication/modal'
import { DeleteOutlined } from '@ant-design/icons'
import {Popconfirm, Spin, Tooltip} from 'antd'
import { NotificationOpen } from 'store/notification/actions'
import { TasksScheduleDatesController } from 'features/сonfiguring-task-autocreation/api'

type TProps = {
	data: ScheduleDatesSet[]
	handleClickFilter: Function
	disabled: boolean
}
const Filters: FC<TProps> = ({ data, handleClickFilter, disabled }) => {
	const put = useDispatch()
	const { setFieldValue } = useFormikContext<TForm>()
	const queryClient = useQueryClient()
	const [activeId, setActiveId] = useState('')

	const { mutate } = useMutation({
		mutationFn: TasksScheduleDatesController.deleteTasksScheduleDates,
		onSuccess: async () => {
			put(NotificationOpen('success', 'Фильтр успешно удалён'))
			await queryClient.invalidateQueries([TasksScheduleDatesController.getTasksScheduleDatesKey])
		},
	})

	const handleClickDiv = (filter: ScheduleDatesSet) => () => {
		const { id } = filter
		if (id === activeId) {
			handleClickFilter(null)
			setActiveId('')
			setFieldValue('scheduleDates', [])
		} else {
			const currentFilter = data.find((it) => it.id === id)
			const scheduleDates = currentFilter?.dateSet.length ? currentFilter?.dateSet : []

			handleClickFilter(filter)
			setActiveId(id)
			setFieldValue('scheduleDates', scheduleDates)
		}
	}

	const onDelete = (id: string) => (event: any) => {
		event.stopPropagation()
		if (id === activeId) handleClickFilter(null)
		mutate(id)
	}

	const emptyIndicator = () => {
		return <div />
	}

	return (
		<div className={styles.filters}>
			{data?.map((filter) => {
				return (
					<Spin spinning={disabled} indicator={emptyIndicator()} key={filter.id}>
						<div
							onClick={handleClickDiv(filter)}
							className={classNames(styles.name, { active: activeId === filter.id, disabled })}
						>
							{filter.name.length > 20 ? (
								<Tooltip title={filter.name}>{filter.name.slice(0, 20) + '...'}</Tooltip>
							) : (
								<>{filter.name}</>
							)}
							<Popconfirm
								title={'Удалить набор дат для создания задач'}
								onConfirm={onDelete(filter.id)}
								onCancel={(event) => event?.stopPropagation()}
							>
								<DeleteOutlined className={styles.icon} onClick={(event) => event.stopPropagation()} />
							</Popconfirm>
						</div>
					</Spin>
				)
			})}
		</div>
	)
}

export default Filters
