import {AxiosResponse} from "axios";
import $api from "../http";
import {T_ID_NAME_CODE_HIDDEN} from "../models/common";
import {THandbookContentResponse, THandbookFilter, TSessionsType} from "../models/handbook";

/**
 * @category Service
 * @class SessionTypeService
 */
export default class SessionTypeService {
    /**
     * Получение всех sessions
     */
    static async getSessionsType(): Promise<AxiosResponse<T_ID_NAME_CODE_HIDDEN[]>> {
        return $api.get('/sessions/types')
    }
    /**
     * Получение всех sessions c filters
     */
    static async getSessionsTypeWithFilter(data: THandbookFilter): Promise<AxiosResponse<THandbookContentResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<THandbookContentResponse>('/sessions/types/search', filter)
    }
    /**
     *  Добавление sessions
     */
    static async addSessionsTypeRecord( data: TSessionsType): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/sessions/types`, data)
    }
    /**
     *   Обновление sessions
     */
    static async updateSessionsTypeRecord( data: TSessionsType, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/sessions/types/${id}`, data)
    }
    /**
     *   Удаление sessions
     */
    static async deleteSessionsTypeRecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/sessions/types/${id}`)
    }
    /**
     *   Массовые действия sessions
     */
    static async bulkSessionsTypeActions(recordsArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/sessions/types/bulk-actions/${action}`, recordsArr)
    }
    /**
     *   Выгрузка данных sessions
     */
    static async downloadSessionsType(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/sessions/types/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }
    /**
     * Выполнение импорта данных sessions из заполненного шаблона в формате Excel
     */
    static async importSessionsType (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/sessions/types/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    /**
     *   Выгрузка шаблона
     */
    static async downloadSessionsTypeTemplate(): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/sessions/types/import/template',
            responseType:'arraybuffer',
        })
    }
}



