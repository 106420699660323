import {put} from "redux-saga/effects";
import {
    DeleteTaskContentErrorAction,
    DeleteTaskContentStartAction,
    DeleteTaskContentSuccessAction,
    GetTasksContentStartAction,
} from "store/tasks/actions/content";
import TasksContentService from "services/tasksContentService";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";


export function* deleteTask({recordId, taskId}: ReturnType<typeof DeleteTaskContentStartAction>) {
    try {
        yield TasksContentService.deleteTaskRecord(recordId)
        yield put(DeleteTaskContentSuccessAction())
        yield put(NotificationOpen('success', 'Запись удалена'))

        yield put(GetTasksContentStartAction(taskId))
    } catch (error: any) {
        yield put(DeleteTaskContentErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить запись'))
    }
}