import { ExtendedSearchTypes } from "features/extended-search";
import {ActionTypes} from "./types";


export const setCounts = <T extends Partial<ExtendedSearchTypes.Stores.Counts["fields"]>>(
    typeContent: ExtendedSearchTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUE, typeContent, value, isToggled
})
export const clearCounts = (typeContent: ExtendedSearchTypes.Stores.TTypeStore) => ({
    type: ActionTypes.CLEAR_VALUE, typeContent
})