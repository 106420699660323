import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    TasksReportCopyrightService,
    TasksReportEstimateService,
    TasksReportPostingService,
    TasksReportReachService
} from "services/tasksReportService";
import {
    GetTaskReportStartAction,
    ToExaminationTaskReportErrorAction,
    ToExaminationTaskReportStartAction,
    ToExaminationTaskReportSuccessAction,
} from "store/tasks/actions/report";
import {ReportType} from "models/task/types";

export function* toExaminationReportTask({recordId, typeTask, taskId}: ReturnType<typeof ToExaminationTaskReportStartAction>) {
    try {
        switch (typeTask) {
            case ReportType.CONTENT_POSTING_REPORT:
                yield TasksReportPostingService.ToExaminationTaskReport(recordId);
                break;
            case ReportType.COPYRIGHT_LIST:
                yield TasksReportCopyrightService.ToExaminationTaskReport(recordId);
                break;
            case ReportType.CONTENT_REACH_REPORT:
                yield TasksReportReachService.ToExaminationTaskReport(recordId);
                break;
            case ReportType.PROJECT_ESTIMATE:
                yield TasksReportEstimateService.ToExaminationTaskReport(recordId);
                break;
        }

        yield put(ToExaminationTaskReportSuccessAction());
        yield put(NotificationOpen('success', 'Запись отправлена на проверку'));

        if (taskId) yield put(GetTaskReportStartAction(taskId, typeTask));
    } catch (error: any) {
        yield put(ToExaminationTaskReportErrorAction(error));
        const message = CreateErrorMessage.response(error);
        yield put(NotificationOpen(
            'error',
            'Ошибка',
            message ? message : 'Не удалось отправить на проверку'
        ));
    }
}