const userActions = [
	{
		id: '58',
		selector: '.ant-table',
		content:
			'Таблица действий конкретного выбранного пользователя позволяет сортировать данные, совершать поиск по определенным действиям и выбранному периоду фильтра',
		role: [],
	},
]

export default userActions
