import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    AddLegalFormOrgErrorAction,
    AddLegalFormOrgStartAction,
    AddLegalFormOrgSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import LegalFormOrganizationService from "services/legalFormOrganizationService";

export function* addLegalFormOrganizationRecord({ data}: ReturnType<typeof AddLegalFormOrgStartAction>) {
    try {
        yield LegalFormOrganizationService.addLegalFormOrganizationRecord(data)
        yield put(AddLegalFormOrgSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.legalFormOrganization))
        yield put(NotificationOpen('success', 'Новая запись справочника правовых форм организации добавлена'))
    } catch (error: any) {
        yield put(AddLegalFormOrgErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить запись'))
    }
}
