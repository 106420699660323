import React, { FC } from 'react'
import { Select, Spin, Tooltip } from 'antd'
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useQuery } from 'react-query'
import { IOptions } from 'models/feels'
import { useFeelsApp, useTasksFields } from 'hooks'
import { TTasksTypesFields } from 'models/task'
import { TStatusesFeels } from 'models/projects/projectFeels'
import { TTaskListFilterData } from 'models/task/task-list'
import { TaskApi } from 'entities/task'
import styles from "./styles.module.less";

const { Option } = Select

type TProjectSelect = {
	setState: (val: string[]) => void
	state: string[]
	projectsIds: string[]
	disabled: boolean
}

const TaskSelect: FC<TProjectSelect> = ({ setState, state, projectsIds, disabled }) => {
	const {types, statuses, isLoading: isLoadingFields} = useTasksFields([TTasksTypesFields.types, TTasksTypesFields.statuses])
	const { reportType, isLoading: isLoadingFeelsApps } = useFeelsApp([TStatusesFeels.reportType])

	const [reportReachHosted] = (reportType && reportType.filter((it) => it.code === 'CONTENT_REACH_REPORT')) || []
	const [typeReport] = (types && types.filter((it) => it.code === 'REPORT')) || []
	const statusIds =
		(statuses && statuses.filter((it) => ['IN_PROGRESS', 'READY'].includes(it.code as string)))?.map((status) => status.value) ||
		[]

	const filterData: TTaskListFilterData = {
		typeIds: typeReport?.value ? [typeReport.value] : [],
		reportTypeIds: reportReachHosted?.value ? [reportReachHosted.value] : [],
		statusIds: statusIds,
		...(projectsIds.length && { projectIds: projectsIds }),
	}

	const { isLoading, data: options } = useQuery({
		queryKey: ['tasks/search', filterData],
		queryFn: () => TaskApi.getAllTasksOptions(filterData),
		enabled: !!statusIds.length && !!typeReport?.value && !!reportReachHosted?.value && !!projectsIds,
	})
	const arr = options?.map((it) => it.value) || []
	const handlerSelect = (values: string[]) => {
		setState(values)
	}

	const onClear = () => {
		setState([])
	}

	const handlerCheckbox = (e: CheckboxChangeEvent) => {
		const checked: boolean = e.target.checked
		if (checked) setState(arr)
		else setState([])
	}

	return (
		<Spin spinning={isLoading}>
			<div className={styles.wrapper}>
				<p className={styles.text}>Задачи</p>
				<Select
					loading={isLoading || isLoadingFields || isLoadingFeelsApps}
					disabled={disabled}
					showSearch
					allowClear
					getPopupContainer={(trigger) => trigger.parentNode}
					mode={'multiple'}
					value={state}
					onClear={onClear}
					maxTagCount={"responsive"}
					placeholder={'Выберите задачи'}
					onChange={handlerSelect}
					optionFilterProp="children"
                    filterOption={(input, option) => {
                        if (option === undefined || null) return false

                        const optionText = option?.['data-code'];
                        return optionText?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
					dropdownRender={(menu) => (
						<>
							{menu}
							<div className={styles.select_checkbox}>
								<Checkbox checked={arr?.length === state?.length} onChange={handlerCheckbox}>
									Выбрать все
								</Checkbox>
							</div>
						</>
					)}
				>
					{options && options.map((option) => renderOptions(option))}
				</Select>
			</div>
		</Spin>
	)
}

export default TaskSelect

function renderOptions(option: IOptions) {
	return (
		<Option key={option.value} value={option.value} data-code={option.code}>
			<Tooltip title={option.code} placement="right">
				{option.label}
			</Tooltip>
		</Option>
	)
}
