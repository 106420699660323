import React from 'react'
import styles from './styles.module.less'
import { Drawer } from 'antd'
import useToggleSidebar from 'hooks/toggleSidebar'
import { FunctionalityInfo } from 'features/functionality-info'

const FunctionalityInfoSidebar = () => {
	const { functionalityInfoStatus, handlerSidebar } = useToggleSidebar()
	return (
		<Drawer
			className={styles.sidebar}
			placement="right"
			width={window.innerWidth > 550 ? 550 : 350}
			closable={false}
			onClose={handlerSidebar('functionalityInfo')}
			visible={functionalityInfoStatus}
		>
			<FunctionalityInfo />
		</Drawer>
	)
}

export default FunctionalityInfoSidebar
