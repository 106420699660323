import { GetRecordErrorAction, GetRecordStartAction, GetRecordSuccessAction } from 'store/tasks/actions/report'
import {
	TasksReportCopyrightService,
	TasksReportEstimateService,
	TasksReportPostingService,
	TasksReportReachService
} from "services/tasksReportService";import { AxiosResponse } from 'axios'
import { TaskApi, TaskTypes } from 'entities/task'
import {call, put, select} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import { NotificationOpen } from 'store/notification/actions'
import { selectReportTask } from 'store/tasks/selectors/selectors-task-report'
import { Query } from 'processes/query'
import { selectParams } from 'store/selectors'

export function* getReportRecord({ typeTask, originalRecordId, updatedRecordId }: ReturnType<typeof GetRecordStartAction>) {
	if (typeTask === TaskTypes.ReportType.CONTENT_REACH_REPORT) {
		yield call(getRecordReach, originalRecordId, updatedRecordId)
	}
	if (typeTask === TaskTypes.ReportType.COPYRIGHT_LIST) {
		yield call(getRecordCopyright, originalRecordId, updatedRecordId)
	}
	if (typeTask === TaskTypes.ReportType.PROJECT_ESTIMATE) {
		yield call(getRecordEstimate, originalRecordId, updatedRecordId)
	}
	if (typeTask === TaskTypes.ReportType.CONTENT_POSTING_REPORT) {
		yield call(getRecordPosting, originalRecordId, updatedRecordId)
	}
}

function* getRecordReach(originalRecordId: string, updatedRecordId?: string) {
	try {
		const { id } = yield select(selectParams)
		const tasks: TaskTypes.ReachRecords[] = yield select(selectReportTask)
		const { data }: AxiosResponse<TaskTypes.ReachRecords> = yield TasksReportReachService.getRecord(updatedRecordId || originalRecordId)
		const newData = replaceRecordById(tasks, data, originalRecordId)
		yield put(GetRecordSuccessAction(newData))
		if (id) {
			Query.invalidate([TaskApi.getApprovedReachAmountSum.key(id)])
			Query.invalidate([TaskApi.getTaskRecordsCount.key(id as string)]);
		}
	} catch (error: any) {
		yield put(GetRecordErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить запись задачи'))
	}
}
function* getRecordCopyright(originalRecordId: string, updatedRecordId?: string) {
	try {
		const tasks: TaskTypes.CopyrightRecords[] = yield select(selectReportTask)
		const { data }: AxiosResponse<TaskTypes.CopyrightRecords> = yield TasksReportCopyrightService.getRecord(updatedRecordId || originalRecordId)
		const newData = replaceRecordById(tasks, data, originalRecordId)
		yield put(GetRecordSuccessAction(newData))

	} catch (error: any) {
		yield put(GetRecordErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить запись задачи'))
	}
}
function* getRecordEstimate(originalRecordId: string, updatedRecordId?: string) {
	try {
		const tasks: TaskTypes.EstimateRecords[] = yield select(selectReportTask)
		const { data }: AxiosResponse<TaskTypes.EstimateRecords> = yield TasksReportEstimateService.getRecord(updatedRecordId || originalRecordId)
		const newData = replaceRecordById(tasks, data, originalRecordId)
		yield put(GetRecordSuccessAction(newData))

	} catch (error: any) {
		yield put(GetRecordErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить запись задачи'))
	}
}
function* getRecordPosting(originalRecordId: string, updatedRecordId?: string) {
	try {
		const tasks: TaskTypes.PostingRecords[] = yield select(selectReportTask)
		const { data }: AxiosResponse<TaskTypes.PostingRecords> = yield TasksReportPostingService.getRecord(updatedRecordId || originalRecordId)
		const newData = replaceRecordById(tasks, data, originalRecordId)
		yield put(GetRecordSuccessAction(newData))

	} catch (error: any) {
		yield put(GetRecordErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить запись задачи'))
	}
}
function replaceRecordById(records: TaskTypes.ReportRecords, newRecord: TaskTypes.ReportRecord, id: string): TaskTypes.ReportRecords  {
	const newTaskRecords = [...records] as TaskTypes.ReportRecords
	// Находим индекс записи, которую нужно заменить
	const targetIndex = newTaskRecords.findIndex(record => record.id === id)

	if (targetIndex !== -1) {
		newTaskRecords[targetIndex] = newRecord;
	}
	return newTaskRecords
}