import { put, select } from 'redux-saga/effects'
import { NotificationOpen } from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	TasksReportCopyrightService,
	TasksReportEstimateService,
	TasksReportPostingService,
	TasksReportReachService,
} from 'services/tasksReportService'
import {
	GetRecordStartAction,
	GetTaskReportStartAction,
	UpdateTaskReportErrorAction,
	UpdateTaskReportStartAction,
	UpdateTaskReportSuccessAction,
} from 'store/tasks/actions/report'
import { TTaskCopyrightNew, TTaskEstimateNew, TTaskPostingNew, TTaskReachNew } from 'models/task/task-report'
import { TTaskRecordsFilter } from 'models/task/task-list'
import { TaskTypes } from 'entities/task'
import { selectReportTasksFilter } from 'store/tasks/selectors/selectors-task-report'
import { selectParams } from 'store/selectors'

export function* updateReportTask({ recordId, data, typeTask }: ReturnType<typeof UpdateTaskReportStartAction>) {
	try {
		let updatedRecordId;// сохраняем в эту переменную id записи если recordId поменялся
		const { id } = yield select(selectParams) //id задачи
		const filter: TTaskRecordsFilter = yield select(selectReportTasksFilter)
		const { searchString, taskId, ...rest } = filter.filterData

		if (typeTask === TaskTypes.ReportType.CONTENT_POSTING_REPORT) {
			const {data: id} = yield TasksReportPostingService.updateTaskReport(recordId, data as TTaskPostingNew)
			updatedRecordId = id
		}
		if (typeTask === TaskTypes.ReportType.COPYRIGHT_LIST) {
			const {data: id} = yield TasksReportCopyrightService.updateTaskReport(recordId, data as TTaskCopyrightNew)
			updatedRecordId = id
		}
		if (typeTask === TaskTypes.ReportType.CONTENT_REACH_REPORT) {
			const {data: id} = yield TasksReportReachService.updateTaskReport(recordId, data as TTaskReachNew)
			updatedRecordId = id
		}
		if (typeTask === TaskTypes.ReportType.PROJECT_ESTIMATE) {
			const {data: id} = yield TasksReportEstimateService.updateTaskReport(recordId, data as TTaskEstimateNew)
			updatedRecordId = id
		}

		yield put(UpdateTaskReportSuccessAction())
		yield put(NotificationOpen('success', 'Запись обновлена'))

		if (searchString || Object.keys(rest).length) {
			if (id) yield put(GetTaskReportStartAction(id, typeTask))
		} else {
			yield put(GetRecordStartAction(typeTask, recordId, updatedRecordId))
		}
	} catch (error: any) {
		yield put(UpdateTaskReportErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить запись'))
	}
}
