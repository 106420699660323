import {takeEvery, takeLatest} from 'redux-saga/effects'
import {
	OptionsGeneralActionTypes,
	OptionsHandbookCategoryProject,
	OptionsHandbookGenreProject,
	OptionsHandbookLegalForm,
	OptionsHandbookRanks,
	OptionsHandbookRegions,
	OptionsHandbookSessionsType,
	UsersHandbookContentDeliveryTypes,
	UsersHandbookContentDirectionTypes,
	UsersHandbookDocumentsTypes,
	UsersHandbookDownloadTypes,
	UsersHandbookInternetTypes,
	UsersHandbookKPETypes,
	UsersHandbookOrganizationTypes,
	UsersHandbookThematicsTypes,
	UsersHandbookUsersActionsTypes
} from 'store/options/action-types'
import {getHandbook} from "./getHandbook";
import {setHandbookFilter} from "./setHandbookFilter";
import {addThematic} from "./thematics/add-thematic";
import {updateThematic} from "./thematics/update-thematic";
import {deleteThematic} from "./thematics/delete-thematic";
import {bulkThematicsActions} from "./thematics/bulk-actions";
import {addInternet} from "./internet/add";
import {bulkInternetActions} from "./internet/bulk-actions";
import {deleteInternet} from "./internet/delete";
import {updateInternet} from "./internet/update";
import {addContentDelivery} from "./content-delivery/add";
import {addKPE} from "./kpe/add";
import {updateContentDelivery} from "./content-delivery/update";
import {updateKPE} from "./kpe/update";
import {deleteContentDelivery} from "./content-delivery/delete";
import {bulkContentDeliveryActions} from "./content-delivery/bulk-actions";
import {bulkKPEActions} from "./kpe/bulk-actions";
import {deleteKPE} from "./kpe/delete";
import {addContentDirection} from "./content-direction/add";
import {updateContentDirection} from "./content-direction/update";
import {deleteContentDirection} from "./content-direction/delete";
import {bulkContentDirectionActions} from "./content-direction/bulk-actions";
import {updateActionsUsers} from "./users-actions/update";
import {addOrganization} from "./organization/add-organization";
import {bulkOrganizationsActions} from "./organization/bulk-actions";
import {deleteOrganization} from "./organization/delete";
import {downloadHandbook} from "./download-excel";
import {getOrganization} from "./organization/getOrganization";
import {updateOrganization} from "./organization/update";
import {downloadHandbookTemplate} from "./download-template";
import {uploadExcel} from "./upload-excel";
import {getAllContentDelivery} from "./content-delivery/getAll";
import {addDocuments} from "./documents/add";
import {updateDocuments} from "./documents/update";
import {deleteDocuments} from "./documents/delete";
import {bulkDocumentsActions} from "./documents/bulk-actions";
import {getContentModuleSection} from "./getContentModuleSection";
import {addRegionsRecord} from "./regions/add";
import {updateRegions} from "./regions/update";
import {deleteRegionsRecord} from "./regions/delete";
import {bulkRegionsActions} from "./regions/bulk-actions";
import {addRanksRecord} from "./ranks/add";
import {updateRanks} from "./ranks/update";
import {deleteRanksRecord} from "./ranks/delete";
import {bulkRanksActions} from "./ranks/bulk-actions";
import {addLegalFormOrganizationRecord} from "./legal-form-organization/add";
import {bulkLegalFormOrganizationActions} from "./legal-form-organization/bulk-actions";
import {deleteLegalFormOrganizationRecord} from "./legal-form-organization/delete";
import {updateLegalFormOrganization} from "./legal-form-organization/update";
import {addSessionsTypeRecord} from "./sessions-type/add";
import {bulkSessionsTypeActions} from "./sessions-type/bulk-actions";
import {deleteSessionsTypeRecord} from "./sessions-type/delete";
import {updateSessionsType} from "./sessions-type/update";
import {bulkCategoryProjectActions} from "./category-project/bulk-actions";
import {bulkGenreProjectActions} from "./genre-project/bulk-actions";
import {deleteCategoryProject} from "./category-project/delete";
import {deleteGenreProject} from "./genre-project/delete";
import {updateCategoryProject} from "./category-project/update";
import {updateGenreProject} from "./genre-project/update";
import {addCategoryProject} from "./category-project/add";
import {addGenreProject} from "./genre-project/add";

export default function* handbookSaga() {
	yield takeEvery(UsersHandbookThematicsTypes.GET_HANDBOOK_START, getHandbook)
	yield takeEvery(UsersHandbookThematicsTypes.SET_HANDBOOK_FILTER, setHandbookFilter)
	yield takeLatest(UsersHandbookThematicsTypes.ADD_THEMATIC_START, addThematic)
	yield takeLatest(UsersHandbookInternetTypes.ADD_INTERNET_START, addInternet)
	yield takeLatest(UsersHandbookContentDeliveryTypes.ADD_CONTENT_DELIVERY_START, addContentDelivery)
	yield takeLatest(UsersHandbookContentDeliveryTypes.GET_ALL_CONTENT_DELIVERY_START, getAllContentDelivery)
	yield takeLatest(UsersHandbookKPETypes.ADD_KPE_START, addKPE)
	yield takeLatest(UsersHandbookContentDirectionTypes.ADD_CONTENT_DIRECTION_START, addContentDirection)
	yield takeLatest(UsersHandbookOrganizationTypes.ADD_ORGANIZATION_START, addOrganization)
	yield takeLatest(UsersHandbookOrganizationTypes.GET_ORGANIZATION_START, getOrganization)
	yield takeLatest(UsersHandbookDocumentsTypes.ADD_DOCUMENTS_START, addDocuments)
	yield takeLatest(OptionsHandbookRegions.ADD_REGIONS_START, addRegionsRecord)
	yield takeLatest(OptionsHandbookRanks.ADD_RANKS_START, addRanksRecord)
	yield takeLatest(OptionsHandbookLegalForm.ADD_LEGAL_FORM_START, addLegalFormOrganizationRecord)
	yield takeLatest(OptionsHandbookSessionsType.ADD_SESSIONS_TYPE_START, addSessionsTypeRecord)
	yield takeLatest(OptionsHandbookCategoryProject.ADD_CATEGORY_PROJECT_START, addCategoryProject)
	yield takeLatest(OptionsHandbookGenreProject.ADD_GENRE_PROJECT_START, addGenreProject)

	yield takeLatest(UsersHandbookDownloadTypes.UPLOAD_START, uploadExcel)
	yield takeLatest(UsersHandbookDownloadTypes.DOWNLOAD_START, downloadHandbook)
	yield takeLatest(UsersHandbookDownloadTypes.DOWNLOAD_TEMPLATE_START, downloadHandbookTemplate)

	yield takeLatest(UsersHandbookThematicsTypes.UPDATE_THEMATIC_START, updateThematic)
	yield takeLatest(UsersHandbookInternetTypes.UPDATE_INTERNET_START, updateInternet)
	yield takeLatest(UsersHandbookContentDeliveryTypes.UPDATE_CONTENT_DELIVERY_START, updateContentDelivery)
	yield takeLatest(UsersHandbookKPETypes.UPDATE_KPE_START, updateKPE)
	yield takeLatest(UsersHandbookContentDirectionTypes.UPDATE_CONTENT_DIRECTION_START, updateContentDirection)
	yield takeLatest(UsersHandbookUsersActionsTypes.UPDATE_USERS_ACTIONS_START, updateActionsUsers)
	yield takeLatest(UsersHandbookOrganizationTypes.UPDATE_ORGANIZATION_START, updateOrganization)
	yield takeLatest(UsersHandbookDocumentsTypes.UPDATE_DOCUMENTS_START, updateDocuments)
	yield takeLatest(OptionsHandbookRegions.UPDATE_REGIONS_START, updateRegions)
	yield takeLatest(OptionsHandbookRanks.UPDATE_RANKS_START, updateRanks)
	yield takeLatest(OptionsHandbookLegalForm.UPDATE_LEGAL_FORM_START, updateLegalFormOrganization)
	yield takeLatest(OptionsHandbookSessionsType.UPDATE_SESSIONS_TYPE_START, updateSessionsType)
	yield takeLatest(OptionsHandbookCategoryProject.UPDATE_CATEGORY_PROJECT_START, updateCategoryProject)
	yield takeLatest(OptionsHandbookGenreProject.UPDATE_GENRE_PROJECT_START, updateGenreProject)

	yield takeLatest(UsersHandbookThematicsTypes.DELETE_THEMATIC_START, deleteThematic)
	yield takeLatest(UsersHandbookInternetTypes.DELETE_INTERNET_START, deleteInternet)
	yield takeLatest(UsersHandbookContentDeliveryTypes.DELETE_CONTENT_DELIVERY_START, deleteContentDelivery)
	yield takeLatest(UsersHandbookKPETypes.DELETE_KPE_START, deleteKPE)
	yield takeLatest(UsersHandbookContentDirectionTypes.DELETE_CONTENT_DIRECTION_START, deleteContentDirection)
	yield takeLatest(UsersHandbookOrganizationTypes.DELETE_ORGANIZATION_START, deleteOrganization)
	yield takeLatest(UsersHandbookDocumentsTypes.DELETE_DOCUMENTS_START, deleteDocuments)
	yield takeLatest(OptionsHandbookRegions.DELETE_REGIONS_START, deleteRegionsRecord)
	yield takeLatest(OptionsHandbookRanks.DELETE_RANKS_START, deleteRanksRecord)
	yield takeLatest(OptionsHandbookLegalForm.DELETE_LEGAL_FORM_START, deleteLegalFormOrganizationRecord)
	yield takeLatest(OptionsHandbookSessionsType.DELETE_SESSIONS_TYPE_START, deleteSessionsTypeRecord)
	yield takeLatest(OptionsHandbookCategoryProject.DELETE_CATEGORY_PROJECT_START, deleteCategoryProject)
	yield takeLatest(OptionsHandbookGenreProject.DELETE_GENRE_PROJECT_START, deleteGenreProject)

	yield takeLatest(UsersHandbookThematicsTypes.BULK_THEMATICS_ACTIONS_START, bulkThematicsActions)
	yield takeLatest(UsersHandbookInternetTypes.BULK_INTERNET_ACTIONS_START, bulkInternetActions)
	yield takeLatest(UsersHandbookContentDeliveryTypes.BULK_CONTENT_DELIVERY_ACTIONS_START, bulkContentDeliveryActions)
	yield takeLatest(UsersHandbookKPETypes.BULK_KPE_ACTIONS_START, bulkKPEActions)
	yield takeLatest(UsersHandbookContentDirectionTypes.BULK_CONTENT_DIRECTION_ACTIONS_START, bulkContentDirectionActions)
	yield takeLatest(UsersHandbookOrganizationTypes.BULK_ORGANIZATION_ACTIONS_START, bulkOrganizationsActions)
	yield takeLatest(UsersHandbookDocumentsTypes.BULK_DOCUMENTS_ACTIONS_START, bulkDocumentsActions)
	yield takeLatest(OptionsHandbookRegions.BULK_REGIONS_ACTIONS_START, bulkRegionsActions)
	yield takeLatest(OptionsHandbookRanks.BULK_RANKS_ACTIONS_START, bulkRanksActions)
	yield takeLatest(OptionsHandbookLegalForm.BULK_LEGAL_FORM_ACTIONS_START, bulkLegalFormOrganizationActions)
	yield takeLatest(OptionsHandbookSessionsType.BULK_SESSIONS_TYPE_ACTIONS_START, bulkSessionsTypeActions)
	yield takeLatest(OptionsHandbookCategoryProject.BULK_CATEGORY_PROJECT_ACTIONS_START, bulkCategoryProjectActions)
	yield takeLatest(OptionsHandbookGenreProject.BULK_GENRE_PROJECT_ACTIONS_START, bulkGenreProjectActions)

	yield takeLatest(OptionsGeneralActionTypes.GET_CONTENT_MODULE_SECTION_START, getContentModuleSection)

}
