const customization = [
	{
		id: '86',
		selector: '.customizationPage',
		content:
			'Функционал страницы позволяет управлять подпиской на телеграм-бота и следить за состоянием подтверждений ваших актуальных email / телефонов',
		role: ['ROLE_CONTRACTOR','ROLE_ADMIN','ROLE_PRODUCER','ROLE_COORDINATOR','ROLE_ANALYST','ROLE_FINANCIER','ROLE_LAWYER','ROLE_MODERATOR','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS', 'ROLE_CONTENT_EDITOR']
	},
	{
		id: '87',
		selector: '.statusPersonalSettingForm',
		content:
			'Боковое меню позволяет перейти в настройки подтверждений и подписок, персональное управление рассылками уведомлений',
		role: ['ROLE_CONTRACTOR','ROLE_ADMIN','ROLE_PRODUCER','ROLE_COORDINATOR','ROLE_ANALYST','ROLE_FINANCIER','ROLE_LAWYER','ROLE_MODERATOR','ROLE_PROJECT_MANAGER','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS', 'ROLE_CONTENT_EDITOR']
	},
	{
		id: '88',
		selector: '.customizationPage',
		content:'Функционал страницы позволяет следить за состоянием подтверждений ваших актуальных email / телефонов',
		role: ['ROLE_VIEWER','ROLE_CLIENT', 'ROLE_AGENT'],
	}
]

export default customization
