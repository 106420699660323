import {z} from "zod";

export const FilterSchema = z.object({
    id: z.string(),
    name: z.string(),
    filterTypeId: z.string(),
    filterData: z.string()//будет приходить строка JSON
})

export type Filter = z.infer<typeof FilterSchema>;

const TypesCodeEnums = z.enum([
    'PROJECTS',
    'PROJECTS_CALENDAR',
    'PROJECTS_CONTENT',
    'PROJECTS_KANBAN',
    'TASKS',
    'TASKS_RECORDS',
    'REACH_TASK',
    'POSTING_TASK',
    'ESTIMATE_TASK',
    'COPYRIGHT_TASK',
    'CONTENT_TASK',
    'USERS_SEARCH',
])
export type TypesCode = z.infer<typeof TypesCodeEnums>
export const TypesSchema = z.object({
    id: z.string(),
    name: z.string(),
    code: TypesCodeEnums,
})
export type Types = z.infer<typeof TypesSchema>