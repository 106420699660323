import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import './confirm-page.less'
//components
import Cookie from 'components/cookie'
//utils
import {dateParse} from 'utils/dateParse'
//selectors
import {
	selectAuthStatus,
	selectIsLogin,
	selectUserConfirmAgreement,
	selectUserConfirmAllEmail,
} from 'store/auth/selectors'
//images
import logo from 'assets/svg/new-logo.svg'
import img from 'assets/svg/confirm-data.svg'
import imgReceiving from 'assets/svg/receiving.svg'
//antd
import {Button, Checkbox, Col, Layout, Row, Spin, Tooltip} from 'antd'
import {ArrowRightOutlined} from '@ant-design/icons'
import UsersService from 'services/usersService'
import {GetCurrentUser} from 'store/auth/actions'
import ConfirmMailComponent from "./confirm-email";
import env from "shared/config/env";

const { Header, Footer, Content } = Layout

//types
type IState = 'confirm-agreement' | 'confirm-receiving'

const ConfirmPage = () => {
	const history = useHistory();
	const put = useDispatch()
	const { isLoading } = useSelector(selectAuthStatus)
	const isLogin = useSelector(selectIsLogin)
	const confirmUserAgreement = useSelector(selectUserConfirmAgreement)
	const confirmEmailAll = useSelector(selectUserConfirmAllEmail)

	const [state, setState] = useState<IState>('confirm-agreement')
	const [confirmAgreement, setConfirmAgreement] = useState<boolean>(false)
	const [confirmReceiving, setConfirmReceiving] = useState<boolean>(false)

	useEffect(()=>{
		if (!isLogin) {
			localStorage.removeItem('path')
			history.push('/')
		}// eslint-disable-next-line
	},[isLogin])

	const sendConfirmReceiving = async () => {
		await UsersService.confirmReceiving()
		put(GetCurrentUser())
		history.push('/')
	}

	useEffect(() => {
		if (confirmUserAgreement) setState('confirm-receiving')
	}, [confirmUserAgreement])

	const handler = (_e: any) => {
		if (state === 'confirm-agreement' && confirmAgreement) {
			setState('confirm-receiving')
			UsersService.confirmAgreement()
		}
		if (state === 'confirm-receiving' && confirmReceiving) {
			sendConfirmReceiving()
		}
	}

	const handlerConfirmAgreement = (e: any) => {
		setConfirmAgreement(e.target.checked)
	}

	const handlerConfirmReceiving = (e: any) => {
		setConfirmReceiving(e.target.checked)
	}

	return (
		<Spin spinning={isLoading}>
			<Layout className="confirmPage">
				<Header className="confirmPage__header">
					<Link to="/" className="confirmPage__header-logo">
						<img src={logo} alt="logo" />
					</Link>
					<div>
						<a className="confirmPage__header-link" rel="noreferrer" href="https://ири.рф" target="_blank">
							<span>
								Переход <br /> на сайт “ИРИ”
							</span>
							<ArrowRightOutlined />
						</a>
					</div>
				</Header>
				<Content className="confirmPage__content">
					<Row>
						<Col>
							<h1>
								Личный кабинет <br />
								для работы с проектами АНО “ИРИ”
							</h1>
							{state === 'confirm-receiving' && (
								<div className="confirm-receiving">
									<Checkbox onChange={handlerConfirmReceiving}>
										Я согласен получать информацию от АНО ИРИ по SMS / электронной почте и осведомлен, что могу отказаться от рассылки в персональных настройках личного кабинета
									</Checkbox>
								</div>
							)}
						</Col>
					</Row>
					<Row>
						<Col lg={12} md={15} sm={24}>
							{state === 'confirm-agreement' && (
								<div className="confirm-data">
									<Checkbox onChange={handlerConfirmAgreement}/>
									<span className="confirm-data--paragraph">
										Я даю свое согласие на обработку моих персональных данных, соглашаюсь с{' '}
										<a href={'/confirm/agreement.pdf'} download className="confirm-data--text">
										{' '}
											пользовательским соглашением
									</a>{' '}
										и
									<a href={'/confirm/policy.pdf'} download className="confirm-data--text">
										{' '}
										политикой конфиденциальности
									</a>
									, ознакомлен с{' '}
										<a href={'/confirm/personal-policy.pdf'} download className="confirm-data--text">
										политикой в отношении обработки персональных данных{' '}
									</a>
									</span>
								</div>
							)}
							{state === 'confirm-receiving' && (
								<div className="confirm-receiving">
									<p>Подтвердите ваши  основные контактные данные для получения уведомлений:</p>
									<div className="confirm-receiving__emails">Email пользователя</div>
									<ConfirmMailComponent disabled={confirmReceiving}/>
								</div>
							)}
								<div className="confirmPage__logout__links">
									<Tooltip title={'Вернуться к авторизации'}>

									<Button
										type="link"
										className="confirmPage__logout"
										href={env.keycloackRedirectUrl || '/'}
									>
										Вернуться назад
									</Button>
									</Tooltip>

									<div className="link--support">
										<a href={`https://tttttt.me/ANO_IRI_Support_bot`} rel="noreferrer" target="_blank">
											Связаться с поддержкой
										</a>
									</div>
								</div>

						</Col>
						{window.innerWidth >= 766 && (
							<Col lg={12} md={9}>
								<img src={state === 'confirm-agreement' ? img : imgReceiving} alt="confirm page" />
							</Col>
						)}
					</Row>
				</Content>
				<Footer className="confirmPage__footer">
					{state === 'confirm-agreement' ? (
						<>
							{confirmAgreement ? (
								<div className="link" onClick={handler}>
									Далее
								</div>
							) : (
								<div className="link" />
							)}
						</>
					) : (
						<>
							{confirmEmailAll && confirmReceiving ? (
								<div className="link" onClick={handler}>
									Далее
								</div>
							) : (
								<div className="link" />
							)}
						</>
					)}

					<span>
						2014-{dateParse().year}© Автономная некоммерческая организация <br />
						«Институт развития интернета» (ИРИ).
					</span>
				</Footer>
				<Cookie />
			</Layout>
		</Spin>
	)
}

export default ConfirmPage
