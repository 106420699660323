export default function CountChunk(file: number): number {
    const chunkSize50mb = 50 * 1024 * 1024;
    const chunkSize200mb = 200 * 1024 * 1024;
    const chunkSize1gb = 1000 * 1024 * 1024;

    if (file >= chunkSize1gb) return chunkSize200mb
    if (file < chunkSize1gb && file >= chunkSize200mb) {
        return file / 100 * 20
    }

    return chunkSize50mb
}