import React from 'react';
import Contest from "./contest";
import Years from "./years";
import Session from "./session";



export const ContestsItem = () => {

    return (
        <div className="item-wrapper">
            <Contest/>
            <Years/>
            <Session/>
        </div>
    )
}