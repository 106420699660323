import React, { useEffect, useState } from 'react'
import styles from './styles.module.less'
import { Empty, Image, Modal, Spin, Tooltip, Upload, UploadProps } from 'antd'
import { NotificationOpen } from 'store/notification/actions'
import { useDispatch } from 'react-redux'
import { DeleteOutlined, FullscreenExitOutlined, PlusOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/es/upload/interface'
import { FileType } from 'models/common'
import classNames from 'classnames'
import env from 'shared/config/env'
import TooltipImage from 'shared/ui/tooltipImage'
import { getBase64Async } from 'utils/getBase64'
import { TaskTypes } from 'entities/task/index'
import { ModalEvent } from 'shared/ui/modal'
import Preview from './preview'

const formats = ['PNG', 'JPEG', 'RAW', 'BMP', 'JPG']
const MAX_FILE_SIZE = 5 * 1024 * 1024 // Максимальный размер файла в МБ

const initState = {
	open: false,
	image: '',
	title: '',
}
type EditProps = {
	onlyView?: never
	files: FileType[] | []
	isEdit: boolean
	tooltip: string
	limit: number
	limitShow: number
	record: TaskTypes.ReachRecords
	columnKey: 'screenshots' | 'counterScreenshots'
	handlerSetImages?: Function
}
type ViewProps = {
	onlyView: boolean
	files: FileType[] | []
	isEdit?: never
	tooltip?: never
	limit: number
	limitShow: number
	record: TaskTypes.ReachRecords
	columnKey: 'screenshots' | 'counterScreenshots'
	handlerSetImages?: never
}
type Props = EditProps | ViewProps

const ImageListEditModalCell = ({ isEdit, tooltip, files, limit, limitShow, handlerSetImages, record, columnKey }: Props) => {
	const put = useDispatch()
	const [preview, setPreview] = useState(initState)
	const [fileList, setFileList] = useState<UploadFile[]>([])
	const [showAll, setShowAll] = useState(!!isEdit)

	useEffect(() => {
		if (files?.length) {
			const images = files.map(
				(el) =>
					({
						name: el.name,
						status: 'uploading',
						uid: el.id,
						url: `${env.API_ENDPOINT}/files/${el.scaledId || el.id}`,
					} as UploadFile)
			)
			setFileList(images)
		}
	}, [files, isEdit])

	const onDelete = (file: UploadFile) => (_e: any) => {
		const list = fileList?.filter((it) => it.url !== file.url)
		setFileList(list)
		handlerSetImages && handlerSetImages(list)
	}
	const handleCancel = () => setPreview(initState)
	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			const image = await getBase64Async(file.originFileObj)
			if (typeof image === 'string') {
				file.preview = image
			}
		}
		setPreview({
			open: true,
			image: file.url || (file.preview as string),
			title: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
		})
	}
	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		if (fileList.length < newFileList.length) {
			const newFile = newFileList[newFileList.length - 1]
			try {
				const type = newFile.type?.split('/')[0]
				const format = newFile.type?.split('/')[1]
				if (type !== 'image') {
					put(NotificationOpen('error', 'Ошибка', 'Файл не является изображением'))
					return
				}
				if (format && !formats.includes(format.toUpperCase())) {
					put(
						NotificationOpen(
							'error',
							'Не допустимое разрешение файла',
							'Разрешенный формат для загрузки - PNG, JPEG, RAW, BMP, JPG.'
						)
					)
					return
				}
			} catch (_error) {
				return false
			}
		}

		setFileList(newFileList)
		handlerSetImages && handlerSetImages(newFileList)
	}
	const validateUpload = (file: File) => {
		if (file.size > MAX_FILE_SIZE) {
			put(NotificationOpen('error', 'Ошибка', 'Размер изображения не должен превышать 5мб'))
			return Upload.LIST_IGNORE
		}
		return false
	}

	const uploadButton = (
		<Tooltip title={isEdit ? tooltip : ''}>
			<div>
				<PlusOutlined />
				<div>Загрузить</div>
			</div>
		</Tooltip>
	)
	const list = (fileList as UploadFile[]).filter((_el, index) => showAll || index < limitShow)
	const countHide = fileList.length - limitShow
	return (
		<div className={classNames(styles.container, { canEdit: isEdit })}>
			<Upload
				beforeUpload={validateUpload}
				listType="picture-card"
				onPreview={handlePreview}
				fileList={list as UploadFile[]}
				onChange={isEdit ? handleChange : undefined}
				itemRender={(originNode, file) => {
					const isHaveThumbUrl = 'thumbUrl' in file
					if (isEdit && isHaveThumbUrl) {
						return originNode
					} else {
						return (
							<TooltipImage src={(file.url as string) || ''}>
								<ImageComponent
									file={file}
									files={files}
									record={record}
									columnKey={columnKey}
								/>
								{isEdit && <DeleteOutlined onClick={onDelete(file)} />}
							</TooltipImage>
						)
					}
				}}
			>
				{isEdit && fileList.length < limit ? uploadButton : null}
			</Upload>
			{!!fileList.length && countHide > 0 && !showAll && (
				<Tooltip title={'Показать все'}>
					<div className={styles.limit} onClick={() => setShowAll(true)}>
						+{countHide}
					</div>
				</Tooltip>
			)}
			{!isEdit && showAll && (
				<Tooltip title={`Показать ${limitShow}`}>
					<div className={styles.limit} onClick={() => setShowAll(false)}>
						<FullscreenExitOutlined />
					</div>
				</Tooltip>
			)}
			<Modal visible={preview.open} title={preview.title} footer={null} onCancel={handleCancel}>
				<img alt="example" style={{ width: '100%' }} src={preview.image} />
			</Modal>
		</div>
	)
}

type ImageComponentProps = {
	file: UploadFile;
	files: FileType[] | []
	record: TaskTypes.ReachRecords
	columnKey: 'screenshots' | 'counterScreenshots'
}
function ImageComponent({ file, files, record, columnKey }: ImageComponentProps) {
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	const onLoadHandler = () => {
		setLoading(false)
	}
	const onErrorHandler = () => {
		setError(true)
	}
	const handlerOpenModal = () => {
		const title = columnKey === 'screenshots' ?
			`Просмотр скриншотов на фиксацию охватов записи "${record?.workingName}. ${record.publishedName}"` :
			`Просмотр скриншотов счетчиков записи "${record?.workingName}. ${record.publishedName}"`

		ModalEvent.open({
			title: (
				<span className={styles.modal_title}>
					{title}
				</span>
			),
			width: 1200,
			destroyOnClose: true,
			component: <Preview record={record} files={files}/>,
		})
	}
	if (error) {
		return <Empty className={styles.error} description={'Не удалось загрузить изображение'} />
	}

	return (
		<Spin spinning={loading}>
			<Image
				onClick={handlerOpenModal}
				src={file.url}
				alt={file?.name}
				onLoad={onLoadHandler}
				onError={onErrorHandler}
				preview={false}
				style={{ cursor: 'pointer' }}
			/>
		</Spin>
	)
}
export default ImageListEditModalCell
