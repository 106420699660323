import React, {FC} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {ReportsSchedulers, ScheduleCreate} from '../../types'
import {ModalEvent} from 'shared/ui/modal'
import {ReportSchedulerController} from 'features/report-auto-sending-schedule/api'
import {Form, Formik, FormikProps} from 'formik'
import styles from './styles.module.less'
import Reports from './reports'
import {IOptions} from 'models/feels'
import RadioButtons from './radio-buttons'
import Users from './users'
import Dates from './dates'
import ProjectsSessions from './projects-sessions'
import Time from './time'
import {Button} from 'antd'
import {validation} from './validation'
import Format from './format'
import {InitialValueDto} from '../../lib/dto'

export type TForm = {
	reports: IOptions[]
	users: IOptions[]
	sessions: IOptions[]
	projects: IOptions[]
	time: any
	radioButtons: 'ALL' | 'SESSIONS' | 'PROJECTS'
	disabledSessions: boolean
	disabledProjects: boolean
} & Omit<ScheduleCreate, 'reportTemplateIds' | 'userIds'>

type TModalScheduler = {
	data?: ReportsSchedulers
	mode: 'create' | 'update'
}
const ModalScheduler: FC<TModalScheduler> = ({ data: dataSchedule, mode }) => {
	const queryClient = useQueryClient()

	const { mutate: create, isLoading: isLoadingCreate } = useMutation({
		mutationFn: (data: ScheduleCreate) => ReportSchedulerController.createSchedulers(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries([ReportSchedulerController.search.key])
			ModalEvent.close()
		},
	})

	const { mutate: update, isLoading: isLoadingUpdate } = useMutation({
		mutationFn: (data: ScheduleCreate) => ReportSchedulerController.updateSchedulers(data, dataSchedule?.id || ''),
		onSuccess: async () => {
			await queryClient.invalidateQueries([ReportSchedulerController.search.key])
			ModalEvent.close()
		},
	})

	const initValues: InitialValueDto = new InitialValueDto(dataSchedule)
	const close = () => {
		ModalEvent.close()
	}
	const onSubmit = (data: InitialValueDto) => {
		const sessionIds = data.sessions.length ? data.sessions.map((el) => el.value) : undefined
		const projectIds = data.projects.length ? data.projects.map((el) => el.value) : undefined

		const inputData = {
			projectIds,
			sessionIds,
		}

		const values: ScheduleCreate = {
			...(mode === 'create' && {reportTemplateIds: data.reports?.map((el) => el.value)}),
			userIds: data.users?.map((el) => el.value),
			inputData: inputData,
			firstFormationDate: data.firstFormationDate,
			lastFormationDate: data.lastFormationDate,
			format: 'XLSX',
			formationTime: data.time,
		}
		if (mode === 'create') create(values)
		if (mode === 'update') update(values)
	}

	return (
		<Formik
			enableReinitialize
			initialValues={initValues}
			validationSchema={validation}
			onSubmit={onSubmit}
			validateOnMount={true}
		>
			{(formik: FormikProps<InitialValueDto>) => {
				return (
					<Form className={styles.container}>
						<div className={styles.header}>
							<span className={styles.title}>Настройка автоформирования и отправки отчетов</span>
						</div>
						<hr />
						{mode === 'create' && <Reports />}
						<Users />
						<RadioButtons />
						<ProjectsSessions />
						<Dates />
						<Time />
						<Format />
						<div className={styles.buttons}>
							<Button type={'default'} onClick={close}>
								Отменить
							</Button>
							<Button
								htmlType={'submit'}
								disabled={!formik.isValid}
								type={'primary'}
								loading={isLoadingCreate || isLoadingUpdate}
							>
								Сохранить
							</Button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}

export default ModalScheduler
