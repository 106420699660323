import { z } from 'zod';
import { FilterRequest, Id_Name_Code_Hidden_Schema, SearchResponse } from 'shared/types/common';

export const TagSchema = Id_Name_Code_Hidden_Schema.extend({
    level: z.number(),
    isForNationalProject: z.boolean(),
    isAssignment: z.boolean(),
});

export type Tag = z.infer<typeof TagSchema>;

export type ProjectTagRequest = {
    code: string | null,
    name: string,
    level: number,
    isForNationalProject: boolean,
    isAssignment: boolean,
    hidden: boolean
}

export type FilterData = {
    searchString?: string;
    hidden?: boolean;
    level?: number,
    isForNationalProject?: boolean,
    isAssignment?: boolean
};

export type ProjectTags = SearchResponse<Tag>;
export type Filter = FilterRequest<FilterData>
