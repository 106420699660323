import {GetCalendarValue} from "../store/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {delay, put, select} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import {UserTypes} from "entities/user";
import {selectRole} from "store/auth/selectors";
import { ExtendedSearchModel } from "features/extended-search";
import { SearchContentProjectModel } from "features/search-project-content";


const rolesForSearchCase: UserTypes.RoleName[] = ['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_CLIENT'];

export function* getDataWorker(_: ReturnType<typeof GetCalendarValue>) {
    try {
        const role: UserTypes.RoleName = yield select(selectRole);
        if (!role) {
            yield delay(500)
            yield put(GetCalendarValue())
        }
        if (rolesForSearchCase.includes(role)) {
            yield put(SearchContentProjectModel.actions.FetchCF('calendar'))
        } else {
            yield put(ExtendedSearchModel.actions.FetchCF('calendar'))
        }
    } catch (error) {
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
    }
}