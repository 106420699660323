import {put, select} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {GetProjectsSuccessAction, SetProjectFilter, SetProjectInfo} from "store/projects/actions";
import {selectProjectsFilter} from "store/projects/selectors";
import ProjectService from "entities/project/api/project";
import {ProjectTypes} from "entities/project";
import {ExtendedSearchLib, ExtendedSearchTypes} from "features/extended-search";
import {TProjectsFilter, TProjectsFilterExtendedSearch} from "models/projects/filter";
import ZodParser from "shared/services/zod-parser";

export function* ProjectsFunction(filterES: ExtendedSearchTypes.Stores.TAllFilterValues) {
    yield put(SetProjectInfo({isLoading: true, isLoaded: false}))
    const filterMain: TProjectsFilter = yield select(selectProjectsFilter);
    const {filterData, ...rest} = filterMain;

    const filter = {
        ...rest,
        filterData: {
            ...(!!filterData?.bulkAction?.length && { bulkAction: filterData.bulkAction}),
            ...(!!filterData?.searchString && { searchString: filterData.searchString}),
            ...ExtendedSearchLib.mapData(filterES),
        }
    } as TProjectsFilterExtendedSearch;
    const {data: projects}: AxiosResponse<ProjectTypes.ProjectsListResponse> = yield ProjectService.getProjectsExtendedSearch(filter)
    ZodParser.parse(ProjectTypes.ProjectsListSchema.array(), projects.content, 'ProjectsFunction')

    if (projects.page * projects.pageSize >= projects.totalCount && projects.totalCount !== 0) {
        yield put(SetProjectFilter({...filterMain, page: 1}))
    } else {
        yield put(GetProjectsSuccessAction(projects))
    }
}