import {put} from 'redux-saga/effects'
import HandbookService from "services/handbookService";
import {
    BulkDocumentsActionsErrorAction,
    BulkDocumentsActionsStartAction,
    BulkDocumentsActionsSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";

export function* bulkDocumentsActions({ userArr, action }: ReturnType<typeof BulkDocumentsActionsStartAction>) {
	try {
		yield HandbookService.bulkDocumentsActions(userArr, action)

		yield put(BulkDocumentsActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.documents))
		yield put(NotificationOpen('success', 'Обновлено успешно'))

	} catch (error: any) {
		yield put(BulkDocumentsActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}
}
