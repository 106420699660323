import {put} from 'redux-saga/effects'
import {NotificationOpen} from '../../../store/notification/actions'
import CreateErrorMessage from '../../../utils/validation/createErrorMessage'
import {AxiosResponse} from 'axios'
import {downloadXLXS} from '../../../utils/downloadXLXS'
import {
	DownloadReportTemplateErrorAction,
	DownloadReportTemplateStartAction, DownloadReportTemplateSuccessAction
} from "../../../store/tasks/actions/report";
import {ReportType} from "../../../models/task/types";
import {
	TasksReportCopyrightService,
	TasksReportEstimateService,
	TasksReportPostingService,
	TasksReportReachService
} from "../../../services/tasksReportService";

export function* downloadTemplate({ reportType }: ReturnType<typeof DownloadReportTemplateStartAction>) {
	try {
		if (reportType === ReportType.CONTENT_POSTING_REPORT) {
			const {data}: AxiosResponse<string> = yield TasksReportPostingService.downloadTemplate()
			downloadXLXS(data, `шаблон размещении контента`)

		}
		if (reportType === ReportType.CONTENT_REACH_REPORT) {
			const {data}: AxiosResponse<string> = yield TasksReportReachService.downloadTemplate()
			downloadXLXS(data, `шаблон охвата размещённого контента`)

		}
		if (reportType === ReportType.COPYRIGHT_LIST) {
			const {data}: AxiosResponse<string> = yield TasksReportCopyrightService.downloadTemplate()
			downloadXLXS(data, `шаблон списка объектов авторского права`)

		}
		if (reportType === ReportType.PROJECT_ESTIMATE) {
			const {data}: AxiosResponse<string> = yield TasksReportEstimateService.downloadTemplate()
			downloadXLXS(data, `шаблон сметы проекта`)
		}
		yield put(DownloadReportTemplateSuccessAction())
	} catch (error: any) {
		yield put(DownloadReportTemplateErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось скачать шаблон'))
	}
}

