import * as Yup from "yup";
import ValidateObj from "utils/validation/object";
import ValidateString from "utils/validation/string";
import {IOptions} from "models/feels";

const validationStepFirst = Yup.object({
    name: Yup.object({
        value: Yup.string().required('Название задачи обязательно для заполнения'),
    }),
    typeProject: Yup.object({
        value: Yup.string().required('Поле тип задачи обязательно для заполнения'),
    }),
    sprint: Yup.object({
        value: Yup.mixed().test('Этап', 'Выберите этап', ValidateObj).required('Обязательное поле этап'),
    }),
    project: Yup.object({
        value: Yup.mixed()
            .test('executor', 'Поле проект не валидно.', ValidateObj)
            .required('Поле проект обязательно для заполнения'),
    }),
    archival: Yup.object({
        value: Yup.string().required('Поле формат задачи обязательно для заполнения'),
    }),
    deadline: Yup.object({
        value: Yup.mixed()
            .test(
                'deadline',
                'Поле «Срок исполнения» обязательно для заполнения',
                function (value: string | Date) {
                    return !!value
                }
            ),
    }),
})
const validationStepSecond = Yup.object({
    description: Yup.object({
        value: Yup.mixed()
            .test('description', 'Максимальное значение символов 5000', ValidateString(5000)),
    }),
    contents: Yup.object({
        value: Yup.mixed()
            .test('description', 'Поле единица контента не валидно', function (
                val: null | Array<{contentCount: number | null, contentFormatType?: IOptions, isContent?: boolean}>
            ) {
                let isValid = true;
                // @ts-ignore
                if(this.options.from[2].value.stepFirst.typeProject?.isReport) return isValid
                    if (Array.isArray(val) && val.length > 0) {
                    let length = val.length;

                    do {
                        if (!val[length - 1]?.contentCount) isValid = false;
                        if (!val[length - 1]?.contentFormatType?.value) isValid = false;
                        if (!val[length - 1]?.isContent) isValid = true
                        length--;
                    } while (isValid && length !== 0);
                }

                return isValid
            }),
    }),
})
const validationStepThird = Yup.object({

})

const validationSchema = Yup.object({
    stepFirst: validationStepFirst,
    stepSecond: validationStepSecond,
    stepThird: validationStepThird
});

export default validationSchema