import { ExtendedSearchTypes } from "features/extended-search";
import {ActionTypes} from "./types";


export const setOrganizations = <T extends Partial<ExtendedSearchTypes.Stores.TOrganizationsState>>(
    typeContent: ExtendedSearchTypes.Stores.TTypeStore, value: T
) => ({
    type: ActionTypes.SET_VALUE, typeContent, value
})
export const setValuesOrganizations = <T extends Partial<Omit<ExtendedSearchTypes.Stores.Organizations, 'isToggled'>>>(
    typeContent: ExtendedSearchTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUES, typeContent, value, isToggled
})
export const clearOrganizations = (typeContent: ExtendedSearchTypes.Stores.TTypeStore) => ({
    type: ActionTypes.CLEAR_VALUE, typeContent
})