import { put } from 'redux-saga/effects'
import { GetHandbookStartAction, SetHandbookFilter } from 'store/options/actions/actions-handbook'
import { TStatusesHandbookFeelsForFilter } from 'models/handbook'

type TStatusString = {
	[key: string]: TStatusesHandbookFeelsForFilter
}

const StatusString: TStatusString = {
	'filterThematic': TStatusesHandbookFeelsForFilter.filterThematic,
	'filterInternet': TStatusesHandbookFeelsForFilter.filterInternet,
	'filterContentDelivery': TStatusesHandbookFeelsForFilter.filterContentDelivery,
	'filterActionsUsers': TStatusesHandbookFeelsForFilter.filterActionsUsers,
	'filterKPE': TStatusesHandbookFeelsForFilter.filterKPE,
	'filterContentDirection': TStatusesHandbookFeelsForFilter.filterContentDirection,
	'filterOrganization': TStatusesHandbookFeelsForFilter.filterOrganization,
	'filterDocuments': TStatusesHandbookFeelsForFilter.filterDocuments,
	'filterRegions': TStatusesHandbookFeelsForFilter.filterRegions,
	'filterRanks': TStatusesHandbookFeelsForFilter.filterRanks,
	'filterLegalForm': TStatusesHandbookFeelsForFilter.filterLegalForm,
	'filterSessionsType': TStatusesHandbookFeelsForFilter.filterSessionsType,
	'filterCategoryProject': TStatusesHandbookFeelsForFilter.filterCategoryProject,
	'filterGenreProject': TStatusesHandbookFeelsForFilter.filterGenreProject,
}

export function* setHandbookFilter({ filter }: ReturnType<typeof SetHandbookFilter>) {
	try {
		const filterName = Object.keys(filter)[0]
		yield put(GetHandbookStartAction(StatusString[filterName]))
	} catch (error: any) {
		console.log(error)
	}
}
