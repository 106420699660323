import {
    ImportTemplateUsersErrorAction,
    ImportTemplateUsersStartAction,
    ImportTemplateUsersSuccessAction,
} from "store/options/actions/actions-users";
import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import UsersService from "services/usersService";
import {downloadXLXS} from "utils/downloadXLXS";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";

export default function* importTemplateUsers(_a: ReturnType<typeof ImportTemplateUsersStartAction>) {
    try {
        const { data: response }: AxiosResponse<any> = yield UsersService.usersImportTemplate()
        yield put(ImportTemplateUsersSuccessAction())

        downloadXLXS(response, 'шаблон пользователей')
        yield put(NotificationOpen('success', 'Шаблон выгружен успешно'))

    } catch (error: any) {
        yield put(ImportTemplateUsersErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось выгрузить'));
    }
}