import React, {FC} from 'react';
import {Radio, RadioChangeEvent} from "antd";

type Props = {
    handler: Function
}
const SortingRadio: FC<Props> = ({handler}) => {
    const handlerRadio = (e: RadioChangeEvent) => {
        const value = e.target.value
        handler(value)
    }

    return (
        <Radio.Group name="radiogroup" onChange={handlerRadio}>
            <Radio value={undefined}>По умолчанию (по возрастанию даты публикации)</Radio>
            <Radio value="ASC">По возрастанию названия ед. контента</Radio>
            <Radio value="DESC">По убыванию названия ед. контента</Radio>
        </Radio.Group>
    );
};

export default SortingRadio;