import {z} from "zod";
import {UserTypes} from 'entities/user'

const MassUpdateUserSchema = z.object({
    userId: z.string(),
    isTutor: z.boolean(),
    tutorVisible: z.boolean()
})

export type MassUpdateUser = z.infer<typeof MassUpdateUserSchema>;

export const MassUpdateUsersSchema = z.object({
    projectIds: z.array(z.string()),
    addProjectUsers: z.array(MassUpdateUserSchema),
    deleteProjectUsers: z.array(MassUpdateUserSchema),
})
export type MassUpdateUsers = z.infer<typeof MassUpdateUsersSchema>;

export const ProjectUserSchema = z.object({
    id: z.string(),
    lastName: z.string(),
    firstName: z.string(),
    middleName: z.string(),
    role: UserTypes.RoleSchema,
    leader: z.boolean(),
    status: z.enum(['ARCHIVE', 'BLOCKED', 'ACTIVE'])
})

export type ProjectUser = z.infer<typeof ProjectUserSchema>;

export type MapProjectUsers =  Map<UserTypes.RoleName, ProjectUser[]>
