import {TOption} from "models/projects/project-form";
import {TDefault} from "./project-form.dto";
import statusValidateRules from "utils/validation/status-validate-rules";
import {IOptions} from "models/feels";
import {ProjectTypes} from "entities/project";
import {TInfoProject} from "../../ui/form-container";
import { UserTypes } from "entities/user";


export class StepFirstDto {
    isPrivateProject: TDefault<boolean>
    name: TDefault<string>
    projectType: TDefault<IOptions<ProjectTypes.Type['code']> | null>
    requestNumber: TDefault<string>
    budget: TDefault<number>
    promotionalBudget: TDefault<number>
    contractNumber: TDefault<string>
    contractDate: TDefault<string | Date>
    agreementDate : TDefault<string | Date>
    advancePayment: TDefault<boolean>
    igk: TDefault<string>
    session: TDefault<SessionDto>
    goal: TDefault<string | null>
    description: TDefault<string | null>
    status: TDefault<IOptions<ProjectTypes.Project['status']['code']> | null>
    completionDate: TDefault<string | Date>

    statusDisabledOptions: string[]

    constructor(project?: ProjectTypes.Project | null) {
        this.isPrivateProject = project ? {value: project.isPrivateProject} : {value: false};
        this.name = project ? {value: project.name} : {value: ''};
        this.projectType = project?.type ? {
            value: {
                value: project.type.id,
                label: project.type.name,
                code: project.type.code
            }
        } : {value: null};
        this.contractNumber = project?.contractNumber ? {value: project.contractNumber} : {value: ''};
        this.requestNumber = project ? {value: project.requestNumber} : {value: ''};
        this.advancePayment = project ? {value: project.advancePayment} : {value: false};
        this.contractDate = project?.contractDate ? {value: project.contractDate} : {value: ''};
        this.agreementDate = project?.agreementDate ? {value: project.agreementDate} : {value: ''};
        this.igk = project?.igk ? {value: project.igk} : {value: ''};
        this.budget = project?.budget ? {value: project.budget} : {value: 0};
        this.promotionalBudget = project?.promotionalBudget ? {value: project.promotionalBudget} : {value: 0};
        this.completionDate = project?.completionDate ? {value: project.completionDate} : {value: ''};
        this.session = {value: new SessionDto(project?.session)};
        this.goal = project ? {value: project.goal} : {value: null};
        this.description = project ? {value: project.description ?? ''} : {value: null};
        this.status = project ? {
            value: {
                value: project.status.id,
                label: project.status.name,
                code: project.status.code
            }
        } : {value: null};

        this.statusDisabledOptions = []
    }

    validate(info: TInfoProject) {
        for (const key in this) {
            // @ts-ignore
            this[key].isDisabled = validate(key, info)
        }
        return this;
    }

    validateStatus(info?: TInfoProject) {
        if (!info) {
            this.statusDisabledOptions = ['DRAFT', 'ACTUAL']
        } else {
            this.statusDisabledOptions = statusValidateRules(info?.status)
        }
        return this
    }
}

export class SessionDto {
    session: TOption | null
    contest: TOption | null
    year: TOption | null

    constructor(el?: ProjectTypes.Project["session"]) {
        this.session = el ? {
            label: `${el.sessionNumber} ${el.sessionType?.name} ${el.year} ${el.contest.code}`,
            value: el.id} as TOption : null;
        this.contest = el ? {label: el.contest.code, value: el.contest.id} as TOption : null;
        this.year = el ? {label: `${el.year}`, value: `${el.year}`} as TOption : null
    }
}

function validate(field: string, info: TInfoProject): boolean  {
    let disabled = false;
    if (!info) return disabled
    const {role, status} = info;
    if (field === 'isPrivateProject') {
        disabled = true;
        if (([
            'ROLE_ADMIN_IS',
            'ROLE_ADMIN',
            'ROLE_MODERATOR',
            'ROLE_PROJECT_MANAGER',
            'ROLE_COORDINATOR',
            'ROLE_PRODUCER'
        ] as UserTypes.RoleName[]).includes(role)) {
            disabled = status === 'COMPLETED' || status === 'SUSPENDED';
        }
        return disabled;
    }
    if (role === 'ROLE_CONTRACTOR') disabled = true
    if ((role === 'ROLE_ADMIN') || (role ==='ROLE_ADMIN_IS')) {
        if (status === 'DRAFT') disabled = false;
        else if (status === 'SUSPENDED' || status === 'COMPLETED') disabled = true
        else if (status === 'ACTUAL'|| status === 'ARCHIVED') disabled = false
    }
    if ((role === 'ROLE_COORDINATOR') || (role ==='ROLE_MODERATOR') || (role === 'ROLE_PROJECT_MANAGER')) {
        if (status === 'DRAFT') disabled = false;
        else if (status === 'SUSPENDED' || status === 'COMPLETED') disabled = true
        else if (status === 'ACTUAL'|| status === 'ARCHIVED') disabled = false
    }
    if ((role === 'ROLE_PRODUCER')) {
        if (status === 'DRAFT') disabled = true
        else if (status === 'SUSPENDED' || status === 'COMPLETED') disabled = true
        else if (status === 'ACTUAL' || status === 'ARCHIVED') disabled = false
    }

    if ((role === 'ROLE_ANALYST') || (role === 'ROLE_FINANCIER') || (role === 'ROLE_LAWYER') || role === 'ROLE_CONTRACTOR' || role === 'ROLE_CONTENT_EDITOR') disabled = true;


    return disabled
}