import {FC, ReactElement} from 'react';
import {useSelector} from "react-redux";
import {selectRole} from "../store/auth/selectors";
import {UserTypes} from 'entities/user'

type Props = {
    role: UserTypes.RoleName[],
    isCan: boolean
    children: ReactElement
}
const WhetherTheUser:FC<Props> = ({role, isCan, children}) => {
    const userRole = useSelector(selectRole);
    if (userRole && isCan && role.includes(userRole)) {
        return children
    } else if (userRole && !isCan && !role.includes(userRole)) {
        return children
    } else return null
};
export default WhetherTheUser;
