import {InferValueTypes} from "models/common";

import * as actions from './actions';
import {CalendarActionTypes} from "./action-types";
import {TCalendarState} from "../../types";


type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;


export const initialState: TCalendarState = {
    filter: {
        page: 1,
        pageSize: 10,
        filterData: {
            isNotVotedRecordsExists: true
        }
    },
    list: [],
    isLoading: false,
    error: null,
    view: 'month'
}

export default function reducer(state: TCalendarState = initialState, action: ActionTypes): TCalendarState {
    switch (action.type) {
        case CalendarActionTypes.SET_VALUE:
            return {...state, ...action.value}
        default:
            return state
    }
}
