import * as actions from './actions'
import { ActionTypes } from './types'
import { InferValueTypes } from 'models/common'
import { Filter } from '../../types'
type ActionsTypes = ReturnType<InferValueTypes<typeof actions>>

export type State = {} & Filter
const initialState: State = {
	page: 1,
	pageSize: 50,
}
export default function reducer(state: State = initialState, action: ActionsTypes): State {
	switch (action.type) {
		case ActionTypes.SET_VALUE:
			return { ...state, ...action.value }
		case ActionTypes.CLEAR_VALUE:
			return initialState
		default:
			return state
	}
}
