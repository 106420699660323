import {z} from "zod";
import {Id_Name_Code_Hidden_Schema, Id_Name_Code_Schema} from "shared/types/common";

export const ContentSubmissionFormSchema = Id_Name_Code_Hidden_Schema.extend({
    requirements: z.string().nullable(),//ID формы сдачи контента
    contentModuleSection: Id_Name_Code_Schema.optional()
})
export type ContentSubmissionForm = z.infer<typeof ContentSubmissionFormSchema>
// ------------
export const ContentFormatTypeSchema = Id_Name_Code_Hidden_Schema.extend({
    submissionForms: z.array(ContentSubmissionFormSchema)
})
export type ContentFormatType = z.infer<typeof ContentFormatTypeSchema>
// -------------
export const ContentFormatsSchema = z.object({
    id: z.string(),
    contentCount: z.number(),
    isAccountedContent: z.boolean().nullable(),
    isContent: z.boolean(),
    type: Id_Name_Code_Hidden_Schema,
    submissionForms: z.array(ContentSubmissionFormSchema)
})
export type ContentFormats = z.infer<typeof ContentFormatsSchema>
// ---------
export const ApprovedContentFormatsSchema = z.object({
    contentFormatType: Id_Name_Code_Schema,
    contentSubmissionForm: Id_Name_Code_Schema,
    approvedMasterContentCount: z.number(),
    approvedPredMasterContentCount: z.number(),
    isContent: z.boolean(),
    isAccountedContent: z.boolean(),
})
export type ApprovedContentFormats = z.infer<typeof ApprovedContentFormatsSchema>;