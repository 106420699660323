import {FC} from 'react';
import {useSelector} from "react-redux";
import {selectProjectAccess} from "store/selectors";
import {TRole} from "models/user";
import {checkAccessValidate, checkProjectStatusAccessValidate} from "utils/validation/checkAccessVaildate";
import {ProjectStatus, ProjectStatusEnum} from "entities/project/types";
import { ProjectTypes } from 'entities/project';

type TProps = {
    typeElement: 'edit' | 'delete'
    statusProject?: ProjectTypes.ProjectStatus
    children: any
}
const ProjectAccess:FC<TProps> = ({typeElement, statusProject, children}) => {
    const {projectStatus: status, role} = useSelector(selectProjectAccess);
    let projectStatus = status || statusProject

    let res = null
    if (typeElement === "edit" && projectStatus && role) {
        const validate = validateEdit(projectStatus, role)
        if (validate) res = children
    }
    if (typeElement === 'delete' && projectStatus && role) {
        const validate = validateDelete(projectStatus, role)
        if (validate) res = children
    }
    return res
};
export default ProjectAccess;

function validateEdit(projectStatus: ProjectTypes.ProjectStatus, role: TRole): boolean {
    let res = false;

    if (checkProjectStatusAccessValidate(
        [ProjectStatusEnum.DRAFT, ProjectStatusEnum.READY_TO_BE_ACTUAL],
            projectStatus
        ) &&
        checkAccessValidate(
            [
                "ROLE_ADMIN",
                "ROLE_COORDINATOR",
                "ROLE_MODERATOR",
                "ROLE_PROJECT_MANAGER",
                "ROLE_ADMIN_IS"
            ],
            role
        )
    ) res = true
    else if (checkProjectStatusAccessValidate([ProjectStatusEnum.SUSPENDED, ProjectStatusEnum.COMPLETED, ProjectStatusEnum.ACTUAL, ProjectStatusEnum.ARCHIVED], projectStatus) &&
        checkAccessValidate([
            "ROLE_ADMIN",
            "ROLE_COORDINATOR",
            "ROLE_FINANCIER",
            "ROLE_ANALYST",
            "ROLE_PRODUCER",
            "ROLE_MODERATOR",
            "ROLE_PROJECT_MANAGER",
            "ROLE_CONTENT_EDITOR",
            "ROLE_ADMIN_IS",
            "ROLE_LAWYER"
        ], role)) res = true

    return res;
}
function validateDelete(projectStatus: ProjectStatus, role: TRole): boolean {
    let res = false;
    if (checkProjectStatusAccessValidate(
            [ProjectStatusEnum.DRAFT, ProjectStatusEnum.READY_TO_BE_ACTUAL],
            projectStatus
        ) &&
        checkAccessValidate(
            [
                "ROLE_ADMIN",
                "ROLE_COORDINATOR",
                "ROLE_MODERATOR",
                "ROLE_PROJECT_MANAGER",
                "ROLE_ADMIN_IS"
            ],
            role
        )
    ) res = true
    return res;
}
