import {UploaderActionTypes} from "./action-types";
import {UploadData, UploadFile} from "./reducer";
import {UnfinishedFile} from "../../models/common";

export const OpenModalUploadAction = (
    entityId?: string,
    uploadFile?: UploadFile,
    uploadData?: UploadData,
) => ({
    type: UploaderActionTypes.OPEN_MODAL, entityId, uploadFile, uploadData
})
export const CloseModalUploadAction = () => ({
    type: UploaderActionTypes.CLOSE_MODAL,
})

export const SetFileUploadAction = (uploadFile: UploadFile) => ({
    type: UploaderActionTypes.SET_FILE, uploadFile
})
export const SetFileIdUploadAction = (fileId: string, taskId?: string) => ({
    type: UploaderActionTypes.SET_FILE_ID, fileId, taskId
})
export const ClearUploadAction = () => ({
    type: UploaderActionTypes.CLEAR
})
export const StartLoad = (taskId?: string) => ({
    type: UploaderActionTypes.START_LOAD, taskId
})
export const AbortedLoad = (taskId?: string) => ({
    type: UploaderActionTypes.ABORTED_LOAD, taskId
})
export const FinishLoad = (taskId?: string) => ({
    type: UploaderActionTypes.FINISH_LOAD, taskId
})

export const GetUnfinishedStartAction = () => ({
    type: UploaderActionTypes.GET_UNFINISHED_START,
})
export const GetUnfinishedSuccessAction = (files: UnfinishedFile[]) => ({
    type: UploaderActionTypes.GET_UNFINISHED_SUCCESS, files
})
export const GetUnfinishedErrorAction = (error: any) => ({
    type: UploaderActionTypes.GET_UNFINISHED_ERROR, error
})

export const DeleteFileStartAction = (fileId?: string, taskId?: string) => ({
    type: UploaderActionTypes.DELETE_FILES_START,
    fileId, taskId
})
export const DeleteFileSuccessAction = () => ({
    type: UploaderActionTypes.DELETE_FILES_SUCCESS
});
export const DeleteFileErrorAction = (error: any) => ({
    type: UploaderActionTypes.DELETE_FILES_ERROR,
    error,
});