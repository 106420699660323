import React, {FC, useEffect, useState} from 'react';
import {ProjectFormDto} from "../lib";
import {useDispatch} from "react-redux";
import {CreateProjectStartAction, UpdateProjectStartAction} from "store/projects/actions";
import {NotificationOpen} from "store/notification/actions";
import {Button, Modal, Tooltip} from "antd";
import {Formik, Form, FormikProps} from "formik";
import styles from './form.module.less'
import {ModalOpenAction} from "store/modals/actions";
import {StepFirst, StepSecond, StepThird, StepFourth, StepFifth} from "./steps";
import {validationSchema} from '../lib';
import classNames from "classnames";
import { ProjectTypes } from 'entities/project';
import {create} from "../lib";
import {edit} from "../lib/edit";
import Banner from "shared/ui/banner";


type Props = {
    isEdit: boolean
    id?: string
    statusCount: number
    data: ProjectFormDto
    handler: any
    handlerClose?: Function
    status?: ProjectTypes.ProjectStatus
}
const FormUi: FC<Props> = ({
     isEdit,
     id,
     statusCount,
     handler,
     handlerClose,
     data
}) => {
    const put = useDispatch()
    const [validateOnChange, setValidateOnChange] = useState(!isEdit)


    useEffect(()=>{
        if (isEdit && !validateOnChange && statusCount !== 1) setValidateOnChange(true)
        // eslint-disable-next-line
    },[statusCount])

    function onSubmit(value: ProjectFormDto, formikProps: any) {
        if (formikProps.isValid) {
            if (isEdit && id) {
                put(NotificationOpen('info', 'Проект обновляется'))
                put(UpdateProjectStartAction(id, edit(value)))
            } else {
                put(CreateProjectStartAction(create(value)))
                put(NotificationOpen('info', 'Проект создается'))
            }
        }
    }

    const showError = (error: any) => () => {
        const stringErrors = JSON.stringify(error)
        const arrErrors = stringErrors
            .replace(/([^а-яёА-ЯЁ«»[0-9])/ig, ' ')
            .replace(/  +/g, '; ')
            .split('; ')

        Modal.error({
            title: 'Форма заполнена не валидно',
            maskClosable: true,
            okText: 'Закрыть',
            className: 'modal-duplication-form-error',
            content: (
                <ul>
                    {arrErrors.map((el: string, i) => {
                        if (el.length > 2) {
                            return (
                                <li key={el + i}>{el}</li>
                            )
                        } else return ''
                    })}
                </ul>
            ),
        });
    }
    return (
        <Formik
            enableReinitialize
            initialValues={data}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount={true}
            validateOnChange={validateOnChange}
        >
            {(formik: FormikProps<ProjectFormDto>) => {
                const status = formik.values.stepFirst.status.value?.code;
                const statusIsStatusDraftOrReadyToBeActual = status === null || status === undefined || status === 'DRAFT' || status === 'READY_TO_BE_ACTUAL'
                const statusIsActualOrArchival =  status === null || status === undefined || status === 'ARCHIVED' || status === 'ACTUAL'

                return (
                    <Form className={styles.form}>
                        {statusCount === 1 && <StepFirst/>}
                        {statusCount === 2 && <StepSecond/>}
                        {statusCount === 3 && <StepThird/>}
                        {statusCount === 4 && <StepFourth/>}
                        {statusCount === 5 && <StepFifth/>}
                        <div className={styles.buttons}>
                            <Button
                                type="default"
                                onClick={(_e) => {
                                    put(ModalOpenAction('form-no-save',{
                                        reset: formik.handleReset,
                                        handlerClose: handlerClose
                                    }))
                                }}
                            >
                                Отменить
                            </Button>
                            {statusCount !== 1 && (
                                <Button type="default" onClick={handler('dec')}>
                                    Назад
                                </Button>
                            )}
                            <>
                                {!formik.isValid && (
                                    <Button type={"default"} onClick={showError(formik.errors)}>
                                        Список ошибок
                                    </Button>
                                )}
                            </>
                            <>
                                <Button type="primary" onClick={handler('inc')}>
                                    Далее
                                </Button>
                                <Tooltip
                                    title={
                                        !formik.isValid ?
                                            `Необходимо корректно заполнить все обязательные поля. Для подробностей нажмите кнопку "Список ошибок"` :
                                            ''
                                    }
                                >
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className={classNames({
                                            unionSave : formik.isValid &&
                                                status !== 'SUSPENDED' &&
                                                status !== 'COMPLETED'
                                        })}
                                        onClick={() => onSubmit(formik.values, formik)}
                                        disabled={
                                            status
                                                ? (status === 'SUSPENDED' || status === 'COMPLETED' || !formik.isValid)
                                                : !formik.isValid
                                        }
                                    >
                                        {isEdit && id ? 'Обновить' : 'Сохранить'}
                                    </Button>
                                </Tooltip>

                            </>
                        </div>
                        {statusIsActualOrArchival && statusCount === 4 && (
                            <Banner>
                                Для изменения/удаления текущих значений пользователей проекта обратитесь к пользователю с правами руководителя
                            </Banner>
                        )}
                        {statusIsStatusDraftOrReadyToBeActual && (
                            <Banner>
                                Для сохранения черновика проекта достаточно заполнить следующие поля: “Название проекта”,
                                “Номер заявки”, “Дата договора” или “Дата соглашения”, “Подрядчик”.
                                Заполнение всех остальных обязательный полей, выделенных звездочкой, необходимо для успешного
                                перевода проекта в статус “Текущий”
                            </Banner>
                        )}
                    </Form>
                )
            }}

        </Formik>
    )
};

export default FormUi;