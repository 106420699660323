import { z } from 'zod'
import { TSort } from '../../models/common'

export const Name_Code_Schema = z.object({
	name: z.string(),
	code: z.string(),
})
export type Name_Code = z.infer<typeof Name_Code_Schema>

export const Id_Name_Code_Schema = z.object({
	id: z.string(),
	name: z.string(),
	code: z.string(),
})
export type Id_Name_Code = z.infer<typeof Id_Name_Code_Hidden_Schema>

export const Id_Name_Code_Hidden_Schema = Id_Name_Code_Schema.extend({
	hidden: z.boolean(),
})
export type Id_Name_Code_Hidden = z.infer<typeof Id_Name_Code_Hidden_Schema>

export const OptionsSchema = z.object({
	value: z.string(),
	label: z.string(),
	code: z.string().optional(),
})

export const SortSchema = z.object({
	field: z.string(),
	direction: z.enum(['ASC', 'DESC']),
})

export type SortDirection = 'ASC' | 'DESC'

export type Sort = {
	field: string
	direction: SortDirection
}
export type SearchParams = {
	page: number
	pageSize: number
	sorts?: Sort
	totalCount?: number
}
export type SearchResponse<T> = SearchParams & {
	content: T[]
}

type FilterParams = {
	page: number
	pageSize: number
	sort?: TSort
}
export type FilterRequest<T> = FilterParams & {
	filterData?: T
}

export const PageCodeSchema = z.enum([
	'PROJECTS',
	'TASKS',
	'USERS_SEARCH',
	'NOTIFICATIONS',
	'PROJECTS_KANBAN',
	'CONTENT_RECORDS_SEARCH',
	'TASKS_RECORDS_CONTENT',
	'TASKS_RECORDS_POSTING',
	'TASKS_RECORDS_REACH',
	'TASKS_RECORDS_COPYRIGHT',
	'TASKS_RECORDS_ESTIMATE',
	'MODAL_CONTENT_RECORDS'
])
export type PageCode = z.infer<typeof PageCodeSchema>
