import React, {FC, useRef, useState} from 'react';
import './index.less'
import {Button, Input, Modal, Spin} from "antd";
import useHandlerModal from "hooks/handlModal";
import $api from "../../../http";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {useSelector} from "react-redux";
import {selectParams} from "store/selectors";
import {FolderFilled} from "@ant-design/icons";
import {GetTasksContentStartAction} from "store/tasks/actions/content";

type Props = {
    status: boolean
}


const setSDHPath = async (taskId:string, str:string, callbackLoading: Function, closeModal: Function, put: any)=>{
    try {
        const {data} = await $api.post<{message: string}>(`/tasks/content/${taskId}/records/from-storage`, str,{
            headers : {
                'Content-Type': 'application/text',
                'Actioncode': 'CREATE_TASKS_CONTENT_TASK_ID_RECORDS_FROM_STORAGE'
            }
        })
        if (data.message) {
            put(NotificationOpen('success', '', data.message))
        }
        put(GetTasksContentStartAction(taskId))
        closeModal()
    } catch (error) {
        const message = CreateErrorMessage.response(error)
        put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось отправить'))
    } finally {
        callbackLoading(false)
    }
}


const ModalSHD: FC<Props> = ({status}) => {
    const {isVisible, closeModal, put} = useHandlerModal(status);
    const [loading, setLoading ] = useState(false)
    const { id } = useSelector(selectParams)
    const inputRef = useRef('')

    const handlerChangeInput = (e:any) => {
        inputRef.current = e.target.value
    }

    const handlerApply = () => {
        setLoading(true)
        setSDHPath(id, inputRef.current, setLoading, closeModal, put)
    }

    return (
        <Modal
            className='ModalSHD'
            title='Добавление записей задачи по файлам из папки на СХД'
            visible={isVisible}
            footer={(
                <>
                    <Button type={"default"} onClick={closeModal}>Отменить</Button>
                    <Button type={"primary"} onClick={handlerApply}>Применить</Button>
                </>
            )}
            maskClosable={true}
            onCancel={closeModal}
        >
            <Spin spinning={loading}>
                <div className='ModalSHD__content'>
                    <p>Укажите путь к папке, из которой требуется добавить файлы и записи задачи</p>

                        <Input
                            className="ModalSHD__content__input"
                            autoComplete="off"
                            type="text"
                            id="input-sxd"
                            placeholder="Введите путь к папке"
                            onChange={handlerChangeInput}
                            prefix={<FolderFilled />}
                        />
                </div>
            </Spin>
        </Modal>
    )
};
export default ModalSHD;