import React, { FC, useState } from 'react'
import './index.less'
import { TypeContent } from 'models/content'
import { CheckCircleOutlined, FileOutlined } from '@ant-design/icons'
import { CloseCircleOutlined } from '@ant-design/icons/lib'
import { Spin, Tooltip } from 'antd'
import { useContentImage } from '../../hooks'
import { FileType } from 'models/common'
import WhetherTheUser from 'components/whetherTheUser'
import { ModalEvent } from 'shared/ui/modal'
import ModalContentRecords from 'widgets/modal-content-records'
import classNames from 'classnames'

type Props = {
	el: TypeContent
	handler: Function
	active: boolean
	image?: FileType
}
const TabItemElement: FC<Props> = ({ el, handler, image, active }) => {
	const { pathImage, loading, imageId, setLoading } = useContentImage(el.file, image)
	const [imageSrc, setImageSrc] = useState(`${pathImage + imageId}`)
	const onOpenModal = (event: any) => {
		event.stopPropagation()
		ModalEvent.open({
			width: 800,
			destroyOnClose: true,
			component: <ModalContentRecords el={el} />,
		})
	}
	const handlerImageLoad = () => {
		setLoading(false)
	}
	const handlerImageError = () => {
		setLoading(false)
		setImageSrc(window.location.origin + '/images/video.png')
	}

	return (
		<div className={classNames('TabItem__list-item', { active: active })} onClick={(_event) => handler(el)}>
			<Tooltip title={el.name.length > 35 ? el.name : ''}>
				<div className="title">{el.name.length > 35 ? el.name.substring(0, 35) + '...' : el.name}</div>
			</Tooltip>
			<Spin spinning={loading}>
				<div className="img">
					{imageId ? (
						<img src={imageSrc} alt={el.name} onLoad={handlerImageLoad} onError={handlerImageError} />
					) : (
						<div className="fileOutlined">
							<FileOutlined />
						</div>
					)}
				</div>
			</Spin>

			<WhetherTheUser role={['ROLE_CLIENT', 'ROLE_AGENT', 'ROLE_VIEWER']} isCan={false}>
				<div className="footer">
					<span>{el.isMasterFile ? 'Мастер-файл' : 'Предмастер-файл'}</span>
					<span>
						{el.status.code === 'APPROVED' ? (
							<CheckCircleOutlined className="green" />
						) : (
							<CloseCircleOutlined className="red" />
						)}
					</span>
				</div>
			</WhetherTheUser>
			<WhetherTheUser
				role={['ROLE_MODERATOR', 'ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER']}
				isCan={true}
			>
				<>{el.status.code === 'APPROVED' ? <small onClick={onOpenModal}>редактировать</small> : null}</>
			</WhetherTheUser>
		</div>
	)
}

export default TabItemElement
