import { combineReducers } from 'redux'
import { DuplicationSchedulersModel } from 'features/сonfiguring-task-autocreation'
import { UserFiltersModel } from 'features/user-filters'
import { SearchContentProjectModel } from 'features/search-project-content'
import { ExtendedSearchModel } from 'features/extended-search'
import { ReportSchedulersModel } from 'features/report-auto-sending-schedule'

export const FeatureReducer = combineReducers({
	duplicationSchedulers: DuplicationSchedulersModel.reducer,
	userFilters: UserFiltersModel.reducer,
	searchProjectContent: SearchContentProjectModel.reducer,
	extendedSearch: ExtendedSearchModel.reducer,
	reportSchedulers: ReportSchedulersModel.reducer,
})
export type TFeatureState = ReturnType<typeof FeatureReducer>
