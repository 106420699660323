import React, {FC, useEffect, useState} from 'react'
import './organizations-field.less'
import {FieldArray, FormikProps, useFormikContext} from 'formik'
import {Button, Spin, Tooltip} from 'antd'
import {DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined} from '@ant-design/icons'

import {SelectComponent} from 'components/formic-control'
import TagsComponent from '../tags/tags'
import {IOptions} from 'models/feels'
import OrganizationsService from 'services/organizationsService'
import WhetherTheUser from "components/whetherTheUser";
import {UserDto} from "entities/user";

import {ArrayHelpers} from "formik/dist/FieldArray";

type ContentFormatFieldsProps = {
	name: string
	role: IOptions | null
}

const getOrganizations = async (callback: Function, callbackLoading: Function, roleId: string) => {
	callbackLoading(true)
	try {
		const { data } = await OrganizationsService.getOrganizationsWithRole(roleId)
		callback(
			data.map((el: any) => {
				return { value: el.id, label: el.name + ' / ' + el.inn, hidden: el.hidden } as IOptions
			})
		)
	} catch (e) {
		console.log(e)
	} finally {
		callbackLoading(false)
	}
}

const OrganizationField: FC<ContentFormatFieldsProps> = ({ name, role }) => {
	const { errors, values } = useFormikContext<UserDto.User>()
	const [loading, setLoading] = useState(false)
	const [organizations, setOrganizations] = useState([])

	const isDisabled = values.disabledOrganizations;

	useEffect(() => {
		if (role?.value) getOrganizations(setOrganizations, setLoading, role.value)
		else setOrganizations([])
	}, [role])

	return (
		<FieldArray
			name={name}
			render={({ form, remove, push }: {
				form: FormikProps<UserDto.User>,
				remove: ArrayHelpers["remove"],
				push: ArrayHelpers["push"]
			}) => (
				<>
					{form.values.organizations.map((item, index) => (
						<div className="field-organization" key={`field-organization${index}`}>
							<div className="field-organization__name">
								<p>
									Организация <sup>*</sup>
								</p>
								<div>
									{form.values.organizations.length > 1 && (
										<Tooltip title="Удалить">
											<Button type="link" onClick={() => remove(index)} className="field-organization__delete">
												<DeleteOutlined />
											</Button>
										</Tooltip>
									)}
									<Tooltip title={TooltipTitle()}>
										<ExclamationCircleOutlined />
									</Tooltip>
								</div>
							</div>
							<div className="field-organization__organization">
								<Tooltip title={!values.role?.value ? 'Выберите роль' : ''}>
									<Spin spinning={loading || !organizations}>
										<SelectComponent
											name={`${name}[${index}].id`}
											options={organizations}
											placeholder="Выберите организацию по наименованию или ИНН"
											disabled={!values.role?.value || values.disabled}
											disabledOptionsIds={
												values.organizations.map((el) => el.id.value)
											}
											errorMessage={
												errors.organizations?.length ?
													(errors.organizations[index] as { id: string })?.id ? (errors.organizations[index] as { id: string })?.id : undefined
													: undefined
											}
										/>
									</Spin>
								</Tooltip>
							</div>
							<div className="field-organization__email">
								<div className="field-organization__email--wrapper">
									<p>
										Email <sup>*</sup>
									</p>
									{values.role?.value && values.organizations[index].id?.value && (
										<TagsComponent
											tags={form.values.organizations[index]?.emails}
											name={`${name}[${index}].emails`}
											type={'email'}
											disabled={form.values.disabled}
										/>
									)}
								</div>
							</div>
							<div className="field-organization__phone">
								<div className="field-organization__phone--wrapper">
									<p>
										Телефон<sup>*</sup>
									</p>
									{values.role?.value && values.organizations[index].id?.value && (
										<TagsComponent
											tags={form.values.organizations[index]?.phones}
											name={`${name}[${index}].phones`}
											type="phone"
											disabled={form.values.disabled}
										/>
									)}
								</div>
							</div>
						</div>
					))}
					<>
						<div className="field-organization__btn-wrapper">
							<Button
								className="field-organization__btn"
								type="link"
								disabled={isDisabled || values.disabled}
								onClick={() =>
									push({
										id: '',
										emails: [],
										phones: [],
									})
								}
							>
								<PlusCircleOutlined /> Добавить организацию
							</Button>
						</div>
					</>
				</>
			)}
		/>
	)
}

export default OrganizationField

function TooltipTitle() {
	return (
		<div className="field-organization__tooltip">
			<p>1.Первое значение в поле email /телефон определяется как “Основное”
				<WhetherTheUser role={["ROLE_CLIENT", "ROLE_VIEWER"]} isCan={false}>
					<span>, на которое будут приходить уведомления</span>
				</WhetherTheUser>
			</p>
			<p>2. Основной email /телефон может быть только один.</p>
			<p>3. Пользователь может изменить основной email /телефон в настройках профиля.</p>
		</div>
	)
}
