import {put, select} from "redux-saga/effects";
import {Logger} from "shared/services";
import {TTaskRecordsFilter, TTaskRecordsFilterData} from "models/task/task-list";
import {
    ClearTasksRecordsFilterAction,
    GetTasksRecordsStartAction,
    SetTasksRecordsFilterAction
} from "store/tasks/actions/records";
import {selectTasksRecordsFilter} from "store/tasks/selectors/tasks-records";
import {ReportType} from "models/task/types";
import {Nullable} from "models/common";

type TFilterDataTasks = {
    filterData: TTaskRecordsFilterData,
    typeTask?: Nullable<{ id: string, code: 'REPORT' | 'CONTENT' }>,
    typeReport?: Nullable<{ id: string, code: ReportType }>,
}
export function* tasksRecords(filter: string, filterId: string | undefined) {
    try {
        const filterTasks: TTaskRecordsFilter = yield select(selectTasksRecordsFilter)
        if (filterId) {
            const filterDataTasks: TFilterDataTasks = JSON.parse(filter);
            yield  put(SetTasksRecordsFilterAction({
                filter: {...filterTasks, filterData: { ...filterDataTasks.filterData}, sort: undefined},
                typeTask: filterDataTasks?.typeTask,
                typeReport: filterDataTasks.typeReport
            }))
        } else {
            yield put(ClearTasksRecordsFilterAction())
        }
        yield put(GetTasksRecordsStartAction())

    } catch (error) {
        Logger.error(error)
    }
}