import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetHandbookStartAction,
    UpdateThematicErrorAction,
    UpdateThematicStartAction,
    UpdateThematicSuccessAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import HandbookService from "services/handbookService";


export function* updateThematic({id, data,}: ReturnType<typeof UpdateThematicStartAction>) {
    try {
        yield HandbookService.updateThematicRecord(data,id)
        yield put(UpdateThematicSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.thematic))
        yield put(NotificationOpen('success', 'Тематика обновлена'))
    } catch (error: any) {
        yield put(UpdateThematicErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить тематику'))
    }
}
