import React, {FC} from 'react'
import styles from './styles.module.less'
import {Checkbox} from 'antd'

type TProps = {
	value: boolean | null
	onChange: any
}

const CheckboxAccountedContent: FC<TProps> = ({ value, onChange }) => {

	const handleCheckboxChange = () => {
		if (value === true) {
			onChange(false)
		} else if (value === false) {
			onChange(null)
		} else {
			onChange(true)
		}
	}
	return (
		<Checkbox
			className={styles.checkbox}
			indeterminate={value === null}
			checked={value === true}
			onChange={handleCheckboxChange}
		>
			{getText(value)}
		</Checkbox>
	)
}

function getText(value: boolean | null) {
	switch (value) {
		case true:
			return 'Учитывается'
		case false:
			return 'Не учитывается'
		case null:
			return 'Не определено'
		default:
			return ''
	}
}

export default CheckboxAccountedContent
