import React from 'react';
import {Image, Spin} from "antd";
import {useSelector} from "react-redux";
import {selectProject} from "store/projects/selectors";
import {selectFile} from "store/files/selectors";
import {CheckOutlined} from "@ant-design/icons";

const ProjectImage = () => {
    const project = useSelector(selectProject);
    const image = useSelector(selectFile(project?.image?.id));
    return (
        <div className="projectPage__card-img">
            {project?.image && (
                <Spin spinning={image?.isLoading}>
                    {image?.error ?  <img src={window.location.origin + '/images/default-direction.png'} alt=""/> :
                     image?.isLoading ?
                            <img src={window.location.origin + '/images/project.png'} alt=""/> :
                            <Image src={image?.data} alt={project.image.name}/>
                    }
                </Spin>
            )}
            {!project?.image && project?.defaultImage && (
                <img src={project?.defaultImage} alt={project.name}/>
            )}
            {!project?.image && !project?.defaultImage && (
                <img src={window.location.origin + '/images/default-direction.png'} alt=""/>
            )}
            {!!project?.projectDataCheckedDatetime && (
                <span><CheckOutlined /> Данные проекта проверены редактором каталога</span>
            )}
        </div>
    )
}

export default ProjectImage;