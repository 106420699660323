import {takeEvery, takeLatest} from 'redux-saga/effects'
import {OptionsNotificationsActionsTypes} from 'store/options/action-types'
import getNotifications from "./getNotifications";
import getActiveNotificationChannels from "./getActiveNotificationChannels";
import {setFilter} from "./setFilter";

export default function* notificationsSaga() {
	yield takeLatest(OptionsNotificationsActionsTypes.GET_NOTIFICATIONS_START, getNotifications)
	yield takeEvery(OptionsNotificationsActionsTypes.GET_ACTIVE_NOTIFICATIONS_CHANNELS_START, getActiveNotificationChannels)
	yield takeEvery(OptionsNotificationsActionsTypes.SET_NOTIFICATION_FILTER, setFilter)


}
