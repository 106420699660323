import { put, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import { NotificationOpen } from '../../store/notification/actions'
import {
	GetAnalyticsPostingTopResourcesErrorAction,
	GetAnalyticsPostingTopResourcesStartAction,
	GetAnalyticsPostingTopResourcesSuccessAction,
} from '../../store/analytics/actions'
import AnalyticsService from '../../services/analyticsService'
import { TAnalyticsFilter, TAnalyticsPostingTopResourcesResponse } from '../../models/analytics/analytics-project'
import { selectAnalyticsFilter } from '../../store/analytics/selectors'

export function* getAnalyticsPostingTopResources(
	_action: ReturnType<typeof GetAnalyticsPostingTopResourcesStartAction>
) {
	try {
		const filter: TAnalyticsFilter = yield select(selectAnalyticsFilter)
		const { data: analytics }: AxiosResponse<TAnalyticsPostingTopResourcesResponse> =
			yield AnalyticsService.getAnalyticsPostingTopResources(filter)
		yield put(GetAnalyticsPostingTopResourcesSuccessAction(analytics))
	} catch (error: any) {
		yield put(GetAnalyticsPostingTopResourcesErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить данные'))
	}
}
