import {combineReducers} from "redux";
import listReducer from './list/reducer'
import contentReducer from './content/reducer'
import settingsReducer from './settings/reducer'
const reducer = combineReducers({
    list: listReducer,
    content: contentReducer,
    settings: settingsReducer
})
export type TContentState = ReturnType<typeof reducer>;

export default reducer;