import * as Yup from 'yup';
import { TForm } from './modal';

export const validation = Yup.object({
	tasks: Yup.mixed().test(
		'tasks',
		'Поле «Задачи» обязательно для заполнения',
		function(value, context) {
			// @ts-ignore
			const values: Array<{schema: unknown, value: TForm}> = context.from;
			const mode = values[0].value.mode;
			return mode === 'create' ? value?.length > 0 && !value.some((el: any) => !el) : true
		}
	),
	schedule: Yup.mixed().test(
		'schedule',
		'Поле «Созданные расписания» обязательно для заполнения',
		function(value, context) {
			// @ts-ignore
			const values: Array<{schema: unknown, value: TForm}> = context.from;
			const mode = values[0].value.mode;
			return mode === 'update' ? value?.length > 0 && !value.some((el: any) => !el) : true
		}
	),
	scheduleDates: Yup.mixed().test(
		'scheduleDates',
		'Поле «Даты создания задач» обязательно для заполнения',
		function(value, context) {
			// @ts-ignore
			const values: Array<{schema: unknown, value: TForm}> = context.from;
			const duplicationTerms = values[0].value.duplicationTerms;
			return duplicationTerms === '2' ? value?.length > 0 && !value.some((el: any) => !el) : true
		}
	),
	firstTaskDate: Yup.mixed().test(
		'firstTaskDate',
		'Поле «Дата создания дубля первой задачи» обязательно для заполнения',
		function(value, context) {
			// @ts-ignore
			const values: Array<{schema: unknown, value: TForm}> = context.from;
			const duplicationTerms = values[0].value.duplicationTerms;
			return duplicationTerms === '1' ? !!value : true
		}
	),
	lastTaskDate: Yup.mixed().test(
		'lastTaskDate',
		'Поле «Дата создания дубля последней задачи» обязательно для заполнения',
		function(value, context) {
			// @ts-ignore
			const values: Array<{schema: unknown, value: TForm}> = context.from;
			const duplicationTerms = values[0].value.duplicationTerms;
			return duplicationTerms === '1' ? !!value : true
		}
	),
	dayOfWeek: Yup.mixed().test(
		'dayOfWeek',
		'Поле «частота создания» обязательно для заполнения',
		function(value, context) {
			// @ts-ignore
			const values: Array<{schema: unknown, value: TForm}> = context.from;
			const duplicationTerms = values[0].value.duplicationTerms;
			return duplicationTerms === '1' ? !!value?.value : true
		}
	),
	daysToDeadline: Yup.mixed().test(
		'daysToDeadline',
		'Поле «Количество дней до дедлайна» обязательно для заполнения',
		function(value) {
			return !!value || value === 0
		}
	),
})