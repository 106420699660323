import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetAllNationalProjectTag2ErrorAction,
    GetAllNationalProjectTag2StartAction,
    GetAllNationalProjectTag2SuccessAction
} from "../../store/national-project-tag2/actions";
import NationalProjectTag2Service from "../../services/nationalProjectTag2Service";

export function* nationalProjectTag2(_: ReturnType<typeof GetAllNationalProjectTag2StartAction> ) {
    try {
        const {data} =  yield NationalProjectTag2Service.getNationalProjectTag2()
        yield put(GetAllNationalProjectTag2SuccessAction(data))
    } catch (error) {
        yield put(GetAllNationalProjectTag2ErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить список тегов национальных проектов 2-го уровня'))
    }
}