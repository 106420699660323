export enum TypesTask {
    CONTENT = 'CONTENT',
    REPORT = 'REPORT'
}

// Типы задач по отчётам
export enum ReportType {
    CONTENT_REACH_REPORT = 'CONTENT_REACH_REPORT',
    COPYRIGHT_LIST = 'COPYRIGHT_LIST',
    PROJECT_ESTIMATE = 'PROJECT_ESTIMATE',
    CONTENT_POSTING_REPORT = 'CONTENT_POSTING_REPORT',
}

