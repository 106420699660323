import {z} from 'zod';
import {FilterRequest, Id_Name_Code_Hidden_Schema, Id_Name_Code_Schema, SearchResponse} from 'shared/types/common';

const SubmissionFormSchema = Id_Name_Code_Hidden_Schema.extend({
    requirements: z.string().nullable(),//ID формы сдачи контента
    contentModuleSection: Id_Name_Code_Schema.optional()
});
export const ContentFormatTypeSchema = Id_Name_Code_Hidden_Schema.extend({
    submissionForms: z.array(SubmissionFormSchema),
    contentCategory: Id_Name_Code_Schema
});
export type SubmissionForm = z.infer<typeof SubmissionFormSchema>;
export type ContentFormatType = z.infer<typeof ContentFormatTypeSchema>;

export type ContentTypeRequest = {
    code: string | null,
    name: string,
    hidden: boolean,
    submissionFormIds: string[]
    contentCategoryId: string
}

export type FilterData = {
    searchString?: string;
    hidden?: boolean;
    contentCategoryIds?: string[];
};

export type ContentFormatTypes = SearchResponse<ContentFormatType>;
export type Filter = FilterRequest<FilterData>
