import {ReportsSchedulers, ReportsSchedulersInputData, ReportsSchedulerUser} from '../../types'
import { IOptions } from 'models/feels'

export class InitialValueDto {
	reports: IOptions[]
	users: IOptions[]
	sessions: IOptions[]
	projects: IOptions[]
	format: 'PDF' | 'DOCX' | 'XLSX'
	radioButtons: 'ALL' | 'SESSIONS' | 'PROJECTS'
	firstFormationDate: string | null
	lastFormationDate: string | null
	formationTime: string | null
	disabledSessions: boolean
	disabledProjects: boolean
	time: string | null
	constructor(data?: ReportsSchedulers) {
		this.reports = data ? [{ value: data.reportTemplate.id, label: data.reportTemplate.name }] : []
		this.users = data ? data.users.map((user) => new OptionUserDto(user)) : []
		this.sessions = this.getSessionsData(data?.inputData)
		this.projects = this.getProjectsData(data?.inputData)
		this.format = 'XLSX'
		this.radioButtons = new RadioDto(data?.inputData).data
		this.firstFormationDate = data ? data.firstFormationDate : null
		this.lastFormationDate = data ? data.lastFormationDate : null
		this.formationTime = data ? data.formationTime : null
		this.disabledSessions = new DisabledDto(data?.inputData).disabledSessions
		this.disabledProjects = new DisabledDto(data?.inputData).disabledProjects
		this.time = data ? data.formationTime : null
	}

	getSessionsData(data: ReportsSchedulersInputData): IOptions[] {
		if (!data) return []
		return data?.sessionIds ? data.sessionIds.map((it: string) => ({ label: it, value: it })) : []
	}
	getProjectsData(data: ReportsSchedulersInputData): IOptions[] {
		if (!data) return []
		return data?.projectIds ? data.projectIds.map((it: string) => ({ label: it, value: it })) : []
	}
}

class OptionUserDto implements IOptions {
	label
	value

	constructor(user: ReportsSchedulerUser) {
		this.value = user.id
		this.label = this.getLabel(user)
	}

	getLabel(user: ReportsSchedulerUser) {
		const firstName = user.firstName ? user.firstName + ' ' : ''
		const lastName = user.lastName ? user.lastName + ' ' : ''
		const middleName = user.middleName ? user.middleName + ' ' : ''
		return `${lastName}${firstName}${middleName} - ${user.role?.ruName ? user.role?.ruName : ''}`
	}
}

class RadioDto {
	data: 'ALL' | 'SESSIONS' | 'PROJECTS'
	constructor(data: ReportsSchedulersInputData) {
		this.data = this.getData(data)
	}

	getData(data: ReportsSchedulersInputData): 'ALL' | 'SESSIONS' | 'PROJECTS' {
		if (!data) return 'ALL'

		if (data?.projectIds?.length && data?.sessionIds?.length) {
			return 'PROJECTS'
		}
		if (data?.sessionIds?.length) {
			return 'SESSIONS'
		}
		return 'ALL'
	}
}

class DisabledDto {
	disabledSessions
	disabledProjects
	constructor(data: ReportsSchedulersInputData) {
		this.disabledProjects = this.getDisabled(data).disabledProjects
		this.disabledSessions = this.getDisabled(data).disabledSessions
	}

	getDisabled(data: ReportsSchedulersInputData) {
		if (!data) return { disabledSessions: true, disabledProjects: true }

		if (data?.sessionIds?.length && data?.projectIds?.length) return { disabledSessions: false, disabledProjects: false }
		if (data?.sessionIds?.length) return { disabledSessions: false, disabledProjects: true }
		if (data?.projectIds?.length) return { disabledSessions: false, disabledProjects: false }
		return { disabledSessions: true, disabledProjects: true }
	}
}
