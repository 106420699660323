import React, {MouseEvent, useMemo} from 'react';
import 'moment/locale/ru';
import locale from "antd/es/date-picker/locale/ru_RU";
import {useDispatch, useSelector} from "react-redux";
import {Button, DatePicker, Select} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {Moment} from "moment/moment";
import moment from "moment";
import {SearchContentProjectModel} from 'features/search-project-content';
import { Stores, Fields } from 'features/search-project-content/types';
const { RangePicker } = DatePicker;

const {Option} = Select;
const optionsFields: Array<{label: string, value: Stores.DatesKeys | 'Параметр неважен'}> = [
    {label: 'Параметр неважен', value: 'Параметр неважен'},
    {label: 'Дата начала договора', value: Fields.FieldsEnums.CONTRACT_DATE},
    {label: 'Дата соглашения', value: Fields.FieldsEnums.AGREEMENT_DATE},
    {label: 'Дата окончания договора', value: Fields.FieldsEnums.COMPLETION_DATE},
]

export const DatesItem = () => {
    const put = useDispatch();

    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main)
    const dates = useSelector(SearchContentProjectModel.selectors.dates)

    const disabledOptions = useMemo(() => {
        return dates[typeContent].fields.map((el) => el.field)
    }, [dates, typeContent]);

    const onSelectChangeType = (index: number) => (value: Stores.DatesKeys | 'Параметр неважен') => {
        const newArray = [...dates[typeContent].fields];
        newArray[index]["field"] = value;
        newArray[index]["startDate"] = '';
        newArray[index]["endDate"] = '';
        put(SearchContentProjectModel.actions.setDates(
            typeContent,
            newArray
        ))
    }

    const addGroup = () => {
        put(SearchContentProjectModel.actions.setDates(typeContent, [
            ...dates[typeContent].fields,
            {field: 'Параметр неважен', startDate: '', endDate: ''}
        ]))
    }

    const onRangePickerChange = (index: number) => (datesMoment: [Moment, Moment] | null | any, formatString: [string, string]) => {
        const newArray = [...dates[typeContent].fields];
        newArray[index].startDate = formatString[0];
        newArray[index].endDate = formatString[1];
        put(SearchContentProjectModel.actions.setDates(
            typeContent,
            newArray
        ))
    }

    const onRemoveHandler = (index: number) => (_event: MouseEvent<HTMLDivElement>) => {
        const newArray = [...dates[typeContent].fields];
        newArray.splice(index, 1)
        put(SearchContentProjectModel.actions.setDates(
            typeContent,
            newArray
        ))
    }

    return (
        <div className="item-wrapper">
            {dates[typeContent].fields.map((item, index) => (
                <div className="item-wrapper__group" key={item.field + index}>
                    <div className="item-wrapper__items">
                        <div className="item">
                            <div className="form-group">
                                <Select
                                    value={item.field}
                                    onChange={onSelectChangeType(index)}
                                    placeholder={'Параметр неважен'}
                                    getPopupContainer={trigger => trigger.parentNode}
                                >
                                    {optionsFields.map((el) => (
                                        <Option
                                            disabled={disabledOptions.includes(el.value)}
                                            value={el.value}
                                            key={el.value}
                                        >
                                            {el.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="item item-second">
                            <div className="form-group">
                                <RangePicker
                                    disabled={item.field === 'Параметр неважен'}
                                    format={['DD-MM-YYYY', 'DD-MM-YYYY']}
                                    value={!!item.startDate ?
                                        [moment(item.startDate, 'DD-MM-YYYY'), moment(item.endDate, 'DD-MM-YYYY')] :
                                        undefined
                                    }
                                    onChange={onRangePickerChange(index)}
                                    placeholder={['Дата начала периода ', 'Дата окончания']}
                                    locale={locale}
                                />
                            </div>
                        </div>
                    </div>

                    {dates[typeContent].fields.length > 1 && (
                        <div className="item__remove" onClick={onRemoveHandler(index)}>
                            <DeleteOutlined />
                        </div>
                    )}
                </div>
            ))}
            {dates[typeContent].fields.length < optionsFields.length - 1 && (
                <div className="btn">
                    <Button type={'link'} onClick={addGroup}>+ Добавить условие</Button>
                </div>
            )}
        </div>
    )
}