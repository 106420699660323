import React from 'react';
import {useQuery} from "react-query";
import {useDispatch, useSelector} from "react-redux";
import {Select} from "antd";
import {SearchContentProjectModel} from 'features/search-project-content';
import {ContestApi} from 'entities/contest'

const { Option} = Select;

const Contest = () => {
    const put = useDispatch();
    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main);
    const contests = useSelector(SearchContentProjectModel.selectors.contests);
    const contestId = contests[typeContent].contestId;

    const {data: options = [], isLoading} = useQuery({
        queryKey: [ContestApi.ContestController.getContestListOptionsKey],
        queryFn: ContestApi.ContestController.getContestListOptions
    })

    function onSelectChange(value: string) {
        put(SearchContentProjectModel.actions.setContests(typeContent, {
            contestId: value, year: undefined, sessionId: undefined
        }))
    }

    return (
        <div className="item">
            <div className="label">Конкурс</div>
            <div className="form-group">
                <Select
                    loading={isLoading}
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={true}
                    onChange={onSelectChange}
                    maxTagCount={"responsive"}
                    value={contestId}
                    placeholder={'Искать среди всех'}
                    filterOption={(input, option) => {
                        if (option === undefined || option === null) return false;
                        return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                >
                    {options.map((el) => (
                        <Option
                            key={el.value}
                            value={el.value}
                            data-name={el.label}
                        >
                            {el.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}

export default Contest;