import React from 'react'
import { DatePicker } from 'antd'
import moment from 'moment/moment'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { Field, useFormikContext } from 'formik'
import { TInitValues } from '../modal-content-records'
import styles from '../styles.module.less'
import { FieldAttributes } from 'formik/dist/Field'
import { RadioButtonsComponent } from 'components/formic-control'

const DateComponent = () => {
	const { touched, errors, values, setFieldValue } = useFormikContext<TInitValues>()
	const onChange = (date: any, dateString: string) => {
		if (date) setFieldValue('date', formatDate(dateString))
		else setFieldValue('date', undefined)
	}

	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Дата и время создания единиц контента<sup>*</sup>
			</span>
			<div className={styles.picker}>
				<Field name="date">
					{(el: FieldAttributes<any>) => {
						return (
							<DatePicker
								{...el.field}
								disabled={values.disabled}
								name={'date'}
								onChange={onChange}
								value={el.meta.value && moment(el.meta.value)}
								showTime={true}
								placeholder={'Выберите дату и время'}
								format={'DD.MM.YYYY HH:mm:ss'}
								locale={locale}
								onBlur={(_event) => {
									// eslint-disable-next-line
									el.form.setTouched({ ...el.form.touched, ['date']: true })
								}}
							/>
						)
					}}
				</Field>
				<div className={styles.radio}>
					<RadioButtonsComponent
						disabled={values.disabled}
						name={'isMasterFile'}
						options={[
							{ value: 'true', label: 'Мастер файлы' },
							{ value: 'false', label: 'Предмастер файлы' },
						]}
					/>
				</div>
			</div>
			{/*// @ts-ignore*/}
			{errors?.date && touched['date'] && <div className="invalid-feel">{errors?.date?.value}</div>}
		</div>
	)
}
function formatDate(date: string) {
	const parts = date.split(' ')
	const datePart = parts[0]
	const timePart = parts[1]

	const [day, month, year] = datePart.split('.')
	const [hours, minutes, seconds] = timePart.split(':')

	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}
export default DateComponent
