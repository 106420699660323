import {useEffect} from 'react';
import {notification} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectNotification} from "store/notification/selectors";
import {NotificationClose} from "store/notification/actions";
import {selectIsLogin} from "store/auth/selectors";
import InnerHTMLFunction from "utils/innerHTMLFunction";

const NotificationComponent = () => {
    const put = useDispatch();
    const isAuth = useSelector(selectIsLogin)
    const {type, description, checkAuth, title, isLogin} = useSelector(selectNotification);

    const isCanView = checkAuth ? isAuth : true;
    const openNotification = () => {
        if (type && isCanView) {
            notification[type]({
                message: title,
                description: description && (<div dangerouslySetInnerHTML={InnerHTMLFunction(description)}/>),
            });

            put(NotificationClose())
        }
    }
    useEffect(() => {
        if (type && isLogin) {
            openNotification()
        }
    }, [type, description, title, isLogin])//eslint-disable-line
    return null
};
export default NotificationComponent;