// Паттерн для поиска текста внутри тегов
const pattern = /<([^>]+)>([^<]+)<\/\1>/g;

const replace = (expression: string ) => {
    const openingQuote = '«';
    const closingQuote = '»';
    if (expression.startsWith('"')) {
        expression = openingQuote + expression.substring(1);
    }

    if (expression.endsWith('"')) {
        expression = expression.substring(0, expression.length - 1) + closingQuote;
    }

    // Разделение содержимого на слова и замена кавычек внутри слов
    const words = expression.split(' ');
    const modifiedWords = words.map((word) => {
        if (word.startsWith('"')) {
            word = openingQuote + word.substring(1);
        }
        if (word.endsWith('"')) {
            word = word.substring(0, word.length - 1) + closingQuote;
        }
        if (word.includes('.') && word.replace('.', '').endsWith('"')) {
            word = word.replace('.', '').substring(0, word.length - 2) + closingQuote + '.';
        }

        return word;
    });

    // Объединение измененных слов обратно в строку
    expression = modifiedWords.join(' ');

    return expression;
}

const replaceQuotesInsideTags = (inputString: string) => {
    const callbackReplace = (match: any, tag: any, text: any) => `<${tag}>${replace(text)}</${tag}>`;
    // Заменяем текст внутри тегов с помощью регулярного выражения и функции замены
    return inputString.replace(pattern, callbackReplace);
}

const replaceQuotes = (expression: string | null) => {
    if (!expression) return null;

    const startTag = '<p>';
    const endTag = '</p>';
    const openingQuote = '«';
    const closingQuote = '»';

    const startIndex = expression.indexOf(startTag);
    const endIndex = expression.indexOf(endTag);

    if (startIndex !== -1 && endIndex !== -1) {
        let modifiedContent = expression.substring(startIndex + startTag.length, endIndex);
        // Замена кавычек в начале и в конце текста
        if (modifiedContent.startsWith('"')) {
            modifiedContent = openingQuote + modifiedContent.substring(1);
        }

        if (modifiedContent.endsWith('"')) {
            modifiedContent = modifiedContent.substring(0, modifiedContent.length - 1) + closingQuote;
        }
        // Разделение содержимого на слова и замена кавычек внутри слов
        const words = modifiedContent.split(' ');
        const modifiedWords = words.map((word) => {
            if (word.startsWith('"')) {
                word = openingQuote + word.substring(1);
            }
            if (word.endsWith('"')) {
                word = word.substring(0, word.length - 1) + closingQuote;
            }
            if (word.includes('.') && word.replace('.', '').endsWith('"')) {
                word = word.replace('.', '').substring(0, word.length - 2) + closingQuote + '.';
            }
            return replaceQuotesInsideTags(word);

        });

        // Объединение измененных слов обратно в строку
        modifiedContent = modifiedWords.join(' ');

        return expression.substring(0, startIndex + startTag.length) + modifiedContent + expression.substring(endIndex);
    } else {
       return replace(expression)
    }
}


export default function replaceQuotesInString (inputString: string | null)  {
    if (!inputString) return null;
    if (inputString.split('</p>').filter(it=>!!it).length > 0) {
        return inputString.split('</p>').map(element => replaceQuotes(element + '</p>')).join('')
    } else {
        return replaceQuotes(inputString)
    }
}
