import {put} from "redux-saga/effects";
import {
    AddTaskContentErrorAction,
    AddTaskContentStartAction,
    AddTaskContentSuccessAction,
    GetTasksContentStartAction
} from "store/tasks/actions/content";
import TasksContentService from "services/tasksContentService";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {AxiosResponse} from "axios";

export function* addTask({taskId}: ReturnType<typeof AddTaskContentStartAction>) {
    try {
        const {data: recordId}: AxiosResponse<string> = yield TasksContentService.addTaskRecord(taskId)
        yield put(AddTaskContentSuccessAction(recordId))
        yield put(NotificationOpen('success', 'Запись добавлена'))

        yield put(GetTasksContentStartAction(taskId))
    } catch (error: any) {
        yield put(AddTaskContentErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить запись'))
    }
}