import {put, select} from 'redux-saga/effects'
import {AxiosResponse} from 'axios'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import {NotificationOpen} from '../../store/notification/actions'
import {
	GetAnalyticsReachDataErrorAction,
	GetAnalyticsReachDataStartAction,
	GetAnalyticsReachDataSuccessAction,
} from '../../store/analytics/actions'
import AnalyticsService from '../../services/analyticsService'
import {TAnalyticsReachDataResponse, TAnalyticsFilterReach} from '../../models/analytics/analytics-project'
import {selectAnalyticsFilterReach} from '../../store/analytics/selectors'

export function* getAnalyticsReachData(_action: ReturnType<typeof GetAnalyticsReachDataStartAction>) {
	try {
		const filter: TAnalyticsFilterReach = yield select(selectAnalyticsFilterReach)
		const { data: coverageData }: AxiosResponse<TAnalyticsReachDataResponse[]> = yield AnalyticsService.getAnalyticsReachData(filter)
		yield put(GetAnalyticsReachDataSuccessAction(coverageData))
	} catch (error: any) {
		yield put(GetAnalyticsReachDataErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить данные'))
	}
}
