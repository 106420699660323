import {put} from "redux-saga/effects";

import TasksService from "services/tasksService";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {AxiosResponse} from "axios";
import {
    GetTasksGantErrorAction,
    GetTasksGantStartAction,
    GetTasksGantSuccessAction
} from "store/tasks/actions/gant-kanban";
import { ProjectTypes } from "entities/project";
import ZodParser from 'shared/services/zod-parser';


export function* getTasksGant({projectId, payment}: ReturnType<typeof GetTasksGantStartAction>) {
    try {
        const {data}: AxiosResponse<ProjectTypes.GanttSprint[]> = yield TasksService.getTasksGant(projectId, payment);
        ZodParser.parse(ProjectTypes.GanttSprintSchema.array(), data, '* getTasksGant')
        yield put(GetTasksGantSuccessAction(data))
    } catch (error: any) {
        yield put(GetTasksGantErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить задачи'))
    }
}