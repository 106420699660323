import {InferValueTypes} from 'models/common'
import * as actions from '../actions/actions-notifications'
import {OptionsNotificationsActionsTypes} from '../action-types'
import {ErrorActionState, StartActionState, SuccessActionState} from 'utils/reducerHalper'
import {
	TNotificationChannels,
	TNotificationFilter,
	TNotificationResponse,
} from 'models/notifications'
import {Logout} from "../../auth/reducer";
import {AuthActionTypes} from "../../auth/action-types";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TOptionsNotificationsState = {
	notifications: TNotificationResponse[] | null
	notification: TNotificationResponse | null
	notificationChannels: TNotificationChannels[] | null
	activeNotificationChannels: TNotificationChannels[] | null
	filter: TNotificationFilter
	delay: string[] | null
	isLoading: boolean
	error: any
}

export const initialState: TOptionsNotificationsState = {
	notifications: null,
	notification: null,
	notificationChannels: null,
	activeNotificationChannels: null,
	delay: null,
	filter: {
		filterData:{},
		sort: {field: "name", direction: "ASC"}
	},
	isLoading: false,
	error: null,
}

export default function optionsNotificationsReducer(
	state: TOptionsNotificationsState = initialState,
	action: ActionTypes | Logout
): TOptionsNotificationsState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return {...initialState}
		case OptionsNotificationsActionsTypes.SET_NOTIFICATION_FILTER:
			return { ...state, filter: action.filter}
		case OptionsNotificationsActionsTypes.CLEAR_BULK_ACTIONS:
			return { ...state, filter: {...state.filter, filterData: {...state.filter.filterData, notificationBulkAction: undefined}}}
		/** START ACTIONS  */
		case OptionsNotificationsActionsTypes.GET_NOTIFICATIONS_START:
			return StartActionState(state)
		case OptionsNotificationsActionsTypes.GET_ACTIVE_NOTIFICATIONS_CHANNELS_START:
			return StartActionState(state)

		/** SUCCESS ACTIONS  */
		case OptionsNotificationsActionsTypes.GET_NOTIFICATIONS_SUCCESS:
			return {
				...SuccessActionState(state),
				notifications: action.payload,
			}
		case OptionsNotificationsActionsTypes.GET_ACTIVE_NOTIFICATIONS__CHANNELS_SUCCESS:
			return {...SuccessActionState(state), activeNotificationChannels: action.data}

		case OptionsNotificationsActionsTypes.GET_NOTIFICATIONS_ERROR:
			return ErrorActionState(state, action)
		case OptionsNotificationsActionsTypes.GET_ACTIVE_NOTIFICATIONS__CHANNELS_ERROR:
			return ErrorActionState(state, action)
		default:
			return state
	}
}
