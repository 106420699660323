import React, {MouseEvent, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
//antd
import {Button, Radio, RadioChangeEvent, Select, Spin} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
//services
//features
import {ExtendedSearchModel, ExtendedSearchTypes} from 'features/extended-search';
import {Fields, Stores} from 'features/extended-search/types';
//others
import {useQuery} from "react-query";
import {AwardsApi} from 'entities/award';

const {Option} = Select;

function useGetsData() {
    const awards = useQuery({
        queryKey: [AwardsApi.getAll.key],
        queryFn: AwardsApi.getAll.fetchOptions,
    })

    const awardsName = useQuery({
        queryKey: [AwardsApi.getFieldsData.key('NAME')],
        queryFn: () => AwardsApi.getFieldsData.fetchOptions('NAME'),
    })

    const awardsYear = useQuery({
        queryKey: [AwardsApi.getFieldsData.key('YEAR')],
        queryFn: () => AwardsApi.getFieldsData.fetchOptions('YEAR'),
    })

    const awardsNomination = useQuery({
        queryKey: [AwardsApi.getFieldsData.key('NOMINATION')],
        queryFn: () => AwardsApi.getFieldsData.fetchOptions('NOMINATION'),
    })

    const awardsStatus = useQuery({
        queryKey: [AwardsApi.getFieldsData.key('STATUS')],
        queryFn: () => AwardsApi.getFieldsData.fetchOptions('STATUS'),
    })

    return {
        loading: [
            awards.isLoading,
            awardsName.isLoading,
            awardsYear.isLoading,
            awardsNomination.isLoading,
            awardsStatus.isLoading].some(Boolean),
        options: {
            AWARD: awards.data || [],
            AWARD_NAME: awardsName.data || [],
            AWARD_YEAR: awardsYear.data || [],
            AWARD_STATUS: awardsStatus.data || [],
            AWARD_NOMINATION: awardsNomination.data || [],
        }
    }
}
const optionsFields: Array<{label: string, value: Stores.AwardsKeys | 'Параметр неважен'}> = [
    {label: 'Параметр неважен', value: 'Параметр неважен'},
    {label: 'Награды', value: Fields.FieldsEnums.AWARD},
    {label: 'Наименование награды', value: Fields.FieldsEnums.AWARD_NAME},
    {label: 'Год награды', value: Fields.FieldsEnums.AWARD_YEAR},
    {label: 'Статус награды', value: Fields.FieldsEnums.AWARD_STATUS},
    {label: 'Номинация награды', value: Fields.FieldsEnums.AWARD_NOMINATION},
];

export const AwardsItem = () => {
    const put = useDispatch();
    const {loading, options} = useGetsData();
    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const awards = useSelector(ExtendedSearchModel.selectors.awards)

    const disabledOptions = useMemo(() => {
        return awards[typeContent].fields.map((el) => el.field)
    }, [awards, typeContent]);

    const onGroupChange = (index: number) => (e: RadioChangeEvent) => {
        const arr = [...awards[typeContent].fields];
        arr[index].isAndOrBesides = e.target.value;
        put(ExtendedSearchModel.actions.setAwards(typeContent, arr))
    }

    const onSelectChangeType = (index: number) => (value: ExtendedSearchTypes.Stores.AwardsKeys | 'Параметр неважен') => {
        const newArray = [...awards[typeContent].fields];
        newArray[index]["field"] = value;
        newArray[index]["values"] = [];
        put(ExtendedSearchModel.actions.setAwards(
            typeContent,
            newArray
        ))
    }
    const onSelectChangeValue = (index: number) => (value: ExtendedSearchTypes.Fields.FieldFilter["values"]) => {
        const newArray = [...awards[typeContent].fields];
        newArray[index]["values"] = value;
        put(ExtendedSearchModel.actions.setAwards(
            typeContent,
            newArray
        ))
    }
    const addGroup = () => {
        put(ExtendedSearchModel.actions.setAwards(typeContent, [
            ...awards[typeContent].fields,
            {field: 'Параметр неважен', isAndOrBesides: false, values: []}
        ]))
    }

    const onRemoveHandler = (index: number) => (_event: MouseEvent<HTMLDivElement>) => {
        const newArray = [...awards[typeContent].fields];
        newArray.splice(index, 1)
        put(ExtendedSearchModel.actions.setAwards(
            typeContent,
            newArray
        ))
    }
    return (
        <Spin spinning={loading}>
            <div className="item-wrapper" key={typeContent}>
                {awards[typeContent].fields.map((item, index) => (
                    <div className="item-wrapper__group" key={item.field + index}>
                        <div className="item-wrapper__items">
                            <div className="item">
                                <div className="form-group">
                                    <Select
                                        value={item.field}
                                        onChange={onSelectChangeType(index)}
                                        placeholder={'Параметр неважен'}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {optionsFields.map((el) => (
                                            <Option
                                                disabled={disabledOptions.includes(el.value)}
                                                value={el.value}
                                                key={el.value}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="or">
                                <Radio.Group value={item.isAndOrBesides} onChange={onGroupChange(index)}>
                                    <Radio value={true}>И</Radio>
                                    <Radio value={false}>Или</Radio>
                                </Radio.Group>
                            </div>
                            <div className="item item-second">
                                <div className="form-group">
                                    <Select
                                        allowClear={true}
                                        mode={"multiple"}
                                        disabled={item.field === 'Параметр неважен'}
                                        maxTagCount={"responsive"}
                                        value={item.values}
                                        onChange={onSelectChangeValue(index)}
                                        placeholder={'Параметр неважен'}
                                        filterOption={(input, option) => {
                                            if (option === undefined || option === null) return false;
                                            return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {item.field !== 'Параметр неважен' && options[item.field].map((el) => (
                                            <Option
                                                value={el.value}
                                                key={el.value}
                                                data-name={el.label}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {awards[typeContent].fields.length > 1 && (
                            <div className="item__remove" onClick={onRemoveHandler(index)}>
                                <DeleteOutlined />
                            </div>
                        )}
                    </div>
                ))}
                {awards[typeContent].fields.length < optionsFields.length - 1 && (
                    <div className="btn">
                        <Button type={'link'} onClick={addGroup}>+ Добавить условие</Button>
                    </div>
                )}
            </div>
        </Spin>
    )
}