import { useQuery, UseQueryResult } from 'react-query'
import { useMemo } from 'react'
import {getContentProjectTags, getNotNationalProjectTags, getProjectTags} from '../api'
import { IOptions } from 'models/feels'
import { Tag } from '../types'
import {transformArrayIdNameForValue} from "../../../utils/transformObjectFeels";

type onSuccessType = (data: Tag[]) => void
type paramsType = { level?: number; isForNationalProject?: boolean, isAssignment?: boolean }

export function useGetProjectTags(params?: paramsType, onSuccess?: onSuccessType): UseQueryResult<Tag[]> {
	return useQuery({
		queryKey: [getProjectTags.key, params],
		queryFn: () => getProjectTags.fetch(params),
		onSuccess,
	})
}
export function useGetProjectTagsOptions(params?: paramsType, onSuccess?: onSuccessType) {
	const { data = [], ...rest } = useGetProjectTags(params, onSuccess)
	const options = useMemo(
		() => data.map((el) =>
					({
						value: el.id,
						label: `${el.name} (${el.level}-уровень)`,
						hidden: el.hidden,
					} as IOptions)
			),
		[data]
	)
	return { data: options, ...rest }
}

export function useGetContentProjectTags(params?: Omit<paramsType, 'isForNationalProject'>, onSuccess?: onSuccessType): UseQueryResult<Tag[]> {
	return useQuery({
		queryKey: [getContentProjectTags.key, params],
		queryFn: () => getContentProjectTags.fetch(params),
		onSuccess,
	})
}
export function useGetContentProjectTagsOptions(params?: Omit<paramsType, 'isForNationalProject'>, onSuccess?: onSuccessType) {
	const { data = [], ...rest } = useGetContentProjectTags(params, onSuccess)
	const options = useMemo(
		() => data.map((el) =>
			({
				value: el.id,
				label: `${el.name} (${el.level}-уровень)`,
				hidden: el.hidden,
			} as IOptions)
		),
		[data]
	)
	return { data: options, ...rest }
}

type QueryParams = {
	level: number;
	projectId: string,
	isAssignment?: boolean
	enabled?: boolean
}
export function useGetNotNationalProjectTags({projectId, level, isAssignment, enabled}: QueryParams, onSuccess?: onSuccessType): UseQueryResult<Tag[]> {
	return useQuery({
		queryKey: [getNotNationalProjectTags.key(projectId), {level, isAssignment}],
		queryFn: () => getNotNationalProjectTags.fetch({projectId, level, isAssignment}),
		enabled: enabled !== undefined ? enabled : true,
		onSuccess,
	})
}
export function useGetNotNationalProjectTagsOptions(data: QueryParams, onSuccess?: onSuccessType) {
	const { data: tags = [], ...rest } = useGetNotNationalProjectTags(data, onSuccess)
	const options = useMemo(() => transformArrayIdNameForValue(tags), [tags]);
	return { data: options, ...rest }
}