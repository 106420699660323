import React, {lazy} from "react";
import {useSelector} from "react-redux";
import {Redirect, Switch} from "react-router-dom";
import {selectRole} from "store/auth/selectors";
import CreateRouting from "routing/create-routing";
import {TASKS} from "routing/names";
import {Routes} from "models/routes";
import SuspenseComponent from "components/suspense";

const TasksPage = lazy(() => import('pages/tasks/tasks-page'))
const TasksProjectsPage = lazy(() => import('pages/tasks/tasks-projects-page'))
const TasksRecordsPage = lazy(() => import('pages/tasks/tasks-records-page'))
const TaskPage = lazy(() => import('pages/tasks/task-page'))
const CreateTaskPage = lazy(() => import('pages/tasks/create-task-page'))
const TaskSettingsPage = lazy(() => import('pages/tasks/task-settings'))

const tasksChildRouts: Routes[] = [
    {path: TASKS.TASKS, Component: TasksPage, name: 'Задачи',},
    {path: TASKS.TASKS_PROJECTS, Component: TasksProjectsPage,},
    {path: TASKS.TASKS_RECORDS, Component: TasksRecordsPage},
    {
        path: TASKS.TASKS_SETTINGS,
        Component: TaskSettingsPage,
        roleHasAccess: [
            'ROLE_ANALYST',
            'ROLE_ADMIN',
            'ROLE_PROJECT_MANAGER',
            'ROLE_ADMIN_IS',
            'ROLE_PRODUCER',
        ]
    },
]

const TaskRouting = () => {
    const userRole = useSelector(selectRole)

    return (
        <SuspenseComponent>
            <TasksPage/>
            <Switch>
                {CreateRouting(tasksChildRouts, userRole)}
                <Redirect to={TASKS.TASKS_PROJECTS}/>
            </Switch>
        </SuspenseComponent>
    )
}

const disabledFieldsOptions: Routes['roleHasNotAccess'] = [
  'ROLE_COORDINATOR',
  'ROLE_CONTRACTOR',
  'ROLE_LAWYER',
  'ROLE_CLIENT',
  'ROLE_VIEWER',
  'ROLE_AGENT',
  'ROLE_PROJECT_MANAGER',
];

const tasksRoutes: Routes[] = [
    {
        path: TASKS.TASKS,
        Component: TaskRouting,
        name: 'Задачи',
        showInMenu: true,
        exact: false,
        roleHasNotAccess: [
          'ROLE_CLIENT',
          'ROLE_VIEWER',
          'ROLE_AGENT'
        ],
    },
    {
        path: TASKS.TASK,
        Component: TaskPage,
        name: 'Задача',
        roleHasNotAccess: [
            'ROLE_CLIENT',
            'ROLE_VIEWER',
            'ROLE_AGENT'
        ],
    },
    {
        path: TASKS.CREATE_TASKS,
        Component: CreateTaskPage,
        name: 'Создание задачи',
        roleHasNotAccess: disabledFieldsOptions
    },
    {
        path: TASKS.TASK_EDIT,
        Component: CreateTaskPage,
        name: 'Редактирование задачи',
        roleHasNotAccess: [
            'ROLE_CLIENT',
            'ROLE_VIEWER',
            'ROLE_AGENT',
            'ROLE_CONTRACTOR'
        ],
    },
]

export default tasksRoutes;