import {put} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import {
    DeleteUserErrorAction,
    DeleteUserStartAction,
    DeleteUserSuccessAction,
    GetUsersListStartAction,
} from 'store/options/actions/actions-users'
import UsersService from 'services/usersService'
import CreateErrorMessage from "utils/validation/createErrorMessage";

export function* deleteUser({ userId }: ReturnType<typeof DeleteUserStartAction>) {
	try {
		yield UsersService.deleteUser(userId)
		yield put(DeleteUserSuccessAction())
		yield put(NotificationOpen('success', 'Пользователь удален'))
		yield put(GetUsersListStartAction())
	} catch (error: any) {
		yield put(DeleteUserErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить пользователя'))
	}
}
