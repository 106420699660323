import {InferValueTypes, T_ID_NAME_CODE} from 'models/common'
import * as actions from './actions'
import {ProjectReportingActionTypes} from './action-types'
import {ErrorActionState, StartActionState, SuccessActionState} from 'utils/reducerHalper'
import {AuthActionTypes} from "../auth/action-types";
import {Logout} from "../auth/reducer";
import {TAvailableReportsResponse} from "models/projects/projects-reporting";
import {ReportTypes} from 'entities/reports'

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

const filterValue = {
	page: 1,
	pageSize: 5,
}

export type TReportingState = {
	reporting: ReportTypes.ReportsResponse | null
	reportsTemplates: T_ID_NAME_CODE[] | null
	availableReports: TAvailableReportsResponse | null
	filter: ReportTypes.Filter
	isLoading: boolean
	error: any
}

export const initialState: TReportingState = {
	reporting: null,
	reportsTemplates: null,
	availableReports: null,
	filter: filterValue,
	isLoading: false,
	error: null,
}

export default function reducer(state: TReportingState = initialState, action: ActionTypes | Logout): TReportingState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return {...initialState}
		case ProjectReportingActionTypes.SET_REPORTING_FILTER:
			return {
				...state,
				filter: action.filter,
			}
		case ProjectReportingActionTypes.SET_REPORTING_CLEAR_FILTER:
			return {
				...state,
				filter: filterValue,
			}

		/** START ACTIONS  */
		case ProjectReportingActionTypes.GET_ALL_REPORTING_START:
			return StartActionState(state)
		case ProjectReportingActionTypes.DOWNLOAD_REPORTING_START:
			return StartActionState(state)
		case ProjectReportingActionTypes.GET_AVAILABLE_REPORTS_START:
			return StartActionState(state)
		case ProjectReportingActionTypes.GET_REPORTS_TEMPLATES_START:
			return StartActionState(state)
		case ProjectReportingActionTypes.DOWNLOAD_FILE_ARCHIVE_START:
			return StartActionState(state)

		/** SUCCESS ACTIONS  */
		case ProjectReportingActionTypes.GET_ALL_REPORTING_SUCCESS:
			return {
				...SuccessActionState(state),
				reporting: action.data,
			}
		case ProjectReportingActionTypes.GET_AVAILABLE_REPORTS_SUCCESS:
			return {
				...SuccessActionState(state),
				availableReports: action.data,
			}
		case ProjectReportingActionTypes.DOWNLOAD_REPORTING_SUCCESS:
			return SuccessActionState(state)
		case ProjectReportingActionTypes.GET_REPORTS_TEMPLATES_SUCCESS:
			return {
				...SuccessActionState(state),
				reportsTemplates: action.data,
			}
		case ProjectReportingActionTypes.DOWNLOAD_FILE_ARCHIVE_SUCCESS:
			return SuccessActionState(state)

		/** ERROR ACTIONS  */
		case ProjectReportingActionTypes.DOWNLOAD_REPORTING_ERROR:
			return ErrorActionState(state, action)
		case ProjectReportingActionTypes.GET_ALL_REPORTING_ERROR:
			return ErrorActionState(state, action)
		case ProjectReportingActionTypes.GET_AVAILABLE_REPORTS_ERROR:
			return ErrorActionState(state, action)
		case ProjectReportingActionTypes.GET_REPORTS_TEMPLATES_ERROR:
			return ErrorActionState(state, action)
		case ProjectReportingActionTypes.DOWNLOAD_FILE_ARCHIVE_ERROR:
			return ErrorActionState(state, action)
		default:
			return state
	}
}
