import React from 'react';
import { DuplicateSchedule } from '../../types';
import { Spin, Switch } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { TaskDuplicationSchedulerController } from 'features/сonfiguring-task-autocreation/api';

const SwitchCell = (enabled: boolean, record: DuplicateSchedule) => {
	const queryClient = useQueryClient();
	const {mutate: enabledHandler, isLoading} = useMutation({
		mutationFn: TaskDuplicationSchedulerController.enabledSchedulers,
		onSuccess: async () => {
			await queryClient.invalidateQueries([TaskDuplicationSchedulerController.search.key])
		},
	})
	const onChange = (enabled: boolean) => {
		enabledHandler({ taskDuplicationScheduleId: record.id, enabled })
	}
	return (
		<Spin spinning={isLoading}>
			<div className={`form-group switch-cell`}>
				<Switch
					onChange={onChange}
					checked={enabled}
				/>
				<small>{enabled ? 'Включено' : 'Выключено'}</small>
			</div>
		</Spin>
	)
};

export default SwitchCell;