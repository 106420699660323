import {TasksGantAndKanbanActionTypes} from "../action-types/gant-and-kanban";

import {ErrorActionState, StartActionState, SuccessActionState} from "utils/reducerHalper";

import {InferValueTypes} from "models/common";

import {TTasksKanban, TTasksKanbanFilter} from "models/task/task.kanban";
import * as actions from '../actions/gant-kanban'
import {Logout} from "../../auth/reducer";
import {AuthActionTypes} from "../../auth/action-types";
import { ProjectTypes } from "entities/project";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TTasksGantKanbanState = {
    tasksGant: ProjectTypes.GanttSprint[] | null
    tasksKanban: TTasksKanban | null
    kanbanFilter: TTasksKanbanFilter
    cardIds: string[]
    isLoading: boolean
    error: any
}

export const initialState: TTasksGantKanbanState = {
    tasksGant: null,
    tasksKanban: null,
    kanbanFilter: {
        filterData: {
            searchString: null,
            stageId: null
        }
    },
    cardIds: [],
    isLoading: false,
    error: null
}

export default function tasksGantKanbanReducer(state: TTasksGantKanbanState = initialState, action: ActionTypes | Logout): TTasksGantKanbanState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState}
        case TasksGantAndKanbanActionTypes.SET_CARD_IDS:
            return {...state, cardIds: action.cardIds}
        case TasksGantAndKanbanActionTypes.CLEAR_CARD_IDS:
            return {...state, cardIds: []}
        case TasksGantAndKanbanActionTypes.CLEAR_BULK_ACTIONS:
            return {...state, kanbanFilter: {
                ...state.kanbanFilter,
                    filterData: {
                    ...state.kanbanFilter.filterData,
                        bulkAction: undefined
                    }
                }}
        case TasksGantAndKanbanActionTypes.GET_TASKS_GANT_START:
            return {...StartActionState(state), tasksGant: null};
        case TasksGantAndKanbanActionTypes.GET_TASKS_KANBAN_START:
            return {...StartActionState(state), tasksKanban: null};

        case TasksGantAndKanbanActionTypes.GET_TASKS_GANT_SUCCESS:
            return {
                ...SuccessActionState(state),
                tasksGant: action.tasksGant
            };
        case TasksGantAndKanbanActionTypes.GET_TASKS_KANBAN_SUCCESS:
            return {
                ...SuccessActionState(state),
                tasksKanban: action.tasksKanban
            };
        case TasksGantAndKanbanActionTypes.SET_TASKS_KANBAN_FILTER:
            return {
                ...state,
                kanbanFilter: action.term
            }
        case TasksGantAndKanbanActionTypes.CLEAR_TASKS_KANBAN_FILTER: {
            return {
                ...state,
                kanbanFilter: initialState.kanbanFilter
            }
        }
        case TasksGantAndKanbanActionTypes.GET_TASKS_GANT_ERROR:
            return ErrorActionState(state, action)
        case TasksGantAndKanbanActionTypes.GET_TASKS_KANBAN_ERROR:
            return ErrorActionState(state, action)
        default:
            return state
    }
}