import { FunctionalityInfoType } from '../types'

export const projectCalendar: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр данных диаграммы этапов контента',
		LevelGroupData: [
			{
				name: 'Сортировка диаграммы',
				text: 'Нажмите для сортировки отображения календаря проекта по месяцам / неделям / дням',
				screenshots: ['/images/screenshots/project-calendar/sorting.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Индикатор срока выполнения задачи',
				text: 'Наведите курсор на индикатор для просмотра статуса:',
				points: [
					{
						label: 'Серый - узнайте количество дней до окончания задачи',
						icon: 'light-gray'
					},
					{
						label: 'Красный - срок задачи просрочен',
						icon: 'light-red'
					}
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр информации на диаграмме',
				text: 'Наведите курсор на название этапа / задачи на диаграмме для просмотра дополнительной информации',
				screenshots: ['/images/screenshots/project-calendar/sprint.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Создание задачи этапа проекта',
		LevelGroupData: [
			{
				name: 'Создание задачи по кнопке "Создать задачу"',
				points: [
					'Нажмите "Создать задачу" для перехода в форму создания задачи',
					'Заполните обязательные и доступные поля формы на 1-3 вкладках',
					'Нажмите “Сохранить”',
				],
				screenshots: ['/images/screenshots/create-task.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Создание задачи этапа проекта',
				points: [
					'Нажмите на плюс для перехода в форму создания задачи',
					'Заполните обязательные и доступные поля формы на 1-3 вкладках',
					'Нажмите “Сохранить”',
				],
				screenshots: ['/images/screenshots/project-calendar/plus.png', '/images/screenshots/task-form.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование задачи',
		LevelGroupData: [
			{
				name: 'Редактирование задачи',
				points: [
					'Нажмите для перехода в форму и редактирования данных задачи',
					'Заполните обязательные и доступные поля формы на 1-3 вкладках',
					'Нажмите “Сохранить”',
				],
				screenshots: ['/images/screenshots/edit-task.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Удаление задачи',
				text: 'Нажмите для удаления задачи',
				icons: ['delete-red'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Настройка этапов проекта',
		LevelGroupData: [
			{
				name: 'Переход во вкладку редактирования этапов проекта',
				text: 'Нажмите на кнопку "Настроить этапы" для перехода во вкладку редактирования этапов',
				screenshots: ['/images/screenshots/project-calendar/button-sprint.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_COORDINATOR', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Добавление нового этапа проекта',
				text: 'Для добавления нового этапа:',
				points: [
					'Нажмите на кнопку "Добавить данные"',
					'Заполните поля формы',
					'Нажмите на галочку для сохранения формы',
				],
				screenshots: ['/images/screenshots/project-calendar/add.png', '/images/screenshots/project-calendar/table.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_COORDINATOR', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Редактирование этапа проекта',
				points: ['Нажмите для редактирования этапа', 'Заполните поля формы', 'Нажмите на галочку для сохранения формы'],
				topIcons: ['edit-blue'],
				screenshots: ['/images/screenshots/project-calendar/table.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_COORDINATOR', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Удаление этапа проекта',
				text: 'Нажмите для удаления этапа',
				icons: ['delete-red'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_COORDINATOR', 'ROLE_PROJECT_MANAGER'],
			},
		],
	},
	{
		LevelGroupName: 'Работа в окне формирования отчетности',
		LevelGroupData: [
			{
				name: 'Переход в модальное окно формирования отчетности',
				text: 'Нажмите для перехода в окно формирования отчетности по проекту',
				screenshots: ['/images/screenshots/project-calendar/button-report-modal.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_COORDINATOR', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Формирование отчета',
				text: 'Для формирования отчета:',
				points: [
					'Заполните поля формы в окне формирования отчетности',
					'Нажмите "Сформировать"',
					'Дождитесь окончания формирования отчета: статус формирования отчета "Created"',
				],
				screenshots: ['/images/screenshots/project-calendar/modal-report.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_COORDINATOR', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Скачивание готового отчета',
				text: 'Нажмите на кнопку скачивания по окончании формирования отчета',
				screenshots: ['/images/screenshots/project-calendar/created.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_COORDINATOR', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Переход на следующую страницу в форме получения отчетов',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_COORDINATOR', 'ROLE_PROJECT_MANAGER'],
			},
		],
	},
	{
		LevelGroupName: 'Настройка этапов проекта',
		LevelGroupData: [
			{
				name: 'Переход во вкладку "Этапы проекта"',
				text: 'Нажмите "Открыть диаграмму" для перехода во вкладку "Этапы проекта"',
				screenshots: ['/images/screenshots/project-calendar/button-open.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_COORDINATOR', 'ROLE_PROJECT_MANAGER'],
			},
		],
	},
]
