import React, {FC, useState} from 'react';
import './modal-delete-project.less'
import {Button, Input, Modal} from "antd";
import useHandlerModal from "hooks/handlModal";
import {DeleteProjectStartAction} from "store/projects/actions";
import {NotificationOpen} from "store/notification/actions";

type ModalDeleteProjectProps = {
    status: boolean
    data: string
}
const ModalDeleteProject: FC<ModalDeleteProjectProps> = ({status, data: projectId}) => {
    const [state, setState] = useState('');
    const {isVisible, closeModal, put} = useHandlerModal(status);

    const deleteHandler = (e:any) => {
        e.preventDefault()
        if (projectId && state === 'УДАЛИТЬ') {
            put(DeleteProjectStartAction(projectId));
            closeModal();
        } else {
            put(NotificationOpen('warning', 'Ошибка валадации', `Необходимо ввести (удалить), вы ввели ${state}`))
            setState('')
        }
    }
    return (
        <Modal
            className='modal-delete-project'
            title="Подтвердите действие"
            visible={isVisible}
            onCancel={closeModal}
            footer={(
                <>
                    <Button type={"default"} onClick={closeModal}>Отменить</Button>
                    <Button type={"primary"} onClick={deleteHandler}>Подтвердить</Button>
                </>
            )}
            maskClosable={true}
        >
            <div className='modal-delete-project__content'>
                <span>Удаление приведет к необратимым последствиям, потере всех связанных операций и настроек.</span>
                <strong>Введите слово УДАЛИТЬ для подтверждения действия</strong>
                <form onSubmit={deleteHandler}>
                    <Input type={"text"} placeholder={'УДАЛИТЬ'} value={state} onChange={event => {
                        setState(event.target.value)
                    }}/>
                </form>
            </div>
        </Modal>
    )
};
export default ModalDeleteProject;