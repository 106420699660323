import { Error } from '../../shared/types/error';

export default class CreateErrorMessage {
	static form(errorsObj: any) {
		let errors: string = '';
		const arr = Object.values(errorsObj);

		function pushError(error: string, index: number, length: number, lower?: boolean) {
			const dot = index !== length - 1 ? ', ' : '.';
			const textError = index !== 0 || lower ? error.toLowerCase() : error;
			errors += `${textError}${dot}`
		}
		arr.forEach((el: any, idx) => {
			if (typeof el === 'string') {
				pushError(el, idx, arr.length)
			} else {
				//проверка для ошибок большей вложенности
				const [obj] = el.length && el.filter((it: any) => Boolean(it))
				if (obj) {
					const arr = Object.values(obj)
					arr.forEach((el: any, idx) => {
						pushError(el, idx, arr.length, true)
					})
				}
			}
		})
		return errors
	}

	static response(error: any) {
		return typeof error === 'string' ? error
			: error?.response?.data?.message
	}
	static responseQuery(error: Error): string {
		if (typeof error.data === 'string') return error.data
		return error.data.message;
	}

	static responseUseTus(error: any): string {
		const errorArray = error?.message.match(/{([^}]*)}/)
		let text = ''
		if (errorArray?.length) {
			try {
				text = errorArray[0].split('"')[3]
			} catch (e) {
				text = 'Что-то пошло не так'
			}
		}
		return text
	}
}

export class CreateTaskErrorMessage {
	static form(errorsObj: any) {
		const stepFirstArr = errorsObj.stepFirst ? Object.values(errorsObj.stepFirst) : []
		const stepSecondArr = errorsObj?.stepSecond ? Object.values(errorsObj?.stepSecond) : []
		const stepThirdArr = errorsObj?.stepThird ? Object.values(errorsObj?.stepThird) : []

		let errors: string = ''
		stepFirstArr.forEach((el: any, idx) => {
			if (idx !== stepFirstArr.length - 1) errors += `${el.value}, `
			else errors += `${el.value}.`
		})
		stepSecondArr.forEach((el: any, idx) => {
			if (idx !== stepSecondArr.length - 1) errors += `${el.value}, `
			else errors += `${el.value}.`
		})
		stepThirdArr.forEach((el: any, idx) => {
			if (idx !== stepThirdArr.length - 1) errors += `${el.value}, `
			else errors += `${el.value}.`
		})
		return errors
	}
}
