import { z } from "zod";
import { VoteSchema } from '../vote';
import { TasksRecordStatusEnum } from '../record-status';

export const PostingRecordsSchema = z.object({
	id: z.string(),
	planPublicationDate: z.string().nullable(),
	actualPublicationDate: z.string().nullable(),
	workingName: z.string().nullable(),
	publishedName: z.string().nullable(),
	contentTaskRecord: z.object({ id: z.string(), name: z.string() }).nullable(),
	link: z.string().nullable(),
	resource: z.object({
		id: z.string(),
		name: z.string(),
		domainLink: z.string(),
		isLocal: z.boolean(),
		type: z.object({ id: z.string(), code: z.string(), name: z.string() }).nullable(),
		hidden: z.boolean(),
		image: z.string()
	}).nullable(),
	contentUnitDimension: z.string().nullable(),
	screenshots: z.array(
		z.object({
			id: z.string(),
			compressedId: z.string(),
			scaledId: z.string(),
			name: z.string(),
			type: z.string(),
			storagePath: z.string(),
			previewFile: z.string().nullable(),
			size: z.number().nullable()
		})
	),
	screenshotDate: z.string().nullable(),
	status: TasksRecordStatusEnum,
	votes: z.array(VoteSchema).nullable(),
	lastTaskRecordWithVotesResponse: z.object({
		id: z.string(),
		votes: z.array(VoteSchema).nullable(),
	}),
	ageCategory: z.string().nullable(),
	isOriginal: z.boolean(),
	isReplaced: z.boolean(),
	task: z.object({
		deadline: z.string(),
		number: z.number(),
		id: z.string(),
		name: z.string(),
		code: z.string(),
	}).optional()
});
export type PostingRecords = z.infer<typeof PostingRecordsSchema>