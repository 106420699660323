import React, { ChangeEvent, FC, useState } from 'react'
import styles from './styles.module.less'
import { Input, Tooltip } from 'antd'
import { FieldTimeOutlined } from '@ant-design/icons'
import { calculateTotalSeconds, secondsToTimeString } from 'utils/convertTime'
import { useDispatch, useSelector } from 'react-redux'
import { ExtendedSearchModel } from 'features/extended-search'

type TProps = {
	item: any
	index: number
}
const ContentTiming: FC<TProps> = ({ item, index }) => {
	const put = useDispatch()
	const { typeContent } = useSelector(ExtendedSearchModel.selectors.main)
	const counts = useSelector(ExtendedSearchModel.selectors.counts)

	const [stateMin, setStateMin] = useState<string | null | undefined>(secondsToTimeString(item?.minValue || 0))
	const [stateMax, setStateMax] = useState<string | null | undefined>(secondsToTimeString(item?.maxValue || 0))

	const onInputChange = (index: number, field: 'minValue' | 'maxValue') => (value: string | number | null | undefined) => {
			const newArray = [...counts[typeContent].fields]
			newArray[index][field] = String(value)
			put(ExtendedSearchModel.actions.setCounts(typeContent, newArray))
		}
	const onBlurMin = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		if (!value) return null

		let digitsOnly = value.replace(/\D/g, '')
		const hours = digitsOnly.slice(0, 2)
		const minutes = digitsOnly.slice(2, 4)
		const seconds = digitsOnly.slice(4, 6)

		if (digitsOnly.length <= 2) {
			setStateMin(digitsOnly.padStart(2, '0') + '0000')
		} else if (digitsOnly.length <= 4) {
			setStateMin(`${hours.padStart(2, '0')}${validateTime(minutes.padStart(2, '0'))}${'00'}`)
		} else if (digitsOnly.length <= 6) {
			setStateMin(`${hours}${validateTime(minutes)}${validateTime(seconds.padStart(2, '0'))}`)
		} else {
			setStateMin(`${hours}${validateTime(minutes)}${validateTime(seconds)}`)
		}
		const secondsTimeMin = getSeconds(digitsOnly)
		const secondsTimeMax = getSeconds(stateMax)

		if (secondsTimeMax && secondsTimeMin && secondsTimeMin > secondsTimeMax) {
			setStateMin(stateMax)
			onInputChange(index, 'minValue')(stateMax)
		}
	}

	const onBlurMax = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		if (!value) return null

		let digitsOnly = value.replace(/\D/g, '')
		const hours = digitsOnly.slice(0, 2)
		const minutes = digitsOnly.slice(2, 4)
		const seconds = digitsOnly.slice(4, 6)

		if (digitsOnly.length <= 2) {
			setStateMax(digitsOnly.padStart(2, '0') + '0000')
		} else if (digitsOnly.length <= 4) {
			setStateMax(`${hours.padStart(2, '0')}${validateTime(minutes.padStart(2, '0'))}${'00'}`)
		} else if (digitsOnly.length <= 6) {
			setStateMax(`${hours}${validateTime(minutes)}${validateTime(seconds.padStart(2, '0'))}`)
		} else {
			setStateMax(`${hours}${validateTime(minutes)}${validateTime(seconds)}`)
		}

		const secondsTimeMax = getSeconds(digitsOnly)
		const secondsTimeMin = getSeconds(stateMin)

		if (secondsTimeMax && secondsTimeMin && secondsTimeMin > secondsTimeMax) {
			setStateMax(stateMin)
			onInputChange(index, 'maxValue')(stateMin)
		}
	}

	const onChangeMin = (e: ChangeEvent<HTMLInputElement>) => {
		const digitsOnly = e.target.value.replace(/\D/g, '')
		const secondsTime = getSeconds(digitsOnly)
		setStateMin(e.target.value)
		onInputChange(index, 'minValue')(secondsTime)
	}
	const onChangeMax = (e: ChangeEvent<HTMLInputElement>) => {
		const digitsOnly = e.target.value.replace(/\D/g, '')
		const secondsTime = getSeconds(digitsOnly)
		setStateMax(e.target.value)
		onInputChange(index, 'maxValue')(secondsTime)
	}

	return (
		<>
			<Tooltip title={'Введите время в формате "ЧЧ:MМ:СС"'}>
				<Input
					className={styles.input}
					autoComplete="off"
					placeholder="00:00:00"
					value={formatTime(stateMin)}
					onChange={onChangeMin}
					onBlur={onBlurMin}
					suffix={<FieldTimeOutlined />}
				/>
			</Tooltip>
			<Tooltip title={'Введите время в формате "ЧЧ:MМ:СС"'}>
				<Input
					className={styles.input}
					autoComplete="off"
					placeholder="00:00:00"
					value={formatTime(stateMax)}
					onChange={onChangeMax}
					onBlur={onBlurMax}
					suffix={<FieldTimeOutlined />}
				/>
			</Tooltip>
		</>
	)
}

export default ContentTiming

function formatTime(value: string | null | undefined) {
	if (!value) return ''

	const digitsOnly = value.replace(/\D/g, '')
	const hours = digitsOnly.slice(0, 2)
	const minutes = digitsOnly.slice(2, 4)
	const seconds = digitsOnly.slice(4, 6)

	if (digitsOnly.length <= 2) {
		return digitsOnly
	} else if (digitsOnly.length <= 4) {
		return `${hours}:${validateTime(minutes)}`
	} else if (digitsOnly.length <= 6) {
		return `${hours}:${validateTime(minutes)}:${validateTime(seconds)}`
	}

	return `${hours}:${validateTime(minutes)}:${validateTime(seconds)}`
}

function getSeconds(value: any) {
	if (!value) return null

	const hours = value.slice(0, 2)
	const minutes = validateTime(value.slice(2, 4))
	const seconds = validateTime(value.slice(4, 6))
	return calculateTotalSeconds(hours, minutes, seconds)
}

function validateTime(value: string) {
	if (!value) return ''
	if (Number(value) > 60) return String(60)
	else return value
}
