import $api from "../http";
import {AxiosResponse} from "axios";
import {TTag, TTagSecondLevel} from "../models/tags";
import {THandbookContentResponse, THandbookFilter, TTagsResponse} from "../models/handbook";

export class TagsService {
    static tagsFirstLevelKey = '/project-tagging/first-lvl-tags/search'
    static async getTagsFirstLevel(data: THandbookFilter): Promise<AxiosResponse<THandbookContentResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<THandbookContentResponse>('/project-tagging/first-lvl-tags/search', filter)
    }

    static getTagsFirstLevelContent (): Promise<AxiosResponse<TTag[]>> {
        return $api.get(`/contents/first-lvl-tags`)
    }
    static tagsSecondLevelContentKey = '/contents/second-lvl-tags'
    static getTagsSecondLevelContent (firstLevelTagIds?: Array<string>): Promise<AxiosResponse<TTagSecondLevel[]>> {
        return $api.post(`/contents/second-lvl-tags`, firstLevelTagIds)
    }

    static getTagsFirstLevelTaskRecordContent(): Promise<AxiosResponse<TTag[]>> {
        return $api.get(`/tasks/content/records/first-lvl-tags`)
    }
    static tagsSecondLevelKey = '/project-tagging/second-lvl-tags/search'
    static async getTagsSecondLevel(data: THandbookFilter): Promise<AxiosResponse<TTagsResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<TTagsResponse>('/project-tagging/second-lvl-tags/search', filter)
    }

    static tagsThirdLevelKey = '/project-tagging/third-lvl-tags/search'
    static async getTagsThirdLevel(data: THandbookFilter): Promise<AxiosResponse<TTagsResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<TTagsResponse>('/project-tagging/third-lvl-tags/search', filter)
    }

    static getTagsSecondLevelProjectKey = '/project-tagging/second-lvl-tags/by-project'
    static getTagsSecondLevelProject(projectId?: string): Promise<AxiosResponse<TTagSecondLevel[]>> {
        return $api.get('/project-tagging/second-lvl-tags/by-project', {params: {projectId: projectId}})
    }
}