import {InferValueTypes, T_ID_NAME_CODE} from 'models/common'
import * as actions from '../actions/actions-handbook'
import {
	TDocumentThematicsListResponse,
	THandbookContentResponse,
	THandbookFilter,
	THandbookLegaFormOrganizationResponse,
	THandbookOrganization,
	THandbookOrganizationResponse,
	TInternetResourceResponse,
	TTHandbookContentOverAll,
} from 'models/handbook'
import {
	OptionsGeneralActionTypes,
	OptionsHandbookCategoryProject,
	OptionsHandbookGenreProject,
	OptionsHandbookLegalForm,
	OptionsHandbookRanks,
	OptionsHandbookRegions,
	OptionsHandbookSessionsType,
	OptionsHandbookTypes,
	UsersHandbookContentDeliveryTypes,
	UsersHandbookContentDirectionTypes,
	UsersHandbookDocumentsTypes,
	UsersHandbookDownloadTypes,
	UsersHandbookInternetTypes,
	UsersHandbookKPETypes,
	UsersHandbookOrganizationTypes,
	UsersHandbookThematicsTypes,
	UsersHandbookUsersActionsTypes,
} from '../action-types'
import {ErrorActionState, StartActionState, SuccessActionState} from 'utils/reducerHalper'
import {Logout} from '../../auth/reducer'
import {AuthActionTypes} from '../../auth/action-types'

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

const filterStartOverAll = {
	page: 1,
	pageSize: 50,
}

export type TUsersActionsState = {
	filterThematic: THandbookFilter
	filterInternet: THandbookFilter
	filterContentDelivery: THandbookFilter
	filterActionsUsers: THandbookFilter
	filterOrganization: THandbookFilter
	filterKPE: THandbookFilter
	filterDocuments: THandbookFilter
	filterContentDirection: THandbookFilter
	filterRegions: THandbookFilter
	filterRanks: THandbookFilter
	filterLegalForm: THandbookFilter
	filterSessionsType: THandbookFilter
	filterCategoryProject: THandbookFilter
	filterGenreProject: THandbookFilter
	contentDirection: THandbookContentResponse | null
	contentDelivery: THandbookContentResponse & { contentModuleSection: T_ID_NAME_CODE} | null
	contentDeliveryAll: TTHandbookContentOverAll[] | null
	actionsUsers: THandbookContentResponse | null
	organization: THandbookOrganizationResponse | null
	organizationId: THandbookOrganization | null
	KPE: THandbookContentResponse | null
	thematic: TDocumentThematicsListResponse | null
	internet: TInternetResourceResponse | null
	documents: TDocumentThematicsListResponse | null
	regions: THandbookContentResponse | null
	ranks: THandbookContentResponse | null
	categoryProject: THandbookContentResponse | null
	genreProject: THandbookContentResponse | null
	legalFormOrganization: THandbookLegaFormOrganizationResponse | null
	sessionsType: THandbookContentResponse | null
	contentModuleSection: T_ID_NAME_CODE[] | null

	bulkAction: string | null
	rowsIds: string[]
	editingKey: string
	isLoading: boolean
	error: any
}

const initialFilter = {
	filterThematic: filterStartOverAll,
	filterInternet: filterStartOverAll,
	filterContentDelivery: filterStartOverAll,
	filterActionsUsers: filterStartOverAll,
	filterKPE: filterStartOverAll,
	filterContentDirection: filterStartOverAll,
	filterOrganization: filterStartOverAll,
	filterDocuments: filterStartOverAll,
	filterRegions: filterStartOverAll,
	filterRanks: filterStartOverAll,
	filterLegalForm: filterStartOverAll,
	filterSessionsType: filterStartOverAll,
	filterCategoryProject: filterStartOverAll,
	filterGenreProject: filterStartOverAll,
}

export const initialState: TUsersActionsState = {
	...initialFilter,
	organizationId: null,
	organization: null,
	KPE: null,
	contentDirection: null,
	contentDelivery: null,
	contentDeliveryAll: null,
	actionsUsers: null,
	thematic: null,
	internet: null,
	documents: null,
	regions: null,
	ranks: null,
	legalFormOrganization: null,
	sessionsType: null,
	categoryProject: null,
	genreProject: null,
	contentModuleSection: null,
	rowsIds: [],
	bulkAction: null,
	editingKey: '',
	isLoading: false,
	error: null,
}

//TODO Нужно разбить на более маленькие редюсеры
export default function handbookReducer(
	state: TUsersActionsState = initialState,
	action: ActionTypes | Logout
): TUsersActionsState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return { ...initialState }
		case OptionsGeneralActionTypes.CLEAR_HANDBOOK_STORE:
			return { ...initialState, [action.keyName]: state[action.keyName]}
		case OptionsGeneralActionTypes.CLEAR_HANDBOOK_FILTER:
			return { ...state, [action.keyName]: filterStartOverAll, rowsIds: [], bulkAction: null}
		case OptionsGeneralActionTypes.CLEAR_HANDBOOK_ERROR:
			return { ...state, error: null}
		case OptionsGeneralActionTypes.SET_BULK_ACTION:
			return {
				...state,
				bulkAction: action.bulkAction
			}
		case OptionsGeneralActionTypes.SET_ROWS_IDS:
			return {
				...state,
				rowsIds: action.rows
			}
		case OptionsGeneralActionTypes.GET_CONTENT_MODULE_SECTION_START:
			return StartActionState(state)
		case UsersHandbookThematicsTypes.GET_HANDBOOK_START:
			return StartActionState(state)
		case UsersHandbookThematicsTypes.DELETE_THEMATIC_START:
			return StartActionState(state)
		case UsersHandbookThematicsTypes.UPDATE_THEMATIC_START:
			return StartActionState(state)
		case UsersHandbookThematicsTypes.ADD_THEMATIC_START:
			return StartActionState(state)
		case UsersHandbookThematicsTypes.BULK_THEMATICS_ACTIONS_START:
			return StartActionState(state)
		case UsersHandbookInternetTypes.DELETE_INTERNET_START:
			return StartActionState(state)
		case UsersHandbookInternetTypes.UPDATE_INTERNET_START:
			return StartActionState(state)
		case UsersHandbookInternetTypes.ADD_INTERNET_START:
			return StartActionState(state)
		case UsersHandbookInternetTypes.BULK_INTERNET_ACTIONS_START:
			return StartActionState(state)
		case UsersHandbookDocumentsTypes.BULK_DOCUMENTS_ACTIONS_START:
			return StartActionState(state)
		case UsersHandbookDocumentsTypes.DELETE_DOCUMENTS_START:
			return StartActionState(state)
		case UsersHandbookDocumentsTypes.ADD_DOCUMENTS_START:
			return StartActionState(state)
		case UsersHandbookDocumentsTypes.UPDATE_DOCUMENTS_START:
			return StartActionState(state)

		case OptionsHandbookLegalForm.BULK_LEGAL_FORM_ACTIONS_START:
			return StartActionState(state)
		case OptionsHandbookLegalForm.DELETE_LEGAL_FORM_START:
			return StartActionState(state)
		case OptionsHandbookLegalForm.UPDATE_LEGAL_FORM_START:
			return StartActionState(state)
		case OptionsHandbookLegalForm.ADD_LEGAL_FORM_START:
			return StartActionState(state)

		case UsersHandbookContentDeliveryTypes.DELETE_CONTENT_DELIVERY_START:
			return StartActionState(state)
		case UsersHandbookContentDeliveryTypes.UPDATE_CONTENT_DELIVERY_START:
			return StartActionState(state)
		case UsersHandbookContentDeliveryTypes.ADD_CONTENT_DELIVERY_START:
			return StartActionState(state)
		case UsersHandbookContentDeliveryTypes.BULK_CONTENT_DELIVERY_ACTIONS_START:
			return StartActionState(state)
		case UsersHandbookContentDeliveryTypes.GET_ALL_CONTENT_DELIVERY_START:
			return StartActionState(state)

		case UsersHandbookContentDirectionTypes.DELETE_CONTENT_DIRECTION_START:
			return StartActionState(state)
		case UsersHandbookContentDirectionTypes.UPDATE_CONTENT_DIRECTION_START:
			return StartActionState(state)
		case UsersHandbookContentDirectionTypes.ADD_CONTENT_DIRECTION_START:
			return StartActionState(state)
		case UsersHandbookContentDirectionTypes.BULK_CONTENT_DIRECTION_ACTIONS_START:
			return StartActionState(state)

		case UsersHandbookOrganizationTypes.BULK_ORGANIZATION_ACTIONS_START:
			return StartActionState(state)

		case UsersHandbookOrganizationTypes.GET_ORGANIZATION_START:
			return {
				...StartActionState(state),
				organizationId: null,
			}
		case UsersHandbookOrganizationTypes.DELETE_ORGANIZATION_START:
			return {
				...StartActionState(state),
				organizationId: null,
			}
		case UsersHandbookOrganizationTypes.UPDATE_ORGANIZATION_START:
			return StartActionState(state)

		case UsersHandbookDownloadTypes.DOWNLOAD_START:
			return StartActionState(state)

		case UsersHandbookDownloadTypes.DOWNLOAD_TEMPLATE_START:
			return StartActionState(state)
		case UsersHandbookDownloadTypes.UPLOAD_START:
			return StartActionState(state)

		case UsersHandbookKPETypes.DELETE_KPE_START:
			return StartActionState(state)
		case UsersHandbookKPETypes.UPDATE_KPE_START:
			return StartActionState(state)
		case UsersHandbookKPETypes.ADD_KPE_START:
			return StartActionState(state)
		case UsersHandbookKPETypes.BULK_KPE_ACTIONS_START:
			return StartActionState(state)
		case OptionsHandbookRegions.DELETE_REGIONS_START:
			return StartActionState(state)
		case OptionsHandbookRegions.UPDATE_REGIONS_START:
			return StartActionState(state)
		case OptionsHandbookRegions.ADD_REGIONS_START:
			return StartActionState(state)
		case OptionsHandbookRegions.BULK_REGIONS_ACTIONS_START:
			return StartActionState(state)

		case OptionsHandbookRanks.DELETE_RANKS_START:
			return StartActionState(state)
		case OptionsHandbookRanks.UPDATE_RANKS_START:
			return StartActionState(state)
		case OptionsHandbookRanks.ADD_RANKS_START:
			return StartActionState(state)
		case OptionsHandbookRanks.BULK_RANKS_ACTIONS_START:
			return StartActionState(state)

		case OptionsHandbookSessionsType.DELETE_SESSIONS_TYPE_START:
			return StartActionState(state)
		case OptionsHandbookSessionsType.UPDATE_SESSIONS_TYPE_START:
			return StartActionState(state)
		case OptionsHandbookSessionsType.ADD_SESSIONS_TYPE_START:
			return StartActionState(state)
		case OptionsHandbookSessionsType.BULK_SESSIONS_TYPE_ACTIONS_START:
			return StartActionState(state)

		case OptionsHandbookCategoryProject.DELETE_CATEGORY_PROJECT_START:
			return StartActionState(state)
		case OptionsHandbookCategoryProject.UPDATE_CATEGORY_PROJECT_START:
			return StartActionState(state)
		case OptionsHandbookCategoryProject.ADD_CATEGORY_PROJECT_START:
			return StartActionState(state)
		case OptionsHandbookCategoryProject.BULK_CATEGORY_PROJECT_ACTIONS_START:
			return StartActionState(state)

		case OptionsHandbookGenreProject.DELETE_GENRE_PROJECT_START:
			return StartActionState(state)
		case OptionsHandbookGenreProject.UPDATE_GENRE_PROJECT_START:
			return StartActionState(state)
		case OptionsHandbookGenreProject.ADD_GENRE_PROJECT_START:
			return StartActionState(state)
		case OptionsHandbookGenreProject.BULK_GENRE_PROJECT_ACTIONS_START:
			return StartActionState(state)

		case UsersHandbookUsersActionsTypes.UPDATE_USERS_ACTIONS_START:
			return StartActionState(state)

		/**
		 * SUCCESS ACTIONS
		 */
		case OptionsGeneralActionTypes.GET_CONTENT_MODULE_SECTION_SUCCESS:
			return {
				...SuccessActionState(state),
				contentModuleSection: action.data
				}
		case UsersHandbookThematicsTypes.GET_HANDBOOK_SUCCESS:
			return {
				...SuccessActionState(state),
				...action.data,
			}

		case UsersHandbookOrganizationTypes.GET_ORGANIZATION_SUCCESS:
			return {
				...SuccessActionState(state),
				organizationId: action.organization,
			}
		case UsersHandbookOrganizationTypes.DELETE_ORGANIZATION_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookOrganizationTypes.BULK_ORGANIZATION_ACTIONS_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookOrganizationTypes.UPDATE_ORGANIZATION_SUCCESS:
			return SuccessActionState(state)

		case UsersHandbookUsersActionsTypes.UPDATE_USERS_ACTIONS_SUCCESS:
			return SuccessActionState(state)

		case UsersHandbookDownloadTypes.DOWNLOAD_SUCCESS:
			return SuccessActionState(state)

		case UsersHandbookDownloadTypes.DOWNLOAD_TEMPLATE_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookDownloadTypes.UPLOAD_SUCCESS:
			return SuccessActionState(state)

		case UsersHandbookContentDirectionTypes.BULK_CONTENT_DIRECTION_ACTIONS_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookContentDirectionTypes.DELETE_CONTENT_DIRECTION_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookContentDirectionTypes.UPDATE_CONTENT_DIRECTION_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case UsersHandbookContentDirectionTypes.ADD_CONTENT_DIRECTION_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}

		case UsersHandbookDocumentsTypes.BULK_DOCUMENTS_ACTIONS_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookDocumentsTypes.DELETE_DOCUMENTS_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookDocumentsTypes.UPDATE_DOCUMENTS_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case UsersHandbookDocumentsTypes.ADD_DOCUMENTS_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}

		case OptionsHandbookLegalForm.BULK_LEGAL_FORM_ACTIONS_SUCCESS:
			return SuccessActionState(state)
		case OptionsHandbookLegalForm.DELETE_LEGAL_FORM_SUCCESS:
			return SuccessActionState(state)
		case OptionsHandbookLegalForm.UPDATE_LEGAL_FORM_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case OptionsHandbookLegalForm.ADD_LEGAL_FORM_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}

		case UsersHandbookContentDeliveryTypes.BULK_CONTENT_DELIVERY_ACTIONS_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookContentDeliveryTypes.DELETE_CONTENT_DELIVERY_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookContentDeliveryTypes.UPDATE_CONTENT_DELIVERY_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case UsersHandbookContentDeliveryTypes.ADD_CONTENT_DELIVERY_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case UsersHandbookContentDeliveryTypes.GET_ALL_CONTENT_DELIVERY_SUCCESS:
			return {
				...SuccessActionState(state),
				contentDeliveryAll: action.data,
			}

		case UsersHandbookKPETypes.BULK_KPE_ACTIONS_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookKPETypes.DELETE_KPE_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookKPETypes.UPDATE_KPE_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case UsersHandbookKPETypes.ADD_KPE_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}

		case UsersHandbookThematicsTypes.BULK_THEMATICS_ACTIONS_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookThematicsTypes.DELETE_THEMATIC_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookThematicsTypes.UPDATE_THEMATIC_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case UsersHandbookThematicsTypes.ADD_THEMATIC_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case UsersHandbookInternetTypes.DELETE_INTERNET_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookInternetTypes.UPDATE_INTERNET_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case UsersHandbookInternetTypes.ADD_INTERNET_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case UsersHandbookInternetTypes.BULK_INTERNET_ACTIONS_SUCCESS:
			return SuccessActionState(state)
		case UsersHandbookThematicsTypes.SET_HANDBOOK_FILTER:
			return {...state, ...action.filter, editingKey: ''}

		case OptionsHandbookTypes.SET_EDITING_KEY :
			return {
				...state,
				editingKey: action.key
			}
		case OptionsHandbookTypes.CLEAR_EDITING_KEY :
			return {
				...state,
				editingKey: ''
			}

		case OptionsHandbookRegions.DELETE_REGIONS_SUCCESS:
			return SuccessActionState(state)
		case OptionsHandbookRegions.UPDATE_REGIONS_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case OptionsHandbookRegions.ADD_REGIONS_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case OptionsHandbookRegions.BULK_REGIONS_ACTIONS_SUCCESS:
			return SuccessActionState(state)

		case OptionsHandbookRanks.DELETE_RANKS_SUCCESS:
			return SuccessActionState(state)
		case OptionsHandbookRanks.UPDATE_RANKS_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case OptionsHandbookRanks.ADD_RANKS_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case OptionsHandbookRanks.BULK_RANKS_ACTIONS_SUCCESS:
			return SuccessActionState(state)

		case OptionsHandbookSessionsType.DELETE_SESSIONS_TYPE_SUCCESS:
			return SuccessActionState(state)
		case OptionsHandbookSessionsType.UPDATE_SESSIONS_TYPE_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case OptionsHandbookSessionsType.ADD_SESSIONS_TYPE_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case OptionsHandbookSessionsType.BULK_SESSIONS_TYPE_ACTIONS_SUCCESS:
			return SuccessActionState(state)

		case OptionsHandbookCategoryProject.DELETE_CATEGORY_PROJECT_SUCCESS:
			return SuccessActionState(state)
		case OptionsHandbookCategoryProject.UPDATE_CATEGORY_PROJECT_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case OptionsHandbookCategoryProject.ADD_CATEGORY_PROJECT_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case OptionsHandbookCategoryProject.BULK_CATEGORY_PROJECT_ACTIONS_SUCCESS:
			return SuccessActionState(state)

		case OptionsHandbookGenreProject.DELETE_GENRE_PROJECT_SUCCESS:
			return SuccessActionState(state)
		case OptionsHandbookGenreProject.UPDATE_GENRE_PROJECT_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case OptionsHandbookGenreProject.ADD_GENRE_PROJECT_SUCCESS:
			return {...SuccessActionState(state), editingKey: ''}
		case OptionsHandbookGenreProject.BULK_GENRE_PROJECT_ACTIONS_SUCCESS:
			return SuccessActionState(state)

		/**
		 * ERROR ACTIONS
		 */
		case OptionsGeneralActionTypes.GET_CONTENT_MODULE_SECTION_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookUsersActionsTypes.UPDATE_USERS_ACTIONS_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookContentDeliveryTypes.GET_ALL_CONTENT_DELIVERY_ERROR:
			return ErrorActionState(state, action)

		case UsersHandbookDownloadTypes.DOWNLOAD_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookDownloadTypes.DOWNLOAD_TEMPLATE_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookDownloadTypes.UPLOAD_ERROR:
			return ErrorActionState(state, action)

		case UsersHandbookContentDeliveryTypes.DELETE_CONTENT_DELIVERY_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookContentDeliveryTypes.UPDATE_CONTENT_DELIVERY_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookContentDeliveryTypes.ADD_CONTENT_DELIVERY_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookContentDeliveryTypes.BULK_CONTENT_DELIVERY_ACTIONS_ERROR:
			return ErrorActionState(state, action)

		case UsersHandbookContentDirectionTypes.DELETE_CONTENT_DIRECTION_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookContentDirectionTypes.UPDATE_CONTENT_DIRECTION_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookContentDirectionTypes.ADD_CONTENT_DIRECTION_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookContentDirectionTypes.BULK_CONTENT_DIRECTION_ACTIONS_ERROR:
			return ErrorActionState(state, action)

		case UsersHandbookKPETypes.DELETE_KPE_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookKPETypes.UPDATE_KPE_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookKPETypes.ADD_KPE_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookKPETypes.BULK_KPE_ACTIONS_ERROR:
			return ErrorActionState(state, action)

		case UsersHandbookOrganizationTypes.GET_ORGANIZATION_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookOrganizationTypes.BULK_ORGANIZATION_ACTIONS_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookOrganizationTypes.DELETE_ORGANIZATION_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookOrganizationTypes.UPDATE_ORGANIZATION_ERROR:
			return ErrorActionState(state, action)

		case UsersHandbookDocumentsTypes.DELETE_DOCUMENTS_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookDocumentsTypes.BULK_DOCUMENTS_ACTIONS_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookDocumentsTypes.UPDATE_DOCUMENTS_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookDocumentsTypes.ADD_DOCUMENTS_ERROR:
			return ErrorActionState(state, action)

		case OptionsHandbookLegalForm.DELETE_LEGAL_FORM_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookLegalForm.BULK_LEGAL_FORM_ACTIONS_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookLegalForm.UPDATE_LEGAL_FORM_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookLegalForm.ADD_LEGAL_FORM_ERROR:
			return ErrorActionState(state, action)

		case UsersHandbookInternetTypes.DELETE_INTERNET_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookInternetTypes.UPDATE_INTERNET_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookInternetTypes.ADD_INTERNET_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookInternetTypes.BULK_INTERNET_ACTIONS_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookThematicsTypes.BULK_THEMATICS_ACTIONS_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookThematicsTypes.DELETE_THEMATIC_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookThematicsTypes.UPDATE_THEMATIC_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookThematicsTypes.ADD_THEMATIC_ERROR:
			return ErrorActionState(state, action)
		case UsersHandbookThematicsTypes.GET_HANDBOOK_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookRegions.DELETE_REGIONS_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookRegions.UPDATE_REGIONS_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookRegions.ADD_REGIONS_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookRegions.BULK_REGIONS_ACTIONS_ERROR:
			return ErrorActionState(state, action)

		case OptionsHandbookRanks.DELETE_RANKS_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookRanks.UPDATE_RANKS_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookRanks.ADD_RANKS_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookRanks.BULK_RANKS_ACTIONS_ERROR:
			return ErrorActionState(state, action)

		case OptionsHandbookSessionsType.DELETE_SESSIONS_TYPE_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookSessionsType.UPDATE_SESSIONS_TYPE_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookSessionsType.ADD_SESSIONS_TYPE_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookSessionsType.BULK_SESSIONS_TYPE_ACTIONS_ERROR:
			return ErrorActionState(state, action)

		case OptionsHandbookCategoryProject.DELETE_CATEGORY_PROJECT_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookCategoryProject.UPDATE_CATEGORY_PROJECT_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookCategoryProject.ADD_CATEGORY_PROJECT_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookCategoryProject.BULK_CATEGORY_PROJECT_ACTIONS_ERROR:
			return ErrorActionState(state, action)

		case OptionsHandbookGenreProject.DELETE_GENRE_PROJECT_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookGenreProject.UPDATE_GENRE_PROJECT_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookGenreProject.ADD_GENRE_PROJECT_ERROR:
			return ErrorActionState(state, action)
		case OptionsHandbookGenreProject.BULK_GENRE_PROJECT_ACTIONS_ERROR:
			return ErrorActionState(state, action)

		default:
			return state
	}
}
