import {UsersActionTypes} from '../action-types'
import {TOrganizationsUser, TPerson} from 'models/user'
import {TUserForm} from 'models/users-list/user-form'
import {UserTypes} from 'entities/user'

/**
 * GET USERS_LIST
 */

export const GetUsersListStartAction = () => ({
	type: UsersActionTypes.GET_USERS_LIST_START,
})

export const GetUsersListSuccessAction = (data: UserTypes.OptionsUserResponse) => ({
	type: UsersActionTypes.GET_USERS_LIST_SUCCESS,
	payload: data,
})

export const GetUsersListErrorAction = (error: any) => ({
	type: UsersActionTypes.GET_USERS_LIST_ERROR,
	error,
})

/**
 * Изменение фильтра пользователей
 */
export const SetUsersFilter = (filter: UserTypes.OptionsUserFilter) => ({
	type: UsersActionTypes.SET_USERS_FILTER,
	filter,
})

/**
 * Создать пользователя
 */
export const CreateUserStartAction = (user: TUserForm) => ({
	type: UsersActionTypes.CREATE_USER_START,
	user
})
export const CreateUserSuccessAction = () => ({
	type: UsersActionTypes.CREATE_USER_SUCCESS,
})
export const CreateUserErrorAction = (error: any) => ({
	type: UsersActionTypes.CREATE_USER_ERROR,
	error,
})

/**
 * Обновление пользователя
 */
export const UpdateUserStartAction = (userId: string, user: TUserForm) => ({
	type: UsersActionTypes.UPDATE_USER_START,
	userId,
	user
})
export const UpdateUserSuccessAction = (user: TUserForm) => ({
	type: UsersActionTypes.UPDATE_USER_SUCCESS,
	user,
})
export const UpdateUserErrorAction = (error: any) => ({
	type: UsersActionTypes.UPDATE_USER_ERROR,
	error,
})

/**
 * Обновление базовых персональных настроек пользователя
 */
export const UpdatePersonalDataStartAction = (data: any) => ({
	type: UsersActionTypes.UPDATE_PERSONAL_DATA_START,
	data
})
export const UpdatePersonalDataSuccessAction = () => ({
	type: UsersActionTypes.UPDATE_PERSONAL_DATA_SUCCESS,
})
export const UpdatePersonalDataErrorAction = (error: any) => ({
	type: UsersActionTypes.UPDATE_PERSONAL_DATA_ERROR,
	error,
})

/**
 * Получение пользователя по ID
 */
export const GetUserStartAction = (userId: string) => ({
	type: UsersActionTypes.GET_USER_START,
	userId,
})
export const GetUserSuccessAction = (user: TUserForm | null) => ({
	type: UsersActionTypes.GET_USER_SUCCESS,
	user,
})
export const GetUserErrorAction = (error: any) => ({
	type: UsersActionTypes.GET_USER_ERROR,
	error,
})

/**
 * Получение пользователя по ID
 */
export const GetUserDetailsStartAction = (userId: string) => ({
	type: UsersActionTypes.SEND_USER_DETAILS_START,
	userId,
})
export const GetUserDetailsSuccessAction = () => ({
	type: UsersActionTypes.SEND_USER_DETAILS_SUCCESS,
})
export const GetUserDetailsErrorAction = (error: any) => ({
	type: UsersActionTypes.SEND_USER_DETAILS_ERROR,
	error,
})
/**
 * Удаление пользователя
 */
export const DeleteUserStartAction = (userId: string) => ({
	type: UsersActionTypes.DELETE_USER_START,
	userId,
})
export const DeleteUserSuccessAction = () => ({
	type: UsersActionTypes.DELETE_USER_SUCCESS,
})
export const DeleteUserErrorAction = (error: any) => ({
	type: UsersActionTypes.DELETE_USER_ERROR,
	error,
})

/**
 * Изменене пароля
 */

export const ChangePasswordStartAction = (userId: string, password: any) => ({
	type: UsersActionTypes.CHANGE_PASSWORD_START,
	userId,
	password,
})

export const ChangePasswordSuccessAction = () => ({
	type: UsersActionTypes.CHANGE_PASSWORD_SUCCESS,
})

export const ChangePasswordErrorAction = (error: any) => ({
	type: UsersActionTypes.CHANGE_PASSWORD_ERROR,
	error,
})

/**
 * Получение пользователей системы задействованых в задачах текущего пользователя
 */
export const GetRelatedUsersStartAction = () => ({
	type: UsersActionTypes.GET_RELATED_USERS_START,
})
export const GetRelatedUsersSuccessAction = (users: TPerson[]) => ({
	type: UsersActionTypes.GET_RELATED_USERS_SUCCESS,
	users,
})
export const GetRelatedUsersErrorAction = (error: any) => ({
	type: UsersActionTypes.GET_RELATED_USERS_ERROR,
	error,
})

export const ExportUsersStartAction = (usersIds: string[], name: string, callback: () => void) => ({
	type: UsersActionTypes.UNLOADING_USERS_START, usersIds, name, callback
})
export const ExportUsersSuccessAction = () => ({
	type: UsersActionTypes.UNLOADING_USERS_SUCCESS
})
export const ExportUsersErrorAction = (error: any) => ({
	type: UsersActionTypes.UNLOADING_USERS_ERROR, error
})

export const ImportTemplateUsersStartAction = () => ({
	type: UsersActionTypes.IMPORT_TEMPLATE_USERS_START
})
export const ImportTemplateUsersSuccessAction = () => ({
	type: UsersActionTypes.IMPORT_TEMPLATE_USERS_SUCCESS
})
export const ImportTemplateUsersErrorAction = (error: any) => ({
	type: UsersActionTypes.IMPORT_TEMPLATE_USERS_ERROR, error
})

export const ImportUsersStartAction = (data: any) => ({
	type: UsersActionTypes.IMPORT_USERS_START, data
})
export const ImportUsersSuccessAction = () => ({
	type: UsersActionTypes.IMPORT_USERS_SUCCESS
})
export const ImportUsersErrorAction = (error: any) => ({
	type: UsersActionTypes.IMPORT_USERS_ERROR, error
})

export const ClearUsersErrorAction = () => ({
	type: UsersActionTypes.CLEAR_USERS_ERROR
})

export const ClearIsSuccessAction = () => ({
	type: UsersActionTypes.CLEAR_USERS_IS_SUCCESS
})
/**
 * Получение организаций пользователя
 */

export const GetOrganizationsUserStartAction = (userId: string) => ({
	type: UsersActionTypes.GET_ORGANIZATIONS_USERS_START,
	userId
})

export const GetOrganizationsUserSuccessAction = (data: TOrganizationsUser[]) => ({
	type: UsersActionTypes.GET_ORGANIZATIONS_USERS_SUCCESS,
	data
})

export const GetOrganizationsUserErrorAction = (error: any) => ({
	type: UsersActionTypes.GET_ORGANIZATIONS_USERS_ERROR,
	error,
})

/**
 * Получение пользователей системы задействованных в задачах проектов текущего пользователя как проверяющие
 */
export const GetInspectorsStartAction = () => ({
	type: UsersActionTypes.GET_INSPECTORS_START,
})
export const GetInspectorsSuccessAction = (inspectors: UserTypes.TInspector[]) => ({
	type: UsersActionTypes.GET_INSPECTORS_SUCCESS,
	inspectors,
})
export const GetInspectorsErrorAction = (error: any) => ({
	type: UsersActionTypes.GET_INSPECTORS_ERROR,
	error,
})

/**
 * Получение списка активных синхронизированных пользователей
 */
export const GetActiveSyncedUsersStartAction = () => ({
	type: UsersActionTypes.GET_LIST_ACTIVE_SYNCED_USERS_START,
})
export const GetActiveSyncedUsersSuccessAction = (users: UserTypes.User[]) => ({
	type: UsersActionTypes.GET_LIST_ACTIVE_SYNCED_USERS_SUCCESS,
	users,
})
export const GetActiveSyncedUsersErrorAction = (error: any) => ({
	type: UsersActionTypes.GET_LIST_ACTIVE_SYNCED_USERS_ERROR,
	error,
})
/**
 * Очищение массовых действий без запроса на сервер
 */
export const ClearBulkActions = () => ({
	type: UsersActionTypes.CLEAR_BULK_ACTIONS,
})