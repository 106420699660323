import {put} from 'redux-saga/effects'
import {GetUsersListStartAction, SetUsersFilter} from '../../../store/options/actions/actions-users'

export function* setUsersFilter(_action: ReturnType<typeof SetUsersFilter>) {
	try {
		yield put(GetUsersListStartAction())
	} catch (error: any) {
		console.log(error)
	}
}
