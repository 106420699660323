import React, {lazy} from 'react'
import {Row} from 'antd'

const AnalyticsMenu = lazy(() => import('../../components/menu/analytics-menu'))

export enum HorizontalBarType {
	kpiTop = 'kpiTop',
	kpiTopProject = 'kpiTopProject',
}

export enum DoughnutType {
	project = 'project',
	posting = 'posting',
}

export enum BarType {
	posting = 'posting',
	kpi = 'kpi',
}

const AnalyticsPage = () => {
	return (
			<Row className="analyticsPage__navigation">
				<AnalyticsMenu />
			</Row>
	)
}

export default AnalyticsPage
