import {put} from 'redux-saga/effects';
import {
    AbortedLoad,
    GetUnfinishedStartAction,
} from "store/uploader/actions";
import {GetTasksContentStartAction} from "store/tasks/actions/content";


export function* abortedLoad({taskId}: ReturnType<typeof AbortedLoad>) {
    try {
        const location = window.location.href.split('/');
        const page = location[3].split('?')[0];
        if (page === 'task' && taskId) yield put(GetTasksContentStartAction(taskId))

        yield put(GetUnfinishedStartAction())

    } catch (error: any) {

    }
}