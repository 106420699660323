import {combineReducers} from "redux";

import mainReducer from './main/reducer'
import projectReducer from './project/reducer'
import agreementOfProjectTaskRecordsReducer from './agreement-of-project-task-records/reducer'
import organizationsReducer from './organizations/reducer'
import contestsReducer from './contests/reducer'
import statusReducer from './status/reducer'
import indicatorsReducer from './indicators/reducer'
import indicatorsContentReducer from './indicatorsContent/reducer'
import awardsReducer from './awards/reducer'
import thematicsReducer from './thematics/reducer'
import regionalsReducer from './regionals/reducer'
import contentReducer from './content/reducer'
import channelsReducer from './channels/reducer'
import usersReducer from './users/reducer'
import famousPeopleReducer from './famousPeople/reducer'
import datesReducer from './dates/reducer'
import countsReducer from './counts/reducer'
import categoriesGenresReducer from './categories-genres/reducer'


import * as actionsMain from './main/actions'
import * as actionsProject from './project/actions'
import * as actionsAgreementOfProjectTaskRecords from './agreement-of-project-task-records/actions'
import * as actionsOrganizations from './organizations/actions'
import * as actionsContests from './contests/actions'
import * as actionsStatus from './status/actions'
import * as actionsIndicators from './indicators/actions'
import * as actionsIndicatorsContent from './indicatorsContent/actions'
import * as actionsAwards from './awards/actions'
import * as actionsThematics from './thematics/actions'
import * as actionsRegionals from './regionals/actions'
import * as actionsContent from './content/actions'
import * as actionsChannels from './channels/actions'
import * as actionsUsers from './users/actions'
import * as actionsFamousPeople from './famousPeople/actions'
import * as actionsDates from './dates/actions'
import * as actionsCounts from './counts/actions'
import * as actionsCategoriesGenres from './categories-genres/actions'
import {ClearAllFilters, ClearFilters} from './actions'

export const reducer = combineReducers({
    main: mainReducer,
    project: projectReducer,
    agreementOfProjectTaskRecords: agreementOfProjectTaskRecordsReducer,
    organizations: organizationsReducer,
    contests: contestsReducer,
    status: statusReducer,
    indicators: indicatorsReducer,
    indicatorsContent: indicatorsContentReducer,
    awards: awardsReducer,
    thematics: thematicsReducer,
    regionals: regionalsReducer,
    content: contentReducer,
    channels: channelsReducer,
    users: usersReducer,
    famousPeople: famousPeopleReducer,
    dates: datesReducer,
    counts: countsReducer,
    categoriesGenres: categoriesGenresReducer
})
export type TCFState = ReturnType<typeof reducer>;
export * as selectors from './selectors';


export const actions = {
    ...actionsMain,
    ...actionsProject,
    ...actionsAgreementOfProjectTaskRecords,
    ...actionsOrganizations,
    ...actionsContests,
    ...actionsStatus,
    ...actionsIndicators,
    ...actionsIndicatorsContent,
    ...actionsAwards,
    ...actionsThematics,
    ...actionsRegionals,
    ...actionsContent,
    ...actionsChannels,
    ...actionsUsers,
    ...actionsFamousPeople,
    ...actionsDates,
    ...actionsCounts,
    ...actionsCategoriesGenres,
    ClearFilters,
    ClearAllFilters
}
