import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import '@vime/core/themes/default.css';
import '@vime/core/themes/light.css';
import './index.less';


import './http/index'

import App from "./app";

ReactDOM.render(
    <App  />,
  document.getElementById('root')
);

// If you want to start measuring performance in your sidebars, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();