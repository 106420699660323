import React, {memo} from 'react';
import './index.less';
const Connect = () => {
    return (
        <div className="Connect">
            <div className='Connect__body'>
                <span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
                <div className='base'>
                    <span></span>
                    <div className='face'></div>
                </div>
            </div>
            <div className='longfazers'>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="Connect__title">Ожидайте подключения</div>
        </div>
    )
};

export default memo(Connect);