import {TApplicationState} from "../aplication-state";
import {createSelector} from "reselect";

const getLogsState = (state: TApplicationState) => state.logs;
export const selectLogs = createSelector(
    getLogsState,
    (state) => state.actions ? state.actions : []
)
export const selectLogsStatus = createSelector(
    getLogsState,
    (state) => state.isLoading
)
export const selectLogsLength = createSelector(
    getLogsState,
    (state) => state.actions ? state.actions.length : 0
)