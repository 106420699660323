import React from 'react';
import styles from './styles.module.less';
import { RadioButtonsComponent } from 'components/formic-control';
import { TForm } from './modal';
import { useFormikContext } from 'formik';

const Duplicate = () => {
	const {values, setFieldValue} = useFormikContext<TForm>();
	return (
		<div className={styles.item}>
			<span className={styles.label}>Условия дублирования <sup>*</sup></span>
			<div className={styles.field}>
				<RadioButtonsComponent
					name={'duplicationTerms'}
					options={[
						{value: '1', label: 'По заданным условиям'},
						{value: '2', label: 'По конкретным датам'},
					]}
					disabled={!values.enabled}
					handler={(value: TForm['duplicationTerms']) => {
						if (value === '1') {
							setFieldValue('scheduleDates', [])
						} else {
							setFieldValue('firstTaskDate', null)
							setFieldValue('lastTaskDate', null)
							setFieldValue('dayOfWeek', null)
						}
					}}
				/>
			</div>
		</div>
	)
};

export default Duplicate;