import React, {FC, useEffect, useRef, useState} from "react"

type TimerProps = {
    onGetCodeClick: any
}

type TInitialState = {
    seconds: number,
    flag: boolean
    done: boolean
}

const initialState:TInitialState = {
    seconds: 60,
    flag: false,
    done : false
}

const Timer:FC<TimerProps> = ({ onGetCodeClick }) => {
    const [state,setState] = useState(initialState)
    const [variableSeconds, setVariableSeconds] = useState("секунд")
    const foo = useRef<any>()

    const handleClickCode = () => {
        onGetCodeClick()
        setState((prevState) =>({
            ...prevState,
            flag: !prevState.flag,
            seconds: 60,
            done: false
        }))
    }

    useEffect(() => {
        const tick = () => {
            setState((prevState) =>({
                ...prevState,
                seconds: prevState.seconds -1
            }))        }
        foo.current = setInterval(tick, 1000)
        return () => clearInterval(foo.current)
    }, [state.flag])

    useEffect(() => {
        if (state.seconds % 10 === 1) {
            setVariableSeconds("секунду")
        }
        if (state.seconds % 10 > 5 || state.seconds % 10 === 0) {
            setVariableSeconds("секунд")
        }
        if (state.seconds % 10 > 1 && state.seconds % 10 < 5) {
            setVariableSeconds("секунды")
        }
        if (Math.trunc(state.seconds / 10) === 1) {
            setVariableSeconds("секунд")
        }
        if (state.seconds === 0) {
            clearInterval(foo.current)
            setState((prevState) =>({
                ...prevState,
                done: true
            }))
        }
    }, [state.seconds])

    return (
        <>
            {state.done ? (
                <span className="list__item__wrapper__confirm--textTwo" onClick={handleClickCode}>
                    Запросить код подтверждение повторно
                </span>
            ) : (
                <span className="list__item__wrapper__confirm--textTwo">
                    Повторная отправка кода возможна через {state.seconds} {variableSeconds}
                </span>
            )}
        </>
    )
}

export default Timer
