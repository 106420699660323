import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    AddInternetErrorAction,
    AddInternetStartAction,
    AddInternetSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import InternetResourcesService from "services/internetResourcesService";

export function* addInternet({ data }: ReturnType<typeof AddInternetStartAction>) {
    try {
        yield InternetResourcesService.addInternet(data)
        yield put(AddInternetSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.internet))

        yield put(NotificationOpen('success', 'Интернет рeсурс добавлен'))
    } catch (error: any) {
        yield put(AddInternetErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить интернет ресурс'))
    }
}
