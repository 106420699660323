import {InferValueTypes} from "models/common";
import * as actions from "./actions";
import {Types, TypesCode} from "features/user-filters/types";
import {ActionTypes} from "./types";

type ActionsTypes = ReturnType<InferValueTypes<typeof actions>>;

export type FilterValues = {
    filterName?: string
    activeFilterId?: string
}
export type UserFiltersStore = Partial<Record<TypesCode, FilterValues>>
export type TStore = {
    isLoading: boolean
    error: any
    types: Types[]
    filters: UserFiltersStore
}
const initialState: TStore = {
    isLoading: false,
    error: null,
    types: [],
    filters: {},
}
export function reducer(
    state: TStore = initialState,
    action: ActionsTypes
): TStore {
    switch (action.type) {
        case ActionTypes.SET_VALUE:
            return {
                ...state,
                ...action.value,
            }
        case ActionTypes.SET_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.typeContent]: {
                        ...action.value
                    }
                }
            }
        default:
            return state
    }
}