import React, {lazy, useEffect} from 'react'
import './analytics.less'
import {Redirect, Switch, useHistory} from 'react-router-dom'

import SuspenseComponent from 'components/suspense'
import {useSelector} from 'react-redux'
import {Row} from "antd";
import {ANALYTICS} from "routing/names";
import {DisabledRoutes, Routes} from "models/routes";
import {selectRole, selectUser} from "store/auth/selectors";
import CreateRouting from "routing/create-routing";
import AnalyticsPage from "./analytics";

const BasicIndicators = lazy(() => import('pages/analytics/basic-indicators/basic-indicators'))
const SummaryData = lazy(() => import('pages/analytics/summary-data/summary-data'))
const CoverageData = lazy(() => import('pages/analytics/reach-data/reach-data'))

const disabledRoles: DisabledRoutes = {
    notAvailableContractor: true,
    notAvailableClient: true,
    notAvailableAgent: true
}
export const analyticsChildrenRoutes: Routes[] = [
    { path: ANALYTICS.ANALYTICS_COVERAGE_DATA, Component: CoverageData, ...disabledRoles, notAvailableViewer: true  },
    { path: ANALYTICS.ANALYTICS_SUMMARY_DATA, Component: SummaryData, ...disabledRoles, notAvailableViewer: true },
    { path: ANALYTICS.ANALYTICS_INDICATORS, Component: BasicIndicators, ...disabledRoles},
]

const AnalyticsRouting = () => {
    const history = useHistory();
    const user = useSelector(selectUser)
    const userRole = useSelector(selectRole)

    useEffect(() => {
        if(user?.role && user.role === 'ROLE_CONTRACTOR') {
            history.push('/projects');
        }
        if(user?.role && user.role === 'ROLE_CLIENT') {
            history.push('/content');
        }
        if(user?.role && user.role === 'ROLE_AGENT') {
            history.push('/content');
        }
    }, [user]);//eslint-disable-line

    return (
        <SuspenseComponent>
            <Row className="analyticsPage">
                <AnalyticsPage/>
                <Switch>
                    {CreateRouting(analyticsChildrenRoutes, userRole)}
                    <Redirect to={ANALYTICS.ANALYTICS_INDICATORS} />
                </Switch>
            </Row>

        </SuspenseComponent>
    )
}

const analyticsRoutes: Routes[] = [
    {path: ANALYTICS.ANALYTICS, Component: AnalyticsRouting, name: 'Аналитика', exact: false},
]

export default analyticsRoutes