import ZodParser from '../services/zod-parser';
import $api from '../../http';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { ZodSchema } from 'zod';
import { Error } from '../types/error';
import { NotificationModel, Notification } from 'processes/notification';

export async function axiosRequest<T>({
	url,
	method,
	data,
	params,
	headers,
	responseType,
	showError = true,
	paramsSerializer,
	parser,
}: {
	url: string;
	method: AxiosRequestConfig['method'];
	data?: AxiosRequestConfig['data'];
	params?: AxiosRequestConfig['params'];
	headers?: AxiosRequestConfig['headers'];
	responseType?: AxiosRequestConfig['responseType'];
	paramsSerializer?: AxiosRequestConfig['paramsSerializer'];
	showError?: boolean;
	parser?: ZodSchema | {list: true, schema: ZodSchema};
}): Promise<{ data: T }> {
	try {
		const result = await $api({
			url,
			method,
			data,
			params,
			headers,
			paramsSerializer,
			responseType,
		});
		if (parser) {
			if ('list' in parser) {
				ZodParser.parse(parser.schema, result.data?.content, url);
			} else {
				ZodParser.parse(parser, result.data, url);
			}
		}
		return { data: result.data };
	} catch (axiosError) {
		const err = axiosError as AxiosError;
		const error = {
			status: err.response?.status,
			data: err.response?.data || err.message,
		} as Error;

		if (showError) {
			Notification.open({ type: 'error', info: error.data as NotificationModel.NotificationInfo });
		}
		throw error;
	}
}