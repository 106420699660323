import {put, select} from "redux-saga/effects";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {FilterValues} from "features/user-filters/model/store/reducer";
import {setUserFiltersValue} from "features/user-filters/model/store/actions";
import Api from "features/user-filters/api";
import {Logger} from "shared/services";
import {selectTasksFilter} from "store/tasks/selectors";
import {TTasksKanbanFilter} from "models/task/task.kanban";

export function* tasks(typeId: string, filter?: FilterValues) {
    try {
        const filterTasks: TTasksKanbanFilter = yield select(selectTasksFilter)
        if (filter?.activeFilterId) {
            yield Api.update(filter.activeFilterId, {
                filterTypeId: typeId,
                name: filter.filterName,
                filterData: JSON.stringify(filterTasks)
            })
        } else {
            yield Api.create({
                filterTypeId: typeId,
                name: filter?.filterName,
                filterData: JSON.stringify(filterTasks)
            })
        }
    } catch (error) {
        Logger.error('createFilterWorker/search', error)
        yield put(setUserFiltersValue({error: error}))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
    }
}