import { z } from "zod";
import { VoteSchema } from '../vote';
import { TasksRecordStatusEnum } from '../record-status';

export const CopyrightRecordsSchema = z.object({
	id: z.string(),
	contentUnitName: z.string().nullable(),
	publicationDate: z.string().nullable(),
	copyrightObjectName: z.string().nullable(),
	contentUnitPlace: z.string().nullable(),
	copyrightHolder: z.string().nullable(),
	document: z.string().nullable(),
	copyrightType: z.string().nullable(),
	status: TasksRecordStatusEnum,
	votes: z.array(VoteSchema).nullable(),
	lastTaskRecordWithVotesResponse: z.object({
		id: z.string(),
		votes: z.array(VoteSchema).nullable(),
	}),
	isOriginal: z.boolean(),
	isReplaced: z.boolean(),
	task: z.object({
		deadline: z.string(),
		number: z.number(),
		id: z.string(),
		name: z.string(),
		code: z.string(),
	}).optional()
});
export type CopyrightRecords = z.infer<typeof CopyrightRecordsSchema>