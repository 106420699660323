import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetAllLegalFormOrganizationErrorAction,
    GetAllLegalFormOrganizationStartAction,
    GetAllLegalFormOrganizationSuccessAction
} from "../../store/legal-form-organization/actions";
import LegalFormOrganizationService from "../../services/legalFormOrganizationService";

export function* legalFormOrganization(_: ReturnType<typeof GetAllLegalFormOrganizationStartAction> ) {
    try {
        const {data} =  yield LegalFormOrganizationService.getLegalFormOrganization()
        yield put(GetAllLegalFormOrganizationSuccessAction(data))
    } catch (error) {
        yield put(GetAllLegalFormOrganizationErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить список правовых форм организации'))
    }
}