import {all, fork} from 'redux-saga/effects';

import appSaga from "./app";
import authSaga from "./auth";
import projectsSaga from "./projects";
import usersSaga from "./options/users-list";
import tasksSaga from "./tasks";
import filesSaga from "./files";
import handbookSaga from "./options/handbook";
import usersActionsSaga from "./options/users-actions";
import projectReportingSaga from "./project-reporting";
import logsSaga from "./logs";
import notificationsSaga from "./options/notifications";
import pageLoader from "./page-loader";
import personalSettingSaga from "./options/personal-setting";
import analyticsSaga from "./analytics";
import sprintsSaga from "./sprints";
import projectDocumentsSaga from "./project-documents";
import uploaderSaga from "./uploader";
import contentSaga from "./content";
import regionsSaga from "./regions";
import tagsSaga from "./tags";
import archiveSaga from "./archive";
import legalFromOrganizationSaga from "./legal-form-organization";
import sessionsTypeSaga from "./sessions-type";
import nationalProjectTag1Saga from "./national-project-tag1";
import nationalProjectTag2Saga from "./national-project-tag2";
import {ExtendedSearchModel} from 'features/extended-search';
import {SearchContentProjectModel} from 'features/search-project-content';
import {CalendarModel} from 'pages/calendar';
import {TaskModel} from 'entities/task';
import {UserFiltersModel} from 'features/user-filters';

function* rootSaga() {
    yield all([
        fork(appSaga),
        fork(logsSaga),
        fork(authSaga),
        fork(UserFiltersModel.Saga),
        fork(projectsSaga),
        fork(usersSaga),
        fork(tasksSaga),
        fork(filesSaga),
        fork(handbookSaga),
        fork(usersActionsSaga),
        fork(projectReportingSaga),
        fork(notificationsSaga),
        fork(pageLoader),
        fork(personalSettingSaga),
        fork(analyticsSaga),
        fork(sprintsSaga),
        fork(projectDocumentsSaga),
        fork(uploaderSaga),
        fork(contentSaga),
        fork(regionsSaga),
        fork(tagsSaga),
        fork(archiveSaga),
        fork(legalFromOrganizationSaga),
        fork(sessionsTypeSaga),
        fork(nationalProjectTag1Saga),
        fork(nationalProjectTag2Saga),
        fork(ExtendedSearchModel.Saga),
        fork(SearchContentProjectModel.Saga),
        fork(CalendarModel.Saga),
        fork(TaskModel.Saga),
    ])
}
export default rootSaga;
