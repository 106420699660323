import {TApplicationState} from "./aplication-state";
import {createSelector} from "reselect";
import { UserTypes } from "entities/user";

const getState = (state: TApplicationState) => state;

export const selectLocation = createSelector(
    getState,
    (state) => state.router.location
)
export const selectProjectAccess = createSelector(
    getState,
    (state) => ({
        role: state.auth.user?.role as UserTypes.RoleName | undefined,
        projectStatus: state.projects.project?.status.code,
        archival: state.archive,
        dates: {
            agreementDate: state.projects.project?.agreementDate,
            completionDate: state.projects.project?.completionDate,
            contractDate: state.projects.project?.contractDate,
        }
    })
)
export const selectParams = createSelector(
    getState,
    (state) => ({
        id: state.router.location.query.id,
        code: state.router.location.query?.code,
        contentModuleSectionId: state.router.location.query?.contentModuleSectionId,
        tab: state.router.location.query?.tab,
    })
)
