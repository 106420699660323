/**
 * Получение персональных настроек для пользователя
 */
export type TPersonalSettingResponse =   {
    emailSetting: {emailEnabled :boolean,personalSetupRequired: boolean, prioritySendEmailEng: string}
    id: string,
    notificationName: string,
    smsSetting: {smsEnabled: boolean,personalSetupRequired: boolean, prioritySendSmsEng: string}
    systemSetting: {systemEnabled: boolean,personalSetupRequired: boolean, prioritySendSystemEng: string}
    telegramSetting: {telegramEnabled:boolean,personalSetupRequired: boolean, prioritySendTelegramEng: string}
}

export type TPersonalSettingRequest =   {
    emailEnabled: boolean,
    id: string,
    notificationName: string,
    smsEnabled: boolean,
    systemMessageEnabled: boolean,
    telegramEnabled: boolean,
}

export enum TYPE_NOTIFICATION_CHANNELS {
    EMAIL = 'EMAIL',
    EMAIL_GROUP = 'EMAIL_GROUP',
    SMS = 'SMS',
    SMS_GROUP = 'SMS_GROUP',
    SYSTEM = 'SYSTEM',
    TELEGRAM = 'TELEGRAM',
    TELEGRAM_GROUP = 'TELEGRAM_GROUP'
}

export type TConfirmResponse = {
    success: boolean,
    message: string
}
