import React from 'react';
import {Col, Row} from "antd";
import {CheckboxComponent} from "components/formic-control";
import {useSelector} from "react-redux";
import {selectRole} from "store/auth/selectors";
import {useFormikContext} from "formik";
import {UserDto} from "entities/user";


const Access = () => {
    const currentUser = useSelector(selectRole)
    const { values } = useFormikContext<UserDto.User>()

    return (
        <>
            <Row gutter={20} align="middle" className="usersListForm-form__item">
                <Col md={12} xs={24} className="label">
                    Ограничение к просмотру списка проектов
                </Col>
                <Col md={12} xs={24} className="usersListForm-form__item--checkbox">
                    <CheckboxComponent
                        disabled={!['ROLE_ADMIN_IS', 'ROLE_ADMIN'].includes(currentUser || '')}
                        name="limitedRightsToViewProjectsList"
                        placeholder={`${values?.limitedRightsToViewProjectsList ? 'присутствует' : 'отсутствует'}`}
                    />
                </Col>
            </Row>
            <Row gutter={20} align="middle" className="usersListForm-form__item">
                <Col md={12} xs={24} className="label">
                    Ограничение к просмотру списка единиц контента проектов
                </Col>
                <Col md={12} xs={24} className="usersListForm-form__item--checkbox">
                    <CheckboxComponent
                        disabled={!['ROLE_ADMIN_IS', 'ROLE_ADMIN'].includes(currentUser || '')}
                        name="limitedRightsToViewContentTaskRecords"
                        placeholder={`${values?.limitedRightsToViewContentTaskRecords ? 'присутствует' : 'отсутствует'}`}
                    />
                </Col>
            </Row>
            <Row gutter={20} align="middle" className="usersListForm-form__item">
                <Col md={12} xs={24} className="label">
                    Доступ к конфиденциальным проектам
                </Col>
                <Col md={12} xs={24} className="usersListForm-form__item--checkbox">
                    <CheckboxComponent
                        disabled={!['ROLE_ADMIN_IS', 'ROLE_ADMIN'].includes(currentUser || '')}
                        name="privateProjectViewAccess"
                        placeholder={`${values?.privateProjectViewAccess ? 'присутствует' : 'отсутствует'}`}
                    />
                </Col>
            </Row>
            <Row gutter={20} align="middle" className="usersListForm-form__item">
                <Col md={12} xs={24} className="label">
                    Доступ к просмотру контента
                </Col>
                <Col md={12} xs={24} className="usersListForm-form__item--checkbox">
                    <CheckboxComponent
                        disabled={currentUser !== 'ROLE_ADMIN_IS'}
                        name="contentViewAccess"
                        placeholder={`${values?.contentViewAccess ? 'присутствует' : 'отсутствует'}`}
                    />
                </Col>
            </Row>
            <Row gutter={20} align="middle" className="usersListForm-form__item">
                <Col md={12} xs={24} className="label">
                    Доступ к скачиванию контента
                </Col>
                <Col md={12} xs={24} className="usersListForm-form__item--checkbox">
                    <CheckboxComponent
                        disabled={currentUser !== 'ROLE_ADMIN_IS'}
                        name="contentDownloadAccess"
                        placeholder={`${values?.contentDownloadAccess ? 'присутствует' : 'отсутствует'}`}
                    />
                </Col>
            </Row>
            <Row gutter={20} align="middle" className="usersListForm-form__item">
                <Col md={12} xs={24} className="label">
                    Доступ к путям хранения контента
                </Col>
                <Col md={12} xs={24} className="usersListForm-form__item--checkbox">
                    <CheckboxComponent
                        disabled={currentUser !== 'ROLE_ADMIN_IS'}
                        name="contentStorageLinkAccess"
                        placeholder={`${values?.contentStorageLinkAccess ? 'присутствует' : 'отсутствует'}`}
                    />
                </Col>
            </Row>
        </>
    )
};

export default Access;