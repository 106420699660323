import {put, select} from 'redux-saga/effects'
import {Logger} from 'shared/services'
import {selectUsersListFilter} from 'store/options/selectors/selectors-users'
import {SetUsersFilter} from 'store/options/actions/actions-users'
import {TSearchStringType} from 'models/user'
import {UserTypes} from 'entities/user'

export function* users(filter: string, filterId: string | undefined) {
	try {
		const usersFilter: UserTypes.OptionsUserFilter = yield select(selectUsersListFilter)
		if (filterId) {
			const filterData = JSON.parse(filter)
			yield put(SetUsersFilter({ ...usersFilter, filterData: { ...filterData } }))
		} else {
			const filterData = { searchStringType: TSearchStringType.CS }
			yield put(SetUsersFilter({ ...usersFilter, filterData: { ...filterData } }))
		}
	} catch (error) {
		Logger.error(error)
	}
}
