import React from 'react'
import { SelectComponent } from 'components/formic-control'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { selectParams } from 'store/selectors'
import styles from '../styles.module.less'
import Api from 'entities/project/api/project'
import { IOptions } from 'models/feels'
import { useErrorNotification } from 'shared/lib/error-notification'
import {useFormikContext} from "formik";
import {TInitValues} from "../modal-content-records";
import DropdownComponent from "./dropdown";

const Regions = () => {
	const { values } = useFormikContext<TInitValues>()
	const { id } = useSelector(selectParams)
	const errorFunction = useErrorNotification('Не удалось получить регионы')

	const { data: regionTags = { regionsAbout: [], regionsFor: [] } } = useQuery({
		queryKey: [Api.getRegionsForKey(id), Api.getRegionsAboutKey(id)],
		queryFn: async () => {
			const [{ data: regionsFor }, { data: regionsAbout }] = await Promise.all([
				Api.getRegionsFor(id),
				Api.getRegionsAbout(id),
			])
			const optionsRegionsFor = regionsFor.map((el) => ({ value: el.id, label: el.name, code: el.code } as IOptions))
			const optionsRegionsAbout = regionsAbout.map(
				(el) => ({ value: el.id, label: el.name, code: el.code } as IOptions)
			)
			return { regionsFor: optionsRegionsFor, regionsAbout: optionsRegionsAbout }
		},
		onError: errorFunction,
		enabled: !!id
	})

	return (
		<>
			<div className={styles.item}>
				<span className={styles.label}>О каком регионе контент</span>
				<div className={styles.field}>
					<SelectComponent
						disabled={values.disabled}
						multi={true}
						options={regionTags.regionsAbout}
						name={'regionsAbout'}
						placeholder={'Выберите регион'}
						maxTagCount={3}
						dropdownRender={<DropdownComponent name={'regionsAbout'} options={regionTags.regionsAbout}/>}
					/>
				</div>
			</div>
			<div className={styles.item}>
				<span className={styles.label}>Для какого региона контент</span>
				<div className={styles.field}>
					<SelectComponent
						disabled={values.disabled}
						multi={true}
						options={regionTags.regionsFor}
						name={'regionsFor'}
						placeholder={'Выберите регион'}
						maxTagCount={2}
						dropdownRender={<DropdownComponent name={'regionsFor'} options={regionTags.regionsFor}/>}
					/>
				</div>
			</div>
		</>
	)
}

export default Regions
