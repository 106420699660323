import React, {FC, useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {selectReporting, selectReportingFilter} from 'store/project-reporting/selectors'
import {
	CreateProjectReportingStartAction, GetAllReportingStartAction,
	GetAllReportingSuccessAction,
	SetReportingClearFilter,
	SetReportingFilter,
} from 'store/project-reporting/actions'
import {Button, Checkbox, Modal, Radio, RadioChangeEvent} from 'antd'
import SockJsClient from 'react-stomp'
import useHandlerModal from 'hooks/handlModal'
import {getFilterData} from './utils'
import FileTask from './file-task'
import {selectUser} from 'store/auth/selectors'
import SessionSelect from './session-select'
import ProjectSelect from './project-select'
import TaskSelect from "./task-select";
import {ReportTypes} from 'entities/reports'
import env from 'shared/config/env'
import TableReporting from "./table/table-reporting";
import {TCreateReporting} from "models/projects/reportings";
import SortingRadio from "./sorting-radio";
import ReactionRadio from "./reaction-radio";
import styles from './styles.module.less'

type TData = {
	title: string
	name: string | null
	id: string | null
	type: ReportTypes.ReportType
	fileExtensions: string[]
	taskId?: string
}

type ProjectReportingProps = {
	status: boolean
	data: TData
}

type TDocument = {
	pdf: boolean,
	docx: boolean,
	xls: boolean,
}

const hasNotAccessRadioAll: ReportTypes.ReportType[] = ['R1.2', 'R7', 'R4', 'R6', 'R11', 'R5', 'R2', 'R3.2', 'R1.3', 'R1.1', 'R9.2', 'R12', 'R8', 'R9.1', 'R3.1', 'R10']

const Reporting: FC<ProjectReportingProps> = ({ status, data }) => {
	const user = useSelector(selectUser)
	const { isVisible, closeModal, put } = useHandlerModal(status)
	const reportingAll = useSelector(selectReporting)
	const filterReporting = useSelector(selectReportingFilter)

	const [document, setDocument] = useState<TDocument>({
		pdf: false,
		docx: false,
		xls: false,
	})
	const [selectSessionsState, setSelectSessionsState] = useState<string[]>([])
	const [selectProjectsState, setSelectProjectsState] = useState<string[]>([])
	const [selectTasksState, setSelectTasksState] = useState<string[]>([])
	const [radio, setRadio] = useState<'projects' | 'sessions' | 'tasks' | undefined>()

	const sortingRef = useRef<string | undefined>(undefined)
	const reactionFixationRef = useRef<boolean>(false)

	const lowerCaseFileExtensions = data.fileExtensions?.map(ext => ext.toLowerCase())

	const type = data?.type
	const isHidden = hasNotAccessRadioAll.includes(data?.type)

	const newFilter = {
		...filterReporting,
		filterData: getFilterData(type, data),
	}

	useEffect(() => {
		if (data.id) setSelectProjectsState([data.id])
		if (data.taskId) setSelectTasksState([data.taskId])
		// eslint-disable-next-line
	}, [data.id, data.taskId])

	useEffect(() => {
		if (isHidden) setRadio('projects')
		if (data.taskId) setRadio('tasks')
		return () => {
			put(GetAllReportingSuccessAction(null))
			put(SetReportingClearFilter())
		}// eslint-disable-next-line
	}, [])

	useEffect(() => {
		reportingAll || put(SetReportingFilter(newFilter))
		// eslint-disable-next-line
	}, [reportingAll])

	const onChangeFile = (key: keyof TDocument) => (e: any) => {
		const checked = e.target.checked
		setDocument(prevState => ({...prevState, [key]: checked}))
	}

    const handlerChangeRef = (ref: {current?: boolean | string} ) => (value?: string | boolean) => {
        ref.current = value
    }

    let onMessageReceived = (msg: any, topic: string) => {
		if (topic === '/user/secured/file-archive-requests/status-changed') {
			put(GetAllReportingStartAction())
		}
		if (topic === `/user/secured/reports/new-status`) {
			msg && put(SetReportingFilter(newFilter))
		}

	}
	const handlerRadio = (e: RadioChangeEvent) => {
		const value = e.target.value

		if (value === 'sessions') {
			setSelectProjectsState([])
			setSelectTasksState([])
		} else if ('projects') {
			let projectIds = data.id ? [data.id] : []
			setSelectProjectsState(projectIds)
			setSelectSessionsState([])
			setSelectTasksState([])
		}
		setRadio(value)
	}
	const createFilterData = (extension: 'DOCX' | 'PDF' | 'XLSX') => {
		return {
			format: extension,
			type: type,
			inputData: {
				sessionIds: selectProjectsState?.length ? undefined : selectSessionsState?.length ? selectSessionsState : [],
				projectIds: selectTasksState?.length ? undefined : selectProjectsState?.length ? selectProjectsState : [],
				taskIds: selectTasksState?.length ? selectTasksState : [],
				...(type === 'R7' && {
					reactionFixation: reactionFixationRef.current
				})
			},
			...(sortingRef.current && {
				sort: {
					field: 'postingReportTaskRecord.contentTaskRecord.name',
					direction: sortingRef.current
				}
			})
		} as TCreateReporting
	}
	const onCLickCreateReporting = (_e: any) => {
		if (document.docx) {
			put(CreateProjectReportingStartAction(createFilterData('DOCX')))
			setDocument(prevState => ({...prevState, docx: false}))
		}

		if (document.pdf) {
			put(CreateProjectReportingStartAction(createFilterData('PDF')))
			setDocument(prevState => ({...prevState, pdf: false}))
		}
		if (document.xls) {
			put(CreateProjectReportingStartAction(createFilterData('XLSX')))
			setDocument(prevState => ({...prevState, xls: false}))
		}
	}

	return (
		<Modal
			className={styles.modal}
			title={data.title}
			visible={isVisible}
			onCancel={closeModal}
			maskClosable={true}
			closable={false}
			footer={null}
		>
			<SockJsClient
				url={`${env.API_ENDPOINT}/secured/ws/connect`}
				topics={[
					`/user/secured/reports/new-status`,
					'/user/secured/file-archive-requests/status-changed'
				]}
				headers={{
					'user-name': user?.id,
				}}
				onMessage={onMessageReceived}
				debug={true}
			/>
			<div className={styles.modal_body}>
				<div className={styles.wrapper}>
					<p className={styles.text}>Фильтр выгрузки</p>
					<Radio.Group name="radiogroup" value={radio} onChange={handlerRadio}>
						{!isHidden && <Radio value={undefined}>Все</Radio>}
						<Radio value="sessions">Данные выбранных сессий</Radio>
						<Radio value="projects">Данные выбранных проектов</Radio>
						{(type === 'R7' || type === 'R12') && <Radio value="tasks">Данные выбранных задач</Radio>}
					</Radio.Group>
				</div>
				{type === 'R7' && (
					<>
						<div className={styles.wrapper}>
							<p className={styles.text}>Сортировка данных отчета</p>
							<SortingRadio handler={handlerChangeRef(sortingRef)}/>
						</div>
						<div className={styles.wrapper}>
							<p className={styles.text}>Наличие фиксации реакций</p>
							<ReactionRadio handler={handlerChangeRef(reactionFixationRef)}/>
						</div>
					</>
				)}
				<SessionSelect
					setState={setSelectSessionsState}
					state={selectSessionsState}
					disabled={!radio}
				/>
				<ProjectSelect
					setState={setSelectProjectsState}
					state={selectProjectsState}
					sessionIds={selectSessionsState}
					disabled={radio !== 'projects' && radio !== 'tasks'}
					type={type}
				/>
				{(type === 'R7' || type === 'R12') && (
					<TaskSelect
						setState={setSelectTasksState}
						state={selectTasksState}
						projectsIds={selectProjectsState}
						disabled={radio !== 'tasks'}
					/>
				)}
				<div className={styles.wrapper}>
					<p className={styles.text}>Формат</p>
					<div className={styles.format}>
						{lowerCaseFileExtensions?.includes('pdf') && (
							<Checkbox checked={document.pdf} onChange={onChangeFile('pdf')}>
								PDF
							</Checkbox>
						)}
						{lowerCaseFileExtensions?.includes('docx') && (
							<Checkbox checked={document.docx} onChange={onChangeFile('docx')}>
								Word
							</Checkbox>
						)}
						{lowerCaseFileExtensions?.includes('xlsx') && (
							<Checkbox checked={document.xls} onChange={onChangeFile('xls')}>
								Excel(.xls)
							</Checkbox>
						)}
						<Button type={'primary'} onClick={onCLickCreateReporting} disabled={isDisabled(!Object.values(document).some(Boolean), selectSessionsState, selectProjectsState, selectTasksState, radio)}>
							Сформировать
						</Button>
					</div>
				</div>
				{type === 'R11' && data.id && <FileTask projectId={data.id} />}
				<hr className="hr" />
				<TableReporting type={type}/>
			</div>
		</Modal>
	)
}

function isDisabled(format: boolean, selectSessions: string[], selectProject: string[], selectTasks: string[], radio: 'projects' | 'sessions' | 'tasks' | undefined) {
	if (format) return true
	if (!radio && format) return true
	if (radio === 'sessions' && !selectSessions.length) return true
	if (radio === 'projects' && !selectSessions.length && !selectProject.length) return true
	if (radio === 'tasks' && !selectTasks.length) return true
	return false
}

export default Reporting
