import {put, select} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {selectParams} from "store/selectors";
import {
    GetTasksContentStartAction,
    SendContentBulkReviewErrorAction,
    SendContentBulkReviewStartAction,
    SendContentBulkReviewSuccessAction
} from "store/tasks/actions/content";
import TasksContentService from "../../../services/tasksContentService";


export function* sendContentBulkReview({recordsIds}: ReturnType<typeof SendContentBulkReviewStartAction>) {
    try {
        const {id} = yield select(selectParams)
        yield TasksContentService.sendBulkReview(recordsIds);

        yield put(SendContentBulkReviewSuccessAction())
        yield put(NotificationOpen('success', 'Записи отправлены на проверку'))
        if (id) yield put(GetTasksContentStartAction(id))
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(SendContentBulkReviewErrorAction(message))
    }
}