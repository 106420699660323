import {takeLatest} from 'redux-saga/effects'
import {ArchiveActionTypes} from "../../store/archive/action-types";
import getArchiveList from "./getArchiveList";
import deleteRecord from "./deleteRecord";
import deleteRecords from "./deleteRecords";
import {setArchiveFilter} from "./setArchiveFilter";

export default function* archiveSaga() {
	yield takeLatest(ArchiveActionTypes.GET_ARCHIVE_LIST_START, getArchiveList)
	yield takeLatest(ArchiveActionTypes.DELETE_ARCHIVE_RECORD_START, deleteRecord)
	yield takeLatest(ArchiveActionTypes.DELETE_ARCHIVE_RECORDS_START, deleteRecords)
	yield takeLatest(ArchiveActionTypes.SET_ARCHIVE_FILTER, setArchiveFilter)

}
