import {LogsActionTypes} from "./action-types";
import {TLogsAction} from "models/logs";

export const LogsAddAction = (data?: TLogsAction) => ({
    type: LogsActionTypes.LOGS_ADD, data
});
export const LogsPushAction = (logs: TLogsAction) => ({
    type: LogsActionTypes.LOGS_PUSH, logs
});

export const LogsLogoutAction = () => ({
    type: LogsActionTypes.LOGS_LOGOUT,
});
export const LogsSendStartAction = (logs: TLogsAction[]) => ({
    type: LogsActionTypes.LOGS_SEND_START,
    logs
});
export const LogsSendSuccessAction = () => ({
    type: LogsActionTypes.LOGS_SEND_SUCCESS,
});
export const LogsSendErrorAction = (error: any) => ({
    type: LogsActionTypes.LOGS_SEND_ERROR,
    error
});