import {useQuery, UseQueryResult} from "react-query";
import {useMemo} from "react";
import {getContentFormatTypesByProject} from "../api";
import {Id_Name_Code} from "shared/types/common";

type onSuccessType = (data: Id_Name_Code[]) => void
export function useGetContentFormatTypeByProject(id: string, onSuccess?: onSuccessType): UseQueryResult<Id_Name_Code[]> {
    return useQuery({
        queryKey: [getContentFormatTypesByProject.key, id],
        queryFn: () => getContentFormatTypesByProject.fetch(id),
        onSuccess,
        enabled: !!id
    });
}
export function useGetContentFormatTypeByProjectOptions(id: string, onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetContentFormatTypeByProject(id,onSuccess);
    const options = useMemo(() => data.map((el) => ({
        value: el.id,
        label: el.name,
        code: el.code,
        hidden: el.hidden
    })), [data]);
    return { data: options, ...rest };
}