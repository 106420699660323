import {put} from 'redux-saga/effects'
import {
	BulkSessionsTypeActionsErrorAction,
	BulkSessionsTypeActionsStartAction,
	BulkSessionsTypeActionsSuccessAction,
	GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import SessionTypeService from "services/sessionTypeService";

export function* bulkSessionsTypeActions({ userArr, action }: ReturnType<typeof BulkSessionsTypeActionsStartAction>) {
	try {
		yield SessionTypeService.bulkSessionsTypeActions(userArr, action)

		yield put(BulkSessionsTypeActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.sessionsType))
		yield put(NotificationOpen('success', 'Обновлено успешно'))

	} catch (error: any) {
		yield put(BulkSessionsTypeActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}
}
