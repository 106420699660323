import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    AddThematicErrorAction,
    AddThematicStartAction,
    AddThematicSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import HandbookService from "services/handbookService";

export function* addThematic({ data}: ReturnType<typeof AddThematicStartAction>) {
    try {
        yield HandbookService.addThematicRecord(data)
        yield put(AddThematicSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.thematic))

        yield put(NotificationOpen('success', 'Тематика добавлена'))
    } catch (error: any) {
        yield put(AddThematicErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить тематику'))
    }
}
