import React, {FC} from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {Tag} from 'antd'

type TDragDropTags = {
	name: string
	index: number
	tag: any
	tags: any
	handleClose: any
	setEditInputValue: any
	setEditInputIndex: any
}

const getColor = (value:any)=> {
	if(value === 'isMain') return '#F2FBCF'
	else return '#F5F5F5'
}

const DragDropTag: FC<TDragDropTags> = ({
	index,
	tags,
	tag,
	handleClose,
	setEditInputIndex,
	setEditInputValue,
}) => {
	return (
		<Draggable draggableId={`${tag + index}`} index={index}>
			{(provided, _snapshot) => (
				<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
					<div>
						<Tag
							className="edit-tag"
							closable={true}
							onClose={() => handleClose(tag)}
							color={index === 0 ? getColor('isMain') : getColor(tags[index].isConfirmed)}
						>
							<span
								onDoubleClick={(e) => {
									setEditInputIndex(index)
									setEditInputValue(tag)
									e.preventDefault()
								}}
							>
								{`${tag.slice(0, 20)}`}
							</span>
						</Tag>
					</div>
				</div>
			)}
		</Draggable>
	)
}

export default DragDropTag
