import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetTasksFieldsErrorAction,
    GetTasksFieldsStartAction,
    GetTasksFieldsSuccessAction
} from "../../store/tasks/actions/tasks";
import TasksService from "../../services/tasksService";
import {T_ID_NAME, T_ID_NAME_CODE} from "../../models/common";
import {TPostingTask} from "../../models/task";


const typeService = {
    "types": TasksService.getTypes,
    "statuses": TasksService.getStatuses,
    "stages": TasksService.getStages,
    "costType": TasksService.getCostType,
    "recordsStatuses": TasksService.getTaskRecordsStatuses,
}

export function* getTasksFields({typeField}: ReturnType<typeof GetTasksFieldsStartAction>) {
    try {
        const { data }: AxiosResponse<T_ID_NAME_CODE[] | T_ID_NAME[] | TPostingTask[]> = yield typeService[typeField]();

        yield put(GetTasksFieldsSuccessAction(typeField, data))
    } catch (error: any) {
        yield put(GetTasksFieldsErrorAction(error))
    }
}