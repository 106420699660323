import {z} from "zod";

export const FileSchema = z.object({
    id: z.string(),
    compressedId: z.string().nullable(),
    scaledId: z.string().nullable(),
    name: z.string(),
    type: z.enum([
        'PDF',
        'VIDEO',
        'IMAGE',
        'AUDIO',
        'ZIP',
        'CSV',
        'DOC',
        'DOCX',
        'PPT',
        'PPTX',
        'TXT',
        'XLS',
        'XLSX',
        'OTHER',
    ]),
    storagePath: z.string().nullable(),
    previewFile: z.object({
        id: z.string(),
        compressedId: z.string().nullable(),
        scaledId: z.string().nullable(),
        name: z.string(),
        type: z.enum([
            'PDF',
            'VIDEO',
            'IMAGE',
            'AUDIO',
            'ZIP',
            'CSV',
            'DOC',
            'DOCX',
            'PPT',
            'PPTX',
            'TXT',
            'XLS',
            'XLSX',
            'OTHER',
        ]),
        storagePath: z.string().nullable(),
        previewFile: z.any(),
        size: z.number().nullable(),
        originFileObj: z.any(),
    }).nullable(),
    size: z.number().nullable(),
    originFileObj: z.any(),
})
export const UploadFileSchema = z.object({
    uid: z.string(),
    size: z.number().optional(),
    name: z.string(),
    fileName: z.string().optional(),
    lastModified: z.number().optional(),
    lastModifiedDate: z.date().optional(),
    url: z.string().optional(),
    status: z
      .enum([
          "error",
          "success",
          "done",
          "uploading",
          "removed"
      ])
      .optional(),
    percent: z.number().optional(),
    thumbUrl: z.string().optional(),
    originFileObj: z.any().optional(),
    response: z.any().optional(),
    error: z.any().optional(),
    linkProps: z.any().optional(),
    type: z.string().optional(),
    xhr: z.any().optional(),
    preview: z.string().optional()
})
export type FileType = z.infer<typeof FileSchema>;
export type UploadFile = z.infer<typeof UploadFileSchema>;