import React from 'react';
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {SearchContentProjectModel} from 'features/search-project-content/index';
import {TagLib} from 'entities/tags'

const { Option} = Select;

export const Teg2 = () => {
    const put = useDispatch();
    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main);
    const thematics = useSelector(SearchContentProjectModel.selectors.thematics);
    const secondLevelTagIds = thematics[typeContent].secondLevelTagIds;

    const secondTags = TagLib.useGetProjectTagsOptions({level: 2, isForNationalProject: false, isAssignment: false})

    function onSelectChange(value: string[]) {
        put(SearchContentProjectModel.actions.setThematics(typeContent, {
            secondLevelTagIds: value
        }))
    }

    return (
        <div className="item">
            <div className="label">Теги проекта (2 уровня)</div>
            <div className="form-group">
                <Select
                    loading={secondTags?.isLoading}
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={true}
                    onChange={onSelectChange}
                    mode={"multiple"}
                    maxTagCount={"responsive"}
                    value={secondLevelTagIds}
                    placeholder={'Искать среди всех'}
                    filterOption={(input, option) => {
                        if (option === undefined || option === null) return false;
                        return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                >
                    {secondTags?.data?.map((el) => (
                        <Option
                            key={el.value}
                            value={el.value}
                            data-name={el.label}
                        >
                            {el.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
};
