import React from 'react'
import styles from '../styles.module.less'
import { RadioButtonsComponent } from 'components/formic-control'
import { useFormikContext } from 'formik'
import { TInitValues } from '../modal-content-records'
import classNames from 'classnames'

const RadioButtons = () => {
	const { values } = useFormikContext<TInitValues>()

	return (
		<div className={styles.radio_buttons}>
			<div className={classNames(styles.is_content, styles.item)}>
				<span className={styles.label}>Тип материала</span>
				<RadioButtonsComponent
					disabled={values.disabled}
					name={'isContent'}
					options={[
						{ label: 'Контент', value: 'true' },
						{ label: 'Рабочий материал', value: 'false' },
					]}
				/>
			</div>
			<div className={classNames(styles.is_accounted_content, styles.item)}>
				<span className={styles.label}>Тип учета</span>
				<RadioButtonsComponent
					disabled={values.disabled}
					name={'isAccountedContent'}
					options={[
						{ label: 'Учитываемый', value: 'true' },
						{ label: 'Неучитываемый', value: 'false' },
						{ label: 'Неопределённый', value: 'null' },
					]}
				/>
			</div>
		</div>
	)
}

export default RadioButtons
