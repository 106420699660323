import {combineReducers} from 'redux';

import tasksFieldsReducer from "./fileds";
import tasks from "./tasks";
import tasksContentReducer from "./task-content";
import tasksReportReducer from "./task-report";
import tasksGantKanbanReducer from "./tasks-gant-kanban";
import tasksReducer from "./records";
import {TaskModel} from "entities/task"

const reducer = combineReducers({
    fields: tasksFieldsReducer,
    tasks: tasks,
    tasksRecords: tasksReducer,
    tasksContent: tasksContentReducer,
    tasksReport: tasksReportReducer,
    tasksGantKanban: tasksGantKanbanReducer,
    taskComments: TaskModel.reducer
})
export type TTasksState = ReturnType<typeof reducer>;

export default reducer;