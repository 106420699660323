import { put } from 'redux-saga/effects'
import { GetActionsUsersStartAction, SetActionsUsersFilter } from 'store/options/actions/actions-actions-users'

export function* setActionsUsersFilter(_: ReturnType<typeof SetActionsUsersFilter>) {
	try {
		yield put(GetActionsUsersStartAction())
	} catch (error: any) {
		console.log(error)
	}
}
