import {combineReducers} from "redux";

import mainReducer from './main/reducer'
import contestsReducer from './contests/reducer'
import datesReducer from './dates/reducer'
import statusReducer from './status/reducer'
import indicatorsReducer from './indicators/reducer'
import indicatorsContentReducer from './indicators-content/reducer'
import thematicsReducer from './thematics/reducer'
import regionalsReducer from './regionals/reducer'
import contentReducer from './content/reducer'
import categoriesGenresReducer from './categories-genres/reducer'

import * as actionsMain from './main/actions'
import * as actionsContests from './contests/actions'
import * as actionsDates from './dates/actions'
import * as actionsStatus from './status/actions'
import * as actionsIndicators from './indicators/actions'
import * as actionsIndicatorsContent from './indicators-content/actions'
import * as actionsThematics from './thematics/actions'
import * as actionsRegionals from './regionals/actions'
import * as actionsContent from './content/actions'
import * as actionsCategoriesGenres from './categories-genres/actions'
import {ClearAllFilters, ClearFilters} from './actions'

export const reducer = combineReducers({
    main: mainReducer,
    contests: contestsReducer,
    dates: datesReducer,
    status: statusReducer,
    indicators: indicatorsReducer,
    indicatorsContent: indicatorsContentReducer,
    thematics: thematicsReducer,
    regionals: regionalsReducer,
    content: contentReducer,
    categoriesGenres: categoriesGenresReducer,
})
export type TCFState = ReturnType<typeof reducer>;
export * as selectors from './selectors';


export const actions = {
    ...actionsMain,
    ...actionsContests,
    ...actionsDates,
    ...actionsStatus,
    ...actionsIndicators,
    ...actionsIndicatorsContent,
    ...actionsThematics,
    ...actionsRegionals,
    ...actionsContent,
    ...actionsCategoriesGenres,
    ClearFilters,
    ClearAllFilters
}
