import {createSelector} from 'reselect';
import {TApplicationState} from '../aplication-state';

const getArchive= (state: TApplicationState) => state.archive;

export const selectArchiveList = createSelector(
    getArchive,
    (state) => ({
        archiveList: state.archiveList,
        isLoading: state.isLoading
    })
)
export const selectArchiveFilter = createSelector(
    getArchive,
    (state) => state.filter
)