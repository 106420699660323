import {put} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import HandbookService from 'services/handbookService'
import {
	GetHandbookStartAction,
	UploadErrorAction,
	UploadStartAction,
	UploadSuccessAction,
} from 'store/options/actions/actions-handbook'
import {TStatusesHandbookFeels} from 'models/handbook'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import LegalFormOrganizationService from "services/legalFormOrganizationService";
import SessionTypeService from "services/sessionTypeService";
import CategoryProjectService from "services/categoryProjectService";
import GenreProjectService from "services/genreProjectService";
import InternetResourcesService from "services/internetResourcesService";

type TUploadAction = {
	[key: string]: any
}

type TStatusString = {
	[key: string]: TStatusesHandbookFeels
}

const UploadAction: TUploadAction = {
	'organization': HandbookService.uploadOrganization,
	'internet': InternetResourcesService.uploadInternet,
	'KPE': HandbookService.uploadKpe,
	'thematic': HandbookService.uploadThematic,
	'content-direction': HandbookService.uploadContentDirection,
	'content-delivery': HandbookService.uploadContentDelivery,
	'documents': HandbookService.uploadDocuments,
	'regions': HandbookService.importRegions,
	'ranks': HandbookService.importRanks,
	'legal-form-organization': LegalFormOrganizationService.importLegalFormOrganization,
	'sessions-type': SessionTypeService.importSessionsType,
	'category-project': CategoryProjectService.importCategoryProject,
	'genre-project': GenreProjectService.importGenreProject,
}

const StatusString: TStatusString = {
	'organization': TStatusesHandbookFeels.organization,
	'internet': TStatusesHandbookFeels.internet,
	'KPE': TStatusesHandbookFeels.KPE,
	'thematic': TStatusesHandbookFeels.thematic,
	'content-delivery': TStatusesHandbookFeels.contentDelivery,
	'content-direction': TStatusesHandbookFeels.contentDirection,
	'documents': TStatusesHandbookFeels.documents,
	'regions': TStatusesHandbookFeels.regions,
	'ranks': TStatusesHandbookFeels.ranks,
	'legal-form-organization': TStatusesHandbookFeels.legalFormOrganization,
	'sessions-type': TStatusesHandbookFeels.sessionsType,
	'category-project': TStatusesHandbookFeels.categoryProject,
	'genre-project': TStatusesHandbookFeels.genreProject
}

export function* uploadExcel({ data, typeName }: ReturnType<typeof UploadStartAction>) {
	try {
		const formData = new FormData()
		formData.append('excel', data)
		yield UploadAction[typeName](formData)
		yield put(UploadSuccessAction())
		yield put(GetHandbookStartAction(StatusString[typeName]))

		yield put(NotificationOpen('success', 'Успешно'))
	} catch (error: any) {
		const message = CreateErrorMessage.response(error)
		yield put(UploadErrorAction(message ? message : 'Не удалось загрузить'))
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось загрузить'))
	}
}
