import { ModalProps } from 'antd';
import React from 'react';
import {eventBus} from "shared/services";

export type ModalType = {
	component: React.ReactNode;
} & ModalProps;

const eventName = 'ModalOpen';
export const subscribeOpen = (callback: Function) => {
	return eventBus.subscribe(eventName, callback);
};

export const open = (data: ModalType) => {
	eventBus.broadcast(eventName, data);
};
