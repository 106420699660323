const reporting = [
    {
        id: '28',
        selector: '.reporting__list',
        content: 'Это автозаполняемые шаблоны отчетов проекта. Просто выберите необходимый отчет, ' +
            'заполните форму и система сформирует документы на базе текущих данных проекта и его задач',
        role: []
    },

];
export default reporting;
