import { z } from 'zod'
import {FilterRequest, Id_Name_Code_Hidden_Schema, SearchResponse} from 'shared/types/common'

export const ContentCategorySchema = Id_Name_Code_Hidden_Schema.extend({
    formatTypes: z.array(Id_Name_Code_Hidden_Schema),
})
export type ContentCategory = z.infer<typeof ContentCategorySchema>

export const ContentCategoryUpdateSchema = z.object({
    code: z.string().nullable(),
    name: z.string(),
    hidden: z.boolean(),
    formatTypeIds: z.array(z.string()),
})
export type ContentCategoryUpdate = z.infer<typeof ContentCategoryUpdateSchema>

export type FilterData = {
    searchString?: string;
    hidden?: boolean;
}

export type FilterContentCategory = FilterRequest<FilterData>

export type ContentCategoryResponse = SearchResponse<ContentCategory>
