import {z} from "zod";

export const InternetResourceSchema = z.object({
    id: z.string(),
    name: z.string(),
    domainLink: z.string(),
    hidden: z.boolean(),
    image: z.string()
})
export const ChannelsSchema = z.object({
    id: z.string(),
    name: z.string(),
    link: z.string(),
    planPublicationCount: z.number(),
    internetResource: InternetResourceSchema
})
export type InternetResource = z.infer<typeof InternetResourceSchema>;
export type Channels = z.infer<typeof ChannelsSchema>;