import { FunctionalityInfoType } from '../types'

export const optionsList: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка пользователей с поиском, фильтрацией, сортировкой, пагинацией ',
		LevelGroupData: [
			{
				name: 'Поиск пользователей',
				text: 'Для поиска пользователей:',
				points: [
					'Нажмите "Введите название"',
					'Введите email / ФИО пользователя / ИНН и название организации / номер телефона',
					'Нажмите на кнопку поиска',
				],
				screenshots: ['/images/screenshots/options-list/search.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Фильтрация списка пользователей',
				text: 'Используйте фильтры для поиска:',
				points: ['Нажмите для перехода в окно фильтрации ', 'Примените фильтры', 'Закройте окно поиска'],
				important: ['! Нажмите "Сохранить фильтр" для сохранения фильтра'],
				screenshots: ['/images/screenshots/options-list/filters.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Сортировка отображения таблицы пользователей',
				text: 'Нажимайте на стрелки для сортировки отображения таблицы проектов',
				screenshots: ['/images/screenshots/options-list/table-sorting.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Настройка количества пользователей, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице пользователей:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Управление выводом полей',
		LevelGroupData: [
			{
				name: 'Настройка скрытия видимости полей таблицы',
				text: 'Для настройки скрытия видимости полей таблицы:',
				points: ['Нажмите на кнопку', 'Выберите столбцы для скрытия', 'Нажмите "Сохранить"'],
				topIcons: ['setting-gray'],
				screenshots: ['/images/screenshots/options-list/setting-field.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Добавление пользователей',
		LevelGroupData: [
			{
				name: 'Добавление пользователей через форму добавления пользователей',
				points: [
					'Нажмите для перехода в форму добавления пользователей',
					'Заполните обязательные и доступные поля формы',
					'Сохраните форму',
				],
				screenshots: ['/images/screenshots/options-list/add-user.png', '/images/screenshots/options-list/form.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Добавление пользователей через загрузку шаблона данных',
				points: [
					'Нажмите "Выгрузить шаблон" для скачивания шаблона',
					'Заполните шаблон',
					'Нажмите "Загрузить данные" для загрузки данных пользователя',
				],
				screenshots: ['/images/screenshots/import-template.png', '/images/screenshots/export-template.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Список пользователей keykloak',
		LevelGroupData: [
			{
				name: 'Список пользователей keykloak',
				text: 'Нажмите для перехода в список пользователей keykloak',
				screenshots: ['/images/screenshots/options-list/user-list.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Оповещение пользователей по email',
		LevelGroupData: [
			{
				name: 'Оповещение пользователей по email',
				text: 'Для оповещения пользователей по email:',
				points: [
					'Нажмите на кнопку для перехода в модальное окно оповещения пользователей',
					'Заполните поля формы',
					'Нажмите "Осуществить рассылку"',
				],
				screenshots: ['/images/screenshots/options-list/notification.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Отправка уведомлений',
		LevelGroupData: [
			{
				name: 'Отправка уведомлений',
				text: 'Для отправки уведомления:',
				points: ['Нажмите на кнопку', 'Выберите уведомление из списка'],
				screenshots: ['/images/screenshots/options-list/send-notification.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных пользователя',
		LevelGroupData: [
			{
				name: 'Редактирование данных пользователя',
				points: [
					'Нажмите для перехода в форму и редактирования данных пользователя',
					'Заполните обязательные и доступные поля формы',
					'Нажмите “Сохранить” ',
				],
				topIcons: ['edit-blue'],
				screenshots: ['/images/screenshots/options-list/form.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Массовые действия',
				text: 'Для массового выполнения действий:',
				points: [
					'Нажмите "Массовые действия"',
					'Выберите массовое действие из выпадающего списка',
					'Выберите пользователей',
					'Нажмите "Применить"',
				],
				screenshots: ['/images/screenshots/options-list/bulk-actions.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Удаление пользователя',
				text: 'Нажмите для удаления пользователя',
				icons: ['delete-red'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
]
