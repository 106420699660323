import React, {useState} from "react";
import {ErrorMessage, Field, useFormikContext} from 'formik';
import {FieldAttributes} from "formik/dist/Field";
import {Input, Tooltip} from "antd";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import TextError from "./text-error";
import {IInput} from "models/feels";
import InnerHTMLFunction from "utils/innerHTMLFunction";


const InputComponent: React.FC<IInput> = ({
    type,
    label,
    name,
    placeholder,
    showError = true,
    allowClear = true,
    disabled,
    tooltip = '',
    errorMessage,
    prefix
}) => {
    const {touched, setTouched} = useFormikContext()
    const [state, setState] = useState(false);
    const handler = () => {
        setState(!state)
    }
    return (
        <div className="form-group">
            {label && (
                <label htmlFor={name} dangerouslySetInnerHTML={InnerHTMLFunction(label)}/>
            )}
            <Field className="form-control" name={name}>
                {(el: FieldAttributes<any>) => {
                    const isError = errorMessage ? errorMessage : el.meta.error
                    const isTouched = el.form.touched[name] ? el.form.touched[name] : el.meta.touched;
                    let classError = ''
                    if ((typeof el.meta.error !== 'function') && isTouched && isError) classError = 'error'
                    return (
                        <>
                            <Tooltip title={tooltip}>
                                <Input
                                    className={classError}
                                    autoComplete="off"
                                    disabled={disabled}
                                    id={name}
                                    allowClear={allowClear}
                                    type={type === 'password' && state ? 'text' : type}
                                    placeholder={placeholder}
                                    prefix={prefix && (<div className="prefix">({prefix})</div>)}
                                    {...el.field}
                                    onBlur={(_e: any) => {
                                        setTouched({...touched, [name]: true})
                                    }}
                                />
                            </Tooltip>
                            {isTouched && isError && showError && (
                                <ErrorMessage name={name} component={TextError}/>
                            )}
                        </>
                    )
                }}
            </Field>
            {type === 'password' && (
                <div className='eye-input' onClick={handler}>
                    {state ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                </div>
            )}
            {/*// @ts-ignore*/}
            {errorMessage && touched[name] && <div className="invalid-feel">{errorMessage}</div>}
        </div>
    )
}
export default InputComponent
