import { put } from 'redux-saga/effects'
import {
	BulkGenreProjectActionsErrorAction,
	BulkGenreProjectActionsStartAction,
	BulkGenreProjectActionsSuccessAction,
	GetHandbookStartAction,
} from 'store/options/actions/actions-handbook'
import { TStatusesHandbookFeels } from 'models/handbook'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import { NotificationOpen } from 'store/notification/actions'
import GenreProjectService from 'services/genreProjectService'

export function* bulkGenreProjectActions({ userArr, action }: ReturnType<typeof BulkGenreProjectActionsStartAction>) {
	try {
		yield GenreProjectService.bulkGenreProjectActions(userArr, action)

		yield put(BulkGenreProjectActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.genreProject))
		yield put(NotificationOpen('success', 'Обновлено успешно'))
	} catch (error: any) {
		yield put(BulkGenreProjectActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}
}
