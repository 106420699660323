import { put } from 'redux-saga/effects'
import {
	GetContentModuleSectionsStartAction,
	GetContestModuleSectionsErrorAction,
	GetContestModuleSectionsSuccessAction,
} from 'store/content/content/actions'
import { AxiosResponse } from 'axios'
import { ContentService } from 'services/contentService'
import { T_ID_NAME_CODE } from '../../models/common'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import { NotificationOpen } from '../../store/notification/actions'

export function* getContentModuleSections({ projectIds }: ReturnType<typeof GetContentModuleSectionsStartAction>) {
	try {
		const { data }: AxiosResponse<T_ID_NAME_CODE[]> = yield ContentService.getContentModuleSections(projectIds)
		yield put(GetContestModuleSectionsSuccessAction(data))
	} catch (error: any) {
		yield put(GetContestModuleSectionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить разделы модуля Контент'))
	}
}
