import {AxiosResponse} from 'axios'
import {put} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {
	GetTagsSecondLevelTaskRecordContentErrorAction,
	GetTagsSecondLevelTaskRecordContentStartAction,
	GetTagsSecondLevelTaskRecordContentSuccessAction
} from "store/tags/actions";
import {TTagSecondLevel} from "models/tags";
import {TagsService} from "services/tagsService";

export default function* getSecondLevelTaskRecordContent({firstLevelTagIds}: ReturnType<typeof GetTagsSecondLevelTaskRecordContentStartAction>) {
	try {
		const {data}: AxiosResponse<TTagSecondLevel[]> = yield TagsService.getTagsSecondLevelContent(firstLevelTagIds);
		yield put(GetTagsSecondLevelTaskRecordContentSuccessAction(data))
	} catch (error: any) {
		yield put(GetTagsSecondLevelTaskRecordContentErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
