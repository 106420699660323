import {put, select} from "redux-saga/effects";
import {Logger} from "shared/services";
import {TTaskRecordsFilter} from "models/task/task-list";
import {selectReportTasksFilter} from "store/tasks/selectors/selectors-task-report";
import {TaskTypes} from 'entities/task'
import {SetTaskReportFilter} from "store/tasks/actions/report";
import {FilterDataReport} from "models/task/task-report";

type TFilterDataTasks = {
    filterData: FilterDataReport,
}
export function* taskReach(filter: string, filterId: string | undefined) {
        try {
            const filterTasks: TTaskRecordsFilter = yield select(selectReportTasksFilter)
            if (filterId) {
                const filterDataTasks: TFilterDataTasks = JSON.parse(filter);
                yield  put(SetTaskReportFilter({...filterTasks, filterData: {searchString: '', ...filterDataTasks}}, TaskTypes.ReportType.CONTENT_REACH_REPORT))
            } else {
               yield put(SetTaskReportFilter({page: 1, pageSize: 50, filterData: { searchString: '' } }, TaskTypes.ReportType.CONTENT_REACH_REPORT))
            }
        } catch (error) {
            Logger.error(error)
        }
}
