import { FunctionalityInfoType } from '../types'

export const calendar: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка проектов с поиском, сортировкой, пагинацией',
		LevelGroupData: [
			{
				name: 'Поиск проектов',
				text: 'Для поиска проектов по названию, номеру договора, номеру заявки:',
				points: ['Нажмите "Введите название"', 'Введите текст', 'Нажмите на кнопку поиска'],
				screenshots: ['/images/screenshots/search-screenshot.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Фильтрация проектов',
				text: 'Используйте фильтры для поиска:',
				points: [
					'Нажмите для перехода в окно фильтрации',
					'Примените фильтры',
					'Нажмите “Найти” для поиска проектов',
					'Нажмите "Сбросить фильтры" для сброса неактуальных фильтров',
				],
				important: [
					'! Нажмите "Развернуть все разделы" для раскрытия списка доступных фильтров либо "Свернуть все разделы" для скрытия развернутого списка фильтров',
					'! Нажмите "Сохранить фильтр" для сохранения фильтра ',
				],
				screenshots: ['/images/screenshots/filters-projects.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Сортировка диаграммы',
				text: 'Нажмите для сортировки отображения календаря проекта по месяцам / неделям / дням',
				screenshots: ['/images/screenshots/sorting-chart.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Настройка количества проектов, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице проектов:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр данных по этапу проекта',
		LevelGroupData: [
			{
				name: 'Просмотр данных проекта и команды',
				text: 'Нажмите для перехода в модальное окно просмотра данных проекта и команды',
				icons: ['info-circle'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр информации на диаграмме',
				text: 'Наведите курсор на название проекта на диаграмме для просмотра дополнительной информации',
				screenshots: ['/images/screenshots/view-info-chart.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Индикатор просроченности задачи ',
				text: 'Наведите курсор на индикатор для просмотра статуса:',
				points: [
					{
						label: 'Красный - содержит просроченные задачи',
						icon: 'light-red'
					},
					{
						label: 'Серый - не содержится просроченных задач',
						icon: 'light-gray'
					}
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр задач этапа',
				text: 'Нажмите "Задачи" для просмотра задач этапа в модальном окне "Задачи этапа"',
				screenshots: ['/images/screenshots/calendar/task-button.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр информации на диаграмме',
				text: 'Наведите курсор на название этапа на диаграмме для просмотра дополнительной информации',
				screenshots: ['/images/screenshots/info-chart-sprint.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
