import React, {FC} from 'react'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {FieldAttributes} from 'formik/dist/Field'
import {Upload} from 'antd'
import TextError from './text-error'
import {DeleteOutlined, PaperClipOutlined} from '@ant-design/icons'
import {IUpload} from '../../models/feels'
import {UploadChangeParam} from 'antd/es/upload'
import {UploadFile} from 'antd/es/upload/interface'
import {useDispatch} from 'react-redux'
import {NotificationOpen} from '../../store/notification/actions'

const validateSize = (typeFile: string, item: any) => {
	let validate: boolean = true
	switch (typeFile) {
		case 'application/pdf':
			if (item.size >= 500000000) {
				validate = false
			}
			break
		case 'video/*':
			if (item.size >= 5000000000) {
				validate = false
			}
			break
		case 'audio/*':
			if (item.size >= 100000000) {
				validate = false
			}
			break
		case 'image/*':
			if (item.size >= 10000000) {
				validate = false
			}
			break
		default:
			validate = true
	}
	return validate
}
const validateUpload = (_file: File) => {
	return false
}

const UploadComponent: FC<IUpload> = ({
  label,
  name,
  placeholder,
  typeUploadFile = 'application/pdf',
  existingLabel,
  existingLabelDisabled = false,
  multi = true,
  disabled,
  extraOptions,
  icon = false,
  callback,
}) => {
	const {setFieldValue} = useFormikContext()
	const put = useDispatch()


	const uploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
		if (info.fileList.length === 0) {
			return
		}
		const files = info.fileList.filter((item) => {
			try {
				const validate = validateSize(typeUploadFile, item)
				!validate && put(NotificationOpen('error', 'Ошибка', 'Файл слишком большой'))
				return validate
			} catch (_error) {
				return false
			}
		})
		setFieldValue(name, files)
	}
	const removeExistingImage = (elem: any, existingFiles: any[]) => (_e: any) => {
		if (existingLabel && !existingLabelDisabled) {
			callback && callback()
			const newArray = existingFiles.filter((el: any) => el.id !== elem)
			setFieldValue(existingLabel, newArray)
		}
	}
	const onRemoveFile = (_file: any) => {
		setFieldValue(name, [])
	}
	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			<Field className="form-control" name={name}>
				{(el: FieldAttributes<any>) => {
					const val = el.field.value;
					const isTouched = el.form.touched[name];
					return (
						<>
							<Upload
								disabled={disabled}
								className="upload-component"
								accept={typeUploadFile}
								listType="picture"
								multiple={multi}
								beforeUpload={validateUpload}
								onChange={uploadChange}
								onRemove={onRemoveFile}
								fileList={val}
							>
								{multi ? (
									<div className="upload-btn">
										{icon || <PaperClipOutlined/>}
									</div>
								) : val.length > 0 ? null : (
									<div className="upload-btn">
										{icon || <PaperClipOutlined/>}
										{placeholder}
									</div>
								)}
							</Upload>
							{isTouched && el.meta.error && <ErrorMessage name={name} component={TextError}/>}
						</>
					)
				}}
			</Field>
			{existingLabel && (
				<Field className="form-control" name={existingLabel}>
					{(el: FieldAttributes<any>) => {
						const val = el.field.value;
						return (
							<>
								<div className="existingLabel">
									{val.map((elem: any) => (
										<div
											key={elem.id}
											className={`existingLabel__item existingLabel__item--${extraOptions} ${existingLabelDisabled && 'disabled'}`}
											onClick={removeExistingImage(elem.id, val)}
										>
											{!extraOptions && <PaperClipOutlined/>}
											{extraOptions && <DeleteOutlined/>}
											<strong>{elem.name}</strong>
											{!extraOptions && <DeleteOutlined/>}
										</div>
									))}
								</div>
							</>
						)
					}}
				</Field>
			)}

		</div>
	)
}
export default UploadComponent
