import React, {Suspense} from 'react';
import {Spin} from "antd";

const SuspenseComponent = (props: any) => (
    <Suspense
        fallback={
            <div className="spin-suspense">
                <Spin size="large" />
            </div>
        }
    >
        {props.children}
    </Suspense>
);
export default SuspenseComponent;