import {takeEvery} from "redux-saga/effects";
import {LogsActionTypes} from "../../store/logs/action-types";
import {sendLogs} from "./sendLogs";
import {addLogs} from "./addLogs";
import {logoutLogs} from "./logoutLogs";


export default function* logsSaga() {
    yield takeEvery(LogsActionTypes.LOGS_ADD, addLogs)
    yield takeEvery(LogsActionTypes.LOGS_SEND_START, sendLogs)
    yield takeEvery(LogsActionTypes.LOGS_LOGOUT, logoutLogs)
}