import {InferValueTypes} from '../../models/common'
import * as actions from './actions'
import {ErrorActionState, StartActionState, SuccessActionState} from '../../utils/reducerHalper'
import {Logout} from '../auth/reducer'
import {AuthActionTypes} from '../auth/action-types'
import {SprintsActionTypes} from './action-types'
import { SprintTypes } from 'entities/sprints'

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TSprintsState = {
	sprints: SprintTypes.SprintProject[] | null
	sprint: SprintTypes.SprintProject | null
	editingKey: string
	isLoading: boolean
	error: any
}

export const initialState: TSprintsState = {
	sprints: null,
	sprint: null,
	editingKey: '',
	isLoading: false,
	error: null,
}

export default function reducer(state: TSprintsState = initialState, action: ActionTypes | Logout): TSprintsState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return { ...initialState }
		/** START ACTIONS  */
		case SprintsActionTypes.GET_SPRINTS_START:
			return StartActionState(state)
		case SprintsActionTypes.GET_SPRINT_ID_START:
			return StartActionState(state)
		case SprintsActionTypes.CREATE_SPRINT_START:
			return StartActionState(state)
		case SprintsActionTypes.UPDATE_SPRINT_ID_START:
			return StartActionState(state)
		case SprintsActionTypes.DELETE_SPRINT_ID_START:
			return StartActionState(state)

		/** SUCCESS ACTIONS  */
		case SprintsActionTypes.GET_SPRINTS_SUCCESS:
			return {
				...SuccessActionState(state),
				sprints: action.data,
			}
		case SprintsActionTypes.GET_SPRINT_ID_SUCCESS:
			return {
				...SuccessActionState(state),
				sprint: action.data,
			}
		case SprintsActionTypes.CREATE_SPRINT_SUCCESS:
			return SuccessActionState(state)
		case SprintsActionTypes.UPDATE_SPRINT_ID_SUCCESS:
			return SuccessActionState(state)
		case SprintsActionTypes.DELETE_SPRINT_ID_SUCCESS:
			return SuccessActionState(state)
		case SprintsActionTypes.SET_EDITING_KEY :
			return {
				...state,
				editingKey: action.key
			}
		case SprintsActionTypes.CLEAR_EDITING_KEY :
			return {
				...state,
				editingKey: ''
			}

		/** ERROR ACTIONS  */
		case SprintsActionTypes.GET_SPRINTS_ERROR:
			return ErrorActionState(state, action.error)
		case SprintsActionTypes.GET_SPRINT_ID_ERROR:
			return ErrorActionState(state, action.error)
		case SprintsActionTypes.CREATE_SPRINT_ERROR:
			return ErrorActionState(state, action.error)
		case SprintsActionTypes.UPDATE_SPRINT_ID_ERROR:
			return ErrorActionState(state, action.error)
		case SprintsActionTypes.DELETE_SPRINT_ID_ERROR:
			return ErrorActionState(state, action.error)
		default:
			return state
	}
}
