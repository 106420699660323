import {put, select} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {AxiosResponse} from "axios";
import {
    TasksReportCopyrightService,
    TasksReportEstimateService,
    TasksReportPostingService,
    TasksReportReachService,
} from "services/tasksReportService";

import {
    GetTasksRecordsErrorAction,
    GetTasksRecordsStartAction,
    GetTasksRecordsSuccessAction,
    SetTasksRecordsFilterAction
} from "store/tasks/actions/records";
import {TTaskRecordsFilter} from "models/task/task-list";
import {selectTasksRecordsFilter, selectTasksRecordsInfo} from "store/tasks/selectors/tasks-records";
import TasksContentService from "services/tasksContentService";
import {ReportType} from "models/task/types";
import ZodParser from 'shared/services/zod-parser';
import { TaskTypes } from "entities/task";


export function* getTasksRecords(_action: ReturnType<typeof GetTasksRecordsStartAction>) {
    try {
        const {typeTask, typeReport } = yield select(selectTasksRecordsInfo)
        const filter: TTaskRecordsFilter = yield select(selectTasksRecordsFilter);
        const checkValidPage = (data: TaskTypes.TaskRecordsResponse<any>): boolean => {
            return !!(data?.totalCount && data.page * data.pageSize >= data?.totalCount && data.totalCount !== 0)
        }

        if (typeTask.code === "CONTENT") {
            const {data}: AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.ContentRecords[]>> = yield TasksContentService.getTaskRecords(filter)
            ZodParser.parse(
              TaskTypes.ContentRecordsSchema.array(),
              data.content,
              '/tasks/content/records/search'
            );
            if (checkValidPage(data)) {
                yield put(SetTasksRecordsFilterAction({
                    filter: {...filter, page: 1},
                    typeTask,
                    typeReport,
                }))
                yield put(GetTasksRecordsStartAction())
            } else yield put(GetTasksRecordsSuccessAction(data))
        } else {
            if (typeReport.code === ReportType.CONTENT_POSTING_REPORT) {
                const {data}: AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.PostingRecords[]>> = yield TasksReportPostingService.getTaskRecords(filter)
                ZodParser.parse(
                  TaskTypes.PostingRecordsSchema.array(),
                  data.content,
                  '/tasks/posting/records/search'
                );
                if (checkValidPage(data)) {
                    yield put(SetTasksRecordsFilterAction({
                        filter: {...filter, page: 1},
                        typeTask,
                        typeReport
                    }))
                    yield put(GetTasksRecordsStartAction())
                } else yield put(GetTasksRecordsSuccessAction(data))
            }
            if (typeReport.code === ReportType.CONTENT_REACH_REPORT) {
                const {data}: AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.ReachRecords[]>> = yield TasksReportReachService.getTaskRecords(filter)
                ZodParser.parse(
                  TaskTypes.ReachRecordsSchema.array(),
                  data.content,
                  '/tasks/reach/records/search'
                );
                if (checkValidPage(data)) {
                    yield put(SetTasksRecordsFilterAction({
                        filter: {...filter, page: 1},
                        typeTask,
                        typeReport
                    }))
                    yield put(GetTasksRecordsStartAction())
                } else yield put(GetTasksRecordsSuccessAction(data))
            }
            if (typeReport.code === ReportType.COPYRIGHT_LIST) {
                const {data}: AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.CopyrightRecords[]>> = yield TasksReportCopyrightService.getTaskRecords(filter)
                ZodParser.parse(
                  TaskTypes.CopyrightRecordsSchema.array(),
                  data.content,
                  '/tasks/copyright/records/search'
                );
                if (checkValidPage(data)) {
                    yield put(SetTasksRecordsFilterAction({
                        filter: {...filter, page: 1},
                        typeTask,
                        typeReport
                    }))
                    yield put(GetTasksRecordsStartAction())
                } else yield put(GetTasksRecordsSuccessAction(data))
            }
            if (typeReport.code === ReportType.PROJECT_ESTIMATE) {
                const {data}: AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.EstimateRecords[]>> = yield TasksReportEstimateService.getTaskRecords(filter)
                ZodParser.parse(
                  TaskTypes.EstimateRecordsSchema.array(),
                  data.content,
                  '/tasks/estimate/records/search'
                );
                if (checkValidPage(data)) {
                    yield put(SetTasksRecordsFilterAction({
                        filter: {...filter, page: 1},
                        typeTask,
                        typeReport
                    }))
                    yield put(GetTasksRecordsStartAction())
                } else yield put(GetTasksRecordsSuccessAction(data))
            }
        }

    } catch (error: any) {
        yield put(GetTasksRecordsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen(
            'error',
            'Ошибка',
            message ? message : 'Не удалось получить задачи'
        ))
    }
}
