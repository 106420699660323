import React, {FC} from 'react';
import './index.less'
import {Empty, Modal, Spin} from "antd";
import useHandlerModal from "hooks/handlModal";
import {useSelector} from "react-redux";
import {selectReachStatistic, selectReportTasksStatus} from "store/tasks/selectors/selectors-task-report";
import {TReachStatistic} from "models/task/task-report";
import moment from "moment";

type Props = {
    status: boolean
}

const ModalReachStatistic: FC<Props> = ({status }) => {
    const {isVisible, closeModal} = useHandlerModal(status);
    const statistic = useSelector(selectReachStatistic)
    const {isLoading} = useSelector(selectReportTasksStatus)

    const getPercent = () => {
        const actual = statistic?.[0];
        const previous = statistic?.[1];

        if (!actual || !previous || previous.reachAmount === 0) {
            return previous?.reachAmount === 0 ? '—' : null;
        }

        const percent = ((actual.reachAmount - previous.reachAmount) / previous.reachAmount) * 100;
        const roundedPercent = Math.round(percent * 100) / 100;

        return roundedPercent > 0 ? `+${roundedPercent}` : roundedPercent;
    };

    return (
            <Modal
                className={'modal-reach-statistic'}
                title="Просмотр статистики охватов публикации"
                visible={isVisible}
                onOk={()=>{}}
                onCancel={closeModal}
                footer={false}
            >
                <Spin spinning={isLoading}>
                    <div className="modal-reach-statistic__percent">
                        <span className="modal-reach-statistic__percent__text">Изменение текущего охвата к предыдущему</span>
                        <span className={`modal-reach-statistic__percent__percent ${typeof getPercent() === 'number' && 'coral'}`}>
                                 {getPercent() === null ? 'нет данных' : getPercent() === '—' ? '—' : getPercent() + ' %'}
                        </span>
                    </div>
                    <div className="modal-reach-statistic__title">
                        <span className="modal-reach-statistic__title__title">Дата и время охвата</span>
                        <span className="modal-reach-statistic__title__text">Охват</span>
                    </div>
                    <hr/>

                    {statistic && statistic.length > 0 ? statistic.map((it: TReachStatistic,index)=>{
                        return (
                            <div key={index} className={`modal-reach-statistic__statistic ${index === 0 || index === 1 ?'modal-duplication-reach-statistic__statistic--bg' : ''}`}>
                            <span className="modal-reach-statistic__statistic__date">
                                {moment(it.reachDateTime).format('HH:mm / DD.MM.yy')}
                            </span>
                                <span className="modal-reach-statistic__statistic__text">{index === 0 &&<em>Текущий</em>}{index === 1 && <em>Предыдущий</em>}</span>
                                <span className="modal-reach-statistic__statistic__amount">{it.reachAmount}</span>
                            </div>
                        )
                    }) : (
                        <Empty/>
                    )}
                </Spin>
            </Modal>
    );
};

export default ModalReachStatistic;