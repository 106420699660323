const calendarProject = [
    {
        id: '1_calendarProject',
        selector: '.form-group + div',
        content: 'Это расширенный фильтр для списка проектов, чтобы вам легко было найти нужные данные',
        role: [],
    },
    {
        id: '2_calendarProject',
        selector: '.gant-table',
        content: 'Календарный график проектов отображает продолжительность этапов, в каком статусе находятся этапы проектов, а также количество всех и выполненных задач этих этапов. При нажатии на счетчик задач можно детально ознакомиться со списком всех задач выбранного этапа. Для просмотра карточки проекта нажмите на "Название проекта"',
        role: [],
    },
]

export default calendarProject
