const ervRanges = [
    {
        id: '1-erv-ranges',
        selector: '.tour-add',
        content:'Для наполнения справочника новыми данными, нажмите “Добавить...”',
        role: [],
    },
    {
        id: '2-erv-ranges',
        selector: '.ant-table',
        content:
            'Таблица справочной информации отражает текущие данные, которые используются в проектах и задачах системы, позволяет отсортировать данные, сделать их видимыми и невидимыми для пользователей системы, а также удалить и отредактировать данные',
        role: [],
    },
]

export default ervRanges
