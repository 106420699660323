import {AxiosResponse} from "axios";
import $api from "../http";
import {T_ID_NAME, T_ID_NAME_CODE} from "../models/common";
import {TPostingTask, TTaskRequestDto, TTaskResponse} from "../models/task";
import {TDuplicationSchedule, TTasksKanban, TTasksKanbanFilter} from "../models/task/task.kanban";
import {TAllTasksListResponse, TTaskFilter, TTaskListResponse} from "../models/task/task-list";
import {MassCreationOfProjectTasksTypes} from "features/mass-creation-of-project-tasks";
import {TaskTypes} from 'entities/task'
import { MassMatchingOfTaskRecordsTypes } from "features/mass-matching-of-task-records";
import { ProjectTypes } from "entities/project";
import ZodParser from '../shared/services/zod-parser';

/**
 * @category Service
 * @subcategory Tasks
 * @class TasksService
 */
export default class TasksService {
    static async getAllTask(data: TTaskFilter): Promise<AxiosResponse<TTaskListResponse>> {
        const filter = { ...data, page: data.page - 1};
        return $api.post<TTaskListResponse>('/tasks/search', filter)
    }
    /**
     * Получение всех этапов задач
     */
    static async getStages(): Promise<AxiosResponse<T_ID_NAME_CODE[]>> {
        return $api.get<T_ID_NAME_CODE[]>('/tasks/stages')
    }
    /**
     * Получение всех статусов задач
     */
    static getStatusesKey = '/tasks/statuses'
    static async getStatuses(): Promise<AxiosResponse<T_ID_NAME_CODE[]>> {
        return $api.get<T_ID_NAME_CODE[]>('/tasks/statuses')
    }
    /**
     * Получение всех типов задач
     */
    static async getTypes(): Promise<AxiosResponse<T_ID_NAME[]>> {
        return $api.get<T_ID_NAME[]>('/tasks/types')
    }
    /**
     * Создание новой задачи
     */
    static async createTask(data: TTaskRequestDto): Promise<AxiosResponse<string>> {
        return $api.post<string>('/tasks', data)
    }
    /**
     * Обновление задачи
     * @param taskId
     * @param {TTaskRequestDto} data
     */
    static async updateTask(taskId: string, data: TTaskRequestDto): Promise<AxiosResponse<string>> {
        return $api.put<string>(`/tasks/${taskId}`, data)
    }
    /**
     * Получение задачи по ID
     */
    static async getTask(taskId: string): Promise<AxiosResponse<TTaskResponse>> {
        return $api.get<TTaskResponse>(`/tasks/${taskId}`)
    }
    /**
     * Удаление задачи
     */
    static async deleteTask(taskId: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/tasks/${taskId}`)
    }
    /**
     * Получение задач по проекту для диаграммы Ганта
     */
    //todo перенести в проект
    static async getTasksGant(projectId: string, payment?: boolean): Promise<AxiosResponse<ProjectTypes.GanttSprint[]>> {
        const paymentValue = payment !== undefined ? {payment} : {};
        return $api({
            method: 'GET',
            url: `/projects/${projectId}/tasks/gantt`,
            params: paymentValue,
            headers: {
                'Actioncode': 'GET_PROJECTS_PROJECT_ID_TASKS_GANTT'
            }
        })
    }
    /**
     * Получение задач по проекту для диаграммы Ганта
     */
    static async getTasksKanban(projectId: string, filter: TTasksKanbanFilter): Promise<AxiosResponse<TTasksKanban>> {
        return $api.post<TTasksKanban>(`/projects/${projectId}/tasks/kanban`, filter, {
            headers: {
                'Actioncode': 'POST_PROJECTS_PROJECT_ID_TASKS_KANBAN'
            }
        })
    }
    /**
     * Получение задач по проекту для диаграммы Ганта
     */
    static async updateStatusTasks(taskId: string, statusId: string): Promise<AxiosResponse<any>> {
        return $api({
            method: 'PUT',
            url: `/tasks/${taskId}/status`,
            params: {
                statusId: statusId
            },
            headers: {
                'Actioncode': 'UPDATE_TASKS_TASK_ID_STATUS'
            }
        })
    }
    //todo удалить если не используется (15.11.23)
    /**
     * Получение готовых задач на размещение контента по проекту
     */
    static async getTasksPosting(projectId: string): Promise<AxiosResponse<TPostingTask[]>> {
        return $api({
            method: 'GET',
            url: `/tasks/posting`,
            params: {
                projectId: projectId
            }
        })
    }
    /**
     * Получение всех статей расходов
     */
    static async getCostType(): Promise<AxiosResponse<T_ID_NAME_CODE[]>> {
        return $api.get<T_ID_NAME_CODE[]>(`/cost-types`)
    }
    /**
     * Дублирование задачи
     */
    static async duplicateTasks(taskId: string, dateList: string[]): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/tasks/${taskId}/duplication`, dateList, {
            headers: {
                'Actioncode': 'UPDATE_TASKS_TASK_ID_DUPLICATION'
            }
        })
    }
    /**
     * Создания расписания авто создания задачи
     */
    static async duplicateSchedulersTasks(data: TDuplicationSchedule): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/tasks/duplication/schedulers`, data)
    }
    /**
     * Обновление расписания авто создания задачи
     */
    static async updateDuplicateSchedulersTasks(taskDuplicationScheduleId: string, dateList: TDuplicationSchedule): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/tasks/duplication/schedulers/${taskDuplicationScheduleId}`, dateList)
    }
    /**
     * Получение списка задач без пагинации
     */
    static getAllTasksKey = '/tasks/all'
    static async getAllTasks(data: TaskTypes.TaskSearchDto): Promise<AxiosResponse<TAllTasksListResponse[]>> {
        ZodParser.parse(TaskTypes.TaskSearchDtoSchema, data, 'getAllTasks TaskSearchDto');
        return $api.post<TAllTasksListResponse[]>('/tasks/all', data);
    }
    /**
     * Получение списка задач без пагинации для статистики
     */
    static getTasksStatisticKey = '/tasks/statistic'
    static async getTasksStatistic(data: {sprintIds: string[]}): Promise<AxiosResponse<TaskTypes.Statistic[]>> {
        return $api.post<TaskTypes.Statistic[]>('/tasks/statistic', data)
    }

    /**
     * Получение всех статусов записей задач, доступных для текущей роли
     */
    static getTaskRecordsStatusesKey = '/tasks/records/statuses/statuses'
    static async getTaskRecordsStatuses(): Promise<AxiosResponse<T_ID_NAME_CODE[]>> {
        return $api.get<T_ID_NAME_CODE[]>('/tasks/records/statuses/statuses')
    }

    /**
     * Массовое создание задач
     */
    static async bulkCreationTask(data: MassCreationOfProjectTasksTypes.TasksBulkCreation): Promise<AxiosResponse<unknown>> {
        return $api.post('/tasks/bulk-creation', data)
    }
    /**
     * Массовое голосование записей задач
     */
    static bulkVoteTaskRecordsKey = '/tasks/records/bulk-vote'
    static async bulkVoteTaskRecords(data: MassMatchingOfTaskRecordsTypes.Request): Promise<AxiosResponse<unknown>> {
        return $api.post('/tasks/records/bulk-vote', data)
    }
    /**
     * Массовое удаление задач
     */
    static async bulkDeleteTasks(recordsId: string[]): Promise<AxiosResponse<unknown>> {
        return $api.delete('/tasks/bulk', {data: recordsId})
    }

    /**
     * Получение списка комментариев для задач с пагинацией
     */
    static async getCommentsTask(data: TaskTypes.Filter): Promise<AxiosResponse<TaskTypes.CommentsResponse>> {
        const filter = { ...data, page: data.page - 1};
        return $api.post<TaskTypes.CommentsResponse>('/task-comments/search', filter)
    }
    /**
     * Update комментария для задачи
     */
    static async updateCommentsTask(id: string, data: TaskTypes.CommentsRequest): Promise<AxiosResponse<unknown>> {
        return $api.put<unknown>(`/task-comments/${id}`, data)
    }
    /**
     * Создание комментария для задачи
     */
    static async createCommentsTask(data: TaskTypes.CommentsRequest): Promise<AxiosResponse<unknown>> {
        return $api.post<unknown>(`/task-comments`, data)
    }
    /**
     * Delete комментария для задачи
     */
    static async deleteCommentsTask(id: string): Promise<AxiosResponse<unknown>> {
        return $api.delete<unknown>(`/task-comments/${id}`)
    }
    /**
     * Изменение статуса задач
     */
    static async changeStatusTask(status: TaskTypes.TasksStatus, ids: string[]): Promise<AxiosResponse<unknown>> {
        return $api.post<unknown>(`/tasks/set-status/${status}`,ids)
    }
}
