import {axiosRequest} from 'shared/config/axios';
import {
	ApprovedContentFormats,
	ApprovedContentFormatsSchema,
	ContentFormats,
	ContentFormatsSchema,
	NotVotedRecordCounts,
	NotVotedRecordCountsSchema,
	ProjectUser,
} from '../types';
import {MapPerson} from "../lib/map-person";
import $api from "../../../http";
import {AxiosResponse} from "axios";
import {Notification} from 'processes/notification';

/**
 * project-view-details-controller
 */

export class GetProjectUsers {
	static key = (projectId: string) => `/projects/${projectId}/users`

	static async fetch(projectId: string): Promise<AxiosResponse<ProjectUser[]>> {
		try {
			return  await $api.get(`/projects/${projectId}/users`, {
				headers: {
					'Actioncode': 'GET_PROJECTS_PROJECT_ID_USERS'
				}
			})
		} catch (e) {
			Notification.open({ type: 'error', info: {message: 'Ошибка в получение пользователей проекта'} });
			throw e
		}
	}
	static async mapUsers(projectId: string){
		const {data: users} = await GetProjectUsers.fetch(projectId)
		return MapPerson(users);
	}
}

export const notVotedRecordCounts = {
	key: (projectId: string) => `/projects/${projectId}/inspectors/not-voted-record-counts`,
	fetch: async (projectId: string) => {
		const {data} = await axiosRequest<NotVotedRecordCounts[]>({
			url: `/projects/${projectId}/inspectors/not-voted-record-counts`,
			method: 'GET',
			showError: true,
			parser: NotVotedRecordCountsSchema.array(),
			headers: {
				'Actioncode': 'GET_PROJECTS_PROJECT_ID_INSPECTORS_NOT_VOTED_RECORD_COUNTS\n'
			}
		})
		return data;
	}
}
export const approvedContentFormats = {
	key: (projectId: string) => `/projects/${projectId}/approved-content-formats`,
	fetch: async (projectId: string) => {
		const {data} = await axiosRequest<ApprovedContentFormats[]>({
			method: 'GET',
			url: `/projects/${projectId}/approved-content-formats`,
			parser: ApprovedContentFormatsSchema.array(),
			headers: {
				'Actioncode': 'GET_PROJECTS_PROJECT_ID_APPROVED_CONTENT_FORMATS'
			}
		})
		return data;
	}
}
export const contentFormats = {
	key: (projectId: string) => `/projects/${projectId}/content-formats`,
	fetch: async (projectId: string) => {
		const {data} = await axiosRequest<ContentFormats[]>({
			method: 'GET',
			url: `/projects/${projectId}/content-formats`,
			parser: ContentFormatsSchema.array()
		})
		return data;
	}
}
