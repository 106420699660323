import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteRegionsErrorAction,
    DeleteRegionsStartAction,
    DeleteRegionsSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import RegionService from "services/regionService";


export function* deleteRegionsRecord({id}: ReturnType<typeof DeleteRegionsStartAction>) {
    try {
        yield RegionService.deleteRegionsRecord(id)
        yield put(DeleteRegionsSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.regions))

        yield put(NotificationOpen('success', 'Регион удален'))

    } catch (error: any) {
        yield put(DeleteRegionsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить регион'))
    }
}
