import React, {FC, useMemo} from 'react';
import {useSelector} from "react-redux";
import './index.less'
import {Button, Modal, Table} from "antd";
import useHandlerModal from "hooks/handlModal";
import {TaskTypes} from "entities/task";
import {TVote} from "models/common";
import {selectRole} from "store/auth/selectors";
import {ColumnsType} from "antd/es/table";
import NameCenterCell from "../../table-cells/name-center-cell";
import StatusCell from "./tables-cells/status-cell/status-cell";
import FilesCell from "./tables-cells/files-cell/files-cell";
import moment from "moment";

type Props = {
    status: boolean
    data: {
        title: string,
        votes: TVote[],
        lastTaskRecordWithVotesResponse: TVote[],
        recordStatus: TaskTypes.TasksRecordStatus,
        status?: TaskTypes.TasksStatus
        taskInfo?: {
            record_id?: string,
            task_id?: string,
        }
    }
}
const ModalVote: FC<Props> = ({status, data}) => {
    const role = useSelector(selectRole)
    const {isVisible, closeModal} = useHandlerModal(status);

    const columns: ColumnsType<TVote> = useMemo(() => {
        const arr: ColumnsType<TVote> = [
            {
                title: 'Роль',
                dataIndex: ['user','role','ruName'],
                key: 'role',
                width: 100,
                render: (role: string) => {
                    return <div className="name">{NameCenterCell(role)}</div>
                }
            },
            {
                title: 'ФИО',
                dataIndex: 'user',
                key: 'user',
                width: 200,
                render: (role: string, vote: TVote) => {
                    const name = `${vote.user.lastName} ${vote.user.firstName} ${vote.user.middleName || ''}`
                    return NameCenterCell(name)
                }
            },
        ]
        if (role !== 'ROLE_CONTRACTOR') {
           arr.push(...[
               {
                   title: 'Статус',
                   dataIndex: 'isApproved',
                   key: 'isApproved',
                   width: 80,
                   render: StatusCell
               },
               {
                   title: 'Дата и время статуса',
                   dataIndex: 'dateTime',
                   key: 'dateTime',
                   width: 160,
                   render: (date: string) => {
                       if (date) return NameCenterCell(moment(date).format('DD.MM.yy/HH:mm:ss'))
                       else return NameCenterCell('__')
                   }
               },
           ])
        }
        arr.push(...[
            {
                title: 'Комментарий',
                dataIndex: 'comment',
                key: 'comment',
                width: 180,
                render: (comment: string) => {
                    return NameCenterCell(comment || 'нет данных')
                }
            },
            {
                title: 'Файл',
                dataIndex: 'files',
                key: 'files',
                width: 180,
                render: FilesCell
            }
        ])
        return arr
    },[role])

    const tableData = useMemo(()=> {

        const votes = data.votes.length ? data.votes.slice().sort((voteOne, voteTwo) => {
            if (!voteOne.dateTime && !voteTwo.dateTime) return 0
            else if (!voteOne.dateTime) return -1
            else if (!voteTwo.dateTime) return 1
            else return moment(voteTwo.dateTime).isBefore(voteOne.dateTime) ? -1 : 1
        }) : []


        const lastTaskRecordWithVotesResponse = data.lastTaskRecordWithVotesResponse.length ? data.lastTaskRecordWithVotesResponse.slice().sort((voteOne, voteTwo) => {
            if (!voteOne.dateTime && !voteTwo.dateTime) return 0
            else if (!voteOne.dateTime) return -1
            else if (!voteTwo.dateTime) return 1
            else return moment(voteTwo.dateTime).isBefore(voteOne.dateTime) ? -1 : 1
        }) : []

        if ((data.recordStatus === 'APPROVED' || data.recordStatus === 'REJECTED') && !votes?.length) {
            return lastTaskRecordWithVotesResponse
        } else {
            return  votes
        }
        // eslint-disable-next-line
    },[data.status, data.votes, data.lastTaskRecordWithVotesResponse])

    return (
        <Modal
            className='modal-vote'
            title={`Комментарии по "${data.title}"`}
            visible={isVisible}
            footer={(
                <Button type={"primary"} onClick={closeModal}>Закрыть</Button>
            )}
            maskClosable={true}
            onCancel={closeModal}
        >
            <div>
                <Table
                    className="table"
                    sticky
                    columns={columns}
                    scroll={{ x: 800 }}
                    dataSource={tableData}
                    rowKey='dateTime'
                    pagination={false}
                />
            </div>
        </Modal>
    )
}
export default ModalVote;