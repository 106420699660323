import { ExtendedSearchTypes } from "features/extended-search";
import {ActionTypes} from "./types";


export const setAgreementOfProjectTaskRecords = <T extends Partial<ExtendedSearchTypes.Stores.AgreementOfProjectTaskRecordsState>>(
  typeContent: ExtendedSearchTypes.Stores.TTypeStore, value: T
) => ({
    type: ActionTypes.SET_VALUE, typeContent, value
})
export const setValueAgreementOfProjectTaskRecords = <T extends Partial<Omit<ExtendedSearchTypes.Stores.AgreementOfProjectTaskRecords, 'isToggled'>>>(
  typeContent: ExtendedSearchTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUES, typeContent, value, isToggled
})
export const clearAgreementOfProjectTaskRecords = (typeContent: ExtendedSearchTypes.Stores.TTypeStore) => ({
    type: ActionTypes.CLEAR_VALUE, typeContent
})