import {put, select} from 'redux-saga/effects'
import { NotificationOpen } from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	DeleteSprintErrorAction,
	DeleteSprintStartAction,
	DeleteSprintSuccessAction,
	GetSprintsStartAction,
} from 'store/sprints/actions'
import SprintService from 'services/sprintService'
import {GetTasksGantStartAction} from "store/tasks/actions/gant-kanban";
import {selectParams} from "store/selectors";

export function* deleteSprint({ stageId }: ReturnType<typeof DeleteSprintStartAction>) {
	try {
		const {id} = yield select(selectParams);

		yield SprintService.deleteSprint(stageId)
		yield put(DeleteSprintSuccessAction())
		yield put(GetSprintsStartAction())
		yield put(NotificationOpen('success', 'Этап удалён'))
		if (id) yield put(GetTasksGantStartAction(id))

	} catch (error: any) {
		yield put(DeleteSprintErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
