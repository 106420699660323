import { notification } from 'antd';
import React, { useEffect } from 'react';
import { Notification as NotificationEvent, NotificationModel } from '..';
import InnerHTMLFunction from 'utils/innerHTMLFunction';

const openNotification = ({ type, info }: NotificationModel.NotificationType) => {
	notification[type]({
		message: info.message,
		description: info?.description && (
			<div dangerouslySetInnerHTML={InnerHTMLFunction(info.description)} />
		),
	});
};

const Notification = () => {
	useEffect(() => {
		const unsubscribe = NotificationEvent.subscribe(openNotification);
		return () => unsubscribe();
	}, []);

	return null;
};
export default Notification;
