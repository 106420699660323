import {ActionTypes} from './types'
import {InferValueTypes} from "models/common";
import * as actions from "./actions";
import { ExtendedSearchTypes } from 'features/extended-search';
type ActionsTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TMainState = {
    isOpen: boolean;
    typeContent: ExtendedSearchTypes.Stores.TTypeStore
}
const initialState: TMainState = {
    isOpen: false,
    typeContent: 'projects',
}
export default function reducer(state: TMainState = initialState, action: ActionsTypes): TMainState {
    switch (action.type) {
        case ActionTypes.SET_VALUE:
            return {...state, ...action.value}
        default:
            return state
    }
}