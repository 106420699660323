import {NotificationActionTypes} from "./action-types";
import {TNotification} from "./reducer";


export const NotificationOpen = (
    types: TNotification,
    title: string | null = null,
    description: string | null = null,
    checkAuth: boolean = true
) => ({
    type: NotificationActionTypes.NOTIFICATION_OPEN,
    types,
    title,
    description,
    checkAuth
})
export const NotificationClose = () => ({
    type: NotificationActionTypes.NOTIFICATION_CLOSE
})
