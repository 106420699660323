import {
    AddTaskReportErrorAction,
    AddTaskReportStartAction,
    AddTaskReportSuccessAction,
    GetTaskReportStartAction
} from "store/tasks/actions/report";
import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {ReportType} from "models/task/types";
import {
    TasksReportCopyrightService,
    TasksReportEstimateService,
    TasksReportPostingService
} from "services/tasksReportService";

export function* addReportTask({taskId, typeTask}: ReturnType<typeof AddTaskReportStartAction>) {
    try {
        let recordId: string = '';
        switch (typeTask) {
            case ReportType.CONTENT_POSTING_REPORT:
                let {data: posting} = yield TasksReportPostingService.addTaskReport(taskId);
                recordId = posting
                break;
            case ReportType.COPYRIGHT_LIST:
                let {data: copyright} = yield TasksReportCopyrightService.addTaskReport(taskId);
                recordId = copyright
                break;
            case ReportType.PROJECT_ESTIMATE:
                let {data: estimate} = yield TasksReportEstimateService.addTaskReport(taskId);
                recordId = estimate
                break;
            default:
                console.log('Данный тип не поддерживается')
        }

        yield put(AddTaskReportSuccessAction(recordId))
        yield put(NotificationOpen('success', 'Запись добавлена'))

        yield put(GetTaskReportStartAction(taskId, typeTask))

    } catch (error: any) {
        yield put(AddTaskReportErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen(
            'error',
            'Ошибка',
            message ? message : 'Не удалось добавить запись'
        ))
    }
}