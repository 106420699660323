import {put, select} from "redux-saga/effects";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../store/notification/actions";
import {
    DuplicateScheduleTasksErrorAction,
    DuplicateScheduleTasksStartAction,
    DuplicateScheduleTasksSuccessAction,
} from "../../store/tasks/actions/tasks";
import TasksService from "../../services/tasksService";
import {GetTasksKanbanStartAction} from "../../store/tasks/actions/gant-kanban";
import {selectParams} from "../../store/selectors";


export function* duplicateScheduleTasks({task, closeModal}: ReturnType<typeof DuplicateScheduleTasksStartAction>) {
    try {
        const { id } = yield select(selectParams)

        yield TasksService.duplicateSchedulersTasks(task)
        yield put(DuplicateScheduleTasksSuccessAction())
        yield put(NotificationOpen('success', 'Успешно', ''))
        yield put(GetTasksKanbanStartAction(id))
        if(closeModal) yield closeModal()

    } catch (error: any) {
        yield put(DuplicateScheduleTasksErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось создать расписание автосоздания задачи'))

    }
}
