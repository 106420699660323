import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetHandbookStartAction,
    UpdateRegionsErrorAction,
    UpdateRegionsStartAction,
    UpdateRegionsSuccessAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import RegionService from "services/regionService";


export function* updateRegions({id, data,}: ReturnType<typeof UpdateRegionsStartAction>) {
    try {
        yield RegionService.updateRegionsRecord(data,id)
        yield put(UpdateRegionsSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.regions))

        yield put(NotificationOpen('success', 'Регион обновлен'))

    } catch (error: any) {
        yield put(UpdateRegionsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить регион'))
    }
}

