import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteRecordErrorAction,
    DeleteRecordStartAction,
    DeleteRecordSuccessAction,
    GetArchiveListStartAction
} from "../../store/archive/actions";
import ArchiveService from "../../services/archiveService";

export default function* deleteRecord( { id }: ReturnType<typeof DeleteRecordStartAction>) {
    try {
        yield ArchiveService.deleteRecord(id);
        yield put(DeleteRecordSuccessAction())
        yield put(GetArchiveListStartAction())
    } catch (error: any) {
        yield put(DeleteRecordErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить'))
    }
}