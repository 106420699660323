import {InferValueTypes} from '../../models/common'
import * as actions from './actions'
import {SidebarsActionTypes} from './action-types'
import {AuthActionTypes} from "../auth/action-types";
import {Logout} from "../auth/reducer";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TSidebarsState = {
	menu: boolean
	project: boolean
	task: boolean
	usersList: boolean
	notification: boolean
	functionalityInfo: boolean
	id: string | null
	secondId: string | null
}

export const initialState: TSidebarsState = {
	menu: false,
	project: false,
	task: false,
	usersList: false,
	notification: false,
	functionalityInfo: false,
	id: null,
	secondId: null,
}

export default function reducer(state: TSidebarsState = initialState, action: ActionTypes | Logout): TSidebarsState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return {...initialState}
		case SidebarsActionTypes.OPEN_SIDEBAR:
			return {
				...initialState,
				[action.typeSidebar]: true,
				id: action.id || null,
				secondId: action.secondId || null,
			}
		case SidebarsActionTypes.CLOSE_SIDEBAR:
			return {
				...state,
				[action.typeSidebar]: false,
				id: null
			}
		case SidebarsActionTypes.CLOSE_ALL_SIDEBAR:
			return initialState
		default:
			return state
	}
}
