import {InferValueTypes, TPaginationDefault} from 'models/common'
import * as actions from './actions'

import { Types } from './action-types'
import { TaskTypes } from 'entities/task';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

type FilterDataForRequest = {
	isMasterFile?: boolean
	taskRecordStatusId?: string
}
export type TContentSettingsFilter = {
	filterData?: {
		searchString?: string
		projectId?: string[]
		projectStatusIds?: string[]
		archival?: boolean | null
		taskStatusId?: string
		regionAboutIds?: string[]
		regionForIds?: string[]
		firstLevelIds?: string[]
		secondLevelTagIds?: string[]
		thirdLevelTagIds?: string[]
	} & FilterDataForRequest
} & TPaginationDefault
export type TContentSettingsState = {
	list: TaskTypes.ContentRecords[] | null
	filter: TContentSettingsFilter
	isLoading: boolean
	error: any
}
const initialState: TContentSettingsState = {
	list: null,
	filter: {
		page: 1,
		pageSize: 50,
		filterData: {archival: null}
	},
	isLoading: false,
	error: null,
}
export default function reducer(state: TContentSettingsState = initialState, action: ActionTypes): TContentSettingsState {
	switch (action.type) {
		case Types.SET_CONTENT_INFO:
			return {
				...state,
				...action.value
			}
		case Types.SET_CONTENT_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					...action.filter,
				},
			}
		default:
			return state
	}
}
