import { takeLatest } from 'redux-saga/effects'
import { PersonalSettingTypes } from '../../../store/options/action-types'
import getPersonalSetting from './getPersonalSetting'
import { updatePersonalSetting } from './updatePersonalSetting'
import sendConfirmEmail from './sendConfirmEmail'
import confirmEmail from './confirmEmail'
import {changePasswordPersonalSetting} from "./changePasswordPersonalSetting";
import confirmTelegram from "./confirmTelegram";

export default function* personalSettingSaga() {
	yield takeLatest(PersonalSettingTypes.GET_PERSONAL_SETTING_START, getPersonalSetting)
	yield takeLatest(PersonalSettingTypes.UPDATE_PERSONAL_SETTING_START, updatePersonalSetting)
	yield takeLatest(PersonalSettingTypes.SEND_CONFIRM_EMAIL_START, sendConfirmEmail)
	yield takeLatest(PersonalSettingTypes.CONFIRM_EMAIL_START, confirmEmail)
	yield takeLatest(PersonalSettingTypes.CHANGE_PASSWORD_PERSONAL_SETTING_START, changePasswordPersonalSetting)
	yield takeLatest(PersonalSettingTypes.CONFIRM_TELEGRAM_START, confirmTelegram)
}
