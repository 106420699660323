import React from 'react'
import styles from './styles.module.less'
import {Select, Spin} from 'antd'
import {useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {action, select} from '../../../model'
import classNames from "classnames";
import {UserApi, UserTypes} from 'entities/user'

const activeUsers = {
	roleNames: [
		"ROLE_MODERATOR",
		"ROLE_ANALYST",
		"ROLE_ADMIN",
		"ROLE_LAWYER",
		"ROLE_PROJECT_MANAGER",
		"ROLE_INTEGRATION_USER",
		"ROLE_ADMIN_IS",
		"ROLE_PRODUCER",
		"ROLE_CONTENT_EDITOR",
		"ROLE_COORDINATOR",
		"ROLE_FINANCIER"
	],
	statuses: ['ACTIVE'],
	isKeycloakSynced: true,
	isDeleted: false
}

const UsersSelect = () => {
	const put = useDispatch()
	const filter = useSelector(select.filter)

	const { data: options, isLoading } = useQuery({
		queryKey: [UserApi.UserController.keyOptionsRoleName, activeUsers],
		queryFn: ()=> UserApi.UserController.optionsRoleName(activeUsers as UserTypes.UsersRequest),
	})

	const handlerChange = (values: any) => {
		put(action.setValue({
				...filter,
				filterData: {
					...filter.filterData,
					userIds: values.length ? values : undefined,
				},
			}))
	}

	return (
		<div className={styles.select_wrapper}>
			<Spin spinning={isLoading}>
				<Select
					mode="multiple"
					getPopupContainer={(trigger) => trigger.parentNode}
					allowClear={true}
					style={{ width: '250px' }}
					maxTagCount={1}
					options={options}
					placeholder={'Пользователи'}
					className={classNames(styles.select,'tour-user-select')}
					onChange={handlerChange}
					filterOption={(input, option) =>
						(option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
					}
				/>
			</Spin>
		</div>
	)
}

export default UsersSelect
