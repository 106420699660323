import {put} from 'redux-saga/effects'

import UsersService from 'services/usersService'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
	ChangePasswordPersonalSettingErrorAction,
	ChangePasswordPersonalSettingStartAction,
	ChangePasswordPersonalSettingSuccessAction
} from "store/options/actions/actions-personal-setting";

export function* changePasswordPersonalSetting({ password }: ReturnType<typeof ChangePasswordPersonalSettingStartAction>) {
	try {
		const formData = new FormData()
		formData.append('oldPassword', password.oldPassword)
		formData.append('newPassword', password.newPassword)
		yield UsersService.changePasswordPersonalSetting(formData)
		yield put(ChangePasswordPersonalSettingSuccessAction())
		yield put(NotificationOpen('success', 'Пароль успешно обновлён'))
	} catch (error: any) {
		yield put(ChangePasswordPersonalSettingErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить пароль'))
	}
}
