import {TasksGantAndKanbanActionTypes} from "../action-types/gant-and-kanban";
import {TTasksKanban, TTasksKanbanFilter} from "models/task/task.kanban";
import { ProjectTypes } from "entities/project";

/**
 * Получение задач по проекту для диаграммы Ганта
 */
export const GetTasksGantStartAction = (projectId: string, payment?: boolean) => ({
    type: TasksGantAndKanbanActionTypes.GET_TASKS_GANT_START,
    projectId, payment
})
export const GetTasksGantSuccessAction = (tasksGant: ProjectTypes.GanttSprint[]) => ({
    type: TasksGantAndKanbanActionTypes.GET_TASKS_GANT_SUCCESS,
    tasksGant
})
export const GetTasksGantErrorAction = (error: any) => ({
    type: TasksGantAndKanbanActionTypes.GET_TASKS_GANT_ERROR,
    error
})
/**
 * Получение задач по проекту для Kanban доски
 */
export const GetTasksKanbanStartAction = (projectId: string) => ({
    type: TasksGantAndKanbanActionTypes.GET_TASKS_KANBAN_START,
    projectId
})
export const GetTasksKanbanSuccessAction = (tasksKanban: TTasksKanban) => ({
    type: TasksGantAndKanbanActionTypes.GET_TASKS_KANBAN_SUCCESS,
    tasksKanban,
})
export const GetTasksKanbanErrorAction = (error: any) => ({
    type: TasksGantAndKanbanActionTypes.GET_TASKS_KANBAN_ERROR,
    error
})
export const SetTasksKanbanFilter = (projectId: string, term: TTasksKanbanFilter) => ({
    type: TasksGantAndKanbanActionTypes.SET_TASKS_KANBAN_FILTER,
    projectId, term
})
export const ClearTasksKanbanFilter = () => ({
    type: TasksGantAndKanbanActionTypes.CLEAR_TASKS_KANBAN_FILTER,
})

export const SetKanbanCardIds = (cardIds: string[]) => ({
    type: TasksGantAndKanbanActionTypes.SET_CARD_IDS, cardIds
})
export const ClearKanbanCardIds = () => ({
    type: TasksGantAndKanbanActionTypes.CLEAR_CARD_IDS
})
export const ClearBulkActions = () => ({
    type: TasksGantAndKanbanActionTypes.CLEAR_BULK_ACTIONS
})