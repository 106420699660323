import React, {FC} from "react";
import {ErrorMessage, Field} from "formik";
import TextError from "./text-error";
import {Radio} from "antd";
import {FieldAttributes} from "formik/dist/Field";
import {IGroup, IOptions} from "models/feels";

type TRadioButtonsComponent = IGroup & {notSetField?: boolean}

const RadioButtonsComponent: FC<TRadioButtonsComponent> = ({
    name,
    options,
    disabled,
    label,
    handler,
    disabledOptionsIds,
    notSetField = false
}) => {

    const handleChange = (form: any) => (e: any) => {
        const value = e.target.value;
        const code = e.target['data-code'];
        if (handler) handler(value, code)
        if (!notSetField) form.setFieldValue(name, value)
    }
    return (
        <div className='form-group'>
            {label && (
                <label htmlFor={name}>{label}</label>
            )}
            <Field className="form-control" id={name} name={name}>
                {(el: FieldAttributes<any>) => {
                    const value = typeof el.field.value === 'string' ? el.field.value : el.field.value?.value
                    return (
                        <>
                            <Radio.Group
                                value={value}
                                onChange={handleChange(el.form)}
                                disabled={disabled}
                            >
                                {options && options.map((option: IOptions) => {
                                    return (
                                        <Radio
                                            data-code={option?.code}
                                            checked={value === (option.value || option.key)}
                                            disabled={disabledOptionsIds?.length ? disabledOptionsIds.includes(option.value) : !!option?.disabled}
                                            key={option.label} value={option.value}>{option.label}
                                        </Radio>
                                    )
                                })}
                            </Radio.Group>
                            {el.meta.touched && el.meta.error && (
                                <ErrorMessage name={name} component={TextError}/>
                            )}
                        </>
                    )
                }}
            </Field>
        </div>
    )
}
export default RadioButtonsComponent;
