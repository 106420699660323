import React, {FC, useState} from 'react';
import './index.less'
import {Spin} from "antd";
import {useHistory} from "react-router-dom";
import {CONTENT} from "routing/names";
import {useContentImage} from "pages/content/hooks";
import {TProjectContent} from "models/projects";


const CardMovie: FC<{ content: TProjectContent, contentModuleSectionId?: string }> = ({content}) => {

    const history = useHistory();
    const {pathImage, loading, imageId, setLoading} = useContentImage(content.image)
    const [imageError, setImageError] = useState(false);

    const handler = () => {
        history.push(`${CONTENT.CONTENT_INFO}?id=${content.id}`)
    }

    const handleImageLoad = () => {
        setLoading(false)
    }
    const handleImageError = () => {
        setImageError(true);
        setLoading(false)
    };

    return (
        <Spin spinning={loading}>
            <div className="card card-movie" onClick={handler}>
                <div className="card__bg"/>
                <div className="card__img">
                    {imageId && !imageError ? (
                        <img
                            src={pathImage + imageId}
                            alt=""
                            onLoad={handleImageLoad}
                            onError={handleImageError}
                        />
                    ) : content.defaultImage ? (
                        <img src={content.defaultImage} alt="" />
                    ) : (
                        <img src={window.location.origin + '/images/default-direction.png'}  alt="" />
                    )}
                </div>
                <div className="card__content">
                    <div className="card__header">
                        <div className="title">{content.name}</div>
                        <div className="status">{content.status?.name}</div>
                    </div>
                </div>
            </div>
        </Spin>
    )
};
export default CardMovie;