import {GetKeyErrorAction, GetKeyStartAction, GetKeySuccessAction} from "../../store/auth/actions";
import {AxiosResponse} from "axios";
import AuthService from "../../services/authService";
import {TGenerateKey} from "../../models/common";
import {put} from "redux-saga/effects";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";


export function* getAuthKey (_action : ReturnType<typeof GetKeyStartAction>){
    try {
        const { data: authKey }: AxiosResponse<TGenerateKey> = yield AuthService.getKey();
        yield put(GetKeySuccessAction(authKey))
    } catch (error: any) {
        yield put(GetKeyErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ))
    }
}
