import {createContext, Dispatch} from "react";

export type TStatus = 'finishLoad' | 'finish' | null
export type ContextState = {
    progress: number,
    status: TStatus
}
export type ContextActions = {
    type: 'SET_PROGRESS',
    payload: number
} | {
    type: 'RESET_PROGRESS',
} | {
    type: 'SET_STATUS',
    payload: TStatus
}
export type ContextDispatch = (action: ContextActions) => void

export const initialState: ContextState = {
    progress: 0,
    status: null
}
type Context = Partial<{ store: ContextState; dispatch: Dispatch<any> }>;
export const UploaderContext = createContext<Context>({store: initialState})