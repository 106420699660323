import React from 'react';
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectFilter} from "store/content/content/selectors";
import {SetSortingFilter} from "store/content/content/actions";
import {TFilterData} from "models/content";

const options = [
  {label: 'Контент' , value: 'true'},
  {label: 'Рабочий материал' , value: 'false'}
]

const ContentSelect = () => {
  const put = useDispatch()
  const filter = useSelector(selectFilter)

  const onChange = (value: string) => {
    let newFilter: TFilterData = {};
    if (filter.filterData) newFilter = { ...filter.filterData }
    newFilter = { ...newFilter, isContent: formatValue(value)}

    put(SetSortingFilter({ ...filter, filterData: newFilter }))
  }
  return (
    <div className="sorting__item">
      <Select
        value={formatValueForSelect(filter.filterData?.isContent)}
        style={{ 'width': '200px' }}
        placeholder="Все типы материала"
        onChange={onChange}
        maxTagCount={"responsive"}
        allowClear
        showSearch
        options={options}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
        }
      />
    </div>
  )
}

function formatValue(value?: string): boolean | undefined {
  if (value === undefined) {
    return undefined;
  }
  return value === 'true';
}

function formatValueForSelect(value?: boolean): string | undefined {
  if (value === undefined) {
    return undefined;
  }
  return value ? 'true' : 'false';
}

export default ContentSelect;