import {ProjectUserDto} from "./dto/project-form_StepFourth.dto";
import {z} from "zod";
export const userSchema = z.object({
    userId: z.string(),
    isTutor: z.boolean(),
    tutorVisible: z.boolean()
})
export type TUser = z.infer<typeof userSchema>;
export function parseUsers(users: ProjectUserDto[], usersTutor: ProjectUserDto[], tutorVisible: boolean, isTutor: boolean = true): TUser[] | null {
    const mapped: TUser[] = [];
    users?.forEach(({value}) => {
        mapped.push({
            userId: value,
            tutorVisible: false,
            isTutor: false
        })
    });
    usersTutor?.forEach(({value}) => {
        mapped.push({userId: value, isTutor: isTutor, tutorVisible: tutorVisible})
    })
    return mapped.length > 0 ? mapped : null
}