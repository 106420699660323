import $api from "../http";
import {AxiosResponse} from "axios";
import {
    TNotificationFilter,
    TNotificationResponse,
    TNotificationsBulkSendEmail,
    TNotificationsChannels,
    TNotificationsForBulkSend,
} from "../models/notifications";

/**
 * @category Service
 * @class NotificationService
 */
export default class NotificationService {
    /**
     * Получение списка уведомления для общих настроек
     */
    static getNotificationsList(filter: TNotificationFilter): Promise<AxiosResponse<TNotificationResponse[]>> {
        return  $api.post<TNotificationResponse[]>('/notifications/search',filter)
    }

    /**
     * Получение всех активных каналов для отправки уведомлений
     */

    static getActiveNotificationsAChannels() : Promise<AxiosResponse<TNotificationsChannels[]>> {
        return $api.get<TNotificationsChannels[]>(`/notifications/active-notification-channels`)
    }

    /**
     * Получение списка возможных уведомлений для массовой отправки
     */
    static getNotificationsForBulkSendKey = '/notifications/for-bulk-send'
    static getNotificationsForBulkSend(): Promise<AxiosResponse<TNotificationsForBulkSend[]>> {
        return  $api.get<TNotificationsForBulkSend[]>(`/notifications/for-bulk-send`)
    }
    /**
     * Массовая отправка уведомления массиву выбранных пользователей по каналу EMAIL
     */
    static getBulkSendEmailKey = '/notifications/bulk-send/email'
    static bulkSendEmail(data: TNotificationsBulkSendEmail): Promise<AxiosResponse<any>> {
        return  $api.post<any>(`/notifications/bulk-send/email`,data)
    }
}
