import {ProjectDocumentsActionTypes} from './action-types'
import {TProjectDocumentsFilter} from '../../models/projects/filter'
import {TDocumentsAll, TResponseProjectDocuments} from '../../models/projects/project-document'

/**
 * get PROJECT-DOCUMENTS_ALL
 */
export const GetProjectDocumentsAllStartAction = () => ({
	type: ProjectDocumentsActionTypes.GET_DOCUMENTS_ALL_START,
})
export const GetProjectDocumentsAllSuccessAction = (data: TDocumentsAll[] | null) => ({
	type: ProjectDocumentsActionTypes.GET_DOCUMENTS_ALL_SUCCESS,
	data,
})
export const GetProjectDocumentsAllErrorAction = (error: any) => ({
	type: ProjectDocumentsActionTypes.GET_DOCUMENTS_ALL_ERROR,
	error,
})

/**
 * get PROJECT-DOCUMENTS - projectId
 */
export const GetProjectDocumentsIdStartAction = () => ({
	type: ProjectDocumentsActionTypes.GET_DOCUMENTS_PROJECT_ID_START,
})
export const GetProjectDocumentsIdSuccessAction = (data: TDocumentsAll[] | null) => ({
	type: ProjectDocumentsActionTypes.GET_DOCUMENTS_PROJECT_ID_SUCCESS,
	data,
})
export const GetProjectDocumentsIdErrorAction = (error: any) => ({
	type: ProjectDocumentsActionTypes.GET_DOCUMENTS_PROJECT_ID_ERROR,
	error,
})

/**
 * get PROJECT-DOCUMENTS - with Filter
 */
export const GetProjectDocumentsWithFilterStartAction = () => ({
	type: ProjectDocumentsActionTypes.GET_DOCUMENTS_WITH_FILTER_START,
})
export const GetProjectDocumentsWithFilterSuccessAction = (data: TResponseProjectDocuments | null) => ({
	type: ProjectDocumentsActionTypes.GET_DOCUMENTS_WITH_FILTER_SUCCESS,
	data,
})
export const GetProjectDocumentsWithFilterErrorAction = (error: any) => ({
	type: ProjectDocumentsActionTypes.GET_DOCUMENTS_WITH_FILTER_ERROR,
	error,
})

/**
 * add Document
 */
export const AddProjectDocumentsStartAction = (data: any) => ({
	type: ProjectDocumentsActionTypes.ADD_DOCUMENTS_START,
	data,
})
export const AddProjectDocumentsSuccessAction = () => ({
	type: ProjectDocumentsActionTypes.ADD_DOCUMENTS_SUCCESS,
})
export const AddProjectDocumentsErrorAction = (error: any) => ({
	type: ProjectDocumentsActionTypes.ADD_DOCUMENTS_ERROR,
	error,
})

/**
 * update Document
 */
export const UpdateProjectDocumentsStartAction = (id: string, data: any) => ({
	type: ProjectDocumentsActionTypes.UPDATE_DOCUMENTS_START,
	id,
	data,
})
export const UpdateProjectDocumentsSuccessAction = () => ({
	type: ProjectDocumentsActionTypes.UPDATE_DOCUMENTS_SUCCESS,
})
export const UpdateProjectDocumentsErrorAction = (error: any) => ({
	type: ProjectDocumentsActionTypes.UPDATE_DOCUMENTS_ERROR,
	error,
})

/**
 * delete Document
 */
export const DeleteProjectDocumentsStartAction = (id: string) => ({
	type: ProjectDocumentsActionTypes.DELETE_DOCUMENTS_START,
	id,
})
export const DeleteProjectDocumentsSuccessAction = () => ({
	type: ProjectDocumentsActionTypes.DELETE_DOCUMENTS_SUCCESS,
})
export const DeleteProjectDocumentsErrorAction = (error: any) => ({
	type: ProjectDocumentsActionTypes.DELETE_DOCUMENTS_ERROR,
	error,
})

/**
 * Изменение фильтра
 */
export const SetProjectDocumentsFilter = (filter: TProjectDocumentsFilter) => ({
	type: ProjectDocumentsActionTypes.SET_PROJECT_DOCUMENTS_FILTER,
	filter,
})

/**
 * Установитьи очистить editing key
 */
export const SetEditingKey = (key: string) => ({
	type: ProjectDocumentsActionTypes.SET_EDITING_KEY,
	key
})
export const ClearEditingKey = () => ({
	type: ProjectDocumentsActionTypes.CLEAR_EDITING_KEY
})