import React, {FC} from 'react';
import styles from './styles.module.less'
import {FieldArray, useFormikContext} from "formik";
import {Button, Col, Row, Spin, Tooltip} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import useFeelsApp from "hooks/feelsApp";
import {TStatusesFeels} from "models/projects/projectFeels";
import {useDispatch} from "react-redux";
import {GetFeelsStartAction} from "store/app/actions";
import {ProjectFormDto} from '../../../lib';
import ReloadList from 'components/forms/components/reload-list/reload-list';
import {SelectComponent} from 'components/formic-control';
import {FamousPeopleLib} from 'entities/famous-people'
import {IOptions} from "models/feels";

type ContentFormatFieldsProps = {
    name: string
    disabled?: boolean
}
const FamousPeople: FC<ContentFormatFieldsProps> = ({name, disabled}) => {
    const {errors, touched } = useFormikContext<ProjectFormDto>()
    const isError = errors.stepFifth?.famousPeople;
    const {isLoading, ranks} = useFeelsApp([TStatusesFeels.ranks])

    function isTouched(id: number) {
        // @ts-ignore
        const fullName = touched[`stepFifth.famousPeople.value[${id - 1}].fullName`]
        // @ts-ignore
        const rank = touched[`stepFifth.famousPeople.value[${id - 1}].rank`]
        return fullName || rank
    }

    return (
        <FieldArray
            name={name}
            render={({form, remove, push}) => {
                const idx = form.values.stepFifth.famousPeople.value.length;
                const disableBtn = isError && idx !== 0;
                return (
                    <>
                        {form.values.stepFifth.famousPeople.value.map((_item: any, index: number) => (
                            <FamousPersonItem
                                key={index}
                                name={name}
                                index={index}
                                remove={remove}
                                disabled={disabled}
                                loading={isLoading}
                                ranks={ranks}
                            />
                        ))}
                        {!disabled && (
                            <>
                                {disableBtn && isTouched(idx) ? (
                                    <div className="invalid-feel">{errors.stepFifth?.famousPeople as string}</div>
                                ) : (
                                    <Button
                                        type="link"
                                        onClick={() => push({
                                            famousPerson: '',
                                            rank: '',
                                        })}>
                                        <PlusCircleOutlined/> Добавить данные
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                )
            }}
        />
    )
}
export default FamousPeople;

type FamousPersonItemProps = {
    name: string;
    index: number;
    remove: (index: number) => void;
    disabled?: boolean;
    ranks: IOptions[] | null
    loading: boolean
};

function FamousPersonItem({ name, index, remove, disabled, ranks, loading }: FamousPersonItemProps) {
    const put = useDispatch()
    const { values } = useFormikContext<ProjectFormDto>();
    const rankValue = values.stepFifth.famousPeople.value[index]?.rank;
    const {data: famousOptions} = FamousPeopleLib.useGetFamousPeopleOptions(rankValue?.value)

    const handlerUpdateRanks = () => {
        put(GetFeelsStartAction(TStatusesFeels.ranks))
    }

    return (
        <Row gutter={[5, 5]}>
            <Col lg={11} span={22}>
                <Spin spinning={loading || !ranks}>
                    <ReloadList handlerUpdateList={handlerUpdateRanks}/>
                    <SelectComponent
                        options={ranks}
                        name={`${name}[${index}].rank`}
                        placeholder="Выберите"
                        disabled={disabled}
                    />
                </Spin>
            </Col>
            <Col lg={11} span={22}>
                <Spin spinning={loading}>
                    <SelectComponent
                        options={famousOptions}
                        name={`${name}[${index}].famousPerson`}
                        placeholder="Известная персона"
                        disabled={disabled}
                    />
                </Spin>
            </Col>
            <Col span={2}>
                {!disabled && (
                    <Tooltip title="Удалить?">
                        <Button className={styles.delete} type="link" onClick={() => remove(index)}>
                            <DeleteOutlined />
                        </Button>
                    </Tooltip>
                )}
            </Col>
        </Row>
    )
}