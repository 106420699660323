import {Routes} from "models/routes";
import {OPTIONS} from "routing/names";
import {lazy} from "react";

const OptionsPage = lazy(() => import('pages/options/options'))


const optionsRoutes: Routes[] = [
    {path: OPTIONS.OPTIONS, Component: OptionsPage, name: 'Настройки', exact: false},
]

export default optionsRoutes;