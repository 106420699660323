import { createSelector } from 'reselect'
import { TApplicationState } from '../aplication-state'
import {IOptions} from "../../models/feels";

const sprintState = (state: TApplicationState) => state.sprints

export const selectSprintStatus = createSelector(sprintState, (state) => ({
	isLoading: state.isLoading,
	error: state.error,
}))

export const selectSprintsList = createSelector(sprintState, (state) => state.sprints)
export const selectSprintsTotalAmount = createSelector(
	selectSprintsList,
	(sprints) => {
		const val = sprints?.reduce((total, sprint) => total + sprint.amount, 0).toFixed(2)
		return Number(val);
	}
)
export const selectSprintsListOptions = createSelector(
	sprintState,
	(state) => state.sprints?.map((stage) => ({
		value: stage.id,
		label: stage.name,
		code: stage.status.name
	} as IOptions)))
export const selectSprint = createSelector(sprintState, (state) => state.sprint)

export const selectSprintsEditingKey = createSelector(sprintState, (state) => state.editingKey)
