import {createSelector} from 'reselect';
import {TApplicationState} from '../aplication-state';

const getUploaderState = (state: TApplicationState) => state.uploader;

export const selectUploaderStatus = createSelector(
    getUploaderState,
    (state) => state.isOpen
)
export const selectUploaderUnfinishedFiles = createSelector(
    getUploaderState,
    (state) => ({unfinishedFiles: state.unfinishedFiles, isLoading: state.isLoading, error: state.error})
)

export const selectUploaderEntityId = createSelector(
    getUploaderState,
    (state) => state.entityId
)
export const selectUploaderFileId = createSelector(
    getUploaderState,
    (state) => state.uploadFile?.id
)
export const selectUploaderInfo = createSelector(
    getUploaderState,
    (state) => ({loading: state.loading, entityId: state.entityId})
)
export const selectForUploader = createSelector(
    getUploaderState,
    (state) => ({
        entityId: state.entityId,
        fileId: state.uploadFile?.id,
        file: state.uploadFile,
        fileIsUpload: state.fileIsUpload,
        isOpen: state.isOpen
    })
)
export const selectUploaderData = createSelector(
    getUploaderState,
    (state) =>({file: state.uploadFile, data: state.uploadData, fileIsUpload: state.fileIsUpload})
)
export const selectUploaderInfoForInfoMenu = createSelector(
    selectUploaderInfo,
    selectUploaderData,
    selectUploaderUnfinishedFiles,
    ({loading}, {fileIsUpload}, {unfinishedFiles}) => ({
        loading: loading,
        fileIsUpload: fileIsUpload,
        unfinishedFiles: unfinishedFiles,
    })
)
export const selectUnfinishedFilesStatus = createSelector(
    getUploaderState,
    (state) => state.isLoading
)