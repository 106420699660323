import React from 'react';
import 'moment/locale/ru';
import locale from "antd/es/date-picker/locale/ru_RU";
import {useDispatch, useSelector} from "react-redux";
import {DatePicker} from "antd";
import moment from "moment/moment";
import {SetArchiveFilter} from "store/archive/actions";
import {selectArchiveFilter} from "store/archive/selectors";

const { RangePicker } = DatePicker

const DatePickerComponent = () => {
    const put = useDispatch()
    const filter = useSelector(selectArchiveFilter)
    const handleChangeDate = (e: any) => {
        const dateOne = e ? moment(e[0]['_d']).format('YYYY-MM-DD') + 'T00:00': null
        const dateTwo = e ? moment(e[1]['_d']).format('YYYY-MM-DD') + 'T23:59': null
        put(SetArchiveFilter({
            ...filter,
            filterData: {
                ...filter.filterData,
                startDateTime: dateOne,
                endDateTime: dateTwo
            }
        }))
    }
    return (
        <div className="form-group form-group--datePicker">
            <RangePicker
                format={'DD.MM.YYYY'}
                allowClear={true}
                onChange={handleChangeDate}
                className="datePicker"
                locale={locale}
            />
        </div>
    )
}

export default DatePickerComponent;