import {
	OptionsGeneralActionTypes,
	OptionsHandbookCategoryProject,
	OptionsHandbookGenreProject,
	OptionsHandbookLegalForm,
	OptionsHandbookRanks,
	OptionsHandbookRegions,
	OptionsHandbookSessionsType,
	OptionsHandbookTypes,
	UsersHandbookContentDeliveryTypes,
	UsersHandbookContentDirectionTypes,
	UsersHandbookDocumentsTypes,
	UsersHandbookDownloadTypes,
	UsersHandbookInternetTypes,
	UsersHandbookKPETypes,
	UsersHandbookOrganizationTypes,
	UsersHandbookThematicsTypes,
	UsersHandbookUsersActionsTypes,
} from '../action-types'
import {
	THandbookCodeNameHidden,
	THandbookFeels,
	THandbookFilterFeels,
	THandbookOrganization,
	THandbookOrganizationAction,
	TInternet,
	TLegalFormOrganization,
	TRanks,
	TRegions,
	TSessionsType,
	TStatusesHandbookFeels,
	TStatusesHandbookFeelsForFilter,
	TTHandbookContentOverAll,
	TThematicOrDocuments,
	TTypeOverallDataRequest,
} from 'models/handbook'
import {T_ID_NAME_CODE} from "models/common";
import {TUsersActionsState} from "../reducer/users-handbook-reducer";

/**
 * Получение списка тематических линий
 */

export const GetHandbookStartAction = (feel: TStatusesHandbookFeels | TStatusesHandbookFeelsForFilter) => ({
	type: UsersHandbookThematicsTypes.GET_HANDBOOK_START,
	feel,
})
export const GetHandbookSuccessAction = (data: THandbookFeels) => ({
	type: UsersHandbookThematicsTypes.GET_HANDBOOK_SUCCESS,
	data,
})
export const GetHandbookErrorAction = (error: any) => ({
	type: UsersHandbookThematicsTypes.GET_HANDBOOK_ERROR,
	error,
})

/**
 * Изменение фильтра справочника
 */
export const SetHandbookFilter = (filter: THandbookFilterFeels) => ({
	type: UsersHandbookThematicsTypes.SET_HANDBOOK_FILTER,
	filter,
})

/**
 * Добавление тематической линии
 */
export const AddThematicStartAction = (data: TThematicOrDocuments) => ({
	type: UsersHandbookThematicsTypes.ADD_THEMATIC_START,
	data,
})
export const AddThematicSuccessAction = () => ({
	type: UsersHandbookThematicsTypes.ADD_THEMATIC_SUCCESS,
})
export const AddThematicErrorAction = (error: any) => ({
	type: UsersHandbookThematicsTypes.ADD_THEMATIC_ERROR,
	error,
})

/**
 * Обновление записи тематической линии
 */
export const UpdateThematicStartAction = (id: string, data: TThematicOrDocuments) => ({
	type: UsersHandbookThematicsTypes.UPDATE_THEMATIC_START,
	id,
	data,
})
export const UpdateThematicSuccessAction = () => ({
	type: UsersHandbookThematicsTypes.UPDATE_THEMATIC_SUCCESS,
})
export const UpdateThematicErrorAction = (error: any) => ({
	type: UsersHandbookThematicsTypes.UPDATE_THEMATIC_ERROR,
	error,
})

/**
 * Удаление записи для тематической линии
 */
export const DeleteThematicStartAction = (id: string) => ({
	type: UsersHandbookThematicsTypes.DELETE_THEMATIC_START,
	id,
})
export const DeleteThematicSuccessAction = () => ({
	type: UsersHandbookThematicsTypes.DELETE_THEMATIC_SUCCESS,
})
export const DeleteThematicErrorAction = (error: any) => ({
	type: UsersHandbookThematicsTypes.DELETE_THEMATIC_ERROR,
	error,
})

/**
 * Изменение справочника (массовые действия)
 */
export const BulkThematicsActionsStartAction = (userArr: string[], action: string) => ({
	type: UsersHandbookThematicsTypes.BULK_THEMATICS_ACTIONS_START,
	userArr,
	action,
})
export const BulkThematicsActionsSuccessAction = () => ({
	type: UsersHandbookThematicsTypes.BULK_THEMATICS_ACTIONS_SUCCESS,
})
export const BulkThematicsActionsErrorAction = (error: any) => ({
	type: UsersHandbookThematicsTypes.BULK_THEMATICS_ACTIONS_ERROR,
	error,
})

/**
 * Добавление интернет ресурсов
 */
export const AddInternetStartAction = (data: Omit<TInternet, 'id'>) => ({
	type: UsersHandbookInternetTypes.ADD_INTERNET_START,
	data,
})
export const AddInternetSuccessAction = () => ({
	type: UsersHandbookInternetTypes.ADD_INTERNET_SUCCESS,
})
export const AddInternetErrorAction = (error: any) => ({
	type: UsersHandbookInternetTypes.ADD_INTERNET_ERROR,
	error,
})

/**
 * Обновление записи интернет ресурсов
 */
export const UpdateInternetStartAction = (id: string, data: Omit<TInternet, 'id'>) => ({
	type: UsersHandbookInternetTypes.UPDATE_INTERNET_START,
	id,
	data,
})
export const UpdateInternetSuccessAction = () => ({
	type: UsersHandbookInternetTypes.UPDATE_INTERNET_SUCCESS,
})
export const UpdateInternetErrorAction = (error: any) => ({
	type: UsersHandbookInternetTypes.UPDATE_INTERNET_ERROR,
	error,
})

/**
 * Удаление записи интернет ресурсов
 */
export const DeleteInternetStartAction = (id: string) => ({
	type: UsersHandbookInternetTypes.DELETE_INTERNET_START,
	id,
})
export const DeleteInternetSuccessAction = () => ({
	type: UsersHandbookInternetTypes.DELETE_INTERNET_SUCCESS,
})
export const DeleteInternetErrorAction = (error: any) => ({
	type: UsersHandbookInternetTypes.DELETE_INTERNET_ERROR,
	error,
})

/**
 * Изменение справочника интернет ресурсов (массовые действия)
 */
export const BulkInternetActionsStartAction = (userArr: string[], action: string) => ({
	type: UsersHandbookInternetTypes.BULK_INTERNET_ACTIONS_START,
	userArr,
	action,
})
export const BulkInternetActionsSuccessAction = () => ({
	type: UsersHandbookInternetTypes.BULK_INTERNET_ACTIONS_SUCCESS,
})
export const BulkInternetActionsErrorAction = (error: any) => ({
	type: UsersHandbookInternetTypes.BULK_INTERNET_ACTIONS_ERROR,
	error,
})

/**
 * Добавление форм сдачи
 */
export const AddContentDeliveryStartAction = (data: TTypeOverallDataRequest) => ({
	type: UsersHandbookContentDeliveryTypes.ADD_CONTENT_DELIVERY_START,
	data,
})
export const AddContentDeliverySuccessAction = () => ({
	type: UsersHandbookContentDeliveryTypes.ADD_CONTENT_DELIVERY_SUCCESS,
})
export const AddContentDeliveryErrorAction = (error: any) => ({
	type: UsersHandbookContentDeliveryTypes.ADD_CONTENT_DELIVERY_ERROR,
	error,
})

/**
 * Получение всех форм сдачи контента проектов
 */
export const GetAllContentDeliveryStartAction = () => ({
	type: UsersHandbookContentDeliveryTypes.GET_ALL_CONTENT_DELIVERY_START,
})
export const GetAllContentDeliverySuccessAction = (data: TTHandbookContentOverAll[]) => ({
	type: UsersHandbookContentDeliveryTypes.GET_ALL_CONTENT_DELIVERY_SUCCESS,
	data,
})
export const GetAllContentDeliveryErrorAction = (error: any) => ({
	type: UsersHandbookContentDeliveryTypes.GET_ALL_CONTENT_DELIVERY_ERROR,
	error,
})

/**
 * Обновление записи форм сдачи
 */
export const UpdateContentDeliveryStartAction = (id: string, data: TTypeOverallDataRequest) => ({
	type: UsersHandbookContentDeliveryTypes.UPDATE_CONTENT_DELIVERY_START,
	id,
	data,
})
export const UpdateContentDeliverySuccessAction = () => ({
	type: UsersHandbookContentDeliveryTypes.UPDATE_CONTENT_DELIVERY_SUCCESS,
})
export const UpdateContentDeliveryErrorAction = (error: any) => ({
	type: UsersHandbookContentDeliveryTypes.UPDATE_CONTENT_DELIVERY_ERROR,
	error,
})

/**
 * Удаление записи форм сдачи
 */
export const DeleteContentDeliveryStartAction = (id: string) => ({
	type: UsersHandbookContentDeliveryTypes.DELETE_CONTENT_DELIVERY_START,
	id,
})
export const DeleteContentDeliverySuccessAction = () => ({
	type: UsersHandbookContentDeliveryTypes.DELETE_CONTENT_DELIVERY_SUCCESS,
})
export const DeleteContentDeliveryErrorAction = (error: any) => ({
	type: UsersHandbookContentDeliveryTypes.DELETE_CONTENT_DELIVERY_ERROR,
	error,
})

/**
 * Изменение справочника форм сдачи (массовые действия)
 */
export const BulkContentDeliveryActionsStartAction = (userArr: string[], action: string) => ({
	type: UsersHandbookContentDeliveryTypes.BULK_CONTENT_DELIVERY_ACTIONS_START,
	userArr,
	action,
})
export const BulkContentDeliveryActionsSuccessAction = () => ({
	type: UsersHandbookContentDeliveryTypes.BULK_CONTENT_DELIVERY_ACTIONS_SUCCESS,
})
export const BulkContentDeliveryActionsErrorAction = (error: any) => ({
	type: UsersHandbookContentDeliveryTypes.BULK_CONTENT_DELIVERY_ACTIONS_ERROR,
	error,
})

/**
 * Добавление KPE
 */
export const AddKPEStartAction = (data: TTypeOverallDataRequest) => ({
	type: UsersHandbookKPETypes.ADD_KPE_START,
	data,
})
export const AddKPESuccessAction = () => ({
	type: UsersHandbookKPETypes.ADD_KPE_SUCCESS,
})
export const AddKPEErrorAction = (error: any) => ({
	type: UsersHandbookKPETypes.ADD_KPE_ERROR,
	error,
})

/**
 * Обновление записи KPE
 */
export const UpdateKPEStartAction = (id: string, data: TTypeOverallDataRequest) => ({
	type: UsersHandbookKPETypes.UPDATE_KPE_START,
	id,
	data,
})
export const UpdateKPESuccessAction = () => ({
	type: UsersHandbookKPETypes.UPDATE_KPE_SUCCESS,
})
export const UpdateKPEErrorAction = (error: any) => ({
	type: UsersHandbookKPETypes.UPDATE_KPE_ERROR,
	error,
})

/**
 * Удаление записи KPE
 */
export const DeleteKPEStartAction = (id: string) => ({
	type: UsersHandbookKPETypes.DELETE_KPE_START,
	id,
})
export const DeleteKPESuccessAction = () => ({
	type: UsersHandbookKPETypes.DELETE_KPE_SUCCESS,
})
export const DeleteKPEErrorAction = (error: any) => ({
	type: UsersHandbookKPETypes.DELETE_KPE_ERROR,
	error,
})

/**
 * Изменение справочника KPE (массовые действия)
 */
export const BulkKPEActionsStartAction = (userArr: string[], action: string) => ({
	type: UsersHandbookKPETypes.BULK_KPE_ACTIONS_START,
	userArr,
	action,
})
export const BulkKPEActionsSuccessAction = () => ({
	type: UsersHandbookKPETypes.BULK_KPE_ACTIONS_SUCCESS,
})
export const BulkKPEActionsErrorAction = (error: any) => ({
	type: UsersHandbookKPETypes.BULK_KPE_ACTIONS_ERROR,
	error,
})

/**
 * Добавление направлений контента
 */
export const AddContentDirectionStartAction = (data: TTypeOverallDataRequest) => ({
	type: UsersHandbookContentDirectionTypes.ADD_CONTENT_DIRECTION_START,
	data,
})
export const AddContentDirectionSuccessAction = () => ({
	type: UsersHandbookContentDirectionTypes.ADD_CONTENT_DIRECTION_SUCCESS,
})
export const AddContentDirectionErrorAction = (error: any) => ({
	type: UsersHandbookContentDirectionTypes.ADD_CONTENT_DIRECTION_ERROR,
	error,
})

/**
 * Обновление записи направлений контента
 */
export const UpdateContentDirectionStartAction = (id: string, data: TTypeOverallDataRequest) => ({
	type: UsersHandbookContentDirectionTypes.UPDATE_CONTENT_DIRECTION_START,
	id,
	data,
})
export const UpdateContentDirectionSuccessAction = () => ({
	type: UsersHandbookContentDirectionTypes.UPDATE_CONTENT_DIRECTION_SUCCESS,
})
export const UpdateContentDirectionErrorAction = (error: any) => ({
	type: UsersHandbookContentDirectionTypes.UPDATE_CONTENT_DIRECTION_ERROR,
	error,
})

/**
 * Удаление записи направлений контента
 */
export const DeleteContentDirectionStartAction = (id: string) => ({
	type: UsersHandbookContentDirectionTypes.DELETE_CONTENT_DIRECTION_START,
	id,
})
export const DeleteContentDirectionSuccessAction = () => ({
	type: UsersHandbookContentDirectionTypes.DELETE_CONTENT_DIRECTION_SUCCESS,
})
export const DeleteContentDirectionErrorAction = (error: any) => ({
	type: UsersHandbookContentDirectionTypes.DELETE_CONTENT_DIRECTION_ERROR,
	error,
})

/**
 * Изменение справочника направлений контента (массовые действия)
 */
export const BulkContentDirectionActionsStartAction = (userArr: string[], action: string) => ({
	type: UsersHandbookContentDirectionTypes.BULK_CONTENT_DIRECTION_ACTIONS_START,
	userArr,
	action,
})
export const BulkContentDirectionActionsSuccessAction = () => ({
	type: UsersHandbookContentDirectionTypes.BULK_CONTENT_DIRECTION_ACTIONS_SUCCESS,
})
export const BulkContentDirectionActionsErrorAction = (error: any) => ({
	type: UsersHandbookContentDirectionTypes.BULK_CONTENT_DIRECTION_ACTIONS_ERROR,
	error,
})

/**
 * Добавление списка организаций
 */
export const AddOrganizationStartAction = (data: THandbookOrganizationAction) => ({
	type: UsersHandbookOrganizationTypes.ADD_ORGANIZATION_START,
	data,
})
export const AddOrganizationSuccessAction = () => ({
	type: UsersHandbookOrganizationTypes.ADD_ORGANIZATION_SUCCESS,
})
export const AddOrganizationErrorAction = (error: any) => ({
	type: UsersHandbookOrganizationTypes.ADD_ORGANIZATION_ERROR,
	error,
})

/**
 * Обновление списка организаций
 */
export const UpdateOrganizationStartAction = (id: string, data: THandbookOrganizationAction) => ({
	type: UsersHandbookOrganizationTypes.UPDATE_ORGANIZATION_START,
	id,
	data,
})
export const UpdateOrganizationSuccessAction = () => ({
	type: UsersHandbookOrganizationTypes.UPDATE_ORGANIZATION_SUCCESS,
})
export const UpdateOrganizationErrorAction = (error: any) => ({
	type: UsersHandbookOrganizationTypes.UPDATE_ORGANIZATION_ERROR,
	error,
})

/**
 * Удаление списка организаций
 */
export const DeleteOrganizationStartAction = (id: string) => ({
	type: UsersHandbookOrganizationTypes.DELETE_ORGANIZATION_START,
	id,
})
export const DeleteOrganizationSuccessAction = () => ({
	type: UsersHandbookOrganizationTypes.DELETE_ORGANIZATION_SUCCESS,
})
export const DeleteOrganizationErrorAction = (error: any) => ({
	type: UsersHandbookOrganizationTypes.DELETE_ORGANIZATION_ERROR,
	error,
})

/**
 * Изменение списка организаций (массовые действия)
 */
export const BulkOrganizationActionsStartAction = (userArr: string[], action: string) => ({
	type: UsersHandbookOrganizationTypes.BULK_ORGANIZATION_ACTIONS_START,
	userArr,
	action,
})
export const BulkOrganizationActionsSuccessAction = () => ({
	type: UsersHandbookOrganizationTypes.BULK_ORGANIZATION_ACTIONS_SUCCESS,
})
export const BulkOrganizationActionsErrorAction = (error: any) => ({
	type: UsersHandbookOrganizationTypes.BULK_ORGANIZATION_ACTIONS_ERROR,
	error,
})

/**
 * Получение организации по ID
 */
export const GetOrganizationStartAction = (id: string) => ({
	type: UsersHandbookOrganizationTypes.GET_ORGANIZATION_START,
	id,
})
export const GetOrganizationSuccessAction = (organization: THandbookOrganization | null) => ({
	type: UsersHandbookOrganizationTypes.GET_ORGANIZATION_SUCCESS,
	organization,
})
export const GetOrganizationErrorAction = (error: any) => ({
	type: UsersHandbookOrganizationTypes.GET_ORGANIZATION_ERROR,
	error,
})

/**
 * Обновление записи действий пользователся
 */

export const UpdateHandbookActionsStartAction = (id: string, data: TTypeOverallDataRequest) => ({
	type: UsersHandbookUsersActionsTypes.UPDATE_USERS_ACTIONS_START,
	id,
	data,
})

export const UpdateHandbookActionsSuccessAction = () => ({
	type: UsersHandbookUsersActionsTypes.UPDATE_USERS_ACTIONS_SUCCESS,
})

export const UpdateHandbookActionsErrorAction = (error: any) => ({
	type: UsersHandbookUsersActionsTypes.UPDATE_USERS_ACTIONS_ERROR,
	error,
})

/**
 * Добавление типов документов
 */
export const AddDocumentsStartAction = (data: TTypeOverallDataRequest) => ({
	type: UsersHandbookDocumentsTypes.ADD_DOCUMENTS_START,
	data,
})
export const AddDocumentsSuccessAction = () => ({
	type: UsersHandbookDocumentsTypes.ADD_DOCUMENTS_SUCCESS,
})
export const AddDocumentsErrorAction = (error: any) => ({
	type: UsersHandbookDocumentsTypes.ADD_DOCUMENTS_ERROR,
	error,
})

/**
 * Обновление записи документов
 */
export const UpdateDocumentsStartAction = (id: string, data: TTypeOverallDataRequest) => ({
	type: UsersHandbookDocumentsTypes.UPDATE_DOCUMENTS_START,
	id,
	data,
})
export const UpdateDocumentsSuccessAction = () => ({
	type: UsersHandbookDocumentsTypes.UPDATE_DOCUMENTS_SUCCESS,
})
export const UpdateDocumentsErrorAction = (error: any) => ({
	type: UsersHandbookDocumentsTypes.UPDATE_DOCUMENTS_ERROR,
	error,
})

/**
 * Удаление записи документов
 */
export const DeleteDocumentsStartAction = (id: string) => ({
	type: UsersHandbookDocumentsTypes.DELETE_DOCUMENTS_START,
	id,
})
export const DeleteDocumentsSuccessAction = () => ({
	type: UsersHandbookDocumentsTypes.DELETE_DOCUMENTS_SUCCESS,
})
export const DeleteDocumentsErrorAction = (error: any) => ({
	type: UsersHandbookDocumentsTypes.DELETE_DOCUMENTS_ERROR,
	error,
})

/**
 * Изменение справочника документов (массовые действия)
 */
export const BulkDocumentsActionsStartAction = (userArr: string[], action: string) => ({
	type: UsersHandbookDocumentsTypes.BULK_DOCUMENTS_ACTIONS_START,
	userArr,
	action,
})
export const BulkDocumentsActionsSuccessAction = () => ({
	type: UsersHandbookDocumentsTypes.BULK_DOCUMENTS_ACTIONS_SUCCESS,
})
export const BulkDocumentsActionsErrorAction = (error: any) => ({
	type: UsersHandbookDocumentsTypes.BULK_DOCUMENTS_ACTIONS_ERROR,
	error,
})

/**
 * Выгрузка данных
 */

export const DownloadStartAction = (data: string[], typeName: string) => ({
	type: UsersHandbookDownloadTypes.DOWNLOAD_START,
	data,
	typeName,
})

export const DownloadSuccessAction = () => ({
	type: UsersHandbookDownloadTypes.DOWNLOAD_SUCCESS,
})

export const DownloadErrorAction = (error: any) => ({
	type: UsersHandbookDownloadTypes.DOWNLOAD_ERROR,
	error,
})

/**
 * Выгрузка шаблона
 */

export const DownloadTemplateStartAction = (typeName: string) => ({
	type: UsersHandbookDownloadTypes.DOWNLOAD_TEMPLATE_START,
	typeName,
})

export const DownloadTemplateSuccessAction = () => ({
	type: UsersHandbookDownloadTypes.DOWNLOAD_TEMPLATE_SUCCESS,
})

export const DownloadTemplateErrorAction = (error: any) => ({
	type: UsersHandbookDownloadTypes.DOWNLOAD_TEMPLATE_ERROR,
	error,
})

/**
 * Загрузка данных в excel
 */
export const UploadStartAction = (data: any, typeName: string) => ({
	type: UsersHandbookDownloadTypes.UPLOAD_START,
	data,
	typeName,
})
export const UploadSuccessAction = () => ({
	type: UsersHandbookDownloadTypes.UPLOAD_SUCCESS,
})
export const UploadErrorAction = (error: any) => ({
	type: UsersHandbookDownloadTypes.UPLOAD_ERROR,
	error,
})

/**
 * Установитьи очистить editing key
 */
export const SetEditingKey = (key: string) => ({
	type: OptionsHandbookTypes.SET_EDITING_KEY,
	key
})
export const ClearEditingKey = () => ({
	type: OptionsHandbookTypes.CLEAR_EDITING_KEY
})

/**
 * Получение всех разделов модуля контента
 */
export const GetContentModuleSectionStartAction = () => ({
	type: OptionsGeneralActionTypes.GET_CONTENT_MODULE_SECTION_START,
})
export const GetContentModuleSectionSuccessAction = (data: T_ID_NAME_CODE[]) => ({
	type: OptionsGeneralActionTypes.GET_CONTENT_MODULE_SECTION_SUCCESS,
	data
})
export const GetContentModuleSectionErrorAction = (error: any) => ({
	type: OptionsGeneralActionTypes.GET_CONTENT_MODULE_SECTION_ERROR,
	error,
})

export const ClearHandbookStore = (keyName: keyof TUsersActionsState) => ({
	type: OptionsGeneralActionTypes.CLEAR_HANDBOOK_STORE,
	keyName
})
export const ClearHandbookFilter = (keyName: keyof TUsersActionsState) => ({
	type: OptionsGeneralActionTypes.CLEAR_HANDBOOK_FILTER,
	keyName
})
export const ClearHandbookError = () => ({
	type: OptionsGeneralActionTypes.CLEAR_HANDBOOK_ERROR,
})

/**
 * Добавление regions
 */
export const AddRegionsStartAction = (data: TRegions) => ({
	type: OptionsHandbookRegions.ADD_REGIONS_START,
	data
})
export const AddRegionsSuccessAction = () => ({
	type: OptionsHandbookRegions.ADD_REGIONS_SUCCESS
})
export const AddRegionsErrorAction = (error: any) => ({
	type: OptionsHandbookRegions.ADD_REGIONS_ERROR,
	error
})

/**
 * Обновление regions
 */
export const UpdateRegionsStartAction = (id: string, data: TRegions) => ({
	type: OptionsHandbookRegions.UPDATE_REGIONS_START,
	id, data,
})
export const UpdateRegionsSuccessAction = () => ({
	type: OptionsHandbookRegions.UPDATE_REGIONS_SUCCESS
})
export const UpdateRegionsErrorAction = (error: any) => ({
	type: OptionsHandbookRegions.UPDATE_REGIONS_ERROR,
	error
})



/**
 * Удаление regions
 */
export const DeleteRegionsStartAction = (id: string) => ({
	type: OptionsHandbookRegions.DELETE_REGIONS_START,
	id
})
export const DeleteRegionsSuccessAction = () => ({
	type: OptionsHandbookRegions.DELETE_REGIONS_SUCCESS
})
export const DeleteRegionsErrorAction = (error: any) => ({
	type: OptionsHandbookRegions.DELETE_REGIONS_ERROR,
	error
})

/**
 * Изменение regions уровня (массовые действия)
 */
export const BulkRegionsActionsStartAction = (userArr: string[], action: string) => ({
	type: OptionsHandbookRegions.BULK_REGIONS_ACTIONS_START,
	userArr,
	action,
})
export const BulkRegionsActionsSuccessAction = () => ({
	type: OptionsHandbookRegions.BULK_REGIONS_ACTIONS_SUCCESS,
})
export const BulkRegionsActionsErrorAction = (error: any) => ({
	type: OptionsHandbookRegions.BULK_REGIONS_ACTIONS_ERROR,
	error,
})

export const SetHandbookBulkAction = (bulkAction: string | null) => ({
	type: OptionsGeneralActionTypes.SET_BULK_ACTION,
	bulkAction
})
export const SetHandbookRowsIds = (rows: string[]) => ({
	type: OptionsGeneralActionTypes.SET_ROWS_IDS,
	rows
})

/**
 * Добавление ranks
 */
export const AddRanksStartAction = (data: TRanks) => ({
	type: OptionsHandbookRanks.ADD_RANKS_START,
	data
})
export const AddRanksSuccessAction = () => ({
	type: OptionsHandbookRanks.ADD_RANKS_SUCCESS
})
export const AddRanksErrorAction = (error: any) => ({
	type: OptionsHandbookRanks.ADD_RANKS_ERROR,
	error
})

/**
 * Обновление ranks
 */
export const UpdateRanksStartAction = (id: string, data: TRanks) => ({
	type: OptionsHandbookRanks.UPDATE_RANKS_START,
	id, data,
})
export const UpdateRanksSuccessAction = () => ({
	type: OptionsHandbookRanks.UPDATE_RANKS_SUCCESS
})
export const UpdateRanksErrorAction = (error: any) => ({
	type: OptionsHandbookRanks.UPDATE_RANKS_ERROR,
	error
})

/**
 * Удаление ranks
 */
export const DeleteRanksStartAction = (id: string) => ({
	type: OptionsHandbookRanks.DELETE_RANKS_START,
	id
})
export const DeleteRanksSuccessAction = () => ({
	type: OptionsHandbookRanks.DELETE_RANKS_SUCCESS
})
export const DeleteRanksErrorAction = (error: any) => ({
	type: OptionsHandbookRanks.DELETE_RANKS_ERROR,
	error
})

/**
 * Изменение ranks уровня (массовые действия)
 */
export const BulkRanksActionsStartAction = (userArr: string[], action: string) => ({
	type: OptionsHandbookRanks.BULK_RANKS_ACTIONS_START,
	userArr,
	action,
})
export const BulkRanksActionsSuccessAction = () => ({
	type: OptionsHandbookRanks.BULK_RANKS_ACTIONS_SUCCESS,
})
export const BulkRanksActionsErrorAction = (error: any) => ({
	type: OptionsHandbookRanks.BULK_RANKS_ACTIONS_ERROR,
	error,
})



/**
 * Создание новой правовой формы организации
 */
export const AddLegalFormOrgStartAction = (data: Omit<TLegalFormOrganization, 'id'>) => ({
	type: OptionsHandbookLegalForm.ADD_LEGAL_FORM_START,
	data
})
export const AddLegalFormOrgSuccessAction = () => ({
	type: OptionsHandbookLegalForm.ADD_LEGAL_FORM_SUCCESS
})
export const AddLegalFormOrgErrorAction = (error: any) => ({
	type: OptionsHandbookLegalForm.ADD_LEGAL_FORM_ERROR,
	error
})

/**
 * Обновление правовой формы организации
 */
export const UpdateLegalFormOrgStartAction = (id: string, data: Omit<TLegalFormOrganization, 'id'>) => ({
	type: OptionsHandbookLegalForm.UPDATE_LEGAL_FORM_START,
	id, data,
})
export const UpdateLegalFormOrgSuccessAction = () => ({
	type: OptionsHandbookLegalForm.UPDATE_LEGAL_FORM_SUCCESS
})
export const UpdateLegalFormOrgErrorAction = (error: any) => ({
	type: OptionsHandbookLegalForm.UPDATE_LEGAL_FORM_ERROR,
	error
})

/**
 * Удаление правовой формы организации
 */
export const DeleteLegalFormOrgStartAction = (id: string) => ({
	type: OptionsHandbookLegalForm.DELETE_LEGAL_FORM_START,
	id
})
export const DeleteLegalFormOrgSuccessAction = () => ({
	type: OptionsHandbookLegalForm.DELETE_LEGAL_FORM_SUCCESS
})
export const DeleteLegalFormOrgErrorAction = (error: any) => ({
	type: OptionsHandbookLegalForm.DELETE_LEGAL_FORM_ERROR,
	error
})

/**
 * Массовые действия над правовыми формами организаций
 */
export const BulkLegalFormOrgActionsStartAction = (userArr: string[], action: string) => ({
	type: OptionsHandbookLegalForm.BULK_LEGAL_FORM_ACTIONS_START,
	userArr,
	action,
})
export const BulkLegalFormOrgActionsSuccessAction = () => ({
	type: OptionsHandbookLegalForm.BULK_LEGAL_FORM_ACTIONS_SUCCESS,
})
export const BulkLegalFormOrgActionsErrorAction = (error: any) => ({
	type: OptionsHandbookLegalForm.BULK_LEGAL_FORM_ACTIONS_ERROR,
	error,
})

/**
 * Добавление sessions
 */
export const AddSessionsTypeStartAction = (data: TSessionsType) => ({
	type: OptionsHandbookSessionsType.ADD_SESSIONS_TYPE_START,
	data
})
export const AddSessionsTypeSuccessAction = () => ({
	type: OptionsHandbookSessionsType.ADD_SESSIONS_TYPE_SUCCESS
})
export const AddSessionsTypeErrorAction = (error: any) => ({
	type: OptionsHandbookSessionsType.ADD_SESSIONS_TYPE_ERROR,
	error
})

/**
 * Обновление sessions
 */
export const UpdateSessionsTypeStartAction = (id: string, data: TRanks) => ({
	type: OptionsHandbookSessionsType.UPDATE_SESSIONS_TYPE_START,
	id, data,
})
export const UpdateSessionsTypeSuccessAction = () => ({
	type: OptionsHandbookSessionsType.UPDATE_SESSIONS_TYPE_SUCCESS
})
export const UpdateSessionsTypeErrorAction = (error: any) => ({
	type: OptionsHandbookSessionsType.UPDATE_SESSIONS_TYPE_ERROR,
	error
})

/**
 * Удаление sessions
 */
export const DeleteSessionsTypeStartAction = (id: string) => ({
	type: OptionsHandbookSessionsType.DELETE_SESSIONS_TYPE_START,
	id
})
export const DeleteSessionsTypeSuccessAction = () => ({
	type: OptionsHandbookSessionsType.DELETE_SESSIONS_TYPE_SUCCESS
})
export const DeleteSessionsTypeErrorAction = (error: any) => ({
	type: OptionsHandbookSessionsType.DELETE_SESSIONS_TYPE_ERROR,
	error
})

/**
 * Изменение sessions (массовые действия)
 */
export const BulkSessionsTypeActionsStartAction = (userArr: string[], action: string) => ({
	type: OptionsHandbookSessionsType.BULK_SESSIONS_TYPE_ACTIONS_START,
	userArr,
	action,
})
export const BulkSessionsTypeActionsSuccessAction = () => ({
	type: OptionsHandbookSessionsType.BULK_SESSIONS_TYPE_ACTIONS_SUCCESS,
})
export const BulkSessionsTypeActionsErrorAction = (error: any) => ({
	type: OptionsHandbookSessionsType.BULK_SESSIONS_TYPE_ACTIONS_ERROR,
	error,
})

/**
 * Добавление тега категорий проектов
 */
export const AddCategoryProjectStartAction = (data: THandbookCodeNameHidden) => ({
	type: OptionsHandbookCategoryProject.ADD_CATEGORY_PROJECT_START,
	data
})
export const AddCategoryProjectSuccessAction = () => ({
	type: OptionsHandbookCategoryProject.ADD_CATEGORY_PROJECT_SUCCESS
})
export const AddCategoryProjectErrorAction = (error: any) => ({
	type: OptionsHandbookCategoryProject.ADD_CATEGORY_PROJECT_ERROR,
	error
})

/**
 * Обновление тега категорий проектов
 */
export const UpdateCategoryProjectStartAction = (id: string, data: THandbookCodeNameHidden) => ({
	type: OptionsHandbookCategoryProject.UPDATE_CATEGORY_PROJECT_START,
	id, data,
})
export const UpdateCategoryProjectSuccessAction = () => ({
	type: OptionsHandbookCategoryProject.UPDATE_CATEGORY_PROJECT_SUCCESS
})
export const UpdateCategoryProjectErrorAction = (error: any) => ({
	type: OptionsHandbookCategoryProject.UPDATE_CATEGORY_PROJECT_ERROR,
	error
})

/**
 * Удаление тега категорий проектов
 */
export const DeleteCategoryProjectStartAction = (id: string) => ({
	type: OptionsHandbookCategoryProject.DELETE_CATEGORY_PROJECT_START,
	id
})
export const DeleteCategoryProjectSuccessAction = () => ({
	type: OptionsHandbookCategoryProject.DELETE_CATEGORY_PROJECT_SUCCESS
})
export const DeleteCategoryProjectErrorAction = (error: any) => ({
	type: OptionsHandbookCategoryProject.DELETE_CATEGORY_PROJECT_ERROR,
	error
})

/**
 * Изменение тега категорий проектов (массовые действия)
 */
export const BulkCategoryProjectActionsStartAction = (userArr: string[], action: string) => ({
	type: OptionsHandbookCategoryProject.BULK_CATEGORY_PROJECT_ACTIONS_START,
	userArr,
	action,
})
export const BulkCategoryProjectActionsSuccessAction = () => ({
	type: OptionsHandbookCategoryProject.BULK_CATEGORY_PROJECT_ACTIONS_SUCCESS,
})
export const BulkCategoryProjectActionsErrorAction = (error: any) => ({
	type: OptionsHandbookCategoryProject.BULK_CATEGORY_PROJECT_ACTIONS_ERROR,
	error,
})


/**
 * Добавление тега жанров проектов
 */
export const AddGenreProjectStartAction = (data: THandbookCodeNameHidden) => ({
	type: OptionsHandbookGenreProject.ADD_GENRE_PROJECT_START,
	data
})
export const AddGenreProjectSuccessAction = () => ({
	type: OptionsHandbookGenreProject.ADD_GENRE_PROJECT_SUCCESS
})
export const AddGenreProjectErrorAction = (error: any) => ({
	type: OptionsHandbookGenreProject.ADD_GENRE_PROJECT_ERROR,
	error
})

/**
 * Обновление тега жанров проектов
 */
export const UpdateGenreProjectStartAction = (id: string, data: THandbookCodeNameHidden) => ({
	type: OptionsHandbookGenreProject.UPDATE_GENRE_PROJECT_START,
	id, data,
})
export const UpdateGenreProjectSuccessAction = () => ({
	type: OptionsHandbookGenreProject.UPDATE_GENRE_PROJECT_SUCCESS
})
export const UpdateGenreProjectErrorAction = (error: any) => ({
	type: OptionsHandbookGenreProject.UPDATE_GENRE_PROJECT_ERROR,
	error
})

/**
 * Удаление тега жанров проектов
 */
export const DeleteGenreProjectStartAction = (id: string) => ({
	type: OptionsHandbookGenreProject.DELETE_GENRE_PROJECT_START,
	id
})
export const DeleteGenreProjectSuccessAction = () => ({
	type: OptionsHandbookGenreProject.DELETE_GENRE_PROJECT_SUCCESS
})
export const DeleteGenreProjectErrorAction = (error: any) => ({
	type: OptionsHandbookGenreProject.DELETE_GENRE_PROJECT_ERROR,
	error
})

/**
 * Изменение тега жанров проектов (массовые действия)
 */
export const BulkGenreProjectActionsStartAction = (userArr: string[], action: string) => ({
	type: OptionsHandbookGenreProject.BULK_GENRE_PROJECT_ACTIONS_START,
	userArr,
	action,
})
export const BulkGenreProjectActionsSuccessAction = () => ({
	type: OptionsHandbookGenreProject.BULK_GENRE_PROJECT_ACTIONS_SUCCESS,
})
export const BulkGenreProjectActionsErrorAction = (error: any) => ({
	type: OptionsHandbookGenreProject.BULK_GENRE_PROJECT_ACTIONS_ERROR,
	error,
})




