import React from 'react';
import {Teg0} from "./teg0";
import {Teg1} from "./teg1";
import {Teg2} from './teg2';
import {Teg3} from './teg3';
import WhetherTheUser from "components/whetherTheUser";

const Tags = () => (
    <>
        <WhetherTheUser role={['ROLE_CONTRACTOR', 'ROLE_CLIENT', 'ROLE_AGENT', 'ROLE_VIEWER']} isCan={false}>
            <Teg0/>
        </WhetherTheUser>
        <Teg1/>
        <Teg2/>
        <Teg3/>
    </>
);

export default Tags;