import React, {FC} from 'react';
import {Checkbox} from 'antd';
import styles from "./styles.module.less";

type TProps = {
    value: boolean | null
    onChange: any
}

const CheckboxComponent: FC<TProps> = ({ value, onChange }) => {

    const handleCheckboxChange = () => {
        if (value === true) {
            onChange(false);
        } else if (value === false) {
            onChange(null);
        } else {
            onChange(true);
        }
    };
    return (
        <Checkbox
            className={styles.checkbox}
            indeterminate={value === null}
            checked={value === true}
            onChange={handleCheckboxChange}
        >
            {getText(value)}
        </Checkbox>
    );
};

function getText(value: boolean | null) {
    switch (value) {
        case true:
            return 'Учитывается'
        case false:
            return 'Не учитывается'
        case null:
            return 'Не определено'
        default:
            return ''
    }
}


export default CheckboxComponent;
