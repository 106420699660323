import {TApplicationState} from "../../aplication-state";
import {createSelector} from "reselect";

const reportTask = (state: TApplicationState) => state.tasks.tasksRecords;

export const selectTasksRecordsFilter = createSelector(
    reportTask,
    (state) => state.filter
)

export const selectTasksRecordsInfo = createSelector(
    reportTask,
    (state) => ({
      typeTask: state.typeTask,
      typeReport: state.typeReport
    })
)
export const selectTasksRecords = createSelector(
    reportTask,
    (state) => ({
        isLoading: state.isLoading,
        tasks: state.tasks,
        isError: state.error
    })
)
export const selectTasksRecordsBulkAction =  createSelector(
    reportTask,
    (state) => state.bulkAction
)
export const selectTasksRecordsRowIds =  createSelector(
    reportTask,
    (state) => state.rowsIds
)