import {put} from "redux-saga/effects";
import {GetTasksKanbanStartAction} from "store/tasks/actions/gant-kanban";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {
    GetTaskStartAction,
    UpdateStatusTaskErrorAction,
    UpdateStatusTaskStartAction,
    UpdateStatusTaskSuccessAction
} from "store/tasks/actions/tasks";
import TasksService from "services/tasksService";

export function* updateStatusTask({taskId, statusId, projectId, callback}: ReturnType<typeof UpdateStatusTaskStartAction>) {
    try {
        yield TasksService.updateStatusTasks(taskId, statusId)
        yield put(UpdateStatusTaskSuccessAction())

        yield put(NotificationOpen('success', 'Статус обновлен', ''))
        yield put(GetTaskStartAction(taskId))
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить статус задачи'))
        yield put(UpdateStatusTaskErrorAction(message ? message : error))
        if (projectId) yield put(GetTasksKanbanStartAction(projectId))
        if (message === 'Задача не содержит записи или в ней содержатся черновые или готовые к проверке записи' && callback) callback()
    }
}