import axios from 'axios'
import env from "shared/config/env";

const $api = axios.create({
    withCredentials: true,
    baseURL: env.API_ENDPOINT,
})
$api.defaults.headers.get['Accept'] = 'application/json';
$api.defaults.headers.post['Accept'] = 'application/json';


axios.defaults.baseURL = env.API_ENDPOINT;
axios.defaults.headers.get['Accept'] = 'application/json';  // default header for all get request
axios.defaults.headers.post['Accept'] = 'application/json'; // default header for all POST request
axios.defaults.withCredentials = true;

export default $api;

