import React, {FC} from "react";
import {Tooltip} from "antd";
import './reload-list.less'
import {ReloadOutlined} from "@ant-design/icons";

type TReloadListProps = {
    handlerUpdateList: () => void
    className?: string
}

const ReloadList:FC<TReloadListProps> = ({handlerUpdateList, className}) => {
    return (
            <div className={`reload-list ${className || ''}`} onClick={handlerUpdateList}>
                <Tooltip title="Обновить список">
                    <ReloadOutlined />
                </Tooltip>
            </div>
    )
}

export default ReloadList