import { ExtendedSearchTypes } from "features/extended-search";
import {ActionTypes} from "./types";


export const setIndicatorsContent = <T extends Partial<ExtendedSearchTypes.Stores.TIndicatorsContentState>>(
    typeContent: ExtendedSearchTypes.Stores.TTypeStore, value: T
) => ({
    type: ActionTypes.SET_VALUE, typeContent, value
})
export const setValuesIndicatorsContent = <T extends Partial<Omit<ExtendedSearchTypes.Stores.IndicatorsContent, 'isToggled'>>>(
    typeContent: ExtendedSearchTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUES, typeContent, value, isToggled
})
export const clearIndicatorsContent = (typeContent: ExtendedSearchTypes.Stores.TTypeStore) => ({
    type: ActionTypes.CLEAR_VALUE, typeContent
})