import React from 'react'
import {SelectComponent} from 'components/formic-control'
import {useQueryClient} from 'react-query'
import {ProjectApi} from 'entities/project'
import {useSelector} from 'react-redux'
import {selectParams} from 'store/selectors'
import {useFormikContext} from 'formik'
import {TInitValues} from '../modal-content-records'
import styles from '../styles.module.less'
import {ContentFormatTypeLib} from 'entities/content-format-type'

const ContentFormatType = () => {
	const { id } = useSelector(selectParams)
	const { setFieldValue, values } = useFormikContext<TInitValues>()
	const queryClient = useQueryClient()

	const {data: contentFormatTypeOptions} = ContentFormatTypeLib.useGetContentFormatTypeByProjectOptions(id as string)

	const handler = () => {
		setFieldValue('submissionForm', [])
		queryClient.invalidateQueries([ProjectApi.ProjectContentSubmissionFormController.getSubmissionFormsById.key(id)])
	}

	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Единица контента <sup>*</sup>
			</span>
			<div className={styles.field}>
				<SelectComponent
					disabled={values.disabled}
					handler={handler}
					options={contentFormatTypeOptions}
					name={'contentFormatType'}
					placeholder={'Выберите единицу контента'}
				/>
			</div>
		</div>
	)
}

export default ContentFormatType
