import { UserTypes } from "entities/user";
import {call} from "redux-saga/effects";
import {extendedSearchWorker} from "./extended-search";
import {searchWorker} from "./search";
import {Types} from "features/user-filters/types";

const rolesForSearchCase: UserTypes.RoleName[] = ['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_CLIENT'];
type Props = {
    role: UserTypes.RoleName,
    filterData: string,
    needToMakeRequest: boolean,
    filterId?: string
    filterType: Types["code"]
}
export function* projectWorker({needToMakeRequest, filterData, filterId, role, filterType}: Props) {
    if (rolesForSearchCase.includes(role)) {
        yield call(
            searchWorker,
            {filterData, needToMakeRequest, filterId, filterType})
    } else {
        yield call(
            extendedSearchWorker,
            {filterData, needToMakeRequest, filterId, filterType})
    }
}