import {AxiosResponse} from 'axios'
import {put} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {
	GetTagsSecondLevelContentErrorAction,
	GetTagsSecondLevelContentStartAction,
	GetTagsSecondLevelContentSuccessAction
} from "../../store/tags/actions";
import {TTagSecondLevel} from "../../models/tags";
import {TagsService} from "../../services/tagsService";

export default function* getSecondLevelContent({firstLevelTagIds}: ReturnType<typeof GetTagsSecondLevelContentStartAction>) {
	try {
		const {data}: AxiosResponse<TTagSecondLevel[]> = yield TagsService.getTagsSecondLevelContent(firstLevelTagIds);
		yield put(GetTagsSecondLevelContentSuccessAction(data))
	} catch (error: any) {
		yield put(GetTagsSecondLevelContentErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
