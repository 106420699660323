import React from 'react';
import { DuplicateSchedule } from '../../types';
import moment from 'moment/moment';
import styles from './scheduleCell.module.less'
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { ModalEvent } from 'shared/ui/modal';

const ScheduleCell = (_: any, record: DuplicateSchedule) => {
	const scheduleDatesLength = record.scheduleDates.length;
	const openList = () => {
		ModalEvent.open({
			title: record.task.name,
			component: <ListSchedule scheduleDates={record.scheduleDates}/>,
			width: 389
		})
	}
	if (!!scheduleDatesLength) {
		return (
			<Tooltip title={'Посмотреть весь перечень дат'}>
				<div className={classNames(styles.cell, styles.list)} onClick={openList}>
					{moment(record.scheduleDates[0]).format('DD.MM.YYYY')} -
					{moment(record.scheduleDates[scheduleDatesLength - 1]).format('DD.MM.YYYY')}
				</div>
			</Tooltip>
		)
	}
	const first = record.firstTaskDate ? `${moment(record.firstTaskDate).format('DD.MM.YYYY')}` : '_';
	const second = record.lastTaskDate ? `${moment(record.lastTaskDate).format('DD.MM.YYYY')}` : '_';
	return (
		<div className={styles.cell}>
			{first} - {second}
		</div>
	)
};
function ListSchedule({scheduleDates}: {scheduleDates: DuplicateSchedule['scheduleDates']}) {
	return (
		<ul className={styles.ul}>
			{scheduleDates.map((el) => (
				<li className={styles.li} key={el}>{moment(el).format('DD.MM.YYYY')}</li>
			))}
		</ul>
	)
}
export default ScheduleCell;