import {TTaskForm, TTaskResponse, TTaskRole} from "models/task";
import {TDefault} from "./task-form.dto";
import {TRoleWithIsMain} from "models/common";
import {TPerson} from "models/user";
import {TInfoFormTask} from "../../task-form";
import taskValidateRules from "utils/validation/task-validate-rules";

export class StepThirdDto  {
    producers: TDefault<TTaskRole |  null>
    coordinators: TDefault<TTaskRole |  null>
    analysts: TDefault<TTaskRole |  null>
    financiers: TDefault<TTaskRole |  null>
    contentEditors: TDefault<TTaskRole |  null>
    projectManagers: TDefault<TTaskRole |  null>
    constructor(task?:TTaskResponse | null) {
        this.producers = task  && task.producers ? {value: task.producers.reduce((acc,person) => {
                return  {...acc, [person.id]: new RoleTasksDto(person)}
            },{})} : {value: null}
        this.coordinators = task && task.coordinators ? {value: task.coordinators.reduce((acc,person) => {
                return  {...acc, [person.id]: new RoleTasksDto(person)}
            }, {})} : {value: null}
        this.analysts = task && task.analysts ? {value: task.analysts.reduce((acc, person) => {
                return {...acc, [person.id]: new RoleTasksDto(person)}
            }, {})} : {value: null}
        this.financiers = task && task.financiers ? {value: task.financiers.reduce((acc, person) => {
                return {...acc, [person.id]: new RoleTasksDto(person)}
            }, {})} : {value: null}
        this.contentEditors = task && task.contentEditors ? {value: task.contentEditors.reduce((acc, person) => {
                return {...acc, [person.id]: new RoleTasksDto(person)}
            }, {})} : {value: null}
        this.projectManagers = task && task.projectManagers ? {value: task.projectManagers.reduce((acc, person) => {
                return {...acc, [person.id]: new RoleTasksDto(person)}
            }, {})} : {value: null}
    }

    validate(info: TInfoFormTask) {
        for (const fieldName in this) {
            // @ts-ignore
            this[fieldName].isDisabled = validate(fieldName, info)
        }
        return this;
    }
}

class RoleTasksDto implements TRoleWithIsMain {
    isMain;
    user;
    constructor(model: TPerson) {
        this.isMain = model.isMain ? model.isMain : false
        this.user = model.id
    }
}

function validate(fieldName: keyof TTaskForm, info: TInfoFormTask): boolean | undefined {
    if (!info) return undefined
    const { role, projectStatus, status } = info
    if (projectStatus === 'ACTUAL' || projectStatus === 'ARCHIVED') {
        if (role === 'ROLE_ADMIN') return taskValidateRules.roleAdmin(status, fieldName)
        if (role === 'ROLE_ADMIN_IS') return taskValidateRules.roleAdminIs(status, fieldName)
        else if (role === 'ROLE_COORDINATOR') return taskValidateRules.roleCoordinator(status, fieldName)
        else if (role === 'ROLE_CONTRACTOR') return taskValidateRules.roleContractor(status, fieldName)
        else if (role === 'ROLE_PRODUCER') return taskValidateRules.roleProducer(status, fieldName)
        else if (role === 'ROLE_ANALYST') return taskValidateRules.roleAnalyst(status, fieldName)
        else if (role === 'ROLE_FINANCIER') return taskValidateRules.roleFinancier(status, fieldName)
        else if (role === 'ROLE_PROJECT_MANAGER') return taskValidateRules.roleProjectManager(status, fieldName)
        else if (role === 'ROLE_CONTENT_EDITOR') return taskValidateRules.roleContentEditor(status, fieldName)

    } else return true
}