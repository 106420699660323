import React from 'react';
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectContentProject, selectFilter} from 'store/content/content/selectors';
import {SetSortingFilter} from "store/content/content/actions";
import {TFilterData} from "models/content";
import {ProjectLib} from 'entities/project';


const SubmissionFormsSelect = () => {
  const put = useDispatch()
  const filter = useSelector(selectFilter);
  const project = useSelector(selectContentProject)

  const {data: submissionFormsOptions, isLoading} = ProjectLib.useGetProjectContentSubmissionByProjectOptions({projectId: project.project?.id as string})

  const onSelectStatus = (values: string[]) => {
    let newFilter: TFilterData = {};
    if (filter.filterData) newFilter = { ...filter.filterData }
    newFilter = { ...newFilter, contentSubmissionFormIds: values}

    put(SetSortingFilter({ ...filter, filterData: newFilter }))
  }
  return (
    <div className="sorting__item">
      <Select
        loading={isLoading}
        value={filter.filterData?.contentSubmissionFormIds || []}
        mode={'multiple'}
        style={{ 'width': '200px' }}
        placeholder="Формат сдачи единиц контента"
        onChange={onSelectStatus}
        maxTagCount={"responsive"}
        showSearch
        options={submissionFormsOptions}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
        }
      />
    </div>
  )
}

export default SubmissionFormsSelect;