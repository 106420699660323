import {put, select} from "redux-saga/effects";
import {GetTasksGantStartAction, GetTasksKanbanStartAction} from "store/tasks/actions/gant-kanban";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {
    DuplicateTasksErrorAction,
    DuplicateTasksStartAction,
    DuplicateTasksSuccessAction,
    GetAllTasksStartAction,
    GetTaskStartAction,
} from "store/tasks/actions/tasks";
import TasksService from "services/tasksService";
import {selectProject} from "store/projects/selectors";
import {ProjectTypes} from "entities/project";

export function* duplicateTasks({taskId, dateList, tasksGet}: ReturnType<typeof DuplicateTasksStartAction>) {
    try {
        const project: ProjectTypes.Project = yield select(selectProject);
        yield TasksService.duplicateTasks(taskId, dateList)
        yield put(DuplicateTasksSuccessAction())
        yield put(NotificationOpen('success', 'Задачи продублированы', ''))

        yield put(GetTaskStartAction(taskId))

        if (tasksGet && tasksGet.length) {
            if (tasksGet.includes('kanban') && project.id) yield put(GetTasksKanbanStartAction(project.id))
            if (tasksGet.includes('gant') && project.id) yield put(GetTasksGantStartAction(project.id))
            if (tasksGet.includes('list')) yield put(GetAllTasksStartAction())
        }

    } catch (error: any) {
        yield put(DuplicateTasksErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось продублировать задачи'))

    }
}