import {createSelector} from 'reselect'
import {TApplicationState} from '../aplication-state'
import {formatAnalyticsData} from "../../utils/transformationValues";

const analyticsState = (state: TApplicationState) => state.analytics

export const selectAnalyticsProjectStatus = createSelector(analyticsState, (state) => ({
	isLoading: state.isLoading,
	error: state.error,
}))
export const selectAnalyticsReachData = createSelector(analyticsState, (state) => state.reachData)
export const selectAnalyticsProject = createSelector(analyticsState, (state) => state.projectCount)
export const selectAnalyticsSessionContentKpi = createSelector(analyticsState, (state) =>
	state.sessionContentKpi ? state.sessionContentKpi : null
)
export const selectAnalyticsSessionContentPosting = createSelector(
	analyticsState,
	(state) =>
		state.sessionContentPosting ? state.sessionContentPosting : null
)
export const selectAnalyticsPostingTop = createSelector(analyticsState, (state) => state.postingTop)
export const selectAnalyticsKpiTop = createSelector(analyticsState, (state) => state.kpiTop)
export const selectAnalyticsKpiTopProject = createSelector(analyticsState, (state) => state.kpiTopProject)
export const selectAnalyticsFilter = createSelector(analyticsState, (state) => state.filter)
export const selectAnalyticsFilterReach = createSelector(analyticsState, (state) => state.filterReach)
export const selectAnalyticsSummaryData = createSelector(analyticsState, (state) => {
	if (state.summaryData) {
		return [
			...formatAnalyticsData(state.summaryData),
			{
				contest: 'Общий итог',
				actualKpi: state.summaryData?.actualKpi,
				actualPostingCount: state.summaryData?.actualPostingCount,
				budget: state.summaryData?.budget,
				kpiPercentage: state.summaryData?.kpiPercentage,
				planKpi: state.summaryData?.planKpi,
				planPostingCount: state.summaryData?.planPostingCount,
				posingDifference: state.summaryData?.posingDifference,
				postingPercentage: state.summaryData?.postingPercentage,
				projectCount: state.summaryData?.projectCount,
				id: '111212123-AAA-1',
			},
		]
	}
})

