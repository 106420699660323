import { put, select } from 'redux-saga/effects'
import { NotificationOpen } from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	TasksReportCopyrightService,
	TasksReportEstimateService,
	TasksReportPostingService,
	TasksReportReachService,
} from 'services/tasksReportService'
import {
	GetRecordStartAction,
	GetTaskReportStartAction,
	VoteTaskReportChange,
	VoteTaskReportErrorAction,
	VoteTaskReportStartAction,
	VoteTaskReportSuccessAction,
} from 'store/tasks/actions/report'
import { ReportType } from 'models/task/types'
import { selectParams } from 'store/selectors'
import { TTaskRecordsFilter } from 'models/task/task-list'
import { selectReportTasksFilter } from 'store/tasks/selectors/selectors-task-report'

export function* voteReportTask({ data, typeTask }: ReturnType<typeof VoteTaskReportStartAction>) {
	try {
		const { id } = yield select(selectParams) //id задачи
		const filter: TTaskRecordsFilter = yield select(selectReportTasksFilter)

		const { searchString, taskId, ...rest } = filter.filterData
		const requestData = {
			...data,
			vote: { ...data.vote, isApproved: data.vote.isInterimApproved, isInterimApproved: null },
		}

		switch (typeTask) {
			case ReportType.CONTENT_POSTING_REPORT:
				yield TasksReportPostingService.voteTaskReport(requestData)
				break
			case ReportType.COPYRIGHT_LIST:
				yield TasksReportCopyrightService.voteTaskReport(requestData)
				break
			case ReportType.CONTENT_REACH_REPORT:
				yield TasksReportReachService.voteTaskReport(requestData)
				break
			case ReportType.PROJECT_ESTIMATE:
				yield TasksReportEstimateService.voteTaskReport(requestData)
				break
		}

		yield put(VoteTaskReportSuccessAction())
		yield put(VoteTaskReportChange(data.vote.recordId))

		if (searchString || Object.keys(rest).length) {
			if (id) yield put(GetTaskReportStartAction(id, typeTask))
		} else {
			yield put(GetRecordStartAction(typeTask, data.vote.recordId))
		}
		yield put(NotificationOpen('success', '', 'Голос засчитан'))
	} catch (error: any) {
		yield put(VoteTaskReportErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось проголосовать'))
	}
}
