import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from 'react-query'
import { Drawer, Spin, Switch } from 'antd'
import useToggleSidebar from 'hooks/toggleSidebar'
import List from './notification-list'
import { selectUser } from 'store/auth/selectors'
import { NotificationApi } from 'entities/notification'
import { Query } from 'processes/query'
import './index.less'

const NotificationSidebar = () => {
	const user = useSelector(selectUser)
	const { notificationStatus, handlerSidebar } = useToggleSidebar()
	const [checked, setChecked] = useState(false)

	const deniedRoles = user && ['ROLE_CLIENT', 'ROLE_AGENT', 'ROLE_VIEWER'].includes(user?.role)

	const { data, isLoading } = useQuery({
		queryKey: [NotificationApi.BrowserNotificationController.getNotificationKey, String(checked)],
		queryFn: () => NotificationApi.BrowserNotificationController.getNotification(checked),
		enabled: !deniedRoles
	})

	const { mutate } = useMutation({
		mutationFn: NotificationApi.BrowserNotificationController.bulkUpdateNotification,
		onSuccess: () => {
			Query.invalidate([NotificationApi.BrowserNotificationController.getNotificationKey, String(checked)])
		},
	})

	function onChange() {
		setChecked((prevState) => !prevState)
	}
	const checkedAllHandler = () => {
		const ids = data?.content.map((it) => it.id)
		mutate({ action: checked ? 'SET_UNREAD' : 'SET_READ', data: ids || [] })
	}

	const visible: boolean = notificationStatus && !!user

	return (
		<Drawer
			className="notificationSidebar"
			placement="right"
			width={350}
			closable={false}
			onClose={handlerSidebar('notification')}
			visible={notificationStatus}
		>
			<div className="notificationSidebar__header">
				<h2>Уведомления </h2>
				<div className="notificationSidebar__nav">
					<div className="notificationSidebar__nav-switch">
						Показать только {!checked && 'не'} прочитанные <Switch onChange={onChange} checked={!checked} />
					</div>
					<div className="notificationSidebar__nav-info" onClick={checkedAllHandler}>
						<strong>Отметить как {checked && 'не'} прочитанное</strong>
					</div>
				</div>
			</div>
			<Spin spinning={isLoading}>
				<div className="notificationSidebar__container">
					{visible && <List data={data} userId={user?.id as string} checked={checked} />}
				</div>
			</Spin>
		</Drawer>
	)
}

export default NotificationSidebar
