import {
    ExportUsersErrorAction,
    ExportUsersStartAction,
    ExportUsersSuccessAction, GetUsersListStartAction,
} from "store/options/actions/actions-users";
import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import UsersService from "services/usersService";
import {downloadXLXS} from "utils/downloadXLXS";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";

export default function* exportUsers({usersIds, name, callback}: ReturnType<typeof ExportUsersStartAction>) {
    try {
        const { data: response }: AxiosResponse<any> = yield UsersService.usersExport(usersIds)
        yield put(ExportUsersSuccessAction())
        downloadXLXS(response, name)
        yield put(NotificationOpen('success', 'Данные выгружены успешно'))
        yield put(GetUsersListStartAction())
        if (callback) callback()
    } catch (error: any) {
        yield put(ExportUsersErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось выгрузить'));
    }
}