import { SearchContentProjectTypes } from "features/search-project-content";
import {ActionTypes} from "./types";


export const setIndicatorsContent = <T extends Partial<SearchContentProjectTypes.Stores.TIndicatorsContentState['content']>>(
    typeContent: SearchContentProjectTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUE, typeContent, value, isToggled
})
export const setValuesIndicatorsContent = <T extends Partial<Omit<SearchContentProjectTypes.Stores.IndicatorsContent, 'isToggled'>>>(
    typeContent: SearchContentProjectTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUES, typeContent, value, isToggled
})
export const clearIndicatorsContent = (typeContent: SearchContentProjectTypes.Stores.TTypeStore) => ({
    type: ActionTypes.CLEAR_VALUE, typeContent
})