import React from 'react';
import styles from './styles.module.less';
import { DatePickerComponent, SelectComponent } from 'components/formic-control';
import { useFormikContext } from 'formik';
import { TForm } from './modal';
import { dayOfWeekOptions } from '../../lib';
import InputNumber from 'components/formic-control/InputNumber';


const Dates = () => {
	const {values, setFieldValue} = useFormikContext<TForm>();
	const duplicationTermsSecond = values.duplicationTerms === '2';
	const date = new Date();
	date.setDate(date.getDate() + 1);
	return (
		<>
			<div className={styles.item}>
				<span className={styles.label}>Дата создания дубля первой задачи</span>
				<DatePickerComponent
					name={'firstTaskDate'}
					disabled={!values.enabled || duplicationTermsSecond}
					placeholder={'Выберите дату первого дубля'}
					isAfter={true}
					isAfterDate={date}
					handler={() => {
						setFieldValue('lastTaskDate', null)
					}}
				/>
			</div>
			<div className={styles.item}>
				<span className={styles.label}>Дата создания дубля последней задачи</span>
				<DatePickerComponent
					disabled={!values.enabled || duplicationTermsSecond || !values.firstTaskDate}
					name={'lastTaskDate'}
					placeholder={'Выберите дату  последнего дубля'}
					isAfter={true}
					isAfterDate={values.firstTaskDate || undefined}
				/>
			</div>
			<div className={styles.item}>
				<span className={styles.label}>Выберите частоту создания</span>
				<SelectComponent
					disabled={!values.enabled || duplicationTermsSecond}
					options={dayOfWeekOptions}
					name={'dayOfWeek'}
					placeholder={'Выберите день недели создания'}
				/>
			</div>

			<div className={styles.item}>
				<span className={styles.label}>Количество дней до дедлайна</span>
				<InputNumber
					disabled={!values.enabled}
					name={'daysToDeadline'}
					placeholder={'Выберите количество дней до окончания задачи'}
					flor={true}
				/>
			</div>
		</>
	)
};

export default Dates;