import {
    GetTaskReportStartAction,
    SendReportBulkVotesErrorAction,
    SendReportBulkVotesStartAction,
    SendReportBulkVotesSuccessAction
} from "store/tasks/actions/report";
import {put, select} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {ReportType} from "models/task/types";
import {
    TasksReportCopyrightService,
    TasksReportEstimateService,
    TasksReportPostingService,
    TasksReportReachService
} from "services/tasksReportService";
import {selectParams} from "store/selectors";


export function* sendReportBulkVote({reportType, votes}: ReturnType<typeof SendReportBulkVotesStartAction>) {
    try {
        const {id} = yield select(selectParams)
        switch (reportType) {
            case ReportType.CONTENT_POSTING_REPORT:
                yield TasksReportPostingService.sendBulkVote(votes);
                break;
            case ReportType.COPYRIGHT_LIST:
                yield TasksReportCopyrightService.sendBulkVote(votes);
                break;
            case ReportType.PROJECT_ESTIMATE:
                yield TasksReportEstimateService.sendBulkVote(votes);
                break;
            case ReportType.CONTENT_REACH_REPORT:
                yield TasksReportReachService.sendBulkVote(votes);
                break;
            default:
                console.log('Данный тип не поддерживается')
        }

        yield put(SendReportBulkVotesSuccessAction())
        yield put(NotificationOpen('success', 'Голоса отправлены'))
        if (id) yield put(GetTaskReportStartAction(id, reportType))
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', message))
        yield put(SendReportBulkVotesErrorAction(message))
    }
}