import { z } from 'zod';

export const AwardSchema = z.object({
	id: z.string(),
	name: z.string(),
	nomination: z.string().nullable(),
	status: z.string().nullable(),
	year: z.number().nullable(),
});
export type Award = z.infer<typeof AwardSchema>;

