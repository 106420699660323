import {
    GetProjectsStartAction,
    GetProjectStartAction,
    UpdateProjectErrorAction,
    UpdateProjectStartAction,
    UpdateProjectSuccessAction
} from "store/projects/actions";
import Api from "entities/project/api/project";
import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";

export function* updateProject({projectId, project}: ReturnType<typeof UpdateProjectStartAction>) {
    try {
        const formData = new FormData();
        const {image, ...rest} = project;

        formData.append('project', JSON.stringify(rest));

        if (image) {
            formData.append('image', image.originFileObj as Blob);
        }

        const location = window.location.href?.split('/')[4]?.split('&')[0];

        if (projectId) {
            yield Api.updateProject(projectId, formData);
            yield put(UpdateProjectSuccessAction());

            yield put(NotificationOpen('success', 'Проект обновлен'))
        } else {
            yield put(NotificationOpen('warning', 'Ошибка', 'У проекта отсутствует id'))
        }

        if (location === `params?id=${projectId}`) {
            yield put(GetProjectStartAction(projectId));
        } else {
            yield put(GetProjectsStartAction());
        }

    } catch (error: any) {
        yield put(UpdateProjectErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить проект'))
    }
}