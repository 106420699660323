import { SearchContentProjectModel, SearchContentProjectTypes } from "features/search-project-content";
import {put, select} from "redux-saga/effects";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {FilterValues} from "../../../store/reducer";
import {setUserFiltersValue} from "../../../store/actions";
import Api from "../../../../api";
import {Logger} from "../../../../../../shared/services";

export function* search(typeId: string, filter?: FilterValues) {
    try {

        const {typeContent}: SearchContentProjectModel.TCFState['main'] = yield select(SearchContentProjectModel.selectors.main)
        const filterData: SearchContentProjectTypes.Stores.TAllFilterValues = yield select(SearchContentProjectModel.selectors.filter(typeContent))
        if (filter?.activeFilterId) {
            yield Api.update(filter.activeFilterId, {
                filterTypeId: typeId,
                name: filter.filterName,
                filterData: JSON.stringify(filterData)
            })
        } else {
            yield Api.create({
                filterTypeId: typeId,
                name: filter?.filterName,
                filterData: JSON.stringify(filterData)
            })
        }
    } catch (error) {
        Logger.error('createFilterWorker/search', error)
        yield put(setUserFiltersValue({error: error}))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
    }
}