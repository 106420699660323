import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import {Table} from "antd";
import classNames from "classnames";
import { NotificationOpen } from 'store/notification/actions';
import TasksService from "services/tasksService";
import { TaskTypes } from 'entities/task';
import { setUserFiltersValue } from 'features/user-filters/model/store/actions';
import CreateErrorMessage from 'utils/validation/createErrorMessage';
import ZodParser from 'shared/services/zod-parser';
import {useColumns} from "./useColumns";
import styles from './styles.module.less';
import { SprintTypes } from 'entities/sprints';

type Props = {sprint: SprintTypes.Sprint}
const TasksModal: FC<Props> = ({sprint}) => {
    const put = useDispatch();
    const tasks = useQuery({
        queryKey: [TasksService.getTasksStatisticKey, sprint.id],
        queryFn: async () => {
            const {data} = await TasksService.getTasksStatistic({sprintIds: [sprint.id]})
            ZodParser.parse(TaskTypes.StatisticSchema.array(), data, 'TasksService.getTasksStatistic')
            return data;
        },
        onError: (error) => {
            put(setUserFiltersValue({error: error}))
            const message = CreateErrorMessage.response(error)
            put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
        },
    })

    const columns = useColumns();

    return (
        <div className={styles.modal}>
            <div className={styles.header}>
                <h2 className={styles.title}>Задачи этапа "{sprint.name}"</h2>
                <span className={classNames(styles.sprint, sprint.status.code)}>{sprint.status.name}</span>
            </div>
            <Table
              loading={tasks.isLoading}
              className={classNames('table', styles.table)}
              sticky
              scroll={{x: 992}}
              columns={columns}
              dataSource={tasks.data || []}
              rowKey='id'
              pagination={false}
            />
        </div>
    );
};

export default TasksModal;