import React, {FC, useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import './index.less'
//antd
import {Button, Col, Modal, Row, Select, Spin, Table, Tooltip} from 'antd'
import {ColumnsType} from 'antd/es/table'
import {DownloadOutlined} from '@ant-design/icons'
//hooks
import useHandlerModal from 'hooks/handlModal'
import {useRecords} from './hooks'
import useTasksFields from 'hooks/tasksField'
import {useDownload} from 'hooks/useDownload'
//components
import NameCenterCell from 'components/table-cells/name-center-cell'
import WhetherTheUser from 'components/whetherTheUser'
import NameCellWithCount from 'components/table-cells/name-cell-with-count/name-cell-with-count'
//store
import {CreateFileArchiveStartAction} from 'store/tasks/actions/content'
import {selectDownloadRights, selectUser} from 'store/auth/selectors'
//models
import {Nullable, T_ID_NAME_CODE} from 'models/common'
import {TTaskRecordsFilterData} from 'models/task/task-list'
import {TTasksTypesFields} from 'models/task'
import {TaskTypes} from 'entities/task'
//other
import DownloadRights from './download-rights'
import {useBulkActions} from './useBulkActions'
import {CONTENT} from 'routing/names'
import {UserTypes} from 'entities/user'
import {useGetBulkActions} from "shared/lib/hooks/use-get-bulk-actions";

type ModalPostingContentProps = {
	status: boolean
	data: {
		projectId?: string
	}
}
const ModalContentRecords: FC<ModalPostingContentProps> = ({ status, data }) => {
	const put = useDispatch()
	const user = useSelector(selectUser)
	const { loading: loadingDownload, download } = useDownload()
	const history = useHistory()

	const downloadRights = useSelector(selectDownloadRights)
	const { recordsStatuses } = useTasksFields([TTasksTypesFields.recordsStatuses])
	const { getRecords, filter, loading, records, handleTable } = useRecords()
	const {bulkActionsOptions, isLoading} = useGetBulkActions('MODAL_CONTENT_RECORDS')


	const isClientAgentViewer = user?.role === 'ROLE_AGENT' || user?.role === 'ROLE_CLIENT' || user?.role === 'ROLE_VIEWER'

	const { isVisible, closeModal } = useHandlerModal(status)
	const { rowSelection, bulkAction, handlerBulkAction, handlerRowIds, rowsIds } = useBulkActions()
	const [valueSelect, setValueSelect] = useState()

	useEffect(() => {
		if (recordsStatuses && isVisible) {
			let filterData: TTaskRecordsFilterData = {
				isMasterFile: true,
				taskRecordStatusId: recordsStatuses?.find((el) => el.code === 'APPROVED')?.value,
				projectIds: data?.projectId ? [data?.projectId] : []
			}
			if (isClientAgentViewer) {
				filterData = { ...filterData, isContent: true, isAccountedContent: 'TRUE' }
			}
			getRecords({
				page: 1,
				pageSize: bulkAction ? 3000 : 5,
				filterData,
			})
		}
	}, [recordsStatuses, isVisible, data?.projectId, bulkAction, isClientAgentViewer]) //eslint-disable-line

	const handlerDownload = (record: TaskTypes.ContentRecords) => () => {
		if (downloadRights) {
			if (record.file) download(record.file.id, record.file.name)
		}
	}

	const handlerBulkActions = (value: any) => {
		setValueSelect(value)
		handlerBulkAction(value)
		handlerRowIds([])
	}

	const handlerApply = () => {
		handlerBulkActions(null)
		put(CreateFileArchiveStartAction(rowsIds))
	}

	const handlerClickLook = () => {
		closeModal()
		history.push(`${CONTENT.CONTENT_INFO}?id=${data.projectId}`)
	}

	const columns: ColumnsType<TaskTypes.ContentRecords> = useMemo(() => {
		const arr: ColumnsType<TaskTypes.ContentRecords> = [
            {
                title: 'Единица контента',
                dataIndex: ['contentFormatType', 'name'],
                key: 'contentFormatType',
                width: 150,
                render: (name: string) => {
                    return NameCenterCell(name)
                },
            },
			{
				title: 'Название единицы контента',
				dataIndex: 'name',
				key: 'name',
				width: 150,
				render: (name: string) => {
					return NameCenterCell(name)
				},
			},
			{
				title: 'О каком регионе контент',
				dataIndex: 'regionAboutSet',
				key: 'regionAboutSet',
				width: 140,
				render: NameCellWithCount,
			},
			{
				title: 'Для какого региона контент',
				dataIndex: 'regionForSet',
				key: 'regionForSet',
				width: 140,
				render: NameCellWithCount,
			},
			{
				title: 'Формат сдачи контента',
				dataIndex: 'submissionForm',
				key: 'submissionForm',
				width: 120,
				render: (submissionForm: Nullable<T_ID_NAME_CODE>) => {
					return NameCenterCell(submissionForm?.name as string)
				},
			},
			{
				title: 'Скачать контент',
				dataIndex: 'file',
				key: 'file',
				width: 120,
				render: (_file: any, record) => {
					if (!user?.contentDownloadAccess) return null
					return (
						<Spin spinning={loadingDownload}>
							<Tooltip title={downloadRights ? 'Скачать' : 'Для скачивания подтвердите согласие'}>
								<div className={`download ${!downloadRights && 'disabled'}`} onClick={handlerDownload(record)}>
									<DownloadOutlined />
								</div>
							</Tooltip>
						</Spin>
					)
				},
			},
		]
		const roleHiddenColumns: ColumnsType<TaskTypes.ContentRecords> = [
			{
				title: 'Тип учёта',
				dataIndex: 'isAccountedContent',
				key: 'isAccountedContent',
				width: 150,
				render: (isAccountedContent: boolean | null) => {
					let name = '__'
					if (typeof isAccountedContent === 'boolean' && isAccountedContent) name = 'Учитываемый'
					if (typeof isAccountedContent === 'boolean' && !isAccountedContent) name = 'Неучитываемый'
					if (isAccountedContent === null) name = 'Неопределённый'
					return NameCenterCell(name)
				},
			},
			{
				title: 'Тип материала',
				dataIndex: 'isContent',
				key: 'isContent',
				width: 150,
				render: (isContent: boolean | null) => {
					return NameCenterCell(isContent === null ? '__' : isContent ? 'Контент' : ' Рабочий материал')
				},
			},
			{
				title: 'Тип файла',
				dataIndex: 'isMasterFile',
				key: 'isMasterFile',
				width: 150,
				render: (isMasterFile: boolean | null) => {
					return NameCenterCell(isMasterFile === null ? '__' : isMasterFile ? 'Мастер' : 'Предмастер')
				},
			},
		]

		return isClientAgentViewer ? arr : [...roleHiddenColumns, ...arr]
		// eslint-disable-next-line
	}, [downloadRights, loadingDownload, user, isClientAgentViewer])

	return (
		<Modal
			className="ModalContentRecords"
			title="Список единиц контента проекта"
			visible={isVisible}
			onCancel={closeModal}
			footer={
				<>
					<Button type={'default'} onClick={closeModal}>
						Закрыть
					</Button>
				</>
			}
			maskClosable={true}
		>
			{user?.contentViewAccess ? <DownloadRights /> : null}
				<Row>
					{!!bulkActionsOptions?.length && (
						<>
							<Col md={8} sm={24} xs={24}>
								<div className="form-group bulk-actions">
									<Select
										loading={isLoading}
										allowClear={true}
										options={bulkActionsOptions}
										className="ModalContentRecords__select"
										placeholder={'Массовые действия'}
										onChange={handlerBulkActions}
										value={valueSelect}
									/>
								</div>
							</Col>

							<Col md={4} sm={24} xs={24}>
								{downloadRights && rowsIds.length > 0 ? (
									<div className="form-group">
										<Button type={'primary'} onClick={handlerApply} disabled={false}>
											Применить
										</Button>
									</div>
								) : null}
							</Col>
						</>
					)}
					<WhetherTheUser role={['ROLE_CONTRACTOR', 'ROLE_VIEWER']} isCan={false}>
						<>
							<Col md={7} />
							{user?.contentViewAccess ? (
								<Col md={5} sm={24} xs={24}>
									<div className="form-group">
										<Button type="primary" ghost onClick={handlerClickLook}>
											Посмотреть контент
										</Button>
									</div>
								</Col>
							) : null}
						</>
					</WhetherTheUser>
				</Row>

			<Table
				rowSelection={bulkAction ? { type: 'checkbox', ...rowSelection } : undefined}
				loading={loading}
				className="table-contentRecords table"
				sticky
				scroll={{ x: 900 }}
				columns={columns}
				dataSource={records ? (bulkAction ? availableRecords(records, user?.role) : records) : []}
				pagination={{
					current: filter ? filter.page : 1,
					pageSize: filter ? filter.pageSize : 5,
					total: filter ? filter.totalCount : 1,
					position: ['bottomCenter'],
					showSizeChanger: true,
					pageSizeOptions: ['5', '10', '20', '50', '100'],
				}}
				rowKey="id"
				onChange={handleTable}
			/>
		</Modal>
	)
}

function availableRecords(records: TaskTypes.ContentRecords[], role?: UserTypes.RoleName) {
	if (!role) return []
	const isCheck = ['ROLE_CLIENT', 'ROLE_AGENT', 'ROLE_VIEWER'].includes(role)
	return records.filter((it: any) => it.file?.id && (isCheck ? it.status === 'APPROVED' : true))
}

export default ModalContentRecords
