import {z} from "zod";
import ZodParser from "shared/services/zod-parser";
import {StepFifthDto} from "../dto/project-form_StepFifth.dto";
import addHours from "../add-hours";

const schema = z.object({
    isNationalProject: z.boolean().nullable(),
    licenseDate: z.date().or(z.string()).nullable(),
    targetAudienceAgeFrom: z.number().nullable(),
    targetAudienceAgeTo: z.number().nullable(),
    famousPeople: z.array(z.object({
        rankId: z.string(),
        famousPersonId: z.string()
    })).nullable(),
    contentLocationRegionIds: z.array(z.string()).nullable(),
    filmClusterIds: z.array(z.string()).nullable(),
    projectDataCheckedDatetime: z.date().or(z.string()).nullable(),
    firstLevelNationalTagIds: z.array(z.string()).nullable(),
    secondLevelNationalTagIds: z.array(z.string()).nullable(),
    firstLevelNationalAssignmentTagIds: z.array(z.string()).nullable(),
    secondLevelNationalAssignmentTagIds: z.array(z.string()).nullable(),
    categoryIds: z.array(z.string()).nullable(),
    genreIds: z.array(z.string()).nullable(),
    awardIds: z.array(z.string()).nullable(),
})
export type EditSchema5 = z.infer<typeof schema>
export function step5(step: StepFifthDto): EditSchema5 {
    const mapped: EditSchema5 = {
        isNationalProject: step.isNationalProject.value || null,
        licenseDate: step.licenseDate.value || null,
        targetAudienceAgeFrom: typeof step.targetAudienceAgeFrom.value?.value === 'string' ? Number(step.targetAudienceAgeFrom.value?.value) || 0 : null,
        targetAudienceAgeTo: Number(step.targetAudienceAgeTo.value?.value) || null,
        famousPeople: step.famousPeople.value.length > 0
            ? step.famousPeople.value.map((el) => ({
                rankId: el.rank?.value as string,
                famousPersonId: el.famousPerson?.value as string,
            })) : null,
        contentLocationRegionIds: step.contentLocationRegions.value.length > 0
            ? step.contentLocationRegions.value.map((el) => el.value) : null,
        filmClusterIds: step.filmClusters.value.length > 0
            ? step.filmClusters.value.map((el) => el.value) : null,
        projectDataCheckedDatetime: (step.projectDataCheckedDatetime.value && step.projectDataCheckedDatetime.code) ?
            step.projectDataCheckedDatetime.value :
            step.projectDataCheckedDatetime.code ? addHours(new Date(), 3) : null,
        firstLevelNationalTagIds: step.firstNationalLevelTags.value.length > 0
            ? step.firstNationalLevelTags.value.map(({value}) => value) : null,
        secondLevelNationalTagIds: step.secondNationalLevelTags.value.length > 0
            ? step.secondNationalLevelTags.value.map(({value}) => value) : null,
        firstLevelNationalAssignmentTagIds: step.firstLevelNationalAssignmentTags.value.length > 0
            ? step.firstLevelNationalAssignmentTags.value.map(({value}) => value) : null,
        secondLevelNationalAssignmentTagIds: step.secondLevelNationalAssignmentTags.value.length > 0
            ? step.secondLevelNationalAssignmentTags.value.map(({value}) => value) : null,
        categoryIds: step.categoryTypes.value.length > 0
            ? step.categoryTypes.value.map(({value}) => value): null,
        genreIds: step.genreTypes.value.length > 0
            ? step.genreTypes.value.map(({value}) => value): null,
        awardIds: step.awards.value.length > 0
            ? step.awards.value.map((value) => value): null
    }
    ZodParser.parse(schema, mapped, 'Edit step5')
    return mapped
}
