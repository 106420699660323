import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {Button, Collapse, DatePicker, Input, Spin, Tooltip} from 'antd'
import { CaretDownOutlined, CaretRightOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import styles from './styles.module.less'
import { useFormikContext } from 'formik'
import { TForm } from '../modal-duplication/modal'
import { TasksScheduleDatesController } from 'features/сonfiguring-task-autocreation/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Filters from './components/filters'
import { ScheduleDatesSet } from '../../types'
import classNames from 'classnames'
import { NotificationOpen } from 'store/notification/actions'

const { Panel } = Collapse
const TasksScheduleDatesSet = () => {
	const put = useDispatch()
	const queryClient = useQueryClient()
	const {values: { duplicationTerms }} = useFormikContext<TForm>()
	const [name, setName] = useState('')
	const [activeKey, setActiveKey] = useState<string | string[]>([])
	const [scheduleDates, setScheduleDates] = useState<Array<string | Date>>([''])
	const activeFilterRef = useRef<ScheduleDatesSet | null>(null)

	const { data: scheduleDatesSet, isLoading } = useQuery({
		queryKey: [TasksScheduleDatesController.getTasksScheduleDatesKey],
		queryFn: TasksScheduleDatesController.getTasksScheduleDates,
	})

	const { mutate: create } = useMutation({
		mutationFn: TasksScheduleDatesController.createTasksScheduleDates,
		onSuccess: async () => {
			put(NotificationOpen('success', 'Фильтр успешно создан'))
			await queryClient.invalidateQueries([TasksScheduleDatesController.getTasksScheduleDatesKey])
		},
	})

	const { mutate: update } = useMutation({
		mutationFn: TasksScheduleDatesController.updateTasksScheduleDates,
		onSuccess: async () => {
			put(NotificationOpen('success', 'Фильтр успешно обновлён'))
			await queryClient.invalidateQueries([TasksScheduleDatesController.getTasksScheduleDatesKey])
		},
	})

	const handleClickCancel = () => {
		setName('')
		setActiveKey([])
		setScheduleDates([''])
		activeFilterRef.current = null
	}

	useEffect(() => {
		if (duplicationTerms === '1') handleClickCancel()
	}, [duplicationTerms])

	const handleClickFilter = (filter: ScheduleDatesSet | null) => {
		activeFilterRef.current = filter
		if (filter) {
			setName(filter?.name)
			setScheduleDates(filter?.dateSet)
		} else {
			setName('')
			setScheduleDates([''])
		}
	}

	const handleAddField = () => {
		setScheduleDates([...scheduleDates, ''])
	}

	const handleRemoveField = (index: number) => () => {
		const newScheduleDates = [...scheduleDates]
		newScheduleDates.splice(index, 1)
		setScheduleDates(newScheduleDates)
	}

	const handleChangeDate = (index: number) => (date: any, dateString: any) => {
		const newScheduleDates = [...scheduleDates]
		const arr = dateString.split('.')
		const dateFormat = arr.reverse().join('-')
		newScheduleDates[index] = date ? new Date(dateFormat) : ''
		setScheduleDates(newScheduleDates)
	}

	const handlerChangeCollapse = (key: string | string[]) => {
		setActiveKey(key)
	}

	const onCreate = () => {
		create({
			name: name,
			dateSet: scheduleDates,
		})
		handleClickCancel()
	}

	const onUpdate = () => {
		update({
			data: { name, dateSet: scheduleDates },
			id: activeFilterRef.current?.id || '',
		})
	}

	const onSubmit = () => {
		if (activeFilterRef.current) {
			onUpdate()
		} else {
			onCreate()
		}
	}
	return (
		<div className={styles.container}>
			<div className={classNames(styles.filters, { mb: isLoading || scheduleDatesSet?.length })}>
				<Spin spinning={isLoading}>
					<Filters
						data={scheduleDatesSet as unknown as ScheduleDatesSet[]}
						handleClickFilter={handleClickFilter}
						disabled={duplicationTerms === '1'}
					/>
				</Spin>
			</div>

			<Collapse
				collapsible={duplicationTerms === '1' ? 'disabled' : undefined}
				bordered={false}
				className={styles.collapse}
				activeKey={activeKey}
				onChange={handlerChangeCollapse}
				expandIcon={({ isActive }) => {
					return isActive ? <CaretDownOutlined /> : <CaretRightOutlined />
				}}
			>
				<Panel header="Добавить наборы дат для создания задач" key="1" className={styles.panel}>
					<div className={styles.field}>
						<div className={styles.field__item}>
							<span className={styles.field__name}>Наименование набора</span>
							<Input
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder={'Введите название набора дат'}
							/>
							<div onClick={handleAddField} className={styles.add}>
								<Tooltip title={'Добавить дату'}>
									<PlusCircleOutlined />
								</Tooltip>

							</div>
						</div>

						<div>
							{scheduleDates.map((field, index) => (
								<div key={index} className={styles.field__item}>
									<span className={styles.counter}>Дата {index + 1}</span>
									<DatePicker
										className={styles.date_picker}
										value={field ? moment(field) : undefined}
										onChange={handleChangeDate(index)}
										format={'DD.MM.YYYY'}
										disabledDate={(date: moment.Moment) => {
											return !date.isAfter(new Date())
										}}
									/>
									<DeleteOutlined onClick={handleRemoveField(index)} className={styles.remove} />
								</div>
							))}
						</div>

						<div className={styles.buttons}>
							<Button onClick={handleClickCancel}>Отменить</Button>
							<Button
								type={'primary'}
								disabled={!name || !scheduleDates.every(Boolean) || (scheduleDatesSet && scheduleDatesSet?.length >= 8)}
								onClick={onSubmit}>
								{activeFilterRef.current ? 'Обновить' : 'Сохранить'}
							</Button>
						</div>
					</div>
				</Panel>
			</Collapse>
			{!!(scheduleDatesSet && scheduleDatesSet?.length >= 8) && !!activeKey.length && (
				<div className={styles.error}>
					Количество создаваемых наборов дат превышено, выберите доступный набор из списка и отредактируйте его для сохранения новых данных
				</div>
			)}

		</div>
	)
}

export default TasksScheduleDatesSet
