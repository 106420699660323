import React from 'react'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { DatePicker } from 'antd'
import styles from './styles.module.less'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import { FieldAttributes } from 'formik/dist/Field'
import TextError from 'components/formic-control/text-error'
import moment from 'moment/moment'
import { InitialValueDto } from '../../lib/dto'

const Time = () => {
	const { setFieldValue } = useFormikContext<InitialValueDto>()
	const onChange = (time: any) => {
		if (time) setFieldValue('time', moment(time).format('HH:mm:ss'))
		else setFieldValue('time', undefined)
	}
	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Время формирования отчета <sup>*</sup>
			</span>
			<div className={styles.picker}>
				<Field name="time">
					{(el: FieldAttributes<any>) => {
						return (
							<DatePicker
								name={'time'}
								onChange={onChange}
								value={el.meta.value && moment(el.meta.value, 'HH:mm:ss')}
								picker={'time'}
								showNow={false}
								placeholder={'Выберите время формирования отчета'}
								locale={locale}
								onBlur={(_event) => {
									// eslint-disable-next-line
									el.form.setTouched({ ...el.form.touched, ['time']: true })
								}}
							/>
						)
					}}
				</Field>
				{<ErrorMessage name={'time'} component={TextError} />}
			</div>
		</div>
	)
}

export default Time
