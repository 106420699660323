import {
    applyMiddleware,
    compose,
    createStore,
} from 'redux';
import {createBrowserHistory, History} from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './workers/index';

import reducers from './store';
import { BreadcrumbsLib } from 'processes/breadcrumbs';

export const history = createBrowserHistory();

const IS_BROWSER = typeof window !== 'undefined';
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}
const composeEnhancers = (IS_BROWSER && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const customMiddleWare = (store: {getState: Function, dispatch: Function
}) => (next: (action: any) => void) => (action: {
    type: string, payload?: {
        action: string,
        location: {
            pathname: string,
            search: string,
            state?: {tooltip?: string}
        }
    }
}) => {
    if (action.type === '@@router/LOCATION_CHANGE' && action?.payload?.action === 'PUSH') {
        const location = action.payload.location;
        const tooltip = location?.state?.tooltip;
        BreadcrumbsLib.SessionStorage.set({
            pathname: location.pathname,
            search: location.search,
            tooltip
        })
    }
    next(action);
}
function configureStore(initialState = {}, history: History) {
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        reducers(history),
        initialState,
        composeEnhancers(applyMiddleware(
            routerMiddleware(history),
            customMiddleWare,
            sagaMiddleware,
        )),
    );

    sagaMiddleware.run(rootSaga);

    return { store };
}

export default configureStore;