const tagDocuments = [
    {
        id: '72',
        selector: '.page__actions-select-bulk',
        content: 'Для выполнения массового действия выберите тип массового действия, которое хотите совершить. Далее выберите записи таблицы и нажмите “Применить”',
        role: [],
    },
    {
        id: '74',
        selector: '.page__actions--download',
        content: 'Справочник позволяет выгрузить текущие данные и шаблон для загрузки новых данных из Excel',
        role: [],
    },
    {
        id: '75',
        selector: '.page__actions--import',
        content:
            'Для массовой загрузки новых данных справочника, заполните выгруженный вами Excel шаблон и добавьте ваш файл при загрузке',
        role: [],
    },
    {
        id: '76',
        selector: '.page__actions--add',
        content:'Для наполнения справочника новыми данными, нажмите “Добавить...”',
        role: [],
    },
    {
        id: '77',
        selector: '.ant-table',
        content:
            'Таблица справочной информации отражает текущие данные, которые используются в проектах и задачах системы, позволяет отсортировать данные, сделать их видимыми и невидимыми для пользователей системы, а также удалить и отредактировать данные',
        role: [],
    },
]

export default tagDocuments
