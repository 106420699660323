import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import {GetTasksRecordsStartAction, SetTasksRecordsSearchFilterAction} from "store/tasks/actions/records";


export function* setTasksRecordsSearchFilter (_: ReturnType<typeof SetTasksRecordsSearchFilterAction>) {
    try {
        yield put(GetTasksRecordsStartAction())

    } catch (error: any) {
        yield put(NotificationOpen(
            'error',
            'Ошибка',
            ''
        ))
    }
}