import React, {FC, useEffect, useState} from 'react';
import './index.less'
import {FileType} from "models/common";
import {Empty, Image, Spin} from "antd";
import env from "shared/config/env";
import TooltipImage from "shared/ui/tooltipImage";

const pathImage = `${env.API_ENDPOINT}/files/`;

const ImageComponentPreviewCompress: FC<Props> = ({
  file, tooltip
}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const preview = file?.compressedId || file?.id
    const imageId = file?.scaledId || file?.compressedId || file?.id;

    useEffect(() => {
        if (!imageId) setLoading(false)
    }, [imageId]);

    const onErrorHandler = () => {
        setError(true);
    }


    const getImage = () => {
        const img = (
            <Image
                src={pathImage + imageId}
                alt={file?.name}
                preview={{
                    src: pathImage + preview
                }}
                onLoad={_event => {
                    setLoading(false)
                }}
                onError={onErrorHandler}
            />
        )
        return tooltip ? (
            <TooltipImage src={pathImage + imageId}>
                {img}
            </TooltipImage>
        ) : img
    }

    return (
        <Spin spinning={loading} className="ImageComponent">
            {(error || !imageId) && <Empty/>}
            {!error && imageId && getImage()}
        </Spin>
    )
};
type Props = {
    file?: FileType | null
    tooltip?: boolean
}
export default ImageComponentPreviewCompress;