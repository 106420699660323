import React from 'react';
import {Select, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {SearchContentProjectModel, SearchContentProjectTypes} from 'features/search-project-content/index';
import {IOptions} from "models/feels";
import {ProjectLib} from 'entities/project';
import {ContentFormatTypeLib} from 'entities/content-format-type'

const { Option} = Select;

type TKey = keyof Omit<
    SearchContentProjectTypes.Stores.Content, 'isToggled'>

export const ProjectItem = () => {
    const {data: formatTypesOptions, isLoading: formatTypesLoading} = ContentFormatTypeLib.useGetContentFormatTypeOptions()
    const {data: submissionFormsOptions, isLoading: submissionFormsLoading} = ProjectLib.useGetProjectContentSubmissionOptions()

    return (
        <Spin spinning={formatTypesLoading || submissionFormsLoading}>
            <div className="item-wrapper column">
                <Item
                    options={formatTypesOptions}
                    name={'contentFormatTypeIds'}
                    label={'Единицы контента в проекте'}
                />
                <Item
                    options={submissionFormsOptions}
                    name={'contentFilterContentSubmissionFormIds'}
                    label={'Формат сдачи единиц контента согласно записям задач (файл)'}
                />
                <Item
                    options={submissionFormsOptions}
                    name={'contentSubmissionFormIds'}
                    label={'Формат сдачи единиц контента в проекте'}
                />
                <Item
                    options={formatTypesOptions}
                    name={'contentFilterContentFormatTypeIds'}
                    label={'Единицы контента согласно записям задач (файл)'}
                />
            </div>
        </Spin>
    )
}
type ItemProps = {
    options: IOptions<string | boolean>[],
    name: TKey,
    label: string
}
function Item({options, name, label}: ItemProps) {
    const put = useDispatch();
    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main)
    const content = useSelector(SearchContentProjectModel.selectors.content)

    function onSelectChange(value: string[]) {
        put(SearchContentProjectModel.actions.setContent(typeContent, {
            [name]: value
        }))
    }

    return (
        <div className="item">
            <div className="label">{label}</div>
            <div className="form-group">
                <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={true}
                    onChange={onSelectChange}
                    maxTagCount={"responsive"}
                    mode={"multiple"}
                    value={content[typeContent][name]}
                    placeholder={'Искать среди всех'}
                    filterOption={(input, option) => {
                        if (option === undefined || option === null) return false;
                        return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                >
                    {options.map((el) => (
                        <Option
                            key={el.value}
                            value={el.value}
                            data-name={el.label}
                        >
                            {el.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}