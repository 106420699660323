import { put } from 'redux-saga/effects'
import { NotificationOpen } from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	DeleteGenreProjectErrorAction,
	DeleteGenreProjectStartAction,
	DeleteGenreProjectSuccessAction,
	GetHandbookStartAction,
} from 'store/options/actions/actions-handbook'
import { TStatusesHandbookFeels } from 'models/handbook'
import GenreProjectService from 'services/genreProjectService'

export function* deleteGenreProject({ id }: ReturnType<typeof DeleteGenreProjectStartAction>) {
	try {
		yield GenreProjectService.deleteGenreProjectRecord(id)
		yield put(DeleteGenreProjectSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.genreProject))
		yield put(NotificationOpen('success', 'Жанр удалён'))
	} catch (error: any) {
		yield put(DeleteGenreProjectErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить жанр'))
	}
}
