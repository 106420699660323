import {z} from "zod";
import {RoleSchema} from "./user";
import {SearchResponse} from "shared/types/common";

export const UserActionSchema = z.object({
    user: z.object({
        id: z.string(),
        lastName: z.string(),
        firstName: z.string(),
        middleName: z.string()
    }),
    role: RoleSchema,
    ipAddress: z.string(),
    dateTime: z.string(),
    actionName: z.string(),
    routingPageAddress: z.string(),
    requestUrl: z.string(),
    requestMethod: z.string(),
    requestPayload: z.string(),
    methodStatusCode: z.string(),
    requestResponse: z.string(),
    actionInformation: z.string(),
    actionParameters: z.object({
        additionalProp1: z.string(),
        additionalProp2: z.string(),
        additionalProp3: z.string()
    })
})

export type UserAction = z.infer<typeof UserActionSchema>
export type UserActionsResponse = SearchResponse<UserAction>

export const UserActionCreateSchema = z.object({
    id: z.string(),
    name: z.string(),
    template: z.string().optional(),
    enabled: z.boolean().optional(),
    code: z.string().nullable().optional()
})
export type UserActionCreate = z.infer<typeof UserActionCreateSchema>
