import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteDocumentsErrorAction,
    DeleteDocumentsStartAction,
    DeleteDocumentsSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import HandbookService from "services/handbookService";


export function* deleteDocuments({id}: ReturnType<typeof DeleteDocumentsStartAction>) {
    try {
        yield HandbookService.deleteDocumentsRecord(id)
        yield put(DeleteDocumentsSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.documents))
        yield put(NotificationOpen('success', 'Тип документа удалён'))
    } catch (error: any) {
        yield put(DeleteDocumentsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить тип документа'))
    }
}
