import { SearchContentProjectTypes } from "features/search-project-content";
import {ActionTypes} from "./types";


export const setThematics = <T extends Partial<Omit<SearchContentProjectTypes.Stores.Thematics, 'isToggled'>>>(
    typeContent: SearchContentProjectTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUE, typeContent, value, isToggled
})
export const clearThematics = (typeContent: SearchContentProjectTypes.Stores.TTypeStore) => ({
    type: ActionTypes.CLEAR_VALUE, typeContent
})