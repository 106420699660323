import { SprintTypes } from 'entities/sprints';
import { TaskTypes } from 'entities/task';
import { z } from 'zod';

export const GanttSprintTaskSchema = z.object({
	id: z.string(),
	name: z.string(),
	code: z.string(),
	number: z.number(),
	type: z.object({
		id: z.string(),
		name: z.string(),
		code: z.nativeEnum(TaskTypes.TypesTask),
	}),
	reportType: z.object({
		id: z.string(),
		name: z.string(),
		code: z.nativeEnum(TaskTypes.ReportType),
	}).nullable(),
	status: z.object({
		id: z.string(),
		name: z.string(),
		code: TaskTypes.TasksStatusEnum,
	}),
	archival: z.boolean(),
	frozen: z.boolean(),
	isRecordsExists: z.boolean(), //Признак наличия записей у задачи
	progress: z.number(), //Прогресс выполнения задачи
	creationDate: z.string(),
	deadline: z.string(),
	taskUntilEndDays: z.number(), //Количество дней до даты завершения задачи
})
export type GanttSprintTask = z.infer<typeof GanttSprintTaskSchema>
export const GanttSprintSchema = z.object({
	id: z.string(),
	name: z.string(),
	dateStart: z.string(),
	dateEnd: z.string(),
	amount: z.number(), //Вознаграждение за спринт
	status: z.object({
		id: z.string(),
		name: z.string(),
		code: SprintTypes.SprintStatusEnum
	}),
	tasks: z.array(GanttSprintTaskSchema)
})
export type GanttSprint = z.infer<typeof GanttSprintSchema>