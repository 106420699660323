import { ProjectTypes } from 'entities/project';
import { TaskTypes } from 'entities/task';
import { UserTypes } from 'entities/user';
import { ContractorAndClient } from './contractor-and-client';
import { Users } from './users';
import CheckReviewer from 'utils/validation/checkReviewer';
import { NewVote, Vote } from 'entities/task/types';


type Props = {
	recordStatus: TaskTypes.TasksRecordStatus,
	projectStatus: ProjectTypes.ProjectStatus | null
	hasComments: boolean

	user: UserTypes.CurrentUser
	votes: Vote[]
	newVote: NewVote | null
}
type Status = 'disabled' | 'hidden' | 'view';
export type ValidateVoting = {
	approved: Status
	warning: Status
	rejected: Status
	changeVote: Omit<Status, 'disabled'>
	handlers: boolean
	send: Status
	messageList: Status
	message: Status
	edit: Status
	votesInfo: Status
}

export const validations = ({
	projectStatus,
	recordStatus,
	hasComments,
	user,
	votes,
	newVote
}: Props): ValidateVoting  => {
	const role = user.role;

	const {isReviewed, isCanReview} = CheckReviewer(user, votes);

	const validate: ValidateVoting = {
		approved: 'hidden',
		rejected: 'hidden',
		warning: 'hidden',
		changeVote: 'hidden',
		messageList: 'hidden',
		message: 'hidden',
		send: 'hidden',
		edit: 'hidden',
		votesInfo: 'hidden',
		handlers: false,
	}
	if (role === 'ROLE_CONTRACTOR' || role === 'ROLE_CLIENT') {
		return ContractorAndClient({
			validate,
			recordStatus,
			hasComments
		})
	}
	const includedRoles: UserTypes.RoleName[] = [
		'ROLE_ADMIN',
		'ROLE_ADMIN_IS',
		'ROLE_PRODUCER',
		'ROLE_COORDINATOR',
		'ROLE_ANALYST',
		'ROLE_FINANCIER',
		'ROLE_LAWYER',
		'ROLE_CONTENT_EDITOR',
		'ROLE_PROJECT_MANAGER',
		'ROLE_MODERATOR',
	];
	if (role && includedRoles.includes(role)) {
		return Users({
			validate,
			role,
			recordStatus,
			hasComments,
			isCanReview,
			isReviewed,
			voteList: votes.length,
			projectStatus,
			vote: newVote
		})
	}


	return validate;
}