import {z} from "zod";
import {RoleSchema} from "./user";

export const InspectorSchema =z.object({
    id: z.string(),
    lastName: z.string(),
    firstName: z.string(),
    middleName: z.string().nullable(),
    role: RoleSchema,
    leader: z.boolean().nullable(),
    status: z.enum(['ACTIVE', 'BLOCKED', 'ARCHIVE']),
    keycloakLastSyncDatetime: z.string().nullable(),
    contentViewAccess: z.boolean().nullable(),
    contentDownloadAccess: z.boolean().nullable(),
    contentStorageLinkAccess: z.boolean().nullable(),
})

export type TInspector = z.infer<typeof InspectorSchema>;