import { Modal } from 'antd';
import React, {FC, useEffect, useState} from 'react';
import { ModalType } from './events';
import styles from './styles.module.less';
import { ModalEvent } from '.';

const ModalContent: FC<{ isOpen: boolean; data: ModalType | null; closeModal: Function }> = ({
	isOpen,
	data,
	closeModal,
}) => {
	const handleCancel = () => {
		closeModal();
	};

	return (
		<Modal
			className={styles.modal}
			centered
			visible={isOpen}
			onCancel={handleCancel}
			footer={null}
			{...data}
		>
			{data?.component}
		</Modal>
	);
};
const ModalComponent = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState<ModalType | null>(null);
	const OpenModal = (data: ModalType) => {
		setIsOpen(true);
		setData(data);
	};
	const CloseModal = () => {
		setIsOpen(false);
		setData(null);
	};

	useEffect(() => {
		const unsubscribe = ModalEvent.subscribeOpen(OpenModal);
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		const unsubscribe = ModalEvent.subscribeClose(CloseModal);
		return () => unsubscribe();
	}, []);

	return <ModalContent isOpen={isOpen} data={data} closeModal={CloseModal} />;
};
export default ModalComponent;
