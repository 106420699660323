import React, { useState } from 'react';
import styles from './styles.module.less';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AwardsApi, AwardsTypes } from 'entities/award';
import { DatePicker, Input, Select, Spin, Tooltip } from 'antd';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
	EditOutlined, PlusCircleOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { ProjectFormDto } from '../../lib';
import { useDispatch } from 'react-redux';
import { NotificationOpen } from 'store/notification/actions';
import { Nullable } from 'models/common';
import ReloadList from 'components/forms/components/reload-list/reload-list';
import WhetherTheUser from 'components/whetherTheUser';
import moment from 'moment';

const {Option} = Select;
type State = AwardsTypes.Award;
const AwardComponent = () => {
	const put = useDispatch();
	const queryClient = useQueryClient();
	const {values, setFieldValue} = useFormikContext<ProjectFormDto>();
	const value = values.stepFifth.awards.value;
	const isDisabled = values.stepFifth.awards.isDisabled;
	const [edit, setEdit] = useState<Nullable<State>>(null);
	const [open, setOpen] = useState(false);

	const awards = useQuery({
		queryKey: [AwardsApi.getAll.key],
		queryFn: AwardsApi.getAll.fetchOptions,
	})

	const deleteAward = useMutation({
		mutationFn: (id: string) => AwardsApi.deleteAward(id),
		onSuccess: async () => {
			await queryClient.invalidateQueries([AwardsApi.getAll.key]);
			put(NotificationOpen( 'success', 'Награда удалена'))
		},
	})

	const handlerUpdateList = async () => {
		await queryClient.invalidateQueries([AwardsApi.getAll.key]);
	}
	const onCancel = (event?: any) => {
		event && event.stopPropagation();
		setEdit(null)
	}
	const onEdit = (props: AwardsTypes.Award) => (event: any) => {
		event.stopPropagation();
		setEdit(props)
	}
	const onDelete = (id: string) => (event: any) => {
		event.stopPropagation();
		deleteAward.mutate(id)
	}
	const onChange = (value: string[]) => {
		setFieldValue('stepFifth.awards.value', value)
	}

	const isLoading = awards.isLoading || deleteAward.isLoading;
	return (
		<div className="form-group">
			<ReloadList handlerUpdateList={handlerUpdateList}/>
			<Select
				className={styles.select}
				getPopupContainer={trigger => trigger.parentNode}
				value={value}
				onChange={onChange}
				mode={'multiple'}
				maxTagCount={'responsive'}
				allowClear
				open={open}
				onDropdownVisibleChange={(open) => {
					if (!edit) {
						setOpen(open)
					}
				}}
				disabled={isDisabled}
				onClick={(event) => event.stopPropagation()}
				loading={isLoading}
				placeholder={'Выберите одну или несколько наград проекта'}
				filterOption={(input, option) => {
					if (option === undefined || null) return false;
					return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
				}}
				dropdownRender={(menu) => {
					return (
						<div className={styles.dropdown}>
							{menu}
							{edit ?
								<EditItem value={edit} isNew={edit.id === 'new'} cancelCallback={onCancel}/> :
								<WhetherTheUser role={[
									'ROLE_ADMIN',
									'ROLE_ADMIN_IS',
									'ROLE_MODERATOR',
									'ROLE_PROJECT_MANAGER',
									'ROLE_COORDINATOR',
									'ROLE_PRODUCER',
									'ROLE_CONTRACTOR',
								]} isCan={true}>
									<div className={styles.newBtn} onClick={onEdit({
										id: 'new',
										year: 0,
										name: '',
										status: '',
										nomination: ''
									})}>
										Добавить новое значение
										<PlusCircleOutlined />
									</div>
								</WhetherTheUser>
							}
						</div>
					)
				}}
			>
				{awards.data?.map((el) => (
					<Option key={el.value} value={el.value} data-name={el.label}>
						<div className={styles.option}>
							<Tooltip title={el.label}>
								<span className={styles.option__label}>
									{el.label}
								</span>
							</Tooltip>
							<WhetherTheUser role={[
								'ROLE_ADMIN',
								'ROLE_ADMIN_IS',
								'ROLE_MODERATOR',
								'ROLE_PROJECT_MANAGER',
								'ROLE_COORDINATOR',
								'ROLE_PRODUCER',
							]} isCan={true}>
								<div className={styles.buttons}>
									<EditOutlined
										className={classNames(styles.editBtn, styles.icon)}
										onClick={onEdit(el.code as AwardsTypes.Award)}
									/>
									<DeleteOutlined
										className={classNames(styles.deleteBtn, styles.icon)}
										onClick={onDelete(el.value)}
									/>
								</div>
							</WhetherTheUser>
						</div>
					</Option>
				))}
			</Select>
		</div>
	)
};

type Props = {
	value: State,
	isNew?: boolean
	cancelCallback: (event?: any) => void
}
function EditItem({value: {id, ...rest}, isNew = false, cancelCallback}: Props) {
	const put = useDispatch();
	const [state, setState] = useState(rest);
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: () => isNew ?
			AwardsApi.create(state) :
			AwardsApi.update(id, state),
		onSuccess: async () => {
			cancelCallback();
			await queryClient.invalidateQueries([AwardsApi.getAll.key]);
			put(NotificationOpen( 'success', `Награда ${isNew ? 'создана' : 'обновлена'}`))
		},
	})

	const disable = [
		state.name.length === 0,
		state.nomination?.length === 0,
		state.status?.length === 0,
		state.year === 0,
	].some(Boolean);

	const onChange = (name: keyof State) => (event: any) => {
		setState({...state, [name]: event.target.value})
	}
	const onSave = () => {
		if (!disable) {
			mutation.mutate()
		}
	}
	return (
		<Spin spinning={mutation.status === 'loading'}>
			<div className={styles.editFeel}>
				<DatePicker.YearPicker
					value={state.year ? moment(new Date().setFullYear(state.year)) : null}
					onChange={(_, dateString) => {
						setState({...state, year: Number(dateString)})
					}}
					placeholder={'Введите год'}
				/>
				<Input
					onChange={onChange('name')}
					value={state.name || ''}
					placeholder={'Введите название'}
				/>
				<Input
					onChange={onChange('nomination')}
					value={state.nomination || ''}
					placeholder={'Введите номинацию'}
				/>
				<Input
					onChange={onChange('status')}
					value={state.status || ''}
					placeholder={'Введите статус'}
				/>
				<div className={styles.buttons}>
					<CheckCircleOutlined
						className={classNames(styles.save, {
							disabled: disable,
						})}
						onClick={onSave}
					/>
					<CloseCircleOutlined className={styles.cancel} onClick={cancelCallback}/>
				</div>
			</div>
		</Spin>
	)
}

export default AwardComponent;