import {put} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	DeleteCategoryProjectErrorAction,
	DeleteCategoryProjectStartAction,
	DeleteCategoryProjectSuccessAction,
	GetHandbookStartAction,
} from 'store/options/actions/actions-handbook'
import {TStatusesHandbookFeels} from 'models/handbook'
import CategoryProjectService from 'services/categoryProjectService'

export function* deleteCategoryProject({ id }: ReturnType<typeof DeleteCategoryProjectStartAction>) {
	try {
		yield CategoryProjectService.deleteCategoryProjectRecord(id)
		yield put(DeleteCategoryProjectSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.categoryProject))
		yield put(NotificationOpen('success', 'Категория удалена'))
	} catch (error: any) {
		yield put(DeleteCategoryProjectErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить категорию'))
	}
}
