import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTus} from "use-tus";
import UploaderModal from "./uploader-modal";
import {
    AbortedLoad,
    DeleteFileStartAction,
    FinishLoad,
    SetFileIdUploadAction,
    SetFileUploadAction,
    StartLoad,
} from "store/uploader/actions";
import {selectForUploader} from "store/uploader/selectors";
import {NotificationOpen} from "store/notification/actions";
import {selectParams} from "store/selectors";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import CountProgress from "utils/countProgress";
import {UploadFile} from "store/uploader/reducer";
import {isSupported} from 'tus-js-client'
import CountChunk from "./count-chunk";
import useUploaderContext from "context/uploader/useUploaderContext";
import keycloak from "../../Keycloak";
import env from "../../shared/config/env";

const Uploader = () => {
    const {setProgress, resetProgress, uploaderState, setStatus} = useUploaderContext()
    const put = useDispatch();
    const {id: taskId} = useSelector(selectParams)
    const {entityId, fileId, file: fileStore, fileIsUpload, isOpen} = useSelector(selectForUploader)
    const { upload, setUpload, error, remove  } = useTus({autoStart: true});

    useEffect(() => {
        if (upload?.url) {
            const url = upload?.url.split('/')
            const urlId = url[url.length - 1];
            if (urlId && (urlId !== fileId)) put(SetFileIdUploadAction(urlId, taskId))
        }// eslint-disable-next-line
    }, [uploaderState.progress]);

    useEffect(() => {
        if (!isSupported && isOpen) {
            put(NotificationOpen('warning',
                '',
                'Этот браузер не поддерживает загрузку. Пожалуйста, используйте современный браузер'
            ))
        }// eslint-disable-next-line
    }, [isOpen]);


    useEffect(() => {
        if (error) {
            const message = CreateErrorMessage.responseUseTus(error)
            put(NotificationOpen('error', 'Ошибка', message))
            taskId && put(AbortedLoad(taskId))
        }
    }, [error, put, taskId])

    const handleSetUpload = useCallback((event: any) => {
        if (event.fileList?.length === 0) return;
        const length = event.fileList?.length;
        const file = event.fileList[length-1].originFileObj;
        if (!file && !entityId) return;
        const uploadFile = {
            length: file.size,
            offset: 0,
            originalFilename: file.name,
            isUploadInProgress: true
        } as UploadFile
        if (fileIsUpload && fileStore) {
            let error = 'Необходимо выбрать тот же файл что загрузили ранее';
            let isError = false;

            if (uploadFile.originalFilename !== fileStore.originalFilename) isError = true;
            else if ((uploadFile.length !== fileStore.length)) {
                error = 'Необходимо выбрать тот же файл что загрузили ранее. Не совпадает размер файла';
                isError = true;
            } else if (`${new Date(file.lastModified).toISOString()}` !== `${new Date(fileStore.originalFileModificationDate).toISOString()}`) {
                error = 'Необходимо выбрать тот же файл что загрузили ранее. Не совпадает дата последнего изменения файла'
                isError = true;
            }
            if (isError) {
                put(NotificationOpen('error', 'Ошибка', error))
                return;
            }
        }
        setProgress(CountProgress(uploadFile.offset, uploadFile.length))
        put(SetFileUploadAction(uploadFile))
        const metadata = {
            filename: file.name,
            modificationDate: `${new Date(file.lastModified).toISOString()}`,
            entityId: entityId as string,
            filetype: file.type,
        }
        if (env.mode !== 'prod') {
            console.groupCollapsed('metadata', metadata)
        }

        const url = `${env.API_ENDPOINT}/files/uploading`;
        setUpload(file, {
            endpoint: url,
            metadata: metadata,
            chunkSize: CountChunk(uploadFile.length),
            onBeforeRequest: async function (req) {
                let xhr = req.getUnderlyingObject();
                xhr.withCredentials = true;
                await keycloak.updateToken(15);
                const token = keycloak.token;

                if (token) {
                    req.setHeader('Authorization', `Bearer ${token}`)
                }
            },
            onShouldRetry: (error, retryAttempt, options) => {
                if (env.mode !== 'prod') {
                    console.log('retryAttempt', retryAttempt, error, options.headers)
                }
                return retryAttempt !== 2;
            },
            onProgress: (bytesSent, bytesTotal) => {
                const progress = CountProgress(bytesSent, bytesTotal)
                setProgress && setProgress(progress, progress === 100 ? 'finishLoad' : undefined)
            },
            onSuccess: () => {
                put(FinishLoad(taskId))
                setStatus && setStatus('finish')
            },
            removeFingerprintOnSuccess: true,
            storeFingerprintForResuming: false,
        });
        // eslint-disable-next-line
    },[setUpload, entityId, fileStore]);

    const handleStart = () => {
        if (!upload) return;
        upload.start();
        put(StartLoad(taskId))
    }

    const handleAbort = useCallback(() => {
        if (!upload) return;
        upload.abort();
        put(AbortedLoad(taskId))
    }, [put, taskId, upload]);

    const handleRemove = useCallback(() => {
        remove()
        put(DeleteFileStartAction(fileId, taskId))
        resetProgress()
    }, [fileId, put, remove, resetProgress, taskId]);
    

    return <UploaderModal
        handleSetUpload={handleSetUpload}
        handleStart={handleStart}
        handleAbort={handleAbort}
        handleRemove={handleRemove}
    />
};
export default Uploader;