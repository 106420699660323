import {delay, put, select} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {FetchCF} from "../store/main/actions";
import {TRole} from "models/user";
import {selectRole} from "store/auth/selectors";
import {GetProjectsSuccessAction, SetProjectFilter, SetProjectInfo} from "store/projects/actions";
import {AxiosResponse} from "axios";
import {TProjectsFilter} from "models/projects/filter";
import {selectProjectsFilter} from "store/projects/selectors";
import Api from "entities/project/api/project";
import {
	SearchContentProjectLib,
	SearchContentProjectModel,
	SearchContentProjectTypes
} from 'features/search-project-content';
import {GetContestsListSuccessAction, SetContentFilter, SetContentInfo} from "store/content/list/actions";
import {selectContentListFilter} from "store/content/list/selectors";
import {TContentFilter} from "models/content";
import CalendarService from "pages/calendar/service";
import {CalendarModel, CalendarTypes} from 'pages/calendar';
import {ProjectTypes} from "entities/project";
import ZodParser from "shared/services/zod-parser";

export function* getDataWorker({ typeContent }: ReturnType<typeof FetchCF>) {
	try {
		const role: TRole = yield select(selectRole);

		if (!role) {
			yield delay(500)
			yield put(FetchCF(typeContent))
		}

		const filterCS: SearchContentProjectTypes.Stores.TAllFilterValues = yield select(SearchContentProjectModel.selectors.filter(typeContent));
		if (typeContent === 'projects') {
			yield put(SetProjectInfo({isLoading: true, isLoaded: false}))
			const filterMain: TProjectsFilter = yield select(selectProjectsFilter);
			const {filterData, ...rest} = filterMain;

			const filter = {
				...rest,
				filterData: {
					...(!!filterData?.bulkAction?.length && { bulkAction: filterData.bulkAction}),
					...(!!filterData?.searchString && { searchString: filterData.searchString}),
					...SearchContentProjectLib.mapData(filterCS)
				}
			} as TProjectsFilter;

			const {data: projects}: AxiosResponse<ProjectTypes.ProjectsListResponse> = yield Api.getProjects(filter)
			ZodParser.parse(ProjectTypes.ProjectsListResponseSchema, projects,'getProjects')

			if (projects.page * projects.pageSize >= projects.totalCount && projects.totalCount !== 0) {
				yield put(SetProjectFilter({...filterMain, page: 1}))
			} else {
				yield put(GetProjectsSuccessAction(projects))
			}

		}

		if (typeContent === 'content') {
			yield put(SetContentInfo({isLoading: true}))
			const filterMain: TContentFilter = yield select(selectContentListFilter);
			const {filterData, ...rest} = filterMain;
			const isContractor = role === 'ROLE_CONTRACTOR';

			const filter = {
				...rest,
				filterData: {
					...(!!filterData?.searchString && { searchString: filterData.searchString}),
					...SearchContentProjectLib.mapData(filterCS),
					...(isContractor && {
						contentStatusCodes: ['REJECTED', 'APPROVED'],
					}),
				}
			} as TProjectsFilter;
			const {data: projects}: AxiosResponse<ProjectTypes.ProjectsListResponse> = yield Api.getProjects(filter)
			ZodParser.parse(ProjectTypes.ProjectsListResponseSchema, projects, 'getProjects')

			if (projects.page * projects.pageSize >= projects.totalCount && projects.totalCount !== 0) {
				yield put(SetContentFilter({...filterMain, page: 1}))
			} else {
				yield put(GetContestsListSuccessAction(projects))
			}
		}

		if (typeContent === 'calendar') {
			yield put(CalendarModel.actions.setCalendarValue({isLoading: true, error: false}))
			const filterMain: CalendarTypes.TCalendarState['filter'] = yield select(CalendarModel.select.filter);
			const {filterData, ...rest} = filterMain;

			const filter = {
				...rest,
				filterData: {
					...(!!filterData?.searchString && { searchString: filterData.searchString}),
					...SearchContentProjectLib.mapData(filterCS),
				}
			} as TProjectsFilter;
			const {data}: AxiosResponse<CalendarTypes.CalendarResponse> = yield CalendarService.search(filter)

			if (data.totalCount && data.page * data.pageSize >= data.totalCount && data.totalCount !== 0) {
				yield put(CalendarModel.actions.setCalendarValue({
					isLoading: false,
					filter: {...filterMain, totalCount: data.totalCount, page: 1},
					list: data.content
				}))
				yield put(FetchCF('calendar'))
			} else {
				yield put(CalendarModel.actions.setCalendarValue({
					isLoading: false,
					filter: {...filterMain, totalCount: data.totalCount},
					list: data.content
				}))
			}
		}
	} catch (error: any) {
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
	}
}
