import React from 'react';
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import env from "shared/config/env";

const queryClient = new QueryClient({defaultOptions: { queries: { refetchOnWindowFocus: false }}})

const WithQuery = (component: () => React.ReactNode) => () => {
	const isProduction = env.mode === 'prod';

	return (
		<QueryClientProvider client={queryClient}>
			{component()}
			{!isProduction && <ReactQueryDevtools initialIsOpen={false} />}
		</QueryClientProvider>);
};

export default WithQuery;
