import React from 'react';
import Tags from "./tags";
import TagsContent from "./tags-content";

export const ThematicsItem = () => {

    return (
        <div className="item-wrapper column">
            <Tags/>
            <TagsContent/>
        </div>
    )
}