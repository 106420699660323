export const ProjectReportingActionTypes = {
	CREATE_REPORTING_START: '[REPORTING] CREATE_REPORTING_START',
	CREATE_REPORTING_SUCCESS: '[REPORTING] CREATE_REPORTING_SUCCESS',
	CREATE_REPORTING_ERROR: '[REPORTING] CREATE_REPORTING_ERROR',

	GET_ALL_REPORTING_START: '[REPORTING] GET_ALL_REPORTING_START',
	GET_ALL_REPORTING_SUCCESS: '[REPORTING] GET_ALL_REPORTING_SUCCESS',
	GET_ALL_REPORTING_ERROR: '[REPORTING] GET_ALL_REPORTING_ERROR',

	GET_REPORTS_TEMPLATES_START: '[REPORTING] GET_REPORTS_TEMPLATES_START',
	GET_REPORTS_TEMPLATES_SUCCESS: '[REPORTING] GET_REPORTS_TEMPLATES_SUCCESS',
	GET_REPORTS_TEMPLATES_ERROR: '[REPORTING] GET_REPORTS_TEMPLATES_ERROR',

	GET_AVAILABLE_REPORTS_START: '[REPORTING] GET_AVAILABLE_REPORTS_START',
	GET_AVAILABLE_REPORTS_SUCCESS: '[REPORTING] GET_AVAILABLE_REPORTS_SUCCESS',
	GET_AVAILABLE_REPORTS_ERROR: '[REPORTING] GET_AVAILABLE_REPORTS_ERROR',

	DOWNLOAD_REPORTING_START: '[REPORTING] DOWNLOAD_REPORTING_START',
	DOWNLOAD_REPORTING_SUCCESS: '[REPORTING] DOWNLOAD_REPORTING_SUCCESS',
	DOWNLOAD_REPORTING_ERROR: '[REPORTING] DOWNLOAD_REPORTING_ERROR',

	DOWNLOAD_FILE_ARCHIVE_START: '[REPORTING] DOWNLOAD_FILE_ARCHIVE_START',
	DOWNLOAD_FILE_ARCHIVE_SUCCESS: '[REPORTING] DOWNLOAD_FILE_ARCHIVE_SUCCESS',
	DOWNLOAD_FILE_ARCHIVE_ERROR: '[REPORTING] DOWNLOAD_FILE_ARCHIVE_ERROR',

	SET_REPORTING_FILTER: '[REPORTING] SET_REPORTING_FILTER',
	SET_REPORTING_CLEAR_FILTER: '[REPORTING] SET_REPORTING_CLEAR_FILTER',
} as const
