import React, {useMemo} from 'react';
import styles from './styles.module.less'
//antd
import Checkbox from "antd/es/checkbox";
import {Spin} from "antd";
//hooks
//components
import ReloadList from "components/forms/components/reload-list/reload-list";
import {SelectComponent} from "components/formic-control";
//
//others
import {useFormikContext} from "formik";
import {ProjectFormDto} from "../../../lib";
import {FilmClusterApi, FilmClusterLib} from 'entities/film-cluster'
import {Query} from 'processes/query'

function filingName(key: string): string {
    return `stepFifth.${key}.value`
}
const FilmClusters = () => {

    const {values, errors, setFieldValue} = useFormikContext<ProjectFormDto>();
    const { isLoading, data: options } = FilmClusterLib.useGetFilmClustersOptions()

    const status = values.stepFirst.status.value
    const form = values.stepFifth
    const formErrors = errors.stepFifth

    const optionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);

    const filteredOptions = options?.filter(option => {
        return !optionsDisabled?.some(value => value === option.value);
    })

    const handlerUpdate = () => Query.invalidate([FilmClusterApi.getFilmClusters.key])

    const selectCheckboxAll = (e: any) => {
        const checked: boolean = e.target.checked
        if (checked) setFieldValue(filingName('filmClusters'), filteredOptions)
        else setFieldValue(filingName('filmClusters'), [])
    }

    return (
        <Spin spinning={isLoading || !options}>
            <ReloadList className="ident" handlerUpdateList={handlerUpdate} />
            <SelectComponent
                name={filingName('filmClusters')}
                placeholder="Выберите кинокластер/кинокластеры"
                options={options}
                disabledOptionsIds={optionsDisabled}
                multi={true}
                showError={false}
                disabled={form.filmClusters.isDisabled}
                errorMessage={formErrors?.filmClusters as string}
                maxTagCount={'responsive'}
                dropdownRender={
                    <Checkbox
                        className={styles.checkboxInSelect}
                        onChange={selectCheckboxAll}
                        checked={options?.length ? form.filmClusters?.value?.length === filteredOptions?.length : false}
                        disabled={form.filmClusters.isDisabled}
                    >
                        <span className={styles.checkboxText}>Выбрать все</span>
                    </Checkbox>
                }
            />
        </Spin>
    );
};

export default FilmClusters;