import {SessionsTypeActionTypes} from './action-types'
import {T_ID_NAME_CODE_HIDDEN} from "../../models/common";

/**
 * получение списка sessions-type
 */
	export const GetSessionsTypeStartAction = () => ({
	type: SessionsTypeActionTypes.GET_SESSIONS_TYPE_START
})
export const GetSessionsTypeSuccessAction = (data: T_ID_NAME_CODE_HIDDEN[]) => ({
	type: SessionsTypeActionTypes.GET_SESSIONS_TYPE_SUCCESS,
	data,
})
export const GetSessionsTypeErrorAction = (error: any) => ({
	type: SessionsTypeActionTypes.GET_SESSIONS_TYPE_ERROR,
	error,
})
