import { FunctionalityInfoType } from '../types'

export const projectReporting: FunctionalityInfoType = [
	{
		LevelGroupName: 'Работа в окне формирования отчетности',
		LevelGroupData: [
			{
				name: 'Переход в модальное окно формирования отчетности',
				text: 'Нажмите для перехода в окно формирования отчетности по проекту',
				screenshots: ['/images/screenshots/project-reporting/cards.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Формирование отчета',
				text: 'Для формирования отчета:',
				points: [
					'Заполните поля формы в окне формирования отчетности',
					'Нажмите "Сформировать"',
					'Дождитесь окончания формирования отчета: статус формирования отчета "Created"',
				],
				screenshots: ['/images/screenshots/project-reporting/modal.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Скачивание готового отчета',
				text: 'Нажмите на кнопку скачивания по окончании формирования отчета',
				screenshots: ['/images/screenshots/project-reporting/created.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход на следующую страницу в форме получения отчетов',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
