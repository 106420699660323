import {axiosRequest} from "shared/config/axios";
import {UserTypes} from 'entities/user'

export class UserActionTypeController {
    static async create(data: Omit<UserTypes.UserActionCreate, 'id'>) {
        await axiosRequest({
            method: "POST",
            url: '/users/actions/types',
            data
        })
    }
    static async update({data, id}: {data: Omit<UserTypes.UserActionCreate, 'id'>, id: string}) {
        await axiosRequest({
            method: "PUT",
            url: `/users/actions/types/${id}`,
            data
        })
    }
    static async delete(id: string) {
        await axiosRequest({
            method: "DELETE",
            url: `/users/actions/types/${id}`,
        })
    }
    static async download(data: string[]){
        return axiosRequest({
            method: 'POST',
            url: `/users/actions/types/export`,
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }
}