import React, {FC, useReducer} from "react";
import {initialState, UploaderContext} from "./uploaderContext";
import UploaderReducer from "./uploaderReducer";


export const UploaderState: FC = ({children}) => {
    const [store, dispatch] = useReducer(UploaderReducer, initialState)
    return (
        <UploaderContext.Provider value={{ store, dispatch }}>
            {children}
        </UploaderContext.Provider>
    )
}