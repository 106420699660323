import React, {FC} from 'react';
import {ProjectTypes} from 'entities/project';
import {TaskTypes} from 'entities/task';
import useBulkAction from 'pages/tasks/task-page/useBulkAction';
import {useDispatch, useSelector} from 'react-redux';
import {validations} from './validations';
import {selectUser} from 'store/auth/selectors';
import styles from './style.module.less';
import {Tooltip} from 'antd';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	FileSearchOutlined,
	MinusCircleOutlined,
	RedoOutlined,
	SendOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import moment from 'moment';
import {
	InterimVoteTaskContentStartAction, VoteTaskContentChange,
	VoteTaskContentChangeStatus,
	VoteTaskContentStartAction
} from 'store/tasks/actions/content';
import {ModalOpenAction} from 'store/modals/actions';
import {TModalVoteUserData} from 'components/modals/modal-vote-user/modal-vote-user';
import {UploadFile} from 'antd/es/upload/interface';
import {
	InterimVoteTaskReportStartAction, VoteTaskReportChange,
	VoteTaskReportChangeStatus,
	VoteTaskReportStartAction
} from 'store/tasks/actions/report';
import {useLocation} from "react-router-dom";
import {useDeleteInterimVote, useDeleteInterimVoteWithoutSuccess} from "../../lib";

export type Report = {
	type: TaskTypes.TypesTask.REPORT;
	task: TaskTypes.PostingRecords |
		TaskTypes.ReachRecords |
		TaskTypes.CopyrightRecords |
		TaskTypes.EstimateRecords;
	reportType: TaskTypes.ReportType;
}
export type Content = {
	type: TaskTypes.TypesTask.CONTENT;
	task: TaskTypes.ContentRecords;
	reportType: never;
}

type Props = {
	title: string;
	recordId: string;
	typeTask: Report | Content;
	votes: TaskTypes.Vote[];
	vote: TaskTypes.NewVote | null;
	taskStatus: TaskTypes.TasksStatus | null;
	recordStatus: TaskTypes.TasksRecordStatus;
	projectStatus: ProjectTypes.ProjectStatus | null;
	isReplaced: boolean;
	lastTaskRecordWithVotesResponse: TaskTypes.Vote[];
	archival?: boolean
};
const Vote: FC<Props> = ({
	title,
	recordId,
	typeTask,
	taskStatus,
	projectStatus,
	recordStatus,
	votes,
	vote,
 	isReplaced,
    lastTaskRecordWithVotesResponse,
 	archival
}) => {
	const put = useDispatch();
	const user = useSelector(selectUser);
	const {bulkAction } = useBulkAction(typeTask.type);
	const location = useLocation()
	const isBulkActonApprove = bulkAction === 'SEND_VOTES_APPROVED';
	const isBulkActonReject = bulkAction === 'SEND_VOTES_REJECTED';
	const pathname = location.pathname

	const page = pathname.split('/')[1]
	const {mutate: deleteInterimVote } = useDeleteInterimVote(typeTask)
	const {mutate: deleteInterimVoteWithoutSuccess } = useDeleteInterimVoteWithoutSuccess(typeTask)
	const validate = user && validations({
		user,
		votes,
		newVote: vote,
		projectStatus,
		hasComments: votes?.some((el) => el.comment !== null),
		recordStatus,
	});

	const handlerVoteFunc = (isApproved: boolean) => () => {
		if (page === 'tasks') return
		const data: TaskTypes.NewVote = {
			vote: {
				comment: vote ? vote.vote.comment : null,
				isApproved: isApproved,
				isInterimApproved: isApproved,
				recordId,
			},
			files: vote?.files || [],
		};

		if (typeTask.type === TaskTypes.TypesTask.CONTENT) {
			put(VoteTaskContentChangeStatus(data));
			if (!archival && isApproved) put(InterimVoteTaskContentStartAction(data))
		} else if (typeTask.type === TaskTypes.TypesTask.REPORT) {
			put(VoteTaskReportChangeStatus(data));
			if (!archival && isApproved) put(InterimVoteTaskReportStartAction(data, typeTask.reportType))
		}

		if (!data.vote.isApproved) {

			const modalData = {
				archival: archival,
				title: title,
				type: typeTask.type === TaskTypes.TypesTask.CONTENT ? 'CONTENT' : 'REPORT',
				comment: data.vote.comment,
				recordId: data.vote.recordId,
				isApproved: data.vote.isApproved,
				isInterimApproved: data.vote.isInterimApproved,
			...(typeTask.type === TaskTypes.TypesTask.REPORT && {files: data.files}),
			...(typeTask.type === TaskTypes.TypesTask.REPORT && {reportType: typeTask.reportType})
			};

			put(ModalOpenAction('vote-user', modalData));
		}
	};

	const voteTaskHandler = () => {
		if (page === 'tasks') return

		if (typeTask.type === TaskTypes.TypesTask.CONTENT && vote) {
			put(VoteTaskContentStartAction(vote))
		}
		if (typeTask.type === TaskTypes.TypesTask.REPORT && vote) {
			put(VoteTaskReportStartAction(vote, typeTask.reportType))
		}
	}
	const handlerChangeVote = () => {
		if (page === 'tasks') return
		if (typeTask.type === TaskTypes.TypesTask.CONTENT) {
			put(VoteTaskContentChange(recordId))
		} else {
			put(VoteTaskReportChange(recordId))
		}
		if (!archival) deleteInterimVote()
	}


	const openModalList = () => {
		put(ModalOpenAction('vote', {
			title: title,
			votes: votes,
			lastTaskRecordWithVotesResponse,
			recordStatus,
			status: taskStatus
		}))
	}

	const openModal = () => {
		if (!archival) deleteInterimVoteWithoutSuccess()
		if (vote) put(ModalOpenAction('vote-user', {
			archival: archival,
			title: title,
			type: typeTask.type,
			comment: vote.vote.comment,
			files: vote.files as unknown as UploadFile[],
			recordId: typeTask.task.id,
			isApproved: vote.vote.isApproved,
			isInterimApproved: vote.vote.isInterimApproved,
			...(typeTask.type === TaskTypes.TypesTask.REPORT && {reportType: typeTask.reportType})
		} as TModalVoteUserData ))
	}

	const getTooltip = (statusCondition: boolean, statusText: string) => {
		const title = statusCondition ? statusText : '';

		if (recordStatus === 'APPROVED' || recordStatus === 'REJECTED') {
			const sorted = votes?.slice().sort((voteOne, voteTwo) => {
				if (typeof voteOne.dateTime === 'string' && typeof voteTwo.dateTime === 'string') {
					return new Date(voteTwo.dateTime).getTime() - new Date(voteOne.dateTime).getTime()
				}
				return 0;
			})
			const latestDateTime: string | null = sorted?.[0]?.dateTime
			const formattedDateTime = latestDateTime ? moment(latestDateTime).format('DD.MM.yy') : ''

			const str = formattedDateTime && isReplaced && recordStatus === 'REJECTED'
				? formattedDateTime + ' и находится на исправлении' : formattedDateTime

			return validate?.handlers ? title : title + str;
		} else {
			return title
		}
	}

	return (
		<div className={styles.VoteCell}>
			<div data-btn='left' className={styles.left}>
				<div className={styles.icon__group}>
					<div className={styles.btn}>
						{isBulkActonApprove && (
							<Tooltip title={'Данные приняты'}>
								<CheckCircleOutlined className={classNames(styles.icon, 'green')} />
							</Tooltip>
						)}
						{isBulkActonReject && (
							<Tooltip title={'Данные отклонены'}>
								<CloseCircleOutlined className={classNames(styles.icon, 'red')} />
							</Tooltip>
						)}

						{validate?.edit === 'disabled' && (
							<Tooltip title={'Редактируется'}>
								<MinusCircleOutlined
									className={classNames(styles.icon, validate.edit)}
								/>
							</Tooltip>
						)}

						{validate?.approved === 'view' && !isBulkActonApprove && !isBulkActonReject && (
							<Tooltip title={getTooltip(validate?.approved === 'view', 'Данные согласованы ')}>
								<CheckCircleOutlined
									className={classNames(styles.icon, 'green')}
									onClick={validate.handlers ? handlerVoteFunc(true) : undefined}
								/>
							</Tooltip>
						)}

						{validate?.rejected === 'view' && !isBulkActonApprove && !isBulkActonReject && (
							<Tooltip title={getTooltip(validate?.rejected === 'view', 'Данные отклонены ')}>
								<CloseCircleOutlined
									className={classNames(styles.icon, 'red')}
									onClick={validate.handlers ? handlerVoteFunc(false) : undefined}
								/>
							</Tooltip>
						)}

						{((validate?.warning === 'view') || (validate?.warning === 'disabled')) && (
							<Tooltip title={'На согласовании'}>
								<WarningOutlined className={classNames(styles.icon, validate.warning)}/>
							</Tooltip>
						)}

						{validate?.changeVote === 'view' && (
							<Tooltip title="Изменить выбор?">
								<RedoOutlined className='icon change' onClick={handlerChangeVote}/>
							</Tooltip>
						)}
					</div>
					{validate?.votesInfo !== 'hidden' && !!votes?.length && (
						<span className={styles.count} onClick={openModalList}>
								проверено {votes.filter((el) => el.isApproved !== null).length}/{votes.length}
							</span>
					)}
					{validate?.votesInfo !== 'hidden' && !!lastTaskRecordWithVotesResponse?.length && !votes?.length && (
						<span className={styles.count} onClick={openModalList}>
								проверено {lastTaskRecordWithVotesResponse.filter((el) => el.isApproved !== null).length}/{lastTaskRecordWithVotesResponse.length}
							</span>
					)}
				</div>
			</div>
			<div data-btn='right' className={styles.right}>
				<Tooltip title={'Комментарий'}>
					{validate?.messageList !== 'hidden' && !vote && (
						<div>
							<FileSearchOutlined
								className={classNames(styles.icon, validate?.messageList)}
								onClick={validate?.messageList !== 'disabled' ? openModalList : undefined}
							/>
						</div>
					)}
					{validate?.message !== 'hidden' && (
						<div>
							<FileSearchOutlined
								className={classNames(
									styles.icon,
									validate?.message,
									vote?.vote?.isApproved === true || !!vote?.vote?.comment ? 'yellow' : 'red'
								)}
								onClick={validate?.message === 'view' ? openModal: undefined}
							/>
						</div>
					)}
				</Tooltip>
				{validate?.send !== 'hidden' && (
					<div className={classNames(styles.icon, validate?.send)}>
						<Tooltip title='Отправить'>
							<SendOutlined onClick={validate?.send !== 'disabled' ? voteTaskHandler : undefined}/>
						</Tooltip>
					</div>
				)}
			</div>
		</div>
	)
};

export default Vote;