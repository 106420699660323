import React, {FC} from 'react';
import {ErrorMessage, Field, useFormikContext} from "formik";
import {FieldAttributes} from "formik/dist/Field";
import TextError from "./text-error";
import ReactQuill from "react-quill";
import FilterHTML from "utils/filterHTML";
import {IFeels} from "models/feels";
import {Tooltip} from "antd";


type TTextAreaQuill = {
    handler?: any
    disabled?: boolean
    limit?: number
} & IFeels

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        ["clean"],
    ],
}

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "color"
]

const TextAreaQuill: FC<TTextAreaQuill> = ({
   label,
   name,
   placeholder,
   handler,
   disabled,
   limit,
   errorMessage
}) => {
    const {setFieldValue, setTouched, touched} = useFormikContext();
    const handleQuillField = (content: string) => {
        if (content === "<p><br></p>") {
            setFieldValue(name, '');
            return
        }
        setFieldValue(name, content);
    };

    return (
        <div className='form-group form-group__quill'>
            {label && (
                <label htmlFor={name}>{label}</label>
            )}
            <Field className="form-control" name={name}>
                {(el: FieldAttributes<any>) => {
                    const value = el.field.value;
                    const isError = errorMessage ? errorMessage : el.meta.error
                    const isTouched = el.form.touched[name] ? el.form.touched[name] : el.meta.touched;
                    return (
                        <div className={`quill-wrapper ${isTouched && isError ? 'error' : ''}`}>
                            <ReactQuill
                                onBlur={_event => {
                                    setTouched({...el.form.touched, [name]: true})
                                }}
                                readOnly={disabled}
                                placeholder={placeholder}
                                value={value}
                                onChange={handler ? handler : handleQuillField}
                                theme="snow"
                                modules={modules}
                                formats={formats}
                            />
                            {limit && (
                                <div className={`limit ${value?.length > limit && 'error'}`}>
                                    <Tooltip title={`Фактический размер: теги + текст ${value?.length}`}>
                                        {value ? FilterHTML(value)?.length : 0} / {limit} {value?.length > limit && `Фактический размер: теги + текст ${value.length} превысил лимит в ${limit} символов`}
                                    </Tooltip>
                                </div>

                            )}
                            {el.meta.error && (
                                <ErrorMessage name={name} component={TextError}/>
                            )}
                        </div>
                    )
                }}
            </Field>
            {/*// @ts-ignore*/}
            {errorMessage && touched[name] && <div className="invalid-feel">{errorMessage}</div>}
        </div>
    )
};
export default TextAreaQuill;