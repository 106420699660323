import {
    GetReachStatisticErrorAction,
    GetReachStatisticStartAction,
    GetReachStatisticSuccessAction
} from "store/tasks/actions/report";
import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {TasksReportReachService} from "services/tasksReportService";
import {AxiosResponse} from "axios";
import {TReachStatistic} from "models/task/task-report";


export function* getReachStatistic ({id,reachDateTime}: ReturnType<typeof GetReachStatisticStartAction>) {
    try {

        const { data } : AxiosResponse<TReachStatistic[]> = yield TasksReportReachService.getReachStatistic(id,reachDateTime)
        yield put(GetReachStatisticSuccessAction(data))
    } catch (error: any) {
        yield put(GetReachStatisticErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen(
            'error',
            'Ошибка',
            message ? message : 'Не получить список'
        ))
    }
}