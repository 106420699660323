import React from 'react';
import styles from './styles.module.less';
import { StarOutlined } from '@ant-design/icons';
import { ProjectTypes, ProjectApi } from 'entities/project';
import classNames from 'classnames';
import { useMutation } from 'react-query';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { GetProjectsStartAction } from 'store/projects/actions';
const FavoriteProject = (
	isFavoriteProject: ProjectTypes.Project['isFavoriteProject'],
	project: ProjectTypes.ProjectsList
) => {
	const put = useDispatch();
	const {mutate, isLoading} = useMutation({
		mutationFn: (isFavoriteProject: boolean) => ProjectApi.Project.changeFavoriteProject(project.id, isFavoriteProject),
		onSuccess: () => {
			put(GetProjectsStartAction())
		}
	})
	return (
		<Spin spinning={isLoading}>
			<div className={classNames(styles.container, {isFavoriteProject})} onClick={(event) => {
				event.stopPropagation();
				mutate(!isFavoriteProject);
			}}>
				<StarOutlined />
			</div>
		</Spin>

	)
};

export default FavoriteProject;