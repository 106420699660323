import {ReactKeycloakProvider} from '@react-keycloak/web';
import React from 'react';
import keycloak from "../../Keycloak";
import {AuthClientEvent} from "@react-keycloak/core/lib/types";
import {PUBLIC_PATH} from "routing/names";
import {StoreAction} from 'processes/store-actions';
import {GetCurrentUser} from "store/auth/actions";
import {TLogsAction} from "models/logs";
import $api from "../../http";

async function LoginAction() {
    const data: TLogsAction = {
        actionTypeCode: "LOGIN",
        dateTime: new Date(),
        actionParameters: undefined,
    }
    $api.post<any>('/users/actions', [data])
        .catch((e: unknown) => {
            console.error(e);
        })
}

const eventLogger = (eventType: AuthClientEvent, error: any) => {
    console.groupCollapsed('eventLogger')
    console.log('eventType', eventType)
    console.log('error', error)
    console.groupEnd()

    if (eventType === 'onAuthSuccess') {
        LoginAction()
    }

    if (eventType === 'onAuthRefreshSuccess') {
        StoreAction.put(GetCurrentUser)
    }

    if (eventType === 'onAuthError' || eventType === 'onInitError') {
        const path = window.location.pathname;
        if (path !== '/error') {
            window.location.replace(PUBLIC_PATH.ERROR)
        }

    }
}

const WithKeycloak = (component: () => React.ReactNode) => () => {

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            onEvent={eventLogger}
        >
            {component()}
        </ReactKeycloakProvider>
    )
}

export default WithKeycloak;
