import {TTaskRecordsFilter} from "models/task/task-list";
import {InferValueTypes} from "models/common";
import {Logout} from "../../auth/reducer";
import * as actions from "../actions/records";
import {TasksRecordsActionTypes} from "../action-types/task-records";
import {ErrorActionState, StartActionState, SuccessActionState} from "utils/reducerHalper";
import {ReportType} from "models/task/types";
import {TaskTypes} from 'entities/task';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;
export type TTasksRecordsState = {
    tasks: TaskTypes.ContentRecords[] |
      TaskTypes.PostingRecords[] |
      TaskTypes.EstimateRecords[] |
      TaskTypes.ReachRecords[] |
      TaskTypes.CopyrightRecords[] |
      null
    filter: TTaskRecordsFilter
    typeTask: { id: string, code: 'REPORT' | 'CONTENT' } | null,
    typeReport: { id: string, code: ReportType } | null
    rowsIds: string[]
    isLoading: boolean
    error: any
    bulkAction?: string
}

export const initialFilter =  {
    page: 1,
    pageSize: 50,
    filterData: {
        searchString: '',
        archival: null
    }
}

export const initialState: TTasksRecordsState = {
    tasks: null,
    filter: initialFilter,
    typeReport: null,
    typeTask: { id: '', code: 'CONTENT'},
    rowsIds: [],
    isLoading: false,
    error: null
}
export default function tasksReducer(state: TTasksRecordsState = initialState, action: ActionTypes | Logout): TTasksRecordsState {
    switch (action.type) {
        case TasksRecordsActionTypes.SET_TASK_FILTER || TasksRecordsActionTypes.SET_TASK_SEARCH_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filter
                },
                typeTask: action.typeTask ? action.typeTask : state.typeTask,
                typeReport: action.typeReport ?
                    action.typeReport :
                    action.typeTask && action.typeTask.code === 'CONTENT' ? null :
                        state.typeReport,
            }
        case TasksRecordsActionTypes.SET_TASK_SEARCH_FILTER:
            return {
                ...state,
                filter: action.filter,
            }
        case TasksRecordsActionTypes.SET_BULK_ACTION:
            return {
                ...state,
                bulkAction: action.bulkAction
            }
        case TasksRecordsActionTypes.SET_ROWS_IDS:
            return {
                ...state,
                rowsIds: action.rows,
            }
        case TasksRecordsActionTypes.CLEAR_TASK_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    filterData: {
                        searchString: '',
                        secondLevelTagIds: [],
                        firstLevelTagIds: [],
                        thirdLevelTagIds: [],
                        projectId: undefined,
                        regionAboutIds:[],
                        regionForIds:[],
                        isOriginal: undefined,
                        isMasterFile: undefined
                    }
                },
                typeReport: null,
                typeTask: {id: '', code: 'CONTENT'},
                tasks: null
            }
        case TasksRecordsActionTypes.RESET_TASK_FILTER:
            return {
                ...state,
                filter: {
                    ...action.filter,
                    page: 1,
                    pageSize: 50
                },
                typeTask: action.typeTask,
                typeReport: action.typeReport,
                tasks: null
            }
        case TasksRecordsActionTypes.GET_TASKS_STAT:
            return {...StartActionState(state), tasks: state.tasks}
        case TasksRecordsActionTypes.GET_TASKS_SUCCESS:
            const {content, page, ...filters} = action.data;
            return {
                ...SuccessActionState(state),
                tasks: content,
                filter: {
                    ...state.filter,
                    page: page + 1,
                    ...filters,
                }
            }
        case TasksRecordsActionTypes.GET_TASKS_ERROR:
            return ErrorActionState(state, action);
        default:
            return state;
    }
}