import {TApplicationState} from "store/aplication-state";
import {createSelector} from "reselect";
import {TypesCode} from "../../types";

const getUserFiltersState = (state: TApplicationState) => state.feature.userFilters;

export const filter = (filter: TypesCode) => createSelector(
    getUserFiltersState,
    (state) => state.filters[filter])
export const activeFilterId = (filter: TypesCode) => createSelector(
    getUserFiltersState,
    (state) => state.filters[filter]?.activeFilterId)
export const filterName = (filter: TypesCode) => createSelector(
    getUserFiltersState,
    (state) => state.filters[filter]?.filterName || '')
export const types = createSelector(
    getUserFiltersState,
    (state) => state.types)
export const filters = createSelector(
    getUserFiltersState,
    (state) => state.filters)

export const status = createSelector(
    getUserFiltersState,
    (state) => ({
        isLoading: state.isLoading,
        error: state.error
    }))