import {put} from "redux-saga/effects";
import {
    GetContentTaskRecordErrorAction,
    GetContentTaskRecordStartAction,
    GetContentTaskRecordSuccessAction
} from "store/tasks/actions/content";
import TasksContentService from "services/tasksContentService";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {AxiosResponse} from "axios";
import {T_ID_NAME} from "models/common";


export function* getContentTaskRecord({projectId}: ReturnType<typeof GetContentTaskRecordStartAction>) {
    try {
        const {data}: AxiosResponse<T_ID_NAME[]> = yield TasksContentService.getContentTaskRecord(projectId)
        yield put(GetContentTaskRecordSuccessAction(data))
    } catch (error: any) {
        yield put(GetContentTaskRecordErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить список одобренных записей задач на контент текущего пользователя по проекту'))
    }
}
