import {put} from 'redux-saga/effects'
import HandbookService from "services/handbookService";
import {
    BulkContentDeliveryActionsErrorAction,
    BulkContentDeliveryActionsStartAction,
    BulkContentDeliveryActionsSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";

export function* bulkContentDeliveryActions({ userArr, action }: ReturnType<typeof BulkContentDeliveryActionsStartAction>) {
	try {
		yield HandbookService.bulkContentDeliveryActions(userArr, action)

		yield put(BulkContentDeliveryActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.contentDelivery))
		yield put(NotificationOpen('success', 'Обновлено успешно'))

	} catch (error: any) {
		yield put(BulkContentDeliveryActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}

}
