import {put, select} from "redux-saga/effects";

import {TTaskResponse} from "../../../models/task";
import {selectTask} from "../../../store/tasks/selectors/selectors-task";
import {
    GetTaskReportStartAction,
    SetTaskReportFilter
} from "../../../store/tasks/actions/report";


export function* setTasksPostingFilter({typeTask}: ReturnType<typeof SetTaskReportFilter>) {
    try {
        const task: TTaskResponse = yield select(selectTask);
        if (task) yield put(GetTaskReportStartAction(task.id, typeTask))
    } catch (e: any) {}
}