import {TFamousPeople} from "models/projects";
import {TDefault} from "./project-form.dto";
import {TOption} from "models/projects/project-form";
import {T_ID_NAME, T_ID_NAME_CODE_HIDDEN} from 'models/common';
import {TTag, TTagSecondLevel} from "models/tags";
import {ProjectTypes} from "entities/project";
import {TInfoProject} from "../../ui/form-container";
import {TRole} from 'models/user';
import {FamousPeopleTypes} from 'entities/famous-people'

export type TOptionAudienceAge = {
    label: string | number | undefined
    value: string | undefined
}

export class StepFifthDto {
    isNationalProject: TDefault<boolean | null>
    licenseDate:  TDefault<string | Date | null>
    targetAudienceAgeFrom: TDefault<TOptionAudienceAge | null>
    targetAudienceAgeTo: TDefault<TOptionAudienceAge| null>
    famousPeople: TDefault<TFamousPeople[] | []>
    contentLocationRegions: TDefault<TOption[] | []>
    filmClusters: TDefault<TOption[] | []>
    projectDataCheckedDatetime: TDefault<string | Date | null> & {code?: boolean}
    firstNationalLevelTags: TDefault<TOption[] | []>
    secondNationalLevelTags: TDefault<TOption[] | []>
    firstLevelNationalAssignmentTags: TDefault<TOption[] | []>
    secondLevelNationalAssignmentTags: TDefault<TOption[] | []>
    categoryTypes: TDefault<TOption[] | []>
    genreTypes: TDefault<TOption[] | []>
    awards: TDefault<string[] | []>

    info?: TInfoProject

    constructor(project?: ProjectTypes.Project | null) {
        this.isNationalProject = project ? {value: project.isNationalProject} : {value: false};
        this.licenseDate = project ? {value: project.licenseDate} : {value: ''};
        this.targetAudienceAgeFrom =  project ? {value: new AudienceAgeDto(project.targetAudienceAgeFrom) } : {value: new AudienceAgeDto(null)};
        this.targetAudienceAgeTo =  project ? {value: new AudienceAgeDto(project.targetAudienceAgeTo) } : {value: new AudienceAgeDto(null)};
        this.famousPeople =  project ? {value: project?.famousPeople?.map((el) => new FamousPeopleDto(el))} : {value: []}
        this.contentLocationRegions = project?.contentLocationRegions?.length ? {value: project?.contentLocationRegions?.map((el) => new LocationRegionsDto(el))} : {value: []}
        this.filmClusters = project?.filmClusters?.length ? {value: project?.filmClusters?.map((el) => new OptionDto(el))} : {value: []}
        this.projectDataCheckedDatetime = project ? {value: project.projectDataCheckedDatetime, code: Boolean(project.projectDataCheckedDatetime)} : {value: '', code: false};
        this.firstNationalLevelTags = project?.firstLevelNationalTags?.length ? {value: project.firstLevelNationalTags.map((el) => new TagsDto(el)),} : {value: []}
        this.secondNationalLevelTags = project?.secondLevelNationalTags?.length ? {value: project.secondLevelNationalTags.map((el) => new TagsDto(el))} : {value: []}
        this.firstLevelNationalAssignmentTags = project?.firstLevelNationalAssignmentTags?.length ? {value: project.firstLevelNationalAssignmentTags.map((el) => new TagsDto(el))} : {value: []}
        this.secondLevelNationalAssignmentTags = project?.secondLevelNationalAssignmentTags?.length ? {value: project.secondLevelNationalAssignmentTags.map((el) => new TagsDto(el))} : {value: []}
        this.categoryTypes = project?.categoryTypes?.length ? {value: project?.categoryTypes?.map((el) => new OptionDto(el))} : {value: []}
        this.genreTypes = project?.genreTypes?.length ? {value: project?.genreTypes?.map((el) => new OptionDto(el))} : {value: []}
        this.awards = !!project?.awards?.length ? {value: project.awards.map((el) => el.id)} : {value: []}
    }

    validate(info: TInfoProject) {
        this.info = info;
        for (const key in this) {
            // @ts-ignore
            if (this[key]) this[key].isDisabled = validate(key, info)
        }
        return this;
    }
    validateTargetAudienceAgeTo() {
        if (!this.targetAudienceAgeFrom.value) {
            this.targetAudienceAgeTo = {value: null, isDisabled: true}
        } else {
            this.targetAudienceAgeTo = {
                value: this.targetAudienceAgeTo.value,
                isDisabled: this.targetAudienceAgeTo.isDisabled ? this.targetAudienceAgeTo.isDisabled : false
            }
        }
        return this;
    }
    validateNationalTags() {
        if (!this.isNationalProject.value) {
            this.firstNationalLevelTags = {value: [], isDisabled: true}
            this.secondNationalLevelTags = {value: [], isDisabled: true}
            this.firstLevelNationalAssignmentTags = {value: [], isDisabled: true}
            this.secondLevelNationalAssignmentTags = {value: [], isDisabled: true}
        } else {
            this.firstNationalLevelTags = {
                value: this.firstNationalLevelTags.value,
                isDisabled: this.firstNationalLevelTags.isDisabled  ? this.firstNationalLevelTags.isDisabled : false
            }
            this.secondNationalLevelTags = {
                value: this.secondNationalLevelTags.value,
                isDisabled: this.secondNationalLevelTags.isDisabled ? this.secondNationalLevelTags.isDisabled : false
            }
            this.firstLevelNationalAssignmentTags = {
                value: this.firstLevelNationalAssignmentTags.value,
                isDisabled: this.firstLevelNationalAssignmentTags.isDisabled  ? this.firstLevelNationalAssignmentTags.isDisabled : false
            }
            this.secondLevelNationalAssignmentTags = {
                value: this.secondLevelNationalAssignmentTags.value,
                isDisabled: this.secondLevelNationalAssignmentTags.isDisabled  ? this.secondLevelNationalAssignmentTags.isDisabled : false
            }
        }
        return this;
    }
}

class FamousPeopleDto implements TFamousPeople {
    famousPerson;
    rank;

    constructor(el: FamousPeopleTypes.FamousPeopleShort) {
        this.famousPerson = {value: el?.famousPerson?.id, label: el?.famousPerson?.fullName}
        this.rank = {value: el?.rank?.id, label: el?.rank?.name}
    }
}

class LocationRegionsDto implements TOption {
    label;
    value;

    constructor(el: T_ID_NAME_CODE_HIDDEN) {
        this.value = el.id;
        this.label = el.name;
    }

}

class AudienceAgeDto implements TOptionAudienceAge{
    label;
    value;

    constructor(el: number | null) {
        this.value = el || el === 0 ? String(el) : undefined
        this.label = el || el === 0 ? String(el) : undefined
    }
}

class TagsDto implements TOption {
    label;
    value;

    constructor(el: TTag | TTagSecondLevel) {
        this.value = el.id;
        this.label = el.name;
    }
}
class OptionDto implements TOption {
    label;
    value;

    constructor(el: T_ID_NAME_CODE_HIDDEN | T_ID_NAME) {
        this.value = el.id;
        this.label = el.name;
    }
}
function validate(field: string, info: TInfoProject): boolean {
    let res = false;
    if (!info) return res
    const {role, status} = info;
    if (field === 'awards') {
        res = true;
        if (([
            'ROLE_ADMIN',
            'ROLE_ADMIN_IS',
            'ROLE_MODERATOR',
            'ROLE_PROJECT_MANAGER',
            'ROLE_COORDINATOR',
            'ROLE_PRODUCER'
        ] as TRole[]).includes(role)) {
            res = status === 'SUSPENDED' || status === 'COMPLETED';
        }
        if (role === 'ROLE_CONTRACTOR' && status === 'ACTUAL') return false
    }
    if ((role === 'ROLE_ADMIN') || (role ==='ROLE_ADMIN_IS')) {
        if (status === 'DRAFT') res = false;
        else if (status === 'SUSPENDED' || status === 'COMPLETED') res = true
        else if (status === 'ACTUAL' || status === 'ARCHIVED') res = false
    }
    if ((role === 'ROLE_PRODUCER')) {
        if (status === 'DRAFT') res = true
        else if (status === 'SUSPENDED' || status === 'COMPLETED')   res = true
        else if (status === 'ACTUAL' || status === 'ARCHIVED') res = false
    }
    if ((role === 'ROLE_COORDINATOR') || (role ==='ROLE_MODERATOR') || (role === 'ROLE_PROJECT_MANAGER')) {
        if (status === 'DRAFT') res = false;
        else if (status === 'SUSPENDED' || status === 'COMPLETED') res = true
        else if (status === 'ACTUAL' || status === 'ARCHIVED') res = false
    }
    if ((role === 'ROLE_ANALYST') || (role === 'ROLE_FINANCIER') || (role === 'ROLE_LAWYER') || role === 'ROLE_CONTENT_EDITOR') res = true;

    return res
}