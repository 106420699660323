import { ExtendedSearchTypes } from "features/extended-search";
import {actions, TCFState} from "./index";

export function ClearFilters(put: any, type: ExtendedSearchTypes.Stores.TTypeStore, key: keyof TCFState) {
    if (key === 'project') put(actions.clearProject(type))
    if (key === 'agreementOfProjectTaskRecords') put(actions.clearAgreementOfProjectTaskRecords(type))
    if (key === 'organizations') put(actions.clearOrganizations(type))
    if (key === 'contests') put(actions.clearContests(type))
    if (key === 'status') put(actions.clearStatus(type))
    if (key === 'indicators') put(actions.clearIndicators(type))
    if (key === 'indicatorsContent') put(actions.clearIndicatorsContent(type))
    if (key === 'awards') put(actions.clearAwards(type))
    if (key === 'thematics') put(actions.clearThematics(type))
    if (key === 'regionals') put(actions.clearRegionals(type))
    if (key === 'content') put(actions.clearContent(type))
    if (key === 'channels') put(actions.clearChannels(type))
    if (key === 'users') put(actions.clearUsers(type))
    if (key === 'famousPeople') put(actions.clearFamousPeople(type))
    if (key === 'dates') put(actions.clearDates(type))
    if (key === 'counts') put(actions.clearCounts(type))
    if (key === 'categoriesGenres') put(actions.clearCategoriesGenres(type))
}
export function ClearAllFilters(put: any, type: ExtendedSearchTypes.Stores.TTypeStore) {
    put(actions.clearProject(type))
    put(actions.clearAgreementOfProjectTaskRecords(type))
    put(actions.clearOrganizations(type))
    put(actions.clearContests(type))
    put(actions.clearStatus(type))
    put(actions.clearIndicators(type))
    put(actions.clearIndicatorsContent(type))
    put(actions.clearAwards(type))
    put(actions.clearThematics(type))
    put(actions.clearRegionals(type))
    put(actions.clearContent(type))
    put(actions.clearChannels(type))
    put(actions.clearUsers(type))
    put(actions.clearFamousPeople(type))
    put(actions.clearDates(type))
    put(actions.clearCounts(type))
    put(actions.clearCategoriesGenres(type))
}
