import React, {FC} from "react";
import {ErrorMessage, Field, useFormikContext} from "formik";
import TextError from "./text-error";
import {Checkbox} from "antd";
import {FieldAttributes} from "formik/dist/Field";
import {IFeels} from "models/feels";


const CheckboxComponent: FC<IFeels> = ({
   name,
   label,
   placeholder,
   handler,
   disabled,
   errorMessage
}) => {
    const {setFieldValue, touched, setTouched} = useFormikContext()
    const handleChange = (value: any) => {
        setFieldValue(name, value.target.checked);
        setTouched({...touched, [name]: true})
        handler && handler(value)
    }

    return (
        <div className='form-group'>
            {label && (
                <label className="nativeLabel" htmlFor={name}>{label}</label>
            )}
            <Field className="form-control" id={name} name={name}>
                {(el: FieldAttributes<any>) => {
                    return (
                        <>
                            <Checkbox
                                id={name}
                                disabled={disabled}
                                defaultChecked={el.field.value}
                                checked={el.field.value}
                                onChange={handleChange}>{placeholder}</Checkbox>
                            {el.meta.touched && el.meta.error && (
                                <ErrorMessage name={name} component={TextError}/>
                            )}
                        </>
                    )
                }}
            </Field>
            {/*// @ts-ignore*/}
            {errorMessage && touched[name] && <div className="invalid-feel">{errorMessage}</div>}
        </div>
    )
}
export default CheckboxComponent;
