import {ActionTypes} from './types'
import {InferValueTypes} from "models/common";
import * as actions from "./actions";
import { SearchContentProjectTypes } from 'features/search-project-content';
type ActionsTypes = ReturnType<InferValueTypes<typeof actions>>;

const initialState: SearchContentProjectTypes.Stores.TRegionalsState = {
    content: {
        isToggled: false,
        fields: [{field: 'Параметр неважен', values: []}]
    },
    projects: {
        isToggled: false,
        fields: [{field: 'Параметр неважен', values: []}]
    },
    calendar: {
        isToggled: false,
        fields: [{field: 'Параметр неважен', values: []}]
    }
}

export default function reducer(
    state: SearchContentProjectTypes.Stores.TRegionalsState = initialState,
    action: ActionsTypes
): SearchContentProjectTypes.Stores.TRegionalsState {
    switch (action.type) {
        case ActionTypes.SET_VALUE:
            return {
                ...state,
                [action.typeContent]: {
                    isToggled: action.isToggled,
                    fields: action.value
                }
            }
        case ActionTypes.CLEAR_VALUE:
            return {
                ...state,
                [action.typeContent]: {
                    isToggled: false,
                    fields: [{field: 'Параметр неважен', isAndOrBesides: false, values: []}]
                }
            }
        default:
            return state
    }
}