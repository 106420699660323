import React, {FC, useEffect, useRef, useState} from 'react';
import styles from './styles.module.less'
import {Popover} from "antd";
import {useTour} from "@reactour/tour";
import stepsTour from "constants/steps";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGraduationCap} from "@fortawesome/free-solid-svg-icons";
import {useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {selectRole} from "store/auth/selectors";
import {TRole} from "models/user";
import useToggleSidebar from "hooks/toggleSidebar";
import {selectTourPage} from "store/app/selectors";
import {ExclamationCircleFilled} from "@ant-design/icons";
import classNames from "classnames";

const iconElement = <FontAwesomeIcon className={styles.icon_tour_header} icon={faGraduationCap}/>

function steps(location: string, role: TRole, page: string | null) {
    const arr = stepsTour[location]
        ?.filter((el) => {
            if (el.role?.length) return (el.role as TRole[]).includes(role)
            else if (!!el.roleCant?.length) return (el.roleCant as TRole[]).includes(role)
            return el
        })

        .filter((el) => {
            if (el.page?.length && page) return !el.page.includes(page)
            return el
        })
        .filter((el) => !(el.mediaHidden && el.mediaHidden >= window.innerWidth))
        .filter((el) => !(el.mediaShow && el.mediaShow <= window.innerWidth))
    return arr?.length ? arr : []
}
type Props = {
    children: React.ReactNode
}
    const TourBtnComponent: FC<Props> = ({children}) => {
    const role = useSelector(selectRole)
    const tourPage = useSelector(selectTourPage)
    const {setIsOpen, setSteps, setCurrentStep, isOpen} = useTour();
    let location = useLocation();
    const {handlerSidebar} = useToggleSidebar()
    const [state, setState] = useState<any[]>([])

    const bodyElem = useRef(document.querySelector("body"))

    useEffect(() => {
        if (role) setState(location.pathname ? steps(location.pathname, role, tourPage) : [])
        setIsOpen(false)
    }, [role, location, setIsOpen, tourPage])

    useEffect(()=>{
        if(isOpen) {
            bodyElem.current?.classList.add("overflow--hidden")
        }
        else bodyElem.current?.classList.remove("overflow--hidden")
    },[isOpen])//eslint-disable-line


    return (
        <div className={styles.item} onClick={e => {
            setSteps(state)
            setCurrentStep(0)
            setIsOpen(true)
            handlerSidebar(null)(e)
        }}>
            {children}
        </div>
    )
    }

const PopoverContent = () => {
    const {handlerSidebar} = useToggleSidebar()

    return (
        <div className={styles.popover}>
            <TourBtnComponent>
                <div className={styles.icon_tour}>
                    {iconElement}
                </div>
                <div className={styles.text}>
                    Тур по сервису
                </div>
            </TourBtnComponent>
            <div className={styles.item} onClick={handlerSidebar('functionalityInfo')}>
                <div className={styles.icon}>
                    <ExclamationCircleFilled/>
                </div>
                <div className={styles.text}>
                    Информация по работе с функционалом страницы
                </div>
            </div>
        </div>
    )
}

const TourBtn = () => {
    return (
        <Popover content={<PopoverContent />}>
            <div className={classNames('infoMenu__tour', styles.tour)}>
                {iconElement}
            </div>
        </Popover>
    )
}

export default TourBtn;
