import React from 'react';
import './index.less'
import {useSelector} from "react-redux";
import {selectProject} from "store/projects/selectors";
import imageChanelDefault from "assets/svg/internet-resource.svg";
import {Tooltip} from "antd";

const ProjectChannels = () => {
    const project = useSelector(selectProject);
    return (
        <div className='resources'>
            <span className='title'>Интернет-ресурсы проекта</span>
            <div className='items'>
                {!!project?.channels.length ? project.channels.map((el) => {
                    const linkRE = /^(http|https):\/\/[^ "]+$/;
                    const url = linkRE.test(el.link) ? el.link : `https://${el.link}`;
                    const image = el.internetResource.image ? el.internetResource.image : imageChanelDefault
                    return (
                        <a
                            key={el.id}
                            href={url}
                            target='_blank'
                            rel="noreferrer"
                            className='item'
                        >
                            <Tooltip title={'Открыть ресурс'}>
                                <img src={image} alt={el.internetResource.id}/>
                                <small>{el.name}</small>
                            </Tooltip>
                        </a>
                    )
                }) : (
                    <a
                        href={'https://контент.ири.рф'}
                        target='_blank'
                        rel="noreferrer"
                        className='item'
                    >
                        <Tooltip title={'Открыть ресурс'}>
                            <img src={'/logo.png'} alt={'контент.ири.рф'}/>
                            <small>Нет данных</small>
                        </Tooltip>
                    </a>
                )}
            </div>
        </div>
    )
}

export default ProjectChannels;