import React, {FC} from 'react';
import styles from './modal-task.module.less'
import {Modal} from "antd";
import useHandlerModal from "hooks/handlModal";
import TaskForm from "components/forms/task-form";

type ModalTaskProps = {
    status: boolean
    taskId?: string
}
const ModalTask: FC<ModalTaskProps> = ({status,taskId}) => {
    const {isVisible, closeModal } = useHandlerModal(status);
    return (
        <Modal
            className={styles.modal_task}
            title={taskId ? 'Редактирование задачи' : 'Создание задачи'}
            visible={isVisible}
            onCancel={closeModal}
            footer={false}
            maskClosable={true}
        >
            <TaskForm id={taskId} closeFunction={closeModal} definitionSidebar={true}/>
        </Modal>
    )
};
export default ModalTask;
