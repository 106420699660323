import { put } from 'redux-saga/effects'
import { GetContentStartAction, SetSortingFilter } from 'store/content/content/actions'

export function* setSortingFilter(_: ReturnType<typeof SetSortingFilter>) {
	try {
		yield put(GetContentStartAction())
	} catch (error: any) {
		console.log(error)
	}
}
