import React from 'react'
import {SelectComponent} from 'components/formic-control'
import {ProjectLib} from 'entities/project'
import {useSelector} from 'react-redux'
import {selectParams} from 'store/selectors'
import styles from '../styles.module.less'
import {useFormikContext} from "formik";
import {TInitValues} from "../modal-content-records";

const SubmissionForm = () => {
	const { values } = useFormikContext<TInitValues>()
	const { id } = useSelector(selectParams)

	const {data: submissionFormsOptions, isLoading} = ProjectLib.useGetProjectContentSubmissionByProjectOptions({projectId: id})

	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Формат сдачи контента <sup>*</sup>
			</span>
			<div className={styles.field}>
				<SelectComponent
					loading={isLoading}
					disabled={values.disabled}
					options={submissionFormsOptions}
					name={'submissionForm'}
					placeholder={'Выберите формат сдачи контента'}
				/>
			</div>
		</div>
	)
}

export default SubmissionForm
