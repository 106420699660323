import $api from '../http'
import {
	TYPE_NOTIFICATION_CHANNELS,
	TPersonalSettingResponse,
	TConfirmResponse,
	TPersonalSettingRequest
} from '../models/personal-setting'
import { AxiosResponse } from 'axios'
/**
 * @category Service
 * @class PersonalSettingService
 */
export default class PersonalSettingService {
	/**
	 * получение персональных настроек
	 */
	static getPersonalSetting(id: string): Promise<AxiosResponse<TPersonalSettingResponse[]>> {
		return $api.get<TPersonalSettingResponse[]>(`/notifications/personal/${id}`)
	}

	/**
	 * Обновление персональных настроек
	 */
	static updatePersonalSetting(id: string, body: TPersonalSettingRequest[]): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/notifications/personal/update/${id}`, body)
	}

	/**
	 * Подтверждения email адреса
	 */

	static confirmEmail(
		userId: string,
		notificationChannel: TYPE_NOTIFICATION_CHANNELS,
		confirmationCode: string,
		account: string | undefined
	): Promise<AxiosResponse<TConfirmResponse>> {
		return $api.put<TConfirmResponse>(
			`/notifications/confirmation/confirm/${userId}/${account}/${notificationChannel}/${confirmationCode}`
		)
	}

	/**
	 * Подтверждения аккаунта telegram
	 */

	static confirmTelegram(code: string): Promise<AxiosResponse<TConfirmResponse>> {
		return $api({
			method: 'PUT',
			url: `/notifications/confirmation/telegram/confirm/${code}`,
			}
		)
	}

	/**
	 * Генерация и отсылка сообщения для подтверждения email адреса
	 */

	static sendConfirmEmail(
		userId: string,
		notificationChannel: TYPE_NOTIFICATION_CHANNELS,
		account: string | undefined
	): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/notifications/confirmation/init/${userId}/${account}/${notificationChannel}`, {
			headers: {
				'Actioncode': 'GET_NOTIFICATIONS_CONFIRMATION_INIT_USER_ID_ACCOUNT_NOTIFICATIONCHANNEL'
			}
		})
	}
}
