import { axiosRequest } from 'shared/config/axios'
import { RecordsCount, RecordsCountSchema } from '../types'
import {TTaskListFilterData, TTaskListResponse} from "../../../models/task/task-list";

export const getTaskRecordsCount = {
	key: (taskId: string) => `/tasks/${taskId}/records/records-count`,
	fetch: async (taskId: string) => {
		const { data } = await axiosRequest<RecordsCount>({
			url: `/tasks/${taskId}/records/records-count`,
			method: 'GET',
			parser: RecordsCountSchema,
			headers: {
				'Actioncode': 'GET_TASKS_TASK_ID_RECORDS_RECORDS_COUNT'
			}
		})
		return data
	},
}

export const changeStatusTask = async ({ id, statusId }: { id: string, statusId: string}) => {
	await axiosRequest({
		method: 'PUT',
		url: `/tasks/${id}/status`,
		params: { statusId },
	})
}

export const resetStatusRecordContent = async ({ recordId }: { recordId: string }) => {
	await axiosRequest({
		method: 'PUT',
		url: `/tasks/content/records/${recordId}/status-reset`,
	})
}

export const recordsBulkActionsContent = async ({ rowsIds, bulkAction }: { rowsIds: string[], bulkAction: string }) => {
	await axiosRequest({
		method: 'POST',
		url: `/tasks/content/records/bulk-actions/${bulkAction}`,
		data: rowsIds
	})
}

export const deleteTaskRecordsBulk = async (taskId: string, typeTask?: 'posting' | 'content' | 'copyright' | 'estimate') => {
	await axiosRequest({
		method: "DELETE",
		url: `/tasks/${typeTask}/${taskId}/records/bulk-records-liquidation`
	})
}

export const getAllTasksOptions = async (filterData: TTaskListFilterData) => {
  	const { data } = await axiosRequest<TTaskListResponse>({
		method: 'POST',
		url: `/tasks/search`,
		data: {page: 0, pageSize: 0, filterData}
	})

	return data.content.map(task => {
		const name = task.name.length > 40 ? task.name.substring(0, 37) + '...' : task.name;
		const formattedDeadline = task?.deadline?.split('-')?.reverse()?.join('.');
		return {
			value: task.id,
			label: `${task.code}-${task.number} ${name} (${formattedDeadline})`,
			code: `${task.code}-${task.number} ${task.name} (${formattedDeadline})`,
		};
	});

}