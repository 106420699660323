import { z } from 'zod';
import { TasksStatusEnum } from './status';

export const StatisticSchema = z.object({
	id: z.string(),
	name: z.string(),
	code: z.string(),
	deadline: z.string(),

	number: z.number(),
	approvedRecordsCount: z.number(),
	rejectedRecordsCount: z.number(),
	verificationRequiredRecordsCount: z.number(),
	taskUntilEndDays: z.number(),

	status: z.object({
		id: z.string(),
		name: z.string(),
		code: TasksStatusEnum,
	})
})
export type Statistic = z.infer<typeof StatisticSchema>;