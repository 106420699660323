import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteContentDeliveryErrorAction,
    DeleteContentDeliveryStartAction,
    DeleteContentDeliverySuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import HandbookService from "services/handbookService";
import {TStatusesHandbookFeels} from "models/handbook";


export function* deleteContentDelivery({id}: ReturnType<typeof DeleteContentDeliveryStartAction>) {
    try {
        yield HandbookService.deleteContentDeliveryRecord(id)
        yield put(DeleteContentDeliverySuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.contentDelivery))

        yield put(NotificationOpen('success', 'Тип формы сдачи удален'))

    } catch (error: any) {
        yield put(DeleteContentDeliveryErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить тип формы сдачи'))
    }
}
