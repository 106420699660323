import * as Yup from "yup";
import {getValue, statusIsNullOrDraftOrReadyToBeActual, ValidateDates} from "./utils";
import {StepFifthDto} from "../dto/project-form_StepFifth.dto";


export const validationStepFifth = Yup.object({
    famousPeople: Yup.mixed().test(
        'famousPeople',
        'Поле «Известные люди проекта» заполнено некорректно',
        function ({value}: StepFifthDto["famousPeople"], context) {
            if (!value.length && statusIsNullOrDraftOrReadyToBeActual(context)) return true;
            let isValid = true;
            if (value.length) {
                let length = value.length;
                do {
                    if (!value[length - 1].famousPerson) isValid = false;
                    if (!value[length - 1].rank) isValid = false;
                    length--;
                } while (isValid && length !== 0);
            }
            return isValid
        }),
    licenseDate: Yup.mixed().test(
        'licenseDate',
        'Поле «Дата приема-передачи лицензии ИРИ» заполнено некорректно',
        function ({value}: StepFifthDto["licenseDate"], context) {
            if (value) {
                const form = getValue(context);
                const validateDefault = ValidateDates({
                    value: value,
                    form: form,
                    context,
                    label: 'Дата приема-передачи лицензии ИРИ',
                    path: 'StepFifthDto.licenseDate'
                })
                if (typeof validateDefault !== "boolean") return validateDefault;
                return true;
            } else return true

        }),
    targetAudienceAgeTo: Yup.mixed().test(
        'targetAudienceAgeTo',
        'Поле «Целевая аудитория проекта» заполнено некорректно',
        function ({value}: StepFifthDto["targetAudienceAgeTo"], context) {
            const targetAudienceAgeFrom = getValue(context).stepFifth.targetAudienceAgeFrom.value;
            if (!value && targetAudienceAgeFrom?.value) {
                return context.createError({
                    path: 'stepFifth.targetAudienceAgeTo',
                    message: `Поле «Целевая аудитория проекта до» обязательно для заполенения`
                })
            }
            return true;
        }),
})