import { Types } from './action-types'
import {
	TContentFilter,
} from 'models/content'
import {TContentState, TContentTypes} from './reducer'
import {ProjectTypes} from "entities/project";

export const SetContentInfo = <T extends Partial<TContentState>>(value: T) => ({
	type: Types.SET_CONTENT_INFO,
	value
})
/**
 * Изменение фильтра
 */
export const SetContentFilter = (filter: TContentFilter, contentType?: TContentTypes) => ({
	type: Types.SET_CONTENT_FILTER,
	filter,
	contentType,
})
export const GetContentListStartAction = () => ({
	type: Types.GET_CONTENT_LIST_STAT,
})
export const GetContestsListSuccessAction = (content: ProjectTypes.ProjectsListResponse) => ({
	type: Types.GET_CONTENT_LIST_SUCCESS,
	content,
})
export const GetContestsListErrorAction = (error: any) => ({
	type: Types.GET_CONTENT_LIST_ERROR,
	error,
})
