import compose from "compose-function";
import withRouter from "./with-router";
import WithStore from "./with-store";
import WithAntd from "./with-antd";
import WithTour from "./with-tour";
import WithTus from "./with-tus";
import WithUploader from "./with-uloader";
import WithKeycloak from "./with-keycloak";
import WithQuery from "./with-query";


const withProviders = compose(
    WithStore,
    WithKeycloak,
    WithQuery,
    WithAntd,
    WithUploader,
    withRouter,
    WithTour,
    WithTus
);
export default withProviders;
