import {AuthActionTypes} from "./action-types";
import {TUserCredentials} from "../../models/user";
import {TGenerateKey} from "../../models/common";
import { UserTypes } from "entities/user";


export const LoginStartAction = (credentials: TUserCredentials) => ({
    type: AuthActionTypes.LOGIN_START,
    credentials
})
export const LoginSuccessAction = (user: UserTypes.CurrentUser) => ({
    type: AuthActionTypes.LOGIN_SUCCESS,
    user,
});
export const LoginErrorAction = (error: any) => ({
    type: AuthActionTypes.LOGIN_ERROR,
    error,
});

export const LoginByUserStartAction = (credentials: TUserCredentials) => ({
    type: AuthActionTypes.LOGIN_BY_USER_START,
    credentials
})

export const GetCurrentUser = () => ({
    type: AuthActionTypes.GET_CURRENT_USER
})

export const LogoutAction = () => ({ type: AuthActionTypes.LOGOUT });

export const RestoreStartAction = (email: string) => ({
    type: AuthActionTypes.RESTORE_START,
    email
});
export const RestoreSuccessAction = () => ({
    type: AuthActionTypes.RESTORE_SUCCESS,
});
export const RestoreErrorAction = (error: any) => ({
    type: AuthActionTypes.RESTORE_ERROR,
    error,
});

export const GenerateKeyStartAction = (password: string | null) => ({
    type: AuthActionTypes.GENERATE_KEY_START,
    password
})
export const GenerateKeySuccessAction = (data: TGenerateKey) => ({
    type: AuthActionTypes.GENERATE_KEY_SUCCESS,
    data,
});
export const GenerateKeyErrorAction = (error: any) => ({
    type: AuthActionTypes.GENERATE_KEY_ERROR,
    error,
});

export const GetKeyStartAction = () => ({
    type: AuthActionTypes.GET_KEY_START
})
export const GetKeySuccessAction = (data: TGenerateKey) => ({
    type: AuthActionTypes.GET_KEY_SUCCESS,
    data,
});
export const GetKeyErrorAction = (error: any) => ({
    type: AuthActionTypes.GET_KEY_ERROR,
    error,
});

export const BlockKeyStartAction = () => ({
    type: AuthActionTypes.BLOCK_KEY_START
})
export const BlockKeySuccessAction = () => ({
    type: AuthActionTypes.BLOCK_KEY_SUCCESS
});
export const BlockKeyErrorAction = (error: any) => ({
    type: AuthActionTypes.BLOCK_KEY_ERROR,
    error,
});
export const ChangeDownloadRightsStartAction = (value: boolean) => ({
    type: AuthActionTypes.CHANGE_DOWNLOAD_RIGHTS_START, value
})
export const ChangeDownloadRightsSuccessAction = (value: boolean) => ({
    type: AuthActionTypes.CHANGE_DOWNLOAD_RIGHTS_SUCCESS, value
});
export const ChangeDownloadRightsErrorAction = (error: any) => ({
    type: AuthActionTypes.CHANGE_DOWNLOAD_RIGHTS_ERROR,
    error,
});