import {put} from 'redux-saga/effects'
import {
	UpdatePersonalDataErrorAction,
	UpdatePersonalDataStartAction,
	UpdatePersonalDataSuccessAction
} from '../../../store/options/actions/actions-users'
import UsersService from '../../../services/usersService'
import CreateErrorMessage from "../../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../../store/notification/actions";
import {GetCurrentUser} from "../../../store/auth/actions";
import {SendUpdateUserFormDto} from "../../../components/modals/modal-user-profile/update-user-profile.dto";

export function* updatePersonalDataUser({ data }: ReturnType<typeof UpdatePersonalDataStartAction>) {
	try {
		const formData = new FormData();
		const userData = new SendUpdateUserFormDto(data)
		formData.append('user', JSON.stringify(userData));

		if (data.image.length !== 0) {
			formData.append('image', data.image[0].originFileObj as Blob);
		}

		yield UsersService.updatePersonalData(formData)

		yield put(UpdatePersonalDataSuccessAction())
		yield put(NotificationOpen('success', 'Персональные настройки пользователя обновлены'))
		yield put(GetCurrentUser())

	} catch (error: any) {
		yield put(UpdatePersonalDataErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))

	}
}
