import {put} from "redux-saga/effects";
import {GetTasksKanbanStartAction, SetTasksKanbanFilter} from "../../../store/tasks/actions/gant-kanban";
import CreateErrorMessage from "../../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../../store/notification/actions";


export function* setTaskKanbanFilter({projectId}: ReturnType<typeof SetTasksKanbanFilter>) {
    try {
        yield put(GetTasksKanbanStartAction(projectId))
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить проект по выбранному фильтру'))
    }
}