import {z} from "zod";

export const PrioritySchema = z.object({
    ALWAYS_SEND: z.string(),
    NOT_SEND: z.string(),
    SEND_IF_SENT_TELEGRAM_AND_WHATSAPP_FAIL: z.string().optional(),
    SEND_IF_SENT_EMAIL_FAIL: z.string().optional()
})
export type Priority = z.infer<typeof PrioritySchema>;
export const PrioritiesSchema = z.object({
    EMAIL: PrioritySchema,
    SMS: PrioritySchema,
    TELEGRAM: PrioritySchema,
    SYSTEM: PrioritySchema,
})

export type Priorities = z.infer<typeof PrioritiesSchema>;

