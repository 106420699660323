import React, { FC } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import useTasksFields from 'hooks/tasksField'
import { TTasksTypesFields } from 'models/task'
import styles from '../styles.module.less'
import {Button, Spin} from 'antd'
import { TaskApi, TaskTypes } from 'entities/task'
import { TypeContent } from 'models/content'
import { NotificationOpen } from 'store/notification/actions'
import { useDispatch } from 'react-redux'
import TasksContentService from 'services/tasksContentService'

type TProps = {
	el: TypeContent
	record?: TaskTypes.ContentRecord
}
const BannerComponent: FC<TProps> = ({ record, el }) => {
	const put = useDispatch()
	const queryClient = useQueryClient()
	const { statuses } = useTasksFields([TTasksTypesFields.statuses])

	const isReady = record?.task?.status?.code === 'READY'
	const inProgress = statuses?.find((it) => it.code === 'IN_PROGRESS')

	const { mutate: changeStatus, isLoading: isLoadingChange } = useMutation({
		mutationFn: TaskApi.changeStatusTask,
		onSuccess: async () => {
			await queryClient.invalidateQueries([TasksContentService.getTaskRecordKey(el.id)])
			put(NotificationOpen('success', 'Успешно'))
		},
	})

	const { mutate: resetStatus, isLoading: isLoadingReset } = useMutation({
		mutationFn: TaskApi.resetStatusRecordContent,
		onSuccess: async () => {
			await queryClient.invalidateQueries([TasksContentService.getTaskRecordKey(el.id)])
			put(NotificationOpen('success', 'Успешно'))
		},
	})

	if (!record) return null

	const bannerContent = {
		READY: {
			title: 'Условия редактирования единицы контента задачи в статусе “Готово”',
			message:
				'Обращаем ваше внимание, чтобы отредактировать данные, требуется изменить статус задач с “Готово” на “В процессе”?',
			buttonText: 'Изменить статус задачи',
		},
		RECORD_APPROVED_ARCHIVAL: {
			title: 'Условия редактирования единицы контента архивной задачи',
			message:
				'Обращаем ваше внимание, чтобы отредактировать данные архивной задачи, требуется изменить статус записи с “Согласована” на “Готова к согласованию заказчиком”?',
			buttonText: 'Изменить статус записи',
		},
	};

	const handlerButton = () => {
		if (isReady && record?.task.id && inProgress?.value) changeStatus({ id: record?.task.id, statusId: inProgress?.value })
		if (!isReady && record.status === 'APPROVED' && record.task.archival && record?.id) resetStatus({ recordId: record?.id })
	}

	const { title, message, buttonText } = bannerContent[isReady ? 'READY' : 'RECORD_APPROVED_ARCHIVAL']

	return (
		<Spin spinning={isLoadingReset || isLoadingChange}>
			<div>
				<span className={styles.title_banner}>{title}</span>
				<span className={styles.message_banner}>{message}</span>
				<div className={styles.buttons}>
					<Button type="primary" onClick={handlerButton} >
						{buttonText}
					</Button>
				</div>
			</div>
		</Spin>
	)
}

export default BannerComponent
