import {
    GetTaskReportStartAction,
    ResetTasksStatusErrorAction,
    ResetTasksStatusStartAction,
    ResetTasksStatusSuccessAction
} from "store/tasks/actions/report";
import {put, select} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {ReportType} from "models/task/types";
import {
    TasksReportCopyrightService,
    TasksReportEstimateService,
    TasksReportPostingService,
    TasksReportReachService
} from "services/tasksReportService";
import {selectParams} from "store/selectors";


export function* resetStatusTask({reportType, recordId}: ReturnType<typeof ResetTasksStatusStartAction>) {
    try {
        const {id} = yield select(selectParams)
        switch (reportType) {
            case ReportType.CONTENT_POSTING_REPORT:
                yield TasksReportPostingService.resetTaskRecordsStatus(recordId);
                break;
            case ReportType.COPYRIGHT_LIST:
                yield TasksReportCopyrightService.resetTaskRecordsStatus(recordId);
                break;
            case ReportType.PROJECT_ESTIMATE:
                yield TasksReportEstimateService.resetTaskRecordsStatus(recordId);
                break;
            case ReportType.CONTENT_REACH_REPORT:
                yield TasksReportReachService.resetTaskRecordsStatus(recordId);
                break;
            default:
                console.log('Данный тип не поддерживается')
        }

        yield put(ResetTasksStatusSuccessAction(recordId))
        yield put(NotificationOpen('success', 'Статус сброшен'))
        if (id) yield put(GetTaskReportStartAction(id, reportType))
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(ResetTasksStatusErrorAction(recordId, message))
    }
}