import {put, select} from "redux-saga/effects";
import {LogsLogoutAction, LogsSendErrorAction} from "store/logs/actions";
import {selectLogs} from "store/logs/selector";
import {TLogsAction} from "models/logs";
import UsersActionsService from "services/usersActionsService";
import keycloak from "../../Keycloak";
import {LogoutAction} from "store/auth/actions";

export function* logoutLogs(_action: ReturnType<typeof LogsLogoutAction>) {
    try {
        const newLog: TLogsAction = {
            actionTypeCode: 'LOGOUT',
            dateTime: new Date(),
        }
        const logs: TLogsAction[] = yield select(selectLogs);

        yield UsersActionsService.sendActions([...logs, newLog])
    } catch (error: any) {
        yield put(LogsSendErrorAction(error))
    } finally {
        keycloak.logout()
        yield put(LogoutAction())
    }
}