import React, {FC, SetStateAction, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
//antd
import {Button, Radio} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
//models
import {IOptions} from "models/feels";
import {TaskModel, TaskTypes} from 'entities/task'
import styles from './sorting.module.less'

const options = [
    {
    label: 'Все комментарии',
    value: 'ALL'
    },
    {
        label: 'Мои комментарии',
        value: 'CURRENT'
    }
]

type TProps = {
    handler: (value: SetStateAction<TaskTypes.TypeSorting>) => void
}

const Sorting: FC<TProps> = ({handler}) => {
    const put = useDispatch()
    const [sort, setSort] = useState< 'ASC' | 'DESC'>( 'DESC')
    const {filter} = useSelector(TaskModel.selectors.store)

    const handleChange = (e: any) => {
        const value = e.target.value;
        handler(value)
    }

    const handlerSort = () => {
        const val: 'ASC' | 'DESC' = sort === 'DESC' ? 'ASC' : 'DESC'
        switch (sort) {
            case "ASC":
                setSort('DESC')
                break
            case "DESC":
                setSort('ASC')
                break
        }
        const newSorter = {
            sort: {field: 'createDateTime', direction: val}
        }
        put(TaskModel.actions.SetInfo({filter: {
                ...filter,
                ...newSorter,
                filterData: {
                    ...filter.filterData,
                }
            }}))
        put(TaskModel.actions.Get())
    }

    return (
       <div className={styles.container}>
           <div className={styles.sorting}>
               <Radio.Group
                   defaultValue={'ALL'}
                   onChange={handleChange}
               >
                   {options && options.map((option: IOptions) => {
                       return (
                           <Radio key={option.label} className={styles.radio} value={option.value}>
                               {option.label}
                           </Radio>
                       )
                   })}
               </Radio.Group>
           </div>
           <div className={styles.direction}>
               <Button className={styles.direction_btn} type="primary" size="small" onClick={handlerSort}>
                   {sort === 'DESC' ? <span>Сначала новые<ArrowDownOutlined /></span> :  <span>Сначала старые<ArrowUpOutlined /></span> }
               </Button>
           </div>
       </div>
    );
};

export default Sorting