import React from 'react';
import classNames from "classnames";
import styles from "./step-first.module.less";
import {Spin} from "antd";
import ReloadList from "components/forms/components/reload-list/reload-list";
import {SelectComponent} from "components/formic-control";
import {useDispatch} from "react-redux";
import {useFormikContext} from "formik";
import {ProjectFormDto} from "features/create-or-edit-project-form/lib/dto/project-form.dto";
import {GetFeelsStartAction} from "store/app/actions";
import {TStatusesFeels} from "models/projects/projectFeels";
import {useQuery} from "react-query";
import {SessionApi} from 'entities/session'
import {ContestApi} from 'entities/contest'
import {Query} from 'processes/query'

function filingName(key: string): string {
    return `stepFirst.${key}.value`
}
const Contest = () => {
    const put = useDispatch()
    const {values, setFieldValue, errors} = useFormikContext<ProjectFormDto>();
    const form = values.stepFirst;
    const contestId = form['session'].value.contest?.value
    const yearId = form['session'].value.year?.value

    const {data: sessionYears =  [], isLoading: isLoadingSessionYears}= useQuery({
        queryKey: [SessionApi.SessionController.getSessionYearsOptionsKey, contestId],
        queryFn: () => SessionApi.SessionController.getSessionYearsOptions(contestId),
        enabled: !!contestId
    })

    const {data: sessions =  [], isLoading: isLoadingSession}= useQuery({
        queryKey: [SessionApi.SessionController.sessionsOptionsKey, {year: yearId, contestId: contestId}],
        queryFn: () => SessionApi.SessionController.getSessionsOptions({year: yearId, contestId: contestId}),
        enabled: !!yearId
    })
    const {data: contestsList =  [], isLoading: isLoadingContests}= useQuery({
        queryKey: [ContestApi.ContestController.getContestListOptionsKey],
        queryFn: ContestApi.ContestController.getContestListOptions
    })

    const handlerUpdateContest =()=> {
        Query.invalidate([ContestApi.ContestController.getContestListOptionsKey])
    }
    const handlerContests = () => {
        setFieldValue('stepFirst.session.value.year', null)
        setFieldValue('stepFirst.session.value.session', null)
        setFieldValue('stepSecond.contentThematics.value', [])
        put(GetFeelsStartAction(TStatusesFeels.thematics))
    }
    const handlerYear = () => {
        setFieldValue('stepFirst.session.value.session', null)
    }
    return (
        <>
            <div className={classNames(styles.contest_label, 'label')}>
                Конкурс<sup>*</sup>
            </div>
            <div className={classNames(styles.contest)}>
                <Spin spinning={isLoadingContests}>
                    <ReloadList handlerUpdateList={handlerUpdateContest}/>
                    <SelectComponent
                        options={contestsList}
                        name={`${filingName('session')}.contest`}
                        handler={handlerContests}
                        placeholder={'Выберите конкурс'}
                        disabled={form.session.isDisabled}
                        errorMessage={errors.stepFirst?.session?.value?.contest as string}
                    />
                </Spin>
            </div>

            <div className={classNames(styles.year_label, 'label', styles.text_right)}>
                Год<sup>*</sup>
            </div>
            <div className={classNames(styles.year)}>
                <Spin spinning={isLoadingSessionYears}>
                    <SelectComponent
                        options={form['session'].value.contest?.value ? sessionYears : null}
                        name={`${filingName('session')}.year`}
                        handler={handlerYear}
                        placeholder={'Выберите год'}
                        disabled={!form.session.value.contest?.value || form.session.isDisabled}
                        errorMessage={errors.stepFirst?.session?.value?.year as string}
                    />
                </Spin>
            </div>

            <div className={classNames(styles.session_label, 'label', styles.text_right)}>
                Сессия<sup>*</sup>
            </div>
            <div className={classNames(styles.session)}>
                <Spin spinning={isLoadingSession}>
                    <SelectComponent
                        options={form.session.value.year ? sessions : null}
                        name={`${filingName('session')}.session`}
                        placeholder={'Выберите сессию'}
                        disabled={!form.session.value.year || form.session.isDisabled}
                        errorMessage={errors.stepFirst?.session?.value?.session as string}
                    />
                </Spin>
            </div>
        </>
    )
};

export default Contest;