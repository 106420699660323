const project = [
    {
        id: '11',
        selector: '.projectPage__info',
        content: 'Тут базовая информация о проекте: название проекта, статус проекта, ' +
            'направление, тематические линии, количество дней до завершения проекта и дата завершения проекта',
        role: ['ROLE_CLIENT', 'ROLE_AGENT']
    },
    {
        id: '11',
        selector: '.projectPage__info',
        content: 'Тут базовая информация о проекте: название проекта, статус проекта, направление, тематические линии, количество дней до завершения проекта и дата завершения проекта',
        role: ['ROLE_CONTRACTOR','ROLE_ADMIN','ROLE_PRODUCER','ROLE_COORDINATOR','ROLE_ANALYST','ROLE_FINANCIER','ROLE_LAWYER','ROLE_MODERATOR','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS']
    },
    {
        id: '12',
        selector: '.StatusProject',
        content: 'Это индикаторы прогресса исполнения этапов проекта. Процент прогресса внутри определенного этапа зависит от типа выполненных задач, их количества и статуса',
        role: []
    },
    {
        id: '13',
        selector: '.projectPage__navigation',
        content: 'Разделы позволяют совершать переход внутри карточки проекта',
        role: ['ROLE_CONTRACTOR','ROLE_ADMIN','ROLE_PRODUCER','ROLE_COORDINATOR','ROLE_ANALYST','ROLE_FINANCIER','ROLE_LAWYER','ROLE_MODERATOR','ROLE_CLIENT', 'ROLE_AGENT','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS']
    },
    {
        id: '14',
        selector: '.projectPage__card-img',
        content: 'При нажатии на изображение проекта, оно развернется в отдельном окне для более удобного просмотра',
        role: [],
        page: ['project-no-data']
    },
    {
        id: '15',
        selector: '.projectPage__goal-description',
        content: 'Это цели и описание проекта',
        role: [],
        page: ['project-no-data']
    },

]
export default project;
