import React from 'react';
import {useDispatch, useSelector} from "react-redux"
import {Select, Spin} from "antd";
import {ExtendedSearchModel, ExtendedSearchTypes} from 'features/extended-search/index';
import {IOptions} from "models/feels";
import {useQuery} from "react-query";
import {SessionApi} from 'entities/session'
import {ContestApi} from 'entities/contest'

const { Option} = Select;
type TKey = keyof Omit<
    ExtendedSearchTypes.Stores.Contests, 'isToggled'>
function useGetsData() {
    const contest = useQuery({
        queryKey: [ContestApi.ContestController.getContestListOptionsKey],
        queryFn: ContestApi.ContestController.getContestListOptions
    })
    const years = useQuery({
        queryKey:[SessionApi.SessionController.getSessionYearsOptionsKey],
        queryFn: () => SessionApi.SessionController.getSessionYearsOptions()
    })
    const session = useQuery({
        queryKey: [SessionApi.SessionController.sessionsOptionsKey],
        queryFn: () => SessionApi.SessionController.getSessionsOptions(),
    })

    return {
        loading: contest.isLoading || years.isLoading || session.isLoading,
        options: {
            contestIds: contest.data || [],
            years: years.data || [],
            sessionIds: session.data || []
        }
    }
}
export const ContestsItem = () => {
    const {loading, options} = useGetsData()

    return (
        <Spin spinning={loading}>
            <div className="item-wrapper">
                <Item options={options.contestIds} name={'contestIds'} label={'Конкурс'}/>
                <Item options={options.years} name={'years'} label={'Год'}/>
                <Item options={options.sessionIds} name={'sessionIds'} label={'Сессия'}/>
            </div>
        </Spin>

    )
}
type ItemProps = {
    options: IOptions[],
    name: TKey,
    label: string
}
function Item({options, name, label}: ItemProps) {
    const put = useDispatch();
    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const contests = useSelector(ExtendedSearchModel.selectors.contests)

    function onSelectChange(value: string[]) {
        put(ExtendedSearchModel.actions.setContests(typeContent, {[name]: value}))
    }

    return (
        <div className="item">
            <div className="label">{label}</div>
            <div className="form-group">
                <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={true}
                    onChange={onSelectChange}
                    maxTagCount={"responsive"}
                    mode={"multiple"}
                    value={contests[typeContent][name]}
                    placeholder={'Искать среди всех'}
                    filterOption={(input, option) => {
                        if (option === undefined || option === null) return false;
                        return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                >
                    {options.map((el) => (
                        <Option
                            key={el.value}
                            value={el.value}
                            data-name={el.label}
                        >
                            {el.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}
