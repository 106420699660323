import React, {FC, useEffect, useRef, useState} from 'react'
import './tags.less'
import {useFormikContext} from 'formik'
import {Input, Tag} from 'antd'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import DragDropTag from './drag-drop-tag'
import MaskedInput from "antd-mask-input";
import {NotificationOpen} from "../../../../../store/notification/actions";
import {TUserForm} from "../../../../../models/users-list/user-form";
import {useDispatch} from "react-redux";
import {emailRegExp, matchDuplicate, reorder} from "./utils";

type TEmail = {
	email: string
	isMain: boolean
	isConfirmed: boolean
}
type TPhone = {
	phone: string
	isMain: boolean
	isConfirmed: boolean
}

type TTags = {
	tags: TEmail[] | TPhone[]
	name: string
	type?: string

}

const TagsComponent: FC<TTags> = ({ tags, name, type }) => {
	const put = useDispatch()
	const { setFieldValue } = useFormikContext<TUserForm>()
	const [inputVisible, setInputVisible] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const [editInputIndex, setEditInputIndex] = useState(-1)
	const [editInputValue, setEditInputValue] = useState('')
	const inputRef = useRef<any>(null)
	const editInputRef = useRef<any>(null)

	useEffect(() => {
		if (inputVisible) {
			inputRef.current?.focus()
		}
	}, [inputVisible])

	useEffect(() => {
		editInputRef.current?.focus()
	}, [editInputValue])

	const handleClose = (removedTag: string) => {
		const newTags = (tags as TPhone[]).filter((item: any) => {
			const tag = type === 'email' ? item.email : item.phone
			return tag !== removedTag
		})
		setFieldValue(name, newTags)
	}

	const showInput = () => {
		setInputVisible(true)
	}

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value)
	}

	const handleInputConfirm = () => {
		if (type === 'email' && !emailRegExp.test(inputValue) && inputValue ) {
			put(NotificationOpen('error','Неккоректный E-mail'))
			return
		}
		const arr = type === 'email' ?
			(tags as TEmail[]).map(it=>it.email) :
			(tags as TPhone[]).map(it=>it.phone)

		if (arr.includes(inputValue)) {
			put(NotificationOpen('error', 'Необходимо указать уникальный email/телефон'))
			return
		}
		if (
			inputValue &&
			tags
				.map((it: any) => {
					const tag = type === 'email' ? it.email : it.phone
					return tag
				})
				.indexOf(inputValue) === -1
		) {
			const key = type === 'email' ? 'email' : 'phone'
			setFieldValue(name, [...tags, { [key]: inputValue, isMain: false, isConfirmed: false }])
		}
		setInputVisible(false)
		setInputValue('')
	}

	const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEditInputValue(e.target.value)
	}

	const handleEditInputConfirm = () => {
		if (type === 'email' && !emailRegExp.test(editInputValue) && editInputValue) {
			put(NotificationOpen('error','Неккоректный E-mail'))
			return
		}
		const arr = type === 'email' ?
			(tags as TEmail[]).map(it=>it.email) :
			(tags as TPhone[]).map(it=>it.phone)

		const isValid = matchDuplicate(arr,editInputValue,editInputIndex)
		if (isValid) {
			put(NotificationOpen('error', 'Необходимо указать уникальный email/телефон'))
			return
		}

		const newTags = [...tags] as any
		const field = type === 'email' ? 'email' : 'phone'
		newTags[editInputIndex][field] = editInputValue
		setFieldValue(name, newTags)
		setEditInputIndex(-1)
		setEditInputValue('')
	}


	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return
		}

		const items = reorder(tags, result.source.index, result.destination.index)

		setFieldValue(name, items)
	}

	return (
		<div className="field-organization__tags">
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={'tags'} direction={'horizontal'}>
					{(provided, snapshot) => (
						<div ref={provided.innerRef} {...provided.droppableProps} className="tags-droppable">
							{tags &&
								tags.map((item: any, index: number) => {
									const tag = type === 'email' ? item.email : item.phone
									if (editInputIndex === index) {
										return (
											<React.Fragment key={tag + index + type}>
												{type === 'phone' && (
													<MaskedInput
														className="tag-input"
														mask={'1(111) 111 11 11'}
														type="tel"
														placeholder="Ваш телефон"
														onChange={handleEditInputChange}
														onBlur={handleEditInputConfirm}
														onPressEnter={handleEditInputConfirm}
														value={editInputValue}
													/>
												)}
												{type === 'email' && (
													<Input
														ref={editInputRef}
														size="small"
														className="tag-input"
														value={editInputValue}
														onChange={handleEditInputChange}
														onBlur={handleEditInputConfirm}
														onPressEnter={handleEditInputConfirm}

													/>
												)}
											</React.Fragment>

										)
									}
									return (
										<DragDropTag
											key={tag + index}
											name={name}
											index={index}
											tag={tag}
											tags={tags}
											handleClose={handleClose}
											setEditInputValue={setEditInputValue}
											setEditInputIndex={setEditInputIndex}
										/>
									)
								})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			{inputVisible && (
				<>
					{type === 'phone' && (
						<MaskedInput
							className="tag-input"
							mask={'1(111) 111 11 11'}
							type="tel"
							placeholder="Ваш телефон"
							onChange={handleInputChange}
							onBlur={handleInputConfirm}
							onPressEnter={handleInputConfirm}
							value={inputValue}
							ref={inputRef}
						/>
					)}
					{type === 'email' && (
						<Input
							ref={inputRef}
							size="small"
							className="tag-input"
							value={inputValue}
							onChange={handleInputChange}
							onBlur={handleInputConfirm}
							onPressEnter={handleInputConfirm}
							placeholder={'Ваш e-mail'}
						/>
					)}
				</>

			)}
			{!inputVisible && <Tag onClick={showInput}>+{type === 'phone' ? ' Добавить телефон' : ' Добавить e-mail'}</Tag>}
		</div>
	)
}
export default TagsComponent
