import {axiosRequest} from 'shared/config/axios'
import {Session, SessionSchema} from '../types'
import {IOptions} from 'models/feels'

export class SessionController {
	static sessionsOptionsKey = 'Options: /sessions'
	static getSessionsProjectOptionsKey = 'Options: /sessions/with-projects'
	static getSessionYearsOptionsKey = 'Options: /sessions/years'
	static getSessionYearsProjectOptionsKey = 'Options: /sessions/with-projects/years'

	static async getSessionYears(contestId?: string) {
		return await axiosRequest<number[]>({
			method: 'GET',
			url: '/sessions/years',
			params: {
				contestId: contestId,
			},
			showError: true,
		})
	}
	static async getSessionYearsOptions(contestId?: string) {
		const { data } = await SessionController.getSessionYears(contestId)
		return data?.map((el) => ({ value: `${el}`, label: `${el}` } as IOptions))
	}
	/**
	 * Получение списка сессий
	 */
	static async getSessions({ year, contestId }: Partial<{ year?: string | number; contestId?: string }> = {}) {
		return await axiosRequest<Session[]>({
			method: 'GET',
			url: '/sessions',
			params: {
				year: year,
				contestId: contestId,
			},
			showError: true,
			parser: SessionSchema,
		})
	}
	static async getSessionsOptions({ year, contestId }: Partial<{ year?: string | number; contestId?: string }> = {}) {
		const { data } = await SessionController.getSessions({ year, contestId })
		return data?.length
			? data?.map((el) => {
					return {
						value: `${el.id}`,
						label: `${el.sessionNumber} ${el.sessionType?.name} ${el.year} (${el.contest.code}) ${el.contest.shortName}`,
					} as IOptions
			  })
			: null
	}

	/**
	 * Получение годов c project
	 */
	static async getSessionYearsProject(contestId?: string) {
		return await axiosRequest<number[]>({
			method: 'GET',
			url: '/sessions/with-projects/years',
			params: {
				contestId: contestId,
			},
			showError: true,
		})
	}
	static async getSessionYearsProjectOptions(contestId?: string) {
		const { data } = await SessionController.getSessionYearsProject(contestId)
		return data?.map((el) => ({ value: `${el}`, label: `${el}` } as IOptions))
	}
	/**
	 * Получение списка сессий c project
	 */
	static async getSessionsProject({ year, contestId }: Partial<{ year?: string | number; contestId?: string }> = {}) {
		return await axiosRequest<Session[]>({
			method: 'GET',
			url: '/sessions/with-projects',
			params: {
				year: year,
				contestId: contestId,
			},
			showError: true,
			parser: SessionSchema,
		})
	}
	static async getSessionsProjectOptions({ year, contestId }: Partial<{ year?: string | number; contestId?: string }> = {}) {
		const { data } = await SessionController.getSessionsProject({ year, contestId })
		return data?.length
			? data?.map((el) => {
				return {
					value: `${el.id}`,
					label: `${el.sessionNumber} ${el.sessionType?.name} ${el.year} (${el.contest.code}) ${el.contest.shortName}`,
				} as IOptions
			})
			: null
	}
}
