import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetProjectsReachDataErrorAction,
    GetProjectsReachDataStartAction,
    GetProjectsReachDataSuccessAction,
} from "../../store/projects/actions";

import Api from "../../entities/project/api/project";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../store/notification/actions";
import {T_ID_NAME} from "../../models/common";


export function* getProjectsReachData({sessionId}: ReturnType<typeof GetProjectsReachDataStartAction>) {
    try {
        const { data: projects }: AxiosResponse<T_ID_NAME[]> = yield Api.getProjectsWithReachData(sessionId);
        yield put(GetProjectsReachDataSuccessAction(projects))
    } catch (error: any) {
        yield put(GetProjectsReachDataErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message))
    }
}
