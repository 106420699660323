import {put} from 'redux-saga/effects'
import {
	BulkLegalFormOrgActionsErrorAction,
	BulkLegalFormOrgActionsStartAction,
	BulkLegalFormOrgActionsSuccessAction,
	GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import LegalFormOrganizationService from "services/legalFormOrganizationService";

export function* bulkLegalFormOrganizationActions({ userArr, action }: ReturnType<typeof BulkLegalFormOrgActionsStartAction>) {
	try {
		yield LegalFormOrganizationService.bulkLegalFormOrganizationActions(userArr, action)

		yield put(BulkLegalFormOrgActionsSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.legalFormOrganization))
		yield put(NotificationOpen('success', 'Обновлено успешно'))

	} catch (error: any) {
		yield put(BulkLegalFormOrgActionsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}
}
