import {put} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {
    GetPersonalSettingStartAction,
    UpdatePersonalSettingErrorAction,
    UpdatePersonalSettingStartAction,
    UpdatePersonalSettingSuccessAction,
} from 'store/options/actions/actions-personal-setting'
import PersonalSettingService from 'services/personalSettingService'

export function* updatePersonalSetting({ id, data }: ReturnType<typeof UpdatePersonalSettingStartAction>) {
	try {
		yield PersonalSettingService.updatePersonalSetting(id, data)

		yield put(UpdatePersonalSettingSuccessAction())
		yield put(GetPersonalSettingStartAction(id))
		yield put(NotificationOpen('success', 'Настройки обновлены'))
	} catch (error: any) {
		yield put(UpdatePersonalSettingErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить'))
	}
}
