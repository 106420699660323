import React from 'react'
import styles from './styles.module.less'
import { DatePickerComponent } from 'components/formic-control'
import { useFormikContext } from 'formik'
import { InitialValueDto } from '../../lib/dto'
import moment from "moment";

const Dates = () => {
	const { values } = useFormikContext<InitialValueDto>()
	return (
		<>
			<div className={styles.item}>
				<span className={styles.label}>
					Первая дата формирования отчета <sup>*</sup>
				</span>
				<DatePickerComponent
					name={'firstFormationDate'}
					placeholder={'Выберите первую дату формирования отчета'}
					isAfter={true}
					isAfterDate={new Date()}
				/>
			</div>
			<div className={styles.item}>
				<span className={styles.label}>
					Последняя дата формирования отчета <sup>*</sup>
				</span>
				<DatePickerComponent
					disabled={!values.firstFormationDate}
					name={'lastFormationDate'}
					placeholder={'Выберите последнюю дату формирования отчета'}
					isAfter={true}
					isAfterDate={values.firstFormationDate ?
						moment(values.firstFormationDate).add(1, 'day')?.toString()
						: undefined
					}
				/>
			</div>
		</>
	)
}

export default Dates
