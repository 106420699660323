import {AxiosResponse} from 'axios'
import $api from '../http'
import {TArchiveFilter} from "../models/archive";

/**
 * @category Service
 * @subcategory All
 * @class ArchiveService
 */
export default class ArchiveService {
    /**
     * @static method getArchiveList()
     * @description Получение списка запросов на формирование архивов текущего пользователя
     */
    static async getArchiveList(data: TArchiveFilter): Promise<AxiosResponse> {
        const filter = {...data, page: data.page - 1}
        return $api.post<any>(`/file-archive-requests/search`,filter)
    }
    /**
     * @static method deleteRecord()
     * @description Удаление запроса
     */
    static async deleteRecord(id:string): Promise<AxiosResponse> {
        return $api.delete<any>(`/file-archive-requests/${id}`)
    }
    /**
     * @static method deleteRecords()
     * @description Удаление запросов
     */
    static async deleteRecords(recordsId:string[]): Promise<AxiosResponse> {
        return $api.delete<any>(`/file-archive-requests/bulk`,{data: recordsId})
    }

}
