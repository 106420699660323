import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {
    GetOrganizationErrorAction,
    GetOrganizationStartAction,
    GetOrganizationSuccessAction
} from "store/options/actions/actions-handbook";
import {THandbookOrganization} from "models/handbook";
import HandbookService from "services/handbookService";


export function* getOrganization({id}: ReturnType<typeof GetOrganizationStartAction>) {
    try {
        const { data: organization}: AxiosResponse<THandbookOrganization> = yield HandbookService.getOrganizationIdRecord(id);

        yield put(GetOrganizationSuccessAction(organization))
    } catch (error: any) {
        yield put(GetOrganizationErrorAction(error))
    }
}
