import { FunctionalityInfoType } from '../types'

export const optionsActionsUsers: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка пользователей с поиском, фильтрацией, сортировкой, пагинацией',
		LevelGroupData: [
			{
				name: 'Переход на другую вкладку',
				text: 'Нажмите для перехода на другую вкладку',
				screenshots: ['/images/screenshots/options-actions-users/tab.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Поиск пользователей',
				text: 'Для поиска:',
				points: ['Нажмите "Поиск"', 'Введите фамилию / имя / логин пользователя'],
				screenshots: ['/images/screenshots/options-actions-users/search.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Фильтрация пользователей',
				text: 'Выберите значения из выпадающего списка для фильтрации списка',
				screenshots: ['/images/screenshots/options-actions-users/filter-role.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Сортировка отображения таблицы действий пользователей',
				text: 'Нажимайте на стрелки для сортировки отображения таблицы',
				screenshots: ['/images/screenshots/options-actions-users/table-sorting.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Настройка количества данных, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице данных:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр данных запроса и ответа пользователя во вкладке "Действия пользователей"',
		LevelGroupData: [
			{
				name: 'Просмотр данных запроса и ответа пользователя',
				text: 'Нажмите для просмотра данных запроса и ответа пользователя',
				screenshots: ['/images/screenshots/options-actions-users/actions-users.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
		],
	},
]
