import {
    GetOrganizationsUserErrorAction,
    GetOrganizationsUserStartAction,
    GetOrganizationsUserSuccessAction
} from "../../../store/options/actions/actions-users";
import {AxiosResponse} from "axios";
import UsersService from "../../../services/usersService";
import {put} from "redux-saga/effects";
import CreateErrorMessage from "../../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../../store/notification/actions";
import {TOrganizationsUser} from "../../../models/user";

export function* getOrganizationsUser({userId}: ReturnType<typeof GetOrganizationsUserStartAction>) {
    try {
        const { data }: AxiosResponse<TOrganizationsUser[]> = yield UsersService.getOrganizationsUser(userId);
        yield put(GetOrganizationsUserSuccessAction(data))

    } catch (error: any) {
        yield put(GetOrganizationsUserErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить данные'))
    }
}