export function transformationValues(value: number | any) {
    if (!value) return 0 + ''
    const numToString = `${value?.toFixed(0)}`;
    if (numToString.length >= 10) {
        const zeroValue = numToString.slice(numToString.length - 9, numToString.length - 7)
        if(Number(zeroValue) === 0) return numToString.slice(0, numToString.length - 9) + ' млрд'
        return numToString.slice(0, numToString.length - 9)+ '.' + numToString.slice(numToString.length - 9, numToString.length - 6) + ' млрд'
    } else if (numToString.length >= 7) {
        const zeroValue = numToString.slice(numToString.length - 6, numToString.length - 4)
        if(Number(zeroValue) === 0) return numToString.slice(0, numToString.length - 6) + ' млн'
        return numToString.slice(0, numToString.length - 6)+ '.' + numToString.slice(numToString.length - 6, numToString.length - 4) + ' млн'
    } else if (numToString.length >= 4) {
        return numToString.slice(0, numToString.length - 3) + ' тыс'
    } else return value
}

export function transformationValuesDay(day: number) {
    const days = ['день','дня','дней'];
    let result;
    let count = day % 100;
    if (count >= 5 && count <= 20) result = days[2];
    else {
        count = count % 10;
        if (count === 1) result = days[0];
        else if (count >= 2 && count <= 4) result = days[1];
        else result = days[2];
    }
    return result;
}

export const transformRolesBack = (type: any) => {
        switch (type[0]) {
            case 'Администратор':
                return ['ROLE_ADMIN']
            case 'Подрядчик':
                return ['ROLE_CONTRACTOR']
            case 'Координатор':
                return ['ROLE_COORDINATOR']
            case 'Аналитик':
                return ['ROLE_ANALYST']
            case 'Продюсер':
                return ['ROLE_PRODUCER']
            case 'Финансист':
                return ['ROLE_FINANCIER']
            case 'Юрист':
                return ['ROLE_LAWYER']
            case 'Клиент':
                return ['ROLE_CLIENT']
            case 'Редактор каталога':
                return ['ROLE_MODERATOR']
            case 'Представитель':
                return ['ROLE_AGENT']
            case 'Редактор контента':
                return ['ROLE_CONTENT_EDITOR']
            case 'Менеджер проектов':
                return ['ROLE_PROJECT_MANAGER']
            case 'Наблюдатель':
                return ['ROLE_VIEWER']
            case 'Администратор информационной безопасности':
                return ['ROLE_ADMIN_IS']
        }
}

export const formatAnalyticsData = (data: any) => {
    let arr: any = []
    if ('contestAnalyticSummaries' in data) {
        arr = JSON.parse(JSON.stringify(data)).contestAnalyticSummaries
    } else {
        arr = data
    }
    return arr.map((it: any) => {
        for (let key in it) {
            if (typeof it[key] === 'object' && it[key]?.length) {
                it['children'] = it[key]
                delete it[key]
            }
            if (it[key] && typeof it[key] === 'object' && 'id' in it[key] && 'name' in it[key]) {
                it['id'] = it[key]['id']
                if ('contest' in it) {
                    it['contest'] = `(${it[key]['code']}) ${it[key]['shortName']}`
                } else {
                    it['contest'] = it[key]['name']
                    delete it[key]
                }
            }
            if (it[key] && typeof it[key] === 'object' && 'id' in it[key] && 'sessionNumber' in it[key]) {
                    it['contest'] = it[key]['sessionNumber'] + ' ' +   it[key]['sessionType']['name']
                    delete it[key]
            }
            if (key === 'year') {
                it['contest'] = it[key]
                it['id'] = String(it[key])
                delete it['year']
            }
        }
        if ('children' in it) {
            it['children'] = formatAnalyticsData(JSON.parse(JSON.stringify(it['children'])))
        }
        return it
    })
}

