import {TagsActionTypes} from "./action-types";
import {TTag, TTagSecondLevel} from "../../models/tags";

/**
 * Получение всех тегов 1-го уровня с возможностью фильтрации по тематикам
 */
export const GetTagsFirstLevelWithThematicStartAction = (thematicIds?: Array<string>) => ({
	type: TagsActionTypes.GET_TAGS_FIRST_LEVEL_THEMATICS_START,
	thematicIds
})
export const GetTagsFirstLevelWithThematicSuccessAction = (data: TTag[]) => ({
	type: TagsActionTypes.GET_TAGS_FIRST_LEVEL_THEMATICS_SUCCESS,
	data,
})
export const GetTagsFirstLevelWithThematicErrorAction = (error: any) => ({
	type: TagsActionTypes.GET_TAGS_FIRST_LEVEL_THEMATICS_ERROR,
	error,
})


/**
 * Получение тегов контента 1-го уровня, указанных в записях задач типа контент
 */
export const GetTagsFirstLevelTaskRecordContentStartAction = () => ({
	type: TagsActionTypes.GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_START,

})
export const GetTagsFirstLevelTaskRecordContentSuccessAction = (data: TTag[]) => ({
	type: TagsActionTypes.GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_SUCCESS,
	data,
})
export const GetTagsFirstLevelTaskRecordContentErrorAction = (error: any) => ({
	type: TagsActionTypes.GET_TAGS_FIRST_LEVEL_TASK_RECORD_CONTENT_ERROR,
	error,
})

/**
 *
 * Получение тегов контента 2-го уровня, указанных в контенте; с возможностью фильтрации по тегам 1-го уровня
 */
export const GetTagsSecondLevelTaskRecordContentStartAction = (firstLevelTagIds?: Array<string>) => ({
	type: TagsActionTypes.GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_START,
	firstLevelTagIds
})
export const GetTagsSecondLevelTaskRecordContentSuccessAction = (data: TTagSecondLevel[]) => ({
	type: TagsActionTypes.GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_SUCCESS,
	data,
})
export const GetTagsSecondLevelTaskRecordContentErrorAction = (error: any) => ({
	type: TagsActionTypes.GET_TAGS_SECOND_LEVEL_TASK_RECORD_CONTENT_ERROR,
	error,
})

/**
 *
 * Получение тегов контента 3-го уровня, указанных в контенте; с возможностью фильтрации по тегам 2-го уровня
 */
export const GetTagsThirdLevelTaskRecordContentStartAction = (secondLevelTagIds?: Array<string>) => ({
	type: TagsActionTypes.GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_START,
	secondLevelTagIds
})
export const GetTagsThirdLevelTaskRecordContentSuccessAction = (data: TTagSecondLevel[]) => ({
	type: TagsActionTypes.GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_SUCCESS,
	data,
})
export const GetTagsThirdLevelTaskRecordContentErrorAction = (error: any) => ({
	type: TagsActionTypes.GET_TAGS_THIRD_LEVEL_TASK_RECORD_CONTENT_ERROR,
	error,
})

/**
 * Получение всех тегов 2-го уровня с возможностью фильтрации по тегам первого уровня
 */
export const GetTagsSecondLevelWithFirstStartAction = (firstLevelTagIds?: Array<string>) => ({
	type: TagsActionTypes.GET_TAGS_SECOND_LEVEL_WITH_FIRST_START,
	firstLevelTagIds
})
export const GetTagsSecondLevelWithFirstSuccessAction = (data: TTagSecondLevel[]) => ({
	type: TagsActionTypes.GET_TAGS_SECOND_LEVEL_WITH_FIRST_SUCCESS,
	data,
})
export const GetTagsSecondLevelWithFirstErrorAction= (error: any) => ({
	type: TagsActionTypes.GET_TAGS_SECOND_LEVEL_WITH_FIRST_ERROR,
	error,
})


/**
 *
 * Получение тегов контента 1-го уровня, указанных в контенте
 */
export const GetTagsFirstLevelContentStartAction = () => ({
	type: TagsActionTypes.GET_TAGS_FIRST_LEVEL_CONTENT_START,

})
export const GetTagsFirstLevelContentSuccessAction = (data: TTag[]) => ({
	type: TagsActionTypes.GET_TAGS_FIRST_LEVEL_CONTENT_SUCCESS,
	data,
})
export const GetTagsFirstLevelContentErrorAction = (error: any) => ({
	type: TagsActionTypes.GET_TAGS_FIRST_LEVEL_CONTENT_ERROR,
	error,
})

/**
 *
 * Получение тегов контента 2-го уровня, указанных в контенте; с возможностью фильтрации по тегам 1-го уровня
 */
export const GetTagsSecondLevelContentStartAction = (firstLevelTagIds?: Array<string>) => ({
	type: TagsActionTypes.GET_TAGS_SECOND_LEVEL_CONTENT_START,
	firstLevelTagIds
})
export const GetTagsSecondLevelContentSuccessAction = (data: TTagSecondLevel[]) => ({
	type: TagsActionTypes.GET_TAGS_SECOND_LEVEL_CONTENT_SUCCESS,
	data,
})
export const GetTagsSecondLevelContentErrorAction = (error: any) => ({
	type: TagsActionTypes.GET_TAGS_SECOND_LEVEL_CONTENT_ERROR,
	error,
})