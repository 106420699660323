import {z} from "zod";
export const TagsSchema = z.object({
    id: z.string(),
    code: z.string(),
    name: z.string(),
    hidden: z.boolean(),
    level: z.number(), //Уровень тега
    isForNationalProject: z.boolean(), // Признак. Тег национального проекта
})

export type Tags = z.infer<typeof TagsSchema>
