import {AxiosResponse} from 'axios'
import $api from '../http'
import {
    TDocumentThematicsListResponse,
    THandbookContentResponse,
    THandbookFilter,
    THandbookOrganization,
    THandbookOrganizationRequest,
    THandbookOrganizationResponse,
    TInternetResourceResponse,
    TTHandbookContentOverAll,
    TThematicOrDocuments,
    TThematicResponse,
    TTypeOverallDataRequest
} from 'models/handbook'
import {T_ID_NAME_CODE} from "../models/common";

/**
 * @category Service
 * @class HandbookService
 */
export default class HandbookService {
    /**
     * Получение списка темотических линий с фильтрами
     */
    static async getHandBookThematics(data: THandbookFilter): Promise<AxiosResponse<TThematicResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<TThematicResponse>(`/projects/thematics/search`, filter)
    }

    /**
     * Получение списка интернет-ресурсов с фильтрами
     */
    static async getHandBookInternetResources(data: THandbookFilter): Promise<AxiosResponse<TInternetResourceResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<TInternetResourceResponse>('/internet-resources/search', filter)
    }


    /**
     * Получение списка документов с фильтрами
     */
    static async getHandBookDocuments(data: THandbookFilter): Promise<AxiosResponse<TDocumentThematicsListResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<TDocumentThematicsListResponse>('/document-types/search', filter)
    }

    /**
     * Получение списка форм сдачи контента с фильтрами
     */
    static async getHandBookContentDelivery(data: THandbookFilter): Promise<AxiosResponse<THandbookContentResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<THandbookContentResponse>('/projects/submission_forms/search', filter)
    }

    /**
     * Получение списка организаций с фильтрами
     */
    static async getHandBookOrganization(data: THandbookFilter): Promise<AxiosResponse<THandbookOrganizationResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<THandbookOrganizationResponse>('/organizations/search', filter)
    }

    /**
     * Получение списка действий пользователя с фильтрами
     */
    static async getHandBookActionsUsers(data: THandbookFilter): Promise<AxiosResponse<THandbookContentResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<THandbookContentResponse>('/users/actions/types/search', filter)
    }

    /**
     * Получение списка данных КПЭ с фильтрами
     */
    static async getHandBookKPE(data: THandbookFilter): Promise<AxiosResponse<THandbookContentResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<THandbookContentResponse>('/kpi-types/search', filter)
    }

    /**
     * Получение списка направлений контента с фильтрами
     */
    static async getHandBookContentDirection(data: THandbookFilter): Promise<AxiosResponse<THandbookContentResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<THandbookContentResponse>('/projects/directions/search', filter)
    }
    /**
     *  Добавление тематической линии
     */
    static async addThematicRecord( data: TThematicOrDocuments): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/projects/thematics`, data)
    }

    /**
     *   Обновление тематической линии
     */
    static async updateThematicRecord(data: TThematicOrDocuments, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/projects/thematics/${id}`, data)
    }
    /**
     *   Удаление тематической линии
     */
    static async deleteThematicRecord(id: string): Promise<AxiosResponse> {
        return $api.delete<any>(`/projects/thematics/${id}`)
    }
    /**
     *   Массовые действия тематика
     */
    static async bulkThematicsActions(usersArr: string[], action: string): Promise<AxiosResponse> {
        return $api.post<any>(`/projects/thematics/bulk-actions/${action}`, usersArr)
    }

    /**
     *  Добавление типов контента
     */
    static async addContentTypeRecord( data: TTypeOverallDataRequest): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/projects/format_types`, data)
    }
    /**
     *   Обновление типов контента
     */
    static async updateContentTypeRecord( data: TTypeOverallDataRequest, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/projects/format_types/${id}`, data)
    }
    /**
     *   Удаление типов контента
     */
    static async deleteContentTypeRecord(id: string): Promise<AxiosResponse> {
        return $api.delete<any>(`/projects/format_types/${id}`)
    }
    /**
     *   Массовые действия типов контента
     */
    static async bulkContentTypeActions(usersArr: string[], action: string): Promise<AxiosResponse> {
        return $api.post<any>(`/projects/format_types/bulk-actions/${action}`, usersArr)
    }

    /**
     * @description Добавление форм сдачи контента
     * @static method addContentDeliveryRecord
     * @param {TTypeOverallDataRequest} data
     */
    static async addContentDeliveryRecord( data: TTypeOverallDataRequest): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/projects/submission_forms`, data)
    }
    /**
     *  Получение всех форм сдачи контента проектов
     */
    static async getAllContentDeliveryRecord(): Promise<AxiosResponse<TTHandbookContentOverAll>> {
        return $api.get<TTHandbookContentOverAll>(`/projects/submission_forms`)
    }
    /**
     *   Обновление форм сдачи контента
     */
    static async updateContentDeliveryRecord( data: TTypeOverallDataRequest, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/projects/submission_forms/${id}`, data)
    }
    /**
     *   Удаление форм сдачи контента
     */
    static async deleteContentDeliveryRecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/projects/submission_forms/${id}`)
    }
    /**
     *   Массовые действия форм сдачи контента
     */
    static async bulkContentDeliveryActions(usersArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/projects/submission_forms/bulk-actions/${action}`, usersArr)
    }

    /**
     *   Обновление действий пользователя
     */
    static async updateActionsUsersRecord( data: TTypeOverallDataRequest, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/users/actions/types/${id}`, data)
    }


    /**
     *  Добавление KPE
     */
    static async addKPERecord( data: TTypeOverallDataRequest): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/kpi-types`, data)
    }
    /**
     *   Обновление KPE
     */
    static async updateKPERecord( data: TTypeOverallDataRequest, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/kpi-types/${id}`, data)
    }
    /**
     *   Удаление KPE
     */
    static async deleteKPERecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/kpi-types/${id}`)
    }
    /**
     *   Массовые действия KPE
     */
    static async bulkKPEActions(usersArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/kpi-types/bulk-actions/${action}`, usersArr)
    }


    /**
     *  Добавление направления контента
     */
    static async addContentDirectionRecord( data: TTypeOverallDataRequest): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/projects/directions`, data)
    }
    /**
     *   Обновление направления контента
     */
    static async updateContentDirectionRecord( data: TTypeOverallDataRequest, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/projects/directions/${id}`, data)
    }
    /**
     *   Удаление направления контента
     */
    static async deleteContentDirectionRecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/projects/directions/${id}`)
    }
    /**
     *   Массовые направления контента
     */
    static async bulkContentDirectionActions(usersArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/projects/directions/bulk-actions/${action}`, usersArr)
    }




    /**
     *  Добавление  списка организаций
     */
    static async addOrganizationRecord( data: THandbookOrganizationRequest): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/organizations`, data)
    }
    /**
     *   Обновление списка организаций
     */
    static async updateOrganizationRecord( data: THandbookOrganizationRequest, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/organizations/${id}`, data)
    }

    /**
     *   Получение организации по ID
     */
    static async getOrganizationIdRecord(id: string): Promise<AxiosResponse<THandbookOrganization>> {
        return $api.get<THandbookOrganization>(`/organizations/${id}`)
    }

    /**
     *   Удаление списка организаций
     */
    static async deleteOrganizationRecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/organizations/${id}`)
    }
    /**
     *   Массовые списка организаций
     */
    static async bulkOrganizationActions(usersArr: string[], action: string): Promise<AxiosResponse> {
        return $api.post<string[]>(`/organizations/bulk-actions/${action}`, usersArr)
    }


    /**
     *  Добавление документов
     */
    static async addDocumentsRecord( data: TTypeOverallDataRequest): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/document-types`, data)
    }
    /**
     *   Обновление документов
     */
    static async updateDocumentsRecord( data: TTypeOverallDataRequest, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/document-types/${id}`, data)
    }
    /**
     *   Удаление документов
     */
    static async deleteDocumentsRecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/document-types/${id}`)
    }
    /**
     *   Массовые действия документов
     */
    static async bulkDocumentsActions(documentsArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/document-types/bulk-actions/${action}`, documentsArr)
    }

    /**
     *  Создание нового тэга 1-го уровня
     */
    static async addFirstTagRecord( data: TTypeOverallDataRequest): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/project-tagging/first-lvl-tags`, data)
    }
    /**
     *   Обновление тэга 1-го уровня
     */
    static async updateFirstTagRecord( data: TTypeOverallDataRequest, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/project-tagging/first-lvl-tags/${id}`, data)
    }
    /**
     *   Удаление тэга 1-го уровня
     */
    static async deleteFirstTagRecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/project-tagging/first-lvl-tags/${id}`)
    }
    /**
     *   Массовые действия тэга 1-го уровня
     */
    static async bulkFirstTagActions(usersArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/project-tagging/first-lvl-tags/bulk-actions/${action}`, usersArr)
    }

    /**
     *   Получить все тэги 1-го уровня
     */
    static async getAllFirstTag(): Promise<AxiosResponse<any>> {
        return $api.get<any>(`/project-tagging/first-lvl-tags`)
    }

    /**
     *  Создание нового тэга 2-го уровня
     */
    static async addSecondTagRecord( data: TTypeOverallDataRequest): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/project-tagging/second-lvl-tags`, data)
    }
    /**
     *   Обновление тэга 2-го уровня
     */
    static async updateSecondTagRecord( data: TTypeOverallDataRequest, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/project-tagging/second-lvl-tags/${id}`, data)
    }
    /**
     *   Удаление тэга 2-го уровня
     */
    static async deleteSecondTagRecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/project-tagging/second-lvl-tags/${id}`)
    }
    /**
     *   Массовые действия тэга 2-го уровня
     */
    static async bulkSecondTagActions(usersArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/project-tagging/second-lvl-tags/bulk-actions/${action}`, usersArr)
    }

    /**
     *   Получить все тэги 2-го уровня
     */
    static async getAllSecondTag(): Promise<AxiosResponse<any>> {
        return $api.get<any>(`/project-tagging/second-lvl-tags`)
    }

    /**
     *  Создание нового тэга 3-го уровня
     */
    static async addThirdTagRecord( data: TTypeOverallDataRequest): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/project-tagging/third-lvl-tags`, data)
    }
    /**
     *   Обновление тэга 3-го уровня
     */
    static async updateThirdTagRecord( data: TTypeOverallDataRequest, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/project-tagging/third-lvl-tags/${id}`, data)
    }
    /**
     *   Удаление тэга 3-го уровня
     */
    static async deleteThirdTagRecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/project-tagging/third-lvl-tags/${id}`)
    }
    /**
     *   Массовые действия тэга 3-го уровня
     */
    static async bulkThirdTagActions(usersArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/project-tagging/third-lvl-tags/bulk-actions/${action}`, usersArr)
    }

    /**
     *   Получить все тэги 3-го уровня
     */
    static async getAllThirdTag(): Promise<AxiosResponse<any>> {
        return $api.get<any>(`/project-tagging/third-lvl-tags`)
    }


    /**
     *   Выгрузка данных справочниках для организаций
     */
    static async downloadOrganizations(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/organizations/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }

    /**
     *   Выгрузка шаблона для организаций
     */
    static async downloadOrganizationsTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/organizations/import/template',
            responseType:'arraybuffer',
        })
    }

    /**
     *   Выгрузка шаблона для тэги проекта 1-го уровня
     */
    static async unloadingFirstTagTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/project-tagging/first-lvl-tags/import/template',
            responseType:'arraybuffer',
        })
    }

    /**
     *   Выгрузка шаблона для тэги проекта 2-го уровня
     */
    static async unloadingSecondTagTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/project-tagging/second-lvl-tags/import/template',
            responseType:'arraybuffer',
        })
    }
    /**
     *   Выгрузка шаблона для тэги проекта 3-го уровня
     */
    static async unloadingThirdTagTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/project-tagging/third-lvl-tags/import/template',
            responseType:'arraybuffer',
        })
    }
    /**
     *   Выгрузка шаблона для регионов
     */
    static async unloadingRegionsTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/region/types/import/template',
            responseType:'arraybuffer',
        })
    }
    /**
     *   Выгрузка шаблона для ranks
     */
    static async unloadingRanksTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/rank/import/template',
            responseType:'arraybuffer',
        })
    }

    /**
     *   Обновление USERS ACTIONS
     */
    static async updateUsersActionsRecord( data: TTypeOverallDataRequest, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/users/actions/types/${id}`, data)
    }


    /**
     * Загрузить файл организаций
     */
    static async uploadOrganization (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/organizations/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }

    static async uploadKpe (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/kpi-types/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }

    /**
     *   Выгрузка шаблона для kpe
     */
    static async downloadKPETemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/kpi-types/import/template',
            responseType:'arraybuffer',
        })
    }

    /**
     *   Выгрузка данных справочниках для kpe
     */
    static async downloadKpe(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/kpi-types/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }

    /**
     * Загрузить файл thematic
     */
    static async uploadThematic (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/projects/thematics/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }

    /**
     *   Выгрузка шаблона для thematics
     */
    static async downloadThematicTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/projects/thematics/import/template',
            responseType:'arraybuffer',
        })
    }

    /**
     *   Выгрузка данных справочниках для thematics
     */
    static async downloadThematics(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/projects/thematics/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }

    /**
     * Загрузить файл ContentType
     */
    static async uploadContentType (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/projects/format_types/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }

    /**
     *   Выгрузка шаблона для ContentType
     */
    static async downloadContentTypeTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/projects/format_types/import/template',
            responseType:'arraybuffer',
        })
    }

    /**
     *   Выгрузка данных справочниках для ContentType
     */
    static async downloadContentType(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/projects/format_types/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }
    /**
     * Загрузить файл ContentDelivery
     */
    static async uploadContentDelivery(data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/projects/submission_forms/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }

    /**
     *   Выгрузка шаблона для ContentDelivery
     */
    static async downloadContentDeliveryTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/projects/submission_forms/import/template',
            responseType:'arraybuffer',
        })
    }

    /**
     *   Выгрузка данных справочниках для ContentDelivery
     */
    static async downloadContentDelivery(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/projects/submission_forms/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }

    /**
     * Загрузить файл ContentDirection
     */
    static async uploadContentDirection(data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/projects/directions/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }

    /**
     *   Выгрузка шаблона для ContentDirection
     */
    static async downloadContentDirectionTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/projects/directions/import/template',
            responseType:'arraybuffer',
        })
    }

    /**
     *   Выгрузка данных справочниках для ContentDirection
     */
    static async downloadContentDirection(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/projects/directions/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }

    /**
     * Загрузить файл Documents
     */
    static async uploadDocuments(data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/document-types/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }

    /**
     *   Выгрузка шаблона для Documents
     */
    static async downloadDocumentsTemplate( ): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/document-types/import/template',
            responseType:'arraybuffer',
        })
    }

    /**
     *   Выгрузка данных справочниках для Documents
     */
    static async downloadDocuments(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/document-types/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }

    /**
     * Импорт тэгов 1-го уровня
     */
    static async importFirstTag (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/project-tagging/first-lvl-tags/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }

    /**
     *   Выгрузка данных справочниках для тэгов 1-го уровня
     */
    static async downloadFirstTag (data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/project-tagging/first-lvl-tags/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }

    /**
     * Импорт тэгов 2-го уровня
     */
    static async importSecondTag (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/project-tagging/second-lvl-tags/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    /**
     * Импорт тэгов 3-го уровня
     */
    static async importThirdTag (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/project-tagging/third-lvl-tags/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    /**
     * Импорт regions
     */
    static async importRegions (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/region/types/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    /**
     * Импорт ranks
     */
    static async importRanks (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/rank/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    /**
     *   Выгрузка данных справочниках для тэгов 2-го уровня
     */
    static async downloadSecondTag (data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/project-tagging/second-lvl-tags/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }
    /**
     *   Выгрузка данных справочниках для тэгов 3-го уровня
     */
    static async downloadThirdTag (data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/project-tagging/third-lvl-tags/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }
    /**
     *   Выгрузка данных справочниках для regions
     */
    static async downloadRegions(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/region/types/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }

    /**
     *   Выгрузка данных справочниках для ranks
     */
    static async downloadRanks(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/rank/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }

    /**
     *   Получение всех разделов модуля контента
     */
    static async getContentModuleSection(): Promise<AxiosResponse<T_ID_NAME_CODE[]>> {
        return $api.get<T_ID_NAME_CODE[]>(`/content-module-section`)
    }

}

