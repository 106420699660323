import {InferValueTypes, T_ID_NAME_CODE_HIDDEN} from '../../models/common'
import * as actions from './actions'
import {ErrorActionState, StartActionState, SuccessActionState} from '../../utils/reducerHalper'
import {Logout} from '../auth/reducer'
import {AuthActionTypes} from '../auth/action-types'
import {RegionsActionTypes} from './action-types'

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

export type TRegionsState = {
	regions: T_ID_NAME_CODE_HIDDEN[] | null
	isLoading: boolean
	error: any
}

export const initialState: TRegionsState = {
	regions: null,
	isLoading: false,
	error: null,
}

export default function reducer(state: TRegionsState = initialState, action: ActionTypes | Logout): TRegionsState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return { ...initialState }

		/** START ACTIONS  */
		case RegionsActionTypes.GET_REGIONS_START:
			return StartActionState(state)

		/** SUCCESS ACTIONS  */
		case RegionsActionTypes.GET_REGIONS_SUCCESS:
			return {...SuccessActionState(state), regions: action.data}

		/** ERROR ACTIONS  */
		case RegionsActionTypes.GET_REGIONS_ERROR:
			return ErrorActionState(state, action.error)
		default:
			return state
	}
}
