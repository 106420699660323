import {useDispatch, useSelector} from "react-redux";
import {selectSideBarId, selectSidebarStatus,} from "../store/sidebars/selectors";
import {CloseAllSidebarsAction, CloseSidebarAction, OpenSidebarAction, TSidebarsType} from "../store/sidebars/actions";

const useToggleSidebar = () => {
    const put = useDispatch();

    const status = useSelector(selectSidebarStatus);
    const {id, secondId} = useSelector(selectSideBarId);

    const handlerSidebar = (type: TSidebarsType | null, id?: string, secondId?: string) => (_e?: any) => {
        if (type === null) put(CloseAllSidebarsAction())
        else if (status[type]) put(CloseSidebarAction(type));
        else put(OpenSidebarAction(type, id, secondId));
    }
    return {
        menuStatus: status.menu,
        isOpen: status.project,
        taskStatus: status.task,
        usersListStatus: status.usersList,
        notificationStatus: status.notification,
        functionalityInfoStatus: status.functionalityInfo,
        id,
        secondId,
        handlerSidebar
    }
};
export default useToggleSidebar;
