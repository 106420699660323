import { takeLatest } from 'redux-saga/effects'
import getSprints from './getSprints'
import getSprintId from './getSprintId'
import { updateSprint } from './update-sprint'
import { createSprint } from './create-sprint'
import { deleteSprint } from './delete-sprint'
import { SprintsActionTypes } from 'store/sprints/action-types'

export default function* sprintsSaga() {
	yield takeLatest(SprintsActionTypes.GET_SPRINTS_START, getSprints)
	yield takeLatest(SprintsActionTypes.GET_SPRINT_ID_START, getSprintId)
	yield takeLatest(SprintsActionTypes.CREATE_SPRINT_START, createSprint)
	yield takeLatest(SprintsActionTypes.UPDATE_SPRINT_ID_START, updateSprint)
	yield takeLatest(SprintsActionTypes.DELETE_SPRINT_ID_START, deleteSprint)
}
