import React, {FC} from 'react';
import useHandlerModal from "../../../hooks/handlModal";
import {Button, Modal} from "antd";

type Props = {
    status: boolean
    data?: string
}
const ModalErrorResetStatus: FC<Props> = ({status, data}) => {
    const {isVisible, closeModal} = useHandlerModal(status);

    const handleCancel = () => {
        closeModal()
    };
    return (
        <Modal
            className={'Template-modal'}
            title="Ошибка обновление статуса записи"
            visible={isVisible}
            onCancel={handleCancel}
            footer={(
                <Button onClick={handleCancel} type={'primary'}>Закрыть</Button>
            )}
        >
            <p>{data}</p>
        </Modal>
    )
};
export default ModalErrorResetStatus;