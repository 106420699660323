import {OptionsNotificationsActionsTypes} from "../action-types";
import {TNotificationChannels, TNotificationFilter, TNotificationResponse,} from "models/notifications";

/**
 * Получение списка всех уведомлений
 */

export const GetNotificationsListStartAction = () => ({
    type: OptionsNotificationsActionsTypes.GET_NOTIFICATIONS_START,
})

export const GetNotificationsListSuccessAction = (data: TNotificationResponse[]) => ({
    type: OptionsNotificationsActionsTypes.GET_NOTIFICATIONS_SUCCESS,
    payload: data,
})

export const GetNotificationsListErrorAction = (error: any) => ({
    type: OptionsNotificationsActionsTypes.GET_NOTIFICATIONS_ERROR,
    error,
})

/**
 *  Получение всех каналов активных
 */

export const GetActiveNotificationChannelsStartAction = () => ({
    type: OptionsNotificationsActionsTypes.GET_ACTIVE_NOTIFICATIONS_CHANNELS_START
})

export const GetActiveNotificationChannelsSuccessAction = (data: TNotificationChannels[]) => ({
    type: OptionsNotificationsActionsTypes.GET_ACTIVE_NOTIFICATIONS__CHANNELS_SUCCESS,
    data
})

export const GetActiveNotificationChannelsErrorAction = (error: any) => ({
    type: OptionsNotificationsActionsTypes.GET_ACTIVE_NOTIFICATIONS__CHANNELS_ERROR,
    error,
})


/**
 * Изменение фильтра
 */
export const SetNotificationFilter = (filter: TNotificationFilter) => ({
    type: OptionsNotificationsActionsTypes.SET_NOTIFICATION_FILTER,
    filter,
})
/**
 * Очищение массовых действий без запроса на сервер
 */
export const ClearBulkActions = () => ({
    type: OptionsNotificationsActionsTypes.CLEAR_BULK_ACTIONS,
})