import {AxiosResponse} from "axios";
import {put} from "redux-saga/effects";
import {downloadXLXS} from "utils/downloadXLXS";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    ExportReachRecordsErrorAction,
    ExportReachRecordsStartAction,
    ExportReachRecordsSuccessAction
} from "store/tasks/actions/report";
import {TasksReportReachService} from "services/tasksReportService";

export default function* exportReachRecords({rowIds, name}: ReturnType<typeof ExportReachRecordsStartAction>) {
    try {
        const { data: response }: AxiosResponse<any> = yield TasksReportReachService.reachRecordsExport(rowIds)
        yield put(ExportReachRecordsSuccessAction())
        downloadXLXS(response, name)
        yield put(NotificationOpen('success', 'Данные выгружены успешно'))
    } catch (error: any) {
        yield put(ExportReachRecordsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось выгрузить'));
    }
}