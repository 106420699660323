import {AxiosResponse} from "axios";
import $api from "../http";
import {THandbookFilter, THandbookLegaFormOrganizationResponse, TLegalFormOrganization} from "../models/handbook";

export default class LegalFormOrganizationService {
    /**
     * Получение всех правовых форм организаций
     */
    static legalFormOrganizationKey = '/organization-legal-forms'
    static getLegalFormOrganization(): Promise<AxiosResponse<TLegalFormOrganization[]>> {
        return $api.get('/organization-legal-forms')
    }
    /**
     * Получение списка правовых форм с пагинацией, сортировкой и поиском
     */
    static async getLegalFormOrganizationWithFilter(data: THandbookFilter): Promise<AxiosResponse<THandbookLegaFormOrganizationResponse>> {
        const filter = { ...data, page: data.page - 1 }
        return $api.post<THandbookLegaFormOrganizationResponse>('/organization-legal-forms/search', filter)
    }
    /**
     *  Добавление записи правовой формы
     */
    static async addLegalFormOrganizationRecord( data: Omit<TLegalFormOrganization, 'id'>): Promise<AxiosResponse<string>> {
        return $api.post<string>(`/organization-legal-forms`, data)
    }
    /**
     *   Обновление записи правовой формы
     */
    static async updateLegalFormOrganizationRecord( data: Omit<TLegalFormOrganization, 'id'>, id: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/organization-legal-forms/${id}`, data)
    }
    /**
     *   Удаление записи правовой формы
     */
    static async deleteLegalFormOrganizationRecord(id: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/organization-legal-forms/${id}`)
    }
    /**
     *   Массовые действия над правовыми формами организаций
     */
    static async bulkLegalFormOrganizationActions(recordsArr: string[], action: string): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/organization-legal-forms/bulk-actions/${action}`, recordsArr)
    }
    /**
     *   Выгрузка данных правовых форм
     */
    static async downloadLegalFormOrganization(data: string[]): Promise<AxiosResponse<any>> {
        return $api({
            method: 'POST',
            url: `/organization-legal-forms/export`,
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: data,
        })
    }
    /**
     * Выполнение импорта данных о правовых формах организаций из заполненного шаблона в формате Excel
     */
    static async importLegalFormOrganization (data: any): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: `/organization-legal-forms/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    /**
     *   Выгрузка шаблона
     */
    static async downloadLegalFormOrganizationTemplate(): Promise<AxiosResponse<any>> {
        return  $api({
            method: 'GET',
            url:  '/organization-legal-forms/import/template',
            responseType:'arraybuffer',
        })
    }
}