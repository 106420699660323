import {GenerateKeyErrorAction, GenerateKeyStartAction, GenerateKeySuccessAction} from "../../store/auth/actions";
import {AxiosResponse} from "axios";
import AuthService from "../../services/authService";
import {TGenerateKey} from "../../models/common";
import {put} from "redux-saga/effects";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../store/notification/actions";


export function* generateAuthKey ({password} : ReturnType<typeof GenerateKeyStartAction>){
    try {
        const { data: authKey }: AxiosResponse<TGenerateKey> = yield AuthService.generateKey(password);
        yield put(GenerateKeySuccessAction(authKey))
        yield put(NotificationOpen('success', 'Ключ сгенерирован'))

    } catch (error: any) {
        yield put(GenerateKeyErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось сгенерировать ключ'))
    }
}
