import {ActionTypes} from './types'
import {InferValueTypes} from "models/common";
import * as actions from "./actions";
import { SearchContentProjectTypes } from 'features/search-project-content';
type ActionsTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TMainState = {
    isOpen: boolean;
    typeContent: SearchContentProjectTypes.Stores.TTypeStore
}
const initialState: TMainState = {
    isOpen: false,
    typeContent: 'projects',
}
export default function reducer(state: TMainState = initialState, action: ActionsTypes): TMainState {
    switch (action.type) {
        case ActionTypes.SET_VALUE:
            return {...state, ...action.value}
        default:
            return state
    }
}