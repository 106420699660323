import {put} from 'redux-saga/effects'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {
	AddCategoryProjectErrorAction,
	AddCategoryProjectStartAction,
	AddCategoryProjectSuccessAction,
	GetHandbookStartAction,
} from 'store/options/actions/actions-handbook'
import {TStatusesHandbookFeels} from 'models/handbook'
import CategoryProjectService from 'services/categoryProjectService'

export function* addCategoryProject({ data }: ReturnType<typeof AddCategoryProjectStartAction>) {
	try {
		yield CategoryProjectService.addICategoryProjectRecord(data)
		yield put(AddCategoryProjectSuccessAction())
		yield put(GetHandbookStartAction(TStatusesHandbookFeels.categoryProject))

		yield put(NotificationOpen('success', 'Новая категория добавлена'))
	} catch (error: any) {
		yield put(AddCategoryProjectErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить категорию'))
	}
}
