import { Filter, ReportsSchedulersSchema, ResponseList, ScheduleCreate } from '../types'
import { axiosRequest } from 'shared/config/axios'

/**
 *
 * @description Получение списка расписаний формирований отчетов
 */
export const search = {
	key: '/reports/schedulers/search',
	fetch: async (params: Filter): Promise<ResponseList> => {
		const filter = { ...params, page: params.page - 1 }
		const { data } = await axiosRequest<ResponseList>({
			method: 'POST',
			url: '/reports/schedulers/search',
			data: filter,
			parser: { list: true, schema: ReportsSchedulersSchema.array() },
		})
		return data
	},
}

/**
 *
 * @description Обновление расписания формирования отчета
 */
export const updateSchedulers = async (data: ScheduleCreate, id: string) => {
	await axiosRequest({
		method: 'PUT',
		url: `/reports/schedulers/${id}`,
		data,
	})
}

/**
 *
 * @description Создание новых расписаний формирования отчетов для пользователей
 */
export const createSchedulers = async (data: ScheduleCreate) => {
	await axiosRequest({
		method: 'POST',
		url: '/reports/schedulers',
		data,
	})
}

export const deleteScheduler = async (id: string) => {
	await axiosRequest({
		method: 'DELETE',
		url: `/reports/schedulers/${id}`,
	})
}
