import React, {useEffect} from 'react'
import './index.less'
import SearchItem from './items/search'
import StatusSelect from './items/statusSelect'
import ContentModuleSectionsSelect from './items/contenSectionsSelect'
import TypeContentSelect from './items/typeContentSelect'
import WhetherTheUser from 'components/whetherTheUser'
import FormatTypesSelect from './items/formatTypes-Select';
import SubmissionFormsSelect from './items/submissionForms-Select';
import ContentSelect from "./items/contentSelect";
import AccountedContentSelect from "./items/accountedContentSelect";
import {useDispatch, useSelector} from "react-redux";
import {selectRole} from "store/auth/selectors";
import {SetSortingFilter} from "store/content/content/actions";
import {selectFilter} from "store/content/content/selectors";

const Sorting = () => {
	const put = useDispatch()
	const role = useSelector(selectRole)
	const filter = useSelector(selectFilter)

	useEffect(()=>{
		if (role && (role === 'ROLE_AGENT' || role === 'ROLE_VIEWER' || role === 'ROLE_CLIENT')) {
			put(SetSortingFilter({ ...filter, filterData: undefined }))
		}
		// eslint-disable-next-line
	}, [role])

	return (
		<div className="sorting-content">
			<SearchItem />
			<WhetherTheUser role={['ROLE_VIEWER', 'ROLE_AGENT', 'ROLE_CLIENT']} isCan={false}>
				<StatusSelect />
			</WhetherTheUser>
			<ContentModuleSectionsSelect />
			<WhetherTheUser role={['ROLE_VIEWER', 'ROLE_AGENT', 'ROLE_CLIENT']} isCan={false}>
				<>
					<TypeContentSelect />
					<ContentSelect/>
					<AccountedContentSelect/>
				</>
			</WhetherTheUser>
			<FormatTypesSelect/>
			<SubmissionFormsSelect/>
		</div>
	)
}
export default Sorting
