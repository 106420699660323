import {TApplicationState} from "../../aplication-state";
import {createSelector} from "reselect";

const usersActionsState = (state: TApplicationState) => state.options.usersActions

export const selectActivityUsersList = createSelector(usersActionsState, (state) => state.activityUsersData)
export const selectActionsUsersList = createSelector(usersActionsState, (state) => state.actionsUsersData)
export const selectActivityUsersFilter = createSelector(usersActionsState, (state) => state.filterActivity)
export const selectActionsUsersFilter = createSelector(usersActionsState, (state) => state.filterActions)
export const selectActionsUsersIsLoading = createSelector(usersActionsState, (state) => state.isLoading)
