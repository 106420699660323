import React, {FC} from 'react';
import styles from './styles.module.less'
import {Tooltip} from "antd";


const TooltipImage: FC<{src: string}> = ({src, children}) => {

    const img = (
        <div className={styles.tooltip__image}>
            <img alt={'tooltip__image'} src={src}/>
        </div>
    )
    return (
        <Tooltip title={img} placement={'top'} className={styles.tooltip__container}>
            {children}
        </Tooltip>
    )
};

export default TooltipImage;