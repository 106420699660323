import {put} from 'redux-saga/effects'
import {
	CreateUserErrorAction,
	CreateUserStartAction,
	CreateUserSuccessAction,
	GetUsersListStartAction,
} from 'store/options/actions/actions-users'
import UsersService from 'services/usersService'
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {UserDto} from "entities/user";

export function* createUser({ user }: ReturnType<typeof CreateUserStartAction>) {
	try {
		const formData = new FormData();

		const userData = new UserDto.SendCreateUserForm(user)

		formData.append('user', JSON.stringify(userData));

		if(user.image.length !==0) {
			formData.append('image',user.image[0].originFileObj)
		}

		yield UsersService.createUser(formData)

		yield put(CreateUserSuccessAction())
		yield put(NotificationOpen('success', 'Пользователь создан'))
		yield put(GetUsersListStartAction())
	} catch (error: any) {
		yield put(CreateUserErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось создать пользователся'))
	}
}
