import React, {FC} from 'react';
import './index.less'
import {Modal, Tooltip} from "antd";
import useHandlerModal from "hooks/handlModal";
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {selectUploaderInfo, selectUploaderUnfinishedFiles} from "store/uploader/selectors";
import PathConverter from "utils/path-converter";
import {TASKS} from "routing/names";
import {UploadFile} from "store/uploader/reducer";
import CountProgress from "utils/countProgress";
import {OpenModalUploadAction} from "store/uploader/actions";
import {UnfinishedFile} from "models/common";
import useUploaderContext from "../../../context/uploader/useUploaderContext";
import ErrorBoundary from '../../error-boundary';

type Props = {
    status: boolean
}
const ModalUpload: FC<Props> = ({status}) => {
    const put = useDispatch()
    const {setProgress} = useUploaderContext()
    const {isVisible, closeModal} = useHandlerModal(status);
    const {unfinishedFiles} = useSelector(selectUploaderUnfinishedFiles);
    const {loading, entityId} = useSelector(selectUploaderInfo)

    const handlerOpenModalUploadFile = (task: UnfinishedFile) => () => {
        const uploadFile = {
            length: task.length,
            offset: task.offset,
            originalFilename: task.originalFilename,
            id: task.id,
            originalFileModificationDate: task.originalFileModificationDate,
            isUploadInProgress: task.isUploadInProgress
        } as Required<UploadFile>
        const progress = CountProgress(task.offset, task.length)
        setProgress && setProgress(progress)
        closeModal()
        if (task.task) {
            put(OpenModalUploadAction(
              task.contentTaskRecord.id,
              uploadFile,
              {taskId: task.task.id, taskName: task.task.name})
            )
        }

    }


    return (
        <Modal
            className='modal-upload-unfinished unfinished'
            title={'Список загрузок'}
            visible={isVisible}
            footer={''}
            maskClosable={true}
            onCancel={closeModal}
        >
            <ErrorBoundary>
                <div className='unfinished__content'>
                    <div className='unfinished__content-title'>
                        <span>Задача</span>
                        <span>Наименование контента</span>
                        <span>Статус загрузки</span>
                    </div>
                    <ul className='unfinished__content-list'>
                        {Array.isArray(unfinishedFiles) && unfinishedFiles?.map((file) => (
                          <li key={file.id}>
                              {file.task ? (
                                <Link to={PathConverter(TASKS.TASK, file.task.id)} onClick={closeModal}>
                                    <Tooltip title={`Открыть задачу - ${file.task.name}`}>
                                        {file.task.code} - {file.task.number}
                                    </Tooltip>
                                </Link>
                              ) : '- - -'}
                              <Tooltip
                                title={loading ?
                                  'Чтобы открыть окно загрузки нужно приостановить текущую загрузку' :
                                  'Открыть окно загрузки'}
                              >
                                <span
                                  className='name'
                                  onClick={loading ? undefined : handlerOpenModalUploadFile(file)}>
                                    {file.contentTaskRecord.name} / {file.originalFilename}
                                </span>
                              </Tooltip>

                              <span className={`status ${file.contentTaskRecord.id === entityId && loading ? 'active' : ''}`}>
                            {file.contentTaskRecord?.id === entityId && loading ? 'В процессе' : 'Приостановлен'}
                        </span>
                          </li>
                        ))}
                    </ul>
                </div>
            </ErrorBoundary>

        </Modal>
    )
};
export default ModalUpload;