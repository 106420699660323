import {z} from "zod";
import ZodParser from "shared/services/zod-parser";
import {StepFourthDto} from "../dto/project-form_StepFourth.dto";
import {parseUsers, userSchema} from "../parse-user";

const schema = z.object({
    contractorOrganizationId: z.string(),
    contractorAgentVisible: z.boolean(),
    projectManagers: z.array(userSchema).nullable(),
    producers: z.array(userSchema).nullable(),
    contractors: z.array(userSchema).nullable(),
    coordinators: z.array(userSchema).nullable(),
    analysts: z.array(userSchema).nullable(),
    financiers: z.array(userSchema).nullable(),
    lawyers: z.array(userSchema).nullable(),
    contentEditors: z.array(userSchema).nullable(),
})
export type EditSchema4 = z.infer<typeof schema>
export function step4(step: StepFourthDto): EditSchema4 {
    const mapped: EditSchema4 = {
        contractorOrganizationId: step.contractorOrganization.value?.value as string,
        contractorAgentVisible: step.contractorAgentVisible.value,
        projectManagers: parseUsers(
            step.projectManagers.value,
            step.projectManagersTutor.value,
            step.projectManagersTutorVisible.value
        ),
        producers: parseUsers(
            step.producers.value,
            step.producersTutor.value,
            step.producersTutorVisible.value
        ),
        contractors: parseUsers(
            [],
            step.contractors.value,
            false,
            false
        ),
        coordinators: parseUsers(
            step.coordinators.value,
            step.coordinatorsTutor.value,
            step.coordinatorsTutorVisible.value
        ),
        analysts: parseUsers(
            step.analysts.value,
            step.analystsTutor.value,
            step.analystsTutorVisible.value
        ),
        financiers: parseUsers(
            step.financiers.value,
            step.financiersTutor.value,
            step.financiersTutorVisible.value
        ),
        lawyers: parseUsers(
            step.lawyers.value,
            step.lawyersTutor.value,
            step.lawyersTutorVisible.value
        ),
        contentEditors: parseUsers(
            step.contentEditors.value,
            step.contentEditorsTutor.value,
            step.contentEditorsTutorVisible.value
        ),
    }
    ZodParser.parse(schema, mapped, 'Edit step4')
    return mapped
}
