import React, {useRef, useState} from 'react';
import {Tooltip} from "antd";
import Search from "antd/es/input/Search";
import {SetContentFilter} from "store/content/list/actions";
import useDebounce from "hooks/useDebounce";
import {useDispatch, useSelector} from "react-redux";
import {selectContentListFilter} from "store/content/list/selectors";

const FilterSearch = () => {
    const put = useDispatch();
    const filters = useSelector(selectContentListFilter);
    const [searchString, setSearchString] = useState(filters.filterData?.searchString);
    const ref = useRef(true);
    const onSearch = (value: any) => {
        ref.current = false;
        put(SetContentFilter({...filters, filterData: {...filters.filterData, searchString: value}}))
    }
    const handlerSearchDebounce = useDebounce((value: string) => {
        if (ref.current) put(SetContentFilter({...filters, filterData: {...filters.filterData, searchString: value}}))
    }, 1500)
    const handlerSearch = (e: any) => {
        ref.current = true;
        setSearchString(e.target.value)
        handlerSearchDebounce(e.target.value)
    }
    return (
        <div className="ContentPage__filters-search form-group">
            <Tooltip title="Поиск по названию проекта, № договора, № заявки" placement="top">
                <Search
                    value={searchString}
                    placeholder={'Поиск контента'}
                    onSearch={onSearch}
                    onChange={handlerSearch}
                    allowClear
                />
            </Tooltip>
        </div>
    )
}

export default FilterSearch;