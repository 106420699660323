import React, {MouseEvent, useMemo} from 'react';
import {Button, Radio, RadioChangeEvent, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {DeleteOutlined} from "@ant-design/icons";
import {ExtendedSearchModel} from 'features/extended-search';
import {Fields, Stores} from 'features/extended-search/types';
import ContentTiming from "./components/content-timing";
import InputNumberComponent from "./components/input-number";

const {Option} = Select;
const optionsFields: Array<{label: string, value: Stores.CountsKeys | 'Параметр неважен'}> = [
    {label: 'Параметр неважен', value: 'Параметр неважен'},
    {label: 'Фактический КПЭ', value: Fields.FieldsEnums.ACTUAL_KPI},
    {label: 'Плановый КПЭ', value: Fields.FieldsEnums.PLAN_KPI},
    {label: 'Бюджет проекта', value: Fields.FieldsEnums.BUDGET},
    {label: 'Целевая аудитория проекта', value: Fields.FieldsEnums.TARGET_AUDIENCE},
    {label: 'Количество ед.контента в проекте', value: Fields.FieldsEnums.PLAN_CONTENT_COUNT},
    {label: 'Всего задач', value: Fields.FieldsEnums.TOTAL_TASK_COUNT},
    {label: 'Готовых задач', value: Fields.FieldsEnums.READY_TASK_COUNT},
    {label: 'До конца срока этапа осталось N дней', value: Fields.FieldsEnums.SPRINT_UNTIL_END_DAYS},
    {label: 'До конца срока задачи осталось N дней', value: Fields.FieldsEnums.TASK_UNTIL_END_DAYS},
    {label: 'Хронометраж единиц контента проекта', value: Fields.FieldsContent.CONTENT_TIMING_MIN_MAX},
]

export const CountsItem = () => {
    const put = useDispatch();

    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const counts = useSelector(ExtendedSearchModel.selectors.counts)

    const disabledOptions = useMemo(() => {
        return counts[typeContent].fields.map((el) => el.field)
    }, [counts, typeContent]);

    const onSelectChangeType = (index: number) => (value: Stores.CountsKeys | 'Параметр неважен') => {
        const newArray = [...counts[typeContent].fields];
        newArray[index]["field"] = value;
        newArray[index]["minValue"] = '';
        newArray[index]["maxValue"] = '';
        newArray[index]["isAndOrBesides"] = false;
        put(ExtendedSearchModel.actions.setCounts(
            typeContent,
            newArray
        ))
    }
    const addGroup = () => {
        put(ExtendedSearchModel.actions.setCounts(typeContent, [
            ...counts[typeContent].fields,
            {field: 'Параметр неважен', isAndOrBesides: false, minValue: '', maxValue: ''}
        ]))
    }

    const onGroupChange = (index: number) => (e: RadioChangeEvent) => {
        const newArray = [...counts[typeContent].fields];
        newArray[index].isAndOrBesides = e.target.value;
        put(ExtendedSearchModel.actions.setCounts(
            typeContent,
            newArray
        ))
    }


    const onRemoveHandler = (index: number) => (_event: MouseEvent<HTMLDivElement>) => {
        const newArray = [...counts[typeContent].fields];
        newArray.splice(index, 1)
        put(ExtendedSearchModel.actions.setCounts(
            typeContent,
            newArray
        ))
    }

    return (
        <div className="item-wrapper">
            {counts[typeContent].fields.map((item, index) => (
                <div className="item-wrapper__group" key={item.field + index}>
                    <div className="item-wrapper__items">
                        <div className="item">
                            <div className="form-group">
                                <Select
                                    value={item.field}
                                    onChange={onSelectChangeType(index)}
                                    placeholder={'Параметр неважен'}
                                    getPopupContainer={trigger => trigger.parentNode}
                                >
                                    {optionsFields.map((el) => (
                                        <Option
                                            disabled={disabledOptions.includes(el.value)}
                                            value={el.value}
                                            key={el.value}
                                        >
                                            {el.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="or">
                            <Radio.Group value={item.isAndOrBesides} onChange={onGroupChange(index)}>
                                <Radio value={true}>Кроме</Radio>
                                <Radio value={false}>Между</Radio>
                            </Radio.Group>
                        </div>
                        <div className="item item-second">
                            {item.field === 'CONTENT_TIMING_MIN_MAX' ? (
                                <ContentTiming item={item} index={index} />
                            ) : (
                                <InputNumberComponent index={index} item={item} />
                            )}
                        </div>
                    </div>
                    {counts[typeContent].fields.length > 1 && (
                        <div className="item__remove" onClick={onRemoveHandler(index)}>
                            <DeleteOutlined />
                        </div>
                    )}
                </div>
            ))}
            {counts[typeContent].fields.length < optionsFields.length - 1 && (
                <div className="btn">
                    <Button type={'link'} onClick={addGroup}>+ Добавить условие</Button>
                </div>
            )}
        </div>
    )
}


