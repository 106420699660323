import './users-list-form.less'
import React, {FC, useEffect, useMemo} from 'react'
import FormComponent from './form'
import {TResponseUser} from 'models/users-list/user-form'
import {useDispatch, useSelector} from 'react-redux'
import {selectGetUser} from 'store/options/selectors/selectors-users'
import {GetUserStartAction} from 'store/options/actions/actions-users'
import {useHistory, useLocation} from "react-router-dom";
import {OPTIONS, PERSONAL_SETTING} from "routing/names";
import {selectRole} from "store/auth/selectors";
import {UserDto} from "entities/user";

type UsersListFormProps = {
	user?: TResponseUser | null
	id?: string | null
}


const UsersListForm: FC<UsersListFormProps> = ({ id }) => {
	const put = useDispatch()
	const currentUser = useSelector(selectRole)
	const {pathname} = useLocation()
	const history = useHistory();
	const user = useSelector(selectGetUser)

	useEffect(() => {
		if (pathname === OPTIONS.OPTIONS_EDIT && !id ) {
			history.push(PERSONAL_SETTING.CUSTOMIZATION, {replace: true})
		}
		// eslint-disable-next-line
	}, [pathname])

	useEffect(() => {
		if (id) put(GetUserStartAction(id))
	}, [id, put])

	const form = useMemo(() => {
		return new UserDto.User(user, currentUser)
	}, [user,currentUser]);
	
	return <FormComponent id={id} user={user} initialValue={form}/>
}
export default UsersListForm
