import {TApplicationState} from "store/aplication-state";
import {createSelector} from "reselect";
import { ExtendedSearchTypes } from "features/extended-search";

const getExtendedSearchState = (state: TApplicationState) => state.feature.extendedSearch;
export const isToggle = createSelector(getExtendedSearchState, (state) => ({
    projects: {
        project: state.project.projects.isToggled,
        agreementOfProjectTaskRecords: state.agreementOfProjectTaskRecords.projects.isToggled,
        organizations: state.organizations.projects.isToggled,
        contests: state.contests.projects.isToggled,
        status: state.status.projects.isToggled,
        indicators: state.indicators.projects.isToggled,
        indicatorsContent: state.indicatorsContent.projects.isToggled,
        awards: state.awards.projects.isToggled,
        thematics: state.thematics.projects.isToggled,
        regionals: state.regionals.projects.isToggled,
        content: state.content.projects.isToggled,
        channels: state.channels.projects.isToggled,
        users: state.users.projects.isToggled,
        famousPeople: state.famousPeople.projects.isToggled,
        dates: state.dates.projects.isToggled,
        counts: state.counts.projects.isToggled,
        categoriesGenres: state.categoriesGenres.projects.isToggled,
    },
    content: {
        project: state.project.content.isToggled,
        agreementOfProjectTaskRecords: state.agreementOfProjectTaskRecords.content.isToggled,
        organizations: state.organizations.content.isToggled,
        contests: state.contests.content.isToggled,
        status: state.status.content.isToggled,
        indicators: state.indicators.content.isToggled,
        indicatorsContent: state.indicatorsContent.content.isToggled,
        awards: state.awards.content.isToggled,
        thematics: state.thematics.content.isToggled,
        regionals: state.regionals.content.isToggled,
        content: state.content.content.isToggled,
        channels: state.channels.content.isToggled,
        users: state.users.content.isToggled,
        famousPeople: state.famousPeople.content.isToggled,
        dates: state.dates.content.isToggled,
        counts: state.counts.content.isToggled,
        categoriesGenres: state.categoriesGenres.content.isToggled,
    },
    calendar: {
        project: state.project.calendar.isToggled,
        agreementOfProjectTaskRecords: state.agreementOfProjectTaskRecords.calendar.isToggled,
        organizations: state.organizations.calendar.isToggled,
        contests: state.contests.calendar.isToggled,
        status: state.status.calendar.isToggled,
        indicators: state.indicators.calendar.isToggled,
        indicatorsContent: state.indicatorsContent.calendar.isToggled,
        awards: state.awards.calendar.isToggled,
        thematics: state.thematics.calendar.isToggled,
        regionals: state.regionals.calendar.isToggled,
        content: state.content.calendar.isToggled,
        channels: state.channels.calendar.isToggled,
        users: state.users.calendar.isToggled,
        famousPeople: state.famousPeople.calendar.isToggled,
        dates: state.dates.calendar.isToggled,
        counts: state.counts.calendar.isToggled,
        categoriesGenres: state.categoriesGenres.calendar.isToggled,
    },
}))
export const isToggleFilter = (type: ExtendedSearchTypes.Stores.TTypeStore) =>
    createSelector(isToggle, (state) => {
        const values = Object.values(state[type])
        return values.some((el) => el === true)
    })
export const main = createSelector(getExtendedSearchState, (state) => state.main)
export const project = createSelector(getExtendedSearchState, (state) => state.project)
export const agreementOfProjectTaskRecords = createSelector(getExtendedSearchState, (state) => state.agreementOfProjectTaskRecords)
export const organizations = createSelector(getExtendedSearchState, (state) => state.organizations)
export const contests = createSelector(getExtendedSearchState, (state) => state.contests)
export const status = createSelector(getExtendedSearchState, (state) => state.status)
export const indicators = createSelector(getExtendedSearchState, (state) => state.indicators)
export const indicatorsContent = createSelector(getExtendedSearchState, (state) => state.indicatorsContent)
export const awards = createSelector(getExtendedSearchState, (state) => state.awards)
export const thematics = createSelector(getExtendedSearchState, (state) => state.thematics)
export const regionals = createSelector(getExtendedSearchState, (state) => state.regionals)
export const content = createSelector(getExtendedSearchState, (state) => state.content)
export const channels = createSelector(getExtendedSearchState, (state) => state.channels)
export const users = createSelector(getExtendedSearchState, (state) => state.users)
export const famousPeople = createSelector(getExtendedSearchState, (state) => state.famousPeople)
export const dates = createSelector(getExtendedSearchState, (state) => state.dates)
export const counts = createSelector(getExtendedSearchState, (state) => state.counts)
export const categoriesGenres = createSelector(getExtendedSearchState, (state) => state.categoriesGenres)

export const filter = (type: ExtendedSearchTypes.Stores.TTypeStore) => createSelector(
    getExtendedSearchState, (state) => ({
        project: {
            codes: state.project[type].codes,
            contractNumbers: state.project[type].contractNumbers,
            requestNumbers: state.project[type].requestNumbers,
            igks: state.project[type].igks,
        },
        agreementOfProjectTaskRecords: {
            isNotVotedRecordsExists: getBoolean(state.agreementOfProjectTaskRecords[type].isNotVotedRecordsExists),
            notVotedRecordsInspectorUserIds: state.agreementOfProjectTaskRecords[type].notVotedRecordsInspectorUserIds,
        },
        organizations: {
            organizationIds: state.organizations[type].organizationIds,
            legalFormIds: state.organizations[type].legalFormIds,
        },
        contests: {
            contestIds: state.contests[type].contestIds,
            sessionIds: state.contests[type].sessionIds,
            years: state.contests[type].years.map((el) => +el),
        },
        status: {
            statusIds: state.status[type].statusIds,
            sprintStatusIds: state.status[type].sprintStatusIds,
            contentDirectionIds: state.status[type].contentDirectionIds,
            contentEditorLabelIds: state.status[type].contentEditorLabelIds,
            moderatorLabelIds: state.status[type].moderatorLabelIds,
        },
        indicators: {
            advancePayment: getBoolean(state.indicators[type].advancePayment),
            isContentCreationProjectType: getBoolean(state.indicators[type].isContentCreationProjectType),
            isNationalProject: getBoolean(state.indicators[type].isNationalProject),
            isDataChecked: getBoolean(state.indicators[type].isDataChecked),
            isFamousPeopleRemarked: getBoolean(state.indicators[type].isFamousPeopleRemarked),
            isProjectPosted: getBoolean(state.indicators[type].isProjectPosted),
            isPrivateProject: getBoolean(state.indicators[type].isPrivateProject),
            isTaskOverdueExist: getBoolean(state.indicators[type].isTaskOverdueExist),
            isFavoriteProject: getBoolean(state.indicators[type].isFavoriteProject),
        },
        indicatorsContent: {
            isContent: getBoolean(state.indicatorsContent[type].isContent),
            isAccountedContent: getAccountedContent(state.indicatorsContent[type].isAccountedContent),
            isMasterContent: getBoolean(state.indicatorsContent[type].isMasterContent),
            contentStatusCodes: state.indicatorsContent[type].contentStatusCodes,
        },
        awards: state.awards[type].fields.filter((el) => el.field !== 'Параметр неважен'),
        thematics: state.thematics[type].fields.filter((el) => el.field !== 'Параметр неважен'),
        regionals: state.regionals[type].fields.filter((el) => el.field !== 'Параметр неважен'),
        content: state.content[type].fields.filter((el) => el.field !== 'Параметр неважен'),
        channels: state.channels[type].fields.filter((el) => el.field !== 'Параметр неважен'),
        users: state.users[type].fields.filter((el) => el.field !== 'Параметр неважен'),
        famousPeople: state.famousPeople[type].fields.filter((el) => el.field !== 'Параметр неважен'),
        dates: state.dates[type].fields.filter((el) => el.field !== 'Параметр неважен'),
        counts: state.counts[type].fields.filter((el) => el.field !== 'Параметр неважен'),
        categoriesGenres: state.categoriesGenres[type].fields.filter((el) => el.field !== 'Параметр неважен'),
    })
)
function getBoolean(value: ExtendedSearchTypes.Stores.ValueTypes) {
    if (value === 'true') return true;
    else if (value === 'false') return false;
    return undefined;
}
function getAccountedContent(value: string) {
    if (value === 'TRUE') return 'TRUE';
    else if (value === 'FALSE') return 'FALSE';
    else if (value === 'NULL') return 'NULL';
    return undefined;
}
