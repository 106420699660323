import {
    GetContentModuleSectionErrorAction,
    GetContentModuleSectionStartAction,
    GetContentModuleSectionSuccessAction
} from "store/options/actions/actions-handbook";
import {put} from "redux-saga/effects";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {AxiosResponse} from "axios";
import {T_ID_NAME_CODE} from "models/common";
import HandbookService from "services/handbookService";


export function* getContentModuleSection(_action: ReturnType<typeof GetContentModuleSectionStartAction>) {
    try {
        const {data}: AxiosResponse<T_ID_NAME_CODE[]> = yield HandbookService.getContentModuleSection()
        yield put(GetContentModuleSectionSuccessAction(data))
    } catch (error) {
        yield put(GetContentModuleSectionErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить разделы модуля контента'))
    }

}
