import {GetAnalyticsReachDataStartAction, SetAnalyticsFilterReach,} from '../../store/analytics/actions'
import {put} from "redux-saga/effects";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../store/notification/actions";

export function* setAnalyticsFilterReach(_action: ReturnType<typeof SetAnalyticsFilterReach>) {
	try {
		yield put(GetAnalyticsReachDataStartAction())
	} catch (error: any) {
		console.log(error)
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
