import { ExtendedSearchTypes } from "features/extended-search";
import {ActionTypes} from "./types";


export const setUsers = <T extends Partial<ExtendedSearchTypes.Stores.Users["fields"]>>(
    typeContent: ExtendedSearchTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUE, typeContent, value, isToggled
})
export const clearUsers = (typeContent: ExtendedSearchTypes.Stores.TTypeStore) => ({
    type: ActionTypes.CLEAR_VALUE, typeContent
})