import $api from "../../http";
import {AxiosResponse} from "axios";
import {CalendarResponse} from "./types";
import {TProjectsFilter, TProjectsFilterExtendedSearch} from "models/projects/filter";

export default class CalendarService {
    static extendedSearch(filter: TProjectsFilterExtendedSearch): Promise<AxiosResponse<CalendarResponse>> {
        filter.page = filter.page - 1;
        return $api.post('/projects/calendar/extended-search', filter)
    }
    static search(filter: TProjectsFilter): Promise<AxiosResponse<CalendarResponse>> {
        filter.page = filter.page - 1;
        return $api.post('/projects/calendar/search', filter)
    }
}