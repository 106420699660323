export function dateParse(value?: string | Date) {
    let date;

    if (typeof value === 'undefined') date = new Date();
    else if (typeof value === 'string') date = new Date(value);
    else date = value;

    const day = date?.getDate();
    const year = date?.getFullYear();
    const month = date?.getMonth() + 1;
    const hours = date?.getHours();
    const minutes = date?.getMinutes();
    const seconds = date?.getSeconds();

    return {
        day: day < 10 ? '0' + day: day,
        year,
        month: month < 10 ? '0' + month: month,
        hours: hours < 10 ? '0' + hours: hours,
        minutes: minutes < 10 ? '0' + minutes: minutes,
        seconds: seconds < 10 ? '0' + seconds: seconds
    }
}
type TTypeDate = 'd:y' | 'h:m:s' | 'd:m:y' | 'd.m.y'
export function dateParseFormat(type: TTypeDate, value?: string | Date) {
    const date = dateParse(value);
    switch (type) {
        case 'd:y':
            return `${date.day}.${date.year}`;
        case 'd:m:y':
            return `${date.day}.${date.month}.${date.year}`;
        case 'h:m:s':
            return `${date.hours}:${date.minutes}:${date.seconds}`;
        case "d.m.y":
            return `${date.hours}.${date.minutes}.${date.seconds}`;
    }
}
