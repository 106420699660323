import React, {SyntheticEvent, useState} from 'react';
import './name-cell-with-count.less';
import NameCenterCell from "../name-center-cell";
import {Tooltip} from "antd";

const NameCellWithCount = (arr: any) => {
    const [visible, setVisible] = useState<boolean>(false)
    const text = !!arr?.length ? arr.slice(0, visible ? arr.length : 1).map((el: any) => el.name).join(', ') : '__'

    const handlerClickShow = (e: SyntheticEvent) => {
        e.stopPropagation()
        setVisible(true)
    }

    const handlerClickCell = (e: SyntheticEvent) => {
        e.stopPropagation()
        setVisible(false)
    }

    return (
        <div className="name-cell-with-count__wrapper" onClick={handlerClickCell}>
            {NameCenterCell(text)}
            {arr?.length > 1 && !visible && (
                <div
                    className="name-cell-with-count__amount"
                    onClick={handlerClickShow}
                >
                    <Tooltip placement="top" title="Посмотреть все">
                        +{arr.length - 1}
                    </Tooltip>
                </div>
            )}
        </div>
    )
};
export default NameCellWithCount;
