import React, {useEffect, useState} from 'react';
import styles from './styles.module.less';
import {ProjectApi, ProjectTypes} from 'entities/project';
import {useMutation, useQuery} from "react-query";
import {Input, Select, Spin, Tooltip} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {selectRole} from "store/auth/selectors";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';
import classNames from "classnames";
import {Nullable} from "models/common";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {UserTypes} from 'entities/user';
import {Query} from 'processes/query'

const {Option} = Select;
const {ProjectLabelController} = ProjectApi;

type State = {
    id: string,
    name: string
}
type PropsCell = {
  type: ProjectTypes.ProjectLabel['labelTypeCode'],
  editorLabels: ProjectTypes.ProjectLabel[],
  project: ProjectTypes.ProjectsList
}

const getDisabledRoles = (type: ProjectTypes.ProjectLabel['labelTypeCode']): UserTypes.RoleName[] => {
  const arr: UserTypes.RoleName[]  = [
    'ROLE_ADMIN_IS',
    'ROLE_ADMIN'
  ]
  if (type === 'FOR_EDITOR') arr.push('ROLE_CONTENT_EDITOR')
  if (type === 'FOR_MODERATOR') arr.push('ROLE_MODERATOR')
  return arr
}

const EditorLabelCell = (
  {type, project, editorLabels}: PropsCell
) => {
    const put = useDispatch();
    const role = useSelector(selectRole)
    const [edit, setEdit] = useState<Nullable<State>>(null);
    const [state, setState] = useState<string[]>([]);

    useEffect(() => {
        if (editorLabels?.length !== state.length) {
					const labels = editorLabels?.map((el) => el.id)
					setState(labels || [])
				}
    }, [editorLabels]);//eslint-disable-line

    const { data = [], isLoading: isLoadingGet } = useQuery({
        queryKey: [ProjectLabelController.get.key, type],
        queryFn: () => ProjectLabelController.get.fetchOptions({labelTypeCode: type}),
    })

    const {mutate: deleteLabel, isLoading: isLoadingDeleteLabel}= useMutation(
      (id: string) => ProjectLabelController.deleteLabels(id),
      {
          onSuccess: () => {
              Query.invalidate([ProjectLabelController.get.key, type])
              put(NotificationOpen( 'success', 'Метка удалена'))
          },
      }
    )
    const onSelect = (value: string[]) => {
        setState(value);
        ProjectApi.Project.changeProjectLabel({
					projectId: project.id,
					labelIds: value,
					labelTypeCode: type,
				})
          .then(() => {
              put(NotificationOpen( 'success', 'Связь обновлена'))
          })
          .catch((error) => {
              const message = CreateErrorMessage.response(error)
              put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
          })
    }
    const onEdit = (id: string, name: string) => (event: any) => {
        event.stopPropagation();
        setEdit({id: id, name})
    }

    const onCancel = (event?: any) => {
        event && event.stopPropagation();
        setEdit(null)
    }
    const onDelete = (id: string) => (event: any) => {
        event.stopPropagation();
        deleteLabel(id)
    }

    const isDisabled = role && (!getDisabledRoles(type).includes(role))

    const tooltip = data
        ?.filter(it => state?.includes(it.value) )
        ?.map(it => it.label).join(', ')

    const isLoading = isLoadingDeleteLabel || isLoadingGet;

    return (
      <div className={styles.container}>
          <Tooltip title={isDisabled ? tooltip : ''}>
              <Select
                  getPopupContainer={trigger => trigger.parentNode}
                  className={styles.select}
                  onClick={(event) => event.stopPropagation()}
                  onChange={onSelect}
                  disabled={isDisabled}
                  loading={isLoading}
                  value={state}
                  maxTagCount={'responsive'}
                  mode={'multiple'}
                  placeholder={'Выберите метку'}
                  filterOption={(input, option) => {
                      if (option === undefined || option === null) return false

                      const optionText = option?.['data-code'];
                      return optionText?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                  }}
                  dropdownRender={(menu) => {
                      return (
                          <div className={styles.dropdown}>
                              {menu}
                              {edit ?
                                  <EditItem
                                      value={edit}
                                      isNew={edit.id === 'new'}
                                      cancelCallback={onCancel}
                                      type={type}
                                  /> :
                                  <div className={styles.newBtn} onClick={onEdit('new', '')}>
                                      Добавить новое значение
                                      <PlusCircleOutlined />
                                  </div>
                              }
                          </div>
                      )
                  }}
              >
                  {data?.map((el) => (
                      <Option key={el.value} value={el.value} data-code={el.label}>
                          <div className={styles.option}>
                              <Tooltip title={el.label}>
                                  <span className={styles.label}>{el.label}</span>
                              </Tooltip>
                              <div className={styles.buttons}>
                                  <EditOutlined
                                      className={classNames(styles.editBtn, styles.icon)}
                                      onClick={onEdit(el.value, el.label)}
                                  />
                                  <DeleteOutlined
                                      className={classNames(styles.deleteBtn, styles.icon)}
                                      onClick={onDelete(el.value)}
                                  />
                              </div>
                          </div>
                      </Option>
                  ))}
              </Select>
          </Tooltip>
      </div>
    )
};

type Props = {
    value: { name: string, id?: string },
    isNew?: boolean
    cancelCallback: (event?: any) => void
    type: ProjectTypes.ProjectLabel['labelTypeCode']
}
function EditItem({value, isNew = false, cancelCallback, type}: Props) {
    const put = useDispatch();
    const [state, setState] = useState(value);
    const {mutate, isLoading}= useMutation(
      (name: string) => isNew ?
        ProjectLabelController.create({
          name, labelTypeCode: type
        }) :
        ProjectLabelController.update({
          id: value.id as string, name, labelTypeCode: type
        }),
      {
          onSuccess: () => {
              cancelCallback()
              Query.invalidate([ProjectLabelController.get.key])
              put(NotificationOpen( 'success', `Метка ${isNew ? 'создана' : 'обновлена'}`))
          },
      }
    )

    const onChange = (event: any) => {
        setState({...state, name: event.target.value})
    }
    const onSave = () => {
        mutate(state.name)
    }
    return (
      <Spin spinning={isLoading}>
          <div className={styles.editFeel}>
              <Input
                onChange={onChange}
                value={state.name}
                placeholder={'Введите новое значение'}
              />
              <div className={styles.buttons}>
                  <CheckCircleOutlined
                    className={classNames(styles.save, {disabled: state.name.length === 0})}
                    onClick={onSave}
                  />
                  <CloseCircleOutlined className={styles.cancel} onClick={cancelCallback}/>
              </div>
          </div>
      </Spin>
    )
}
export default EditorLabelCell;