import {useContext} from "react";
import {ContextState, TStatus, UploaderContext} from "./uploaderContext";

export default function useUploaderContext() {
    const {store, dispatch} = useContext(UploaderContext)
    if (store === undefined) {
        throw new Error('store must be inside a Provider with a value');
    }
    if (dispatch === undefined) {
        throw new Error('dispatch must be inside a Provider with a value');
    }
    const setProgress = (val: number, type?: TStatus) => {
        if (type) {
            dispatch({
                type: 'SET_STATUS',
                payload: type
            })
        }
        dispatch({
            type: 'SET_PROGRESS',
            payload: val
        })
    }
    const resetProgress = () => {
        dispatch({
            type: 'RESET_PROGRESS',
        })
    }
    const setStatus = (type: TStatus) => {
        dispatch({
            type: 'SET_STATUS',
            payload: type
        })
    }
    const uploaderState: ContextState = {
        ...store
    }
    return {uploaderState, resetProgress, setProgress, setStatus}
}