import { Types } from './action-types'
import {TContentSettingsFilter, TContentSettingsState} from './reducer'

export const SetContentInfo = <T extends Partial<TContentSettingsState>>(value: T) => ({
	type: Types.SET_CONTENT_INFO,
	value
})
/**
 * Изменение фильтра
 */
export const SetContentFilter = (filter: TContentSettingsFilter) => ({
	type: Types.SET_CONTENT_FILTER,
	filter
})

export const GetContentSettingsList = (count?: number) => ({
	type: Types.GET_LIST, count
})

