import {z} from "zod";
import {RoleSchema} from "entities/user/types";

export const ResponsibleUserSchema = z.object({
    id: z.string(),
    lastName: z.string(),
    firstName: z.string(),
    middleName: z.string(),
    role: RoleSchema
})
export type ResponsibleUser = z.infer<typeof ResponsibleUserSchema>;