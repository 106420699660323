export function formatTime (value: string | null | undefined)  {
    if (!value) {
        return '';
    }
    const digitsOnly = value.replace(/\D/g, '');
    const hours = digitsOnly.slice(0, 2);
    const minutes = digitsOnly.slice(2, 4);
    const seconds = digitsOnly.slice(4, 6);

    if (digitsOnly.length <= 2) {
        return digitsOnly;
    } else if (digitsOnly.length <= 4) {
        return `${hours}:${validateTime(minutes)}`;
    } else if (digitsOnly.length <= 6) {
        return `${hours}:${validateTime(minutes)}:${validateTime(seconds)}`;
    }

    return `${hours}:${validateTime(minutes)}:${validateTime(seconds)}`;
}

export function validateTime(value: string) {
    if (!value) return ''
    if (Number(value) > 60) return String(60)
    else return value
}