import {ExtendedSearchTypes} from "features/extended-search";
import {ActionTypes} from "./types";


export const setCategoriesGenres = <T extends Partial<ExtendedSearchTypes.Stores.CategoriesGenres["fields"]>>(
    typeContent: ExtendedSearchTypes.Stores.TTypeStore, value: T, isToggled: boolean = true
) => ({
    type: ActionTypes.SET_VALUE, typeContent, value, isToggled
})
export const clearCategoriesGenres = (typeContent: ExtendedSearchTypes.Stores.TTypeStore) => ({
    type: ActionTypes.CLEAR_VALUE, typeContent
})