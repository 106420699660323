import { TaskTypes } from 'entities/task'
import { TTaskForm } from 'models/task'

export default class taskValidateRules {
	static roleAdmin(status: TaskTypes.TasksStatus, type: keyof TTaskForm) {
		if (status === 'TO_IMPLEMENTATION') {
			return StatusProject.Implementation(type)
		} else if (status === 'IN_PROGRESS') {
			return StatusProject.InProgress(type)
		} else if (status === 'READY') return true
		else return undefined
	}
	static roleAdminIs(status: TaskTypes.TasksStatus, type: keyof TTaskForm) {
		if (status === 'TO_IMPLEMENTATION') {
			return StatusProject.Implementation(type)
		} else if (status === 'IN_PROGRESS') {
			return StatusProject.InProgress(type)
		} else if (status === 'READY') return true
		else return undefined
	}
	static roleCoordinator(status: TaskTypes.TasksStatus, type: keyof TTaskForm) {
		if (status === 'TO_IMPLEMENTATION' || status === 'IN_PROGRESS') {
			return StatusProject.MixStatus(type)
		} else if (status === 'READY') return true
		else return undefined
	}
	static roleProjectManager(status: TaskTypes.TasksStatus, type: keyof TTaskForm) {
		if (status === 'TO_IMPLEMENTATION') {
			return StatusProject.Implementation(type)
		} else if (status === 'IN_PROGRESS') {
			return StatusProject.InProgress(type)
		} else if (status === 'READY') return true
		else return undefined
	}
	static roleProducer(status: TaskTypes.TasksStatus, type: keyof TTaskForm) {
		if (status === 'TO_IMPLEMENTATION') {
			return StatusProject.Implementation(type)
		} else if (status === 'IN_PROGRESS') {
			return StatusProject.InProgress(type)
		} else if (status === 'READY') return true
		else return undefined
	}
	//todo refact _type
	static roleContractor(status: TaskTypes.TasksStatus, _type: keyof TTaskForm) {
		if (status === 'TO_IMPLEMENTATION' || status === 'IN_PROGRESS' || status === 'READY') return true
		else return undefined
	}
	static roleAnalyst(status: TaskTypes.TasksStatus, type: keyof TTaskForm) {
		if (status === 'TO_IMPLEMENTATION') {
			return StatusProject.Implementation(type)
		}
		if (status === 'IN_PROGRESS') {
			return StatusProject.InProgress(type)
		}
	}
	static roleFinancier(status: TaskTypes.TasksStatus, type: keyof TTaskForm) {
		if (status === 'TO_IMPLEMENTATION') {
			return StatusProject.Implementation(type)
		}
		if (status === 'IN_PROGRESS') {
			return StatusProject.InProgress(type)
		}
	}
	static roleContentEditor(status: TaskTypes.TasksStatus, type: keyof TTaskForm) {
		if (status === 'TO_IMPLEMENTATION') {
			return StatusProject.Implementation(type)
		} else if (status === 'IN_PROGRESS') {
			return StatusProject.InProgress(type)
		} else if (status === 'READY') return true
		else return undefined
	}
}

class StatusProject {
	static Implementation(type: keyof TTaskForm) {
		if (
			type === 'status' ||
			type === 'typeProject' ||
			type === 'report'
		) return true
		else return undefined
	}
	static InProgress(type: keyof TTaskForm) {
		if (
			type === 'status' ||
			type === 'typeProject' ||
			type === 'report' ||
			type === 'project'
		) return true
		else return undefined
	}
	static MixStatus(type: keyof TTaskForm) {
		if (
			type === 'name' ||
			type === 'typeProject' ||
			type === 'project' ||
			type === 'deadline' ||
			type === 'description' ||
			type === 'contents' ||
			type === 'report' ||
			type === 'reachRecordsScreenshotRequired' ||
			type === 'reachRecordsCounterScreenshotRequired' ||
			type === 'postingRecordsScreenshotRequired' ||
			type === 'producers' ||
			type === 'status' ||
			type === 'sprint' ||
			type === 'analysts' ||
			type === 'financiers' ||
			type === 'isContentRecordDescription' ||
			type === 'projectManagers' ||
			type === 'regionTagsRequired' ||
			type === 'contentTagRequired'

		) return true
		else return undefined
	}
}
