import {InferValueTypes} from '../../models/common'

import * as actions from './actions'
import {ProjectDocumentsActionTypes} from './action-types'
import {ErrorActionState, StartActionState, SuccessActionState} from '../../utils/reducerHalper'
import {AuthActionTypes} from '../auth/action-types'
import {Logout} from '../auth/reducer'
import {TDocumentsAll, TResponseProjectDocuments} from '../../models/projects/project-document'
import {TProjectDocumentsFilter} from '../../models/projects/filter'

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>

const filterValue = {
	page: 1,
	pageSize: 10,
}

export type TProjectDocumentsState = {
	documentsAll: TDocumentsAll[] | null
	documentsId: TDocumentsAll[] | null
	documentsWithFilter: TResponseProjectDocuments | null
	filter: TProjectDocumentsFilter
	editingKey: string
	isLoading: boolean
	isLoadingFile: boolean
	error: any
}

export const initialState: TProjectDocumentsState = {
	documentsAll: null,
	documentsId: null,
	documentsWithFilter: null,
	filter: filterValue,
	editingKey: '',
	isLoading: false,
	isLoadingFile: false,
	error: null,
}

export default function reducer(
	state: TProjectDocumentsState = initialState,
	action: ActionTypes | Logout
): TProjectDocumentsState {
	switch (action.type) {
		case AuthActionTypes.LOGOUT:
			return { ...initialState }
		case ProjectDocumentsActionTypes.SET_PROJECT_DOCUMENTS_FILTER:
			return {
				...state,
				filter: action.filter,
			}

		/** START ACTIONS  */
		case ProjectDocumentsActionTypes.GET_DOCUMENTS_ALL_START:
			return StartActionState(state)
		case ProjectDocumentsActionTypes.GET_DOCUMENTS_PROJECT_ID_START:
			return StartActionState(state)
		case ProjectDocumentsActionTypes.GET_DOCUMENTS_WITH_FILTER_START:
			return StartActionState(state)
		case ProjectDocumentsActionTypes.DELETE_DOCUMENTS_START:
			return StartActionState(state)
		case ProjectDocumentsActionTypes.UPDATE_DOCUMENTS_START:
			return {
				...state,
				error: null,
				isLoadingFile: true
			}
		case ProjectDocumentsActionTypes.ADD_DOCUMENTS_START:
			return {
				...state,
				error: null,
				isLoadingFile: true
			}

		/** SUCCESS ACTIONS  */
		case ProjectDocumentsActionTypes.GET_DOCUMENTS_ALL_SUCCESS:
			return {
				...SuccessActionState(state),
				documentsAll: action.data,
			}
		case ProjectDocumentsActionTypes.GET_DOCUMENTS_PROJECT_ID_SUCCESS:
			return {
				...SuccessActionState(state),
				documentsId: action.data,
			}
		case ProjectDocumentsActionTypes.GET_DOCUMENTS_WITH_FILTER_SUCCESS:
			return {
				...SuccessActionState(state),
				documentsWithFilter: action.data,
			}
		case ProjectDocumentsActionTypes.DELETE_DOCUMENTS_SUCCESS:
			return SuccessActionState(state)
		case ProjectDocumentsActionTypes.UPDATE_DOCUMENTS_SUCCESS:
			return {
				...state,
				isLoadingFile: false
			}
		case ProjectDocumentsActionTypes.ADD_DOCUMENTS_SUCCESS:
			return {
				...state,
				isLoadingFile: false
			}
		case ProjectDocumentsActionTypes.SET_EDITING_KEY :
			return {
				...state,
				editingKey: action.key
			}
		case ProjectDocumentsActionTypes.CLEAR_EDITING_KEY :
			return {
				...state,
				editingKey: ''
			}

		/** ERROR ACTIONS  */
		case ProjectDocumentsActionTypes.GET_DOCUMENTS_ALL_ERROR:
			return ErrorActionState(state, action)
		case ProjectDocumentsActionTypes.GET_DOCUMENTS_PROJECT_ID_ERROR:
			return ErrorActionState(state, action)
		case ProjectDocumentsActionTypes.GET_DOCUMENTS_WITH_FILTER_ERROR:
			return ErrorActionState(state, action)
		case ProjectDocumentsActionTypes.DELETE_DOCUMENTS_ERROR:
			return ErrorActionState(state, action)
		case ProjectDocumentsActionTypes.ADD_DOCUMENTS_ERROR:
			return {
				...state,
				isLoadingFile: false,
				error: action.error
			}
		case ProjectDocumentsActionTypes.UPDATE_DOCUMENTS_ERROR:
			return {
				...state,
				isLoadingFile: false,
				error: action.error
			}
		default:
			return state
	}
}
