import {z} from "zod";
import {RoleSchema} from "../../user/types";

export const NotificationChannelSchema = z.object({
    id: z.string(),
    code: z.string(),
    afterMinutes: z.number(),
    prioritySendRus: z.string(),
    prioritySend: z.string(),
    personalSetupRequired: z.boolean()
})

export type NotificationChannel = z.infer<typeof NotificationChannelSchema>;

export const NotificationSchema = z.object({
    id: z.string(),
    name: z.string(),
    type: z.enum(['REMINDER', 'NOTIFICATION']),
    eventName: z.string(),
    notificationChannels: z.array(NotificationChannelSchema),
    rolesForNotifications: z.array(RoleSchema),
    enabled: z.boolean(),
    bulkSendAllowed: z.boolean()
})

export type Notification = z.infer<typeof NotificationSchema>;

export const NotificationRequestSchema = z.object({
    name: z.string(),
    notificationType: z.enum(['REMINDER', 'NOTIFICATION']),
    eventName: z.string(),
    isEnabled: z.boolean(),
    channelCodes: z.array(z.string()).nullable().optional(),
    afterMinutes: z.object({
        EMAIL: z.number().optional(),
        TELEGRAM: z.number().optional(),
        SMS: z.number().optional(),
        SYSTEM: z.number().optional(),
    }).optional(),
    prioritySend: z.array(z.object({
        channelName: z.string(),
        setupRequired: z.boolean(),
        value: z.string()
    })).optional(),
    rolesForNotifications: z.array(z.string()).nullable().optional(),
    bulkSendAllowed: z.boolean().optional()
})

export type NotificationRequest = z.infer<typeof NotificationRequestSchema>;

export const NotificationTemplateSchema = z.object({
    id: z.string(),
    channelName: z.string(),
    template: z.string(),
    design: z.string(),
})
export type NotificationTemplate = z.infer<typeof NotificationTemplateSchema>;

export const NotificationTemplateRequestSchema = z.object({
    id: z.string(),
    templateId: z.string(),
    data: z.object({
        template: z.string().optional(),
        design: z.string().optional()
    }),
})
export type NotificationTemplateRequest = z.infer<typeof NotificationTemplateRequestSchema>;
