import { axiosRequest } from 'shared/config/axios'
import {
	Contest,
	ContestResponse,
	ContestSchema,
	ContestShort,
	ContestShortSchema,
	ContestUpdate,
	FilterContest,
} from '../types'
import { AxiosResponse } from 'axios'
import { downloadXLXS } from 'utils/downloadXLXS'
import { IOptions } from 'models/feels'

export class ContestController {
	static getContestListKey = '/contests'
	static getContestListOptionsKey = `Options: ` + ContestController.getContestListKey
	static getWithFilterKey = `/contests/search`
	static getContestWithProjectsKey = `/contests/with-projects`
	static getContestWithProjectsOptionsKey = `Options: ` + ContestController.getContestWithProjectsKey

	/**
	 * Получение списка конкурсов без пагинации
	 */
	static async getContestList() {
		const { data } = await axiosRequest<ContestShort[]>({
			method: 'GET',
			url: '/contests',
			showError: true,
			parser: ContestShortSchema.array(),
		})
		return data
	}
	static async getContestListOptions() {
		const data = await ContestController.getContestList()
		return data.map((el) => ({ value: el.id, label: `(${el.code}) ${el.shortName}` } as IOptions))
	}

	/**
	 * Получение списка конкурсов c фильтрами
	 */
	static async getWithFilter(filter: FilterContest): Promise<AxiosResponse<ContestResponse>> {
		const newFilter = { ...filter, page: filter.page - 1 }
		const { data } = await axiosRequest<AxiosResponse<ContestResponse>>({
			method: 'POST',
			url: `/contests/search`,
			data: newFilter,
			showError: true,
			parser: { list: true, schema: ContestSchema.array() },
		})
		return data
	}

	/**
	 * Изменение данных конкурса
	 */
	static async updateContest({ contestId, data }: { contestId: string; data: ContestUpdate }) {
		await axiosRequest<AxiosResponse<any>>({
			method: 'PUT',
			url: `/contests/${contestId}`,
			data,
			showError: true,
		})
	}

	/**
	 *
	 * Создание пустой записи для конкурса
	 */
	static async createContest(data: ContestUpdate) {
		await axiosRequest<AxiosResponse<any>>({
			method: 'POST',
			url: `/contests`,
			data,
			showError: true,
		})
	}

	/**
	 * Фактическое удаление конкурса из БД
	 */
	static async deleteContest(id: string) {
		await axiosRequest<AxiosResponse<any>>({
			method: 'DELETE',
			url: `/contests/${id}`,
			showError: true,
		})
	}
	/**
	 * Массовое действие
	 */
	static async bulkActions({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) {
		await axiosRequest<AxiosResponse<any>>({
			method: 'POST',
			url: `/contests/bulk-actions/${bulkAction}`,
			data: rowIds,
			showError: true,
		})
	}
	/**
	 * Выполнение выгрузки списка в формате Excel
	 */
	static async download(value: string[]) {
		const { data }: any = await axiosRequest<AxiosResponse<any>>({
			method: 'POST',
			url: `/contests/export`,
			responseType: 'arraybuffer',
			headers: {
				'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
			data: value,
			showError: true,
		})
		downloadXLXS(data, 'contests')
	}
	/**
	 * Получение файла шаблона для выполнения импорта данных о конкурсах
	 */
	static async downloadTemplate() {
		const { data }: any = await axiosRequest<AxiosResponse<any>>({
			method: 'GET',
			url: '/contests/import/template',
			responseType: 'arraybuffer',
			headers: {
				'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
			showError: true,
		})
		downloadXLXS(data, 'contests-template')
	}
	/**
	 * Выполнение импорта данных о конкурсах из заполненного шаблона в формате Excel
	 */
	static async upload(data: FormData) {
		await axiosRequest({
			method: 'POST',
			url: '/contests/import',
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data,
			showError: true,
		})
	}
	/**
	 * Получение списка конкурсов, содержащих проекты, без пагинации
	 */
	static async getContestWithProjects() {
		return await axiosRequest<Array<Omit<Contest, 'targetAudience'>>>({
			method: 'GET',
			url: '/contests/with-projects',
			showError: true,
		})
	}
	static async getContestWithProjectsOptions() {
		const { data } = await ContestController.getContestWithProjects()
		return data.map((el) => ({ label: `(${el.code}) ${el.shortName}`, value: el.id } as IOptions))
	}
}
