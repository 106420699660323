import $api from "../http";
import {AxiosResponse} from "axios";
import env from "../shared/config/env";

export async function CreateObjUrlFunction(fileId: string) {
    try {
        const {data}: AxiosResponse<any> = await $api({
            method: 'GET',
            url: `/files/${fileId}`,
            responseType: 'blob',
        });
        return window.URL.createObjectURL(data);
    } catch (e) {
        console.error(e)
    }
}
export async function CreateObjUrlFunctionAndLink(fileId: string, blank?: string) {
    try {
        const path = await CreateObjUrlFunction(fileId);
        if (path) {
            const link = document.createElement("a");
            link.href = path;
            link.target = blank ? blank : '_blank'
            link.click();
        }
        return;
    } catch (e) {
        throw new Error()
    }
}

export function DownloadElement(fileId: string, fileName: string) {
    try {
        const link = document.createElement("a");
        link.href = `${env.API_ENDPOINT}/files/${fileId}`;
        link.download = fileName;
        document.body.appendChild(link); // Добавляем ссылку в DOM для поддержки старых браузеров
        link.click();
        document.body.removeChild(link); // Удаляем ссылку после скачивания
    } catch (err) {
        console.error('Error downloading file:', err);
    }
}