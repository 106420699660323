import { SearchContentProjectModel } from "features/search-project-content";
import {useDispatch} from "react-redux";
import {UserFiltersModel, UserFiltersTypes } from ".";
import {Types} from "./types";

export function useResetFilter(filterType: UserFiltersTypes.Types['code']) {
    const put = useDispatch();
    return () => {
        put(UserFiltersModel.actions.setUserFilter(filterType, {
            filterName: undefined,
            activeFilterId: undefined,
        }))
    }
}
export function getTypeContent(filterType: Types["code"]): SearchContentProjectModel.TCFState['main']['typeContent'] {
    switch (filterType) {
        case 'PROJECTS_CALENDAR':
            return 'calendar'
        case 'PROJECTS_CONTENT':
            return 'content'
        default:
            return 'projects'
    }
}