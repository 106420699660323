import {AxiosResponse} from 'axios'
import $api from '../http'
import {TUserActionResponse, TUserActionsType, TUserOptionsFilter, TUsersActionResponse} from '../models/user'
import {TLogsAction} from "../models/logs";
import {TTypeOverallDataRequest} from "../models/handbook";

/**
 * @category Service
 * @class UsersActionsService
 */
export default class UsersActionsService {
	/**
	 * Логирование действий пользователя
	 */
	static async sendActions(data: TLogsAction[]): Promise<AxiosResponse<any>> {
		return $api.post<any>('/users/actions', data)
	}
	/**
	 * Получение всей активности пользователей системы
	 */
	static async getActivityUsers(data: TUserOptionsFilter): Promise<AxiosResponse<TUsersActionResponse>> {
		const filter = { ...data, page: data.page - 1 }
		return $api.post<TUsersActionResponse>('/users/actions/statistic', filter)
	}
	/**
	 * Получение действий пользователя
	 */
	static async getActionsUsers(data: TUserOptionsFilter): Promise<AxiosResponse<TUserActionResponse>> {
		const filter = { ...data, page: data.page - 1 }
		return $api.post<TUserActionResponse>(`/users/actions/search`, filter)
	}

	/**
	 *   Обновление действий пользователя
	 */
	static async updateActionsUsersRecord( data: TTypeOverallDataRequest, id: string): Promise<AxiosResponse<any>> {
		return $api.put<any>(`/users/actions/types/${id}`, data)
	}
	/**
	 * Получение всех типов действий пользователей
	 */
	static actionsTypeKey = '/users/actions/types'
	static async getActionsType(): Promise<AxiosResponse<TUserActionsType[]>> {
		return $api.get<TUserActionsType[]>('/users/actions/types')
	}
}
