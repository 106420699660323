export const ArchiveActionTypes = {
    GET_ARCHIVE_LIST_START: '[ARCHIVE] GET_ARCHIVE_LIST_START',
    GET_ARCHIVE_LIST_SUCCESS: '[ARCHIVE] GET_ARCHIVE_LIST_SUCCESS',
    GET_ARCHIVE_LIST_ERROR: '[ARCHIVE] GET_ARCHIVE_LIST_ERROR',

    DELETE_ARCHIVE_RECORD_START: '[ARCHIVE] DELETE_ARCHIVE_RECORD_START',
    DELETE_ARCHIVE_RECORD_SUCCESS: '[ARCHIVE] DELETE_ARCHIVE_RECORD_SUCCESS',
    DELETE_ARCHIVE_RECORD_ERROR: '[ARCHIVE] DELETE_ARCHIVE_RECORD_ERROR',

    DELETE_ARCHIVE_RECORDS_START: '[ARCHIVE] DELETE_ARCHIVE_RECORDS_START',
    DELETE_ARCHIVE_RECORDS_SUCCESS: '[ARCHIVE] DELETE_ARCHIVE_RECORDS_SUCCESS',
    DELETE_ARCHIVE_RECORDS_ERROR: '[ARCHIVE] DELETE_ARCHIVE_RECORDS_ERROR',

    SET_ARCHIVE_FILTER: '[ARCHIVE] SET_ARCHIVE_FILTER',

} as const;
