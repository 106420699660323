import {createSelector} from 'reselect'
import {TApplicationState} from '../aplication-state'
import {IOptions} from "../../models/feels";

const reportingState = (state: TApplicationState) => state.reporting

export const selectReporting = createSelector(reportingState, (state) => state.reporting)

export const selectReportingFilter = createSelector(reportingState, (state) => state.filter)
export const selectAvailableReports = createSelector(reportingState, (state) => state.availableReports)

export const selectReportsTemplatesOptions = createSelector(
    reportingState,
    (state) => {
    if (state.reportsTemplates) {
        return state.reportsTemplates.map((el) => ({label:`${el.code} - ${el.name}`, value: el.id} as IOptions))
    }
    else return null;
})

export const selectReportingIsLoading = createSelector(reportingState, (state) => state.isLoading)

