import React, {useMemo} from 'react';
import ContractorFieldForm from "./contractor/contractor";
import UsersFieldForm from "./users";
import {useFormikContext} from "formik";
import {ProjectFormDto} from "../../../lib";
import {UserApi} from 'entities/user'
import {useQuery} from "react-query";

const ProducersField = () => {
    const {values, errors} = useFormikContext<ProjectFormDto>();
    const form = values.stepFourth;

    const {data: producers = null, refetch, isLoading} = useQuery({
        queryKey: [UserApi.UserController.keyOptions, {roleNames: ['ROLE_PRODUCER'], isDeleted: false}],
        queryFn: ()=> UserApi.UserController.optionsStatus({roleNames: ['ROLE_PRODUCER'], isDeleted: false}),
    })

    const projectManagersPresence = form.projectManagers.value.length > 0

    const memorize = useMemo(() => {
        return {
            disabledField: form.producersTutor.value?.map((el) => el.value),
            disabledFieldTutor: form.producers.value?.map((el) => el.value),
            options: producers,
            disable: form.producers.isDisabled,
            disableTutor: !(form.producers.value?.length > 0),
            disableTutorVisible: !(form.producersTutor.value?.length > 0)
        }
    }, [producers, form.producers.value, form.producers.isDisabled, form.producersTutor.value]);

    const handlerUpdateUsers = async () => {
        await refetch()
    }

    return (
        <UsersFieldForm
            isLoadingFeels={isLoading}
            field={"producers"}
            fieldTutor={"producersTutor"}
            fieldTutorVisible={"producersTutorVisible"}
            options={memorize.options}
            disable={memorize.disable}
            disableTutor={memorize.disableTutor}
            disableTutorVisible={memorize.disableTutorVisible}
            label={{first: projectManagersPresence
                    ? 'Продюсер': (
                        <>Продюсер <sup>*</sup></>
                    ),
                second: 'Куратор продюсера'}}
            disabledOptions={memorize.disabledField}
            disabledOptionsTutor={memorize.disabledFieldTutor}
            errorMessage={errors.stepFourth?.producers as string}
            handlerUpdateList={handlerUpdateUsers}
        />
    )
}
const CoordinatorField = () => {
    const {values, errors} = useFormikContext<ProjectFormDto>();
    const form = values.stepFourth;

    const {data: coordinators = null, refetch, isLoading} = useQuery({
        queryKey: [UserApi.UserController.keyOptions, {roleNames: ['ROLE_COORDINATOR'], isDeleted: false}],
        queryFn: ()=> UserApi.UserController.optionsStatus({roleNames: ['ROLE_COORDINATOR'], isDeleted: false}),
    })

    const projectManagersPresence = form.projectManagers.value.length > 0;

    const memorize = useMemo(() => {
        return {
            disabledField: form.coordinatorsTutor.value?.map((el) => el.value),
            disabledFieldTutor: form.coordinators.value?.map((el) => el.value),
            options: coordinators,
            disable: form.coordinators.isDisabled,
            disableTutor: !(form.coordinators.value?.length > 0),
            disableTutorVisible: !(form.coordinatorsTutor.value?.length > 0)
        }
    }, [coordinators, form.coordinators.value, form.coordinators.isDisabled, form.coordinatorsTutor.value]);

    const handlerUpdateUsers = async () => {
        await refetch()
    }
    return (
        <UsersFieldForm
            isLoadingFeels={isLoading}
            field={"coordinators"}
            fieldTutor={"coordinatorsTutor"}
            fieldTutorVisible={"coordinatorsTutorVisible"}
            options={coordinators}
            disable={memorize.disable}
            disableTutor={memorize.disableTutor}
            disableTutorVisible={memorize.disableTutorVisible}
            label={{
                first: projectManagersPresence
                    ? 'Координатор' : (
                        <>Координатор <sup>*</sup></>
                    ),
                second: 'Куратор координатора'}}
            disabledOptions={memorize.disabledField}
            disabledOptionsTutor={memorize.disabledFieldTutor}
            errorMessage={errors.stepFourth?.coordinators as string}
            handlerUpdateList={handlerUpdateUsers}
        />
    )
}
const LawyersField = () => {
    const {values, errors} = useFormikContext<ProjectFormDto>();
    const form = values.stepFourth;

    const {data: lawyers = null, refetch, isLoading} = useQuery({
        queryKey: [UserApi.UserController.keyOptions, {roleNames: ['ROLE_LAWYER'], isDeleted: false}],
        queryFn: ()=> UserApi.UserController.optionsStatus({roleNames: ['ROLE_LAWYER'], isDeleted: false}),
    })

    const memorize = useMemo(() => {
        return {
            disabledFiled: form.lawyersTutor.value?.map((el) => el.value),
            disabledFiledTutor: form.lawyers.value?.map((el) => el.value),
            options: lawyers,
            disable: form.lawyers.isDisabled,
            disableTutor: !(form.lawyers.value?.length > 0),
            disableTutorVisible: !(form.lawyersTutor.value?.length > 0)
        }
    }, [form.lawyersTutor.value, form.lawyers.value, form.lawyers.isDisabled, lawyers]);

    const handlerUpdateUsers = async () => {
        await refetch()
    }

    return (
        <UsersFieldForm
            isLoadingFeels={isLoading}
            field={"lawyers"}
            fieldTutor={"lawyersTutor"}
            fieldTutorVisible={"lawyersTutorVisible"}
            options={memorize.options}
            disable={memorize.disable}
            disableTutor={memorize.disableTutor}
            disableTutorVisible={memorize.disableTutorVisible}
            label={{first: (
                    <>Юрист <sup>*</sup></>
                ), second: 'Куратор юриста'}}
            disabledOptions={memorize.disabledFiled}
            disabledOptionsTutor={memorize.disabledFiledTutor}
            errorMessage={errors.stepFourth?.lawyers as string}
            handlerUpdateList={handlerUpdateUsers}
        />
    )
}
const FinanciersField = () => {
    const {values, errors} = useFormikContext<ProjectFormDto>();
    const form = values.stepFourth;

    const {data: financiers = null, refetch, isLoading} = useQuery({
        queryKey: [UserApi.UserController.keyOptions, {roleNames: ['ROLE_FINANCIER'], isDeleted: false}],
        queryFn: ()=> UserApi.UserController.optionsStatus({roleNames: ['ROLE_FINANCIER'], isDeleted: false}),
    })

    const memorize = useMemo(() => {
        return {
            disabledFiled: form.financiersTutor.value?.map((el) => el.value),
            disabledFiledTutor: form.financiers.value?.map((el) => el.value),
            options: financiers,
            disable: form.financiers.isDisabled,
            disableTutor: !(form.financiers.value?.length > 0),
            disableTutorVisible: !(form.financiersTutor.value?.length > 0)
        }
    }, [financiers, form.financiers.isDisabled, form.financiers.value, form.financiersTutor.value]);

    const handlerUpdateUsers = async () => {
        await refetch()
    }

    return (
        <UsersFieldForm
            isLoadingFeels={isLoading}
            field={"financiers"}
            fieldTutor={"financiersTutor"}
            fieldTutorVisible={"financiersTutorVisible"}
            options={memorize.options}
            disable={memorize.disable}
            disableTutor={memorize.disableTutor}
            disableTutorVisible={memorize.disableTutorVisible}
            label={{first: (
                <>Финансист <sup>*</sup></>
                ), second: 'Куратор финансиста'}}
            disabledOptions={memorize.disabledFiled}
            disabledOptionsTutor={memorize.disabledFiledTutor}
            errorMessage={errors.stepFourth?.financiers as string}
            handlerUpdateList={handlerUpdateUsers}
        />
    )
}
const AnalystsField = () => {
    const {values, errors} = useFormikContext<ProjectFormDto>();
    const form = values.stepFourth;

    const {data: analysts = null, refetch, isLoading} = useQuery({
        queryKey: [UserApi.UserController.keyOptions, {roleNames: ['ROLE_ANALYST'], isDeleted: false}],
        queryFn: ()=> UserApi.UserController.optionsStatus({roleNames: ['ROLE_ANALYST'], isDeleted: false}),
    })

    const memorize = useMemo(() => {
        return {
            disabledFiled: form.analystsTutor.value?.map((el) => el.value),
            disabledFiledTutor: form.analysts.value?.map((el) => el.value),
            options: analysts,
            disable: form.analysts.isDisabled,
            disableTutor: !(form.analysts.value?.length > 0),
            disableTutorVisible: !(form.analystsTutor.value?.length > 0)
        }
    }, [analysts, form.analysts.isDisabled, form.analysts.value, form.analystsTutor.value]);

    const handlerUpdateUsers = async () => {
        await refetch()
    }

    return (
        <UsersFieldForm
            isLoadingFeels={isLoading}
            field={"analysts"}
            fieldTutor={"analystsTutor"}
            fieldTutorVisible={"analystsTutorVisible"}
            options={memorize.options}
            disable={memorize.disable}
            disableTutor={memorize.disableTutor}
            disableTutorVisible={memorize.disableTutorVisible}
            label={{first: (<>Аналитик <sup>*</sup></>), second: 'Куратор аналитика'}}
            disabledOptions={memorize.disabledFiled}
            disabledOptionsTutor={memorize.disabledFiledTutor}
            errorMessage={errors.stepFourth?.analysts as string}
            handlerUpdateList={handlerUpdateUsers}
        />
    )
}
const ContentEditorsField = () => {
    const {values, errors} = useFormikContext<ProjectFormDto>();
    const form = values.stepFourth;

    const {data: contentEditors = null, refetch, isLoading} = useQuery({
        queryKey: [UserApi.UserController.keyOptions, {roleNames: ['ROLE_CONTENT_EDITOR'], isDeleted: false}],
        queryFn: ()=> UserApi.UserController.optionsStatus({roleNames: ['ROLE_CONTENT_EDITOR'], isDeleted: false}),
    })

    const memorize = useMemo(() => {
        return {
            disabledFiled: form.contentEditorsTutor.value?.map((el) => el.value),
            disabledFiledTutor: form.contentEditors.value?.map((el) => el.value),
            options: contentEditors,
            disable: form.contentEditors.isDisabled,
            disableTutor: !(form.contentEditors.value?.length > 0),
            disableTutorVisible: !(form.contentEditorsTutor.value?.length > 0)
        }
    }, [contentEditors, form.contentEditors.isDisabled, form.contentEditors.value, form.contentEditorsTutor.value]);

    const handlerUpdateUsers = async () => {
        await refetch()
    }

    return (
        <UsersFieldForm
            isLoadingFeels={isLoading}
            field={"contentEditors"}
            fieldTutor={"contentEditorsTutor"}
            fieldTutorVisible={"contentEditorsTutorVisible"}
            options={memorize.options}
            disable={memorize.disable}
            disableTutor={memorize.disableTutor}
            disableTutorVisible={memorize.disableTutorVisible}
            label={{first: 'Редактор контента', second: 'Куратор редактора контента'}}
            disabledOptions={memorize.disabledFiled}
            disabledOptionsTutor={memorize.disabledFiledTutor}
            errorMessage={errors.stepFourth?.contentEditors as string}
            handlerUpdateList={handlerUpdateUsers}
        />
    )
}

const ProjectManagersField = () => {
    const {values, errors} = useFormikContext<ProjectFormDto>();
    const form = values.stepFourth;

    const {data: projectManagers = null, refetch, isLoading} = useQuery({
        queryKey: [UserApi.UserController.keyOptions, {roleNames: ['ROLE_PROJECT_MANAGER'], isDeleted: false}],
        queryFn: ()=> UserApi.UserController.optionsStatus({roleNames: ['ROLE_PROJECT_MANAGER'], isDeleted: false}),
    })

    const producersCoordinatorsPresence = form.producers.value.length > 0 && form.coordinators.value.length > 0;

    const memorize = useMemo(() => {
        return {
            disabledFiled: form.projectManagersTutor.value?.map((el) => el.value),
            disabledFiledTutor: form.projectManagers.value?.map((el) => el.value),
            options: projectManagers,
            disable: form.projectManagers.isDisabled,
            disableTutor: !(form.projectManagers.value?.length > 0),
            disableTutorVisible: !(form.projectManagersTutor.value?.length > 0)
        }
    }, [projectManagers, form.projectManagers.isDisabled, form.projectManagers.value, form.projectManagersTutor.value]);

    const handlerUpdateUsers = async () => {
        await refetch()
    }

    return (
        <UsersFieldForm
            isLoadingFeels={isLoading}
            field={"projectManagers"}
            fieldTutor={"projectManagersTutor"}
            fieldTutorVisible={"projectManagersTutorVisible"}
            options={memorize.options}
            disable={memorize.disable}
            disableTutor={memorize.disableTutor}
            disableTutorVisible={memorize.disableTutorVisible}
            label={{
                first: producersCoordinatorsPresence
                    ? 'Менеджер проекта' : (
                        <>Менеджер проекта <sup>*</sup></>
                    ),
                second: 'Куратор менеджера проекта'
        }}
            disabledOptions={memorize.disabledFiled}
            disabledOptionsTutor={memorize.disabledFiledTutor}
            errorMessage={errors.stepFourth?.projectManagers as string}
            handlerUpdateList={handlerUpdateUsers}
        />
    )
}

const StepFourth = () => {
    return (
        <>
            <ContractorFieldForm/>
            <ProjectManagersField/>
            <ProducersField/>
            <ContentEditorsField/>
            <CoordinatorField/>
            <LawyersField/>
            <FinanciersField/>
            <AnalystsField/>
        </>
    )
};
export default React.memo(StepFourth);