import {BlockKeyErrorAction, BlockKeyStartAction, BlockKeySuccessAction} from "../../store/auth/actions";
import AuthService from "../../services/authService";
import {put} from "redux-saga/effects";
import CreateErrorMessage from "../../utils/validation/createErrorMessage";
import {NotificationOpen} from "../../store/notification/actions";


export function* blockAuthKey (_action : ReturnType<typeof BlockKeyStartAction>){
    try {
        yield AuthService.blockKey();
        yield put(BlockKeySuccessAction())
        yield put(NotificationOpen('success', 'Ключ заблокирован'))

    } catch (error: any) {
        yield put(BlockKeyErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось заблокировать ключ'))
    }
}
