import {createFilter, setUserFiltersValue} from "../../store/actions";
import {call, delay, put, select} from "redux-saga/effects";
import {UserFiltersModel, UserFiltersTypes } from "features/user-filters/index";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {FilterValues} from "../../store/reducer";
import { UserTypes } from "entities/user";
import {TRole} from "models/user";
import {selectRole} from "store/auth/selectors";
import {extendedSearch} from "./project/extended-search";
import {search} from "./project/search";
import {Logger} from "shared/services";
import {tasksProjects} from "./tasks-records/tasks-projects";
import {tasksRecords} from "./tasks-records/tasks-records";
import {tasks} from "./tasks";
import {taskContent} from "./task/task-content";
import {taskReach} from "./task/task-reach";
import {taskCopyright} from "./task/task-copyright";
import {taskPosting} from "./task/task-posting";
import {taskEstimate} from "./task/task-estimate";
import {users} from "./users/users";

const rolesForSearchCase: UserTypes.RoleName[] = ['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_CLIENT'];
export function* createFilterWorker({filterType, callback}: ReturnType<typeof createFilter>) {
    try {
        const role: TRole = yield select(selectRole);
        if (!role) {
            yield delay(500)
            yield put(createFilter(filterType, callback))
        }

        const filter: FilterValues | undefined = yield select(UserFiltersModel.select.filter(filterType));
        const types: UserFiltersTypes.Types[] = yield select(UserFiltersModel.select.types)
        const typeId = types.find(({code}) => code === filterType)?.id;
        if (!typeId) return;
        if (['PROJECTS', 'PROJECTS_CALENDAR', 'PROJECTS_CONTENT'].includes(filterType)) {
            if (rolesForSearchCase.includes(role)) {
                yield call(search, typeId, filter)
            } else {
                yield call(extendedSearch, typeId, filter)
            }
        }
        if (filterType === 'TASKS') {
           yield call(tasksProjects, typeId, filter)
        }
        if (filterType === 'TASKS_RECORDS') {
            yield call(tasksRecords, typeId, filter)
        }
        if (filterType === 'PROJECTS_KANBAN') {
            yield call(tasks, typeId, filter)
        }
        if (filterType === 'CONTENT_TASK') {
            yield call(taskContent, typeId, filter)
        }
        if (filterType === 'REACH_TASK') {
            yield call(taskReach, typeId, filter)
        }
        if (filterType === 'COPYRIGHT_TASK') {
            yield call(taskCopyright, typeId, filter)
        }
        if (filterType === 'POSTING_TASK') {
            yield call(taskPosting, typeId, filter)
        }
        if (filterType === 'ESTIMATE_TASK') {
            yield call(taskEstimate, typeId, filter)
        }
        if (filterType === 'USERS_SEARCH') {
            yield call(users, typeId, filter)
        }
        callback()//повторный запрос фильтра
    } catch (error) {
        Logger.error('createFilterWorker', error)
        yield put(setUserFiltersValue({error: error}))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Что-пошло не так'))
    } finally {
        yield put(setUserFiltersValue({isLoading: false}))
    }
}