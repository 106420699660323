import $api from "../../http";
import {put} from 'redux-saga/effects';
import {AxiosResponse} from 'axios';
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {
    GetUnfinishedErrorAction,
    GetUnfinishedStartAction,
    GetUnfinishedSuccessAction
} from "store/uploader/actions";
import {UnfinishedFile} from "models/common";


export function* getUnfinishedFiles(_action: ReturnType<typeof GetUnfinishedStartAction>) {
    try {
        const {data: files}: AxiosResponse<UnfinishedFile[]> = yield $api.get('/files/uploading/unfinished')
        yield put(GetUnfinishedSuccessAction(files))


    } catch (error: any) {
        yield put(GetUnfinishedErrorAction(error));
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : `Не удалось получить файлы`))
    }
}