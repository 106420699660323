import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Select, Spin} from "antd";
import AppService from "services/appService";
import LegalFormOrganizationService from "services/legalFormOrganizationService";
import {ExtendedSearchModel, ExtendedSearchTypes} from 'features/extended-search/index';
import {TLegalFormOrganization} from "models/handbook";
import {TOrganization} from "models/common";
import {IOptions} from "models/feels";
import {useQuery} from "react-query";
const { Option} = Select;

function useGetsData() {
    const organizationsWithContractors = useQuery({
        queryKey: [AppService.organizationsWithContractorsKey],
        queryFn: async () => {
            const {data} = await AppService.getOrganizationsWithContractors()
            return transform(data)
        },
        refetchOnWindowFocus: false
    })
    const organizationsLegalForm = useQuery({
        queryKey: [LegalFormOrganizationService.legalFormOrganizationKey],
        queryFn: async () => {
            const {data} = await LegalFormOrganizationService.getLegalFormOrganization()
            return transformLegalForm(data)
        },
        refetchOnWindowFocus: false
    })

    return {
        loading: organizationsWithContractors.isLoading || organizationsLegalForm.isLoading,
        options: {
            organizationIds: organizationsWithContractors.data || [],
            legalFormIds: organizationsLegalForm.data || []
        }
    }
}
type TKey = keyof Omit<
    ExtendedSearchTypes.Stores.Organizations, 'isToggled'>
export const OrganizationItem = () => {
    const put = useDispatch();
    const {loading, options} = useGetsData();

    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const organizations = useSelector(ExtendedSearchModel.selectors.organizations)

    const onSelectChange = (field: TKey) => (value: string[]) => {
        put(ExtendedSearchModel.actions.setOrganizations(typeContent, {[field]: value}))
    }

    return (
        <Spin spinning={loading}>
            <div className="item-wrapper">
                <div className="item">
                    <div className="label">Наименование</div>
                    <div className="form-group">
                        <Select
                            allowClear={true}
                            showSearch={true}
                            onChange={onSelectChange("organizationIds")}
                            maxTagCount={"responsive"}
                            mode={"multiple"}
                            value={organizations[typeContent]["organizationIds"]}
                            getPopupContainer={trigger => trigger.parentNode}
                            placeholder={'Искать среди всех'}
                            filterOption={(input, option) => {
                                if (option === undefined || option === null) return false;
                                const numberInput = +input
                                if (isNaN(numberInput)) {
                                    return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } else return option['data-code'].indexOf(input) >= 0;
                            }}
                        >
                            {options.organizationIds.map((el) => (
                                <Option
                                    key={el.value}
                                    value={el.value}
                                    data-code={el.code}
                                    data-name={el.label}
                                >
                                    {el.label}<br/><small>ИНН - {el.code}</small>
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="item">
                    <div className="label">Форма организации</div>
                    <div className="form-group">
                        <Select
                            allowClear={true}
                            showSearch={true}
                            onChange={onSelectChange("legalFormIds")}
                            maxTagCount={"responsive"}
                            mode={"multiple"}
                            getPopupContainer={trigger => trigger.parentNode}
                            value={organizations[typeContent]["legalFormIds"]}
                            placeholder={'Искать среди всех'}
                            filterOption={(input, option) => {
                                if (option === undefined || option === null) return false;
                                return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                        >
                            {options.legalFormIds.map((el) => (
                                <Option
                                    key={el.value}
                                    value={el.value}
                                    data-name={el.label}
                                >
                                    {el.label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
        </Spin>

    )
}
function transform(arr: TOrganization[]): IOptions[] {
    return arr.map((el) => ({value: el.id, label: el.name, code: el.inn}))
}
function transformLegalForm(arr: TLegalFormOrganization[]): IOptions<string | null>[] {
    return arr.map((el) => ({value: el.id, label: el.fullName, code: el.code || ''}))
}