import React, {FC, useState} from 'react';
import {TaskModel } from "entities/task";
import {Button} from "antd";
import styles from '../modal.module.less'
import {useDispatch} from "react-redux";
import ReactQuill from "react-quill";
import FilterHTML from "utils/filterHTML";

type TProps = {
    id: string
}

const CreateComment: FC<TProps> = ({id}) => {
    const put = useDispatch()
    const [value,setValue] = useState('')
    const [flag, setFlag] = useState(false)

    const handlerChange = (value: any) => {
        if (value === "<p><br></p>") {
            setValue('');
            return
        }
        setValue(value)
    }

    const handlerClickInput = () => {
        setFlag(true)
    }

    const handlerClickSave = () => {
        if(value) {
            put(TaskModel.actions.Create({
                text: value,
                taskId: id
            }))
            setValue('')
            setFlag(false)
        }
    }

    const handlerClickCancel = () => {
        setFlag(false)
    }

    return (
        <div className={styles.create}>
            <div className={styles.circle}/>
            {!flag && (
                <div className={styles.add} onClick={handlerClickInput}>
                    Добавить комментарий...
                </div>
            )}
            {flag && (
              <div className={styles.create_area}>
                  <ReactQuill
                      onChange={handlerChange}
                      theme="snow"
                      value={value}
                  />
                  <p className={styles.limit}>
                      {value ? FilterHTML(value)?.length : 0} / 5000 {value?.length > 5000 && `Фактический размер: теги + текст ${value.length} превысил лимит в 5000 символов`}
                  </p>
                  <div className={styles.button_container}>
                      <Button htmlType="submit" className={styles.button_save} onClick={handlerClickSave}>
                          Сохранить
                      </Button>
                      <Button htmlType="submit" className={styles.button_cancel} onClick={handlerClickCancel}>
                          Отменить
                      </Button>

                  </div>
              </div>
            )}

        </div>
    );
};

export default CreateComment;