import {call, put, select} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {AxiosResponse} from "axios";
import {
    TasksReportCopyrightService,
    TasksReportEstimateService,
    TasksReportPostingService,
    TasksReportReachService
} from "services/tasksReportService";
import {selectReportTasksFilter} from "store/tasks/selectors/selectors-task-report";
import {TTaskEstimateCost, TTaskReportsTypes} from "models/task/task-report";
import {TTaskRecordsFilter} from "models/task/task-list";
import {
    GetTaskReportErrorAction,
    GetTaskReportStartAction,
    GetTaskReportSuccessAction,
    SetTaskReportFilter,
    VoteTaskReportChangeMultipleStatuses
} from "store/tasks/actions/report";
import {Query} from "processes/query";
import {TaskApi, TaskTypes} from 'entities/task';
import ZodParser from 'shared/services/zod-parser';
import {selectUser} from "store/auth/selectors";
import {UserTypes} from 'entities/user'

export function* getReportTasks({taskId, typeTask}: ReturnType<typeof GetTaskReportStartAction>) {
    try {
        const filter: TTaskRecordsFilter = yield select(selectReportTasksFilter);
        let res: TTaskReportsTypes = {page: 1, pageSize: 50, content: []};
        let resCost: TTaskEstimateCost[] | null = null;

        if (typeTask === 'CONTENT_POSTING_REPORT') {
            const {data: Posting}: AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.PostingRecords[]>> = yield TasksReportPostingService.getTaskRecords({
                ...filter,
                filterData: {...filter.filterData, taskId: taskId}
            })
            ZodParser.parse(
              TaskTypes.PostingRecordsSchema.array(),
              Posting.content,
              `/tasks/posting/records/search`
            );
            res = Posting;
        }

        if (typeTask === 'PROJECT_ESTIMATE') {
            const {data: Estimate}: AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.EstimateRecords[]>> = yield TasksReportEstimateService.getTaskRecords({
                ...filter,
                filterData: {...filter.filterData, taskId: taskId}
            })
            res = Estimate;
            const {data: EstimateCost}: AxiosResponse<TTaskEstimateCost[]> = yield TasksReportEstimateService.getAllTaskCostReport(taskId, filter)
            ZodParser.parse(
              TaskTypes.EstimateRecordsSchema.array(),
                Estimate.content,
              `/tasks/estimate/records/search`
            );
            resCost = EstimateCost;
        }

        if (typeTask === 'CONTENT_REACH_REPORT') {
            const {data: Reach}: AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.ReachRecords[]>> = yield TasksReportReachService.getTaskRecords({
                ...filter,
                filterData: {...filter.filterData, taskId: taskId}
            })
            ZodParser.parse(
              TaskTypes.ReachRecordsSchema.array(),
              Reach.content,
              `/tasks/reach/records/search`
            );
            res = Reach;
            Query.invalidate([TaskApi.getApprovedReachAmountSum.key(taskId)]);
        }
        if (typeTask === 'COPYRIGHT_LIST') {
            const {data: Copyright}: AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.CopyrightRecords[]>> = yield TasksReportCopyrightService.getTaskRecords({
                ...filter,
                filterData: {...filter.filterData, taskId: taskId}
            })
            ZodParser.parse(
              TaskTypes.CopyrightRecordsSchema.array(),
              Copyright.content,
              `/tasks/copyright/records/search`
            );
            res = Copyright;
        }
        yield call(saveVotes, res)
        if (checkValidPage(res)) {
            yield put(SetTaskReportFilter({...filter, page: 1}, typeTask))
        } else {
            Query.invalidate([TaskApi.getTaskRecordsCount.key(taskId as string)]);
            yield put(GetTaskReportSuccessAction(res, new Date().toISOString(), resCost))
        }

    } catch (error: any) {
        yield put(GetTaskReportErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen(
            'error',
            'Ошибка',
            message ? message : 'Не удалось получить задачи'
        ))
    }
}

function checkValidPage(data: TaskTypes.TaskRecordsResponse<any>): boolean {
    return !!(data?.totalCount && data.page * data.pageSize >= data?.totalCount && data.totalCount !== 0)
}
/**
 * После получения данных по записям, сохраняем в redux голоса(votes), используем action (VoteTaskReportChangeMultipleStatuses) который сохраняет пачкой
 */
function* saveVotes (data: TaskTypes.TaskRecordsResponse<any>) {
    const user: UserTypes.CurrentUser = yield select(selectUser)
    let votes: Record<string, TaskTypes.NewVote> = {};

    for (const record of data.content) {
        for (const vote of record?.votes || []) {
            const voteData: TaskTypes.NewVote = {
                vote: {
                    comment: vote.comment || null,
                    isApproved: vote.isInterimApproved,
                    isInterimApproved: vote.isInterimApproved,
                    recordId: record.id,
                },
                files: vote.files || [],
            };
            if (vote.isInterimApproved !== null && vote.user.id === user.id) {
                votes[record.id] = voteData
            }
        }
    }
    yield put(VoteTaskReportChangeMultipleStatuses(votes))
}