import {call, put, select} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import { CalendarModel, CalendarTypes } from "pages/calendar";
import CalendarService from "pages/calendar/service";
import {ExtendedSearchLib, ExtendedSearchTypes } from "features/extended-search";
import {TProjectsFilterExtendedSearch} from "models/projects/filter";

export function* CalendarFunction(filterES: ExtendedSearchTypes.Stores.TAllFilterValues): Generator<any, void, any> {
    yield put(CalendarModel.actions.setCalendarValue({isLoading: true, error: false}))
    const filterMain: CalendarTypes.TCalendarState['filter'] = yield select(CalendarModel.select.filter);
    const {filterData, ...rest} = filterMain;

    const filter = {
        ...rest,
        filterData: {
            ...(!!filterData?.searchString && { searchString: filterData.searchString}),
            ...ExtendedSearchLib.mapData(filterES),
        }
    } as TProjectsFilterExtendedSearch;
    const {data}: AxiosResponse<CalendarTypes.CalendarResponse> = yield CalendarService.extendedSearch(filter)
    if (data.totalCount && data.page * data.pageSize >= data.totalCount && data.totalCount !== 0) {
        yield put(CalendarModel.actions.setCalendarValue({
            isLoading: false,
            filter: {...filterMain, totalCount: data.totalCount, page: 1},
            list: data.content
        }))
        yield call(CalendarFunction, filterES)
    } else {
        yield put(CalendarModel.actions.setCalendarValue({
            isLoading: false,
            filter: {...filterMain, totalCount: data.totalCount},
            list: data.content
        }))
    }
}