import {ActionTypes} from './types'
import {InferValueTypes} from "models/common";
import * as actions from "./actions";
import { ExtendedSearchTypes } from 'features/extended-search';
type ActionsTypes = ReturnType<InferValueTypes<typeof actions>>;

const initialState: ExtendedSearchTypes.Stores.TProjectState = {
    content: {
        isToggled: false,
        requestNumbers: [],
        contractNumbers: [],
        codes: [],
        igks: [],
    },
    projects: {
        isToggled: false,
        requestNumbers: [],
        contractNumbers: [],
        codes: [],
        igks: [],
    },
    calendar: {
        isToggled: false,
        requestNumbers: [],
        contractNumbers: [],
        codes: [],
        igks: [],
    }
}

export default function reducer(
    state: ExtendedSearchTypes.Stores.TProjectState = initialState,
    action: ActionsTypes
): ExtendedSearchTypes.Stores.TProjectState {
    switch (action.type) {
        case ActionTypes.SET_VALUE:
            return {
                ...state,
                [action.typeContent]: {
                    ...state[action.typeContent],
                    isToggled: true,
                    ...action.value}
            }
        case ActionTypes.SET_VALUES:
            return {
                ...state,
                [action.typeContent]: {
                    ...state[action.typeContent],
                    isToggled: action.isToggled,
                    ...action.value}
            }
        case ActionTypes.CLEAR_VALUE:
            return {
                ...state,
                [action.typeContent]: initialState[action.typeContent]
            }
        default:
            return state
    }
}