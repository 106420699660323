import {put, select} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {selectParams} from "store/selectors";
import {
    GetTasksContentStartAction,
    SendContentBulkVotesErrorAction,
    SendContentBulkVotesStartAction,
    SendContentBulkVotesSuccessAction
} from "store/tasks/actions/content";
import TasksContentService from "services/tasksContentService";


export function* sendContentBulkVote({votes}: ReturnType<typeof SendContentBulkVotesStartAction>) {
    try {
        const {id} = yield select(selectParams)
        yield TasksContentService.sendBulkVote(votes);
        yield put(SendContentBulkVotesSuccessAction())
        yield put(NotificationOpen('success', 'Голоса отправлены'))
        if (id) yield put(GetTasksContentStartAction(id))
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', message))
        yield put(SendContentBulkVotesErrorAction(message))
    }
}