import {put} from "redux-saga/effects";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    CreateFileArchiveErrorAction,
    CreateFileArchiveStartAction,
    CreateFileArchiveSuccessAction
} from "store/tasks/actions/content";
import TasksContentService from "services/tasksContentService";
import {NotificationOpen} from "store/notification/actions";


export function* createFileArchive({recordsIds}: ReturnType<typeof CreateFileArchiveStartAction>) {
    try {
        yield TasksContentService.createFileArchive(recordsIds);
        yield put(CreateFileArchiveSuccessAction())
        yield put(NotificationOpen('success', 'Архив формируется', 'Для скачивания перейдите в  окно запросов на получение архивов'))
    } catch (error: any) {
        const message = CreateErrorMessage.response(error)
        yield put(CreateFileArchiveErrorAction(message))
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось сформировать архив'))

    }
}