export const TasksActionTypes = {
    CLEAR_STATUS: '[TASKS] CLEAR_STATUS',

    GET_TASKS_FIELDS_STAT: '[TASKS] GET_TASKS_FIELDS_STAT',
    GET_TASKS_FIELDS_SUCCESS: '[TASKS] GET_TASKS_FIELDS_SUCCESS',
    GET_TASKS_FIELDS_ERROR: '[TASKS] GET_TASKS_FIELDS_ERROR',

    GET_TASKS_START: '[TASKS] GET_TASKS_START',
    GET_TASKS_SUCCESS: '[TASKS] GET_TASKS_SUCCESS',
    GET_TASKS_ERROR: '[TASKS] GET_TASKS_ERROR',

    SET_TASK_FILTER: '[TASKS] SET_TASK_FILTER',
    RESET_TASK_FILTER: '[TASKS] RESET_TASK_FILTER',

    CREATE_TASK_START: '[TASKS] CREATE_TASK_START',
    CREATE_TASK_SUCCESS: '[TASKS] CREATE_TASK_SUCCESS',
    CREATE_TASK_ERROR: '[TASKS] CREATE_TASK_ERROR',

    GET_TASK_START: '[TASKS] GET_TASK_START',
    GET_TASK_SUCCESS: '[TASKS] GET_TASK_SUCCESS',
    GET_TASK_ERROR: '[TASKS] GET_TASK_ERROR',

    DELETE_TASK_START: '[TASKS] DELETE_TASK_START',
    DELETE_TASK_SUCCESS: '[TASKS] DELETE_TASK_SUCCESS',
    DELETE_TASK_ERROR: '[TASKS] DELETE_TASK_ERROR',

    UPDATE_TASK_START: '[TASKS] UPDATE_TASK_START',
    UPDATE_TASK_SUCCESS: '[TASKS] UPDATE_TASK_SUCCESS',
    UPDATE_TASK_ERROR: '[TASKS] UPDATE_TASK_ERROR',

    UPDATE_STATUS_TASK_START: '[TASKS] UPDATE_STATUS_TASK_START',
    UPDATE_STATUS_TASK_SUCCESS: '[TASKS] UPDATE_STATUS_TASK_SUCCESS',
    UPDATE_STATUS_TASK_ERROR: '[TASKS] UPDATE_STATUS_TASK_ERROR',

    GET_TASK_POSTING_START: '[TASKS] GET_TASK_POSTING_START',
    GET_TASK_POSTING_SUCCESS: '[TASKS] GET_TASK_POSTING_SUCCESS',
    GET_TASK_POSTING_ERROR: '[TASKS] GET_TASK_POSTING_ERROR',

    DUPLICATE_TASKS_START: '[TASKS] DUPLICATE_TASKS_START',
    DUPLICATE_TASKS_SUCCESS: '[TASKS] DUPLICATE_TASKS_SUCCESS',
    DUPLICATE_TASKS_ERROR: '[TASKS] DUPLICATE_TASKS_ERROR',

    DUPLICATE_SCHEDULE_TASKS_START: '[TASKS] DUPLICATE_SCHEDULE_TASKS_START',
    DUPLICATE_SCHEDULE_TASKS_SUCCESS: '[TASKS] DUPLICATE_SCHEDULE_TASKS_SUCCESS',
    DUPLICATE_SCHEDULE_TASKS_ERROR: '[TASKS] DUPLICATE_SCHEDULE_TASKS_ERROR',

    UPDATE_DUPLICATE_SCHEDULE_TASKS_START: '[TASKS] UPDATE_DUPLICATE_SCHEDULE_TASKS_START',
    UPDATE_DUPLICATE_SCHEDULE_TASKS_SUCCESS: '[TASKS] UPDATE_DUPLICATE_SCHEDULE_TASKS_SUCCESS',
    UPDATE_DUPLICATE_SCHEDULE_TASKS_ERROR: '[TASKS] UPDATE_DUPLICATE_SCHEDULE_TASKS_ERROR',

    GET_ALL_TASKS_START: '[TASKS] GET_ALL_TASKS_START',
    GET_ALL_TASKS_SUCCESS: '[TASKS] GET_ALL_TASKS_SUCCESS',
    GET_ALL_TASKS_ERROR: '[TASKS] GET_ALL_TASKS_ERROR',
    CLEAR_ALL_TASKS: '[TASKS] CLEAR_ALL_TASKS',

    SET_ROW_IDS:'[TASKS] SET_ROW_IDS',
    CLEAR_ROW_IDS:'[TASKS] CLEAR_ROW_IDS',

} as const;
