import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetHandbookStartAction,
    UpdateDocumentsErrorAction,
    UpdateDocumentsStartAction,
    UpdateDocumentsSuccessAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import HandbookService from "services/handbookService";


export function* updateDocuments({id, data,}: ReturnType<typeof UpdateDocumentsStartAction>) {
    try {
        yield HandbookService.updateDocumentsRecord(data,id)
        yield put(UpdateDocumentsSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.documents))
        yield put(NotificationOpen('success', 'Тип документа обновлен'))
    } catch (error: any) {
        yield put(UpdateDocumentsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить тип документа'))
    }
}
