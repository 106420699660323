import {TasksReportActionTypes} from "../action-types/tasks-report";
import {
    TReachStatistic,
    TTaskCopyrightNew,
    TTaskEstimateCost,
    TTaskEstimateNew,
    TTaskPostingNew,
    TTaskReachNew,
    TTaskReportsTypes
} from "models/task/task-report";
import {ReportType} from "models/task/types";
import {TaskTypes} from "entities/task";
import {TTaskRecordsFilter} from "models/task/task-list";


/**
 * Добавление записи для задачи на публикацию контента
 */
export const AddTaskReportStartAction = (
    taskId: string,
    typeTask: ReportType
) => ({
    type: TasksReportActionTypes.ADD_TASK_START,
    taskId, typeTask
})
export const AddTaskReportSuccessAction = (recordId: string) => ({
    type: TasksReportActionTypes.ADD_TASK_SUCCESS, recordId
})
export const AddTaskReportErrorAction = (error: any) => ({
    type: TasksReportActionTypes.ADD_TASK_ERROR,
    error
})
/**
 * Получение списка записей задачи без пагинации
 */
export const GetTaskReportStartAction = (taskId: string, typeTask: TaskTypes.ReportType) => ({
    type: TasksReportActionTypes.GET_TASKS_START,
    taskId, typeTask
})
export const GetTaskReportSuccessAction = (
    tasks: TTaskReportsTypes,
    timeStamp: string,
    tasksCost?: TTaskEstimateCost[] | null
) => ({
    type: TasksReportActionTypes.GET_TASKS_SUCCESS, tasks, timeStamp, tasksCost
})
export const GetTaskReportErrorAction = (error: any) => ({
    type: TasksReportActionTypes.GET_TASKS_ERROR,
    error
})
/**
 * Обновление записи для задачи на публикацию контента
 */
export const UpdateTaskReportStartAction = (
    recordId: string,
    data: TTaskPostingNew | TTaskEstimateNew | TTaskCopyrightNew | TTaskReachNew | any,
    typeTask: ReportType,
) => ({
    type: TasksReportActionTypes.UPDATE_TASK_START,
    recordId, data, typeTask
})
export const UpdateTaskReportSuccessAction = () => ({
    type: TasksReportActionTypes.UPDATE_TASK_SUCCESS
})
export const UpdateTaskReportErrorAction = (error: any) => ({
    type: TasksReportActionTypes.UPDATE_TASK_ERROR,
    error
})
/**
 * Удаление записи задачи контента
 */
export const DeleteTaskReportStartAction = (recordId: string, typeTask: ReportType, taskId?: string) => ({
    type: TasksReportActionTypes.DELETE_TASK_START, recordId, typeTask, taskId
})
export const DeleteTaskReportSuccessAction = () => ({
    type: TasksReportActionTypes.DELETE_TASK_SUCCESS
})
export const DeleteTaskReportErrorAction = (error: any) => ({
    type: TasksReportActionTypes.DELETE_TASK_ERROR,
    error
})
// ---------------------
/**
 * Голосование за запись (голос и коментраий)
 */
export const VoteTaskReportChangeStatus = (data: TaskTypes.NewVote) => ({
    type: TasksReportActionTypes.VOTE_TASK_STATUS,
    data
})
export const VoteTaskReportChangeMultipleStatuses = (data: Record<string, TaskTypes.NewVote>) => ({
    type: TasksReportActionTypes.VOTE_TASK_MULTIPLE_STATUSES,
    data
})
export const VoteTaskReportChangeComment = (data: TaskTypes.NewVote) => ({
    type: TasksReportActionTypes.VOTE_TASK_COMMENT,
    data
})
export const VoteTaskReportChange = (id: string) => ({
    type: TasksReportActionTypes.VOTE_TASK_CHANGE,
    id
})

/**
 * Голосование за запись (отправка данных)
 */
export const VoteTaskReportStartAction = (data: TaskTypes.NewVote, typeTask: ReportType) => ({
    type: TasksReportActionTypes.VOTE_TASK_START,
    data, typeTask
})
export const VoteTaskReportSuccessAction = () => ({
    type: TasksReportActionTypes.VOTE_TASK_SUCCESS
})
export const VoteTaskReportErrorAction = (error: any) => ({
    type: TasksReportActionTypes.VOTE_TASK_ERROR,
    error
})

/**
 * Промежуточное Голосование за запись (отправка данных)
 */
export const InterimVoteTaskReportStartAction = (data: TaskTypes.NewVote, typeTask: ReportType) => ({
    type: TasksReportActionTypes.INTERIM_VOTE_TASK_START,
    data, typeTask
})
export const InterimVoteTaskReportSuccessAction = () => ({
    type: TasksReportActionTypes.INTERIM_VOTE_TASK_SUCCESS
})
export const InterimVoteTaskReportErrorAction = (error: any) => ({
    type: TasksReportActionTypes.INTERIM_VOTE_TASK_ERROR,
    error
})

export const SetTaskReportFilter = (filter: TTaskRecordsFilter , typeTask: ReportType) => ({
    type: TasksReportActionTypes.SET_TASK_FILTER,
    filter, typeTask
})
export const SetTaskReportReachFilter = (filter: TTaskRecordsFilter) => ({
    type: TasksReportActionTypes.SET_TASK_REPORT_REACH_FILTER,
    filter
})
export const SetTaskReportEstimateFilter = (filter: TTaskRecordsFilter) => ({
    type: TasksReportActionTypes.SET_TASK_REPORT_ESTIMATE_FILTER,
    filter
})
export const SetReportBulkAction = (bulkAction: string | null) => ({
    type: TasksReportActionTypes.SET_BULK_ACTION,
    bulkAction
})
export const SetReportRowsIds = (rows: string[]) => ({
    type: TasksReportActionTypes.SET_ROWS_IDS,
    rows
})

/**
 * Отправка записи на проверку
 */
export const ToExaminationTaskReportStartAction = (recordId: string, typeTask: ReportType, taskId?: string) => ({
    type: TasksReportActionTypes.TO_EXAMINATION_TASK_START,
    recordId, typeTask, taskId
})
export const ToExaminationTaskReportSuccessAction = () => ({
    type: TasksReportActionTypes.TO_EXAMINATION_TASK_SUCCESS
})
export const ToExaminationTaskReportErrorAction = (error: any) => ({
    type: TasksReportActionTypes.TO_EXAMINATION_TASK_ERROR,
    error
})

export const ResetTasksStatusStartAction = (reportType: ReportType, recordId: string) => ({
    type: TasksReportActionTypes.RESET_STATUS_TASK_START, reportType, recordId
})
export const ResetTasksStatusSuccessAction = (recordId: string) => ({
    type: TasksReportActionTypes.RESET_STATUS_TASK_SUCCESS,
    recordId
})
export const ResetTasksStatusErrorAction = (recordId: string, error: any) => ({
    type: TasksReportActionTypes.RESET_STATUS_TASK_ERROR,
    recordId, error
})

export const SendReportBulkVotesStartAction = (reportType: ReportType, votes: TaskTypes.NewVote['vote'][]) => ({
    type: TasksReportActionTypes.SEND_BULK_VOTES_START, reportType, votes
})
export const SendReportBulkVotesSuccessAction = () => ({
    type: TasksReportActionTypes.SEND_BULK_VOTES_SUCCESS,
})
export const SendReportBulkVotesErrorAction = (error: any) => ({
    type: TasksReportActionTypes.SEND_BULK_VOTES_ERROR,
    error
})
export const SendReportBulkReviewStartAction = (reportType: ReportType, recordsIds: string[]) => ({
    type: TasksReportActionTypes.SEND_BULK_REVIEW_START, reportType, recordsIds
})
export const SendReportBulkReviewSuccessAction = () => ({
    type: TasksReportActionTypes.SEND_BULK_REVIEW_SUCCESS,
})
export const SendReportBulkReviewErrorAction = (error: any) => ({
    type: TasksReportActionTypes.SEND_BULK_REVIEW_ERROR,
    error
})
/**
 * Получение списка записей задач на охваты, связанных с той же записью на размещение, что и указанная запись
 */
export const GetReachStatisticStartAction = (id: string, reachDateTime: string) => ({
    type: TasksReportActionTypes.GET_REACH_STATISTIC_START, id, reachDateTime
})
export const GetReachStatisticSuccessAction = (data: TReachStatistic[]) => ({
    type: TasksReportActionTypes.GET_REACH_STATISTIC_SUCCESS,
    data
})
export const GetReachStatisticErrorAction = (error: any) => ({
    type: TasksReportActionTypes.GET_REACH_STATISTIC_ERROR,
    error
})

/**
 * Cкачать шаблон
 */
export const DownloadReportTemplateStartAction = (reportType: string | null) => ({
    type: TasksReportActionTypes.DOWNLOAD_TEMPLATE_START,
    reportType
})
export const DownloadReportTemplateSuccessAction = () => ({
    type: TasksReportActionTypes.DOWNLOAD_TEMPLATE_SUCCESS,
})
export const DownloadReportTemplateErrorAction = (error: any) => ({
    type: TasksReportActionTypes.DOWNLOAD_TEMPLATE_ERROR,
    error
})

/**
 * Импорт данных из заполненного шаблона в формате Excel
 */
export const UploadReportTemplateStartAction = (reportType: string, data: any) => ({
    type: TasksReportActionTypes.UPLOAD_TEMPLATE_START,
    reportType, data
})
export const UploadReportTemplateSuccessAction = () => ({
    type: TasksReportActionTypes.UPLOAD_TEMPLATE_SUCCESS
})
export const UploadReportTemplateErrorAction = (error: any) => ({
    type: TasksReportActionTypes.UPLOAD_TEMPLATE_ERROR,
    error
})
/**
 * Выполнение выгрузки списка в формате Excel
 */
export const ExportReachRecordsStartAction = (rowIds: string[], name: string) => ({
    type: TasksReportActionTypes.DOWNLOAD_EXCEL_START, rowIds, name
})
export const ExportReachRecordsSuccessAction = () => ({
    type: TasksReportActionTypes.DOWNLOAD_EXCEL_SUCCESS
})
export const ExportReachRecordsErrorAction = (error: any) => ({
    type: TasksReportActionTypes.DOWNLOAD_EXCEL_ERROR, error
})

export const ClearTaskReportFilter = () => ({
    type: TasksReportActionTypes.CLEAR_FILTER
})

/**
 * Получение одной записи задачи
 */
export const GetRecordStartAction = (typeTask: ReportType, originalRecordId: string, updatedRecordId?: string) => ({
    type: TasksReportActionTypes.GET_RECORD_START, typeTask, originalRecordId, updatedRecordId
})
export const GetRecordSuccessAction = (data: TaskTypes.ReportRecords) => ({
    type: TasksReportActionTypes.GET_RECORD_SUCCESS, data
})
export const GetRecordErrorAction = (error: any) => ({
    type: TasksReportActionTypes.GET_RECORD_ERROR, error
})