import {axiosRequest} from 'shared/config/axios'
import {ContentFormatType, ContentFormatTypes, ContentFormatTypeSchema, ContentTypeRequest, Filter} from '../types'
import {downloadXLXS} from "utils/downloadXLXS";
import {Id_Name_Code} from "shared/types/common";

/**
 * Получение всех типов контента проектов
 */
export const getContentFormatTypes = {
    key: '/projects/format_types',
    fetch: async (params?: {isLinkedWithCategory: boolean}): Promise<ContentFormatType[]> => {
        const { data } = await axiosRequest<ContentFormatType[]>({
            method: 'GET',
            url: '/projects/format_types',
            parser: ContentFormatTypeSchema.array(),
            params
        });
        return data;
    },
};
/**
 * Получение всех типов контента проектов по проекту
 */
export const getContentFormatTypesByProject = {
    key: (projectId: string) => `/projects/${projectId}/format_types`,
    fetch: async (projectId: string) => {
        const {data} = await axiosRequest<Id_Name_Code[]>({
            url: `/projects/${projectId}/format_types`,
            method: 'GET',
            showError: true,
            headers: {
                'Actioncode': 'GET_PROJECTS_PROJECT_ID_FORMAT_TYPES'
            }
        })
        return data
    },
}

/**
 * Получение списка тематик контента с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
    key: '/projects/format_types/search',
    fetch: async (filter: Filter): Promise<ContentFormatTypes> => {
        const newFilter = { ...filter, page: filter.page - 1 };
        const { data } = await axiosRequest<ContentFormatTypes>({
            method: 'POST',
            url: '/projects/format_types/search',
            data: newFilter,
            parser: { list: true, schema: ContentFormatTypeSchema.array() },
        });
        return data;
    },
};
/**
 * Обновление записи
 */
export const updateContentType = async ({ id, data }: { id: string; data: ContentTypeRequest }) => {
    await axiosRequest({
        method: 'PUT',
        url: `/projects/format_types/${id}`,
        data,
    });
};
/**
 * Создание новой записи
 */
export const createContentType = async (data: ContentTypeRequest) => {
    await axiosRequest({
        method: 'POST',
        url: '/projects/format_types',
        data,
    });
};
/**
 * Удаление записи
 */
export const deleteContentType = async (id: string) => {
    await axiosRequest({
        method: 'DELETE',
        url: `/projects/format_types/${id}`,
    });
};
/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
    const { data }: any = await axiosRequest({
        method: 'POST',
        url: `/projects/format_types/export`,
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        data: value,
        showError: true,
    })
    downloadXLXS(data, 'sessions')
}
/**
 * Получение файла шаблона для выполнения импорта данных о сессиях
 */
export const downloadTemplate = async () => {
    const { data }: any = await axiosRequest({
        method: 'GET',
        url: '/projects/format_types/import/template',
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        showError: true,
    })
    downloadXLXS(data, 'sessions-template')
}
/**
 * Получение файла шаблона для выполнения импорта данных о типах форматов контента
 */
export const upload = async (data: FormData) => {
    await axiosRequest({
        method: 'POST',
        url: '/projects/format_types/import',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data,
        showError: true,
    })
}
/**
 * Массовое действие
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
    await axiosRequest({
        method: 'POST',
        url: `/projects/format_types/bulk-actions/${bulkAction}`,
        data: rowIds,
        showError: true,
    })
    return bulkAction
}
