import {InferValueTypes, T_ID_NAME, T_ID_NAME_CODE} from "models/common";

import * as actions from './actions';
import {ProjectsActionTypes} from "./action-types";

import {TProjectsFilter} from "models/projects/filter";
import {ErrorActionState, StartActionState, SuccessActionState} from "../../utils/reducerHalper";
import {TActualOrArchivedProjects} from "models/projects/actual-projects";
import {AuthActionTypes} from "../auth/action-types";
import {Logout} from "../auth/reducer";
import { ProjectTypes } from "entities/project";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TProjectsState = {
    projects: ProjectTypes.ProjectsList[] | null
    filter: TProjectsFilter
    project: ProjectTypes.Project | null
    projectReachData: T_ID_NAME[] | null
    projectsReporting: T_ID_NAME[] | null
    actualProjects: TActualOrArchivedProjects[] | null
    projectTypes: T_ID_NAME_CODE[] | null
    isLoaded: boolean
    isLoading: boolean
    error: any
}

export const initialState: TProjectsState = {
    projects: null,
    filter: {
        page: 1,
        pageSize: 50,
        filterData: {
            isNotVotedRecordsExists: true
        }
    },
    project: null,
    projectReachData: null,
    projectsReporting: null,
    actualProjects: null,
    projectTypes: null,
    isLoaded: false,
    isLoading: false,
    error: null
}

export default function reducer(state: TProjectsState = initialState, action: ActionTypes | Logout): TProjectsState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState}

        case ProjectsActionTypes.SET_PROJECTS_INFO:
            return {...state, ...action.value}
        case ProjectsActionTypes.SET_PROJECTS_FILTER:
            return {...state, filter: action.filter}
        case ProjectsActionTypes.GET_PROJECTS_TYPES_CLEAR:
            return {...state, projectTypes: null}
        case ProjectsActionTypes.CLEAR_BULK_ACTIONS:
            return {...state, filter: {...state.filter, filterData: {...state.filter.filterData, bulkAction: undefined} }}

        /** START ACTIONS  */
        case ProjectsActionTypes.GET_PROJECT_START:
            return {...StartActionState(state), project: null, isLoaded: false}
        case ProjectsActionTypes.GET_PROJECTS_START:
            return {...StartActionState(state), isLoaded: false}
        case ProjectsActionTypes.CREATE_PROJECTS_START:
            return {...StartActionState(state), isLoaded: false}
        case ProjectsActionTypes.DELETE_PROJECT_START:
            return {...StartActionState(state), isLoaded: false}
        case ProjectsActionTypes.UPDATE_PROJECT_START:
            return {...StartActionState(state), isLoaded: false}
        case ProjectsActionTypes.GET_ACTUAL_OR_ARCHIVED_PROJECTS_START:
            return {...StartActionState(state), isLoaded: false}
        case ProjectsActionTypes.UPDATE_PROJECT_STATUS_START:
            return {...StartActionState(state), isLoaded: false}
        case ProjectsActionTypes.GET_PROJECTS_REACH_DATA_START:
            return {...StartActionState(state), isLoaded: false}
        case ProjectsActionTypes.GET_PROJECTS_REPORTING_START:
            return {...StartActionState(state), isLoaded: false}
        case ProjectsActionTypes.BULK_PROJECT_START:
            return {...state, isLoading: true}
        case ProjectsActionTypes.GET_PROJECTS_TYPES_START:
            return StartActionState(state)
        case ProjectsActionTypes.DOWNLOAD_TEMPLATE_START:
            return StartActionState(state)
        case ProjectsActionTypes.UPLOAD_START:
            return StartActionState(state)
        case ProjectsActionTypes.DOWNLOAD_PROJECT_LIST_START:
            return StartActionState(state)

        /** SUCCESS ACTIONS  */
        case ProjectsActionTypes.CREATE_PROJECTS_SUCCESS:
            return {...SuccessActionState(state), isLoaded: true}
        case ProjectsActionTypes.UPDATE_PROJECT_STATUS_SUCCESS:
            return SuccessActionState(state)
        case ProjectsActionTypes.GET_PROJECTS_SUCCESS:
            const {content, page, ...filters} = action.project;
            return {
                ...SuccessActionState(state),
                projects: content,
                filter: {
                    ...state.filter,
                    page: page + 1,
                    ...filters,
                },
            }
        case ProjectsActionTypes.GET_PROJECT_SUCCESS:
            return {...SuccessActionState(state), project: action.project,}
        case ProjectsActionTypes.UPDATE_PROJECT_SUCCESS:
            return {...SuccessActionState(state), isLoaded: true}
        case ProjectsActionTypes.DELETE_PROJECT_SUCCESS:
            return {...SuccessActionState(state), project: null, isLoaded: true}
        case ProjectsActionTypes.GET_ACTUAL_OR_ARCHIVED_PROJECTS_SUCCESS:
            return {...SuccessActionState(state), actualProjects: action.projects}
        case ProjectsActionTypes.GET_PROJECTS_REACH_DATA_SUCCESS:
            return {...SuccessActionState(state), projectReachData: action.projects}
        case ProjectsActionTypes.GET_PROJECTS_REPORTING_SUCCESS:
            return {...SuccessActionState(state), projectsReporting: action.projects}
        case ProjectsActionTypes.BULK_PROJECT_SUCCESS:
            return {...state, filter: {...initialState.filter}}
        case ProjectsActionTypes.GET_PROJECTS_TYPES_SUCCESS:
            return {...SuccessActionState(state), projectTypes: action.types}
        case ProjectsActionTypes.DOWNLOAD_TEMPLATE_SUCCESS:
            return SuccessActionState(state)
        case ProjectsActionTypes.UPLOAD_SUCCESS:
            return SuccessActionState(state)
        case ProjectsActionTypes.DOWNLOAD_PROJECT_LIST_SUCCESS:
            return SuccessActionState(state)
        /** ERROR ACTIONS  */
        case ProjectsActionTypes.UPDATE_PROJECT_STATUS_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.GET_PROJECTS_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.GET_PROJECT_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.CREATE_PROJECTS_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.DELETE_PROJECT_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.UPDATE_PROJECT_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.DOWNLOAD_PROJECT_LIST_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.GET_ACTUAL_OR_ARCHIVED_PROJECTS_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.GET_PROJECTS_REACH_DATA_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.GET_PROJECTS_REPORTING_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.BULK_PROJECT_ERROR:
            return {...state, isLoading: false, error: action.error}
        case ProjectsActionTypes.GET_PROJECTS_TYPES_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.DOWNLOAD_TEMPLATE_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.UPLOAD_ERROR:
            return ErrorActionState(state, action)
        case ProjectsActionTypes.CLEAR_ERROR:
            return {...state, error: null}
        default:
            return state
    }
}
