import {Award, AwardSchema} from './types';
import {axiosRequest} from 'shared/config/axios';
import {IOptions} from '../../models/feels';

export const getAll = {
	key: '/awards',
	keyOptions: 'Options: /awards',
	fetch: async () => {
		const {data} = await axiosRequest<Award[]>({
			url: '/awards',
			method: 'GET',
			parser: AwardSchema.array()
		})
		return data;
	},
	fetchOptions: async (): Promise<IOptions<Award>[]> => {
		const {data} = await axiosRequest<Award[]>({
			url: '/awards',
			method: 'GET',
			parser: AwardSchema.array()
		})
		return data.map((el) => ({
			value: el.id,
			label: `${el.year} - ${el.name} - ${el.nomination} - ${el.status}`,
			code: el
		}));
	}
}

export const getFieldsData = {
	key: (fieldCode: string) => `/awards/fields-data/${fieldCode}`,

	fetch: async (fieldCode: string) => {
		const {data} = await axiosRequest<string[]>({
			url: `/awards/fields-data/${fieldCode}`,
			method: 'GET',
		})
		return data;
	},
	fetchOptions: async (fieldCode: string)=> {
		const {data} = await axiosRequest<string[]>({
			url: `/awards/fields-data/${fieldCode}`,
			method: 'GET',
		})

		return data.map((el) => {
			return {
				value: el,
				label: el,
				code: el
			}
		});
	}
}

export const getProjectAwards = {
	key: (projectId: string) => `/projects/${projectId}/awards`,
	fetch: async (projectId: string) => {
		const {data} = await axiosRequest<Award[]>({
			url: `/projects/${projectId}/awards`,
			method: 'GET',
			parser: AwardSchema.array(),
			headers: {
				'Actioncode': 'GET_PROJECTS_PROJECT_ID_AWARDS'
			}
		})
		return data;
	}
}
export const create = async (data: Omit<Award, 'id'>) => {
	await axiosRequest({
		url: '/awards',
		method: 'POST',
		data,
	})
}
export const update = async (id: string, data: Omit<Award, 'id'>) => {
	await axiosRequest({
		url: `/awards/${id}`,
		method: 'PUT',
		data,
	})
}
export const deleteAward = async (id: string) => {
	await axiosRequest({
		url: `/awards/${id}`,
		method: 'DELETE',
	})
}
