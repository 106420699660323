import { InfoCircleOutlined } from '@ant-design/icons';
import React, {FC} from 'react';
import styles from './styles.module.less';
import classNames from "classnames";

const Banner: FC<{type?: 'default' | 'danger', children: React.ReactNode}> = ({type= 'default', children}) => {
    return (
        <div className={classNames(styles.container, {danger: type === 'danger'})}>
            <div className={styles.icon}><InfoCircleOutlined /></div>
            <span>{children}</span>
        </div>
    )
}

export default Banner;