import React, {useEffect, useState} from 'react';
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {SearchContentProjectModel} from 'features/search-project-content';
import {IOptions} from "models/feels";
import {NotificationOpen} from "store/notification/actions";
import {SessionApi} from 'entities/session'

const { Option} = Select;

type State = {
    content: IOptions[],
    projects: IOptions[],
    calendar: IOptions[],
}
const Session = () => {
    const put = useDispatch();
    const [options, setOptions] = useState<State>({
        projects: [],
        content: [],
        calendar: [],
    })
    const [loading, setLoading] = useState(false);
    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main);
    const contests = useSelector(SearchContentProjectModel.selectors.contests);

    const contestId = contests[typeContent].contestId;
    const year = contests[typeContent].year;
    const sessionId = contests[typeContent].sessionId;

    function getData(year: string) {
        setLoading(true);
        SessionApi.SessionController.getSessionsOptions({year, contestId})
            .then((data) => {
                setOptions({
                    ...options,
                    [typeContent]: data || []
                })
            })
            .catch((e) => {
                put(NotificationOpen('error', '', String(e)))
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (year) getData(year)
    }, [year]);//eslint-disable-line



    function onSelectChange(value: string) {
        put(SearchContentProjectModel.actions.setContests(typeContent, {
            sessionId: value
        }))
    }

    return (
        <div className="item">
            <div className="label">Сессия</div>
            <div className="form-group">
                <Select
                    loading={loading}
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={true}
                    disabled={!year}
                    onChange={onSelectChange}
                    maxTagCount={"responsive"}
                    value={sessionId}
                    placeholder={'Искать среди всех'}
                    filterOption={(input, option) => {
                        if (option === undefined || option === null) return false;
                        return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                >
                    {options[typeContent].map((el) => (
                        <Option
                            key={el.value}
                            value={el.value}
                            data-name={el.label}
                        >
                            {el.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}

export default Session;
