import React, {ChangeEvent, FC, MouseEvent, useState} from 'react';
import './index.less'
import 'moment/locale/ru';
import locale from "antd/es/date-picker/locale/ru_RU";
import useHandlerModal from "hooks/handlModal";
import {Button, Col, DatePicker, Input, Modal, Row, Tooltip} from "antd";
import {Moment} from "moment/moment";
import {
    ControlGroup,
    Controls,
    ControlSpacer,
    FullscreenControl,
    PlaybackControl,
    Player,
    ScrubberControl,
    TimeProgress,
    Video,
    VolumeControl
} from "@vime/react";
import {DeleteOutlined} from "@ant-design/icons";
import moment from "moment";
import {FileType} from "shared/types/file";
import env from "shared/config/env";
import classNames from "classnames";
import { TaskTypes } from 'entities/task';

export type ModalTimeCodeContentPropsData = {
    isEdit: boolean
    file?: TaskTypes.ContentRecords['file']
    showVideo: boolean
    timeCodes: TaskTypes.ContentRecords['timeCodes']
    setTimeCodesHandler: (codes: TaskTypes.ContentRecords['timeCodes']) => void
}
type ModalTimeCodeContentProps = {
    status: boolean
    data: ModalTimeCodeContentPropsData
}
const path = env.API_ENDPOINT + '/files/';
const ModalTimeCodeContent: FC<ModalTimeCodeContentProps> = ({status, data}) => {
    // const [limit, setLimit] = useState<number | null>(null);
    // const put = useDispatch();
    const [timeCodes, setTimeCodes] = useState(data.timeCodes)
    const [currentTime, setCurrentTime] = useState({time: 0});
    const {isVisible, closeModal } = useHandlerModal(status);

    const handlerTime = (index: number) => (value: Moment | null) => {
        const timeCodesUpdate = [...timeCodes];
        if (value) {
            const time = {
                h: validateValue(value.get('hours')),
                m: validateValue(value.get('minutes')),
                s: validateValue(value.get('seconds')),
            }
            timeCodesUpdate[index].timeline = `${time.h}:${time.m}:${time.s}`;
            const timeVideo = String(value.format('LTS'))
            // if (limit) {
            //     const limitTime = parseTime(limit);
            //     if (validateTime(timeVideo, limitTime)) {
            //         timeCodesUpdate[index].timeline = timeVideo
            //     } else {
            //         put(NotificationOpen(
            //             'warning',
            //             'Не валидная временная метка',
            //             'Время должно быть в рамка видео файла'
            //         ))
            //         timeCodesUpdate[index].timeline = ''
            //     }
            // } else {
            //     timeCodesUpdate[index].timeline = timeVideo
            // }
            timeCodesUpdate[index].timeline = timeVideo
        } else {
            timeCodesUpdate[index].timeline = ''
        }
        setTimeCodes([...timeCodesUpdate])
    }

    const saveHandler = () => {
        data.setTimeCodesHandler(timeCodes)
        closeModal()
    }

    const closeModalHandler = () => {
        closeModal()
    }
    const addNewTimeCodeHandler = () => {
        setTimeCodes([...timeCodes, {id: null, timeline: '', name: ''}])
    }
    const deleteTimeCodeHandler = (index: number) => () => {
        const timeCodesFiltered = timeCodes;
        timeCodesFiltered.splice(index, 1)
        setTimeCodes([...timeCodesFiltered])
    }
    const addNameTimeCodeHandler = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const timeCodesUpdate = [...timeCodes];
        timeCodesUpdate[index].name = event.target.value;
        setTimeCodes(timeCodesUpdate)
    }

    const onTimeClick = (timeString: string) => (_event: MouseEvent<HTMLSpanElement>) => {
        const time = timeString.split(':');
        const convertTime = (+time[0] * 3600) + (+time[1] * 60) + +time[2];
        setCurrentTime({time: convertTime})
    }

    const canAddTimeCode = () => {
        const length = timeCodes.length;
        if (length === 0) return true
        return !!(timeCodes[length - 1].timeline && timeCodes[length - 1].name);
    }

    return (
        <Modal
            className='ModalTimeCodeContent'
            title="Тайм-коды контента"
            visible={isVisible}
            onCancel={closeModalHandler}
            footer={(
                <>
                    <Button type={"default"} onClick={closeModalHandler}>Закрыть</Button>
                </>
            )}
            maskClosable={true}
        >
            <div className="ModalTimeCodeContent__content">
                {data.showVideo && data.file && isVisible && (
                    // <VideoContent currentTime={currentTime} file={data.file} setLimit={setLimit}/>
                    <VideoContent currentTime={currentTime} file={data.file}/>
                )}
                <div className="code-list" key={timeCodes.length}>
                    <div className="code-list__info">
                        <span className="text">
                            Информация о тайм-кодах
                        </span>
                        {data.isEdit && (
                            <Tooltip title={canAddTimeCode() ? '' : 'Необходимо заполнить наименование метки хронометража и хронометраж'}>
                                <Button disabled={!canAddTimeCode()} type={'primary'} onClick={addNewTimeCodeHandler}>Добавить тайм-код</Button>
                            </Tooltip>
                        )}
                    </div>
                    {!data.isEdit && (
                        <ul className="timeCodesList">
                            {timeCodes.map((timeCode, index) => {
                                return (
                                    <li
                                        className={classNames("timeCode")}
                                        key={index}
                                    >
                                        <span
                                            className="time"
                                            onClick={onTimeClick(timeCode.timeline)}
                                        >
                                            {timeCode.timeline}</span> - <span>{timeCode.name}
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                    )}

                    {data.isEdit && timeCodes.map((timeCode, index) => {
                        const arr = timeCode.timeline.split(':')
                        const date = new Date();
                        if (timeCode.timeline) {
                            date.setHours(+arr[0])
                            date.setMinutes(+arr[1])
                            date.setSeconds(+arr[2])
                        }
                        return (
                            <Row justify={"space-between"} className="code-list__item" key={index}>
                                <Col span={10}>
                                    <div className="form-group">
                                        <DatePicker
                                            picker={'time'}
                                            onChange={handlerTime(index)}
                                            value={timeCode.timeline ? moment(date) : undefined}
                                            showNow={false}
                                            placeholder={'Введите хронометраж'}
                                            locale={locale}
                                        />
                                    </div>
                                </Col>
                                <Col span={11}>
                                    <div className="form-group">
                                        <Input
                                            placeholder={'Введите наименование метки хронометража'}
                                            value={timeCode.name}
                                            onChange={addNameTimeCodeHandler(index)}
                                        />
                                    </div>
                                </Col>
                                <Col span={2}>
                                    <div className="delete" onClick={deleteTimeCodeHandler(index)}>
                                        <DeleteOutlined />
                                    </div>
                                </Col>
                            </Row>
                        )
                    })}
                    {data.isEdit && (
                        <Tooltip title={canAddTimeCode() ? '' : 'Необходимо заполнить наименование метки хронометража и хронометраж или удалить все метки'}>
                            <Button disabled={!canAddTimeCode()} className="save" type={'primary'} onClick={saveHandler}>Сохранить</Button>
                        </Tooltip>

                    )}
                </div>
            </div>
        </Modal>
    )
};

function VideoContent({
    file, currentTime, _setLimit}: {
    file: FileType, currentTime: {time: number}, _setLimit?: (val: number) => void}
) {
    return (
        <div className={`videoAndAudio ${file?.type}`}>
            <Player
                theme="dark"
                currentTime={currentTime.time}
                onVmReady={(_event: CustomEvent<void>) => {
                    // @ts-ignore
                    // if (event.target?.duration) setLimit(event.target?.duration)
                }}
                currentPoster={file?.previewFile ? path + file?.previewFile?.id : undefined}
            >
                <>
                    <Video crossOrigin="use-credentials">
                        <source data-src={`${path}${file?.id}`} />
                    </Video>

                    <Controls fullWidth>
                        <ControlGroup>
                            <ScrubberControl/>
                        </ControlGroup>

                        <ControlGroup style={{background: '#1812123b'}}>
                            <PlaybackControl/>
                            <VolumeControl/>
                            <TimeProgress separator="/" />
                            <ControlSpacer/>
                            <FullscreenControl/>
                        </ControlGroup>

                    </Controls>
                </>
            </Player>
        </div>
    )
}
// function validateTime(timeVideo: string, limit: string) {
//     const [hours, minutes, second] = timeVideo.split(':');
//     const [hoursLimit, minutesLimit, secondLimit] = limit.split(':');
//
//     if (+hours === +hoursLimit) {
//         if (+minutes === +minutesLimit) {
//             return +second <= +secondLimit;
//         } else return +minutes < +minutesLimit;
//     } else if (+hours < +hoursLimit) {
//         if (+minutes === +minutesLimit) {
//             return +second <= +secondLimit;
//         } else return +minutes < +minutesLimit;
//     } else return false;
// }
// function parseTime(time: number) {
//     const date = new Date(time * 1000);
//     const result = new Date(date.toISOString().slice(0, -1));
//     const formattedTime = moment(result);
//     return String(formattedTime.format('LTS'))
// }
function validateValue(value: number) {
    if (value >= 10) return value
    return '0' + String(value)
}
export default ModalTimeCodeContent;