import {axiosRequest} from 'shared/config/axios';
import {ContentSubmissionForm, ContentSubmissionFormSchema} from '../types';

/**
 * @description Получение всех форм сдачи контента проектов
 */
export const getSubmissionForms = {
	key: '/projects/submission_forms',
	fetch: async () => {
		const {data} = await axiosRequest<ContentSubmissionForm[]>({
			url: '/projects/submission_forms',
			method: 'GET',
			showError: true,
			parser: ContentSubmissionFormSchema.array()
		})
		return data
	},
}
/**
 * Получение всех форм сдачи контента проектов по проекту (с возможностью фильтрации по
 * типу формата контента)
 */

export const getSubmissionFormsById = {
	key: (projectId: string) => `/projects/${projectId}/submission_forms`,
	fetch: async (data: {projectId: string, formatTypeId?: string}) => {
		const {data: contentSubmissionForm} = await axiosRequest<ContentSubmissionForm[]>({
			url: `/projects/${data.projectId}/submission_forms`,
			method: 'GET',
			showError: true,
			...(data?.formatTypeId && {params: {formatTypeId: data?.formatTypeId}}),
			parser: ContentSubmissionFormSchema.array(),
			headers: {
				'Actioncode': 'GET_PROJECTS_PROJECT_ID_SUBMISSION_FORMS'
			}
		})
		return contentSubmissionForm
	},
}