import {put} from 'redux-saga/effects'
import {NotificationOpen} from '../../store/notification/actions'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import {
	GetReportsTemplatesErrorAction,
	GetReportsTemplatesStartAction,
	GetReportsTemplatesSuccessAction,
} from '../../store/project-reporting/actions'

import {AxiosResponse} from 'axios'
import ReportingService from "../../services/reportingService";
import {T_ID_NAME_CODE} from "../../models/common";

export function* getReportsTemplates(_ : ReturnType<typeof GetReportsTemplatesStartAction>) {
	try {
		const { data }: AxiosResponse<T_ID_NAME_CODE[]> = yield ReportingService.getReportsTemplates()
		yield put(GetReportsTemplatesSuccessAction(data))
	} catch (error: any) {
		yield put(GetReportsTemplatesErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить шаблоны отчетов'))
	}
}
