import {put, select} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {GetContestsListSuccessAction, SetContentFilter, SetContentInfo} from "store/content/list/actions";
import {selectContentListFilter} from "store/content/list/selectors";
import Api from "entities/project/api/project";
import {ProjectTypes} from "entities/project";
import {ExtendedSearchLib, ExtendedSearchTypes} from "features/extended-search";
import {TContentFilter} from "models/content";
import ZodParser from "shared/services/zod-parser";

export function* ContentFunction(filterES: ExtendedSearchTypes.Stores.TAllFilterValues) {
    yield put(SetContentInfo({isLoading: true}))
    const filterMain: TContentFilter = yield select(selectContentListFilter);
    const {filterData, ...rest} = filterMain;

    const filter = {
        ...rest,
        filterData: {
            ...(!!filterData?.searchString && { searchString: filterData.searchString}),
            ...ExtendedSearchLib.mapData(filterES),
            contentStatusCodes: ['REJECTED', 'APPROVED']
        }
    } as TContentFilter<Partial<ExtendedSearchTypes.Fields.TFilterData>>;
    const {data: projects}: AxiosResponse<ProjectTypes.ProjectsListResponse> = yield Api.getProjectsExtendedSearch(filter)
    ZodParser.parse(ProjectTypes.ProjectsListResponseSchema, projects, 'getProjectsExtendedSearch')
    if (projects.page * projects.pageSize >= projects.totalCount && projects.totalCount !== 0) {
        yield put(SetContentFilter({...filterMain, page: 1}))
    } else {
        yield put(GetContestsListSuccessAction(projects))
    }
}