import { createSelector } from 'reselect'
import { TApplicationState } from '../aplication-state'
import {IOptions} from "../../models/feels";

const legalFormState = (state: TApplicationState) => state.legalFormOrganization

export const selectLegalFormStatus = createSelector(legalFormState, (state) => ({
	isLoading: state.isLoading,
	error: state.error,
}))

export const selectLegalFormOrganizationOptions = createSelector(legalFormState, (state)=>{
	return state.legalFormOrganizations ? state.legalFormOrganizations?.filter((it)=>!it.hidden).map((el)=>{
		return {value: el.id, label: el.fullName, code: el.code} as IOptions
	}) : []
})

export const selectLegalFormOrganizationShortNameOptions = createSelector(legalFormState, (state)=>{
	return state.legalFormOrganizations ? state.legalFormOrganizations?.map((el)=>{
		return {value: el.id, label: el.shortName, code: el.code, hidden: el.hidden} as IOptions
	}) : []
})

export const selectLegalFormListWithStatus = createSelector(selectLegalFormStatus, selectLegalFormOrganizationOptions,
	(info,list)=> ({
		isLoading: info.isLoading,
		legalFormOrganizationsOptions: list,
	}))
