import {LogsAddAction, LogsPushAction, LogsSendStartAction} from "store/logs/actions";
import {put, select} from "redux-saga/effects";
import {selectLogs, selectLogsLength} from "store/logs/selector";
import {TLogsAction} from "models/logs";

export function* addLogs({data}: ReturnType<typeof LogsAddAction>) {
    try {
        const logsLength: number = yield select(selectLogsLength);
        const logs: TLogsAction[] = yield select(selectLogs);
        const currentTime = new Date().toISOString(); // текущее местное время

        const newLog: TLogsAction = {
            actionTypeCode: data?.actionTypeCode,
            dateTime: currentTime,
            actionParameters: data?.actionParameters ? data.actionParameters : undefined,
            routingPageAddress: data?.routingPageAddress,
            requestUrl: data?.requestUrl,
            requestMethod: data?.requestMethod,
            requestPayload: data?.requestPayload,
            methodStatusCode: data?.methodStatusCode,
            requestResponse: data?.requestResponse ? data.requestResponse : null,
        }
        if (logsLength < 5) {
            yield put(LogsPushAction(newLog))
        } else {
            yield put(LogsSendStartAction(logs))
            yield put(LogsPushAction(newLog))
        }
    } catch (error: any) {
        console.log(error)
    }
}