import {TPersonalSettingResponse, TYPE_NOTIFICATION_CHANNELS} from '../../../models/personal-setting'
import {PersonalSettingTypes} from '../action-types'

/**
 * GET PERSONAL_SETTING
 */

export const GetPersonalSettingStartAction = (id: string) => ({
	type: PersonalSettingTypes.GET_PERSONAL_SETTING_START,
	id,
})

export const GetPersonalSettingSuccessAction = (data: TPersonalSettingResponse[]) => ({
	type: PersonalSettingTypes.GET_PERSONAL_SETTING_SUCCESS,
	data,
})

export const GetPersonalSettingErrorAction = (error: any) => ({
	type: PersonalSettingTypes.GET_PERSONAL_SETTING_ERROR,
	error,
})

/**
 * UPDATE PERSONAL_SETTING
 */

export const UpdatePersonalSettingStartAction = (id: string, data: any) => ({
	type: PersonalSettingTypes.UPDATE_PERSONAL_SETTING_START,
	id,
	data,
})

export const UpdatePersonalSettingSuccessAction = () => ({
	type: PersonalSettingTypes.UPDATE_PERSONAL_SETTING_SUCCESS,
})

export const UpdatePersonalSettingErrorAction = (error: any) => ({
	type: PersonalSettingTypes.UPDATE_PERSONAL_SETTING_ERROR,
	error,
})

/**
 * Подтверждения email адреса
 */
export const ConfirmEmailStartAction = (
	id: string,
	notificationChannel: TYPE_NOTIFICATION_CHANNELS,
	code: string,
	account: string | undefined
) => ({
	type: PersonalSettingTypes.CONFIRM_EMAIL_START,
	id,
	notificationChannel,
	code,
	account
})
export const ConfirmEmailSuccessAction = () => ({
	type: PersonalSettingTypes.CONFIRM_EMAIL_SUCCESS,
})
export const ConfirmEmailErrorAction = (error: any) => ({
	type: PersonalSettingTypes.CONFIRM_EMAIL_ERROR,
	error,
})

/**
 * Генерация и отсылка сообщения для подтверждения email адреса
 */
export const SendConfirmEmailStartAction = (id: string, notificationChannel: TYPE_NOTIFICATION_CHANNELS, account: string | undefined) => ({
	type: PersonalSettingTypes.SEND_CONFIRM_EMAIL_START,
	id,
	notificationChannel,
	account
})
export const SendConfirmEmailSuccessAction = () => ({
	type: PersonalSettingTypes.SEND_CONFIRM_EMAIL_SUCCESS,
})
export const SendConfirmEmailErrorAction = (error: any) => ({
	type: PersonalSettingTypes.SEND_CONFIRM_EMAIL_ERROR,
	error,
})

/**
 * Изменение данных при смене пароля
 */
export const UpdatePasswordLengthStartAction = (length: number) => ({
	type: PersonalSettingTypes.UPDATE_PASSWORD_LENGTH_START,
	length
})
export const UpdateContainSymbolsStartAction = (bol: boolean) => ({
	type: PersonalSettingTypes.UPDATE_CONTAIN_SYMBOLS_START,
	bol
})
export const UpdateLettersNumbersStartAction = (bol: boolean) => ({
	type: PersonalSettingTypes.UPDATE_LETTERS_NUMBERS_START,
	bol,
})

/**
 * Изменене пароля в персональных настройках
 */

export const ChangePasswordPersonalSettingStartAction = (password: any) => ({
	type: PersonalSettingTypes.CHANGE_PASSWORD_PERSONAL_SETTING_START,
	password,
})

export const ChangePasswordPersonalSettingSuccessAction = () => ({
	type: PersonalSettingTypes.CHANGE_PASSWORD_PERSONAL_SETTING_SUCCESS,
})

export const ChangePasswordPersonalSettingErrorAction = (error: any) => ({
	type: PersonalSettingTypes.CHANGE_PASSWORD_PERSONAL_SETTING_ERROR,
	error,
})

/**
 * Подтверждения аккаунта telegram
 */
export const ConfirmTelegramStartAction = (code: string) => ({
	type: PersonalSettingTypes.CONFIRM_TELEGRAM_START,
	code,
})
export const ConfirmTelegramSuccessAction = () => ({
	type: PersonalSettingTypes.CONFIRM_TELEGRAM_SUCCESS,
})
export const ConfirmTelegramErrorAction = (error: any) => ({
	type: PersonalSettingTypes.CONFIRM_TELEGRAM_ERROR,
	error,
})

