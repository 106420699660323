import { TablePaginationConfig } from 'antd'
import { Key } from 'react'

export default function handlerTable(
	pagination: TablePaginationConfig,
	filter: Record<string, (Key | boolean)[] | null>,
	sorter: any
) {
	let newPagination = {}
	let newSorter = {}
	if (pagination) {
		newPagination = {
			page: pagination.current || 1,
			pageSize: pagination.pageSize || 10,
			totalCount: pagination.total || 0,
		}
	}
	if (sorter && sorter.order) {
		let direction = 'ASC'

		if (sorter.order === 'descend') {
			direction = 'DESC'
		}
		newSorter = {
			sort: {
				field: getField(sorter.field),
				direction,
			},
		}
	} else newSorter = { sort: undefined }
	return {
		...newPagination,
		...newSorter,
		// ...filter //todo фильтр где-то используется если да то продумать логику с пустыми значеньями
	}
}

function getField(field: string) {
	switch (field) {
		case 'firstName':
			return 'fio'
		case 'region':
			return 'region.name'
		default: return field
	}
}