const tasksSetting = [
    {
        id: '1',
        selector: '.ant-input',
        content: 'Поиск позволяет найти необходимые данные по коду, названию и номеру задачи',
        role: []
    },
    {
        id: '2',
        selector: '.tour-button-create',
        content: 'Для массового создания расписаний дублирования задач типа "Отчет об охвате размещенного контента" нажмите "Массовое создание расписаний дублирования"',
        role: []
    },
    {
        id: '3',
        selector: '.tour-button-update',
        content: 'Для массового обновления расписаний дублирования задач типа "Отчет об охвате размещенного контента" нажмите "Массовое обновление расписаний дублирования"',
        role: []
    },
    {
        id: '4',
        selector: '.table',
        content: 'Таблица расписаний автосоздания задач на фиксацию охватов отображает созданные правила автосоздания с указанием дат создания, тип расписания, период создания первого и последнего дублей, частоту создания, количество дней до дедлайна, наименование задач',
        role: []
    },

];
export default tasksSetting;
