import { put, select } from 'redux-saga/effects'
import {
	GetRecordStartAction,
	GetTasksContentStartAction,
	VoteTaskContentChange,
	VoteTaskContentErrorAction,
	VoteTaskContentStartAction,
	VoteTaskContentSuccessAction,
} from 'store/tasks/actions/content'
import TasksContentService from 'services/tasksContentService'
import { NotificationOpen } from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import { selectTask } from 'store/tasks/selectors/selectors-task'
import { TTaskResponse } from 'models/task'
import { TTaskRecordFilter } from 'models/task/task-content'
import { selectContentTasksFilter } from 'store/tasks/selectors/selectors-task-content'

export function* voteTask({ data }: ReturnType<typeof VoteTaskContentStartAction>) {
	try {
		const task: TTaskResponse = yield select(selectTask)
		const filter: TTaskRecordFilter = yield select(selectContentTasksFilter)

		const { searchString, ...rest } = filter.filterData
		const requestData = {
			...data,
			vote: { ...data.vote, isApproved: data.vote.isInterimApproved, isInterimApproved: null },
		}

		yield TasksContentService.voteTaskRecord(requestData)
		yield put(VoteTaskContentSuccessAction())

		yield put(VoteTaskContentChange(data.vote.recordId))

		if (searchString || Object.keys(rest).length) {
			if (task && task.id) put(GetTasksContentStartAction(task.id))
		} else {
			yield put(GetRecordStartAction(data.vote.recordId))
		}
		yield put(NotificationOpen('success', '', 'Голос засчитан'))
	} catch (error: any) {
		yield put(VoteTaskContentErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось проголосовать'))
	}
}
