import {z} from 'zod';
import {TasksRecordStatusEnum} from '../record-status';
import {Id_Name_Code_Hidden_Schema, Id_Name_Code_Schema} from 'shared/types/common';
import {FileSchema} from 'shared/types/file';
import {VoteSchema} from '../vote';
import {RoleSchema} from "entities/user/types";

export const ContentRecordsSchema = z.object({
	id: z.string(),
	name: z.string().nullable(),
	description: z.string().nullable(),
	contentFormatType: Id_Name_Code_Schema.nullable(),
	submissionForm: Id_Name_Code_Schema.nullable(),
	isMasterFile: z.boolean(),
	votes: z.array(VoteSchema).nullable(),
	lastTaskRecordWithVotesResponse: z.object({
		id: z.string(),
		votes: z.array(VoteSchema).nullable(),
	}).nullable(),
	file: FileSchema.nullable(),
	fileUpload: z.object({
		id: z.string(),
		originalFilename: z.string(),
		originalFileModificationDate: z.string(),
		length: z.number(),
		offset: z.number(),
		contentUsersCount: z.number().optional(),
		isUploadInProgress: z.boolean(),
		task: z.object({
			id: z.string(),
			name: z.string(),
			code: z.string(),
			number: z.number()
		}).nullable(),
		contentTaskRecord: z.object({ id: z.string(), name: z.string() })
	}).nullable(),
	dateTime: z.string(),
	timeCodes: z.array(
		z.object({ id: z.string().nullable(), timeline: z.string(), name: z.string() })
	),
	status: TasksRecordStatusEnum,
	zeroLevelTags: z.array(Id_Name_Code_Hidden_Schema),
	firstLevelTags: z.array(Id_Name_Code_Hidden_Schema),
	secondLevelTags: z.array(Id_Name_Code_Hidden_Schema),
	thirdLevelTags: z.array(Id_Name_Code_Hidden_Schema),
	firstLevelAssignmentTags: z.array(Id_Name_Code_Hidden_Schema),
	secondLevelAssignmentTags: z.array(Id_Name_Code_Hidden_Schema),
	regionForSet: z.array(Id_Name_Code_Hidden_Schema),
	regionAboutSet: z.array(Id_Name_Code_Hidden_Schema),
	contentTiming: z.number().nullable(),
	isOriginal: z.boolean(),
	isContent: z.boolean(),
	isAccountedContent: z.boolean(),
	isReplaced: z.boolean(),
	task: z.object({
		deadline: z.string(),
		number: z.number(),
		id: z.string(),
		name: z.string(),
		code: z.string(),
	}).optional()
})
export type ContentRecords = z.infer<typeof ContentRecordsSchema>

export const ContentRecordSchema = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string(),
	task: z.object({
		id: z.string(),
		status: z.object({ id: z.string(), code: z.string(), name: z.string() }),
		archival: z.boolean()
	}),
	contentFormatType: z.object({
		id: z.string(),
		code: z.string(),
		name: z.string()
	}),
	submissionForm: z.object({
		id: z.string(),
		code: z.string(),
		name: z.string()
	}),
	isMasterFile: z.boolean(),
	file: z.object({
		id: z.string(),
		compressedId: z.string(),
		scaledId: z.string(),
		name: z.string(),
		type: z.string(),
		storagePath: z.string(),
		previewFile: z.string(),
		size: z.number()
	}),
	dateTime: z.string(),
	status: z.string(),
	zeroLevelTags: z.array(
		z.object({
			id: z.string(),
			code: z.string(),
			name: z.string(),
			hidden: z.boolean()
		})
	),
	firstLevelTags: z.array(
		z.object({
			id: z.string(),
			code: z.string(),
			name: z.string(),
			hidden: z.boolean()
		})
	),
	secondLevelTags: z.array(
		z.object({
			id: z.string(),
			code: z.string(),
			name: z.string(),
			hidden: z.boolean()
		})
	),
	thirdLevelTags: z.array(
		z.object({
			id: z.string(),
			code: z.string(),
			name: z.string(),
			hidden: z.boolean()
		})
	),
	firstLevelAssignmentTags: z.array(
		z.object({
			id: z.string(),
			code: z.string(),
			name: z.string(),
			hidden: z.boolean()
		})
	),
	secondLevelAssignmentTags: z.array(
		z.object({
			id: z.string(),
			code: z.string(),
			name: z.string(),
			hidden: z.boolean()
		})
	),
	regionAboutSet: z.array(
		z.object({
			id: z.string(),
			name: z.string(),
			code: z.string(),
			hidden: z.boolean()
		})
	),
	regionForSet: z.array(
		z.object({
			id: z.string(),
			name: z.string(),
			code: z.string(),
			hidden: z.boolean()
		})
	),
	contentTiming: z.number(),
	isContent: z.boolean(),
	isAccountedContent: z.boolean().nullable(),
	timeCodes: z.array(
		z.object({
			id: z.string(),
			timeline: z.string(),
			name: z.string(),
		})
	)
})
export type ContentRecord = z.infer<typeof ContentRecordSchema>

export const MassTasksContentRecordsSchema = z.object({
	contentRecordIds: z.array(z.string()),
	addUserIds: z.array(z.string()),
	deleteUserIds: z.array(z.string()),
})
export type MassTasksContentRecords = z.infer<typeof MassTasksContentRecordsSchema>;

export const RelatedUserSchema = z.object({
	id: z.string(),
	lastName: z.string(),
	firstName: z.string(),
	middleName: z.string().nullable(),
	role: RoleSchema,
	leader: z.boolean(),
	status: z.enum(['ACTIVE', 'BLOCKED', 'ARCHIVE']),
})

export type RelatedUser = z.infer<typeof RelatedUserSchema>;
