import React, {FC, useEffect, useState} from 'react';
import styles from './modal.module.less'
import UsersModal from "widgets/user-modal";
import {ProjectApi, ProjectTypes} from 'entities/project'
import {Spin} from "antd";
import {CalendarTypes} from 'pages/calendar';
import moment from "moment";

const ModalInfoAndTeam: FC<{project: CalendarTypes.Calendar}> = ({project}) => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<ProjectTypes.MapProjectUsers | null>(null);
    useEffect(() => {
        ProjectApi.ProjectViewDetailsController.GetProjectUsers.mapUsers(project.id).then((data) => {
            setUsers(data as ProjectTypes.MapProjectUsers)
        }).finally(() => {
            setLoading(false);
        })
    }, [project])

    return (
        <div className={styles.root}>
            <div className={styles.info}>
                <div className={styles.title}>
                    Номер заявки <div className={styles.value}>{project.requestNumber || '-'}</div>
                </div>
                <div className={styles.title}>
                    Дата начала проекта
                    <div className={styles.value}>
                        {!!project.contractDate ? moment(project.contractDate).format('DD.MM.YYYY') : '-'}
                    </div>
                </div>
                <div className={styles.title}>
                    Номер договора <div className={styles.value}>{project.contractNumber || '-'}</div>
                </div>
                <div className={styles.title}>
                    Дата окончания проекта
                    <div className={styles.value}>
                        {!!project.completionDate ? moment(project.completionDate).format('DD.MM.YYYY') : '-'}
                    </div>
                </div>
            </div>
            <Spin spinning={loading}>
                {users && <UsersModal data={users}/>}
            </Spin>
        </div>
    )
};

export default ModalInfoAndTeam;