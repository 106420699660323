import {z} from "zod";

export const SprintStatusEnum = z.enum([
    'INACTIVE', 'ACTIVE', 'CLOSED', 'ENDED_OPEN'
])
export type SprintStatus = z.infer<typeof SprintStatusEnum>;

export const SprintSchema = z.object({
    id: z.string(),
    name: z.string(),
    dateStart: z.string(),
    dateEnd: z.string(),
    readyTaskCount: z.number(),
    taskOverdueCount: z.number(),
    totalTaskCount: z.number(),
    status: z.object({
        id: z.string(),
        name: z.string(),
        code: SprintStatusEnum
    })
})
export type Sprint = z.infer<typeof SprintSchema>

export const SprintProjectSchema = z.object({
    id: z.string(),
    name: z.string(),
    projectId: z.string(),
    amount: z.number(),
    dateStart: z.string(),
    dateEnd: z.string(),
    status: z.object({
        id: z.string(),
        name: z.string(),
        code: SprintStatusEnum
    }),
    taskExists: z.boolean()
})
export type SprintProject = z.infer<typeof SprintProjectSchema>;

export const CreateSprintSchema = z.object({
    projectId: z.string(),
    dateStart: z.string(),
    dateEnd: z.string(),
    amount: z.number(),
    name: z.string(),
})

export type CreateSprint = z.infer<typeof CreateSprintSchema>;