import {put} from 'redux-saga/effects'
import {GetAllReportingStartAction, SetReportingFilter} from "../../store/project-reporting/actions";

export function* setFilter(_action: ReturnType<typeof SetReportingFilter>) {
    try {
        yield put(GetAllReportingStartAction())
    } catch (error: any) {
        console.log(error)
    }
}
