import { useQuery } from 'react-query'
import { IOptions } from 'models/feels'
import ReportingService from 'services/reportingService'
export function useReportsTemplates() {
    return useQuery({
        queryKey: [ReportingService.reportsTemplatesKey],
        queryFn: async () => {
            const { data } = await ReportingService.getReportsTemplates();
            const arr: IOptions[] = [];
            data.forEach((report) => {
                if (report.code === 'R14' || report.code === 'R15' || report.code === 'R16' || report.code === 'R17') {
                    arr.push({ value: report.id, label: `${report.code} - ${report.name}` } as IOptions);
                }
            });
            return arr;
        }
    });
}