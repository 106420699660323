import React from 'react'
import {useQuery} from 'react-query'
import styles from './styles.module.less'
import {SelectComponent} from 'components/formic-control'
import {UserApi, UserTypes} from "entities/user";

const activeUsers = {
	roleNames: [
		"ROLE_MODERATOR",
		"ROLE_ANALYST",
		"ROLE_ADMIN",
		"ROLE_LAWYER",
		"ROLE_PROJECT_MANAGER",
		"ROLE_INTEGRATION_USER",
		"ROLE_ADMIN_IS",
		"ROLE_PRODUCER",
		"ROLE_CONTENT_EDITOR",
		"ROLE_COORDINATOR",
		"ROLE_FINANCIER"
	],
	statuses: ['ACTIVE'],
	isKeycloakSynced: true,
	isDeleted: false
}

const Users = () => {

	const { data: users = [], isLoading } = useQuery({
		queryKey: [UserApi.UserController.keyOptionsRoleName, activeUsers],
		queryFn: ()=> UserApi.UserController.optionsRoleName(activeUsers as UserTypes.UsersRequest),
	})

	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Получатели выгрузки отчета <sup>*</sup>
			</span>
			<div className={styles.field}>
				<SelectComponent
					options={users}
					multi={true}
					maxTagCount={'responsive'}
					loading={isLoading}
					name={'users'}
					placeholder={'Выберите пользователей-получателей отчетов'}
				/>
			</div>
		</div>
	)
}

export default Users

