import React, {FC} from 'react';
import './index.less'
import {Modal} from "antd";
import useHandlerModal from "hooks/handlModal";
import UsersModal from "widgets/user-modal";
import {ProjectTypes} from 'entities/project'


type ModalProjectUsersProps = {
    status: boolean,
    data: ProjectTypes.MapProjectUsers
}

const ModalProjectUsers: FC<ModalProjectUsersProps> = ({status, data }) => {
    const {isVisible, closeModal} = useHandlerModal(status);

    return (
        <Modal
            className='modal-projectUsers'
            title="Команда проекта"
            visible={isVisible}
            footer={null}
            maskClosable={true}
            onCancel={closeModal}
        >
            <UsersModal data={data}/>
        </Modal>
    )
};

export default ModalProjectUsers;
