import React from 'react';
import {Radio, RadioChangeEvent} from "antd";
import {useDispatch, useSelector} from "react-redux";
import './index.less';
import {SearchContentProjectModel, SearchContentProjectTypes} from 'features/search-project-content';
import {IOptions} from "models/feels";
import WhetherTheUser from 'components/whetherTheUser';

type TKey = keyof Omit<
    SearchContentProjectTypes.Stores.Indicators, 'isToggled'>

const options: SearchContentProjectTypes.Stores.IndicatorsOptions = {
    isProjectPosted: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Размещается', value: 'true'},
        {label: 'Не размещается', value: 'false'},
    ],
    isFavoriteProject: [
        {label: 'Все проекты', value: 'Все проекты'},
        {label: 'Избранные', value: 'true'},
        {label: 'Остальные', value: 'false'},
    ],
}
export const IndicatorItem = () => {
    return (
        <div className="item-wrapper Indicators">
            <Item
                options={options.isProjectPosted}
                label={'Размещение проекта'}
                name={'isProjectPosted'}
            />
            <WhetherTheUser role={['ROLE_CLIENT', 'ROLE_AGENT']} isCan={false}>
              <Item
                options={options.isFavoriteProject}
                label={'Избранный проект'}
                name={'isFavoriteProject'}
              />
            </WhetherTheUser>
        </div>
    )
}
type ItemProps = {
    options: IOptions[],
    name: TKey,
    label: string
}
function Item({options, name, label}: ItemProps) {
    const put = useDispatch();
    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main)
    const indicators = useSelector(SearchContentProjectModel.selectors.indicators)
    const onGroupChange = (e: RadioChangeEvent) => {
        put(SearchContentProjectModel.actions.setIndicators(typeContent, {[name]: e.target.value}))
    }
    return (
        <div className="item">
            <div className="label">{label}</div>
            <div className="form-group form-group--radio">
                <Radio.Group
                    value={indicators[typeContent][name]}
                    onChange={onGroupChange}
                >
                    {options.map((option: IOptions) => {
                        return (
                            <Radio
                                checked={indicators[typeContent][name] === (option.value)}
                                key={option.label}
                                value={option.value}
                            >
                                {option.label}
                            </Radio>
                        )
                    })}
                </Radio.Group>
            </div>
        </div>
    )
}