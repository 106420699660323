import {z} from "zod";

export const ContractorOrganizationSchema = z.object({
    id: z.string(), //ID организации
    name: z.string(), //Краткое наименование организации
    inn: z.string(), //ИНН
    ogrnOgrnip: z.string(), //ОГРН/ОГРНИП
    region: z.object({
        id: z.string(),//ID региона
        name: z.string(),//Наименование региона
        code: z.string(),//Код региона
        hidden: z.boolean(),//Признак. Запись не отображается для выбора пользователя
    }).nullable(),
    legalForm: z.object({
        id: z.string(),//ID правовой формы организации
        shortName: z.string(),//Краткое наименование правовой формы
    })
})
export type ContractorOrganization = z.infer<typeof ContractorOrganizationSchema>