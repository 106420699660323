const calendar = [
    {
        id: '22',
        selector: '.calendar-row',
        content: 'Диаграмма Ганта отражает этапы проекта, перечень задач, их сроки начала и завершения, а также процент выполнения этапов и задач. Для наполнения диаграммы данными используйте кнопки “Настроить этапы” и “Создать задачу”',
        role: ['ROLE_ADMIN','ROLE_PRODUCER','ROLE_COORDINATOR','ROLE_ANALYST','ROLE_FINANCIER','ROLE_LAWYER','ROLE_MODERATOR','ROLE_CLIENT', 'ROLE_AGENT','ROLE_PROJECT_MANAGER','ROLE_ADMIN_IS'],
        page: ['sprint', 'calendar-form','no-data']
    },
    {
        id: '22',
        selector: '.calendar-row',
        content: 'Диаграмма Ганта отражает этапы проекта, перечень задач, их сроки начала и завершения, а также процент выполнения этапов и задач.',
        role: ['ROLE_CONTRACTOR'],
        page: ['sprint', 'calendar-form','no-data']
    },
];
export default calendar;
