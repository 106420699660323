import { FunctionalityInfoType } from '../types'

export const task: FunctionalityInfoType = [
	{
		LevelGroupName: 'Редактирование данных задачи',
		LevelGroupData: [
			{
				name: 'Редактирование данных задачи',
				points: [
					'Нажмите для перехода в форму и редактирования данных задачи',
					'Заполните обязательные и доступные поля формы на 1-3 вкладках',
					'Нажмите “Сохранить”',
				],
				topIcons: ['edit-black'],
				screenshots: ['/images/screenshots/task-form.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_FINANCIER',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Обновление статуса задачи',
				text: 'Нажмите и выберите значение из выпадающего списка для обновления статуса задачи',
				screenshots: ['/images/screenshots/task/status.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_CONTRACTOR',
					'ROLE_MODERATOR',
				],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных проекта',
		LevelGroupData: [
			{
				name: 'Редактирование данных проекта',
				text: 'Нажмите для перехода в форму редактирования проекта. Для редактирования проекта:',
				points: [
					'Заполните обязательные и доступные поля формы на 1-5 вкладке',
					'Сохраните форму',
					'Нажмите "Список ошибок" в случае возникновения ошибок',
					'Исправьте перечисленные ошибки на вкладках формы',
					'Сохраните форму повторно',
				],
				topIcons: ['edit-black'],
				screenshots: ['/images/screenshots/project-form.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_LAWYER',
				],
			},
		],
	},

	{
		LevelGroupName: 'Просмотр данных задачи',
		LevelGroupData: [
			{
				name: 'Просмотр и добавление комментариев к задаче',
				points: [
					'Нажмите "Комментарии" для просмотра комментариев к задаче',
					'Нажмите "Добавить комментарий"',
					'Нажмите “Сохранить”',
					'Нажмите "Изменить" / "Удалить" для редактирования данных',
					'Нажмите "Сначала новые / старые" либо "Все комментарии / Мои комментарии" для сортировки комментариев',
				],
				screenshots: ['/images/screenshots/task/comment.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_LAWYER',
				],
			},
			{
				name: 'Просмотр команды проекта',
				text: 'Нажмите на иконку участника проекта для перехода в модальное окно команды проекта',
				screenshots: ['/images/screenshots/task/team.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_LAWYER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр списка записей задач с поиском, фильтрацией, пагинацией',
		LevelGroupData: [
			{
				name: 'Поиск задач',
				text: 'Для поиска задач по ссылке, рабочему названию публикации и названию контента при публикации:',
				points: ['Нажмите "Поиск"', 'Введите текст', 'Нажмите на кнопку поиска'],
				screenshots: ['/images/screenshots/task/search.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_LAWYER',
				],
			},
			{
				name: 'Фильтрация задач',
				text: 'Используйте фильтры для поиска:',
				points: [
					'Нажмите для перехода в окно фильтрации',
					'Примените фильтры',
					'Закройте окно фильтрации для поиска задач',
					'Нажмите "Сбросить фильтры" для сброса неактуальных фильтров ',
				],
				important: ['! Нажмите "Сохранить фильтр" для сохранения фильтра'],
				screenshots: ['/images/screenshots/task/filter.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_LAWYER',
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_LAWYER',
				],
			},
			{
				name: 'Настройка количества записей, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице записей задач',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_LAWYER',
				],
			},
			{
				name: 'Переход в окно просмотра скриншотов в задачах на охват ',
				text: 'Нажимите на скриншот для перехода в модальное окно просмотра скриншотов',
				screenshots: ['/images/screenshots/task/view-screenshots.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_LAWYER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Управление выводом полей',
		LevelGroupData: [
			{
				name: 'Настройка скрытия видимости полей таблицы:',
				text: 'Для настройки скрытия видимости полей таблицы:',
				points: ['Нажмите на кнопку', 'Выберите столбцы для скрытия', 'Нажмите "Сохранить"'],
				icons: ['setting-gray'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Загрузка контента',
		LevelGroupData: [
			{
				name: 'Скачивание контента',
				text: 'Нажмите для скачивания контента',
				icons: ['download'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS'],
			},
			{
				name: 'Добавление файла контента',
				text: 'Нажмите для добавления файла контента',
				screenshots: ['/images/screenshots/task/add.png'],

				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_MODERATOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_PRODUCER',
					'ROLE_CONTRACTOR',
				],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных записей задач',
		LevelGroupData: [
			{
				name: 'Массовые действия над записями задач',
				text: 'Для массового выполнения действий:',
				points: [
					'Нажмите "Массовые действия"',
					'Выберите массовое действие из выпадающего списка',
					'Выберите записи задач',
					'Нажмите "Применить"',
				],
				screenshots: [
					'/images/screenshots/task/bulk-actions.png',
					'/images/screenshots/checked-checkbox.png',
					'/images/screenshots/button-apply.png',
				],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_CONTRACTOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Действия над записями обычных задач типа Контент',
		LevelGroupData: [
			{
				name: 'Создание записей обычных задач типа Контент',
				text: 'Для создания новой записи задачи:',
				points: ['Нажмите "Добавить данные"', 'Заполните поля формы', 'Нажмите на галочку для сохранения формы'],
				screenshots: ['/images/screenshots/task/add-data.png'],
				roles: ['ROLE_CONTRACTOR'],
			},
			{
				name: 'Редактирование записей обычных задач типа Контент',
				text: 'Для редактирования записи задачи:',
				points: [
					'Нажмите на кнопку для перехода в режим редактирования',
					'Заполните поля формы',
					'Нажмите на галочку для сохранения формы',
				],
				important: [
					'! Вы можете редактировать записи обычных задач типа Контент в статусе "Готова к проверке", "Черновик"',
				],
				icons: ['edit-blue'],
				roles: ['ROLE_CONTRACTOR'],
			},
			{
				name: 'Удаление записей обычных задач типа Контент',
				text:
					'Нажмите для удаления записи задачи \n' +
					'\n' +
					'Вы можете удалять записи  обычных задач типа Контент в статусе "Готова к проверке", "Черновик"',
				important: [
					'! Вы можете редактировать записи обычных задач типа Контент в статусе "Готова к проверке", "Черновик"',
				],
				icons: ['delete-red'],
				roles: ['ROLE_CONTRACTOR'],
			},
			{
				name: 'Отправка на согласование записей обычных задач типа Контент',
				text: 'Нажмите для отправки на согласование записей обычных задач типа Контент',
				important: ['! Вы можете отправлять на согласование записи задач в статусе "Готова\u00A0к\u00A0проверке"'],
				icons: ['send-yellow'],
				roles: ['ROLE_CONTRACTOR'],
			},
		],
	},
	{
		LevelGroupName: 'Действия над записями обычных задач типа Отчет',
		LevelGroupData: [
			{
				name: 'Создание записей обычных задач типа Отчет',
				text: 'Для создания новой записи задачи:',
				points: ['Нажмите "Добавить данные"', 'Заполните поля формы', 'Нажмите на галочку для сохранения формы'],
				important: ['! Вы не имеете возможности создавать записи обычных задач типа "Отчет об охвате размещенного контента"',],
				screenshots: ['/images/screenshots/task/add-data.png'],
				roles: ['ROLE_CONTRACTOR'],
			},
			{
				name: 'Редактирование записей обычных задач типа Отчет',
				text: 'Для редактирования записи задачи:',
				points: [
					'Нажмите на кнопку для перехода в режим редактирования',
					'Заполните поля формы',
					'Нажмите на галочку для сохранения формы',
				],
				important: ['! Вы можете редактировать записи обычных задач типа Отчет в статусе "Готова к проверке", "Черновик", "Отклонена"'],
				icons: ['edit-blue'],
				roles: ['ROLE_CONTRACTOR'],
			},
			{
				name: 'Удаление записей обычных задач типа Отчет',
				text: 'Нажмите для удаления записи задачи',
				important: [
					'! Вы можете удалять записи обычных задач типа Отчет в статусе "Готова к проверке", "Черновик", "Отклонена"',
					'! Вы не имеете возможности удалять записи задач типа "Отчёт об охвате размещённого контента"'
				],
				icons: ['delete-red'],
				roles: ['ROLE_CONTRACTOR'],
			},
			{
				name: 'Отправка на согласование записей обычных задач типа Отчет',
				text: 'Нажмите для отправки на согласование записей обычных задач типа Отчет',
				important: ['! Вы можете отправлять на согласование записи задач в статусе "Готова к проверке"'],
				icons: ['send-yellow'],
				roles: ['ROLE_CONTRACTOR'],
			},
		],
	},
	{
		LevelGroupName: 'Действия над записями обычных задач',
		LevelGroupData: [
			{
				name: 'Отмена результатов согласования записей обычных задач',
				text:
					'Нажмите для отмены результатов согласования',
				important: ['! Вы можете отменять результаты согласования записей обычных задач со статусом "Согласована", "Отклонена"'],
				icons: ['retweet'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR'],
			},
			{
				name: 'Редактирование одобренных записей обычных задач',
				text: 'Вы имеете возможность редактировать записи обычных задач в статусе "Согласована":',
				points: [
					'Нажмите на кнопку для перехода в режим редактирования',
					'Заполните поля формы',
					'Нажмите на галочку для сохранения формы',
				],
				icons: ['edit-blue'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
			{
				name: 'Удаление одобренных записей обычных задач',
				text: 'Нажмите для удаления записи обычной задачи в статусе "Согласована"',
				icons: ['delete-red'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
		],
	},
	{
		LevelGroupName: 'Действия над записями архивных задач типа Контент',
		LevelGroupData: [
			{
				name: 'Создание записей архивных задач типа Контент',
				text: 'Для создания новой записи архивной задачи типа Контент:',
				points: ['Нажмите "Добавить данные"', 'Заполните поля формы', 'Нажмите на галочку для сохранения формы'],
				screenshots: ['/images/screenshots/task/add-data.png'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
			{
				name: 'Редактирование записей архивных задач типа Контент',
				text: 'Для редактирования записи задачи:',
				points: [
					'Нажмите на кнопку для перехода в режим редактирования',
					'Заполните поля формы',
					'Нажмите на галочку для сохранения формы',
				],
				important: [
					'! Вы можете редактировать записи архивных задач типа Контент в статусе "Готова к проверке", "Создана заказчиком"',
				],
				icons: ['edit-blue'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
			{
				name: 'Удаление записей архивных задач типа Контент',
				text: 'Нажмите для удаления записи задачи',
				important: ['! Вы можете удалять записи архивных задач типа Контент в статусе "Готова к проверке", "Создана заказчиком"'],
				icons: ['delete-red'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
			{
				name: 'Отмена результатов согласования записей архивных задач типа Контент',
				text: 'Нажмите для отмены результатов согласования',
				important: ['! Вы можете отменять результаты согласования записей архивных задач типа Контент в статусе "Согласована", "Отклонена"'],
				icons: ['retweet'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
		],
	},
	{
		LevelGroupName: 'Действия над записями архивных задач типа Отчет',
		LevelGroupData: [
			{
				name: 'Создание записей архивных задач типа Отчет',
				text: 'Для создания новой записи архивной задачи типа Отчет:',
				points: ['Нажмите "Добавить данные"', 'Заполните поля формы', 'Нажмите на галочку для сохранения формы'],
				important: [
					'! Вы не имеете возможности создавать записи архивной задачи типа "Отчет об охвате размещенного контента"',
				],
				screenshots: ['/images/screenshots/task/add-data.png'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
			{
				name: 'Редактирование записей архивных задач типа Отчет',
				text: 'Для редактирования записи задачи:',
				points: [
					'Нажмите на кнопку для перехода в режим редактирования',
					'Заполните поля формы',
					'Нажмите на галочку для сохранения формы',
				],
				important: [
					'! Вы можете редактировать записи архивных задач типа Отчет в статусе "Готова к проверке", "Создана заказчиком", "Отклонена"',
				],
				icons: ['edit-blue'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
			{
				name: 'Удаление записей архивных задач типа Отчет',
				text:
					'Нажмите для удаления записи задачи \n' +
					'\n' +
					'Вы можете удалять записи архивных задач типа Отчет в статусе "Готова к проверке", "Создана заказчиком", "Отклонена"',
				important: ['! Вы не имеете возможности удалять записи задач типа "Отчет об охвате размещенного контента"'],
				icons: ['delete-red'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
			{
				name: 'Отмена результатов согласования записей архивных задач типа Отчет',
				text: 'Нажмите для отмены результатов согласования записей архивных задач типа Отчет',
				important: ['! Вы можете отменять результаты согласования записей архивных задач типа Отчет в статусе "Согласована"'],
				icons: ['retweet'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR'],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр данных результатов согласования записей задач',
		LevelGroupData: [
			{
				name: 'Данные записи согласованы',
				text: 'Статус записи "Согласована"',
				icons: ['check-circle'],
				roles: ['ROLE_CONTRACTOR'],
			},
			{
				name: 'Данные записи отклонены',
				text: 'Статус записи "Отклонена"',
				icons: ['close-circle'],
				roles: ['ROLE_CONTRACTOR'],
			},
			{
				name: 'Данные записи редактируются',
				text: 'Иконка "Редактируется" соответствует статусам записи "Черновик", "Готова к согласованию", "На исправлении"',
				icons: ['minus-circle'],
				roles: ['ROLE_CONTRACTOR'],
			},
			{
				name: 'Данные на согласовании',
				text: 'Статус записи "На согласовании"',
				icons: ['warning-gray'],
				roles: ['ROLE_CONTRACTOR'],
			},

			{
				name: 'Данные записи согласованы',
				text: 'Статус записи "Согласована" Нажмите\u00A0для\u00A0просмотра\u00A0данных\u00A0согласования',
				screenshots: ['/images/screenshots/task/approved.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Данные записи отклонены',
				text: 'Статус записи "Отклонена" Нажмите\u00A0"Проверено"\u00A0для\u00A0просмотра\u00A0данных\u00A0согласования',
				screenshots: ['/images/screenshots/task/rejected.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Данные записи редактируются',
				text:
					'Иконка "Редактируется" соответствует статусам записи "Черновик", "Готова к согласованию", "На исправлении"\n' +
					'\n' +
					'Нажмите\u00A0"Проверено"\u00A0для\u00A0просмотра данных согласования',
				screenshots: ['/images/screenshots/task/edited.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Данные на согласовании',
				text: 'Статус записи "На согласовании" Нажмите\u00A0"Проверено"\u00A0для\u00A0просмотра\u00A0данных согласования',
				screenshots: ['/images/screenshots/task/approval.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр комментариев к записи задачи',
				text: 'Нажмите для просмотра комментариев к записи задачи со статусом "Согласована", "Отклонена"',
				icons: ['file-search'],
				roles: [
					'ROLE_CONTRACTOR',
					'ROLE_ADMIN',
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_ADMIN_IS'
				],
			},
		],
	},
	{
		LevelGroupName: 'Согласование записей задач в статусе "Готова к проверке"',
		LevelGroupData: [
			{
				name: 'Согласование записей задач',
				text: 'Для согласования записи задачи в статусе "Готова к проверке":',
				points: [
					'Нажмите на зеленую галочку в поле "Результаты согласования" для согласования записи\n' +
						'ЛИБО\n' +
						'Нажмите на красный крестик в поле "Результаты согласования" для отклонения записи задачи',
					'Оставьте комментарий по задаче',
					'Нажмите на стрелку для отправки голоса',
				],
				important: ['! Вы можете согласовать несколько записей задач через массовые действия '],
				screenshots: ['/images/screenshots/task/approval-record.png'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
			{
				name: 'Отмена голосования',
				text: 'Нажмите для изменения выбора',
				icons: ['reload'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
			{
				name: 'Отправка голоса',
				text: 'Нажмите для отправки голоса',
				icons: ['send-blue'],
				roles: ['ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER', 'ROLE_PRODUCER'],
			},
		],
	},
	{
		LevelGroupName: 'Согласование записей задач в статусе "На согласовании"',
		LevelGroupData: [
			{
				name: 'Согласование записей задач',
				text: 'Для согласования записи задачи в статусе "На согласовании":',
				points: [
					'Нажмите на зеленую галочку в поле "Результаты согласования" для согласования записи\n' +
						'ЛИБО\n' +
						'Нажмите на красный крестик в поле "Результаты согласования" для отклонения записи задачи',
					'Оставьте комментарий по задаче',
					'Нажмите на стрелку для отправки голоса',
				],
				important: ['! Вы можете согласовать несколько записей задач через массовые действия '],
				screenshots: ['/images/screenshots/task/approval-record.png'],
				roles: [
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Отмена согласования',
				text: 'Нажмите для изменения выбора',
				icons: ['reload'],
				roles: [
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Отправка голоса',
				text: 'Нажмите для отправки голоса',
				icons: ['send-blue'],
				roles: [
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Красная иконка комментариев',
				text: 'Для отправки голоса:',
				icons: ['file-search-red'],
				points: [
					'Нажмите для перехода в модальное окно комментариев',
					'Оставьте комментарий по отклонённой записи задачи',
					'Отправьте голос',
				],
				roles: [
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Оранжевая иконка комментариев',
				text: 'Комментарий по отклоненной записи задачи оставлен: вы можете отправить свой голос',
				icons: ['file-search-yellow'],
				roles: [
					'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Работа в окне формирования отчетности для задач на охваты',
		LevelGroupData: [
			{
				name: 'Переход в окно формирования отчетности в карточке задач типа "Отчет об охвате размещенного контента"',
				text: 'Нажмите для перехода в окно формирования отчетности по проекту',
				screenshots: ['/images/screenshots/task/btn-records-count.png'],
				roles: [
					'ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR', 'ROLE_FINANCIER', 'ROLE_LAWYER',
					'ROLE_MODERATOR', 'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER'
				],
			},
			{
				name: 'Формирование отчета',
				text: 'Для формирования отчета:',
				screenshots: ['/images/screenshots/task/form-report.png'],
				points: [
					'Заполните поля формы в окне формирования отчетности',
					'Нажмите "Сформировать"',
					'Дождитесь окончания формирования отчета: статус формирования отчета "Created"',
				],
				roles: [
					'ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR', 'ROLE_FINANCIER', 'ROLE_LAWYER',
					'ROLE_MODERATOR', 'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER'
				],
			},
			{
				name: 'Скачивание готового отчета',
				text: 'Нажмите на кнопку скачивания по окончании формирования отчета',
				screenshots: ['/images/screenshots/task/created.png'],
				roles: [
					'ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR', 'ROLE_FINANCIER', 'ROLE_LAWYER',
					'ROLE_MODERATOR', 'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER'
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN', 'ROLE_ADMIN_IS', 'ROLE_ANALYST',
					'ROLE_CONTENT_EDITOR',
					'ROLE_COORDINATOR', 'ROLE_FINANCIER', 'ROLE_LAWYER',
					'ROLE_MODERATOR', 'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER'
				],
			},
		]
	},
]
