import {axiosRequest} from 'shared/config/axios'
import {FilmClusterRequest, FilmClusters, Filter,} from '../types'
import {downloadXLXS} from 'utils/downloadXLXS'
import {Id_Name_Code_Hidden, Id_Name_Code_Hidden_Schema} from "shared/types/common";
import {IOptions} from "models/feels";

/**
 * Получение всех кинокластеров
 */
export const getFilmClusters = {
    key: '/film-cluster',
    fetch: async (): Promise<Id_Name_Code_Hidden[]> => {
        const { data } = await axiosRequest<Id_Name_Code_Hidden[]>({
            method: 'GET',
            url: '/film-cluster',
            parser: Id_Name_Code_Hidden_Schema.array(),
        });
        return data;
    },
    async fetchOptions() {
        const data = await getFilmClusters.fetch()
        return data.map((el) => ({
            value: el.id,
            label: el.name,
            hidden: el.hidden,
        } as IOptions))
    }
};

/**
 * Получение списка инокластеров с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
    key: '/film-cluster/search',
    fetch: async (filter: Filter): Promise<FilmClusters> => {
        const newFilter = { ...filter, page: filter.page - 1 };
        const { data } = await axiosRequest<FilmClusters>({
            method: 'POST',
            url: `/film-cluster/search`,
            data: newFilter,
            parser: { list: true, schema: Id_Name_Code_Hidden_Schema.array() },
        });
        return data;
    },
};
/**
 * Обновление кинокластера
 */
export const update = async ({ id, data }: { id: string; data: FilmClusterRequest }): Promise<void> => {
    await axiosRequest({
        method: 'PUT',
        url: `/film-cluster/${id}`,
        data,
    });
};

/**
 * Создание нового кинокластера
 */
export const create = async (data: FilmClusterRequest): Promise<void> => {
    await axiosRequest({
        method: 'POST',
        url: `/film-cluster`,
        data,
    });
};

/**
 * Удаление кинокластера
 */
export const deleteFilmCluster = async (id: string): Promise<void> => {
    await axiosRequest({
        method: 'DELETE',
        url: `/film-cluster/${id}`,
    });
};

/**
 * Массовое действие
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
    await axiosRequest({
        method: 'POST',
        url: `/film-cluster/bulk-actions/${bulkAction}`,
        data: rowIds,
    });
    return bulkAction
};

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]): Promise<void> => {
    const { data }: any = await axiosRequest({
        method: 'POST',
        url: `/film-cluster/export`,
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        data: value,
    });
    downloadXLXS(data, 'film-cluster');
};

/**
 * Получение файла шаблона для выполнения импорта данных
 */
export const downloadTemplate = async (): Promise<void> => {
    const { data }: any = await axiosRequest({
        method: 'GET',
        url: '/film-cluster/import/template',
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
    });
    downloadXLXS(data, 'film-cluster-template');
};

/**
 * Выполнение импорта данных о категориях единиц контента из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData): Promise<void> => {
    await axiosRequest({
        method: 'POST',
        url: '/film-cluster/import',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data,
    });
};