import { useDispatch } from 'react-redux';
import CreateErrorMessage from 'utils/validation/createErrorMessage';
import { NotificationOpen } from 'store/notification/actions';

export function useErrorNotification(messageOptional?: string) {
	const put = useDispatch();
	return function(error: unknown) {
		const message = CreateErrorMessage.response(error);
		const messageDefault = messageOptional || 'Что-пошло не так'
		put(NotificationOpen('error', 'Ошибка', message ? message : messageDefault));
	}

}