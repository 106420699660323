import React, { FC } from 'react'
import './index.less'
import { Audio, DefaultUi, Player, Video } from '@vime/react'
import { FileType, Nullable } from 'models/common'
import { Button, Empty, Skeleton } from 'antd'
import { useDispatch } from 'react-redux'
import { ImageComponentPreview } from 'components/image-component'
import WhetherTheUser from 'components/whetherTheUser'
import env from 'shared/config/env'
import { ModalOpenAction } from 'store/modals/actions'
import { FORMATS_FOR_CONVERSION } from 'utils/varibales'

type Props = {
	file?: FileType | null
	name?: Nullable<string>
	masterFile?: boolean
	image?: Nullable<FileType>
}

const path = env.API_ENDPOINT + '/files/'

const ViewContent: FC<Props> = ({ file, name, masterFile, image }) => {
	const put = useDispatch()
	if (!file || !file.id || !file.type) return <Empty />

	const handlerClickPreview = () => {
		put(ModalOpenAction('content-task', {
				title: name,
				file: file,
			})
		)
	}

	return (
		<>
			{file.type === 'IMAGE' && (
				<div className="file-image">
					<ImageComponentPreview file={file} />
				</div>
			)}
			{file.type === 'OTHER' && (
				<div className="file-other">
					<Skeleton.Image />
					<strong>Не поддерживаемый тип файла</strong>
				</div>
			)}
			{FORMATS_FOR_CONVERSION.includes(file.type) && (
				<div className="file-other">
					<Button type={'primary'} onClick={handlerClickPreview}>
						Посмотреть превью файла
					</Button>
				</div>
			)}
			{(file.type === 'VIDEO' || file.type === 'AUDIO') && (
				<div className={`videoAndAudio ${file.type}`}>
					{file.type === 'VIDEO' && (
						<div className={`description ${file.type}`}>
							<div>Просмотр {name}</div>
							<WhetherTheUser role={['ROLE_CLIENT', 'ROLE_AGENT']} isCan={false}>
								<>
									<div>{masterFile ? 'Мастер файл' : 'Предмастер-файл'}</div>
								</>
							</WhetherTheUser>
						</div>
					)}
					{file.type === 'AUDIO' && (
						<div className={`description ${file.type}`}>
							<div className="description__image">
								<ImageComponentPreview file={image} />
							</div>
							<div className="description__content">
								{name}
								<WhetherTheUser role={['ROLE_CLIENT', 'ROLE_AGENT']} isCan={false}>
									<>
										<div>{masterFile ? 'Мастер файл' : 'Предмастер-файл'}</div>
									</>
								</WhetherTheUser>
							</div>
						</div>
					)}
					<Player theme="dark" currentPoster={file.previewFile ? path + file.previewFile?.id : undefined}>
						{file.type === 'VIDEO' && (
							<Video crossOrigin="use-credentials">
								<source data-src={`${path}${file.id}`} />
							</Video>
						)}
						{file.type === 'AUDIO' && (
							<Audio crossOrigin="use-credentials">
								<source data-src={`${path}${file.id}`} />
							</Audio>
						)}
						<DefaultUi />
					</Player>
				</div>
			)}
		</>
	)
}
export default ViewContent
