import {put, select} from "redux-saga/effects";
import {
    GetTasksContentStartAction,
    SetTaskContentFilter
} from "../../../store/tasks/actions/content";
import {TTaskResponse} from "../../../models/task";
import {selectTask} from "../../../store/tasks/selectors/selectors-task";



export function* setTasksContentFilter(_: ReturnType<typeof SetTaskContentFilter>) {
    try {
        const task: TTaskResponse = yield select(selectTask)
        if (task) yield put(GetTasksContentStartAction(task.id))
    } catch (e: any) {}
}