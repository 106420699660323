import React, {FC} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useMutation} from 'react-query'
import {useLocation} from 'react-router-dom'
import styles from './styles.module.less'
//formik && antd
import {Form, Formik} from 'formik'
import {Button, Spin} from 'antd'
//models
import {TDuplicationSchedule, TTasksKanbanItem} from 'models/task/task.kanban'
import {IOptions} from 'models/feels'
//store
import {DuplicateScheduleTasksStartAction, UpdateDuplicateScheduleTasksStartAction} from 'store/tasks/actions/tasks'
import {NotificationOpen} from 'store/notification/actions'
import {GetTasksKanbanStartAction} from 'store/tasks/actions/gant-kanban'
import {selectParams} from 'store/selectors'
//components
import {RadioButtonsComponent, SwitchComponent} from 'components/formic-control'
import ScheduleDates from './schedule-dates'
import Dates from './dates'
//others
import {ModalEvent} from 'shared/ui/modal'
import {validation} from './validation'
import {DayOfWeekHandbookSelect} from '../../lib'
import {TaskDuplicationSchedulerController} from 'features/сonfiguring-task-autocreation/api'
import {Query} from "processes/query";

type Props = {
	taskId: string
	duplicationSchedule: TTasksKanbanItem['duplicationSchedule']
}
export type TForm = {
	duplicationTerms: '1' | '2'
	scheduleDates: string[] | Date[]
	dayOfWeek: IOptions | null
	daysToDeadline: string | null
} & Omit<TDuplicationSchedule, 'scheduleDates' | 'dayOfWeek' | 'daysToDeadline'>

const ModalDuplication: FC<Props> = ({ taskId, duplicationSchedule }) => {
	const put = useDispatch()
	const location = useLocation()
	const { id } = useSelector(selectParams)
	const isUpdate = !!duplicationSchedule

	const { mutate: enabledHandler, isLoading } = useMutation({
		mutationFn: TaskDuplicationSchedulerController.enabledSchedulers,
		onSuccess: () => {
			if (location.pathname === '/project/tasks') {
				put(GetTasksKanbanStartAction(id))
			} else {
				Query.invalidate([TaskDuplicationSchedulerController.search.key])
			}
			put(NotificationOpen('success', '', 'Расписание успешно отключено'))
			ModalEvent.close()
		},
	})

	const initValues: TForm = {
		firstTaskDate: duplicationSchedule ? duplicationSchedule.firstTaskDate : null,
		lastTaskDate: duplicationSchedule ? duplicationSchedule.lastTaskDate : null,
		dayOfWeek: duplicationSchedule?.dayOfWeek
			? {
					value: duplicationSchedule.dayOfWeek, // @ts-ignore
					label: DayOfWeekHandbookSelect[duplicationSchedule.dayOfWeek],
			  }
			: null,
		scheduleDates: duplicationSchedule ? duplicationSchedule.scheduleDates : [],
		daysToDeadline: duplicationSchedule ? String(duplicationSchedule.daysToDeadline) : null,
		enabled: duplicationSchedule ? duplicationSchedule.enabled : true,
		taskId: taskId,
		duplicationTerms: duplicationSchedule?.scheduleDates.length ? '2' : '1',
	}

	const onChangeSwitch = (enabled: boolean) => {
		if (isUpdate && !enabled) {
			enabledHandler({ taskDuplicationScheduleId: duplicationSchedule.id, enabled })
		}
	}

	const close = () => {
		ModalEvent.close()
	}

	const handler = () => {
		ModalEvent.close()
		Query.invalidate([TaskDuplicationSchedulerController.search.key])
	}

	const onSubmit = (data: TForm) => {
		const values: TDuplicationSchedule = {
			scheduleDates: data.scheduleDates,
			enabled: data.enabled,
			dayOfWeek: data.dayOfWeek?.value ? data.dayOfWeek?.value : null,
			firstTaskDate: data.firstTaskDate,
			lastTaskDate: data.lastTaskDate,
			taskId,
			daysToDeadline: !!data.daysToDeadline ? Number(data.daysToDeadline) : 0,
		}
		if (isUpdate) {
			put(UpdateDuplicateScheduleTasksStartAction(duplicationSchedule?.id, values, handler))
		} else {
			put(DuplicateScheduleTasksStartAction(values, ModalEvent.close))
		}
	}

	return (
		<Spin spinning={isLoading}>
			<Formik
				enableReinitialize
				initialValues={initValues}
				validationSchema={validation}
				onSubmit={onSubmit}
				validateOnMount={true}
				validateOnChange={true}
			>
				{(formik) => {
					const values = formik.values
					const enabled = values.enabled
					return (
						<Form className={styles.container}>
							<div className={styles.header}>
								<span className={styles.title}>Настройка автосоздания задачи</span>
								<SwitchComponent name={'enabled'} label={enabled ? 'Включена' : 'Выключена'} handler={onChangeSwitch} />
							</div>
							<hr />
							<div className={styles.item}>
								<span className={styles.label}>Условия дублирования</span>
								<div className={styles.field}>
									<RadioButtonsComponent
										name={'duplicationTerms'}
										options={[
											{ value: '1', label: 'По заданным условиям' },
											{ value: '2', label: 'По конкретным датам' },
										]}
										disabled={!values.enabled}
										handler={(value: TForm['duplicationTerms']) => {
											if (value === '1') {
												formik.setFieldValue('scheduleDates', [])
											} else {
												formik.setFieldValue('firstTaskDate', null)
												formik.setFieldValue('lastTaskDate', null)
												formik.setFieldValue('dayOfWeek', null)
											}
										}}
									/>
								</div>
							</div>
							<hr />
							<div className={styles.item}>
								<span className={styles.label}>Даты создания задач</span>
								<ScheduleDates />
							</div>
							<hr />
							<Dates />
							<div className={styles.buttons}>
								<Button type={'default'} onClick={close}>
									Отменить
								</Button>
								<Button disabled={!formik.isValid} type={'primary'} htmlType={'submit'}>
									{isUpdate ? 'Обновить' : 'Сохранить'}
								</Button>
							</div>
						</Form>
					)
				}}
			</Formik>
		</Spin>
	)
}

export default ModalDuplication
