import React, {FC, useEffect} from 'react'
import './index.less'
import {Modal, Table, Tooltip} from 'antd'

import useHandlerModal from '../../../hooks/handlModal'
import {ColumnsType} from 'antd/es/table'
import {TColumns} from '../../../models/common'
import {GetOrganizationsUserStartAction} from '../../../store/options/actions/actions-users'
import {useSelector} from 'react-redux'
import {selectGetOrganizationsUser} from '../../../store/options/selectors/selectors-users'
import ContactsCell from './table-cell/contacts-cell'
import {ExclamationCircleOutlined} from "@ant-design/icons";

type TModalProps = {
	status: boolean
	data?: any
}

const ModalUserContactDetails: FC<TModalProps> = ({ status, data }) => {
	const { isVisible, closeModal, put } = useHandlerModal(status)
	const organizationsUser = useSelector(selectGetOrganizationsUser)

	useEffect(() => {
		put(GetOrganizationsUserStartAction(data.id))
		// eslint-disable-next-line
	}, [])

	const columns: ColumnsType<TColumns> = [
		{
			title: 'Организация / ИНН',
			dataIndex: 'name',
			key: 'name',
			render: (name, user: any) => {
				return (
					<div className="modal-user-contact-details__wrapper">
						<div className="modal-user-contact-details__title-cell">
							{name && <span>{name}</span>}
							{user?.inn && <small>{user?.inn}</small>}
						</div>
					</div>
				)
			},
			width: 100,
			sorter: false,
		},
		{
			title: TitleColumn('Email'),
			dataIndex: 'emails',
			key: 'name',

			render: ContactsCell,
			width: 210,
			sorter: false,
		},
		{
			title: TitleColumn('Tелефон'),
			dataIndex: 'phones',
			key: 'phones',
			render: ContactsCell,
			width: 200,
			sorter: false,
		},
	]
	return (
		<Modal
			className="modal-user-contact-details"
			title={Title(data.firstName)}
			visible={isVisible}
			maskClosable={true}
			footer={false}
			onCancel={closeModal}
		>
			<div className="modal-user-contact-details__body">
				<Table
					loading={false}
					className="modal-user-contact-details__table table"
					sticky
					scroll={{ x: 1000 }}
					columns={columns}
					dataSource={organizationsUser ? organizationsUser : []}
					rowKey="id"
					pagination={false}
				/>
			</div>
		</Modal>
	)
}

function Title(username: string) {
	return (
		<div className="modal-user-contact-details__title">
			Контактные данные пользователя
			<span>{username}</span>
		</div>
	)
}

function TitleColumn(name: string) {
	return (
		<div className="title-column">
			{name}
			<Tooltip placement="right" title={TooltipTitle()}>
				<ExclamationCircleOutlined />
			</Tooltip>
		</div>

	)
}

function TooltipTitle() {
	return (
		<div className="modal-user-contact-details__tooltip">
			<p>1.Первое значение в поле email /телефон определяется как “Основное”, на которое будут приходить уведомления</p>
			<p>2. Основной email /телефон может быть только один.</p>
			<p>3. Пользователь может изменить основной email /телефон в настройках профиля.</p>
		</div>
	)
}

export default ModalUserContactDetails
