export type TFilterData = {
    searchString:  string; //Строка поиска (наименование проекта, номер договора, номер заявки)
    requestNumbers: string[]; //Список номеров заявок
    contractNumbers: string[]; //Список номеров договоров
    codes: string[]; //Список кодов проекта
    igks: string[]; //Список ИГК

    isNotVotedRecordsExists: boolean; //Признак наличия записей задач проекта требующих проверки
    notVotedRecordsInspectorUserIds: string[]; //Список ID пользователей проверяющих задач для уточняющей
    // фильтрации по isNotVotedRecordsExists (используется только вместе с isNotVotedRecordsExists)

    organizationIds: string[]; //Список ID организаций
    legalFormIds: string[]; //Список ID правовых форм организаций

    contestIds: string[]; //Список ID конкурсов
    years: number[]; //Список годов
    sessionIds: string[]; //Список ID сессий

    statusIds: string[]; //Список ID статусов
    sprintStatusIds: string[]; //Список ID статусов этапов проектов
    contentDirectionIds: string[]; //Список ID направлений контента
    contentEditorLabelIds: string[]; //Список ID меток редакторов контента
    moderatorLabelIds: string[]; //Список ID меток модераторов контента

    genreIds: string[]; //Список ID жанров
    categoryIds: string[]; //Список ID категорий

    advancePayment: boolean; //Признак авансирование
    isNationalProject: boolean; //Признак национального проекта
    isDataChecked: boolean; //Признак проверки данных проекта
    isFamousPeopleRemarked: boolean; //Признак наличия в проекте данных о известных людях
    isApprovedMasterContentExist: boolean; //Признак наличия в проекте данных контента (апрувнутый, мастер-файл)
    isVisibleForAgent: boolean; //Признак видимости проекта для роли "Представитель"
    isContentCreationProjectType: boolean; //Признак проекта на создание
    isProjectPosted: boolean; //Признак размещения проекта
    isPrivateProject: boolean; //Признак конфиденциальности проекта
    isTaskOverdueExist: boolean; //Признак наличия просроченных задач у проекта
    isFavoriteProject: boolean; //Признак избранного проекта

    fieldFilters: FieldFilter[] //Список фильтров по выпадающим спискам
    excludeWithoutContent: boolean //Признак исключения проектов без контента
    contentFieldFilters: ContentFieldFilters[] //Список фильтров по полям контента
    isContent: boolean //Признак (Контент / Рабочий материал)
    isAccountedContent: string // Признак типа учета
    isMasterContent: boolean // Признак Мастер / Предмастер файл
    contentStatusCodes: string[]; //Список статусов

    bulkAction: BulkAction[]
}
export type FieldFilter = {
    field: FieldsEnums; //Код поля, по которому производится фильтрация
    values: string[]; //Список значений для фильтрации
    minValue?: number; //Минимальное значение для фильтрации
    maxValue?: number; //Максимальное значение для фильтрации
    startDate?: string | Date; //Начальная дата для фильтрации по датам
    endDate?: string | Date; //Конечная дата для фильтрации по датам
    isAndOrBesides?: boolean; //Признак и/или (для списка значений), а также кроме/между (для минимального и максимального значений): true - и/кроме, false - или/между
}
export enum FieldsEnums {
    CONTENT_THEMATIC = 'CONTENT_THEMATIC',
    ZERO_LVL_TAG = 'ZERO_LVL_TAG',
    FIRST_LVL_TAG = 'FIRST_LVL_TAG',
    SECOND_LVL_TAG = 'SECOND_LVL_TAG',
    THIRD_LVL_TAG = 'THIRD_LVL_TAG',
    FIRST_LVL_NATIONAL_PROJECT_TAG = 'FIRST_LVL_NATIONAL_PROJECT_TAG',
    SECOND_LVL_NATIONAL_PROJECT_TAG = 'SECOND_LVL_NATIONAL_PROJECT_TAG',
    REGION_ABOUT = 'REGION_ABOUT',
    REGION_FOR = 'REGION_FOR',
    CONTRACTOR_ORGANIZATION_REGION = 'CONTRACTOR_ORGANIZATION_REGION',
    CONTENT_LOCATION_REGION = 'CONTENT_LOCATION_REGION',
    CONTENT_FORMAT_TYPE = 'CONTENT_FORMAT_TYPE',
    CONTENT_CATEGORY= 'CONTENT_CATEGORY',
    CONTENT_SUBMISSION_FORM = 'CONTENT_SUBMISSION_FORM',
    INTERNET_RESOURCE = 'INTERNET_RESOURCE',
    CHANNEL = 'CHANNEL',
    USER = 'USER',
    FAMOUS_PERSON_RANK = 'FAMOUS_PERSON_RANK',
    FAMOUS_PERSON = 'FAMOUS_PERSON',
    CONTRACT_DATE = 'CONTRACT_DATE',
    AGREEMENT_DATE = 'AGREEMENT_DATE',
    COMPLETION_DATE = 'COMPLETION_DATE',
    FIRST_CONTENT_CREATION_DATE = 'FIRST_CONTENT_CREATION_DATE',
    ALL_CONTENT_CREATION_DATE = 'ALL_CONTENT_CREATION_DATE',
    FIRST_CONTENT_POSTING_DATE = 'FIRST_CONTENT_POSTING_DATE',
    ALL_CONTENT_POSTING_DATE = 'ALL_CONTENT_POSTING_DATE',
    ALL_CONTENT_REACH_FIX_DATE = 'ALL_CONTENT_REACH_FIX_DATE',
    LICENSE_DATE = 'LICENSE_DATE',
    ACTUAL_KPI = 'ACTUAL_KPI',
    PLAN_KPI = 'PLAN_KPI',
    BUDGET = 'BUDGET',
    TARGET_AUDIENCE = 'TARGET_AUDIENCE',
    PLAN_CONTENT_COUNT = 'PLAN_CONTENT_COUNT',
    TOTAL_TASK_COUNT = 'TOTAL_TASK_COUNT',
    READY_TASK_COUNT = 'READY_TASK_COUNT',
    CATEGORY = 'CATEGORY',
    GENRE = 'GENRE',
    PROJECT_INTERNET_RESOURCE_TYPE = 'PROJECT_INTERNET_RESOURCE_TYPE',
    POSTING_RECORD_INTERNET_RESOURCE_TYPE = 'POSTING_RECORD_INTERNET_RESOURCE_TYPE',
    SPRINT_UNTIL_END_DAYS = 'SPRINT_UNTIL_END_DAYS',
    TASK_UNTIL_END_DAYS = 'TASK_UNTIL_END_DAYS',
    CONTENT_TIMING_MIN_MAX = 'CONTENT_TIMING_MIN_MAX',
    AWARD = 'AWARD',
    AWARD_NAME = 'AWARD_NAME',
    AWARD_YEAR = 'AWARD_YEAR',
    AWARD_STATUS = 'AWARD_STATUS',
    AWARD_NOMINATION = 'AWARD_NOMINATION',
    FIRST_LVL_ASSIGNMENT_TAG = 'FIRST_LVL_ASSIGNMENT_TAG',
    SECOND_LVL_ASSIGNMENT_TAG = 'SECOND_LVL_ASSIGNMENT_TAG',
    FIRST_LVL_NATIONAL_PROJECT_ASSIGNMENT_TAG = 'FIRST_LVL_NATIONAL_PROJECT_ASSIGNMENT_TAG',
    SECOND_LVL_NATIONAL_PROJECT_ASSIGNMENT_TAG = 'SECOND_LVL_NATIONAL_PROJECT_ASSIGNMENT_TAG',
    CONTENT_FILTER_FIRST_LVL_ASSIGNMENT_TAG = 'CONTENT_FILTER_FIRST_LVL_ASSIGNMENT_TAG',
    CONTENT_FILTER_SECOND_LVL_ASSIGNMENT_TAG = 'CONTENT_FILTER_SECOND_LVL_ASSIGNMENT_TAG',
    FILM_CLUSTER = 'FILM_CLUSTER',
}
export type ContentFieldFilters = {
    field: FieldsContent //Код поля, по которому производится фильтрация
    minValue?: number; //Минимальное значение для фильтрации
    maxValue?: number; //Максимальное значение для фильтрации
    values?: string[] //Список значений для фильтрации
    isAndOrBesides?: boolean; //Признак и/или (для списка значений), а также кроме/между (для минимального и максимального значений): true - и/кроме, false - или/между
}
export enum FieldsContent {
    CONTENT_FILTER_ZERO_LVL_TAG = 'CONTENT_FILTER_ZERO_LVL_TAG',
    CONTENT_FILTER_FIRST_LVL_TAG = 'CONTENT_FILTER_FIRST_LVL_TAG',
    CONTENT_FILTER_SECOND_LVL_TAG = 'CONTENT_FILTER_SECOND_LVL_TAG',
    CONTENT_FILTER_THIRD_LVL_TAG = 'CONTENT_FILTER_THIRD_LVL_TAG',
    CONTENT_FILTER_REGION_ABOUT = 'CONTENT_FILTER_REGION_ABOUT',
    CONTENT_FILTER_REGION_FOR = 'CONTENT_FILTER_REGION_FOR',
    CONTENT_FILTER_CONTENT_SUBMISSION_FORM  = 'CONTENT_FILTER_CONTENT_SUBMISSION_FORM',
    CONTENT_FILTER_CONTENT_FORMAT_TYPE = 'CONTENT_FILTER_CONTENT_FORMAT_TYPE',
    CONTENT_TIMING_MIN_MAX = 'CONTENT_TIMING_MIN_MAX',
    CONTENT_FILTER_FIRST_LVL_ASSIGNMENT_TAG = 'CONTENT_FILTER_FIRST_LVL_ASSIGNMENT_TAG',
    CONTENT_FILTER_SECOND_LVL_ASSIGNMENT_TAG = 'CONTENT_FILTER_SECOND_LVL_ASSIGNMENT_TAG',
}
export enum BulkAction  {
    ASSIGN_DRAFT_STATUS = 'ASSIGN_DRAFT_STATUS',
    ASSIGN_ACTUAL_STATUS = 'ASSIGN_ACTUAL_STATUS',
    ASSIGN_SUSPENDED_STATUS = 'ASSIGN_SUSPENDED_STATUS',
    ASSIGN_COMPLETED_STATUS = 'ASSIGN_COMPLETED_STATUS',
    DELETE = 'DELETE',
    SHOW_FOR_AGENT = 'SHOW_FOR_AGENT',
    HIDE_FOR_AGENT = 'HIDE_FOR_AGENT',
}
