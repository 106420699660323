import {useEffect} from 'react';
import {subscribePut} from "./events";
import {useDispatch} from "react-redux";

export const StoreActionsComponent = () => {
    const put = useDispatch();
    const putAction = (callback: Function) => {
        put(callback())
    }
    useEffect(() => {
        const unsubscribe = subscribePut(putAction)
        return () => unsubscribe();
    }, [])//eslint-disable-line

    return null;
};
