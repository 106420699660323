import {createSelector} from 'reselect';
import {TApplicationState} from '../aplication-state';

const getSidebarsState = (state: TApplicationState) => state.sidebars;

export const selectSidebarStatus = createSelector(
    getSidebarsState,
    (state) => ({
        menu: state.menu,
        project: state.project,
        task: state.task,
        usersList: state.usersList,
        notification: state.notification,
        functionalityInfo: state.functionalityInfo,
    })
)

export const selectSideBarId = createSelector(
    getSidebarsState,
    (state) => ({
            id: state.id,
            secondId: state.secondId
    })
)
