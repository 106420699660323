import {SidebarsActionTypes} from './action-types'

export type TSidebarsType = 'menu' |
	'project' |
	'task' |
	'notification'|
	'functionalityInfo'


export const OpenSidebarAction = (typeSidebar: TSidebarsType, id?: string, secondId?: string) => ({
	type: SidebarsActionTypes.OPEN_SIDEBAR,
	typeSidebar, id, secondId
})
export const CloseSidebarAction = (typeSidebar: TSidebarsType) => ({
	type: SidebarsActionTypes.CLOSE_SIDEBAR,
	typeSidebar
})
export const CloseAllSidebarsAction = () => ({
	type: SidebarsActionTypes.CLOSE_ALL_SIDEBAR,
})
