import {put} from "redux-saga/effects";
import {
    CreateProjectErrorAction,
    GetProjectsStartAction,
    UpdateProjectStatusStartAction,
} from "store/projects/actions";
import Api from "entities/project/api/project";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";

export function* updateProjectStatus({projectId, statusId}: ReturnType<typeof UpdateProjectStatusStartAction>) {
    try {
        yield Api.updateProjectsStatus(projectId, statusId)
        yield put(GetProjectsStartAction())
    } catch (error: any) {
        yield put(CreateProjectErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить статус проекта'))
    }
}