import {TRole} from "../../models/user";
import { ProjectStatus } from "entities/project/types";
import { TaskTypes } from "entities/task";

function checkAccessValidate(arr: TRole[], role: TRole): boolean {
    return arr.some((ar) => ar === role);
}
function checkProjectStatusAccessValidate(arr: ProjectStatus[], projectStatus: ProjectStatus): boolean {
    return arr.some((p) => p === projectStatus);
}
function checkTaskStatusAccessValidate(arr: TaskTypes.TasksStatus[], status: TaskTypes.TasksStatus): boolean {
    return arr.some((p) => p === status);
}
export {checkAccessValidate, checkProjectStatusAccessValidate, checkTaskStatusAccessValidate}