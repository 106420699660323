import React, {lazy} from 'react';
import {useSelector} from "react-redux";
import './project.less'
import {selectParams} from "store/selectors";
import {Row} from "antd";
import {PROJECTS} from "routing/names";
import ComeBackBtn from "processes/breadcrumbs/ui/come-back-btn";
import ErrorComponent from "components/error-boundary";

const ProjectForm = lazy(() => import('features/create-or-edit-project-form/ui'));

const CreateAndEditProjectPage = () => {
    const {id} = useSelector(selectParams);
    return (
        <ErrorComponent>
            <div className={`projectPage projectPage-create`}>
                <Row className='projectPage__top' align='middle' justify='space-between'>
                    <ComeBackBtn breadcrumbs={[
                        {link: PROJECTS.PROJECTS, name: 'Проекты'}
                    ]}/>
                </Row>
                <div className={'projectPage-form'}>
                    <ProjectForm id={id}/>
                </div>

            </div>
        </ErrorComponent>
    )
}

export default CreateAndEditProjectPage;
