import {TOption} from "models/projects/project-form";
import {TDefault} from "./project-form.dto";
import {ProjectTypes} from "entities/project";
import {TInfoProject} from "../../ui/form-container";


export class StepFourthDto {
    contractorOrganization: TDefault<TOption | null>
    contractorAgentVisible: TDefault<boolean>
    contractors: TDefault<ProjectUserDto[] | []>

    producers: TDefault<ProjectUserDto[] | []>
    coordinators: TDefault<ProjectUserDto[] | []>
    analysts: TDefault<ProjectUserDto[] | []>
    financiers: TDefault<ProjectUserDto[] | []>
    lawyers: TDefault<ProjectUserDto[] | []>
    contentEditors: TDefault<ProjectUserDto[] | []>
    projectManagers : TDefault<ProjectUserDto[] | []>


    producersTutor: TDefault<ProjectUserDto[] | []>
    producersTutorVisible: TDefault<boolean> = {value: false}
    coordinatorsTutor: TDefault<ProjectUserDto[] | []>
    coordinatorsTutorVisible: TDefault<boolean> = {value: false}
    analystsTutor: TDefault<ProjectUserDto[] | []>
    analystsTutorVisible: TDefault<boolean> = {value: false}
    financiersTutor: TDefault<ProjectUserDto[] | []>
    financiersTutorVisible: TDefault<boolean> = {value: false}
    lawyersTutor: TDefault<ProjectUserDto[] | []>
    lawyersTutorVisible: TDefault<boolean> = {value: false}
    contentEditorsTutor: TDefault<ProjectUserDto[] | []>
    contentEditorsTutorVisible: TDefault<boolean> = {value: false}
    projectManagersTutor: TDefault<ProjectUserDto[] | []>
    projectManagersTutorVisible: TDefault<boolean> = {value: false}

    constructor(project?: ProjectTypes.Project | null) {
        this.contractorOrganization = project ?
            {value: {value: project.contractorOrganization.id, label: project.contractorOrganization.name}} :
            {value: null}
        this.contractorAgentVisible = project ? {value: project.contractorAgentVisible} : {value: false}
        this.contractors = {value: []}

        this.producers = {value: []}
        this.producersTutor = {value: []}
        this.producersTutorVisible = {value: false}

        this.coordinators = {value: []}
        this.coordinatorsTutor = {value: []}
        this.coordinatorsTutorVisible = {value: false}

        this.analysts = {value: []}
        this.analystsTutor = {value: []}
        this.analystsTutorVisible = {value: false}

        this.financiers = {value: []}
        this.financiersTutor = {value: []}
        this.financiersTutorVisible = {value: false}

        this.lawyers = {value: []}
        this.lawyersTutor = {value: []}
        this.lawyersTutorVisible = {value: false}

        this.contentEditors = {value: []}
        this.contentEditorsTutor = {value: []}
        this.contentEditorsTutorVisible = {value: false}

        this.projectManagers = {value: []}
        this.projectManagersTutor = {value: []}
        this.projectManagersTutorVisible = {value: false}

        if (project) {
            fillingPerson.call(this, 'producers', 'producersTutor', 'producersTutorVisible', project.producers)
            fillingPerson.call(this, 'coordinators', 'coordinatorsTutor', 'coordinatorsTutorVisible', project.coordinators)
            fillingPerson.call(this, 'analysts', 'analystsTutor', 'analystsTutorVisible', project.analysts)
            fillingPerson.call(this, 'financiers', 'financiersTutor', 'financiersTutorVisible', project.financiers)
            fillingPerson.call(this, 'lawyers', 'lawyersTutor', 'lawyersTutorVisible', project.lawyers)
            fillingPerson.call(this, 'contentEditors', 'contentEditorsTutor', 'contentEditorsTutorVisible', project.contentEditors)
            fillingPerson.call(this, 'projectManagers', 'projectManagersTutor', 'projectManagersTutorVisible', project.projectManagers)

            fillingPerson.call(this, 'contractors', '', '', project.contractors)
        }

    }

    validate(info: TInfoProject) {
        for (const key in this) {
            // @ts-ignore
            this[key].isDisabled = validate(key, info)
        }
    }
}

export class ProjectUserDto implements TOption {
    label;
    value;

    constructor(el: ProjectTypes.UserProject) {
        this.label = `${el.firstName} ${el.lastName} ${el.middleName ? el.middleName : ''}`
        this.value = el.id
    }
}

function fillingPerson(this: StepFourthDto, persons: string, keyTutor: string, keyTutorVisible: string, arr: ProjectTypes.UserProject[]) {
    arr.forEach((el) => {
        const user = new ProjectUserDto(el)
        if (el.isTutor && keyTutor) {
            // @ts-ignore
            this[keyTutor].value = [...this[keyTutor]?.value, user]
        } else {
            // @ts-ignore
            this[persons].value = [...this[persons]?.value, user]
        }
        if (el.tutorVisible && keyTutorVisible) {
            // @ts-ignore
            this[keyTutorVisible].value = true
        }
    })
}


function validate(field: string, info: TInfoProject): boolean {
    let res = false;
    if (!info) return res
    const {role, status } = info;
    if (role === 'ROLE_CONTRACTOR') res = true
    if ((role === 'ROLE_ADMIN') || (role ==='ROLE_ADMIN_IS')) {
        if (status === 'DRAFT') res = false;
        else if (status === 'SUSPENDED' || status === 'COMPLETED') res = true
        else if (status === 'ACTUAL' || status === 'ARCHIVED') {
            if (field === 'contractorOrganization') res = true
        }
    }
    if ((role === 'ROLE_COORDINATOR') || (role ==='ROLE_MODERATOR') || (role === 'ROLE_PROJECT_MANAGER')) {
        if (status === 'DRAFT') res = false;
        else if (status === 'SUSPENDED' || status === 'COMPLETED') res = true
        else if (status === 'ACTUAL'|| status === 'ARCHIVED') {
            if (field === 'contractorOrganization') res = true
        }
    }
    if ((role === 'ROLE_PRODUCER')) {
        res = true;
        if (status === 'ACTUAL'|| status === 'ARCHIVED') {
            if (field === 'producers') res = false
        }
    }
    if (role === 'ROLE_ANALYST') {
        res = true;
        if (status === 'ACTUAL'|| status === 'ARCHIVED') {
            if (field === 'analysts') res = false
        }
    }
    if (role === 'ROLE_FINANCIER') {
        res = true;
        if (status === 'ACTUAL'|| status === 'ARCHIVED') {
            if (field === 'financiers') res = false
        }
    }
    if (role === 'ROLE_LAWYER') {
        res = true;
        if (status === 'ACTUAL'|| status === 'ARCHIVED') {
            if (field === 'lawyers') res = false
        }
    }
    if (role === 'ROLE_CONTENT_EDITOR') {
        res = true;
        if (status === 'ACTUAL'|| status === 'ARCHIVED') {
            if (field === 'contentEditors') res = false
        }
    }
    return res
}

