import { FunctionalityInfoType } from '../types'

export const optionsPersonalSettings: FunctionalityInfoType = [
	{
		LevelGroupName: 'Настройки подтверждений и подписок',
		LevelGroupData: [
			{
				name: 'Переход на другую вкладку',
				text: 'Нажмите для перехода на другую вкладку',
				screenshots: ['/images/screenshots/options-personal-settings/tabs.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Подписка на бот оповещения системы контент.ири.рф',
				text: 'Нажмите "Подписаться" для подписки на бот оповещения системы контент.ири.рф',
				screenshots: ['/images/screenshots/options-personal-settings/bot.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Подтверждение email  и телефона пользователя',
				text: 'Нажмите "Подтвердить" для подтверждения email / телефона',
				screenshots: ['/images/screenshots/options-personal-settings/confirm.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Управление уведомлениями',
		LevelGroupData: [
			{
				name: 'Управление личными уведомлениями',
				points: ['Выберите каналы получения уведомлений', 'Нажмите "Сохранить"'],
				screenshots: ['/images/screenshots/options-personal-settings/control.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
