import { createSelector } from 'reselect';
import {TApplicationState} from "../aplication-state";

const getNotificationState = (state: TApplicationState) => state.notification;

export const selectNotification = createSelector(
    getNotificationState,
    (state) => ({
        title: state.title,
        description: state.description,
        type: state.type,
        checkAuth: state.checkAuth,
        isLogin: state.isLogin
    })
)

