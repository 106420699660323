import { NotificationModel } from '..';
import { eventBus } from 'shared/services';

const eventName = 'NotificationOpen';
export const subscribe = (callback: Function) => {
	return eventBus.subscribe(eventName, callback);
};

export const open = (data: NotificationModel.NotificationType) => {
	eventBus.broadcast(eventName, data);
};
