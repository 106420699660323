import React from 'react'
import styles from './styles.module.less'
import CheckboxComponent from 'components/formic-control/Checkbox'
import { useFormikContext } from 'formik'
import { InitialValueDto } from '../../lib/dto'

const Format = () => {
	const { setFieldValue } = useFormikContext<InitialValueDto>()
	const handler = (e: any) => {
		const value = e.target.checked ? 'XLSX' : ''
		setFieldValue('format', value)
	}
	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Форма отчета <sup>*</sup>
			</span>
			<CheckboxComponent name={'format'} placeholder={'Excel (.xls)'} handler={handler} />
		</div>
	)
}

export default Format
