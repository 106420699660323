import {TNationalTag} from "../../models/handbook";
import {NationalProjectTag2Types} from "./action-types";

/**
 * Получение всех NationalProjectTag2
 */
export const GetAllNationalProjectTag2StartAction = () => ({
	type: NationalProjectTag2Types.GET_ALL_NATIONAL_PROJECT_TAG2_START,
})
export const GetAllNationalProjectTag2SuccessAction = (data: TNationalTag[]) => ({
	type: NationalProjectTag2Types.GET_ALL_NATIONAL_PROJECT_TAG2_SUCCESS,
	data
})
export const GetAllNationalProjectTag2ErrorAction = (error: any) => ({
	type: NationalProjectTag2Types.GET_ALL_NATIONAL_PROJECT_TAG2_ERROR,
	error,
})
