import {put} from "redux-saga/effects";
import {LogsSendErrorAction, LogsSendStartAction, LogsSendSuccessAction} from "store/logs/actions";
import UsersActionsService from "services/usersActionsService";

export function* sendLogs({logs}: ReturnType<typeof LogsSendStartAction>) {
    try {
        yield UsersActionsService.sendActions(logs)
        yield put(LogsSendSuccessAction())
    } catch (error: any) {
        yield put(LogsSendErrorAction(error))
    }
}