import React, {FC, useEffect, useState} from 'react';
import './index.less'
import {FileType} from "models/common";
import {Empty, Image, Spin} from "antd";
import env from "shared/config/env";

const pathImage = `${env.API_ENDPOINT}/files/`

const ImageComponentPreview: FC<{ file?: FileType | null, altImg?: string, alt?: string }> = ({
  file,
  altImg,
  alt = '',
}) => {
    const [loading, setLoading] = useState(true);
    const imageId = file?.compressedId ? file.compressedId : file?.id;

    useEffect(() => {
        if (!imageId) setLoading(false)
    }, [imageId]);

    return (
        <Spin spinning={loading} className="ImageComponent">
            {imageId ? (
                <Image
                    src={pathImage + imageId}
                    alt={alt ? alt : file?.name}
                    onLoad={_event => {
                        setLoading(false)
                    }}
                />
            ) : altImg ? (
                <Image src={altImg} alt={alt} onLoad={_event => {
                    setLoading(false)
                }}/>
            ) : <Empty/>}
        </Spin>
    )
};
export default ImageComponentPreview;