import {LogoutAction} from "store/auth/actions";
import AuthService from "services/authService";


export function* logoutWorker(_a: ReturnType<typeof LogoutAction>) {
    try {
        yield AuthService.logout();
    } catch (error: any) {
        console.log(error)
    }
}