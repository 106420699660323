import {useDispatch} from "react-redux";
import {useState} from "react";
import {ModalCloseAction} from "../store/modals/actions";

const useHandlerModal = (status?: boolean) => {
    const put = useDispatch();
    const [isVisible, setIsVisible] = useState<boolean>(status ? status : false);
    const closeModal = () => {
        setIsVisible(false);
        setTimeout(() => {
            put(ModalCloseAction());
        }, 300)

    }
    return {isVisible, closeModal, put}
}
export default useHandlerModal;
