import React from 'react'
import {useSelector} from 'react-redux'
import styles from '../styles.module.less'
import {SelectComponent} from 'components/formic-control'
import {selectParams} from 'store/selectors'
import {useFormikContext} from "formik";
import {TInitValues} from "../modal-content-records";
import DropdownComponent from "./dropdown";
import {TagLib} from 'entities/tags'

const FirstAssignmentTags = () => {
	const { values } = useFormikContext<TInitValues>()
	const { id } = useSelector(selectParams)

	const {data: firstAssignmentTags} = TagLib.useGetNotNationalProjectTagsOptions({projectId: id, level: 1, enabled: !!id, isAssignment: true})

	return (
		<div className={styles.item}>
			<span className={styles.label}>Теги поручений единиц контента проекта 1 уровня</span>
			<div className={styles.field}>
				<SelectComponent
					disabled={values.disabled || values.disabledForRoles}
					multi={true}
					options={firstAssignmentTags}
					name={'firstAssignmentTags'}
					placeholder={'Выберите тег'}
					maxTagCount={3}
					dropdownRender={<DropdownComponent name={'firstAssignmentTags'} options={firstAssignmentTags}/>}
				/>
			</div>
		</div>
	)
}

export default FirstAssignmentTags
