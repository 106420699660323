import {axiosRequest} from 'shared/config/axios'
import {
    ContentCategory,
    ContentCategoryResponse,
    ContentCategorySchema,
    ContentCategoryUpdate,
    FilterContentCategory,
} from '../types'
import {downloadXLXS} from 'utils/downloadXLXS'

/**
 * Получение всех категорий единиц контента
 */
export const getContentCategories = {
    key: '/projects/content-categories',
    fetch: async (): Promise<ContentCategory[]> => {
        const { data } = await axiosRequest<ContentCategory[]>({
            method: 'GET',
            url: '/projects/content-categories',
            parser: ContentCategorySchema.array(),
        });
        return data;
    },
};

/**
 * Получение списка категорий единиц контента с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
    key: '/projects/content-categories/search',
    fetch: async (filter: FilterContentCategory): Promise<ContentCategoryResponse> => {
        const newFilter = { ...filter, page: filter.page - 1 };
        const { data } = await axiosRequest<ContentCategoryResponse>({
            method: 'POST',
            url: `/projects/content-categories/search`,
            data: newFilter,
            parser: { list: true, schema: ContentCategorySchema.array() },
        });
        return data;
    },
};
/**
 * Обновление категории единиц контента
 */
export const update = async ({ id, data }: { id: string; data: ContentCategoryUpdate }): Promise<void> => {
    await axiosRequest({
        method: 'PUT',
        url: `/projects/content-categories/${id}`,
        data,
    });
};

/**
 * Создание новой категории единиц контента
 */
export const create = async (data: ContentCategoryUpdate): Promise<void> => {
    await axiosRequest({
        method: 'POST',
        url: `/projects/content-categories`,
        data,
    });
};

/**
 * Удаление категории единиц контента
 */
export const deleteContentCategory = async (id: string): Promise<void> => {
    await axiosRequest({
        method: 'DELETE',
        url: `/projects/content-categories/${id}`,
    });
};

/**
 * Массовое действие
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
    await axiosRequest({
        method: 'POST',
        url: `/projects/content-categories/bulk-actions/${bulkAction}`,
        data: rowIds,
    });
    return bulkAction
};

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]): Promise<void> => {
    const { data }: any = await axiosRequest({
        method: 'POST',
        url: `/projects/content-categories/export`,
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        data: value,
    });
    downloadXLXS(data, 'content-categories');
};

/**
 * Получение файла шаблона для выполнения импорта данных
 */
export const downloadTemplate = async (): Promise<void> => {
    const { data }: any = await axiosRequest({
        method: 'GET',
        url: '/projects/content-categories/import/template',
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
    });
    downloadXLXS(data, 'content-categories-template');
};

/**
 * Выполнение импорта данных о категориях единиц контента из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData): Promise<void> => {
    await axiosRequest({
        method: 'POST',
        url: '/projects/content-categories/import',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data,
    });
};