import {put} from 'redux-saga/effects'
import {
	InterimVoteTaskContentErrorAction,
	InterimVoteTaskContentStartAction,
	InterimVoteTaskContentSuccessAction,
} from 'store/tasks/actions/content'
import TasksContentService from 'services/tasksContentService'
import {NotificationOpen} from 'store/notification/actions'
import CreateErrorMessage from 'utils/validation/createErrorMessage'

export function* interimVoteTask({ data }: ReturnType<typeof InterimVoteTaskContentStartAction>) {
	try {
		//при отправке isInterimApproved (промежуточного голоса) isApproved всегда null
		const requestData = {
			...data,
			vote: { ...data.vote, isApproved: null, isInterimApproved: data.vote.isInterimApproved },
		}

		yield TasksContentService.voteTaskRecord(requestData)
		yield put(InterimVoteTaskContentSuccessAction())
	} catch (error: any) {
		yield put(InterimVoteTaskContentErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось проголосовать'))
	}
}
