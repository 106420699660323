import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    GetHandbookStartAction,
    UpdateSessionsTypeErrorAction,
    UpdateSessionsTypeStartAction,
    UpdateSessionsTypeSuccessAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import SessionTypeService from "services/sessionTypeService";


export function* updateSessionsType({id, data,}: ReturnType<typeof UpdateSessionsTypeStartAction>) {
    try {
        yield SessionTypeService.updateSessionsTypeRecord(data,id)
        yield put(UpdateSessionsTypeSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.sessionsType))
        yield put(NotificationOpen('success', 'Тип сессии обновлен'))
    } catch (error: any) {
        yield put(UpdateSessionsTypeErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить тип сессии'))
    }
}

