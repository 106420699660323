import {useQuery} from "react-query";
import {PageCode} from "../../types/common";
import RoleController from "../../api/role-controller";

export function useGetBulkActions(pageCode: PageCode) {
    const bulkActions = useQuery({
        queryKey: [RoleController.keyOptions(pageCode)],
        queryFn: () => RoleController.bulkOptions(pageCode),
    })

    return {
        isLoading: bulkActions.isLoading,
        bulkActionsOptions: bulkActions.data || []
    }
}