import React, {FC, useState} from 'react';
import './modal-delete.less'
import {Button, Input, Modal} from "antd";
import {NotificationOpen} from "store/notification/actions";
import useHandlerModal from "hooks/handlModal";
import {DeleteUserStartAction} from "store/options/actions/actions-users";
import {
    BulkOrganizationActionsStartAction,
    DeleteOrganizationStartAction
} from "store/options/actions/actions-handbook";

type ModalDeleteProps = {
    status: boolean
    data: {
        id: string | string[]
        callback: Function
    }
    type: string
}
const ModalDelete: FC<ModalDeleteProps> = ({status, data, type}) => {
    const [state, setState] = useState('');
    const {isVisible, closeModal, put} = useHandlerModal(status);

    const deleteHandler = (e:any) => {
        e.preventDefault()
        if (data.id && state === 'УДАЛИТЬ') {
            switch (type) {
                case "delete-session":
                case "delete-contest":
                case 'delete-format-type':
                case "delete-content-category":
                case "delete-erv-range":
                case "delete-users":
                case "delete-tag":
                case "delete-famous-people":
                case "delete-film-cluster":
                    data.callback && data.callback();
                    break
                case "delete-user":
                    put(DeleteUserStartAction(data.id as string));
                    break
                case "delete-organization":
                    put(DeleteOrganizationStartAction(data.id as string));
                    break
                case "delete-organizations":
                    put(BulkOrganizationActionsStartAction(data.id as string[], 'DELETE'));
                    break
                default:
                    return null
            }
            closeModal();
        } else {
            put(NotificationOpen('warning', 'Ошибка валадации', `Необходимо ввести (УДАЛИТЬ), вы ввели ${state}`))
            setState('')
        }
    }
    return (
        <Modal
            className='modal-delete-contest'
            title="Подтвердите действие"
            visible={isVisible}
            onCancel={closeModal}
            footer={(
                <>
                    <Button type={"default"} onClick={closeModal}>Отменить</Button>
                    <Button type={"primary"} onClick={deleteHandler}>Подтвердить</Button>
                </>
            )}
            maskClosable={true}
        >
            <div className='modal-delete-contest__content'>
                <span>Удаление приведет к необратимым последствиям, потере всех связанных операций и настроек.</span>
                <strong>Введите слово УДАЛИТЬ для подтверждения действия</strong>
                <form onSubmit={deleteHandler}>
                    <Input type={"text"} placeholder={'УДАЛИТЬ'} value={state} onChange={event => {
                        setState(event.target.value)
                    }}/>
                </form>
            </div>
        </Modal>
    )
};
export default ModalDelete;
