import { ErrorActionState, StartActionState, SuccessActionState } from 'utils/reducerHalper'

import { InferValueTypes, T_ID_NAME_CODE } from 'models/common'
import * as actions from './actions'
import {
	TContentCountResponse,
	TContentFilter,
	TProjectResponse,
} from 'models/content'
import { Types } from './action-types'
import {TResponseProjects} from "models/projects";
import {Logout} from "../../auth/reducer";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>
export type TContentTypes =
	| 'VIDEO_CONTENT'
	| 'AUDIO_CONTENT'
	| 'PHOTO_CONTENT'
	| 'TEXT_CONTENT'
	| 'SOFTWARE_CONTENT'
	| 'OTHER_CONTENT'
	| string

export type TContentState = {
	contentList: TResponseProjects["content"] | null
	filter: TContentFilter
	count: TContentCountResponse[] | null
	project: TProjectResponse | null
	typeContent: TContentTypes | null
	contentModuleSections: T_ID_NAME_CODE[] | null
	isLoading: boolean
	error: any
}
const initialState: TContentState = {
	contentList: null,
	filter: {
		page: 1,
		pageSize: 10,
	},
	count: null,
	project: null,
	typeContent: null,
	contentModuleSections: null,
	isLoading: false,
	error: null,
}
export default function reducer(state: TContentState = initialState, action: ActionTypes | Logout): TContentState {
	switch (action.type) {
		case Types.SET_CONTENT_INFO:
			return {
				...state,
				...action.value
			}
		case Types.SET_CONTENT_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					...action.filter,
				},
				typeContent: action.contentType ? action.contentType : null,
			}
		case Types.GET_CONTENT_LIST_STAT:
			return StartActionState(state)
		case Types.GET_CONTENT_LIST_SUCCESS:
			const { content, page, ...filters } = action.content
			return {
				...SuccessActionState(state),
				contentList: content,
				filter: {
					...state.filter,
					page: page + 1,
					...filters,
				},
			}
		case Types.GET_CONTENT_LIST_ERROR:
			return ErrorActionState(state, action)
		default:
			return state
	}
}
