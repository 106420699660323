import {put} from "redux-saga/effects";
import {NotificationOpen} from "store/notification/actions";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {
    DeleteRanksErrorAction,
    DeleteRanksStartAction,
    DeleteRanksSuccessAction,
    GetHandbookStartAction
} from "store/options/actions/actions-handbook";
import {TStatusesHandbookFeels} from "models/handbook";
import RanksService from "services/ranksService";


export function* deleteRanksRecord({id}: ReturnType<typeof DeleteRanksStartAction>) {
    try {
        yield RanksService.deleteRanksRecord(id)
        yield put(DeleteRanksSuccessAction())
        yield put(GetHandbookStartAction(TStatusesHandbookFeels.ranks))
        yield put(NotificationOpen('success', 'Звание удалено'))

    } catch (error: any) {
        yield put(DeleteRanksErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось удалить звание'))
    }
}
