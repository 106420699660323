import {axiosRequest} from "shared/config/axios";
import {MassUpdateUsers, ProjectObjectLinks} from "../types";

export const  updateProjectUsers =  async (data: MassUpdateUsers) => {
    await axiosRequest({
        method: 'PUT',
        url: `/projects/project-users`,
        data,
    });
}
export const  updateProjectObjectsLinks = async (data: ProjectObjectLinks) => {
    await axiosRequest({
        method: 'PUT',
        url: `/projects/projects-objects-links`,
        data,
    });
}