const settings = [
	{
		id: '60',
		selector: '.setting-form__mail',
		content:
			'Настройка условий рассылки уведомлений позволяет установить время отправки сообщения на определенный канал',
		role: [],
	},
	{
		id: '61',
		selector: '.setting-form__channels',
		content:
			'Настройка распределения отправки сообщений по каналам позволяет выставить приоритет отправки и ее последовательность',
		role: [],
	},
	{
		id: '62',
		selector: '.notification-menu',
		content: 'Боковое меню позволяет перейти в настройки шаблонов текущего уведомления для каждого канала рассылки',
		role: [],
	},
]

export default settings
