import {delay, put, select} from "redux-saga/effects";
import {
    GetContentListStartAction,
    GetContestsListErrorAction,
} from "store/content/list/actions";
import {selectRole} from "store/auth/selectors";
import {TRole} from "models/user";
import { SearchContentProjectModel } from "features/search-project-content";
import { ExtendedSearchModel } from "features/extended-search";

const rolesForSearchCase: TRole[] = ['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_CLIENT', 'ROLE_AGENT'];
export function* getContentList(_action: ReturnType<typeof GetContentListStartAction>) {
    try {
        const role: TRole = yield select(selectRole);
        if (!role) {
            yield delay(500)
            yield put(GetContentListStartAction())
        }

        if (rolesForSearchCase.includes(role)) {
            yield put(SearchContentProjectModel.actions.FetchCF('content'))
        } else {
            yield put(ExtendedSearchModel.actions.FetchCF('content'))
        }

    } catch (error: any) {
        yield put(GetContestsListErrorAction(error))
    }
}
