import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import {history} from "configure-store";


const WithRouter = (component: () => React.ReactNode) => () => {
	return <ConnectedRouter history={history}>{component()}</ConnectedRouter>;
};

export default WithRouter;
