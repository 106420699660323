import React, { useState } from 'react'
import { Input, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import useDebounce from 'hooks/useDebounce'
import { useDispatch, useSelector } from 'react-redux'
import { action, select } from '../../../model'
import classNames from 'classnames'
import styles from '../styles.module.less'

const Search = () => {
	const put = useDispatch()
	const filter = useSelector(select.filter)
	const [search, setSearch] = useState<string | undefined>(filter.filterData?.searchString)

	const onSearch = (e: any) => {
		const value = e?.target?.value
		put(action.setValue({
				...filter,
				filterData: {
					...filter.filterData,
					searchString: value || undefined,
				},
			}))
	}
	const handleChangeSearch = useDebounce(onSearch, 400)

	const onSearchChange = (e: any) => {
		setSearch(e.target.value)
		handleChangeSearch(e)
	}

	return (
		<Tooltip title="Поиск по названию, коду отчета и фио пользователя" placement="top">
			<div className={classNames('form-group', styles.search, 'tour-search')}>
				<Input
					autoComplete="off"
					value={search}
					allowClear={true}
					prefix={<SearchOutlined className={styles.prefix} />}
					placeholder="Поиск"
					onChange={onSearchChange}
				/>
			</div>
		</Tooltip>
	)
}

export default Search
