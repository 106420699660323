import React, {FC} from 'react';
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {SearchContentProjectModel, SearchContentProjectTypes} from 'features/search-project-content/index';
import {TagLib} from 'entities/tags'

const { Option} = Select;
type Props = {
    name: keyof SearchContentProjectTypes.Stores.Thematics
    level: number
}
export const Teg: FC<Props> = ({name, level}) => {
    const put = useDispatch();
    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main);

    const thematics = useSelector(SearchContentProjectModel.selectors.thematics);
    const contentFilterLvlTagIds = thematics[typeContent][name];

    const {data, isLoading} = TagLib.useGetContentProjectTagsOptions({level, isAssignment: false})

    function onSelectChange(value: string[]) {
        put(SearchContentProjectModel.actions.setThematics(typeContent, {
            [name]: value
        }))
    }

    return (
        <div className="item">
            <div className="label">Теги единиц контента проекта <br/>({`${level}`} уровня)</div>
            <div className="form-group">
                <Select
                    loading={isLoading}
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={true}
                    onChange={onSelectChange}
                    mode={"multiple"}
                    maxTagCount={"responsive"}
                    value={contentFilterLvlTagIds as string[]}
                    placeholder={'Искать среди всех'}
                    filterOption={(input, option) => {
                        if (option === undefined || option === null) return false;
                        return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                >
                    {data?.map((el) => (
                        <Option
                            key={el.value}
                            value={el.value}
                            data-name={el.label}
                        >
                            {el.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
};
