import { IOptions } from 'models/feels';

export const DayOfWeekHandbook = {
	'MONDAY': 'Понедельник',
	'TUESDAY': 'Вторник',
	'WEDNESDAY': 'Среда',
	'THURSDAY': 'Четверг',
	'FRIDAY': 'Пятница',
	'SATURDAY': 'Суббота',
	'SUNDAY': 'Воскресенье'
}
export const DayOfWeekHandbookSelect = {
	'MONDAY': 'Каждый понедельник',
	'TUESDAY': 'Каждый вторник',
	'WEDNESDAY': 'Каждую среду',
	'THURSDAY': 'Каждый четверг',
	'FRIDAY': 'Каждую пятницу',
	'SATURDAY': 'Каждую субботу',
	'SUNDAY': 'Каждое воскресенье'
}

const arrDayOfWeekHandbook: IOptions[] = [];
for (let key in DayOfWeekHandbookSelect) {
	// @ts-ignore
	arrDayOfWeekHandbook.push({value: key, label: DayOfWeekHandbookSelect[key]} as IOptions)
}
export const dayOfWeekOptions: IOptions[] = arrDayOfWeekHandbook;