import React from 'react';
import './header.less'
import {Link} from 'react-router-dom';
import logo from 'assets/svg/logoBig.svg'
import MainMenu from "../menu/main-menu";
import InfoMenu from "../menu/info-menu";


const Header = () => {
    return (
        <header className='header'>
            <div className="header__left">
                <Link to={'/'}><img src={logo} className='logo-header' alt="Ири.рф"/></Link>
                <MainMenu mode={"horizontal"}/>
            </div>
            <div className="header__right">
                <InfoMenu/>
            </div>
        </header>
    )
};
export default Header;