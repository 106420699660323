import { z } from 'zod'
import { FilterRequest, SearchResponse } from 'shared/types/common'

export const ContestSchema = z.object({
	id: z.string(),
	shortName: z.string(),
	code: z.string(),
	name: z.string(),
	startDate: z.string(),
	endDate: z.string(),
	year: z.number(),
	targetAudience: z.string(),
})
export type Contest = z.infer<typeof ContestSchema>

export const ContestShortSchema = z.object({
	id: z.string(),
	shortName: z.string(),
	code: z.string(),
	name: z.string(),
	startDate: z.string(),
	endDate: z.string(),
})
export type ContestShort = z.infer<typeof ContestShortSchema>

export const ContestUpdateSchema = z.object({
	code: z.string().nullable(),
	shortName: z.string(),
	name: z.string(),
	startDate: z.string(),
	endDate: z.string(),
	targetAudience: z.string(),
})

export type ContestUpdate = z.infer<typeof ContestUpdateSchema>

export const FilterDataSchema = z.object({
	searchString: z.string().optional(),
	startDate: z.string().optional(),
	endDate: z.string().optional(),
	statusIds: z.array(z.string()).optional(),
	bulkAction: z.string().optional(),
})

export type FilterData = z.infer<typeof FilterDataSchema>

export type FilterContest = FilterRequest<FilterData>

export type ContestResponse = SearchResponse<Contest>
