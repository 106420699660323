import {AxiosResponse} from 'axios'
import {put} from 'redux-saga/effects'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {
	GetTagsThirdLevelTaskRecordContentErrorAction,
	GetTagsThirdLevelTaskRecordContentStartAction,
	GetTagsThirdLevelTaskRecordContentSuccessAction
} from "store/tags/actions";
import {TTagSecondLevel} from "models/tags";
import {TagsService} from "services/tagsService";

export default function* getThirdLevelTaskRecordContent({secondLevelTagIds}: ReturnType<typeof GetTagsThirdLevelTaskRecordContentStartAction>) {
	try {
		const {data}: AxiosResponse<TTagSecondLevel[]> = yield TagsService.getTagsThirdLevel({
			page:0,
			pageSize: 0,
			filterData: {secondLvlTagIds: secondLevelTagIds}
		});
		yield put(GetTagsThirdLevelTaskRecordContentSuccessAction(data))
	} catch (error: any) {
		yield put(GetTagsThirdLevelTaskRecordContentErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message))
	}
}
