import {
    GetUserDetailsErrorAction,
    GetUserDetailsStartAction,
    GetUserDetailsSuccessAction
} from "../../../store/options/actions/actions-users";
import UsersService from "../../../services/usersService";
import {put} from "redux-saga/effects";
import {NotificationOpen} from "../../../store/notification/actions";
import CreateErrorMessage from "../../../utils/validation/createErrorMessage";


export function* sendUserDetails({userId}: ReturnType<typeof GetUserDetailsStartAction>) {
    try {
        yield UsersService.sendUserDetails(userId)
        yield put(GetUserDetailsSuccessAction())
        yield put(NotificationOpen('success', 'Письмо отправленно вам на почту'))
    } catch (error) {
        yield put(GetUserDetailsErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Повторите попытку позже'))
    }
    
} 