import React from 'react';
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {selectFilter} from "store/content/content/selectors";
import {SetSortingFilter} from "store/content/content/actions";
import {TFilterData} from "models/content";

const options = [
  {label: 'Учитываемый' , value: 'TRUE'},
  {label: 'Неучитываемый' , value: 'FALSE'},
  {label: 'Неопределённый' , value: 'NULL'}
]

const AccountedContentSelect = () => {
  const put = useDispatch()
  const filter = useSelector(selectFilter)

  const onChange = (value: string) => {
    let newFilter: TFilterData = {};
    if (filter.filterData) newFilter = { ...filter.filterData }
    newFilter = { ...newFilter, isAccountedContent: value}

    put(SetSortingFilter({ ...filter, filterData: newFilter }))
  }
  return (
    <div className="sorting__item">
      <Select
        value={filter.filterData?.isAccountedContent}
        style={{ 'width': '200px' }}
        placeholder="Все типы учета"
        onChange={onChange}
        maxTagCount={"responsive"}
        allowClear
        showSearch
        options={options}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label as unknown as string).toLowerCase().includes(input.toLowerCase())
        }
      />
    </div>
  )
}
export default AccountedContentSelect;